import React from 'react'
import { FastField } from 'formik';
import { Select } from 'botbit-ui-components'  
import './CampaignButton.css';

const TRANSLATIONS_PREFIX = "panels.campaigns.minimalEditor."

export default class CampaignButton extends React.Component {
    handleButtonSelectChange = (value) => () => {
        this.setState({
            buttonValue: value
        })
    }

    render(){
        if(!this.props.campaignBeingEdited || !this.props.campaignBeingEdited.countAsWT) return null;
        return(
            <div className="buttonContainer">
                {this.props.withLabel && <span className="inputLabel">{this.props.t("panels.campaigns.minimalEditor.button")}</span>}
                <div className="campaignButton">
                    {this.props.isEditing && 
                    <>
                    <FastField
                        name="campaign.isQrRedeem"
                        render={(props) => 
                            <Select className={(window.innerWidth < 576 ? 'w-100' : '')}
                            options={[
                                {
                                text: this.props.t(TRANSLATIONS_PREFIX + "buttonOption"),
                                value: false
                                },
                                {
                                text: this.props.t(TRANSLATIONS_PREFIX + "qrCodeOption"),
                                value: true
                                }
                            ]}
                            selected={ this.props.t(TRANSLATIONS_PREFIX + (props.field.value ? "qrCodeOption" : "buttonOption"))}
                            getValue={ (value) => { 
                                if (props.field.value !== value[0]) {
                                    props.form.setFieldValue(props.field.name, value[0]);
                                }
                            }}
                            />
                        }
                    ></FastField>
                    </>     
                    }
                    {!this.props.isEditing &&
                    <FastField
                        name={"campaign.isQrRedeem"}
                        render={(props) => 
                            <span className="">{this.props.t(TRANSLATIONS_PREFIX + (props.field.value ? "qrCodeOption" : "buttonOption"))}</span>
                        }
                    ></FastField>
                    }
                </div>
            </div>
        )
    }
}