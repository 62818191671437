import axiosClient from './axiosClient'

export const getFlows = companyId => {
    return axiosClient.get(`/conversations/flows?companyId=${companyId}`);
}


export const getFlow = id => {
    return new Promise(resolve=>{
        axiosClient.get(`/conversations/flows/${id}`)
        .then(response=>{
            const flow = response.data.data;
            const frontendFlow = {
                ...flow,
                steps: flow.steps.map(s=>({
                    ...s,
                    transientId: s.id,
                    actions: s.actions.map(a=>({
                        ...a,
                        optionTransientNextConversationFlowStepId: a.optionNextConversationFlowStep ?
                            a.optionNextConversationFlowStep.id : null
                    }))
                }))
            }
            resolve(frontendFlow);
        })
    });
}

export const postFlow = flow => {
    return axiosClient.post(`/conversations/flows/`, flow)
}