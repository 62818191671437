import { deepCopy } from "../../commons/utils/deepCopy";
import { Views } from "./Views";
import { timePeriodsIds as timePeriods, getLabel, getPrevious, getAllTimePeriods } from 'botbit-ui-components';
import { translations } from "../../config/translations/Feedback/FeedbackDashboard";
import { getTranslationFunction } from "../../config/translations/translate";

const t = getTranslationFunction(translations);

export const createDefaultValues = (provinces, localities, companies, defaultCompanyId, stores, periods, sources, view) => {

    let conditionalFilters = [
        {
            id: 'scores',
            label: t("score"),
            type: 'multiselect',
            icon: 'star',
            search: false,
            className: 'col-12',
            placeholder: t("score"),
            priority: false,
            visible: view !== Views.DETRACTORS_VIEW,
            values: [
                { checked: false, value: "1", text: "1" },
                { checked: false, value: "2", text: "2" },
                { checked: false, value: "3", text: "3" },
                { checked: false, value: "4", text: "4" },
                { checked: false, value: "5", text: "5" }
            ]
        }, {
            id: 'botbitGenerated',
            label: t("stimulatedByBotbit"),
            type: 'switch',
            className: 'col-12',
            priority: false,
            visible: view !== Views.DETRACTORS_VIEW,
            checked: false
        }]

    let filters = [{
        id: "timePeriod",
        label: t("period"),
        type: "timeperiod",
        icon: 'calendar-alt',
        className: 'col-12',
        placeholder: t("custom"),
        priority: true,
        visible: true,
        options: [...getAllTimePeriods(), timePeriods.CUSTOM],
        value: { value: timePeriods.LAST_7_DAYS, text: getLabel(timePeriods.LAST_7_DAYS) }
    }, {
        id: 'sources',
        label: t("source"),
        type: 'multiselect',
        search: false,
        valueKey: 'name',
        className: 'col-12',
        placeholder: t("allSources"),
        priority: false,
        visible: true,
        values: sources.map(source => ({
            checked: false,
            value: source.id.toString(),
            text: source.name
        }))
    },
    ...conditionalFilters,
    {
        id: 'withoutReply',
        label: t("withoutReply"),
        type: 'switch',
        className: 'col-12',
        priority: false,
        visible: true,
        checked: false
    }, {
        id: 'withComment',
        label: t("withComment"),
        type: 'switch',
        className: 'col-12',
        priority: false,
        visible: true,
        checked: false
    }, {
        id: 'autoReplied',
        label: t("autoReplied"),
        type: 'switch',
        className: 'col-12',
        priority: false,
        visible: true,
        checked: false
    }, {
        id: 'provinces',
        label: t("province"),
        type: 'multiselect',
        search: true,
        className: 'col-12',
        placeholder: t("allProvinces"),
        priority: false,
        visible: true,
        values: [...provinces].map(province => ({
            checked: false,
            value: province,
            text: province,
        }))
    }, {
        id: 'localities',
        label: t("locality"),
        type: 'multiselect',
        search: true,
        className: 'col-12',
        placeholder: t("allLocalities"),
        priority: false,
        visible: true,
        values: [...localities].map(locality => ({
            checked: false,
            value: locality,
            text: locality
        }))
    }]

    /*if (companies && companies.length > 1) {
        filters = [{
            id: 'company',
            type: 'multiselect',
            search: true,          
            className: 'col-12',
            placeholder: t('company'),
            label: t('company'),
            priority: true,
            visible: true,
            values: companies.map(company => ({
                checked: company.id === defaultCompanyId,
                value: company.id.toString(),
                text: company.name
            }))
        }].concat(filters)
    }*/

    return filters
}

export const includes = (filters, filterId) => {
    return filters.filter(f => f.id === filterId).length > 0;
}

export const getSavedFilters = () => {
    return [{
        value: '1',
        text: 'Google 5 estrellas última semana',
        checked: false,
        selectedFilters: {
            sources: ['2'],
            scores: ['5'],
            timePeriod: ['3']
        }
    }, {
        value: '2',
        text: 'Google 5 estrellas últimos 6 meses',
        checked: false,
        selectedFilters: {
            sources: ['2'],
            scores: ['5'],
            timePeriod: ['6']
        }
    }, {
        value: '3',
        text: 'Fuentes públicas última semana',
        checked: false,
        selectedFilters: {
            sources: ['2', '3'],
            timePeriod: ['3']
        }
    }];
}

export const applySelectedFiltersOverFilters = (selectedFilters, filters) => {

    const newFilters = deepCopy(filters);

    Object.keys(selectedFilters).forEach(filterId => {
        newFilters.forEach(filter => {
            if (filter.id === filterId) {
                const selectedValues = selectedFilters[filterId];
                filter.values.forEach(v => {
                    v.checked = selectedValues.includes(v.value);
                });
            }
        });
    });

    return newFilters;
}

export const removeInvisibleFilters = (selectedFilters, filters) => {
    const selection = selectedFilters.timePeriod;
    if (selection && selection.length > 0) {
        removeFiltersByIds(filters, 'specificPeriod');
        removeSelectedFiltersByIds(selectedFilters, 'from', 'to');
    }
}

export const removeFiltersByIds = (filters, ...ids) => {
    ids.forEach(id => {
        const pos = filters.map(f => f.id).indexOf(id)
        if (pos !== -1) {
            filters.splice(pos, 1)
        }
    })
}

export const removeSelectedFiltersByIds = (filters, ...ids) => {
    ids.forEach(id => filters[id] = undefined)
}

export const getSelectedTimePeriodLabel = (filters) => {
    if (!filters) {
        return '';
    }
    let label = filters.find(f => f.id === "timePeriod").value ?
        filters.find(f => f.id === "timePeriod").value.text :
        '';
    return label;
}

export const getSelectedTimePeriodPrevious = (filters) => {
    if (!filters) {
        return '';
    }

    let label =
        filters.find(f => f.id === 'timePeriod').value
            ? getPrevious(filters.find(f => f.id === 'timePeriod').value.value) : null;

    if (!label) {
        label = 'anterior'
    }
    return label;
}

export const hideFilters = (filterSetter, filters, ...ids) => {
    let newFilters = filters;
    filters.forEach(filter => {
        if (ids.includes(filter.id)) {
            newFilters.find(newFilter => newFilter.id === filter.id).visible = false;
        }
    })
    filterSetter(newFilters)
}

export const setDetractorHiddenFiltersValues = (filtersSetter, filters, filtersToCopy) => {
    if (filters && filtersToCopy) {
        let newFilters = filters;
        newFilters.find(a => a.id === "scores").values[0].checked = filtersToCopy.find(a => a.id === "scores").values[0].checked;
        newFilters.find(a => a.id === "scores").values[1].checked = filtersToCopy.find(a => a.id === "scores").values[1].checked;
        newFilters.find(a => a.id === "scores").values[2].checked = filtersToCopy.find(a => a.id === "scores").values[2].checked;
        newFilters.find(a => a.id === "scores").values[3].checked = filtersToCopy.find(a => a.id === "scores").values[3].checked;
        newFilters.find(a => a.id === "scores").values[4].checked = filtersToCopy.find(a => a.id === "scores").values[4].checked;
        newFilters.find(a => a.id === "botbitGenerated").checked = filtersToCopy.find(a => a.id === "botbitGenerated").checked;
        filtersSetter(newFilters)
    }
}

export const showFilters = (filters, ...ids) => {
    filters.forEach(filter => {
        if (ids.includes(filter.id)) {
            filter.visible = true;
        }
    })
}

export const getReviewFiltersToRedirect = (filters, periodLabel, params) => {

    let _filters = deepCopy(filters);

    _filters.forEach(filter => {

        // unselect all timePeriod options
        if (filter.id === 'timePeriod') {
            filter.value = { value: 'CUSTOM', text: periodLabel, from: new Date(params.from), to: new Date(params.to) };
        }

        // set the botbitGenerated filter
        if (filter.id === 'botbitGenerated') {
            filter.checked = params.botbitGenerated
        }

        // set the promoters filter (if selected)
        if ((params.promoters || params.publicPromoters) && filter.id === 'scores') {
            filter.visible = true
            filter.values.forEach(value => value.checked = value.value === '5')
        }

        // set the detractors filter (if selected)
        if (params.detractors && filter.id === 'scores') {
            filter.visible = true
            filter.values.forEach(value => value.checked = (value.value === '1' || value.value === '2' || value.value === '3'))
        }

        // set the public filter (if selected)
        if (params.publicPromoters && filter.id === 'sources') {
            filter.values.forEach(value => value.checked = value.value !== '1')
        }
    })

    return deepCopy(_filters);
}

export const getSelectedCompany = (filters) => {
    const companyFilter = filters.find(f => f.id === 'companies')
    return companyFilter && companyFilter.values.find(v => v.checked).value
}

export default createDefaultValues;
