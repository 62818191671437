import React from 'react';
import { Table } from "botbit-ui-components"
import { useSelector } from 'react-redux';
import { getFlows } from '../../../api/flowsApi';
import { useHistory } from 'react-router-dom';
import { MDBBtn } from 'mdbreact';
import translate from '../../../config/translations/translate';


export const FlowsDashboardContainer = ({t}) => {

    const selectedCompany = useSelector(state => state.application.selectedCompany);

    const history = useHistory();


    return <div className="flows-container">
        <h1>
            {t("title")}
        </h1>
        <MDBBtn
            className="float-left"
            color="primary"
            onClick={
                ()=>history.push("/flows/create")
            }
        >{t("add")}</MDBBtn>
        <div className={"text-left row"}>
            <div className="flows-table  w-50 m-auto  mb-5">
                <Table
                    getData={() => {
                        return getFlows(selectedCompany.id)
                    }}
                    columns={[{
                        dataField: "name",
                        title: t("name"),
                    }]}
                    dataPath="data.data.items"
                    elementKey="id"
                    onElementClick={(item) => { history.push("/flows/"+item.id)}}
                    pagination='button'
                    className='text-center'
                />
            </div>
        </div>
    </div>
}

FlowsDashboardContainer.translations={
    title:{
        es: "Flujos de conversaciones",
        en: "Conversation flows"
    },
    add: {
        es:"+ Nuevo flujo",
        en: "+ New Flow"
    },
    name: {
        es: "Nombre",
        en: "Name"
    }
}

export default translate(FlowsDashboardContainer);