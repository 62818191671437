export const clientsRowAction = {
    rowActions: {
        editClient: {
            es: "Editar Cliente",
            en: "Edit Client"
        },
        addTask: {
            es: "Agregar tarea",
            en: "Add task"
        },
        addReservation: {
            es: "Agregar reserva",
            en: "Add reservation"
        },
        sendCampaign: {
            es: "Enviar campaña",
            en: "Send campaign"
        },
        unignore: {
            es: "Designorar",
            en: "Unignore"
        },
        ignore: {
            es: "Ignorar",
            en: "Ignore"
        },
        delete: {
            es: "Eliminar",
            en: "Delete"
        },
        deletePhysical: {
            es: "Eliminar (Físico)",
            en: "Delete (Physical)"
        },
        sendNps: {
            es: "Enviar NPS",
            en: "Send NPS"
        },
        reviewHistory: {
            es: "Ver el historial de reseñas",
            en: "Go to review history"
        }
    }
}

export const translations = {
    name: {
        es: "Nombre",
        en: "Name"
    },
    lastname: {
        es: "Apellido",
        en: "Lastname"
    },
    phone: {
        es: "Teléfono",
        en: "Phone"
    },
    gender: {
        es: "Género",
        en: "Gender"
    },
    country: {
        es: "País",
        en: "Country"
    },
    origin: {
        es: "Fuente",
        en: "Origin"
    },
    registrationDate: {
        es: "Registrado",
        en: "Registered"
    },
    clients: {
        es: "Clientes",
        en: "Clients"
    },
    searchUsers: {
        es: "Buscar Usuarios",
        en: "Search Users"
    },
    importCsv: {
        es: "Importar CSV",
        en: "Import CSV"
    },
    adminActivity: {
        es: "Actividad de administrador",
        en: "Admin activity"
    },
    create: {
        es: "Crear",
        en: "Create"
    },
    segment: {
        es: "Segmento",
        en: "Segment"
    },
    genre: {
        es: "Género",
        en: "Genre"
    },
    men: {
        es: "Hombres",
        en: "Men"
    },
    women: {
        es: "Mujeres",
        en: "Women"
    },
    email: {
        es: "Email",
        en: "Email"
	},
	userStatusType: {
		title: {
			es: "Estado",
			en: "Status"
		},
		1: {
			es: "Averiguador",
			en: "Lead"
		},
		2: {
			es: "Suscriptor mensual",
			en: "Monthly"
		},
		3: {
			es: "Ex socio",
			en: "Inactive"
		},
		4: {
			es: "Suscriptor anual",
			en: "Annual"
		},
		5: {
			es: "Desconocido",
			en: "Unknown"
		},
        6: {
            es: "Suscriptor Semestral",
            en: "Biannual Subscriptor"
        },
		opportunity: {
			es: "Oportunidad",
			en: "Opportunity"
		}
	}
}
