import axiosClient from './axiosClient';

export const getAccounts = (sourceId, companyId) => {
    // const dummy = {
    //     data: {
    //         "data": {
    //           "items": [
    //             {
    //               "id": 67,
    //               "company": {
    //                 "id": 3,
    //                 "name": "RAY",
    //                 "logo": "3/53572b7d-b891-4acd-9f5a-e4c5938fedeb.gif",
    //                 "hotBotId": "5e9e11b14933740028bf9118",
    //                 "hotBotSSOId": "5e9e11b14933740028bf9115",
    //                 "hasSubscriptionStores": null
    //               },
    //               "administrator": {
    //                 "id": 2299,
    //                 "name": "Melisa",
    //                 "lastName": "Raiczyk",
    //                 "email": "melisa@botbit.io",
    //                 "avatar": "mini-noavatar.jpg",
    //                 "username": "meli.multadmin",
    //                 "fullName": "Melisa Raiczyk"
    //               },
    //               "configuration": {},
    //               "status": "CONNECTED",
    //               "errorDetail": null,
    //               "connectedTimestamp": 1629831669000,
    //               "enabled": true,
    //               "source": {
    //                 "id": 18,
    //                 "configuration": {
    //                   "matching": {
    //                     "allowedTypes": [
    //                       "LOCATION"
    //                     ]
    //                   }
    //                 },
    //                 "name": "facebook_messenger_instagram",
    //                 "displayName": "Facebook",
    //                 "category": "CONVERSATION",
    //                 "authorizationType": "OAUTH",
    //                 "integrationType": "WEBHOOK",
    //                 "documentationUrl": "",
    //                 "logo": "source_logos/facebook_messenger_instagram.jpeg",
    //                 "logoAbsolute": "https://admin3.botbit.io/static/img/source_logos/facebook_messenger_instagram.jpeg",
    //                 "countByStatus": null
    //               },
    //               "name": "Alix W. Escalante",
    //               "externalId": "10223564555955330",
    //               "store": null,
    //               "matchingType": "LOCATION",
    //               "locations": [
    //                 {
    //                   "id": 128,
    //                   "store": {
    //                     "id": 1520,
    //                     "companyId": 3,
    //                     "companyName": "RAY",
    //                     "name": "Tienda 1 de zim (copy)"
    //                   },
    //                   "externalId": "131351110813864",
    //                   "name": "AW Social Food",
    //                   "phone": null,
    //                   "addressLine1": null,
    //                   "addressLine2": null,
    //                   "city": null,
    //                   "region": null,
    //                   "country": null,
    //                   "email": null,
    //                   "website": null,
    //                   "placeId": "AWSocialFood",
    //                   "enabled": true,
    //                   "massImportRequired": true,
    //                   "createdAt": 1629831669000,
    //                   "lastSyncAt": 1629831669000,
    //                   "source": {
    //                     "id": 18,
    //                     "name": "facebook_messenger_instagram",
    //                     "category": "CONVERSATION"
    //                   }
    //                 }
    //               ]
    //             },
    //             {
    //               "id": 64,
    //               "company": {
    //                 "id": 3,
    //                 "name": "RAY",
    //                 "logo": "3/53572b7d-b891-4acd-9f5a-e4c5938fedeb.gif",
    //                 "hotBotId": "5e9e11b14933740028bf9118",
    //                 "hotBotSSOId": "5e9e11b14933740028bf9115",
    //                 "hasSubscriptionStores": null
    //               },
    //               "administrator": {
    //                 "id": 2299,
    //                 "name": "Melisa",
    //                 "lastName": "Raiczyk",
    //                 "email": "melisa@botbit.io",
    //                 "avatar": "mini-noavatar.jpg",
    //                 "username": "meli.multadmin",
    //                 "fullName": "Melisa Raiczyk"
    //               },
    //               "configuration": {},
    //               "status": "DISCONNECTED",
    //               "errorDetail": null,
    //               "connectedTimestamp": 1628716630000,
    //               "enabled": true,
    //               "source": {
    //                 "id": 18,
    //                 "configuration": {
    //                   "matching": {
    //                     "allowedTypes": [
    //                       "LOCATION"
    //                     ]
    //                   }
    //                 },
    //                 "name": "facebook_messenger_instagram",
    //                 "displayName": "Facebook",
    //                 "category": "CONVERSATION",
    //                 "authorizationType": "OAUTH",
    //                 "integrationType": "WEBHOOK",
    //                 "documentationUrl": "",
    //                 "logo": "source_logos/facebook_messenger_instagram.jpeg",
    //                 "logoAbsolute": "https://admin3.botbit.io/static/img/source_logos/facebook_messenger_instagram.jpeg",
    //                 "countByStatus": null
    //               },
    //               "name": "Melisa Raiczyk",
    //               "externalId": "10226237461104635",
    //               "store": null,
    //               "matchingType": "LOCATION",
    //               "locations": [
    //                 {
    //                   "id": 121,
    //                   "store": {
    //                     "id": 2049,
    //                     "companyId": 3,
    //                     "companyName": "RAY",
    //                     "name": "Tiende Adrian 2049"
    //                   },
    //                   "externalId": "839744049514173",
    //                   "name": "RAY",
    //                   "phone": null,
    //                   "addressLine1": null,
    //                   "addressLine2": null,
    //                   "city": null,
    //                   "region": null,
    //                   "country": null,
    //                   "email": null,
    //                   "website": null,
    //                   "placeId": "therayapp",
    //                   "enabled": false,
    //                   "massImportRequired": true,
    //                   "createdAt": 1628716155000,
    //                   "lastSyncAt": 1628716630000,
    //                   "source": {
    //                     "id": 18,
    //                     "name": "facebook_messenger_instagram",
    //                     "category": "CONVERSATION"
    //                   }
    //                 }
    //               ]
    //             },
    //             {
    //               "id": 65,
    //               "company": {
    //                 "id": 3,
    //                 "name": "RAY",
    //                 "logo": "3/53572b7d-b891-4acd-9f5a-e4c5938fedeb.gif",
    //                 "hotBotId": "5e9e11b14933740028bf9118",
    //                 "hotBotSSOId": "5e9e11b14933740028bf9115",
    //                 "hasSubscriptionStores": null
    //               },
    //               "administrator": {
    //                 "id": 2310,
    //                 "name": "Esteban",
    //                 "lastName": "De Rosa",
    //                 "email": "melisa@botbit.io",
    //                 "avatar": "mini-noavatar.jpg",
    //                 "username": "melisa.company",
    //                 "fullName": "Esteban De Rosa"
    //               },
    //               "configuration": {},
    //               "status": "CONNECTED",
    //               "errorDetail": null,
    //               "connectedTimestamp": 1627664573000,
    //               "enabled": true,
    //               "source": {
    //                 "id": 18,
    //                 "configuration": {
    //                   "matching": {
    //                     "allowedTypes": [
    //                       "LOCATION"
    //                     ]
    //                   }
    //                 },
    //                 "name": "facebook_messenger_instagram",
    //                 "displayName": "Facebook",
    //                 "category": "CONVERSATION",
    //                 "authorizationType": "OAUTH",
    //                 "integrationType": "WEBHOOK",
    //                 "documentationUrl": "",
    //                 "logo": "source_logos/facebook_messenger_instagram.jpeg",
    //                 "logoAbsolute": "https://admin3.botbit.io/static/img/source_logos/facebook_messenger_instagram.jpeg",
    //                 "countByStatus": null
    //               },
    //               "name": "Maria Algajifhjajhg Huiescu",
    //               "externalId": "103825821997311",
    //               "store": null,
    //               "matchingType": "LOCATION",
    //               "locations": [
    //                 {
    //                   "id": 111,
    //                   "store": {
    //                     "id": 3,
    //                     "companyId": 3,
    //                     "companyName": "RAY",
    //                     "name": "RAY"
    //                   },
    //                   "externalId": "100133499032674",
    //                   "name": "Test TV Movie",
    //                   "phone": null,
    //                   "addressLine1": null,
    //                   "addressLine2": null,
    //                   "city": null,
    //                   "region": null,
    //                   "country": null,
    //                   "email": null,
    //                   "website": null,
    //                   "placeId": "Test-TV-Movie-100133499032674",
    //                   "enabled": null,
    //                   "massImportRequired": true,
    //                   "createdAt": 1627664573000,
    //                   "lastSyncAt": 1627664573000,
    //                   "source": {
    //                     "id": 18,
    //                     "name": "facebook_messenger_instagram",
    //                     "category": "CONVERSATION"
    //                   }
    //                 },
    //                 {
    //                   "id": 112,
    //                   "store": null,
    //                   "externalId": "107998041573108",
    //                   "name": "Test Sports Page",
    //                   "phone": null,
    //                   "addressLine1": null,
    //                   "addressLine2": null,
    //                   "city": null,
    //                   "region": null,
    //                   "country": null,
    //                   "email": null,
    //                   "website": null,
    //                   "placeId": "Test-Sports-Page-107998041573108",
    //                   "enabled": null,
    //                   "massImportRequired": true,
    //                   "createdAt": 1627664573000,
    //                   "lastSyncAt": 1627664573000,
    //                   "source": {
    //                     "id": 18,
    //                     "name": "facebook_messenger_instagram",
    //                     "category": "CONVERSATION"
    //                   }
    //                 }
    //               ]
    //             },
    //             {
    //               "id": 71,
    //               "company": {
    //                 "id": 3,
    //                 "name": "RAY",
    //                 "logo": "3/53572b7d-b891-4acd-9f5a-e4c5938fedeb.gif",
    //                 "hotBotId": "5e9e11b14933740028bf9118",
    //                 "hotBotSSOId": "5e9e11b14933740028bf9115",
    //                 "hasSubscriptionStores": null
    //               },
    //               "administrator": {
    //                 "id": 2310,
    //                 "name": "Esteban",
    //                 "lastName": "De Rosa",
    //                 "email": "melisa@botbit.io",
    //                 "avatar": "mini-noavatar.jpg",
    //                 "username": "melisa.company",
    //                 "fullName": "Esteban De Rosa"
    //               },
    //               "configuration": {},
    //               "status": "CONNECTED",
    //               "errorDetail": null,
    //               "connectedTimestamp": 1631570260000,
    //               "enabled": true,
    //               "source": {
    //                 "id": 18,
    //                 "configuration": {
    //                   "matching": {
    //                     "allowedTypes": [
    //                       "LOCATION"
    //                     ]
    //                   }
    //                 },
    //                 "name": "facebook_messenger_instagram",
    //                 "displayName": "Facebook",
    //                 "category": "CONVERSATION",
    //                 "authorizationType": "OAUTH",
    //                 "integrationType": "WEBHOOK",
    //                 "documentationUrl": "",
    //                 "logo": "source_logos/facebook_messenger_instagram.jpeg",
    //                 "logoAbsolute": "https://admin3.botbit.io/static/img/source_logos/facebook_messenger_instagram.jpeg",
    //                 "countByStatus": null
    //               },
    //               "name": "Gustavo Fuhr",
    //               "externalId": "120971746951311",
    //               "store": null,
    //               "matchingType": "LOCATION",
    //               "locations": [
    //                 {
    //                   "id": 132,
    //                   "store": {
    //                     "id": 3,
    //                     "companyId": 3,
    //                     "companyName": "RAY",
    //                     "name": "RAY"
    //                   },
    //                   "externalId": "17841449276487703",
    //                   "name": "Gustavo Fuhr (Instagram)",
    //                   "phone": null,
    //                   "addressLine1": null,
    //                   "addressLine2": null,
    //                   "city": null,
    //                   "region": null,
    //                   "country": null,
    //                   "email": null,
    //                   "website": null,
    //                   "placeId": "gfuhr_rayapp",
    //                   "enabled": true,
    //                   "massImportRequired": true,
    //                   "createdAt": 1631570260000,
    //                   "lastSyncAt": 1631570260000,
    //                   "source": {
    //                     "id": 18,
    //                     "name": "facebook_messenger_instagram",
    //                     "category": "CONVERSATION"
    //                   }
    //                 },
    //                 {
    //                   "id": 133,
    //                   "store": {
    //                     "id": 3,
    //                     "companyId": 3,
    //                     "companyName": "RAY",
    //                     "name": "RAY"
    //                   },
    //                   "externalId": "692727381566184",
    //                   "name": "Test Page",
    //                   "phone": null,
    //                   "addressLine1": null,
    //                   "addressLine2": null,
    //                   "city": null,
    //                   "region": null,
    //                   "country": null,
    //                   "email": null,
    //                   "website": null,
    //                   "placeId": "Test-Page-692727381566184",
    //                   "enabled": true,
    //                   "massImportRequired": true,
    //                   "createdAt": 1631570260000,
    //                   "lastSyncAt": 1631570260000,
    //                   "source": {
    //                     "id": 18,
    //                     "name": "facebook_messenger_instagram",
    //                     "category": "CONVERSATION"
    //                   }
    //                 }
    //               ]
    //             }
    //           ],
    //           "pageNumber": 0,
    //           "pageSize": 0,
    //           "totalSize": 0,
    //           "empty": false
    //         },
    //         "result": "success",
    //         "message": "ok",
    //         "statusCode": 200
    //       }
    // }
    // return new Promise(res=> res(dummy))
    const url = `/sources/${sourceId}/accounts?companyId=${companyId}`;
    return axiosClient.get(url);
}

export const disconnect = (accountId, companyId) => {
    const url = `/sources/accounts/${accountId}/disconnect/?companyId=${companyId}`;
    return axiosClient.put(url);
}