import React, { Component } from "react";
import { translate } from "react-i18next";
import { connect } from 'react-redux';
import SocialAccounts from "./SocialAccounts";
import { CaptureMode } from '../../commons/enums/CaptureMode'
import { getSocialAccountsToCaptureUsers, getSocialAccountsToCaptureReviews, googleOptIn, facebookOptIn } from '../../api/socialAccountsApi';
import { getStoresForAdministrator } from "../../api/administratorApi";
import { setLoading } from '../../commons/components/application/miscActions'

//const ENABLED_COMPANIES_TO_CAPTURE_USER = [3, 24]
const ENABLED_COMPANIES_TO_CAPTURE_USER = []

class SocialAccountsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    showCaptureUsers = () => ENABLED_COMPANIES_TO_CAPTURE_USER.includes(this.props.selectedCompany.id)

    goToConnectedStores = () => this.props.history.push('/feedback/connectedstores')

    render() {
        return (<>
            {this.showCaptureUsers() && <SocialAccounts
                title={this.props.t('socialAccounts.usersTitle')}
                t={this.props.t}
                captureMode={CaptureMode.USERS}
                getSocialAccounts={getSocialAccountsToCaptureUsers}
                googleOptIn={googleOptIn}                
                getStoresByCompany={(companyId) => getStoresForAdministrator(this.props.admin.id, companyId)}
                availableCompanies={this.props.availableCompanies}
                selectedCompany={this.props.selectedCompany}      
            />}
            {<SocialAccounts
                title={this.props.t('socialAccounts.reviewsTitle')}
                t={this.props.t}
                captureMode={CaptureMode.REVIEWS}
                getSocialAccounts={getSocialAccountsToCaptureReviews}
                googleOptIn={googleOptIn}
                facebookOptIn={facebookOptIn}
                goToConnectedStores={this.goToConnectedStores}
                availableCompanies={this.props.availableCompanies}
                selectedCompany={this.props.selectedCompany}
            />}
        </>)
    }
}

function mapStateToProps(state) {
	return {
        admin: state.login.admin,
        availableCompanies: state.application.availableCompanies,
        selectedCompany: state.application.selectedCompany
	}
}

export default connect(mapStateToProps, {setLoading})(translate('common')(SocialAccountsContainer))
