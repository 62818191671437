import React from 'react';
import ProfileAvatar from '../../commons/components/utils/table/formaters/ProfileAvatar';
import RegisteredTroughIcon from '../../commons/components/utils/table/formaters/RegisteredTroughIcon';
import TimeAgo from '../../commons/components/utils/TimeAgo.js';
import Gender from '../../commons/components/utils/Gender';
import ReactCountryFlag from "react-country-flag";
import translate from "../../config/translations/translate";
import { clientsRowAction, translations } from "../../config/translations/Clients/ClientsTable";
import ClientActions from './ClientActions';
import UserDetailsContainer from '../../commons/components/users/details/UserDetailsContainer.js';
import { CollectionVisualizer } from 'botbit-ui-components'
import CreateUser from './SearchUserForm.js';
import deepCopy from "../../commons/utils/deepCopy";
import ImportUsersModal from './ImportUsersModal.js';
import { getDefaultFilterValues } from './FiltersHelper.js';
import { getUserActivity, getStore } from '../../api/storeApi';
import { connect } from 'react-redux';

const getRegistration = (user, store) => {
	if (!store)
		return;
	let registration;
	if (user.storeRegistrations) {
		registration = user.storeRegistrations.find((sr) => {
			return (sr.storeId === store.id);
		});
	}
	return registration;
}

class ClientsTable extends React.Component {

	constructor(props) {
		super(props);
		const defaultFilterValues = getDefaultFilterValues(this.props.segments, this.props);
		this.state = {
			showImportModal: false,
			filters: this.getFiltersWithSegmentApplied(defaultFilterValues.filters),
			defaultFilters: defaultFilterValues.filters,
			initialOrder: defaultFilterValues.initialOrder
		}
	}

	getFiltersWithSegmentApplied = (filters) => {
		const { selectedSegment } = this.props;
		if (selectedSegment) {
			filters.find(f => f.id === "segments").values.map(s => {
				if (s.value && s.value.toString() === selectedSegment) s.checked = true
				return s;
			})
		}
		return filters;
	}

	hideImportModal = () => {
		this.setState(
			{
				showImportModal: false
			}
		);
	}

	showImportModal = () => {
		this.setState(
			{
				showImportModal: true
			}
		);
	}

	closeCreateUserModal = () => {
		this.setState(
			{
				showCreateUserModal: false
			}
		);
	}

	showCreateUserModal = () => {
		this.setState(
			{
				showCreateUserModal: true
			}
		);
	}



	openAction = (action, client, updateRow) => {
		if (this.state.actionsContainer && this.state.actionsContainer.actionName) this.closeAction();
		this.getUserAvailableStores(client.id).then(() => {
			this.setState(
				{
					actionsContainer: {
						actionName: action.label,
						actionId: action.id,
						client: client,
						updateUser: updateRow
					}
				}
			);
		})
	}

	closeAction = () => {
		this.setState(
			{
				actionsContainer: {
					actionName: undefined,
					client: undefined
				}
			}
		);
	}

	getUserAvailableStores = async (userId) => {
		this.setState({ selectedUser: userId });
		const companyStoresIds = this.props.availableStores.map(store => store.id);
		const res = await getUserActivity(userId, companyStoresIds);
		const storesArray = res.data.data.userInformation.storeRegistrations;
		const storesFiltered = storesArray.filter(store => companyStoresIds.includes(store.storeId));
		const formattedStores = await storesFiltered.map(async store => {
			const storeRes = await getStore(store.storeId);
			const storeData = storeRes.data.data;
			return storeData;
		})
		const storesDone = await Promise.all(formattedStores).then(res => res);
		this.setState({ userStores: [...storesDone] })
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedStoreIsSuscription !== this.props.selectedStoreIsSuscription || prevProps.segments !== this.props.segments) {
			let { filters, initialOrder } = getDefaultFilterValues(this.props.segments, this.props);
			this.setState({
				defaultFilters: filters,
				filters: this.getFiltersWithSegmentApplied(filters),
				initialOrder: initialOrder
			})
		}
		if (prevProps.selectedStore !== this.props.selectedStore) {
			this.setState({ hideTable: true });
		}
		if (this.state.hideTable) {
			this.setState({ hideTable: false })
		}
	}

	rowActions = (row, updateRow) => {
		const { t } = this.props;
		const storesRegistered = row.storeRegistrations;
		const storesIgnored = storesRegistered.filter(store => store.ignored && store.storeCompanyId === this.props.selectedCompany.id);
		const thisAction = (actionList, actionId) => (actionList.find(action => action.id === actionId))
		let actions = [
			{
				key: 1,
				color: "",
				icon: "edit",
				id: "edit",
				label: t("rowActions.editClient"),
				onClick: () => {
					this.openAction(thisAction(actions, 'edit'), row, updateRow)
				}
			}
		];
		if (this.props.selectedStoreIsSuscription || (this.props.selectedStore.id === -1 && this.props.selectedCompany.hasSubscriptionStores)) {
			actions.push(
				{
					key: 4,
					color: "",
					id: "add-task",
					icon: "tasks",
					label: t("rowActions.addTask"),
					onClick: () => {
						this.openAction(thisAction(actions, 'add-task'), row, updateRow)
					}
				}
			);
		}
		else {
			actions.push(
				{
					key: 2,
					color: "",
					id: "reservation",
					icon: "calendar-check",
					label: t("rowActions.addReservation"),
					onClick: () => {
						this.openAction(thisAction(actions, 'reservation'), row)
					}
				}
			);
			// }
		}
		actions.push(
			{
				key: 3,
				color: "",
				id: "send-campaign",
				icon: "envelope",
				label: t("rowActions.sendCampaign"),
				onClick: () => {
					this.openAction(thisAction(actions, 'send-campaign'), row)
				}
			}
		)
		if (storesIgnored && storesIgnored.length > 0) {
			actions.push(
				{
					key: 6,
					color: "",
					id: "unignore",
					icon: "ban",
					label: t("rowActions.unignore"),
					onClick: () => {
						this.openAction(thisAction(actions, 'unignore'), row, updateRow)
					}
				}
			);
		} else {
			actions.push(
				{
					key: 7,
					color: "",
					id: "ignore",
					icon: "ban",
					label: t("rowActions.ignore"),
					onClick: () => {
						this.openAction(thisAction(actions, 'ignore'), row, updateRow)
					}
				}
			);
			// }
			actions.push(
				{
					key: 6,
					color: "",
					id: "delete",
					icon: "user-minus",
					label: t("rowActions.delete"),
					onClick: () => {
						this.openAction(thisAction(actions, 'delete'), row, updateRow)
					}
				}
			);
		}
		if (this.props.admin.objectAccess === 'ALL') {
			actions.push(
				{
					key: 8,
					color: "",
					id: "delete-physical",
					icon: "trash",
					label: t("rowActions.deletePhysical"),
					onClick: () => {
						this.openAction(thisAction(actions, 'delete-physical'), row, updateRow)
					}
				}
			);
		}
		if (row.phone) {
			actions.push({
				key: 9,
				icon: "comments",
				id: "send-nps",
				label: t("rowActions.sendNps"),
				onClick: () => this.openAction(thisAction(actions, 'send-nps'), row),
			})
		}
		actions.push(
			{
				key: 10,
				color: "",
				id: "review-history",
				icon: "comment-alt",
				label: t("rowActions.reviewHistory"),
				onClick: () => {
					this.openAction(thisAction(actions, 'review-history'), row, updateRow)
				}
			}
		)
		return actions;
	}

	openModal = () => {
		this.setState({ openModal: true });
	}

	closeModal = () => {
		this.setState({ openModal: false });
	}

	render = () => {
		const { t } = this.props;
		const columns = [
			// for BIG screens: greater than md
			{
				dataField: 'ignored',
				title: '',
				className: 'text-center d-none d-md-block',
				size: "2",
				formatter: (cellContent, row) => {
					const registration = getRegistration(row, this.props.selectedStore);
					const registeredStores = row.storeRegistrations;
					const storesIgnored = registeredStores.filter(store => store.ignored && store.storeCompanyId === this.props.selectedCompany.id)
					return (
						<ProfileAvatar avatar={row.avatar} storeRegistration={registration} blackListed={row.emailStatus === 'blacklisted'} inactive={storesIgnored.length > 0} />
					);
				}
			}, {
				dataField: 'name',
				title: t('name'),
				className: 'my-auto d-none d-md-block',
				sort: true,
				formatter: (cellContent, row) => {
					const hasFirstName = row.name && row.name !== "" && row.name.toLowerCase() !== "null";
					const hasLastName = row.lastName && row.lastName !== "" && row.lastName.toLowerCase() !== "null";
					var textToShow = null;
					if ( hasFirstName || hasLastName) {
						textToShow = hasFirstName ? row.name : "";
					} else {
						textToShow = row.email.replace("@botbitmail.com", "")
					}
					return (
						<span>{textToShow}</span>
					);
				},
				size: "2"
			}, {
				dataField: 'lastName',
				title: t('lastname'),
				sort: true,
				className: 'my-auto d-none d-md-block',
				formatter: (cellContent, row) => {
					const hasFirstName = row.name && row.name !== "" && row.name.toLowerCase() !== "null";
					const hasLastName = row.lastName && row.lastName !== "" && row.lastName.toLowerCase() !== "null";
					var textToShow = null;
					if ( hasFirstName || hasLastName) {
						textToShow = hasLastName ? row.lastName : "";
					} else {
						textToShow = ""
					}
					return (
						<span>{textToShow}</span>
					);
				},
				size: "2"
			}, {
				dataField: 'gender',
				title: t('gender'),
				className: 'm-auto d-none d-md-block',
				sort: true,
				formatter: (cellContent, row) => {
					return (
						<Gender gender={cellContent} size='2x' />
					);
				},
				size: "1",
			}, {
				dataField: 'country',
				title: t('country'),
				className: 'm-auto d-none d-md-block',
				formatter: (cellContent, row) => {
					// TODO: inferir pais del email
					return (
						<ReactCountryFlag styleProps={{ width: '1.8rem', height: '1.8rem' }} code={cellContent === null ? '' : cellContent} svg />
					);
				},
				size: "1"
			}, {
				dataField: 'ignored',
				title: '',
				className: 'text-center d-md-none',
				size: 3,
				formatter: (cellContent, row) => {
					const registration = getRegistration(row, this.props.selectedStore);
					const registeredStores = row.storeRegistrations;
					const storesIgnored = registeredStores.filter(store => store.ignored && store.storeCompanyId === this.props.selectedCompany.id)
					return (
						<ProfileAvatar storeRegistration={registration} avatar={row.avatar} inactive={storesIgnored.length > 0} />
					);
				}
			}, {
				dataField: 'name',
				title: t('name'),
				classes: 'align-left',
				className: 'row-title d-md-none',
				sort: true,
				isHeader: true,
				formatter: (cellContent, row) => {
					return (
						<span>{row.userDisplayName}</span>
					);
				},
				size: 12
			},
			{
				dataField: 'gender',
				title: t('gender'),
				className: 'd-md-none ',
				sort: true,
				formatter: (cellContent, row) => {
					return (
						<Gender gender={cellContent} size='2x' />
					);
				},
				size: 2,
			}, {
				dataField: 'country',
				title: t('country'),
				className: 'd-md-none ',
				formatter: (cellContent, row) => {
					// TODO: inferir pais del email
					return (
						<ReactCountryFlag styleProps={{ width: '1.8rem', height: '1.8rem' }} code={cellContent === null ? '' : cellContent} svg />
					);
				},
				size: 3
			}
		];

		let config = [
			{
				key: 1,
				icon: "plus",
				id: "create-user",
				label: t("create"),
				primary: true,
				onClick: this.showCreateUserModal,
			}
		];

		if (this.props.selectedStore && this.props.selectedStore.id !== -1) {
			config.push(
				{
					key: 3,
					icon: "file-import",
					label: t("importCsv"),
					color: "red",
					primary: false,
					onClick: this.showImportModal
				}
			);
			config.unshift(
				{
					key: 2,
					icon: "file-export",
					label: t("adminActivity"),
					primary: false,
					onClick: this.props.exportAdminEvents
				}
			);
			columns.push(
				{
					dataField: 'registeredThrough',
					title: t('origin'),
					className: 'm-auto d-none d-md-block',
					hidden: !this.props.storeView,
					formatter: (cellContent, row) => {
						const name = cellContent ? cellContent.id : '';
						return (
							<RegisteredTroughIcon name={name} />
						);
					},
					size: "1"
				}
			);
			columns.push(
				{
					dataField: 'registeredThrough',
					title: t('origin'),
					className: 'd-md-none align-items-center justify-content-center',
					hidden: !this.props.storeView,
					formatter: (cellContent, row) => {
						const name = cellContent ? cellContent.id : '';
						return (
							<RegisteredTroughIcon name={name} />
						);
					},
					size: 2
				}
			);
			columns.push({
				dataField: 'registrationDate',
				title: t('registrationDate'),
				classes: 'align-middle text-center',
				className: 'm-auto d-none d-md-block',
				sort: true,
				hidden: !this.props.storeView,
				formatter: (cellContent, user) => {
					var registration;
					if (!this.props.selectedStore) {
						registration = {};
						user.storeRegistrations.forEach(
							(sr) => {
								if (!registration.registrationDate || registration.registrationDate > sr.registrationDate)
									registration.registrationDate = sr.registrationDate;
							}
						);
					}
					else {
						if (user.storeRegistrations) {
							registration = user.storeRegistrations.find((sr) => {
								return (sr.storeId === this.props.selectedStore.id);
							});
						} else {
							registration = {}
						}
					}
					return (
						<TimeAgo date={registration ? registration.registrationDate : undefined} noDateText='panels.clients.noRegistered' />
					);
				},
				size: 2
			});
		}

		return (
			<React.Fragment>
				<ImportUsersModal
					show={this.state.showImportModal}
					t={this.props.t}
					store={this.props.selectedStore}
					admin={this.props.admin}
					token={this.props.token}
					closeModal={this.hideImportModal} />
				<CreateUser
					show={this.state.showCreateUserModal}
					closeModal={this.closeCreateUserModal}
					user={{}}
					stores={this.props.availableStores}
					createUser={this.props.createUser}
					selectedStore={this.props.selectedStore && this.props.selectedStore.id !== -1 ? this.props.selectedStore : undefined} />
				{this.state.userStores && this.state.selectedUser && (
					<ClientActions
						{...this.state.actionsContainer}
						onClose={this.closeAction}
						stores={this.props.availableStores}
						selectedStore={this.state.userStores.length === 1 && this.state.userStores[0]}
						componentSelectedStore={this.props.selectedStore && this.props.selectedStore.id !== -1 ? this.props.selectedStore : undefined}
					/>
				)}
				<div className='mx-lg-5'>
					{!this.state.hideTable &&
						<CollectionVisualizer
							scrollTopButton
							title={t("clients")}
							export={this.props.exportUsers}
							exportName={this.props.exportName}
							formats={[{ type: 'text/csv', format: 'csv' }]}
							columns={columns}
							getData={this.props.getData}
							headerActions={config}
							rowActions={this.rowActions}
							defaultFilters={deepCopy(this.state.defaultFilters)}
							filters={this.state.filters}
							onResetFilters={(newFilters) => this.setState({ filters: newFilters, hideTable: true })}
							onFiltersApply={(a, newFilters) => this.setState({ filters: newFilters })}
							alwaysFloatingFilter={true}
							showFiltersInfo={{}}
							initialOrder={this.state.initialOrder}
							filtersTitle={t("searchUsers")}
							actionsPrefix="users"
							expandRow={
								(active, row, onRowChanged) => {
									return (
										active === true &&
										<div className='pt-2 gray-bg'>
											<UserDetailsContainer 
												selectedStores={
													this.props.selectedStore && this.props.selectedStore.id !== -1 ?
													[this.props.selectedStore] : 
													this.props.availableStores
												} 
												userId={row.id} />
										</div>
									)
								}
							}
						/>
					}
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		selectedCompany: state.application.selectedCompany,
	}
}

ClientsTable.translations = {
	...clientsRowAction,
	...translations
}

export default connect(mapStateToProps)(translate(ClientsTable));
