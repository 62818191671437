import React, { useEffect, useState } from "react";
import { CollectionVisualizer, getLabel } from "botbit-ui-components";
import deepCopy from "../../commons/utils/deepCopy";
import { withRouter } from "react-router-dom";
import { FilterTypes } from 'botbit-ui-components';
import { translations } from "../../config/translations/CollectionVisualizeContainer";
import translate from "../../config/translations/translate";

const CollectionVisualizerContainerWithCompanies = ({ history, companies, selectedCompany, stores, setFilters, t, ...props }) => {

	const params = new URLSearchParams(decodeURIComponent(history.location.search))
	const COMPANY_FILTER_ID = 'companies'
	const STORES_FILTER_ID = 'stores'

	const hasCompanyParam = params.get(COMPANY_FILTER_ID) !== null;
	const debugEnabled = false;

	if (debugEnabled) {
		console.log("------------------- CollectionVisualizerContainerWithCompanies START")
		console.log("hasCompanyParam", hasCompanyParam)
		console.log("companies", companies)
		console.log("selectedCompany", selectedCompany)
		console.log("props", props)
		for (let p of params) {
			console.log("urlParams", p)
		}
	}


	// Filters managed by CollectionVisualizerContainer
	const defaultInternalFilters = props.defaultFilters || [];
	const [internalFilters, setInternalFilters] = useState((props.filters) ? undefined : (props.defaultFilters || defaultInternalFilters))
	const [renderStores, setRenderStores] = useState(true);
	const [initialOrder, setInitialOrder] = useState(null);
	const getDefaultFilters = () => props.defaultFilters || defaultInternalFilters
	const getFilters = () => props.filters || internalFilters

	const isFilterPresent = (filters, filterId) => {
		if (!filters) 
			return false;
		return filters.some(f => f.id === filterId)
	}
	useEffect(() => {
		if (!renderStores) setRenderStores(true)
	}, [renderStores])

	useEffect(() => {
		if (debugEnabled)
			console.log("useEffect selectedCompany")

		if (!selectedCompany)
			return
		const filtersCopy = deepCopy(getFilters())
		const companyFilter = filtersCopy.find(f => f.id === COMPANY_FILTER_ID)
		if (!companyFilter)
			return
		companyFilter.values.map(v => v.checked = (v.value === selectedCompany.id.toString()))
		if (props.filters && setFilters)
			setFilters(filtersCopy)
		if (internalFilters)
			setInternalFilters(filtersCopy)

	}, [selectedCompany])

	useEffect(() => {
		if (debugEnabled)
			console.log("useEffect []")

		let newFilters = getFilters();
		const getFilter = (filterId) => {
			return filterId && newFilters.find(filter => filter.id === filterId)
		}

		let order = props.initialOrder;
		params.forEach((param, key) => {
			const filter = getFilter(key)
			const values = param.split('|')
			if (filter) {
				let filterType = FilterTypes[filter.type]
				filter[filterType.valueField] = filterType.stringsToValues(values, filter)
			} else if (key === "order") {
				order = { name: values[0], direction: values[1] }
			} else if (key === "from") {
				const filter2 = getFilter("timePeriod")
				filter2.value.from = new Date(values[0]);
			} else if (key === "to") {
				const filter3 = getFilter("timePeriod")
				filter3.value.to = new Date(values[0]);
			}
		})

		// si me pasan periodo CUSTOM y no me pasan fecha pongo 7 dias x default
		const tpFilter = getFilter("timePeriod");
		if (tpFilter && tpFilter.value && tpFilter.value.value === "CUSTOM" && (!tpFilter.value.from || !tpFilter.value.to)) {
			tpFilter.value.from = null;
			tpFilter.value.to = null;
			tpFilter.value.value = "LAST_7_DAYS";
			tpFilter.value.text = getLabel("LAST_7_DAYS")
		}

		setInitialOrder(order);
		if (props.filters && setFilters)
			setFilters(newFilters)
		if (internalFilters)
			setInternalFilters(newFilters)
		setRenderStores(false);
	}, []);

	const getCompanyFilter = (checked) => {
		const company = companies.find(c => c.id === selectedCompany.id)
		const defCompId = (company) ? selectedCompany.id : companies[0].id

		return {
			id: COMPANY_FILTER_ID,
			type: 'multiselect',
			icon: 'building',
			search: true,
			className: 'col-12',
			placeholder: 'Empresa',
			label: 'Empresa',
			priority: true,
			visible: companies.length > 1, //true,
			values: companies.map(company => ({
				checked: company.id === defCompId && !hasCompanyParam && checked,
				value: company.id.toString(),
				text: company.name
			}))
		}
	}

	const getStoresFilter = () => {
		return {
			id: STORES_FILTER_ID,
			label: t("store"),
			type: 'multiselect',
			icon: 'store-alt',
			search: true,
			className: 'col-12',
			placeholder: 'Todas las tiendas',
			priority: true,
			visible: true,
			values: stores.map(store => ({
				checked: props.selectedStores ? props.selectedStores.some(selectedStore => parseInt(selectedStore) === store.id) : false,
				value: store.id.toString(),
				text: store.name,
				dependencyId: store.companyId
				//icon: store.logo            
			})),
			dependsOn: COMPANY_FILTER_ID
		}
	}

	const addCompanyFilter = (filters, checked) => {
		if (isFilterPresent(filters, COMPANY_FILTER_ID) || !companies) return
		const companyFilter = getCompanyFilter(checked)
		filters.push(companyFilter)
	}

	const addStoresFilter = (filters) => {
		if (isFilterPresent(filters, STORES_FILTER_ID) || !stores) return
		const storesFilter = getStoresFilter()
		filters.push(storesFilter)
	}

	// niguna company esta seleccionada en defaultFilters
	addCompanyFilter(getDefaultFilters(), false)
	// en filters si estan seleccionadas
	addCompanyFilter(getFilters(), true)
	addStoresFilter(getDefaultFilters())
	addStoresFilter(getFilters())

	const onInternalFiltersApply = (selectedFilters, filters) => {
		if (internalFilters) setInternalFilters(filters)
		if (props.onFiltersApply) props.onFiltersApply(selectedFilters, filters)
	}

	const onResetInternalFilters = () => {
		if (internalFilters) setInternalFilters(getDefaultFilters())
		if (props.onResetFilters) props.onResetFilters()
	}

	if (debugEnabled)
		console.log("------------------- CollectionVisualizerContainerWithCompanies END")

	return <div>
		{renderStores && <CollectionVisualizer
			{...props}
			initialOrder={initialOrder}
			filters={getFilters()}
			defaultFilters={getDefaultFilters()}
			onFiltersApply={onInternalFiltersApply}
			onResetFilters={onResetInternalFilters}
		>
		</CollectionVisualizer>}
	</div>
}
CollectionVisualizerContainerWithCompanies.translations = {
	...translations
}

export default withRouter(translate(CollectionVisualizerContainerWithCompanies))
