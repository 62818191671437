import React from 'react';
import { connect } from 'react-redux';
import { logoutRequest } from './loginActions';
import { envConfig } from '../../config/config.js';
import { getCookieFromBrowser, removeCookie } from '../../commons/utils/cookie';
import axiosClient from '../../api/axiosClient';
import { setLoading } from '../../commons/components/application/miscActions'
import { withRouter } from 'react-router-dom'

class LogoutContainer extends React.Component {

    shouldComponentUpdate() {
        return !this.props.loggedIn;
    }

    componentDidMount() {

        // const fromFeedback = window.location.href.startsWith(envConfig.feedbackDomain);

        this.props.setLoading(true);

        var url = envConfig.frontendPhpUrl + "/externalLogout.php";
        if (this.props.phpSession != null) {
            url += "?session=" + this.props.phpSession;
        }
        var OneSignal = window.OneSignal || [];
        OneSignal.push(function() {
            OneSignal.removeExternalUserId();
        });
        this.props.logoutRequest();
        removeCookie("rememberMeUsernameCookie");
        axiosClient.defaults.headers.common['Authorization'] = "";
        axiosClient.get(url)
            .then((response) => {
                this.props.setLoading(false);
                //window.location.href = (fromFeedback) ? envConfig.feedbackDomain + "/login" + this.props.location.search
                //    : envConfig.frontendPhpUrl + "/login" + this.props.location.search;
                this.props.history.push("/login" + this.props.location.search);
            })
          
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.login.loggedIn,
        phpSession: getCookieFromBrowser("PHPSESSID")
    };
}

export default withRouter(connect(mapStateToProps, { logoutRequest, setLoading })(LogoutContainer))