import { envConfig } from "../../config";

export const translations = {
    title: {
        es: "Centro de integraciones",
        en: "Integration center"
    },
    subtitle: {
        es: `Aquí podrás conectar las aplicaciones que utilizas con ${envConfig.companyName}`,
        es_ar: `Acá vas a poder conectar las aplicaciones que utilizás con ${envConfig.companyName}`,
        en: `Connect your external applications with ${envConfig.companyName} here`
    },
    connect: {
        es: "Conectar",
        en: "Connect"
    },
    addModalTitle: {
        es: "Conecta con {{sourceName}}",
        en: "Connect with {{sourceName}}"
    }
}