import { translations } from '../../../config/translations/Dashboard/CaptiveGraph';
import { getTranslationFunction } from '../../../config/translations/translate';

const t = getTranslationFunction({ ...translations });
export const RegisteredThrough = [
    {
        id: "MANUAL",
        text: t('captiveMethods.manual'),
        eventStatsField: "registrationsManualCount",
        color: "#66AA00"
    },
    {
        id: "CRM",
        text: t('captiveMethods.crm'),
        eventStatsField: "registrationsCRMCount",
        color: "#B82E2E"
    },
    {
        id: "ONLINE_ORDERING",
        text: t('captiveMethods.onlineShop'),
        eventStatsField: "registrationsOnlineOrderingCount",
        color: "#316395"
    },
    {
        id: "PAYMENT",
        text: t('captiveMethods.payments'),
        eventStatsField: "registrationsPaymentCount",
        color: "#DD4477"
    },
    {
        id: "WIFI",
        text: t('captiveMethods.wifi'),
        eventStatsField: "registrationsWifiCount",
        color: "#3366CC"
    },
    {
        id: "EMAIL",
        text: t('captiveMethods.email'),
        eventStatsField: "registrationsEmailCount",
        color: "#994499"
    },
    {
        id: "IMPORTATION",
        text: t('captiveMethods.imports'),
        eventStatsField: "registrationsImportCount",
        color: "#FF9900"
    },
    {
        id: "RESERVATION",
        text: t('captiveMethods.reservation'),
        eventStatsField: "registrationsReservationCount",
        color: "#109618"
    },
    {
        id: "REVIEW_REQUEST",
        text: t('captiveMethods.reviewRequest'),
        eventStatsField: "registrationsReviewRequestCount",
        color: "#AAA1C8"
    },
    {
        id: "OTHER",
        text: t('captiveMethods.other'),
        eventStatsField: "registrationsOtherCount",
        color: "#DD6677"
    }

]