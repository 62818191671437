import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts'
import Loading from '../../commons/components/utils/Loading';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Feedback/Leaderboard";
import { useCompanyAvailableStores } from '../../commons/utils/useCompanyAvalaibleStores';
import { getLabel, getSuffixLabel, calculateFrom, calculateTo } from "botbit-ui-components"
import { getRRFunnel } from '../../api/leaderboardApi';
import _ from "lodash";
import FunnelDetailCards from './FunnelDetailCard';
import "./RRFunnel.scss";
import FunnelDetailModal from './FunnelDetailModal';


const RRFunnel = ({ filters, t, stores: storesProp, className, adminId }) => {
    const [data, setData] = useState()
    const [dataObj, setDataObj] = useState({})
    const [isFunnelDetailsModalOpen, setIsFunnelDetailsModalOpen] = useState(false);
    const [funnelModalMode, setFunnelModalMode] = useState("")
    // const [filtersParam, setFiltersParams] = useState()
    const companyAvailableStores = useCompanyAvailableStores();

    useEffect(() => {
        createFunnel();
    }, [filters, storesProp])

    let options = {
        vAxis: {
            minValue: 0,
        },
        chartArea: { width: '80%', height: '60%' },
        colors: ["#B066FE"],
        areaOpacity: 0.8,
        enableInteractivity: false,
        legend: {
            position: "none"
        }
    }

    const getPeriodFromFilters = (filterValues) => {
        return _.at(filterValues, "timePeriod[0].value")[0]
            ? _.at(filterValues, "timePeriod[0].value")[0] !== "CUSTOM"
                ? { suffixText: getSuffixLabel(_.at(filterValues, "timePeriod[0].value")[0]), text: "· 📅 " + getLabel(_.at(filterValues, "timePeriod[0].value")[0]), from: calculateFrom(_.at(filterValues, "timePeriod[0].value")[0]), to: calculateTo(_.at(filterValues, "timePeriod[0].value")[0]) }
                : { suffixText: getSuffixLabel(_.at(filterValues, "timePeriod[0].value")[0]), text: "· 📅 " + getLabel(_.at(filterValues, "timePeriod[0].value")[0]), from: _.at(filterValues, "timePeriod[0]")[0].from, to: _.at(filterValues, "timePeriod[0]")[0].to }
            : { text: "" }
    }

    const getFiltersParams = () => {
        let { stores, order, timePeriod } = filters;

        if (!stores) {
            if (storesProp) stores = storesProp;
            else {
                stores = companyAvailableStores.map(s => (s.id));
            }
        }
        if (!order) {
            order = "TOTAL_REVIEWS_REQUESTED"
        }

        if (Array.isArray(timePeriod) && timePeriod[0].value) {
            timePeriod = getPeriodFromFilters(filters);
        }

        return {
            ...filters,
            stores,
            order,
            timePeriod
        }
    }

    // TODO: Fix timePeriodObj

    const createFunnel = async () => {
        const { stores, timePeriod, order } = getFiltersParams();
        getRRFunnel(stores, timePeriod, order, adminId).then(res => {
            let summaryItems = res.data.data.items
            if (adminId && adminId !== "scheduler") {
                summaryItems = summaryItems.filter(item => item.adminId === parseInt(adminId))
            }
            const chartValues = summaryItems[0];
            if (chartValues) {
                const { totalAccepted, totalPrivateGenerated: totalPrivate, totalPublicGenerated: totalPublic } = chartValues
                setDataObj({ totalAccepted, totalPrivate, totalPublic });
                setData([
                    ['X', 'Y'],
                    [t("totalSent"), totalAccepted],
                    [t("totalPrivateGenerated"), totalPrivate],
                    [t("totalPublicGenerated"), totalPublic],
                ])
            } else {
                setData(null)
                setDataObj(null)
            }

        })
    }

    const openDetailModal = mode => {
        setFunnelModalMode(mode)
        setIsFunnelDetailsModalOpen(true)
    }

    return (
        <div className={"chart-container " + className}>
            <FunnelDetailModal
                isOpen={isFunnelDetailsModalOpen}
                toggle={() => setIsFunnelDetailsModalOpen(false)}
                stores={getFiltersParams().stores}
                period={getFiltersParams().timePeriod}
                mode={funnelModalMode}
                adminId={adminId}
            />
            {data && dataObj ? <div className="chart-and-info-container">
                <Chart
                    //por alguna razon, el relleno del chart no ocupa 100% de su espacio. tengo que sacarle 1 pixel por la izq para poder colocar la chart bien, aunque queda un espacio blanco.
                    width={'100%'}
                    height={'170px'}
                    chartType="AreaChart"
                    loader={<Loading loading={true} small />}
                    data={data}
                    options={options}
                    data-testid="rr-chart"
                />
                <div className="data-container">
                    <div className="details-cards">
                        <FunnelDetailCards
                            title={t("totalSent")}
                            icon="paper-plane"
                            // substring={substring}
                            btnText={t("viewClients")}
                            showButton={dataObj.totalAccepted > 0}
                            onButtonClik={() => openDetailModal("users")}
                        >
                            <span className="card-content">{dataObj.totalAccepted}</span>
                        </FunnelDetailCards>
                        <FunnelDetailCards
                            title={t("totalPrivateGenerated")}
                            icon="reply"
                            // substring={substring}
                            btnText={t("viewSurveys")}
                            showButton={dataObj.totalPrivate > 0}
                            onButtonClik={() => openDetailModal("surveys")}
                        >
                            <span className="card-content">{dataObj.totalPrivate}</span>
                        </FunnelDetailCards>
                        <FunnelDetailCards
                            title={t("totalPublicGenerated")}
                            icon="comment"
                            // substring={substring}
                            btnText={t("viewReviews")}
                            showButton={dataObj.totalPublic > 0}
                            onButtonClik={() => openDetailModal("reviews")}
                        >
                            <span className="card-content">{dataObj.totalPublic}</span>
                        </FunnelDetailCards>
                    </div>
                </div >
            </div>
                : null
            }
        </div>
    );
}

RRFunnel.translations = { ...translations }

export default translate(RRFunnel);