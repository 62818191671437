import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsEnabledToPost } from "../../api/socialLocationPostsApi";
import translate from "../../config/translations/translate";
import { setLoading } from "../../commons/components/application/miscActions";
import { UxSelect } from "botbit-ui-components";

const SocialLocationPostLocationsSelect = ({
  onChange,
  socialLocationId: defaultSelected,
  t
}) => {
  const company = useSelector((state) => state.application.selectedCompany);
  const dispatch = useDispatch();
  const [locations, setLocations] = React.useState();
  const [options, setOptions] = React.useState();

  useEffect(() => {
    if (company) {
      dispatch(setLoading(true));
      getLocationsEnabledToPost(company.id).then((res) => {
        const data = res.data.data;
        setLocations(data);
        setOptions(
          data
            .map((account) =>
              !account.enableToPost
                ? []
                : 
                account.socialLocationIds.map((socialLocationId) => {
                    return {
                      text:
                        socialLocationId.storeName +
                        " - " +
                        socialLocationId.name,
                      value: socialLocationId.id,
                      selected: -1 === defaultSelected || socialLocationId.id === defaultSelected,
                    };
                  })
            )
            .flat()
        );
        onChange(data
          .filter((account) => account.enableToPost)
          .map((account) => ({
            ...account,
            socialLocationIds: account.socialLocationIds.filter(
              (socialLocationId) => defaultSelected===socialLocationId.id
            ),
          }))
          .filter((account) => account.socialLocationIds.length > 0))
        dispatch(setLoading(false));
      });
    }
  }, []);

  if (!locations || !options) return null;
  return (
    <div>
      <UxSelect
        label={t("selectLocations")}
        options={options}
        onChange={(e) => {
          const selectedLocations = locations
            .filter((account) => account.enableToPost)
            .map((account) => ({
              ...account,
              socialLocationIds: account.socialLocationIds.filter(
                (socialLocationId) => e.includes(socialLocationId.id)
              ),
            }))
            .filter((account) => account.socialLocationIds.length > 0);
          onChange(selectedLocations);
        }}
        selectAll={{ show: true, label: t("selectAll") }}
        className="d-flex"
      />
    </div>
  );
};

SocialLocationPostLocationsSelect.translations = {
  locationNotEnabled: {
    es: "No tienes permiso para postear en este perfil. Por favor solicita que te de permiso a ",
    en: "You don't have permission to post in this profile. Please request permission to ",
  },
  swalText: {
    es: "Publicación creada",
    en: "Post created",
  },
  ok: {
    es: "Aceptar",
    en: "Ok",
  },
  selectAll: {
    es: "Publicar en todos los locales",
    en: "Select all",
  },
  swalErrorText: {
    es: "Error al crear la publicación en alguna ubicación",
    en: "Error creating post in some location",
  },
  seePost: {
    es: "Ver publicación",
    en: "See post",
  },
  errorCreatingPost: {
    es: "Error",
    en: "Error",
  },
  selectLocations:{
    es: "Selecciona las ubicaciones",
    en: "Select locations"
  }
};
export default translate(SocialLocationPostLocationsSelect);
