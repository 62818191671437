import React from "react";
import { MDBCard, MDBSpinner, MDBRow, MDBContainer } from "mdbreact";
import Chart from "react-google-charts";
import './reviewsSummary.css';
import { round } from '../../commons/utils/numbers';
import {Carousel} from 'botbit-ui-components'

const DetractorSummary = ({ data, loading, onSummaryClick }) => {
    if (loading) {
        return <div className="text-center m-auto detractors-loading"><MDBSpinner /></div>    
    }
    const scores = [], reviews = [], publicRate = [], replyRate = [];

    const createSimpleValueTooltipContent = (label, value) => {
        return '<div class="summary-tooltip">' +
            '<span class="summary-tooltip-small">' + label + '</span><br/>' +
            '<span class="summary-tooltip-big">' + value + '</span>' +
            '</div>';
    };

    const createReplyRateTooltipContent = (label, manualReplies, manualReplyRate, autoReplies, autoReplyRate) => {
        return '<div class="summary-tooltip">' +
            '<span class="summary-tooltip-small">' + label + '</span><br/>' +
            '<span class="auto-reply-color">Automáticas:</span> <b>' + autoReplies + ' (' + autoReplyRate + ')</b><br/>' +
            '<span class="manual-reply-color">Manuales:</span> <b>' + manualReplies + ' (' + manualReplyRate + ')</b>' +
            '</div>';
    };

    const chartSelectEvent = (chart) => {
        const selection = chart.getSelection()
        if (selection.length === 1) {
          const [selectedItem] = selection
          const params = {
              from: data[selectedItem.row].from,
              to: data[selectedItem.row].to,
              botbitGenerated: false,
              detractors: true
          }
          const label = data[selectedItem.row].longLabel
          if (onSummaryClick) {
            onSummaryClick(label, params);
          }
        }
    }

    /*
    {
      "shortLabel": "abr 2019",
      "longLabel": "abril 2019",
      "from": "2019-04-01T00:00:00",
      "to": "2019-05-01T00:00:00",
      "score": 2.4027,
      "reviews": 293,
      "publicDetractorReviews": 164,
      "publicReviews": 775,
      "publicRate": 22,
      "botbitReplies": 13,
      "botbitReviews": 129,
      "botbitReplyRate": 11,
      "botbitAvgReplyTime": 2382579.0769,
      "replies": 20,
      "replyRate": 7
    },*/
    scores.push([
        { role: 'domain' },
        { role: 'data', label: 'score' },
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'tooltip', type: 'string', 'p': {'html': true} },
        { role: 'style' }
    ])
    reviews.push([
        { role: 'domain' },
        { role: 'data', label: 'reviews' },
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'tooltip', type: 'string', 'p': {'html': true} },
        { role: 'style' }
    ])
    publicRate.push([
        { role: 'domain' },
        { role: 'data', label: 'publicRate'},
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'tooltip', type: 'string', 'p': {'html': true} },
        { role: 'style' }
    ])
    replyRate.push([
        { role: 'domain' },
        { role: 'data', label: 'Manual'},
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { role: 'data', label: 'Automáticos'},
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'tooltip', type: 'string', 'p': {'html': true} },
        { role: 'style' }
    ])    
    if (data) {
        data.forEach(item => {
            const scoreRounded = round(item.score, 2);
            scores.push([
                // domain
                item.shortLabel,
                // data: score
                scoreRounded,
                // annotation
                (data.length <= 8) ? scoreRounded : null,
                // tooltip
                createSimpleValueTooltipContent(item.longLabel, scoreRounded),
                // style
                null
                //'fill-color: #4285f4' => primary-color
                //'fill-color: #cce5ff;stroke-width: 1;stroke-color: #004085'
            ]);
            reviews.push([
                // domain
                item.shortLabel,
                // data: reviews
                item.reviews,
                // annotation
                (data.length <= 8) ? item.reviews : null,
                // tooltip
                createSimpleValueTooltipContent(item.longLabel, item.reviews),
                // style
                null
                //'fill-color: #4285f4' => primary-color
                //'fill-color: #cce5ff;stroke-width: 1;stroke-color: #004085'
            ]);
            publicRate.push([
                // domain
                item.shortLabel,
                // data
                item.publicRate / 100,
                // annotation
                (data.length <= 8 && item.publicRate > 0) ? item.publicRate + '%' : null,
                // tooltip
                createSimpleValueTooltipContent(item.longLabel, (item.publicRate) ? item.publicRate + '%' : '0%'),
                // style
                null
                //'fill-color: #cce5ff;stroke-width: 1;stroke-color: #004085' // primary-color
            ]);
            replyRate.push([
                // domain
                item.shortLabel,
                // data: Manual
                item.manualReplyRate / 100,
                // tooltip
                createReplyRateTooltipContent(item.longLabel, item.manualReplies, (item.manualReplyRate) ? item.manualReplyRate + '%' : '0%', item.autoReplies, (item.autoReplyRate) ? item.autoReplyRate + '%' : '0%'),
                // data: Automatic
                item.autoReplyRate / 100,                
                // annotation
                (data.length <= 8 && item.replyRate > 0) ? item.replyRate + '%' : null,
                // tooltip
                createReplyRateTooltipContent(item.longLabel, item.manualReplies, (item.manualReplyRate) ? item.manualReplyRate + '%' : '0%', item.autoReplies, (item.autoReplyRate) ? item.autoReplyRate + '%' : '0%'),
                // style
                null
                //'fill-color: #cce5ff;stroke-width: 1;stroke-color: #004085' // primary-color
            ]);            
        });
    }
    const items = [
        <MDBCard className="card-body m-2">
            <MDBContainer>
                <MDBRow className="chart-summary-row">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ColumnChart"
                        loader={<div>Cargando...</div>}
                        data={publicRate}
                        options={{
                            title: '% Detractores públicos',
                            chartArea: { 'width': (data.length > 8) ? '80%' : '100%', 'height': '70%' },
                            bar: { groupWidth: '90%' },
                            legend: { position: 'none' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxis: {
                                format: 'percent',
                                minValue: 0,
                                maxValue: 0.001
                            },
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            }
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow>
                    <span className="summary-legend">Evolución del porcentaje de detractores públicos durante el período seleccionado.</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>,
        <MDBCard className="card-body m-2">            
            <MDBContainer>
                <MDBRow className="chart-summary-row">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ColumnChart"
                        loader={<div>Cargando...</div>}
                        data={scores}
                        options={{
                            chartArea: { 'width': (data.length > 8) ? '80%' : '100%', 'height': '70%' },
                            title: 'Puntaje',
                            legend: { position: 'none' },
                            bar: { groupWidth: '90%' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxis: {
                                minValue: 0,
                                maxValue: 5
                            },
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            }
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow>
                    <span className="summary-legend">Evolución del promedio de puntaje de las reseñas de detractores durante el período seleccionado.</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>,
        <MDBCard className="card-body m-2">
            <MDBContainer>
                <MDBRow className="chart-summary-row">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ColumnChart"
                        loader={<div>Cargando...</div>}
                        data={replyRate}
                        options={{
                            title: 'Tasa de respuesta a detractores',
                            chartArea: { 'width': (data.length > 8) ? '80%' : '100%', 'height': '70%' },
                            bar: { groupWidth: '90%' },
                            legend: { position: 'none' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxis: {
                                format: 'percent',
                                minValue: 0,
                                maxValue: 0.001
                            },
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            },
                            isStacked: true
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow>
                    <span className="summary-legend">Evolución del % de reseñas repondidas a detractores <span className="auto-reply-color">automática</span> y <span className="manual-reply-color">manualmente</span>.</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>,
        <MDBCard className="card-body m-2">
            <MDBContainer>
                <MDBRow className="chart-summary-row">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ColumnChart"
                        loader={<div>Cargando...</div>}
                        data={reviews}
                        options={{
                            labels: 'value',
                            title: 'Cantidad de reseñas',
                            chartArea: { 'width': (data.length > 8) ? '80%' : '100%', 'height': '70%' },
                            bar: { groupWidth: '90%' },
                            legend: { position: 'none' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxis: {
                                minValue: 0,
                                maxValue: 1
                            },
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            },
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow>
                    <span className="summary-legend">Evolución de la cantidad de reseñas de detractores durante el período seleccionado.</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>        
    ];

    return <Carousel
        items={items}
        autoPlay={false}
        fadeOutAnimation={true}
        mouseDragEnabled={true}
        responsive={
            {
                600: {
                    items: 2
                },
                1200: {
                    items: 4
                }
            }
        }
        disableAutoPlayOnAction={true}
        buttonsDisabled={true}
        infinite={false}
    />
}

export default DetractorSummary