import { MDBInput, MDBSwitch, MDBBtn } from 'mdbreact';
import { Select } from 'botbit-ui-components';
import React, { useState, useRef, useEffect } from 'react';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Messenger/Messenger";


function useOutsideHandler(ref, onClickOutside) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && onClickOutside) {
                onClickOutside();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const AssignConversation = ( {t, 
            selectedAdministratorId, 
            administrators, 
            assignCallback, 
            unassignCallback,
            showNotifyUser,
            onClickOutside} ) => {
    const [notifyUser, setNotifyUser] = useState(false);
    const [assignComment, setAssignComment] = useState(null);
    const [administratorId, setAdministratorId] = useState();

    const wrapperRef = useRef(null);
    useOutsideHandler(wrapperRef, onClickOutside);
    
    return (
        <div ref={wrapperRef}>
            <div className="assign-conversation select-in-dropdown-container">
                <Select 
                    selected={ t("operatorsSelectPlaceholder") }
                    search={true}
                    options={administrators.map(s => ({ text: s.fullName, value: s.id.toString(), checked: administratorId === s.id.toString() }))}
                    getValue={(values) => {
                        setAdministratorId(values[0])
                    }}/>
                <MDBInput
                    type="textarea"
                    size="lg"
                    outline
                    label={ t("assignComment") }
                    id="text-input-custom"
                    className="text-dark"
                    containerClass="mb-0"
                    getValue={(value) => { setAssignComment(value) }}
                />
                <div className="text-warning mb-3">{t("assignCommentInfo")}</div>
                { showNotifyUser && <MDBSwitch
                    labelLeft={t("assignNotifyUser")}
                    labelRight=""
                    className="text-dark"
                    checked={notifyUser}
                    getValue={(value) => setNotifyUser(value)}
                />}
				<MDBBtn disabled={administratorId === undefined} color="primary" onClick={ () => {
                        assignCallback(administratorId, assignComment, notifyUser)
                    } }
                    size="sm">{t("assignConfirm")}
                </MDBBtn>
				{ selectedAdministratorId && <MDBBtn color="primary" onClick={ () => {
                            unassignCallback(assignComment)
                        } }
                        size="sm">{t("unassignConfirm")}
                
                    </MDBBtn>
                }
            </div>

        </div>
    );
}

AssignConversation.translations = { ...translations }

export default translate(AssignConversation);
