import { getOptInUrl } from "../../api/sourceApi";

export const AuthorizationType = {
    OAUTH: {
        id: "OAUTH",
        method: async (source, selectedCompany, fields) => {
            const res = await getOptInUrl(
                source.name,
                selectedCompany.id,
                fields);
            const { url } = res.data.data;
            window.location.href = url;
        }
    },
    TOKEN: {
        id: "TOKEN",
        method: async (source, selectedCompany, fields) => {
            //TODO
        }
    }
}

export const connect = async (source, selectedCompany, fields) => {
    const res = await AuthorizationType[source.authorizationType].method(source,selectedCompany, fields);
    return res;
}

export const someStoreConnected = (source) => {
    return source && source.countByStatus && source.countByStatus.some(s => s.status === "CONNECTED" && s.count > 0)
}

export const someStore = (source) => {
    const countByStatus = source && source.countByStatus;
    for (let key in countByStatus) {
        if (countByStatus[key].count > 0) return true;
    }
    return false;
}

export const MatchingType = {
    LOCATION: "LOCATION",
    ACCOUNT: "ACCOUNT"
}
