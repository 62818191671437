import { MDBCardBody, MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { Card } from "react-bootstrap";
import Moment from 'react-moment';
import { translations } from '../../../config/translations/Feedback/SentimentContainer';
import { translations as monthTranslations } from '../../../config/translations/Months';
import translate from '../../../config/translations/translate';
import Score from '../Score';
import SocialSourceIcon from '../SocialSourceIcon';
import { SectionTitle } from './SectionTitle';

const SentimentKeywordGroup = ({ keywords, sentiment, t }) => {
	const matchedKeywords = keywords.filter(kw => kw.sentiment === sentiment)

	return <div className={`day-card-keywords-container day-card-keywords-container-${sentiment.toLowerCase()} border-left-${sentiment.toLowerCase()}`}>
		<div className="sentiment">
			{t(`dailyStatisticsSection.dayDetail.${sentiment.toLowerCase()}`).toUpperCase()}
		</div>
		<div className={`${sentiment.toLowerCase()}-keyword words review-keyword-sentiment`}>
			{matchedKeywords.map((mk, index) => `${mk.reviewSentimentKeyword.keyword}${index < matchedKeywords.length - 1 ? ', ' : ''}`)}
		</div>
	</div>

}

const SentimentDayCard = ({ t, sentiment, onSendFeedback, sendFeedback, activeKeywordIds }) => {
	let thumbsDownClassName = ''
	let thumbsUpClassName = ''
	if (sentiment.feedback === 'GOOD') {
		thumbsDownClassName = 'other-feedback'
		thumbsUpClassName = 'good-feedback'
	}
	if (sentiment.feedback === 'BAD') {
		thumbsDownClassName = 'bad-feedback'
		thumbsUpClassName = 'other-feedback'
	}

	const filteredSentiments = sentiment.keywordSentiments && sentiment.keywordSentiments.filter(sentiment => activeKeywordIds.includes(sentiment.reviewSentimentKeyword.id))

	return <div className='sentiment-day-card-container'>
		<Card className={`sentiment-day-card border-card border-${sentiment.sentiment.toLowerCase()}`}>
			<MDBCardBody className='review-card-body'>
				<MDBRow>
					<MDBCol size='2'>
						<img src={sentiment.review.reviewerProfilePhotoUrl} className="rounded-circle profile-image" alt='' />
					</MDBCol>
					<MDBCol size='3' className='col-in-the-middle'>
						<div>
							<div className='reviewer-name'>
								{sentiment.review.reviewerDisplayName}
							</div>
						</div>
						<Moment
							fromNow
							locale={navigator.language.toLocaleLowerCase()}
							className='moment'
						>{sentiment.review.date}</Moment>
					</MDBCol>
					<MDBCol size='2' className='col-in-the-middle align-self-center'>
						<Score value={sentiment.review.score} size="s" />&nbsp;
					</MDBCol>
					<MDBCol size='2' className='col-in-the-middle align-self-center'>
						<SocialSourceIcon site={sentiment.socialSource.id} />
					</MDBCol>
					<MDBCol size='3' className='align-self-center pl-0'>
						<div className={`${sentiment.sentiment.toLowerCase()}-keyword review-global-sentiment`}>
							{t(`dailyStatisticsSection.dayDetail.${sentiment.sentiment.toLowerCase()}`)}
						</div>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol size='12' className="store-name">
						{sentiment.store && <span>{sentiment.store.name}</span>}
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol size='12' className='mt-2 mb-2'>
						<div className="scrollbar scrollbar-secondary mx-auto" style={{ height: '70px', overflowX: 'hidden', overflowY: 'auto' }}>
							{sentiment.analyzedComment}
						</div>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol size='12'>
						<div className="scrollbar scrollbar-secondary mx-auto" style={{ height: '148px', overflowX: 'hidden', overflowY: 'auto' }}>
							<SentimentKeywordGroup
								keywords={filteredSentiments}
								sentiment='POSITIVE'
								t={t}
							/>
							<SentimentKeywordGroup
								keywords={filteredSentiments}
								sentiment='NEUTRAL'
								t={t}
							/>
							<SentimentKeywordGroup
								keywords={filteredSentiments}
								sentiment='NEGATIVE'
								t={t}
							/>
						</div>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol size='12' className='mt-2'>
						{t('dailyStatisticsSection.dayDetail.feedback.askForFeedback')}
						<span className={'send-feedback-action ' + thumbsUpClassName}>
							<MDBIcon far icon="thumbs-up" onClick={() => sendFeedback(sentiment.id, undefined, true)} />
						</span>
						<span className={'send-feedback-action ' + thumbsDownClassName}>
							<MDBIcon far icon="thumbs-down" onClick={() => onSendFeedback(sentiment.id)} />
						</span>
					</MDBCol>
				</MDBRow>
			</MDBCardBody>
		</Card>
	</div>
}

const SentimentDayDetail = ({ t, sentimentsByDay, onSendFeedback, sendFeedback, dayStats, activeKeywords }) => {
	if (!sentimentsByDay || !dayStats || !activeKeywords) {
		return <Card className='fixed-height-400'>
			<MDBCardBody className='sentiment-day-summary card-content'>
				<MDBRow className='h-100'>
					<MDBCol className="d-flex align-items-center justify-content-center" size='12'>
						<p className='text-center text-left font-italic'>{t('dailyStatisticsSection.clickOnDayHelp')}</p>
					</MDBCol>
				</MDBRow>
			</MDBCardBody>
		</Card>
	}

	const [firstItemVisible, setFirstItemVisible] = useState(0)

	useEffect(() => {
		setFirstItemVisible(0);
	}, [dayStats.day]);

	let reviewsCarousel;
	const responsiveConfig = {
		0: { items: 1 },
		600: { items: 1 },
		1200: { items: 2 },
		1900: { items: 3 },
	}

	const onSlideChanged = (e) => {
		setFirstItemVisible(e.item)
	}

	// calculate items per page according to responsive config
	// responsiveConfig MUST HAVE the keys sorted desc and MUST HAVE the '0' element
	let totalItemsPerPage;
	Object.keys(responsiveConfig).forEach((key) => {
		if (window.innerWidth > parseInt(key)) {
			totalItemsPerPage = responsiveConfig[key].items
		}
	})
	// with items per page, calculate if prev or next button must be showed or hidden
	const totalItems = sentimentsByDay.length
	const hasElementsAtLeft = firstItemVisible > 0 && totalItemsPerPage < totalItems
	const haselEmentsAtRight = firstItemVisible + totalItemsPerPage < totalItems
	const prevBtnStyles = hasElementsAtLeft ? "" : "slide-btn-hide"
	const nextBtnStyles = haselEmentsAtRight ? "" : "slide-btn-hide"
	const activeKeywordIds = activeKeywords.map(keyword => keyword.id)

	const items = sentimentsByDay.map((sentiment) =>
		<SentimentDayCard
			sentiment={sentiment}
			onSendFeedback={onSendFeedback}
			sendFeedback={sendFeedback}
			t={t}
			activeKeywordIds={activeKeywordIds}
		/>)

	const monthName = t(`months.${dayStats.month}`)

	return <div className='fixed-height-400'>
		<Card>
			<MDBCardBody className='sentiment-day-summary card-content'>
				<MDBRow>
					<MDBCol size='4'>
						<SectionTitle
							help={[t('dailyStatisticsSection.dayDetail.help1')]}
							title={`${t('dailyStatisticsSection.dayDetail.title')} ${monthName} ${dayStats.day}`}
							name={'daily-statistics'}
						/>
					</MDBCol>
					<MDBCol size='4'>
						<div className='text-center'>
							<span className='green-circle'>&#9679;&nbsp;</span>{t('dailyStatisticsSection.dayDetail.positiveMentions')}: <span className='mentions-count'>{dayStats.totalPositiveReviews}</span>&nbsp;
							(%{Math.round(dayStats.positiveWithoutNeutralPercentage)})
						</div>
					</MDBCol>
					<MDBCol size='4'>
						<div className='text-center'>
							<span className='red-circle'>&#9679;&nbsp;</span>{t('dailyStatisticsSection.dayDetail.negativeMentions')}: <span className='mentions-count'>{dayStats.totalNegativeReviews}</span>&nbsp;
							(%{Math.round(dayStats.negativeWithoutNeutralPercentage)})
						</div>
					</MDBCol>
					<MDBCol size='12'>
						<div className="sentiment-day-carousel-container">
							<AliceCarousel
								onSlideChanged={onSlideChanged}
								autoPlay={false}
								fadeOutAnimation={true}
								mouseDragEnabled={true}
								responsive={responsiveConfig}
								disableAutoPlayOnAction={true}
								buttonsDisabled={true}
								dotsDisabled={true}
								infinite={false}
								items={items}
								ref={(el) => {
									reviewsCarousel = el
								}}
								startIndex={firstItemVisible}
							/>
							<button className={`slide-btn slide-btn-prev ${prevBtnStyles}`} onClick={() => reviewsCarousel.slidePrev()}>&lt;</button>
							<button className={`slide-btn slide-btn-next ${nextBtnStyles}`} onClick={() => reviewsCarousel.slideNext()}>&gt;</button>
						</div>
					</MDBCol>
				</MDBRow>
			</MDBCardBody>
		</Card>
	</div>
}

SentimentDayDetail.translations = {
	...translations,
	...monthTranslations
};

export default translate(SentimentDayDetail);
