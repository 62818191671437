import React, { useState } from 'react';

import translate from '../../../config/translations/translate';
import { translations } from '../../../config/translations/Feedback/SentimentContainer';
import { translations as monthTranslations } from '../../../config/translations/Months';
import { Modal } from 'react-bootstrap';
import { MDBInput } from 'mdbreact';

const SendFeedbackModal = ({ t, show, hide, reviewSentimentId, onAccept }) => {
	const [message, setMessage] = useState(false)

	return (
		<Modal show={show} onHide={hide}>
			<Modal.Header closeButton>
				<Modal.Title>{t("dailyStatisticsSection.dayDetail.feedback.sendFeedback.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='p-3'>
				{t('dailyStatisticsSection.dayDetail.feedback.somethingWrongTellUsMore')}
				<MDBInput 
					type="textarea" 
					label="" 
					outline 
					getValue={ (newValue) => setMessage(newValue)}
				/>
			</Modal.Body>
			<Modal.Footer className='p-3'>
				<button className='btn btn-secondary btn-sm w-100' onClick={hide}>
					{t("dailyStatisticsSection.dayDetail.feedback.sendFeedback.cancel")}
				</button>
				<button className='btn btn-primary btn-sm w-100' onClick={ () => {onAccept(reviewSentimentId, message, false)}}>
					{t("dailyStatisticsSection.dayDetail.feedback.sendFeedback.send")}
				</button>
			</Modal.Footer>
		</Modal>
	)
}

SendFeedbackModal.translations = {
	...translations,
	...monthTranslations
};

export default translate(SendFeedbackModal);
