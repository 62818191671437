import axiosClient from "./axiosClient";

export const getLocalPages = (domain) => {
  //  return new Promise((resolve)=>
  //      resolve({data:mockLocalPages})
  //  )
  return axiosClient.get("/localpages/" + domain);
};

export const claimPage = (placeId) => {
  // return new Promise((resolve)=>
  //      resolve({})
  //  )
  return axiosClient.post(`/localpages/claim/${placeId}`);
};

export const updateStyles = (placeId, styles) => {
  // return new Promise((resolve)=>
  //      resolve({})
  //  )
  return axiosClient.post(`/localpages/${placeId}/style`, styles);
};

export const createClient = (companyId, domain) => {
  // return new Promise((resolve)=>
  //      resolve({})
  //  )
  return axiosClient.post(`/localpages/${companyId}/domain/${domain}`);
};

export const getLanding = (companyId) => {
  // return new Promise((resolve)=>
  //      resolve({data:mockLanding})
  //  )
  return axiosClient.get(`/landing/${companyId}`);
};

export const updateLanding = (companyId, styles) => {
  // return new Promise((resolve)=>
  //      resolve({data:mockLanding})
  //  )
  return axiosClient.put(`/landing/${companyId}`, styles);
};
