import React, { Component } from 'react';
import { MDBIcon, MDBBadge, MDBSwitch, MDBBtn } from 'mdbreact';
import { translate } from 'react-i18next';
import {
  setLoading,
  setError,
} from '../../commons/components/application/miscActions';
import { connect } from 'react-redux';
import ActivateFirstCampaign from './ActivateFirstCampaign';
import { Toast, toast, Modal, ModalBody } from 'botbit-ui-components';
import {
  getCampaignsByCompanyId,
  switchCampaignActive,
  exportCampaigns,
  sendCampaign,
} from '../../api/campaignsApi';
import { deleteCampaign } from '../../api/campaignsApi';
import NewCampaign from './NewCampaign/NewCampaign';
import deepCopy from '../../commons/utils/deepCopy';
import { envConfig } from '../../config/config';
import MinimalEditor from './MinimalEditor/MinimalEditor';
import {
  PreviewModal,
  handlePreview,
  countStores,
} from './MinimalEditor/Helper';
import { createCampaignListDefaultFilters } from './CampaignsHelper';
import { withRouter } from 'react-router-dom';
import { setCampaignListFilters } from './CampaignListFilters/CampaignListFiltersActions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import CollectionVisualizerContainer from '../collectionVisualizer/CollectionVisualizerContainer';
import DateTime from '../../commons/components/time/DateTime';
import { firePageView } from "../../commons/components/utils/trackers";

const MySwal = withReactContent(Swal);

const TRANSLATIONS_PREFIX = 'panels.campaigns.home.';

const mapStateToProps = (state) => {
  return {
    availableStores: state.application.availableStores,
    availableCompanies: state.application.availableCompanies,
    selectedCompany: state.application.selectedCompany,
    selectedStore: state.application.selectedStore,
    admin: state.login.admin,
    token: state.login.token,
    loading: state.misc.loading,
    filters: state.campaignList.filters,
  };
};

class CampaignsListContainer extends Component {
  constructor(props) {
    super(props);

    const defaultFilters = createCampaignListDefaultFilters(this.props.t);

    this.state = {
      activeTab: 'smart',
      preview: false,
      filters: [],
      collapseIDSmart: true,
      collapseIDCustom: true,
      confirmationModalIsOpen: false,
      actionCampaignName: '',
      onConfirmActionFunction: undefined,
      campaignBeingEdited: undefined,
      newCampaignSubCategory: false,
      renderCollectionVisualizer: true,
      defaultFilters: defaultFilters,
    };
    this.applyFilters = this.applyFilters.bind(this);
    this.rowActions = this.rowActions.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);

    if (!this.props.filters) {
      //si no estaban definidos, defino los filtros en redux
      const initialFilters = deepCopy(
        defaultFilters ? defaultFilters : this.state.defaultFilters
      );
      this.props.setCampaignListFilters(initialFilters);
    }
  }

  componentDidMount = () => {
    firePageView();
  }

  renderCollectionVisualizer = () => {
    this.setState(
      {
        renderCollectionVisualizer: false,
      },
      () => {
        this.setState({
          renderCollectionVisualizer: true,
        });
      }
    );
  };

  //lo pongo en negativo asi no tengo que modificar todos los lugares donde es llamada
  closeModal(notReRender) {
    this.setState({
      confirmationModalIsOpen: false,
      newCampaignModalIsOpen: false,
      newCampaignSubCategory: false,
    });
    if (!notReRender) this.renderCollectionVisualizer();
  }

  resetFilters = () => {
    this.props.setCampaignListFilters(this.state.defaultFilters);
  };

  openEditor = (campaign) => {
    if (campaign.isPredefined) {
      this.setState({
        ...this.state,
        minimalEditorIsOpen: true,
        campaignBeingEditedId: campaign.id,
      });
    } else {
      this.props.history.push(`/campaigns/${campaign.id}/edit`);
    }
  };

  rowActions = (row, updateRow) => {
    let actions = [
      {
        key: 1,
        label: this.props.t('panels.campaigns.otherLabels.edit'),
        id: "edit",
        showAction: () => row.hasWriteAccess,
        onClick: () => {
          this.openEditor(row);
        },
      },
      {
        key: 2,
        label: this.props.t('panels.campaigns.otherLabels.duplicate'),
        id: "duplicate",
        showAction: () => !row.isPredefined,
        onClick: () => {
          this.props.history.push(
            `/campaigns/${row.id}/create?templateType=CUSTOM&companyId=${row.companyId}`
          );
        },
      },
      {
        key: 3,
        label: this.props.t('panels.campaigns.otherLabels.previsualize'),
        id: "previsualize",
        onClick: () => this.handlePreview(row),
      },
      {
        key: 4,
        color: 'danger',
        label: this.props.t('panels.campaigns.otherLabels.delete'),
        id: "delete",
        showAction: () => row.hasWriteAccess,
        onClick: () => {
          this.setState({
            confirmationModalIsOpen: true,
            confirmationModalAction: 'delete',
            actionCampaignName: row.name,
            onConfirmActionFunction: this.deleteCampaign(
              row.id,
              updateRow,
              row
            ),
          });
        },
      },
      {
        key: 5,
        label: this.props.t('panels.campaigns.otherLabels.sendNow.sendNow'),
        id: "sendNow",
        onClick: () => this.handleSendNow(row),
        showAction: () => row.hasWriteAccess,
      },
    ];
    return actions;
  };

  handleSendNow = ({ id, companyId, name, active, segment }) => {
    const translationPrefix = 'panels.campaigns.otherLabels.sendNow.';
    const { t, setLoading } = this.props;
    let swalText = t(`${translationPrefix}text.${!!segment}`, {
      status: t(`${translationPrefix}text.active.${active}`),
    });
    let confirmationButtonText = t(
      `${translationPrefix}${segment ? `sendNow` : 'goBack'}`
    );
    let swalType = segment ? 'warning' : 'error';
    let campaignName = name.substring(0, 13) + (name.length >= 13 ? '...' : '');
    MySwal.fire({
      title: t(`${translationPrefix}sendNowSub`, { name: campaignName }),
      text: swalText,
      type: swalType,
      showCancelButton: segment,
      cancelButtonText: t(`${translationPrefix}cancel`),
      confirmButtonText: confirmationButtonText,
      confirmButtonColor: '#1ab394',
    }).then((result) => {
      if (result.value && segment) {
        setLoading(true);
        //llamada al servicio
        sendCampaign(id, companyId)
          .then((response) => {
            toast(
              t(`${translationPrefix}campaignSentSuccessfully`, {
                name: campaignName,
              })
            );
          })
          .catch((err) => {
            toast(
              t(`${translationPrefix}campaignDeliveryHadError`, {
                name: campaignName,
              })
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  applyFilters(selectedFilters, newFilters) {
    this.props.setCampaignListFilters(deepCopy(newFilters));
  }

  closeMinimalEditor = () => {
    this.setState(
      {
        minimalEditorIsOpen: false,
      },
      () => this.renderCollectionVisualizer()
    );
  };

  deactivateCampaign = (campaignId, isActive, updateRow, row) => () => {
    switchCampaignActive(campaignId, isActive)
      .then(() => {
        this.setState({
          confirmationModalIsOpen: false,
          lastDeactivatedCampaignId: campaignId,
        });
        updateRow(row, 'refresh');
        toast(
          this.props.t(
            'panels.campaigns.otherLabels.actions.campaign' +
            (isActive ? 'Deactivated' : 'Activated')
          ) +
          ' ' +
          this.state.actionCampaignName,
          {
            actionButtonText: this.props.t(
              'panels.campaigns.otherLabels.actions.undo'
            ),
            actionButtonOnClick: () => {
              switchCampaignActive(campaignId, !isActive).then(() => {
                updateRow(row, 'refresh');
                toast(
                  this.props.t(
                    'panels.campaigns.otherLabels.actions.campaign' +
                    (!isActive ? 'Deactivated' : 'Activated')
                  ) +
                  ' ' +
                  this.state.actionCampaignName
                );
              });
            },
          }
        );
      })
      .finally(() => { });
  };

  deleteCampaign = (campaignId, updateRow, row) => () => {
    this.props.setLoading(true);
    deleteCampaign(campaignId)
      .then((res) => {
        this.setState({
          confirmationModalIsOpen: false,
        });
        updateRow(row, 'refresh');
        toast(
          this.props.t('panels.campaigns.otherLabels.actions.campaignDeleted') +
          ' ' +
          this.state.actionCampaignName
        );
      })
      .finally(() => {
        this.props.setLoading(false);
      });
  };

  handleSwitchChange = (
    isActive,
    campaignName,
    campaignId,
    updateRow,
    row
  ) => () => {
    this.setState({
      confirmationModalIsOpen: true,
      confirmationModalAction: isActive ? 'deactivate' : 'activate',
      actionCampaignName: campaignName,
      onConfirmActionFunction: this.deactivateCampaign(
        campaignId,
        isActive,
        updateRow,
        row
      ),
    });
  };

  confirmationModal = () => {
    return (
      <Modal
        isOpen={this.state.confirmationModalIsOpen}
        centered={true}
        className="confirmationModal"
      >
        <ModalBody style={{ paddingBottom: '17px' }}>
          <p
            style={{
              fontSize: '20px',
              lineHeight: '28px',
              fontWeight: '600',
              color:
                this.state.confirmationModalAction === 'delete'
                  ? 'red'
                  : 'inherit',
            }}
          >
            {this.props.t(
              'panels.campaigns.otherLabels.actions.' +
              this.state.confirmationModalAction +
              'Title'
            )}{' '}
            "{this.state.actionCampaignName}"
          </p>
          <p
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '14px',
              lineHeight: '19px',
            }}
          >
            {this.props.t(
              'panels.campaigns.otherLabels.actions.' +
              this.state.confirmationModalAction +
              'Text'
            )}
            <br />
            {this.props.t(
              'panels.campaigns.otherLabels.actions.' +
              this.state.confirmationModalAction +
              'SubText'
            )}
          </p>
          <div className="text-right">
            <MDBBtn
              color={
                this.state.confirmationModalAction === 'delete'
                  ? 'red'
                  : 'primary'
              }
              onClick={this.state.onConfirmActionFunction}
              style={{ width: '146px' }}
              size="sm"
              className="text-capitalize shadow-none font-size-small"
            >
              <strong style={{ fontSize: '14px' }}>
                {this.props.t(
                  'panels.campaigns.otherLabels.actions.' +
                  this.state.confirmationModalAction +
                  'Button'
                )}
              </strong>
            </MDBBtn>
            <button
              onClick={this.closeModal}
              className="link-like-btn text-capitalize font-size-small"
              style={{
                fontSize: '14px',
                color: '#19AA89',
                fontWeight: '600',
                lineHeight: '19px',
                marginLeft: '17px',
              }}
            >
              {this.props.t('panels.campaigns.otherLabels.actions.cancel')}
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  newCampaignModal = () => {
    return (
      <>
        <Modal isOpen={this.state.newCampaignModalIsOpen} fullscreen>
          <ModalBody className="p-0">
            {this.props.selectedCompany && (
              <NewCampaign
                setLoading={this.props.setLoading}
                subCategory={this.state.newCampaignSubCategory}
                availableStores={this.props.availableStores}
                onBack={this.closeModal}
                onSaveAndClose={this.closeModal}
                t={this.props.t}
                companies={this.props.availableCompanies}
                companyId={this.props.selectedCompany.id}
                history={this.props.history}
              />
            )}
          </ModalBody>
        </Modal>
      </>
    );
  };

  defaultCompany = () => {
    return this.props.selectedCompany.id;
  };

  // setEmptyActivableCampaigns = () => {
  // 	this.setState({
  // 		emptyActivableCampaigns: true
  // 	})
  // }

  filterInactives = () => {
    let newFilters = deepCopy(this.props.filters);
    let statusFilter = newFilters.find((filter) => filter.id === 'status');
    statusFilter.values.forEach((value) => {
      if (value.value === 'inactive') {
        value.checked = true;
      } else {
        value.checked = false;
      }
    });
    this.props.setCampaignListFilters(newFilters);
  };

  emptyState = () => {
    let content = (
      <div>
        <ActivateFirstCampaign
          companyId={this.defaultCompany()}
          // onEmpty={this.setEmptyActivableCampaigns}
          withText
          withBlank
          onCardClick={(subCategory) => this.openNewCampaignModal(subCategory)}
        />
      </div>
    );

    // if(this.state.emptyActivableCampaigns){
    // 	content =
    // 	<div className="d-flex flex-column align-items-center noResultsActivateFirstCampaign">
    // 		<div className="noResultsActivateFirstCampaignImage">
    // 			{getEmptyActivableCampaignsImage()}
    // 		</div>
    // 		<h1 className="text-center">{this.props.t(TRANSLATIONS_PREFIX + 'emptyState.title')}</h1>{/* No tienes campañas activas */}
    // 		<MDBBtn className="outlined-primary-button campaignsActionButton" onClick={this.filterInactives}>{this.props.t(TRANSLATIONS_PREFIX + 'emptyState.actionButton')}</MDBBtn>
    // 		{/* Ver campañas inactivas */}
    // 	</div>
    // }
    return content;
  };

  getEffectivityProperty(id, prop) {
    if (id) {
      const effectivities = [
        {
          id: 'MEDIUM',
          color: '#ffc107',
          icon: 'circle',
          label:
            ' ' + this.props.t('panels.campaigns.filters.effectivity.medium'),
        },
        {
          id: 'HIGH',
          color: '#49C6C5',
          icon: 'check-circle',
          label:
            ' ' + this.props.t('panels.campaigns.filters.effectivity.high'),
        },
        {
          id: 'LOW',
          color: 'red',
          icon: 'exclamation-circle',
          label: ' ' + this.props.t('panels.campaigns.filters.effectivity.low'),
        },
      ];
      return effectivities.find((e) => e.id === id)[prop];
    } else return 'N/A';
  }

  openNewCampaignModal = (subCategory) => {
    if (subCategory && subCategory.subCategoryName === 'BLANK') {
      this.props.history.push(
        `campaigns/create?templateId=68&companyId=${this.props.selectedCompany.id}&templateType=LAYOUT`
      );
    } else {
      this.setState({
        newCampaignSubCategory: subCategory ? subCategory : false,
        newCampaignModalIsOpen: true,
      });
    }
  };
  minimalEditorModal = () => {
    return (
      <div className="minimalEditorModalContainer">
        <Modal
          isOpen={this.state.minimalEditorIsOpen}
          fullscreen
          className="minimalEditorModal"
        >
          <ModalBody className="p-0">
            <MinimalEditor
              setLoading={(val) => this.props.setLoading(val)}
              mode="edit"
              onBack={this.closeMinimalEditor}
              t={this.props.t}
              campaignId={this.state.campaignBeingEditedId}
              availableStores={this.props.availableStores}
              companyId={this.props.selectedCompany.id}
              history={this.props.history}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  };

  exportCampaigns = (order, filters) => {
    this.props.setLoading(true);
    return new Promise((resolve, reject) => {
      exportCampaigns(filters)
        .then((response) => {
          resolve(response.data);
        })
        .finally(() => {
          this.props.setLoading(false);
        });
    });
  };

  setPreviewIsOpen = (row) => (isOpen) => {
    return new Promise((resolve) => {
      this.setState(
        {
          preview: isOpen ? row : false,
        },
        () => resolve()
      );
    });
  };

  handlePreview = (row) => {
    handlePreview(this.setPreviewIsOpen(row), row);
  };

  render() {
    const columns = [
      {
        dataField: 'campaign',
        title: '',
        size: '12',
        className: 'd-block d-md-none d-lg-none mobileColumn',
        formatter: (cellContent, row, updateRow) => {
          let mainImageUrl;
          if (row.mainImage) {
            mainImageUrl = row.mainImage;
          } else if (row.attachedImages && row.attachedImages !== '') {
            mainImageUrl = row.attachedImages.split(';')[0];
          } else {
            mainImageUrl = envConfig.imgUrl + 'img/stores/no-image.png';
          }
          // let formattedUpdateTimestamp = row.lastUpdateTimestamp
          //   ? moment(row.lastUpdateTimestamp)
          //   : null;
          return (
            <div className="d-flex">
              <div className="imgContainer">
                <img alt="mainImage" src={mainImageUrl} />
                {row.isPredefined === true && (
                  <MDBBadge className="smartCampaignBadge background-smart">
                    <MDBIcon far icon="lightbulb" />
                  </MDBBadge>
                )}
              </div>
              <div className="campaignInfo">
                <p className="campaignName">
                  {row.name.substring(0, 18) +
                    (row.name.length >= 18 ? '...' : '')}
                </p>
                <p className="segmentStores">
                  {countStores(row.segment, this.props.t)}
                </p>
                <p>
                  {this.props.t("panels.campaigns.card.metrics.totalOpened")}: &nbsp;&nbsp;
                  <b>
                    {row.metrics.totalUniqueOpened}
                  </b>
                </p>
                <p>
                  {this.props.t("panels.campaigns.card.metrics.totalSent")}: &nbsp;&nbsp;
                  <b>
                    {row.metrics.totalSent}
                  </b>
                </p>
                <p className="clientsThatReturned">
                  {this.props.t(TRANSLATIONS_PREFIX + 'walkthrough')}: &nbsp;&nbsp;
                  <strong className="d-inline-block">
                    {row.metrics && row.metrics.totalWalkthrough
                      ? row.metrics.totalWalkthrough
                      : '0'}
                  </strong>
                </p>
              </div>
              <div className="switchContent">
                <MDBSwitch
                  disabled={!row.hasWriteAccess}
                  checked={row.active !== undefined ? row.active : 'false'}
                  onChange={this.handleSwitchChange(
                    row.active,
                    row.name,
                    row.id,
                    updateRow,
                    row
                  )}
                  labelLeft=""
                  labelRight=""
                />
              </div>
            </div>
          );
        },
      },
      {
        dataField: 'campaign',
        title: this.props.t('panels.campaigns.columns.campaign'),
        className: 'd-none d-md-block',
        formatter: (cellContent, row) => {
          let mainImageUrl;
          if (row.mainImage) {
            mainImageUrl = row.mainImage;
          } else if (row.attachedImages && row.attachedImages !== '') {
            mainImageUrl = row.attachedImages.split(';')[0];
          } else {
            mainImageUrl = envConfig.imgUrl + 'img/stores/no-image.png';
          }
          return (
            <div className="d-flex">
              <div>
                <div
                  style={{
                    width: '70px',
                    height: '56px',
                    borderRadius: '5%',
                    backgroundImage: `url(${mainImageUrl})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
                {row.isPredefined === true && (
                  <MDBBadge
                    className="background-smart shadow-none position-absolute"
                    style={{ top: '38px', left: '66px' }}
                  >
                    <MDBIcon far icon="lightbulb" />
                  </MDBBadge>
                )}
              </div>
              <div style={{ marginLeft: '17px' }}>
                <p
                  className="m-0 text-dark h-auto text-truncate"
                  style={{
                    fontSize: '14px',
                    lineHeight: '16px',
                    fontWeight: '600',
                    maxWidth: '140px',
                  }}
                >
                  {row.name}
                </p>
                <p
                  className="font-weight-normal text-dark m-0"
                  style={{
                    fontStyle: 'normal',
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                >
                  {`${row.metrics && row.metrics.totalDelivered} ${this.props.t(
                    `${TRANSLATIONS_PREFIX}sent`
                  )}`}
                </p>
                {row.isPredefined === true && (
                  <MDBBadge
                    className="shadow-none background-smart"
                    style={{ marginTop: '7px' }}
                  >
                    {this.props.t('panels.campaigns.otherLabels.smart')}
                  </MDBBadge>
                )}
              </div>
            </div>
          );
        },
        size: '3',
      },
      {
        dataField: 'stores',
        title: this.props.t('panels.campaigns.columns.stores.text'),
        className: 'd-none d-md-block',
        formatter: (cellContent, row) => {
          let storesCount = countStores(row.segment, this.props.t);
          return (
            <u
              className="text-dark"
              style={{
                fontSize: '12px',
                lineHeight: '19px',
                fontWeight: '600',
              }}
            >
              {storesCount}
            </u>
          );
        },
        size: '2',
      },
      {
        dataField: 'opened',
        title: this.props.t('panels.campaigns.columns.opened'),
        className: 'd-none d-md-block',
        formatter: (cellContent, row) => {
          return (
            <div
              className="text-dark"
              style={{
                fontSize: '12px',
                lineHeight: '19px',
                fontWeight: '600',
              }}
            >
              {row.metrics.totalUniqueOpened}
            </div>
          );
        },
        size: '1',
      },
      {
        dataField: 'clicks',
        title: this.props.t('panels.campaigns.columns.clicks'),
        className: 'd-none d-md-block',
        formatter: (cellContent, row) => {
          return (
            <div
              className="text-dark"
              style={{
                fontSize: '12px',
                lineHeight: '19px',
                fontWeight: '600',
              }}
            >
              {row.metrics.totalUniqueClicked}
            </div>
          );
        },
        size: '1',
      },
      {
        dataField: 'returned',
        title: this.props.t('panels.campaigns.columns.returned'),
        className: 'd-none d-md-block',
        formatter: (cellContent, row) => {
          return (
            <div
              className="text-dark"
              style={{
                fontSize: '12px',
                lineHeight: '19px',
                fontWeight: '600',
              }}
            >
              {row.metrics && row.metrics.totalWalkthrough
                ? row.metrics.totalWalkthrough
                : '0'}
            </div>
          );
        },
        size: '1',
      },
      {
        dataField: 'effectivity',
        title: this.props.t('panels.campaigns.columns.effectivity'),
        className: 'd-none d-md-block',
        formatter: (cellContent, row) => {
          return (
            <div
              style={{
                fontSize: '12px',
                lineHeight: '19px',
                fontWeight: '600',
                width: '119%',
                color: this.getEffectivityProperty(
                  row.metrics.campaignPerformance,
                  'color'
                ),
              }}
            >
              <MDBIcon
                icon={this.getEffectivityProperty(
                  row.metrics.campaignPerformance,
                  'icon'
                )}
              />
              {this.getEffectivityProperty(
                row.metrics.campaignPerformance,
                'label'
              )}
            </div>
          );
        },
        size: '1',
      },
      {
        dataField: 'lastEdited',
        title: this.props.t('panels.campaigns.columns.editedBy'),
        className: 'd-none d-md-block  lastEdited p-0',
        formatter: (cellContent, row) => {
          let formattedUpdateTimestamp = row.lastUpdateTimestamp
            ? moment(row.lastUpdateTimestamp)
            : null;
          return (
            <div className="lastEditedContent">
              {(row.lastEditedBy && row.lastEditedBy.fullName) ||
                formattedUpdateTimestamp ? (
                  <>
                    <div
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        backgroundImage: `url(${envConfig.staticImgUrl}avatars/${row.lastEditedBy && row.lastEditedBy.avatar
                            ? row.lastEditedBy.avatar
                            : 'mini-noavatar.jpg'
                          })`,
                        backgroundSize: 'cover',
                        marginBottom: '5px',
                      }}
                    />
                    <span
                      className="text-dark text-truncate"
                      style={{
                        fontSize: '12px',
                        lineHeight: '19px',
                        fontWeight: '600',
                        maxWidth: '100%',
                      }}
                    >
                      {(row.lastEditedBy && row.lastEditedBy.fullName) || 'N/A'}
                    </span>
                    <DateTime timestamp={formattedUpdateTimestamp} bold />
                  </>
                ) : (
                  <span
                    className="text-dark"
                    style={{
                      fontSize: '12px',
                      lineHeight: '19px',
                      fontWeight: '600',
                      maxWidth: '100%',
                    }}
                  >
                    {' '}
                  N/A{' '}
                  </span>
                )}
            </div>
          );
        },
        // size: '2'
      },
      {
        dataField: 'switch',
        title: '',
        className: 'd-none d-md-block',
        formatter: (cellContent, row, updateRow) => {
          return (
            <div style={{ marginTop: '2px' }}>
              <MDBSwitch
                disabled={!row.hasWriteAccess}
                checked={row.active !== undefined ? row.active : 'false'}
                onChange={this.handleSwitchChange(
                  row.active,
                  row.name,
                  row.id,
                  updateRow,
                  row
                )}
                labelLeft=""
                labelRight=""
              />
            </div>
          );
        },
        size: '1',
      },
    ];
    let config = [
      {
        key: 1,
        icon: 'plus',
        label: this.props.t('panels.campaigns.otherLabels.newCampaign'),
        id: "new-campaign",
        primary: true,
        onClick: () => this.openNewCampaignModal(),
      },
    ];
    return (
      <div id="collVisContainer" className="mt-4">
        {this.confirmationModal()}
        <Toast autoClose={4000} />
        {this.newCampaignModal()}
        {this.minimalEditorModal()}
        <PreviewModal
          campaignName={this.state.preview.name}
          campaignSubject={this.state.preview.subject}
          campaignId={this.state.preview.id}
          isOpen={this.state.preview}
          close={() => this.handlePreview(false)}
          t={this.props.t}
        />
        {!this.props.loading && (
          //mx-lg-5
          <div className="mx-lg-5">
            {this.state.renderCollectionVisualizer && this.props.filters && (
              <CollectionVisualizerContainer
                scrollTopButton={true}
                export={this.exportCampaigns}
                exportName={`Campaigns-${Date.now()}`}
                formats={[{ type: 'text/csv', format: 'csv' }]}
                rowActions={this.rowActions}
                rowActionsClassName={'rowActionsNew'}
                actionsPrefix="campaigns"
                columns={columns}
                headerActions={config}
                customEmptyState={this.emptyState}
                // showCustomEmptyState={this.showCustomEmptyState()}
                title={this.props.t('menu.campaigns.title')}
                subTitle={this.props.t(
                  'panels.campaigns.otherLabels.last30days'
                )}
                pagination={'infinite'}
                onRowClick={(row, event) =>
                  this.props.history.push(`/campaigns/${row.id}`)
                }
                getData={(page, order, filters) => {
                  return getCampaignsByCompanyId(
                    this.props.selectedCompany.id,
                    page,
                    order,
                    filters
                  );
                }}
                showFiltersInfo={{ hideOnDefault: true }}
                defaultFilters={this.state.defaultFilters}
                filters={this.props.filters}
                onFiltersApply={this.applyFilters}
                onResetFilters={this.resetFilters}
                setFilters={this.props.setCampaignListFilters}
                stores={this.props.availableStores}
                companies={this.props.availableCompanies}
                selectedCompany={this.props.selectedCompany}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default translate('common')(
  connect(mapStateToProps, { setLoading, setError, setCampaignListFilters })(
    withRouter(CampaignsListContainer)
  )
);
