import React from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBSpinner } from
    "mdbreact";
import Score, { getScoreColor } from "./Score";
import SocialSourceIcon, { getSiteName } from "./SocialSourceIcon";
import Number from '../../commons/components/utils/FormatedNumber'
import Chart from "react-google-charts";
import { round } from '../../commons/utils/numbers';
import './reviewsSummary.css';
import { Carousel } from 'botbit-ui-components';

const ReviewsSummary = ({ data, loading, t }) => {
    if (loading)
        return <div className="text-center m-auto reviews-loading"><MDBSpinner /></div>
    const averageScore = round(data.averageScore, 1)
    let totalsByScore = [
    ]
    // TODO ver que hacer con los puntajes 0
    data.totalsByScore.forEach(totalByScore => totalByScore.score > 0 && totalsByScore.unshift([`${totalByScore.score}`, totalByScore.totalReviews, getScoreColor(totalByScore.score), totalByScore.totalReviews]))
    totalsByScore.unshift(
        [
            'Puntaje',
            'Total',
            { role: 'style' },
            {
                sourceColumn: 0,
                role: 'annotation',
                type: 'string',
                calc: 'stringify',
            },
        ])
    const summaryCardClass = (data.totalsBySocialSource.length <= 2) ? "reviews-summary-card-2-sources" : "reviews-summary-card-3-sources"
    const items = [
        <MDBCard className={["card-body", "m-2", summaryCardClass]}>
            <MDBCardTitle>{t("reviewsTotal")}</MDBCardTitle>
            <h1><Number number={data.totalReviews} /></h1>
        </MDBCard>,
        <MDBCard className={["card-body", "m-2", summaryCardClass]}>
            <MDBCardTitle>{t("averageScore")}</MDBCardTitle>
            <h2><Score value={averageScore} size="l" starDimension="30px" /><br />{averageScore}</h2>
        </MDBCard>,
        <MDBCard className={["card-body", "m-2", summaryCardClass]}>
            <MDBCardTitle>{t("totalScore")}</MDBCardTitle>
            <Chart
                chartLanguage={require('browser-locale')()}
                // width={'200px'}
                height={'100px'}
                chartType="BarChart"
                loader={<div>Cargando...</div>}
                data={totalsByScore}
                options={{
                    labels: 'value',
                    chartArea: { 'width': '90%', 'height': '100%' },
                    legend: 'none',
                }}
            // For tests
            // rootProps={{ 'data-testid': '6' }}
            />
        </MDBCard>,
        <MDBCard className={["card-body", "m-2", summaryCardClass]}>
            <MDBCardTitle>{t("totalBySource")}</MDBCardTitle>
            {
                data.totalsBySocialSource.map(totalBySocialSourceId =>
                    <MDBRow key={totalBySocialSourceId.socialSourceId} className='mb-2'>
                        <MDBCol><small><SocialSourceIcon site={totalBySocialSourceId.socialSourceId} /> <b>{getSiteName(totalBySocialSourceId.socialSourceId)}</b> (<Number number={totalBySocialSourceId.totalReviews} />) </small></MDBCol>
                        <MDBCol size="4" className="text-right m-auto"><Score value={round(totalBySocialSourceId.averageScore, 1)} size="s" /></MDBCol>
                    </MDBRow>)
            }
        </MDBCard>]

    return <Carousel
        items={items}
        autoPlay={false}
        fadeOutAnimation={true}
        mouseDragEnabled={true}
        responsive={
            {
                600: {
                    items: 2
                },
                1200: {
                    items: 4
                }
            }
        }
        disableAutoPlayOnAction={true}
        buttonsDisabled={true}
        infinite={false}
    />
}

export default ReviewsSummary