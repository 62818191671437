import {
	Filters,
	timePeriodsIds as timePeriods,
	calculateFrom,
	calculateTo
} from "botbit-ui-components";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import 'moment/locale/es';
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { translations } from "../../../config/translations/Feedback/QuestionStatsContainer";
import translate from "../../../config/translations/translate";
import { getAnswerStats } from '../../../api/feedbackApi';
import { NewLoader } from '../../NewLoader/NewLoader';
import './QuestionStatsContainer.scss';
import ReactApexChart from 'react-apexcharts';

const useMock = false
// const mock = [
// 	{
// 		"questionType": "OPTIONS",
// 		"questionId": 1,
// 		"totalAnswers": 1,
// 		"options": [
// 			{
// 				"optionId": 8,
// 				"total": 0,
// 				"text": "bueno"
// 			},
// 			{
// 				"optionId": 9,
// 				"total": 0,
// 				"text": "malo"
// 			}
// 		],
// 		"text": "Que te parecio el lugar?"
// 	},
// 	{
// 		"questionType": "OPTIONS",
// 		"questionId": 3,
// 		"totalAnswers": 2,
// 		"options": [
// 			{
// 				"optionId": 1,
// 				"total": 10,
// 				"text": "Pizza"
// 			},
// 			{
// 				"optionId": 2,
// 				"total": 15,
// 				"text": "Empanadas"
// 			},
// 			{
// 				"optionId": 3,
// 				"total": 50,
// 				"text": "Tarta"
// 			},
// 			{
// 				"optionId": 4,
// 				"total": 0,
// 				"text": "Hamburguesa"
// 			}
// 		],
// 		"text": "Que te gustaria que agreguemos al menu?"
// 	},
// 	{
// 		"questionType": "OPTIONS",
// 		"questionId": 4,
// 		"totalAnswers": 1,
// 		"options": [
// 			{
// 				"optionId": 7,
// 				"total": 48,
// 				"text": "option3"
// 			},
// 			{
// 				"optionId": 5,
// 				"total": 12,
// 				"text": "option1"
// 			},
// 			{
// 				"optionId": 6,
// 				"total": 0,
// 				"text": "option2"
// 			}
// 		],
// 		"text": "Texto muy largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo largo "
// 	},
// 	{
// 		"questionType": "SCORE",
// 		"questionId": 2,
// 		"totalAnswers": 2,
// 		"totalOneStarAnswers": 10,
// 		"totalTwoStarsAnswers": 10,
// 		"totalThreeStarsAnswers": 10,
// 		"totalFourStarsAnswers": 10,
// 		"totalFiveStarsAnswers": 10,
// 		"text": "Como calificarias la atencion?"
// 	}
// ]

const mock = []
const ceilHours = date => {
	date.setHours(date.getHours() + 1);
	date.setMinutes(0, 0, 0)
	return date;
}

const floorHours = date => {
	date.setHours(date.getHours());
	date.setMinutes(0, 0, 0)
	return date;
}

const apexOptions = {
	chart: {
		// width: 200,
		type: 'donut',
		events: {
			legendClick: function (chartContext, seriesIndex, config) {
				return false; // Evita el comportamiento por defecto al hacer clic en la leyenda
			}
		}
	},
	labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
	legend: {
		position: 'bottom'
	},
	dataLabels: {
		enabled: true,
		style: {
			fontSize: '11px',
			fontFamily: 'Helvetica, Arial, sans-serif',
			fontWeight: 'normal'
		},
		formatter: function (val, opts) {
			const total = opts.w.globals.seriesTotals.reduce((a, b) => {
				return a + b;
			}, 0);
			if (total === 0) {
				return '0'
			}
			const value = opts.w.globals.seriesTotals[opts.seriesIndex]
			const percentage = Math.round(value * 100 / total);
			return value + ' (' + percentage + '%)';
		},
	},
	plotOptions: {
		pie: {
			donut: {
				size: '50%',
				labels: {
					show: true,
					total: {
						show: true,
						showAlways: true,
						formatter: function (w) {
							return w.globals.seriesTotals.reduce((a, b) => {
								return a + b
							}, 0)
						}
					}
				}
			},
		}
	}
}

const createOptionsGraph = (stats) => {
	const labels = []
	const series = []
	stats.options.forEach(element => {
		labels.push(element.text)
		series.push(element.total)
	});

	return <ReactApexChart
		options={{
			...apexOptions,
			labels
		}}
		series={series}
		type="donut"
	// width={200} 
	/>
}

const stars = [
	'&#9733;',
	'&#9733;&#9733;',
	'&#9733;&#9733;&#9733;',
	'&#9733;&#9733;&#9733;&#9733;',
	'&#9733;&#9733;&#9733;&#9733;&#9733;',
]
const getStarArrayLabels = () => [1, 2, 3, 4, 5].map(star => `<span>${stars[star - 1]}</span>`)

const createScoreGraph = (stats) => {
	return <ReactApexChart
		options={{
			...apexOptions,
			labels: getStarArrayLabels(),
			colors: ['#FF0000', '#FF6600', '#FFCC00', '#33CC33', '#009900']
		}}
		series={[
			stats.totalOneStarAnswers,
			stats.totalTwoStarsAnswers,
			stats.totalThreeStarsAnswers,
			stats.totalFourStarsAnswers,
			stats.totalFiveStarsAnswers
		]}
		type="donut"
	// width={200} 
	/>

}

const QuestionStatsContainer = ({ t, history, trackEvent }) => {

	const selectedCompany = useSelector(state => state.application.selectedCompany);
	const availableCompanies = useSelector(state => state.application.availableCompanies);
	const availableStores = useSelector(state => state.application.availableStores)
	const [filterValues, setFilterValues] = useState({
		timePeriod: [{
			value: timePeriods.LAST_30_DAYS,
			to: ceilHours(calculateTo(timePeriods.LAST_30_DAYS)),
			from: floorHours(calculateFrom(timePeriods.LAST_30_DAYS))
		}],
		companies: [selectedCompany.id],
		stores: []
	})
	const [answerStats, setAnswerStats] = useState(undefined)
	const [isLoading, setIsLoading] = useState(true);

	const schemas = {
		timePeriod: {
			id: 'timePeriod',
			label: t("timePeriod"),
			type: 'timeperiod',
			icon: 'calendar-alt',
			placeholder: t("custom"),
			priority: true,
			visible: true,
			options: [timePeriods.THIS_MONTH, timePeriods.LAST_30_DAYS, timePeriods.LAST_6_MONTHS, timePeriods.LAST_12_MONTHS, timePeriods.THIS_YEAR, timePeriods.CUSTOM],
			defaultValues: filterValues.timePeriod,
		},
		companies: {
			id: "companies",
			label: t("company"),
			type: "select",
			icon: "building",
			placeholder: t("allCompanies"),
			priority: true,
			visible: availableCompanies.length > 1,
			options: availableCompanies.map(s => ({ value: s.id, text: s.name })),
			defaultValues: filterValues.companies,
			search: true
		},
		stores: {
			id: "stores",
			label: t("store"),
			type: "multiselect",
			icon: "store-alt",
			placeholder: t("allStores"),
			priority: true,
			visible: true,
			options: availableStores.map(s => ({ value: s.id, text: s.name, dependencyId: s.companyId })),
			search: true,
			defaultValues: [],
			dependsOn: "companies"
		}
	};

	useEffect(() => {
		if (useMock) {
			setAnswerStats(mock)
			setIsLoading(false)
			return;
		}

		// firePageView()
		setIsLoading(true)
		getAnswerStats(filterValues.companies[0],
			filterValues.timePeriod[0].from,
			filterValues.timePeriod[0].to,
			filterValues.stores
		).then((rsp) => {
			setAnswerStats(rsp.data.data.answers)
		}).finally(() => {
			setIsLoading(false)
		})
	}, [JSON.stringify(filterValues)])

	return (<>
		<MDBContainer className="mt-3 question-stats-container">
			<MDBRow>
				<MDBCol size="12">
					<Filters
						title={t("title")}
						schemas={schemas}
						values={filterValues}
						onChange={(newValues) => {
							setFilterValues(newValues)
						}}
						showInfo
					/>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				{!isLoading && answerStats && answerStats.length > 0 && answerStats.map((stat, index) => <MDBCol
					className='mt-5'
					size="12"
					lg="4"
					md="6"
					sm="6"
					key={index}
				>
					<MDBCard>
						<MDBCardHeader className='stats-card-header'>
							<p className='stats-card-title'>
								{stat.text}
							</p>
						</MDBCardHeader>
						<MDBCardBody className='stats-card-body'>
							{stat.questionType === "OPTIONS" && createOptionsGraph(stat)}
							{stat.questionType === "SCORE" && createScoreGraph(stat)}
						</MDBCardBody>
					</MDBCard>
				</MDBCol>)
				}
				{!isLoading && (!answerStats || answerStats.length === 0) &&
					<h3 className='text-center w-100'>{t('noData')}</h3>
				}
				{isLoading && <MDBCol size='12'>
					<NewLoader />
				</MDBCol>
				}
			</MDBRow>
			<br />
			<br />
			<br />
		</MDBContainer>
	</>)
}

QuestionStatsContainer.translations = {
	...translations
}

export default translate(QuestionStatsContainer);