import React from 'react'
import { connect } from 'react-redux'
import { setLoading, setError } from '../application/miscActions'
import Events from './Events';
import { getEvents } from '../../../api/eventApi';
import { Button } from 'react-bootstrap'

const mapStateToProps = (state) => {
    return {
        companyId: state.application.selectedCompany.id
    }
};
class EventsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            table: this.props.mode === undefined || this.props.mode === 'table'
        };
        this.fetchEvents = this.fetchEvents.bind(this)
    }

    componentDidMount() {
        // this.fetchEvents()
    }

    fetchEvents(filters) {
        this.props.setLoading(true);
        return (getEvents(filters).then((response) => {
            this.props.setLoading(false)
            return response.data;
        }))
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.companyId !== this.props.companyId) {
        //     this.fetchEvents()
        // }
    }

    render = () => {
        return (
            //filters={{ subTypes: [EventSubType.CHECK_IN], stores: [2] }}
            <div>
                {(this.props.toggle === undefined || this.props.toggle === true) && <Button className="m-t m-b" onClick={() => this.setState({ table: !this.state.table })}>Toggle</Button>}
                <Events updateUser={this.props.updateUser} mode={this.state.table ? "table" : "feed"} getEvents={this.props.getEvents !== undefined ? this.props.getEvents : (filters) =>
                    new Promise((resolve, reject) => {
                        this.props.setLoading(true);
                        getEvents(filters).then((response) => {
                            this.props.setLoading(false)
                            resolve(response.data.data);
                        })
                    })
                } />
            </div>
        )
    }
}

export default connect(mapStateToProps, { setLoading, setError })(EventsContainer)
