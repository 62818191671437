import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { translations } from '../../../config/translations/Feedback/SentimentContainer';
import translate from '../../../config/translations/translate';
import { SectionTitle } from './SectionTitle';
import { MDBInput } from 'mdbreact';

const isNumber = (number) => number || number === 0

const mock = [
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2742,
		"storeName": "Tea Connectio Oroño",
		"totalPositiveReviews": 62,
		"totalNegativeReviews": 2,
		"totalNeutralReviews": 164,
		"totalReviews": 228,
		"totalPositiveAndNegativeReviews": 64,
		"positivePercentage": 27.192982456140353,
		"negativePercentage": 0.8771929824561403,
		"neutralPercentage": 71.9298245614035,
		"positiveWithoutNeutralPercentage": 96.875,
		"negativeWithoutNeutralPercentage": 3.125,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2742,
			"storeName": "Tea Connectio Oroño",
			"totalPositiveReviews": 187,
			"totalNegativeReviews": 94,
			"totalNeutralReviews": 439,
			"totalReviews": 720,
			"totalPositiveAndNegativeReviews": 281,
			"positivePercentage": 25.972222222222225,
			"negativePercentage": 13.055555555555557,
			"neutralPercentage": 60.972222222222214,
			"positiveWithoutNeutralPercentage": 66.54804270462633,
			"negativeWithoutNeutralPercentage": 33.451957295373674,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2743,
		"storeName": "Tea Connection Alto Rosario",
		"totalPositiveReviews": 22,
		"totalNegativeReviews": 4,
		"totalNeutralReviews": 52,
		"totalReviews": 78,
		"totalPositiveAndNegativeReviews": 26,
		"positivePercentage": 28.205128205128204,
		"negativePercentage": 5.128205128205128,
		"neutralPercentage": 66.66666666666667,
		"positiveWithoutNeutralPercentage": 84.61538461538461,
		"negativeWithoutNeutralPercentage": 15.384615384615387,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2743,
			"storeName": "Tea Connection Alto Rosario",
			"totalPositiveReviews": 98,
			"totalNegativeReviews": 23,
			"totalNeutralReviews": 287,
			"totalReviews": 408,
			"totalPositiveAndNegativeReviews": 121,
			"positivePercentage": 24.019607843137255,
			"negativePercentage": 5.637254901960785,
			"neutralPercentage": 70.34313725490196,
			"positiveWithoutNeutralPercentage": 80.99173553719008,
			"negativeWithoutNeutralPercentage": 19.00826446280992,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2685,
		"storeName": "tea connection AVALOS",
		"totalPositiveReviews": 27,
		"totalNegativeReviews": 4,
		"totalNeutralReviews": 65,
		"totalReviews": 96,
		"totalPositiveAndNegativeReviews": 31,
		"positivePercentage": 28.125,
		"negativePercentage": 4.166666666666666,
		"neutralPercentage": 67.70833333333333,
		"positiveWithoutNeutralPercentage": 87.09677419354838,
		"negativeWithoutNeutralPercentage": 12.903225806451616,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2685,
			"storeName": "tea connection AVALOS",
			"totalPositiveReviews": 56,
			"totalNegativeReviews": 32,
			"totalNeutralReviews": 128,
			"totalReviews": 216,
			"totalPositiveAndNegativeReviews": 88,
			"positivePercentage": 25.925925925925924,
			"negativePercentage": 14.814814814814813,
			"neutralPercentage": 59.25925925925927,
			"positiveWithoutNeutralPercentage": 63.63636363636363,
			"negativeWithoutNeutralPercentage": 36.36363636363637,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2680,
		"storeName": "tea connection CONDE",
		"totalPositiveReviews": 11,
		"totalNegativeReviews": 4,
		"totalNeutralReviews": 39,
		"totalReviews": 54,
		"totalPositiveAndNegativeReviews": 15,
		"positivePercentage": 20.37037037037037,
		"negativePercentage": 7.4074074074074066,
		"neutralPercentage": 72.22222222222223,
		"positiveWithoutNeutralPercentage": 73.33333333333333,
		"negativeWithoutNeutralPercentage": 26.66666666666667,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2680,
			"storeName": "tea connection CONDE",
			"totalPositiveReviews": 28,
			"totalNegativeReviews": 18,
			"totalNeutralReviews": 62,
			"totalReviews": 108,
			"totalPositiveAndNegativeReviews": 46,
			"positivePercentage": 25.925925925925924,
			"negativePercentage": 16.666666666666664,
			"neutralPercentage": 57.40740740740741,
			"positiveWithoutNeutralPercentage": 60.86956521739131,
			"negativeWithoutNeutralPercentage": 39.13043478260869,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2715,
		"storeName": "Tea Connection Devoto",
		"totalPositiveReviews": 20,
		"totalNegativeReviews": 0,
		"totalNeutralReviews": 40,
		"totalReviews": 60,
		"totalPositiveAndNegativeReviews": 20,
		"positivePercentage": 33.33333333333333,
		"negativePercentage": 0,
		"neutralPercentage": 66.66666666666667,
		"positiveWithoutNeutralPercentage": 100,
		"negativeWithoutNeutralPercentage": 0,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2715,
			"storeName": "Tea Connection Devoto",
			"totalPositiveReviews": 68,
			"totalNegativeReviews": 0,
			"totalNeutralReviews": 64,
			"totalReviews": 132,
			"totalPositiveAndNegativeReviews": 68,
			"positivePercentage": 51.515151515151516,
			"negativePercentage": 0,
			"neutralPercentage": 48.484848484848484,
			"positiveWithoutNeutralPercentage": 100,
			"negativeWithoutNeutralPercentage": 0,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2682,
		"storeName": "tea connection FORMOSA",
		"totalPositiveReviews": 5,
		"totalNegativeReviews": 3,
		"totalNeutralReviews": 28,
		"totalReviews": 36,
		"totalPositiveAndNegativeReviews": 8,
		"positivePercentage": 13.88888888888889,
		"negativePercentage": 8.333333333333332,
		"neutralPercentage": 77.77777777777779,
		"positiveWithoutNeutralPercentage": 62.5,
		"negativeWithoutNeutralPercentage": 37.5,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2682,
			"storeName": "tea connection FORMOSA",
			"totalPositiveReviews": 5,
			"totalNegativeReviews": 18,
			"totalNeutralReviews": 31,
			"totalReviews": 54,
			"totalPositiveAndNegativeReviews": 23,
			"positivePercentage": 9.25925925925926,
			"negativePercentage": 33.33333333333333,
			"neutralPercentage": 57.407407407407405,
			"positiveWithoutNeutralPercentage": 21.73913043478261,
			"negativeWithoutNeutralPercentage": 78.26086956521739,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2713,
		"storeName": "Tea Connection Gorostiaga",
		"totalPositiveReviews": 8,
		"totalNegativeReviews": 0,
		"totalNeutralReviews": 10,
		"totalReviews": 18,
		"totalPositiveAndNegativeReviews": 8,
		"positivePercentage": 44.44444444444444,
		"negativePercentage": 0,
		"neutralPercentage": 55.55555555555556,
		"positiveWithoutNeutralPercentage": 100,
		"negativeWithoutNeutralPercentage": 0,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2713,
			"storeName": "Tea Connection Gorostiaga",
			"totalPositiveReviews": 48,
			"totalNegativeReviews": 26,
			"totalNeutralReviews": 94,
			"totalReviews": 168,
			"totalPositiveAndNegativeReviews": 74,
			"positivePercentage": 28.57142857142857,
			"negativePercentage": 15.476190476190476,
			"neutralPercentage": 55.952380952380956,
			"positiveWithoutNeutralPercentage": 64.86486486486487,
			"negativeWithoutNeutralPercentage": 35.13513513513513,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2806,
		"storeName": "Tea Connection Junín",
		"totalPositiveReviews": 13,
		"totalNegativeReviews": 4,
		"totalNeutralReviews": 37,
		"totalReviews": 54,
		"totalPositiveAndNegativeReviews": 17,
		"positivePercentage": 24.074074074074073,
		"negativePercentage": 7.4074074074074066,
		"neutralPercentage": 68.51851851851852,
		"positiveWithoutNeutralPercentage": 76.47058823529412,
		"negativeWithoutNeutralPercentage": 23.529411764705884,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2806,
			"storeName": "Tea Connection Junín",
			"totalPositiveReviews": 17,
			"totalNegativeReviews": 2,
			"totalNeutralReviews": 29,
			"totalReviews": 48,
			"totalPositiveAndNegativeReviews": 19,
			"positivePercentage": 35.41666666666667,
			"negativePercentage": 4.166666666666666,
			"neutralPercentage": 60.416666666666664,
			"positiveWithoutNeutralPercentage": 89.47368421052632,
			"negativeWithoutNeutralPercentage": 10.526315789473685,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2829,
		"storeName": "Tea Connection La Plata",
		"totalPositiveReviews": 3,
		"totalNegativeReviews": 7,
		"totalNeutralReviews": 20,
		"totalReviews": 30,
		"totalPositiveAndNegativeReviews": 10,
		"positivePercentage": 10,
		"negativePercentage": 23.333333333333332,
		"neutralPercentage": 66.66666666666667,
		"positiveWithoutNeutralPercentage": 30,
		"negativeWithoutNeutralPercentage": 70,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2829,
			"storeName": "Tea Connection La Plata",
			"totalPositiveReviews": 35,
			"totalNegativeReviews": 12,
			"totalNeutralReviews": 79,
			"totalReviews": 126,
			"totalPositiveAndNegativeReviews": 47,
			"positivePercentage": 27.77777777777778,
			"negativePercentage": 9.523809523809524,
			"neutralPercentage": 62.6984126984127,
			"positiveWithoutNeutralPercentage": 74.46808510638297,
			"negativeWithoutNeutralPercentage": 25.531914893617028,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2679,
		"storeName": "tea connection LACROZE",
		"totalPositiveReviews": 4,
		"totalNegativeReviews": 0,
		"totalNeutralReviews": 14,
		"totalReviews": 18,
		"totalPositiveAndNegativeReviews": 4,
		"positivePercentage": 22.22222222222222,
		"negativePercentage": 0,
		"neutralPercentage": 77.77777777777777,
		"positiveWithoutNeutralPercentage": 100,
		"negativeWithoutNeutralPercentage": 0,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2679,
			"storeName": "tea connection LACROZE",
			"totalPositiveReviews": 26,
			"totalNegativeReviews": 15,
			"totalNeutralReviews": 49,
			"totalReviews": 90,
			"totalPositiveAndNegativeReviews": 41,
			"positivePercentage": 28.888888888888886,
			"negativePercentage": 16.666666666666664,
			"neutralPercentage": 54.44444444444445,
			"positiveWithoutNeutralPercentage": 63.41463414634146,
			"negativeWithoutNeutralPercentage": 36.58536585365854,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2712,
		"storeName": "Tea Connection Libertador",
		"totalPositiveReviews": 1,
		"totalNegativeReviews": 0,
		"totalNeutralReviews": 5,
		"totalReviews": 6,
		"totalPositiveAndNegativeReviews": 1,
		"positivePercentage": 16.666666666666664,
		"negativePercentage": 0,
		"neutralPercentage": 83.33333333333334,
		"positiveWithoutNeutralPercentage": 100,
		"negativeWithoutNeutralPercentage": 0,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2712,
			"storeName": "Tea Connection Libertador",
			"totalPositiveReviews": 40,
			"totalNegativeReviews": 0,
			"totalNeutralReviews": 32,
			"totalReviews": 72,
			"totalPositiveAndNegativeReviews": 40,
			"positivePercentage": 55.55555555555556,
			"negativePercentage": 0,
			"neutralPercentage": 44.44444444444444,
			"positiveWithoutNeutralPercentage": 100,
			"negativeWithoutNeutralPercentage": 0,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2714,
		"storeName": "Tea Connection Lomitas",
		"totalPositiveReviews": 7,
		"totalNegativeReviews": 0,
		"totalNeutralReviews": 11,
		"totalReviews": 18,
		"totalPositiveAndNegativeReviews": 7,
		"positivePercentage": 38.88888888888889,
		"negativePercentage": 0,
		"neutralPercentage": 61.11111111111111,
		"positiveWithoutNeutralPercentage": 100,
		"negativeWithoutNeutralPercentage": 0,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2714,
			"storeName": "Tea Connection Lomitas",
			"totalPositiveReviews": 36,
			"totalNegativeReviews": 16,
			"totalNeutralReviews": 68,
			"totalReviews": 120,
			"totalPositiveAndNegativeReviews": 52,
			"positivePercentage": 30,
			"negativePercentage": 13.333333333333334,
			"neutralPercentage": 56.666666666666664,
			"positiveWithoutNeutralPercentage": 69.23076923076923,
			"negativeWithoutNeutralPercentage": 30.769230769230774,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2768,
		"storeName": "Tea Connection Mendoza Plaza",
		"totalPositiveReviews": 1,
		"totalNegativeReviews": 3,
		"totalNeutralReviews": 14,
		"totalReviews": 18,
		"totalPositiveAndNegativeReviews": 4,
		"positivePercentage": 5.555555555555555,
		"negativePercentage": 16.666666666666664,
		"neutralPercentage": 77.77777777777777,
		"positiveWithoutNeutralPercentage": 25,
		"negativeWithoutNeutralPercentage": 75,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2768,
			"storeName": "Tea Connection Mendoza Plaza",
			"totalPositiveReviews": 54,
			"totalNegativeReviews": 12,
			"totalNeutralReviews": 66,
			"totalReviews": 132,
			"totalPositiveAndNegativeReviews": 66,
			"positivePercentage": 40.909090909090914,
			"negativePercentage": 9.090909090909092,
			"neutralPercentage": 49.99999999999999,
			"positiveWithoutNeutralPercentage": 81.81818181818183,
			"negativeWithoutNeutralPercentage": 18.181818181818173,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2678,
		"storeName": "tea connection MONTEVIDEO",
		"totalPositiveReviews": 3,
		"totalNegativeReviews": 1,
		"totalNeutralReviews": 2,
		"totalReviews": 6,
		"totalPositiveAndNegativeReviews": 4,
		"positivePercentage": 50,
		"negativePercentage": 16.666666666666664,
		"neutralPercentage": 33.333333333333336,
		"positiveWithoutNeutralPercentage": 75,
		"negativeWithoutNeutralPercentage": 25,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2678,
			"storeName": "tea connection MONTEVIDEO",
			"totalPositiveReviews": 29,
			"totalNegativeReviews": 13,
			"totalNeutralReviews": 72,
			"totalReviews": 114,
			"totalPositiveAndNegativeReviews": 42,
			"positivePercentage": 25.438596491228072,
			"negativePercentage": 11.403508771929824,
			"neutralPercentage": 63.15789473684211,
			"positiveWithoutNeutralPercentage": 69.04761904761905,
			"negativeWithoutNeutralPercentage": 30.95238095238095,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2998,
		"storeName": "Tea connection Nordelta",
		"totalPositiveReviews": 16,
		"totalNegativeReviews": 3,
		"totalNeutralReviews": 53,
		"totalReviews": 72,
		"totalPositiveAndNegativeReviews": 19,
		"positivePercentage": 22.22222222222222,
		"negativePercentage": 4.166666666666666,
		"neutralPercentage": 73.6111111111111,
		"positiveWithoutNeutralPercentage": 84.21052631578947,
		"negativeWithoutNeutralPercentage": 15.789473684210535,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2998,
			"storeName": "Tea connection Nordelta",
			"totalPositiveReviews": 57,
			"totalNegativeReviews": 22,
			"totalNeutralReviews": 125,
			"totalReviews": 204,
			"totalPositiveAndNegativeReviews": 79,
			"positivePercentage": 27.941176470588236,
			"negativePercentage": 10.784313725490197,
			"neutralPercentage": 61.274509803921575,
			"positiveWithoutNeutralPercentage": 72.15189873417721,
			"negativeWithoutNeutralPercentage": 27.84810126582279,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2744,
		"storeName": "Tea Connection Palmares",
		"totalPositiveReviews": 15,
		"totalNegativeReviews": 7,
		"totalNeutralReviews": 50,
		"totalReviews": 72,
		"totalPositiveAndNegativeReviews": 22,
		"positivePercentage": 20.833333333333336,
		"negativePercentage": 9.722222222222223,
		"neutralPercentage": 69.44444444444443,
		"positiveWithoutNeutralPercentage": 68.18181818181817,
		"negativeWithoutNeutralPercentage": 31.818181818181827,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2744,
			"storeName": "Tea Connection Palmares",
			"totalPositiveReviews": 70,
			"totalNegativeReviews": 5,
			"totalNeutralReviews": 141,
			"totalReviews": 216,
			"totalPositiveAndNegativeReviews": 75,
			"positivePercentage": 32.407407407407405,
			"negativePercentage": 2.314814814814815,
			"neutralPercentage": 65.27777777777779,
			"positiveWithoutNeutralPercentage": 93.33333333333333,
			"negativeWithoutNeutralPercentage": 6.666666666666671,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2683,
		"storeName": "tea connection PAUNERO",
		"totalPositiveReviews": 6,
		"totalNegativeReviews": 6,
		"totalNeutralReviews": 30,
		"totalReviews": 42,
		"totalPositiveAndNegativeReviews": 12,
		"positivePercentage": 14.285714285714285,
		"negativePercentage": 14.285714285714285,
		"neutralPercentage": 71.42857142857144,
		"positiveWithoutNeutralPercentage": 50,
		"negativeWithoutNeutralPercentage": 50,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2683,
			"storeName": "tea connection PAUNERO",
			"totalPositiveReviews": 24,
			"totalNegativeReviews": 18,
			"totalNeutralReviews": 84,
			"totalReviews": 126,
			"totalPositiveAndNegativeReviews": 42,
			"positivePercentage": 19.047619047619047,
			"negativePercentage": 14.285714285714285,
			"neutralPercentage": 66.66666666666666,
			"positiveWithoutNeutralPercentage": 57.14285714285714,
			"negativeWithoutNeutralPercentage": 42.85714285714286,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2769,
		"storeName": "Tea Connection Scalabrini",
		"totalPositiveReviews": 0,
		"totalNegativeReviews": 4,
		"totalNeutralReviews": 8,
		"totalReviews": 12,
		"totalPositiveAndNegativeReviews": 4,
		"positivePercentage": 0,
		"negativePercentage": 33.33333333333333,
		"neutralPercentage": 66.66666666666667,
		"positiveWithoutNeutralPercentage": 0,
		"negativeWithoutNeutralPercentage": 100,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2769,
			"storeName": "Tea Connection Scalabrini",
			"totalPositiveReviews": 34,
			"totalNegativeReviews": 10,
			"totalNeutralReviews": 58,
			"totalReviews": 102,
			"totalPositiveAndNegativeReviews": 44,
			"positivePercentage": 33.33333333333333,
			"negativePercentage": 9.803921568627452,
			"neutralPercentage": 56.86274509803922,
			"positiveWithoutNeutralPercentage": 77.27272727272727,
			"negativeWithoutNeutralPercentage": 22.727272727272734,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2681,
		"storeName": "tea connection SINCLAIR",
		"totalPositiveReviews": 3,
		"totalNegativeReviews": 1,
		"totalNeutralReviews": 14,
		"totalReviews": 18,
		"totalPositiveAndNegativeReviews": 4,
		"positivePercentage": 16.666666666666664,
		"negativePercentage": 5.555555555555555,
		"neutralPercentage": 77.77777777777779,
		"positiveWithoutNeutralPercentage": 75,
		"negativeWithoutNeutralPercentage": 25,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2681,
			"storeName": "tea connection SINCLAIR",
			"totalPositiveReviews": 64,
			"totalNegativeReviews": 13,
			"totalNeutralReviews": 91,
			"totalReviews": 168,
			"totalPositiveAndNegativeReviews": 77,
			"positivePercentage": 38.095238095238095,
			"negativePercentage": 7.738095238095238,
			"neutralPercentage": 54.166666666666664,
			"positiveWithoutNeutralPercentage": 83.11688311688312,
			"negativeWithoutNeutralPercentage": 16.883116883116884,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2684,
		"storeName": "tea connection UNICENTER",
		"totalPositiveReviews": 3,
		"totalNegativeReviews": 0,
		"totalNeutralReviews": 9,
		"totalReviews": 12,
		"totalPositiveAndNegativeReviews": 3,
		"positivePercentage": 25,
		"negativePercentage": 0,
		"neutralPercentage": 75,
		"positiveWithoutNeutralPercentage": 100,
		"negativeWithoutNeutralPercentage": 0,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2684,
			"storeName": "tea connection UNICENTER",
			"totalPositiveReviews": 14,
			"totalNegativeReviews": 7,
			"totalNeutralReviews": 45,
			"totalReviews": 66,
			"totalPositiveAndNegativeReviews": 21,
			"positivePercentage": 21.21212121212121,
			"negativePercentage": 10.606060606060606,
			"neutralPercentage": 68.18181818181817,
			"positiveWithoutNeutralPercentage": 66.66666666666666,
			"negativeWithoutNeutralPercentage": 33.33333333333334,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2716,
		"storeName": "Tea Connection Uriburu",
		"totalPositiveReviews": 42,
		"totalNegativeReviews": 12,
		"totalNeutralReviews": 120,
		"totalReviews": 174,
		"totalPositiveAndNegativeReviews": 54,
		"positivePercentage": 24.137931034482758,
		"negativePercentage": 6.896551724137931,
		"neutralPercentage": 68.9655172413793,
		"positiveWithoutNeutralPercentage": 77.77777777777779,
		"negativeWithoutNeutralPercentage": 22.222222222222214,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2716,
			"storeName": "Tea Connection Uriburu",
			"totalPositiveReviews": 85,
			"totalNegativeReviews": 14,
			"totalNeutralReviews": 159,
			"totalReviews": 258,
			"totalPositiveAndNegativeReviews": 99,
			"positivePercentage": 32.945736434108525,
			"negativePercentage": 5.426356589147287,
			"neutralPercentage": 61.627906976744185,
			"positiveWithoutNeutralPercentage": 85.85858585858585,
			"negativeWithoutNeutralPercentage": 14.141414141414145,
			"previousMonth": null
		}
	},
	{
		"keywordId": null,
		"keyword": null,
		"storeId": 2770,
		"storeName": "Tea Connection Vuelta de Obligado",
		"totalPositiveReviews": 5,
		"totalNegativeReviews": 0,
		"totalNeutralReviews": 13,
		"totalReviews": 18,
		"totalPositiveAndNegativeReviews": 5,
		"positivePercentage": 27.77777777777778,
		"negativePercentage": 0,
		"neutralPercentage": 72.22222222222223,
		"positiveWithoutNeutralPercentage": 100,
		"negativeWithoutNeutralPercentage": 0,
		"previousMonth": {
			"keywordId": null,
			"keyword": null,
			"storeId": 2770,
			"storeName": "Tea Connection Vuelta de Obligado",
			"totalPositiveReviews": 52,
			"totalNegativeReviews": 20,
			"totalNeutralReviews": 96,
			"totalReviews": 168,
			"totalPositiveAndNegativeReviews": 72,
			"positivePercentage": 30.952380952380953,
			"negativePercentage": 11.904761904761903,
			"neutralPercentage": 57.142857142857146,
			"positiveWithoutNeutralPercentage": 72.22222222222221,
			"negativeWithoutNeutralPercentage": 27.777777777777786,
			"previousMonth": null
		}
	}
]

// FIXME: CHECK MOCK FALSE BEFORE PUSH
const useMock = false

const KeywordsDistributionByStores = ({ sentimentsByStore, t }) => {
	const [sortBy, setSortBy] = useState('positivityRate')

	const data = useMock ? mock : sentimentsByStore
	const sortedSentimentsByStore = data && data.sort((elementA, elementB) => {
		if (sortBy === 'positivityRate') {
			if (isNumber(elementA.positiveWithoutNeutralPercentage) && isNumber(elementB.positiveWithoutNeutralPercentage)) {
				if (elementA.positiveWithoutNeutralPercentage === elementB.positiveWithoutNeutralPercentage) {
					if (elementA.previousMonth && elementB.previousMonth && isNumber(elementA.previousMonth.positiveWithoutNeutralPercentage) && isNumber(elementB.previousMonth.positiveWithoutNeutralPercentage)) {
						return elementB.previousMonth.positiveWithoutNeutralPercentage - elementA.previousMonth.positiveWithoutNeutralPercentage
					}
				}
				return elementB.positiveWithoutNeutralPercentage - elementA.positiveWithoutNeutralPercentage
			}
			if (isNumber(elementA.positiveWithoutNeutralPercentage)) {
				return -1
			}
			if (isNumber(elementB.positiveWithoutNeutralPercentage)) {
				return 1
			}
			return elementB.totalPositiveReviews - elementA.totalPositiveReviews
		}
		else if (sortBy === 'totalMentions') {
			if (isNumber(elementA.totalPositiveAndNegativeReviews) && isNumber(elementB.totalPositiveAndNegativeReviews)) {
				return elementB.totalPositiveAndNegativeReviews - elementA.totalPositiveAndNegativeReviews
			}
			if (isNumber(elementA.totalPositiveAndNegativeReviews)) {
				return -1
			}
			if (isNumber(elementB.totalPositiveAndNegativeReviews)) {
				return 1
			}
			return 0
		} else {
			return 0
		}
	})

	const apexSeriesGroups = {
		'positivityRate': {
			series: [{
				name: t('keywordAnalysisSection.distributionByStore.graph.positivePercentage'),
				group: 'positivePercentage',
				data: []
			}, {
				name: t('keywordAnalysisSection.distributionByStore.graph.positivePercentagePrevMonth'),
				group: 'positivePercentagePrevMonth',
				data: []
			}],
			colors: ['#19d108', '#34A953'],
		},
		'totalMentions': {
			series: [{
				name: t('keywordAnalysisSection.distributionByStore.graph.positive'),
				group: 'actual',
				data: []
			}, {
				name: t('keywordAnalysisSection.distributionByStore.graph.negative'),
				group: 'actual',
				data: []
			}, {
				name: t('keywordAnalysisSection.distributionByStore.graph.positivePrevMonth'),
				group: 'prev',
				data: []
			}, {
				name: t('keywordAnalysisSection.distributionByStore.graph.negativePrevMonth'),
				group: 'prev',
				data: []
			}],
			colors: ['#34A953', '#fc5603', '#1b9c10', '#bd4509'],
		}
	}

	// const apexSeries = [{
	// 	name: t('keywordAnalysisSection.distributionByStore.graph.positivePercentage'),
	// 	group: 'percentage',
	// 	data: []
	// },{
	// 	name: t('keywordAnalysisSection.distributionByStore.graph.positive'),
	// 	group: 'actual',
	// 	data: []
	// }, {
	// 	name: t('keywordAnalysisSection.distributionByStore.graph.negative'),
	// 	group: 'actual',
	// 	data: []
	// }, {
	// 	name: t('keywordAnalysisSection.distributionByStore.graph.positivePrevMonth'),
	// 	group: 'prev',
	// 	data: []
	// }, {
	// 	name: t('keywordAnalysisSection.distributionByStore.graph.negativePrevMonth'),
	// 	group: 'prev',
	// 	data: []
	// }]
	const apexCategories = []

	sortedSentimentsByStore.forEach((sentiment, index) => {
		if (sentiment.totalPositiveAndNegativeReviews > 0) {
			apexCategories.push(sentiment.storeName)
			apexSeriesGroups['positivityRate'].series[0].data.push(sentiment.positiveWithoutNeutralPercentage)
			apexSeriesGroups['positivityRate'].series[1].data.push(sentiment.previousMonth ? sentiment.previousMonth.positiveWithoutNeutralPercentage : 0)
			apexSeriesGroups['totalMentions'].series[0].data.push(sentiment.totalPositiveReviews)
			apexSeriesGroups['totalMentions'].series[1].data.push(sentiment.totalNegativeReviews)
			apexSeriesGroups['totalMentions'].series[2].data.push(sentiment.previousMonth ? sentiment.previousMonth.totalPositiveReviews : 0)
			apexSeriesGroups['totalMentions'].series[3].data.push(sentiment.previousMonth ? sentiment.previousMonth.totalNegativeReviews : 0)
		}
	})

	const apexOptions = {
		chart: {
			type: 'bar',
			// height: 350,
			stacked: true,
			toolbar: {
				show: false
			},
			// offsetX: 30
		},
		stroke: {
			colors: ["transparent"],
			width: 1
		},
		dataLabels: {
			style: {
				colors: ['#000'],
				fontSize: '10',
				fontWeight: 'normal'
			},
			// offsetX: 0,
			// position: 'bottom',
			formatter: (val, context) => {
				return val.toFixed(0) + (sortBy === 'positivityRate' ? '%' : '');
			}
		},
		plotOptions: {
			bar: {
				horizontal: true,
				barHeight: '90%'
			}
		},
		xaxis: {
			categories: apexCategories,
			labels: {
				formatter: (val) => {
					return val.toFixed(0) + (sortBy === 'positivityRate' ? '%' : '');
				}
			}
		},
		yaxis: {
			labels: {
				// offsetX: -30, // Ajusta el margen izquierdo de las etiquetas del eje Y
				align: 'left',
				offsetX: -5,
				minWidth: '260px',
				maxWidth: '260px'
			},
		},
		fill: {
			opacity: 1,
		},
		colors: apexSeriesGroups[sortBy].colors,
		legend: {
			position: 'top',
			horizontalAlign: 'center'
		},
		tooltip: {
			shared: true,
			intersect: false,
			followCursor: true,
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {

				//return <div></div>

				const series0 = apexSeriesGroups['totalMentions'].series[0].data[dataPointIndex]
				const series1 = apexSeriesGroups['totalMentions'].series[1].data[dataPointIndex]
				const series2 = apexSeriesGroups['totalMentions'].series[2].data[dataPointIndex]
				const series3 = apexSeriesGroups['totalMentions'].series[3].data[dataPointIndex]

				const totalActual = series0 + series1
				const totalPrev = series2 + series3

				const valueToShow0 = totalActual === 0 ? t('noData') : `${series0} (${Math.round(series0 * 100 / totalActual)}%)`
				const valueToShow1 = totalActual === 0 ? t('noData') : `${series1} (${Math.round(series1 * 100 / totalActual)}%)`
				const valueToShow2 = totalPrev === 0 ? t('noData') : `${series2} (${Math.round(series2 * 100 / totalPrev)}%)`
				const valueToShow3 = totalPrev === 0 ? t('noData') : `${series3} (${Math.round(series3 * 100 / totalPrev)}%)`

				return (
					'<div>' +
					'	<div class="apexcharts-tooltip-title">' + (apexCategories[dataPointIndex]) + '</div>' +
					'	<div class="apexcharts-tooltip-content">' +
					'		<div>' + apexSeriesGroups['totalMentions'].series[0].name + ':&nbsp;<strong>' + valueToShow0 + '</strong></div>' +
					'		<div>' + apexSeriesGroups['totalMentions'].series[1].name + ':&nbsp;<strong>' + valueToShow1 + '</strong></div>' +
					'		<div>' + apexSeriesGroups['totalMentions'].series[2].name + ':&nbsp;<strong>' + valueToShow2 + '</strong></div>' +
					'		<div>' + apexSeriesGroups['totalMentions'].series[3].name + ':&nbsp;<strong>' + valueToShow3 + '</strong></div>' +
					'	</div>' +
					'</div>'
				);
			},
		}
	}

	const chartSize = Math.max(sortedSentimentsByStore.length > 1 ? 60 * (sortedSentimentsByStore.length - 1) : 0, 260)

	return <div className='store-ranking-section'>
		<SectionTitle
			help={[t('keywordAnalysisSection.distributionByStore.help1'), t('keywordAnalysisSection.distributionByStore.help2')]}
			title={t('keywordAnalysisSection.distributionByStore.title')}
			name={'distribution-by-store'}
		/>
		<div className="d-flex align-items-center mb-2">
			<div>{t('keywordAnalysisSection.distributionByStore.sortBy.label')}</div>
			<MDBInput
				type="radio"
				id='positivityRate'
				name='positivityRate'
				label={t('keywordAnalysisSection.distributionByStore.sortBy.positivityRate')}
				checked={sortBy === 'positivityRate'}
				onClick={() => setSortBy('positivityRate')}
				labelClass='fontSize-12px'
			/>
			<MDBInput
				type="radio"
				id='totalMentions'
				name='totalMentions'
				label={t('keywordAnalysisSection.distributionByStore.sortBy.totalMentions')}
				checked={sortBy === 'totalMentions'}
				onClick={() => setSortBy('totalMentions')}
				labelClass='fontSize-12px'
			/>
		</div>

		<div className="scrollbar scrollbar-secondary mx-auto" style={{ height: '640px', overflowX: 'hidden', overflowY: 'auto' }}>
			<ReactApexChart options={apexOptions} series={apexSeriesGroups[sortBy].series} type="bar" height={chartSize} />
		</div>

	</div>

}

KeywordsDistributionByStores.translations = {
	...translations
};

export default translate(KeywordsDistributionByStores);
