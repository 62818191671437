import React from 'react';
import {Alert, Row} from "react-bootstrap";
import PackPurchaseButton from '../../commons/components/mercadoPago/PackPurchaseContainer';
import {translate} from 'react-i18next';

const AlertPurchasePack = ({selectedCompany, admin, t}) => {
  
  const mobile = window.innerWidth < 992;
  let info;
  if (mobile) {
    info = 	<a rel="noopener noreferrer" href="https://help.botbit.io/es/articles/4384968-emails-incluidos-en-tu-plan-y-packs" target="_blank">{t("panels.emailActivity.alert.helpMobile")}</a>
  }
  else {
    info = <span>{t("panels.emailActivity.alert.help_1")}<a rel="noopener noreferrer" href="https://help.botbit.io/es/articles/4384968-emails-incluidos-en-tu-plan-y-packs" target="_blank">{t("panels.emailActivity.alert.help_2")}</a></span>
  }
  return (
  <Row>
    <div className="col-12">
      <Alert variant="info">
        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div className="word-wrap-cs col-md-8 col-xs-12" style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', whiteSpace: (mobile && 'pre-line') || ''}}>
	  	{t("panels.emailActivity.alert.purchase")}<br/>
      {info}
          </div>
          <div className="col-md-4 pl-lg-1 pl-sm-0 col-xs-12" style={{textAlign: 'center'}}>
            <PackPurchaseButton
              selectedCompany={selectedCompany}
              admin={admin}/>
          </div>
        </Row>
      </Alert>
    </div>
  </Row>
)};

export default translate('common')(AlertPurchasePack)
