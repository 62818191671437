export const translations = {
    add: {
        es: "Conectar",
        en: "Connect"
    },
    settings: {
        es: "Ajustes",
        en: "Settings"
    },
    seeConnections: {
        es: "Ver conexiones",
        en: "Connections"
    },
    connectWith: {
        es: 'Conectar con',
        en: 'Connect with'
    }
}