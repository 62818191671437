import React, { Component } from "react";
import { translate } from "react-i18next";
import { connect } from 'react-redux';
import { saveReplyTemplate, deleteReplyTemplate, getFirstHundredReplyTemplates } from '../../api/feedbackApi';
import ReplyTemplates from "./ReplyTemplates";

class ReplyTemplatesContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (<>
            {<ReplyTemplates
                t={this.props.t}
                getReplyTemplates={getFirstHundredReplyTemplates}
                onSaveReplyTemplate={saveReplyTemplate}
                onDeleteReplyTemplate={deleteReplyTemplate}
                admin={this.props.admin}
                availableCompanies={this.props.availableCompanies}
                selectedCompany={this.props.selectedCompany}
            />}
        </>)
    }
}

function mapStateToProps(state) {
	return {
        admin: state.login.admin,
        availableCompanies: state.application.availableCompanies,
        selectedCompany: state.application.selectedCompany
	}
}

export default connect(mapStateToProps)(translate('common')(ReplyTemplatesContainer))