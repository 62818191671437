import React, { useState } from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReplyTemplateEditModal from './ReplyTemplateEditModal';
import "./settings.css";
import CollectionVisualizerContainer from "../collectionVisualizer/CollectionVisualizerContainer";
import withEventTracking from '../../config/withEventTracking';

const MySwal = withReactContent(Swal);

const ReplyTemplates = ({ t, availableCompanies, selectedCompany, admin, getReplyTemplates, onSaveReplyTemplate, onDeleteReplyTemplate, trackEvent }) => {

    const [showReplyTemplateEditModal, setShowReplyTemplateEditModal] = useState(false)

    const prefix = 'panels.feedback.settings.replies'

    const emptyReplyTemplate = () => {
        return {
            name: '',
            comment: '',
            companyId: selectedCompany.id,
            category: ''
        }
    }

    const [replyTemplate, setReplyTemplate] = useState(emptyReplyTemplate())

    const [actions, setActions] = useState(undefined)

    const openReplyTemplateEditModal = (replyTemplate, updateRow) => {
        setActions({ updateRow: updateRow })
        setReplyTemplate(replyTemplate)
        setShowReplyTemplateEditModal(true)
    }

    const openReplyTemplateAddModal = (updateRow) => {
        setActions({ updateRow: updateRow, action: 'append' })
        setReplyTemplate(emptyReplyTemplate())
        setShowReplyTemplateEditModal(true)
    }

    const openReplyTemplateDeleteModal = (replyTemplate, updateRow) => {
        MySwal.fire({
            title: t(`${prefix}.delete.title`),
            text: t(`${prefix}.delete.text`),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t(`${prefix}.delete.confirm`),
            cancelButtonText: t(`${prefix}.delete.cancel`),
            confirmButtonColor: '#1ab394'
        }).then(result => {
            if (result.value) {
                if (onDeleteReplyTemplate) {
                    onDeleteReplyTemplate(replyTemplate, selectedCompany.id).then((response) => {
                        if (response && response.data && response.data.data && response.data.data.length > 0) {
                            openDisabledRulesWarning(response.data.data)
                        }
                        updateRow(replyTemplate, 'refresh')
                        trackEvent('reply_template_deleted')
                    })
                }
            }
        });
    }

    const openDisabledRulesWarning = (disabledRules) => {
        MySwal.fire({
            title: t(`${prefix}.delete.disabledrules.title`),
            text: t(`${prefix}.delete.disabledrules.text`) + disabledRules,
            type: 'warning',
            showCancelButton: false,
            confirmButtonText: t(`${prefix}.delete.disabledrules.ok`),
            confirmButtonColor: '#1ab394'
        });
    }

    const canEdit = (row) => {
        return admin && admin.operations.includes("REPLY_TEMPLATES__EDIT") && (!row || !row.isExternal)
    }

    const canDelete = (row) => {
        return admin && admin.operations.includes("REPLY_TEMPLATES__DELETE") && (!row || !row.isExternal)
    }

    const rowActions = (row, updateRow) => {

        const actions = []

        if (canEdit(row)) {
            actions.push({
                key: 1,
                color: "",
                icon: "edit",
                label: t(`${prefix}.edit.title`),
                onClick: () => openReplyTemplateEditModal(row, updateRow)
            })
        }

        if (canDelete(row)) {
            actions.push({
                key: 2,
                color: "",
                icon: "trash",
                label: t(`${prefix}.delete.title`),
                onClick: () => openReplyTemplateDeleteModal(row, updateRow)
            })
        }
        return actions
    }

    const headerActions = (updateRow) => {
        return canEdit() ? [{
            key: 1,
            icon: "plus",
            label: t(`${prefix}.add.title`),
            primary: true,
            onClick: () => openReplyTemplateAddModal(updateRow)
        }] : []
    }

    const columns = [
        // for BIG screens: greater than md
        {
            title: 'Nombre',
            dataField: 'name',
            className: 'd-none d-md-block',
            size: 2
        },
        {
            title: 'Contenido',
            dataField: 'comment',
            className: 'd-none d-md-block text-left text-truncate'
        },
        {
            title: 'Categoría',
            dataField: 'category',
            className: 'd-none d-md-block text-left text-truncate',
            size: 2
        },
        {
            title: 'Aprobación',
            dataField: 'externalState',
            className: 'd-none d-md-block text-left text-truncate',
            size: 2
        },
        // for SMALL screens: lower than md
        {
            dataField: 'name',
            isHeader: true,
            className: 'd-md-none row-title'
        },
        {
            dataField: 'comment',
            className: 'd-md-none text-truncate'
        },
        {
            dataField: 'category',
            className: 'd-md-none text-truncate'
        },
        {
            dataField: 'externalState',
            className: 'd-md-none text-truncate'
        }
    ]

    const getDefaultFilterValues = () => []

    const getData = () => {
        return getReplyTemplates(selectedCompany.id)
    }

    return (<div id="settings">

        <ReplyTemplateEditModal
            showModal={showReplyTemplateEditModal}
            setShowModal={setShowReplyTemplateEditModal}
            replyTemplate={replyTemplate}
            onSaveReplyTemplate={onSaveReplyTemplate}
            actions={actions}
        />

        <MDBContainer className="mt-3">
            <CollectionVisualizerContainer
                title={t(`${prefix}.title`)}
                getData={(page, order, filters) => getData()}
                columns={columns}
                pagination={false}
                rowActions={rowActions}
                headerActions={headerActions}
                defaultFilters={getDefaultFilterValues()}
                alwaysFloatingFilter={true}
                filtersTitle={t('panels.feedback.settings.selectCompany')}
                showFiltersInfo
                expandRow={(active, row, onRowChanged) => {
                    return (<MDBContainer>
                        <MDBRow><MDBCol className="m-3">{row.comment}</MDBCol></MDBRow>
                    </MDBContainer>)
                }}
                availableCompanies={availableCompanies}
                selectedCompany={selectedCompany}
            />
        </MDBContainer>
    </div>)

}

export default withEventTracking(ReplyTemplates)
