import { HeaderAlert } from 'botbit-ui-components';
import React, { useState, useEffect } from 'react';
import { getHostedPage, markSubscriptionAsProcessing } from '../../../../api/freeTrialApi'
import { getTranslationFunction, getCurrentLocale } from "../../../../config/translations/translate";
import { translations } from "../../../../config/translations/ChargeBeeHeader"
import moment from 'moment/min/moment-with-locales';
import "./ChargeBeeHeader.scss"
import deepCopy from "../../../../commons/utils/deepCopy";

const ChargeBeeHeader = ({ company, companyChange }) => {
	const [showModal, setShowModal] = useState(true);

	const t = getTranslationFunction(translations);
	const subscriptionId = (company && company.companySubscription && company.companySubscription.id) ? company.companySubscription.id : null;

	useEffect(() => {
		//console.log("INICIALIZANDO CHARGEBEE con company: ", company)
		if (company.companySubscription && window.Chargebee) {
			window.Chargebee.init({
				site: company.companySubscription.site,
			});
		}
		// const cbPortal = chargebeeInstance.createChargebeePortal();
	}, [subscriptionId])

	if (!company.companySubscription || company.companySubscription.status !== "IN_TRIAL" || !showModal)
		return null;

	if (company.companySubscription.isProcessingCheckout)
		return null;

	if (window.location.pathname==='/chat')
		return null;

	function getHostedPageWrapper() {
		var result = new Promise(
			function (resolve, reject) {
				getHostedPage(company.companySubscription.id, "professional_" + company.companySubscription.currency.toLowerCase() + "_1").then((result) => {
					//console.log(result.data.data.hosted_page);
					resolve(result.data.data.hosted_page);
				}).catch((error) => {
					reject(error);
				})
			}
		)
		return result;
	}

	moment.locale(getCurrentLocale())
	const endDate = moment(company.companySubscription.trialExpirationTimestamp).format("LL");
	const plan = company.plans.filter((plan) => plan.type === "PLAN")
	const planName = plan[0].name;
	return (
		<div className="chargebee-header-container">
			<HeaderAlert
				type="warning"
				action={() => (
					<>
						{t("trialMessage", { planName: planName, expirationDate: endDate })} &nbsp;&nbsp;
						<button
							className={`link-like-btn ${(window.innerWidth < 992 && 'padding-5px') || ''}`}
							style={{ color: "#337ab7", cursor: "pointer", textDecoration: "none" }}
							onClick={() => {
								var chargebeeInstance = window.Chargebee.getInstance();
								chargebeeInstance.openCheckout({
									hostedPage: getHostedPageWrapper,
									loaded: function () {
										// will be called once checkout page is loaded
										//console.log("loaded");
										const chargeBeeModal = document.querySelector("div#app");
										if (chargeBeeModal) {
											chargeBeeModal.style.marginBottom = 0;
										}
									},
									error: function (error) {
										chargebeeInstance.closeAll();
										// will be called if the promise passed causes an error
										//console.log("error", error);
									},
									success: function (hostedPageId) {
										// will be called when a successful checkout happens.
										// console.log("success", hostedPageId);
										/*setTimeout(
											function () {
												window.location.reload();
											}, 2000);*/
										markSubscriptionAsProcessing(company.companySubscription.id).then(
											(result) => {
												var newCompany = deepCopy(company);
												newCompany.companySubscription = result.data.data;
												companyChange(newCompany);
											}
										);
									},
									close: () => {
										// will be called when the user closes the checkout modal box
										// console.log("checkout new closed");
									},
									step(step) {
										// will be called for each step involved in the checkout process
										//console.log("checkout", step);
									}
								});
							}
							}>
							{t("buyMessage")}
						</button>
					</>
				)}
				toggle={() => setShowModal(false)}>
				{/* <div style={(window.innerWidth < 992 && { padding: '5px' }) || {}}>Chargebee</div> */}
			</HeaderAlert>
		</div>
	);
}

export default ChargeBeeHeader;