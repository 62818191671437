export const SocialLocationPostCallToActionType = {
    BOOK: "BOOK",
    ORDER: "ORDER",
    SHOP: "SHOP",
    LEARN_MORE: "LEARN_MORE",
    SIGN_UP: "SIGN_UP",
    CALL: "CALL",
    ACTION_TYPE_UNSPECIFIED: "ACTION_TYPE_UNSPECIFIED",
    STANDARD: "STANDARD",
};

export const SocialLocationPostTopicType = {
    EVENT: "EVENT",
    OFFER: "OFFER",
    STANDARD: "STANDARD",
};