import React from 'react'
import i18next from 'i18next';
import CampaignButton from '../CampaignButton/CampaignButton'
import CampaignBenefit from '../CampaignBenefit/CampaignBenefit'
import CampaignSegment from '../CampaignSegment/CampaignSegment'
import CampaignFromAddress from '../CampaignFromAddress/CampaignFromAddress'
import CampaignReplyTo from '../CampaignReplyTo/CampaignReplyTo';
import CampaignCompany from '../CampaignCompany/CampaignCompany';
import CampaignValidity from '../CampaignValidity/CampaignValidity';
import CampaignSubject from '../CampaignSubject/CampaignSubject'
import CampaignMessage from '../CampaignMessage/CampaignMessage'
import CampaignName from '../CampaignName/CampaignName';
import Segments from "../../segments/Segments/Segments";
import { MediaGallery, Select } from 'botbit-ui-components'
import { FastField, Field } from 'formik';
import CronGenerator from '../MaximalEditor/CronGenerator';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment'
import TimePicker from 'rc-time-picker'
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'rc-time-picker/assets/index.css';
import {envConfig} from '../../../config/config'
import {MDBInput} from 'mdbreact'
import className from 'classnames'
import { useToken } from "../../../commons/utils/token.js"


var cronstrue = require('cronstrue/i18n');

const fromEmailContainer = ({isEditing, formRef, t, campaignBeingEdited, key}) => {
    return <CampaignFromAddress 
            key={key}
            t={t} 
            formik={formRef.current}
            isEditing={isEditing}
            campaign={campaignBeingEdited}/>
}
const replyToEmailContainer = ({isEditing, formRef, t, campaignBeingEdited, key}) => {
    return <CampaignReplyTo 
            key={key}
            t={t} 
            formik={formRef.current}
            isEditing={isEditing}
            campaign={campaignBeingEdited}/>
}

const responseEmailsAndDisplayContainer = ({isEditing, formRef, t, campaignBeingEdited, key}) => {
    return [fromEmailContainer({t,isEditing, formRef, campaign: campaignBeingEdited, key}),
            fromDisplayContainer({t, isEditing, formRef, key: (key + "brother")}),
            replyToEmailContainer({t, isEditing, formRef, key: (key + "brother2")})]
}

const messageAndSubjectContainer = ({isEditing, t, productCategoriesArray, key, formRef}) => {
    return [
        messageContainer({isEditing, t, productCategoriesArray, key, formRef}),
        subjectContainer({isEditing, t, productCategoriesArray, key:(key+"brother")})
    ]
}

const companyContainer = ({t, key}) => <CampaignCompany 
    key={key} 
    t={t} >    
</CampaignCompany>

const nameContainer = ({t, key}) => <CampaignName
                                                key={key}
                                                // isEditing={isEditing} 
                                                t={t}
                                                ></CampaignName>

const validityContainer = ({campaignBeingEdited, isEditing, t, key}) => <CampaignValidity 
    campaign={campaignBeingEdited}
    isEditing={isEditing} 
    key={key}
    t={t} >    
</CampaignValidity>

const segmentContainer = ({isEditing, t, campaignBeingEdited, availableStores, storesActivableSmartCampaign, formRef, key}) => {
    return <CampaignSegment t={t}
            isEditing={isEditing}
            key={key}
            campaign={campaignBeingEdited}
            availableStores={availableStores.filter(as => as.companyId === campaignBeingEdited.companyId)}
            storesActivableSmartCampaign={storesActivableSmartCampaign}
            formik={formRef.current}
            />
}

const buttonContainer = (props) => {
    return <CampaignButton {...props} />
}

const sendDatesContainer = ({campaignBeingEdited, 
                            key, 
                            isEditing, 
                            t, 
                            deliverySettingsOptions, 
                            TRANSLATIONS_PREFIX,
                            formRef, 
                            deliverySettingSelected, 
                            validCronExpression, 
                            setValidCronExpression,
                            renderDeliveryOptionCard}) => {
    if(isEditing){
        const setSendOnceOnValue = (modificator, value) => {
            const { sendOnceOn } = formRef.current.state.values.campaign;
            value = moment(value)
            let currentSendOnceOn = moment(sendOnceOn || undefined)
            let day = currentSendOnceOn.year() + '-' + (currentSendOnceOn.month() + 1) + '-' + currentSendOnceOn.date()
            let time = (currentSendOnceOn.hour() + ":" + currentSendOnceOn.minute() + ":00")
            switch (modificator) {
                case 'day':
                    day = value.year() + '-' + (value.month() + 1) + '-' + value.date()
                    break;
                case 'time':
                    time = value.hour() + ":" + value.minute() + ":00"
                    break;
                default:
                    break;
            }
            let newDate = Date.parse(day + ' ' + time);
                formRef.current.setFieldValue('campaign.sendOnceOn', newDate)
        }
        
        return(
            <div className="optionsCards">
                {renderDeliveryOptionCard(false, deliverySettingsOptions.noProgram)}
                {renderDeliveryOptionCard(true, deliverySettingsOptions.sendOnce, 
                    <Field
                    name={'campaign.sendOnceOn'}
                    render={(props) => {
                        const hasError = Object.keys(props.form.errors).length > 0 &&
                                        Object.keys(props.form.errors.campaign).length > 0 && 
                                        props.form.errors.campaign.sendOnceOn !== undefined
                            return (
                                <div className={className({"sendOnceOnContainer": true, "hasError": hasError})}>
                                    <span>{t(TRANSLATIONS_PREFIX + "the")}</span>
                                    <DayPickerInput
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        format="LL"
                                        value={props.field.value ? moment(props.field.value).toDate() : moment().add(1, 'h').toDate()}
                                        placeholder={`${formatDate(new Date(), 'LL', i18next.languages[0].slice(0,2))}`}
                                        dayPickerProps={{
                                            locale: i18next.languages[0].slice(0,2),
                                            localeUtils: MomentLocaleUtils,
                                        }}
                                        onDayChange={(value) => {
                                            setSendOnceOnValue("day", value)
                                        }}
                                    />
                                    <span>{t(TRANSLATIONS_PREFIX + 'at')}</span>
                                    <TimePicker 
                                        value={props.field.value ? moment(props.field.value) : moment().add(1, 'h')} 
                                        showSecond={false} 
                                        onChange={(value) => {
                                            setSendOnceOnValue("time", value)
                                        }}
                                        allowEmpty={false}
                                    />
                                    {hasError && <span className="error-message">{props.form.errors.campaign.sendOnceOn}</span>}
                                    {usersNotReelegibleDaysQtty({t: t})}
                                </div>
                            )}}
                    />
                )}
                {renderDeliveryOptionCard(true, deliverySettingsOptions.sendPeriodically, 
                        <div className="sendPeriodicallyCollapsable">
                            <Field
                            name={'campaign.cronExpression'}
                            render={(props) => <CronGenerator 
                                t={t} 
                                showDescription 
                                cronExpression={campaignBeingEdited.cronExpression} 
                                onChange={(value, valid) => {
                                    if(value.cronExpression !== formRef.current.state.values.campaign.cronExpression
                                        && deliverySettingSelected === deliverySettingsOptions.sendPeriodically){
                                        formRef.current.setFieldValue(props.field.name, value.cronExpression)
                                    }
                                    if(valid !== validCronExpression){
                                        setValidCronExpression(valid)
                                    }
                                }}
                                />}
                            />
                            {activeTo({t: t})}
                            {usersNotReelegibleDaysQtty({t: t})}
                        </div>
                    )}
            </div>
        )
    } else {
        let expression = "";
        if(campaignBeingEdited && campaignBeingEdited.cronExpression){
            expression = cronstrue.toString(campaignBeingEdited.cronExpression
                , {verbose:true, locale: i18next.languages[0].slice(0,2)},
                { dayOfWeekStartIndexZero: false });
        }
    
        return <span key={key} className="sendDatesContainer">
            {expression}
        </span>
    }
}

const activeTo = ({t}) => {
    const CRON_TRANSLATIONS_PREFIX = "panels.campaigns.maximalEditor.cron."
    const getFormattedDate = (props, modificator, value) => {
        value = moment(value)
        let currentActiveFrom = moment(props.field.value || undefined)
        let day = currentActiveFrom.year() + '-' + (currentActiveFrom.month() + 1) + '-' + currentActiveFrom.date()
        let time = (currentActiveFrom.hour() + ":" + currentActiveFrom.minute() + ":00")
        switch (modificator) {
            case 'day':
                day = value.year() + '-' + (value.month() + 1) + '-' + value.date()
                break;
            case 'time':
                time = (value.hours() + ":" + value.minutes() + ":00")
                break;
            default:
                break;
        }
        let newDate = Date.parse(day + ' ' + time);
        return newDate;
    }
    let isMobile = (window.innerWidth < 576)
    const dayPicker = (props) => <DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        format="LL"
        value={props.field.value ? moment(props.field.value).toDate() : moment().add(1, 'h').toDate()}
        placeholder={`${formatDate(new Date(), 'LL', i18next.languages[0].slice(0,2))}`}
        dayPickerProps={{
            locale: i18next.languages[0].slice(0,2),
            localeUtils: MomentLocaleUtils,
        }}
        onDayChange={(value) => {
            props.form.setFieldValue(props.field.name, getFormattedDate(props, 'day', value))
        }}
    />
    const timePicker = (props) => <TimePicker 
        allowEmpty={false}
        value={props.field.value ? moment(props.field.value) : moment().add(1, 'h')} 
        showSecond={false}
        onChange={(value) => {
            props.form.setFieldValue(props.field.name, getFormattedDate(props, 'time', value))
       }}
    />
    return(
            <Field
            name={'campaign.activeFrom'}
            render={(props) => {
                let hasError = Object.keys(props.form.errors).length > 0 &&
                                        Object.keys(props.form.errors.campaign).length > 0 && 
                                        props.form.errors.campaign.activeFrom !== undefined && 
                                        props.form.errors.campaign.activeFrom
                return(
                    <div className={"activeToContainer" + (hasError ? ' hasError' : '')}>
                        {!isMobile ?
                        <>
                            {t(`${CRON_TRANSLATIONS_PREFIX}from`)}
                            {dayPicker(props)}
                            {t(`${CRON_TRANSLATIONS_PREFIX}at`)}
                            {timePicker(props)}
                        </>
                        :
                        <div className="mt-2 mobileFromToCron">
                            <div>
                                {t(`${CRON_TRANSLATIONS_PREFIX}from`)}
                                {dayPicker(props)}
                            </div>
                            <div>
                                {t(`${CRON_TRANSLATIONS_PREFIX}at`)}
                                {timePicker(props)}
                            </div>
                        </div>}
                        {hasError && <span className="error-message">{hasError}</span>}
                    </div>
                )
            }}
            />
    )
}

const promoCodesContainer = ({TRANSLATIONS_PREFIX, renderButton, promocodes, t}) => {
    return(
        <div className="promocodesContainer mt-5">
            <span className="deliveryTitle">{t(TRANSLATIONS_PREFIX + "promocodes")}</span>
            <div className="promocodeCard">
                <div>{renderButton("clipboard", false)}</div>
                <Field
                name={"campaign.promoCodeTag"}
                render={(props) => 
                    <Select  
                        className="promocodeSelect"
                        options={promocodes}
                        getValue={(val) => {
                            props.form.setFieldValue(props.field.name, val[0])
                        }}/>
                    }
                />
            </div>
        </div>
    )
}

const benefitContainer = ({showLabels, isEditing, t, campaignBeingEdited, formRef, productCategoriesArray, withLabel, key}) => {
    return <CampaignBenefit 
            key={key}
            data={campaignBeingEdited}
            t={t}
            isEditing={isEditing}
            productCategories={productCategoriesArray}
            formik={formRef.current}
            showLabels={showLabels}
            withLabel={withLabel}
            />
}

const subjectContainer = ({isEditing, t, productCategoriesArray, key}) => {
    return <CampaignSubject
            t={t}
            key={key}
            productCategories={productCategoriesArray}
            isEditing={isEditing}/>
}

const onMediaGallerySelect = (props, res, formRef) => {
    let image;
    if(res.length > 0){
        image = res[0].image; 
    }
    props.form.setFieldValue(props.field.name, image)
    setTimeout(() => formRef.current.submitForm(formRef.current.state.values), 0)
}

const imageIndexInJson = (imageArray, file) => {
    var encodedFile = encodeURI(file);
    var i;
    if(imageArray){
        for (i = 0; i < imageArray.length; i++) {
            if(imageArray[i].src === encodedFile){
                return [i];
            }
          }	
    }
    return [];
}

const imageContainer = ({t, campaignBeingEdited, formRef, images, companyId, key, show}) => {
    let mainImageUrl = campaignBeingEdited.mainImage ? campaignBeingEdited.mainImage : envConfig.staticImgUrl + "/stores/no-image.png"
    let defaultSelected = ((campaignBeingEdited.mainImage && images) ? (imageIndexInJson(images, campaignBeingEdited.mainImage.toString())) : [])
    const token = useToken();
    return( show === undefined || show ?
        <div key={key} className="campaignImageContainer">
                <Field
                name="campaign.mainImage"
                render={(props) => {
                    const hasError = Object.keys(props.form.errors).length > 0 &&
                                        Object.keys(props.form.errors.campaign).length > 0 && 
                                        props.form.errors.campaign.mainImage !== undefined
                    return  <div className={(hasError ? 'hasError' : '')}>
                        <img className={"card-img-top"} style={{width: '105px',
                                                                float: 'right',
                                                                marginTop: '-48px',
                                                                height: '59px'}}
                            src={ props.field.value ? props.field.value : mainImageUrl } alt="" /> 
                        <MediaGallery 
                            isNested
                            images={images}
                            defaultSelected={defaultSelected}
                            t={t}
                            onSelect={(res) => {
                                onMediaGallerySelect(props, res, formRef)
                            }}
                            showPreview={false}
                            multipleSelection={false}
                            triggerMode="button"
			    token={token}
                            triggerIcon={props.field.value ? "pen" : "plus"}
                            labels={{
                                "openModalTrigger": "",
                                "uploadTabTitle": t("components.mediaGallery.upload"),
                                "selectTabTitle": t("components.mediaGallery.select"),
                                "clickOrDragHere": t("components.mediaGallery.clickOrDragHere"),
                                "ok": t("components.mediaGallery.ok"),
                                "upload": t("components.mediaGallery.upload")
                            }}
                            postUrl={
                                envConfig.servicesUrl+"/upload" 
                                    + (companyId ? "?companyId="+ companyId : '')
                            }
                            />
                            {hasError && <span className="error-message">{props.form.errors.campaign.mainImage}</span>}
                    </div>
            }} /> 
        </div> : null
    )
}

const usersNotReelegibleDaysQtty = ({t, key}) => {
    let TRANSLATIONS_PREFIX = 'panels.campaigns.minimalEditor.';
    return <div key={key} className="usersNotReelegibleDaysQttyContainer">
                <FastField
                    name="campaign.usersNotReelegibleDaysQtty"
                    render={(props) =>{
                    const hasError = Object.keys(props.form.errors).length > 0 &&
                                    Object.keys(props.form.errors.campaign).length > 0 && 
                                    props.form.errors.campaign.usersNotReelegibleDaysQtty !== undefined
                    return <>
                        <span className="usersNotReelegibleDaysQttyLabel">
                            {t(TRANSLATIONS_PREFIX + "usersNotReelegibleDaysQtty")}
                        </span>
                        <div className={className({"usersNotReelegibleDaysQttyInputContainer": true, "hasError": hasError})} >
                            <MDBInput 
                            onChange={(e) => {
                                props.form.setFieldValue(props.field.name, e.target.value)
                            }} 
                            className="usersNotReelegibleDaysQttyInput" 
                            type="number" 
                            value={props.field.value}
                            />
                            <span> {t(TRANSLATIONS_PREFIX + "days")}</span>
                            {hasError && <span className="error-message">{props.form.errors.campaign.usersNotReelegibleDaysQtty}</span>}
                        </div>
                    </>}
                    }
                />
            </div>
}

const fromDisplayContainer = ({t, isEditing, formRef, key}) => {
    let TRANSLATIONS_PREFIX = 'panels.campaigns.minimalEditor.';
    return isEditing ? <div key={key} className="fromDisplayContainer">
                <FastField
                    name="campaign.fromDisplay"
                    render={(props) =>{
                    const hasError = Object.keys(props.form.errors).length > 0 &&
                                    Object.keys(props.form.errors.campaign).length > 0 && 
                                    props.form.errors.campaign.fromDisplay !== undefined
                    return <div className={className({"fromDisplayInputContainer": true, "hasError": hasError})} >
                            <span className="fromDisplayLabel" style={{fontWeight: '600'}}>
                                {t(TRANSLATIONS_PREFIX + "fromDisplay")}
                            </span>
                                <MDBInput 
                                onChange={(e) => {
                                    props.form.setFieldValue(props.field.name, e.target.value)
                                }} 
                                className="fromDisplayInput" 
                                value={props.field.value}
                                />
                                {hasError && <span className="error-message">{props.form.errors.campaign.fromDisplay}</span>}
                        </div>
                        
                    }}
                />
            </div>
            :
            <div key={key}>
                <span className="fromDisplayLabel">{t(TRANSLATIONS_PREFIX + "fromDisplay")}</span>
                <FastField
                    name="campaign.fromDisplay"
                    render={(props) => {
                        return <span>{props.field.value}</span>
                    }}
                />
            </div>
}

const messageContainer = ({isEditing, t, productCategoriesArray, key, formRef}) => {
    return <CampaignMessage
            key={key}
            t={t}
            productCategories={productCategoriesArray}
            isEditing={isEditing}
            formRef={formRef}/>
}

const segmentsListContainer = ({formRef, show, onRowClick, t, isMobile, TRANSLATIONS_PREFIX}) => {
    let campaign = formRef.current.state.values.campaign;
    return <Segments 
                t={t}
                title={!isMobile ? t(TRANSLATIONS_PREFIX + 'selectSegment') : null}
                onRowClick={onRowClick}
                isSelectable
                selectedId={campaign.segment && campaign.segment.id}
                show={show}
                canDelete={false}
                companyId={campaign.company.id}
                canSelectCompany={false}
                canViewUsers={false}
                canViewDescription={false}
                mustTargetOnlyAdminStores={true}
                />
}



// const tabs = {
//     emailContent: "emailContent",
//     segment: "segment",
//     deliverySettings: "deliverySettings"
// }

const containers = {
    company: {
        name: "company", 
        renderFunction: companyContainer,
        isEditable: true
    },
    benefit: {
        name: "benefit", 
        renderFunction: benefitContainer,
        isEditable: true,
        fields: ["benefitTypeId", "productCategory", "customProductName", "discount"],
    },
    segment: {
        name: "segment", 
        renderFunction: segmentContainer,
        isEditable: true,
        fields: ["segment"],
    },
    fromEmail: {
        name: "fromEmail", 
        renderFunction: fromEmailContainer,
        isEditable: true,
        fields: ["fromAddress"],
    },
    replyToEmail: {
        name: "replyToEmail",
        renderFunction: replyToEmailContainer,
        isEditable: true,
        fields:["replyToAddress"]
        
    },
    responseEmailsAndDisplay: {
        name: "responseEmailsAndDisplay",
        renderFunction: responseEmailsAndDisplayContainer,
        isEditable: true,
        fields: ["fromAddress", "replyToAddress"]
    },
    messageAndSubject: {
        name: "messageAndSubject",
        renderFunction: messageAndSubjectContainer,
        isEditable: true
    },
    message: {
        name: "message", 
        renderFunction: messageContainer,
        isEditable: true,
        fields: ["benefitMessage"],
    },
    subject: {
        name: "subject", 
        renderFunction: subjectContainer,
        isEditable: true,
        fields: ["subject"],
    },
    image: {
        name: "image", 
        renderFunction: imageContainer,
        isEditable: false,
        fields: ["mainImage"]
    },
    button: {
        name: "button", 
        renderFunction: buttonContainer,
        isEditable: true
    },
    sendDates: {
        name: "sendDates", 
        renderFunction: sendDatesContainer,
        isEditable: false,
        fields: ["sendOnceOn", "cronExpression", "promoCodesTag", "activeFrom"]
    },
    validity: {
        name: "validity",
        renderFunction: validityContainer,
        isEditable: true,
        fields: ['wtValidTo', 'wtInterval']
    },
    name: {
        name: "name",
        renderFunction: nameContainer,
        isEditable: true,
        fields: ['name'],
    },
    usersNotReelegibleDaysQtty: {
        name: "usersNotReelegibleDaysQtty",
        renderFunction: usersNotReelegibleDaysQtty,
        isEditable: true,
        fields: ["usersNotReelegibleDaysQtty"],
    },
    fromDisplay: {
        name: "fromDisplay", 
        renderFunction: fromDisplayContainer,
        isEditable: true,
        fields: ["fromDisplay"],
    },
    promoCodes: {
        name: 'promoCodesTag',
        renderFunction: promoCodesContainer,
        isEditable: true,
        fields: ['promoCodesTag']
    },
    segmentsList: {
        name: "segmentsList",
        renderFunction: segmentsListContainer,
        isEditable: true,
        fields: ["segment"]
    }
}
export { containers }
