export const translations = {
    date: {
        es: "Fecha",
        en: "Date"
    },
    store: {
        es: "Tienda",
        en: "Store"
    },
    socialSource: {
        es: "Sitio",
        en: "Social Source"
    },
    score: {
        es: "Puntaje",
        en: "Score"
    },
    activity: {
        es: "Actividad",
        en: "Activity"
    },
    reviews: {
        es: "Reseñas",
        en: "Reviews"
    },
    reviewsTotal: {
        es: "Total de reseñas",
        en: "Total reviews"
    },
    averageScore: {
        es: "Puntaje promedio",
        en: "Average score"
    },
    totalScore: {
        es: "Total por puntaje",
        en: "Total by score"
    },
    totalBySource: {
        es: "Total por sitio",
        en: "Total by source"
    },
    comparison: {
        es: "Comparación",
        en: "Comparison"
    },
}