import axiosClient from './axiosClient'

export const getOauthClientDetails = (companyId, errorHandler = undefined) => {
    return axiosClient.get(`/security/oauth-client-details?companyId=${companyId}`, { errorHandler: errorHandler });
}

export const createOauthClientDetails = (email, companyId) => {
    return axiosClient.post(`/security/oauth-client-details`, { email: email, companyId: companyId });
}

export const resetOauthClientDetails = (email, clientId) => {
    return axiosClient.post(`/security/oauth-client-details/reset`, { email: email, clientId: clientId });
}

export const getTokenSSO = (audience, companyId) => {
    return axiosClient.get(`/security/sso?audience=${audience}&companyId=${companyId}`);
}