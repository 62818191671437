import React from 'react';
import { envConfig } from '../../config/config';
import "./PodiumEmpty.scss"

const PodiumEmpty = ({ t }) => {
    return (
        <div className="podium-empty-state">
            <img src={envConfig.staticImgUrl + "no-results.png"} alt="Empty state" />
            <h2>{t("emptyStateMsg")}</h2>
        </div>
    );
}

export default PodiumEmpty;