import React, { useEffect, useState } from "react";
import { CollectionVisualizer, Modal, ModalBody, Select } from "botbit-ui-components";
import { getAccount, matchAccount } from "../../../api/sourceApi";
import { MDBIcon, MDBBtn } from "mdbreact";
import "./MatchSourceWithStores.scss";
import translate from "../../../config/translations/translate";
import { translations } from "../../../config/translations/integrations/MatchSourceWithStores";
import _ from "lodash";
import { useCompanyAvailableStores } from "../../../commons/utils/useCompanyAvalaibleStores";
import {MatchingType} from "../IntegrationsHelper";
import {setLoading} from "../../../commons/components/application/miscActions";
import {useDispatch} from "react-redux";

const MatchSourceWithStores = ({ id, t, onFinish }) => {
    const dispatch = useDispatch();

    const [account, setAccount] = useState(null);
    const isMobile = (window.innerWidth < 992);
    const [elementToMatchEditionIndex, setElementToMatchEditionIndex] = useState(false);
    const availableStores = useCompanyAvailableStores().filter(s=>s.status==='ACTIVE');
    const [selectedStore, setSelectedStore] = useState(false);

    const elementToMatchEditionIsOpen = typeof (elementToMatchEditionIndex) === "number";
    
    const getElementsToMatch = () => {
        if(account) {
            if(account.matchingType === MatchingType.LOCATION) {
                //ElementsToMatch are locations
                return (account.sourceLocationsToMatch);
            } else {
                //ElementsToMatch are accounts
                return ([account]);
            }
        }
    }

    const elementsToMatch = getElementsToMatch();

    useEffect(() => {
        getAccount(id).then(res => {
            const _account = res.data.data;
            _account.sourceLocationsToMatch = _account.sourceLocationsToMatch.map(
                location=>({
                    ...location,
                    showWarning: location.store ? location.store.name : undefined
                }))
            const allowedTypes =_.at(_account, "source.configuration.matching.allowedTypes")[0];
            if(allowedTypes && allowedTypes.includes(MatchingType.LOCATION)) {
                _account.matchingType = MatchingType.LOCATION;
                if(_account.sourceLocationsToMatch.length === 1){
                    setElementToMatchEditionIndex(0);
                }
            } else {
                _account.matchingType = MatchingType.ACCOUNT;
                setElementToMatchEditionIndex(0);
            }
            setAccount(_account);
        })
    }, [])

    const matchedColumn = {
        dataField: "matched",
        size: 1,
        className: "d-flex align-items-center justify-content-center p-0",
        formatter: (dataField, elementToMatch) => {
            if(!elementToMatch.isSelectable)
                return <MDBIcon size='2x'  icon="pause-circle" className="text-muted" />
            return (elementToMatch.store ?
                <MDBIcon className="completed-or-not completed" icon="check" /> :
                <MDBIcon className="completed-or-not incomplete" icon="times" />
        )}
    };

    const columnsForAccountMatching = [
        matchedColumn,
        {
            dataField: "name",
            size: 10,
            isHeader: isMobile,
            title: t("columns.name"),
            className: `text-truncate d-flex align-items-center`
        },

    ];

    const columnsForLocationMatching = [
        matchedColumn,
        {
            dataField: "name",
            size: 5,
            isHeader: isMobile,
            title: t("columns.name"),
            className: `text-truncate d-flex align-items-center`
        },
        {
            dataField: "store.name",
            size: 5,
            title: t("columns.matchedStore"),
            formatter: (dataField, elementToMatch) => {
                return <div>{elementToMatch.store ? elementToMatch.store.name : undefined}</div>
            },
            className: `text-truncate d-flex align-items-center`
        },
        // {
        //     dataField: "addressLine1",
        //     size: isMobile ? 5 : 3,
        //     title: t("columns.address"),
        //     className: `text-truncate d-flex align-items-center`,
        // },
        // {
        //     dataField: "city",
        //     size: isMobile ? 5 : 2,
        //     title: t("columns.city"),
        //     className: `text-truncate d-flex align-items-center`,
        // },
    ]

    const editElementToMatch = (elementToMatch) => {
        const elementToEdit = _.findIndex(elementsToMatch, (e => e.externalId === elementToMatch.externalId));
        if(!elementsToMatch[elementToEdit].isSelectable) return;
        setElementToMatchEditionIndex(elementToEdit);
    }

    const matchElementWithStore = (storeId, elementToMatch) => {
        const store=availableStores.find(s=>s.id===storeId);
        let _account = {...account};
        if(account.matchingType === MatchingType.ACCOUNT) {
            _account.store = {...store};
        } else {
            let sourceLocation = _account.sourceLocationsToMatch.find(s => s.externalId === elementToMatch.externalId);
            sourceLocation.store = {...store};
        }
        setAccount(_account);
        return _account;
    }
    
    const handleModalSubmit = (selectedStore, element) => {
        matchElementWithStore(selectedStore, element)
        setElementToMatchEditionIndex(false)
    }
    
    const handleSubmit = () => {
        let _account = account;
        if(elementsToMatch.length === 1){
            _account = matchElementWithStore(selectedStore, elementsToMatch[0])
        }
        dispatch(setLoading(true));
        matchAccount(_account).then(() => {
            dispatch(setLoading(false));
            onFinish(true);
        })
        .catch((error) => {
            onFinish(false, _.at(error, "response.data.message")[0]);
        });

    }

    const renderMatchOneElementForm = () => {
        const selectedElementToMatch = elementsToMatch[elementToMatchEditionIndex];
        if(!selectedElementToMatch) return  null;
        const showWarning = selectedElementToMatch.showWarning;
        if(selectedElementToMatch.store){
            const selectedElementToMatchStore = availableStores.find(s=>s.id===selectedElementToMatch.store.id);
            if(selectedElementToMatchStore && !selectedStore)
                setSelectedStore(selectedElementToMatchStore.id);
        }
        return <> 
            <h1>
            {elementToMatchEditionIsOpen && 
                <>
                    {t(`modalTitle.before`)}
                    <span className="font-weight-bold"> {elementsToMatch[elementToMatchEditionIndex].name}?</span>
                </>
            }
            </h1>
            {showWarning && <div className='text-warning'>{t(`modal.warning`)}: {showWarning}</div>}
            <Select
                selected={t("selectAStore")}
                options={availableStores.map(as => ({
                    value: as.id,
                    text: as.name,
                    checked: selectedElementToMatch.store && as.id === selectedElementToMatch.store.id
                }))}
                getValue={(newValues) => {
                    setSelectedStore(newValues[0]);
                }}
            />
        </>
    }


    const rowActions = (elementToMatch) => {
        if(!elementToMatch.isSelectable)
            return [];
        return [
            {
                key: 1,
                icon: "pen",
                onClick: () => editElementToMatch(elementToMatch)
            }
        ]
    }
    const renderMultipleElementsToMatch = () => {
        return <><CollectionVisualizer
            pagination={false}
            getData={async () => {
                return { data: { data: { items: [...elementsToMatch] } } }
            }}
            columns={account.matchingType === MatchingType.LOCATION ? columnsForLocationMatching : columnsForAccountMatching}
            rowActions={rowActions}
            showMobileColumnTitles
            onRowClick={editElementToMatch}
        />
        <Modal className="match-modal" isOpen={elementToMatchEditionIsOpen}
            toggle={() => setElementToMatchEditionIndex(false)}>
            <ModalBody>
                <MDBIcon className="cursor-pointer" icon="arrow-left" onClick={() => setElementToMatchEditionIndex(false)}></MDBIcon>
                {renderMatchOneElementForm()}
                <MDBBtn 
                    className="text-capitalize"
                    color="primary" 
                    onClick={() => handleModalSubmit(selectedStore, elementsToMatch[elementToMatchEditionIndex])}>{t("modalSubmit")}</MDBBtn>
            </ModalBody>
        </Modal></>
    }

    const renderSingleElementToMatch = () => {
        return <div>
            {renderMatchOneElementForm()}
        </div>
    }

    return <div className="match-source-with-stores">
        {elementsToMatch ? (
            elementsToMatch.length !== 1 ? 
            renderMultipleElementsToMatch():
            renderSingleElementToMatch()) 
            
            : null}
        <MDBBtn 
            className="text-capitalize"
            color="primary" 
            onClick={handleSubmit}>{t("submit")}</MDBBtn>
    </div> 
}

MatchSourceWithStores.translations = {
    ...translations,
}

export default translate(MatchSourceWithStores);
