import translate from '../../../config/translations/translate';
import React, { useState, useEffect } from 'react';
import './CaptiveGraph.css';
import { translations } from '../../../config/translations/Dashboard/CaptiveGraph';
import { Chart } from 'react-google-charts';
import LinkToHelpCenter from '../LinkToHelpCenter/LinkToHelpCenter';
import { Filters } from 'botbit-ui-components';
import { NewLoader } from '../../NewLoader/NewLoader';
import { getRegistrationMetrics } from "../../../api/dashboardApi";
import { useCompanyAvailableStores } from "../../../commons/utils/useCompanyAvalaibleStores";
import { MDBCard } from "mdbreact";
import moment from "moment"
import listToText from '../../../commons/utils/listToText';
import { RegisteredThrough } from './RegisteredThrough';
import _ from "lodash"
import useWindowDimensions from '../../../commons/utils/useWindowDimensions';

const getEmptyDataAnalysisObject = () => {
  const res = {};
  RegisteredThrough.forEach((r) => {
    res[r.id] = { maximum: 0, total: 0 };
  });
  return res;
}

const CaptiveGraph = ({ t, className, openModal, period, stores, filterValues, viewMode }) => {
  const [captiveData, setCaptiveData] = useState(null)
  const [captiveDataFiltered, setCaptiveDataFiltered] = useState(null);
  let [selectedMethod, setSelectedMethod] = useState(RegisteredThrough.map(r => r.id));
  const [aggrupationType, setAggrupationType] = useState(null);
  const [dataAnalysis, setDataAnalysis] = useState(getEmptyDataAnalysisObject());
  const { isLarge } = useWindowDimensions();

  if (viewMode) selectedMethod = filterValues.registeredThrough;

  const companyAvailableStores = useCompanyAvailableStores();

  let storesSelected = stores;
  if (!storesSelected || storesSelected.length === 0) {
    storesSelected = companyAvailableStores.map(as => as.id);
  }

  useEffect(() => {
    if (captiveData || captiveDataFiltered || aggrupationType) {
      setAggrupationType(null);
      setCaptiveData(null);
      setCaptiveDataFiltered(null);
    }
  }, [JSON.stringify(filterValues)])

  useEffect(() => {
    async function getCapitveDataFromBackend() {
      const res = await getRegistrationMetrics(storesSelected, period)
      const { dailyInformation, aggrupationType } = res.data.data;
      setAggrupationType(aggrupationType);

      if (dailyInformation) {
        let dataArray = [
          [
            '',
            ...RegisteredThrough.map(r => r.text)
          ],
        ];
        dailyInformation.forEach((item) => {
          const date = moment(item.date);
          let formattedDate = date.format("MM-YYYY");
          if (aggrupationType === "DAILY") {
            formattedDate = date.format("DD-MM");
          }
          const _item = [
            formattedDate,
            ...RegisteredThrough.map(r => item[r.eventStatsField]),
            date
          ];
          dataArray.push(_item);
        });
        let _dataAnalysis = getEmptyDataAnalysisObject();
        dataArray.slice(1).forEach(item => {
          RegisteredThrough.forEach((r, index) => {
            //Acumulo los totales
            _dataAnalysis[r.id].total += parseInt(item[index + 1]);

            //calculo los maximos
            if (item[index + 1] > _dataAnalysis[r.id].maximum) {
              _dataAnalysis[r.id].maximum = item[index + 1];
            }
          });
        });
        setDataAnalysis(_dataAnalysis);
        setSelectedMethod(RegisteredThrough.map(r => r.id).filter(r => _dataAnalysis[r].maximum > 0))
        setCaptiveData(dataArray);
      }
    }
    getCapitveDataFromBackend()
  }, [JSON.stringify(filterValues)])

  useEffect(() => {
    if (captiveData) {
      let dataArray = [...captiveData];
      if (selectedMethod) {
        const filteredArray = dataArray.map((itemArray) => {
          let selectedDataArray = [itemArray[0]];
          selectedMethod.forEach((method) => {
            let rtIndex = _.findIndex(RegisteredThrough, (r) => r.id === method);
            selectedDataArray.push(itemArray[rtIndex + 1]);
          });
          return selectedDataArray;
        });
        dataArray = filteredArray;
      }
      setCaptiveDataFiltered(dataArray);
    }
  }, [captiveData, selectedMethod]);

  const computeCurrentMaximum = (selectedMethod) => {
    const filteredMaximums = selectedMethod.map(r => dataAnalysis[r].maximum);
    return filteredMaximums.reduce((a, b) => a + b, 0) * 1.2;
  }

  const renderEmptyState = () => {
    return <div className="empty-state">
      <h1>{t("emptyState")}</h1>
    </div>
  }

  const getBarColorsForSelectedMethods = () => {
    let colorsArray = [];
    if (selectedMethod) {
      selectedMethod.forEach(method => {
        const methodFiltered = RegisteredThrough.find(r => r.id === method);
        colorsArray.push(methodFiltered && methodFiltered.color);
      })
    }
    return colorsArray;
  }

  const filtersSchema = {
    registeredThrough: {
      id: "registeredThrough",
      type: "multiselect",
      options: RegisteredThrough.map(r => ({ value: r.id, text: r.text })),
      placeholder: t("selectMethod"),
      label: t("selectMethod"),
      defaultValues: RegisteredThrough.map(r => r.id).filter(r => dataAnalysis[r].maximum > 0),
      visible: true,
      priority: true
    }
  }
  return (
    <div data-intercom-target={`captiveContainer`} className={`captive-graph ${className || ''}`}>
      {!viewMode && <h3 className="dashboard-title">{t('title', { period: period.text })}</h3>}
      {(captiveDataFiltered && RegisteredThrough.some(r => dataAnalysis[r.id].maximum > 0)) ? <div className="h-100" data-intercom-target={`captiveCard`}>
        <MDBCard className="graph-card">
          <div className="d-flex flex-row justify-content-between align-items-center mx-2 p-2">
            <h4 className="registration-total m-0">Total: {selectedMethod.map(s => dataAnalysis[s].total).reduce((a, b) => a + b, 0)}</h4>
            {!viewMode && <Filters schemas={filtersSchema}
              className="more-actions"
              values={{ registeredThrough: selectedMethod }}
              onChange={({ registeredThrough }) => setSelectedMethod((registeredThrough.length === 0) ? filtersSchema.registeredThrough.defaultValues : registeredThrough)}
            />}
          </div>
          <Chart
            width={'100%'}
            height={isLarge ? '250px' : '300px'}
            chartType="ColumnChart"
            loader={<NewLoader className="loader" />}
            data={captiveDataFiltered}
            options={{
              isStacked: true,
              colors: getBarColorsForSelectedMethods() || [],
              chartArea: { width: '85%' },
              legend: { position: 'top', maxLines: 3 },
              vAxis: {
                viewWindow: {
                  min: 0,
                  max: Math.ceil(computeCurrentMaximum(selectedMethod)) || 1
                },
                viewWindowMode: 'explicit',
              },
              format: "0"
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback: ({ chartWrapper }) => {
                  const { row } = chartWrapper.getChart().getSelection()[0];
                  openModal(storesSelected, selectedMethod, aggrupationType, captiveData[row + 1][captiveData[row + 1].length - 1]);
                },
              },
            ]}
            legendToggle
          />
          <div className="card-footer grey"><b>{t('linkToHelpCenter.helperTextB', { sources: listToText(selectedMethod.map(m => RegisteredThrough.find(r => r.id === m).text), 3) })}</b>{t("linkToHelpCenter.helperText", { period: period.suffixText })}</div>
        </MDBCard>
      </div> : renderEmptyState()}
      <LinkToHelpCenter
        intercomName={`captiveFooter`}
        labels={{
          beforeLinkText: t('linkToHelpCenter.beforeLinkText', { period: period.suffixText, sources: listToText(selectedMethod.map(m => RegisteredThrough.find(r => r.id === m).text), 3) }),
          linkText: t('linkToHelpCenter.linkText'),
          afterLinkText: t('linkToHelpCenter.afterLinkText'),
        }}
        url="https://help.botbit.io/es/articles/3200769-cuales-son-las-distintas-fuentes-para-captar-los-datos-de-mis-clientes?utm_source=BotBit&utm_medium=backOffice&utm_campaign=dashboard"
      />
    </div>
  );
};

CaptiveGraph.translations = {
  ...translations,
};

export default translate(CaptiveGraph);
