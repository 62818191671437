import React, { useEffect, useState } from 'react';
import { envConfig } from "../../config/config";
import { MDBBtn, MDBCard, MDBInput } from "mdbreact";
import { Formik, Form, Field } from "formik";
import { translations } from "../../config/translations/SetPassword";
import translate from "../../config/translations/translate";
import Loading from "../../commons//components/utils/Loading"
import { checkFirstLogin, setFirstTimeInformation } from '../../api/administratorApi';
import * as Yup from "yup"
import { useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ImageCropper, Image } from "botbit-ui-components";
import "./SetPassword.scss"
import { uploadAdministratorAvatar } from '../../api/utilsApi';


const SetPassword = ({ t }) => {
    const [passCase, setPassCase] = useState();
    const [passAreDifferent, setPassAreDifferent] = useState(false);
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);
    const [userBasicData, setUserBasicData] = useState({});
    const history = useHistory();

    const verificationSchema = Yup.object().shape({
        name: Yup.string().required(t("inputIsRequired")),
        lastName: Yup.string().required(t("inputIsRequired")),
        password: Yup.string().required(t("inputIsRequired")),
        confirmPassword: Yup.string().required(t("inputIsRequired"))
    })

    const params = new URLSearchParams(history.location.search);
    const username = params.get("username");
    const firstLogin = params.get("firstLogin")
    const token = params.get("token")

    useEffect(() => {
        checkFirstLogin(username, token).then(res => {
            const { basicData } = res.data.data;
            if (basicData) {
                const { name, lastName } = basicData;
                setUserBasicData({
                    name,
                    lastName
                })
            }
            if (firstLogin === "true") {
                setPassCase("firstTime");
                return
            }
            if (res.data.data.madeTheFirstLogin === false) setPassCase("firstTime");
            else setPassCase("reset")
        })
    }, [])

    const _uploadImage = (formData, blob) => {
        return uploadAdministratorAvatar(formData, token);
    }

    const onUploadSuccess = (uploadResponse, blob, setFieldValue) => {
        setFieldValue(`avatar`, uploadResponse.data.data[0]);
        setIsCropModalOpen(false);
    }

    const openCropModal = event => {
        event.preventDefault();
        setIsCropModalOpen(true)
    }

    return (
        <div className="set-password">
            <div className="set-password_container">
                {/* <img src={envConfig.staticImgUrl + "botbit_logo_320px.png"} alt="botbit-logo" /> */}
                <MDBCard className="px-4 pb-4">
                    {passCase !== undefined
                        ? <Formik
                            validationSchema={verificationSchema}
                            initialValues={{
                                name: userBasicData.name || "",
                                lastName: userBasicData.lastName || "",
                                password: "",
                                confirmPassword: ""
                            }}
                            onSubmit={({ avatar, name, lastName, password, confirmPassword }) => {
                                if (password === confirmPassword) {
                                    const body = {
                                        administrator: {
                                            avatar,
                                            username,
                                            name,
                                            lastName
                                        },
                                        password,
                                        token
                                    }
                                    setFirstTimeInformation(body)
                                        .then(res => {
                                            if (res.status === 200) {
                                                history.push(`/login?username=${username}`)
                                            }
                                        })
                                } else {
                                    setPassAreDifferent(true);
                                }
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form autoComplete="off">
                                    <Modal isOpen={isCropModalOpen} class='UpdateCompanyModal my-0'>
                                        <ModalHeader toggle={() => setIsCropModalOpen(false)}></ModalHeader>
                                        <ModalBody style={{ textAlign: 'center' }}>
                                            <label style={{ color: "#898989" }} id="info">{t("chooseImage")}</label>
                                            <ImageCropper className="image-cropper" displayMessage={t("dragAndDrop")} onUpload={_uploadImage} onUploadSuccess={(a, b) => onUploadSuccess(a, b, setFieldValue)} /> {/* Añado parametro setFieldValue a la función porque no lo tengo como variable global */}
                                        </ModalBody>
                                    </Modal>
                                    <div className="container-header">
                                        <div className="first-line">
                                            <h2>{t("joinYourTeam")}</h2>
                                            <img src={envConfig.staticImgUrl + "RAY-Brand-Iso-Red.png"} alt="botbit-logo-mini" />
                                        </div>
                                        <div className="second-line">
                                            <h4>{t("subtitle")}</h4>
                                        </div>
                                    </div>
                                    <div className="image-and-upload-container mt-4">
                                        <Image scaleFixedSize={{ width: 80, height: 80 }} src={(values.avatar && values.avatar.length > 0) ? values.avatar : envConfig.staticImgUrl + "/noavatar.jpg"}></Image>
                                        <button id="add-image" onClick={openCropModal}>
                                            <div>
                                                <i className="fa fa-plus" style={{ cursor: "pointer", color: 'white' }}></i>
                                            </div>
                                        </button>
                                    </div>
                                    <Field name="name">
                                        {({ field, form }) => (
                                            <>
                                                <MDBInput
                                                    id="name"
                                                    label={t(`name`)}
                                                    name="name"
                                                    value={field.value}
                                                    // type="name"
                                                    getValue={value => {
                                                        setFieldValue(field.name, value);
                                                        setPassAreDifferent(false)
                                                    }}
                                                />
                                                {form.submitCount > 0 && form.errors && form.errors.name && <span className="error-msg d-block">{form.errors.name}</span>}
                                            </>
                                        )}
                                    </Field>
                                    <Field name="lastName">
                                        {({ field, form }) => (
                                            <>
                                                <MDBInput
                                                    id="lastName"
                                                    label={t(`lastName`)}
                                                    name="lastName"
                                                    value={field.value}
                                                    // type="lastName"
                                                    getValue={value => {
                                                        setFieldValue(field.name, value);
                                                        setPassAreDifferent(false)
                                                    }}
                                                />
                                                {form.submitCount > 0 && form.errors && form.errors.lastName && <span className="error-msg d-block">{form.errors.lastName}</span>}
                                            </>
                                        )}
                                    </Field>
                                    {/* </MDBCol> */}
                                    {/* <MDBCol size="12" md="6"> */}
                                    <Field name="password">
                                        {({ field, form }) => (
                                            <>
                                                <MDBInput
                                                    id="password"
                                                    label={t(`${passCase}.password`)}
                                                    name="password"
                                                    value={field.value}
                                                    type="password"
                                                    getValue={value => {
                                                        setFieldValue(field.name, value);
                                                        setPassAreDifferent(false)
                                                    }}
                                                />
                                                {form.submitCount > 0 && form.errors && form.errors.password && <span className="error-msg d-block">{form.errors.password}</span>}
                                            </>
                                        )}
                                    </Field>
                                    <Field name="confirmPassword">
                                        {({ field, form }) => (
                                            <>
                                                <MDBInput
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    label={t(`${passCase}.confirmPassword`)}
                                                    value={field.value}
                                                    type="password"
                                                    getValue={value => {
                                                        setFieldValue(field.name, value);
                                                        setPassAreDifferent(false)
                                                    }}
                                                />
                                                {form.submitCount > 0 && form.errors && form.errors.confirmPassword && <span className="error-msg d-block">{form.errors.confirmPassword}</span>}
                                            </>
                                        )}
                                    </Field>
                                    {passAreDifferent && <span className="error-msg d-block">{t("passShouldBeTheSame")}</span>}
                                    {/* </MDBCol> */}
                                    <MDBBtn className="submit-btn mt-2" type="submit" color="primary">{t(`${passCase}.submit`)}</MDBBtn>
                                    {/* </MDBRow> */}
                                    {/* </MDBContainer> */}
                                </Form>
                            )}
                        </Formik>
                        : <Loading loading={passCase === undefined} />
                    }
                </MDBCard>
            </div>
        </div>
    );
}

SetPassword.translations = { ...translations }

export default translate(SetPassword);
