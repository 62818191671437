export const translations = {
  linkToHelpCenter: {
    helperTextB: {
      es: "Datos de clientes captados por {{sources}} ",
      es_ar: "Datos de clientes captados por {{sources}} ",
      en: "Captured customer data from {{sources}} ",
    },
    helperText: {
      es: "{{period}}. Haz click en cada barra para ver el detalle.",
      es_ar: "{{period}}. Haz click en cada barra para ver el detalle.",
      en: "{{period}}. Click on each bar for more details.",
    },
    beforeLinkText: {
      es: "Entérate cómo captar más clientes haciendo",
      es_ar: "Entérate cómo captar más clientes haciendo",
      en: "Find out how to get more customers by doing",
    },
    linkText: {
      es: "click aquí",
      es_ar: "click aquí",
      en: "click here",
    },
    afterLinkText: {
      es: " ",
      es_ar: " ",
      en: " ",
    },
  },
  captiveMethods: {
    manual: {
      es: "Reg. Manual",
      es_ar: "Reg. Manual",
      en: "Manual",
    },
    crm: {
      es: "CRM",
      es_ar: "CRM",
      en: "CRM",
    },
    onlineShop: {
      es: "Venta",
      es_ar: "Venta",
      en: "Sales",
    },
    payments: {
      es: "Pagos",
      es_ar: "Pagos",
      en: "Payments",
    },
    wifi: {
      es: "WiFi",
      es_ar: "WiFi",
      en: "WiFi",
    },
    email: {
      es: "Email",
      en: "Email"
    },
    imports: {
      es: "Importaciones",
      en: "Imports",
    },
    reservation: {
      es: "Reservas",
      es_ar: "Reservas",
      en: "Books",
    },
    reviewRequest: {
      es: "Pedido reseña",
      es_ar: "Pedido reseña",
      en: "Review request",
    },
    other: {
      es: "Otros",
      es_ar: "Otros",
      en: "Other",
    }

  },
  title: {
    es: "Nuevos clientes {{period}}",
    es_ar: "Nuevos clientes {{period}}",
    en: "New customers {{period}}",
  },
  selectMethod: {
    es: "Fuentes",
    en: "Sources"
  },
  emptyState: {
    es: "No hay registros para las tiendas y período seleccionados.",
    en: "There are no registers for the stores and period selected."
  },
  timePeriod: {
    es: "Periodo",
    en: "Period"
  },
  store: {
    es: "Tiendas",
    en: "Stores"
  },
  captiveModal: {
    es: "Clientes registrados",
    es_AR: "Clientes registrados",
    en: "Registered customers",
    theDay: {
      es: "el {{date}}",
      en: "on {{date}}"
    },
    thePeriod: {
      es: "en {{date1}}",
      en: "in {{date1}}"
    }
  },
};
