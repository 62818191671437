import React from "react";
import {WizardNavigationButtons} from "../../commons/components/utils/form/wizard/Wizard";

const NextSteps = (props) => {
  return <div className="ibox-content pb-1">
    <form id="next-steps" onSubmit={(e) => {
      e.preventDefault();
      props.nextHandler({})
    }}>
    <div className="row">
      <div className="col-12 pb-5">Bienvenido lo siguiente que tenes que hacer es...</div>
    </div>
      <WizardNavigationButtons backHandler={props.backHandler}/>
    </form>
    </div>
};

export default NextSteps;