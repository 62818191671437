import React from 'react'
import UserDetails from './UserDetails';
import { getUserActivity, getStore } from '../../../../api/storeApi';
import PropTypes from 'prop-types';
import { setLoading } from '../../../../commons/components/application/miscActions';
import { connect } from 'react-redux';
import Loading from '../../utils/Loading.js';
import { updateUserStatus } from '../../../../api/userApi.js';

class UserDetailsContainer extends React.Component {

	constructor(props) {
		super(props);
		const params = new URLSearchParams(this.props.queryParams)
		this.state = {
			loading: true,
			paramSelectedStore: {
				id: parseInt(params.get('storeId'))
			}
		}
	}

	componentDidMount() {
		this.updateUser();
		if (this.state.paramSelectedStore.id && this.state.paramSelectedStore.id !== -1 && this.props.availableStores) {
			this.setState({
				paramSelectedStore: this.props.availableStores.find(as => as.id === this.state.paramSelectedStore.id)
			})
		}
	}

	getUserAvailableStores = async (stores) => {
		const companyStoresIds = stores.map(store => store.id);
		const res = await getUserActivity(this.props.userId, companyStoresIds);
		const storesArray = res.data.data.userInformation.storeRegistrations;
		const storesFiltered = storesArray.filter(store => companyStoresIds.includes(store.storeId));
		return storesFiltered;
	}

	getSelectedStores = async () => {
		let stores;
		if (this.props.availableStores && this.props.selectedCompany) {
			stores = this.props.availableStores.filter(as => as.companyId === this.props.selectedCompany.id);
		}

		if (this.props.selectedStores) { 
			stores = this.props.selectedStores }
		else if (this.state.paramSelectedStore.id && this.state.paramSelectedStore.id !== -1) { 
			stores = [this.state.paramSelectedStore] }

		const filteredStores = await this.getUserAvailableStores(stores);

		return filteredStores;
	}

	updateUser = async () => {
		this.setState({
			userDetails: undefined,
			loading: true
		});

		const stores = await this.getSelectedStores();
		const formattedStores = await stores.map(async store => {
			const storeRes = await getStore(store.storeId);
			const storeData = storeRes.data.data;
			return storeData;
		})
		const storesDone = await Promise.all(formattedStores).then(res => res);

		getUserActivity(this.props.userId, stores.map(s => s.storeId))
			.then((res) => {
				var userDetails = res.data.data;
				this.setState({
					userDetails: userDetails,
					loading: false,
					stores: storesDone,
				});
			})
	}

	updateUserStatus = (userStatus) => {
		let store = (this.props.selectedStores && this.props.selectedStores.length === 1)
			? this.props.selectedStores[0].id : this.state.paramSelectedStore.id
		updateUserStatus(this.props.userId, store, userStatus)
			.then(
				response => {
					this.setState(
						{
							userDetails: {
								...this.state.userDetails,
								userStatus: response.data.data
							}
						}
					);
				}
			).finally(this.updateUser);
	}

	render = () => {
		return (

			<div className={"wrapper wrapper-content gray-bg pt-0"}>
				<Loading loading={this.state.loading} small />
				{
					this.state.userDetails && this.state.stores &&
					(
						<UserDetails
							updateUserStatus={this.updateUserStatus}
							userDetails={this.state.userDetails}
							inline={this.props.inline !== false}
							updateUser={this.updateUser}
							selectedStores={this.props.selectedStores || [this.state.paramSelectedStore] || this.props.availableStores }
							// selectedStores={this.getSelectedStores()}
							availableStores={this.props.availableStores}
							stores={this.props.stores}
							admin={this.props.admin}
							review={this.props.review || undefined}
							hideActions={this.props.hideActions}
							componentSelectedStore={this.props.componentSelectedStore}
						/>
					)
				}
			</div>
		)
	}
}

UserDetailsContainer.propTypes = {
	userId: PropTypes.any.isRequired
}

const mapStateToProps = (state) => {
	return {
		availableStores: state.application.availableStores,
		admin: state.login.admin,
		selectedCompany: state.application.selectedCompany
	}
};

export default connect(mapStateToProps, { setLoading })(UserDetailsContainer);
