import React from 'react';
import {connect} from 'react-redux';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import ReactCountryFlag from "react-country-flag";

import { setLoading, setError } from '../../commons/components/application/miscActions'

import { getCompaniesBasicDataForAdministrator } from '../../api/administratorApi';
import { getEvents, createStoreFromEvent } from '../../api/salesforceApi.js';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/es';

import { MDBSelect, MDBContainer, MDBRow, MDBCol, MDBBtn, MDBBadge } from "mdbreact";

import { Modal, ModalHeader } from "botbit-ui-components";
import { CollectionVisualizer } from "botbit-ui-components";

import { Formik, Form, FastField } from 'formik';

import { envConfig } from '../../config/config.js';

const MySwal = withReactContent(Swal);

class SalesForceListContainer extends React.Component {

	constructor(props) {
		super(props);
		this.formRef = React.createRef();
		this.state = {
			createModalOpened: false,
			clickedEvent: undefined,
			loading: true
		}
		props.setLoading(true)
	}

	componentDidMount () {
		getCompaniesBasicDataForAdministrator(this.props.admin.id, null)
			.then((response) => {
				this.setState({
					...this.state,
					companies: response.data.data
				});
				this.props.setLoading(false);
			})
			.catch((error) => {
				console.log(error)
				this.props.setLoading(false);
			})

	}

	showCreateModal = (row, updateRow) => {
		//console.log(row);
		this.setState(
			{
				createModalOpened: true,
				clickedEvent: row,
				selectedCompany: -1,
				updateRow: (row) => {
					updateRow(row);
				}
			}
		);
	}

	hideCreateModal = () => {
		this.setState(
			{
				createModalOpened: false,
				clickedEvent: undefined
			}
		);
	}
	
	showData(row) {
		var text = "";
		var detail = row.resultDetail;
		if (detail) {
			// altas
			if (detail.newCompany)
				text = "<p align='left'>Se creó con éxito la empresa <strong>" + detail.newCompany.companyName + " (" + detail.newCompany.companyId + ")</strong></p>";
			if (detail.newStore) {
				text += "<p align='left'>Se creó con éxito la tienda <strong>" + detail.newStore.storeName + " (" + detail.newStore.storeId + ")</strong> en la empresa <strong>" + detail.newStore.companyName + " (" + detail.newStore.companyId + ")</strong>. ";
				text += "<a href='" + envConfig.frontendPhpUrl + "/storeConfigWizard.php?currentStore=" + detail.newStore.storeId + "' target='_blank'>Ir a la configuracion de la tienda</a></p>";
			}
			if (detail.newAdmins && detail.newAdmins.length > 0) {
				detail.newAdmins.forEach((element, index) => {	
					text += "<p align='left'>Se creó con éxito el administrador <strong>" + element.fullName + " (" + element.id + ")</strong> con perfil <strong>" + element.profile + "</strong></p>";
				})
			}
			if (detail.modifiedAdmins && detail.modifiedAdmins.length > 0) {
				detail.modifiedAdmins.forEach((element, index) => {	
					text += "<p align='left'>Se dieron permisos al administrador <strong>" + element.fullName + " (" + element.id + ")</strong> con perfil <strong>" + element.profile + "</strong></p>";
				})
			}
			// bajas
			if (detail.deactivatedStore)
				text += "<p align='left'>Se desactivó la tienda <strong>" + detail.deactivatedStore.storeName + " (" + detail.deactivatedStore.storeId + ")</strong> en la empresa <strong>" + detail.deactivatedStore.companyName + " (" + detail.deactivatedStore.companyId + ")</strong></p>";
			if (detail.cloudtraxNetworkDeleted && detail.cloudtraxNetworkDeleted === "true")
				text += "<p align='left'>Se eliminó la red de CloudTrax</p>";
				if (detail.deactivatedAdmins && detail.deactivatedAdmins.length > 0) {
					detail.deactivatedAdmins.forEach((element, index) => {	
						text += "<p align='left'>Se desactivó al administrador <strong>" + element.fullName + " (" + element.id + ")</strong> con email <strong>" + element.email + "</strong></p>";
					})
				}
			if (detail.deactivatedCampaigns && detail.deactivatedCampaigns.length > 0) {
				detail.deactivatedCampaigns.forEach((element, index) => {	
					text += "<p align='left'>Se desactivó la campaña <strong>" + element.name + " (" + element.id + ")</strong></p>";
				})
			}
		}
		MySwal.fire({
			title: 'Ok!',
			html: text,
			type: 'success',
			confirmButtonText: 'Cerrar',
			confirmButtonColor:'#1ab394'
		}).then((result) => {
		});	
	}

	processSubmit = (values) => {
		if (!values.company) {
			MySwal.fire({
				title: 'Se debe seleccionar una empresa',
				text: "Se debe seleccionar una empresa",
				type: 'warning',
				confirmButtonText: 'Cerrar',
				confirmButtonColor:'#1ab394'
			}).then((result) => {
			});

		} else {
			// prevenir multiples submits
			if (this.state.processing)
				return;

			this.setState({
				processing: true
			});

			var companyValue = null;
			// -1 es null
			if (values.company !== -1)
				companyValue = values.company;

			createStoreFromEvent(this.state.clickedEvent.id, companyValue).then ((response) => {
				this.state.updateRow(response.data.data);
				this.showData(response.data.data);
				this.setState(
					{
						createModalOpened: false,
						clickedEvent: undefined,
						updateRow: undefined, 
						processing: false
					}
				);
			})
		}
	}
	
	rowActions = (row, updateRow) => {
		let actions = [];
		if (row.status === "PENDING") {
			actions.push({
				key:1,
				color:"",
				icon:"plus-circle",
				label:'Dar de alta',
				onClick: () => this.showCreateModal(row, updateRow)
			});
		}
		if (row.status === "COMPLETE") {
			actions.push({
				key:1,
				color:"",
				icon:"list-alt",
				label:'Ver resultado',
				onClick: () => this.showData(row)
			});
		}
		return actions;
	}

	render(){
		if (this.props.loading)
			return null;

		const columns = [
			/*{
				dataField: 'jsonCompanySalesForceId',
				text: 'companySalesForceId',
				title:'Salesforce AccountId',
				className: 'col-2',
			},*/
			{
				dataField: 'type',
				text: 'Tipo',
				title:'Tipo',
				className: 'col-1',
				//size: 2,
				formatter: (cellContent, row) => {
					var color;
					if (row.type === "CREATION")
						color = "blue";
					else if (row.type === "CHURN")
						color = "black";
					else
						color = "gray";
					return (
						<MDBBadge color={color}>{row.type}</MDBBadge>
					);
				}
			},
			{
				dataField: 'jsonCompanyName',
				text: "Account SalesForce",
				title: "Account SalesForce",
				className: 'col-3'
			},
			{
				dataField: 'jsonStoreName',
				text: "Opp Salesforce",
				title: "Opp Salesforce",
				className: 'col-3'
			},
			{
				dataField: 'timestamp',
				text: "Fecha",
				title: "Fecha",
				className: 'col-2',
				formatter: (cellContent, row) => {
					return new moment(row.timestamp).locale("es").format("LLL")
				}
			},
			{
				dataField: 'countryCode',
				text: "País",
				title: "País",
				className: 'col-1',
				formatter: (cellContent, row) => {
					return (
						<ReactCountryFlag styleProps={{width: '1.8rem',height: '1.8rem'}} code={cellContent===null?'':cellContent} svg />
					);
				}
			},
			{
				dataField: 'status',
				text: "Status",
				title: "Status",
				//className: 'col-2',
				formatter: (cellContent, row) => {
					var color;
					if (row.status === "PENDING")
						color = "orange";
						else if (row.status === "COMPLETE")
						color = "green";
					else if (row.status === "ERROR")
						color = "red";
					return (
						<MDBBadge color={color}>{row.status}</MDBBadge>
					);
				}
			}
		];
		const filters = [
			{
				id: "status",
				type: 'select',
				search: true,          
				className: 'col-12',
				placeholder: "Status",
				label: "Status",
				priority: true,
				visible: true,
				values: [ 
					{
						checked: false,
						value: "ALL",
						text: "Todos"
					},
					{
						checked: false,
						value: "COMPLETE",
						text: "Completo"
					},
					{
						checked: true,
						value: "PENDING",
						text: "Pendiente"
					},
					{
						checked: false,
						value: "ERROR",
						text: "Error"
					}
				],
			},
			{
				id: "eventType",
				type: 'select',
				search: true,          
				className: 'col-12',
				placeholder: "Tipo",
				label: "Tipo",
				priority: true,
				visible: true,
				values: [ 
					{
						checked: true,
						value: "ALL",
						text: "Todos"
					},
					{
						checked: false,
						value: "CREATION",
						text: "Nuevas tiendas"
					},
					{
						checked: false,
						value: "CHURN",
						text: "Tiendas deshabilitadas"
					}
				],
			}
		];

		return (
			<>
				<Modal isOpen={this.state.createModalOpened} centered toggle={this.hideCreateModal}>
					<ModalHeader toggle={this.hideCreateModal}>
						Dar de alta
					</ModalHeader>
					<Formik ref={this.formRef} onSubmit={(values) => { this.processSubmit(values) }}>
						<Form>
							<MDBContainer>
							<MDBRow>
								<MDBCol>
									<FastField
										name="company"
										render={(props) => {
											var options = [ { text: "-- Crear nueva empresa --", value: "-1", checked: props.field.value === -1  } ];
											this.state.companies.map((element, index) => {
												options.push({ 
													text: element.name, 
													value: element.id+"",
													checked: props.field.value===element.id 
												})
												return null;
											})
											return (
												<MDBSelect
													label={ "Seleccione empresa" }
													options={ options }
													search={ true }
													getValue={ (values) => { 
														let intValue = (values && values[0]) ? parseInt(values[0]) : null;
														if (intValue !== null && props.field.value !== intValue) {
															props.form.setFieldValue(props.field.name, intValue); 
															var selectedCompany = null;
															if (intValue === -1) {
																selectedCompany = -1;
															} else {
																for (var i=0; i<options.length; i++) {
																	if (options[i].value === intValue+"") {
																		selectedCompany = options[i].text;
																	}
																}
															}
															this.setState({
																...this.state,
																selectedCompany: selectedCompany
															})
														}
													}}
												/>
											);
										}}
									/>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12">
									<p>
										<strong>Resumen: </strong>
									{ this.state.clickedEvent && this.state.selectedCompany === -1 && 
											<>Se va a crear una nueva empresa {this.state.clickedEvent.jsonCompanyName} con una tienda {this.state.clickedEvent.jsonStoreName} </> } 
									{ this.state.clickedEvent && this.state.selectedCompany !== -1 && 
											<>Se va a crear una tienda {this.state.clickedEvent.jsonStoreName} en la empresa {this.state.selectedCompany } </> } 
									</p>
								</MDBCol>
							</MDBRow>
							<br></br>
							<MDBRow className="buttonbar">
								<MDBCol className="text-right col-6">
										<a href="#!" className="rotate-btn text-dark" data-card="card-1" onClick={() => this.hideCreateModal() }>
											<MDBBtn color="secondary" size="sm">
												Cancelar
											</MDBBtn>
										</a>
								</MDBCol>
								<MDBCol className="text-left col-6">
										<MDBBtn className="mr-1" color="primary" size="sm" type="submit"
												disabled={ !this.props.admin.operations.includes("CAMPAIGNS__EDIT") && !this.state.processing }
											>
											Guardar
										</MDBBtn>
								</MDBCol>
							</MDBRow>
							<br></br>
							</MDBContainer>
						</Form>
					</Formik>
				</Modal>
				<br></br><br></br>
				<CollectionVisualizer
					columns={columns}
					getData={ (page, order, filters) => getEvents(page,  filters) }
					dataPath="data.items"
					title="SalesForce"
					defaultFilters={filters}
					alwaysFloatingFilter={true}
					filtersTitle={"Estado"}
					showFiltersInfo
					rowActions={this.rowActions}
				/>
				<br></br><br></br>
				<br></br><br></br>
			</>);
	}
}

const mapStateToProps = (state) => {
	return {
		admin: state.login.admin,
		token: state.login.token
	}
}

export default connect(mapStateToProps, { setLoading, setError })(SalesForceListContainer);
