import React from "react";
import { Field } from "formik";
import { MDBCol, MDBRow } from "mdbreact";
import { Error } from "./Error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { HexColorPicker } from "react-colorful";
import { 
    UxSelect,
    UxCustomTextInput,
    UxCustomTextArea
 } from "botbit-ui-components";
export const TextArea = ({ name, label, errors }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <div className="form-group">
          <UxCustomTextArea
            data-testid={name}
            placeholder={label}
            {...field}
          />
          <Error errors={errors} isSubmitting={isSubmitting} />
        </div>
      )}
    </Field>
  );
};

export const Text = ({ name, label, errors, info, prefix }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { isSubmitting, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <div className="form-group">
          <UxCustomTextInput prefix={prefix} type="text" data-testid={name} placeholder={label} {...field} id={field.name}
            onChange={(e) => {
                setFieldValue(field.name, e.target.value);
             }}
          />
          {info && (
            <>
              <FontAwesomeIcon
                style={{ position: "relative", left: "95%", top: "-3rem" }}
                data-tip
                data-for={name + ".info"}
                icon={faInfoCircle}
                className="text-warning"
              />
              <ReactTooltip id={name + ".info"} effect="solid" multiline className="w-50">
                <span>{info}</span>
              </ReactTooltip>
            </>
          )}
          <Error errors={errors} isSubmitting={isSubmitting} />
        </div>
      )}
    </Field>
  );
};

export const ColorField = ({ name, label, errors }) => {

  return <Field name={name}>
    {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldValue, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (

        <div className="my-2">
          <MDBRow>
          <MDBCol size="2">

          <span>
            {label +": "+ (field.value || "")}
          </span>
          </MDBCol>
          <MDBCol>

          <HexColorPicker
              style={{height:"100px", width:"100px"}}
              color={field.value}
              onChange={c=>setFieldValue(name, c)}
              />
              </MDBCol>
          </MDBRow>
          <Error errors={errors} isSubmitting={isSubmitting} />
        </div>
      
    )}
  </Field>
}

export const Select = ({ name, options, label, errors, className }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldValue, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <div className={"form-group "+ className}>
          <UxSelect
            label={label}
            data-testid={name}
            multiple={false}
            options={options.map((o) => ({
              ...o,
              selected: o.value === field.value,
            }))}
            {...field}
            // selected={field.value}
            onChange={(value) => {
              value[0] !== field.value && setFieldValue(field.name, value[0]);
            }}
          />
          <Error errors={errors} isSubmitting={isSubmitting} />
        </div>
      )}
    </Field>
  );
};
