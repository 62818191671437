import React, { useEffect } from 'react';
import Segments from "./Segments/Segments";
import {MDBContainer} from 'mdbreact';
import { translate } from 'react-i18next';
import {withRouter} from "react-router-dom"
import './SegmentsContainer.css'
import { firePageView } from "../../commons/components/utils/trackers";

const SegmentsContainer = ({t}) => {

    useEffect(() => {
        firePageView()
    }, [])

    return <MDBContainer id="segmentsView">
        <Segments 
            title={t("menu.segments.title")}
            t={t}
            canDelete
            canViewDescription
            canUpdateClients
            canViewUsers={false}
            ></Segments>
    </MDBContainer>
}

export default (translate('common')(withRouter(SegmentsContainer)));