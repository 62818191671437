import axiosClient from './axiosClient'

export const getToken = (user, password, axiosErrorHandler = undefined) => {
    let bodyFormData = new FormData();
    bodyFormData.set('grant_type', 'password');
    bodyFormData.set('username', user);
    bodyFormData.set('password', password);
    return axiosClient.post(`/oauth/token`, bodyFormData, {
        auth: {
            username: 'botbit_frontend',
            password: 'larompemos'
        },
        headers: { 'Content-Type': 'multipart/form-data' },
        errorHandler: axiosErrorHandler
    }).then(response => {
        const token = response.data.access_token;
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return response;
    })
}