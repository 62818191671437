import axiosClient from "./axiosClient"

export const uploadAdministratorAvatar = (formData, token) => {
    const headers = token ? {
        headers:{
            Authorization:token
        }
    } : {};
    return axiosClient.post("/administrators/first-login/avatar-upload", formData, headers);
}
