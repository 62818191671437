import axiosClient from './axiosClient';


export const getCampaigns = (stores) => {
	var storeIds = stores.map((s) => (s.id));
	var url = `/campaigns/basic?stores[]=${storeIds}`
	return axiosClient.get(url);
}

export const getCampaignsByCompanyId = (companyId, page, order, filters) => {
	var companyParameter = (filters && filters.company && filters.company.length > 0) ? `&companyId=${filters.company[0]}` : "";
	if (companyParameter === "" && companyId) {
		companyParameter= `&companyId=${companyId}`;
	}
	var typeParameter = (filters.type[0] === null || filters.type[0] === undefined) ? "" : `&type=${filters.type[0]}`;
	var orderParameter = (order === undefined || order === null) ? '' : '&sort=' + order.name + ',' + order.direction
	var storeFilters = (filters && filters.stores && filters.stores.length > 0) ? "&storeIds[]=" + filters.stores.join() : "";
	var textFilter = (filters && filters.text && filters.text.length > 0) ? "&textPattern=" + filters.text : "";
	var activeFilter = (filters && filters.status && filters.status.length > 0 && filters.status !== "all") ? "&active=" + filters.status : "";
	var url = `/campaigns?pageNumber=${page}&pageSize=12${companyParameter}${orderParameter}${typeParameter}${storeFilters}${textFilter}${activeFilter}`
	return axiosClient.get(url);
}

export const getBasicCampaignsByCompanyId = (companyId) => {
	return axiosClient.get(`/campaigns/basic?companyId=${companyId}`);
}

export const getFilterPossibleValues = (filterDef, adminId, stores, companyId) => {

	var url = filterDef.filterAttribute.options.possibleValuesURL + "?adminId=" + adminId;
	if (stores) {
		var storesString = "";
		for (var i=0; i<stores.length; i++) {
		 	//let param = "&stores[]=" + stores[i].id;
			//url = url + param;
			if (i !== 0)
				storesString += ",";
			storesString += stores[i].id;
		}
		url = url + "&stores[]=" + storesString;
	}
	if (companyId) {
		url = url + "&companyId=" + companyId;
	}
	return axiosClient.get(url);
}

export const updateCampaign = (campaign) => {
	//nullear los id de filtro negativo.
	if(campaign.segment && campaign.segment.filters && campaign.segment.filters.length > 0) {
		campaign.segment.filters = campaign.segment.filters.map((filter) => {
			if(filter.id < 0) {
				filter.id = null;
			}
			return filter; 
		});
	}

	return new Promise((resolve, reject) => {
		axiosClient.post('/campaigns', campaign).then(res => {
			resolve(res);
		})
		.catch(error => {
			reject(error);
		})
	});
}

export const switchCampaignActive = (campaignId, isActive) => {
	const suffix = isActive ? "/deactivate" : "/activate"
	return axiosClient.put('/campaigns/' + campaignId + suffix);
}

export const getCampaignPreview = (campaignId) => {
	return axiosClient.get('/campaigns/' + campaignId + "/preview");
}

export const getInProgressCampaignPreview = (campaign) => {
	return axiosClient.post(`/campaigns/preview`, campaign);
}

export const getCampaignProductCategories = (campaignId) => {
	return new Promise(
		resolve=> {
			axiosClient.get('/campaigns/' + campaignId + "/productCategories")
				.then(
					res => resolve(res.data.data)
				)
		}
	);
}

export const sendCampaign = (campaignId, companyId) => {
	return axiosClient.post(`/campaigns/${campaignId}/send?companyId=${companyId}`);
}

export const deleteCampaign = (campaignId) => {
	return axiosClient.delete('/campaigns/' + campaignId);
}
export const getActivableCampaigns = (companyId) => {
	return axiosClient.get('/campaigns/smart/create/categories?companyId=' + companyId);
}
export const getCampaignDetail = (campaignId) => {
	return new Promise(
		resolve=> {
			axiosClient.get('/campaigns/' + campaignId)
				.then((res) => {
					return resolve(res.data.data)
				})
		}
	);
}

export const getCampaignTemplates = (companyId, pageNumber, type, pageSize) => {
	if(!pageSize) pageSize = 10;
	let promise = axiosClient.get(
		`/campaigns/templates?companyId=${companyId}&pageNumber=${pageNumber}
		&pageSize=${pageSize}&type=${type}`);
	return promise;
}

export const getStoresActivableSmartCampaign = (companyId, subcategoryId, campaignId) => {
	return axiosClient.get(`/campaigns/smart/categories/${subcategoryId}/stores?companyId=${companyId}` + (campaignId ? `&campaignId=${campaignId}` : ""));
}

export const getEmptySmartCampaign = (companyId, subCategoryId) => {
	//negativizar los ids nulos de segment.filters.
	return new Promise(resolve=> {
		axiosClient
			.get(`/campaigns/smart/subcategory/${subCategoryId}/empty?companyId=${companyId}`)
				.then((res) => {
					let campaign = res.data.data;
					if(campaign.segment && campaign.segment.filters && campaign.segment.filters.length > 0) {
						let fakeId = -1;
						campaign.segment.filters.forEach((filter, index) => {		
							if(filter.id === null) {
								filter.id = fakeId - index;  
							}
						});
					}
					resolve(campaign)
				})
		});
}

export const getEmptyCampaignWithTemplate = (templateId, companyId) => {
	return new Promise(resolve=> {
		axiosClient
			.get(`/campaigns/templates/${templateId}/empty?companyId=${companyId}`)
				.then((res) => {
					resolve(res.data.data)
				})
		});
}

export const getEmptyDuplicatedCampaign = (campaignId, companyId) => {
	return new Promise(resolve=> {
		axiosClient
			.get(`/campaigns/${campaignId}/empty?companyId=${companyId}`)
			.then((res) => {
				resolve(res.data.data)
			})
		});
}

export const getPromoCodes = (companyId) => {
	return axiosClient.get(`/promo-codes/available/by-tag?companyId=${companyId}`)
}

export const sendTestEmail = (campaignWithEmails) => {
	return axiosClient.post(`/campaigns/send/test`, campaignWithEmails);
}

export const exportCampaigns = (filters) => {
	return axiosClient.get(`/campaigns/csv?${getFilterParams(filters)}`)
}

const getFilterParams = ({stores=[], status, text, company, type}) => {
	const storesParam = (stores[0] !== undefined ?`storeIds=${stores}&` : "");
	const companyParam = (company[0] !== undefined ?`companyId=${company[0]}&` : "");
	const typeParam = (type[0] !== "" && type[0] !== undefined ?`type=${type[0]}&` : (type[0] === "" ? "type=all&" : ""));
	const statusParam = (status[0] !== undefined ? `active=${status.toString()}&` : "");
	const textPatternParam = (text !== undefined ? `textPattern=${encodeURIComponent(text)}&` : "");
	return `${storesParam}${statusParam}${textPatternParam}${companyParam}${typeParam}`;
}

export const getAdminsEmailsForCompany = (companyId) => {
	return axiosClient.get(`/companies/${companyId}/administrators?active=true`)
}