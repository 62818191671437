import { FeedbackFiltersConstants } from './FeedbackFiltersConstants';

export const setFeedbackFilters = (filters) => {    
    return {
        type: FeedbackFiltersConstants.SET_FEEDBACK_FILTERS,
        payload: {
            filters: filters
        }
    }
}

export const setFeedbackSavedFilters = (savedFilters) => {
    return {
        type: FeedbackFiltersConstants.SET_FEEDBACK_SAVED_FILTERS,
        payload: {
            savedFilters: savedFilters
        }
    }
}

export const setFeedbackPreviousFilters = (previousFilters, previousView, previousOrder) => {
    return {
        type: FeedbackFiltersConstants.SET_FEEDBACK_PREVIOUS_FILTERS,
        payload: {
            previousFilters: previousFilters,
            previousView: previousView,
            previousOrder: previousOrder
        }
    }
}
