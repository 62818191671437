import translate from '../../../config/translations/translate';
import React, { useState, useEffect } from 'react';
import './GoogleViewsGraph.css';
import { translations } from '../../../config/translations/Dashboard/GoogleViewsGraph';
import { Chart } from 'react-google-charts';
import LinkToHelpCenter from '../LinkToHelpCenter/LinkToHelpCenter';
import { NewLoader } from '../../NewLoader/NewLoader';
import moment from "moment";
import { getGoogleViews } from "../../../api/dashboardApi";
import { useSelector } from "react-redux";
import { MDBCard, MDBIcon } from 'mdbreact';

// legendPosition estaba definida coma variable de estado de forma innecesaria porque su valor no cambia
const legendPosition = "start";

const GoogleViewsGraph = ({ t, className, openModal, stores, filterValues }) => {
    const [graphData, setGraphData] = useState([]);
    // const [legendPosition, _setLegendPosition] = useState("start");
    const selectedCompany = useSelector(state => state.application.selectedCompany);
    const [max, setMax] = useState(0);


    useEffect(() => {
        if (graphData && graphData.length > 0) {
            setGraphData([]);
        }
    }, [JSON.stringify(filterValues)])

    useEffect(() => {
        async function get() {
            const res = await getGoogleViews(selectedCompany.id, stores)
            let { views } = res.data.data;

            setMax(
                Math.max.apply(
                    null,
                    views.map(v => v.views)
                )
            );
            // startedWithBotBit="2020-05-10"
            let dataArray = []
            if (views && views.length > 0) {
                dataArray = [
                    [
                        { type: "date", label: "Fecha", id: "fecha" },
                        { type: 'string', role: 'annotation' },
                        { type: 'string', role: 'annotationText' },
                        t("views"),
                        { type: 'string', role: 'tooltip' }
                    ]
                ];
                views.forEach((item) => {
                    dataArray.push([
                        moment(item.date).toDate(),
                        null,
                        null,
                        item.views,
                        getTooltipText(item)
                    ]);
                });
            }
            setGraphData(dataArray);
        }
        get();
    }, [JSON.stringify(filterValues)]);

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const getTooltipText = (item) => {
        let formattedDate = moment(item.date).format("MMMM YYYY");
        const numFormat = new Intl.NumberFormat()
        return `${capitalize(formattedDate)}: ${numFormat.format(item.views)}`;
    }

    return (
        <div data-intercom-target={`googleViewsContainer`} className={`google-views-graph ${className || ''}`}>
            <h3 className="dashboard-title">{t('title')}</h3>
            {graphData && graphData.length > 1 ? <div className="googleViewsCard" data-intercom-target={`googleViewsCard`}>
                <MDBCard className="graph-card">
                    <div className="my-auto">
                        <Chart
                            width={'100%'}
                            height={"210px"}
                            chartType="LineChart"
                            loader={<NewLoader className="loader" />}
                            data={graphData}
                            options={{
                                chartArea: { width: '85%', height: "75%" },
                                hAxis: {
                                    format: 'MM-YYYY',
                                },
                                vAxis: {
                                    viewWindow: {
                                        min: 0,
                                        max: max || 1
                                    },
                                    viewWindowMode: 'explicit',
                                    format: "0"
                                },
                                format: "0",
                                legend: { position: "top", maxLines: 3, alignment: legendPosition }
                            }}
                            legendToggle
                        />
                        <span className="disclaimer">{t("disclaimerLastDays")}</span>
                    </div>
                    <div className="card-footer"><b>{t("monthViews")}<MDBIcon fab icon="google" /> {t("maps")} <MDBIcon icon="map-marker" /></b> {t("period")}</div>
                </MDBCard>
            </div>
                : <div className="empty-state"><h1>{t("noData")}</h1></div>}
            <LinkToHelpCenter
                intercomName={`googleViewsFooter`}
                labels={{
                    beforeLinkText: t('beforeLinkText'),
                    linkText: t('linkText'),
                    afterLinkText: ' '
                }}
                url="https://help.botbit.io/es/articles/4549475-que-son-y-para-que-sirven-las-fichas-de-google?utm_source=BotBit&utm_medium=backOffice&utm_campaign=dashboard"
            />
        </div>
    );
};

GoogleViewsGraph.translations = {
    ...translations
}

export default translate(GoogleViewsGraph);