import { FeedbackFiltersConstants } from './FeedbackFiltersConstants'

export function feedbackFiltersReducers(state = {}, action) {
	switch (action.type) {
		case FeedbackFiltersConstants.SET_FEEDBACK_FILTERS:
			return {
				...state,
				filters: action.payload.filters
			};
		case FeedbackFiltersConstants.SET_FEEDBACK_SAVED_FILTERS:
			return {
				...state,
				savedFilters: action.payload.savedFilters
			}
		case FeedbackFiltersConstants.SET_FEEDBACK_PREVIOUS_FILTERS:
			return {
				...state,
				previousFilters: action.payload.previousFilters,
				previousView: action.payload.previousView,
				previousOrder: action.payload.previousOrder
			}
		default:
			return state
	}
}

export default feedbackFiltersReducers;