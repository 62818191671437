import React, { Component } from "react";
import { connect } from 'react-redux'
import { setFeedbackFilters, setFeedbackSavedFilters, setFeedbackPreviousFilters } from './FeedbackFiltersActions';
import { CollectionVisualizer } from "botbit-ui-components";
import { MDBRow, MDBContainer } from "mdbreact";
import { withRouter } from 'react-router-dom'
import Score from "./Score";
import ValueComparator from "./ValueComparator"
import DetractorSummary from "./DetractorSummary";
import { getAvailableSites, getDetractors, getDetractorsEvolution } from '../../api/feedbackApi';
import { getTimePeriods } from "botbit-ui-components";
import {
  createDefaultValues, getSavedFilters, applySelectedFiltersOverFilters,
  removeInvisibleFilters, getSelectedTimePeriodLabel,
  getSelectedTimePeriodPrevious,
  hideFilters,
  getReviewFiltersToRedirect,
  setDetractorHiddenFiltersValues
} from "./FiltersHelper";
import './feedbackCenter.css';
import Moment from 'react-moment';
import { Views } from "./Views";
import deepCopy from "../../commons/utils/deepCopy";
import { getCompaniesBasicDataForAdministrator } from "../../api/administratorApi";
import translate from "../../config/translations/translate";

const mapStateToProps = (state) => {
  return {
    availableStores: state.application.availableStores,
    filters: state.feedback.filters,
    savedFilters: state.feedback.savedFilters,
    admin: state.login.admin
  }
};

const provinces = new Set();
const localities = new Set();

class DetractorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summary: [],
      summaryLoading: false,
      showFilterModal: false,
      timePeriods: getTimePeriods(),
      availableSites: [],
      order: this.getInitialOrder()
    }

    this.props.availableStores.forEach(store => {
      if (store.province.trim().length > 0)
        provinces.add(store.province);
      if (store.locality.trim().length > 0)
        localities.add(store.locality);
    });
  }

  componentDidMount = () => {

    const availableSitesPromise = getAvailableSites()
    const companiesPromise = getCompaniesBasicDataForAdministrator(this.props.admin.id, true)

    Promise.all([availableSitesPromise, companiesPromise]).then(values => {
      const availableSites = values[0].data.data
      const companies = values[1].data.data
      this.setState({
        availableSites: availableSites,
        companies: companies
      }, () => this.initFilters())
    })
  }

  getInitialOrder = () => {
    const params = new URLSearchParams(this.props.location.search);
    const sortParam = params.get('sort')
    if (sortParam) {
      const sortParamSplitted = sortParam.split(',')
      return {
        name: sortParamSplitted[0],
        direction: sortParamSplitted[1]
      }
    }
    return undefined
  }

  getDetractorsEvolution = (selectedFilters) => {
    removeInvisibleFilters(selectedFilters, this.props.filters);
    this.setState({ summaryLoading: true });

    getDetractorsEvolution(selectedFilters).then((response) => {
      if (response.data.data && response.data.data.length > 0) {
        this.setState({ summary: response.data.data });
      }
    }).finally(() => {
      this.setState({ summaryLoading: false })
    });
  }

  onFiltersApply = (selectedFilters, filters) => {
    this.props.setFeedbackFilters(filters);
    this.getDetractorsEvolution(selectedFilters);
  }

  initFilters = () => {
    if (this.props.filters) {
      const defaultFilters = this.getInitialFilters();
      this.setState({ filtersBeforeDetractors: deepCopy(this.props.filters) })
      hideFilters(this.props.setFeedbackFilters, this.props.filters, 'scores', 'botbitGenerated')
      setDetractorHiddenFiltersValues(this.props.setFeedbackFilters, this.props.filters, defaultFilters)
    } else {
      this.setDefaultFiltersValues();
    }
  }

  componentWillUnmount() {
    const otherViewsFiltersDefaultValues = createDefaultValues(
      provinces, localities,
      this.state.companies, this.props.admin.companyId,
      this.props.availableStores, this.state.timePeriods,
      this.state.availableSites, this.props.history.location.pathname
    );
    setDetractorHiddenFiltersValues(this.props.setFeedbackFilters, this.props.filters, (this.state.filtersBeforeDetractors || otherViewsFiltersDefaultValues))
  }

  getInitialFilters = () => {
    const filtersDefaultValues = createDefaultValues(
      provinces, localities,
      this.state.companies, this.props.admin.companyId,
      this.props.availableStores, this.state.timePeriods,
      this.state.availableSites, Views.DETRACTORS_VIEW
    );
    // Seteando los filtros readOnly en true.
    let defaultFilters = deepCopy(filtersDefaultValues);
    defaultFilters.find(a => a.id === "scores").values[0].checked = true;
    defaultFilters.find(a => a.id === "scores").values[1].checked = true;
    defaultFilters.find(a => a.id === "scores").values[2].checked = true;
    defaultFilters.find(a => a.id === "scores").values[3].checked = false;
    defaultFilters.find(a => a.id === "scores").values[4].checked = false;
    defaultFilters.find(a => a.id === "botbitGenerated").checked = false;
    this.setState({ defaultFilters: defaultFilters });
    return defaultFilters;
  }

  setDefaultFiltersValues = () => {
    const filtersDefaultValues = this.getInitialFilters();
    this.props.setFeedbackFilters(filtersDefaultValues);

    const savedFilters = getSavedFilters();
    this.props.setFeedbackSavedFilters(savedFilters);
  }

  redirectToReviews = (periodLabel, params) => {

    this.props.setFeedbackPreviousFilters(deepCopy(this.props.filters), Views.DETRACTORS_VIEW, this.state.order)

    const reviewFilters = getReviewFiltersToRedirect(this.props.filters, periodLabel, params)
    //console.log(deepCopy(reviewFilters));
    this.props.setFeedbackFilters(reviewFilters)

    this.props.history.push('/reviews?fromSummary=true')
  }

  onSavedFiltersChange = (selectedFilters) => {
    const filtersApplied = applySelectedFiltersOverFilters(selectedFilters, this.props.filters);
    this.props.setFeedbackFilters(filtersApplied);
  }

  getData = (filters, order) => {
    this.setState({
      order: order
    })
    return getDetractors(filters, order)
  }

  formatTime = (value) => value && <Moment unix from="1970-01-01T00:00:00-0300" interval={0} ago>{value}</Moment>
  formatPercentage = (value) => value + '%'

  render() {
    const { t } = this.props;
    const columns = [
      // for BIG screens: greather than md  
      {
        dataField: 'name',
        title: t("store"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        size: 2
      }, {
        dataField: 'score',
        title: t("score"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            formatter={value => <Score value={value} size='s' />}
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation} />
        }
      }, {
        dataField: 'reviews',
        title: t("reviews"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation}
            inverse />
        }
      }, {
        dataField: 'publicRate',
        title: t("critics"),
        description: t("criticsRate"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation}
            formatter={this.formatPercentage}
            inverse />
        }
      }, {
        dataField: 'botbitReplyRate',
        title: t("responses"),
        description: t("responseReviewRate"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation}
            formatter={this.formatPercentage} />
        }
      }, {
        dataField: 'botbitAvgReplyTime',
        title: t("time"),
        description: t("timeAverage"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation}
            formatter={this.formatTime}
            inverse />
        }
      }, {
        dataField: 'replyRate',
        title: t("replyRate"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation}
            formatter={this.formatPercentage} />
        }
      },
      // for SMALL screens: lower than md
      {
        dataField: 'name',
        isHeader: true,
        title: t("store"),
        sortable: false,
        className: 'align-left d-md-none row-title',
        size: 12
      }, {
        dataField: 'publicRate',
        title: t("critics"),
        sortable: true,
        description: t("criticsRate"),
        className: 'align-center text-truncate d-md-none',
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation}
            formatter={this.formatPercentage}
            inverse />
        },
        size: 4
      }, {
        dataField: 'botbitReplyRate',
        title: t("responses"),
        description: t("responseReviewRate"),
        sortable: true,
        className: 'align-center text-truncate d-md-none',
        size: 4,
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation}
            formatter={this.formatPercentage} />
        }
      }, {
        dataField: 'botbitAvgReplyTime',
        title: t("time"),
        description: t("timeAverage"),
        sortable: true,
        className: 'align-center text-truncate d-md-none',
        size: 4,
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation}
            formatter={this.formatTime}
            inverse />
        }
      }];

    return (<>
      <MDBContainer className="mt-3">
        {this.state.defaultFilters &&
          <CollectionVisualizer
            scrollTopButton
            title="Detractores"
            showFiltersInfo={{
              readOnly: ['scores']
            }}
            header={<>
              <MDBRow>
                {this.state.summary &&
                  <DetractorSummary
                    data={this.state.summary}
                    loading={this.state.summaryLoading}
                    onSummaryClick={this.redirectToReviews} />}
              </MDBRow>
              <MDBRow>
                <h4 className="ml-2 subtitle">{t("comparison")} {getSelectedTimePeriodLabel(this.props.filters).toLowerCase()} vs {getSelectedTimePeriodPrevious(this.props.filters)}</h4>
              </MDBRow>
            </>}
            //amp autoRefresh={60000}
            defaultFilters={this.state.defaultFilters}
            filters={this.props.filters}
            alwaysFloatingFilter={true}
            columnsCommonClasses="m-auto"
            columns={columns}
            getData={(page, order, filters) => this.getData(filters, order)}
            dataPath="data"
            pagination={false}
            initialOrder={this.state.order}
            onFiltersApply={this.onFiltersApply}
            onRowClickFilterBy="stores"
            onResetFilters={this.setDefaultFiltersValues}
            //amp savedFilters={this.props.savedFilters}
            //amp onSavedFiltersChange={this.onSavedFiltersChange}
            filtersTitle="Filtrar Reseñas"
          />}
      </MDBContainer>
    </>)
  }
}

export default connect(mapStateToProps, { setFeedbackFilters, setFeedbackSavedFilters, setFeedbackPreviousFilters })(withRouter(translate(DetractorContainer)));
