export const translations = {
    someElements: {
        es: "{{elements}} y {{moreElementsCount}} más",
        en: "{{elements}} and {{moreElementsCount}} more"
    },
    and: {
        es: 'y',
        en: 'and'
    },
    altAnd: {
        es: "e",
        en: "and"
    }
}