import React from 'react';
import FormControl from "./InputField";

class InputAddressField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '', scriptLoad: !!window.google, id: props.id?props.id:props.input.name };
    this.onScriptLoad = this.onScriptLoad.bind(this);
    this.fillInAddress = this.fillInAddress.bind(this);
  }

  shortName = (addressComponent) => (addressComponent && addressComponent.short_name? addressComponent.short_name:'');

  fillInAddress=(place)=>{
    if(!place) return;

    /*if(this.isAStore(place)){
      webSite = place.website;
      name = place.name;
    }*/
    const {marker, infoWindow, map} = this.state;
    infoWindow.close();
    marker.setVisible(false);

    map.setCenter(place.geometry.location);
    marker.setIcon(/** @type {google.maps.Icon} */({
      url: place.icon,
      size: new window.google.maps.Size(71, 71),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(17, 34),
      scaledSize: new window.google.maps.Size(35, 35)
    }));
    marker.setPosition(place.geometry.location);
    marker.setVisible(true);

    let address = '';
    if (place.address_components) {
      address = [this.shortName(place.address_components[0]),
                 this.shortName(place.address_components[1]),
                 this.shortName(place.address_components[2])
      ].join(' ');
    }

    infoWindow.setContent('<div><strong>' + place.name + '</strong><br>' + address);
    infoWindow.open(map, marker);
    this.props.input.onChange(place.name + (address!==''?",":"") + address);
  };

  isAStore = (place) =>(place.price_level !== undefined || place.reviews !== undefined || place.photos !== undefined || place.opening_hours !== undefined);
  initMap = (autocomplete) =>{
    let map = new window.google.maps.Map(document.getElementById(this.state.id+'-map'));
    map.setZoom(17);
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geoLocation =new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        const circle = new window.google.maps.Circle({center: geoLocation, radius: position.coords.accuracy});
        autocomplete.setBounds(circle.getBounds());
        map.setCenter(geoLocation);
      }, (positionError => {
        console.warn(positionError);
        map.setCenter( new window.google.maps.LatLng( -34.609030, -58.373220));//Obelisco
      }))
    }
    return map;
  };

  onScriptLoad = () =>{
    this.setState({...this.state, scriptLoad: true});
    const autocomplete = new window.google.maps.places.Autocomplete((document.getElementById(this.state.id)));
    const map = this.initMap(autocomplete);
    const marker = new window.google.maps.Marker({
      map: map,
      anchorPoint: new window.google.maps.Point(0, -29),
      animation: window.google.maps.Animation.DROP
    });
    const infoWindow = new window.google.maps.InfoWindow();

    this.setState({...this.state, marker: marker, infoWindow:infoWindow, map:map});

    autocomplete.bindTo('bounds', map);
    // When the user selects an address from the dropdown, populate the address
    // fields in the form.
    autocomplete.addListener('place_changed', () => this.fillInAddress(autocomplete.getPlace()));
  };

    componentDidMount() {
    if (!window.google) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=AIzaSyB_1e6pQ_E8tvLkxLVTbfCgwkESF3huCLY&libraries=places`;
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      //this.setState({...this.state, script:s});
      // Below is important.
      //We cannot access google.maps until it's finished loading
      s.addEventListener('load', e => {
        this.onScriptLoad()
      })
    } else {
      this.onScriptLoad()
    }
  }

  onAddressChange =async (e) => {
    console.log(e.target);
    //const { input } = this.props;
    //const targetFile = e.target.files[0];
      //input.onChange(val)
  };

  render() {
    if (!this.state.scriptLoad) {
      return "Loading";
    }
    //console.log(this.props);
    const id = this.props.id?this.props.id:this.props.input.name;
    //console.log(this.props.input.value);

    return <React.Fragment>
      <FormControl
        {...this.props}
        id={id}
        name={this.props.name}
        type="text"
        placeholder={this.props.placeholder}
        autoFocus={this.props.autoFocus}
        onChange={ this.onAddressChange}
      />
      <div id={id +'-map'} style={{height: "400px", marginBottom: "15px", position: "relative", overflow: "hidden"}}>

      </div>
    </React.Fragment>
  }
}

export default InputAddressField;