import React from 'react';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { envConfig } from '../../../config/config';
import { updateSubscription } from '../../../api/webPushDevicesApi';

class OneSignalOptInContainer extends React.Component {

    componentDidMount () {

        if (!envConfig.webPushOptInEnabled) {
            console.log('opt-in web push notifications are disabled...');
            return;
        }

        if (!envConfig.oneSignalAppId) {
            console.log('To activate opt-in you must define oneSignalAppId in config.js...');
            return;
        }

        this.addOneSignalScript();
        this.init();
    }

    addOneSignalScript() {
        const script = document.createElement("script");

        script.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
        script.async = "";

        document.head.appendChild(script);
    }

    init() {
        const texts = {
            /* limited to 90 characters */
            actionMessage: this.props.t("webPush.actionMessage"),
            /* acceptButton limited to 15 characters */
            acceptButton: this.props.t("webPush.acceptButton"),
            /* cancelButton limited to 15 characters */
            cancelButton: this.props.t("webPush.cancelButton")
        }
        const externalUserId = this.props.adminId + "";
        setTimeout(() => {
            var OneSignal = window.OneSignal || [];
            if(!OneSignal.initilized){
                OneSignal.push(function() {
                    OneSignal.on("subscriptionChange", (isSubscribed) => {
                        OneSignal.getUserId(userId => {
                            updateSubscription(userId);
                        });
                    });
                    
                    OneSignal.push(function() {
                        OneSignal.showSlidedownPrompt();
                    });
                    OneSignal.init({
                        appId: envConfig.oneSignalAppId,
                        // safari_web_id: "web.onesignal.auto.639febc2-a356-4a97-8e69-81281557724a",
                        safari_web_id: "web.onesignal.auto.639febc2-a356-4a97-8e69-81281557724a",
                        promptOptions: texts,
                        // notifyButton: {
                            //     enable: true,
                            //     position: "bottom-left"
                            // }
			notificationClickHandlerMatch: 'exact',
                        notificationClickHandlerAction: 'focus',
                        });
                    });
                    OneSignal.push(function() {
                        OneSignal.setExternalUserId(externalUserId);
                    });
                }
            }, (envConfig.oneSignalTimeout) ? envConfig.oneSignalTimeout : 15000);
    }

    render() {
        return null;
    }
}

export default compose(translate('common'))(OneSignalOptInContainer);
