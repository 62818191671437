import React from 'react';
import { Formik, Field, Form } from 'formik';
import { MDBSelect, MDBInput, MDBDatePicker } from "mdbreact";
import MDBStoreSelector from "../../commons/components/utils/form/StoreSelector.js";
import { MDBChannelSelector } from "./TaskForm.js";
import { UserPill } from '../../commons/components/users/details/UserCard.js';
import { getStoreInquiryTypes } from "../../api/inquiryApi.js";

export default class InquiryForm extends React.Component {

	render(){
		return (
			<Formik ref={this.formRef} initialValues={{storeId: this.props.selectedStore ? this.props.selectedStore.id : undefined}} onSubmit={this.props.onSubmit}>
				<Form>
					<div className="container">
						<div className="row">
							<div className="col pt-3">
								<UserPill user={this.props.user}/>
							</div>
						</div>
						{
							this.props.stores &&
								<div className="row">
									<div className="col">
										<Field name="storeId"
											render={
												(props) => (
													<MDBStoreSelector 
														stores={this.props.stores}
														selectedStore={this.props.selectedStore}
														getValue={ (values) => { if (props.field.value !== values[0]) props.form.setFieldValue('storeId', values[0]) }}
													/>
												)
											}
										/>
									</div>
								</div>
						}
						<div className="row">
							<div className="col-12 col-lg-6">
								<Field
									name="channelId"
									render={(props) => {
										return (
											 <MDBChannelSelector
															value={props.field.value}
															getValue={ (value) => { props.form.setFieldValue('channelId', value)}}/>
										);
									}}
								/>

							</div>
							<div className="col-12 col-lg-6">
								<Field
									name="inquiryTypeId"
									render={(props) => {
										return (
											<>
												<MDBInquiryTypeSelector 
													getValue={ (values) => { if (props.field.value !== values[0]) props.form.setFieldValue('inquiryTypeId', values[0]) }}
													selectedStore={props.form.values.storeId}
													value={props.field.value}
												/>
											</>
										);
									}}
								/>


							</div>
						</div>
						<div className="row">
							<div className="col">
								<Field
									name="timestamp"
									render={(props) => {
										return (
											<>
												<MDBDatePicker
													label="Fecha"
													type="date-local"
													className="botbit-mdb-datepicker botbit-form-input  form-control border-0 p-0 my-3"
													defaultValue={getInitDate()}
													getValue={ (value) => { props.form.setFieldValue('timestamp', new Date(value).getTime())}}
													disableFuture={true}
												/>
											</>
										);
									}}
								/>

							</div>
						</div>
						<div className="row">
							<div className="col">
								<Field
									name="notes"
									render={(props) => {
										return (
											<>
												<MDBInput
													label="Notas"
													type="textarea"
													labelClass="text-dark"
													icon="sticky-note"
													labelStyle={{background: "#f3f3f4; !important"}}
													background
													getValue={ (value) => { props.form.setFieldValue('notes', value)}}
												/>
											</>
										);
									}}
								/>


							</div>
						</div>
						<div className="row">
							<div className="col">
								<button
									block
									type="submit"
									className="btn btn-primary block full-width m-t"
								>
									Guardar
								</button>
							</div>
						</div>
					</div>


				</Form>
			</Formik>
		)
	}
}


class MDBInquiryTypeSelector extends React.Component {
	constructor(props){
		super(props);
		this.state={}
	}

	async updateTypes(selectedStore) {
		if(!selectedStore) return;
		let response = await getStoreInquiryTypes(this.props.selectedStore);
		this.setState( {
			typeOptions : response.data.data.map(
				type => {
					return {
						value:type.id,
						text:`${type.type}`,
						checked: type.id===this.props.value
					}
				}
			)
		})
	}

	componentDidMount = () => {
		this.updateTypes(this.props.selectedStore);
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.selectedStore===prevProps.selectedStore) return;
		this.updateTypes(this.props.selectedStore)
	}

	shouldComponentUpdate(nextProps, nextState){
		return this.props.selectedStore!==nextProps.selectedStore || 
			this.props.value !== nextProps.value
			|| this.state.typeOptions !== nextState.typeOptions;
	}

	render(){
		return (
			<>
				<MDBSelect
					options={this.state.typeOptions}
					color="primary"
					selected="Motivo de consulta"
					getValue={this.props.getValue}
					onBlur={this.props.onBlur}
				/>
			</>
		);

	}


}

const getInitDate=()=>{
	let a=new Date().toISOString();
	return a.substr(0,a.lastIndexOf(':'));
}
