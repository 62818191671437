import axiosClient from './axiosClient'
import objectToQueryString from '../commons/utils/objectToQueryString'

export const getSocialAccountsToCaptureUsers = (filters, errorHandler = undefined) => {
    return axiosClient.get(`/socialaccounts${objectToQueryString(filters)}&capture=USERS`, { errorHandler: errorHandler })
}

export const getSocialAccountsToCaptureReviews = (filters, errorHandler = undefined) => {
    return axiosClient.get(`/socialaccounts${objectToQueryString(filters)}&capture=REVIEWS`, { errorHandler: errorHandler })
}

export const googleOptIn = (captureMode, companyId, storeId = undefined) => {
    let queryParams = `?companyId=${companyId}&capture=${captureMode}`
    if (storeId) queryParams += `&storeId=${storeId}`
    return axiosClient.get(`/google-optin${queryParams}`)
}

export const googleOptInCallback = (params) => {
    return axiosClient.get(`/google-optin-callback${params}`)
}

export const facebookOptIn = (companyId) => {
    const queryParams = `?companyId=${companyId}`
    return axiosClient.get(`/facebook-optin${queryParams}`)
}

export const facebookOptInCallback = (params) => {
    return axiosClient.get(`/facebook-optin-callback${params}`)
}

export const getLocations = (socialAccountId) => {
    return axiosClient.get(`/socialaccounts/${socialAccountId}/locations`)
}
