import React, { useEffect, useState } from "react";
import { CollectionVisualizer } from "botbit-ui-components";
import deepCopy from "../../commons/utils/deepCopy";
import { withRouter } from "react-router-dom";
import { FilterTypes } from 'botbit-ui-components';
import { translations } from "../../config/translations/CollectionVisualizeContainer";
import translate from "../../config/translations/translate";

const CollectionVisualizerContainer = ({ history, companies, selectedCompany, stores,
    setFilters, t,
    ...props }) => {

    const params = new URLSearchParams(decodeURIComponent(history.location.search))
    const COMPANY_FILTER_ID = 'company'
    const STORES_FILTER_ID = 'stores'

    // Filters managed by CollectionVisualizerContainer
    const defaultInternalFilters = props.defaultFilters || [];
    const [internalFilters, setInternalFilters] = useState((props.filters) ? undefined : (props.defaultFilters || defaultInternalFilters))
    const [renderStores, setRenderStores] = useState(true);
    const [initialOrder, setInitialOrder] = useState(null);
    const getDefaultFilters = () => props.defaultFilters || defaultInternalFilters
    const getFilters = () => props.filters || internalFilters

    const isFilterPresent = (filters, filterId) => {
        if (!filters) return false;
        return filters.some(f => f.id === filterId)
    }
    useEffect(() => {
        if (!renderStores) setRenderStores(true)
    }, [renderStores])

    useEffect(() => {

        if (!selectedCompany) return

        const filtersCopy = deepCopy(getFilters())
        const companyFilter = filtersCopy.find(f => f.id === COMPANY_FILTER_ID)
        if (!companyFilter) return

        companyFilter.values.map(v => v.checked = (v.value === selectedCompany.id.toString()))

        if (props.filters && setFilters) setFilters(filtersCopy)
        if (internalFilters) setInternalFilters(filtersCopy)

    }, [selectedCompany])

    useEffect(() => {
        let newFilters = getFilters();
        const getFilter = (filterId) => {
            return filterId && newFilters.find(filter => filter.id === filterId)
        }

        let order = props.initialOrder;
        params.forEach((param, key) => {
            const filter = getFilter(key)
            const values = param.split('|')
            if (filter) {
                let filterType = FilterTypes[filter.type]
                filter[filterType.valueField] = filterType.stringsToValues(values, filter)
            } else if (key === "order") {
                order = { name: values[0], direction: values[1] }
            }
        })
        setInitialOrder(order);
        if (props.filters && setFilters) setFilters(newFilters)
        if (internalFilters) setInternalFilters(newFilters)
        setRenderStores(false);
    }, []);

    const getCompanyFilter = () => {
        const company = companies.find(c => c.id === selectedCompany.id)
        const defCompId = (company) ? selectedCompany.id : companies[0].id

        return {
            id: COMPANY_FILTER_ID,
            type: 'select',
            search: true,
            className: 'col-12',
            placeholder: 'Empresa',
            label: 'Empresa',
            priority: true,
            visible: false,
            values: companies.map(company => ({
                checked: company.id === defCompId,
                value: company.id.toString(),
                text: company.name
            }))
        }
    }

    const getStoresFilter = () => {
        return {
            id: STORES_FILTER_ID,
            label: t("store"),
            type: 'multiselect',
            icon: 'store-alt',
            search: true,
            className: 'col-12',
            placeholder: 'Todas las tiendas',
            priority: true,
            visible: true,
            values: stores.map(store => ({
                checked: props.selectedStores ? props.selectedStores.some(selectedStore => parseInt(selectedStore) === store.id) : false,
                value: store.id.toString(),
                text: store.name,
                dependencyId: store.companyId
                //icon: store.logo            
            })),
            dependsOn: COMPANY_FILTER_ID
        }
    }

    const addCompanyFilter = (filters) => {

        if (isFilterPresent(filters, COMPANY_FILTER_ID) || !companies) return

        const companyFilter = getCompanyFilter()

        filters.push(companyFilter)
    }

    const addStoresFilter = (filters) => {

        if (isFilterPresent(filters, STORES_FILTER_ID) || !stores) return

        const storesFilter = getStoresFilter()

        filters.push(storesFilter)
    }

    addCompanyFilter(getDefaultFilters())
    addCompanyFilter(getFilters())
    addStoresFilter(getDefaultFilters())
    addStoresFilter(getFilters())

    const onInternalFiltersApply = (selectedFilters, filters) => {
        if (internalFilters) setInternalFilters(filters)
        if (props.onFiltersApply) props.onFiltersApply(selectedFilters, filters)
    }

    const onResetInternalFilters = () => {
        if (internalFilters) setInternalFilters(getDefaultFilters())
        if (props.onResetFilters) props.onResetFilters()
    }

    return <div>
        {renderStores && <CollectionVisualizer
            {...props}
            initialOrder={initialOrder}
            filters={getFilters()}
            defaultFilters={getDefaultFilters()}
            onFiltersApply={onInternalFiltersApply}
            onResetFilters={onResetInternalFilters}
        >
        </CollectionVisualizer>}
    </div>
}
CollectionVisualizerContainer.translations = {
    ...translations
}

export default withRouter(translate(CollectionVisualizerContainer))
