import { MDBContainer } from 'mdbreact';
import React, { useEffect, useRef, useState } from 'react';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Messenger/Messenger";
import { Actions, RenderMode, Select } from "botbit-ui-components";
import { useCompanyAvailableStores } from '../../commons/utils/useCompanyAvalaibleStores';
import useWindowDimensions from '../../commons/utils/useWindowDimensions';
import ConversationItem from './ConversationItem';
import { useMessengerData } from './useMessengerData';
import { useSelector } from 'react-redux';
import { getConnectedStores } from '../../api/storeApi';
import useOnScreen from '../../commons/utils/useOnScreen';
import { Toast } from 'botbit-ui-components';
import { Filters } from "botbit-ui-components";
import { getTypesForCompany } from '../../api/issuesApi'

const ChatsContainer = ({ t, hide }) => {
    const companyAvailableStores = useCompanyAvailableStores()
    const {
        administrators,
        chatsList,
        selectedStore,
        setSelectedStore,
        createNewConversation,
        setSelectedChat,
        setConversationsList,
        conversationFilterValues,
        setConversationFilterValues,
        canCreateConversationOnSelectedStore
    } = useMessengerData()
    const { isMedium } = useWindowDimensions();
    const [loadingConversations, setLoadingConversations] = useState(false);

    const [clicked, setClicked] = useState(false);

    const bottomOfConversationList = useRef();
    const isInTheBottom = useOnScreen(bottomOfConversationList);

    const selectedCompany = useSelector(state => state.application.selectedCompany)
    const logedAdmin = useSelector(state => state.login.admin)
    const adminOperations = logedAdmin.operations;
    const [connectedStores, setConnectedStores] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);

    const adminHasAssignerOperation = adminOperations.includes("CONVERSATION__ASSIGN");

    const adminHasAutoassignerOperation = adminOperations.includes("CONVERSATION_UNASSIGNED__VIEW");

    useEffect(() => {
        (async () => {
            const response = await getConnectedStores(selectedCompany.id);
            const stores = response.data.data ? response.data.data : [];
            // setSelectedStore(stores[0]);
            if (stores.length)
                setSelectedStore(companyAvailableStores.find(s => s.id === stores[0].id));
            setConnectedStores(stores);
        })()
    }, [])

    useEffect(() => {
        (async () => {
            const response = await getTypesForCompany(selectedCompany.id);
            const types = response.data.data.type ? response.data.data.type : [];
            setCompanyTypes(types);
        })()
    }, [])

    useEffect(() => {
        if (isInTheBottom) {
            setLoadingConversations(true)
            setConversationsList({ onLoadFinish: () => { setLoadingConversations(false) } });
        }
    }, [isInTheBottom])

    /*    useEffect(() => {
            if (selectedStore) {
                getSourceAccounts({ storeId: selectedStore.id, category: "CONVERSATION" })
                    .then(res => {
                        const sourceArray = res.data.data;
                        setSelectedStoreHasWS(sourceArray.some(s => s.source.id === 17))
                    })
            }
        }, [ selectedStore ? selectedStore.id : undefined])
    */
    var flattenedCompanyStates = [];
    companyTypes.forEach(type => type.states.forEach(state => flattenedCompanyStates.push({ value: state.id, text: state.name, dependencyId: type.id })));
    var operatorFixedOptions = [
        // { value: "ALL", text: t("conversationFilters.showAll") },
        { value: logedAdmin.id.toString(), text: t("conversationFilters.showAssignedToMe") },
        { value: "UNASSIGNED", text: t("conversationFilters.showUnassigned") }
    ];

    const schemas = {
        types: {
            id: "types",
            //label: "types",
            type: "multiselect",
            placeholder: t("conversationFilters.allTypes"),
            label: t("conversationFilters.allTypes"),
            priority: false,
            visible: companyTypes.length > 0 && flattenedCompanyStates.length > 0,
            options: companyTypes.map(type => ({ value: type.id, text: type.name })),
            search: true,
            //defaultValues: conversationFilterValues.types
            defaultValues: []
        },
        states: {
            id: "states",
            //label: "states",
            type: "multiselect",
            placeholder: t("conversationFilters.allStates"),
            label: t("conversationFilters.allStates"),
            priority: false,
            visible: companyTypes.length > 0 && flattenedCompanyStates.length > 0,
            options: flattenedCompanyStates,
            search: true,
            dependsOn: "types",
            //defaultValues: conversationFilterValues.states
            defaultValues: []
        },
        assignedTo: {
            id: "assignedTo",
            type: "multiselect",
            placeholder: t("conversationFilters.assignedTo"),
            label: t("conversationFilters.assignedTo"),
            priority: false,
            visible: adminHasAssignerOperation || adminHasAutoassignerOperation,
            options: adminHasAssignerOperation ? operatorFixedOptions.concat(administrators.filter(admin=>admin.id!==logedAdmin.id).map(admin => ({ value: admin.id.toString(), text: admin.fullName }))) : operatorFixedOptions,
            search: true,
            defaultValues: conversationFilterValues.assignedTo
            // defaultValues: adminHasAssignerOperation ? [] : (adminHasAutoassignerOperation ? [ logedAdmin.id.toString(), 'UNASSIGNED'] : [logedAdmin.id.toString()])
        },
        conversationState: {
            id: "conversationState",
            type: "select",
            placeholder: t("conversationFilters.conversationState.placeHolder"),
            label: t("conversationFilters.conversationState.placeHolder"),
            priority: false,
            visible: true,
            options: [ {value: "OPEN", text: t("conversationFilters.conversationState.open")}, {value: "CLOSED", text: t("conversationFilters.conversationState.closed")} ],
            search: false,
            //defaultValues: conversationFilterValues.assignedTo
            defaultValues: ["OPEN"]
        },
        userName: {
            id: "userName",
            label: t("conversationFilters.userName"),
            type: "input",
            priority: false,
            visible: true,
            //defaultValues: conversationFilterValues.userName
            defaultValues: []
        },
        userPhone: {
            id: "userPhone",
            label: t("conversationFilters.userPhone"),
            type: "input",
            placeholder: "userPhone",
            priority: false,
            visible: true,
            //defaultValues: conversationFilterValues.userPhone
            defaultValues: []
        }
    };

    const actions = [{
            key: 1,
            icon: 'plus',
            label: t("conversationActions.new"),
            //color: "blue",
            primary: false,
            onClick: () => { createNewConversation() }
    }]

    const storeActions = [{
        label: selectedStore ? selectedStore.name : t("store"),
        triggerColor: "primary",
        triggerOutline: true,
        key: "store-selector",
        content: () => <div className="select-in-dropdown-container">
            <Select
                expanded
                options={connectedStores.map(s => ({ text: s.name, value: s.id, checked: selectedStore && s.id === selectedStore.id }))}
                getValue={(values) => {
                    if (!selectedStore || values[0] !== selectedStore.id) {
                        setSelectedStore(companyAvailableStores.find(s => s.id === values[0]));
                        setSelectedChat(null)
                    }
                }} />
        </div>
    }];

    if (hide) return null;
    return (
        <div className="col-12 col-md-4 p-0 chats-container" id="chats-container">
            <Toast containerId="chats-container" autoClose={false} />
            <div className="chats-container-header">
                <div className="store-selector-container">
                    <h4 className="store-selector-title">
                        {isMedium ? t("conversationWithMobile") : t("conversationWith")}
                    </h4>
                    <Actions
                        className="store-selector-dropdown-container"
                        actions={storeActions}
                        renderMode={RenderMode.DROPDOWN}
                    />
                </div>
                <MDBContainer className="chats-tabs">
                    {/* <MDBNav color="primary" tabs>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={selectedTab === 0}
                                onClick={() => {
                                    setSelectedTab(0);
                                    setSelectedChat(null);
                                }}
                                role="tab"
                            >
                                <MDBIcon icon="check" /> {t("openChats")}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={selectedTab === 2}
                                onClick={() => {
                                    setSelectedTab(2);
                                    setSelectedChat(null);
                                }}
                                role="tab"
                            >
                                <MDBIcon icon="envelope" /> {t("closedChats")}
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav> */}
                    <div className="conversation-actions-container">
                        <Filters
                            //title={t("title")}
                            showInfo
                            schemas={schemas}
                            values={conversationFilterValues}
                            onChange={(newValues) => {
                                //console.log(newValues)
                                setConversationFilterValues(newValues);
                            }}
                            actions={ canCreateConversationOnSelectedStore && actions }
                        />
                        {/* {canCreateConversationOnSelectedStore &&
                            <MDBBtn
                                rounded
                                size={"sm"}
                                tag="a"
                                floating
                                flat
                                onClick={createNewConversation}>
                                <MDBIcon fas="true" icon="plus" />
                            </MDBBtn>
                        } */}
                    </div>
                </MDBContainer>
            </div>
            {chatsList && chatsList.length > 0
                ? <div className="chats-list">
                    {chatsList.map(c => (
                        <ConversationItem
                            setClicked={setClicked}
                            selected={clicked === c.id}
                            conversation={c}
                            key={c.id}
                        />
                    ))}
                </div>
                : <div className="empty-state">
                    No hay resultados
                </div>
            }
            <div style={{ position: 'relative', height: '5px', backgroundColor: "#1ab394" }} ref={bottomOfConversationList} />
            {
                loadingConversations &&
                <div className="top-of-the-conversation lds-ellipsis">
                    <div></div><div></div><div></div><div></div>
                </div>
            }
        </div>
    );
}


ChatsContainer.translations = { ...translations }

export default translate(ChatsContainer);
