import { applicationConstants } from './applicationConstants';

export const companyChange = (company) => {
	return {
        type: applicationConstants.SELECTED_COMPANY_CHANGED,
        payload: {
			selectedCompany: company
        }
    }
}

export const toggleNavigationBar = () => {
    return {
        type: applicationConstants.TOGGLE_NAVIGATION_BAR,
    }
}

export const setAvailableStores = (stores) => {
	return {
		type: applicationConstants.SET_AVAILABLE_STORES,
		payload : {
			availableStores : stores
		}
	}
}

export const setAvailableCompanies = (companies) => {
	return {
		type: applicationConstants.SET_AVAILABLE_COMPANIES,
		payload : {
			availableCompanies : companies
		}
	}
}
