import React, { Component } from 'react';
import { MixpanelConsumer } from 'react-mixpanel/lib/MixpanelContext';
import { emitTrackingEvent, emitUnsecureTrackingEvent } from '../api/trackingEvent';

export const trackers = {
    INTERCOM: "INTERCOM",
    MIXPANEL: "MIXPANEL",
    BACKEND: "BACKEND",
    BACKEND_UNSECURE: "BACKEND_UNSECURE"
}

const getAllExternalTrackers = () => {
    return [trackers.INTERCOM, trackers.MIXPANEL]
}

const withEventTracking = (WrappedComponent) => {
    class ComponentWithEventTracking extends Component {
        render() {
            const self = this;
            return (<MixpanelConsumer>
                {mixpanel => (<WrappedComponent
                    {...self.props}
                    mixpanel={mixpanel}
                    intercom={window.Intercom}
                    trackEvent={(event, metadata = {}, updateIntercom = false, targets = [...getAllExternalTrackers()]) => {
                        if (targets.includes(trackers.MIXPANEL)) mixpanel.track(event, { ...metadata })
                        if (targets.includes(trackers.INTERCOM)) {
                            window.Intercom("trackEvent", event, { ...metadata })
                            if (updateIntercom){
                                //TODO buscar una mejor forma de hacerlo. Estoy consultando con Intercom.
                                setTimeout(() => { window.Intercom('update') }, 2000)
                            }
						}
						//console.log("sending", event)
                        if (targets.includes(trackers.BACKEND)) emitTrackingEvent({subType: event, source: "BOTBIT_BACKOFFICE", ...metadata})
                        if (targets.includes(trackers.BACKEND_UNSECURE)) emitUnsecureTrackingEvent({subType: event, source: "BOTBIT_BACKOFFICE", ...metadata})
                    }}
                />)}
            </MixpanelConsumer>)
        }
    }

    return ComponentWithEventTracking
}

export default withEventTracking;