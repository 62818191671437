import axiosClient from './axiosClient'

export const getHostedPage = (subscriptionId, planId) => {
    return axiosClient.get(`/free-trial/chargebee/hosted-page?subscriptionId=${subscriptionId}&planId=${planId}`);
}

export const getFreeTrialStatus = (userName) => {
	return axiosClient.get(`/free-trial/status?userName=${userName}`);
}

export const markSubscriptionAsProcessing = (subscriptionId) => {
	return axiosClient.post(`/free-trial/subscription/${subscriptionId}/processing`);
}