import { useSelector } from "react-redux";
import { getFirstHundredReplyTemplates } from "../../../api/feedbackApi";

const { useState, useEffect } = require("react");

const useCompanyTemplates = (category) => {

    const selectedCompany = useSelector(state => state.application.selectedCompany.id);

    const [templates, setTemplates] = useState();

    useEffect(()=>{
        if(!templates)
            getFirstHundredReplyTemplates(selectedCompany, category).then(
                res => {
                    setTemplates(res.data.data.items);
                }
            );
    },[])

    return templates || []
}

export default useCompanyTemplates;