import React from 'react'
import { translate } from "react-i18next";
import EventsContainer from '../../events/EventsContainer';

const UserDetails = ({ t, events, updateUser }) => (
  <EventsContainer updateUser={updateUser} mode="feed" toggle={false} getEvents={
    (filters) =>
      new Promise((resolve, reject) => {
        resolve(events.map(event => mapEvent(event)));
      })} />
);

/*
 * Mapea el format de evento que viene en el servicio /stores/analytics/user-activity
 * FIXME: volar esto, el visualizador de eventos tiene que levantar los eventos del servicio de 
 * eventos, esto está por backward compatibility. 
 */
const mapEvent = (event) => {
  let _event = event;
  _event.store = {
    id: event.storeId,
    name: event.storeName
  }
  _event.subType = event.eventSubType;
  return _event;
}

export default translate('common')(UserDetails)
