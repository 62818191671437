import React from "react"
import './valueComparator.css'

const ValueComparator = ({ current, previous, variation, formatter, inverse }) => {
    return <>
        {current !== null && <span className="value">{formatter ? formatter(current) : current}</span>}
        {current === null && <span className="value">{formatter ? formatter(0) : 0}</span>}
        {variation !== null && <small><span style={{ color: `${getColor(variation, inverse)}` }}> ({variation}%)</span></small>}<br />
        {previous !== null && <small>vs {formatter ? formatter(previous) : previous}</small>}
        {previous === null && <small>vs {formatter ? formatter(0) : 0}</small>}
    </>
}

const getColor = (value, inverse) => value === 0 ? 'orange' : value > 0 ? inverse ? 'red' : 'green' : inverse ? 'green' : 'red'

export default ValueComparator