import { envConfig } from "../../config";

export const translations = {
    metrics: {
        es: "Métricas",
        en: "Metrics"
    },
    store: {
        es: "Tienda",
        en: "Store"
	},
	company: {
        es: "Empresa",
        en: "Company"
    },
    score: {
        es: "Puntaje",
        en: "Score"
    },
    reviews: {
        es: "Reseñas",
        en: "Reviews"
    },
    responses: {
        es: "Respuestas",
        en: "Responses"
    },
    responseRate: {
        es: "Tasa de Respuestas",
        en: "Response Rate"
    },
    responseReviewRate: {
        es: `Tasa de respuesta de reseñas de ${envConfig.companyName}`,
        en: `${envConfig.companyName} review response rate`
    },
    stimulatedByBotbit: {
        es: `Estimuladas por ${envConfig.companyName}`,
        en: `Stimulated by ${envConfig.companyName}`
    },
    exportPDF: {
        es: "Exportar PDF",
        en: "Export PDF"
    },
    comparison: {
        es: "Comparación",
        en: "Comparison"
    },
    period: {
        es: "Período",
        en: "Period"
    },
    custom: {
        es: "Personalizado",
        en: "Custom"
    },
    source: {
        es: "Fuente",
        en: "Source"
    },
    allSources: {
        es: "Todas las fuentes",
        en: "All sources"
    },
    withoutReply: {
        es: "Sin responder",
        en: "Unanswered"
    },
    withComment: {
        es: "Con comentario",
        en: "With commentary"
    },
    autoReplied: {
        es: "Respondida automáticamente",
        en: "Autoreplied"
    },
    province: {
        es: "Provincia",
        en: "Province"
    },
    allProvinces: {
        es: "Todas las provincias",
        en: "All provinces"
    },
    locality: {
        es: "Localidad",
        en: "Location"
    },
    allLocalities: {
        es: "Todas las localidades",
        en: "All locations"
    },
    automatic: {
        es: "Automática",
        en: "Automatic"
    },
    scoreEvolution: {
        es: "Evolución del promedio de puntaje de las reseñas durante el período seleccionado.",
        en: "Evolution of the average score of the reviews during the selected period."
    },
    botbitStimulatedReviews: {
        es: `Reseñas estimuladas por ${envConfig.companyName}`,
        en: `${envConfig.companyName} stimulated reviews`
    },
    quantityReviews: {
        es: `Cantidad de reseñas en sitios públicos estimuladas a partir de encuestas de satisfacción de ${envConfig.companyName}.`,
        en: `Number of reviews on public sites stimulated from ${envConfig.companyName} satisfaction surveys.`
    },
    promotersPercent: {
        es: "% Promotores en sitios públicos",
        en: "% Promoters in public places"
    },
    fiveStarsPercent: {
        es: `Porcentaje de reseñas públicas de 5 estrellas que fueron estimuladas por ${envConfig.companyName}.`,
        en: `Percentage of 5-star public reviews that were stimulated by ${envConfig.companyName}.`
    },
    overallResponseRate: {
        es: "Tasa de respuesta global",
        en: "Overall response rate"
    },
    and: {
        es: "y",
        end: "and"
    },
    responseRateEvolution: {
        first: {
            es: "Evolución del % de reseñas repondidas",
            en: "Evolution of the % of answered reviews"
        },
        second: {
            es: "automática",
            en: "automatic"
        },
        third: {
            es: "manualmente",
            en: "manually"
        },
        fourth: {
            es: "durante el período seleccionado",
            en: "during the selected period"
        }
    },
    reviewsQuantity: {
        es: "Cantidad de reseñas",
        en: "Number of reviews"
    },
    reviewsEvolution: {
        es: "Evolución de la cantidad de reseñas durante el período seleccionado.",
        en: "Evolution of number of reviews during the selected period."
    },
    critics: {
        es: "Detractores",
        en: "Critics"
    },
    criticsRate: {
        es: "Tasa de detractores en sitios públicos",
        en: "Critics rate in plublic places"
    },
    time: {
        es: "Tiempos",
        en: "Time"
    },
    timeAverage: {
        es: "Tiempos promedio de respuesta",
        en: "Average response time"
    },
    replyRate: {
        es: "Tasa de respuesta",
        en: "Reply rate"
    },
    insightsDisclaimer: {
        es: "(*) La información mostrada en los gráficos es desde el primer día de cada semana/mes",
        en: "(*) The information shown in the graphs is from the first day of each week/month"
    }
}
