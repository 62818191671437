import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Navigation from "../../../components/layout/navigation/Navigation.js";
// import Footer from "../../../components/layout/Footer.js";
import Header from "../../../components/layout/header/HeaderContainer.js";
import PageTitle from "../../../components/layout/PageTitle";
import { connect } from "react-redux";
import AdminActivityContainer from "../../../components/adminActivity/AdminActivityContainer";
import {
  getStoresForAdministrator,
  getCompaniesBasicDataForAdministrator,
} from "../../../api/administratorApi.js";
import { getCompany } from "../../../api/companyApi.js";

import {
  companyChange,
  toggleNavigationBar,
  setAvailableCompanies,
  setAvailableStores,
} from "./applicationActions";
import { setLoading } from "./miscActions";

import EmailActivityContainer from "../../../components/emailActivity/EmailActivityContainer";
import APIConfigContainer from "../../../components/apiConfig/APIConfigContainer";
import Confirmation from "../../../components/confirmation/ConfirmationContainer";

import { getPageTitleParams } from "../../../config/menu";
import {
  getNotificationsForAdministrator,
  markNotificationReaded,
} from "../../../api/administratorApi";
import { envConfig } from "../../../config/config.js";
import { securityConfig } from "../../../config/security.js";
import "./AppRouter.css";
import ClientsContainer from "../../../components/clients/ClientsContainer.js";
import TaskContainer from "../../../components/clients/TaskContainer.js";
import TasksContainer from "../../../components/tasks/TasksContainer.js";
import OneSignalOptInContainer from "../../../components/layout/onesignaloptin/OneSignalOptInContainer.js";
import CampaignsListContainer from "../../../components/campaigns/CampaignsListContainer.js";
import SalesForceListContainer from "../../../components/salesforce/SalesForceListContainer.js";
import UserDetails from "../../../commons/components/users/details/UserDetailsContainer.js";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getMenuFromLocation } from "../../../config/menu.js";
import MaximalEditor from "../../../components/campaigns/MaximalEditor/MaximalEditor.js";
import AnalyticsContainer from "../../../components/campaigns/Analytics/AnalyticsContainer.js";
import SegmentsContainer from "../../../components/segments/SegmentsContainer";
import OrdersContainer from "../../../components/orders/OrdersContainer";
import ConfigurationsContainer from "../../../components/configurations/ConfigurationsContainer.js";
import FeedbackDashboardContainer from "../../../components/feedback/DashboardContainer.js";
import ReviewsContainer from "../../../components/feedback/ReviewsContainer.js";
import ReviewContainer from "../../../components/feedback/ReviewContainer.js";
import SocialAccountsContainer from "../../../components/socialAccounts/SocialAccountsContainer";
import GoogleOptInCallback from "../../../components/socialAccounts/GoogleOptInCallback";
import FacebookOptInCallback from "../../../components/socialAccounts/FacebookOptInCallback";
import ConnectedStoresContainer from "../../../components/feedback/ConnectedStoresContainer";
import ReplyTemplatesContainer from "../../../components/feedback/ReplyTemplatesContainer";
import AutoReplyRulesContainer from "../../../components/feedback/AutoReplyRulesContainer";
import NPSContainer from "../../../components/feedback/nps/NPSContainer.js";
import DashboardContainer from "../../../components/dashboard/DashboardContainer";
import { setFeedbackFilters } from "../../../components/feedback/FeedbackFiltersActions";
import IntegrationsContainer from "../../../components/integrations/IntegrationsContainer.js";
import OptInCallback from "../../../components/integrations/OptInCallback/OptInCallback";
import FinishIntegration from "../../../components/integrations/FinishIntegration/FinishIntegration.js";
import SuspendStore from "../../../components/SuspendStore/SuspendStore";
import {
  Modal,
  ModalHeader,
  ModalBody,
  HeaderAlert,
} from "botbit-ui-components";
import ReactivateStores from "../Reactivation/ReactivateStores.js";
import Connect from "../../../components/integrations/Connect/Connect.js";
import AccountList from "../../../components/integrations/AccountList.js";
import Company from "../../../components/companies/Company.js";
import CompanyQR from "../../../components/companies/CompanyQR/CompanyQR.js";
import CompanyTabletopDownloader from "../../../components/tabletop/companyTabletopDownloader.js";
import ReviewsWidget from "../../../components/feedback/ReviewsWidget.js";
import UserReviewHistoryContainer from "../../../components/UserReviewHistory/UserReviewHistoryContainer.js";
import { translations } from "../../../config/translations/AuhenticatedRouter";
import translate from "../../../config/translations/translate";

import { sendGAEvent } from "../utils/trackers.js";
import UpgradePlan from "../../../components/UpgradePlan/UpgradePlan.js";
import UpgradePlanModal from "../../../components/UpgradePlan/UpgradePlanModal.js";
import ChargeBeeHeader from "./ChargeBee/ChargeBeeHeader.js";
import Leaderboard from "../../../components/Leaderboard/Leaderboard.js";
import AdminInvite from "../../../components/AdminInvite/AdminInvite.js";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import withEventTracking from "../../../config/withEventTracking";
import { trackers } from "../../../config/withEventTracking";
import ChecklistContainer from "../../../components/checklist/ChecklistContainer.js";
import RRFunnelView from "../../../components/RRFunnel/RRFunnelView.js";
import CaptiveGraphView from "../../../components/dashboard/CaptiveGraph/CaptiveGraphView.js";
import MessengerCore from "../../../components/Messenger/MessengerCore.js";
import MessengerWrapper from "../../../components/Messenger/MessengerWrapper.js";
import ChatsDashboardContainer from "../../../components/Messenger/ChatsDashboardContainer.js";
import IssuesDashboardContainer from "../../../components/Issues/IssuesDashboardContainer.js";
import FlowCreator from "../../../components/Messenger/MessengerFlow/FlowCreator.js";
import FlowsDashboardContainer from "../../../components/Messenger/MessengerFlow/FlowsDashboardContainer.js";
import Administrators from "../../../components/administrators/Administrators.js";
import IssuesConfigurationContainer from "../../../components/Issues/IssueTypesConfigurationContainer.js";
import AdministratorEdit from "../../../components/administrators/Administrator.js";
import IssueTypeEditContainer from "../../../components/Issues/IssueTypeEditContainer.js";
import SentimentContainer from "../../../components/feedback/sentiment/SentimentContainer.js";
import KeywordsConfigContainer from "../../../components/feedback/sentiment/KeywordsConfigContainer.js";
import QuestionStatsContainer from "../../../components/feedback/questions/QuestionStatsContainer.js";
import SocialLocationPostContainer from "../../../components/LocalSEO/SocialLocationPostContainer.js";
import { BusinessProfilesTableContainer } from "../../../components/LocalSEO/googleBusinessProfile/index.js";
import LocalPagesContainer from "../../../components/LocalSEO/LocalPages/index.js";
import LocalPagesLanding from "../../../components/LocalSEO/LocalPagesLanding/index.js";
import { NoResultsImage } from "../../../components/UserReviewHistory/NoResultsImage.js";

const MySwal = withReactContent(Swal);

class AuthenticatedRouter extends React.Component {
  constructor(props) {
    super(props);

    this.searchParams = new URLSearchParams(props.location.search);

    const openReactivateModalByQuery = this.searchParams.get("reactivate");
    this.showModalAtInit = openReactivateModalByQuery === "true";

    this.state = {
      initializing: true,
      notificationsOpen: false,
      existSuspendedStores: false,
      shouldShowModal: false,
      shouldShowSuspendedStores: true,
      showUpgradeModal: false,
      fromChecklist: false,
    };
    this.handleClickOutsideNotification =
      this.handleClickOutsideNotification.bind(this);
  }

  updateURLForRefresh = () => {
    this.searchParams.delete("reactivate");
    window.history.pushState(
      null,
      null,
      `${window.location.pathname}?${this.searchParams.toString()}`
    );
  };

  showModal = (show) => {
    this.setState({ ...this.state, shouldShowModal: show });
  };

  modalToggle = (show) => {
    if (!this.showModalAtInit) {
      this.showModal(show);
    } else {
      this.updateURLForRefresh();
      this.showModal(show);
    }
  };

  expandNotifications = () => {
    if (this.state.notifications == null) {
      this.setState({
        notificationsOpen: false,
      });
    } else {
      this.setState({
        notificationsOpen: !this.state.notificationsOpen,
      });
    }
  };

  handleClickOutsideNotification() {
    if (this.state.notificationsOpen) {
      this.setState({
        notificationsOpen: false,
      });
    }
  }

  getNotifications = () => {
    getNotificationsForAdministrator(this.props.admin.id, (error) => {
      return { ignore: true };
    }).then((res) => {
      let notifications = res.data.data;
      this.setState({ ...this.state, notifications: notifications });
    });
  };

  markAsReadAndGotoNotification = (event, notification) => {
    markNotificationReaded(notification.id).then(() => {
      if (notification.target == null) {
        return;
      }
      if (notification.target.indexOf(".php") > 0) {
        window.location.href =
          envConfig.frontendPhpUrl + "/" + notification.target;
      } else {
        // ruta react
        this.props.history.push("/" + notification.target);
        this.handleClickOutsideNotification();
      }
    });
  };

  checkIfExistSuspendedStores = () => {
    const companyStores =
      this.props.selectedCompany &&
      this.props.availableStores &&
      this.props.availableStores.filter(
        (s) => s.companyId === this.props.selectedCompany.id
      );
    const suspendedStore = companyStores
      ? companyStores.find((store) => store.status === "SUSPENDED")
      : null;
    return suspendedStore ? true : false;
  };

  componentDidUpdate() {
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    // Si se viene de la vista de checklist, se provee un banner para poder regresar
    const fromChecklistParam = urlSearchParams.get("fromChecklist");
    const fromChecklist = !!(
      fromChecklistParam && fromChecklistParam === "true"
    );
    if (fromChecklist !== this.state.fromChecklist) {
      this.setState({ fromChecklist: fromChecklist });
    }
  }

  async componentDidMount() {
    this.props.setLoading(true);
    const { t } = this.props;
    const getCompanies = getCompaniesBasicDataForAdministrator(
      this.props.admin.id,
      true
    );
    const getStores = getStoresForAdministrator(this.props.admin.id);
    const responses = await Promise.all([getCompanies, getStores]);

    const companies = responses[0].data.data;
    const stores = responses[1].data.data;

    const hasActiveStores = stores.find((store) => store.status !== "INACTIVE");
    if (!hasActiveStores) {
      this.logout();
      MySwal.fire({
        type: "error",
        title: "Ups",
        text: t("noActiveStores"),
        heightAuto: false,
      }).then(() => {
        this.props.setLoading(false);
      });
      return;
    }

    await this.setCurrentCompany(companies);

    this.props.setAvailableCompanies(companies);
    this.props.setAvailableStores(stores);

    this.props.setLoading(false);

    this.setState((state) => {
      return {
        availableStores: stores,
        availableCompanies: companies,
        initializing: false,
      };
    });

    this.getNotifications();
    this.timer = setInterval(this.getNotifications, 120000);

    const user = {
      user_id: this.props.admin.id,
      email: this.props.admin.email,
      name: this.props.admin.name + " " + this.props.admin.lastName,
      _companyId: this.props.admin.companyId,
    };
    const intercomInit = {
      app_id: envConfig.INTERCOM_APP_ID,
      ...user,
      custom_launcher_selector: "#intercomChat",
    };
    window.Intercom("boot", intercomInit);
    if (window.innerWidth < 768)
      window.Intercom("update", {
        hide_default_launcher: window.innerWidth < 768,
      });

    const urlSearchParams = new URLSearchParams(this.props.location.search);
    // Si se recibe la orden de lanzar un tour de intercom por requestParam, se lanza
    const tourId = urlSearchParams.get("startTour");
    if (tourId) window.Intercom("startTour", tourId);
  }

  setCurrentCompany = async (companies) => {
    companies.length === 1
      ? //this.props.companyChange(companies[0]) :
        await this.getCompanyAndStoreInRedux(companies[0].id)
      : await this.assumeCompaniesFromLocationOrSetTheFirstOne(companies);

    if (this.showModalAtInit) {
      this.setState({ ...this.state, shouldShowModal: true });
    }
  };

  getCompanyAndStoreInRedux = async (companyId) => {
    const getCopmanySynch = getCompany(companyId);
    const responses = await Promise.all([getCopmanySynch]);
    const newCompany = responses[0].data.data;
    this.props.companyChange(newCompany);
  };

  assumeCompaniesFromLocationOrSetTheFirstOne = async (companies) => {
    const params = new URLSearchParams(this.props.location.search);
    const companyId = parseInt(params.get("companyId")) || undefined;
    const companyParam = companyId
      ? companies.find((company) => company.id === companyId)
      : undefined;
    // Si hay company para seleccionar del request param, se selecciona
    if (companyParam) {
      //this.props.companyChange(companyParam)
      await this.getCompanyAndStoreInRedux(companyParam.id);
      return;
    }
    // si no hay company elegida (o la company que está no existe en la lista de companies),
    // me aseguro de que haya aunque sea la primera company de la lista
    const selectedCompany = this.props.selectedCompany
      ? companies.find(
          (company) => company.id === this.props.selectedCompany.id
        )
      : undefined;
    if (!selectedCompany)
      //this.props.companyChange(companies[0])
      await this.getCompanyAndStoreInRedux(companies[0].id);
    // si hay company seleccionada la seteo en el contexto redux (caso F5)
    else {
      //this.props.companyChange(selectedCompany);
      await this.getCompanyAndStoreInRedux(selectedCompany.id);
    }
  };

  componentWillUnmount = () => {
    if (this.props.admin != null) clearInterval(this.timer);
  };

  logout = () => {
    this.props.history.push("/logout");
  };

  companyChanged = async (companyId) => {
    if (!companyId) return;
    const params = this.buildHomeParams(companyId);
    // no hace falta hacerlo aca, cuando recarga la pagina lo setea
    //const selectedCompany = this.state.availableCompanies.find(company => company.id === companyId)
    //this.props.companyChange(selectedCompany)
    //await this.getCompanyAndStoreInRedux(companyId)
    // Siempre que se cambia de company, se redirige al home

    window.location.href = "/dashboard?" + params.toString();
    //this.props.history.push('/dashboard?' + params.toString())
  };

  buildHomeParams = (companyId) => {
    const params = new URLSearchParams(this.props.location.search);
    params.set("companyId", companyId);
    return params;
  };

  removeFeedbackFilters = () => {
    if (
      !this.props.location.pathname.includes("feedback") &&
      this.props.feedbackFilters
    ) {
      this.props.setFeedbackFilters(undefined);
    }
  };

  setShowUpgradeModal = (show, feature = "") => {
    this.setState({ ...this.state, feature, showUpgradeModal: show });
  };

  checkPlanPermissions = (e) => {
    if (this.state.showUpgradeModal) return;
    var element = e.target;
    var securityKey = null;
    var i = 0;
    //console.log("looking data botbit security")
    while (element && !securityKey && i <= 10) {
      //console.log("looking data botbit security " + i, element)
      securityKey = element.getAttribute("data-botbit-security");
      element = element.parentElement;
      i++;
    }
    if (!securityKey) return;
    //console.log("security key found", securityKey)
    var itemSecurity = securityConfig.elements[securityKey];
    //console.log("security config", itemSecurity)
    if (!itemSecurity) return;

    //const company = this.state.availableCompanies.find(company => company.id === this.props.selectedCompany.id);
    const company = this.props.selectedCompany;
    const authorized = itemSecurity.operations.every((v) =>
      company.operations.includes(v)
    );
    if (!authorized) {
      sendGAEvent(
        "purchase_intention",
        "upgrade_showed",
        itemSecurity.feature,
        1,
        null,
        null
      );
      e.preventDefault();
      e.stopPropagation();
      this.setShowUpgradeModal(true, itemSecurity.operations[0]);
    }
  };

  render() {
    const { t } = this.props;
    this.removeFeedbackFilters();
    let availableCompanies =
      (this.state && this.state.availableCompanies) || [];
    let availableStores =
      this.state && this.state.availableStores
        ? this.state.availableStores
        : [];
    const pageTitleParams = getPageTitleParams(
      this.props.history.location.pathname
    );
    return (
      <React.Fragment>
        <div onClickCapture={this.checkPlanPermissions}>
          {/* <Error error={this.props.serverError} popupMode={true} cleanError={this.props.cleanError} /> */}
          {/* <MDBModal className="review-text" isOpen={this.state.showUpgradeModal} toggle={() => this.setShowUpgradeModal(!this.state.showUpgradeModal)}>
						<MDBModalHeader toggle={() => this.setShowUpgradeModal(!this.state.showUpgradeModal)}>Blah blah blah</MDBModalHeader>
						<MDBModalBody>
							<p>Upgrade</p>
							<button onClick={() => {
								// TODO: aca tiene que el interestedFeature.id que viene en el servicio 
								sendGAEvent('purchase_intention', 'upgrade_clicked', "PONER_FEATURE_ACA", 1, null, null);
								window.Intercom('showNewMessage');
							}
							}>Upgrade</button>
						</MDBModalBody>
					</MDBModal> */}
          <UpgradePlanModal
            isOpen={this.state.showUpgradeModal}
            toggle={() =>
              this.setShowUpgradeModal(!this.state.showUpgradeModal)
            }
            product={this.state.feature}
          />
          <Navigation
            handleLogout={this.logout}
            admin={this.props.admin}
            token={this.props.token}
            selectedCompany={this.props.selectedCompany}
            navigationMenuExpanded={this.props.navigationMenuExpanded}
          />
          <div id="page-wrapper" className="gray-bg">
            <OneSignalOptInContainer
              selectedCompany={this.props.selectedCompany}
              adminId={this.props.admin.id}
            />
            <Header
              handleLogout={this.logout}
              admin={this.props.admin}
              availableCompanies={availableCompanies}
              selectedCompany={this.props.selectedCompany}
              selectedStore={this.props.selectedStore}
              availableStores={availableStores}
              onSelectCompany={(selected) => this.companyChanged(selected[0])}
              toggleNavigationBar={this.props.toggleNavigationBar}
              notifications={this.state.notifications}
              clickNotificationHandler={this.markAsReadAndGotoNotification}
              expandNotifications={this.expandNotifications}
              handleClickOutsideNotification={
                this.handleClickOutsideNotification
              }
              notificationsOpen={this.state.notificationsOpen}
            />
            <ChargeBeeHeader
              company={this.props.selectedCompany}
              companyChange={this.props.companyChange}
            />
            {this.state.shouldShowSuspendedStores &&
              this.checkIfExistSuspendedStores() && (
                <HeaderAlert
                  type="danger"
                  action={() => (
                    <button
                      className={`link-like-btn ${
                        (window.innerWidth < 992 && "padding-5px") || ""
                      }`}
                      style={{
                        color: "#337ab7",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      onClick={() => this.showModal(true)}
                    >
                      {t("reactivate")}
                    </button>
                  )}
                  toggle={() =>
                    this.setState({
                      ...this.state,
                      shouldShowSuspendedStores: false,
                    })
                  }
                >
                  <div
                    style={
                      (window.innerWidth < 992 && { padding: "5px" }) || {}
                    }
                  >
                    {t("youHaveSuspendedStores.first")}{" "}
                    <b>{t("youHaveSuspendedStores.second")}</b>{" "}
                    {t("youHaveSuspendedStores.third")}.
                  </div>
                </HeaderAlert>
              )}
            {this.state.fromChecklist && (
              <HeaderAlert
                type="warning"
                action={() => (
                  <>
                    {t("returnToChecklist")}{" "}
                    <button
                      className={`link-like-btn ${
                        (window.innerWidth < 992 && "padding-5px") || ""
                      }`}
                      style={{
                        color: "#337ab7",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      onClick={() => {
                        this.setState({ ...this.state, fromChecklist: false });
                        this.props.history.push("/checklist");
                      }}
                    >
                      {t("returnToChecklistList")}
                    </button>
                  </>
                )}
                toggle={() =>
                  this.setState({ ...this.state, fromChecklist: false })
                }
              ></HeaderAlert>
            )}
            {this.state.shouldShowModal && (
              <div id="reactivate-modal-container">
                <Modal
                  isOpen={this.state.shouldShowModal === true}
                  className="reactivate-modal"
                >
                  <ModalHeader toggle={this.modalToggle}>
                    {t("reactivateStores")}
                  </ModalHeader>
                  <ModalBody>
                    <ReactivateStores
                      adminId={this.props.admin.id}
                      toggle={this.modalToggle}
                      company={this.props.selectedCompany}
                    ></ReactivateStores>
                  </ModalBody>
                </Modal>
              </div>
            )}
            {pageTitleParams.showPageTitle === true && (
              <PageTitle {...pageTitleParams} />
            )}
            <div className="main-content">
              {!this.state.initializing && (
                <ConectedSwitch
                  selectedStore={this.props.selectedStore}
                  availableStores={this.state.availableStores}
                  selectedCompany={this.props.selectedCompany}
                  availableCompanies={this.state.availableCompanies}
                >
                  {/* <Route path={'/'} exact component={DashboardContainer}/> */}
                  <Redirect from="/" exact to="/dashboard" />
                  {this.props.admin.operations.includes("REVIEWS__VIEW")&&
                    <Route path={"/dashboard"} component={DashboardContainer} />
                  }
                  {
                    !this.props.admin.operations.includes("REVIEWS__VIEW")&&
                      <Route path={"/dashboard"} component={()=>(
                        <div className="text-center my-5">
                          <NoResultsImage/>
                        </div>
                        )
                      } />
                  }
                  <Route
                    path={"/emailActivity"}
                    render={(props) => <EmailActivityContainer />}
                  />
                  <Route
                    path={"/configurations"}
                    render={(props) => <ConfigurationsContainer />}
                  />
                  <Route path={"/api"} render={() => <APIConfigContainer />} />
                  <Route
                    path={"/confirmation"}
                    render={() => (
                      <Confirmation queryString={this.props.location.search} />
                    )}
                  />
                  <Route
                    path={"/tasks"}
                    render={(props) => <TasksContainer />}
                  />
                  <Route
                    path={"/task/:id"}
                    render={(props) => (
                      <TaskContainer taskId={props.match.params.id} />
                    )}
                  />
                  <Route
                    path={"/crm/user/:id"}
                    render={(props) => (
                      <UserDetails
                        queryParams={this.props.location.search}
                        userId={props.match.params.id}
                        inline={false}
                      />
                    )}
                  />
                  <Route
                    path={"/crm"}
                    render={() => (
                      <ClientsContainer
                        queryParams={this.props.location.search}
                      />
                    )}
                  />
                  <Route
                    path={"/campaigns"}
                    exact
                    render={(props) => <CampaignsListContainer />}
                  />
                  <Route
                    path={"/companyDashboard"}
                    exact
                    /*component={Home}*/ render={(props) => "companyDashboard"}
                  />
                  <Route
                    path={"/buy/:product"}
                    exact
                    /*component={Home}*/ render={(props) => (
                      <UpgradePlan product={props.match.params.product} />
                    )}
                  />
                  {/* <Route path={'/companies'} exact render={(props) => (<CompaniesListContainer />)} /> */}
                  <Route
                    path={"/companies"}
                    exact
                    render={(props) => <Company />}
                  />
                  <Route
                    path={"/companies/setQr"}
                    exact
                    render={(props) => <CompanyQR />}
                  />
                  <Route
                    path={"/adminActivity"}
                    render={(props) => <AdminActivityContainer />}
                  />
                  <Route
                    path={"/salesforce"}
                    exact
                    render={(props) => <SalesForceListContainer />}
                  />
                  <Route
                    path={"/campaigns"}
                    exact
                    render={(props) => <CampaignsListContainer />}
                  />
                  <Route
                    path={"/campaigns/:id/create"}
                    render={(props) => (
                      <MaximalEditor
                        mode={"create"}
                        id={props.match.params.id}
                        queryParams={this.props.location.search}
                      ></MaximalEditor>
                    )}
                  />
                  <Route
                    path={"/campaigns/create"}
                    render={(props) => (
                      <MaximalEditor
                        mode={"create"}
                        queryParams={this.props.location.search}
                      ></MaximalEditor>
                    )}
                  />
                  <Route
                    path={"/campaigns/:id/edit"}
                    render={(props) => (
                      <MaximalEditor
                        mode={"edit"}
                        id={props.match.params.id}
                        queryParams={this.props.location.search}
                      ></MaximalEditor>
                    )}
                  />
                  <Route
                    path={"/campaigns/:id"}
                    render={(props) => (
                      <AnalyticsContainer campaignId={props.match.params.id} />
                    )}
                  />
                  <Route
                    path={"/segments"}
                    render={(props) => <SegmentsContainer></SegmentsContainer>}
                  />

                  <Route
                    path={"/orders/:view"}
                    render={(props) => (
                      <OrdersContainer view={props.match.params.view} />
                    )}
                  />

                  <Route
                    path={"/feedback/metrics"}
                    component={FeedbackDashboardContainer}
                  />
                  <Route
                    path={"/feedback/survey"}
                    component={QuestionStatsContainer}
                  />
                  <Route path={"/feedback/nps"} component={NPSContainer} />
                  <Route
                    path={"/feedback/reviews/:reviewId"}
                    component={ReviewContainer}
                  />
                  <Route
                    path={"/feedback/reviews"}
                    component={ReviewsContainer}
                  />
                  <Route
                    path={"/feedback/replytemplates"}
                    component={ReplyTemplatesContainer}
                  />
                  <Route
                    path={"/feedback/autoreplyrules"}
                    component={AutoReplyRulesContainer}
                  />
                  <Route
                    path={"/feedback/connectedstores"}
                    component={ConnectedStoresContainer}
                  />
                  <Route path={"/feedback/widget"} component={ReviewsWidget} />
                  <Route
                    path={"/feedback"}
                    component={FeedbackDashboardContainer}
                  />
                  <Route path={"/sentiment"} component={SentimentContainer} />

                  <Route
                    path={"/socialaccounts"}
                    component={SocialAccountsContainer}
                  />
                  <Route
                    path={"/google-optin-callback"}
                    component={GoogleOptInCallback}
                  />
                  <Route
                    path={"/facebook-optin-callback"}
                    component={FacebookOptInCallback}
                  />
                  <Route
                    path={"/integrations"}
                    exact
                    component={IntegrationsContainer}
                  />
                  <Route
                    path={"/integrations/callback"}
                    exact
                    component={OptInCallback}
                  />
                  <Route
                    path={"/integrations/finish/:id"}
                    exact
                    render={(props) => (
                      <FinishIntegration id={props.match.params.id} />
                    )}
                  />
                  <Route
                    path={"/integrations/connect/:sourceId"}
                    exact
                    component={Connect}
                  />
                  <Route path={"/suspend/:storeId"} component={SuspendStore} />
                  <Route
                    path={"/integrations/accounts/:sourceId"}
                    exact
                    component={AccountList}
                  />
                  <Route
                    path={"/tabletop"}
                    component={CompanyTabletopDownloader}
                  />
                  <Route
                    path={"/reviewHistory"}
                    component={UserReviewHistoryContainer}
                  />
                  <Route path={"/leaderboard"} component={Leaderboard} />
                  <Route path={"/settings/invite"} component={AdminInvite} />
                  <Route
                    path={"/detailedReviewRequest"}
                    component={RRFunnelView}
                  />
                  <Route path={"/checklist"} component={ChecklistContainer} />
                  <Route path={"/captiveGraph"} component={CaptiveGraphView} />
                  <Route
                    path={"/chat/dashboard"}
                    component={ChatsDashboardContainer}
                  />
                  <Route
                    path={"/chat/conversations/:conversationId"}
                    component={MessengerWrapper}
                  />
                  <Route path={"/flows/create"} component={FlowCreator} />
                  <Route
                    path={"/flows/:id"}
                    exact
                    render={(props) => (
                      <FlowCreator id={props.match.params.id} />
                    )}
                  />
                  <Route path={"/flows/"} component={FlowsDashboardContainer} />
                  <Route path={"/chat"} component={MessengerWrapper} />
                  <Route path={"/messenger"} component={MessengerCore} />
                  <Route
                    path={"/issues/dashboard"}
                    component={IssuesDashboardContainer}
                  />
                  <Route
                    path={"/settings/administrators/:id"}
                    exact
                    render={(props) => (
                      <AdministratorEdit
                        id={
                          props.match.params.id === "create"
                            ? undefined
                            : props.match.params.id
                        }
                      />
                    )}
                  />
                  <Route
                    path={"/settings/administrators"}
                    exact
                    component={Administrators}
                  />
                  <Route
                    path={"/settings/sentiments/keywords"}
                    exact
                    component={KeywordsConfigContainer}
                  />
                  <Route
                    path={"/settings/issues"}
                    exact
                    component={IssuesConfigurationContainer}
                  />
                  <Route
                    path={"/settings/issues/:id"}
                    exact
                    render={(props) => (
                      <IssueTypeEditContainer
                        issueTypeId={
                          props.match.params.id === "create"
                            ? undefined
                            : props.match.params.id
                        }
                      />
                    )}
                  />
                  <Route
                    path={"/localseo/posts"}
                    render={() => <SocialLocationPostContainer />}
                  />
                  <Route
                    path={"/localseo/gbp"}
                    render={() => <BusinessProfilesTableContainer />}
                  />
                  <Route
                    path={"/localseo/localpages"}
                    render={() => <LocalPagesContainer />}
                  />
                  <Route
                    path={"/localseo/landingpage"}
                    render={() => <LocalPagesLanding />}
                  />
                </ConectedSwitch>
              )}
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

/*const canEntry = (location, stores, menuEntry) => {
	if (!menuEntry || !menuEntry.product) return true;
	let filteredStores = stores.filter((s) => s[menuEntry.product]);
	return filteredStores.length > 0;
}*/

const ConectedSwitch = withEventTracking(
  withRouter(
    ({
      location,
      children,
      selectedStore,
      availableStores,
      selectedCompany,
      availableCompanies,
      trackEvent,
    }) => {
      //let stores;
      let menuEntry = getMenuFromLocation(location.pathname);

      const locationToCheck = useLocation();
      React.useEffect(() => {
        // runs on location, i.e. route, change
        //console.log('handle route change here', locationToCheck)
        //console.log('menu entry', menuEntry)
        if (menuEntry && menuEntry.key) {
          trackEvent(
            menuEntry.key.toUpperCase() + "_VIEWED",
            { type: "PAGE_VIEW" },
            false,
            [trackers.BACKEND]
          );
        }
      }, [locationToCheck.pathname]);

      //const company = availableCompanies.find(company => company.id === selectedCompany.id);
      const company = selectedCompany;

      if (menuEntry && menuEntry.planOperations) {
        //console.log("operations: ", menuEntry.planOperations);
        const authorized = menuEntry.planOperations.every((v) =>
          company.operations.includes(v)
        );
        if (!authorized) {
          sendGAEvent(
            "purchase_intention",
            "upgrade_showed",
            menuEntry.feature,
            1,
            null,
            null
          );
          return <UpgradePlan product={menuEntry.planOperations[0]} />;
        }
      }

      return <Switch> {children} </Switch>;

      /*if (selectedStore)
		stores = [selectedStore];
	else
		stores = availableStores;
	if (canEntry(location, stores, menuEntry)) {
		return (
			<Switch>
				{children}
			</Switch>
		)
	} else {
		return (
			<BuyProduct product={menuEntry.product} />
		)
	}*/
    }
  )
);

function mapStateToProps(state) {
  return {
    selectedStore: state.application.selectedStore,
    selectedCompany: state.application.selectedCompany,
    availableCompanies: null,
    admin: state.login.admin,
    token: state.login.token,
    navigationMenuExpanded: state.application.navigationMenuExpanded,
    initializing: state.misc.initializing,
    feedbackFilters: state.feedback.filters,
    availableStores: state.application.availableStores,
  };
}

AuthenticatedRouter.translations = { ...translations };

export default connect(mapStateToProps, {
  toggleNavigationBar,
  companyChange,
  setLoading,
  setAvailableCompanies,
  setAvailableStores,
  setFeedbackFilters,
})(withRouter(translate(AuthenticatedRouter)));
