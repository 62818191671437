import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SmallWidget = ({type,style, icon, title, mainText, className}) => {
        const severity = { 'navy-bg': (type === 'success'),
          'lazur-bg': (type === 'info'),
          'yellow-bg': (type === 'warning'),
          'red-bg': (type === 'danger'),
        };
        return (
            <div className={classNames('widget style1', className, severity)}  style={style}>
                <div className="row">
                    <div className="col-4">
                        <FontAwesomeIcon icon={icon?icon:''} size='4x'/>
                    </div>
                    <div className="col-8 text-right">
                         {title&&(<span>{title}</span>)}
                        <h2 className="font-bold">{mainText}</h2>
                    </div>
                </div>
            </div>
        )
};

export default SmallWidget;
