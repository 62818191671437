export const translations = {
    confirmation: {
        es: "Confirmación ",
        en: "Confirmation "
    },
    deleteClient: {
        es: "Eliminar al cliente: ",
        en: "Delete client: "
    },
    confirm: {
        es: "Confirmar",
        en: "Proceed"
    },
    deleted: {
        es: "!Eliminado! ",
        en: "Deleted! "
    },
    userDeleted: {
        es: "El usuario ha sido elminado.",
        en: "The user has been deleted."
    },
    fromTheStore: {
        es: " de la tienda ",
        en: " from the store "
    },
    added: {
        es: 'Agregada! ',
        en: "Added! "
    },
    reservationAdded: {
        es: "Se agregó la reserva con éxito",
        en: "Reservation was saved succesfully"
    },
    unignoreClient: {
        es: "Designorar al cliente:",
        en: "Unignore client:"
    },
    unignored: {
        es: 'Designorado! ',
        em: "Unignored! "
    },
    userUnignored: {
        es: "El usuario ha sido designorado.",
        en: "The user has been unignored"
    },
    ignoreClient: {
        es: "Ignorar al cliente:",
        en: "Ignore client:"
    },
    ignored: {
        es: 'Ignorado! ',
        en: "Ignored! "
    },
    userIgnored: {
        es: "El usuario ha sido ignorado.",
        en: "The user has been ignored"
    },

}