import React from 'react';
import { translate } from 'react-i18next';
import Button from '../utils/form/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';


export class MercadoPagoButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    openModal = () => {
        this.setState({ ...this.state, open: true });
    }

    closeModal = () => {
        this.setState({ ...this.state, open: false });
    }

    render() {
        return (
            <>
                <Button
                    onClick={this.props.onClick}
                    className={`align-middle text-uppercase ${this.props.buttonClassName}`}>
                    <FontAwesomeIcon icon={faShoppingCart} /> <div>{this.props.customText ? this.props.customText : this.props.t("common.purchase")}</div>
                </Button>
            </>
        )
    }
}


export default (translate('common')(MercadoPagoButton));