import React from 'react'
import { Field } from 'formik';
import { MDBIcon, MDBInput } from "mdbreact";
import ReactTooltip from 'react-tooltip';
import {Select} from "botbit-ui-components";
import { getBenefitMessage, benefitIsEmpty, getBenefit } from '../MinimalEditor/Helper'
import "./CampaignMessage.css";

const TRANSLATIONS_PREFIX = "panels.campaigns.minimalEditor."

export default class CampaignMessage extends React.Component {
    constructor(props){
        super(props)
        this.state={
        }
    }

    renderMessageInput = (props) => {
        const {t, formRef} = this.props;
        const hasError = Object.keys(props.form.errors).length > 0 &&
                        Object.keys(props.form.errors.campaign).length > 0 && 
                        props.form.errors.campaign.benefitMessage !== undefined
        return <div className="benefitMessageContainer">
            <Select
                className="benefitMessageIsCustomSelector"  
                options={[
                    {value: "benefit", text: t(TRANSLATIONS_PREFIX + "messageBenefit"), checked: !props.form.values.campaign.benefitMessageIsCustom}, 
                    {value: "custom", text: t(TRANSLATIONS_PREFIX + "messageCustom"), checked: props.form.values.campaign.benefitMessageIsCustom}
                ]}
                getValue={(val) => {
                    let isCustom = (val[0] === "custom");
                    props.form.setFieldValue("campaign.benefitMessageIsCustom", isCustom)
                    let campaign = formRef.current.state.values.campaign;
                    campaign.benefitMessageIsCustom = isCustom;
                    props.form.setFieldValue("campaign.benefitMessage", getBenefit(campaign, this.props.t, this.props.productCategories));
                }}
            />
            {props.form.values.campaign.benefitMessageIsCustom ? 
                <div className={`${hasError ? ' hasError' : ''} benefitMessageInput`}>
                    <MDBInput 
                        // initialInputValue={props.form.values.campaign.benefitMessage} 
                        value={props.field.value}
                        onChange={ (e, errorMessage) => { 
                            if (props.field.value !== e.target.value) 
                                props.form.setFieldValue("campaign.benefitMessage", e.target.value);
                        }}
                    />
                    {hasError && <span className="error-message">{props.form.errors.campaign.benefitMessage}</span>}
                </div>
                 : <span className="messageBenefitLabel">{getBenefitMessage(props.form.values.campaign, this.props.t, this.props.productCategories)} {this.benefitEmptyWarningIcon(props)}</span>}
        </div>
    }

    benefitEmptyWarningIcon = (props) => {
        return benefitIsEmpty(props.form.values.campaign) 
                && !props.form.values.campaign.benefitMessageIsCustom &&
                <>
                    <MDBIcon data-tip data-for="benefitEmptyDisclaimer" className="ml-2 text-danger" icon="exclamation-circle"></MDBIcon>    
                    <ReactTooltip id='benefitEmptyDisclaimer' effect='solid' 
                    overridePosition={(e) => {
                        let position = {...e}
                        if(window.innerWidth < 576){
                            position =  {
                                    left: 15,
                                    top: e.top
                            }
                        } 
                        return position
                    }}>
                        <span>{this.props.t(`${TRANSLATIONS_PREFIX}benefitMore.benefitEmptyWarning`)}</span>
                    </ReactTooltip>
                </>
    }

    render(){
        return(
            <div className="campaignMessage">
                <span className={"inputLabel " + (!this.props.isEditing ? "d-inline-block float-left" : "")}>
                {this.props.t(TRANSLATIONS_PREFIX + "message")}
                </span>
                <Field
                name={"campaign.benefitMessage"}
                render={(props) => {
                    return (
                        <>
                            {!this.props.isEditing ? 
                                <div className="d-inline-block inputSpan">
                                    <span className="font-weight-normal">
                                        {getBenefitMessage(
                                            props.form.values.campaign, 
                                            this.props.t, 
                                            this.props.productCategories)}
                                    </span>
                                    {this.benefitEmptyWarningIcon(props)}
                                </div> :
                                this.renderMessageInput(props)
                            }
                        </>
                    )
                
                }}
                />
            </div>
        )
    }
}