import React from 'react';
import { translate } from 'react-i18next';
import Error from '../../commons/components/utils/Error';
import {withRouter} from "react-router-dom"; 

class NotificationsViewer extends React.Component {
    render = () => {
        if (this.props.notifications.length === 0) {
          return null
        }
        const lastNotification = this.props.notifications[this.props.notifications.length-1];
        return (
            <React.Fragment>
                <Error error={{ message: lastNotification.message }} popupMode={true}
                    cleanError={() => {
                        this.props.removeNotifications()
                        if(lastNotification.navigateOnOk && lastNotification.navigateOnOk !== "") {
                            this.props.history.push(lastNotification.navigateOnOk);
                        }
                    }} />
            </React.Fragment>
        );
    }
}

export default translate('common')(withRouter(NotificationsViewer))