import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import {useCompanyAvailableStores } from "../../commons/utils/useCompanyAvalaibleStores";
import {Select, Actions, RenderMode} from "botbit-ui-components";
import { translate } from 'react-i18next';

const withStoreSelector = (Crm) => {
    const CrmWithStoreSelector = ({t}) => {
        const [reRender, setReRender] = useState(true);

        useEffect(() => {
            let wait = setTimeout(() => {
                setReRender(false);
            }, 1000)
            return () => clearTimeout(wait);
        }, []);

        useEffect(() => {
            if(!reRender) setReRender(true)
        }, [reRender])

        const selectableStores = useCompanyAvailableStores();
        useEffect(() => {
            selectableStores.push({id:-1, name:t("header.comboAllStoresLabel"), storeCategory:{}});
        },[selectableStores]);

        const [selectedStore, setSelectedStore] = useState(selectableStores[0]);
        
        let actions = [{
            label: t("panels.campaigns.filters.store") + (selectedStore && selectedStore.id!==-1 ? " (1)" : ""),
            triggerColor: "primary",
            triggerOutline: true,
            key: "store-selector",
            content: () => <div className="select-in-dropdown-container">
                <Select 
                    expanded
                    options={selectableStores.map(s => ({text: s.name, value: s.id, checked: selectedStore && s.id === selectedStore.id}))}
                    getValue={(values) => {
                        if(!selectedStore || values[0] !== selectedStore.id) {
                            setSelectedStore(selectableStores.find(s => s.id === values[0]));
                        }
                    }}/>
            </div>
        }];

        const storeSelectorJSX = <div className="clients-store-selector-action-container">
            <Actions actions={actions} renderMode={RenderMode.DROPDOWN}/>
        </div>
        let elemToAttachFilter = "botbit-table-actions"
        if(window.innerWidth < 992) {
            elemToAttachFilter= 'header-actions-container';
        }
        const actionsSelector = document.getElementsByClassName(elemToAttachFilter)[0];    
        return <div>
            {actionsSelector && ReactDOM.createPortal(storeSelectorJSX, actionsSelector)}
            <Crm selectedStore={selectedStore} storeChange={setSelectedStore} availableStores={selectableStores.filter(s=>s.id>0)} reRender={setReRender}/>
        </div>
    }
    return translate('common')(CrmWithStoreSelector);
}

export default withStoreSelector;
