import React from 'react'
import { MDBIcon, MDBBtn } from 'mdbreact'

export const MinimalEditorBox = ({containerName, 
    bodyContent, 
    editable, 
    t, 
    TRANSLATIONS_PREFIX, 
    isEditing,
    setIsEditing,
    handleCancelEdit,
    containerClassName,
    handleSave,
    isMobile,
    hide}) => {
    if(hide) return null;
    return (
            <div className={(containerClassName ? containerClassName : '') 
                            + ' ' 
                            + containerName 
                            + " mt-3 editorBoxContainer " 
                            + (isEditing === true ? 'big' : '')}>
                <span className={"d-inline-block boxTitle" + (isEditing === true ? ' editingTitle ' : '')}>
                    {t(TRANSLATIONS_PREFIX + containerName)}
                </span>
                {editable && !isEditing &&
                    <MDBIcon icon="pen" className="d-inline-block float-right" onClick={ () => setIsEditing(containerName, true)}/>
                }
                <span className="content d-block">
                    {bodyContent}
                </span>
                {isEditing && !isMobile &&
                    <div className="saveCloseBtn mt-3">
                        <MDBBtn outline color="primary" onClick={() => handleCancelEdit()}>
                            {t(TRANSLATIONS_PREFIX + "cancelBtn")}
                        </MDBBtn>
                        <MDBBtn color="primary" onClick={() => handleSave()}>{t(TRANSLATIONS_PREFIX + "saveBtn")}</MDBBtn>
                    </div>
                }
            </div>
    )
}
