import React from 'react';
import Moment from 'react-moment';
import ReactCountryFlag from 'react-country-flag';
import { MDBIcon } from 'mdbreact';
import { getTranslationFunction } from '../../config/translations/translate';
import { translations } from '../../config/translations/Dashboard/userModal';
import {
  getPromoters,
  getDetractors,
  getRegistrationUsers,
} from '../../api/dashboardApi';
import Score from "../feedback/Score";
import { getRegisteredThroughConfiguration } from "../../commons/components/events/Events";

const t = getTranslationFunction({ ...translations });
const COLUMNS_PREFIX = 'columnName';

export const getCaptiveModal = (storesSelected, methods) => ({
  id: "captiveModal",
  title: t('captiveModal'),
  order: { name: "eventTimestamp", direction: "asc" },
  columns: [
    {
      dataField: 'eventTimestamp',
      title: t(`${COLUMNS_PREFIX}.eventTimestamp`),
      // className: 'd-none d-md-block',
      size: 12,
      formatter: (cellContent, row) => {
        return (
          <span>
            <Moment format="L LT" locale="es">
              {cellContent}
            </Moment>
          </span>
        );
      },
    },
    {
      dataField: 'email',
      title: t(`${COLUMNS_PREFIX}.email`),
      // className: 'd-none d-md-block',
      size: 25,
      formatter: (cellContent, row) => {
        return (
          <a className="cyan-text" href={`mailto:${cellContent}`}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: 'storeName',
      title: t(`${COLUMNS_PREFIX}.store`),
      // className: 'd-none d-md-block',
      size: 18,
      formatter: (cellContent, row) => {
        return (
          <div>
            <ReactCountryFlag
              styleProps={{ width: '1.2rem', height: '1.2rem' }}
              code={row.storeCountry === null ? '' : row.storeCountry}
              svg
            />{' '}
            {cellContent}
          </div>
        );
      },
    },
    {
      dataField: 'registeredThrough',
      title: t(`${COLUMNS_PREFIX}.registeredThrough`),
      // className: 'd-none d-md-block',
      size: 12,
      formatter: (cellContent, row) => {
        const config = getRegisteredThroughConfiguration(cellContent);
        return cellContent ? (
          <div classname="d-flex flex-column justify-content-center">
            <MDBIcon className="d-block" icon={config && config.icon} size="2x" />
            <div className="d-block">{config && config.desc}</div>
          </div>
        ) : '';
      },
    },
    {
      dataField: '',
      title: '',
      // className: 'd-none d-md-block',
      size: 10,
      formatter: (cellContent, row) => {
        return <div className="some-class"><span className="row-btn">Ver Actividad</span></div>;
      },
    },
  ],
  columnsMobile: [
    {
      dataField: '',
      // className: 'd-block d-md-none',
      size: 100,
      formatter: (cellContent, row) => {
        const avatarImg =
          row.avatar && row.avatar !== '' ? row.avatar : 'noavatar.jpg';
        let imgsrc = `https://admin.botbit.io/static/img/avatars/${avatarImg}`;
        const config = getRegisteredThroughConfiguration(row.registeredThrough);
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              // margin: 'auto'
            }}
            className="mobile-column"
          >
            <div className="mobile-image-container">
              <div className="w-100">
                <img src={imgsrc} style={{ width: "45px" }} className="rounded-circle" alt="" />
              </div>
            </div>
            <div
              style={{ marginLeft: 15, fontSize: '0.8rem', width: "80%" }}
              className="mobile-info-container"
            >
              <h5 style={{ margin: 0 }}>{`${(row.name) ? row.name : ''} ${(row.lastName) ? row.lastName : ''}`}</h5>
              <span>{row.email}</span>
              <p style={{ margin: 0 }}>
                {`${t('registered')} `}
                <Moment format="L" locale="es">
                  {row.eventTimestamp}
                </Moment>{' '}
                <Moment format="LT" locale="es">
                  {row.eventTimestamp}
                </Moment>
              </p>
              <span>{
                (row.registeredThrough ? <MDBIcon icon={config && config.icon} size="1x" /> : '')
              }</span>
              <span>{" " + config.desc}</span>
            </div>
          </div>
        );
      },
    },
  ],
  elementKey: 'eventTimestamp',
  data: (page, order, filters, stores, period) => getRegistrationUsers(page, storesSelected, period, methods),
  dataPath: "data.data"
});

export const getPromotersConfig = (companyId) => ({
  title: t('promoters'),
  showHistory: true,
  securityId: "users-review-history",
  columnsMobile: [
    {
      dataField: '',
      // className: 'd-block d-md-none',
      size: 100,
      formatter: (cellContent, row) => {
        const avatarImg =
          row.reviewerProfilePhotoUrl && row.reviewerProfilePhotoUrl !== ""
            ? row.reviewerProfilePhotoUrl
            : "https://admin.botbit.io/static/img/avatars/noavatar.jpg";
        let imgsrc = `${avatarImg}`;
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              // margin: 'auto'
            }}
            className="mobile-column"
          >
            <div className="mobile-image-container">
              <div className="w-100">
                <img src={imgsrc} style={{ width: "45px" }} className="rounded-circle" alt="" />
              </div>
            </div>
            <div
              style={{ marginLeft: 15, fontSize: '0.8rem', width: '80%' }}
              className="mobile-info-container"
            >
              <h5 style={{ margin: 0 }}>{`${row.reviewerDisplayName}`}</h5>
              <span>{row.reviewerEmail}</span>
              {/* <a
                style={{ textDecoration: 'none' }}
                // className="cyan-text"
                href={`mailto:${row.reviewerEmail}`}
              >
                {row.reviewerEmail}
              </a> */}
              <div>
                El <Moment format="L" locale="es">{row.beforeDate}</Moment> {row.beforeScore}⭐ <MDBIcon fas icon="arrow-right" style={{ color: "#000" }} /> El <Moment format="L" locale="es">{row.afterDate}</Moment> {row.afterScore}⭐</div>
            </div>
          </div>
        );
      },
    },
  ],
  columns: [
    {
      dataField: "avatar",
      title: t(`${COLUMNS_PREFIX}.avatar`),
      // className: "d-none d-md-block",
      formatter: (cellContent, row) => {
        const avatarImg =
          row.reviewerProfilePhotoUrl && row.reviewerProfilePhotoUrl !== ""
            ? row.reviewerProfilePhotoUrl
            : "https://admin.botbit.io/static/img/avatars/noavatar.jpg";
        let imgsrc = `${avatarImg}`;
        return (
          <div className="w-100">
            <img src={imgsrc} style={{ width: "45px" }} className="rounded-circle" alt="" />
          </div>
        );
      },
      size: 8,
    },
    {
      dataField: 'reviewerDisplayName',
      title: t(`${COLUMNS_PREFIX}.name`),
      // className: 'd-none d-md-block',
      classes: 'align-middle',
      size: 17,
      formatter: (cellContent, row) => {
        return `${cellContent}`;
      },
    },
    {
      dataField: 'reviewerEmail',
      title: t(`${COLUMNS_PREFIX}.email`),
      // className: 'd-none d-md-block',
      classes: 'align-middle',
      size: 30,
      formatter: (cellContent, row) => {
        return (
          <a className="cyan-text" href={`mailto:${cellContent}`}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: 'storeName',
      title: t(`${COLUMNS_PREFIX}.store`),
      // className: 'd-none d-md-block',
      size: 15,
      formatter: (cellContent, row) => {
        return (
          <div>
            <ReactCountryFlag
              styleProps={{ width: '1.2rem', height: '1.2rem' }}
              code={row.storeCountry === null ? '' : row.storeCountry}
              svg
            />{' '}
            {cellContent}
          </div>
        );
      },
    },
    {
      dataField: 'beforeScore',
      title: t(`${COLUMNS_PREFIX}.beforeScore`),
      // className: 'd-none d-md-block',
      size: 15,
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex flex-column align-items-start">
            <Score value={cellContent} size='l' />
            <Moment format="L LT" locale="es">
              {row.beforeDate}
            </Moment>
          </div>
        );
      }
    },
    {
      dataField: 'afterScore',
      title: t(`${COLUMNS_PREFIX}.afterScore`),
      // className: 'd-none d-md-block',
      size: 15,
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex flex-column align-items-start">
            <Score value={cellContent} size='l' />
            <Moment format="L LT" locale="es">
              {row.afterDate}
            </Moment>
          </div>
        );
      }
    },
  ],
  elementKey: 'reviewerDisplayName',
  hideDefaultColumns: true,
  data: (page, order, filters, stores, period) => getPromoters(companyId, stores, period, page),
  dataPath: "data.data.items",
})

export const getDetractorsConfig = (companyId) => ({
  title: t('detractors'),
  showHistory: true,
  securityId: "users-review-history",
  columnsMobile: [
    {
      dataField: '',
      // className: 'd-block d-md-none',
      size: 100,
      formatter: (cellContent, row) => {
        const avatarImg =
          row.reviewerProfilePhotoUrl && row.reviewerProfilePhotoUrl !== ""
            ? row.reviewerProfilePhotoUrl
            : "https://admin.botbit.io/static/img/avatars/noavatar.jpg";
        let imgsrc = `${avatarImg}`;
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              // margin: 'auto'
            }}
            className="mobile-column"
          >
            <div className="mobile-image-container">
              <div className="w-100">
                <img src={imgsrc} style={{ width: "45px" }} className="rounded-circle" alt="" />
              </div>
            </div>
            <div
              style={{ marginLeft: 15, fontSize: '0.8rem', width: '80%' }}
              className="mobile-info-container"
            >
              <h5 style={{ margin: 0 }}>{`${row.reviewerDisplayName}`}</h5>
              <span>{row.reviewerEmail}</span>
              {/* <a
                style={{ textDecoration: 'none' }}
                // className="cyan-text"
                href={`mailto:${row.reviewerEmail}`}
              >
                {row.reviewerEmail}
              </a> */}
              <div>
                El <Moment format="L" locale="es">{row.beforeDate}</Moment> {row.beforeScore}⭐ <MDBIcon fas icon="arrow-right" style={{ color: "#000" }} /> El <Moment format="L" locale="es">{row.afterDate}</Moment> {row.afterScore}⭐</div>
            </div>
          </div>
        );
      },
    },
  ],
  columns: [
    {
      dataField: "avatar",
      title: t(`${COLUMNS_PREFIX}.avatar`),
      // className: "d-none d-md-block",
      formatter: (cellContent, row) => {
        const avatarImg =
          row.reviewerProfilePhotoUrl && row.reviewerProfilePhotoUrl !== ""
            ? row.reviewerProfilePhotoUrl
            : "https://admin.botbit.io/static/img/avatars/noavatar.jpg";
        let imgsrc = `${avatarImg}`;
        return (
          <div className="w-100">
            <img src={imgsrc} style={{ width: "45px" }} className="rounded-circle" alt="" />
          </div>
        );
      },
      size: 8,
    },
    {
      dataField: 'reviewerDisplayName',
      title: t(`${COLUMNS_PREFIX}.name`),
      // className: 'd-none d-md-block',
      classes: 'align-middle',
      size: 17,
      formatter: (cellContent, row) => {
        return `${cellContent}`;
      },
    },
    {
      dataField: 'reviewerEmail',
      title: t(`${COLUMNS_PREFIX}.email`),
      // className: 'd-none d-md-block',
      classes: 'align-middle',
      size: 30,
      formatter: (cellContent, row) => {
        return (
          <a className="cyan-text" href={`mailto:${cellContent}`}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: 'storeName',
      title: t(`${COLUMNS_PREFIX}.store`),
      // className: 'd-none d-md-block',
      size: 15,
      formatter: (cellContent, row) => {
        return (
          <div>
            <ReactCountryFlag
              styleProps={{ width: '1.2rem', height: '1.2rem' }}
              code={row.storeCountry === null ? '' : row.storeCountry}
              svg
            />{' '}
            {cellContent}
          </div>
        );
      },
    },
    {
      dataField: 'beforeScore',
      title: t(`${COLUMNS_PREFIX}.beforeScore`),
      // className: 'd-none d-md-block',
      size: 15,
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex flex-column align-items-start">
            <Score value={cellContent} size='l' />
            <Moment format="L LT" locale="es">
              {row.beforeDate}
            </Moment>
          </div>
        );
      }
    },
    {
      dataField: 'afterScore',
      title: t(`${COLUMNS_PREFIX}.afterScore`),
      // className: 'd-none d-md-block',
      size: 15,
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex flex-column align-items-start">
            <Score value={cellContent} size='l' />
            <Moment format="L LT" locale="es">
              {row.afterDate}
            </Moment>
          </div>
        );
      }
    },
  ],
  elementKey: 'reviewerDisplayName',
  hideDefaultColumns: true,
  data: (page, order, filters, stores, period) => getDetractors(companyId, stores, period, page),
  dataPath: "data.data.items",
})

export const satisfactionModal = {
  title: t('satisfactionModal'),
  columns: [
    {
      dataField: '',
      // className: 'd-block d-md-none',
      formatter: (cellContent, row) => {
        const avatarImg =
          row.avatar && row.avatar !== '' ? row.avatar : 'noavatar.jpg';
        let imgsrc = `https://admin.botbit.io/static/img/avatars/${avatarImg}`;
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              // margin: 'auto'
            }}
            className="mobile-column"
          >
            <div className="mobile-image-container">
              <div className="w-100">
                <img src={imgsrc} style={{ width: "45px" }} className="rounded-circle" alt="" />
              </div>
            </div>
            <div
              style={{ marginLeft: 15, fontSize: '0.8rem' }}
              className="mobile-info-container"
            >
              <h5 style={{ margin: 0 }}>{`${(row.name) ? row.name : ''} ${(row.lastName) ? row.lastName : ''}`}</h5>
              <a
                style={{ textDecoration: 'none' }}
                href={`mailto:${row.email}`}
              >
                {row.email}
              </a>
              <span className="row-btn row-btn-mobile">Ver Actividad</span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'eventTimestamp',
      title: t(`${COLUMNS_PREFIX}.eventTimestamp`),
      // className: 'd-none d-md-block',
      formatter: (cellContent, row) => {
        return (
          <span>
            <Moment format="YYYY-MM-DD HH:mm" locale="es">
              {cellContent}
            </Moment>
          </span>
        );
      },
    },
    {
      dataField: 'email',
      title: t(`${COLUMNS_PREFIX}.email`),
      // className: 'd-none d-md-block',
      classes: 'align-middle',
      size: 2,
      formatter: (cellContent, row) => {
        return (
          <a className="cyan-text" href={`mailto:${cellContent}`}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: 'storeName',
      title: t(`${COLUMNS_PREFIX}.store`),
      // className: 'd-none d-md-block',
      size: 3,
      formatter: (cellContent, row) => {
        return (
          <div>
            <ReactCountryFlag
              styleProps={{ width: '1.2rem', height: '1.2rem' }}
              code={row.storeCountry === null ? '' : row.storeCountry}
              svg
            />{' '}
            {cellContent}
          </div>
        );
      },
    },
    {
      dataField: 'payment',
      title: t(`${COLUMNS_PREFIX}.payment`),
      // className: 'd-none d-md-block',
      // size: 2,
      formatter: (cellContent, row) => {
        return cellContent ? <MDBIcon fas icon="shopping-cart" /> : '';
      },
    },
    {
      dataField: '',
      title: '',
      // className: 'd-none d-md-block',
      // size: 1.5,
      formatter: (cellContent, row) => {
        return <span className="row-btn">Ver Actividad</span>;
      },
    },
  ],
  elementKey: 'userId',
  data: (page, order, filters, stores, period) => getRegistrationUsers(page, stores, period),
};