import React from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { translate } from "react-i18next";
import { getUserStatus } from '../../api/userApi.js';

class StatusSelect extends React.Component {

	constructor(props) {
		super(props);
		this.state={status: props.userStatus? props.userStatus.userStatusType : {id:5, statusType: 'unknow'}}
	}

	render = () => {
		if(!this.props.userStatus) return null;
		if(this.props.editable === false){
			return (
				<span>{this.props.t(`userStatusType.${this.state.status.id}`)}</span>
			);
		}
		return (
			<MDBDropdown className='text-right'>
				<MDBDropdownToggle caret color="primary">
					{this.props.t(`userStatusType.${this.state.status.id}`)}
				</MDBDropdownToggle>
				<MDBDropdownMenu basic>
					{this.props.userStatus.availableTargetTypes.map(
						us => {
							return (
								<MDBDropdownItem onClick={
									()=> {
										this.props.updateUserStatus(us);
										this.setState({status: us });
									}
								}>
									{this.props.t(`userStatusType.${us.id}`)}
								</MDBDropdownItem>
							)
						}
					)}
				</MDBDropdownMenu>
			</MDBDropdown>
		);
	}

}

const TranslatedStatusSelect = translate('common')(StatusSelect);

export default TranslatedStatusSelect;

export class StatusSelectContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state={};
	}

	componentDidMount(){
		this.checkStatus();
	}

	componentDidUpdate(prevProps, prevState) {
	}

	checkStatus = () => {
		if(!this.props.storeId) return;
		getUserStatus(this.props.userId, this.props.storeId)
			.then(
				response => {
					this.setState(
						{
							userStatus : response.data.data
						}
					);
				}
			);
	}

	render(){
		if (!this.state.userStatus) return null;
		return (
			<TranslatedStatusSelect 
				userStatus={this.state.userStatus} 
				editable={false}
			/>
		)
	}
}
