import React from "react";
import {Field, reduxForm} from "redux-form";
import InputField from "../../commons/components/utils/form/InputField";
import {translate} from "react-i18next";
import InputFileField from "../../commons/components/utils/form/InputFileField";
import {connect} from "react-redux";
import InputSliderField from "../../commons/components/utils/form/InputSliderField";
import InputSwitchField from "../../commons/components/utils/form/InputSwitchField";
import {required, validEmail, validURL} from "../../commons/components/utils/form/validations";
import {normalizeEmail, normalizeUrl} from "../../commons/components/utils/form/normalize";
import {WizardNavigationButtons} from "../../commons/components/utils/form/wizard/Wizard";

class CompanyForm extends React.Component {

  render =() => {
    const { /*t,*/ handleSubmit, /*submitting,*/ nextHandler, onBoardingForm:form } = this.props;
    const srcImg = form && form.values && form.values.logo?form.values.logo:"http://placehold.it/180";
    return (
      <div className="ibox-content pb-1">
      <form className="container" noValidate="" onSubmit={handleSubmit(values => nextHandler(values))}>
        <Field
          name="name"
          type="text"
          component={InputField}
          autoFocus
          placeholder='Ingrese el nombre'
          validate={required}
        />

        <div className="row" >
          <div className="col">
            <Field
              name="logo"
              component={InputFileField}
              placeholder='Elegir archivo...'
              autoFocus
              accept=".gif,.jpg,.jpeg,.png"
              label="Logo de la tienda"
            />
          </div>
        </div>

        <div className="row pb-4" >
          <div className="col no-margins">
            <img id={'logo-img'} src={srcImg} alt="Logo" className="rounded float-right" style={{"height":"180px", "width":"180px" }}/>
          </div>
        </div>

        <Field
          name="website"
          type="text"
          component={InputField}
          placeholder='Ingrese la url'
          label="Sitio Web"
          validate={[required,validURL]}
          normalize={normalizeUrl}
        />
        <Field
          name="avgTicket"
          type="number"
          component={InputField}
          placeholder='Ingrese el Valor'
          label="Ticket promedio"
          validate={required}
        />

        <Field
          name="avgCustomerGroup"
          maxValue={10}
          minValue={1}
          component={InputSliderField}
          label="N° de personas por Grupo en promedio"
        />

        <Field
          name="rewardActive"
          label="Activar gamification"
          component={InputSwitchField}
        />

        { form && form.values && form.values.rewardActive?
          <React.Fragment>
              <Field
                name="reward"
                type="text"
                component={InputField}
                placeholder='Media pinta de tu cerveza favorita?. Salud!'
                label="Premio"
              />
              <Field
                name="rewardProbability"
                maxValue={100}
                minValue={0}
                component={InputSliderField}
                formatLabel={value => `${value}%`}
                label="Probabilidad de ganar"
              />
              <Field
              name="maximumNumberReward"
              maxValue={100}
              minValue={0}
              component={InputSliderField}
              label="Cantidad máxima de premios por día (0 sin tope)"
              />

          </React.Fragment>
          :''
        }

        <Field
          name="campaignEmail"
          type="text"
          component={InputField}
          placeholder='Ingrese direccion de email'
          label="Email por defecto para campañas"
          validate={[required,validEmail]}
          normalize={normalizeEmail}
        />
        <WizardNavigationButtons/>
      </form>
    </div>)
  }
}
const mapStateToProps = state => {
  return {
    onBoardingForm: state.form.onBoarding
  };
};

export default reduxForm({form:"onBoarding",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  }) (connect(mapStateToProps, {}) (translate('common') (CompanyForm)));