import React from "react";
import Score from "../../feedback/Score";
import { MDBBadge, MDBIcon } from "mdbreact";
import { getTranslationFunction } from "../../../config/translations/translate";
import { translations } from "../../../config/translations/Dashboard/CardsConfig";
import Moment from 'react-moment';
import listToText from "../../../commons/utils/listToText";

/* eslint-disable jsx-a11y/accessible-emoji */
export const CardsConfig = (identifier) => {
  const t = getTranslationFunction(translations);

  const colors = {
    'red': '#FF3646',
    'green': '#08C852',
    'yellow': '#FFBC37',
    'black': 'black'
  }

  const getColorFromPerformance = performance => {
    return {
      'HIGH': colors['green'],
      'MEDIUM': colors['yellow'],
      'LOW': colors['red']
    }[performance] || colors['black']
  }

  const storesCards = [
    {
      id: "storesByScore",
      body: ({ storesTop, storesLow, storesMid }, action, period, { isSmallDesktop }) => (
        <div className="data-card-body">
          <div>
            <div>
              <span><b>{t(isSmallDesktop ? "scoreShort" : "scoreBetween", { low: 4, high: 5 })}</b> ⭐ <MDBIcon icon="arrow-right" className="small-icon" /> {(storesTop && storesTop.length > 0) ? <button className="link-like-btn" onClick={() => action(storesTop, "HIGH")}>{storesTop.length} {storesTop.length !== 1 ? t("stores") : t("store")}</button> : `0 ${t("stores")}`}</span>
            </div>
            <div>
              <span><b>{t(isSmallDesktop ? "scoreShort" : "scoreBetween", { low: 3, high: 4 })}</b> ⭐ <MDBIcon icon="arrow-right" className="small-icon" /> {(storesMid && storesMid.length > 0) ? <button className="link-like-btn" onClick={() => action(storesMid, "LOW")}>{storesMid.length} {storesMid.length !== 1 ? t("stores") : t("store")}</button> : `0 ${t("stores")}`}</span>
            </div>
            <div>
              <span><b>{t(isSmallDesktop ? "scoreShort" : "scoreBetween", { low: 1, high: 3 })}</b> ⭐ <MDBIcon icon="arrow-right" className="small-icon" /> {(storesLow && storesLow.length > 0) ? <button className="link-like-btn" onClick={() => action(storesLow, "LOW")}>{storesLow.length} {storesLow.length !== 1 ? t("stores") : t("store")}</button> : `0 ${t("stores")}`}</span>
            </div>
          </div>
        </div>
      ),
      footer: (data, fc, period) => (
        <div className="card-footer grey">
          <b>{t("rankingByRating")}</b> {t("storesInPeriod", { period: period.suffixText }) + " "}
          <button className="link-like-btn" onClick={fc}>{t(`seeDetail`)}</button>
        </div>
      ),
    },
    {
      id: "lowestScoreStore",
      body: ({ score, name }) => (
        <div className="data-card-body">
          <h2 style={{ fontSize: "40px", color: getColorFromPerformance(score.performance) }}>{score.current}</h2>
          <Score starDimension="25px" value={score.current} size="l" />
          <h3>{name}</h3>
        </div>
      ),
      footer: (data, fc) => (
        <div className="card-footer grey">
          <button className="link-like-btn" onClick={() => fc(data)}>{t(`seeDetail`)}</button>
        </div>
      ),
    },
    {
      id: "highestScoreStore",
      body: ({ score, name }) => (
        <div className="data-card-body">
          <h2 style={{ fontSize: "40px", color: getColorFromPerformance(score.performance) }}>{score.current}</h2>
          <Score starDimension="25px" value={score.current} size="l" />
          <h3>{name}</h3>
        </div>
      ),
      footer: (data, fc) => (
        <div className="card-footer grey">
          <button className="link-like-btn" onClick={() => fc(data)}>{t(`seeDetail`)}</button>
        </div>
      ),
    },
    {
      id: "singleStoreScore",
      body: ({ score, name }) => (
        <div className="data-card-body">
          <h2 style={{ fontSize: "40px", color: getColorFromPerformance(score.performance) }}>{score.current}</h2>
          <Score starDimension="25px" value={score.current} size="l" />
          <h3>{name}</h3>
        </div>
      ),
      footer: (data, fc) => (
        <div className="card-footer grey">
          <button className="link-like-btn" onClick={() => fc(data)}>{t(`seeDetail`)}</button>
        </div>
      ),
    },
    {
      id: "moreStores",
      body: (data, action) => (
        <div className="data-card-body">
          <div className="data-card-body">
            <span>{t("addMoreStores.prev")}<b style={{ fontWeight: "bold" }}>{t(t("addMoreStores.bold"))}</b>{t("addMoreStores.post")} </span>
            <button className="link-like-btn" onClick={() => action()} >{t(`addMoreStores.clickHere`)}</button>
          </div>
        </div>
      ),
      footer: (data, fc) => (
        <div></div>
      ),
    },
  ];

  const reputationCards = [
    {
      id: "averageScoreFromAllSources",
      body: ({ total }) => (
        <div className="data-card-body">
          <h2 className="my-0" style={{ color: getColorFromPerformance(total.performance), fontSize: "40px" }}>{total.current}</h2>
          <Score starDimension="25px" value={total.current} size="l" />
        </div>
      ),
      footer: (data, fc, period) => (
        <div className="card-footer grey">
          {data.sources.length &&
            <>
              <label style={{ fontWeight: 'normal', marginBottom: 0 }}>
                <b>{t("averageScore")}</b>
                {t("averageScoreSources", { sources: listToText(data.sources, 3), period: period.suffixText })}&nbsp;
                <button className="link-like-btn" onClick={fc}>{t(`seeDetail`)}</button>
              </label>
            </>
          }
          {
            !data.sources.length &&
            <button className="link-like-btn" onClick={fc}>{t(`seeDetail`)}</button>
          }
        </div>
      ),
    },
    {
      id: "reviewRequest",
      // noTitle: true,
      compactCard: true,
      body: (data, actions, period) => {
        // let totalReviewGenerated = { current: 25, variation: 54 }
        const { totalRequestSent, manualRequestSent, automaticRequestSent} = data;
        return (
          <div className="data-card-body">
            {new Date(period.from) < new Date(2021, 3, 1)
              ? <span className="data-alert">{t("beforeApril")}</span>
              : <>

                <div className="sents-container">
                  <div className="first-row">
                    <h2 className="sent-number">{totalRequestSent.current}</h2>
                    <div className="detailed">
                      {manualRequestSent.current && manualRequestSent.current > 0 ? <button title={t("manualFull")} className="link-like-btn" onClick={actions.manual}>{t("manual", { manual: manualRequestSent.current })}</button> : null}
                      {automaticRequestSent.current && automaticRequestSent.current > 0 ? <button title={t("automaticFull")} className="link-like-btn" onClick={actions.automatic}>{t("automatic", { automatic: automaticRequestSent.current })}</button> : null}
                    </div>
                  </div>
                  <span>{t("sentRequest")} {totalRequestSent.variation && totalRequestSent.variation !== 0 ?
                    <MDBBadge
                      color={totalRequestSent.variation >= 0 ? "success" : "danger"}
                    >
                      <MDBIcon className="variation-icon" icon={totalRequestSent.variation >= 0 ? "arrow-up" : "arrow-down"} /> {Math.abs(totalRequestSent.variation)}%
                      </MDBBadge>
                    : null}</span>
                </div>
{/* 
                <div className="sents-container">
                  <div className="first-row">
                    <h2 className="sent-number">{totalReviewGenerated.current}</h2>
                    <div className="detailed">
                      {privateReviewGenerated && privateReviewGenerated.current > 0 && <span><SocialSourceIcon site={1} /> {SocialSourceNames[1]} ({privateReviewGenerated.current})</span>}
                      {googleReviewGenerated && googleReviewGenerated.current > 0 && <span><SocialSourceIcon site={2} /> {SocialSourceNames[2]} ({googleReviewGenerated.current})</span>}
                      {facebookReviewGenerated && facebookReviewGenerated.current > 0 && <span><SocialSourceIcon site={3} /> {SocialSourceNames[3]} ({facebookReviewGenerated.current})</span>}
                    </div>
                  </div>
                  <span>{t("generatedReviews")} {totalReviewGenerated.variation &&
                    <MDBBadge
                      color={totalReviewGenerated.variation >= 0 ? "success" : "danger"}
                    >
                      <MDBIcon className="variation-icon" icon={totalReviewGenerated.variation >= 0 ? "arrow-up" : "arrow-down"} /> {Math.abs(totalReviewGenerated.variation)}%
                          </MDBBadge>}</span>
                </div> */}
              </>
            }

          </div>
        )
      },
      footer: (data, fc, period) => (
        <>
          {new Date(period.from) < new Date(2021, 3, 1)
            ? null
            : <div className="card-footer grey">
              <span>
                {t("rrCardFooter.first")}
                <b>{t(data.totalRequestSent.current !== 1 ? "rrCardFooter.second" : "rrCardFooter.secondAlt", { total: data.totalRequestSent.current })}</b>
                {t("rrCardFooter.fourth", { period: period.suffixText })}
              </span>
            </div>}
        </>
      ),
    },
    {
      id: "fiveStarGoogleReviews",
      body: ({ generated, total }) => (
        <div className="data-card-body flex-row">
          <h2 className="d-inline" style={{fontSize: "40px" }}>{total.current}</h2>
        </div>
      ),
      footer: ({ total }, fc, period) => (
        <div className="card-footer grey">
          {t('totalOfFiveStars', { period: period.suffixText })}
          {/* <b>{t('weGenerated', { percentage: generatedPercentage.current }) + " "}</b>
          {t(`generated`, { period: period.suffixText }) + " "} */}
          {total.current > 0 && (<span>
            <button className="link-like-btn" onClick={fc}>{t(`seeDetail`)}</button>
          </span>)}
        </div>
      ),
    },
    {
      id: "answeredReviews",
      body: ({ answered, total }) => (
        <div className="data-card-body flex-row align-items-center">
          <h2 className="d-inline mr-1" style={{ color: (answered.current === 0 && colors['black']) || colors['green'], fontSize: "40px" }}>
            {answered.current}
          </h2>{" "}
          {t("outOf")} {total.current}
        </div>
      ),
      footer: ({ answeredPercentage, timeSaved, period }, fc) => (
        <div className="card-footer grey">
          <label className="d-inline">
            {t(`answered`)}
            {answeredPercentage.current}%
          </label>{" " + t("period", { period: period.suffixText }) + " "}
          {answeredPercentage.current > 0 && timeSaved.current && (
            <>
              {t(`youSaved`)}{" "}
              <label className="d-inline"><Moment locale={t("locale")} fromNow ago>{new Date(new Date().getTime() - (timeSaved.current))}</Moment> {" " + t('hoursOfWork')}</label>.{" "}
              <span>
                <button className="link-like-btn" onClick={fc}>{t(`seeDetail`)}</button>
              </span>
            </>)}
        </div>
      )
    },
  ]

  const retentionCards = [
    {
      id: "totalUnsatisfiedClients",
      body: ({ total }) => (
        <div className="data-card-body flex-row align-items-center">
          <h2 className="d-inline mr-1" style={{ fontSize: "40px", color: (total.current === 0 && colors['black']) || colors['red'] }}>
            {total.current}
          </h2>
        </div>
      ),
      footer: ({ answered, total }, fc) => (
        (total.current > 0 && total.current !== answered.current) ? <div className="card-footer grey">
          {t("notAnsweredTo")}<b>{`${total.current - answered.current} ${total.current - answered.current > 1 ? t("badExperience") : t("badExperienceSing")} `}</b>
          <span>
            <button className="link-like-btn" onClick={fc}>{total.current - answered.current > 1 ? t(`answerToTheRest`) : t(`answerToTheRestSing`)}</button>
          </span>
        </div> : answered.current > 0 && answered.current === total.current && <div className="card-footer grey">
          {<span>{t(`allClientsAnswered`)}</span>}
        </div>
      )
    },
    {
      id: "nowSatisfiedClients",
      body: ({ total }) => (
        <div className="data-card-body flex-row align-items-center">
          <h2 className="d-inline mr-1" style={{ fontSize: "40px", color: (total.current === 0 && colors['black']) || colors['green'] }}>
            {total.current}
          </h2>
        </div>
      ),
      footer: (data, fc) => (
        data.total.current
          ? <div className="card-footer grey">
            {t("clientsWho") + " "}<b>{t("latestSatisfied")}</b>{" "}
            <span>
              <button className="link-like-btn" onClick={fc}>{" " + t(`seeDetail`)}</button>
            </span>
          </div>
          : <div className="card-footer grey">{t("clientsWho") + " "}<b>{t("latestSatisfied")}</b></div>
      )
    },
    {
      id: "nowUnsatisfiedClients",
      body: ({ total }) => (
        <div className="data-card-body flex-row align-items-center">
          <h2 className="d-inline mr-1" style={{ fontSize: "40px", color: (total.current === 0 && colors['black']) || colors['red'] }}>
            {total.current}
          </h2>
        </div>
      ),
      footer: (data, fc) => (
        data.total.current
          ? <div className="card-footer grey">
            {t("clientsWho") + " "}<b>{t("latestUnsatisfied")}</b>{" "}
            <span>
              <button className="link-like-btn" onClick={fc}>{" " + t(`seeDetail`)}</button>
            </span>
          </div>
          : <div className="card-footer grey">{t("clientsWho") + " "}<b>{t("latestUnsatisfied")}</b></div>
      )
    }
  ]




  const config = [
    ...storesCards,
    ...reputationCards,
    ...retentionCards
  ];

  return config.find((c) => c.id === identifier);
};


// const promotionsCards = [
//   {
//     id: "promotionsSent",
//     body: ({ total }) =>
//       withVariationArrow(
//         <div className="data-card-body">
//           <h2>{total.current}</h2>
//         </div>,
//         total.variation
//       ),
//     footer: (data, fc) => (
//       <div className="card-footer" style={{ background: "#F3F3F3" }}>
//         <button className="link-like-btn" onClick={fc}>{t(`seeDetail`)}</button>
//       </div>
//     ),
//   },
//   {
//     id: "promotionsOpened",
//     body: ({ total, percentage }) =>
//       withVariationArrow(
//         <div className="d-flex">
//           <h2>{total.current}</h2>{" "}
//           <p className="mb-0">({percentage.current}%)</p>
//         </div>,
//         total.variation
//       ),
//     footer: (data, fc) => (
//       <div className="card-footer" style={{ background: "#F3F3F3" }}>
//         <button className="link-like-btn" onClick={fc}>{t(`seeDetail`)}</button>
//       </div>
//     ),
//   },
//   {
//     id: "promotionsRedeemed",
//     body: ({ total, percentage }) =>
//       withVariationArrow(
//         <div className="d-flex">
//           <h2>{total.current}</h2>{" "}
//           <p className="mb-0">({percentage.current}%)</p>
//         </div>,
//         total.variation
//       ),
//     footer: (data, fc) => (
//       <div className="card-footer" style={{ background: "#F3F3F3" }}>
//         <button className="link-like-btn" onClick={fc}>{t(`seeDetail`)}</button>
//       </div>
//     ),
//   },
// ]

// const revenueCards = [
//   {
//     id: "faceToFaceSale",
//     body: ({ total, visits }) =>
//       withVariationArrow(
//         <div className="data-card-body">
//           <h2>$ {total.current}</h2>
//           <p className="font-weight-bold">
//             {visits.current} {t(`visits`)}
//           </p>
//         </div>,
//         total.variation
//       ),
//     footer: (data, fc) => (
//       <div className="card-footer grey">
//         {t(`stimulatedByBotbitUpper`)}.{" "}
//         <button className="link-like-btn" onClick={() => fc()}>{t(`seeDetail`)}</button>
//       </div>
//     ),
//   },
//   {
//     id: "onlineSales",
//     body: ({ total, orders }) =>
//       withVariationArrow(
//         <div className="data-card-body">
//           <h2>$ {total.current}</h2>
//           <p className="font-weight-bold">
//             {orders.current} {t(`orders`)}
//           </p>
//         </div>,
//         total.variation
//       ),
//     footer: ({ botbitStimulated }, fc) => (
//       <div className="card-footer grey">
//         <h3 className=" d-inline">
//           $ {botbitStimulated.current}
//         </h3>{" "}
//         {t(`stimulatedByBotbit`)}.{" "}
//         <button className="link-like-btn" onClick={() => fc()}>{t(`seeDetail`)}</button>
//       </div>
//     ),
//   },
//   {
//     id: "estimatedComissionsSavings",
//     body: ({ total }) =>
//       withVariationArrow(
//         <div className="data-card-body">
//           <h2>$ {total.current}</h2>
//         </div>,
//         total.variation
//       ),
//     footer: (data, fc) => (
//       <div className="card-footer" style={{ background: "#F3F3F3" }}>
//         <button className="link-like-btn" onClick={() => fc()}>{t(`seeDetail`)}</button>
//       </div>
//     ),
//   },
// ]

// const withVariationArrow = (jsxData, variation) => (
//   <div className="d-flex justify-content-between align-items-end">
//     {jsxData}
//     <MDBIcon
//       icon={variationConvention[`${variation}`]}
//       className={variationConvention[`${variation}`]}
//     />
//   </div>
// );

// const variationConvention = {
//   BETTER: "arrow-up",
//   EQUAL: "minus",
//   WORSE: "arrow-down",
// };
