import React from 'react'
import { Row, Col, Container } from "react-bootstrap";
import UserCard from './UserCard';
import UserActivitySummary from './UserActivitySummary';
import UserValue from './UserValue';
import UserActivityDetail from './UserActivityDetail';
import UserVisitationsDetail from './UserVisitationsDetail';
import StatusSelect from '../../../../components/clients/StatusSelect.js'
import { Actions, RenderMode } from "botbit-ui-components";
import { Carousel } from "botbit-ui-components";
import ClientActions from "../../../../components/clients/ClientActions.js"
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import UserTags from './UserTags.js';
import { connect } from 'react-redux';
import { clientsRowAction } from "../../../../config/translations/Clients/ClientsTable";
import { translations } from "../../../../config/translations/Clients/UserDetails";
import translate from "../../../../config/translations/translate";

class UserDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	openAction = (action, client, review = undefined) => {
		this.setState(
			{
				actionsContainer: {
					actionName: action.label,
					actionId: action.id,
					client: client,
					updateUser: this.props.updateUser,
					review: review || undefined

				}
			}
		);
	}

	closeAction = () => {
		this.setState(
			{
				actionsContainer: {
				}
			}
		);
	}


	actions = () => {
		const { t } = this.props;
		let user = this.props.userDetails.userInformation;
		const storesRegistered = user.storeRegistrations;
		const storesRegisteredIgnored = storesRegistered.filter(store => store.ignored && store.storeCompanyId === this.props.selectedCompany.id)
		const thisAction = (actionId) => (actions.find(action => action.id === actionId))
		let actions = [
			{
				key: 1,
				icon: "edit",
				id: "edit",
				label: t("rowActions.editClient"),
				color: "blue",
				onClick: () => this.openAction(thisAction('edit'), user)
			}

		];

		if ((this.props.selectedStores.length === 1 && this.props.selectedStores[0].storeCategory &&
			this.props.selectedStores[0].storeCategory.isSuscription) || this.props.selectedCompany.hasSubscriptionStores) {
			actions.push(
				{
					key: 4,
					color: "",
					icon: "tasks",
					id: "add-task",
					label: t("rowActions.addTask"),
					onClick: () => this.openAction(thisAction('add-task'), user)
				}
			);
		}
		else {
			if (this.props.selectedStores) {
				actions.push(
					{
						key: 2,
						color: "",
						id: "reservation",
						icon: "calendar-check",
						label: t("rowActions.addReservation"),
						onClick: () => this.openAction(thisAction('reservation'), user)
					}
				);
			}
		}
		actions.push(
			{
				key: 3,
				color: "",
				id: "send-campaign",
				icon: "envelope",
				label: t("rowActions.sendCampaign"),
				onClick: () => this.openAction(thisAction('send-campaign'), user)
			}
		)
		if (storesRegisteredIgnored && storesRegisteredIgnored.length > 0) {
			actions.push(
				{
					key: 6,
					color: "",
					id: "designore",
					icon: "ban",
					label: t("rowActions.unignore"),
					onClick: () => this.openAction(thisAction('unignore'), user)
				}
			);
		} else {
			actions.push(
				{
					key: 7,
					color: "",
					id: "ignore",
					icon: "ban",
					label: t("rowActions.ignore"),
					onClick: () => this.openAction(thisAction('ignore'), user)
				}
			);
		}
		actions.push(
			{
				key: 6,
				color: "",
				id: "delete",
				icon: "user-minus",
				label: t("rowActions.delete"),
				onClick: () => this.openAction(thisAction('delete'), user)
			}
		)
		if (this.props.admin.objectAccess === 'ALL') {
			actions.push(
				{
					key: 8,
					color: "",
					icon: "trash",
					id: "delete-physical",
					label: t("rowActions.deletePhysical"),
					onClick: () => this.openAction(thisAction('delete-physical'), user)
				}
			);
		}
		if (user.phone) {
			actions.push({
				key: 9,
				icon: "comments",
				id: "send-nps",
				label: t("rowActions.sendNps"),
				onClick: () => this.openAction(thisAction('send-nps'), user),
			})
		}
		actions.push(
			{
				key: 10,
				id: "review-history",
				color: "",
				icon: "comment-alt",
				label: t("rowActions.reviewHistory"),
				onClick: () => {
					this.openAction(thisAction('review-history'), user, this.props.review)
				}
			}
		)
		return actions;
	}
	render() {
		const { t } = this.props;
		let { userDetails, inline, selectedStores, updateUserStatus } = this.props;
		let items = [
			<UserCard
				user={userDetails.userInformation}
				registrationDate={userDetails.registrationDate}
				lastVisitationDate={userDetails.lastVisitationDate}
				inline={inline}
			/>,
			<UserActivitySummary userDetails={userDetails} />,
		];
		if (!(selectedStores && selectedStores.length === 1 && selectedStores[0] && selectedStores[0].storeCategory &&
			selectedStores[0].storeCategory.isSuscription))
			items.push(
				<UserValue t={t} value={userDetails.clientValue} />
			);

		return (
			<Container fluid className='animated animated-short fadeInRight pl-0'>
				<MDBContainer>
					{/* <MDBRow>
						{
							// !inline && this.props.selectedStores.length === 1 && this.props.selectedStores[0] && (
							!inline && (
								<span className="w-100 text-right">
									<Actions renderMode={RenderMode.DEFAULT} actions={this.actions()} />
									<ClientActions
										{...this.state.actionsContainer}
										onClose={this.closeAction}
										stores={this.props.stores || this.props.availableStores}
										selectedStore={this.props.selectedStores.length === 1 && this.props.selectedStores[0]}
									/>
								</span>
							)
						}
					</MDBRow> */}
					<MDBRow>
						{inline &&
							<MDBCol size="12" className="my-auto user-tags" >
								{selectedStores && selectedStores.length > 0 &&
									<div style={{ overflow: "auto" }}>
										<UserTags selectedStore={selectedStores} userId={userDetails.userInformation.id} admin={this.props.admin} />
									</div>
								}
							</MDBCol>
						}
						{ (!inline && !this.props.hideActions) && (
							<>
								<MDBCol size="9" md="11" className="my-auto user-tags p-0" >
									{selectedStores && selectedStores.length > 0 &&
										<div style={{ overflow: "auto" }}>
											<UserTags selectedStore={selectedStores} userId={userDetails.userInformation.id} admin={this.props.admin} />
										</div>
									}
								</MDBCol>
								<MDBCol size="3" md="1" className="p-0">
									{/* // !inline && this.prop.selectedStores.length === 1 && this.props.selectedStores[0] && ( */}
									<span>
										<Actions actionsPrefix="users" renderMode={RenderMode.DEFAULT} actions={this.actions()} />
										<ClientActions
											{...this.state.actionsContainer}
											onClose={this.closeAction}
											stores={this.props.stores || this.props.availableStores}
											selectedStore={this.props.selectedStores.length === 1 && this.props.selectedStores[0]}
											componentSelectedStore={this.props.componentSelectedStore}
										/>
									</span>
								</MDBCol>
							</>
						)}
					</MDBRow>
					<MDBRow>
						<MDBCol lg="6" md="12">
							<div className='text-right'>
								{
									selectedStores && selectedStores.length === 1 && selectedStores[0] && selectedStores[0].storeCategory &&
									selectedStores[0].storeCategory.isSuscription &&
									<span>
										<StatusSelect userStatus={userDetails.userStatus} updateUserStatus={updateUserStatus} />
									</span>
								}
							</div>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
				<Carousel
					items={items}
					autoPlay={false}
					fadeOutAnimation={true}
					mouseDragEnabled={true}
					responsive={
						{
							600: {
								items: 1
							},
							1200: {
								items: 3
							}
						}
					}
					disableAutoPlayOnAction={true}
					buttonsDisabled={true}
					infinite={false}
				/>
				<Row>
					<Col className='d-none d-md-block' lg={7}>
						<UserVisitationsDetail visitationsByStore={userDetails.visitationsGroupdByStores} visitationsByDate={userDetails.visitationsGroupedByDate} />
					</Col>
					<Col className="my-2" md={12} lg={5} style={{ overflowY: 'scroll', minHeight: '350px' }}>
						<UserActivityDetail events={userDetails.allEvents ? userDetails.allEvents.slice(0, 100) : undefined} updateUser={this.props.updateUser} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		selectedCompany: state.application.selectedCompany,
	}
}

UserDetails.translations = {
	...clientsRowAction,
	...translations
}

export default connect(mapStateToProps)(translate(UserDetails));
