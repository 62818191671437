import React, { useEffect, useState } from 'react';
import { getAdminAvailableProfiles, getAdminById, updateAdministrator } from '../../api/administratorApi';
// import Loading from '../../commons/components/utils/Loading';
import { FastField, Field, Form, Formik } from 'formik';
import { MDBBtn, MDBContainer, MDBInput, MDBSwitch } from 'mdbreact';
import { useSelector } from 'react-redux';
import translate from '../../config/translations/translate';
import { Select } from "botbit-ui-components";
import { useHistory } from 'react-router-dom';
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody, ImageCropper, Image } from "botbit-ui-components";
import { uploadAdministratorAvatar } from '../../api/utilsApi';
import { envConfig } from "../../config/config";


const AdministratorEdit = ({ id }) => {

    const [administrator, setAdministrator] = useState();
    const [profiles, setProfiles] = useState();
    const logedAdmin = useSelector(state => state.login.admin)
	const selectedCompany = useSelector(state => state.application.selectedCompany);

    const logedAdminId = logedAdmin.id
    const objectAccess = logedAdmin.objectAccess
    const adminOperations = logedAdmin.operations
    
    const adminHasAssignerOperation = adminOperations.includes("CONVERSATION__ASSIGN") || objectAccess === "ALL";
    const adminHasReplierOperation = adminOperations.includes("CONVERSATION__REPLY") || objectAccess === "ALL";

    useEffect(() => {
        if (id) {
            getAdminById(id).then((res) => {
                setAdministrator({
                    ...res.data.data,
                    stores:res.data.data.stores.map(
                        s=> ({id:s.id, name:s.name, companyId:s.companyId})
                    )
                });
            });
        } else {
            setAdministrator({
                active: true,
                sendWTEmail: true,
                detractorsAlert: true,
                weeklyReport: true,
				weeklyReviewReport: true,
                reviewChangesAlert: true,
                profiles: [],
                companies: [],
                stores: [],
				companyId: selectedCompany.id
            });
        }
        getAdminAvailableProfiles(logedAdminId).then((res) => {
            setProfiles(res.data.data);
        })
    }, [id])

    if (administrator && profiles) {
        const selfProfile = administrator.id === logedAdminId;
        return <AdministratorForm 
			selfProfile={selfProfile}
			administrator={administrator} 
			profiles={profiles}
			adminHasAssignerOperation={adminHasAssignerOperation}
			adminHasReplierOperation={adminHasReplierOperation}>
		</AdministratorForm>
    } else {
        return null;
    }
};

/**
 * 
 * @param {*} props
 * @param {Array} props.profiles 
 */
const AdministratorFormComponent = ({selfProfile, administrator, profiles, t, adminHasReplierOperation, adminHasAssignerOperation}) => {

	const initialMaxObject = calculateMaxObjectAccess(administrator.profiles);

    const availableCompanies = useSelector(state => state.application.availableCompanies);
    const availableStores = useSelector(state => state.application.availableStores.map(
        s=> ({id:s.id, name:s.name, companyId:s.companyId})
    ));
	const selectedHeaderCompany = useSelector(state => state.application.selectedCompany);

    const [maxObjectAccess, setMaxObjectAccess] = useState(initialMaxObject);
    const [selectedCompanies, setSelectedCompanies] = useState(calculateSelectedCompanies(administrator, initialMaxObject==="SELF"));
    const [selectedStores, setSelectedStores] = useState(administrator.stores.map(c=>c.id.toString()));
    const [storesOptions, setStoresOptions] = useState(calculateStoresOptions(availableStores, selectedCompanies, selectedStores, initialMaxObject==="SELF"))
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);
    
    const history = useHistory();

    const schema = Yup.object().shape({
        email: Yup.string().email(({ value }) => t("invalidEmail", { value }))
            .required(t("fieldIsRequired")),
        name: Yup.string().required(t("fieldIsRequired")),
        lastName:  Yup.string().required(t("fieldIsRequired")),
        username:  Yup.string().required(t("fieldIsRequired")),
    })

    const openCropModal = event => {
        event.preventDefault();
        setIsCropModalOpen(true)
    }

    const _uploadImage = (formData) => {
        return uploadAdministratorAvatar(formData);
    }

    const onUploadSuccess = (uploadResponse, blob, setFieldValue) => {
        let filename=envConfig.staticImgUrl + "avatars/" + uploadResponse.data.data[0];
        const separator=filename.lastIndexOf("/");
        if(separator)
            filename=filename.substring(filename.lastIndexOf("/")+1);
        setFieldValue(`avatar`,filename);
        setIsCropModalOpen(false);
    }

    return (
        <div className="container administrator-edit">
            <h1>{t("title")}</h1>
            <Formik
                initialValues={administrator}
                onSubmit={(values, actions) => {
                    updateAdministrator(values)
                    .then(res=>{
                        if(selfProfile)
                            history.push('/dashboard')
                        else
                            history.push('/settings/administrators/')
                    });
                }}
                validationSchema={schema}
            >
                {props => (
                    <Form>
                        {selfProfile && 
                            <div>Avatar<br/>
                                <div className="image-and-upload-container mt-4">
                                    <Modal isOpen={isCropModalOpen} class='UpdateCompanyModal my-0'>
                                        <ModalHeader toggle={() => setIsCropModalOpen(false)}></ModalHeader>
                                        <ModalBody style={{ textAlign: 'center' }}>
                                            <label style={{ color: "#898989" }} id="info">{t("chooseImage")}</label>
                                            <ImageCropper className="image-cropper" displayMessage={t("dragAndDrop")} onUpload={_uploadImage} onUploadSuccess={(a, b) => onUploadSuccess(a, b, props.setFieldValue)} /> {/* Añado parametro setFieldValue a la función porque no lo tengo como variable global */}
                                        </ModalBody>
                                    </Modal>
                                    <Image scaleFixedSize={{ width: 80, height: 80 }} src={(props.values.avatar && props.values.avatar.length > 0) ? envConfig.staticImgUrl + "avatars/" + props.values.avatar : envConfig.staticImgUrl + "/noavatar.jpg"}></Image>
                                    <button id="add-image" onClick={openCropModal}>
                                        <div>
                                            <i className="fa fa-plus" style={{ cursor: "pointer", color: 'white' }}></i>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        }

                        {input(props, 'name', t)}
                        {input(props, 'lastName', t)}
                        {input(props, 'username', t)}
                        {input(props, 'email', t)}
						<br/>
                        {!selfProfile && switchInput(props, 'active', t)}
                        {switchInput(props, 'sendWTEmail', t)}
                        {switchInput(props, 'weeklyReport', t)}
                        {switchInput(props, 'weeklyReviewReport', t)}
                        {switchInput(props, 'detractorsAlert', t)}
                        {switchInput(props, 'reviewChangesAlert', t)}
                        {switchInput(props, 'promotersAlert', t)}

                        <FastField name={'locale'}>
                            {({ field, form }) => (
                                <Select
                                    selected={t('label.locale')}
                                    label={t('label.locale')}
                                    getValue={(value) => props.setFieldValue('locale', value[0])}
                                    options={[
                                        { text: "es-AR", value: "es-AR", checked: "es-AR" === field.value },
                                        { text: "pt-BR", value: "pt-BR", checked: "pt-BR" === field.value },
                                        { text: "en-US", value: "en-US", checked: "en-US" === field.value },
                                        { text: "fr-FR", value: "fr-FR", checked: "fr-FR" === field.value }
                                    ]}
                                >
                                </Select>
                            )}
                        </FastField>
                        <FastField name={'profiles'}>
                            {({ field, form }) => (
                                !selfProfile &&
                                <>
                                    <Select
                                        selected={t('label.profiles')}
                                        label={t('label.profiles')}
                                        getValue={
                                            (value) => {
                                                const extraProfiles = field.value.filter(p => !p.selectable);
                                                const selectedProfiles = [...profiles.filter(p => p.id === value[0]), ...extraProfiles];
												const maxSelectedObjectAccess = calculateMaxObjectAccess(selectedProfiles);
												setMaxObjectAccess(maxSelectedObjectAccess)
												
												if(maxSelectedObjectAccess === "SELF"){
													props.setFieldValue('companies',[]);
													setSelectedCompanies([]);
													setStoresOptions(calculateStoresOptions(availableStores, [], selectedStores,  true))
												} else if(maxSelectedObjectAccess === "COMPANY"){
                                                    props.setFieldValue('stores', []);
                                                    setSelectedStores([]);
                                                    props.setFieldValue('companies', [ {id: selectedHeaderCompany.id.toString()} ]);
													setSelectedCompanies([selectedHeaderCompany.id.toString()]);
                                                    setStoresOptions(calculateStoresOptions(availableStores, selectedCompanies, [], false))
                                                } else if(maxSelectedObjectAccess === "ALL"){
                                                    props.setFieldValue('companies',[]);
                                                    props.setFieldValue('stores',[]);
                                                    props.setFieldValue('companyId',3);
                                                }

                                                props.setFieldValue('profiles', selectedProfiles);
                                            }
                                        }    
                                        options={profiles.map(p => (
                                            {
                                                text: p.id + ': ' + p.description,
                                                value: p.id,
                                                checked: field.value && field.value.some(v=>v.id === p.id)
                                            }
                                        ))}
                                        search={true}
                                    ></Select>
                                    {   (adminHasAssignerOperation || adminHasReplierOperation) &&
                                        <>
                                            <h4>{t('conversations')}</h4>
                                            <MDBContainer className="role-container">
                                                <MDBInput
                                                    id="conversationRoleNone"
                                                    checked={!field.value.some(v => v.id==="CONVERSATION_REPLIER")
                                                        && !field.value.some(v => v.id==="CONVERSATION_AUTOASSIGNER")
                                                        && !field.value.some(v => v.id==="CONVERSATION_MANAGER")}
                                                    label={t("conversationRoleNone")}
                                                    type="radio"
                                                    onClick={() => {
                                                        const updatedProfiles = props.values.profiles.filter(
                                                            p => p.id !== "CONVERSATION_AUTOASSIGNER" &&
                                                                p.id !== "CONVERSATION_MANAGER" &&
                                                                p.id !== "CONVERSATION_REPLIER"
                                                        )
                                                        props.setFieldValue("profiles", updatedProfiles);
                                                    }}
                                                />
                                                { adminHasReplierOperation && <MDBInput
                                                    id="conversationRoleReplier"
                                                    checked={field.value.some(v => v.id==="CONVERSATION_REPLIER")}
                                                    label={t("conversationRoleReplier")}
                                                    type="radio"
                                                    onClick={() => {
                                                        const updatedProfiles = props.values.profiles.filter(
                                                            p => p.id !== "CONVERSATION_AUTOASSIGNER" &&
                                                                p.id !== "CONVERSATION_MANAGER" &&
                                                                p.id !== "CONVERSATION_REPLIER"
                                                        )
                                                        updatedProfiles.push({id:"CONVERSATION_REPLIER"})
                                                        props.setFieldValue("profiles", updatedProfiles);
                                                    }}
                                                />}
                                                { adminHasAssignerOperation && <MDBInput
                                                    id="conversationRoleAutoasigner"
                                                    checked={field.value.some(v => v.id==="CONVERSATION_AUTOASSIGNER")}
                                                    label={t("conversationRoleAutoasigner")}
                                                    type="radio"
                                                    onClick={() => {
                                                        const updatedProfiles = props.values.profiles.filter(
                                                            p => p.id !== "CONVERSATION_AUTOASSIGNER" &&
                                                                p.id !== "CONVERSATION_MANAGER" &&
                                                                p.id !== "CONVERSATION_REPLIER"
                                                        )
                                                        updatedProfiles.push({id:"CONVERSATION_AUTOASSIGNER"})
                                                        props.setFieldValue("profiles", updatedProfiles);
                                                    }}
                                                />}
                                                { adminHasAssignerOperation && <MDBInput
                                                    id="conversationRoleAsigner"
                                                    checked={field.value.some(v => v.id==="CONVERSATION_MANAGER")}
                                                    label={t("conversationRoleAsigner")}
                                                    type="radio"
                                                    onClick={() => {
                                                        const updatedProfiles = props.values.profiles.filter(
                                                            p => p.id !== "CONVERSATION_AUTOASSIGNER" &&
                                                                p.id !== "CONVERSATION_MANAGER" &&
                                                                p.id !== "CONVERSATION_REPLIER"
                                                        )
                                                        updatedProfiles.push({id:"CONVERSATION_MANAGER"})
                                                        props.setFieldValue("profiles", updatedProfiles);
                                                    }}
                                                />}
                                            </MDBContainer>
                                            {form.errors[field.name] && <span className="feedback text-danger">{form.errors[field.name]}</span>}
                                            <p className="helperMsg">{t("conversationRoleHelperMsg")}</p>
                                        </>
                                    }
                                </>
                            )}
                        </FastField>
                        {   maxObjectAccess === "COMPANY" &&
                            <Field name={'companies'}>
                                {({ field, form }) => (
                                    !selfProfile &&
                                    <Select
                                        selected={t('label.companies')}
                                        label={t('label.companies')}
                                        getValue={(value) => {
                                            setSelectedCompanies(value);
                                            setStoresOptions(calculateStoresOptions(availableStores, value, selectedStores, false))
                                            props.setFieldValue('companies', availableCompanies.filter(s => value.includes(s.id.toString())));
                                        }}
                                        multiple={true}
                                        options={availableCompanies.map(ac => (
                                            {
                                                text: ac.name,
                                                value: ac.id.toString(),
                                                checked: selectedCompanies.includes(ac.id.toString())
                                            }
                                        ))}
                                        search={true}
                                    ></Select>
                                )}
                            </Field>
                        }
                        {   maxObjectAccess === "SELF" &&
                            <Field name={'companyId'}>
                                {({ field, form }) => (
                                    !selfProfile && 
                                    <Select
                                        selected={t('label.companyId')}
                                        label={t('label.companyId')}
                                        getValue={(value) => {
                                            props.setFieldValue('companyId', value[0]);

                                        }}
                                        multiple={false}
                                        options={availableCompanies.map(ac => (
                                            {
                                                text: ac.name,
                                                value: ac.id.toString(),
                                                checked: (field.value && field.value.toString() === ac.id.toString())//!!!field.value.findIndex(c => c.id.toString() === ac.id.toString())
                                            }
                                        ))}
                                        search={true}
                                    ></Select>
                                )}
                            </Field>
                        }
                        { maxObjectAccess && maxObjectAccess !== "ALL" && !selfProfile && <Field name={'stores'}>
                            {({ field, form }) => (
                                <Select
                                    selected={t('label.stores')}
                                    label={maxObjectAccess === "SELF" ? t('label.stores') : t('label.aditionalstores')}
                                    getValue={(value) => {
                                        props.setFieldValue('stores', availableStores.filter(s => value.includes(s.id.toString())))
                                        setSelectedStores(value);
                                        setStoresOptions(calculateStoresOptions(availableStores, selectedCompanies, value, maxObjectAccess === "SELF"))

                                    }}
                                    multiple={true}
                                    options={storesOptions}
                                    search={true}
                                ></Select>
                            )}
                        </Field>}
                        
                        <MDBBtn type="submit" color='primary'>{t('submit')}</MDBBtn>
                        <MDBBtn onClick={()=>history.goBack()} color='secondary'>{t('cancel')}</MDBBtn>

                    </Form>

                )}

            </Formik>

        </div>
    )
}

function calculateSelectedCompanies(administrator, isSelfAccess){
    if(isSelfAccess){
        /**@type Array */
        const stores =  administrator.stores;
        const companies = stores.map(s=>s.companyId.toString());
        return [...new Set(companies)];
    }
    return administrator.companies.map(c=>c.id.toString())
}

const calculateMaxObjectAccess = (profiles) => {
	if (!profiles || profiles.length === 0) {
		return undefined;
	}

	let max = undefined;
	profiles.forEach(profile => {
		if (profile.objectAccess) {
			if (!max) {
				max = profile.objectAccess;
			} else if (max === "SELF") {
				max = profile.objectAccess;
			} else if (max === "COMPANY" && profile.objectAccess === "ALL") {
				max = "ALL"
			}
		}
	});
	return max;
}



const calculateStoresOptions = (availableStores, selectedCompanies, selectedStores, isSelfAccess)=> {
    let filteredStores = isSelfAccess ? availableStores : availableStores.filter(s => isSelfAccess || !selectedCompanies.includes(s.companyId.toString()));
    return filteredStores.map(as => (
        {
            text: as.name,
            value: as.id.toString(),
            checked: selectedStores.includes(as.id.toString())
        }
    ))
}

AdministratorFormComponent.translations = {
    label: {
        locale: {
            es: "Idioma",
            en: "Language"
        },
        name: {
            es: "Nombre",
            en: "Name"
        },
        lastName: {
            es: "Apellido",
            en: "Last Name"
        },
        profiles: {
            es: "Perfiles",
            en: "Profiles"
        },
        companies: {
            es: "Empresas",
            en: "Companies"
        },
        stores: {
            es: "Tiendas",
            en: "Stores"
        },
        aditionalstores: {
            es: "Tiendas adicionales",
            en: "Aditional stores"
        },
        email: {
            es: "Email",
            en: "Email"
        },
        username:{
            es: "Nombre de usuario",
            en: "User name"
        },
        companyId: {
            es: "Empresa a la que pertenece el administrador",
            en: "Administrator company"
        }
    },
    submit: {
        es: "Guardar",
        en: "Save"
    },
    cancel: {
        es: "Cancelar",
        en: "Cancel"
    },
    title: {
        es: "Edición de administrador",
        en: "Administrator edit form"
    },
    labelRight: {
        active: {
            es: "Activo",
            en: "Active"
        },
        sendWTEmail: {
            es: "Enviar alerta de Walkthrough",
            en: "Walkthrough alert"
        },
        weeklyReport: {
            es: "Reporte semanal",
            en: "Weekly report"
        },
		weeklyReviewReport: {
            es: "Reporte semanal de reputación",
            en: "Weekly reputation report"
        },
        detractorsAlert: {
            es: "Alerta de detractores",
            en: "Detractors alert"
        },
        reviewChangesAlert: {
            es: "Alerta de cambio en reseñas",
            en: "Review changes alert"
        },
        promotersAlert: {
            es: "Alerta de reseñas estimuladas",
            en: "Stimulated reviews alert"
        }
    },
    conversations:{
        es: "Acceso a las conversaciones",
        en: "Conversations Access"
    },
    conversationRoleNone: {
        es: "Sin acceso a conversaciones",
        en: "No access to conversations"
    },
    conversationRoleReplier: {
        es: "Puede contestar conversaciones asignadas",
        en: "Can reply assigned conversations"
    },
    conversationRoleAsigner: {
        es: "Puede contestar y asignar conversaciones",
        en: "Can reply and assign conversations"
    },
    conversationRoleAutoasigner:{
        es: "Puede contestar conversaciones asignadas y sin asignación",
        en: "Can reply assigned and unassigned conversations"
    },
    conversationRoleHelperMsg: {
        es: "Selecciona que permisos sobre las conversaciones tenga el administrador",
        en: "Select permissions over conversations for administrator"
    },
    fieldIsRequired: {
        es: "Este campo es requerido",
        en: "This is a requiered field"
    },
    invalidEmail: {
        es: "Ingrese un email válido",
        en: "Enter a valid email"
    }
}

const AdministratorForm = translate(AdministratorFormComponent);

const input = (props, name, t) => (
    <div>
        <FastField name={name}>
            {({ field, form }) => (
                <>
                    <MDBInput
                        type="text"
                        getValue={value => props.setFieldValue(name, value)}
                        onFocus={()=>form.setFieldTouched(name)}
                        value={field.value}
                        label={t('label.' + name)}
                        name={name}
                    />
                    {form.errors[field.name] && <div className="feedback text-danger">{form.errors[field.name]}</div>}
                </>
            )}
        </FastField>
    </div>
)

const switchInput = (props, name, t) => (
    <FastField name={name}>
        {({ field, form }) => {
            return <MDBSwitch
                labelRight={t("labelRight."+name)}
                labelLeft=""
                checked={field.value}
                getValue={value => {
                    props.setFieldValue(name,value)
                }}
            ></MDBSwitch>
        }}
    </FastField>
)

export default AdministratorEdit;