import React from "react";
import { MDBBadge, MDBIcon } from "mdbreact";
import StarRatings from 'react-star-ratings';
import { round } from '../../commons/utils/numbers';

const Score = ({ value, size, starDimension, nps }) => {
    if (size === 's')
        return <MDBBadge className={nps ? `px-3` : ""} pill color={getScoreColor(value)}>
            {round(value, 2)} {!nps && <MDBIcon className="ml-1" icon="star" size="1x" />}
        </MDBBadge>
    else
        return <StarRatings
            rating={round(value, 2)}
            starDimension={starDimension ? starDimension : "15px"}
            starSpacing="0px"
        />

}

export const getScoreColor = (score) => {
    if (score >= 4.5) //9 y 10 son promoters
        return 'success'
    else if (score < 3.5) // <7 detractors
        return 'danger'
    else
        return 'warning' // 7 y 8 neutros
}

export default Score