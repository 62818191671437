import axiosClient from './axiosClient';

export const getAllChannels = () => {
	return axiosClient.get("/tasks/channels")
}

export const getTaskTypes = (storeId) => {
	return axiosClient.get(`/tasks/stores/${storeId}/task-types`);
}

export const saveTask = (task) => {
	return axiosClient.post("/tasks", task);
}


export const getTask = (id) => {
	return axiosClient.get(`/tasks/${id}`);
}

export const getTasks = (page, order, filters) => {
	const pageParam=page !== undefined ?`page=${page}&` : "";
	const orderParam=order ? `sort=${order.name}&sortDirection=${order.direction}&` : "";
	return axiosClient.get(`/tasks?${getFilterParams(filters)}${pageParam}${orderParam}`);
}

export const exportTasks = (order, filters) => {
	const orderParam=order ? `sort=${order.name}&sortDirection=${order.direction}&` : "";
	return new Promise(
		resolve=> {
			axiosClient
				.get(`/tasks/csv?${getFilterParams(filters)}${orderParam}status=true&`)
				.then(
					res => resolve(res.data)
				)
		}
	)
}

const getFilterParams = ({stores=[], adminFrom, adminTo, status, dueDate, userFilter, closeDate, creationDate, inactiveAdministratorOnly, withHistory}) => {
	const storeIdsParam=stores[0] !== undefined ?`storeIds%5B%5D=${stores}&` : "";
	const adminFromParam=adminFrom[0] !== undefined ?`adminFrom=${adminFrom[0]}&` : "";
	const adminToParam=adminTo[0] !== undefined ?`adminTo=${adminTo[0]}&` : "";
	const statusParam=status[0] !== undefined ?`status%5B%5D=${status.toString()}&` : "";
	const dueDateFromParam=dueDate[1] !== undefined ?`dueDateFrom=${dueDate[1].toISOString()}&` : "";
	const dueDateToParam=dueDate[2] !== undefined ?`dueDateTo=${dueDate[2].toISOString()}&`: "";
	const closeDateFromParam=closeDate[1] !== undefined ?`closeDateFrom=${closeDate[1].toISOString()}&` : "";
	const closeDateToParam=closeDate[2] !== undefined ?`closeDateTo=${closeDate[2].toISOString()}&`: "";
	const creationDateFromParam=creationDate[1] !== undefined ?`creationDateFrom=${creationDate[1].toISOString()}&` : "";
	const creationDateToParam=creationDate[2] !== undefined ?`creationDateTo=${creationDate[2].toISOString()}&`: "";
	const userFilterParam=userFilter !== undefined ? `userFilter=${encodeURIComponent(userFilter)}&` : "";
	const activeAdministratorParam =`activeAdministrator=${encodeURIComponent(!!!inactiveAdministratorOnly[0])}&`;
	const withHistoryFromParam = withHistory[1] !== undefined ? `withHistoryDateFrom=${withHistory[1].toISOString()}&` : "";
	const withHistoryToParam = withHistory[2] !== undefined ? `withHistoryDateTo=${withHistory[2].toISOString()}&` : "";
	return `${storeIdsParam}${adminFromParam}${adminToParam}${statusParam}${dueDateToParam}${dueDateFromParam}${userFilterParam}${closeDateFromParam}${closeDateToParam}${activeAdministratorParam}${creationDateFromParam}${creationDateToParam}${withHistoryFromParam}${withHistoryToParam}`;
}
