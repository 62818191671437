import React from 'react';
import { MDBSelect } from "mdbreact";
import { translations } from "../../../../config/translations/Feedback/FeedbackDashboard";
import translate from "../../../../config/translations/translate";


class StoreSelector extends React.Component {

	constructor(props) {
		super(props);
		this.state = { storeSelectOptions: this.storeSelectOptions() };
	}

	storeSelectOptions = () => {
		return this.props.stores.map(
			store => {
				return {
					value: `${store.id}`,
					text: `${store.name}`,
					checked: this.props.selectedStore && this.props.selectedStore.id === store.id
				}
			}
		);
	}

	componentDidUpdate(prevProps, prevState) {
		this.setState({ storeSelectOptions: this.storeSelectOptions() })
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.props.value !== nextProps.value;
	}

	render() {
		const { t } = this.props;
		if (this.props.selectedStore)
			return (<div>{this.props.selectedStore.name}</div>);
		return (
			<MDBSelect
				options={this.state.storeSelectOptions}
				color="primary"
				selected={t("store")}
				search={true}
				getValue={this.props.getValue}
				onChange={() => (undefined)}
			/>

		)
	}
}

StoreSelector.translations = {
	...translations
}

export default translate(StoreSelector)