import { envConfig } from "../../../config";

export const translations = {
  p1: {
    es: `¿Sabías que puedes conectar la aplicación de ${envConfig.companyName} con Shopify? Desde el momento que te conectes registraremos a todos los clientes que te compren a través de esta plataforma y podremos pedirle feedback cada vez que compren en tu tienda en línea o bien podrás realizar campañas especificas para ellos`,
    en: `Did you know that you can connect the ${envConfig.companyName} application with Shopify? From the moment you connect we will register all the customers who buy from you through this platform and we can ask for feedback every time they buy from your online store or you can run specific campaigns for them `
  },
  p2: {
    es: "Conectarlo es muy sencillo:",
    en: "Connecting it is very simple:"
  },
  p3: {
    es: 'Ingresa el nombre de tu tienda de Shopify y haz click en "Conectar"',
    en: 'Enter the name of your Shopify store and click "Connect"'
  },
  p4: {
    es: `Te pedirá los datos de tu cuenta de Shopify y que aceptes los permisos para trabajar junto a ${envConfig.companyName}.`,
    en: `It will ask you for your Shopify account details and to accept the permissions to work with ${envConfig.companyName}.`
  },
  p5: {
    neutral: {
      es: "Por último, elige en",
      en: "Finally, choose in"
    },
    strong: {
      es: `cuál de tus tiendas de ${envConfig.companyName} quieres recibir los datos de tus clientes de Shopify`,
      en: `which of your ${envConfig.companyName} stores do you want to receive the data of your Shopify customers`
    }
  },
  p6: {
    es: "¡Listo! ¡Ya estás conectado!",
    en: "Done! You're already connected!"
  },
  p7: {
    text: {
      es: "Para mayor información, haz",
      en: "For more information, do"
    },
    link: {
      es: "click aquí",
      en: "click here"
    }
  }
}