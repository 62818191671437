import React from "react";
import { getTranslationFunction } from "../../config/translations/translate";
import { translations } from "../../config/translations/Dashboard/userModal";

const t = getTranslationFunction({ ...translations });
const COLUMNS_PREFIX = "columnName";

const defaulColumns = [
  {
    dataField: "avatar",
    title: t(`${COLUMNS_PREFIX}.avatar`),
    // className: "d-none d-md-block",
    formatter: (cellContent, row) => {
      const avatarImg =
        row.avatar && row.avatar !== "" ? row.avatar : "noavatar.jpg";
      let imgsrc = `https://admin.botbit.io/static/img/avatars/${avatarImg}`;
      return (
        <div className="w-100">
          <img src={imgsrc} className="rounded-circle" style={{ width: "45px" }} alt="" />
        </div>
      );
    },
    size: 8,
  },
  {
    dataField: "name",
    title: t(`${COLUMNS_PREFIX}.name`),
    // className: "d-none d-md-block",
    size: 15,
    formatter: (cellContent, row) => {
      return `${(row.name) ? row.name : ''} ${(row.lastName) ? row.lastName : ''}`;
    },
  },
];

export default defaulColumns;
