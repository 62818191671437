const companiesWithSentiments = [3,24,516,840,925,1015,1016,1024,1058,1090,1122,1133,1162,1248,1261,1262]

const allConfig = {
	"development": {
		"dummyEnabled": false,
		"servicesUrl": "http://localhost:8080",
		"companyName": "RAY",
		"backofficeUrl": "http://admin0.botbit.io",
		"frontendPhpUrl": "http://admin0.botbit.io",
		"captivePhpUrl": "http://captive0.botbit.io",
		"staticImgUrl": "http://admin0.botbit.io/static/img/",
		"rayLogoWhiteUrl": "http://admin0.botbit.io/static/img/RAY-Brand-Black.png",
		"rayLogoUrl": "http://admin0.botbit.io/static/img/RAY-Brand-Red.png",
		"imgUrl": "http://admin0.botbit.io/",
		"GA_ID_CAPTIVE": "UA-88592030-4",
		"GA_ID_ADMIN": "UA-88592030-7",
		"webPushOptInEnabled": false,
		"oneSignalAppId": "43b103a2-fede-4dfc-abc0-ae8b6a81ee29",
		"oneSignalTimeout": 10000,
		"INTERCOM_APP_ID": "o5b1wi0r",
		"feedbackDomain": "http://feedback0.botbit.io",
		"mixpanelToken": "16513d395fd957611e44567aa980082e",
		"ordersUrls": {
			"orders": "https://api-sso.qa.hotbot.ai/sso?path=shop-orders",
			"stats": "https://api-sso.qa.hotbot.ai/sso?path=stats",
			"chats": "https://api-sso.qa.hotbot.ai/sso?path=chats",
			"settings": {
				"shop": "https://api-sso.qa.hotbot.ai/sso?path=settings/shop",
				"channels": "https://api-sso.qa.hotbot.ai/sso?path=settings/channels",
				"builder": "https://api-sso.qa.hotbot.ai/sso?path=settings/builder",
			}
		},
		"token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYm90Yml0X2JhY2tlbmQiXSwidXNlcl9uYW1lIjoiYm90Yml0X3JlZ2lzdHJhdGlvbiIsImF1dGhvcml0aWVzIjpbIk9SREVSU19TVEFUU19fVklFVyIsIlNUT1JFX0FOQUxZVElDU19fVklFVyIsIkhFTFBFUlNfX1VTRSIsIlNFR01FTlRTX19ERUxFVEUiLCJVU0VSU19FVkVOVF9fRVhFQ1VURSIsIkNSTV9fVklFVyIsIlJFUExZX1RFTVBMQVRFU19fVklFVyIsIk9SREVSU19DSEFOTkVMU19fQ09ORklHIiwiQVVUT19SRVBMWV9SVUxFU19fRURJVCIsIlJFUExZX1RFTVBMQVRFU19fRURJVCIsIkNPTVBBTklFU19fVklFVyIsIkFDQ0VTU19QT0lOVFNfX0RFTEVURSIsIlJFVklFV1NfX0VESVQiLCJVU0VSU19fVklFVyIsIkVWRU5UU19fRURJVCIsIlJFU0VSVkFUSU9OU19fVklFVyIsIlNUT1JFU19fRURJVCIsIlVTRVJTX19ERUxFVEUiLCJQUk9NT19DT0RFU19fVklFVyIsIk9BVVRIX19WSUVXIiwiT0FVVEhfX0VESVQiLCJERVBSRUNBVEVEIiwiQURNSU5JU1RSQVRPUlNfX0RFTEVURSIsIkFETUlOSVNUUkFUT1JTX19FRElUIiwiQVVUT19SRVBMWV9SVUxFU19fREVMRVRFIiwiREVWSUNFU19fVklFVyIsIkNBTVBBSUdOU19fRURJVCIsIlBST01PX0NPREVTX19FRElUIiwiU1RPUkVTX19WSUVXIiwiU0xfTUFOVUFMX01BVENISU5HU19fVklFVyIsIlVTRVJTX19FRElUIiwiQ09NUEFOWV9BTkFMWVRJQ1NfX1ZJRVciLCJSRVNFUlZBVElPTlNfX0VESVQiLCJBRE1JTl9FVkVOVFNfX1ZJRVciLCJPUkRFUlNfX0NPTkZJRyIsIkJBVENIX19FWEVDVVRFIiwiQURNSU5JU1RSQVRPUlNfX1ZJRVciLCJERVZJQ0VTX19ERUxFVEUiLCJUUkFGRklDX0FOQUxZU0lTX19WSUVXIiwiQUNDRVNTX1BPSU5UU19fRURJVCIsIkRFVklDRVNfX0VESVQiLCJTVE9SRVNfX0VYRUNVVEUiLCJTRUdNRU5UU19fVklFVyIsIlVTRVJfVEFHU19fRURJVCIsIlBST01PX0NPREVTX19ERUxFVEUiLCJBVVRPX1JFUExZX1JVTEVTX19WSUVXIiwiT1JERVJTX19WSUVXIiwiU0VHTUVOVFNfX0VESVQiLCJDUk1fX0VESVQiLCJDT01QQU5JRVNfX0VESVQiLCJBQ0NFU1NfUE9JTlRTX19WSUVXIiwiUkVWSUVXU19fUkVQT1JUIiwiUkVHSVNURVJFRF9VU0VSX19ERUxFVEUiLCJSRVBMWV9URU1QTEFURVNfX0RFTEVURSIsIkNBTVBBSUdOU19fVklFVyIsIlJFVklFV1NfX1JFUExZIiwiUkVWSUVXU19fVklFVyJdLCJqdGkiOiIxZTM4MWVmOC1kYmVlLTRlOGMtOGM0MS05OGU4MmY3YzQxMzYiLCJjbGllbnRfaWQiOiJib3RiaXRfcmVnaXN0cmF0aW9uIiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl19.m_7rOzQwNDDckQh2DFyXmczzGFTxlHXQnpohFZ_JBeg",
		"purchaseLanding": "https://landings.rayapp.io/postfreetrial/",
		"socketUrl": "http://socket0.botbit.io",
		"companiesWithSentiments": companiesWithSentiments,
		// "HOTJAR_HJID": "3623858",
		// "HOTJAR_HJSV": "6",
	},
	"docker": {
		"dummyEnabled": false,
		"servicesUrl": "http://localhost:8080",
		"companyName": "RAY",
		"backofficeUrl": "http://localhost:80",
		"frontendPhpUrl": "http://localhost:80",
		"captivePhpUrl": "http://captive0.botbit.io",
		"staticImgUrl": "http://localhost:8000/static/img/",
		"rayLogoWhiteUrl": "http://admin0.botbit.io/static/img/RAY-Brand-Black.png",
		"rayLogoUrl": "http://admin0.botbit.io/static/img/RAY-Brand-Red.png",
		"imgUrl": "http://localhost:8000/",
		"GA_ID_CAPTIVE": "UA-88592030-4",
		"GA_ID_ADMIN": "UA-88592030-7",
		"webPushOptInEnabled": false,
		"oneSignalAppId": "43b103a2-fede-4dfc-abc0-ae8b6a81ee29",
		"oneSignalTimeout": 10000,
		"INTERCOM_APP_ID": "o5b1wi0r",
		"feedbackDomain": "http://feedback0.botbit.io",
		"mixpanelToken": "16513d395fd957611e44567aa980082e",
		"ordersUrls": {
			"orders": "https://api-sso.qa.hotbot.ai/sso?path=shop-orders",
			"stats": "https://api-sso.qa.hotbot.ai/sso?path=stats",
			"chats": "https://api-sso.qa.hotbot.ai/sso?path=chats",
			"settings": {
				"shop": "https://api-sso.qa.hotbot.ai/sso?path=settings/shop",
				"channels": "https://api-sso.qa.hotbot.ai/sso?path=settings/channels",
				"builder": "https://api-sso.qa.hotbot.ai/sso?path=settings/builder",
			}
		},
		"token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYm90Yml0X2JhY2tlbmQiXSwidXNlcl9uYW1lIjoiYm90Yml0X3JlZ2lzdHJhdGlvbiIsImF1dGhvcml0aWVzIjpbIk9SREVSU19TVEFUU19fVklFVyIsIlNUT1JFX0FOQUxZVElDU19fVklFVyIsIkhFTFBFUlNfX1VTRSIsIlNFR01FTlRTX19ERUxFVEUiLCJVU0VSU19FVkVOVF9fRVhFQ1VURSIsIkNSTV9fVklFVyIsIlJFUExZX1RFTVBMQVRFU19fVklFVyIsIk9SREVSU19DSEFOTkVMU19fQ09ORklHIiwiQVVUT19SRVBMWV9SVUxFU19fRURJVCIsIlJFUExZX1RFTVBMQVRFU19fRURJVCIsIkNPTVBBTklFU19fVklFVyIsIkFDQ0VTU19QT0lOVFNfX0RFTEVURSIsIlJFVklFV1NfX0VESVQiLCJVU0VSU19fVklFVyIsIkVWRU5UU19fRURJVCIsIlJFU0VSVkFUSU9OU19fVklFVyIsIlNUT1JFU19fRURJVCIsIlVTRVJTX19ERUxFVEUiLCJQUk9NT19DT0RFU19fVklFVyIsIk9BVVRIX19WSUVXIiwiT0FVVEhfX0VESVQiLCJERVBSRUNBVEVEIiwiQURNSU5JU1RSQVRPUlNfX0RFTEVURSIsIkFETUlOSVNUUkFUT1JTX19FRElUIiwiQVVUT19SRVBMWV9SVUxFU19fREVMRVRFIiwiREVWSUNFU19fVklFVyIsIkNBTVBBSUdOU19fRURJVCIsIlBST01PX0NPREVTX19FRElUIiwiU1RPUkVTX19WSUVXIiwiU0xfTUFOVUFMX01BVENISU5HU19fVklFVyIsIlVTRVJTX19FRElUIiwiQ09NUEFOWV9BTkFMWVRJQ1NfX1ZJRVciLCJSRVNFUlZBVElPTlNfX0VESVQiLCJBRE1JTl9FVkVOVFNfX1ZJRVciLCJPUkRFUlNfX0NPTkZJRyIsIkJBVENIX19FWEVDVVRFIiwiQURNSU5JU1RSQVRPUlNfX1ZJRVciLCJERVZJQ0VTX19ERUxFVEUiLCJUUkFGRklDX0FOQUxZU0lTX19WSUVXIiwiQUNDRVNTX1BPSU5UU19fRURJVCIsIkRFVklDRVNfX0VESVQiLCJTVE9SRVNfX0VYRUNVVEUiLCJTRUdNRU5UU19fVklFVyIsIlVTRVJfVEFHU19fRURJVCIsIlBST01PX0NPREVTX19ERUxFVEUiLCJBVVRPX1JFUExZX1JVTEVTX19WSUVXIiwiT1JERVJTX19WSUVXIiwiU0VHTUVOVFNfX0VESVQiLCJDUk1fX0VESVQiLCJDT01QQU5JRVNfX0VESVQiLCJBQ0NFU1NfUE9JTlRTX19WSUVXIiwiUkVWSUVXU19fUkVQT1JUIiwiUkVHSVNURVJFRF9VU0VSX19ERUxFVEUiLCJSRVBMWV9URU1QTEFURVNfX0RFTEVURSIsIkNBTVBBSUdOU19fVklFVyIsIlJFVklFV1NfX1JFUExZIiwiUkVWSUVXU19fVklFVyJdLCJqdGkiOiIxZTM4MWVmOC1kYmVlLTRlOGMtOGM0MS05OGU4MmY3YzQxMzYiLCJjbGllbnRfaWQiOiJib3RiaXRfcmVnaXN0cmF0aW9uIiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl19.m_7rOzQwNDDckQh2DFyXmczzGFTxlHXQnpohFZ_JBeg",
		"purchaseLanding": "https://landings.rayapp.io/postfreetrial/",
		"socketUrl": "http://socket0.botbit.io",
		"companiesWithSentiments": companiesWithSentiments
	},
	"docker-mac": {
		"dummyEnabled": false,
		"servicesUrl": "http://localhost:8080",
		"companyName": "RAY",
		"backofficeUrl": "http://localhost:80",
		"frontendPhpUrl": "http://localhost:80",
		"captivePhpUrl": "http://captive0.botbit.io",
		"staticImgUrl": "http://localhost:8000/static/img/",
		"rayLogoWhiteUrl": "http://localhost:8000/static/img/RAY-Brand-Black.png",
		"rayLogoUrl": "http://localhost:8000/static/img/RAY-Brand-Red.png",
		"imgUrl": "http://localhost:8000/",
		"GA_ID_CAPTIVE": "UA-88592030-4",
		"GA_ID_ADMIN": "UA-88592030-7",
		"webPushOptInEnabled": false,
		"oneSignalAppId": "43b103a2-fede-4dfc-abc0-ae8b6a81ee29",
		"oneSignalTimeout": 10000,
		"INTERCOM_APP_ID": "o5b1wi0r",
		"feedbackDomain": "http://feedback0.botbit.io",
		"mixpanelToken": "16513d395fd957611e44567aa980082e",
		"ordersUrls": {
			"orders": "https://api-sso.qa.hotbot.ai/sso?path=shop-orders",
			"stats": "https://api-sso.qa.hotbot.ai/sso?path=stats",
			"chats": "https://api-sso.qa.hotbot.ai/sso?path=chats",
			"settings": {
				"shop": "https://api-sso.qa.hotbot.ai/sso?path=settings/shop",
				"channels": "https://api-sso.qa.hotbot.ai/sso?path=settings/channels",
				"builder": "https://api-sso.qa.hotbot.ai/sso?path=settings/builder",
			}
		},
		"token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYm90Yml0X2JhY2tlbmQiXSwidXNlcl9uYW1lIjoiYm90Yml0X3JlZ2lzdHJhdGlvbiIsImF1dGhvcml0aWVzIjpbIk9SREVSU19TVEFUU19fVklFVyIsIlNUT1JFX0FOQUxZVElDU19fVklFVyIsIkhFTFBFUlNfX1VTRSIsIlNFR01FTlRTX19ERUxFVEUiLCJVU0VSU19FVkVOVF9fRVhFQ1VURSIsIkNSTV9fVklFVyIsIlJFUExZX1RFTVBMQVRFU19fVklFVyIsIk9SREVSU19DSEFOTkVMU19fQ09ORklHIiwiQVVUT19SRVBMWV9SVUxFU19fRURJVCIsIlJFUExZX1RFTVBMQVRFU19fRURJVCIsIkNPTVBBTklFU19fVklFVyIsIkFDQ0VTU19QT0lOVFNfX0RFTEVURSIsIlJFVklFV1NfX0VESVQiLCJVU0VSU19fVklFVyIsIkVWRU5UU19fRURJVCIsIlJFU0VSVkFUSU9OU19fVklFVyIsIlNUT1JFU19fRURJVCIsIlVTRVJTX19ERUxFVEUiLCJQUk9NT19DT0RFU19fVklFVyIsIk9BVVRIX19WSUVXIiwiT0FVVEhfX0VESVQiLCJERVBSRUNBVEVEIiwiQURNSU5JU1RSQVRPUlNfX0RFTEVURSIsIkFETUlOSVNUUkFUT1JTX19FRElUIiwiQVVUT19SRVBMWV9SVUxFU19fREVMRVRFIiwiREVWSUNFU19fVklFVyIsIkNBTVBBSUdOU19fRURJVCIsIlBST01PX0NPREVTX19FRElUIiwiU1RPUkVTX19WSUVXIiwiU0xfTUFOVUFMX01BVENISU5HU19fVklFVyIsIlVTRVJTX19FRElUIiwiQ09NUEFOWV9BTkFMWVRJQ1NfX1ZJRVciLCJSRVNFUlZBVElPTlNfX0VESVQiLCJBRE1JTl9FVkVOVFNfX1ZJRVciLCJPUkRFUlNfX0NPTkZJRyIsIkJBVENIX19FWEVDVVRFIiwiQURNSU5JU1RSQVRPUlNfX1ZJRVciLCJERVZJQ0VTX19ERUxFVEUiLCJUUkFGRklDX0FOQUxZU0lTX19WSUVXIiwiQUNDRVNTX1BPSU5UU19fRURJVCIsIkRFVklDRVNfX0VESVQiLCJTVE9SRVNfX0VYRUNVVEUiLCJTRUdNRU5UU19fVklFVyIsIlVTRVJfVEFHU19fRURJVCIsIlBST01PX0NPREVTX19ERUxFVEUiLCJBVVRPX1JFUExZX1JVTEVTX19WSUVXIiwiT1JERVJTX19WSUVXIiwiU0VHTUVOVFNfX0VESVQiLCJDUk1fX0VESVQiLCJDT01QQU5JRVNfX0VESVQiLCJBQ0NFU1NfUE9JTlRTX19WSUVXIiwiUkVWSUVXU19fUkVQT1JUIiwiUkVHSVNURVJFRF9VU0VSX19ERUxFVEUiLCJSRVBMWV9URU1QTEFURVNfX0RFTEVURSIsIkNBTVBBSUdOU19fVklFVyIsIlJFVklFV1NfX1JFUExZIiwiUkVWSUVXU19fVklFVyJdLCJqdGkiOiIxZTM4MWVmOC1kYmVlLTRlOGMtOGM0MS05OGU4MmY3YzQxMzYiLCJjbGllbnRfaWQiOiJib3RiaXRfcmVnaXN0cmF0aW9uIiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl19.m_7rOzQwNDDckQh2DFyXmczzGFTxlHXQnpohFZ_JBeg",
		"purchaseLanding": "https://landings.rayapp.io/postfreetrial/",
		"socketUrl": "http://socket0.botbit.io",
		"companiesWithSentiments": companiesWithSentiments
	},
	"preprod": {
		"dummyEnabled": false,
		"servicesUrl": "https://api3.botbit.io",
		"companyName": "RAY",
		"backofficeUrl": "https://admin3.botbit.io",
		"frontendPhpUrl": "https://admin3.botbit.io",
		"captivePhpUrl": "https://captive3.botbit.io",
		"staticImgUrl": "https://admin3.botbit.io/static/img/",
		"rayLogoWhiteUrl": "https://admin3.botbit.io/static/img/RAY-Brand-Black.png",
		"rayLogoUrl": "https://admin3.botbit.io/static/img/RAY-Brand-Red.png",
		"imgUrl": "https://admin3.botbit.io/",
		"GA_ID_CAPTIVE": "UA-88592030-4",
		"GA_ID_ADMIN": "UA-88592030-7",
		"webPushOptInEnabled": false,
		"oneSignalAppId": "a57a2994-127b-4a9b-a88d-1cbc3cfedeb8",
		"oneSignalTimeout": 10000,
		"INTERCOM_APP_ID": "o5b1wi0r",
		"feedbackDomain": "https://feedback3.botbit.io",
		"mixpanelToken": "16513d395fd957611e44567aa980082e",
		"ordersUrls": {
			"orders": "https://api.hotbot.ai/sso?path=shop-orders",
			"stats": "https://api.hotbot.ai/sso?path=stats",
			"chats": "https://api.hotbot.ai/sso?path=chats",
			"settings": {
				"shop": "https://api.hotbot.ai/sso?path=settings/shop",
				"channels": "https://api.hotbot.ai/sso?path=settings/channels",
				"builder": "https://api.hotbot.ai/sso?path=settings/builder",
			}
		},
		"token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYm90Yml0X2JhY2tlbmQiXSwidXNlcl9uYW1lIjoiYm90Yml0X3JlZ2lzdHJhdGlvbiIsImF1dGhvcml0aWVzIjpbIk9SREVSU19TVEFUU19fVklFVyIsIlNUT1JFX0FOQUxZVElDU19fVklFVyIsIkhFTFBFUlNfX1VTRSIsIlNFR01FTlRTX19ERUxFVEUiLCJVU0VSU19FVkVOVF9fRVhFQ1VURSIsIkNSTV9fVklFVyIsIlJFUExZX1RFTVBMQVRFU19fVklFVyIsIk9SREVSU19DSEFOTkVMU19fQ09ORklHIiwiQVVUT19SRVBMWV9SVUxFU19fRURJVCIsIlJFUExZX1RFTVBMQVRFU19fRURJVCIsIkNPTVBBTklFU19fVklFVyIsIkFDQ0VTU19QT0lOVFNfX0RFTEVURSIsIlJFVklFV1NfX0VESVQiLCJVU0VSU19fVklFVyIsIkVWRU5UU19fRURJVCIsIlJFU0VSVkFUSU9OU19fVklFVyIsIlNUT1JFU19fRURJVCIsIlVTRVJTX19ERUxFVEUiLCJQUk9NT19DT0RFU19fVklFVyIsIkRBU0hCT0FSRF9fVklFVyIsIk9BVVRIX19WSUVXIiwiT0FVVEhfX0VESVQiLCJERVBSRUNBVEVEIiwiQURNSU5JU1RSQVRPUlNfX0RFTEVURSIsIkFETUlOSVNUUkFUT1JTX19FRElUIiwiQVVUT19SRVBMWV9SVUxFU19fREVMRVRFIiwiREVWSUNFU19fVklFVyIsIkNBTVBBSUdOU19fRURJVCIsIlBST01PX0NPREVTX19FRElUIiwiU1RPUkVTX19WSUVXIiwiU0xfTUFOVUFMX01BVENISU5HU19fVklFVyIsIlVTRVJTX19FRElUIiwiQ09NUEFOWV9BTkFMWVRJQ1NfX1ZJRVciLCJSRVNFUlZBVElPTlNfX0VESVQiLCJBRE1JTl9FVkVOVFNfX1ZJRVciLCJPUkRFUlNfX0NPTkZJRyIsIkJBVENIX19FWEVDVVRFIiwiQURNSU5JU1RSQVRPUlNfX1ZJRVciLCJERVZJQ0VTX19ERUxFVEUiLCJUUkFGRklDX0FOQUxZU0lTX19WSUVXIiwiQUNDRVNTX1BPSU5UU19fRURJVCIsIkRFVklDRVNfX0VESVQiLCJTVE9SRVNfX0VYRUNVVEUiLCJTRUdNRU5UU19fVklFVyIsIlVTRVJfVEFHU19fRURJVCIsIlBST01PX0NPREVTX19ERUxFVEUiLCJBVVRPX1JFUExZX1JVTEVTX19WSUVXIiwiT1JERVJTX19WSUVXIiwiU0VHTUVOVFNfX0VESVQiLCJDUk1fX0VESVQiLCJDT01QQU5JRVNfX0VESVQiLCJBQ0NFU1NfUE9JTlRTX19WSUVXIiwiUkVWSUVXU19fUkVQT1JUIiwiUkVQTFlfVEVNUExBVEVTX19ERUxFVEUiLCJSRUdJU1RFUkVEX1VTRVJfX0RFTEVURSIsIkNBTVBBSUdOU19fVklFVyIsIlJFVklFV1NfX1JFUExZIiwiUkVWSUVXU19fVklFVyJdLCJqdGkiOiIwZGI5ODhiZi1mMjI2LTRkNmMtYmIzZi1hODU4NDdkNTBkYzEiLCJjbGllbnRfaWQiOiJib3RiaXRfcmVnaXN0cmF0aW9uIiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl19.HCavjZeOUsqMRdR_FxcwsddVIIxt4qhpxA4M305HQFU",
		"purchaseLanding": "https://landings.rayapp.io/postfreetrial/",
		"socketUrl": "https://socket3.botbit.io",
		"companiesWithSentiments": companiesWithSentiments
	},
	"prod": {
		"dummyEnabled": false,
		"servicesUrl": "https://api.botbit.io",
		"companyName": "RAY",
		"backofficeUrl": "https://admin.botbit.io",
		"frontendPhpUrl": "https://admin.botbit.io",
		"captivePhpUrl": "https://captive.botbit.io",
		"staticImgUrl": "https://admin-botbit.azureedge.net/static/img/",
		"rayLogoWhiteUrl": "https://admin-botbit.azureedge.net/static/img/RAY-Brand-Black.png",
		"rayLogoUrl": "https://admin-botbit.azureedge.net/static/img/RAY-Brand-Red.png",
		"imgUrl": "https://admin-botbit.azureedge.net/",
		"GA_ID_CAPTIVE": "UA-87359842-1",
		"GA_ID_ADMIN": "UA-88592030-1",
		"webPushOptInEnabled": false,
		"oneSignalAppId": "29ed7dd6-3119-443f-94d5-51e2d8868daa",
		"oneSignalTimeout": 10000,
		"INTERCOM_APP_ID": "z0yssql1",
		"HOTJAR_HJID": "3623858",
		"HOTJAR_HJSV": "6",
		"feedbackDomain": "https://feedback.botbit.io",
		"mixpanelToken": "4f31342991b5746fb103586e382b04ec",
		"ordersUrls": {
			"orders": "https://api.hotbot.ai/sso?path=shop-orders",
			"stats": "https://api.hotbot.ai/sso?path=stats",
			"chats": "https://api.hotbot.ai/sso?path=chats",
			"settings": {
				"shop": "https://api.hotbot.ai/sso?path=settings/shop",
				"channels": "https://api.hotbot.ai/sso?path=settings/channels",
				"builder": "https://api.hotbot.ai/sso?path=settings/builder",
			}
		},
		"token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYm90Yml0X2JhY2tlbmQiXSwidXNlcl9uYW1lIjoiYm90Yml0X3JlZ2lzdHJhdGlvbiIsImF1dGhvcml0aWVzIjpbIk9SREVSU19TVEFUU19fVklFVyIsIlNUT1JFX0FOQUxZVElDU19fVklFVyIsIkhFTFBFUlNfX1VTRSIsIlNFR01FTlRTX19ERUxFVEUiLCJVU0VSU19FVkVOVF9fRVhFQ1VURSIsIkNSTV9fVklFVyIsIlJFUExZX1RFTVBMQVRFU19fVklFVyIsIk9SREVSU19DSEFOTkVMU19fQ09ORklHIiwiQVVUT19SRVBMWV9SVUxFU19fRURJVCIsIlJFUExZX1RFTVBMQVRFU19fRURJVCIsIkNPTVBBTklFU19fVklFVyIsIkFDQ0VTU19QT0lOVFNfX0RFTEVURSIsIlJFVklFV1NfX0VESVQiLCJVU0VSU19fVklFVyIsIkVWRU5UU19fRURJVCIsIlJFU0VSVkFUSU9OU19fVklFVyIsIlNUT1JFU19fRURJVCIsIlVTRVJTX19ERUxFVEUiLCJQUk9NT19DT0RFU19fVklFVyIsIk9BVVRIX19WSUVXIiwiT0FVVEhfX0VESVQiLCJERVBSRUNBVEVEIiwiQURNSU5JU1RSQVRPUlNfX0RFTEVURSIsIkFETUlOSVNUUkFUT1JTX19FRElUIiwiQVVUT19SRVBMWV9SVUxFU19fREVMRVRFIiwiREVWSUNFU19fVklFVyIsIkNBTVBBSUdOU19fRURJVCIsIlBST01PX0NPREVTX19FRElUIiwiU1RPUkVTX19WSUVXIiwiU0xfTUFOVUFMX01BVENISU5HU19fVklFVyIsIlVTRVJTX19FRElUIiwiQ09NUEFOWV9BTkFMWVRJQ1NfX1ZJRVciLCJSRVNFUlZBVElPTlNfX0VESVQiLCJBRE1JTl9FVkVOVFNfX1ZJRVciLCJPUkRFUlNfX0NPTkZJRyIsIkJBVENIX19FWEVDVVRFIiwiQURNSU5JU1RSQVRPUlNfX1ZJRVciLCJERVZJQ0VTX19ERUxFVEUiLCJUUkFGRklDX0FOQUxZU0lTX19WSUVXIiwiQUNDRVNTX1BPSU5UU19fRURJVCIsIkRFVklDRVNfX0VESVQiLCJTVE9SRVNfX0VYRUNVVEUiLCJTRUdNRU5UU19fVklFVyIsIlVTRVJfVEFHU19fRURJVCIsIlBST01PX0NPREVTX19ERUxFVEUiLCJBVVRPX1JFUExZX1JVTEVTX19WSUVXIiwiT1JERVJTX19WSUVXIiwiU0VHTUVOVFNfX0VESVQiLCJDUk1fX0VESVQiLCJDT01QQU5JRVNfX0VESVQiLCJBQ0NFU1NfUE9JTlRTX19WSUVXIiwiUkVWSUVXU19fUkVQT1JUIiwiUkVHSVNURVJFRF9VU0VSX19ERUxFVEUiLCJSRVBMWV9URU1QTEFURVNfX0RFTEVURSIsIkNBTVBBSUdOU19fVklFVyIsIlJFVklFV1NfX1JFUExZIiwiUkVWSUVXU19fVklFVyJdLCJqdGkiOiIxZTM4MWVmOC1kYmVlLTRlOGMtOGM0MS05OGU4MmY3YzQxMzYiLCJjbGllbnRfaWQiOiJib3RiaXRfcmVnaXN0cmF0aW9uIiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl19.m_7rOzQwNDDckQh2DFyXmczzGFTxlHXQnpohFZ_JBeg",
		"purchaseLanding": "https://landings.rayapp.io/postfreetrial/",
		"socketUrl": "https://socket.botbit.io",
		"companiesWithSentiments": companiesWithSentiments
	},
	"aws-prod": {
		"dummyEnabled": false,
		"servicesUrl": "https://api.rayapp.io",
		"companyName": "RAY",
		"backofficeUrl": "https://admin.rayapp.io",
		"frontendPhpUrl": "https://admin.rayapp.io",
		"captivePhpUrl": "https://captive.botbit.io",
		"staticImgUrl": "https://cdn-admin.rayapp.io/static/img/",
		"rayLogoWhiteUrl": "https://cdn-admin.rayapp.io/static/img/RAY-Brand-Black.png",
		"rayLogoUrl": "https://cdn-admin.rayapp.io/static/img/RAY-Brand-Red.png",
		"imgUrl": "https://cdn-admin.rayapp.io/",
		"GA_ID_CAPTIVE": "UA-87359842-1",
		"GA_ID_ADMIN": "UA-88592030-1",
		"webPushOptInEnabled": true,
		"oneSignalAppId": "d86c4524-6ca6-4c90-91ee-6cbbd9e23c25",
		"oneSignalTimeout": 10000,
		"INTERCOM_APP_ID": "z0yssql1",
		"HOTJAR_HJID": "3623858",
		"HOTJAR_HJSV": "6",
		"feedbackDomain": "https://feedback.botbit.io",
		"mixpanelToken": "4f31342991b5746fb103586e382b04ec",
		"ordersUrls": {
			"orders": "https://api.hotbot.ai/sso?path=shop-orders",
			"stats": "https://api.hotbot.ai/sso?path=stats",
			"chats": "https://api.hotbot.ai/sso?path=chats",
			"settings": {
				"shop": "https://api.hotbot.ai/sso?path=settings/shop",
				"channels": "https://api.hotbot.ai/sso?path=settings/channels",
				"builder": "https://api.hotbot.ai/sso?path=settings/builder",
			}
		},
		"token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYm90Yml0X2JhY2tlbmQiXSwidXNlcl9uYW1lIjoiYm90Yml0X3JlZ2lzdHJhdGlvbiIsImF1dGhvcml0aWVzIjpbIk9SREVSU19TVEFUU19fVklFVyIsIlNUT1JFX0FOQUxZVElDU19fVklFVyIsIkhFTFBFUlNfX1VTRSIsIlNFR01FTlRTX19ERUxFVEUiLCJVU0VSU19FVkVOVF9fRVhFQ1VURSIsIkNSTV9fVklFVyIsIlJFUExZX1RFTVBMQVRFU19fVklFVyIsIk9SREVSU19DSEFOTkVMU19fQ09ORklHIiwiQVVUT19SRVBMWV9SVUxFU19fRURJVCIsIlJFUExZX1RFTVBMQVRFU19fRURJVCIsIkNPTVBBTklFU19fVklFVyIsIkFDQ0VTU19QT0lOVFNfX0RFTEVURSIsIlJFVklFV1NfX0VESVQiLCJVU0VSU19fVklFVyIsIkVWRU5UU19fRURJVCIsIlJFU0VSVkFUSU9OU19fVklFVyIsIlNUT1JFU19fRURJVCIsIlVTRVJTX19ERUxFVEUiLCJQUk9NT19DT0RFU19fVklFVyIsIk9BVVRIX19WSUVXIiwiT0FVVEhfX0VESVQiLCJERVBSRUNBVEVEIiwiQURNSU5JU1RSQVRPUlNfX0RFTEVURSIsIkFETUlOSVNUUkFUT1JTX19FRElUIiwiQVVUT19SRVBMWV9SVUxFU19fREVMRVRFIiwiREVWSUNFU19fVklFVyIsIkNBTVBBSUdOU19fRURJVCIsIlBST01PX0NPREVTX19FRElUIiwiU1RPUkVTX19WSUVXIiwiU0xfTUFOVUFMX01BVENISU5HU19fVklFVyIsIlVTRVJTX19FRElUIiwiQ09NUEFOWV9BTkFMWVRJQ1NfX1ZJRVciLCJSRVNFUlZBVElPTlNfX0VESVQiLCJBRE1JTl9FVkVOVFNfX1ZJRVciLCJPUkRFUlNfX0NPTkZJRyIsIkJBVENIX19FWEVDVVRFIiwiQURNSU5JU1RSQVRPUlNfX1ZJRVciLCJERVZJQ0VTX19ERUxFVEUiLCJUUkFGRklDX0FOQUxZU0lTX19WSUVXIiwiQUNDRVNTX1BPSU5UU19fRURJVCIsIkRFVklDRVNfX0VESVQiLCJTVE9SRVNfX0VYRUNVVEUiLCJTRUdNRU5UU19fVklFVyIsIlVTRVJfVEFHU19fRURJVCIsIlBST01PX0NPREVTX19ERUxFVEUiLCJBVVRPX1JFUExZX1JVTEVTX19WSUVXIiwiT1JERVJTX19WSUVXIiwiU0VHTUVOVFNfX0VESVQiLCJDUk1fX0VESVQiLCJDT01QQU5JRVNfX0VESVQiLCJBQ0NFU1NfUE9JTlRTX19WSUVXIiwiUkVWSUVXU19fUkVQT1JUIiwiUkVHSVNURVJFRF9VU0VSX19ERUxFVEUiLCJSRVBMWV9URU1QTEFURVNfX0RFTEVURSIsIkNBTVBBSUdOU19fVklFVyIsIlJFVklFV1NfX1JFUExZIiwiUkVWSUVXU19fVklFVyJdLCJqdGkiOiIxZTM4MWVmOC1kYmVlLTRlOGMtOGM0MS05OGU4MmY3YzQxMzYiLCJjbGllbnRfaWQiOiJib3RiaXRfcmVnaXN0cmF0aW9uIiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl19.m_7rOzQwNDDckQh2DFyXmczzGFTxlHXQnpohFZ_JBeg",
		"purchaseLanding": "https://landings.rayapp.io/postfreetrial/",
		"socketUrl": "https://socket.rayapp.io",
		"companiesWithSentiments": companiesWithSentiments
	},
}

// si no esta definido tomo development
var environmentKey = (process.env.REACT_APP_ENVIRONMENT) ? process.env.REACT_APP_ENVIRONMENT : "development";

// TODO: cuando usemmos las variables default de node ver si esto sirve o queda obsoleto
// var environmentKey = null;
// // estoy en el browser
// if (typeof window !== 'undefined') {
// 	// si tengo el parametro de ambiente lo tomo
// 	if (typeof window.ENV !== 'undefined')
// 		environmentKey = window.ENV;
// 	// sino tomo el default
// 	else
// 		environmentKey = process.env.NODE_ENV;
// }
// else {
// 	// si tengo el parametro de ambiente lo tomo
// 	if (typeof process.env.ENVIRONMENT !== 'undefined')
// 		environmentKey = process.env.ENVIRONMENT;
// 	// sino tomo el default
// 	else
// 		environmentKey = process.env.NODE_ENV;
// }

console.log("Using config for " + environmentKey);

export const envConfig = allConfig[environmentKey];
export const envName = environmentKey;