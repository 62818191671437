import React, { useEffect, useState } from 'react';
import { Table, Filters, Carousel, timePeriodsIds as timePeriods, getLabel, getSuffixLabel, calculateFrom, calculateTo } from "botbit-ui-components"
import { getLeaderboard, getLeaderboardCsv } from '../../api/leaderboardApi';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Feedback/Leaderboard";
import _ from "lodash"
import useWindowDimensions from "../../commons/utils/useWindowDimensions";
import PodiumCard from '../PodiumCard/PodiumCard';
import PodiumEmpty from '../PodiumEmpty/PodiumEmpty';
import { MDBBadge, MDBIcon } from "mdbreact";

import "./Leaderboard.scss"
import AdminInviteModal from '../AdminInvite/AdminInviteModal';
import ClientActions from '../clients/ClientActions';
import withEventTracking, { trackers } from '../../config/withEventTracking';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../commons/components/application/miscActions';
import FunnelDetailModal from '../RRFunnel/FunnelDetailModal';

const Leaderboard = ({ t, trackEvent }) => {
	const availableStores = useSelector(state => state.application.availableStores);
	const availableCompanies = useSelector(state => state.application.availableCompanies)
	const selectedCompany = useSelector(state => state.application.selectedCompany);
	const [filterValues, setFilterValues] = useState(null)
	const [filtersFormatted, setFiltersFormatted] = useState();
	const [podiumDataArray, setPodiumDataArray] = useState(undefined)
	const [hideEmptyState, setHideEmptyState] = useState(false);
	const [hideTable, setHideTable] = useState(false);
	const [isAdminInviteModalOpen, setIsAdminInviteModalOpen] = useState(false);
	const [isFunnelDetailsModalOpen, setIsFunnelDetailsModalOpen] = useState(false);
	const [isNpsOneStepMode, setIsNpsOneStepMode] = useState(true);
	const [adminId, setAdminId] = useState(null)
	const history = useHistory()
	const dispatch = useDispatch();

	const { isMedium } = useWindowDimensions();

	useEffect(() => {
		const params = new URLSearchParams(history.location.search);
		const dateFromParam = params.get("dateFrom")
		const dateToParam = params.get("dateTo");
		const storesParam = params.get("stores")
		const periodParam = params.get("period");
		const companiesParam = params.get("companies");

		let newFilterValues = { timePeriod: [{ value: timePeriods.LAST_30_DAYS }], stores: [], companies: [selectedCompany.id] }

		if (periodParam && periodParam !== timePeriods.CUSTOM) {
			newFilterValues = {
				...newFilterValues,
				timePeriod: [{ value: periodParam }]
			}
		} else if (dateFromParam && dateToParam) {
			newFilterValues = {
				...newFilterValues,
				timePeriod: [{
					value: timePeriods.CUSTOM,
					from: new Date(dateFromParam),
					to: new Date(dateToParam),
				}]
			}
		}

		if (companiesParam) {
			newFilterValues = {
				...newFilterValues,
				companies: [
					...companiesParam.split(",").map(elem => parseInt(elem))
				]
			}
		}

		if (storesParam) {
			newFilterValues = {
				...newFilterValues,
				stores: [
					...storesParam.split(",").map(elem => parseInt(elem))
				]
			}
		}

		if (JSON.stringify(filterValues) !== JSON.stringify(newFilterValues)) {
			setFilterValues({ ...newFilterValues })
		}
	}, [])

	useEffect(() => {
		if (!filterValues) return;
		const formatted = { stores: getStoresFromFilters(), timePeriod: getPeriodFromFilters(), order: getOrderFromFilters() }
		setFiltersFormatted(formatted)
		const completeStores = availableStores.filter((store) => formatted.stores.includes(store.id));
		const companyIds = completeStores.map((store) => store.companyId);
		const npsOneStepMode = availableCompanies.some(company => (companyIds.includes(company.id) && company.npsCampaign.subCategoryId === 16));
		setIsNpsOneStepMode(npsOneStepMode);
	}, [filterValues])


	const orderOptions = [
		{
			value: "TOTAL_REVIEWS_REQUESTED",
			text: t("totalSent")
		},
		{
			value: "TOTAL_REVIEWS_ANSWERED",
			text: t("totalPrivateGenerated")
		},
		{
			value: "TOTAL_REVIEWS_GENERATED",
			text: t("totalPublicGenerated")
		},
		{
			value: "AVERAGE_REVIEWS_ANSWERED",
			text: t("averagePrivateScore")
		},
	]

	const getColumns = () => {
		if (isMedium) return (
			[
				{
					dataField: "adminAvatar",
					title: t(""),
					size: 100,
					// className: "mb-2",
					formatter: (cellContent, row) => {
						let imgsrc = `https://admin.botbit.io/static/img/avatars/${cellContent}`;
						return (
							<div className="mobile-row_container w-100">
								<div className="mobile-row_heading">
									<img src={imgsrc} className="rounded-circle mobile-row_admin-avatar" style={{ width: "45px" }} alt="" />
									<h3 className="mobile-row_admin-name">{row.adminName} {row.adminLastName}</h3>
								</div>
								<div className="mobile-row_metrics">

									<ul className="mobile-row_metrics-list">
										<li className="mobile-row_metrics-list_item">
											<MDBIcon icon='paper-plane' /> {t("totalSent")}: <MDBBadge pill color="default">{row.totalAccepted}</MDBBadge>
										</li>

										{!isNpsOneStepMode && <li className="mobile-row_metrics-list_item">
											<MDBIcon icon='reply' /> {t("totalPrivateGenerated")}: <MDBBadge pill color="default">{row.totalPrivateGenerated}</MDBBadge>
										</li>}

										{!isNpsOneStepMode && <li className="mobile-row_metrics-list_item">
											<MDBIcon icon='comment' /> {t("totalPublicGenerated")}: <MDBBadge pill color="default">{row.totalPublicGenerated}</MDBBadge>
										</li>}

										{!isNpsOneStepMode && <li className="mobile-row_metrics-list_item">
											<MDBIcon icon='star' /> {t("averagePrivateScore")}: <MDBBadge pill color="default">{row.averagePrivateScore || "N/A"}</MDBBadge>
										</li>}
									</ul>

								</div>
							</div>
						);
					},
				},
			]
		)

		let columns =
			[
				{
					dataField: "adminAvatar",
					title: t(""),
					size: isNpsOneStepMode ? 12 : 6,
					// className: "mb-2",
					formatter: (cellContent, row) => {
						let imgsrc = `https://admin.botbit.io/static/img/avatars/${cellContent}`;
						return (
							<div className="w-100">
								<img src={imgsrc} className="rounded-circle" style={{ width: "45px" }} alt="" />
							</div>
						);
					},
				},
				{
					dataField: "adminName",
					title: t("adminName"),
					size: isNpsOneStepMode ? 60 : 34,
					// className: "mb-2",
					formatter: (cellContent, row) => {
						return `${cellContent} ${row.adminLastName}`;
					},
				},
				{
					dataField: "totalAccepted",
					title: t("totalSent"),
					size: isNpsOneStepMode ? 20 : 15,
					// className: "mb-2",
					formatter: (cellContent, row) => {
						return (
							<span>
								<i data-test="fa" className="fa fa-paper-plane"></i> {cellContent}
							</span>
						);
					},
				}
			];

		if (!isNpsOneStepMode) {
			columns.push({
				dataField: "totalPrivateGenerated",
				title: t("totalPrivateGenerated"),
				size: 15,
				// className: "mb-2",
				formatter: (cellContent, row) => {
					return (
						<span>
							<i data-test="fa" className="fa fa-reply"></i> {cellContent}
						</span>
					);
				},
			})
			columns.push({
				dataField: "totalPublicGenerated",
				title: t("totalPublicGenerated"),
				size: 15,
				// className: "mb-2",
				formatter: (cellContent, row) => {
					return (
						<span>
							<i data-test="fa" className="fa fa-comment"></i> {cellContent}
						</span>
					);
				},
			})
			columns.push({
				dataField: "averagePrivateScore",
				title: t("averagePrivateScore"),
				size: 15,
				// className: "mb-2",
				formatter: (cellContent, row) => {
					return (
						<span>
							<i data-test="fa" className="fa fa-star"></i> {cellContent || "N/A"}
						</span>
					);
				},
			})
		}

		return columns;
	}

	const openAdminInviteModal = () => {
		setIsAdminInviteModalOpen(true);
	}
	const closeAdminInviteModal = () => {
		setIsAdminInviteModalOpen(false);
	}

	const getSchemas = () => {
		const orderObj = {
			id: "order",
			label: t("order"),
			type: "select",
			icon: "store-alt",
			placeholder: t("allStores"),
			priority: true,
			visible: true,
			options: orderOptions,
			search: false,
			intercomTarget: "leaderboard_actions_order"
		}
		const schemas = {
			timePeriod: {
				id: 'timePeriod',
				label: t("timePeriod"),
				type: 'timeperiod',
				icon: 'calendar-alt',
				placeholder: t("custom"),
				priority: true,
				visible: true,
				options: [timePeriods.THIS_MONTH, timePeriods.LAST_30_DAYS, timePeriods.LAST_6_MONTHS, timePeriods.LAST_12_MONTHS, timePeriods.THIS_YEAR, timePeriods.CUSTOM],
				defaultValues: [{ value: timePeriods.LAST_30_DAYS }],
			},
			companies: {
				id: "companies",
				label: t("company"),
				type: "multiselect",
				icon: "building",
				placeholder: t("allCompanies"),
				priority: true,
				visible: availableCompanies.length > 1,
				options: availableCompanies.map(s => ({ value: s.id, text: s.name })),
				defaultValues: filterValues.companies,
				search: true
			},
			stores: {
				id: "stores",
				label: t("store"),
				type: "multiselect",
				icon: "store-alt",
				placeholder: t("allStores"),
				priority: true,
				visible: true,
				options: availableStores.map(s => ({ value: s.id, text: s.name, dependencyId: s.companyId })),
				search: true,
				defaultValues: [],
				dependsOn: "companies"
			}
		}
		if (isMedium) {
			return isNpsOneStepMode ? schemas : { order: orderObj, ...schemas }
		} else {
			return isNpsOneStepMode ? schemas : { ...schemas, order: orderObj };
		}
	}

	const getPeriodFromFilters = () => {
		return _.at(filterValues, "timePeriod[0].value")[0]
			? _.at(filterValues, "timePeriod[0].value")[0] !== "CUSTOM"
				? { suffixText: getSuffixLabel(_.at(filterValues, "timePeriod[0].value")[0]), text: "· 📅 " + getLabel(_.at(filterValues, "timePeriod[0].value")[0]), from: calculateFrom(_.at(filterValues, "timePeriod[0].value")[0]), to: calculateTo(_.at(filterValues, "timePeriod[0].value")[0]) }
				: { suffixText: getSuffixLabel(_.at(filterValues, "timePeriod[0].value")[0]), text: "· 📅 " + getLabel(_.at(filterValues, "timePeriod[0].value")[0]), from: _.at(filterValues, "timePeriod[0]")[0].from, to: _.at(filterValues, "timePeriod[0]")[0].to }
			: { text: "" }
	}

	const getStoresFromFilters = () => {
		if (!filterValues.stores || filterValues.stores.length === 0 || filterValues.stores[0] === "allStores") {
			return availableStores.filter(s => filterValues.companies.includes(s.companyId)).map(s => s.id);
		}
		return filterValues.stores
	}

	const getOrderFromFilters = () => {
		if (!filterValues.order || filterValues.order.length === 0) return "TOTAL_REVIEWS_REQUESTED";
		return filterValues.order[0]
	}

	const formatLeaderboard = async (stores, timePeriod, order) => {
		dispatch(setLoading(true));
		let data = await getLeaderboard(stores, timePeriod, order);
		const dataArray = data.data.data.items;
		// Estas variables terminaran conteniendo la data del podio y de la tabla
		let podiumData, tableData;

		// Se manejan manualmente los casos en los cuales del servicio vengan menos de 3 admins
		switch (dataArray.length) {
			case 0:
				setHideEmptyState(false);
				podiumData = undefined;
				tableData = []
				break;
			case 1:
				setHideEmptyState(true);
				podiumData = dataArray;
				tableData = [];
				break;
			case 2:
				setHideEmptyState(false);
				podiumData = [dataArray[0]];
				tableData = [dataArray[1]];
				break;
			case 3:
				setHideEmptyState(true);
				podiumData = dataArray.slice(0, 3);
				tableData = [];
				break;
			default:
				setHideEmptyState(false);
				podiumData = dataArray.slice(0, 3);
				tableData = [...dataArray.slice(3)]
				break;
		}
		data.data.data.items = [...tableData];
		setPodiumDataArray(podiumData);
		dispatch(setLoading(false));
		return data;
	}

	const renderPodium = () => {
		let isScoreNA = false;
		podiumDataArray.forEach(admin => {
			if (admin.averagePrivateScore === null) isScoreNA = true;
		})
		const emptyState = (<PodiumEmpty t={t} />)
		const cardsArray = podiumDataArray.map((admin, index) => {
			return (<div className={`col-lg-3 col-sm-4 col-12 mx-auto ${index === 0 ? "podium-first" : ""}`.trim()}>
				<PodiumCard
					position={index + 1}
					title={`${admin.adminName} ${admin.adminLastName}`}
					responses={admin.totalPrivateGenerated}
					score={admin.averagePrivateScore || "N/A"}
					generatedReviews={admin.totalPublicGenerated}
					requestReview={admin.totalAccepted}
					avatar={admin.adminAvatar}
					selectedParam={filtersFormatted.order}
					onClick={() => showFunnelDetail(admin)}
					showFooter={!isNpsOneStepMode}
				/>
			</div>)
		})
		if (isScoreNA && (filtersFormatted.order === "AVERAGE_REVIEWS_ANSWERED" || filtersFormatted.order[0] === "AVERAGE_REVIEWS_ANSWERED")) {
			if (!hideTable) setHideTable(true);
			return emptyState
		} else {
			if (hideTable) setHideTable(false);
			return cardsArray;
		}
	}

	const renderCarousel = () => {
		const cards = renderPodium();
		const isEmptyState = !Array.isArray(cards);
		if (isEmptyState) {
			return cards
		} else {
			return (<Carousel
				items={cards}
				autoPlay={false}
				fadeOutAnimation={true}
				mouseDragEnabled={true}
				responsive={{
					"991": {
						"items": 3
					}
				}}
				disableAutoPlayOnAction={true}
				buttonsDisabled={true}
				infinite={false}
				autoWidth
				stagePadding={({ paddingRight: 15 })}
			/>)
		}
	}

	const exportCSV = () => {
		// alert("Oh no")
		const { stores, timePeriod, order } = filtersFormatted;
		trackEvent('LEADERBOARD_EXPORT_REQUESTED', { type: 'EXPORT' }, true, [trackers.BACKEND])
		getLeaderboardCsv(stores, timePeriod, order)
			.then(fileBlob => {
				const fileName = `Leaderboard - ${Date.now()}`;
				try {
					var blob = new Blob([fileBlob.data], { type: "text/csv" });
					//downloading the file depends on the browser
					//IE handles it differently than chrome/webkit
					if (window.navigator && window.navigator.msSaveOrOpenBlob) {
						window.navigator.msSaveOrOpenBlob(blob, fileName);
					} else {
						var elem = window.document.createElement('a');
						elem.href = window.URL.createObjectURL(blob);
						elem.download = fileName + ".csv";
						document.body.appendChild(elem);
						elem.click();
						document.body.removeChild(elem);
					}
				} catch (exc) {
					console.log("Save Blob method failed with the following exception.");
					console.log(exc);
				}
			})
	}

	const getActions = () => {
		const actions = [
			{
				key: "leaderboard_action_" + 1,
				icon: "file-export",
				label: t("exportCSV"),
				primary: false,
				onClick: () => exportCSV()
			},
		]
		// Dejo esto comentado por si vuelve a ser necesario pronto
		// if (adminAccess === "ALL" || adminAccess === "COMPANY") {
		if (filterValues.companies.length <= 1) {
			actions.unshift({
				key: "leaderboard_action_" + 2,
				icon: "user-plus",
				label: t("inviteAdmin"),
				intercomTarget: "leaderboard_actions_invite_user",
				primary: false,
				onClick: () => {
					openAdminInviteModal();
				}
			})
		}

		return actions;
	}

	const showFunnelDetail = (admin) => {
		const { adminId, adminName, adminLastName, averagePrivateScore: avgScore } = admin;
		if (isNpsOneStepMode) {
			setAdminId(adminId)
			setIsFunnelDetailsModalOpen(true)
		} else {
			if (filterValues.timePeriod[0].value !== "CUSTOM") {
				history.push(`/detailedReviewRequest?period=${filterValues.timePeriod[0].value}&stores=${filtersFormatted.stores.join(",")}&admin=${adminId}&adminName=${adminName}&adminLastName=${adminLastName}${avgScore ? "&avgScore=" + avgScore : ""}`)
			} else {
				const { from, to } = filterValues.timePeriod[0];
				history.push(`/detailedReviewRequest?period=${filterValues.timePeriod[0].value}&dateFrom=${from.toISOString()}&dateTo=${ceilHours(to).toISOString()}&stores=${filtersFormatted.stores.join(",")}&admin=${adminId}&adminName=${adminName}&adminLastName=${adminLastName}${avgScore ? "&avgScore=" + avgScore : ""}`)
			}

		}
	}

	const ceilHours = date => {
		date.setHours(date.getHours() + 1);
		date.setMinutes(0, 0, 0)

		return date;
	}

	return (
		<div className="leaderboard">
			<ClientActions />
			<AdminInviteModal isOpen={isAdminInviteModalOpen} toggle={closeAdminInviteModal} triggerOpenModal={() => setIsAdminInviteModalOpen(true)} />
			{ adminId && filtersFormatted && filtersFormatted.stores && filtersFormatted.timePeriod && <FunnelDetailModal
				isOpen={isFunnelDetailsModalOpen}
				toggle={() => setIsFunnelDetailsModalOpen(false)}
				stores={filtersFormatted.stores.join(",")}
				period={filtersFormatted.timePeriod}
				mode={"users"}
				adminId={adminId}
			/>}
			{filterValues && <Filters
				title={t("title")}
				schemas={getSchemas()}
				values={filterValues}
				onChange={(newValues) => setFilterValues(newValues)}
				showInfo
				actions={getActions()}
				actionsIntercomTarget="leaderboard_actions"
			/>}

			{podiumDataArray && filtersFormatted && filtersFormatted.order && <div className="row">

				<div className="container">
					<div data-intercom-target="leaderboard_ranking" className="row podiumWrapper">
						{!isMedium
							? renderPodium()
							: renderCarousel()
						}
					</div>
				</div>
				{/* END .container */}
			</div>}
			{/* END .row */}

			{filtersFormatted && filtersFormatted.stores && filtersFormatted.timePeriod &&
				<div style={{
					display: (hideEmptyState || hideTable) ? "none" : "unset"
				}}>
					<Table
						filterValues={filtersFormatted}
						getData={(page, order, filters) => formatLeaderboard(filters.stores, filters.timePeriod, filters.order)}
						pagination={false}
						columns={getColumns()}
						order="TOTAL_REVIEWS_REQUESTED"
						dataPath="data.data.items"
						elementKey="adminName"
						className="classname-prueba"
						emptyStateIntercomTarget="leaderboard_ranking"
						onElementClick={element => showFunnelDetail(element)}
					// export={() => alert("Oh no!")}
					// exportName={`TasksExport-${Date.now()}`}
					// formats={[{ type: 'text/csv', format: 'csv' }]}
					/>
				</div>}
		</div>
	);
}

Leaderboard.translations = { ...translations }

export default withEventTracking(translate(Leaderboard));
