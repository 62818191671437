import { envConfig } from "../../config";

export const sourcesTranslations = {
    sources: {
        status: {
            NOT_CONNECTED: {
                es: "No conectado",
                en: "Not connected"
            },
            NO_LOCATIONS_MATCHED: {
                es: "No se asociaron tiendas",
                en: "No stores were associated"
            },
            CONNECTED: {
                es: "Conectado",
                en: "Connected"
            }
        },
        shopify: {
            name: {
                es: "Shopify",
                en: "Shopify"
            },
            description: {
                es: "Obtén los datos de los clientes que compran a través de Shopify, crea campañas especiales para ellos, envíales una encuesta de satisfacción para que sepas cómo fue su experiencia.",
                en: "Get your customer's data who buy through Shopify, create special campaigns for them, send them a satisfaction survey so you know how was their experience."
            },
            category: {
                es: "eCommerce",
                en: "eCommerce"
            },
            fields: {
                shopName: {
                    es: "Nombre de la tienda en Shopify",
                    en: "Shopify store's name",
                    short: {
                        es: "Nombre de la tienda",
                        en: "Store's name"
                    }
                }
            },
            prefix: {
                es: "https://",
                en: "https://"
            },
            sufix: {
                es: ".myshopify.com",
                en: ".myshopify.com"
            }
        },
        gmail: {
            name: {
                es: "Gmail",
                en: "Gmail"
            },
            description: {
                es: "Recibe los datos de los clientes que te contactan a través de tu casilla de correo. Envíales una encuesta de satisfacción para saber si su consulta fue respondida correctamente y conocer cómo fue su experiencia.",
                en: "Get you customer's data which contact you through your mailbox. Send them a satisfaction survey to find out if their query was answered correctly and find out how their experience was."
            },
            category: {
                es: "Email",
                en: "Email"
            },
            fields: {
                shopName: {
                    es: "Cuenta de Gmail",
                    en: "Gmail account",
                    short: {
                        es: "Cuenta de Gmail",
                        en: "Gmail account"
                    }
                }
            },
            // prefix: {
            //     es: "https://"
            // },
            // sufix: {
            //     es: ".myshopify.com"
            // }
        },
        rdstation: {
            name: {
                es: "RD Station",
                en: "RD Station"
            },
            description: {
                es: `Obtén datos de los clientes que han llegado como leads a RD Station. Una vez que estén en ${envConfig.companyName} podremos enviarles encuesta de satisfacción y campañas de marketing.`,
                en: "Get customer's data that arrived as leads to RD Station. We can send them satisfaction surveys and marketing campaigns."
            },
            category: {
                es: "CRM",
                en: "CRM"
            }
        },
        botmaker: {
            name: {
                es: "Botmaker",
                en: "Botmaker"
            },
            description: {
                es: `Recibe, contesta y adminstra consultas de clientes por whatsapp en el Inbox de Ray`,
                en: "Receive, answer and manage customer whatsapp interactions through Ray's Inbox"
            },
            category: {
                es: "Chat",
                en: "Chat"
            }
        },
        facebook_messenger_instagram: {
            name: {
                es: "Facebook Messenger & Instagram",
                en: "Facebook Messenger & Instagram"
            },
            description: {
                es: `Recibe, contesta y adminstra consultas de clientes por facebook messenger e instagram en el Inbox de Ray`,
                en: "Receive, answer and manage customer facebook messenger and instagram interactions through Ray's Inbox"
            },
            category: {
                es: "Chat",
                en: "Chat"
            }
        },
        tienda_nube: {
            name: {
                es: "Tienda Nube",
                en: "TIenda Nube"
            },
            description: {
                es: `Envía encuestas de satisfacción automáticas y campañas de marketing a los clientes que compraron por Tienda Nube.`,
                en: "Send automatic satisfaction surveys and marketing campaigns to customers who bought through Tienda Nube."
            },
            category: {
                es: "eCommerce",
                en: "eCommerce"
            }
        }
    }
}