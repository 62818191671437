import React from "react";
import { faCalendarAlt as pickerIcon } from '@fortawesome/free-solid-svg-icons'
import { FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { translate } from 'react-i18next';

import DateRangePicker from "react-bootstrap-daterangepicker";
import './dateRangePicker.css';

//var locale = "en";
var moment = require('moment');
if (typeof window !== 'undefined') {
	//locale = require('browser-locale')()
	require('bootstrap-daterangepicker/daterangepicker.css');
}

class StyledDateRangePicker extends React.Component {

	constructor(props) {
		super(props);
		this.handleEvent = this.handleEvent.bind(this);
		//console.log("DATERANGE.constructor")
        /*if (props.initDate)
            this.state = props.initDate;
        else
		    this.state = { dateFrom: moment().subtract(30, 'day').toDate(), dateTo: new Date() };*/
	}

	handleEvent(event, picker) {
		//console.log("DATERANGE.handleevent");
		// console.log(picker);
		var dateSelection = { dateFrom: picker.startDate.toDate(), dateTo: picker.endDate.toDate() };
		//this.setState(dateSelection);
		this.props.onSelect(dateSelection);
	}


	render() {
		const { t } = this.props;

		//console.log("DATERANGE.render ---------------------------------------")
		//console.log("DATERANGE.render.props", this.props.initDate)
		//console.log("DATERANGE.render.state", this.state)

		var ranges = {};
		ranges[t("components.dateRangePicker.ranges.today")] = [moment(), moment()];
		if (!this.props.singleDatePicker) {
			ranges[t("components.dateRangePicker.ranges.last7days")] = [moment().subtract(6, 'days'), moment()];
			ranges[t("components.dateRangePicker.ranges.last30days")] = [moment().subtract(29, 'days'), moment()];
			ranges[t("components.dateRangePicker.ranges.lastMonth")] = [moment().startOf('month').subtract(1, 'month'), moment().startOf('month').subtract(1, 'day')];
			ranges[t("components.dateRangePicker.ranges.thisMonth")] = [moment().startOf('month'), moment().endOf('month')];
		} 

		const locale = {
			applyLabel: t("components.dateRangePicker.applyLabel"),
			cancelLabel: t("components.dateRangePicker.cancelLabel"),
			fromLabel: t("components.dateRangePicker.fromLabel"),
			toLabel: t("components.dateRangePicker.toLabel"),
			customRangeLabel: t("components.dateRangePicker.customLabel"),
			daysOfWeek: [
				t("common.days.abbreviated.sun"),
				t("common.days.abbreviated.mon"), 
				t("common.days.abbreviated.tue"), 
				t("common.days.abbreviated.wed"), 
				t("common.days.abbreviated.thu"), 
				t("common.days.abbreviated.fri"), 
				t("common.days.abbreviated.sat")], 
			monthNames: [
				t("common.months.complete.jan"), 
				t("common.months.complete.feb"), 
				t("common.months.complete.mar"), 
				t("common.months.complete.apr"), 
				t("common.months.complete.may"), 
				t("common.months.complete.jun"), 
				t("common.months.complete.jul"), 
				t("common.months.complete.aug"), 
				t("common.months.complete.sep"), 
				t("common.months.complete.oct"), 
				t("common.months.complete.nov"), 
				t("common.months.complete.dec")],
			firstDay: 1,
			format: 'DD/MM/YYYY'
		}
		// para que se puedan pisar properties, consolido todo en options y 
		// sobreescribo con ...this.props las que hayan pasado por fuera
		var options = {
			ranges,
			locale,
			appyClass: 'btn-primary',
			cancelClass: 'btn-default',
			buttonClasses: ['btn', 'btn-sm'],
			showDropdowns: true,
			showWeekNumbers: true,
			timePicker: false,
			timePickerIncrement: 1,
			timePicker12Hour: false,
			opens: 'right',
			drops: 'down',
			showCustomRangeLabel: true,
			minDate: '01/01/2012',
			singleDatePicker: false,
			...this.props,
		}

		return (
			<FormGroup>
				<DateRangePicker
					onApply={this.handleEvent}
					ranges={options.ranges}
					startDate={this.props.initDate.startDate}
					endDate={this.props.initDate.endDate}
					applyClass={options.applyClass}
					cancelClass={options.cancelClass}
					buttonClasses={options.buttonClasses}
					showDropdowns={options.showDropdowns}
					showWeekNumbers={options.showWeekNumbers}
					timePicker={options.timePicker}
					timePickerIncrement={options.timePickerIncrement}
					timePicker12Hour={options.timePicker12Hour}
					opens={options.opens}
					drops={options.drops}
					showCustomRangeLabel={options.showCustomRangeLabel}
					minDate={options.minDate}
					locale={options.locale}
					singleDatePicker={options.singleDatePicker}
				>
					<div id="reportrange" className="form-control">
						<FontAwesomeIcon icon={pickerIcon} />
						&nbsp;&nbsp;
						<span>
						{ this.props.initDate.dateFrom.toLocaleDateString()}
						{ !options.singleDatePicker && (" - " + this.props.initDate.dateTo.toLocaleDateString()) }
						</span>
						&nbsp;
						<b className="caret"></b>
					</div>
				</DateRangePicker>
			</FormGroup>
		)
	}
}

StyledDateRangePicker.defaultProps = {
	initDate: { dateFrom: moment().subtract(30, 'day').toDate(), dateTo: new Date() }
}

export const NoConnectedDateRangePicker = (StyledDateRangePicker);

export default translate('common')(StyledDateRangePicker);
