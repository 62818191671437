import React from "react";

export const updateIcon = <svg
  width="32"
  height="29"
  viewBox="0 0 32 29"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M26 3.5C26 2.70435 26.3161 1.94129 26.8787 1.37868C27.4413 0.816071 28.2044 0.5 29 0.5C29.7956 0.5 30.5587 0.816071 31.1213 1.37868C31.6839 1.94129 32 2.70435 32 3.5V25.5C32 26.2957 31.6839 27.0587 31.1213 27.6213C30.5587 28.1839 29.7956 28.5 29 28.5C28.2044 28.5 27.4413 28.1839 26.8787 27.6213C26.3161 27.0587 26 26.2957 26 25.5V25.072C21.676 22.59 17.02 21.386 12.176 20.906L12.986 26.33C13.0404 26.619 13.0304 26.9164 12.9567 27.201C12.8831 27.4857 12.7476 27.7506 12.5598 27.9769C12.3721 28.2032 12.1368 28.3854 11.8706 28.5103C11.6045 28.6353 11.314 28.7001 11.02 28.7H9.924C9.53524 28.7 9.15492 28.5866 8.82956 28.3739C8.50419 28.1611 8.24789 27.8581 8.092 27.502L4.392 20.522C4.25734 20.5198 4.12267 20.5178 3.988 20.516C2.92873 20.5055 1.91624 20.0781 1.16981 19.3264C0.423381 18.5748 0.00310369 17.5593 0 16.5L0 12.5C0.00356957 11.4406 0.42314 10.425 1.16833 9.67192C1.91351 8.91888 2.92468 8.48868 3.984 8.474C5.64005 8.45154 7.29561 8.40154 8.95 8.324C15.036 8.016 21.246 6.626 26 3.926V3.5ZM28 3.5V25.5C28 25.7652 28.1054 26.0196 28.2929 26.2071C28.4804 26.3946 28.7348 26.5 29 26.5C29.2652 26.5 29.5196 26.3946 29.7071 26.2071C29.8946 26.0196 30 25.7652 30 25.5V3.5C30 3.23478 29.8946 2.98043 29.7071 2.79289C29.5196 2.60536 29.2652 2.5 29 2.5C28.7348 2.5 28.4804 2.60536 28.2929 2.79289C28.1054 2.98043 28 3.23478 28 3.5ZM26 6.2C21.312 8.61 15.582 9.884 10 10.266V18.732C10.36 18.752 10.718 18.776 11.074 18.804C16.21 19.182 21.26 20.292 26 22.79V6.2ZM8 18.63V10.37C6.6724 10.4186 5.34433 10.4533 4.016 10.474C3.48154 10.4803 2.9709 10.6961 2.59389 11.0749C2.21688 11.4538 2.00363 11.9655 2 12.5V16.5C2 17.6 2.896 18.504 4.012 18.518C5.34186 18.5334 6.67137 18.5707 8 18.63ZM6.686 20.58L9.904 26.654L9.924 26.702H11.02L11.016 26.674L10.13 20.742C8.98274 20.6728 7.83465 20.6181 6.686 20.578V20.58Z"
    fill="#1A1A1A" />
</svg>;

export const offerIcon = <svg
  width="32"
  height="33"
  viewBox="0 0 32 33"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M21.7071 14.7929C22.0976 15.1834 22.0976 15.8166 21.7071 16.2071L15.7071 22.2071C15.5196 22.3946 15.2652 22.5 15 22.5C14.7348 22.5 14.4804 22.3946 14.2929 22.2071L11.2929 19.2071C10.9024 18.8166 10.9024 18.1834 11.2929 17.7929C11.6834 17.4024 12.3166 17.4024 12.7071 17.7929L15 20.0858L20.2929 14.7929C20.6834 14.4024 21.3166 14.4024 21.7071 14.7929Z"
    fill="#1A1A1A" />
  <path
    d="M7 0.5C7.55228 0.5 8 0.947715 8 1.5V2.5H24V1.5C24 0.947715 24.4477 0.5 25 0.5C25.5523 0.5 26 0.947715 26 1.5V2.5H28C30.2091 2.5 32 4.29086 32 6.5V28.5C32 30.7091 30.2091 32.5 28 32.5H4C1.79086 32.5 0 30.7091 0 28.5V6.5C0 4.29086 1.79086 2.5 4 2.5H6V1.5C6 0.947715 6.44772 0.5 7 0.5ZM2 8.5V28.5C2 29.6046 2.89543 30.5 4 30.5H28C29.1046 30.5 30 29.6046 30 28.5V8.5H2Z"
    fill="#1A1A1A" />
</svg>;


export const eventIcon = (<svg
  width="32"
  height="33"
  viewBox="0 0 32 33"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M22 30.5C24.1217 30.5 26.1566 29.6571 27.6569 28.1569C29.1571 26.6566 30 24.6217 30 22.5C30 20.3783 29.1571 18.3434 27.6569 16.8431C26.1566 15.3429 24.1217 14.5 22 14.5C19.8783 14.5 17.8434 15.3429 16.3431 16.8431C14.8429 18.3434 14 20.3783 14 22.5C14 24.6217 14.8429 26.6566 16.3431 28.1569C17.8434 29.6571 19.8783 30.5 22 30.5ZM32 22.5C32 25.1522 30.9464 27.6957 29.0711 29.5711C27.1957 31.4464 24.6522 32.5 22 32.5C19.3478 32.5 16.8043 31.4464 14.9289 29.5711C13.0536 27.6957 12 25.1522 12 22.5C12 19.8478 13.0536 17.3043 14.9289 15.4289C16.8043 13.5536 19.3478 12.5 22 12.5C24.6522 12.5 27.1957 13.5536 29.0711 15.4289C30.9464 17.3043 32 19.8478 32 22.5Z"
    fill="#1A1A1A" />
  <path
    d="M18.876 24.388C18.97 25.58 19.912 26.508 21.602 26.62V27.5H22.352V26.614C24.102 26.492 25.124 25.556 25.124 24.2C25.124 22.964 24.344 22.328 22.944 22L22.352 21.86V19.46C23.104 19.546 23.58 19.956 23.694 20.524H25.01C24.916 19.374 23.93 18.476 22.352 18.378V17.5H21.602V18.4C20.108 18.546 19.092 19.444 19.092 20.716C19.092 21.84 19.848 22.556 21.106 22.848L21.602 22.97V25.514C20.834 25.398 20.324 24.974 20.21 24.388H18.876ZM21.596 21.68C20.858 21.51 20.458 21.16 20.458 20.636C20.458 20.048 20.89 19.608 21.602 19.48V21.68H21.596ZM22.46 23.172C23.358 23.38 23.77 23.716 23.77 24.31C23.77 24.988 23.256 25.452 22.352 25.538V23.148L22.46 23.172Z"
    fill="#1A1A1A" />
  <path
    d="M2 0.5C1.46957 0.5 0.960859 0.710714 0.585786 1.08579C0.210714 1.46086 0 1.96957 0 2.5V18.5C0 19.0304 0.210714 19.5391 0.585786 19.9142C0.960859 20.2893 1.46957 20.5 2 20.5H10.166C10.282 19.812 10.456 19.144 10.682 18.5H6C6 17.4391 5.57857 16.4217 4.82843 15.6716C4.07828 14.9214 3.06087 14.5 2 14.5V6.5C3.06087 6.5 4.07828 6.07857 4.82843 5.32843C5.57857 4.57828 6 3.56087 6 2.5H26C26 3.56087 26.4214 4.57828 27.1716 5.32843C27.9217 6.07857 28.9391 6.5 30 6.5V13.556C30.76 14.236 31.434 15.012 32 15.864V2.5C32 1.96957 31.7893 1.46086 31.4142 1.08579C31.0391 0.710714 30.5304 0.5 30 0.5H2Z"
    fill="#1A1A1A" />
  <path
    d="M19.996 10.666L20 10.5C19.9996 9.81118 19.8213 9.13414 19.4824 8.53446C19.1435 7.93477 18.6555 7.43275 18.0657 7.07704C17.4758 6.72132 16.8041 6.52395 16.1156 6.50405C15.427 6.48415 14.745 6.64239 14.1356 6.96344C13.5262 7.2845 13.01 7.75749 12.6371 8.3366C12.2641 8.91571 12.047 9.58132 12.0068 10.269C11.9666 10.9566 12.1047 11.643 12.4076 12.2616C12.7106 12.8802 13.1681 13.4101 13.736 13.8C15.4635 12.1545 17.6435 11.0631 19.996 10.666Z"
    fill="#1A1A1A" />
</svg>)

