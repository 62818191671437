import React from 'react'
import { connect } from 'react-redux'
import { setLoading, setError } from '../../commons/components/application/miscActions'
import IBox from '../../commons/components/inspinia/IBox'
import { createOauthClientDetails, getOauthClientDetails, resetOauthClientDetails } from '../../api/securityApi'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Row, Col} from "react-bootstrap";
import ApiConfigTitle from "./ApiConfigTitle";
import ApiConfigClientDetails from "./ApiConfigClientDetail";
import './apiConfig.css';

const MySwal = withReactContent(Swal);

const mapStateToProps = (state) => {
    return {
        companyId: state.application.selectedCompany.id
    }
};
class APIConfigContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // loading: true
        };
        this.handleClick = this.handleClick.bind(this)
        this.fetchOauthClientDetails = this.fetchOauthClientDetails.bind(this)
    }

    componentDidMount() {
        this.fetchOauthClientDetails()
    }

    fetchOauthClientDetails() {
        this.props.setLoading(true);
        getOauthClientDetails(this.props.companyId).then((response) => {
            this.setState(
                {
                    oauthClientDetails: response.data.data
                });
            this.props.setLoading(false)
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.fetchOauthClientDetails()
        }
    }

    handleClick = (email, reset, clientId) => {
        this.props.setLoading(true)
        let promise = (reset === true ? resetOauthClientDetails(email, clientId) : createOauthClientDetails(email, this.props.companyId))
        promise.then((response) => {
            const oauthClientDetails = [...this.state.oauthClientDetails]
            if (reset === true)
                oauthClientDetails.shift()
            oauthClientDetails.push(response.data.data)
            this.setState({
                oauthClientDetails: oauthClientDetails
            });
            this.props.setLoading(false)
            // Todo cambiar esto x una notificación
            MySwal.fire({
                type: 'success',
                title: 'Credenciales generadas',
                html: `Chequeá tu correo (<b>${email}</b>) para empezar a utilizar la API`
            })
        })
    };

    render = () => {
        return (
            <div className="wrapper wrapper-content">
              <Row>
                <Col lg={12}>
                  <IBox showIcons={false} title="Credenciales del API">
                    <ApiConfigTitle/>
                    {this.state.oauthClientDetails != null?
                        <ApiConfigClientDetails onButtonClick={(email, reset, clientId)=>this.handleClick(email, reset, clientId)}
                                                oauthClientDetails={this.state.oauthClientDetails} />:
                      ''}
                  </IBox>
                </Col>
              </Row>
            </div>
        )
    }
}

export default connect(mapStateToProps, { setLoading, setError })(APIConfigContainer)