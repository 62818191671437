export const translations = {
    title: {
        es: "Suma gente a tu equipo",
        en: "Add people to your team"
    },
    email: {
        es: "Emails",
        en: "Emails"
    },
    name: {
        es: "Nombre",
        en: "Name"
    },
    role: {
        es: "Rol",
        en: "Role"
    },
    username: {
        es: "Nombre de usuario",
        en: "Username"
    },
    submit: {
        es: "Enviar invitación",
        en: "Send Invite"
    },
    lastName: {
        es: "Apellido",
        en: "Last Name"
    },
    swalTitle: {
        es: "¡Exito!",
        en: "Success!"
    },
    swalText: {
        es: "Tus invitaciones serán enviadas. ¡Suma más gente a tu equipo!",
        en: "Your invitations will be sent. Add more people to your team now!"
    },
    fieldIsRequired: {
        es: "Este campo es requerido",
        en: "This field is required"
    },
    employer: {
        es: "Managers",
        en: "Employer"
    },
    staff: {
        es: "Empleados",
        en: "Staff"
    },
    generalHelperMsg: {
        es: "Invita a tus colaboradores a que formen parte de tu equipo, ingresando sólo sus mails les llegará la invitación para sumarse",
        en: "Invite your collaborators to be part of your team, by entering only their emails they will receive the invitation to join"
    },
    emailHelperMsg: {
        es: "Ingresa los emails separados por comas",
        en: "Enter emails separated by commas"
    },
    roleHelperMsg: {
        es: "Aprende sobre los diferentes roles haciendo",
        en: "Learn about the different roles by"
    },
    clickHere: {
        es: "click aquí",
        en: "click here"
    },
    invalidEmail: {
        es: "{{value}} no es un email válido",
        en: "{{value}} is not an valid email"
    },
    inviteUsers: {
        es: "Invitar usuarios",
        en: "Invite users"
    },
    conversationRoleNone: {
        es: "Sin acceso a conversaciones",
        en: "No access to conversations"
    },
    conversationRoleReplier: {
        es: "Puede contestar conversaciones asignadas",
        en: "Can reply assigned conversations"
    },
    conversationRoleAsigner: {
        es: "Puede contestar y asignar conversaciones",
        en: "Can reply and assign conversations"
    },
    conversationRoleAutoasigner:{
        es: "Puede contestar conversaciones asignadas y sin asignación",
        en: "Can reply assigned and unassigned conversations"
    },
    conversationRoleHelperMsg: {
        es: "Selecciona que permisos sobre las conversaciones tenga el nuevo administrador",
        en: "Select permissions over conversations for new administrator"
    }
}