import React, { Component } from "react";
import { connect } from 'react-redux'
import { setFeedbackFilters, setFeedbackSavedFilters, setFeedbackPreviousFilters } from './FeedbackFiltersActions';
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import Score from "./Score";
import ValueComparator from "./ValueComparator"
import DashboardSummary from "./DashboardSummary";
import { exportInsighs, getAvailableSites, getInsights, getInsightsEvolution } from '../../api/feedbackApi';
import { getTimePeriods } from "botbit-ui-components";
import {
  createDefaultValues, getSavedFilters, applySelectedFiltersOverFilters,
  removeInvisibleFilters, getSelectedTimePeriodLabel,
  getSelectedTimePeriodPrevious,
  showFilters,
  getReviewFiltersToRedirect
} from "./FiltersHelper";
import './feedbackCenter.css';
import { Views } from "./Views";
import deepCopy from "../../commons/utils/deepCopy";
import CollectionVisualizerContainerWithCompanies from "../collectionVisualizer/CollectionVisualizerContainerWithCompanies";
import { firePageView } from "../../commons/components/utils/trackers";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { translations } from "../../config/translations/Feedback/FeedbackDashboard";
import translate from "../../config/translations/translate";
import withEventTracking, { trackers } from '../../config/withEventTracking';

const mapStateToProps = (state) => {
  return {
    availableStores: state.application.availableStores,
    availableCompanies: state.application.availableCompanies,
    selectedCompany: state.application.selectedCompany,
    filters: state.feedback.filters,
    savedFilters: state.feedback.savedFilters,
    admin: state.login.admin
  }
};

const provinces = new Set();
const localities = new Set();

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardSummary: [],
      dashboardSummaryLoading: false,
      showFilterModal: false,
      timePeriods: getTimePeriods(),
      availableSites: [],
      order: this.getInitialOrder()
    }

    this.props.availableStores.forEach(store => {
      if (store.province.trim().length > 0)
        provinces.add(store.province);
      if (store.locality.trim().length > 0)
        localities.add(store.locality);
    });
  }

  componentDidMount = async () => {
    const availableSitesResponse = await getAvailableSites()
    const availableSites = availableSitesResponse.data.data
    this.setState({
      availableSites: availableSites
    }, () => this.initFilters())
    firePageView()
  }

  getInitialOrder = () => {
    const params = new URLSearchParams(this.props.location.search);
    const sortParam = params.get('sort')
    if (sortParam) {
      const sortParamSplitted = sortParam.split(',')
      return {
        name: sortParamSplitted[0],
        direction: sortParamSplitted[1]
      }
    }
    return undefined
  }

  // initRequestParameters = (filters) => {
  //   const params = new URLSearchParams(this.props.location.search)
  //   setTimePeriodFromRequestParam(filters, params)
  //   setBotbitGeneratedFromRequestParam(filters, params)
  // }

  getInsightsEvolution = (selectedFilters) => {
    removeInvisibleFilters(selectedFilters, this.props.filters);
    this.setState({ dashboardSummaryLoading: true });

    var copiedFilters = deepCopy(selectedFilters);
		// si habilito esto no se ajusta la fecha from al primer dia del mes
    //copiedFilters.resetFromTime=[false];

    getInsightsEvolution(copiedFilters).then((response) => {
      if (response.data.data && response.data.data.length > 0) {
        this.setState({ dashboardSummary: response.data.data });
      }
    }).finally(() => {
      this.setState({ dashboardSummaryLoading: false })
    });
  }

  onFiltersApply = (selectedFilters, filters) => {
    this.props.setFeedbackFilters(filters);
    this.getInsightsEvolution(selectedFilters);
  }

  initFilters = () => {
    if (this.props.filters) {
      this.getInitialFilters();
      showFilters(this.props.filters, 'scores', 'botbitGenerated')
    } else {
      this.setDefaultFiltersValues();
    }
  }

  getInitialFilters = () => {
    const filtersDefaultValues = createDefaultValues(
      provinces, localities,
      this.props.availableCompanies, this.props.selectedCompany.id,
      this.props.availableStores, this.state.timePeriods,
      this.state.availableSites, Views.DASHBOARD_VIEW
    );
    this.setState({ defaultFilters: deepCopy(filtersDefaultValues) });
    return filtersDefaultValues;
  }

  setDefaultFiltersValues = () => {
    const filtersDefaultValues = this.getInitialFilters();
    this.props.setFeedbackFilters(filtersDefaultValues);
    // this.initRequestParameters(filtersDefaultValues);
    const savedFilters = getSavedFilters();
    this.props.setFeedbackSavedFilters(savedFilters);
  }

  redirectToReviews = (periodLabel, params) => {
    this.props.setFeedbackPreviousFilters(deepCopy(this.props.filters), Views.DASHBOARD_VIEW, this.state.order)

    const reviewFilters = getReviewFiltersToRedirect(this.props.filters, periodLabel, params)
    this.props.setFeedbackFilters(reviewFilters)

    this.props.history.push(`${Views.REVIEWS_VIEW}?fromSummary=true`)
  }

  onSavedFiltersChange = (selectedFilters) => {
    const filtersApplied = applySelectedFiltersOverFilters(selectedFilters, this.props.filters);
    this.props.setFeedbackFilters(filtersApplied);
  }

  getData = (filters, order) => {
    var copiedFilters = deepCopy(filters);
		copiedFilters.resetFromTime=[false];
    this.setState({
      order: order
    })
    return getInsights(copiedFilters, order)
  }

  formatPercentage = (value) => value + '%'

  emitMetricsExportEvent = () => {
    this.props.trackEvent('FEEDBACK_METRICS_EXPORT_REQUESTED', {type:'EXPORT'}, true, [trackers.BACKEND])
  }

  exportPDF = () => {
    this.setState({ dashboardSummaryLoading: true })
    this.emitMetricsExportEvent();
    const doc = new jsPDF()
    const docName = (this.props.selectedCompany ? this.props.selectedCompany.name : '') + (this.props.filters ? ' - ' + this.props.filters.find(filter => filter.id === "timePeriod").value.text : '')
    doc.text(docName, 1, 6)
    html2canvas(document.getElementById("metrics-container"), {
      scale: 1,
      windowWidth: 1366,
      imageTimeout: 1
    }).then((canva => {
      doc.html(canva, {
        callback: (doc) => {
          doc.save(docName + ".pdf");
          this.setState({ dashboardSummaryLoading: false });
        },
        html2canvas: {
          scale: 0.19,
          windowWidth: 1366,
          imageTimeout: 0
        },
        y: 12
      })
    }))
  }


  exportInsights = (_order, filters) => {
    this.setState({ dashboardSummaryLoading: true });
    this.emitMetricsExportEvent();
    var copiedFilters = deepCopy(filters);
		copiedFilters.resetFromTime=[false];
    return new Promise((resolve) => {
      exportInsighs(copiedFilters)
        .then(res => {
          resolve(res.data)
        })
        .finally(() => this.setState({ dashboardSummaryLoading: false }))
    });
  }


  render() {
    const { t } = this.props;
    const columns = [
      // for BIG screens: greather than md
      {
        dataField: 'name',
        title: t("store"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        size: 4
      }, {
        dataField: 'score',
        title: t("score"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            formatter={value => <Score value={value} size='s' />}
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation} />
        }
      }, {
        dataField: 'reviews',
        title: t("reviews"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation} />
        }
      }, {
        dataField: 'replies',
        title: t("responses"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation} />
        }
      }, {
        dataField: 'replyRate',
        title: t("responseRate"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation}
            formatter={this.formatPercentage} />
        }
      }, {
        dataField: 'botbitGeneratedReviews',
        title: t("stimulatedByBotbit"),
        sortable: true,
        className: 'align-left text-truncate d-none d-md-block',
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation} />
        }
      },
      // for SMALL screens: lower than md
      {
        dataField: 'name',
        isHeader: true,
        title: t("store"),
        sortable: false,
        className: 'align-left text-truncate d-md-none col-sm-12 row-title',
        size: 12
      }, {
        dataField: 'score',
        title: t("score"),
        sortable: true,
        className: 'align-center text-truncate d-md-none col-5',
        formatter: (cellContent, row) => {
          return <ValueComparator
            formatter={value => <Score value={value} size='s' />}
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation} />
        },
        size: 5
      }, {
        dataField: 'reviews',
        title: t("reviews"),
        sortable: true,
        className: 'align-center text-truncate d-md-none col-4',
        size: 4,
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation} />
        }
      }, {
        dataField: 'replies',
        title: t("responses"),
        sortable: true,
        className: 'align-left text-truncate d-md-none col-3 p-0',
        size: 3,
        formatter: (cellContent, row) => {
          return <ValueComparator
            current={cellContent.current}
            previous={cellContent.previous}
            variation={cellContent.variation} />
        }
      }];

    const headerActions = [
      {
        key: 1,
        icon: 'file-pdf',
        label: t("exportPDF"),
        id: "export-pdf",
        primary: false,
        onClick: () => this.exportPDF(),
      },
    ]

    return (<>
      <MDBContainer className="mt-3 metrics-container" id="metrics-container">
        {this.state.defaultFilters &&
          <CollectionVisualizerContainerWithCompanies
            title={t("metrics")}
            scrollTopButton
            showFiltersInfo
            header={<>
              <MDBRow>
                {this.state.dashboardSummary &&
                  <DashboardSummary
                    data={this.state.dashboardSummary}
                    loading={this.state.dashboardSummaryLoading}
                    onSummaryClick={this.redirectToReviews} />}
              </MDBRow>
              <MDBRow>
                <MDBCol size="12">
                  <p className="align-center">{t("insightsDisclaimer")}</p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <h4 className="ml-2 subtitle">{t("comparison")} {getSelectedTimePeriodLabel(this.props.filters).toLowerCase()} vs {getSelectedTimePeriodPrevious(this.props.filters)}</h4>
              </MDBRow>
            </>}
            //amp autoRefresh={60000}
            defaultFilters={this.state.defaultFilters}
            filters={this.props.filters}
            setFilters={this.props.setFeedbackFilters}
            alwaysFloatingFilter={true}
            columnsCommonClasses="m-auto"
            columns={columns}
            getData={(page, order, filters) => this.getData(filters, order)}
            dataPath="data"
            pagination={false}
            initialOrder={this.state.order}
            onFiltersApply={this.onFiltersApply}
            onRowClickFilterBy="stores"
            onResetFilters={this.setDefaultFiltersValues}
            headerActions={headerActions}
            export={this.exportInsights}
            exportName={(this.props.selectedCompany ? this.props.selectedCompany.name : '') + (this.props.filters ? ' - ' + this.props.filters.find(filter => filter.id === "timePeriod").value.text : '')}
            formats={[{ type: 'text/csv', format: 'csv' }]}
            //amp savedFilters={this.props.savedFilters}
            //amp onSavedFiltersChange={this.onSavedFiltersChange}
            filtersTitle="Filtrar Reseñas"
            companies={this.props.availableCompanies}
            selectedCompany={this.props.selectedCompany}
            stores={this.props.availableStores}
          />}
      </MDBContainer>
    </>)
  }
}

DashboardContainer.translations = {
  ...translations
}

export default withEventTracking(connect(mapStateToProps, { setFeedbackFilters, setFeedbackSavedFilters, setFeedbackPreviousFilters })(translate(DashboardContainer)));
