import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import { useMessengerData } from './useMessengerData';
import Autosuggest from 'react-autosuggest';
import { findStoreUserByPhone } from '../../api/userApi';
import { Field, Form, Formik } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput';
import translate from "../../config/translations/translate";
import {getConversation}  from '../../api/messengerApi';
import './ConversationCreation.scss'

function renderSuggestion(suggestion){
    return (<div className='user-suggestion pl-075 py-1'>
        <span>{suggestion.name} {suggestion.lastName} - </span>
        <span>{suggestion.phone}</span>
    </div>)
}

const ConversationCreationComponent = ({t})=>{
    const {closeChat, storeSocialAccounts, setSelectedChat, selectedChat, selectedStore, addOrUpdateConversation, setIsAppLoading} = useMessengerData();
    if(!selectedChat) return null;

    let _selectedChat = {
        ...selectedChat
    }

    if(!selectedChat.sourceAccountId && storeSocialAccounts.length===1){
        const account = storeSocialAccounts[0];
        _selectedChat = {...selectedChat, sourceAccountId:account.id, channel:account.source.configuration.channels[0]}

    }

    const _onSubmit = (values) =>{
        setIsAppLoading(true);
        getConversation(values).then((res)=>{
            const conversation = res.data.data;
            addOrUpdateConversation(conversation);
            setSelectedChat(conversation);
        }).finally(() => {
            setIsAppLoading(false);
        })
    }

    return (<div className=''>
        {
            selectedChat.user &&
            renderSuggestion(selectedChat.user)
        }
        {
            !selectedChat.user && 
            <ConversationCreationForm
                onCancel={closeChat}
                selectedChat={_selectedChat}
                onSubmit={_onSubmit}
                storeSocialAccounts={storeSocialAccounts}
                selectedStore={selectedStore}
                t={t}
            ></ConversationCreationForm>
        }
    </div>)
}

ConversationCreationComponent.translations={
    name: { es:'Ingresa el nombre', en:'Enter the name'},
    sendTo:{ es: "Enviar a", en:"Send To"},
    nameRequired: { es: 'Debes ingresar el nombre', en:'You must enter the name'},
    phoneError: { es: 'Debes ingresar un teléfono con un formato válido', en:'You must enter a phone with a valid format'},
    userSearchPlaceholder: { es: 'Escribe el nombre o teléfono', en:'Search the name or phone'},
    submit: { es:'Enviar ahora', en:'Send now'},
    title: { es: 'Nuevo mensaje desde: ', en:'New message from: '},
    newContact: { es: 'Nuevo contacto', en:'New contact'},
    selectedPhone: { es: 'Teléfono utilizado', en:'Line used'},
    userRequired: { es: 'Debes seleccionar un usuario, o puedes crear uno nuevo', en: 'You must select a user, or you can create a new one'},
    phoneFormatHint: { es: 'Debes indicar el número con el código del país y el código de área',  en:'You must indicate the number with the country code and the area code'}
}

export const ConversationCreation = translate(ConversationCreationComponent)

const Error = ({ message, submitCount, className }) => {
	if (submitCount === 0) return null;
	return (
		<span className={`text-danger position-relative ${className || ""}`}>{message}</span>
	)
}


export const ConversationCreationForm = ({selectedChat, onSubmit, storeSocialAccounts, onCancel, selectedStore, t})=>{
    const userSchema =  Yup.object().required(t('userRequired')).shape({
        name: Yup.string().required(t('nameRequired')),
        // lastName: Yup.string().required('El apellido es requerido.'),
        phone: Yup.mixed().required(t('phoneError')).test("test-phone", t('phoneError'),
            (value) => {
                if (value === undefined || value === null || value === "") return true;
                if (!value.startsWith('+'))
                    value = '+' + value;
                const phoneNumber = parsePhoneNumberFromString(value);
                return phoneNumber !== undefined && phoneNumber.isValid();
            }),
    })
    const [userSearchValue, setUserSearchValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    useEffect(()=>{
        if(userSearchValue){
            findStoreUserByPhone(selectedChat.storeId, userSearchValue)
            .then((res)=>{
                console.log(res.data.data)
                setSuggestions(res.data.data)
            })
        }
    }, [userSearchValue])


    return (
        <div className='conversation-creation pt-4 pl-3'>
            <button id='cancel-creation' className='btn btn-link'  onClick={()=>onCancel()}>
                <MDBIcon icon='times' style={{color:'#000'}}></MDBIcon>
            </button>
            <div className='conversation-form'>
                <span className='h3 m-0 p-0'>{t('title')+selectedStore.name + " " 
                + (selectedChat.sourceAccountId ? storeSocialAccounts.find((sa)=>sa.id===selectedChat.sourceAccountId).externalId : "")}</span>
                <Formik
                    validationSchema={Yup.object().shape({
                        user: userSchema
                    })}
                    initialValues={selectedChat}
                    onSubmit={values => onSubmit(values)}>
                    {({ errors, values, setFieldValue, submitCount }) => (
                        <Form
                            className='m-0 mx-2 py-5 container'
                        >
                            { 
                                !selectedChat.sourceAccountId &&
                                <Field name="sourceAccountId">
                                    {({ field, form }) => (
                                        <div className='pl-075'>
                                            <select id='source-account' className='form-control' onChange={(e)=>{
                                                    const value = e.target.value;
                                                    if(!value){
                                                        setFieldValue("sourceAccountId",undefined);
                                                        setFieldValue("channel",undefined);    
                                                    } else {
                                                        const account = storeSocialAccounts.find((sa)=>sa.id===value)
                                                        setFieldValue("sourceAccountId",account.id);
                                                        setFieldValue("channel",account.source.configuration.channels[0]);
                                                    }
                                                }}>
                                                {storeSocialAccounts.map((sa)=>{
                                                    return <option key={sa.id} value={sa.id}>{sa.externalId}</option>
                                                })}
                                            </select>
                                        </div>
                                    )}
                                </Field>
                            }
                            {/* {
                                selectedChat.sourceAccountId &&
                                <div id='source-account' className='pl-075'>{t('selectedPhone')} {storeSocialAccounts.find((sa)=>sa.id===selectedChat.sourceAccountId).externalId}</div>
                            } */}
                            <div className='pl-075 h5'>{t('sendTo')}</div>
                            {
                                values.user && values.user.id &&
                                renderSuggestion(values.user)
                            }
                            {
                                (!values.user || values.user.id) && 
                                <Field name="user">
                                {({ field, form }) => (
                                    <div className='row mt-3'>
                                        <Autosuggest
                                            containerProps={{
                                                className:'col-12 col-md-7'
                                            }}
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={()=>{}}
                                            onSuggestionsClearRequested={()=>{}}
                                            getSuggestionValue={(suggestion)=>( `${suggestion}`)}
                                            onSuggestionSelected={(e, {suggestion})=>{
                                                setUserSearchValue('');
                                                setFieldValue("user", suggestion);
                                            }}
                                            shouldRenderSuggestions={()=>true}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={{
                                                id:'user', 
                                                value:userSearchValue,
                                                onChange:(e)=>{setUserSearchValue(e.target.value || '')},
                                                className:'form-control autocomplete-input',
                                                placeholder:t('userSearchPlaceholder'),
                                                style:{
                                                    border: '0px',
                                                    borderBottom: '1px solid #ced4da',
                                                    backgroundColor: 'transparent',
                                                    paddingLeft:0,
                                                    marginLeft:'12px'
                                                }
                                            }}
                                        ></Autosuggest>
                                        <div className='col-12 col-md-5'>
                                            <MDBBtn  
                                                onClick={()=>
                                                    setFieldValue("user", {name:userSearchValue})
                                                }
                                                color='primary'
                                                className='new-contact m-0 p-1 px-4 h-100'
                                            >
                                                <MDBIcon className='mr-2' icon='user-plus'></MDBIcon>{t('newContact')}
                                            </MDBBtn>
                                        </div>
                                        <div className='col-12'>
                                            <Error className='pl-075' submitCount={submitCount} message={errors.user && !values.user ? t('userRequired') : undefined} />
                                        </div>
                                    </div>
                                )}
                                </Field>
                            }
                            {
                                values.user && !values.user.id &&
                                <Container className='pl-075'>
                                    <Row>
                                        <Col lg={12}>

                                            <Field
                                                name="user.name"
                                                render={(props) => {
                                                    return (
                                                        <>
                                                            <MDBInput
                                                                label={t('name')}
                                                                type="text"
                                                                value={props.field.value}
                                                                getValue={(value = "") => { props.form.setFieldValue('user.name', value) }}
                                                            />
                                                            <Error submitCount={props.form.submitCount} message={errors.user ? errors.user.name : undefined} />

                                                        </>
                                                    );
                                                }}
                                            />
                                        </Col>
                                       
                                    </Row>
                                    <Row>
                                    
                                        <Col lg={12}>
                                            <Field
                                                name="user.phone"
                                                type="text"
                                                render={(props) => {
                                                    return (
                                                        <>
                                                            <IntlTelInput
                                                                label={t('phone')}
                                                                defaultValue={props.field.value ? (props.field.value) : ''}
                                                                defaultCountry={(values.country)
                                                                    ? values.country.toLowerCase()
                                                                    : selectedStore
                                                                        ? selectedStore.country.toLowerCase()
                                                                        : ""}
                                                                inputClassName="form-control"
                                                                containerClassName="intl-tel-input md-form"
                                                                onPhoneNumberChange={
                                                                    (_valid, raw, _a, formatted) => {
                                                                        props.form.setFieldValue('user.phone', formatted);
                                                                    }
                                                                }
                                                                onPhoneNumberBlur={(_valid, raw, _c, formatted) => {
                                                                    props.form.setFieldValue("user.phone", formatted);
                                                                    // console.log({ formatted, raw, store: this.props.store, country: values.country })
                                                                }}
                                                                allowDropdown
                                                            />
                                                            <Error className="mt-3" submitCount={props.form.submitCount} message={errors.user ? errors.user.phone : undefined} />
                                                            <span className="d-block">{t('phoneFormatHint')}</span>
                                                        </>
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            <div className='pl-075'>
                                <MDBBtn
                                    color='primary'
                                    type="submit"
                                    className='m-0 mt-5'
                                >
                                    {t('submit')}
                                </MDBBtn>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}