
import React from 'react';
import { Select } from 'botbit-ui-components';
import { ConversationFlowActionType } from './FlowCreator';
import { IssueInput } from '../../Issues/IssuesContainer';
import useCompanyTypes from './useCompanyTypes';
import useCompanyTemplates from './useCompanyTemplates';
import useCompanyAdministratos from './useCompanyAdministrators';
import { MDBBtn } from 'mdbreact';

/**
 * @typedef ConversationFinalAction
 * @property {string} name nombre de la acción
 * @property {('SEND_TEMPLATE'|'CREATE_ISSUE'|'ASSIGN_TO_ADMINISTRATOR'|'EXIT_FLOW')} type tipo de la acción (ConversationFlowActionType)
 * @property {Object} createIssueState para CREATE_ISSUE, el estado del issue a crear
 * @property {Object} createIssueType para CREATE_ISSUE, el tipo del issue a crear
 * @property {Object} transferAdministrator
 * @property {Object} sendTemplate
 */

 /**
 * 
 * @param {Object} props
 * @param {ConversationFinalAction[]} props.actions opciones del subflujo
 * @param {Function} props.setActions función de actualización de acciones
 */
export const ActionsContainer = ({t, actions=[], setActions}) => {
    return <div>
        <ActionsTable
            actions={actions}
            t={t}
            removeAction={index=>{
                setActions(actions.filter((v,i,a)=>i!==index))
            }}
            updateAction={(index,value)=>{
                const newActions = [...actions];
                newActions[index] = value;
                setActions(newActions);
            }}
        ></ActionsTable>
        <MDBBtn color="primary"
            data-testid="ActionContainerButton"
            onClick={() => setActions([...actions, {}])}
            >{t("add")}</MDBBtn>
    </div>
}

export const ActionTypes = ['ASSIGN_TO_ADMINISTRATOR','SEND_TEMPLATE','CREATE_ISSUE','EXIT_FLOW']

export const ActionsTable = ({actions=[], t, removeAction, updateAction }) => {
    
    const companyIssueTypes = useCompanyTypes();
    const companyTemplates = useCompanyTemplates("CHAT");
    const companyAdministrators = useCompanyAdministratos();
    return <div className="container">
        {
            !actions.length &&
            <p>{t("actionsTable.empty")}</p>
        }
        {
            actions.map((action, index, arr) => {
                const excludedOptions = index > 0 ?
                    arr.slice(0,index).map(v=>v.type) :
                    [];
                const actionTypes = ActionTypes.filter(a => !excludedOptions.includes(a));
                return <div key={index}>
                    <ActionTableRow
                        removeAction={()=>removeAction(index)}
                        updateAction={newAction => updateAction(index, newAction)}
                        action={action}
                        t={t}
                        companyIssueTypes={companyIssueTypes}
                        companyTemplates={companyTemplates}
                        administrators={companyAdministrators}
                        actionTypes={actionTypes}
                    ></ActionTableRow>
                </div>
            })
        }
    </div>
}

/**
 * 
 * @param {Object} props
 * @param {Function} props.t
 * @param {Function} props.removeAction callback para eliminar la acción
 * @param {ConversationFinalAction} props.action accion a dibujar  
 * @param {Function} props.updateAction se ejecuta al actualizar una acción
 * @param {Object[]} props.administrators
 * @param {Object[]} props.companyIssueTypes
 * @param {Object[]} props.companyTemplates
 */
export const ActionTableRow = ({action = {}, removeAction, t, updateAction, administrators, companyIssueTypes, companyTemplates, actionTypes = ActionTypes}) =>{
    const actionTypeSelectOptions = actionTypes.map(type=>({
        checked: type === action.type,
        text: t("actionTypeSelect."+type),
        value: type
    }))
    
    return <div className="row" data-testid="ActionTableRow">
        <div className="col-5">
            <Select
                selected={t('actionTypeSelect.placeholder')}
                options={actionTypeSelectOptions}
                data-testid="actionTypeSelect"
                getValue={newType=>{
                    updateAction({
                        type:newType[0]
                    })
                }}
            ></Select>
        </div>
        <div className="col-5">
            <ActionDetailInput 
                action={action}
                updateAction={updateAction}
                administrators={administrators}
                companyIssueTypes={companyIssueTypes}
                companyTemplates={companyTemplates}
            />
        </div>
        <div className="col-2 m-auto">
            <MDBBtn color="secondary" size="sm" data-testid="actionRemoveButton" onClick={removeAction}>⌫</MDBBtn>
        </div>
    
    </div>;
}

/**
 * 
 * @param {Object} props
 * @param {Function} props.t
 * @param {ConversationFinalAction} props.action accion a dibujar
 * @param {Function} props.updateAction función para actualizar la acción
 * @param {Object[]} props.administrators
 * @param {Object[]} props.companyIssueTypes
 * @param {Object[]} props.companyTemplates
 */
export const ActionDetailInput =({action={}, updateAction, administrators, companyIssueTypes, companyTemplates})=>{

    const type = action.type;

    switch (type) {
        case ConversationFlowActionType.CREATE_ISSUE:
            return <IssueInput 
                types={companyIssueTypes}
                currentType={action.createIssueType}
                currentState={action.createIssueState}
                onTypeSelected={typeId => {
                    updateAction({
                        ...action,
                        createIssueType: {
                            id: typeId[0]
                        }
                    })
                }}
                onStateSelected={stateId => {
                    updateAction({
                        ...action,
                        createIssueState: {
                            id: stateId[0]
                        }
                    })
                }}
                ></IssueInput>
            case ConversationFlowActionType.ASSIGN_TO_ADMINISTRATOR:
                return <CompanyAdminSelect
                    administrators={administrators}
                    selected={action.assignToAdministrator}
                    onAdministratorSelected={adminId =>{
                        updateAction({
                            ...action,
                            assignToAdministrator: {
                                id:adminId[0]
                            }
                        })
                    }}
                />
            case ConversationFlowActionType.SEND_TEMPLATE:
                return <TemplateSelect
                    companyTemplates={companyTemplates}
                    selected={action.sendTemplate}
                    onTemplateSelected={templateId =>{
                        updateAction({
                            ...action,
                            sendTemplate: {
                                id:templateId[0]
                            }
                        })
                    }}
                />
        default:
            break;
    }
    return null;
}


export const CompanyAdminSelect = ({placeholder="Seleccione un administrador", administrators, selected, onAdministratorSelected}) => {

    if(!administrators)
        return null;
    
    const selectedValue=selected && selected.id && selected.id.toString();

    const administratorsOptions = administrators.map(
        s => ({
            text: s.fullName, 
            value: s.id.toString(), 
            checked: s.id.toString() === selectedValue
        })
    )


    return <Select
        selected={placeholder}
        options={administratorsOptions}
        getValue={onAdministratorSelected}
        search={true}
        />
}

export const TemplateSelect = ({companyTemplates, selected, onTemplateSelected}) => {
    if(!companyTemplates)
        return null;

    const selectedValue=selected && selected.id && selected.id.toString();

    const selectOptions = companyTemplates.map(
        s => ({
            text: s.name, 
            value: s.id.toString(), 
            checked: s.id.toString() === selectedValue
        })
    )


    return <Select
        selected="Seleccione una plantilla"
        options={selectOptions}
        getValue={onTemplateSelected}
        />
}