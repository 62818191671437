import React, {useState, useEffect} from 'react';
import {MDBContainer} from 'mdbreact';
import {SmartCampaignWizard} from './Steps/SmartCampaignWizard';
import {SelectCampaignType} from './Steps/SelectCampaignType';
import {getStoresActivableSmartCampaign, 
        getCampaignDetail,
        getEmptySmartCampaign} from '../../../api/campaignsApi';
import './new-campaign.css'
import MinimalEditor from '../MinimalEditor/MinimalEditor';
import '../MinimalEditor/MinimalEditor.css';

// const TRANSLATION_PREFIX = 'panels.campaigns.newCampaign.';

const NewCampaign = ({availableStores, companyId, companies, t, onSaveAndClose, onBack, subCategory, history, setLoading}) => {  
  const [campaignTypeWasPredefined, setCampaignTypeWasPredefined] = useState((subCategory === true))
  const [step, setStep] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(companyId);
  const [campaignType, setCampaignType] = useState(null);
  const [stores, setStores] = useState({});
  const [campaign, setCampaign] = useState(null);
  const [campaignIsReady, setCampaignIsReady] = useState(null);
  const [initialSmartCampaignWizardStep, setInitialSmartCampaignWizardStep] = useState(0)
  

  const willSetCampaignType = (type) => {
    setCampaignIsReady(false);
    if(type.categoryName){
      type.subCategoryName = type.categoryName;
    } else {
      type.categoryName = type.subCategoryName;
    }

    if(type){
      setStep(1);
      setCampaignType(type);
      getStoresActivableSmartCampaign(selectedCompany, type.id).then((res) => {
        setStores(res.data.data);
      });
      getEmptySmartCampaign(selectedCompany, type.id).then((res) => {
        setCampaign(res);
        setCampaignIsReady(true);
      })
    } else {
      //ir al maximal.
    }
  }

  useEffect(() => {
    if(subCategory){
      setCampaignTypeWasPredefined(true);
      willSetCampaignType(subCategory);
    }
  }, []);

  useEffect(() => {
    if(step === 2) {
      setInitialSmartCampaignWizardStep(2);
    } else if (step === 0) {
      setInitialSmartCampaignWizardStep(0);
    }
  },[step])

  const willSetCampaign = (campaign) => {
    setCampaign(campaign);
    setStep(step+1);
  }

    const goToStep = (step) => () => {
      setStep(step);
    }

    const returnFromMinimalEditor = () => {
      getCampaignDetail(campaign.id).then(campaignWithChanges => {
        setCampaign(campaignWithChanges);
        goToStep(1)();
      })
    }

    let content = null;
    switch(step){
      case 0:
        content = selectedCompany && <SelectCampaignType 
                    companies={companies} 
                    onBack={() => onBack(true)} 
                    t={t}
                    selectedCompany={selectedCompany} 
                    setCampaignType={willSetCampaignType}
                    setSelectedCompany={setSelectedCompany}
                    history={history}></SelectCampaignType>;
        break;
      case 1: 
        content = campaignIsReady && <SmartCampaignWizard
                    setLoading={setLoading}
                    history={history}
                    t={t}
                    initialStep={initialSmartCampaignWizardStep}
                    availableStores = {availableStores}
                    company={selectedCompany}
                    storesActivableSmartCampaign={stores}
                    campaignType={campaignType}
                    campaign={campaign}
                    setCampaign={willSetCampaign}
                    onBack={(campaignTypeWasPredefined ? onBack : goToStep(0))}
                    onClose={onBack}
                    ></SmartCampaignWizard>;
        break;
      case 2: 
        // minimalEditor.
        content = <MinimalEditor 
                      setLoading={setLoading}
                      onBack={returnFromMinimalEditor}
                      onClose={onSaveAndClose}
                      t={t}
                      campaignId={campaign.id}
                      availableStores={availableStores}
                      companyId={companyId}
                      history={history}/>;
        break;
      default:
        break;
    }

    return <MDBContainer className={(step === 2) ? "p-0" : ""} id="new-campaign">
        { content }
    </MDBContainer>
}

export default NewCampaign;