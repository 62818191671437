export const translations = {
    conversation: {
        es: "Conversación de {{user}} con",
        en: "{{user}}'s conversation with"
    },
    state: {
        es: "Estado actual:",
        en: "Current state:"
    },
    reviewHistory: {
        es: "Historial de reseñas y respuestas de ",
        en: "History of reviews and responses from "
    },
    with: {
        es: " con ",
        en: " with "
    },
    noComment: {
        es: "No dejó comentario",
        en: "Reviewer left no comment"
    },
    answered: {
        es: " respondió el ",
        en: " answered at "
    },
    rated: {
        es: " calificó con ",
        en: " rated with "
    },
    in: {
        es: " en ",
        en: " in "
    },
    at: {
        es: " el ",
        en: " at "
    },
    emptyState: {
        es: "Este usuario no tiene reseñas sobre esta tienda",
        en: "This user have no reviews for this store"
    },
    newFeature: {
        es: "Esta nueva funcionalidad podría no estar disponible en tu plan en un futuro",
        en: "This new feature may not be available in your current plan at the future"
    }
}