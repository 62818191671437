import React, {useState, useEffect} from 'react';
import { translate } from 'react-i18next';
import {withRouter} from "react-router"
import './OrdersContainer.css'
import { getTokenSSO } from '../../api/securityApi';
import Iframe from 'react-iframe';
import { envConfig } from '../../config/config';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../commons/components/application/miscActions';

const OrdersContainer = ({t, view, history}) => {

    const selectedCompany = useSelector(state => state.application.selectedCompany)

    const dispatch = useDispatch()

    const [token, setToken] = useState(undefined)    

    const urls = {
        orders: envConfig.ordersUrls.orders,
        stats: envConfig.ordersUrls.stats,
        chats: envConfig.ordersUrls.chats,
        settingsShop: envConfig.ordersUrls.settings.shop,
        settingsChannels: envConfig.ordersUrls.settings.channels,
        settingsBuilder: envConfig.ordersUrls.settings.builder
    }

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        dispatch(setLoading(true))
        if (selectedCompany.hotBotSSOId) {
            const response = await getTokenSSO('HOTBOT', selectedCompany.id)
            if (response && response.data && response.data.data && response.data.data.token) {
                setToken(response.data.data.token)
            }
        } else {
            history.push('/buy/orders')
        }
        dispatch(setLoading(false))
    }

    const getUrl = () => urls[view] + '&token=' + token + '&locale=' + getLanguage()

    const getLanguage = () => navigator.language && navigator.language.split('-')[0]

    return <>
        {token &&
            <Iframe position="relative" 
                url={getUrl()}
                className="orders-iframe"
                frameBorder="0"
            />
        }
    </>
}

export default (translate('common')(withRouter(OrdersContainer)));