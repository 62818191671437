import React from 'react';
import LineChart from '../../utils/charts/LineChart.js';
import PieChart from '../../utils/charts/PieChart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBCard, MDBCardBody } from 'mdbreact';
import { translations } from "../../../../config/translations/Clients/UserVisitationsDetail";
import translate from "../../../../config/translations/translate";

const calculateVAxis = (visitations) => {
	var names = visitations.slice(0,6).map((val) => {
		return val.storeName
	});
	names = Array.from(new Set(names));
	var vAxis = names.map((name) => {
		return {
			label: name,
			calc: (x) => {
				return x.userVisits.filter(i => (i.storeName===name)).length;
			}
		}
	});
	return vAxis;
}
class UserVisitationsDetail extends React.Component {

	render(){
		const { t } = this.props;
		if(
			!this.props.visitationsByStore ||
			this.props.visitationsByStore.length === 0
		){
			return (
				<div className='h-100 d-flex'>
					<div className='m-auto text-center'>
						<div>
							<h1>
								{t("noVisits")}
							</h1>
						</div>
						<div><FontAwesomeIcon icon='clock' size='5x'></FontAwesomeIcon></div>
					</div>
				</div>
			)
		}
		return (
			<MDBCard className="m-2">
				<MDBCardBody>
					<h3 className='mb-0'>{t("visitsHistory")}</h3>
					<PieChart
						source={this.props.visitationsByStore}
						hAxis={
							{
								label: t("store"),
								calc: (item) => (item.storeName)
							}
						}
						vAxis={
							{
								label: t("visits"),
								calc: (item) => (item.totalVisits)
							}
						}
					></PieChart>
					<h3 className='mb-0 mt-2'>{t("visitsLastYear")}</h3>
					<LineChart
						source={this.props.visitationsByDate}
						hAxis={
							{
								label: t("date"),
								calc: (item) => {
									var d = new Date(item.date);
									return `${d.getMonth()+1}/${d.getFullYear()}`;
								}
							}
						}
						vAxis= {calculateVAxis(this.props.visitationsByStore)}
					></LineChart>
				</MDBCardBody>
			</MDBCard>
		);
	}
}

UserVisitationsDetail.translations = { ...translations }
export default translate(UserVisitationsDetail)
