import { CampaignListFiltersConstants } from './CampaignListFiltersConstants'

export function CampaignListFiltersReducers(state = {}, action) {
	switch (action.type) {
		case CampaignListFiltersConstants.SET_CAMPAIGNS_LIST_FILTERS:
			return {
				...state,
				filters: action.payload.filters
			};
		case CampaignListFiltersConstants.SET_CAMPAIGNS_LIST_SAVED_FILTERS:
			return {
				...state,
				savedFilters: action.payload.savedFilters
			}
		case CampaignListFiltersConstants.SET_CAMPAIGNS_LIST_PREVIOUS_FILTERS:
			return {
				...state,
				previousFilters: action.payload.previousFilters,
				previousView: action.payload.previousView,
				previousOrder: action.payload.previousOrder
			}
		default:
			return state
	}
}

export default CampaignListFiltersReducers;