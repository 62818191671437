export const translations = {
    howProbably: {
        first: {
            es: "¿Qué tan probable es que recomiendes",
            en: "How likely are you to recommend"
        },
        second: {
            es: "a tus amigos o familiares?",
            en: "to your friends or family?"
        }
    },
    unlikely: {
        es: "Poco probable",
        en: "Unlikely"
    },
    veryLikely: {
        es: "Muy probable",
        en: "Very Likely"
    },
    badExperience: {
        es: "¿Tuviste una mala experiencia?",
        en: "You had a bad experience?"
    },
    googleBtn: {
        es: "Déjanos tu reseña en Google",
        en: "Review us on Google"
    },
    facebookBtn: {
        es: "Déjanos tu reseña en Facebook",
        en: "Review us on Facebook"
    },
    privateComment: {
        es: "Déjanos un mensaje",
        en: "Leave us a message"
    },
    internBtn: {
        es: "Contáctanos directamente",
        en: "Contact us"
    },
    sendComment: {
        es: "Enviar",
        en: "Send"
    },
    text1:{
        es:"Tu opinión es muy importante para nosotros, y podría ayudarnos a ofrecerte un mejor servicio en el futuro.",
        en:"Your opinion is very important to us, and it could help us serve you better in the future."
    },
    text2:{
        es: "Si tuviste algún inconveniente, comunícate directamente con nosotros para poder ayudarte rápidamente.",
        en: "If you have any problems, please contact us directly so we can help you quickly."
    },
    thanks:{
        es: "Hola, ¡gracias por elegirnos!",
        en: "Hello, thank you for choosing us!"
    },
    thank:{
        es: "¡Gracias!",
        en: "Thanks!"
    },
    thankForComment: {
        es: "¡Muchas gracias por tu feedback! Lo tendremos muy en cuenta para seguir mejorando.",
        en: "Thank you very much for your feedback! We will take it into account to continue improving."
    },
    canClose:{
        es: "Ya puedes cerrar esta ventana 😊",
        en: "You can close this window 😊"
    },
	redirecting: {
		suggestion: {
			en: "There is nothing more rewarding for us than having your opinion on ",
			es: "No hay nada más gratificante para nosotros que contar con tu opinión en "
		},
		button: {
			en: "Go to ",
			es: "Ir a "
		}
	}
}