import React from 'react'
import { Row, Col } from "react-bootstrap";
// import { translate } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Gender from '../../utils/Gender';
import SocialMediaLinks from '../../utils/SocialMediaLinks';
import TimeAgo from '../../utils/TimeAgo.js';
import ProfileAvatar from '../../utils/table/formaters/ProfileAvatar';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { MDBChip } from "mdbreact";
import { MDBCard, MDBCardBody } from 'mdbreact';
import { translations } from "../../../../config/translations/Clients/UserCard";
import translate from "../../../../config/translations/translate";


export const UserPill = ({ user, linkToDetail = true, storeId, isSubscription = false }) => {
	const storeParam = storeId ? `storeId=${storeId}` : ''
	// const subscriptionParam = isSubscription ? `&isSubscription=${isSubscription}` : ''
	return <a href={linkToDetail ? `/crm/user/${user.id}?${storeParam}` : "#"}>
		<MDBChip size="sm" src={`https://admin.botbit.io/static/img/avatars/${user.avatar ? user.avatar : 'noavatar.jpg'}`} alt={`${user.name} ${user.lastName}`} bgColor="light-blue lighten-4" className="mb-0 mr-1" waves>
			{`${user.name} ${user.lastName}`}
		</MDBChip>
	</a>
}

const UserCard = ({ t, user, lastVisitationDate, registrationDate, inline = true }) => {
	let phoneNumber = parsePhoneNumberFromString(user.phone ? user.phone : "", user.country);
	return (
		<MDBCard className='m-2'>
			<MDBCardBody>
				<Row>
					{
						!inline &&
						<Col lg={4}>
							<Row>
								<Col>
									<ProfileAvatar avatar={user.avatar}></ProfileAvatar>
								</Col>
							</Row>
							<Row>
								<Col>
									<SocialMediaLinks links={
										{
											facebook: user.facebookProfile,
											google: user.googleProfile,
											linkedIn: user.linkedInProfile,
											twitter: user.twitterProfile,
											instagram: user.instagramProfile
										}} />
								</Col>
							</Row>
						</Col>
					}
					<Col lg={inline ? 12 : 8}>
						<Row>
							<Col>
								{
									!inline &&
									<Row>
										<Col >
											<h3 className="d-inline mr-4">
												{user.name} {user.lastName}
											</h3>
											<Gender gender={user.gender} />
											{
												user.country &&
												<ReactCountryFlag code={user.country} svg />
											}
										</Col>
									</Row>

								}
								{user.birthDate &&
									<Row className="small mb-2">
										<Col>
											<FontAwesomeIcon className="m-r-xs" icon="birthday-cake" />
											<b>{t("birthday")}:</b> {new Date(user.birthDate).toLocaleDateString()}
										</Col>
									</Row>
								}
								<Row className="small mb-2">
									<Col>
										<FontAwesomeIcon className="m-r-xs" icon="envelope" />
										<b>Email:</b> {user.email}
									</Col>
								</Row>
								{user.phone &&
									<Row className="small mb-2">
										<Col>
											<FontAwesomeIcon className="m-r-xs" icon="phone" />
											<b>{t("phone")}:</b> {
												phoneNumber ?
													phoneNumber.formatNational() :
													user.phone
											} {phoneNumber && phoneNumber.isValid() &&
												<a href={`https://wa.me/${phoneNumber.number.replace('+', '')}`} target="_blank" rel="noopener noreferrer">
													<i className="m-l-xs fab fa-lg fa-whatsapp" style={{ color: '#09d261' }}></i>
												</a>
											}
										</Col>
									</Row>
								}
								<Row className="small mb-2">
									<Col>
										<FontAwesomeIcon className="m-r-xs" icon="user-check" />
										<b>{t("seenFirstTime")}:</b> <TimeAgo date={registrationDate} />
									</Col>
								</Row>
								<Row className="small mb-2">
									<Col>
										<FontAwesomeIcon className="m-r-xs" icon="user-clock" />
										<b>{t("seenLastTime")}:</b> <TimeAgo noDateText='panels.clients.noVisits' date={lastVisitationDate} />
									</Col>
								</Row>
								{
									inline &&
									<Row>
										<Col>
											<SocialMediaLinks links={
												{
													facebook: user.facebookProfile,
													google: user.googleProfile,
													linkedIn: user.linkedInProfile,
													twitter: user.twitterProfile,
													instagram: user.instagramProfile
												}
											} />
										</Col>
									</Row>
								}
							</Col>
						</Row>
					</Col>
				</Row>
			</MDBCardBody>
		</MDBCard>
	)
}

UserCard.translations = {
	...translations
}

export default translate(UserCard)
