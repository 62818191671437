import React from 'react'
import './BestDaysCardContainer.css'
import {BestDaysCard} from './BestDaysCards/BestDaysCard'
import { Carousel } from 'botbit-ui-components'

export const BestDaysCardContainer = ({t, className, bestOpeningDays, mobileWidth}) => {

    const getBestDaysCardArray = () => {
        let daysArray = [];
        bestOpeningDays.forEach((day, index) => {
            daysArray.push(<BestDaysCard key={index} t={t} first={index === 0} day={day}/>)
        })
        return daysArray;
    }

    return(
        <div className={"bestDaysCardContainer" + (className ? ' ' + className : '')}>
            {window.innerWidth < mobileWidth ?
                <Carousel 
                    items={getBestDaysCardArray()}
                    autoPlay={false}
                    fadeOutAnimation={true}
                    mouseDragEnabled={true}
                    responsive={{
                            "993": {
                                "items": 3
                            }
                        }}
                    disableAutoPlayOnAction={true}
                    buttonsDisabled={true}
                    infinite={false}
                    // stagePadding = { ( { paddingRight: 30 } ) }  
                /> : 
                <>
                    {getBestDaysCardArray()}
                </>
            }
        </div>
    )
}