export const translations = {
  noData: {
    es: "No hay detalle para este usuario",
    en: "There is no data for this user"
  },
  revenueModal: {
    es_AR: "Clientes que Compraron",
    es: "Clientes que Compraron",
    en: "Customers who Bought",
  },
  purchased: {
    es: "Compro el",
    es_AR: "Compró el",
    en: "Purchased on",
  },
  onlineSales: {
    es: "Venta Online",
    es_AR: "Venta Online",
    en: "Online Sales",
  },
  faceToFaceModal: {
    es: "Ventas Presenciales",
    es_AR: "Ventas Presenciales",
    en: "Face to Face Sales",
  },
  registered: {
    es: "Se registró el",
    es_AR: "Se registró el",
    en: "Registered on",
  },
  promoters: {
    es: "Clientes insatisfechos que ahora están felices 💕",
    en: "Dissatisfied customers who are now happy 💕"
  },
  detractors: {
    es: "Clientes felices que ahora están insatisfechos 💔",
    en: "Happy customers who are now dissatisfied 💔"
  },
  columnName: {
    avatar: {
      es: "Avatar",
      es_AR: "Avatar",
      en: "Avatar",
    },
    name: {
      es: "Nombre",
      es_AR: "Nombre",
      en: "Name",
    },
    beforeScore: {
      es: "Antes",
      es_AR: "Antes",
      en: "Before",
    },
    afterScore: {
      es: "Ahora",
      es_AR: "Ahora",
      en: "Now",
    },
    eventTimestamp: {
      es: "Cuándo",
      es_AR: "Cuándo",
      en: "When",
    },
    email: {
      es: "Email",
      es_AR: "Email",
      en: "Email",
    },
    ticket: {
      es: "Importe",
      es_AR: "Importe",
      en: "Amount",
    },
    store: {
      es: "Tienda",
      es_AR: "Tienda",
      en: "Store",
    },
    stimulated: {
      es: "Estimulado",
      es_AR: "Estimulado",
      en: "Stimulated",
    },
    registeredThrough: {
      es: "Forma de Registro",
      es_AR: "Forma de Registro",
      en: "Record Form",
    },
  },
};
