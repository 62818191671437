import React from "react";
import {Col, Row} from "react-bootstrap";
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import {translate} from "react-i18next";



const MySwal = withReactContent(Swal);

const handleClick = (onButtonClick, reset = false, clientId = null, t) => {
  MySwal.fire({
    title: t("apiConfig.enterEmail"),
    input: 'email',
    inputPlaceholder: 'email'
  }).then(email => {
    if (email.value === undefined || email.value.length === 0) {
      MySwal.fire({
        type: 'error',
        title: 'Oops...',
        text: t("apiConfig.invalidEmail")
      })
      return
    }
    onButtonClick(email.value, reset, clientId);
  })
};

const ApiConfigClientDetails = ({onButtonClick, oauthClientDetails, t}) => {
  if (oauthClientDetails.length === 0) {
    return  <React.Fragment>
          <Row>
              <Col lg={12}>
                <p>{t("apiConfig.longDesc")}</p>
              </Col>
          </Row>
          <Row>
            <Col lg={12}>
                  <button className='btn btn-primary float-right' onClick={()=>handleClick(onButtonClick, null, null, t)}>{t("apiConfig.generate")}</button>
              </Col>
          </Row>
    </React.Fragment>
  }
  const aClient = oauthClientDetails[0];
  return ( <React.Fragment>
            <Row>
              <div className="col-12">
                <p className="word-wrap-cs">{t("apiConfig.sentTo")}<b>{aClient.admin.email} </b>. {t("apiConfig.memoryAid")}
                </p>
              </div>
            </Row>
            <Row>
              <div className="col-12">
                <table className="api-config-table">
                  <tbody>
                  <tr>
                    <td className='word-wrap-cs api-config-title'><b>client_id:</b></td>
                    <td className='word-wrap-cs api-config-value pl-2 text-left'>{aClient.clientId}</td>
                  </tr>
                  <tr>
                    <td className='word-wrap-cs api-config-title'><b>username:</b></td>
                    <td className='word-wrap-cs api-config-value pl-2 text-left'>{aClient.admin.username}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </Row>
            <Row>
              <Col lg={12}>
                <p className="word-wrap-cs">{t("apiConfig.reset.longDesc")}</p>
                <button className='btn btn-primary'
                        onClick={() => handleClick(onButtonClick,true, aClient.clientId, t)}>
                  {t("apiConfig.reset.button")}
                </button>
              </Col>
            </Row>
    </React.Fragment>
  )
};

export default translate('common')(ApiConfigClientDetails)