export const translations = {
  stores: {
    es: "Tiendas",
    en: "Stores",
  },
  sources: {
    es: "Fuentes",
    en: "Sources"
  },
  scores: {
    es: "Puntajes",
    en: "Scores"
  },
  stars: {
    es: "{{score}} estrellas",
    en: "{{score}} stars"
  },
  oneStar: {
    es: "1 estrella",
    en: "1 star"
  },
  theme: {
    es: "Tema",
    en: "Theme"
  },
  copy1: {
    es_ar: 'Copiá y pegá el siguiente código en tu web. Tenés que hacerlo en la ubicación en la que querés que aparezca el widget.',
    es: 'Copia y pega el siguiente código en tu web. Tienes que hacerlo en la ubicación en donde quieres que aparezca el widget.',
    en: 'Copy and paste the following piece of code in your web page. You need to place it where you want the widget to appear.'
  },
  copy2: {
    es_ar: 'Copiá y pegá el siguiente código antes de que termine el tag body de tu HTML.',
    es: 'Copia y pega el siguiente código antes de que termine el tag body de tu HTML.',
    en: 'Copy and paste the following piece of code before your HTML\'s body tag ends.'
  },
  widgetAlreadyConfigured: {
    es_ar: 'Ahora que configuraste el widget, copiá y pegá los códigos a continuación para agregarlo a tu web. Si hiciste algún cambio y ya tenés el widget funcionando, no tenés que volver a pegarlo, solo refrescar tu página.',
    es: 'Ahora que configuraste el widget, copia y pega los códigos a continuación para agregarlo a tu web. Si hiciste algún cambio y ya tienes el widget funcionando, no tienes que volver a pegarlo, solo refrescar tu página',
    en: 'Now that the widget is configured, copy and paste the following pieces of code to add it to your web page. If you only made changes and you already have a functioning widget, you don\'t need to paste them again, only to refresh your page.'
  },
  code: {
    es: 'Código',
    en: 'Code'
  },
  copyButton: {
    es: 'Copiar',
    en: 'Copy'
  },
  applyButton: {
    es: 'Aplicar',
    en: 'Apply'
  },
  cleanButton: {
    es: 'Limpiar',
    en: 'Clean'
  },
  personalize: {
    es: 'Personaliza tu widget',
    en: 'Customize your widget'
  },
  title: {
    en: 'Reviews Widget',
    es: 'Widget de Reviews'
  },
  others: {
    es: 'Otros',
    en: 'Others'
  },
  amount: {
    es: 'Cantidad',
    en: 'Amount'
  },
  amountMessage: {
    es: 'Cantidad máxima de reviews a mostrar. Se debe ingresar un número mayor o igual a 15.',
    en: 'Maximum amount of reviews to show. Input number must be greater or equal to 15.'
  },
  light: {
    es: 'Claro',
    en: 'Light'
  },
  dark: {
    es: 'Oscuro',
    en: 'Dark'
  },
  gray: {
    es: 'Gris',
    en: 'Gray'
  },
  newFeature: {
    es: "Esta nueva funcionalidad podría no estar disponible en tu plan en un futuro",
    en: "This new feature may not be available in your current plan at the future"
  }
}