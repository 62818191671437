import React from "react";
//import FormControl from './FormControl';

export class InputFileField extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      targetFile: '',
      srcImg:''
    }
  }
  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  onFileChange = async (e) => {
    const { input } = this.props;
    const targetFile = e.target.files[0];
    if (targetFile) {
      const val = await this.getBase64(targetFile);
      this.setState({...this.state, targetFile: targetFile, srcImg:  val});
      input.onChange(val)
    } else {
      this.setState({...this.state, targetFile: '', srcImg: ''});
      input.onChange(null)
    }
  };

  render = ()=> {
    const id = this.props.id?this.props.id:this.props.name
    //console.log(this.props);
   const targetFile = this.state.targetFile;
  return (
    <React.Fragment>
            <div className="form-group custom-file">
              <input id={id} type="file" className="custom-file-input"  accept={this.props.accept}  autoFocus={this.props.autoFocus}
                   onChange={ this.onFileChange}
            />
            <label htmlFor={id} className="custom-file-label" style={{whiteSpace:"nowrap"}}>{targetFile===''?this.props.placeholder:targetFile.name}</label>
          </div>
    </React.Fragment>
  )}
}

export default InputFileField;