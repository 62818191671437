/**
 * Transforms an array into a readable text. Can show "First element, second element and N more"
 * @param {array} elements elements to show
 * @param {number} maxElementsToShow max amount of elements to show before the N more (if undefined no limit is applied)
 * @param {function} t translation function
 * @returns {string} formatted text
 */

import { getTranslationFunction } from "../../config/translations/translate";
import { translations } from "../../config/translations/Utils/listToText.js"

export const listToText = (elements, maxElementsToShow) => {
    const t = getTranslationFunction(translations);
    if (!maxElementsToShow) {
        maxElementsToShow = elements.length;
    }

    let formattedText = "";

    if (elements && elements.length > 0) {
        let elementsText = "";
        // recorro los elementos y los voy poniendo separados por coma en elements
        for (let i = 0; i < Math.min(maxElementsToShow, elements.length); i++) {
            elementsText += `${(i > 0) ? ", " : ""}${elements[i]}`;
        }

        //"n mas": si n > 0 lo escribo 
        if (elements.length - maxElementsToShow > 0) {
            formattedText = t("someElements",
                {
                    elements: elementsText,
                    moreElementsCount: (elements.length - maxElementsToShow)
                })
        }
        //si no devuelvo elements
        else {
            elementsText = elementsText.split(',');
            if (elementsText.length > 1) {
                const last = elementsText.pop();
                elementsText = last.trim().charAt(0) !== "I" && last.trim().charAt(0) !== "i"
                    ? elementsText.join(', ') + ` ${t('and')} ${last}`
                    : elementsText.join(', ') + ` ${t('altAnd')} ${last}`
            }
            formattedText = elementsText;
        }
    }

    return formattedText;

}
export default listToText;