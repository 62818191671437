export const translations = {
    title: {
        es: "¿En qué podemos mejorar? Tu opinión es muy importante para nosotros.",
        en: "What can we improve? Your opinion is very important for us."
    },
    badExperience: {
        es: "¿Tuviste una mala experiencia?",
        en: "You had a bad experience?"
    },
    googleBtn: {
        es: "Déjanos tu reseña en Google",
        en: "Review us on Google"
    },
    facebookBtn: {
        es: "Déjanos tu reseña en Facebook",
        en: "Review us on Facebook"
    },
    privateComment: {
        es: "Déjanos un mensaje",
        en: "Contact us directly"
    },
    internBtn: {
        es: "Contáctanos directamente",
        en: "Contact us"
    },
    sendComment: {
        es: "Enviar",
        en: "Send"
    },
    swalTitle: {
        es: "¡Mensaje Enviado!",
        en: "Message Sent!"
    },
    swalText: {
        es: "Tu mensaje ha sido enviado exitosamente",
        en: "Your message has been send succesfully"
    },
    finalTitle: {
        es: "Gracias, apreciamos tu feedback",
        en: "Thanks, we appreciate your feedback"
    },
    finalMessage: {
        es: "Tu mensaje ha sido enviado exitosamente",
        en: "Your message has been send succesfully"
    },
    yourScore: {
        es: "Tu calificación es ",
        en: "Your score is "
    },
}
