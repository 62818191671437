import React, { useState } from 'react';
import CaptiveGraph from './CaptiveGraph';
import {
  timePeriodsIds as timePeriods,
  getLabel,
  getSuffixLabel,
  calculateFrom,
  calculateTo,
  Filters
} from 'botbit-ui-components';
import _ from "lodash";
import { useCompanyAvailableStores } from '../../../commons/utils/useCompanyAvalaibleStores';
import translate from '../../../config/translations/translate';
import { translations } from '../../../config/translations/Dashboard/CaptiveGraph';
import UserModal from '../../UserModal/UserModal';
import { getCaptiveModal } from '../userModalConfig';
import moment from "moment"
import { RegisteredThrough } from './RegisteredThrough';

const CaptiveGraphView = ({ t }) => {
  const [filterValues, setFilterValues] = useState({ timePeriod: [{ value: timePeriods.LAST_30_DAYS }], stores: [], registeredThrough: RegisteredThrough.map(r => r.id) })
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [usersModalData, setUsersModalData] = useState({});
  const companyAvailableStores = useCompanyAvailableStores()
  // const [dataAnalysis, setDataAnalysis] = useState(getEmptyDataAnalysisObject());

  const schemas = {
    timePeriod: {
      id: 'timePeriod',
      label: t("timePeriod"),
      type: 'timeperiod',
      icon: 'calendar-alt',
      placeholder: t("custom"),
      priority: true,
      visible: true,
      options: [timePeriods.THIS_MONTH, timePeriods.LAST_30_DAYS, timePeriods.LAST_6_MONTHS, timePeriods.LAST_12_MONTHS, timePeriods.THIS_YEAR, timePeriods.CUSTOM],
      defaultValues: filterValues.timePeriod,
    },
    stores: {
      id: "stores",
      label: t("store"),
      type: "multiselect",
      icon: "store-alt",
      placeholder: t("allStores"),
      priority: true,
      visible: true,
      options: companyAvailableStores.map(s => ({ value: s.id, text: s.name })),
      search: true,
      defaultValues: [],
      dependsOn: "companies"
    },
    registeredThrough: {
      id: "registeredThrough",
      type: "multiselect",
      options: RegisteredThrough.map(r => ({ value: r.id, text: r.text })),
      placeholder: t("selectMethod"),
      label: t("selectMethod"),
      defaultValues: RegisteredThrough.map(r => r.id),
      // defaultValues: RegisteredThrough.map(r => r.id).filter(r => dataAnalysis[r].maximum > 0),
      visible: true,
      priority: true
    }
  };

  const getPeriod = () => {
    return _.at(filterValues, "timePeriod[0].value")[0]
      ? _.at(filterValues, "timePeriod[0].value")[0] !== "CUSTOM"
        ? { suffixText: getSuffixLabel(_.at(filterValues, "timePeriod[0].value")[0]), text: "· 📅 " + getLabel(_.at(filterValues, "timePeriod[0].value")[0]), from: floorHours(calculateFrom(_.at(filterValues, "timePeriod[0].value")[0])), to: ceilHours(calculateTo(_.at(filterValues, "timePeriod[0].value")[0])) }
        : { suffixText: getSuffixLabel(_.at(filterValues, "timePeriod[0].value")[0]), text: "· 📅 " + getLabel(_.at(filterValues, "timePeriod[0].value")[0]), from: floorHours(_.at(filterValues, "timePeriod[0]")[0].from), to: _.at(filterValues, "timePeriod[0]")[0].to }
      : { text: "" }
  }

  const getStores = () => {
    if (filterValues.stores && filterValues.stores.length > 0)
      return filterValues.stores;
    else if (filterValues.companies && filterValues.companies.length > 0)
      return companyAvailableStores.map(s => s.id);
  }

  const ceilHours = date => {
    date.setHours(date.getHours() + 1);
    date.setMinutes(0, 0, 0)

    return date;
  }

  const floorHours = date => {
    date.setHours(date.getHours());
    date.setMinutes(0, 0, 0)

    return date;
  }

  const openModal = (modalConfig) => {
    document.body.style.overflowY = "hidden"
    setUsersModalData(modalConfig);
    setIsModalOpen(true);
  };

  const getPeriodForModal = () => {
    let period;
    if (usersModalData.aggruptationType === "DAILY") {
      period = {
        from: usersModalData.date.toDate(),
        to: usersModalData.date.toDate(),
        text: usersModalData.date.format("DD-MM-YYYY")
      }
    } else {
      period = {
        from: usersModalData.date.startOf('month').toDate(),
        to: usersModalData.date.endOf("month").toDate(),
        text: usersModalData.date.format("DD-MM-YYYY")
      };
    }
    return period;
  }

  const getTitleForModal = () => {
    let dateLabel = t("captiveModal.thePeriod", { date1: moment(usersModalData.date.toDate()).format("MMMM YYYY") });
    if (usersModalData.aggruptationType === "DAILY") {
      dateLabel = t("captiveModal.theDay", { date: moment(usersModalData.date.toDate()).format("LL") })
    }
    return `${t('captiveModal')} ${dateLabel}`;
  }


  return (
    <div className="captive-graph-view">
      {isModalOpen && Object.keys(usersModalData).length > 0 && (
        <UserModal
          hideActions={true}
          isOpen={isModalOpen}
          toggle={() => {
            document.body.style.overflowY = "auto";
            setIsModalOpen(false);
            setUsersModalData({});
          }}
          stores={getStores()}
          period={usersModalData.id === "captiveModal"
            ? getPeriodForModal()
            : getPeriod()}
          {...usersModalData}
          title={usersModalData.id === "captiveModal"
            ? getTitleForModal()
            : usersModalData.title}
        />
      )}

      <Filters
        title={t("title", { period: getPeriod().text })}
        schemas={schemas}
        values={filterValues}
        onChange={(newValues) => {
          setFilterValues(newValues)
        }
        }
        showInfo
      />
      <CaptiveGraph
        viewMode
        openModal={(stores, methods, aggruptationType, date) => openModal({ ...getCaptiveModal(stores, methods), aggruptationType, date })}
        period={getPeriod()}
        stores={getStores()}
        filterValues={filterValues}
      />
    </div>
  );
}

CaptiveGraphView.translations = { ...translations }

export default translate(CaptiveGraphView);