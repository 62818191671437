
import {useState, useEffect} from "react";
import { getCategories } from "../../api/companyApi";

const useCategories = (t) => {
    const [categories, setCategories] = useState(null)
    const translateName = (a) => a.name//t(`common.categories.${a.name}.text`)
    
    useEffect(() => {
        getCategories().then((res) => {
            let cats = res.data.data.sort(function (a, b) {
                if (translateName(a) > translateName(b)) {
                    return 1;
                }
                if (translateName(a) < translateName(b)) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            cats = cats.map(c => {c.name = translateName(c); return c;});
            setCategories(cats);
        })
    }, []);
    return categories;
}

export default useCategories;