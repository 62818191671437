import React from "react";
import { MDBCard, MDBSpinner, MDBRow, MDBContainer } from "mdbreact";
import Chart from "react-google-charts";
import './reviewsSummary.css';
import { round } from '../../commons/utils/numbers';
import { Carousel } from "botbit-ui-components";
import { envConfig } from "../../config/config";

const SuperAdminSummary = ({ data, loading, onSummaryClick }) => {
    if (loading) {
        return <div className="text-center m-auto detractors-loading"><MDBSpinner /></div>
    }
    const detractorsFollowUp = [], publicRate = [];

    const createSimpleValueTooltipContent = (label, value) => {
        return '<div class="summary-tooltip">' +
            '<span class="summary-tooltip-small">' + label + '</span><br/>' +
            '<span class="summary-tooltip-big">' + value + '</span>' +
            '</div>';
    };

    const chartSelectEvent = (chart) => {
        const selection = chart.getSelection()
        if (selection.length === 1) {
            const [selectedItem] = selection
            const params = {
                from: data[selectedItem.row].from,
                to: data[selectedItem.row].to,
                botbitGenerated: false,
                detractors: true
            }
            const label = data[selectedItem.row].longLabel
            if (onSummaryClick) {
                onSummaryClick(label, params);
            }
        }
    }

    /*
    {
      "shortLabel": "abr 2019",
      "longLabel": "abril 2019",
      "from": "2019-04-01T00:00:00",
      "to": "2019-05-01T00:00:00",
      "score": 2.4027,
      "reviews": 293,
      "publicDetractorReviews": 164,
      "publicReviews": 775,
      "publicRate": 22,
      "botbitReplies": 13,
      "botbitReviews": 129,
      "botbitReplyRate": 11,
      "botbitAvgReplyTime": 2382579.0769,
      "replies": 20,
      "replyRate": 7
    },*/
    detractorsFollowUp.push([
        { role: 'domain' },
        { role: 'data', label: 'publicRate' },
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'data', label: 'botbitReplyRate' },
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { sourceColumn: 1, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'data', label: 'botbitAvgReplyTime' },
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { role: 'style' }
    ])
    publicRate.push([
        { role: 'domain' },
        { role: 'data', label: 'publicRate' },
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { role: 'style' }
    ])
    if (data) {
        data.forEach(item => {
            const botbitAvgReplyTimeInMinutes = item.botbitAvgReplyTime / 60
            detractorsFollowUp.push([
                // domain
                item.label,
                // data1: publicRate
                item.publicRate / 100,
                // tooltip
                createSimpleValueTooltipContent('Tasa de detractores online', (item.publicRate) ? item.publicRate + '%' : '0%'),
                // annotation
                (data.length <= 8 && item.publicRate > 0) ? item.publicRate + '%' : null,
                // data2: botbitReplyRate
                item.botbitReplyRate / 100,
                // tooltip
                createSimpleValueTooltipContent('Tasa de respuestas BotBit', (item.botbitReplyRate) ? item.botbitReplyRate + '%' : '0%'),
                // annotation
                (data.length <= 8 && item.botbitReplyRate > 0) ? item.botbitReplyRate + '%' : null,
                // data3: botbitAvgReplyTime in minutes
                botbitAvgReplyTimeInMinutes,
                // tooltip
                createSimpleValueTooltipContent('Tiempo de respuesta promedio', round(botbitAvgReplyTimeInMinutes, 0) + ' min'),
                // style
                null
            ]);
            publicRate.push([
                // domain
                item.shortLabel,
                // data
                item.publicRate / 100,
                // annotation
                (data.length <= 8 && item.publicRate > 0) ? item.publicRate + '%' : null,
                // tooltip
                createSimpleValueTooltipContent(item.longLabel, (item.publicRate) ? item.publicRate + '%' : '0%'),
                // style
                null
                //'fill-color: #cce5ff;stroke-width: 1;stroke-color: #004085' // primary-color
            ]);
        });
    }
    const items = [
        <MDBCard className="card-body m-2">
            <MDBContainer>
                <MDBRow className="super-admin-card">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ComboChart"
                        loader={<div>Cargando...</div>}
                        data={detractorsFollowUp}
                        options={{
                            title: 'Seguimiento a detractores',
                            chartArea: { 'width': (data.length > 8) ? '70%' : '70%', 'height': '70%' },
                            bar: { groupWidth: '90%' },
                            legend: { position: 'none' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxes: {
                                0: {
                                    format: 'percent',
                                    minValue: 0,
                                    maxValue: 0.001
                                },
                                1: {
                                    minValue: 0,
                                    maxValue: 200
                                }
                            },
                            series: {
                                0: { targetAxisIndex: 0, type: 'line' },
                                1: { targetAxisIndex: 0, type: 'line' },
                                2: { targetAxisIndex: 1, type: 'bars' },
                            },
                            colors: ['#ff3547', '#00c851', '#3366cc'],
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            }
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(4, chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow className="pt-1">
                    <div className="col-1 p-0" style={{ width: '20px', height: '10px', backgroundColor: '#ff3547' }} />
                    <span className="col-11 summary-legend minor-paddings">Tasa de detractores en sitios públicos</span>
                </MDBRow>
                <MDBRow className="pt-1">
                    <div className="col-1 p-0" style={{ width: '20px', height: '10px', backgroundColor: '#00c851' }} />
                    <span className="col-11 summary-legend minor-paddings">Tasa de respuestas a reseñas {envConfig.companyName}</span>
                </MDBRow>
                <MDBRow className="pt-1">
                    <div className="col-1 p-0" style={{ width: '20px', height: '10px', backgroundColor: '#3366cc' }} />
                    <span className="col-11 summary-legend minor-paddings">Tiempo prom. de respuesta a reseñas {envConfig.companyName}</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>,
        <MDBCard className="card-body m-2">
            <MDBContainer>
                <MDBRow className="super-admin-card">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ColumnChart"
                        loader={<div>Cargando...</div>}
                        data={publicRate}
                        options={{
                            title: '% Detractores públicos',
                            chartArea: { 'width': (data.length > 8) ? '80%' : '100%', 'height': '70%' },
                            bar: { groupWidth: '90%' },
                            legend: { position: 'none' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxis: {
                                format: 'percent',
                                minValue: 0,
                                maxValue: 0.001
                            },
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            }
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow>
                    <span className="summary-legend">Evolución del porcentaje de detractores públicos durante el período seleccionado.</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>
    ];

    return <Carousel
        items={items}
        autoPlay={false}
        fadeOutAnimation={true}
        mouseDragEnabled={true}
        responsive={
            {
                600: {
                    items: 2
                },
                1200: {
                    items: 4
                }
            }
        }
        disableAutoPlayOnAction={true}
        buttonsDisabled={true}
        infinite={false}
    />
}

export default SuperAdminSummary