import React from "react"
import { MDBListGroup, MDBListGroupItem } from "mdbreact"

const pad = (num, size) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}


const ruleToNaturalLanguage = (t, rule) => {
    const prefix = 'panels.feedback.settings.rules.nl.readonly'
    var text = '';

    if (rule.category === 'FEEDBACK') {
        const firstPart = t(`${prefix}.1`)
        const operator = t(`${prefix}.${rule.operator}`)
        const score = '<strong>' + rule.score
        const secondPart = t(`${prefix}.2.${rule.score === 1 ? 'single' : 'plural'}`) + '</strong>'
        const thirdPart = t(`${prefix}.3`)
        const fourthPart = t(`${prefix}.4`)
    
         text = firstPart
            + ' ' + operator
            + ' ' + score
            + ' ' + secondPart
            + ((rule.onlyForEmptyReviews) ? (' ' + thirdPart) : '')
            + ', ' + fourthPart
            + ":"
    } else if (rule.category === 'CHAT') {
        if (rule.filterType === 'TIME_RANGE') {
            const orWord = t(`${prefix}.or`)
            var ranges = rule.timeRangesFilter;
            var rangesText = '';
            ranges.forEach( (element, index) => {
                const dayName = t(`${prefix}.days.${element.day.toLowerCase()}`)
                rangesText = rangesText + dayName + "(" + pad(element.startHour,2) + ':' + pad(element.startMinute,2) + "-" + 
                    pad(element.endHour,2) + ':' + pad(element.endMinute,2 )+ ')'
                if (index < ranges.length-1) {
                    var separator;
                    if (index === ranges.length-2)
                        separator = ' ' + orWord + ' '
                    else
                        separator = ', ';
                    rangesText = rangesText + separator;
                }
            });

            text = t(`${prefix}.timeRange`, { ranges: rangesText });
        } else if (rule.filterType === 'LAST_INTERACTION_DAYS_INTERVAL') {
            text = t(`${prefix}.lastInteraction`, { days: rule.lastInteractionDaysIntervalFilter });
        }

		if (rule.conversationFlow && rule.conversationFlow.id) {
			text = text + t(`${prefix}.executeFlow`)
		} else {
			text = text + t(`${prefix}.autoReplyTemplate`)
		}

    }


    return <div dangerouslySetInnerHTML={{__html: text}}></div>
}


const AutoReplyRuleNaturalLanguage = ({ t, rule, onClickReplyTemplate }) => {
    return (<>
        <div className="mb-2">{ruleToNaturalLanguage(t, rule)}</div>
        <MDBListGroup>
			{rule.replyTemplates && rule.replyTemplates.map(template =>
                <MDBListGroupItem onClick={() => onClickReplyTemplate(template)} key={template.id}>{template.name}</MDBListGroupItem>
            )}
            {rule.conversationFlow && rule.conversationFlow.id && 
                <MDBListGroupItem key={rule.conversationFlow.id}>{rule.conversationFlow.name}</MDBListGroupItem>
            }
        </MDBListGroup>
    </>)
}

export default AutoReplyRuleNaturalLanguage