import React from 'react';
import { connect } from 'react-redux';
import { setLoading } from '../../commons/components/application/miscActions'
import { loginSuccess, loginFail } from './loginActions'
import { getToken } from '../../api/oAuthApi'
import { getFreeTrialStatus } from '../../api/freeTrialApi'
import { envConfig } from '../../config/config.js';
import { findByUsername, checkFirstLogin } from '../../api/administratorApi'
import { withRouter } from 'react-router-dom'
import Login from './Login';
import Loading from '../../commons/components/utils/Loading';
import withEventTracking from '../../config/withEventTracking';
import { trackers } from "../../config/withEventTracking";

const axiosErrorHandler = (props) => (error) => {
    if (error.response && error.response.data) {
        //FIXME no tiene que setearse el texto acá
        if (error.response.status === 400) {
            switch (error.response.data.error_description) {
                case 'User is disabled':
                    error.message = 'Usuario invalido o deshabilitado'
                    break;
                default:
                    error.message = 'Usuario o contraseña inválidos'

            }
        }
        // props.loginFail(error);
    }
    return error;
};
class LoginContainer extends React.Component {

    constructor(props) {
        super(props);
        this.loginRequest = this.loginRequest.bind(this)
        this.state = {
            isLoading: true
        }
    }


    loginRequest = (data) => {
        this.props.setLoading(true);
        const { user, password } = data;

        getFreeTrialStatus(user).then((response) => {
            var data = response.data.data;
            if (data.action === "REDIRECT") {
                window.location.href = envConfig.purchaseLanding + "?redirectUrl=" + data.url;
            } else {
                getToken(user, password, axiosErrorHandler(this.props)).then((response) => {
                    const token = response.data.access_token;
                    findByUsername(user, axiosErrorHandler(this.props)).then((response) => {
                        this.props.loginSuccess({ administrator: response.data.data, token: token }, '');
                        this.mixpanelHandler(response.data.data)
                        this.props.trackEvent("login", {}, false, [trackers.BACKEND])
                    })
                });
            }
        }
        );
    }

    appendSeparatorToUrl(url) {
        if (url.indexOf("?") > -1)
            return url + "&";
        else
            return url + "?";
    }

    mixpanelHandler = (admin) => {
        const { mixpanel } = this.props;
        mixpanel.identify(admin.id);
        mixpanel.people.set({
            "$email": admin.email,    // only reserved properties need the $
            "$first_name": admin.name,
            "$last_name": admin.lastname,
            "$avatar": "https://cdn-admin.botbit.io/static/img/avatars/" + admin.avatar, // Hay
            //   "$created": admin.created_at,    // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
            "username": admin.username,
            "company_id": admin.companyId,
            //   "company_name": admin.company.name,
            //   "company_category": admin.company.category, //i.e. library
            //   "company_plan": admin.company.plan
        });

        //mixpanel.track("login", {});
    }

    renderLogin() {
        this.setState({ isLoading: false });
        document.getElementById('wrapper').classList.add("login-set")
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        let username = params.get('username');
        let token = params.get('token');
        if (username && token) {
            checkFirstLogin(username, token, (err) => {
                this.props.history.push(`/login?username=${username}&firstLogin=false`);
                this.renderLogin()
                err.ignore = true
                return err;
            }).then((res) => {
                if (res.data.data.madeTheFirstLogin === false) this.props.history.push(`/setPassword?username=${username}&token=${token}&firstLogin=true`);
                else this.renderLogin()
            })
        } else {
            this.renderLogin()
        }
    }

    componentWillUnmount() {
        document.getElementById('wrapper').classList.remove("login-set");
    }

    render() {
        const params = new URLSearchParams(this.props.location.search);
        if (this.props.login.loggedIn) {
            // si tengo el parametro originalUrl tengo que forwardear luego del login a la pagina especificada
            let redirectTo = params.get('originalUrl') === null ? this.getHome() : decodeURIComponent(params.get('originalUrl').trim());
            //hasTodoTask().then(res => {
                //if (!!res.data.data) {
                //   redirectTo = "/checklist";
                //}
                //else 
				if (redirectTo.length === 0 || redirectTo === '/') {
                    redirectTo = this.getHome();
                }


                // si el redirect es a php, agrego sso y redirijo
                // sino solo cambio de ruta en el router
                if (redirectTo.indexOf(".php") > -1) {
                    redirectTo = this.appendSeparatorToUrl(redirectTo);
                    redirectTo = redirectTo + `sso=${this.props.login.token}`;
                    window.location.href = redirectTo;
                } else {
                    const index = redirectTo.indexOf("botbit.io")
                    if (index !== -1) {
                        redirectTo = redirectTo.substring(index + 9)
                    }
                    this.props.history.push(redirectTo);
                }
            //})

            return null;
        }
        let username = params.get('username');

        return (
            <>{this.state.isLoading
                ? <Loading fullScreen={true} loading={this.state.isLoading} />
                : <Login handleLogin={this.loginRequest} initialValues={{ user: username }} />
            }</>
        );
    }

    getHome = () => {
        return '/dashboard'
    }

}

const mapStateToProps = (state) => {
    return state
};

export default withRouter(connect(mapStateToProps, { loginSuccess, loginFail, setLoading })(withEventTracking(LoginContainer)))
