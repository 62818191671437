import React, { useState } from 'react';
import {countStores} from "../../campaigns/MinimalEditor/Helper";
import CampaignSegment from "../../campaigns/CampaignSegment/CampaignSegment"

const TRANSLATION_PREFIX = "panels.campaigns.analytics."
const SegmentDetail = ({segment, t, availableStores}) => {
    const [isEmpty, setEmpty] = useState(false);
    const [isCorrupted, setCorrupted] = useState(false);

    const segmentDetail = <CampaignSegment t={t}
    isEditing={false}
    campaign={{segment: segment}}
    availableStores={availableStores}
    storesActivableSmartCampaign={[""]}
    setInvalid={(empty, corrupted) => {setEmpty(empty); setCorrupted(corrupted)}} />

    return <div>
        { !isCorrupted ?
            <>
                {t(`${TRANSLATION_PREFIX}fromStores`, {stores: countStores(segment, t, false)})}
                {!isEmpty && <>
                    {t(`${TRANSLATION_PREFIX}clientsThat`)}
                    {segmentDetail}
                </>}
            </> : 
            t(`${TRANSLATION_PREFIX}segmentCorrupted`)
        }
    </div>
}

export default SegmentDetail;