import axiosClient from './axiosClient';
import { calculateFrom, calculateTo } from 'botbit-ui-components'
import {dateTOYYYYMMDD} from '../commons/utils/formatDate'

export const exportAdminEvents = (order,{storeId, companyId}) => {
	return axiosClient.get('/adminEvents/csv?categories[]=CRM&success=true&storeIds[]='+[storeId]+'&companyIds[]='+[companyId]);
}

export const getAdminEventActivityTypes = () => {
	return new Promise((resolve) => {
		axiosClient.get('/adminEvents/types')
			.then((res) =>{
				resolve(res.data.data);
			});
	});
}

export const getAdminEventList = (page, order, filters) => {
	let timePeriodFrom;
	let timePeriodTo;
	if(filters && filters.timePeriod && filters.timePeriod.length > 0){
		timePeriodFrom = dateTOYYYYMMDD(calculateFrom(filters.timePeriod[0]))
		timePeriodTo = dateTOYYYYMMDD(calculateTo(filters.timePeriod[0]))
	}
	let isTrueSet = (filters.status[0] === 'true');
	let companyFilter = (filters && filters.company && filters.company.length > 0) ? "&companyIds[]=" + filters.company.join() : "";
	let activityParameter = (filters && filters.activity && filters.activity.length > 0) ? "&types[]=" + filters.activity.join() : "";
	let storeFilters = (filters && filters.stores && filters.stores.length > 0) ? "&storeIds[]=" + filters.stores.join() : "";
	let successFilter = (filters && filters.status && filters.status.length > 0 && filters.status[0] !== "all") ? "&success=" + isTrueSet : '';
	return axiosClient.get(`/adminEvents?pageNumber=${page}&sort=timestamp&sortDirection=desc&pageSize=12${successFilter}${activityParameter}${storeFilters}${companyFilter}`,
	{
		params: {
			timestampFrom: timePeriodFrom,
			timestampTo: timePeriodTo
		}
	}
	)
}
