import React, { useEffect, useState } from 'react';
import { MDBIcon } from 'mdbreact';
import "./PodiumCard.scss"
import { translations } from "../../config/translations/Feedback/Podium/Podium";
import translate from "../../config/translations/translate";

const PodiumCard = ({ onClick, position, title, responses, score, generatedReviews, requestReview, avatar, selectedParam, t, showFooter }) => {
    const [highlightedParam, setHighlightedParam] = useState();
    const [paramList, setParamList] = useState()

    useEffect(() => {
        let uniqueParam, paramArray;
        const posibleValues = [
            {
                id: "TOTAL_REVIEWS_REQUESTED",
                value: requestReview,
                text: t("request"),
                icon: "paper-plane"
            },
            {
                id: "TOTAL_REVIEWS_ANSWERED",
                value: responses,
                text: t("responses"),
                icon: "reply"
            },
            {
                id: "TOTAL_REVIEWS_GENERATED",
                value: generatedReviews,
                text: t("generated"),
                icon: "comment"
            },
            {
                id: "AVERAGE_REVIEWS_ANSWERED",
                value: score,
                text: t("score"),
                icon: "star"
            },
        ]
        uniqueParam = posibleValues.find(param => param.id === selectedParam);
        paramArray = posibleValues.filter(param => param.id !== selectedParam);
        setHighlightedParam(uniqueParam);
        setParamList(paramArray);
    }, [selectedParam, requestReview, responses, generatedReviews, score])

    const getTitle = () => {
        const medalList = ["🏆", "🥈", "🥉"];
        const medal = medalList[position - 1]
        return (`${medal} ${title}`)
    }

    return (

        <div key={"podiumCard_" + position} className={`card text-center h-100`.trim()} onClick={onClick}>
            {highlightedParam && paramList && <>
                <img src={"https://admin.botbit.io/static/img/avatars/" + avatar} className="rounded-circle" alt="User" />
                <div className="card-body">
                    <h2 className="card-title">{getTitle()}</h2>
                    <div className="pos"><span><MDBIcon fas="true" icon={highlightedParam.icon}></MDBIcon> {highlightedParam.value}</span> {highlightedParam.text}</div>
                </div>
                {/* END .card-body */}

                <div className="card-footer mdb-color lighten-3 text-center">
                    <ul className='list-unstyled list-inline font-small mb-0'>
                        { showFooter && paramList.filter(params => params.value !== "N/A").map((param, index) => (
                            <li key={index} className='list-inline-item pr-2 white-text'>
                                <MDBIcon fas icon={param.icon}></MDBIcon> <span className="h5">{param.value}</span>

                                {/* {param.text} */}
                            </li>
                        ))}
                    </ul>
                </div>
                {/* END .card-footer */}
            </>}
        </div>
    );
}

PodiumCard.translations = { ...translations }

export default translate(PodiumCard);


