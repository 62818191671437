export const translations = {
    weAreDone: {
        es: "¡Terminamos!",
        en: "That's it!"
    },
    error: {
        es: "Ocurrió un error: {{errorMessage}}",
        en: "There was an error: {{errorMessage}}",
        defaultMessage : {
            es: "Error inesperado. Intenta nuevamente en unos minutos.",
            en: "Unexpected error. Please try again in a couple minutes."
        }
    },
    goBack: {
        es: "Volver",
        en: "Go back"
    }
}