import DataCard from "./DataCard"
import React, { useState } from 'react'
import './DataCardContainer.scss'
import { CardsConfig } from "./CardsConfig"
import { Carousel } from 'botbit-ui-components'
import LinkToHelpCenter from "../LinkToHelpCenter/LinkToHelpCenter"
import translate from "../../../config/translations/translate"
import { NewLoader } from "../../NewLoader/NewLoader"

const DataCardContainer = ({ data, title, className, footer, t, dataPerId, emptyState, name, period }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  let renderData = () => {
    let dataArray = [];
    dataPerId.forEach(dataObj => {
      let i = 1;
      if ((!data.highestScoreStore || !data.lowestScoreStore) && data.singleStoreScore) {
        data.moreStores = true;
      }
      for (const [key, value] of Object.entries(data)) {
        const cardConfig = CardsConfig(key)
        if (cardConfig && value && cardConfig.id === dataObj.id) {
          dataArray.push(<DataCard intercomName={`${name}Card${i}`} period={period} key={cardConfig.id} data={value} config={cardConfig} actions={dataObj.actions} extraData={dataObj.extraData} />)
          i++;
        }
      }
    })
    return dataArray
  }


  const dataCards = data && renderData();
  return (
    <div data-intercom-target={`${name}Container`} className={className}>
      <h3 className="dashboard-title">{title}</h3>
      <div className="data-cards">
        {dataCards && dataCards.length > 0 && data ?
          (window.innerWidth < 991 ?
            <Carousel
              items={dataCards}
              autoPlay={false}
              fadeOutAnimation={true}
              mouseDragEnabled={true}
              responsive={{
                "991": {
                  "items": 3
                }
              }}
              disableAutoPlayOnAction={true}
              buttonsDisabled={true}
              infinite={false}
              autoWidth
              startIndex={currentIndex}
              stagePadding={({ paddingRight: 20 })}
              onSlideChanged={(event) => setCurrentIndex(event.slide)}
            />
            :
            <>
              {dataCards}
            </>)
          : !data ? <NewLoader className="py-5" /> : <div className="empty-state">
            <h1 className="text-center">{emptyState}</h1>
          </div>
        }
      </div>
      <LinkToHelpCenter
        intercomName={`${name}Footer`}
        labels={{
          beforeLinkText: footer.text,
          linkText: footer.link,
          afterLinkText: " "
        }}
        url={footer.url}
      />
    </div>
  )
}
export default translate(DataCardContainer)