import React from 'react';
import { getTranslationFunction } from "../../config/translations/translate";
import { translations } from "../../config/translations/Feedback/Leaderboard";
import Moment from 'react-moment';
import { getRegisteredThroughConfiguration } from '../../commons/components/events/Events';
import { MDBIcon } from 'mdbreact';
import { getFunnelReviewDetail, getFunnelUserDetail } from '../../api/leaderboardApi';
import Score from '../feedback/Score';
import UserDetailsContainer from "../../commons/components/users/details/UserDetailsContainer";
import SocialSourceIcon from "../feedback/SocialSourceIcon"
import { SocialSourceNames } from '../../commons/enums/SocialSource';
import Review from '../feedback/Review';
import { getReview, reply, report } from '../../api/feedbackApi';
const t = getTranslationFunction({ ...translations })

const configs = (paramObj) => ({
  userModal: {
    id: "clients",
    title: t('reviewRequestSent'),
    columns: [
      {
        dataField: "userAvatar",
        title: t(`avatar`),
        formatter: (_cellContent, row) => {
          const avatarImg =
            row.avatar && row.avatar !== "" ? row.avatar : "noavatar.jpg";
          let imgsrc = `https://admin.botbit.io/static/img/avatars/${avatarImg}`;
          return (
            <div className="w-100">
              <img src={imgsrc} className="rounded-circle" style={{ width: "45px" }} alt="" />
            </div>
          );
        },
        size: 8,
      },
      {
        dataField: "userName",
        title: t(`userName`),
        size: 32,
        formatter: (cellContent, row) => {
          return row.userDisplayName;
        },
      },
      {
        dataField: 'timestamp',
        title: t(`timestamp`),
        size: 30,
        formatter: (cellContent, row) => {
          return (
            <span>
              <Moment format="L LT" locale="es">
                {cellContent}
              </Moment>
            </span>
          );
        },
      },
      {
        dataField: 'channel',
        title: t(`channel`),
        size: 10,
        className: paramObj.adminId && paramObj.adminId === "scheduler" ? "d-none" : "",
        formatter: (cellContent, row) => {
          const config = getRegisteredThroughConfiguration(cellContent);
          return cellContent ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <MDBIcon fab={config && config.iconType === "fab"} className="d-block" icon={config && config.icon} size="2x" />
              <div className="d-block">{config && config.desc}</div>
            </div>
          ) : '';
        },
      },
    ],
    columnsMobile: [
      {
        dataField: '',
        // className: 'd-block d-md-none',
        size: 100,
        formatter: (cellContent, row) => {
          const avatarImg =
            row.avatar && row.avatar !== '' ? row.avatar : 'noavatar.jpg';
          let imgsrc = `https://admin.botbit.io/static/img/avatars/${avatarImg}`;
          const config = getRegisteredThroughConfiguration(row.channel);
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                // margin: 'auto'
              }}
              className="mobile-column"
            >
              <div className="mobile-image-container">
                <div className="w-100">
                  <img src={imgsrc} style={{ width: "45px" }} className="rounded-circle" alt="" />
                </div>
              </div>
              <div
                style={{ marginLeft: 15, fontSize: '0.8rem', width: "80%" }}
                className="mobile-info-container"
              >
                <h5 style={{ margin: 0 }}>{`${row.userDisplayName}`}</h5>
                {/* <span>{row.email}</span> */}
                <p style={{ margin: 0 }}>
                  {/* {`${t('registered')} `} */}
                  <Moment format="L" locale="es">
                    {row.timestamp}
                  </Moment>{' '}
                  <Moment format="LT" locale="es">
                    {row.timestamp}
                  </Moment>
                </p>
                <span>{
                  (row.channel ? <><MDBIcon fab={config && config.iconType === "fab"} icon={config && config.icon} size="1x" /> {config && config.desc}</> : '')
                }</span>
                {/* <span>{" " + config.desc}</span> */}
              </div>
            </div>
          );
        },
      },
    ],
    expandElement: (active, element) => {
	  if (!active || !element.userId) return undefined;
	  // parche horrible, los stores me vienen en distintos formatos dependiendo donde se llama
	  //const stores = Array.isArray(paramObj.stores) ? paramObj.stores.map(id => ({ id: id })) : paramObj.stores.split(",").map(store => ({ id: parseInt(store) }))
	  const stores = paramObj.stores.split(",").map(store => ({ id: parseInt(store) }))
      return (
        <div className="pt-2 gray-bg">
          {(element.userId
            ? <UserDetailsContainer
              inline={true}
              userId={element.userId}
              selectedStores={stores}
            // componentSelectedStore={element.storeId}
            />
            : <span className="p-2">{t("noData")}</span>)
          }
        </div>
      );
    },
    elementKey: 'correlationId',
    getData: (page, order, filters) => getFunnelUserDetail(paramObj, page),
    dataPath: "data.data.items"

  },
  surveysModal: {
    id: "surveys",
    title: paramObj.publicReviews ? t("publicReviews") : t('surveys'),
    columns: [
      {
        dataField: "reviewerProfilePhotoUrl",
        title: t(`avatar`),
        formatter: (_cellContent, row) => {
          let imgsrc = row.reviewerProfilePhotoUrl;
          return (
            <div className="w-100">
              <img src={imgsrc} className="rounded-circle" style={{ width: "45px" }} alt="" />
            </div>
          );
        },
        size: 8,
      },
      {
        dataField: 'reviewerDisplayName',
        title: t(`userName`),
        // className: 'd-none d-md-block',
        size: 20,
        formatter: (cellContent, row) => {
          return (
            <div>
              {cellContent}
            </div>
          );
        },
      },
      {
        dataField: 'date',
        title: t(`timestamp`),
        // className: 'd-none d-md-block',
        size: 16,
        formatter: (cellContent, row) => {
          return (
            <span>
              <Moment format="L LT" locale="es">
                {cellContent}
              </Moment>
            </span>
          );
        },
      },
      {
        dataField: 'storeName',
        title: t(`storeName`),
        // className: 'd-none d-md-block',
        size: 20,
        formatter: (cellContent, row) => {
          return (
            <div>
              {cellContent}
            </div>
          );
        },
      },
      {
        dataField: 'socialSourceId',
        title: t("socialSource"),
        className: paramObj.publicReviews ? "" : "d-none",
        size: paramObj.publicReviews ? 10 : null,
        formatter: (cellContent, row) => {
          return <SocialSourceIcon site={cellContent} />
        }
      },
      {
        dataField: 'score',
        title: t("score"),
        size: 16,
        formatter: (cellContent, row) => {
          return <Score value={cellContent} />
        }
      },
    ],
    columnsMobile: [
      {
        dataField: '',
        // className: 'd-block d-md-none',
        size: 100,
        formatter: (cellContent, row) => {
          let imgsrc = row.reviewerProfilePhotoUrl;
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                // margin: 'auto'
              }}
              className="mobile-column"
            >
              <div className="mobile-image-container">
                <img src={imgsrc} className="rounded-circle" style={{ width: "45px" }} alt="" />
              </div>
              <div
                style={{ marginLeft: 15, fontSize: '0.8rem', width: "80%" }}
                className="mobile-info-container"
              >
                <h5 style={{ margin: 0 }}>{row.reviewerDisplayName}</h5>
                <span className="d-block" style={{ margin: 0 }}>{`${row.storeName}`}</span>
                {/* <span>{row.email}</span> */}
                <p style={{ margin: 0 }}>
                  {/* {`${t('registered')} `} */}
                  <Moment format="L" locale="es">
                    {row.date}
                  </Moment>{' '}
                  <Moment format="LT" locale="es">
                    {row.date}
                  </Moment>
                </p>
                {paramObj.publicReviews && <span className="d-block">Fuente: {SocialSourceNames[row.socialSourceId]}</span>}
                <span>{
                  (row.score ? <><Score value={row.score} size='s' /></> : '')
                }</span>
              </div>
            </div>
          );
        },
      },
    ],
    expandElement: (active, element, onRowChanged) => {
      return (active === true && <Review
        mode="inline"
        review={element}
        onRowChanged={onRowChanged}
        getReview={getReview}
        onReply={(comment) => postReply(element, comment)}
        onReport={(comment) => postReport(element, comment)}
        replyTemplates={paramObj.replyTemplates}
        // replyTemplates={[]}
        onSaveReplyTemplate={async () => { }}
        // onSaveReplyTemplate={_saveReplyTemplate}
        // admin={props.admin}
        selectedCompanyId={element.companyId}
        isActive={true}
      // isActive={checkIfStoreIsActive(element.storeId)}
      />)
    },
    elementKey: 'id',
    getData: (page, order, filters) => getFunnelReviewDetail(paramObj, page),
    dataPath: "data.data.items"

  },
});

const postReply = (review, comment) => {
  const promise = new Promise((resolve) => {
    reply(review.id, comment).then(() => {
      resolve('success');
    })
  })
  return promise;
}

const postReport = (review, comment) => {
  const promise = new Promise((resolve) => {
    report(review.id, comment).then(() => {
      resolve('success');
    })
  })
  return promise;
}

// const _saveReplyTemplate = (replyTemplate) => {
//   const promise = new Promise((resolve) => {
//     saveReplyTemplate(replyTemplate).then(response => {
//       const newTemplate = response.data.data
//       this.setState({
//         replyTemplates: [...this.state.replyTemplates, {
//           value: newTemplate.id.toString(),
//           text: newTemplate.name
//         }]
//       })
//       resolve('sucess')
//     })
//   })
//   return promise;
// }

// const _getReplyTemplates = async (company) => {
//   if (!company || !company.operations) return;
//   if (!company.operations.includes("REPLY_TEMPLATES__SELECT"))
//     return;
//   const response = await getReplyTemplates(company.id);
//   if (!response || !response.data || !response.data.data) return;
//   const templates = response.data.data;
//   const replyTemplates = templates.map(t => {
//     return { text: t.name, value: t.id };
//   });
//   return replyTemplates;
// }

export const getFunnelConfig = ({ stores, period, adminId, channel, mode, company, replyTemplates }) => {
  switch (mode) {
    case "users":
      return configs({ stores, period, adminId, channel, company, replyTemplates }).userModal;
    case "surveys":
      return configs({ stores, period, adminId, channel, company, replyTemplates, publicReviews: false }).surveysModal;
    case "reviews":
      return configs({ stores, period, adminId, channel, company, replyTemplates, publicReviews: true }).surveysModal;
    default:
      return configs({ stores, period, adminId, channel, company, replyTemplates }).userModal;
  }
}