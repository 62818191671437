export const translations = {
  title: {
    es: "Guía rápida de inicio",
    en: "Quick start guide",
  },
  subtitle: {
    es: "Conoce RAY rápidamente con estos simples pasos",
    en: "Get to know RAY quickly with these simple steps"
  },
  todotask: {
    doitnow: {
      en: 'Do it now',
      es: 'Realizar Tarea'
    },
    gonow: {
      en: "Go Now",
      es: "Ir ahora"
    },
    ignore: {
      es: "Ignorar tarea",
      en: "ignore"
    },
    unignore: {
      es: "Dejar de ignorar",
      en: "unignore"
    },
    completed: {
      en: "This task is completed.",
      es: "Esta tarea ya fué realizada pero puedes volver a hacerlo siempre que lo precises"
    },
    ignored: {
      es: "Esta tarea fué ignorada pero puedes activarla siempre que lo precises",
      en: "This task is ignored"
    },
    configure_stores: {
      title: {
        en: "Configure your pending stores",
        es: "Configura tus tiendas pendientes"
      },
      todo: {
        es: "Si tienes tiendas para dar de alta, no pierdas ni un minuto más configúralas en unos simples pasos para que ya comiencen a generar reseñas en Google",
        en: "If you have stores to register, don't waste another minute configure them in a few simple steps so that they start generating reviews on Google"
      },
      disabled: {
        en: 'All the licenses of your subscription are configured',
        es: 'Todas las licencias de tu subscripción estan configuradas'
      }
    },
    connect_stores: {
      title: {
        en: "Connect your stores",
        es: "Conecta tus tiendas"
      },
      todo: {
        es: "Si tus tiendas no están conectadas correctamente con Google no le permiten a tus clientes que dejen reseñas luego de que los encuestemos.<br>Además no podremos traerte la información a RAY para que veas todas las reseñas de forma centralizada.<br>¡Revisa las configuraciones para que todas tus tiendas estén conectadas!",
        en: 'If your stores are not properly connected with Google, they will not allow your customers to leave reviews after we survey them. <br> Also, we will not be able to bring the information to RAY so that you can see all the reviews centrally. <br> Check the settings so that all your stores are connected!'
      }
    },
    request_review: {
      title: {
        en: "Get your first review",
        es: "Obtén tu primer reseña"
      },
      todo: {
        en: "Haven't requested your first review request yet? It is a fundamental tool to know what your customers think and earn your stars on Google!",
        es: '¿Aún no has solicitaste tu primer pedido de reseña? Es una herramienta fundamental para saber qué opinan tus clientes y ganar tus estrellas en Google!'
      }
    },
    reply_review: {
      title: {
        en: "Reply reviews",
        es: "Responde reseñas"
      },
      disabled: {
        en: "You must get your first review first, clicking on the previous task, and then return over here 🙂.",
        es: "Primero debes obtener tu primer reseña haciendo click en la tarea anterior y luego vuelve por aquí 🙂."
      },
      todo: {
        en: "If you answer reviews you show that you value your customers and Google will position you better when someone searches for a business like yours.",
        es: "Si respondes reseñas demuestras que valoras a tus clientes y Google te posicionará mejor cuando alguien busque un negocio como el tuyo."
      }
    },
    invite_user: {
      title: {
        en: "Invite users",
        es: "Invita usuarios"
      },
      todo: {
        en: "Each employee you invite can boost 5-star reviews by up to 100%!",
        es: "Cada empleado que invites puede potenciar hasta un 100% la generación de reseñas 5 estrellas!"
      }
    },
    check_messages:{
      title: {
        es:"Respóndele a tus clientes a la brevedad",
        en: "Respond to your customers promptly"
      },
      todo:{
        es:"Mantente al día con tus clientes respondiendo en seguida sus dudas y consultas.",
        en:"Stay up-to-date with your customers by answering their questions and queries right away."
      }
    },
    connect_inbox:{
      title: {
        es:"Recibe las consultas de tus clientes",
        en: "Receive inquiries from your clients"
      },
      todo:{
        es:"Resuelve tus ventas de forma centralizada desde un único lugar, conecta todas tus tiendas a Facebook, WhatsApp y próximamente Instagram.",
        en:"Solve your sales centrally from a single place, connect all your stores to Facebook, WhatsApp and soon Instagram"
      }
    }
  }
}
