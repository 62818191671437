import React from 'react'
import { CollectionVisualizer } from 'botbit-ui-components'
import './ClientsAnalytics.scss'
import moment from 'moment'
import UserDetailsContainer from '../../../commons/components/users/details/UserDetailsContainer'
import { envConfig } from '../../../config/config'
import { useCompanyAvailableStores } from "../../../commons/utils/useCompanyAvalaibleStores";

const TRANSLATIONS_PREFIX = 'panels.campaigns.analytics.clientsData.'

export const ClientsAnalytics = ({ t, getData, title, isOpened, campaignId, period, eventSubType }) => {
  const eventSubTypesTranslationKeys = {
    "WALKTHROUGH": "returnedOn",
    "CLICKED": "clickedOn",
    "OPENED": "openedOn"
  }

  const columns = [
    {
      dataField: '',
      className: 'd-block d-md-none',
      formatter: (cellContent, row) => {
        const avatarImg = row.avatar && row.avatar !== "" ? row.avatar : 'noavatar.jpg';
        let imgsrc = `${envConfig.staticImgUrl}avatars/${avatarImg}`;
        return (
          <div className="mobile-column">
            <div className="mobile-image-container">
              <div className="avatarField"
                style={{ backgroundImage: `url(${imgsrc})` }}
              />
            </div>
            <div className="mobile-info-container">
              <span>{(row.name) ? row.name : ''} {(row.lastName) ? row.lastName : ''}</span>
              <span>{(row.email) ? row.email : ''}</span>
              <span>{t(`${TRANSLATIONS_PREFIX}${eventSubTypesTranslationKeys[eventSubType]}`)}{moment(row.eventTimestamp).format('D/M/YYYY HH:mm')}</span>
            </div>
          </div>

        );
      },
      size: 12
    },
    {
      dataField: 'avatar',
      title: t(`${TRANSLATIONS_PREFIX}avatar`),
      className: 'd-none d-md-block',
      formatter: (cellContent, row) => {
        const avatarImg = row.avatar && row.avatar !== "" ? row.avatar : 'noavatar.jpg';
        let imgsrc = `${envConfig.staticImgUrl}avatars/${avatarImg}`;
        return (
          <div className="avatarField"
            style={{ backgroundImage: `url(${imgsrc})` }}
          />
        );
      },
      size: 1
    },
    {
      dataField: 'name',
      size: 3,
      title: t(`${TRANSLATIONS_PREFIX}name`),
      className: 'd-none d-md-block',
      formatter: (cellContent, row) => {
        return (
          <div>
            {row.name} {row.lastName}
          </div>

        );
      },
    },
    {
      dataField: 'eventTimestamp',
      size: isOpened ? 3 : 1,
      title: t(`${TRANSLATIONS_PREFIX}timestamp`),
      className: 'd-none d-md-block',
      formatter: (cellContent, row) => {
        return (
          <div>
            {moment(cellContent).format('D/M/YYYY HH:mm')}
          </div>

        );
      },
    },
    {
      dataField: 'email',
      size: 4,
      title: t(`${TRANSLATIONS_PREFIX}mail`),
      className: 'd-none d-md-block',
      formatter: (cellContent, row) => {
        return (
          <div>
            {cellContent}
          </div>

        );
      },
    },
    !isOpened &&
    {
      dataField: 'storeName',
      size: 3,
      title: t(`${TRANSLATIONS_PREFIX}stores`),
      className: 'd-none d-md-block',
      formatter: (cellContent, row) => {
        return (
          <div>
            {cellContent}
          </div>

        );
      },
    },
  ]

  const companyStores = useCompanyAvailableStores();


  return (
    <div className="clientsAnalyticsCollVisContainer">
      <CollectionVisualizer
        getData={(page, order, filters) => getData(campaignId, period, page, eventSubType)}
        columns={columns}
        pagination={'button'}
        dataPath={'data'}
        title={title}
        rowKey={"userId"}
        expandRow={
          (active, row, onRowChanged) => {
            return (
              active === true &&
              <div className='pt-2 gray-bg'>
                <UserDetailsContainer selectedStores={companyStores} userId={row.userId} inline={false} />
              </div>
            )
          }
        }
      />
    </div>
  )
}