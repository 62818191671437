import React, { useEffect, useMemo, useState } from "react";
import {
  analyzePlaces,
  getPlacesByFlags as getPlaces,
  getSummary,
} from "../../../api/googleBusinessProfileApi";
import translate from "../../../config/translations/translate";
import { ImprovementsModal } from "./LocationCardContainer";
import "./styles.css";
import { useSelector } from "react-redux";
import { FirstConfigPageContainer } from "./FirstConfigPage";
import { LoadingPageContainer } from "./LoadingPage";
import { ErrorPageContainer } from "./ErrorPage";
import {
  Table,
  UxSelect,
  UxMetricDisplay,
  UxScoreBar,
} from "botbit-ui-components";
import "bootstrap/dist/js/bootstrap.bundle";
import { MDBBtn, MDBIcon, MDBInput } from "mdbreact";
import withEventTracking, { trackers } from "../../../config/withEventTracking";
import { Modal, ModalHeader, ModalBody } from "botbit-ui-components";
import TimeAgo from "../../../commons/components/utils/TimeAgo";
import SocialLocationPostContainer from "../SocialLocationPostContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faSearch } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { getProcessStatus } from "../../../api/processApi";

const buildCaption = (summary, t, openModal, openPostModal) => ({
  caption: {
    title: t("summary.captionTitle"), //"Mejora los siguientes indicadores para subir tu score!",
    elements: [
      {
        icon: "tag",
        value: summary.placesWithoutKeywords,
        description: t("summary.placesWithoutKeywords"), //"Locales sin Keywords",
        highlighted: {
          text: "string",
          color: "danger",
        },
        callToAction: {
          text: t("summary.seeVideo"),
          onclickHandler: openModal,
          type: "link",
        },
        totalCount: `/${summary.numberOfPlaces}`,
      },
      {
        icon: "sticky-note",
        value: summary.placesWithoutPosts,
        description: (
          <div>
            {t("summary.placesWithoutPosts")}
            <br />
            {t("summary.last15")}
          </div>
        ), //"Locales sin publicaciones",
        highlighted: {
          text: "string",
          color: "danger",
        },
        callToAction: {
          text: t("summary.makePost"),
          onclickHandler: openPostModal,
          type: "button",
        },
        totalCount: `/${summary.numberOfPlaces}`,
      },
      {
        icon: "camera",
        value: summary.placesWithoutPhotos,
        description: t("summary.placesWithoutPhotos"), //"Locales sin fotos",
        highlighted: {
          text: "string",
          color: "danger",
        },
        callToAction: {
          text: t("summary.seeVideo"),
          onclickHandler: openModal,
          type: "link",
        },
        totalCount: `/${summary.numberOfPlaces}`,
      },
      {
        icon: "phone-alt",
        value: summary.placesWithoutPhone,
        description: t("summary.placesWithoutPhone"), //"Locales sin teléfono",
        highlighted: {
          text: "string",
          color: "danger",
        },
        callToAction: {
          text: t("summary.seeVideo"),
          onclickHandler: openModal,
          type: "link",
        },
        totalCount: `/${summary.numberOfPlaces}`,
      },
      {
        icon: "globe",
        value: summary.placesWithoutWebsite,
        description: t("summary.placesWithoutWebsite"), //"Locales sin sitio web",
        highlighted: {
          text: "string",
          color: "danger",
        },
        callToAction: {
          text: t("summary.seeVideo"),
          onclickHandler: openModal,
          type: "link",
        },
        totalCount: `/${summary.numberOfPlaces}`,
      },
    ],
  },
});

const metricsConfiguration = (summary, t, openModal, openPostModal) => ({
  outstanding: {
    icon: "string",
    content: "React.Component",
    value: summary.score || 0,
    title: t("summary.currentScore"),
  },
  caption: buildCaption(summary, t, openModal, openPostModal).caption,
});

const BusinessProfilesTable = ({ t, trackEvent }) => {
  const selectedCompany = useSelector(
    (state) => state.application.selectedCompany
  );
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [filters, setFilters] = useState({});
  const [summary, setSummary] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isPostModalOpen, setIsPostModalOpen] = useState();
  const [lastAnalysisDate, setLastAnalysisDate] = useState();

  const filtersConfig = [
    { id: "withoutKeywords", type: "checkbox", label: t("withoutKeywords") },
    { id: "withoutPosts", type: "checkbox", label: t("withoutPosts") },
    { id: "withoutPhotos", type: "checkbox", label: t("withoutPhotos") },
    { id: "withoutPhone", type: "checkbox", label: t("withoutPhone") },
    { id: "withoutWebsite", type: "checkbox", label: t("withoutWebsite") },
    {
      id: "all",
      type: "checkbox",
      label: t("all"),
      onClick: (f) => ({}),
      checked: (f) => Object.keys(f).filter((k) => k !== "query").length === 0,
    },
  ];

  const columns = [
    {
      dataField: "score",
      title: t("score"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => <UxScoreBar value={cellContent} />,
      size: 10,
    },
    {
      dataField: "name",
      title: t("name"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => (
        <div className="mt-4">
          {cellContent} <br />
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.65619 0.945559C6.84902 0.762939 7.15098 0.762939 7.34381 0.945559C8.68832 2.21887 10.5025 2.99909 12.5 2.99909C12.5318 2.99909 12.5635 2.9989 12.5952 2.9985C12.8134 2.99579 13.0082 3.13496 13.0764 3.3423C13.3514 4.17881 13.5 5.07211 13.5 5.99912C13.5 9.96047 10.7906 13.2877 7.12463 14.2313C7.04288 14.2523 6.95713 14.2523 6.87537 14.2313C3.20937 13.2877 0.5 9.96047 0.5 5.99912C0.5 5.07211 0.648582 4.17881 0.92361 3.3423C0.99178 3.13496 1.18656 2.99579 1.40481 2.9985C1.43649 2.9989 1.46822 2.99909 1.5 2.99909C3.4975 2.99909 5.31168 2.21887 6.65619 0.945559ZM7 4.99908C7.27614 4.99908 7.5 5.22294 7.5 5.49908V7.99908C7.5 8.27523 7.27614 8.49908 7 8.49908C6.72386 8.49908 6.5 8.27523 6.5 7.99908V5.49908C6.5 5.22294 6.72386 4.99908 7 4.99908ZM7 9.49908C6.72386 9.49908 6.5 9.72294 6.5 9.99908V10.0041C6.5 10.2802 6.72386 10.5041 7 10.5041H7.005C7.28114 10.5041 7.505 10.2802 7.505 10.0041V9.99908C7.505 9.72294 7.28114 9.49908 7.005 9.49908H7Z" 
              fill={source.verified ? "#1EB8FF" : "gray"}/>
            </svg>  
            <span
              className={"ml-1 "+(source.verified ? "text-blue" : "text-gray")}
              style={{ fontWeight: 700}}
            >          
              {source.verified ? t("verified") : t("notVerified")}
            </span>
        </div>
      ),
      size: 15,
    },
    {
      dataField: "phone",
      title: t("phone"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        return (
          <CellFormatter
            content={source.phone || t("noPhone")}
            actionButton={{
              show: !source.phone,
              icon: { far: true, icon: "play-circle" },
              // color: "white",
              onclickHandler: () => setIsOpen(true),
              buttonText: t("add"),
            }}
          />
        );
      },
      size: 11,
    },
    {
      dataField: "website",
      title: t("website"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        // if (!source.website)
        let url;
        try {
          url =  cellContent ? new URL(cellContent) : undefined;
        } catch (error) {
          console.log(error);
        }
        const content = (
          <CellFormatter
            content={
              url ? (
                <a href={source.website} target="_blank">
                  {" "}
                  {url.hostname}{" "}
                </a>
              ) : t("noWebSite")
            }
            actionButton={{
              show:!url,
              icon: { far: true, icon: "play-circle" },
              // color: "white",
              onclickHandler: () => setIsOpen(true),
              buttonText: t("add"),
            }}
            tooltip={cellContent ? cellContent : undefined}
          />
        );
        return content;
        // return (
        //   <a href={source.website} target="_blank">
        //     {cellContent}
        //   </a>
        // );
      },
      size: 13,
    },
    {
      title: t("address"),
      dataField: "address",
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        return (
          <CellFormatter
            content={cellContent || t("noAddress")}
            actionButton={{
              show: !source.address,
              icon: { far: true, icon: "play-circle" },
              // color: "white",
              onclickHandler: () => setIsOpen(true),
              buttonText: t("add"),
            }}
          />
        );
      },
      size: 11,
    },
    {
      dataField: "postHistoryCount",
      title: t("postHistoryCount"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        return (
          <CellFormatter
            content={cellContent || t("noPosts")}
            actionButton={{
              show: true,
              // button: true,
              icon: { fas: true, icon: "plus-circle" },
              onclickHandler: () => setIsPostModalOpen(source.socialLocationId),
              buttonText: t("createPost"),
              color: "#2383E2",
            }}
          />
        );
      },
      size: 11,
    },
    {
      dataField: "photos",
      title: t("photos"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        return (
          <CellFormatter
            content={cellContent || t("noPhoto")}
            actionButton={{
              show: true,
              icon: { far: true, icon: "play-circle" },
              // color: "white",
              onclickHandler: () => setIsOpen(true),
              buttonText: t("add"),
            }}
          />
        );
      },
      size: 11,
    },
    {
      title: t("categories"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        return (
          <ImprovementsModal
            socialLocationId={source.socialLocationId}
            currentCategories={source.categories || []}
            t={t}
          />
        );
      },
      size: 15,
    },
    {
      title: "",
      formatter: (cellContent, source) => {
        return (
          <a
            rel="noopener noreferrer"
            href={`https://www.google.com/maps/place/?q=place_id:${source.placeId}`}
            target="_blank"
            className="m-auto text-dark"
          >
            <MDBIcon fas icon="external-link-alt" />
          </a>
        );
      },
      size: 3,
    },
  ];

  const emmit = (action) =>
    trackEvent(action.toLowerCase(), {}, false, [
      trackers.BACKEND,
      trackers.MIXPANEL,
    ]);

  React.useEffect(() => {
    if (loading || firstTime) return;
    emmit("GBP_audit_viewed");
    getSummary(selectedCompany.id).then((res) => {
      if (res.data.data) {
        setSummary(res.data.data);
      }
    });
  }, [loading, firstTime]);

  const analyze = async () => {
    setFirstTime(false);
    setLoading(true);
    setSummary(undefined);
  
    try {
      const response = await analyzePlaces(selectedCompany.id);
      const processId = response.data.data.id; 
      checkAnalyzeStatus(processId);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };
  
  const checkAnalyzeStatus = async (processId) => {
    try {
      const response = await getProcessStatus(processId);
      if (response.data.data) {
        const status = response.data.data.state;
  
        if (status === 'FINISHED_OK') {
          setLoading(false);
        } else if (status === 'RUNNING') {
          setTimeout(() => checkAnalyzeStatus(processId), 5000);
        } else {
          setError('Unexpected error: ' + status);
        }
      } else {
        setError('Invalid response');
      }
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };


  if (error) {
    return <ErrorPageContainer error={error} />;
  } else if (firstTime) {
    return <FirstConfigPageContainer analyze={analyze} />;
  } else if (loading) {
    return <LoadingPageContainer />;
  } else {
    const getTableData = (page, order, filters) =>
      getPlaces(page, order, {
        companyId: selectedCompany.id,
        ...filters,
      }).then((res) => {
        if (res.data.data) {
          if (!res.data.data.lastAnalysisDate) setFirstTime(true);
          else setLastAnalysisDate(res.data.data.lastAnalysisDate);
        }

        return res;
      });
    return (
      <div className="gbp-container">
        <div className="title">
          <div className="title-text">
            <img
              src="/static/img/gbp-icon.svg"
              alt="Icon"
              width="44"
              height="44"
            />
            &nbsp;&nbsp;
            <span
              style={{
                fontFamily: "Open Sans",
                fontSize: "28px",
                color: "1A1A1A",
              }}
            >
              {t("welcome")}
            </span>
            &nbsp;&nbsp;
            <span
              style={{
                fontFamily: "Open Sans",
                fontSize: "28px",
                color: "1A1A1A",
                fontWeight: "bold",
              }}
            >
              {t("title")}
            </span>
          </div>
          <div className="last-update">
            <span>
              {t("analyze")}
              <TimeAgo date={lastAnalysisDate}></TimeAgo>
            </span>
            <MDBBtn
              size="sm"
              onClick={() => analyze()}
              style={{ width: "50px", padding: "10px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#ffffff"
                  d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"
                />
              </svg>
            </MDBBtn>
          </div>
        </div>
        {summary && (
          <>
            <UxMetricDisplay
              data={metricsConfiguration(
                summary,
                t,
                () => setIsOpen(true),
                () => setIsPostModalOpen(-1)
              )}
            />
            <VideoModal
              description={t("summary.videoDescription")}
              title={t("summary.videoTitle")}
              subtitle={t("summary.videoSubtitle")}
              isOpen={isOpen}
              toggle={() => setIsOpen(!isOpen)}
              url={"https://www.youtube.com/watch?v=Qx8ElTKfE-g"}
            />
            <PostModal
              isOpen={isPostModalOpen !== undefined}
              toggle={() => setIsPostModalOpen(undefined)}
              title={t("createPost")}
              socialLocationId={isPostModalOpen}
            />
          </>
        )}
        <div className="my-5">
          <Filters
            onFilterChange={setFilters}
            filters={filtersConfig}
            queryInputLabel={t("searchLabel")}
          />
        </div>
        <Table
          pagination={false}
          getData={getTableData}
          filterValues={filters}
          title={t("title")}
          subTitle={<p>{t("subtitle")}</p>}
          columns={columns}
          showMobileColumnTitles={false}
        />
      </div>
    );
  }
};

const Filters = ({
  onFilterChange,
  filters,
  showQueryString = true,
  queryInputLabel,
}) => {
  const [filtersState, setFiltersState] = useState({});

  useEffect(() => {
    onFilterChange(filtersState);
  }, [filtersState]);

  return (
    <div className="row justify-content-start ml-1">
      {filters.map((filter) => {
        switch (filter.type) {
          case "select":
            return (
              <div className="col-12 col-md-3">
                <UxSelect
                  options={filter.options}
                  label={filter.label}
                  onChange={(a) => {
                    const newFilters = { ...filtersState };
                    newFilters[filter.id] = a;
                    setFiltersState(newFilters);
                  }}
                ></UxSelect>
              </div>
            );
          case "checkbox":
            return (
              <div className="col-12 col-md-1 px-0">
                <span>
                  <input
                    type="checkbox"
                    checked={
                      filter.checked !== undefined
                        ? filter.checked(filtersState)
                        : filtersState[filter.id]
                    }
                    className="m-1"
                    style={{
                      position: "unset",
                      pointerEvents: "unset",
                      opacity: 1,
                    }}
                    onChange={(e) => {
                      if (filter.onClick) {
                        setFiltersState(filter.onClick(filtersState) || {});
                        return;
                      }
                      const newFilters = { ...filtersState };
                      newFilters[filter.id] = e.target.checked;
                      setFiltersState(newFilters);
                    }}
                  />
                  <span className="align-top">{filter.label}</span>
                </span>
              </div>
            );
          default:
            return null;
        }
      })}
      {showQueryString && (
        <div
          class="form-outline col-12 col-md-3 offset-md-3"
          data-mdb-input-init
        >
          {/* <i class="fas fa-magnifying-glass"></i> */}
          <span class="input-group">
  <span class="input-group-text bg-white" id="basic-addon1"><FontAwesomeIcon icon={faSearch}/></span>
          
          <input
            type="text"
            id="formControlLg"
            class="form-control form-control-lg h-100"
            onChange={(e) => {
              const newFilters = { ...filtersState };
              newFilters.query = e.target.value;
              setFiltersState(newFilters);
            }}
            placeholder={queryInputLabel || "Search"}
          />
</span>
          {/* <MDBInput
            getValue={(e) => {
              const newFilters = { ...filtersState };
              newFilters.query = e;
              setFiltersState(newFilters);
            }}
            outline
            size="sm"
          >
            <i className="fas fa-exclamation-circle"></i>
          </MDBInput> */}
        </div>
      )}
    </div>
  );
};

BusinessProfilesTable.translations = {
  welcome: {
    en: "Optimize your ",
    es: "Optimiza tus ",
  },
  title: {
    en: "Google Business Profiles",
    es: "Google Business Profiles",
  },
  subtitle: {
    en: "Welcome to our new Google Business Profiles report!",
    es: "¡Bienvenido a nuestro nuevo reporte de Perfiles de Google My Business!",
  },
  searchLabel: {
    en: "Search",
    es: "Buscar por nombre, provincia, país",
  },
  searchPlaceholder: {
    en: "Search location",
    es: "Buscar ubicación",
  },
  verifiedInfo: {
    en: "This profile is verified by Google",
    es: "Este perfil está verificado por Google",
  },
  noWebSite: {
    en: "No website",
    es: "Sin sitio web",
  },
  reviews: {
    en: "Rate",
    es: "Rate",
  },
  photos: {
    en: "photos",
    es: "fotos",
  },
  categories: {
    en: "Categories",
    es: "Categorías",
  },
  howToEliminateProfile: {
    en: "How to eliminate this profile",
    es: "Cómo eliminar este perfil",
  },
  noCategories: {
    en: "No categories",
    es: "Sin categorías",
  },
  noPhone: {
    en: "No phone",
    es: "Sin teléfono",
  },
  name: {
    en: "Title",
    es: "Título",
  },
  address: {
    en: "Address",
    es: "Dirección",
  },
  complete: {
    en: "Complete",
    es: "Completa",
  },
  noPhoto: {
    en: "No photo!",
    es: "¡No tienes fotos!",
  },
  duplicated: {
    en: "Duplicated",
    es: "Duplicado",
  },
  profileSelectLabel: {
    en: "Profiles",
    es: "Perfiles",
  },
  descriptionStatusLabel: {
    en: "Description",
    es: "Descripción",
  },
  incomplete: {
    en: "Incomplete",
    es: "Incompleta",
  },
  toExtensive: {
    en: "To extensive",
    es: "Muy larga",
  },
  none: {
    en: "None",
    es: "No tiene",
  },
  categoriesStatusLabel: {
    en: "Categories",
    es: "Categorías",
  },
  improvements: {
    en: "Category improvements",
    es: "Análisis de categorías",
  },
  improvementsSubtitle: {
    en: "Based on the queries your customers perform to find your location, we recommend changing the categories of your Google Business Profile as follows:",
    es: "Según las consultas que realizan sus clientes para encontrar su ubicación, le recomendamos cambiar las categorías de su perfil comercial de Google de la siguiente manera",
  },
  currentCategories: {
    en: "Current",
    es: "Actuales",
  },
  recomendation: {
    en: "Recommendation",
    es: "Recomendadas",
  },
  seeTutorial: {
    en: "See tutorial",
    es: "Ver tutorial",
  },
  categoriesMessage: {
    "text-danger": {
      es: "Necesitas agregar palabras clave!",
      en: "You need to add categories!",
    },
    "text-warning": {
      es: "Tus categorias pueden ser optimizadas",
      en: "Categories can be optimized",
    },
    "text-success": {
      es: "Tus palabras clave son correctas",
      en: "Your Categories are correct",
    },
  },
  seeImprovements: {
    es: "Sugeridas",
    en: "Suggested",
  },
  assignedKeywords: {
    en: "Categories",
    es: "Categorías",
  },
  seePlace: {
    es: "Ver página",
    en: "See place",
  },
  addAddress: {
    es: "Agrega una dirección",
    en: "Add an address",
  },
  primary: {
    es: "Primaria",
    en: "Primary",
  },
  secondary: {
    es: "Secundarias",
    en: "Secondary",
  },
  improvementsfooter: {
    en: "To improve",
    es: "Para mejorar",
  },
  improvementsfooterSort: {
    en: "# . update the order",
    es: "# . actualiza el orden",
  },
  improvementsfooterAdd: {
    en: "Add new categories",
    es: "Agregar nuevas categorias",
  },
  analyze: {
    en: "Last update  ",
    es: "Actualizados ",
  },
  directionStatus: {
    INCOMPLETE: {
      en: "Incomplete address",
      es: "Dirección Incompleta",
    },
    MATCHED: {
      en: "Correct address",
      es: "Dirección correcta",
    },
    MISMATCHED: {
      en: "Mismatched direction",
      es: "Dirección no coincidente",
    },
  },
  summary: {
    captionTitle: {
      en: "Improve the following indicators to increase your score!",
      es: "Mejora los siguientes indicadores para subir tu score!",
    },
    placesWithoutKeywords: {
      en: "Locations with incorrect categories",
      es: "Locales con categorías incorrectas",
    },
    placesWithoutPosts: {
      en: "Locations without posts",
      es: "Locales sin Google Posts",
    },
    last15: {
      en: "(last 15 days)",
      es: "(últimos 15 días)",
    },
    placesWithoutPhotos: {
      en: "Locations without photos",
      es: "Locales sin fotos",
    },
    placesWithoutPhone: {
      en: "Locations without phone",
      es: "Locales sin teléfono",
    },
    placesWithoutWebsite: {
      en: "Locations without website",
      es: "Locales sin sitio web",
    },
    currentScore: {
      en: "Your current score is:",
      es: "Tu score actual es:",
    },
    seeVideo: {
      en: "Play video",
      es: "Ver video",
    },
    videoTitle: {
      en: "How to improve your Google My Business profile?",
      es: "¿Cómo mejorar tu perfil de Google My Business?",
    },
    videoSubtitle: {
      en: "Discover how to improve your Google My Business profile",
      es: "Descubre cómo mejorar tu perfil de Google My Business",
    },
    videoDescription: {
      en: "In this video we show you how to improve your Google My Business profile",
      es: "En este video te mostramos cómo mejorar tu perfil de Google My Business",
    },
    makePost: {
      en: "+ Post",
      es: "+ Postear",
    },
  },
  postHistoryCount: {
    en: "Posts",
    es: "Posts",
  },
  phone: {
    en: "Phone",
    es: "Teléfono",
  },
  notVerified: { es: "Sin verificar", en: "Unverified" },
  verified: { es: "Verificada", en: "Verified" },
  withoutKeywords: { es: "Categorías", en: "Categories" },
  withoutPosts: { es: "Google Posts", en: "Google Posts" },
  withoutPhotos: { es: "Fotos", en: "Photos" },
  withoutPhone: { es: "Teléfono", en: "Phone" },
  withoutWebsite: { es: "Website ", en: "Website" },
  all: { es: "Ver Todos ", en: "All" },
  add: { es: "Agregar", en: "Add" },
  createPost: { es: "Crear post", en: "Create post" },
  noPosts: { es: "¡No tienes posts!", en: "No posts!" },
};

const VideoModal = ({ isOpen, toggle, title, subtitle, description, url }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Qx8ElTKfE-g?si=nfr9e32wx-bb4LDY&amp;start=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <h4>{subtitle}</h4>
        <p>{description}</p>
      </ModalBody>
    </Modal>
  );
};

const PostModal = ({ isOpen, toggle, socialLocationId }) => {
  return (
    <SocialLocationPostContainer
      modal={{
        isOpen,
        toggle,
      }}
      socialLocationId={socialLocationId}
    />
  );
};

const CellFormatter = ({ content, actionButton, tooltip }) => {
  const tpid = useMemo(() => Math.random().toString(), []);
  return (
    <div className="mt-4 d-inline-block  text-truncate">
      <ReactTooltip id={tpid} effect="solid">
        <span>{tooltip ? tooltip : content}</span>
      </ReactTooltip>
      <span data-tip data-for={tpid}>
        {content}
      </span>
      {actionButton.show && (
        <div>
          <div
            className={
              actionButton.button
                ? "metric-button text-white "
                : "metric-call-to-action"
            }
            onClick={actionButton.onclickHandler}
            style={{ color: actionButton.color || "#222" }}
          >
            <>
              <MDBIcon
                fixed
                iconType={actionButton.iconType || "solid"}
                size="1x"
                // className={actionButton.className || " text-dark"}
                {...actionButton.icon}
              />
              <div>{actionButton.buttonText}</div>
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export const BusinessProfilesTableContainer = withEventTracking(
  translate(BusinessProfilesTable)
);
