import React, { Component } from "react";
import { translate } from "react-i18next";
import { getConnectedStores } from '../../api/feedbackApi';
import ConnectedStores from "./ConnectedStores";
import { connect } from 'react-redux';

class ConnectedStoreContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (<>
            {<ConnectedStores
                t={this.props.t}
                companies={this.state.companies}
                getConnectedStores={getConnectedStores}
                availableCompanies={this.props.availableCompanies}
                selectedCompany={this.props.selectedCompany}
            />}
        </>)
    }
    
}

function mapStateToProps(state) {
	return {
        availableCompanies: state.application.availableCompanies,
        selectedCompany: state.application.selectedCompany
	}
}

export default connect(mapStateToProps)(translate('common')(ConnectedStoreContainer))