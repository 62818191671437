import { Formik } from "formik";
import React from "react";
import translate from "../../config/translations/translate.js";
import "./styles.css";
import { SocialLocationPostCallToActionType } from "./SocialLocationPostCallToActionType";
import { offerValidationSchema } from "./validations.js";
import { offerFormTtranslations } from "./translations.js";
import { FormikPostImageField } from "./ImageField.js";
import { FormikDatePicker } from "./DatePicker.js";
import { Text, TextArea, Select } from "./Inputs.js";
import { showCallToActionUrl } from "./showCallToActionUrl.js";

const actionTypes = Object.values(SocialLocationPostCallToActionType);

const SocialLocationPostOfferForm = ({
  t,
  socialLocationPost,
  onSubmit,
  images,
  formRef,
}) => {
  const validationSchema = offerValidationSchema(t);

  return (
    <div data-testid="SocialLocationPostUpdateForm">
      <Formik
        ref={formRef}
        initialValues={{ ...socialLocationPost }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ errors, handleSubmit, values }) => {
          return (
            <form
              data-testid="SocialLocationPostUpdateFormForm"
              onSubmit={handleSubmit}
            >
              <h4>{t("panels.socialLocationPost.form.titles.addImage")}</h4>
              <div className="row">
                <div className="col-md-4">
                  <FormikPostImageField
                    label={t(
                      "panels.socialLocationPost.form.label.media.sourceUrl"
                    )}
                    images={images}
                    errors={errors.media}
                    t={t}
                  />
                </div>
                <div className="col-md-8">
                  <Text
                    name="event.title"
                    label={t("panels.socialLocationPost.form.label.title")}
                    errors={errors.event && errors.event.title}
                  />
                  <TextArea
                    name="summary"
                    label={t("panels.socialLocationPost.form.label.summary")}
                    errors={errors.summary}
                  />
                  <Select
                    name="callToAction.actionType"
                    options={actionTypes.map((o) => ({
                      text: t(o),
                      value: o,
                    }))}
                    label={t(
                      "panels.socialLocationPost.form.label.callToAction.actionType"
                    )}
                    errors={
                      errors.callToAction && errors.callToAction.actionType
                    }
                    className="w-50 ml-auto"
                  />
                  {showCallToActionUrl(values) && (
                    <Text
                      info={t(`utmInfo`)}
                      name="callToAction.url"
                      label={t(
                        "panels.socialLocationPost.form.label.callToAction.url"
                      )}
                      errors={errors.callToAction && errors.callToAction.url}
                      prefix={'https://'}
                      />
                  )}
                </div>
              </div>
              <h4>{t("panels.socialLocationPost.form.titles.postDetails")}</h4>
              <h6>{t("panels.socialLocationPost.form.titles.offerDate")}</h6>
              <div className="row form-group">
                <div className="col-12 col-md-4">
                  <FormikDatePicker
                    name="event.schedule.startDate"
                    label={t(`panels.socialLocationPost.form.label.startDate`)}
                    errors={
                      errors.event &&
                      errors.event.schedule &&
                      errors.event.schedule.startDate
                    }
                  />
                </div>
                <div className="col-12 col-md-4">
                  <FormikDatePicker
                    name="event.schedule.endDate"
                    label={t(`panels.socialLocationPost.form.label.endDate`)}
                    errors={
                      errors.event &&
                      errors.event.schedule &&
                      errors.event.schedule.endDate
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h6>{t("panels.socialLocationPost.form.titles.termsConditions")}</h6>

                  <Text
                    name="offer.termsConditions"
                    label={t(
                      "panels.socialLocationPost.form.label.termsConditions"
                    )}
                    errors={errors.offer && errors.offer.termsConditions}
                  />
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-6">
                      <h6>{t("panels.socialLocationPost.form.titles.couponCode")}</h6>
                      <Text
                        name="offer.couponCode"
                        label={t(
                          "panels.socialLocationPost.form.label.couponCode"
                        )}
                        errors={errors.offer && errors.offer.couponCode}
                      />
                    </div>
                    <div className="col-md-6">
                      <h6>{t("panels.socialLocationPost.form.titles.redemLink")}</h6>
                      <Text
                        name="offer.redeemOnlineUrl"
                        label={t(
                          "panels.socialLocationPost.form.label.redeemOnlineUrl"
                        )}
                        errors={errors.offer && errors.offer.redeemOnlineUrl}
                        prefix="https://"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

SocialLocationPostOfferForm.translations = offerFormTtranslations;

export default translate(SocialLocationPostOfferForm);
