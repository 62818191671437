import React from 'react'
import { Field } from 'formik';
import { InputWithTags } from "botbit-ui-components";
import "./CampaignSubject.css";

const TRANSLATIONS_PREFIX = "panels.campaigns.minimalEditor."

export default class CampaignSubject extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <div className="campaignSubject">
                <span className="inputLabel d-inline-block float-left">
                    {this.props.t(TRANSLATIONS_PREFIX + "subject")}
                </span>
                <Field
                    name={"campaign.subject"}
                    render={(props) => {
                        return !this.props.isEditing ?
                            <span className="d-inline-block font-weight-normal inputSpan">{props.field.value}</span> :
                            <InputWithTags
                                withValidation
                                tags={["Tienda", "Nombre", "benefitMessage"]}
                                initialInputValue={props.field.value}
                                onChange={(newVal, error) => {
                                    if (props.field.value !== newVal)
                                        props.form.setFieldValue('campaign.campaignSubjectIsValid', (error === null))
                                    props.form.setFieldValue(props.field.name, newVal);
                                }
                                } />
                    }
                    }
                />
            </div>
        )
    }
}