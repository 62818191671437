export const translations = {
	qrcode: {
		es: "Código QR",
		en: "QR Code"
	},
	editCompany: {
		es: "Editar empresa",
		en: "Edit Company"
	},
	addStore: {
		es: "Agregar Tienda",
		en: "Add Store"
	},
	seeStores: {
		es: "Ver tiendas",
		en: "See stores"
	},
	deleteCompany: {
		es: "Borrar empresa",
		en: "Delete company"
	},
	confirmation: {
		es: "Confirmación",
		en: "Confirmation"
	},
	deleteTheCompany: {
		es: "Eliminar la empresa: ",
		en: "Delete the company: "
	},
	confirm: {
		es: "Confirmar",
		en: "Confirm"
	},
	deleted: {
		es: "¡Eliminada!",
		en: "Deleted!"
	},
	deletedText: {
		es: "La empresa ha sido eliminada.",
		en: "The company has been removed"
	},
	title: {
		es: "Empresa",
		en: "Company"
	},
	createCompany: {
		es: "Crear Empresa",
		en: "Add Company"
	},
	basicData: {
		es: "Datos Básicos",
		en: "Basic Data"
	},
	brand: {
		es: "Marca",
		en: "Brand"
	},
	name: {
		es: "Nombre",
		en: "Name"
	},
	language: {
		es: "Idioma",
		en: "Language"
	},
	country: {
		es: "País",
		en: "Country"
	},
	category: {
		es: "Categoría",
		en: "Category"
	},
	segment: {
		es: "Segmento",
		en: "Segment"
	},
	campaignDeliveryData: {
		es: "Datos para envíos de Campaña",
		en: "Campaign Delivery Data"
	},
	senderName: {
		es: "Nombre del Remitente",
		en: "Sender's Name"
	},
	fromEmail: {
		es: "Mail de envío",
		en: "Sender's Mail"
	},
	replyTo: {
		es: "Mail de respuestas",
		en: "Reply email"
	},
	socialNetworks: {
		es: "Redes Sociales",
		en: "Social Networks"
	},
	otherData: {
		es: "Otros Datos",
		en: "Other Data"
	},
	manualRegistration: {
		es: "Registro manual",
		en: "Manual registration"
	},
	bgForMobile: {
		es: "Background para Mobile",
		en: "Background for Mobile"
	},
	bgForDesktop: {
		es: "Background para Desktop",
		en: "Background for Desktop"
	},
	urlPrefix: {
		es: "Prefijo de la URL",
		en: "URL prefix"
	},
	hotbotId: {
		es: "ID de Hotbot",
		en: "Hotbot ID"
	},
	npsScoreScale: {
		label: {
			es: "Escala de puntaje de NPS",
			en: "NPS score scale"
		},
		STARS: {
			es: "5 Estrellas",
			en: "5 Stars"
		},
		NUMBERS: {
			es: "0 a 10",
			en: "0 to 10"
		},
		null: {
			es: "--",
			en: "--",
		}
	},
	showCustomQuestionsWhenScoreIsLessOrEqualThan: {
		label: {
			es: "Mostrar preguntas personalizadas cuando el puntaje es",
			en: "Show custom questions when score is"
		},
		always: {
			en: "Always show",
			es: "Mostrar siempre"
		},
		never: {
			en: 'Never show',
			es: "No mostrar nunca"
		},
		less: {
			en: 'or less',
			es: 'o menos'
		}
	},
	askUserDataForRegistration: {
		label: {
			en: 'Ask user data for manual Registration',
			es: 'Preguntar datos de usuario para registro manual'
		},
		true: {
			en: 'Yes',
			es: 'Si'
		},
		false: {
			en: 'No',
			es: 'No'
		}
	},
	customNpsQuestion: {
		es: "Pregunta NPS",
		en: "NPS Question"
	},
	minDaysBetweenSameUserFeedbacks: {
		es: "Mínimo de dias entre reseñas del mismo usuario",
		en: "Minimum days between same user reviews"
	},
	registrationBgColor: {
		es: "Color de fondo",
		en: "Background color"
	},
	storeSelectorLabel: {
		es: "Mensaje de selección de tienda",
		en: "Store selection message"
	}
}