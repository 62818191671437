import axiosClient from './axiosClient'

export const getEvents = (page, filters) => {
	var params = "";
	if (filters.status && filters.status[0] && filters.status[0] !== "ALL")
		params="&status=" + filters.status;
	if (filters.eventType && filters.eventType[0] && filters.eventType[0] !== "ALL") {
		params = params + "&eventType=" + filters.eventType;
	}
    return axiosClient.get(`/salesforce?pageNumber=${page}${params}`);
}

export const createStoreFromEvent = (eventId, companyId) => {
	var companyParam = "";
	if (companyId) {
		companyParam = "?companyId=" + companyId;
	}
    return axiosClient.post(`/salesforce/events/${eventId}/create${companyParam}`);
}