export const translations = {
    openChats: {
        es: "Abiertas",
        en: "Open"
    },
    closedChats: {
        es: "Cerradas",
        en: "Closed"
    },
    agenda: {
        es: "Agenda",
        en: "Agenda"
    },
    order: {
        es: "Orden",
        en: "Order"
    },
    newer: {
        es: "Más nuevos",
        en: "Newer"
    },
    older: {
        es: "Más viejos",
        en: "Older"
    },
    store: {
        es: "Tienda",
        en: "Store"
    },
    conversationWith: {
        es: "Conversaciones de la tienda:",
        en: "Conversations from store:"
    },
    conversationWithMobile: {
        es: "Conversaciones en:",
        en: "Conversations in:"
    },
    viewedFor: {
        es: "Visto por:",
        en: "Viewed for:"
    },
    stateChangedTo: {
        es: "cambió el estado de la conversación a",
        en: "change the conversation status to"
    },
    assignmentTo: {
        es: "{{senderAdmin}} asignó la conversación a {{targetAdmin}}",
        en: "{{senderAdmin}} has assigned the conversation to {{targetAdmin}}"
    },
    unassignmentTo: {
        es: "{{senderAdmin}} desasignó la conversación",
        en: "{{senderAdmin}} has unassigned the conversation"
    },
    assignment: {
        es: "Asignación",
        en: "Assignment"
    },
    unassignment: {
        es: "Desasignación",
        en: "Unassignment"
    },
    open: {
        es: "abierta",
        en: "open"
    },
    closed: {
        es: "cerrada",
        en: "closed"
    },
    stateChange: {
        es: "Cambio de estado",
        en: "State change"
    },
    yesterday: {
        es: "Ayer",
        en: "Yesterday"
    },
    emptyChatTitle: {
        es: "No olvides responderle a tus clientes",
        en: "Don't forget to answering your customers"
    },
    emptyChatSubtitle: {
        es: "Elige una conversación del menú de la izquierda o comienza una nueva.",
        en: "Pick your conversations from the left menu or start a new one!"
    },
    automaticMessage: {
        es: "Mensaje automático",
        en: "Automatic message"
    },
    messageFrom: {
        es: "Mensaje de",
        en: "Message from"
    },
    documentReceived: {
        in: {
            es: "Documento recibido",
            en: "Document received"
        },
        out: {
            es: "Documento enviado",
            en: "Document sent"
        }
    },
    newTemplate: {
        es: "Nueva plantilla",
        en: "New template"
    },
    enterText: {
        es: "Escribe algo",
        en: "Enter some text"
    },
    onlyTemplates: {
        WHATSAPP: {
            es: "Solo puedes enviar plantillas aprobadas por Whatsapp hasta que el cliente responda",
            en: "You only can send templates approved by Whatsapp until the customer send you a response"
        },
        FACEBOOK_MESSENGER: {
            es: "Solo puedes enviar mensajes luego de que el cliente inicia una conversación",
            en: "You only can send messages after the customer starts a conversation"
        },
        INSTAGRAM: {
            es: "Solo puedes enviar mensajes luego de que el cliente inicia una conversación",
            en: "You only can send messages after the customer starts a conversation"
        }
    },
    emojis: {
        es: "Emojis",
        en: "Emojis"
    },
    templates: {
        es: "Plantillas",
        en: "Templates"
    },
    attach: {
        es: "Adjuntar",
        en: "Attach"
    },
    reviewRequest: {
        es: "Solicitar Reseña",
        en: "Review Request"
    },
    unsupportedMessage:{
        es: "Archivo adjunto no soportado",
        en: "Not supported attachment"
    },
    assign: {
        es: "Asignar administrador",
        en: "Assign administrator"
    },
    assignComment: {
        es: "Comentario",
        en: "Comment"
    },
    assignCommentInfo: {
        es: "Este comentario es interno y solo lo verán tus compañeros.",
        en: "This comment is internal and will only be seen by your colleagues."
    },
    assignNotifyUser: {
        es: "Notificar al cliente quién lo atenderá.",
        en: "Notify customer"
    },
    assignConfirm: {
        es: "Asignar",
        en: "Assign"
    },
    unassignConfirm: {
        es: "Desasignar",
        en: "Unassign"
    },
    assignOtherAdministrator: {
        es:"Asignar a otra persona",
        en:"Assign to other"
    },
    assignAdministrator:{
        es:"Asignar conversación",
        en:"Assign conversation"
    },
    assignedAdministrator:{
        es:"Asignado a: ",
        en:"Assigned to: "
    },
    automaticUnassignment:{
        es: "La conversación fue desasignada.",
        en: "The conversation was unassigned."
    },
    issueMessage:{
        es: "{{senderAdmin}} marcó la conversación como {{type}}",
        en: "{{senderAdmin}} marked the conversation as {{type}}"
    },
    issueUpdate:{
        es: "Se marcó la conversación",
        en: "The conversation was marked"
    },
    operatorsSelectPlaceholder:{
        es:"Seleccione un operador",
        en: "Select an operator"
    },
    conversationFilters: {
        showAll: {
            es: "Cualquiera",
            en: "All"
        },
        showAssignedToMe: {
            es: "Mi",
            en: "Me"
        },
        showUnassigned: {
            es: "Nadie",
            en: "Unassigned"
        },
        allTypes: {
            es: "Motivos",
            en: "Types"
        }, 
        allStates: {
            es: "Estados",
            en: "States"
        },
        userName: {
            es: "Nombre cliente",
            en: "Customer's name"
        },
        userPhone: {
            es: "Teléfono cliente",
            en: "Customer's phone"
        },
        assignedTo: {
            es: "Asignada a",
            en: "Assigned to"
        },
        conversationState: {
            placeHolder: {
                es: "Conversaciones",
                en: "Conversations"
            },
            open: {
                es: "Abiertas",
                en: "Open"
            },
            closed: {
                es: "Cerradas",
                en: "Closed"
            }
        }
    },
    conversationActions: {
        new: {
            es: "Nueva",
            en: "New"
        }
    },
    maxUploadLimit: {
        title: {
            es: "Tamaño máximo excedido",
            en: "Maximum size exceeded"
        },
        content: {
            es: "El tamaño del archivo adjunto no puede exceder 25 megabytes",
            en: "Attachment size cannot exceed 25 megabytes"
        }
    }
}
