import { MDBBtn, MDBIcon } from 'mdbreact';
import React from 'react';
import useWindowDimensions from '../../commons/utils/useWindowDimensions';

const FunnelDetailCards = ({ title, icon, substring, btnText, onButtonClik, children, showButton }) => {
    const { isMedium } = useWindowDimensions()
    return (
        <div className="funnel-detail-card">
            <span className="title"><MDBIcon icon={icon} /> {title}</span>
            {children}
            <span className="substring">{substring}</span>
            {showButton && <MDBBtn size={isMedium ? "sm" : undefined} color="secondary" onClick={onButtonClik} outline>{btnText}</MDBBtn>}
        </div>
    );
}

export default FunnelDetailCards;