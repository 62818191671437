import React, { Component } from "react";
import PropTypes, {object, oneOf} from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import './table.css';
import filterFactory from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function formatter(column, colIndex, { sortElement, filterElement }) {
    return (
		<HeaderFormatter sortElement={sortElement} filterElement={filterElement} text={column.text}/>
	);
  }

class HeaderFormatter extends Component {

	constructor(props){
		super(props);
		this.state={showFilter:false}
	}

	toggle = (event) => {
		event.stopPropagation();
		this.setState({showFilter:!this.state.showFilter});
	}

	render(){
		const {filterElement, text, sortElement}= this.props;
		if (!filterElement && !sortElement){
			return (
				<div>
					{ text }
				</div>
			);
		}
		const filterClass = this.state.showFilter ? 'd-block mr-2 my-auto' : 'd-none';
		const textClass =  !this.state.showFilter ? 'd-block my-auto mr-2' : 'd-none';
		const btnClass =  !this.state.showFilter ? 'btn btn-xs btn-white mr-auto my-auto' : 'btn btn-xs btn-success my-auto mr-auto';
	return (
			<div className='d-inline-flex'>
				<SortCaret order={sortElement.props.order}/>
				{
					filterElement !== undefined &&
						(
							<div className={`${filterClass}`}>
								{ filterElement }
							</div>
						)
				}
				<div  className={`${textClass}`}>
					{ text }
				</div>
				{
					filterElement !== undefined &&
						(
							<button onClick={this.toggle} className={btnClass}><FontAwesomeIcon icon='search'/></button>
						)
				}
			</div>
		);

	}
}

const SortCaret = ({order}) => {
	var iconName;
	switch (order){
		case "asc":
			iconName="sort-up";
			break;
		case "desc":
			iconName="sort-down";
			break;
		default:
			iconName="sort";
	}
	return (
	<div className="my-auto mr-2">
		<FontAwesomeIcon icon={iconName}></FontAwesomeIcon>
	</div>
	)
}


class Table extends Component {

    constructor(props){
        super(props);
        this.state={
            columns: props.columns.map(
                (col)=>(
                    {
                        ...col,
                        attrs:{'data-label':col.text !== '' ? col.text+':' : ''},
                        headerFormatter: formatter,
						headerClasses: col.headerClasses + " align-bottom"
                    }
                )
            )
        }
		// scroll infinito
		// TODO mantener en el estado un booleano hasMoreItiems para decidir si llamar al servicio
		//window.onscroll = () => {
		//if (
		//window.innerHeight + document.documentElement.scrollTop
		//=== document.getElementById("wrapper").offsetHeight 
		//) {
		//this.props.getPage(this.state);
		//}
		//};
	}
	
	handleTableChange = (type, { sortField, sortOrder,filters }) => {
		this.props.handleTableChange(type, { sortField, sortOrder,filters });
		this.setState({ sortField: sortField, sortOrder: sortOrder, filters: filters });
	}

    render = () => {
        return (
            <div className='table-wrapper'>
                <BootstrapTable 
					remote ={ { filter: true, sort:true } }
					keyField={this.props.keyField}
					data={this.props.data}
					onTableChange={this.handleTableChange}
					filter={ filterFactory() }
					columns={this.state.columns}
					bordered={false}
					classes={`cards-list cards-list-${this.props.breackpointToCard}`}
					rowClasses={`card-${this.props.breackpointToCard}`}
					expandRow={ this.props.expandRow }
					rowStyle={ { cursor: 'pointer' } }
                />
                {
                    this.props.getPage !== undefined &&
						this.props.data.length !== 0 &&
                    (
                        <button
                        className='btn btn-white'
                        onClick={(event) => {
                            this.props.getPage(this.state);
                        }}
                        >
							Ver más
							<FontAwesomeIcon className='ml-2' icon="arrow-circle-right" />
                        </button>
                    )
                }
            </div>
        )

    };
}

Table.propTypes = {
    columns : PropTypes.arrayOf(object).isRequired,
    data : PropTypes.arrayOf(object).isRequired,
    breackpointToCard : oneOf(['sm', 'md', 'lg']).isRequired,
    getPage : PropTypes.func.isRequired
}

Table.defaultProps = {
    pageSize: 10,
    page: 1,
  }

export default (Table);
