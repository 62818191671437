import React, { useState, useEffect } from 'react';
import './DashboardContainer.css';
import DataCardContainer from './DataCard/DataCardContainer';
import translate from '../../config/translations/translate';
import { translations } from '../../config/translations/Dashboard/DashboardContainer';
import UserModal from '../UserModal/UserModal';
import {
	timePeriodsIds as timePeriods,
	getLabel,
	getSuffixLabel,
	calculateFrom,
	calculateTo
} from 'botbit-ui-components';
import _ from "lodash"
import {
	getDetractorsConfig, getPromotersConfig,

} from './userModalConfig';
import GoogleViewsGraph from "./GoogleViewsGraph/GoogleViewsGraph";
import { Filters } from "botbit-ui-components";
import { withRouter } from 'react-router-dom';
import { getRetentions, getReputationMetrics, getStoresMetrics, addLicenses, getRRMetrics } from "../../api/dashboardApi";
import { useSelector } from "react-redux";
import moment from "moment"
import { SocialSourceNames } from '../../commons/enums/SocialSource';
import { useRange } from "../../commons/utils/useWindowDimensions";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { firePageView } from "../../commons/components/utils/trackers";
// import ReviewsWidgetDiv from '../../components/feedback/ReviewsWidgetDiv';
import MinLeaderboard from './MinLeaderboard/MinLeaderboard';
import ReviewsGraph from './ReviewsGraph/ReviewsGraph';
import FunnelDetailModal from '../RRFunnel/FunnelDetailModal';

const MySwal = withReactContent(Swal);

const DashboardContainer = ({ t, history, trackEvent }) => {

	const selectedCompany = useSelector(state => state.application.selectedCompany);
	const [filterValues, setFilterValues] = useState({ timePeriod: [{ value: timePeriods.LAST_30_DAYS }], companies: [selectedCompany.id], stores: [] })
	const [retentionData, setRetentionData] = useState(null);
	const [reputationData, setReputationData] = useState(null);
	const [storesData, setStoresData] = useState(null);
	const availableCompanies = useSelector(state => state.application.availableCompanies);
	const isSmallDesktop = useRange(992, 1366);
	const navigationMenuExpanded = useSelector(state => state.application.navigationMenuExpanded);
	const availableStores = useSelector(state => state.application.availableStores)
	const [isFunnelDetailsModalOpen, setIsFunnelDetailsModalOpen] = useState(false);

	const allAvailableStoreIds = availableStores.map(s => s.id)
	const companiesAvailableStores = availableStores.filter(s => filterValues.companies && filterValues.companies.includes(s.companyId))
	const companiesAvailableStoreIds = companiesAvailableStores.map(s => s.id)
	// const [showReviewsWidget, setShowReviewsWidget] = useState(true);

	const schemas = {
		timePeriod: {
			id: 'timePeriod',
			label: t("timePeriod"),
			type: 'timeperiod',
			icon: 'calendar-alt',
			placeholder: t("custom"),
			priority: true,
			visible: true,
			options: [timePeriods.THIS_MONTH, timePeriods.LAST_30_DAYS, timePeriods.LAST_6_MONTHS, timePeriods.LAST_12_MONTHS, timePeriods.THIS_YEAR, timePeriods.CUSTOM],
			defaultValues: filterValues.timePeriod,
		},
		companies: {
			id: "companies",
			label: t("company"),
			type: "multiselect",
			icon: "building",
			placeholder: t("allCompanies"),
			priority: true,
			visible: availableCompanies.length > 1,
			options: availableCompanies.map(s => ({ value: s.id, text: s.name })),
			defaultValues: filterValues.companies,
			search: true
		},
		stores: {
			id: "stores",
			label: t("store"),
			type: "multiselect",
			icon: "store-alt",
			placeholder: t("allStores"),
			priority: true,
			visible: true,
			options: availableStores.map(s => ({ value: s.id, text: s.name, dependencyId: s.companyId })),
			search: true,
			defaultValues: [],
			dependsOn: "companies"
		}
	};

	useEffect(() => {
		firePageView()
	}, [])

	useEffect(() => {
		setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
	}, [navigationMenuExpanded])

	useEffect(() => {

	}, [])

	const restartAllData = () => {
		setReputationData(null);
		setRetentionData(null);
		setStoresData(null);
	}


	const [isModalOpen, setIsModalOpen] = useState(false);
	const [usersModalData, setUsersModalData] = useState({});

	useEffect(() => {

		if (reputationData || retentionData || storesData) {
			restartAllData()
		}
	}, [JSON.stringify(filterValues)])


	useEffect(() => {
		async function getRetentionsFromBackend() {
			const res = await getRetentions(selectedCompany.id, getStores(), getPeriod())
			setRetentionData(res.data.data);
		}

		async function getReputationFromBackend() {
			const res = await getReputationMetrics(selectedCompany.id, getStores(), getPeriod())
			const data = res.data.data;

			const resForRRCard = await getRRMetrics(getStores(), getPeriod());
			const dataForRRCard = resForRRCard.data.data;

			const getSources = totalsBySocialSource => {
				if (!totalsBySocialSource) return [];
				const socialSourceNames = SocialSourceNames;
				return totalsBySocialSource.filter(t => t.totalReviews > 0).map(t => t.socialSourceId).map(ssid => socialSourceNames[ssid]);
			}

			if (data.averageScoreFromAllSources) {
				data.averageScoreFromAllSources = {
					...data.averageScoreFromAllSources,
					sources: getSources(data.totalsBySocialSource),
					period: getPeriod()
				}
			}

			if (data.answeredReviews) {
				data.answeredReviews = {
					...data.answeredReviews,
					sources: getSources(data.totalsBySocialSource),
					period: getPeriod()
				}
			}

			if (dataForRRCard.totalRequestSent.current > 0) {
				data.reviewRequest = { ...dataForRRCard };
			}

			setReputationData(data);
		}

		async function getStoresMetricsFromBackend() {
			const res = await getStoresMetrics(selectedCompany.id, getStores(), getPeriod());
			const data = res.data.data;

			if (data.storesByScore) {
				data.storesByScore = {
					...data.storesByScore,
					period: getPeriod()
				}
			}
			setStoresData(data);
		}

		getStoresMetricsFromBackend();
		getReputationFromBackend();
		getRetentionsFromBackend();
		// setShowReviewsWidget(filterValues.companies && filterValues.companies.length === 1);
	}, [JSON.stringify(filterValues)])

	const openModal = (modalConfig) => {
		document.body.style.overflowY = "hidden"
		setUsersModalData(modalConfig);
		setIsModalOpen(true);
	};

	const getFiltersURLParams = () => {
		const params = new URLSearchParams();

		_.at(filterValues, "timePeriod[0].value")[0] && params.append("timePeriod", _.at(filterValues, "timePeriod[0].value")[0]);
		_.at(filterValues, "timePeriod[0].from")[0] && params.append("from", _.at(filterValues, "timePeriod[0].from")[0]);
		_.at(filterValues, "timePeriod[0].to")[0] && params.append("to", _.at(filterValues, "timePeriod[0].to")[0]);
		_.at(filterValues, "stores")[0] && params.append("stores", _.at(filterValues, "stores")[0].join("|"));
		_.at(filterValues, "companies")[0] && params.append("companies", _.at(filterValues, "companies")[0].join("|"));
		return params;
	};

	const getPeriod = () => {
		return _.at(filterValues, "timePeriod[0].value")[0]
			? _.at(filterValues, "timePeriod[0].value")[0] !== "CUSTOM"
				? { suffixText: getSuffixLabel(_.at(filterValues, "timePeriod[0].value")[0]), text: "· 📅 " + getLabel(_.at(filterValues, "timePeriod[0].value")[0]), from: floorHours(calculateFrom(_.at(filterValues, "timePeriod[0].value")[0])), to: ceilHours(calculateTo(_.at(filterValues, "timePeriod[0].value")[0])) }
				: { suffixText: getSuffixLabel(_.at(filterValues, "timePeriod[0].value")[0]), text: "· 📅 " + getLabel(_.at(filterValues, "timePeriod[0].value")[0]), from: floorHours(_.at(filterValues, "timePeriod[0]")[0].from), to: _.at(filterValues, "timePeriod[0]")[0].to }
			: { text: "" }
	}

	const getLast12Months = () => {
		return { suffixText: getSuffixLabel(timePeriods.LAST_12_MONTHS), text: "· 📅 " + getLabel(timePeriods.LAST_12_MONTHS), from: floorHours(calculateFrom(timePeriods.LAST_12_MONTHS)), to: ceilHours(calculateTo(timePeriods.LAST_12_MONTHS)) }
	}

	const getStores = () => {
		// si hay stores seleccionados son esos los que quiero consultar
		if (filterValues.stores && filterValues.stores.length > 0)
			return filterValues.stores;
		// no hay stores seleccionados pero si companies, retorno todos los stores posibles
		else if (filterValues.companies && filterValues.companies.length > 0)
			return companiesAvailableStoreIds;
		// no hay stores seleccionados ni companies seleccionadas, retorno todos los stores
		else
			return allAvailableStoreIds
	}

	const onAverageScoreFromAllSourcesClick = () => {
		const params = getFiltersURLParams();
		history.push(`/feedback/reviews?${params}`);
	};

	const onFiveStarGoogleReviewsClick = () => {
		const urlParams = getFiltersURLParams();
		urlParams.append("scores", "5");
		urlParams.append("sources", "2");
		// urlParams.append("botbitGenerated", "true");
		history.push(`/feedback/reviews?${urlParams.toString()}`);
	};

	const onAnsweredReviewsClick = () => {
		const params = getFiltersURLParams();
		params.append("autoReplied", true);
		history.push(`/feedback/reviews?${params}`);
	};

	const renderReputationCards = () => {
		return <DataCardContainer
			className="data-card-container align-top"
			title={t('reputationTitle', {
				period: getPeriod().text,
			})}
			period={getPeriod()}
			name="reputation"
			footer={{ text: t('reputationFooter'), link: t('defaultLinkText'), url: "https://help.botbit.io/es/articles/4520460-como-mejorar-las-metricas?utm_source=BotBit&utm_medium=backOffice&utm_campaign=dashboard" }}
			data={reputationData}
			emptyState={t("noReviewData")}
			dataPerId={[
				{
					id: 'fiveStarGoogleReviews',
					actions: { footer: onFiveStarGoogleReviewsClick },
				},
				{
					id: 'answeredReviews',
					actions: { footer: onAnsweredReviewsClick },
				},
				{
					id: 'reviewRequest',
					actions: {
						body: {
							manual: () => history.push(`/leaderboard?stores=${getStores().join(",")}&dateFrom=${getPeriod().from.toISOString()}&dateTo=${getPeriod().to.toISOString()}&period=${filterValues.timePeriod[0].value}`),
							automatic: () => {
								const completeStores = availableStores.filter((store) => getStores().includes(store.id));
								const companyIds = completeStores.map((store) => store.companyId);
								const existCompanyWithOneStep = availableCompanies.some(company => (companyIds.includes(company.id) && company.npsCampaign.subCategoryId === 16));
								if (existCompanyWithOneStep) {
									setIsFunnelDetailsModalOpen(true)
								} else {
									history.push(`/detailedReviewRequest?stores=${getStores().join(",")}&dateFrom=${getPeriod().from.toISOString()}&dateTo=${getPeriod().to.toISOString()}&period=${filterValues.timePeriod[0].value}&admin=scheduler`)
								}
							}
						},
						footer: onAverageScoreFromAllSourcesClick
					},
				},
			]}
		/>
	}

	const onScoreCardClick = (data) => {
		let { score: { performance }, id } = data;

		const params = getFiltersURLParams();
		params.append("order", `score|${performance === "HIGH" ? 'desc' : 'asc'}`);
		params.delete("stores");
		params.append("stores", id);
		history.push(`/feedback/reviews?${params}`);
	}

	const onAddStoresCardClick = (data) => {
		addLicenses(selectedCompany.id)
			.then(res => {
				if (res.status === 200) {
					MySwal.fire({
						type: 'success',
						title: t('success'),
						text: t('newStoreSwalMsg')
					})
				}
			})
	}

	const onStoresClick = (stores, order) => {
		const params = new URLSearchParams()
		const storesIds = stores.map(s => s.id);
		_.at(filterValues, "timePeriod[0].value")[0] && params.append("timePeriod", _.at(filterValues, "timePeriod[0].value")[0]);
		storesIds && params.append("stores", storesIds.join("|"));
		params.append("order", `score|${order === "HIGH" ? 'desc' : 'asc'}`);
		history.push(`/feedback/metrics?${encodeURIComponent(params)}`);
	}

	const onStoresDetailClick = () => {
		history.push(`/feedback/metrics?${getFiltersURLParams()}`);
	}

	const renderStoresCards = () => {
		return <DataCardContainer
			className="data-card-container align-center"
			title={t('storesTitle', {
				period: getPeriod().text,
			})}
			period={getPeriod()}
			name="stores"
			emptyState={t("noData")}
			footer={{ text: t('storesFooter'), link: t('defaultLinkText'), url: "https://help.botbit.io/es/articles/4520455-como-mejorar-el-puntaje-de-tus-tiendas?utm_source=BotBit&utm_medium=backOffice&utm_campaign=dashboard" }}
			data={storesData}
			dataPerId={[
				{
					id: 'storesByScore',
					actions: { body: onStoresClick, footer: onStoresDetailClick },
					extraData: { isSmallDesktop }
				},
				{
					id: 'lowestScoreStore',
					actions: { footer: onScoreCardClick },
				},
				{
					id: 'highestScoreStore',
					actions: { footer: onScoreCardClick },
				},
				{
					id: 'singleStoreScore',
					actions: { footer: onScoreCardClick },
				},
				{
					id: 'moreStores',
					actions: { body: onAddStoresCardClick },
				}

			]}
		/>
	}

	const onAnswerNowClick = () => {
		const params = getFiltersURLParams();
		params.append("withoutReply", "true");
		// params.append("withComment", "true");
		params.append("scores", "1|2|3");
		history.push(`/feedback/reviews?${params}`);
	}

	const onPromotersModalOpen = () => {
		openModal(getPromotersConfig(selectedCompany.id));
	}

	const onDetractorsModalOpen = () => {
		openModal(getDetractorsConfig(selectedCompany.id));
	}

	const renderRetentionCards = () => {
		return <DataCardContainer
			className="data-card-container align-end"
			name="retention"
			title={t('retentionTitle', {
				period: getPeriod().text
			})}
			period={getPeriod()}
			footer={{ text: t('retentionFooter'), link: t('defaultLinkText'), url: "https://help.botbit.io/es/articles/4520485-como-mejorar-la-retencion-de-tus-clientes?utm_source=BotBit&utm_medium=backOffice&utm_campaign=dashboard" }}
			data={retentionData}
			dataPerId={[
				{
					id: 'totalUnsatisfiedClients',
					actions: { footer: onAnswerNowClick },
				},
				{
					id: 'nowSatisfiedClients',
					actions: { footer: onPromotersModalOpen },
				},
				{
					id: 'nowUnsatisfiedClients',
					actions: { footer: onDetractorsModalOpen },
				},
			]}
		/>
	}

	const getPeriodForModal = () => {
		let period;
		if (usersModalData.aggruptationType === "DAILY") {
			period = {
				from: usersModalData.date.toDate(),
				to: usersModalData.date.toDate(),
				text: usersModalData.date.format("DD-MM-YYYY")
			}
		} else {
			period = {
				from: usersModalData.date.startOf('month').toDate(),
				to: usersModalData.date.endOf("month").toDate(),
				text: usersModalData.date.format("DD-MM-YYYY")
			};
		}
		return period;
	}

	const getTitleForModal = () => {
		let dateLabel = t("captiveModal.thePeriod", { date1: moment(usersModalData.date.toDate()).format("MMMM YYYY") });
		if (usersModalData.aggruptationType === "DAILY") {
			dateLabel = t("captiveModal.theDay", { date: moment(usersModalData.date.toDate()).format("LL") })
		}
		return `${t('captiveModal')} ${dateLabel}`;
	}

	const cleanDateToFilter = newValues => {
		if (newValues.timePeriod[0] && newValues.timePeriod[0].to) {
			const { to, from } = newValues.timePeriod[0]
			newValues.timePeriod[0].to = ceilHours(to)
			newValues.timePeriod[0].from = floorHours(from)
		}

		return newValues;
	}

	const ceilHours = date => {
		date.setHours(date.getHours() + 1);
		date.setMinutes(0, 0, 0)

		return date;
	}

	const floorHours = date => {
		date.setHours(date.getHours());
		date.setMinutes(0, 0, 0)

		return date;
	}

	return (
		<div className="dashboard-container">
			<FunnelDetailModal
				isOpen={isFunnelDetailsModalOpen}
				toggle={() => setIsFunnelDetailsModalOpen(false)}
				stores={getStores().join(",")}
				period={getPeriod()}
				mode={"users"}
				adminId={"scheduler"}
			/>
			<Filters
				title={t("title")}
				schemas={schemas}
				values={filterValues}
				onChange={(newValues) => {

					setFilterValues(cleanDateToFilter(newValues))
				}
				}
				showInfo
			/>

			<div className="dashboard-masonry d-block d-md-flex">
				<div className="col-md-5 col-12 d-flex flex-column justify-content-between">
					<div
						className="dashboard-element-container"
						style={{
							order: 2,
						}}>
						{/* <CaptiveGraph
              openModal={(stores, methods, aggruptationType, date) => openModal({ ...getCaptiveModal(stores, methods), aggruptationType, date })}
              stores={getStores()}
              period={getPeriod()}
              filterValues={filterValues}
            /> */}
						<ReviewsGraph
							period={getLast12Months()}
							stores={getStores()}
							filterValues={filterValues}
						/>
					</div>
					<div style={{ order: 3 }} className="dashboard-element-container">
						<MinLeaderboard
							stores={getStores()}
							period={getPeriod()}
							companies={filterValues.companies}
						/>
					</div>
					<div
						className="dashboard-element-container"
						style={{
							order: 4,
						}}>
						<GoogleViewsGraph
							period={getPeriod()}
							stores={getStores()}
							filterValues={filterValues}
						/>
					</div>
				</div>


				<div className="col-md-7 col-12 d-flex flex-column carousels-column">
					<div
						className="dashboard-element-container h-33"
						style={{
							order: 3,
						}}>
						{renderStoresCards()}
					</div>
					<div
						className="dashboard-element-container h-33"
						style={{
							order: 1,
						}}>
						{renderReputationCards()}
					</div>
					<div
						className="dashboard-element-container h-33"
						style={{
							order: 5,
						}}>
						{renderRetentionCards()}
					</div>
				</div>


			</div>
			{/* <div className="link-to-old-dashboard">
        <h2 className="text-center">{t("seeOldVersion")}<a href={`${envConfig.frontendPhpUrl}/index.php?companyId=${selectedCompany.id}&&sso=${token.replace("Bearer ", "")}`}> {t("seeOldVersion.clickHere")}</a></h2>
      </div> */}
			{isModalOpen && Object.keys(usersModalData).length > 0 && (
				<UserModal
					hideActions={true}
					isOpen={isModalOpen}
					toggle={() => {
						document.body.style.overflowY = "auto";
						setIsModalOpen(false);
						setUsersModalData({});
					}}
					stores={getStores()}
					period={usersModalData.id === "captiveModal"
						? getPeriodForModal()
						: getPeriod()}
					{...usersModalData}
					title={usersModalData.id === "captiveModal"
						? getTitleForModal()
						: usersModalData.title}
				/>
			)}
		</div>
	);
};

DashboardContainer.translations = {
	...translations,
};

export default withRouter(translate(DashboardContainer));