export const translations = {
	title: {
		es: 'Categorías para análisis de sentimientos',
		en: 'Topics for sentiment analysis'
	},
	table: {
		header: {
			keyword: {
				es: 'Categorías',
				en: 'Topics'
			}
		},
		actions: {
			create: {
				button: {
					es: 'Crear categorías',
					en: 'Create topic'
				},
				modal: {
					title: {
						es: 'Crear categoría',
						en: 'Create topic'
					},
					text: {
						es: 'Ingrese el nombre de la categoría (sin espacios, números o símbolos)',
						en: 'Type your topic name (no spaces, numbers or symbols)'
					},
					confirm: {
						es: 'Confirmar',
						en: 'Confirm'
					},
					cancel: {
						es: 'Cancelar',
						en: 'Cancel'
					},
					label: {
						es: 'Categoría',
						en: 'Topic'
					}
				}
			},
			delete: {
				button: {
					es: 'Eliminar',
					en: 'Delete'
				},
				confirmation: {
					es: 'Se va a eliminar la categoría. ¿Estás seguro?',
					en: 'Topic will be deleted. Are you sure?'
				},
				confirm: {
					es: 'Confirmar',
					en: 'Confirm'
				},
				cancel: {
					es: 'Cancelar',
					en: 'Cancel'
				},
				close: {
					es: 'Cerrar',
					en: 'Close'
				},
				success: {
					es: 'La categoría se eliminó exitosamente',
					en: 'Topic deleted succesfully'
				}
			}
		}
	}
}