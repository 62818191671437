import React, { useEffect, useState } from 'react';
import translate from "../../../config/translations/translate";
import { translations } from "../../../config/translations/Dashboard/DashboardContainer";
import { MDBCard, MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import Chart from 'react-google-charts';
import { NewLoader } from '../../NewLoader/NewLoader';
import { getReviewsCount } from '../../../api/feedbackApi';
import Loading from '../../../commons/components/utils/Loading';
import { Filters } from "botbit-ui-components";
import { SocialSourceNames } from '../../../commons/enums/SocialSource';
import LinkToHelpCenter from '../LinkToHelpCenter/LinkToHelpCenter';
import listToText from '../../../commons/utils/listToText';
import { useHistory } from 'react-router';
import { getGoogleViews } from '../../../api/dashboardApi';
import { useSelector } from 'react-redux';

const ReviewsGraph = ({ period, className, t, stores, filterValues }) => {
    const [graphData, setGraphData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [emptyState, setEmptyState] = useState(false);
    const [selectedSocialSources, setSelectedSocialSources] = useState([1, 2, 3]);
    const [page, setPage] = useState(1);
    const [startedWithRay, setStartedWithRay] = useState("");
    const [startedWithRayIsOnRange, setStartedWithRayIsOnRange] = useState(false);
    const selectedCompany = useSelector(state => state.application.selectedCompany)

    const history = useHistory()

    useEffect(() => {
        getData()
    }, [
        JSON.stringify(period),
        JSON.stringify(stores),
        JSON.stringify(selectedSocialSources),
    ])

    useEffect(() => {
        (() => {
            if (!startedWithRay || !graphData) return;
            if (!checkIfDateIsAfterStartedWithRay(graphData[0].from, true) && checkIfDateIsAfterStartedWithRay(graphData[graphData.length - 1].from, true)) {
                setStartedWithRayIsOnRange(true);
            } else setStartedWithRayIsOnRange(false);
        })()
    }, [
        graphData, 
        startedWithRay, 
        JSON.stringify(period),
        JSON.stringify(stores),
        JSON.stringify(selectedSocialSources),
    ])

    const getData = async () => {
        setIsLoading(true);
        setEmptyState(false);
        const sources = selectedSocialSources.length > 0 ? selectedSocialSources : undefined;
        const res = await getReviewsCount({ period, stores, sources });

        const data = res.data.data;
        const dataEmpty = data.filter(row => row.reviews > 0).length === 0;

        const startedWithRay = await getGoogleViews(selectedCompany.id, stores)
            .then(res => {
                const date = new Date();
                const lastDateOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)

                if (res.status !== 200) return lastDateOfMonth;

                let dateStarted = res.data.data.startedWithBotBit;
                return dateStarted || lastDateOfMonth;
            })

        if (dataEmpty) {
            setEmptyState(true);
        } else {
            setGraphData(data)
            setStartedWithRay(startedWithRay);
            setIsLoading(false);
        }
    }

    const formatData = () => {
        if (!graphData) return [];

        const dataArr = page === 0 ? [
            ["Period", t("count"), t("score"), {role: 'style'}]
        ] : [
            ["Period", t("count"), {role: 'style'}]
        ]

        if (page === 0)
            graphData.forEach(row => dataArr.push([row.shortLabel, row.reviews, row.score > 0 ? row.score : null, null]))
        else if (page === 1) {
            graphData.forEach(row => dataArr.push([row.shortLabel, row.reviewsAccumulated, (checkIfDateIsAfterStartedWithRay(row.from) && startedWithRayIsOnRange) ? 'color: #dc3545' : 'color: #007bff']));
        }
        return dataArr;
    }

    const checkIfDateIsAfterStartedWithRay = (date) => {
        const formDate = new Date(date);
        const dateIsAfter = new Date(formDate.getFullYear(), formDate.getMonth(), 20) >= new Date(startedWithRay);
        return dateIsAfter
    }

    const filterSchema = {
        socialSource: {
            id: "socialSource",
            type: "multiselect",
            options: [
                {
                    value: 1,
                    text: SocialSourceNames[1]
                },
                {
                    value: 2,
                    text: SocialSourceNames[2]
                },
                {
                    value: 3,
                    text: SocialSourceNames[3]
                },
            ],
            placeholder: t("socialSource"),
            label: t("socialSource"),
            // defaultValues: RegisteredThrough.map(r => r.id).filter(r => dataAnalysis[r].maximum > 0),
            visible: true,
            priority: true
        }
    }

    const getFirstAndLastDateOfMonthFromRow = (row) => {
        const now = new Date();
        const date = new Date(now.getFullYear(), now.getMonth() - (11 - row), 1);

        let from = new Date(date.getFullYear(), date.getMonth(), 1);
        const to = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);

        if (page === 1)
            from.setFullYear(1998, 0, 0);

        return { to, from }
    }

    return (
        <div data-intercom-target={`reviewsGraphContainer`} className={`reviews-graph ${className || ''}`}>
            <h3 className="dashboard-title">{t('reviewsGraphTitle', { period: period.text })}</h3>
            <MDBCard data-intercom-target="reviewRequestGraph" className={`graph-card${emptyState ? " hidden-card" : ""}`}>
                <MDBRow className={"reviews-graph_header"}>
                    <MDBCol size="12" md="8">
                        <MDBPagination className="graph-pagination" size="sm">
                            <MDBPageItem active={page === 1}>
                                <MDBPageNav onClick={() => setPage(1)}>
                                    {t("accumulatedTab")}
                                </MDBPageNav>
                            </MDBPageItem>
                            <MDBPageItem active={page === 0}>
                                <MDBPageNav onClick={() => setPage(0)}>
                                    {t("reviewsTab")}
                                </MDBPageNav>
                            </MDBPageItem>
                        </MDBPagination>
                    </MDBCol>
                    {<MDBCol size="10" md="4">
                        <Filters
                            schemas={filterSchema}
                            values={{ socialSource: selectedSocialSources }}
                            onChange={({ socialSource }) => { setSelectedSocialSources(socialSource) }}
                        />
                    </MDBCol>}
                </MDBRow>
                {!emptyState ?
                    <>
                        {graphData && startedWithRay && !isLoading ?
                            <>
                                <Chart
                                    width="100%"
                                    height="210px"
                                    chartType="ComboChart"
                                    loader={<NewLoader className="loader" />}
                                    data={formatData()}
                                    options={{
                                        vAxes: {
                                            0: {
                                                title: t("count"),
                                                titleTextStyle: {
                                                    italic: false,
                                                },
                                                gridlines: {
                                                    color: page === 0 ? "transparent" : "unset"
                                                },
                                                format: "short"
                                            },
                                            1: {
                                                title: t("score"),
                                                minValue: 0,
                                                ticks: [1, 2, 3, 4, 5],
                                                titleTextStyle: {
                                                    italic: false,
                                                    color: page === 1 ? "none" : "unset"
                                                },
                                                textPosition: page === 1 ? "none" : "unset"
                                            }
                                        },
                                        hAxis: { title: '', ticks: "2" },
                                        pointSize: 12,
                                        legend: "none",
                                        chartArea: { 'width': '80%', 'height': '75%' },
                                        series: {
                                            0: {
                                                type: "bars",
                                                targetAxisIndex: 0
                                            },
                                            1: {
                                                type: page === 0 ? 'line' : 'none',
                                                targetAxisIndex: 1,
                                                pointShape: 'star',
                                                color: page === 0 ? "FFB900" : "none"
                                            }
                                        },
                                    }}
                                    chartEvents={[
                                        {
                                            eventName: "select",
                                            callback: ({ chartWrapper }) => {
                                                if (page === 1) return;
                                                const { row } = chartWrapper.getChart().getSelection()[0];
                                                const { from, to } = getFirstAndLastDateOfMonthFromRow(row);
                                                history.push(encodeURI(`/feedback/reviews?timePeriod=CUSTOM&from=${from.toISOString()}&to=${to.toISOString()}&sources=${selectedSocialSources.join("|")}&stores=${stores.join("|")}&companies=${filterValues.companies.join("|")}`))
                                            }
                                        },
                                    ]}
                                />
                                <span className="inner-footer card-footer">
                                    <b>{t("reviewsFrom.first", {
                                        sources: listToText(Object.entries(SocialSourceNames)
                                            .filter(([key]) => selectedSocialSources.includes(parseInt(key)))
                                            .map(([_, val]) => val)
                                        )
                                    })}</b> {t("reviewsFrom.second", { period: period.suffixText })} {(page === 0) ? t("footerHelper") :( startedWithRayIsOnRange ? t("colorGuide") : "")}
                                </span>
                            </>
                            : <Loading loading={true} />}
                    </>
                    : <div className="empty-state" style={{ height: 300 }}>
                        <h1>{t("reviewGraphEmptyState")}</h1>
                    </div>
				}
            </MDBCard>
            <LinkToHelpCenter
                intercomName={`reviewsGraphFooter`}
                labels={{
                    beforeLinkText: t("generateMoreReviews") + " ",
                    linkText: t('defaultLinkText'),
                    afterLinkText: "",
                }}
                // url="https://help.botbit.io/es/articles/3200769-cuales-son-las-distintas-fuentes-para-captar-los-datos-de-mis-clientes?utm_source=BotBit&utm_medium=backOffice&utm_campaign=dashboard"
                linkRenderer={() => (<button onClick={() => window.location.search = "?send_review_request=true"} className="link-like-btn">{t('sendingRequests')}</button>)}
            />
        </div>
    );
}

ReviewsGraph.translations = { ...translations }

export default translate(ReviewsGraph);