import { useSelector } from "react-redux";
import { getTypesForCompany } from "../../../api/issuesApi";

const { useState, useEffect } = require("react");

const useCompanyTypes = () => {

    const selectedCompany = useSelector(state => state.application.selectedCompany.id);

    const [types, setTypes] = useState();

    useEffect(()=>{
        if(!types)
            getTypesForCompany(selectedCompany).then(
                res => {
                    setTypes(res.data.data.type);
                }
            );
    },[])

    return types || []
}

export default useCompanyTypes;