import React from 'react';
import { MDBBtn, MDBBtnGroup } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFemale } from '@fortawesome/free-solid-svg-icons'
import { faMale } from '@fortawesome/free-solid-svg-icons'
import { faGenderless } from '@fortawesome/free-solid-svg-icons'

export default class GenderSelector extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selected: this.props.selected
        }
    }
    toggleGender(gender){
        this.props.onChange(gender);
        this.setState({
            selected: gender
        })
    }
    render() {
        return (
            <React.Fragment> 
                <MDBBtnGroup className="d-none d-sm-block mt-4">
                    <MDBBtn style={{width: "85px", marginRight: "2px", marginBottom: "2px"}} onClick={() => this.toggleGender("F")} color="primary" className={'p-2 ' + (this.state.selected === "F" ? 'active' : '')}><FontAwesomeIcon icon={faFemale} /> Mujer</MDBBtn>
					&nbsp;
                    <MDBBtn style={{width: "85px", marginRight: "2px", marginBottom: "2px"}} onClick={() => this.toggleGender("M")} color="primary" className={'p-2 ' + (this.state.selected === "M" ? 'active' : '')}><FontAwesomeIcon icon={faMale} /> Hombre</MDBBtn>
					&nbsp;
                    <MDBBtn style={{width: "85px", marginRight: "2px", marginBottom: "2px"}} onClick={() => this.toggleGender("O")} color="primary" className={'p-2 ' + (this.state.selected === "O" ? 'active' : '')}><FontAwesomeIcon icon={faGenderless} /> Otro</MDBBtn>
					&nbsp;
                </MDBBtnGroup>
            </React.Fragment>
        )
    }
}

export class GenderSelectorReduxForm extends React.Component {


    render(){
        const {input} = this.props;
        return (
            <React.Fragment>
                <GenderSelector {...input} label={this.props.placeholder} selected={input.value}/>
            </React.Fragment>
        )
    }
}
