import React from 'react';
import EmailActivityChart from './EmailActivityChart';
import {getEmailStatus, getDailyInformationForCompanyByDateRange} from '../../api/companyApi';
import { connect } from 'react-redux';
import { setLoading } from '../../commons/components/application/miscActions'
import AlertPurchasePack from "./AlertPurchasePack";
import EmailDelivered from "./EmailDelivered";
import EmailTotals from "./EmailTotals";
import {EMAIL_CLICKED_COUNT, EMAIL_DELIVERED_COUNT, EMAIL_OPEN_COUNT} from "./constans";
import { firePageView } from "../../commons/components/utils/trackers";

const moment = require('moment');
const thisMonth= () => {
    return {
        dateFrom: moment().startOf('month').toDate(),
        dateTo : moment().endOf('month').toDate()
    }
};
const valuesOfInterest = [EMAIL_DELIVERED_COUNT, EMAIL_OPEN_COUNT, EMAIL_CLICKED_COUNT];

const getTotalsTemplate = () => (
    {
        base: {
            serviceKey: EMAIL_DELIVERED_COUNT,
        },
        totals: [
            {
                serviceKey: EMAIL_OPEN_COUNT
            },
            {
                serviceKey: EMAIL_CLICKED_COUNT,
            }
        ]
    }
);

const getCalculatedDataForStats = (dailyInformation) => {
    let result = {};
    if (dailyInformation) {
        let totalEmails = 0;

        dailyInformation.forEach(day => (totalEmails = totalEmails + day.emailDeliveredCount));

        const totals = getTotalsTemplate();

        totals.base.total = totalEmails;
	let initialValue = {};
	
        for (const voi in valuesOfInterest) {
		initialValue[valuesOfInterest[voi]]=0;
        }

        const totalsMap = dailyInformation.reduce((acc, curr) => {
            const copy = Object.assign({}, acc);
            for (const voi in valuesOfInterest) {
                copy[valuesOfInterest[voi]] += curr[valuesOfInterest[voi]];
            }
            return copy;
        }, initialValue);

        totals.totals.map(t => t.total = totalsMap[t.serviceKey]?totalsMap[t.serviceKey]:0);

        result = totals;
    }
    return result;
};

const getCalculatedDataForChart = (dailyInformation=[]) => {
    const chartData =
        dailyInformation.map(day => {
            const dayArray = [day.date];
            valuesOfInterest.map(voi => dayArray.push(day[voi]));
            return dayArray;
        });
    return chartData !== undefined ? chartData : [];
};

class EmailActivityContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state={ 
		currentMonthTotal: 0,
		currentSelectedDateRange: thisMonth()
	};
    }

    updateEmailStatus = () => {
        this.props.setLoading(true);

        getEmailStatus(this.props.selectedCompany.id)
        .then(emailStatus => {
            this.setState({...this.state, companyEmailPack:emailStatus.data.data});
        });

        getDailyInformationForCompanyByDateRange(
            this.props.selectedCompany.id,
            thisMonth()
		).then(res => {
			const data  = res.data.data
            this.setState({
                dailyInformationForStats: data,
				currentMonthTotal: getCalculatedDataForStats(data).base.total
            });
        }).finally(()=>{this.props.setLoading(false);});
    };

    updateChartData = (range=thisMonth()) => {
        getDailyInformationForCompanyByDateRange(this.props.selectedCompany.id, range)
		.then(response =>{
			const data  = response.data.data;
            this.setState({
                ...this.state,
				dailyInformationForChart : data,
				dailyInformationForStats : data,
                currentSelectedDateRange: range
            });
        });
    };
    
    componentDidMount(){
        this.updateEmailStatus();
        this.updateChartData();
        firePageView();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedCompany.id !== prevProps.selectedCompany.id ) {
            this.updateEmailStatus();
            this.updateChartData(this.state.currentSelectedDateRange);
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => nextState!=={};

    render = () => {
      let data;
      if(this.state.dailyInformationForStats){
        data = getCalculatedDataForStats(this.state.dailyInformationForStats);
      }
      const {selectedCompany, admin, t} = this.props;
      const hasData = this.state.dailyInformationForStats && data!==undefined && JSON.stringify(data)!=='{}';
        return (
            <div className="wrapper wrapper-content animated fadeInRight">
                { hasData &&
                  (<React.Fragment>
                    <AlertPurchasePack selectedCompany={selectedCompany} admin={admin} t={t}/>
					<EmailDelivered  packStatus={this.state.companyEmailPack} dataBaseTotal={this.state.currentMonthTotal} t={t}/>
                    <EmailTotals base={data.base} totals={data.totals} t={t}/>
                  </React.Fragment>
                )
                }
                {
                    this.state.dailyInformationForChart &&
                    <EmailActivityChart 
                        dailyInformation={getCalculatedDataForChart(this.state.dailyInformationForChart)}
                        dateChangeHandler={this.updateChartData}
                        initDate={this.state.currentSelectedDateRange}
                    />
                }
            </div>
        ) 
    } 
    
}

const mapStateToProps = (state) =>{
    return {
        selectedCompany: state.application.selectedCompany,
        admin: state.login.admin
    }
};

export default (connect(mapStateToProps, {setLoading}) (EmailActivityContainer));
