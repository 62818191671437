import React from "react";
import {Button} from "botbit-ui-components"
import translate from "../../../config/translations/translate";
import "./styles.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ErrorPage = ({ t, error }) => {

	const history = useHistory();
  return (
    <div className="error-page-container">
		<div className="error-page-front">
			<div className="error-page-header">
        	    <h4 style={{fontFamily:'Open Sans', fontWeight:'normal', fontSize:'18px', color:'black'}}>
        	        {t('title')}
        	    </h4>
        	</div>
			<hr/>
        	<div className="error-page-body">
        	    <Button onClick={()=>history.push("/socialaccounts")}>
					{t('buttonText')}
				</Button>
        	</div>
		</div>
    </div>
  );
};

ErrorPage.translations = {
  title: {
    en: "It was not possible to analyze the profiles",
    es: "No fue posible analizar los perfiles",
  },
  buttonText: {
    en: "See my connected stores",
    es: "Ver mis tiendas conectadas",
  },
};

export const ErrorPageContainer = translate(
	ErrorPage
);
