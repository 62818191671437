import React, { useState, useEffect } from 'react';
import { SegmentFiltersInterpreter } from '../../campaigns/SegmentHelper';
import { getPossibleSegments, deleteSegment as deleteSegmentService, getSegmentMetrics } from '../../../api/segmentApi';
import { MDBInput } from 'mdbreact';
import { envConfig } from '../../../config/config';
import { Toast, toast } from "botbit-ui-components";
import "./Segments.css"
import { countStores } from "../../campaigns/MinimalEditor/Helper";
import SegmentDetail from "../SegmentDetail/SegmentDetail";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from '../../../commons/components/application/miscActions';
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import { withRouter } from "react-router-dom";
import CollectionVisualizerContainer from '../../collectionVisualizer/CollectionVisualizerContainer';

const MySwal = withReactContent(Swal);
const MINIMAL_EDITOR_PREFIX = "panels.campaigns.maximalEditor.";
const TRANSLATIONS_PREFIX = "panels.campaigns.segments.";

const Segments = ({
    t,
    title,
    onRowClick,
    isSelectable,
    selectedId,
    show,
    canDelete,
    canViewUsers,
    canViewDescription,
    canUpdateClients,
    history,
    mustTargetOnlyAdminStores
}) => {
    const isMobile = (window.innerWidth < 576 ? true : false);
    const [reRender, setReRender] = useState(true);
    const hasRowActions = (canViewUsers || canDelete || canUpdateClients);
    const availableStores = useSelector(state => state.application.availableStores)
    const availableCompanies = useSelector(state => state.application.availableCompanies);
    const selectedCompany = useSelector(state => state.application.selectedCompany)
    const token = useSelector(state => state.login.token)

    // const loading = useSelector(state => state.misc.loading)
    const dispatch = useDispatch();

    const viewUsers = (id) => {
        // window.location.href = `/crm?segment=${id}&currentStore=-1`;
        history.push(`/crm?segment=${id}&currentStore=-1`)
    }

    const refreshMetrics = (id, updateRow, row) => {
        getSegmentMetrics(id)
            .then((response) => {
                MySwal.fire({
                    title: t(`${TRANSLATIONS_PREFIX}refreshMetricsModal.title`),
                    text: t(`${TRANSLATIONS_PREFIX}refreshMetricsModal.text`, row),
                    type: 'info',
                    showCancelButton: false,
                    confirmButtonText: t(`${TRANSLATIONS_PREFIX}refreshMetricsModal.confirm`)
                });
                updateRow([response.data.data]);
            });
    }

    const deleteSegment = ({ id, name }, deleteRowInTable) => {
        MySwal.fire({
            title: t(`${TRANSLATIONS_PREFIX}deleteModal.title`, { name }),
            text: t(`${TRANSLATIONS_PREFIX}deleteModal.text`),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t(`${TRANSLATIONS_PREFIX}deleteModal.confirm`),
            confirmButtonColor: "red",
            cancelButtonText: t(`${TRANSLATIONS_PREFIX}deleteModal.cancel`)
        }).then(res => {
            if (res.value) {
                dispatch(setLoading(true));
                deleteSegmentService(id).then(() => {
                    deleteRowInTable();
                    dispatch(setLoading(false));
                })
            }
        })

    }

    const segmentsData = (page, order, filters) => {
        return getPossibleSegments("CUSTOM", filters.company[0], page, mustTargetOnlyAdminStores, selectedId);
    }

    useEffect(() => {
        if (!reRender) {
            setReRender(true);
        }
    }, [reRender])

    // !isMobile ? : null
    let config = [
        {
            key: "newSegment",
            icon: "plus",
            label: isMobile ? t(`${TRANSLATIONS_PREFIX}actions.new`) : t(`${TRANSLATIONS_PREFIX}actions.newSegment`),
            primary: true,
            onClick: () => {
                window.open(`${envConfig.frontendPhpUrl}/newSegment.php?closeOnSave=true&companyId=${selectedCompany.id}&sso=${token}`, '_blank')
                toast(t(`${MINIMAL_EDITOR_PREFIX}outdatedResults`), {
                    actionButtonText: t(`${MINIMAL_EDITOR_PREFIX}updateNow`),
                    actionButtonOnClick: () => setReRender(false),
                    dismiss: true
                })
            }
        }
    ];
    const columns = [
        {
            dataField: 'name',
            title: t(MINIMAL_EDITOR_PREFIX + 'filters.name'),
            className: 'd-block d-md-none p-0',
            size: 7,
            formatter: (cellContent, row) => {
                return (
                    <div className="segmentMobile nameColumn">
                        {isSelectable && <div className="segmentRadioButton">
                            <MDBInput checked={selectedId === row.id} label=" " type="radio" id="radio1" />
                        </div>}
                        <div className="w-90">
                            <span className="d-block">{row.name}</span>
                            <span className="d-block">
                                <span className="mr-1">
                                    {t(MINIMAL_EDITOR_PREFIX + 'filters.clientsShort')}:
                                </span>
                                {renderApproximateClients(row, true)}
                            </span>
                            {/* cellContent.currentUsers */}
                        </div>
                    </div>
                );
            },
        },
        {
            dataField: 'stores',
            title: t(MINIMAL_EDITOR_PREFIX + 'filters.stores'),
            className: 'd-block d-md-none p-0',
            size: 4,
            formatter: (cellContent, row) => {
                return (
                    <div className="segmentMobile storesColumn">
                        <u>{countStores(row, t)}</u>
                    </div>
                );
            },
        },
        {
            dataField: '',
            title: '',
            className: `d-none ${isSelectable ? "d-md-block" : ""}`,
            size: 1,
            formatter: (cellContent, row) => {
                return (
                    isSelectable && <div className="segmentRadioButton">
                        <MDBInput checked={selectedId === row.id} label=" " type="radio" id="radio1" />
                    </div>
                );
            },
        },
        {
            dataField: 'name',
            title: t(MINIMAL_EDITOR_PREFIX + 'filters.name'),
            className: 'd-none d-md-block',
            size: 2
        },
        {
            dataField: 'stores',
            title: t(MINIMAL_EDITOR_PREFIX + 'filters.stores'),
            className: 'd-none d-md-block',
            size: 2,
            formatter: (cellContent, row) => {
                return (
                    <u>{countStores(row, t)}</u>
                );
            },
        },
        {
            dataField: 'genre',
            size: 2,
            title: t(MINIMAL_EDITOR_PREFIX + 'filters.gender'),
            className: 'd-none d-md-block',
            formatter: (cellContent, row) => {
                return (
                    <div>{SegmentFiltersInterpreter(t, row, "gender")}</div>
                );
            },
        },
        {
            dataField: 'age',
            title: t(MINIMAL_EDITOR_PREFIX + 'filters.age'),
            className: 'd-none d-md-block',
            size: isSelectable ? 1 : 1,
            formatter: (cellContent, row) => {
                return (
                    <div>{SegmentFiltersInterpreter(t, row, "age")}</div>
                );
            },
        },
        {
            dataField: 'lastEdit',
            title: t(MINIMAL_EDITOR_PREFIX + 'filters.creation'),
            className: 'd-none d-md-block',
            size: isSelectable ? 2 : 1,
            formatter: (cellContent, row) => {
                var epochDate = new Date(row.lastEditionDate);
                return (
                    <div>{epochDate.getDate() + '/' + (epochDate.getMonth() + 1) + '/' + epochDate.getFullYear()}</div>
                );
            },
        },
        // {
        //     dataField: 'lastClientsUpdate',
        //     title: t(MINIMAL_EDITOR_PREFIX + 'filters.lastClientsUpdate'),
        //     className: 'd-none d-md-block',
        //     size: 1,
        //     formatter: (cellContent, row) => {
        //         var epochDate = new Date(row.lastEditionDate);
        //         return (
        //             <div>{epochDate.getDate() + '/' + (epochDate.getMonth() + 1) + '/' + epochDate.getFullYear()}</div>
        //         );
        //     },
        // },
        {
            dataField: 'aproximateClients',
            title: t(MINIMAL_EDITOR_PREFIX + 'filters.clients'),
            className: 'd-none d-md-block',
            disclaimer: canUpdateClients ? t(MINIMAL_EDITOR_PREFIX + 'filters.clientsDisclaimer') : undefined,
            formatter: (cellContent, row) => renderApproximateClients(row)
        }
    ];

    const renderApproximateClients = (row, isMobile) => {
        let approxClients = "-"
        if(row.lastTimeClientsUpdated) {
            let formattedDate = new Date(row.lastTimeClientsUpdated.timestamp);

            approxClients = `${row.lastTimeClientsUpdated.targetedUsers} `;
            approxClients += isMobile ? t(`${MINIMAL_EDITOR_PREFIX}filters.clientsUntilShort`) : t(`${MINIMAL_EDITOR_PREFIX}filters.clientsUntil`)
            approxClients += ` ${formattedDate.getDate() + '/' + (formattedDate.getMonth() + 1) + '/' + formattedDate.getFullYear()}`  
        }

        return (
            <div>{approxClients}</div>
        );
    }

    const rowActions = (row, updateRow) => {
        let actions = [
            {
                key: 3,
                color: "",
                showAction: () => canUpdateClients,
                icon: "sync",
                label: t(`${TRANSLATIONS_PREFIX}actions.refreshMetrics`),
                onClick: () => refreshMetrics(row.id, (metrics) => updateRow({ ...row, metrics: metrics, lastTimeClientsUpdated: (metrics.length > 0) ? metrics[metrics.length - 1] : null }), row)
            },
            {
                key: 2,
                color: "",
                showAction: () => !!canViewUsers,
                icon: "users",
                label: t(`${TRANSLATIONS_PREFIX}actions.seeUsers`),
                onClick: () => viewUsers(row.id)
            },
            {
                key: 1,
                color: "",
                icon: "trash",
                showAction: () => canDelete,
                label: t(`${TRANSLATIONS_PREFIX}actions.deleteSegment`),
                onClick: () => deleteSegment(row, () => updateRow(row, "delete"))
            },
        ]
        return row.canEdit ? actions : [];
    }

    return (
        <>
            {(show && reRender) ? <div className="segmentCollVis">
                <CollectionVisualizerContainer
                    scrollTopButton
                    columns={columns}
                    title={title}
                    pagination={true}
                    headerActions={config}
                    getData={segmentsData}
                    rowActions={hasRowActions && rowActions}
                    showFiltersInfo
                    onRowClick={onRowClick}
                    expandRow={canViewDescription ? ((active, row) => {
                        return <div className="segmentDetailContainer">
                            <h4>{row.name}</h4>
                            <SegmentDetail availableStores={availableStores} t={t} segment={row}></SegmentDetail>
                        </div>
                    }) : undefined}
                    companies={availableCompanies}
                    selectedCompany={selectedCompany}
                />
            </div> : null}
            <Toast containerId={"segments-toast"} autoClose={false} />
        </>
    );
}

Segments.defaultProps = {
    onRowClick: () => { },
    rowActions: () => [],
    canSelectCompany: true,
    show: true,
    mustTargetOnlyAdminStores: false
}

export default withRouter(Segments);
