import React, { useRef, useState, useEffect } from 'react';
import { MDBIcon, MDBContainer, MDBBtn } from "mdbreact"
import { Formik, Form } from "formik";
import { containers } from '../../MinimalEditor/ContainerHelper';
import { getProductCategoriesByCompany } from "../../../../api/companyApi";
import { updateCampaign } from "../../../../api/campaignsApi";
import { saveCampaign, validationSchema } from "../../MinimalEditor/Helper";
import { CampaignBanner } from '../CampaignBanner';
import { Modal, ModalBody } from 'botbit-ui-components'

const TRANSLATION_PREFIX_CAMPAIGNS = 'panels.campaigns.'
const TRANSLATION_PREFIX = TRANSLATION_PREFIX_CAMPAIGNS + 'newCampaign.smartCampaignWizard.';
export const SmartCampaignWizard = ({ history, t, company, onBack, onClose, initialStep, campaignType, availableStores, storesActivableSmartCampaign, campaign: originalCampaign, setCampaign: setOriginalCampaign, setLoading }) => {
    const [wizardStep, setWizardStep] = useState((initialStep ? initialStep : 0));
    const [productCategoriesArray, setProductCategoriesArray] = useState([]);
    const [campaign, setCampaign] = useState(originalCampaign);
    const [exitModalIsOpen, setExitModalIsOpen] = useState(false);
    const [exitModalIsClosing, setExitModalIsClosing] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        let productCategories = [];
        getProductCategoriesByCompany(company)
            .then((res => {
                productCategories = [];
                for (var i in res) {
                    productCategories.push(res[i])
                }
                setProductCategoriesArray(productCategories);
            }))
    }, [])

    const getFormikCampaign = () => {
        return formRef.current && formRef.current.state.values;
    }

    const renderStep = (step) => {
        switch (step) {
            case 0:
                window.history.pushState({ newStep: 1 }, "step 1", "?step=1")
                return storeSegmentAndBenefitForm();
            case 1:
                window.history.pushState({ newStep: 2 }, "step 2", "?step=2")
                return subjectMessageAndButtonForm();
            case 2:
                window.history.pushState({ newStep: 3 }, "step 3", "?step=3")
                return fromToForm();
            default:
                break;
        }
    }
    const stepTitle = (step) => {
        switch (step) {
            case 0:
                return ""
            case 1:
                return t(TRANSLATION_PREFIX + "emailContentTitle");
            case 2:
                return t(TRANSLATION_PREFIX + "fromToTitle")
            default:
                break;
        }
    }

    const storeSegmentAndBenefitForm = () => {
        if (!getFormikCampaign() && availableStores) {
            return null;
        }
        return <div className="storeSegmentAndBenefitForm">
            <span className="mr-1">{t(TRANSLATION_PREFIX + "thisCampaignWillBeSent")}</span>
            {containers.sendDates.renderFunction({
                campaignBeingEdited: campaign
            })}
            <span>{t(TRANSLATION_PREFIX + "andItWillTarget")}</span>
            {containers.segment.renderFunction({
                isEditing: true,
                t: t,
                campaignBeingEdited: campaign,
                formRef: formRef,
                storesActivableSmartCampaign: storesActivableSmartCampaign,
                availableStores: availableStores
            })}
            <span className="ml-1">{t(TRANSLATION_PREFIX + "andTheyWillGet")}</span>
            {containers.benefit.renderFunction({
                isEditing: true,
                t: t,
                campaignBeingEdited: campaign,
                formRef: formRef,
                productCategoriesArray: productCategoriesArray
            })}
        </div>;
    }
    const subjectMessageAndButtonForm = () => {
        return <div className="subject-message-and-button-form">
            {containers.messageAndSubject.renderFunction({
                isEditing: true,
                t: t,
                productCategoriesArray: productCategoriesArray,
                formRef: formRef
            })}
            {containers.button.renderFunction({
                isEditing: true,
                t: t,
            })}
        </div>
    }

    const fromToForm = () => {
        return (
            <div class="fromToDisplayForm">
                {containers.responseEmailsAndDisplay.renderFunction({
                    isEditing: true,
                    t: t,
                    formRef: formRef,
                    campaignBeingEdited: campaign
                })}
            </div>
        )
    }

    const getInitialValues = campaign => {
        const initialValues = {};
        initialValues.campaign = campaign; //JSON.parse(JSON.stringify(campaign));
        if (campaign.segment && campaign.segment.filters) {
            campaign.segment.filters.forEach(field => {
                initialValues["filter_" + field.id] = field.operatorValues; //JSON.parse(JSON.stringify(field.operatorValues));
            });
        }
        if (!campaign.segment) {
            initialValues.campaign.segment = {};
        }
        if (!campaign.segment.stores) {
            initialValues.campaign.segment.stores = [];
        }
        if (campaign.replyToAddress === null) {
            initialValues.campaign.replyToAddress = "";
        }
        if (campaign.fromAddress === null) {
            initialValues.campaign.fromAddress = "";
        }
        return initialValues;
    }

    const goBack = () => {
        if (wizardStep === 0) {
            if (campaign.id !== null) {
                setExitModalIsClosing(false);
                setExitModalIsOpen(true);
            } else {
                onBack();
            }
        } else {
            if (Object.keys(formRef.current.state.errors).length > 0) {
                formRef.current.setValues(getInitialValues(campaign));
            }
            setWizardStep(wizardStep - 1);
        }
    }

    const close = () => {
        if (campaign.id !== null) {
            setExitModalIsClosing(true);
            setExitModalIsOpen(true);
        } else {
            onClose();
        }
    }

    const nextStep = (newValues) => {
        setLoading(true)
        saveCampaign(newValues,
            t,
            productCategoriesArray,
            updateCampaign)
            .then(newCampaign => {
                formRef.current.setValues(getInitialValues(newCampaign));
                if (wizardStep === 2) {
                    setOriginalCampaign(newCampaign);
                    window.history.pushState({ newStep: 4 }, "step 4", "?step=4")
                } else {
                    setWizardStep(wizardStep + 1);
                }
                setCampaign(newCampaign);
            }).finally(() => setLoading(false));
    }

    const exitModal = (isClosing) => {
        return <Modal isNested isOpen={exitModalIsOpen} fullscreen centered={true} id="exitModal">
            <ModalBody style={{ paddingBottom: '17px' }}>
                <p style={{
                    fontSize: '20px',
                    lineHeight: '28px',
                    fontWeight: '600'
                }}>
                    {t(TRANSLATION_PREFIX + "exitModalTitle")}
                </p>
                <p style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '19px'
                }}>
                    {t(TRANSLATION_PREFIX + "exitModalText")}
                </p>
                <div className="text-right">
                    <MDBBtn color="primary" onClick={() => { isClosing ? onClose() : onBack() }} style={{ width: '146px', textTransform: "none" }} size="sm" className="shadow-none font-size-small">
                        <strong style={{ fontSize: '14px' }}>
                            {(t(TRANSLATION_PREFIX + "exitModalYes"))}
                        </strong>
                    </MDBBtn>
                    <button className="link-like-btn font-size-small" style={{
                        fontSize: '14px',
                        color: '#19AA89',
                        fontWeight: '600',
                        lineHeight: '19px',
                        marginLeft: "17px"
                    }} onClick={() => { setExitModalIsOpen(false) }}>
                        {(t(TRANSLATION_PREFIX + "exitModalNo"))}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    }

    return <div>
        {exitModal(exitModalIsClosing)}
        <div className="d-flex justify-content-between">
            <button className="link-like-btn" onClick={goBack}>
                <MDBIcon icon="arrow-left" className="back-button" fas></MDBIcon>
            </button>
            <button className="link-like-btn" onClick={close}>
                <MDBIcon icon="times" className="back-button" fas></MDBIcon>
            </button>
        </div>
        <div className="new-campaign-header">
            <div>
                <h1>{t(TRANSLATION_PREFIX + "smartCampaignOfCategory", {
                    'CATEGORY': t(TRANSLATION_PREFIX_CAMPAIGNS + "otherLabels." + campaignType.categoryName)
                })}</h1>
            </div>
            <MDBContainer className="mt-3 smart-campaign-wizard-container">
                {campaign && <Formik
                    ref={formRef}
                    initialValues={getInitialValues(campaign)}
                    onSubmit={nextStep}
                    validationSchema={validationSchema(t, true, false)}
                >
                    <Form>
                        <div>
                            {stepTitle(wizardStep) && <h1 className="title">
                                {stepTitle(wizardStep)}
                            </h1>}
                            <div className={stepTitle(wizardStep) ? "mt-2" : "mt-5"}>
                                {renderStep(wizardStep)}
                            </div>
                        </div>
                        <MDBBtn type="submit" color="primary">{t(TRANSLATION_PREFIX + "saveAndNext")}</MDBBtn>
                    </Form>
                </Formik>}
                <CampaignBanner className="d-none d-md-block campaignBanner" type={(campaignType.categoryName)} />
            </MDBContainer>
        </div>
    </div>
}
