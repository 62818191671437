import React from "react";
import {Field, reduxForm} from "redux-form";
import {translate} from "react-i18next";
import InputAddressField from "../../commons/components/utils/form/InputAddressField";
import {connect} from "react-redux";
import InputField from "../../commons/components/utils/form/InputField";
import {WizardNavigationButtons} from "../../commons/components/utils/form/wizard/Wizard";
import {required, validURL} from "../../commons/components/utils/form/validations";

const MercadoPagoHelp = (props) => (
  <span className="help-block form-help">
            {/* eslint-disable-next-line */}
    <a href="#">Cómo configuro Mercado Pago?</a>
            </span>
)
class StoreForm extends React.Component {

  render =() => {
    const { /*t, handleSubmit, submitting,*/ nextHandler, storeId, onBoardingForm:form } = this.props;
    return (<div className="ibox-content pb-1">
      <form id={"form-"+storeId} onSubmit={(e) => {
        e.preventDefault();
        nextHandler({})
      }}>
        <Field
          name={"address"+storeId}
          component={InputAddressField}
          autoFocus
          placeholder='Ingrese la dirección'
          validate={required}
        />

        <div className="row">
          <div className="col">
          <Field
          type="text"
          name={"mpToken"+storeId}
          component={InputField}
          autoFocus
          placeholder='Ingrese el Token si usa Mercado Pago'
          label="Token Mercado Pago"
          help={MercadoPagoHelp}
          />
          </div>
        </div>

        <Field
          type="text"
          name={"facebook"+storeId}
          component={InputField}
          autoFocus
          placeholder='https://www.facebook.com/'
          icon={['fab', 'facebook']}
          label="Facebook"
          validate={[required, validURL]}
        />

        <Field
          type="text"
          name={"instagram"+storeId}
          component={InputField}
          autoFocus
          placeholder='https://www.instagram.com/'
          icon={['fab','instagram']}
          label="Instagram"
          validate={[required, validURL]}
        />
        <WizardNavigationButtons backHandler={this.props.backHandler}/>
        </form>
    </div>)
  }
}
const mapStateToProps = state => {
  return {
    onBoardingForm: state.form.onBoarding
  };
};
export default reduxForm({form:"onBoarding",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
  }) (connect(mapStateToProps, {}) (translate('common') (StoreForm)));