import { combineReducers } from "redux";

import { login } from "../../../components/login/loginReducer.js";
import { application } from "./applicationReducer.js";
import { misc } from "./miscReducer";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
//import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from "redux-persist";
import feedbackFiltersReducers from "../../../components/feedback/FeedbackFiltersReducers";

import storage from "redux-persist/lib/storage";
import CampaignListFiltersReducers from "../../../components/campaigns/CampaignListFilters/CampaignListFiltersReducers.js";
import {reporterReducer} from "../../../commons/reporter/reporter"
//const loggerMiddleware = createLogger();

const defaultPersistConfig = {
  key: "local",
  storage: storage,
  whitelist: ["login", "application"],
};
//FIXME: lograr que application quede en session storage

const sessionPersistConfig = {
  key: "session",
  storage: storage,
  whitelist: ["application2"],
};

const rootReducer = combineReducers({
  login,
  application: persistReducer(sessionPersistConfig, application),
  misc,
  form: formReducer,
  feedback: feedbackFiltersReducers,
  campaignList: CampaignListFiltersReducers,
  reporter: reporterReducer(20)
});

const persistedReducer = persistReducer(defaultPersistConfig, rootReducer);
let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(
      //loggerMiddleware
      thunk
    )
  )
);

export const persistor = persistStore(store);
