import React from 'react';
import { getTasks, exportTasks } from '../../api/taskApi.js';
import { CollectionVisualizer } from 'botbit-ui-components';
import { translate } from 'react-i18next';
import { MDBIcon, MDBBadge } from 'mdbreact';
import TimeAgo from '../../commons/components/utils/TimeAgo.js';
import TaskContainer from '../clients/TaskContainer.js';
import { connect } from 'react-redux';
import deepCopy from '../../commons/utils/deepCopy';
import {
  getAllTimePeriods as getAllTimePeriodsUI,
  timePeriodsIds,
} from 'botbit-ui-components';
import { getStoreAdminitrators } from '../../api/administratorApi.js';
import './Tasks.css';
import withStoreSelector from '../clients/withStoreSelector.js';
import { firePageView } from "../../commons/components/utils/trackers";

const getAllTimePeriods = () => {
  let tp = getAllTimePeriodsUI();
  tp.push(timePeriodsIds.CUSTOM);
  return tp;
};

const getDefaultFilterValues = (t, admins, stores) => {
  return [
    {
      id: 'creationDate',
      label: 'Creación',
      type: 'timeperiod',
      icon: 'calendar-alt',
      className: 'col-12',
      placeholder: 'Creación',
      priority: true,
      visible: true,
      options: getAllTimePeriods(),
    },
    {
      id: 'dueDate',
      label: 'Vencimiento',
      type: 'timeperiod',
      icon: 'calendar-alt',
      className: 'col-12',
      placeholder: 'Vencimiento',
      priority: true,
      visible: true,
      options: getAllTimePeriods(),
    },
    {
      id: 'closeDate',
      label: 'Cierre',
      type: 'timeperiod',
      icon: 'calendar-alt',
      className: 'col-12',
      placeholder: 'Cierre',
      priority: true,
      visible: true,
      options: getAllTimePeriods(),
    },
    {
      id: 'withHistory',
      label: 'Tareas con actividad',
      type: 'timeperiod',
      icon: 'calendar-alt',
      className: 'col-12',
      placeholder: 'Tareas con actividad',
      priority: true,
      visible: true,
      options: getAllTimePeriods(),
    },
    {
      id: 'userFilter',
      label: 'Usuario',
      type: 'input',
      className: 'col-12',
      placeholder: 'Usuario',
      priority: false,
      visible: true,
    },
    {
      id: 'status',
      label: 'Estado',
      type: 'multiselect',
      search: false,
      className: 'col-12',
      placeholder: 'Estado',
      priority: true,
      visible: true,
      values: [
        { text: t('taskStatusType.1'), value: 1, checked: true },
        { text: t('taskStatusType.2'), value: 2 },
        { text: t('taskStatusType.3'), value: 3, checked: true },
      ],
    },
    {
      id: 'adminFrom',
      label: 'Creador',
      type: 'select',
      search: true,
      className: 'col-12',
      placeholder: 'Creador',
      priority: false,
      visible: true,
      values: admins.map((a) => {
        return {
          checked: false,
          value: a.id,
          text: `${a.name} ${a.lastName}`,
        };
      }),
    },
    {
      id: 'adminTo',
      label: 'Responsable',
      type: 'select',
      search: true,
      className: 'col-12',
      placeholder: 'Responsable',
      priority: false,
      visible: true,
      values: admins.map((a) => {
        return {
          value: a.id,
          text: `${a.name} ${a.lastName}`,
        };
      }),
    },
    {
      id: 'inactiveAdministratorOnly',
      label: 'Tareas asignadas a administradores inactivos',
      type: 'switch',
      priority: false,
      visible: true,
      checked: false
    }
  ];
};

const getInitialFilterValues = (t, admins, logedAdmin, stores) => {
  return [
    // {
    // 	id: "storeIds",
    // 	label:"Tienda",
    // 	type: "multiselect",
    // 	className: 'col-12',
    // 	placeholder: 'Tienda',
    // 	priority: true,
    // 	visible: true,
    // 	search: true,
    // 	values: stores.map(s=>
    // 		{
    // 			return {
    // 				checked: false,
    // 				value: s.id,
    // 				text: `${s.name}`
    // 			}
    // 		}
    // 	)
    // },
    
    {
      id: 'creationDate',
      label: 'Creación',
      type: 'timeperiod',
      icon: 'calendar-alt',
      className: 'col-12',
      placeholder: 'Creación',
      priority: true,
      visible: true,
      options: getAllTimePeriods(),
    },
    {
      id: 'dueDate',
      label: 'Vencimiento',
      type: 'timeperiod',
      icon: 'calendar-alt',
      className: 'col-12',
      placeholder: 'Vencimiento',
      priority: true,
      visible: true,
      options: getAllTimePeriods(),
    },
    {
      id: 'closeDate',
      label: 'Cierre',
      type: 'timeperiod',
      icon: 'calendar-alt',
      className: 'col-12',
      placeholder: 'Cierre',
      priority: true,
      visible: true,
      options: getAllTimePeriods(),
    },
    {
      id: 'withHistory',
      label: 'Tareas con actividad',
      type: 'timeperiod',
      icon: 'calendar-alt',
      className: 'col-12',
      placeholder: 'Tareas con actividad',
      priority: true,
      visible: true,
      options: getAllTimePeriods(),
    },
    {
      id: 'userFilter',
      label: 'Usuario',
      type: 'input',
      className: 'col-12',
      placeholder: 'Usuario',
      priority: false,
      visible: true,
    },
    {
      id: 'status',
      label: 'Estado',
      type: 'multiselect',
      search: false,
      className: 'col-12',
      placeholder: 'Estado',
      priority: false,
      visible: true,
      values: [
        { text: t('taskStatusType.1'), value: 1, checked: true },
        { text: t('taskStatusType.2'), value: 2 },
        { text: t('taskStatusType.3'), value: 3, checked: true },
      ],
    },
    {
      id: 'adminFrom',
      label: 'Creador',
      type: 'select',
      search: true,
      className: 'col-12',
      placeholder: 'Creador',
      priority: false,
      visible: true,
      values: admins.map((a) => {
        return {
          checked: false,
          value: a.id,
          text: `${a.name} ${a.lastName}`,
        };
      }),
    },
    {
      id: 'adminTo',
      label: 'Responsable',
      type: 'select',
      search: true,
      className: 'col-12',
      placeholder: 'Responsable',
      priority: false,
      visible: true,
      values: admins.map((a) => {
        return {
          checked: a.id === logedAdmin.id,
          value: a.id,
          text: `${a.name} ${a.lastName}`,
        };
      }),
    },
    {
      id: 'inactiveAdministratorOnly',
      label: 'Tareas asignadas a administradores inactivos',
      type: 'switch',
      priority: false,
      visible: true,
      checked: false
    }
  ];
};

class TasksContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async updateAdministrators() {
    const selectedStores =
      this.props.selectedStore && this.props.selectedStore.id !== -1
        ? this.props.selectedStore.id
        : this.props.availableStores;
    let response = await getStoreAdminitrators(selectedStores);
    this.setState({
      admins: response.data.data,
      filters: getInitialFilterValues(
        this.props.t,
        response.data.data,
        this.props.admin,
        this.props.availableStores
      ),
    });
    this.props.reRender(new Date().toLocaleString());
  }

  componentDidMount() {
    this.updateAdministrators();
    firePageView();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedStore !== this.props.selectedStore) {
      this.updateAdministrators();
    }
  }

  render() {
    if (!this.state.admins) return null;
    const { t } = this.props;
    const columns = [
      {
        dataField: 'type',
        title: 'Objetivo',
        classes: 'align-middle',
        sort: true,
        className: 'm-auto d-none d-md-block',
        size: '1',
        formatter: (cellContent, row) => {
          return <div>{row.typeDetail ? row.typeDetail.label : ''}</div>;
        },
      },
      {
        dataField: 'timestamp',
        title: 'Creada',
        classes: 'align-middle',
        sort: true,
        className: 'm-auto d-none d-md-block',
        size: '2',
        formatter: (cellContent, row) => {
          return (
            <TimeAgo
              date={cellContent ? cellContent : undefined}
              noDateText="panels.clients.noRegistered"
            />
          );
        },
      },
      {
        dataField: 'user',
        title: 'Usuario',
        classes: 'align-middle',
        className: 'm-auto d-none d-md-block',
        sort: true,
        formatter: (cellContent, row) => {
          return <PersonLinkedName person={row.user} />;
        },
        size: '2',
      },
      {
        dataField: 'user',
        title: 'Usuario',
        classes: 'align-left',
        className: 'row-title d-md-none',
        sort: true,
        isHeader: true,
        formatter: (cellContent, row) => {
          return <PersonLinkedName label="Usuario: " person={row.user} />;
        },
        size: 12,
      },
      {
        dataField: 'adminTo',
        title: 'Responsable',
        classes: 'align-middle',
        sort: true,
        className: 'm-auto d-none d-md-block',
        formatter: (cellContent, row) => {
          return (
            <span>
              {row.adminTo ? `${row.adminTo.name} ${row.adminTo.lastName}` : ''}{' '}
            </span>
          );
        },
        size: '2',
      },
      {
        dataField: 'adminTo',
        title: 'Responsable',
        classes: 'align-left',
        className: 'row-title d-md-none',
        sort: true,
        isHeader: true,
        formatter: (cellContent, row) => {
          return (
            <span>
              {row.adminTo
                ? `Responsable: ${row.adminTo.name} ${row.adminTo.lastName}`
                : ''}{' '}
            </span>
          );
        },
        size: 12,
      },
      {
        dataField: 'channel',
        title: 'Canal',
        classes: 'align-middle col-4 col-md-1',
        sort: true,
        className: 'm-auto',
        formatter: (cellContent, row) => {
          return <ChannelIcon channelId={row.channel} />;
        },
      },
      {
        dataField: 'dueDate',
        title: 'Vence',
        classes: 'align-middle',
        sort: true,
        className: 'm-auto col-4 col-md-1',
        formatter: (cellContent, row) => {
          if (!cellContent) return null;
          let dueDate = new Date(cellContent);
          dueDate.setHours(23);
          dueDate.setMinutes(59);
          const now = new Date();
          const cerrada = row.status ? row.status.id === 2 : false;
          let color;
          if (cerrada) color = 'success';
          else {
            const vencida = now.getTime() > dueDate.getTime();
            if (!vencida) {
              const venceHoy =
                dueDate.getFullYear() === now.getFullYear() &&
                dueDate.getMonth() === now.getMonth() &&
                dueDate.getDate() === now.getDate();
              if (!venceHoy) color = 'success';
              else color = 'warning';
            } else color = 'danger';
          }

          return (
            <MDBBadge color={color}>{`${dueDate.getDate()}/${dueDate.getMonth() + 1
              }/${dueDate.getFullYear()}`}</MDBBadge>
          );
        },
      },
      {
        dataField: 'closeDate',
        title: 'Cerrada',
        classes: 'align-middle',
        className: 'm-auto d-none d-md-block col-2 col-md-1',
        sort: true,
        formatter: (cellContent, row) => {
          if (!cellContent) return null;
          let closeDate = new Date(cellContent);
          closeDate.setHours(23);
          closeDate.setMinutes(59);
          return (
            <MDBBadge color='info'>{`${closeDate.getDate()}/${closeDate.getMonth() + 1
              }/${closeDate.getFullYear()}`}</MDBBadge>
          );
        },
      },
      {
        dataField: 'status',
        title: 'Estado',
        className: 'm-auto col-4 col-md-1 small',
        sort: true,
        formatter: (cellContent, row) => {
          if (!row.status) return null;
          let color = undefined;
          switch (row.status.id) {
            case 1:
              color = 'warning';
              break;
            case 2:
              color = 'muted';
              break;
            case 3:
              color = 'warning';
              break;
            default:
              color = 'primary';
              break;
          }
          return (
            <span className={`text-uppercase text-${color}`}>
              {t('taskStatusType.' + row.status.id)}
            </span>
          );
        },
      },
    ];
    const defaultFilters = getDefaultFilterValues(
      t,
      this.state.admins,
      this.props.availableStores
    );
    const selectedStores =
      this.props.selectedStore && this.props.selectedStore.id !== -1
        ? [this.props.selectedStore.id]
        : this.props.availableStores.map((s) => s.id);
    return (
      <div className="mx-lg-5  py-5 mb-5">
        {this.state.filters && (
          <CollectionVisualizer
            scrollTopButton
            title={this.props.t('menu.tasks.text')}
            headerActions={[]}
            export={(order, filters) =>
              exportTasks(order, { stores: selectedStores, ...filters })
            }
            exportName={`TasksExport-${Date.now()}`}
            formats={[{ type: 'text/csv', format: 'csv' }]}
            filtersTitle={'Buscar tareas'}
            columns={columns}
            getData={(page, order, filters) =>
              getTasks(page, order, { stores: selectedStores, ...filters })
            }
            dataPath="data"
            filters={this.state.filters}
            defaultFilters={deepCopy(defaultFilters)}
            onResetFilters={() =>
              this.setState({ filters: deepCopy(defaultFilters) })
            }
            onFiltersApply={(values, newFilters) =>
              this.setState({ filters: newFilters })
            }
            alwaysFloatingFilter={true}
            showFiltersInfo={{}}
            initialOrder={{
              name: 'dueDate',
              direction: 'DESC',
            }}
            expandRow={(active, row, onRowChanged) => {
              return (
                active === true && (
                  <div className="pt-2 pb-5 px-lg-5 gray-bg">
                    <TaskContainer
                      taskId={row.id}
                      onSubmit={onRowChanged}
                      selectedStore={this.props.selectedStore}
                    ></TaskContainer>
                  </div>
                )
              );
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.login.admin,
  };
};

export default withStoreSelector(
  connect(mapStateToProps)(translate('common')(TasksContainer))
);

const PersonLinkedName = ({ label, person, detailUrl }) => {
  let text = (
    <span>
      {label} {person ? `${person.name} ${person.lastName}` : person.name}
    </span>
  );
  if (detailUrl) text = <a href={detailUrl}>{text}</a>;
  return text;
};

export const ChannelIcon = ({ channelId }) => {
  switch (channelId) {
    case 1:
      return <MDBIcon size="2x" className="icon" icon="at"></MDBIcon>;
    case 2:
      return <MDBIcon size="2x" className="icon" icon="handshake"></MDBIcon>;
    case 3:
      return <MDBIcon size="2x" className="icon" icon="phone"></MDBIcon>;
    case 4:
      return <i className="fa-whatsapp fa-2x fab icon"></i>;
    default:
      return null;
  }
};
