export const translations = {
	title: {
		es: 'Encuestas personalizadas',
		en: 'Custom surveys'
	},
    store: {
        es: "Tienda",
        en: "Store"
    },
    timePeriod: {
        es: "Periodo",
        en: "Period"
    },
    company: {
        es: "Empresa",
        en: "Company"
    },
	noData: {
		es: "No tienes encuestas activas",
		en: "You dont have active surveys"
	}

}