import axiosClient from './axiosClient';

export const getCampaignAnalytics = (campaignId, period, axiosErrorHandler = undefined) => {
    let periodFrom;
    let periodTo;
    if(period){
        periodFrom = period.from ? `?from=${(period.from.getFullYear() + '-' + (period.from.getMonth()+1) + '-' + period.from.getDate())}` : ''
        periodTo = period.to ? `&to=${period.to.getFullYear() + '-' + (period.to.getMonth()+1) + '-' + period.to.getDate()}` : ''
    }
    return axiosClient.get(`campaigns/${campaignId}/analytics${periodFrom}${periodTo}`, {errorHandler: axiosErrorHandler});
    // return axiosClient.get('http://www.mocky.io/v2/5e74ee21300000d488a5f82b')
}

export const getTargetedUsers = (campaignId, axiosErrorHandler = undefined) => {
    return axiosClient.get(`campaigns/${campaignId}/targeted-users`, { errorHandler: axiosErrorHandler });
}

export const getClientsAnalytics = (campaignId, period, pageNumber, eventSubType) => {
    let periodFrom;
    let periodTo;
    let pageSize = 12;
    if(period){
        periodFrom = period.from ? `&from=${(period.from.getFullYear() + '-' + (period.from.getMonth()+1) + '-' + period.from.getDate())}` : ''
        periodTo = period.to ? `&to=${period.to.getFullYear() + '-' + (period.to.getMonth()+1) + '-' + period.to.getDate()}` : ''
    }
    let url = `/campaigns/${campaignId}/analytics/events?pageNumber=${pageNumber}&pageSize=${pageSize}${periodFrom}${periodTo}&eventSubType=${eventSubType}`
    // let url = 'http://www.mocky.io/v2/5e857569300000c70297b012'
    return new Promise((resolve) => {
		axiosClient.get(url)
        .then((res) =>{
            res.data.pageSize = pageSize;
            resolve(res);
        });
	});
}

