export const QuartzCronFormatterParse = (cron) => {
  let parsedCron = { cronExpression: cron };
  var parts = cron.split(' ');
  if (parts.length !== 7) {
    console.warn('Invalid cron expression. Skip parsing...');
  } else {
    let hours = parts[2];
    let minutes = parts[1];

    if (minutes.indexOf('/') !== -1) {
      //minutes
      minutes = minutes.substring(2, minutes.length);
      parsedCron = {
        ...parsedCron,
        value: 'minutes',
        checked: true,
        minutesRepeater: minutes,
      };
    } else if (parts[4] === '*' && parts[5] === '?' && parts[6] === '*') {
      // daily
      parsedCron = {
        ...parsedCron,
        value: 'daily',
        minutes: minutes,
        checked: true,
        hours: hours,
      };
    } else if (parts[4] === '*' && parts[5] !== '?') {
      // weekly
      let daysOfWeek = parts[5] === '' ? [] : parts[5].split(',');
      parsedCron = {
        ...parsedCron,
        value: 'weekly',
        minutes: minutes,
        hours: hours,
        checked: true,
        daysOfWeek: daysOfWeek,
      };
    } else if (parts[4] !== '*') {
      // monthly
      parsedCron = {
        ...parsedCron,
        value: 'monthly',
        dayNumber: parts[3],
        checked: true,
        monthRepeater: parts[4].split('/')[1],
      };
    }
  }
  return parsedCron;
};

export const QuartzCronFormatterBuild = ({
  value,
  minutesRepeater,
  minutes,
  hours,
  daysOfWeek,
  dayNumber,
  monthRepeater,
}) => {
  switch (value) {
    case 'minutes':
      return `0 0/${minutesRepeater} * ? * * *`;
    case 'daily':
      return '0 ' + minutes + ' ' + hours + ' 1/1 * ? *';
    case 'weekly':
      var dow = daysOfWeek.sort().join(',');
      return '0 ' + minutes + ' ' + hours + ' ? * ' + dow + ' *';
    case 'monthly':
      return '0 0 0 ' + dayNumber + ' 1/' + monthRepeater + ' ? *';
    default:
      break;
  }
};
