import { loginConstants } from './loginConstants';

// let user = JSON.parse(localStorage.getItem('user'));
// const initialState = user ? { loggedIn: true, user } : {};

function getAllOperations(profile) {
	var operations = [];
	for (var operation of profile.operations) {
		operations.push (operation.id);
	}
	if (profile.children != null) {
		for (var childProfile of profile.children) {
			var childOperations = getAllOperations(childProfile);
			operations = [ ...operations, ...childOperations ];
		}
	}
	return operations;
}

function removeDuplicates(array) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }
    return a;
}

export function login(state = { loggedIn: false }, action) {
	switch (action.type) {
		case loginConstants.LOGIN_SUCCESS:
			var maxObjectAccess = null;
			var allOperations = [];
			for (var profile of action.admin.profiles) {
				var profileOperations = getAllOperations(profile);
				if (maxObjectAccess == null) {
					maxObjectAccess = profile.objectAccess;
				} else {
					// SELF es el mas bajo, lo piso
					if (maxObjectAccess === "SELF") {
						maxObjectAccess = profile.objectAccess;
					}
					// si es COMPANY lo piso solamente si tengo ALL
					if (maxObjectAccess === "COMPANY" && profile.objectAccess === "ALL") {
						maxObjectAccess = "ALL";
					}
				}
				allOperations = [ ...allOperations, ...profileOperations];
			}
			allOperations = removeDuplicates(allOperations);
			action.admin.operations = allOperations;
			action.admin.objectAccess = maxObjectAccess;
			return {
				...state,
				loggedIn: true,
				loading: false,
				admin: action.admin,
				token: action.token,
				rememberMe: action.rememberSesion
			};
		case loginConstants.LOGIN_REQUEST:
			return {
				...state,
				loading: true,
				error: undefined
			};
		case loginConstants.LOGIN_FAILURE:
			return {
				loggedIn: false,
				loading: false,
				error: action.error.response,
				token: null
			};
		case loginConstants.LOGOUT:
			return {
				loggedIn: false,
				token: null,
			};
		default:
			return state
	}
}
