import React, { useState } from 'react';
import CustomInput from './CustomInput';
import "./MessengerCore.scss"
import {  MDBIcon } from 'mdbreact';
import useWindowDimensions from '../../commons/utils/useWindowDimensions';
import { useMessengerData } from './useMessengerData';
import { Actions, RenderMode } from 'botbit-ui-components';
import MessagesList from './MessagesList';
import { ConversationCreation } from './ConversationCreation';
import ImageViewer from 'react-simple-image-viewer'
import ImagePreviewer from './ImagePreviewer';
import { envConfig } from "../../config/config";
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Messenger/Messenger";
import AssignConversation from "./AssignConversation"
import { useSelector } from 'react-redux';
import { Collapsable } from 'botbit-ui-components';
import { IssueContainer } from '../Issues/IssuesContainer';

const MessengerCore = ({t, className, showBack}) => {
    const { 
        selectedChat, 
        closeChat, 
        markConversationAsClosed, 
        markConversationAsOpen, 
        closeImageInImageViewer, 
        selectedImages, 
        isImageViewerOpen, 
        isImagePreviewerOpen,
        administrators,
        assignConversationWrapper,
        unassignConversationWrapper,
        selectedStore,
        conversationIssue
     } = useMessengerData();


    const [assignConversationModalOpen, setAssignConversationModalOpen] = useState(false);
    const { isMedium } = useWindowDimensions()

    const adminOperations = useSelector(state => state.login.admin.operations)
    const chatIsOpen = selectedChat && selectedChat.state === 'OPEN';
    let adminHasAssignerOperation = adminOperations.includes("CONVERSATION__ASSIGN");

    let isAssigned = (selectedChat && selectedChat.currentAssignment);
    const assignedAdministrator = isAssigned ? selectedChat.currentAssignment.assignedAdministrator : undefined


    const actions = [
        {
            key: 0,
            id: chatIsOpen ? "close-conversation" : "open-conversation",
            color: "white",
            icon: chatIsOpen ? "check" : "envelope-open",
            label: chatIsOpen ? 'Cerrar conversación' : 'Abrir conversación',
            onClick: () => {
                if (chatIsOpen)
                    markConversationAsClosed();
                else
                    markConversationAsOpen();
            }
        }
    ];
    

    return (
        <div className={"messenger-core "+className}>
            {isImageViewerOpen &&
                <ImageViewer 
                    src={selectedImages}
                    currentIndex={0}
                    onClose={closeImageInImageViewer}
                />
            }
            {isImagePreviewerOpen &&
                <ImagePreviewer />
            }
            {selectedChat && selectedChat.id
                ? <>
                    <div className="conversation-header">
                        <div className="conversation-info w-50">
                            {showBack && isMedium && <MDBIcon
                                fas
                                icon="angle-left"
                                onClick={closeChat}
                                style={{
                                    fontSize: "18px",
                                    padding: "6px 6px 6px 0"
                                }}
                            />}
                            {/* Por los momentos dejamos comentado el avatar, podría usarse luego */}
                            {/* <div className="avatar-container">
                                <div className="w-100">
                                    <img src={`https://admin.botbit.io/static/img/avatars/${selectedChat.user.avatar}`} style={{ width: isMedium ? "45px" : "55px" }} className="rounded-circle" alt="" />
                                </div>
                            </div> */}
                            <div className="w-100">
                                <h4 className="conversation-name">{selectedChat.user.fullName}</h4>
                                {
                                    selectedChat && adminHasAssignerOperation &&
                                    <Collapsable
                                        padding="d-block"
                                        noButton
                                        isCard={true}
                                        isOpen={assignConversationModalOpen}
                                        toggle={() => setAssignConversationModalOpen(!assignConversationModalOpen)}
                                        notCollapsed={
                                            <div className="w-100">
                                                <span className='not-collapsed-label text-dark p-1'>{isAssigned ? t(`assignedAdministrator`)+assignedAdministrator.fullName : t(`assignAdministrator`)}</span>
                                            </div>
                                        }
                                        collapsed={
                                            <div>
                                                { 
                                                    assignConversationModalOpen &&
                                                    <AssignConversation
                                                        selectedAdministratorId={isAssigned ? assignedAdministrator.id : undefined}
                                                        administrators={ administrators.filter(a=> !assignedAdministrator || a.id!==assignedAdministrator.id) }
                                                        assignCallback={ (administratorId, assignComment, notifyUser) => {
                                                            assignConversationWrapper(selectedChat.id, administratorId, assignComment, notifyUser);
                                                            setAssignConversationModalOpen(false);
                                                        }}
                                                        unassignCallback={(comment) => {
                                                            unassignConversationWrapper(selectedChat.id, comment);
                                                            setAssignConversationModalOpen(false);
                                                        }}
                                                        showNotifyUser={selectedChat.expiredState==="ACTIVE"}
                                                        onClickOutside={()=>setAssignConversationModalOpen(false)}
                                                    />
                                                }
                                            </div>
                                        }
                                    />
                                }
                            </div>
                        </div>

                        
                        <div className='d-flex'>

                            {
                                selectedChat && selectedStore &&
                                <IssueContainer
                                    storeId={selectedStore.id}
                                    userId={selectedChat.user.id}
                                    conversationId={selectedChat.id}
                                    conversationIssue={conversationIssue}
                                ></IssueContainer>
                            }
                            {selectedChat.lastMessageTimestamp && <Actions
                                actionsPrefix="chat"
                                renderMode={RenderMode.DEFAULT}
                                actions={actions} />}
                        </div>

                    </div>
                    {/* <div ref={messagesContainer} className="messages-container">
                        {messages && messages.length > 0
                            ? <ul>
                                {messages.map(m => (
                                    <Message
                                        key={Math.random()}
                                        messageObj={m}
                                    />
                                ))}
                            </ul>
                            : null
                        }
                    </div> */}
                    <MessagesList />
                </>
                : <ConversationCreation/>
            }
            {selectedChat && (selectedChat.id || selectedChat.user) && <CustomInput/>}
            {
                // Not selected chat empty state
                !selectedChat && 
                    <div className="chat-empty-state">
                            <img src={envConfig.staticImgUrl + "chat-empty-state.png"} alt="empty-state" />
                            <h3>{t("emptyChatTitle")}</h3>
                            <p>{t("emptyChatSubtitle")}</p>
                    </div>
            }
        </div>
    )
}

MessengerCore.translations = { ...translations }

export default translate(MessengerCore);
