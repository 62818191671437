import React from 'react';

import { comparePaths, menuConfig } from '../../../config/menu.js';
import NavigationHeader from './NavigationHeader';
import NavigationItem from './NavigationItem';
import { withRouter } from 'react-router-dom';
import '../layout.css';
import { connect } from 'react-redux';
// import { envConfig } from '../../../config/config.js';

class Navigation extends React.Component {

	constructor(props) {
		super(props);
		var menu = this.initMenu(this.props.history.location.pathname, menuConfig(this.props.selectedCompany, this.props.admin).menu);
		this.state = { menu: menu };
	}

	componentDidUpdate(prevProps) {
		// console.log ("navigation props, actual and prev", this.props.location.pathname, prevProps.location.pathname)
		// console.log ('Navigation', this.props.admin)
		if (prevProps.selectedCompany.id !== this.props.selectedCompany.id 
			|| prevProps.selectedCompany.operations !== this.props.selectedCompany.operations 
			|| this.props.location.pathname !== prevProps.location.pathname) {
			const menu = this.initMenu(this.props.history.location.pathname, menuConfig(this.props.selectedCompany, this.props.admin).menu);
			this.setState({ menu: menu })
		}
	}

	handleClick = (clickedItem, event, target) => {
		event.preventDefault();

		if (target === "_blank") {
			window.open(clickedItem.url, target);
			return;
		}

		// el item no tiene hijos y tiene URL, debo ir a la URL
		if ((clickedItem.children == null || clickedItem.noChildrenMenu) && clickedItem.url != null) {
			const selectedCompanyId = (this.props.selectedCompany == null) ? -1 : this.props.selectedCompany.id;
			// url externa php
			if (clickedItem.url.startsWith('http')) {
				var newUrl = clickedItem.url;
				if (newUrl.indexOf("?") > 0)
					newUrl = newUrl + `&sso=${this.props.token}&companyId=${selectedCompanyId}`;
				else
					newUrl = newUrl + `?sso=${this.props.token}&companyId=${selectedCompanyId}`;

				window.location.href = newUrl;
			}
			// es una URL node.js
			else {
				const { menu } = this.state;
				this.props.history.push(clickedItem.url);
				this.setState((state) => {
					// Important: read `state` instead of `this.state` when updating.
					return { menu: this.initMenu(this.props.history.location.pathname, menu) }
				});
			}
		} else if (clickedItem.children != null) {
			const { menu } = this.state;
			const prevState = clickedItem.active;

			for (const item of menu) {
				if (item.key === clickedItem.key) {
					item.active = !item.active;
				} else {
					// estoy expandiendo, entonces colapso todo el resto
					if (!prevState)
						item.active = false;
				}
			}
			this.setState((state) => {
				// Important: read `state` instead of `this.state` when updating.
				return { menu: menu }
			});


			// this.setState({
			// 	menu: menu
			// });
		}
	}

	initMenu(url, menu) {
		for (const item of menu) {
			var isActive = false;
			if (item.children) {
				for (const subitem of item.children) {

					let anyChildrenIsActive = false;
					if (subitem.possibleChildren) {
						anyChildrenIsActive = !!subitem.possibleChildren.find(path => comparePaths(path, url));
					}

					if (subitem.url === url || anyChildrenIsActive) {
						isActive = true;
						subitem.active = true;
					} else {
						subitem.active = false;
					}
				}
			}
			item.active = isActive;
		}
		return menu;
	}

	render() {
		const { menu } = this.state;
		const { navigationMenuExpanded, admin } = this.props;
		const selectedCompanyId = (this.props.selectedCompany == null) ? -1 : this.props.selectedCompany.id;

		document.body.classList.toggle("mini-navbar", !navigationMenuExpanded);

		return (
			<>
				<nav className="navbar-default navbar-static-side" role="navigation">
					<div className="sidebar-collapse">
						<ul className="nav metismenu" id="side-menu">
							<NavigationHeader handleLogout={this.props.handleLogout} admin={admin} token={this.props.token} selectedCompanyId={selectedCompanyId} />
							{
								menu.map(function (object, index) {
									return (<NavigationItem
										key={object.key}
										item={object}
										clickFn={this.handleClick.bind(this)}
										admin={admin}
										selectedCompany={this.props.selectedCompany}
										navigationMenuExpanded={this.props.navigationMenuExpanded}
										token={this.props.token} />)
								}, this)
							}
						</ul>
					</div>
				</nav>
			</>
		)
	}
}

const mapStateToProps = state => ({
	selectedCompany: state.application.selectedCompany
})

export default connect(mapStateToProps)(withRouter(Navigation))
