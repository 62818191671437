import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { envConfig } from "../../../config/config";

export const initialize = () => {
	ReactGA.initialize(envConfig.GA_ID_ADMIN);
	// habilitar para debug, mucho mejor que console.log
	//ReactGA.initialize(envConfig.GA_ID_ADMIN, { debug: true });

	//console.log("initializing GA: ", envConfig.GA_ID_ADMIN);

	// Adrian: 2020-11-24
	// Se prueba que cada 15 segundos se envíe
	// la info de page view a GA, para tener una información
	// más precisa del tiempo de permanencia de cada página
	// y del bounce rate
	// Adrian: 2020-11-26
	// Funciona bien, pero cada llamada a pageview
	// cuenta como un hit distinto y, lo peor, es que
	// parece reiniciar el tiempo de permanencia :-(
	// clavando el tiempo promedio de la sesión en, justamente,
	// 15 segundos!!
	/* setInterval(() => {
	  ReactGA.pageview(window.location.pathname)
	}, 15000) */

	if (envConfig.HOTJAR_HJID !== undefined && envConfig.HOTJAR_HJSV !== undefined)
		hotjar.initialize(envConfig.HOTJAR_HJID, envConfig.HOTJAR_HJSV);
}

export const identify = (user, signUpDate) => {
	if (hotjar.initialized() && user) {
		console.log("Identify User in Hot jar", user)
		hotjar.identify(user.id, { name: user.name, lastName: user.lastName, email: user.email, username: user.username, signUpDate:signUpDate });
	}
}

export const firePageView = () => {
	//ReactGA.pageview(window.location.pathname + window.location.search)
	//console.log('firePV', window.location.pathname);
	ReactGA.pageview(window.location.pathname);
};

export const sendGAEvent = (category, action, label, value, nonInteraction, transport) => {
	let event = { category: category, action: action };

	if (label) {
		event.label = label;
	}

	if (value) {
		event.value = value;
	}

	if (nonInteraction) {
		event.nonInteraction = nonInteraction;
	}

	if (transport) {
		event.transport = transport;
	}

	ReactGA.event(event);
};

export const EventEnums = {
	category: {
		product: 'product'
	}
}
