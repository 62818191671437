import React, { useEffect, useState } from 'react';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Issues/IssueTypeEditContainer";
import { getIssueType, saveIssueType } from '../../api/issuesApi';
import * as Yup from 'yup';
import { MDBBtn, MDBContainer, MDBIcon, MDBInput, MDBSwitch } from "mdbreact";
import { FieldArray, Formik, FastField, ErrorMessage, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import "./IssueTypeEditContainer.scss"

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const IssuesTypeEditContainer = ({ t, issueTypeId }) => {
	const [issueInEdition, setIssueinEdition] = useState(null);
	const history=useHistory();
	const selectedCompany = useSelector(state => state.application.selectedCompany);
	
	const emptyIssueType = {
		name: "",
		description: "",
		states: [],
		company: {
			id: selectedCompany.id
		}
	}

	useEffect(() => {
		if (issueTypeId) {
			getIssueTypeSynch(issueTypeId);
		} else {
			setIssueinEdition(emptyIssueType);
		}
	}, [])

	const getIssueTypeSynch = async (issueTypeId) => {
		let response = await getIssueType(issueTypeId);
		setIssueinEdition(response.data.data)
	}

	const buildSimpleInput = (elementType, elementName, labelKey) => {
		var containerClass = "d-flex justify-content-start mb-2";
		var labelClass = "d-none d-md-flex align-items-center mb-2 form-label ml-4";
		if (elementType === "switch") {
			containerClass = containerClass + " adjust-switch"
		}
		if (elementType === "text")
			labelClass = labelClass + " mr-3"
		

		return <div key={elementName}>
			<div className={containerClass}>
				<div className={labelClass} >
					{t(labelKey)}:
				</div>
				<div>
					<FastField
						name={elementName}
						render={(props) => {
							return (
								(elementType === "text" && <MDBInput
									containerClass="mt-0 mb-0"
									type="text"
									value={props.field.value}
									getValue={(value) => { 
										props.form.setFieldValue(props.field.name, value) 
									}}
								/>) || 
								(elementType === "switch" && <MDBSwitch
									labelLeft="" 
									labelRight=""
									checked={props.field.value}
									onChange={() => {
										props.form.setFieldValue(props.field.name, !props.field.value);
									}}
								/>) || 
								<p>UNSUPPORTED TYPE</p>
							);
						}}
					/>
					{ elementType !== "switch" && <ErrorMessage name={elementName} component="div"
						className="invalid-feedback d-block"
						style={{ marginTop: '0px' }} />
					}
				</div>
			</div>
		</div>
	}

	const buildArrayField  = (values) => {
		return <div key="states">
			<FieldArray
				name="states"
				render={(props) => {
					return (
						<>
							<div className="d-flex justify-content-start mb-2 mt-4">
								<h2 className="subtitle">{t("statesTitle")}</h2>
								<span style={{ textDecoration: "underline", cursor: "pointer", color: "blue" }}
									onClick={ () => { props.push( {
										name: "",
										description: "",
										isFinalState: false
									});
								}}>
									{t("addState")}
								</span>
							</div>
							{   
								values.states.map ((state, index) => (
									<div className="d-flex justify-content-start state-block" key={index}>
										<div>
											<div className="d-flex justify-content-start" key={index}>
												{ buildSimpleInput("text", `states.${index}.name`, "stateNameLabel") } 
												{ buildSimpleInput("switch", `states.${index}.isFinalState`, "stateFinalStateLabel") } 
											</div>
											{ buildSimpleInput("text", `states.${index}.description`, "stateDescriptionLabel") } 
										</div>
										<div className="remove-state ml-4">
											<MDBIcon icon='trash' onClick={ () => { props.remove(index); }}/>
										</div>
									</div>
								))
							}

						</>
					);
				}}
			/>
		</div>
	}

	const buildForm = (values) => {
		const nameField = buildSimpleInput("text", "name", "issueTypeNameLabel")
		const descriptionField = buildSimpleInput("text", "description", "issueTypeDescriptionLabel")
		const statesFields = buildArrayField(values)
		return [nameField, descriptionField, statesFields]
	}

	return <div className="issue-type-edition">
        <MDBContainer>
			{issueInEdition && <>
				<h1 className="title mb-4">{ issueInEdition.id ? t("editionTitle") : t("creationTitle") }</h1> 
				<Formik

					initialValues={ issueInEdition }
					validationSchema={
						Yup.object().shape({
							name: Yup.string().required(t("issueTypeNameRequired")),
							states: Yup.array().of(
								Yup.object().shape({
									name: Yup.string().required(t("issueTypeNameRequired")),
								})
							)
						})
					}
					onSubmit={(values) => {
						saveIssueType(values).then((response) => {
							MySwal.fire({
								type: "success",
								text: t("saveOkText"),
								showCancelButton: false,
								showConfirmButton: true,
								confirmButtonText: t("saveOkButton"),
								confirmButtonColor: "#ff7043",
							}).then(res => {
								if (res.value) {
									history.goBack();
								}
							})
						});
						
					}}>

					{({ errors, values, touched, isValid }) => ( 
						<Form>
							{ buildForm(values) }
							<div className='form-buttons mt-4'>
								<MDBBtn color="danger" size="sm" onClick={() => {
									history.goBack();
								}}>
									<MDBIcon icon="times" size="1x" /> {t('cancel')}
								</MDBBtn>
								<MDBBtn color="primary" type="submit" size="sm">
									<MDBIcon icon="edit" size="1x" /> {t('save')}
								</MDBBtn>
							</div>
						</Form>
					)}
				</Formik>

			</>
			}
		</MDBContainer>
	</div>
}

IssuesTypeEditContainer.translations = { ...translations }

export default translate(IssuesTypeEditContainer);
