export const translations = {
  timePeriod: {
    es: "Período",
    en: "Period",
  },
  custom: {
    es: "Personalizado",
    en: "Custom",
  },
  store: {
    es: "Tienda",
    en: "Store",
  },
  company: {
    es: "Empresa",
    en: "Company",
  },
  allStores: {
    es: "Todas las tiendas",
    en: "All stores"
  },
  allCompanies: {
    es: "Todas las empresas",
    en: "All companies"
  },
  reputationTitle: {
    es: "Tus reseñas {{period}}",
    en: "Your reviews {{period}}",
  },
  reputationFooter: {
    es_ar: "¿Querés mejorar estas métricas? Hace",
    es: "¿Quieres mejorar estas métricas? Haz",
    en: "Do you want to improve these metrics?",
  },
  storesTitle: {
    es: "Tus tiendas {{period}}",
    en: "Your stores {{period}}"
  },
  storesFooter: {
    es: "Conoce las mejoras prácticas para mejorar el puntaje de tus tiendas haciendo",
    es_ar: "Conocé las mejoras prácticas para mejorar el puntaje de tus tiendas haciendo",
    en: "Learn about the best practices to improve the score of your stores by doing"
  },
  retentionTitle: {
    es: "Retención de clientes {{period}}",
    en: "Client retention {{period}}"
  },
  retentionFooter: {
    es: "Aprende a mejorar la retención de tus clientes haciendo",
    en: "Learn how to improve your customer retention by doing"
  },
  title: {
    es: "Panel de control",
    en: "Dashboard"
  },
  seeOldVersion: {
    es: "¿Quieres ver la versión anterior?",
    es_ar: "¿Querés ver la versión anterior?",
    en: "I miss the old version ",
    clickHere: {
      es: "click aquí",
      en: "take me back"
    }
  },
  noData: {
    es: "No hay puntaje para las tiendas y período seleccionados.",
    en: "There is no score for stores and periods selected."
  },
  noReviewData: {
    es: "No hay reseñas para las tiendas y período seleccionados.",
    en: "There is no reviews for stores and periods selected."
  },
  lastReviews: {
    es: "Últimas reseñas de tus clientes",
    en: "Last reviews of your customers"
  },
  captiveModal: {
    es: "Clientes registrados",
    es_AR: "Clientes registrados",
    en: "Registered customers",
    theDay: {
      es: "el {{date}}",
      en: "on {{date}}"
    },
    thePeriod: {
      es: "en {{date1}}",
      en: "in {{date1}}"
    }
  },
  defaultLinkText: {
    es: "click aquí",
    es_ar: "click acá",
    en: "click here"
  },
  success: {
    es: "Exito",
    en: "Success!"
  },
  newStoreSwalMsg: {
    es: "Un ejecutivo de ventas se comunicará contigo, ¡muchas gracias!",
    en: "A sales executive will contact you, thank you very much!"
  },
  leaderboardTitle: {
    es: "Tabla de posiciones {{period}}",
    en: "Leaderboard {{period}}"
  },
  leaderboardFooter: {
    es: "Conoce la tabla de posiciones completa haciendo",
    en: "To see the full leaderboard"
  },
  reviewsGraphTitle: {
    es: "Reseñas y puntaje promedio {{period}}",
    en: "Reviews and average score {{period}}"
  },
  score: {
    es: "Puntaje",
    en: "Score"
  },
  count: {
    es: "Reseñas",
    en: "Reviews"
  },
  emptyState: {
    es: "No hay datos para las tiendas y período seleccionados.",
    en: "There is no data for the stores and period selected."
  },
  reviewGraphEmptyState: {
    es: "No hay datos para las tiendas, fuentes y período seleccionados.",
    en: "There is no data for the stores, sources and period selected."
  },
  podiumEmptyState: {
    es: "Puedes figurar en este podio si envías pedidos de reseña.",
    en: "You can be in this podium if you send review requests."
  },
  goNow: {
    es: "¡Vamos ahora!",
    en: "Go now!"
  },
  socialSource: {
    es: "Fuentes",
    en: "Sources"
  },
  generateMoreReviews: {
    es: "Genera más reseñas",
    en: "Generate more reviews"
  },
  sendingRequests: {
    es: "enviando solicitudes",
    en: "sending requests"
  },
  reviewsFrom: {
    first: {
      es: "Reseñas de {{sources}}",
      en: "Reviews from {{sources}}"
    },
    second: {
      es: "que dejaron tus clientes {{period}}.",
      en: "left by your clients {{period}}."
    }
  },
  reviewsTab: {
    es: "Reseñas y puntaje",
    en: "Reviews and score"
  },
  accumulatedTab: {
    es: "Evolución reseñas",
    en: "Reviews evolution"
  },
  footerHelper: {
    es: "Haz click en cada barra para ver el detalle.",
    en: "Click any bars to see the details."
  },
  colorGuide: {
    es: "En rojo las reseñas acumuladas desde que iniciaste con RAY.",
    en: "In red the reviews evolution after signing with RAY."
  }
}