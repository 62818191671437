import React, { useEffect, useState } from "react";
import translate from "../../../config/translations/translate";
import MatchSourceWithStores from "../MatchLocationsWithStores/MatchSourceWithStores";
import { MDBContainer, MDBCard, MDBIcon, MDBAnimation, MDBBtn } from "mdbreact"
import "./FinishIntegration.scss";
import { Image } from "botbit-ui-components";
import { envConfig } from "../../../config/config";
import { getSource } from "../../../api/sourceApi";
import { translations } from "../../../config/translations/integrations/FinishIntegration"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const FinishIntegration = ({ t, id }) => {
    const [step, setStep] = useState(0);
    const [error, setError] = useState(null);
    const [sourceImg, setSourceImg] = useState(null);
    const history = useHistory();
    const queryParams = new URLSearchParams(history.location.search)
    const sourceId = queryParams.get("source");
    const selectedCompany = useSelector(state => state.application.selectedCompany);

    useEffect(() => {
        getSource(sourceId, selectedCompany.id).then(res => {
            setSourceImg(res.data.data.logoAbsolute)
        })
    }, [])

    const handleFinishMatching = (_wasSuccessful, errorMsg) => {
        setStep(1);
        if (!_wasSuccessful) {
            setError(errorMsg || t("error.defaultMessage"))
        }
    }

    const renderResultMessage = () => {
        return <div id="result-message">
            <h1>{t("weAreDone")}</h1>
            {error && <h2>{t("error", { errorMessage: error })}</h2>}
            <div className="result-image-construct">
                <Image className="source-logo" src={sourceImg}
                // scaleFixedSize={{width: "100px", height: "100px"}} 
                />
                <div className="result-icon">
                    <Image className="plug-image" src={`${envConfig.staticImgUrl}plug.png`} />
                    <div className="state-icon">
                        <MDBAnimation type="fadeIn" count={3} duration="3s">
                            <MDBIcon size="10x" icon={error ? "times-circle" : "check-circle"} far />
                        </MDBAnimation>
                    </div>
                </div>
                <Image className="botbit-logo" src={`${envConfig.rayLogoUrl}`}
                />
            </div>
            <div className="d-flex justify-content-center mt-5">
                <MDBBtn
                    color="primary"
                    className="text-capitalize"
                    onClick={() => history.push(`/integrations`)}>
                    {t("goBack")}
                </MDBBtn>
            </div>
        </div>
    }

    return <MDBContainer id="finish-integration">
        <MDBCard className="finish-integration-card">
            {step === 0 &&
                <MatchSourceWithStores onFinish={handleFinishMatching} id={id} />
            }
            {step === 1 &&
                renderResultMessage()
            }
        </MDBCard>
    </MDBContainer>
}

FinishIntegration.translations = {
    ...translations
}

export default translate(FinishIntegration);
