import React from 'react';
import Button from '../utils/form/Button';
import { translate } from 'react-i18next';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import Loading from '../utils/Loading';
import MercadoPagoButton from './MercadoPagoButton';
import Number from '../utils/FormatedNumber';
import './PackPurchase.css';
import CollectionVisualizerContainer from '../../../components/collectionVisualizer/CollectionVisualizerContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MercadoPagoModal from "./MercadoPagoModal";
import { envConfig } from "../../../config/config";
import { connect } from 'react-redux';
import { getCompany } from '../../../api/companyApi';

class PackPurchaseButton extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            activeRowModal: null,
            isOpen: false,
            product: "",
            href: ""
        };
        this.mobile = window.innerWidth < 992;
        this.competitorPrices = {
            mailChimp: {
                20000: 400,
                // 50000: 1000,
                // 100000: 2000,
                // 500000: ,
                1000000: 1250,
                2500000: 3125
            },
            emBlue: {
                20000: 36,
                // 50000: 180,
                100000: 360,
                500000: 900,
                1000000: 1800,
                2500000: 2160
            },
            doppler: {
                20000: 116,
                // 50000: 240,
                100000: 360,
                500000: 450,
                1000000: 780,
                2500000: 1830
            }
        }
    }

    componentDidMount() {
        getCompany(this.props.selectedCompany.id)
            .then(res => this.setState({ ...this.state, companyCountry: res.data.data.country }));
    }

    getCompetitorPrice = (competitor, amount) => {
        return (
            this.competitorPrices[competitor] &&
                this.competitorPrices[competitor][amount] ? `US$ ${this.competitorPrices[competitor][amount]}` :
                'N/A'
        );
    }

    openModal = () => (this.setState({ open: true }));

    closeModal = () => (this.setState({ open: false }));

    openPackModal = ({ product, href }) => {
        this.setState({
            product,
            href,
            isOpen: true
        })
    }

    renderPacks = () => {
        const content = this.props.availablePacks.map((pack) => (<Item {...pack} prices={this.competitorPrices}
            getCompetitorPrice={this.getCompetitorPrice}
            openPackModal={(config) => {
                this.openPackModal(config)
            }}
            closeModal={this.closeModal}
            companyCountry={this.state.companyCountry}
            t={this.props.t}
            key={pack.name} />));
        const firstRow = <Container>
            <Row>
                <Col lg={4}></Col>
                <Col lg={2}>
                    <a href={envConfig.staticImgUrl + "mailchimp-price-list.png"} target="_blank" rel="noopener noreferrer"><img src="/static/img/mailchimp-logo.png" alt="" className="column-logo"></img></a>
                </Col>
                <Col lg={2}>
                    <a href="https://www.embluemail.com/calculator-price-professional.html" target="_blank" rel="noopener noreferrer"><img src="/static/img/emblue-logo.png" alt="" className="column-logo"></img></a>
                </Col>
                <Col lg={2} style={{ display: 'flex', margin: 'auto' }}>
                    <a href="https://www.fromdoppler.com/es/precios-planes-prepagos/" target="_blank" rel="noopener noreferrer"><img src="/static/img/doppler-logo.png" alt="" className="column-logo"></img></a>
                </Col>
                <Col lg={2} style={{ display: 'flex', margin: 'auto' }}>
                    <img src={envConfig.rayLogoUrl} alt="" className="column-logo"></img>
                </Col>
            </Row>
        </Container>
        return (
            <>
                {!this.mobile && firstRow}
                {content}
            </>
        );
    }

    render = () => {
        const columns = [
            {
                dataField: 'name',
                formatter: (cellContent, row) => {
                    return (
                        <Container style={{ margin: 0 }}>
                            <Row
                                onClick={() => {
                                    if (row === this.state.activeRowModal) {
                                        this.setState({ activeRowModal: null });
                                    }
                                    else {
                                        this.setState({ activeRowModal: row });
                                    }
                                }
                                }>
                                <Col xs={11}>
                                    {/* <div>{this.props.t("panels.emailActivity.purchasePack." + row.name)}</div> */}
                                    <div>{`${this.props.t("panels.emailActivity.quantity")}`}<strong><Number style={{ fontSize: "13px" }} number={row.quantity} /></strong></div>
                                </Col>
                                <Col xs={1} style={{ padding: 0, display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon icon={row === this.state.activeRowModal ? 'angle-down' : 'angle-right'}></FontAwesomeIcon>
                                </Col>
                            </Row>
                        </Container>
                    );
                },
                size: '12',
            },
        ];
        return (
            <React.Fragment id="main-container">
                <MercadoPagoModal isOpen={this.state.isOpen} product={this.state.product} href={this.state.href} fullscreen toggle={() => this.setState({ isOpen: false })} />
                <Button
                    onClick={this.openModal}
                    data-botbit-security="emailActivity-purchase_pack"
                    className={`btn-primary pack-purchase-button ${this.mobile ? "" : "float-right"}`}>
                    {this.props.t("panels.emailActivity.purchasePackButton")}
                </Button>
                <Modal show={this.state.open}
                    onHide={this.closeModal} centered size="lg" >
                    <Modal.Header closeButton>
                        <div>
                            <Modal.Title>{this.props.t("panels.emailActivity.purchasePackTitle")}</Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Loading loading={!this.props.availablePacks} />
                        {((this.props.availablePacks && this.mobile) && (
                            <>
                                <CollectionVisualizerContainer
                                    getData={async (page, order, filters) => { return { data: { data: { items: this.props.availablePacks } } } }}
                                    columns={columns}
                                    pagination={false}
                                    expandRow={(active, row, onRowChanged) => {
                                        return (
                                            <Container className="mobile-expand-row">
                                                <Row className="mobile-row">
                                                    <Col xs={4}>
                                                        <a rel="noopener noreferrer" href={envConfig.staticImgUrl + "mailchimp-price-list.png"} target="_blank"><img src="/static/img/mailchimp-logo.png" alt="" className="column-logo"></img></a>
                                                    </Col>
                                                    <Col xs={{ span: 5, offset: 3 }} className="mobile-column">
                                                        {this.getCompetitorPrice('mailChimp', row.quantity)}
                                                    </Col>
                                                </Row>
                                                <Row className="mobile-row">
                                                    <Col xs={4}>
                                                        <a rel="noopener noreferrer" href="https://www.embluemail.com/calculator-price-professional.html" target="_blank"><img src="/static/img/emblue-logo.png" alt="" className="column-logo"></img></a>
                                                    </Col>
                                                    <Col xs={{ span: 5, offset: 3 }} className="mobile-column">
                                                        {this.getCompetitorPrice('emBlue', row.quantity)}
                                                    </Col>
                                                </Row>
                                                <Row className="mobile-row">
                                                    <Col xs={4}>
                                                        <a rel="noopener noreferrer" href="https://www.fromdoppler.com/es/precios-planes-prepagos/" target="_blank"><img src="/static/img/doppler-logo.png" alt="" className="column-logo"></img></a>
                                                    </Col>
                                                    <Col xs={{ span: 5, offset: 3 }} className="mobile-column">
                                                        {this.getCompetitorPrice('doppler', row.quantity)}
                                                    </Col>
                                                </Row>
                                                <Row className="mobile-row">
                                                    <Col xs={4} className="center-image-container">
                                                        <img src={envConfig.rayLogoUrl} alt="" className="column-logo"></img>
                                                    </Col>
                                                    <Col xs={{ span: 5, offset: 3 }} className="mobile-column">
                                                        <MercadoPagoButton product={"panels.emailActivity.purchasePack." + row.name}
                                                            href={row.initPoint}
                                                            title="panels.emailActivity.purchasePackTitle"
                                                            customText={`US$ ${row.price}${(this.state.companyCountry && this.state.companyCountry === "AR" && " + IVA") || ""}`}
                                                            buttonClassName="mobile-purchase-button"
                                                            onClick={() => {
                                                                this.closeModal();
                                                                this.openPackModal({
                                                                    product: `panels.emailActivity.purchasePack.${row.name}`,
                                                                    href: row.initPoint,
                                                                })
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        )
                                    }} />
                                <p>{this.props.t("panels.emailActivity.comparePrices")}</p>
                                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                                    <li>
                                        <a href={envConfig.staticImgUrl + "mailchimp-price-list.png"} target="_blank" rel="noopener noreferrer">Mailchimp</a>
                                    </li>
                                    <li>
                                        <a href="https://www.embluemail.com/calculator-price-professional.html" target="_blank" rel="noopener noreferrer">emBlue</a>
                                    </li>
                                    <li>
                                        <a href="https://www.fromdoppler.com/es/precios-planes-prepagos/" target="_blank" rel="noopener noreferrer">Doppler</a>
                                    </li>
                                </ul>
                            </>

                        )) ||
                            (this.props.availablePacks && !this.mobile && <Row>{this.renderPacks()}</Row>)}
                    </Modal.Body>
                </Modal>

            </React.Fragment>
        )
    }
}

class Item extends React.Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         activeRowModal: null,
    //         isOpen: false,
    //         product: "",
    //         href: ""
    //     };
    // }
    render() {
        return (
            <React.Fragment>
                {/* <MercadoPagoModal isOpen={this.state.isOpen} product={this.state.product} href={this.state.href} fullscreen toggle={() => this.setState({ isOpen: false })} /> */}

                <Container>
                    <Row>
                        <Col lg={4}>
                            {/* <Row>
                                <Col lg={9} >
                                    <strong>
                                        {this.props.t("panels.emailActivity.purchasePack." + this.props.name)}
                                    </strong>
                                </Col>
                            </Row> */}
                            <Row className="pack-purchase-pop-quantity-row">
                                <Col lg={9}>{
                                    this.props.t("panels.emailActivity.quantity")
                                }<strong><Number number={this.props.quantity} />{" mails"}</strong></Col>

                            </Row>
                        </Col>
                        <Col lg={2} className="pack-purchase-pop-button-col text-cell">
                            {this.props.getCompetitorPrice('mailChimp', this.props.quantity)}
                        </Col>
                        <Col lg={2} className="pack-purchase-pop-button-col text-cell">
                            {this.props.getCompetitorPrice('emBlue', this.props.quantity)}
                        </Col>
                        <Col lg={2} className="pack-purchase-pop-button-col text-cell">
                            {this.props.getCompetitorPrice('doppler', this.props.quantity)}
                        </Col>
                        <Col lg={2} className="pack-purchase-pop-button-col">
                            <MercadoPagoButton product={"panels.emailActivity.purchasePack." + this.props.name}
                                // href={this.props.initPoint}
                                title="panels.emailActivity.purchasePackTitle"
                                customText={`US$ ${this.props.price}${(this.props.companyCountry && this.props.companyCountry === "AR" && " + IVA") || ""}`}
                                buttonClassName="purchase-button"
                                onClick={() => {
                                    this.props.closeModal();
                                    this.props.openPackModal({
                                        product: `panels.emailActivity.purchasePack.${this.props.name}`,
                                        href: this.props.initPoint,
                                    })

                                    // this.setState({
                                    //     product: `panels.emailActivity.purchasePack.${this.props.name}`,
                                    //     href: this.props.initPoint,
                                    //     isOpen: true,
                                    // })
                                }}
                            />
                        </Col>
                    </Row>

                    <br />
                </Container>


            </React.Fragment>)
    }
}


function mapStateToProps(state) {
    return {
        selectedCompany: state.application.selectedCompany
    }
}
export default connect(mapStateToProps)(translate('common')(PackPurchaseButton));