import React from "react";
import Switch from "react-switch";

export class InputSwitchField extends React.Component {

  render =() => {
    const value = this.props.input.value===true;
    return (
      <div className="form-group">
        <div className="row align-items-start">
          <div className="col">
            <label>{this.props.label}</label>
          </div>
          <div className="col">
              <Switch onChange={value => this.props.input.onChange(value)} checked={value} />
          </div>
        </div>
      </div>
    )

  }
}
export default InputSwitchField;