import React from 'react'
import { MDBInput } from "mdbreact";

const NPSCustomQuestionInput = ({ customQuestion, setValue, value }) => {
	if (customQuestion.type === "TEXT") {
		return <MDBInput
			key={"text-" + customQuestion.id}
			className="custom-question-text-input"
			//label={customQuestion.text}
			type="textarea"
			outline
			value={value}
			getValue={ (value) => setValue(value) }
		/>
	}
	if (customQuestion.type === "OPTIONS") {
		let options = [];
		customQuestion.npsCustomQuestionOptions.forEach((element, index) => {
			options.push(<MDBInput 
				key={"option-" + customQuestion.id + "-" + index}
				containerClass="custom-question-radio-input"
				//containerClass="pl-0"
				type="radio"
				name={'radio-group-' + customQuestion.id}
				id={"option-" + customQuestion.id + "-" + index}
				label={element.text} 
				checked={value === element.id}
				onClick={ () => {
					setValue(element.id)
				}}
				value={element.id}
			/>)
		});
		return options;
	}
	if (customQuestion.type === "SCORE") {
		return <div key={"score-"+customQuestion.id} className="text-left">
			<span>{[1, 2, 3, 4, 5].map(score => npsStar(score, value, setValue))}</span>
		</div>
	}
	//return <div></div>;
}

const npsStar = (score, value, setValue) => {
	let className = (value >= score) ? "score-selected" : "score-unselected";
	return <a key={"star-"+score} href="#selectOption" className={className} onClick={() => setValue(score, value, setValue)}>★</a>
}

export default NPSCustomQuestionInput;
