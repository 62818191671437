import { MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import React, { useState } from 'react';
import Chart from 'react-google-charts';
import { translations } from '../../../config/translations/Feedback/SentimentContainer';
import { translations as monthTranslations } from '../../../config/translations/Months';
import translate from '../../../config/translations/translate';
import { SectionTitle } from './SectionTitle';

// const isNumber = (number) => number || number === 0

const options = {
	chartArea: {
		width: '100%',
		height: '85%',
		top: 50
	},
	legend: {
		position: 'top',
		maxLines: 2
	},
	// legend: 'none',
	pieHole: 0.3,
	pieSliceText: 'value-and-percentage',
	pieSliceTextStyle: {
		// color: 'black',  // Color del texto
		fontSize: 10     // Tamaño de la fuente
	}
}

const KeywordsDistributionByComments = ({ t, sentimentsByKeyword, selectedKeywords }) => {
	const [activeTab, setActiveTab] = useState("1")

	// const sentimentsByKeywordSorted = sentimentsByKeyword && sentimentsByKeyword.sort((elementA, elementB) => {
	// 	if (isNumber(elementA.positiveWithoutNeutralPercentage) && isNumber(elementB.positiveWithoutNeutralPercentage)) {
	// 		return elementB.positiveWithoutNeutralPercentage - elementA.positiveWithoutNeutralPercentage
	// 	}
	// 	if (isNumber(elementA.positiveWithoutNeutralPercentage)) {
	// 		return -1
	// 	}
	// 	if (isNumber(elementB.positiveWithoutNeutralPercentage)) {
	// 		return 1
	// 	}
	// 	return 0
	// })

	const allData = [
		["Keyword", "Total ocurrences"]
	];

	const positiveData = [
		["Keyword", "Total ocurrences"]
	];

	const negativeData = [
		["Keyword", "Total ocurrences"]
	];

	const slices = []

	let totalIndex = 0;
	let positiveIndex = 0;
	let negativeIndex = 0;
	sentimentsByKeyword.forEach((element) => {
		if (element.totalPositiveAndNegativeReviews > 0) {
			allData.push([element.keyword, element.totalPositiveAndNegativeReviews])
			if (selectedKeywords && selectedKeywords.includes(element.keywordId)) {
				slices["1"] = { [totalIndex]: { offset: 0.1 } }
			}
			totalIndex++
		}
		if (element.totalPositiveReviews > 0) {
			positiveData.push([element.keyword, element.totalPositiveReviews])
			if (selectedKeywords && selectedKeywords.includes(element.keywordId)) {
				slices["2"] = { [positiveIndex]: { offset: 0.1 } }
			}
			positiveIndex++
		}
		if (element.totalNegativeReviews > 0) {
			negativeData.push([element.keyword, element.totalNegativeReviews])
			if (selectedKeywords && selectedKeywords.includes(element.keywordId)) {
				slices["3"] = { [negativeIndex]: { offset: 0.1 } }
			}
			negativeIndex++
		}
	})

	const dataArray = {
		"1": allData,
		"2": positiveData,
		"3": negativeData
	}

	const toggle = tab => e => {
		if (activeTab !== tab) {
			setActiveTab(tab)
		}
	};

	return <div className='tab-content-custom'>
		<SectionTitle
			help={[t('keywordAnalysisSection.distributionByComments.help1')]}
			title={t('keywordAnalysisSection.distributionByComments.title')}
			name={'sentiment-month-keyword-distribution'}
		/>
		<MDBNav className="nav-tabs">
			<MDBNavItem>
				<MDBNavLink link to="#" active={activeTab === "1"} onClick={toggle("1")} role="tab" >
					{t('keywordAnalysisSection.distributionByComments.all')}
				</MDBNavLink>
			</MDBNavItem>
			<MDBNavItem>
				<MDBNavLink link to="#" active={activeTab === "2"} onClick={toggle("2")} role="tab" >
					{t('keywordAnalysisSection.distributionByComments.positive')}
				</MDBNavLink>
			</MDBNavItem>
			<MDBNavItem>
				<MDBNavLink link to="#" active={activeTab === "3"} onClick={toggle("3")} role="tab" >
					{t('keywordAnalysisSection.distributionByComments.negative')}
				</MDBNavLink>
			</MDBNavItem>
		</MDBNav>
		<Chart
			chartType="PieChart"
			data={dataArray[activeTab]}
			options={
				{
					...options,
					slices: {
						...slices[activeTab]
					}
				}
			}
			width={"100%"}
			height={"260px"}
		/>
	</div>
}

KeywordsDistributionByComments.translations = {
	...translations,
	...monthTranslations
};

export default translate(KeywordsDistributionByComments);
