import React from "react";
import { MDBContainer } from "mdbreact";
import {CollectionVisualizer} from "botbit-ui-components";
import "./settings.css";
import SocialSourceIcon from './SocialSourceIcon';

const StoresConnected = ({t, availableCompanies, selectedCompany, getConnectedStores}) => {

    const prefix = 'panels.feedback.settings.connectedstores'

    const columns = [
        // for BIG screens: greater than md
        {
            title: 'Id',
            dataField: 'storeId',
            className: 'd-none d-md-block',
            size: 1
        },
        {
            title: t('store'),
            dataField: 'storeDisplayName',
            size: 4,
            className: 'd-none d-md-block text-left row-title'
        },
        {
            title: t('socialLocation'),
            dataField: 'socialLocationDisplayName',
            size: 4,
            className: 'd-none d-md-block text-left'
        },
        {
            dataField: 'socialSourceId',
            title: 'Sitio',
            className: 'align-left text-truncate d-none d-md-block p-0',
            size: 1,
            formatter: (cellContent, row) => {
              return <SocialSourceIcon site={cellContent} />
            }
        },
        {
            title: t('matchingKey'),
            dataField: 'matchingKey',
            className: 'd-none d-md-block',
            size: 2
        },        
        // for SMALL screens: lower than md
        {
            title: t('store'),
            isHeader: true,
            dataField: 'storeDisplayName',
            className: 'd-md-none row-title'
        },
        {
            dataField: 'socialLocationDisplayName',
            className: 'd-md-none'
        }, {
            dataField: 'socialSourceId',
            className: 'align-left d-md-none pl-0',
            formatter: (cellContent, row) => {
              return <SocialSourceIcon site={cellContent} />
            },
            size: 2
        }
    ]

    const getData = (filters, page) => {
        return getConnectedStores(selectedCompany.id, filters, page)
    }

    const getDefaultFilterValues = () => {

        const textPatternFilter = {
            id: 'textPattern',
            label: t('search'),
            type: 'input',
            className: 'col-12',
            placeholder: t('search'),
            priority: true,
            visible: true
        }

        return [ textPatternFilter ]
    }

    return (<div id="settings">
        <MDBContainer className="mt-3">
            <CollectionVisualizer
                title={t(`${prefix}.title`)}
                getData={(page, order, filters) => getData(filters, page)}
                columns={columns}
                pagination={true}
                defaultFilters={getDefaultFilterValues()}
                alwaysFloatingFilter={true}
                filtersTitle={t(`${prefix}.filters.title`)}
                showFiltersInfo
                availableCompanies={availableCompanies}
                selectedCompany={selectedCompany}
            />
        </MDBContainer>
    </div>)

}

export default StoresConnected
