import axios from "axios";

import { envConfig } from '../config/config.js';
import { logoutRequest } from "../components/login/loginActions";
import { setError, setLoading } from "../commons/components/application/miscActions";

export const baseURL = envConfig.servicesUrl; //'https://api.botbit.io';
export const phpBaseUrl = envConfig.frontendPhpUrl;

const client = axios.create({
  baseURL: baseURL,
  timeout: 120000
});

export const phpClient = axios.create({
  baseURL: phpBaseUrl,
  timeout: 120000
});

export const setTokenApiClient = (token) => {
  if (token != null) {
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

export const initApiClient = (store) => {
  client.interceptors.response.use(undefined, (axiosError) => {
    let error;
    if (axiosError.config.hasOwnProperty('errorHandler') && axiosError.config.errorHandler !== undefined) {
      error = axiosError.config.errorHandler(axiosError);
    } else {
      console.log(axiosError);
      var theMessage = 'Por favor reintente nuevamente en unos minutos';
      if (axiosError.response && axiosError.response.data && axiosError.response.data.message)
        theMessage = axiosError.response.data.message;
      error = {
        message: theMessage,
        status: axiosError.status === undefined ? 500 : axiosError.status
      };
      if (axiosError && axiosError.response) {
        switch (axiosError.response.status) {
          case 401:
			console.log(axiosError);
			client.defaults.headers.common['Authorization'] = "";
            store.dispatch(logoutRequest());
            break;
          case 403:
            error.message = 'Acceso denegado'
            break;
          default:
            console.error('Error! ' + axiosError)
        }
      }
    }
    // Solo hago el dispatch del error si no hay que ignorarlo
    console.log(error);
    if (error.ignore === undefined || error.ignore === false)
      store.dispatch(setError(error))
    store.dispatch(setLoading(false))
    return Promise.reject(axiosError);
  });
}

export default client;