import React, { useEffect, useRef, useState } from 'react';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Messenger/Messenger";
import listToText from '../../commons/utils/listToText';
import useOnScreen from '../../commons/utils/useOnScreen';
import { setMsgAsRead } from '../../api/messengerApi';
import { useSelector } from 'react-redux';
import { MDBIcon } from "mdbreact";
import moment from "moment";

const Message = ({ children, messageObj, t }) => {
    const [viewedFor, setViewedFor] = useState([])
    // const { openImageInImageViewer } = useMessengerData()
    const { message, senderAdmin, targetAdmin, direction, stateByAdmin, id, type, changedConversationState, state, requestedTimestamp } = messageObj;
    const msgRef = useRef();
    const [msgIsRead, setMsgIsRead] = useState(false);
    const isVisible = useOnScreen(msgRef)
    const currentAdmin = useSelector(state => state.login.admin)

    useEffect(() => {
        if (stateByAdmin && stateByAdmin.length > 0) {
            const adminsWhoViewdTheMessage = stateByAdmin
                .filter(i => i.state === "VIEWED");

            setViewedFor(adminsWhoViewdTheMessage);
        }
    }, [stateByAdmin])

    useEffect(() => {
        if (isVisible) {
            markAsSeenIfShould(id);
        }
    }, [isVisible])

    const markAsSeenIfShould = (msjId) => {
        if (msgIsRead || !stateByAdmin) return;
        const alreadySeen = stateByAdmin
            .some(admin => admin.administrator.id === currentAdmin.id && admin.state === "VIEWED");

        if (alreadySeen) setMsgIsRead(true);
        else {
            setMsgAsRead(msjId);
        }
    }

    const replaceLinks = (text) => {
        if (!text) return null;
        const linkRegex = /((https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        return text.replace(linkRegex, '<a target="_blank" rel="noopener noreferrer" href="$1">$1</a>').replaceAll("\n", "<br/>")
    }

    const renderMsgContent = () => {
        const formattedMsg = replaceLinks(message);

        //console.log("messageObj", messageObj)
        //console.log("type", type)
        //console.log("messageObj.attachments", messageObj.attachments)
        //console.log("senderAdmin", senderAdmin)
        //console.log("targetAdmin", targetAdmin)

        if (type === "ASSIGNMENT")
            return <div>
                <div>
                    {t("assignmentTo", { "senderAdmin": senderAdmin ? senderAdmin.fullName : "", "targetAdmin": targetAdmin ? targetAdmin.fullName : ""} )}
                </div>
                <div>
                    {formattedMsg || ""}
                </div>
            </div>

        if(type === "ISSUE_UPDATE"){
            return t("issueMessage", {"senderAdmin": senderAdmin ? senderAdmin.fullName : "", type: formattedMsg});
        }
        if (type === "UNASSIGNMENT"){
            if(senderAdmin.id>0)
                return t("unassignmentTo", {"senderAdmin": senderAdmin ? senderAdmin.fullName : ""});
            else
                return t("automaticUnassignment")
        }

        if (type === "STATE_CHANGE")
            return `${senderAdmin ? senderAdmin.name : ""} ${t("stateChangedTo")} ${t(changedConversationState ? changedConversationState.toLowerCase() : "")}`;

        if (type === "TEXT")
            return <span dangerouslySetInnerHTML={{__html: senderAdmin 
                ? senderAdmin.name !== "botbit_backend"
                    ? `${t("messageFrom")} ${senderAdmin.name}: ${formattedMsg}`
                    : `${t("automaticMessage")}: ${formattedMsg} `
                : formattedMsg}} />

        if (type === "MULTIMEDIA" && messageObj.attachments && messageObj.attachments.length > 0) {
            var multimediaContent = messageObj.attachments.map((attachment, index) => {
                if (attachment.type === "IMAGE") {
                    return <span key={"attachment_"+index} className="img-message-container">
                                     <img className="img-message" src={attachment.url} alt="Media message" />
                                 </span>
                }
                if (attachment.type === "VIDEO") {
                    return <span key={"attachment_"+index} className="img-message-container">
                                     <video className="img-message" alt="Media message" controls>
                                         <source src={attachment.url} ></source>
                                         <source src='/static/video/404.webm' type='video/webm'></source>
                                    </video>
                                 </span>
                }
                if (attachment.type === "AUDIO") {
                    return <span key={"attachment_"+index} className="message-audio-container">
                        <audio controlsList="nodownload" controls src={attachment.url} className="message-audio">
                            Your browser does not support the <code>audio</code> element
                        </audio>
                    </span>
                }
                if (attachment.type === "FILE") {
                    var textToShow;
                    if (direction==="OUT" && attachment.originalFileName)
                        textToShow = attachment.originalFileName;
                    else
                        textToShow = t("documentReceived." + direction.toLowerCase());
                    return <div key={"attachment_"+index} className="message-file-container">
                        <div key={"attachment_"+index} className="message-file-preview">
                            <img src={attachment.contentType ? `/static/img/content_types/${attachment.contentType}.png` : `/static/img/content_types/unknown.png`} alt="&nbsp;"/>
                        </div>
                        <div className='message-file-description-container'>
                            <div className='message-file-description'>
                                <a href={attachment.url} target="_blank" rel="noopener noreferrer"><MDBIcon icon='download'/></a> {textToShow} 
                            </div>
                        </div>
                    </div>
                }
                return null;
            });
            return multimediaContent;
        }

        if (type === "UNSUPPORTED")
            return <span>{t('unsupportedMessage')}</span> 
        return "";
    }

    const renderChecks = () => {
        const checkByState = {
            SENT: {
                icon: "check",
                color: "gray",
            },
            DELIVERED: {
                icon: "check-double",
                color: "gray",
            },
            VIEWED: {
                icon: "check-double",
                color: "blue",
            },
            REQUESTED: {
                icon: "clock",
                color: "gray",
                far: true
            }
        }

        const icon = checkByState[state]

        return icon ? <MDBIcon far={icon.far} className={`check-icon icon-${icon.color}`} icon={icon.icon} /> : null
    }

    const renderDateAndTime = () => {
        const todayRef = moment(new Date());
        const startOfToday = todayRef.clone().startOf("day");
        const messageDate = moment(new Date(requestedTimestamp));
        const isToday = messageDate.isSame(startOfToday, "d");

        return isToday ? messageDate.format("LT") : messageDate.format("L LT");
    }

    return (
        <li ref={msgRef} className="msg-container">
            <span className={`msg-bubble ${direction ? `direction-${direction.toLowerCase()}` : ""}`} >
                {children}
                {renderMsgContent()}
                    <span className="viewed-for">
                        {renderDateAndTime()}<br />
                        {viewedFor && viewedFor.length > 0 &&
                            <span>
                                {t("viewedFor")} {listToText(viewedFor.map(a => a.administrator.name), 2)}
                            </span>
                        }
                    </span>
                {direction === "OUT" && renderChecks()}
            </span>
        </li>
    );
}

Message.translations = { ...translations }

export default translate(Message);