export const translations = {
    title: {
        es: "Solicitar reseña para:",
        en: "Request a review for:"
    },
    altTitle: {
        es: "Solicitar reseña para",
        en: "Request a review for"
    },
    name: {
        es: "Nombre",
        en: "Name"
    },
    customerName: {
        es: "Nombre del cliente",
        en: "Customer name"
    },
    emailOrPhone: {
        es: "Email o teléfono:",
        en: "Email or phone:"
    },
    emailOrPhone2: {
        es: "Email o teléfono",
        en: "Email or phone"
    },
    email: {
        es: "Email",
        en: "Email"
    },
    phone: {
        es: "Teléfono",
        en: "Phone"
    },
    pickAStore: {
        es: "Para:",
        en: "From:"
    },
    store: {
        es: "Tienda",
        en: "Store"
    },
    submit: {
        es: "Enviar ahora",
        en: "Send now"
    },
    fieldRequired: {
        es: "Este campo es requerido",
        en: "This field is required"
    },
    shouldBeAValidEmail: {
        es: "Debes ingresar un email válido",
        en: "You should put a valid email address"
    },
    shouldBeAValidPhone: {
        es: "Debes ingresar un teléfono válido",
        en: "You should put a valid phone number"
    },
    success: {
        es: "¡Éxito!",
        en: "Success!"
    },
    successMsg: {
        es: "Estamos enviando tu solicitud. ¡Continúa solicitando reseñas!",
        en: "We are sending your request. Continue requesting!"
    },
    newRequest: {
        es: "Nuevo envío 🚀",
        en: "New request 🚀"
    },
    noAvailablestores: {
        es: "Esta company no tiene tiendas activas",
        en: "This company have no active stores"
    },
    sendTo: {
        es: "Enviar a:",
        en: "Send to:"
    },
    continue: {
        es: "Continuar",
        en: "Continue"
    },
    clickHere: {
        es: "haz click aquí",
        en: "click here"
    },
    newRequestAlt: {
        es: "Solicitar otra",
        en: "New request"
    },
    cheerUp: {
        first: {
            es: "¡Felicitaciones",
            en: "Congrats"
        },
        second: {
            es: "Enviaste tu primer solicitud de reseña",
            en: "You just send your first review"
        },
        third: {
            es: "Ahora ve a tu email y abre el que dice",
            en: "Now go to your email inbox and opens the one that says"
        },
        fourth: {
            es: "cuéntanos cómo fue tu experiencia con",
            en: "tell us how it went with"
        },
        fifth: {
            es: "Califícate con un 10 y te llevaremos a Google para que generes tu primer reseña 5",
            en: "Rank yourself with a 10 and we will take you to Google to generate your first review with 5"
        },
        sixth: {
            es: "Si después de hacerlo no pudiste generar tu primer reseña, haz click",
            en: "If you couldn't generate your first review, click"
        },
        seventh: {
            es: "aquí",
            en: "here"
        },
        eighth: {
            es: "y te explicamos porqué.",
            en: "and we will tell you why."
        },
        ninth: {
            es: "¿Tienes dudas? Mira este breve video",
            en: "Got any doubts? Watch this short video"
        }

    },
    stepOne: {
        first: {
            es: "¡Felicitaciones {{name}}! Solicitaste tu primer reseña y estás muy cerca de tener tus 5⭐️ en Google",
            en: "Congrats {{name}}! You requested your first review and you are very close to having your 5⭐️ on Google"
        },
        second: {
            es: "Haz click en continuar y en menos de 1 minuto las 5 estrellas serán tuyas 🤩",
            en: "Click on continue and in less than 1 minute the 5 stars will be yours 🤩"
        }
    },
    stepTwo: {
        first: {
            es: "Solo te falta",
            en: "You only need"
        },
        second: {
            es: "abrir tu email ({{email}}), calificar con 10 y dejar tu reseña en Google",
            en: "open your email ({{email}}), rate with 10 and leave your review on Google"
        },
        third: {
            es: "cómo muestra este video👇",
            en: "how this video shows👇"
        },
        fourth: {
            es: "Una vez que lo hayas hecho haz click en continuar",
            en: "Once you have done it, click on continue"
        },
        btn: {
            es: "Abrir email",
            en: "Open email"
        }
    },
    stepThree_1_1: {
        firstP: {
            es: "¡Obtuviste tu primer reseña privada al responder la encuesta de satisfacción! 😃",
            en: "You've got your first private review when responding to the customer satisfaction survey! 😃"
        },
        secondP: {
            first: {
                es: "Las reseñas privadas son muy valiosas: te permitirán contactar a los clientes que hayan tenido una mala experiencia y resolver sus problemas, antes que su enojo 😡 llegue a Google o Facebook y te haga perder ventas.",
                en: "Private reviews are highly valuable: they will allow you to contact customers who have had a bad experience and solve their problems, before their anger 😡 reaches Google or Facebook and causes you to lose sales."
            }
        }
    },
    stepThree_1_2: {
        firstP: {
            es: "¿Qué pasó con tus 5⭐️ en Google? En breve recibirás un email con todo el detalle.",
            en: "What happened with your Google 5⭐️? You'll get an email with all the details soon."
        },
        secondP: {
            es: "Mientras tanto respondamos la reseña privada que generaste 🙌",
            en: "Meanwhile, let's reply the private review you've generated 🙌"
        },
        thirdP: {
            es: "Responde la tuya ahora, te llevará menos de un minuto.",
            en: "Reply yours now, it'll take you less than a minute"
        },
        button: {
            es: "¡Vamos a responderla!",
            en: "Let's reply it!"
        }
    },
    stepThree_2: {
        firstP: {
            es: "No has respondido la encuesta de satisfacción que te enviamos 🤔",
            en: "You didn't reply the customer satisfaction survey we've sent you 🤔"
        },
        secondP: {
            es: "¿No has encontrado el email? No te preocupes, inténtalo nuevamente con otra dirección de correo.",
            en: "You didn't find the email? Don't worry, try again with another email address."
        }
    },
    sendWhatsapp: {
        es: "Enviar por Whatsapp",
        en: "Send by Whatsapp"
    },
    sendSMS: {
        es: "Enviar por SMS",
        en: "Send by SMS"
    },
    reviewAdvice: {
        zero: {
            es: "¿Sabías qué?",
            en: "Did you know?"
        },
        first: {
            es: "Los negocios que responden reseñas son",
            en: "Business that reply reviews are"
        },
        second: {
            es: "más propensos a elevar su calificación",
            en: "more likely to get a better rating"
        },
        third: {
            es: "media estrella",
            en: "(around half a star)"
        },
        fourth: {
            es: "en 6 meses.",
            en: "in 6 months."
        }
    }
}
