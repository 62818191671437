
export const hasMasterOfTheUniverseProfile = (admin) => {
    return hasProfile(admin, 'MASTER_OF_THE_UNIVERSE')
}

export const hasCompanyAdminProfile = (admin) => {
    return hasProfile(admin, 'COMPANY_ADMIN')
}

const hasProfile = (admin, profileId) => {
    if (!admin.profiles) return false
    const profile = admin.profiles.find(profile => {
        return profile.id === profileId
    })
    return profile !== undefined
}

export const hasOperation = (admin, operation) => {
    if (!admin.operations) return false
    const findResult = admin.operations.find(adminOpertaion => {
        return operation === adminOpertaion;
    })
    return findResult !== undefined
}

export const OPERATIONS = {
	USER_TAGS__EDIT : "USER_TAGS__EDIT"
}


export default hasMasterOfTheUniverseProfile;
