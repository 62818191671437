import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import { Formik, Form, Field } from 'formik';
import { HeaderAlert } from "botbit-ui-components";
import { useSelector } from "react-redux";
import { InlineShareButtons } from 'sharethis-reactjs';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import translate from "../../../config/translations/translate";
import { translations } from "../../../config/translations/Companies/CompanyQR";
import { withRouter } from "react-router-dom";

import "./CompanyQR.scss"
import { getCompany, updateCompany } from '../../../api/companyApi';
import { useHistory } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const CompanyQR = ({ t }) => {
  const [qrCode, setQrCode] = useState();
  const [company, setCompany] = useState();
  const [initialValues, setInitialValues] = useState();
  const selectedCompany = useSelector(state => state.application.selectedCompany);

  const history = useHistory();

  const generateQR = (values) => {
    setQrCode(values);
  }

  const sendCompany = (values) => {
    return updateCompany({
      ...company,
      ...values
    })
  }

  useEffect(() => {
    if (!company) {
      // setCompany(await getCompany())
      getCompany(selectedCompany.id).then(res => {
        setCompany(res.data.data);
      })
    } else {
      setInitialValues({
        urlPrefixForQr: company.urlPrefixForQr ? company.urlPrefixForQr : undefined,
        useDeviceLocation: company.useDeviceLocation ? company.useDeviceLocation : false,
        storeSelectorLabel: company.storeSelectorLabel ? company.storeSelectorLabel : "Elige tu tienda favorita"
      })
    }
  }, [company])

  useEffect(() => {
    if (initialValues && initialValues.urlPrefixForQr) {
      setQrCode(initialValues);
    }
  }, [initialValues])

  return (
    <>
      <div className="qr-settings-container pb-5">
        {initialValues && company && <MDBCard className="p-3">
          <MDBContainer className="my-2 mx-0 w-100">
            <MDBRow size="12" >
              <MDBCol size="12" md="8" lg="9">
                {/* <span className="d-flex align-items-center"> */}
                <MDBIcon
                  icon="arrow-left"
                  size="2x"
                  className="arrow-back mr-3 text-dark"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/companies");
                  }}
                />
                <h1 className="mb-4 font-weight-bold text-dark">{t("companyQR")}</h1>
                {/* </span> */}
                <Formik
                  initialValues={initialValues}
                  onSubmit={values => {
                    sendCompany(values).then(res => {
                      if (res.status === 200) {
                        generateQR(values);
                        MySwal.fire({
                          type: "success",
                          title: t("qrSaved"),
                          text: t("qrSavedText")
                        })
                      }
                    });
                  }}
                >
                  {({ error, touched, values }) => (
                    <Form>
                      <>
                        <MDBRow size="12" className="align-items-center" style={{ marginBottom: "3rem" }}>
                          <Field name="urlPrefixForQr">
                            {({ field, form, meta }) => (
                              <>
                                <MDBCol size="12" > <label>{t("urlPrefixLabel")}</label></MDBCol>
                                <MDBCol size="12" >
                                  <MDBInput
                                    className="w-100"
                                    size="12"
                                    type="text"
                                    value={field.value}
                                    hint={t("prefixHint")+company.name.toLowerCase().replace(/\s/g, '')}
                                    getValue={(value) => {
                                      if (value !== field.value) form.setFieldValue(field.name, value);
                                    }}
                                  />
                                  <span className="d-block">{t("clientsCouldAccessA")}<b>https://{field.value ? field.value : "[prefix]"}.botbit.io</b>{t("clientsCouldAccessB")}</span>
                                </MDBCol>
                              </>
                            )}
                          </Field>
                        </MDBRow>
                        {/* <MDBRow size="12" className="align-items-center">
                          <Field name="useDeviceLocation">
                            {({ field, form, meta }) => (
                              <>
                                <MDBCol size="12" md="5">
                                  <label>Pedir ubicación para saber en qué tienda está</label>
                                </MDBCol>
                                <MDBCol size="12" md="7">
                                  <MDBSwitch
                                    checked={field.value}
                                    labelLeft=""
                                    labelRight=""
                                    getValue={value => {
                                      if (value !== field.value) form.setFieldValue(field.name, value);
                                    }}
                                  />
                                </MDBCol>
                              </>
                            )}
                          </Field>
                        </MDBRow> */}
                        <MDBRow size="12" className="align-items-center">
                          <Field name="storeSelectorLabel">
                            {({ field, form, meta }) => (
                              <>
                                <MDBCol size="12" md="5"> <label>{t("helperMessageLabel")}</label></MDBCol>
                                <MDBCol size="12" md="7">
                                  <MDBInput
                                    className="w-100"
                                    size="12"
                                    type="text"
                                    value={field.value}
                                    // label="Ingrese un mensaje"
                                    // placeholder="Seleccione su sucursal favorita"
                                    getValue={(value) => {
                                      if (value !== field.value) form.setFieldValue(field.name, value);
                                    }}
                                  />
                                </MDBCol>
                              </>
                            )}
                          </Field>
                        </MDBRow>
                      </>
                      <MDBBtn type="submit" color="primary">{t("save")}</MDBBtn>
                    </Form>
                  )}
                </Formik>
              </MDBCol>
              <MDBCol size="12" md="4" lg="3">
                <MDBRow className="mb-0">
                  <HeaderAlert
                    type="info"
                  >
                    <span>{t("forDownloading.a")}<b>{t("forDownloading.b")}</b>{t("forDownloading.c")}<b>{t("forDownloading.d")}</b></span>
                  </HeaderAlert>
                </MDBRow>
                <MDBRow className="mb-0">
                  <div className="qr-code-container w-100 mw-500 m-auto">
                    <img style={{ width: "100%" }} src={`https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=https://admin.botbit.io/registration?companyId=${selectedCompany.id}&source=QR`} alt="" className="qr-code-image" />
                  </div>
                </MDBRow>
                <MDBRow className="mb-0 mt-2">
                  {qrCode && <span className="m-auto">
                    <InlineShareButtons
                      // style={{ width: "100%", margin: "auto" }}
                      config={{
                        alignment: "center",
                        color: "social",
                        enabled: true,
                        font_size: 16,
                        labels: "cta",
                        language: t("language"),
                        networks: [
                          "facebook",
                          "twitter",
                          "whatsapp",
                          "email",
                          "linkedin"
                        ],
                        padding: 7,
                        radius: 4,
                        // show_total: true,
                        size: 45,
                        top: 14,
                        image: `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=https://admin.botbit.io/registration?companyId=${selectedCompany.id}&source=QR`,
                        url: `https://${qrCode.urlPrefixForQr}.botbit.io?utm_source=botbit&utm_medium=sharethis&utm_campaign=admin_share `,
                        title: `${t("signOn")} ${company.name}`
                      }}
                    />
                  </span>}
                </MDBRow>
                {qrCode && <MDBRow className="mb-0">
                  <HeaderAlert
                    type="info"
                    icon="no"
                  >
                    <span>{t("useUrl")}<a href={`https://${qrCode.urlPrefixForQr}.botbit.io`} target="__blank" rel="noopener noreferrer"><b>{`https://${qrCode.urlPrefixForQr}.botbit.io`}</b></a></span>
                  </HeaderAlert>
                </MDBRow>}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCard>}
      </div>
    </>
  );
}

CompanyQR.translations = {
  ...translations
}

export default withRouter(translate(CompanyQR));
