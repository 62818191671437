import {useSelector} from "react-redux";
import { useState, useEffect } from 'react';

export const useCompanyAvailableStores = () => {
    const filterCompanyStores = (availableStores, selectedCompany) => {
        return availableStores.filter(s => s.companyId === selectedCompany.id);
    }
    
    const selectedCompany = useSelector(state => state.application.selectedCompany)
    const availableStores = useSelector(state => state.application.availableStores)
    
    const [companyStores, setCompanyStores] = useState(selectedCompany && availableStores && filterCompanyStores(availableStores, selectedCompany));
    useEffect(() => {
        if (selectedCompany && availableStores) setCompanyStores(filterCompanyStores(availableStores, selectedCompany));
    }, [selectedCompany, availableStores]);
    
    return companyStores;
}