import React, { Component } from "react";
import { translate } from "react-i18next";
import { setLoading } from '../../commons/components/application/miscActions';
import { getFirstHundredAutoReplyRules, getReplyTemplates, saveAutoReplyRule, deleteAutoReplyRule, saveReplyTemplate } from '../../api/feedbackApi';
import { getFlows } from '../../api/flowsApi';
import { connect } from 'react-redux';
import AutoReplyRules from "./AutoReplyRules";

class AutoReplyRulesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    save = (autoReplyRule) => {
        this.props.setLoading(true);
        const promise = new Promise((resolve) => {
            saveAutoReplyRule(autoReplyRule).then((response) => {
            resolve(response);
          }).finally(() => {
            this.props.setLoading(false);
          });
        })
        return promise;
    }

    render() {
        return (<>
            {<AutoReplyRules
                t={this.props.t}
                admin={this.props.admin}
                getRules={getFirstHundredAutoReplyRules}
                getReplyTemplates={getReplyTemplates}
				getFlows={getFlows}
                onSaveAutoReplyRule={this.save}
                onDeleteAutoReplyRule={deleteAutoReplyRule}
                onSaveReplyTemplate={saveReplyTemplate}
                availableCompanies={this.props.availableCompanies}
                selectedCompany={this.props.selectedCompany}
            />}
			<br/>
			<br/>
        </>)
    }
    
}

function mapStateToProps(state) {
	return {
        admin: state.login.admin,
        availableCompanies: state.application.availableCompanies,
        selectedCompany: state.application.selectedCompany
	}
}

export default connect(mapStateToProps, {setLoading})(translate('common')(AutoReplyRulesContainer))
