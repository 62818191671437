export const EventSource = {
    OTHER: 'OTHER',
    CLOUDTRAX: 'CLOUDTRAX',
    SENDGRID: 'SENDGRID',
    BOTBIT: 'BOTBIT',
    CHROME_EXTENSION: 'CHROME_EXTENSION',
    MERCADO_PAGO: 'MERCADO_PAGO',
    MERAKI: 'MERAKI',
    GOOGLE: 'GOOGLE',
    FACEBOOK: 'FACEBOOK',
    RDSTATION: 'RDSTATION'
}