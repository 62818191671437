
import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom'
import { MDBContainer, MDBInput, MDBCard, MDBIcon, MDBBadge, MDBTabContent, MDBTabPane, MDBNavLink, MDBNavItem, MDBNav, MDBBtn } from "mdbreact";
import { Toast, toast, MediaGallery, Actions, Action, RenderMode, Select, Collapsable } from 'botbit-ui-components'
import { getAllTimePeriods, Modal, ModalHeader, ModalBody, ActionRenderMode } from 'botbit-ui-components';
import {
    getCampaignDetail, getCampaignProductCategories,
    getEmptyCampaignWithTemplate, getEmptyDuplicatedCampaign, updateCampaign, sendCampaign, deleteCampaign, getPromoCodes, getInProgressCampaignPreview
} from '../../../api/campaignsApi';
import { getProductCategoriesByCompany, getImagesForCompany } from '../../../api/companyApi';
import { containers } from "../../campaigns/MinimalEditor/ContainerHelper";
import { validationSchema, saveCampaign, PreviewModal, handlePreview, getBenefitMessage, benefitIsEmpty } from '../MinimalEditor/Helper';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import i18next from 'i18next'
import { Formik, Form, Field, FastField } from 'formik';
import { setLoading } from '../../../commons/components/application/miscActions'
import './MaximalEditor.css';
import { envConfig } from '../../../config/config';
import CKEditor from 'ckeditor4-react';
import MinimalEditor from '../MinimalEditor/MinimalEditor'
import moment from 'moment'
import 'moment/locale/es';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { campaignsAreEqual } from '../CampaignsHelper';
import TestEmail from '../TestEmail/TestEmail';
import { useToken } from "../../../commons/utils/token.js"
import "../../segments/Segments/Segments.css";


const MySwal = withReactContent(Swal);

const MINIMAL_EDITOR_TRANSLATIONS_PREFIX = "panels.campaigns.minimalEditor."
const TRANSLATIONS_PREFIX = "panels.campaigns.maximalEditor.";
const deliverySettingsOptions = {
    noProgram: "noProgram",
    sendNow: "sendNow",
    sendOnce: "sendOnce",
    sendPeriodically: "sendPeriodically"
}

const emailContentContainers = [
    containers.company,
    containers.name,
    containers.fromEmail,
    containers.fromDisplay,
    containers.replyToEmail,
    containers.subject,
    containers.benefit,
    containers.message,
    containers.validity,
    containers.button,
    containers.image
]

const segmentContainers = [
    containers.segmentsList
]
const deliverySettingsContainers = [
    containers.sendDates,
    containers.promoCodes
]

const replacementOptions = (t) => [
    { text: "{logo}: " + t(`${TRANSLATIONS_PREFIX}logoDescription`), value: "{logo}" },
    { text: "{Nombre}: " + t(`${TRANSLATIONS_PREFIX}NombreDescription`), value: "{Nombre}" },
    { text: "{Apellido}: " + t(`${TRANSLATIONS_PREFIX}ApellidoDescription`), value: "{Apellido}" },
    { text: "{fechaVencimiento}: " + t(`${TRANSLATIONS_PREFIX}fechaVencimientoDescription`), value: "{fechaVencimiento}" },
    { text: "{Tienda}: " + t(`${TRANSLATIONS_PREFIX}TiendaDescription`), value: "{Tienda}" },
    { text: "{socialLinks}: " + t(`${TRANSLATIONS_PREFIX}socialLinksDescription`), value: "{socialLinks}" },
    { text: "{address}: " + t(`${TRANSLATIONS_PREFIX}addressDescription`), value: "{address}" },
    { text: "{qrCode}: " + t(`${TRANSLATIONS_PREFIX}qrCodeDescription`), value: "{qrCode}" },
    { text: "{promoCode}: " + t(`${TRANSLATIONS_PREFIX}promoCodeDescription`), value: "{promoCode}" },
    { text: "{benefitMessage}: " + t(`${TRANSLATIONS_PREFIX}benefitMessageDescription`), value: "{benefitMessage}" },
]

const deliveryOptions = (t) => {
    let options = [
        { text: "WhatsApp", link: "https://api.whatsapp.com/send?phone={whatsapp}&text=&source=&data=", image: "logowhatsapp.png", value: "WhatsApp" },
        { text: "PedidosYa", link: "http://www.pedidosya.com", image: "logopedidosya.png", value: "PedidosYa" },
        { text: "Rappi", link: "http://www.rappi.com", image: "logorappifinal.png", value: "Rappi" },
        { text: "Glovo", link: "http://www.glovoapp.com", image: "logoglovo2.png", value: "Glovo" },
        { text: "UberEats", link: "http://www.ubereats.com", image: "logoubereatsFINAL.png", value: "UberEats" },
        { text: "domicilios.com", link: "http://www.domicilios.com", image: "logodomicilios.png", value: "domicilios.com" },
        { text: "SinDelantal", link: "http://www.sindelantal.mx", image: "sindelantallogo.png", value: "SinDelantal" },
        { text: "DiDi", link: "https://www.didi-food.com/es-MX", image: "logodidi.png", value: "DiDi" },
    ]
    return options.map(o => {
        o.image = `${envConfig.staticImgUrl}${o.image}`;
        o.htmlElement = `<a href="${o.link}">
            <img style="width:45%" src="${o.image}" alt="${o.text}"/>
        </a>`
        return o;
    })
}

var cronstrue = require('cronstrue/i18n');

class MaximalEditor extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.editorRef = React.createRef();
        const params = new URLSearchParams(this.props.queryParams);
        this.templateId = params.get('templateId');
        this.templateType = params.get('templateType');
        this.companyId = params.get("companyId") || this.props.companyId;
        this.activeTab = params.get("activeTab");
        this.name = params.get("name");
        this.subject = params.get("subject");
        this.mainImageReq = Boolean(params.get("mainImageReq"));
        this.state = {
            isMobile: (window.innerWidth < 576 ? true : false),
            activeTab: (this.activeTab ? this.activeTab : "1"),
            campaign: null,
            productCategoriesArray: [],
            tagsModalIsOpen: false,
            deleteModalIsOpen: false,
            validCronExpression: true,
            activeFromDay: moment().toDate(),
            activeFromTime: moment(),
            renderCollectionVisualizer: true,
            campaignNotSavedModal: false,
            emailModalIsOpen: false
        }
        this.loadCampaignAndProductCategories();
    }

    loadCampaignAndProductCategories = () => {
        this.props.setLoading(true);
        //estoy en el constructor, aca puedo mutar state directamente
        // this.state.campaign = null;
        // this.state.productCategoriesArray = [];

        this.setState({
            campaign: null,
            productCategoriesArray: [],
        })

        let productCategories = [];
        let productCategoriesArray = [];
        let productCategoriesPromise;
        if (this.modeIsEdit()) {
            productCategoriesPromise = getCampaignProductCategories(this.props.id)
        } else {
            //si estoy clonando, mando companyId que esta en /campaigns/:id/create, sino mando lo que me viene por URL de creacion de campania
            productCategoriesPromise = getProductCategoriesByCompany(this.companyId ? this.companyId : this.props.id)
        }
        productCategoriesPromise.then((res => {
            productCategories = res;
            for (var i in productCategories) {
                productCategoriesArray.push(productCategories[i])
            }
            this.setState({
                productCategoriesArray: productCategoriesArray
            })

            if (this.state.campaign !== null) {
                this.props.setLoading(false);
            }
        })).catch((error) => {
            if (this.state.campaign !== null) {
                this.props.setLoading(false);
            }
        })
        let campaignPromise;
        if (this.modeIsEdit()) {
            campaignPromise = getCampaignDetail(this.props.id)
        } else {
            if (this.templateType === "CUSTOM") {
                //type is CUSTOM, entonces estoy clonando una campania, sea por creacion de campania o por duplicacion
                //si estoy clonando, mando companyId que esta en /campaigns/:id/create, sino mando lo que me viene por URL de creacion de campania
                campaignPromise = getEmptyDuplicatedCampaign((this.props.id ? this.props.id : this.templateId), this.companyId)

            } else {
                // necesito traer una campania sin ID, totalmente vacia pero con un template HTML nada mas.
                campaignPromise = getEmptyCampaignWithTemplate(this.templateId, this.companyId)
            }
        }
        campaignPromise.then(res => {
            this.setState({ campaign: res, deliverySettingSelected: this.getDeliverySettingSelected(res) });
            if (this.state.productCategoriesArray !== []) {
                this.props.setLoading(false);
            }
            getImagesForCompany(res.companyId)
                .then((responseImages) => {
                    let imagesToSet = responseImages.data.data ? responseImages.data.data : [];

                    // esto saca del link que trae el backend la parte de /static adelante, 
                    // es para que en docker ande, pero no esta bien
                    imagesToSet.map(image => image.src = image.src.replace("/static", ""));
                    this.setState({
                        images: imagesToSet
                    })
                }).catch((error) => {
                    this.setState({ ...this.state });
                })
            getPromoCodes(res.companyId)
                .then((res) => {
                    let promocodes = res.data.data.map(object => {
                        return {
                            "text": `${object.tag} (${this.props.t(`${TRANSLATIONS_PREFIX}availablePromocodes`, { count: object.availableCodes })})`,
                            "value": object.tag,
                            "checked": (object.tag === this.state.campaign.promoCodeTag)
                        }
                    })
                    promocodes = [{ text: this.props.t(`${TRANSLATIONS_PREFIX}noPromocodes`), value: null, checked: !promocodes.some(code => code.checked === true) }, ...promocodes]

                    this.setState({ promocodes: promocodes })
                })
        }).catch((error) => {
            console.error(error)
            if (this.state.productCategoriesArray !== []) {
                this.props.setLoading(false);
            }
        })
    }

    getDeliverySettingSelected = (campaign) => {
        let optionSelected = ''
        if ((campaign.cronExpression === null || campaign.cronExpression === "") && campaign.sendOnceOn === null) {
            optionSelected = deliverySettingsOptions.noProgram;
        } else if (campaign.cronExpression) {
            optionSelected = deliverySettingsOptions.sendPeriodically
            this.setState({
                currentCronExpression: cronstrue.toString(campaign.cronExpression
                    , { verbose: true, locale: i18next.languages[0].slice(0, 2) },
                    { dayOfWeekStartIndexZero: false })
            })
        } else if (campaign.sendOnceOn) {
            optionSelected = deliverySettingsOptions.sendOnce
        }
        return optionSelected;
    }

    modeIsEdit = () => {
        return this.props.mode === "edit";
    }
    templateTypeIsCustom = () => {
        return this.templateType === "CUSTOM"
    }


    componentDidUpdate = (prevState, newState) => {
        if (this.state.isMobile && window.innerWidth > 576) {
            this.setState({
                isMobile: false
            })
        }
        if (!this.state.isMobile && window.innerWidth < 576) {
            this.setState({
                isMobile: true
            })
        }
    }

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab });
        if (tab === "2" && this.state.selectedNewSegment) {
            this.renderCollectionVisualizer()
            this.setState({ selectedNewSegment: false })
        }
    }

    getSwal = (type) => {
        let swalParams = { type: 'error' };
        const { t } = this.props;
        switch (type) {
            case 'benefitIsEmpty':
                swalParams = {
                    title: t(`${TRANSLATIONS_PREFIX}benefitEmptyTitle`),
                    text: t(`${TRANSLATIONS_PREFIX}benefitEmptyDesc`),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t(`${TRANSLATIONS_PREFIX}save`),
                    cancelButtonText: t(`${TRANSLATIONS_PREFIX}cancel`),
                    confirmButtonColor: '#1ab394'
                }
                break;
            case 'campaignHasErrors':
                swalParams = {
                    ...swalParams,
                    title: t(TRANSLATIONS_PREFIX + "campaignHasErrors"),
                    html: t(TRANSLATIONS_PREFIX + "checkErrors")
                }
                break
            case 'qrInTemplate':
                swalParams = {
                    title: t(`${TRANSLATIONS_PREFIX}benefitEmptyTitle`),
                    text: t(`${TRANSLATIONS_PREFIX}qrNoBenefitDesc`),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t(`${TRANSLATIONS_PREFIX}save`),
                    cancelButtonText: t(`${TRANSLATIONS_PREFIX}cancel`),
                    confirmButtonColor: '#1ab394'
                }
                break;
            case 'qrNotInTemplate':
                swalParams = {
                    title: t(`${TRANSLATIONS_PREFIX}qrNotInTemplate`),
                    text: t(`${TRANSLATIONS_PREFIX}qrNotInTemplateDesc`),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t(`${TRANSLATIONS_PREFIX}save`),
                    cancelButtonText: t(`${TRANSLATIONS_PREFIX}cancel`),
                    confirmButtonColor: '#1ab394'
                }
                break;
            default:
                break;
        }
        return swalParams
    }

    saveCampaign = (isSubmit, closeOnSave) => {
        let qrPresentInTemplate = this.formRef.current.state.values.campaign.htmlTemplate.toString().includes('{qrCode}')
        let campaignBenefitIsEmpty = benefitIsEmpty(this.formRef.current.state.values.campaign);
        if (Object.keys(this.formRef.current.state.errors).length === 0
            && this.state.validCronExpression) {
            //si el beneficio esta vacio, le aviso
            if (isSubmit) {
                if (campaignBenefitIsEmpty) {
                    MySwal.fire(this.getSwal(qrPresentInTemplate ? 'qrInTemplate' : 'benefitIsEmpty'))
                        .then((result) => {
                            if (result.value) this.submitForm(this.formRef.current.state.values, closeOnSave);
                        });
                }
                else if (!qrPresentInTemplate) {
                    MySwal.fire(this.getSwal('qrNotInTemplate'))
                        .then((result) => {
                            if (result.value) this.submitForm(this.formRef.current.state.values, closeOnSave);
                        });
                } else {
                    this.submitForm(this.formRef.current.state.values, closeOnSave);
                }
            }
            else {
                this.submitForm(this.formRef.current.state.values, closeOnSave);
            }
            return true
        } else {
            MySwal.fire(this.getSwal('campaignHasErrors'))
            this.setState({ reRender: !this.state.reRender })
            return false
        }
    }

    openEmailModal = () => {
        if (this.formRef.current.state.errors && this.formRef.current.state.errors.campaign && this.formRef.current.state.errors.campaign.fromAddress) {
            MySwal.fire({
                type: 'error',
                title: this.props.t(TRANSLATIONS_PREFIX + "campaignHasErrors"),
                html: this.props.t(TRANSLATIONS_PREFIX + "checkFromAddress")
            })
        } else {
            this.setState({ emailModalIsOpen: true })
        }
    }

    minimalEditorModal = () => {
        return (
            <Modal isOpen={this.state.minimalEditorIsOpen} fullscreen className="minimalEditorModal">
                <ModalBody className="p-0">
                    <MinimalEditor
                        setLoading={(val) => this.props.setLoading(val)}
                        onBack={this.closeEditor}
                        onClose={this.closeEditor}
                        t={this.props.t}
                        campaignId={this.state.campaign.id}
                        availableStores={this.props.availableStores}
                        companyId={this.props.companyId}
                        mode="edit"
                        disableMaximalEdition={true}
                    />
                </ModalBody>
            </Modal>
        )
    }

    closeEditor = () => {
        this.setState({
            minimalEditorIsOpen: false
        })
        this.loadCampaignAndProductCategories()
    }

    renderActions = () => {
        // let duplicateAction = {
        //     key: 1,
        //     label: this.props.t(TRANSLATIONS_PREFIX + 'duplicate'),
        //     onClick: ()=>{this.props.history.push(`/campaigns/${this.state.campaign.id}/create`)},
        //     showAction: () => (this.modeIsEdit() && !this.state.campaign.isPredefined)
        // };

        let testEmail = {
            key: 141,
            label: this.props.t(MINIMAL_EDITOR_TRANSLATIONS_PREFIX + 'testMail'),
            onClick: () => { this.openEmailModal() }
        };

        let deleteAction = {
            key: 2,
            label: this.props.t(TRANSLATIONS_PREFIX + 'delete'),
            onClick: () => { this.setState({ deleteModalIsOpen: true }) },
            showAction: () => (this.modeIsEdit() && !this.state.campaign.isPredefined)
        }

        let previewAction = {
            key: 3,
            label: this.props.t(TRANSLATIONS_PREFIX + 'preview'),
            color: "primary",
            outline: true,
            primary: (!this.state.isMobile),
            renderMode: (!this.state.isMobile ? ActionRenderMode.LINK : ActionRenderMode.WITH_TEXT),
            onClick: () => this.handlePreview("open")
        };

        let goToMinimalEditorAction = {
            key: 4,
            label: this.props.t(TRANSLATIONS_PREFIX + 'goToMinimalEditor'),
            color: "primary",
            outline: true,
            primary: (!this.state.isMobile),
            renderMode: (!this.state.isMobile ? ActionRenderMode.BUTTON : ActionRenderMode.WITH_TEXT),
            showAction: (() => this.modeIsEdit() && this.state.campaign.isPredefined),
            onClick: () => { this.openMinimalEditor() }
        };

        let saveAndCloseAction = {
            key: 5,
            label: this.props.t(TRANSLATIONS_PREFIX + 'saveAndClose'),
            color: "primary",
            primary: true,
            renderMode: ActionRenderMode.BUTTON,
            onClick: () => this.saveCampaign(true, true)
        }

        let saveAction = {
            key: 5,
            label: this.props.t(TRANSLATIONS_PREFIX + 'save'),
            color: "primary",
            primary: false,
            renderMode: ActionRenderMode.WITH_TEXT,
            onClick: () => this.saveCampaign(true, false)
        }

        const actionsCollection = [deleteAction, previewAction, goToMinimalEditorAction, testEmail, saveAction]
        let actions;

        if (this.state.isMobile) {
            actions = [
                {
                    key: 3,
                    label: this.props.t(MINIMAL_EDITOR_TRANSLATIONS_PREFIX + "moreOptions"),
                    color: "primary",
                    outline: true,
                    primary: true,
                    renderMode: ActionRenderMode.DROPDOWN,
                    content: () => actionsCollection.map(ac => <Action renderMode={ActionRenderMode.WITH_TEXT} {...ac}></Action>)
                },
                saveAndCloseAction
            ]
        }
        else {
            actions = actionsCollection.concat([saveAndCloseAction]);
        }
        return <Actions actions={actions} renderMode={RenderMode.DEFAULT} />
    }

    openMinimalEditor = () => {
        //guardo la campania antes de ir al minimal
        let hasErrors = this.saveCampaign(false, false)
        if (hasErrors) {
            setTimeout(() => this.setState({ minimalEditorIsOpen: true }), 500)
        }
    }

    setPreviewIsOpen = (isOpen) => {
        return new Promise((resolve) => {
            this.setState({
                preview: isOpen
            }, () => resolve())
        })
    }

    handlePreview = (action) => {
        handlePreview(this.setPreviewIsOpen, (action === "open"));
    }

    onBack = () => {
        //si me estoy yendo, hice cambios y no los guarde, entonces le aviso al usuario
        let formikCampaign = this.formRef.current.state.values.campaign;
        if (campaignsAreEqual(formikCampaign, this.state.campaign) && this.modeIsEdit()) {
            this.props.history.push(`/campaigns`);
        } else {
            this.setState({ campaignNotSavedModal: true })
        }
    }

    campaignNotSavedModal = () => {
        const { t } = this.props;
        return (
            <Modal className="campaignNotSavedModal" toggle={() => this.setState({ campaignNotSavedModal: false })} isOpen={this.state.campaignNotSavedModal} centered>
                <ModalBody>
                    <span className="modalTitle d-block">{t(`${TRANSLATIONS_PREFIX}notSavedModal.title`)}</span>
                    <span className="modalContent">{t(`${TRANSLATIONS_PREFIX}notSavedModal.content`)}</span>
                    <div className="modalButtons">
                        <MDBBtn color="primary" onClick={() => this.props.history.push('/campaigns')}>{t(`${TRANSLATIONS_PREFIX}notSavedModal.exit`)}</MDBBtn>
                        <button style={{ textDecoration: "none", color: "#fff" }} className="link-like-btn-important" onClick={() => this.setState({ campaignNotSavedModal: false })}>{t(`${TRANSLATIONS_PREFIX}notSavedModal.cancel`)}</button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }


    renderHeader = () => {
        return <div id="header">
            <div className="left-side">
                <div className="icon-container">
                    <MDBIcon onClick={this.onBack} icon="arrow-left"></MDBIcon>
                </div>
                <div className="titleContainer">
                    <h1 className="text-truncate">{this.props.t('panels.campaigns.otherLabels.' + (this.modeIsEdit() ? 'edit' : 'create')) + ' ' + (this.modeIsEdit() ? this.state.campaign.name : this.props.t(TRANSLATIONS_PREFIX + "newCampaign"))}</h1>
                </div>
                {this.state.campaign.isPredefined &&
                    <MDBBadge color="warning" className="smartBadge shadow-none">
                        <MDBIcon far icon="lightbulb" /> {this.props.t(MINIMAL_EDITOR_TRANSLATIONS_PREFIX + "smartCampaign")}
                    </MDBBadge>
                }
            </div>
            <div className="right-side">
                {this.renderActions()}
            </div>
        </div>
    }

    submitForm = (values, isSubmit) => {
        this.props.setLoading(true)
        saveCampaign(values, this.props.t, this.state.productCategoriesArray, updateCampaign)
            .then((res) => {
                if (this.state.sendCampaignNow) {
                    sendCampaign(res.id, res.companyId)
                }
                this.props.setLoading(false)
                if (isSubmit) {
                    this.props.history.push(`/campaigns/${res.id}`)
                } else {
                    this.setState({
                        campaign: res
                    })
                    this.formRef.current.setFieldValue("campaign", res);
                }
            })
    }

    getInitialValues = campaign => {
        const initialValues = {};
        initialValues.campaign = campaign; //JSON.parse(JSON.stringify(campaign));
        initialValues.campaign.campaignSubjectIsValid = true;
        if (campaign.segment && campaign.segment.filters) {
            campaign.segment.filters.forEach(field => {
                initialValues["filter_" + field.id] = field.operatorValues; //JSON.parse(JSON.stringify(field.operatorValues));
            });
        }
        initialValues.campaign.subject = this.subject || campaign.subject;
        initialValues.campaign.name = this.name || campaign.name;
        initialValues.campaign.mainImageReq = this.mainImageReq;
        if (initialValues.cronExpression && (!initialValues.usersNotReelegibleDaysQtty || initialValues.usersNotReelegibleDaysQtty < 1)) initialValues.usersNotReelegibleDaysQtty = 1;
        return initialValues;
    }

    getFormContent = () => {
        let parameters = {
            isEditing: true,
            t: this.props.t,
            formRef: this.formRef,
            productCategoriesArray: this.state.productCategoriesArray,
            withLabel: true
        }

        let formContent = emailContentContainers.map((container, index) => {
            let containerParameters = parameters;
            containerParameters.key = index;
            switch (container.name) {
                case containers.benefit.name:
                    containerParameters.campaignBeingEdited = this.state.campaign;
                    break;
                case containers.button.name:
                    containerParameters.campaignBeingEdited = this.formRef.current.state.values.campaign;
                    break;
                case containers.validity.name:
                    containerParameters.campaignBeingEdited = this.formRef.current.state.values.campaign;
                    break;
                case containers.image.name:
                    containerParameters.show = !!this.mainImageReq
                    break;
                default:
                    break;
            }
            return container.renderFunction(containerParameters);
        });
        return formContent
    }

    renderEmailContent = () => {

        if (this.state.renderCustomOptions) {
            const { editor } = this.editorRef.current;

            //media gallery button

            //creo un elemento span
            var mediaGalleryContainer = document.createElement('span');
            //le doy id
            mediaGalleryContainer.id = "toolbar-media-gallery"
            //le doy classNames para los estilos de ckeditor (que se vea como un boton del toolbar)
            mediaGalleryContainer.classList = ["cke_toolgroup custom-toolbar-extra"]
            //agrego este span al toolbar
            const toolboxRef = document.getElementById(editor.id + "_toolbox")
            if (toolboxRef) {
                toolboxRef.appendChild(mediaGalleryContainer)
                //renderizo dentro del span el media gallery 
                ReactDOM.render(
                    this.mediaGalleryButton(),
                    document.getElementById("toolbar-media-gallery"));
            }

            // tags button

            var tagsContainer = document.createElement('span');
            tagsContainer.id = "toolbar-tags"
            tagsContainer.classList = ["cke_toolgroup custom-toolbar-extra"]
            if (toolboxRef) {
                toolboxRef.appendChild(tagsContainer)
                ReactDOM.render(
                    this.tagsButton(),
                    document.getElementById("toolbar-tags"));
            }

            //delivery button

            var deliveryContainer = document.createElement('span');
            deliveryContainer.id = "toolbar-delivery"
            deliveryContainer.classList = ["cke_toolgroup custom-toolbar-extra"]
            if (toolboxRef) {
                toolboxRef.appendChild(deliveryContainer)
                ReactDOM.render(
                    this.deliveryButton(),
                    document.getElementById("toolbar-delivery"));
            }
        }

        let toolbarOptions = [
            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
            { name: 'styles', items: ['Format', 'Font', 'FontSize'] },
            { name: 'colors', items: ['TextColor', 'BGColor'] },
            { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Blockquote', 'JustifyLeft', 'JustifyCenter', 'JustifyRight'] },
            { name: 'links', items: ['Link'] },
            { name: 'insert', items: ['Table', 'EmojiPanel'] },
            { name: 'document', items: ['Source'] },
        ]

        if (this.state.isMobile) {
            toolbarOptions = [['Bold', 'Italic', 'TextColor'],
            ['JustifyLeft', 'JustifyCenter'],
            ['EmojiPanel']]
        }

        return <div className="email-content">
            {this.tagsModal()}
            {this.dilveriesModal()}
            <MDBCard className="p-4">
                {this.getFormContent()}
            </MDBCard>
            <div id="html-editor-container">
                {this.state.isMobile &&
                    <div id="htmlContentDisclaimer">
                        <span>{this.props.t(`${TRANSLATIONS_PREFIX}htmlMobileDisclaimer`)}</span>
                    </div>
                }
                {this.state.images && <FastField
                    name="campaign.htmlTemplate"
                    render={(props) => (
                        <>
                            <CKEditor
                                data={props.field.value}
                                ref={this.editorRef}
                                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                                config={{
                                    height: "70vh",
                                    toolbar: toolbarOptions,
                                    extraPlugins: 'colorbutton, font, emoji, smiley, image, justify, colordialog, dialog, dialogui',
                                    removeButtons: "",
                                    allowedContent: true,
                                    resize_enabled: false,
                                    on: {
                                        instanceReady: () => {
                                            this.setState({ renderCustomOptions: true },
                                                () => setTimeout(() => this.setState({ renderCustomOptions: false }), 1000)
                                            )
                                        },
                                        change: (value) => {
                                            props.form.setFieldValue(props.field.name, value.editor.getData());
                                        }
                                    }
                                }}
                            />
                        </>
                    )}
                />}

            </div>
        </div>;
    }

    createSegmentFilters = () => {
        var adminCompanyStores = this.props.availableStores;
        let filters = [];
        filters = [
            {
                id: 'stores',
                type: 'multiselect',
                search: true,
                className: 'col-12',
                placeholder: this.props.t('panels.campaigns.filters.store'),
                label: this.props.t('panels.campaigns.filters.store'),
                priority: true,
                visible: true,
                values: adminCompanyStores.map(store => ({
                    checked: false,
                    value: store.id.toString(),
                    text: store.name,
                    dependencyId: store.companyId
                })),
                dependsOn: 'company'
            },
            {
                id: "timePeriod",
                label: this.props.t(TRANSLATIONS_PREFIX + "filters.lastEditDate"),
                type: "timeperiod",
                icon: 'calendar-alt',
                className: 'col-12',
                placeholder: this.props.t(TRANSLATIONS_PREFIX + "filters.lastEditDate"),
                priority: true,
                visible: true,
                options: getAllTimePeriods(),
            }
        ].concat(filters)

        return filters;
    }

    insertHtml = (htmlToInsert) => {
        //obtengo una referencia al editor (con el ref)
        const { editor } = this.editorRef.current;
        //inserto html (en donde esta el cursor parado)
        editor.insertHtml(htmlToInsert);
        // actualizo formik para que cuando se guarde la campaña el html esté al día
        this.formRef.current.setFieldValue("campaign.htmlTemplate", editor.getData());
    }

    insertText = (textToInsert) => {
        //obtengo una referencia al editor (con el ref)
        const { editor } = this.editorRef.current;
        //inserto html (en donde esta el cursor parado)
        editor.insertText(textToInsert);
        // actualizo formik para que cuando se guarde la campaña el html esté al día
        this.formRef.current.setFieldValue("campaign.htmlTemplate", editor.getData());
    }

    mediaGalleryButton = () => {
        //suprimir warning de que el a no tiene href. 
        //El ckeditor pone estilos muy especificos que no nos vale la pena recrear.
        /* eslint-disable jsx-a11y/anchor-is-valid */
        const token = useToken();
        return <a className="cke_button cke_button_off"><MediaGallery
            images={this.state.images}
            appendTo={"maximal-editor"}
            onSelect={(res) => {
                this.insertHtml(`<img style="width: 100%;" src="${res[0].image}" alt="${res[0].name}"/>`);
            }}
            showPreview={false}
            multipleSelection={false}
            triggerIcon={"image"}
	    token={token}
            postUrl={
                envConfig.servicesUrl + "/upload"
                + `?companyId=${this.state.campaign.companyId}`
            }
            triggerMode="link"
        /></a>
        /* eslint-enable */

    }

    tagsButton = () => {
        //suprimir warning de que el a no tiene href. 
        //El ckeditor pone estilos muy especificos que no nos vale la pena recrear.
        /* eslint-disable jsx-a11y/anchor-is-valid */
        return (
            <>
                <a
                    id="tags-container"
                    onClick={() => this.setState({ tagsModalIsOpen: true })}
                    className="cke_button cke_button_off"
                >
                    <MDBIcon icon="tags"></MDBIcon>
                </a>
            </>)
        /* eslint-enable */
    }

    tagsModal = () => <Modal toggle={() => this.setState({ tagsModalIsOpen: false })} isOpen={this.state.tagsModalIsOpen}>
        <ModalHeader toggle={() => this.setState({ tagsModalIsOpen: false })}>
            {this.props.t(TRANSLATIONS_PREFIX + "selectATag")}
        </ModalHeader>
        <ModalBody className="tags-modal">
            <Select
                expanded
                options={replacementOptions(this.props.t)}
                getValue={(val) => {
                    this.insertText(val[0]);
                    this.setState({ tagsModalIsOpen: false });
                }} />
        </ModalBody>
    </Modal>

    deliveryButton = () => {
        //suprimir warning de que el a no tiene href. 
        //El ckeditor pone estilos muy especificos que no nos vale la pena recrear.
        /* eslint-disable jsx-a11y/anchor-is-valid */
        return (
            <>
                <a
                    id="delivery-container"
                    onClick={() => this.setState({ deliveriesModalIsOpen: true })}
                    className="cke_button cke_button_off"
                >
                    <MDBIcon icon="motorcycle"></MDBIcon>
                </a>
            </>)
        /* eslint-enable */
    }

    dilveriesModal = () => {
        const options = deliveryOptions(this.props.t);
        return <Modal toggle={() => this.setState({ deliveriesModalIsOpen: false })} isOpen={this.state.deliveriesModalIsOpen}>
            <ModalHeader toggle={() => this.setState({ deliveriesModalIsOpen: false })}>
                {this.props.t(TRANSLATIONS_PREFIX + "deliveryButton")}
            </ModalHeader>
            <ModalBody className="deliveries-modal">
                <Select
                    expanded
                    options={options}
                    getValue={(val) => {
                        const htmlToAppend = options.find(de => de.value === val[0]).htmlElement;
                        this.insertHtml(htmlToAppend);
                        this.setState({ deliveriesModalIsOpen: false });
                    }} />
            </ModalBody>
        </Modal>
    }

    renderCollectionVisualizer = () => {
        this.setState({
            renderCollectionVisualizer: false
        }, () => {
            this.setState({
                renderCollectionVisualizer: true
            })
        })
    }

    renderSegments = () => {
        return segmentContainers.map(container => container.renderFunction({
            formRef: this.formRef,
            show: this.state.renderCollectionVisualizer,
            onRowClick: this.onRowClick,
            t: this.props.t,
            isMobile: this.state.isMobile,
            TRANSLATIONS_PREFIX: TRANSLATIONS_PREFIX
        }));
    }

    onRowClick = (row, event) => {
        this.formRef.current.setFieldValue('campaign.segment', row)
        setTimeout(() => this.setState({ reRender: !this.state.reRender, selectedNewSegment: true }), 0)
    }

    renderButton = (type, condition) => {
        let button = <MDBInput checked={condition} label=" " type="radio" id="radio1" />
        if (type !== "radio") {
            button = <div><MDBIcon icon={type} /></div>
        }
        return button
    }

    handleDeliverySettingClick = (option) => {
        let setFieldValue = this.formRef.current.setFieldValue;
        if (option !== this.state.deliverySettingSelected) {
            this.setState({ deliverySettingSelected: option })
            setFieldValue("campaign.usersNotReelegibleDaysQtty", 1)
            switch (option) {
                case deliverySettingsOptions.sendPeriodically:
                    setFieldValue("campaign.sendOnceOn", null)
                    setFieldValue("campaign.activeFrom", moment().add(1, 'h'))
                    break;
                case deliverySettingsOptions.sendOnce:
                    setFieldValue("campaign.sendOnceOn", moment().add(1, 'h'))
                    setFieldValue("campaign.activeFrom", null)
                    setFieldValue("campaign.cronExpression", null)
                    break;
                case deliverySettingsOptions.noProgram:
                    setFieldValue("campaign.sendOnceOn", null)
                    setFieldValue("campaign.cronExpression", null)
                    setFieldValue("campaign.activeFrom", null)
                    break;
                default:
                    break;
            }
        }
    }

    renderDeliveryOptionCard = (isCollapsable, deliveryOption, collapsedContent) => {
        const optionContainerClassName = "optionContainer"
        return (
            isCollapsable ?
                <div className="bbCollapsable">
                    <Collapsable
                        padding="p-0"
                        noButton={true}
                        isOpen={this.state.deliverySettingSelected === deliveryOption}
                        toggle={() => this.handleDeliverySettingClick(deliveryOption)}
                        notCollapsed={
                            <div className={optionContainerClassName}>
                                {this.renderButton("radio", this.state.deliverySettingSelected === deliveryOption)}
                                <span>{this.props.t(TRANSLATIONS_PREFIX + deliveryOption)}</span>
                            </div>
                        }
                        collapsed={<div>{collapsedContent}</div>}
                    />
                </div>
                :
                <div className={optionContainerClassName}
                    onClick={() => this.handleDeliverySettingClick(deliveryOption)}
                >
                    {this.renderButton("radio", this.state.deliverySettingSelected === deliveryOption)}
                    <span>{this.props.t(TRANSLATIONS_PREFIX + deliveryOption)}</span>
                </div>
        )
    }

    renderDeliverySettings = () => {
        const { t } = this.props;
        const { campaign, deliverySettingSelected } = this.state;
        return (
            <div className="deliverySettings">
                <div className="deliveryContainer">
                    {!campaign.active && (deliverySettingSelected === deliverySettingsOptions.sendPeriodically || deliverySettingSelected === deliverySettingsOptions.sendOnce) &&
                        <div id="inactiveCampaignDisclaimer">
                            <MDBIcon icon="exclamation-triangle" />
                            <span>{t(`${TRANSLATIONS_PREFIX}inactiveCampaignDisclaimer`)}</span>
                        </div>
                    }
                    <span className="deliveryTitle">{t(TRANSLATIONS_PREFIX + "sendDate")}</span>
                    {containers.sendDates.renderFunction({
                        campaignBeingEdited: this.formRef.current.state.values.campaign,
                        isEditing: true,
                        t: t,
                        deliverySettingsOptions: deliverySettingsOptions,
                        TRANSLATIONS_PREFIX: TRANSLATIONS_PREFIX,
                        formRef: this.formRef,
                        deliverySettingSelected: deliverySettingSelected,
                        validCronExpression: this.state.validCronExpression,
                        setValidCronExpression: (valid) => () => this.setState({ validCronExpression: valid }),
                        renderDeliveryOptionCard: this.renderDeliveryOptionCard,
                        activeFromTo: this.activeFromTo
                    })}
                    {this.state.promocodes &&
                        containers.promoCodes.renderFunction({
                            TRANSLATIONS_PREFIX: TRANSLATIONS_PREFIX,
                            renderButton: this.renderButton,
                            promocodes: this.state.promocodes,
                            t: t
                        })
                    }
                </div>
            </div>
        );
    }

    validationStyles = (tab, validLabel, invalidLabel) => {
        let emailContentHasErrors = false;
        let deliveryOptionsHasErrors = false;
        if (Object.keys(this.formRef.current.state.errors).length > 0) {
            emailContentContainers.forEach(container => {
                container && container.fields && container.fields.forEach(field => {
                    if (Object.keys(this.formRef.current.state.errors.campaign).includes(field)) {
                        emailContentHasErrors = true;
                    }
                })
            })
            if (!this.state.validCronExpression) deliveryOptionsHasErrors = true;
            deliverySettingsContainers.forEach(container => {
                container.fields.forEach(field => {
                    if (Object.keys(this.formRef.current.state.errors.campaign).includes(field)) {
                        deliveryOptionsHasErrors = true;
                    }
                })
            })
        }
        const campaign = this.formRef.current.state.values.campaign;
        switch (tab) {
            case "emailContent":
                return (!emailContentHasErrors ? validLabel : invalidLabel)
            case "segment":
                return (campaign.segment && campaign.segment.id > 0 ? validLabel : invalidLabel)
            case "options":
                return (!deliveryOptionsHasErrors ? validLabel : invalidLabel);
            default:
                break;
        }
    }

    renderTabs = () => {
        return (
            <div id="tabsContainer" className="classic-tabs">
                <MDBNav classicTabs>
                    <MDBNavItem className="section-tab-item" active={this.state.activeTab === "1"}>
                        <MDBNavLink className={"text-truncate " + this.validationStyles("emailContent", '', "invalidTab")} to="#" onClick={() => this.setActiveTab("1")} >
                            {this.props.t(TRANSLATIONS_PREFIX + (this.state.isMobile ? "content" : 'emailContent'))} <MDBIcon fas="true" icon={this.validationStyles("emailContent", 'check-circle', "exclamation-circle")}></MDBIcon>
                        </MDBNavLink>
                    </MDBNavItem>
                    {!this.state.campaign.isPredefined &&
                        <MDBNavItem className="section-tab-item" active={this.state.activeTab === "2"}>
                            <MDBNavLink className={"text-truncate " + this.validationStyles("segment", '', "invalidTab")} to="#" onClick={() => this.setActiveTab("2")} >
                                {this.props.t(TRANSLATIONS_PREFIX + 'segment')} <MDBIcon fas="true" icon={this.validationStyles("segment", 'check-circle', "exclamation-circle")}></MDBIcon>
                            </MDBNavLink>
                        </MDBNavItem>
                    }
                    <MDBNavItem className="section-tab-item" active={this.state.activeTab === "3"}>
                        <MDBNavLink className={"text-truncate " + this.validationStyles("options", '', "invalidTab")} to="#" onClick={() => this.setActiveTab("3")} >
                            {this.props.t(TRANSLATIONS_PREFIX + (this.state.isMobile ? 'options' : 'deliveryOptions'))} <MDBIcon fas="true" icon={this.validationStyles("options", 'check-circle', "exclamation-circle")}></MDBIcon>
                        </MDBNavLink>
                    </MDBNavItem>
                </MDBNav>
                <MDBTabContent
                    className="card mb-5"
                    activeItem={this.state.activeTab}
                >
                    <MDBTabPane tabId="1">
                        {this.renderEmailContent()}
                    </MDBTabPane>
                    <MDBTabPane tabId="2">
                        {this.renderSegments()}
                    </MDBTabPane>
                    <MDBTabPane tabId="3">
                        {this.renderDeliverySettings()}
                    </MDBTabPane>
                </MDBTabContent>
            </div>
        )
    }

    deleteCampaign = () => {
        deleteCampaign(this.state.campaign.id)
            .then(() => {
                this.setState({
                    confirmationModalIsOpen: false
                })
                this.props.history.push('/campaigns')
            })
    }

    deleteModal = () => {
        return (
            <Modal isOpen={this.state.deleteModalIsOpen} toggle={() => this.setState({ deleteModalIsOpen: false })} centered className="deletionModal">
                <ModalBody>
                    <p>
                        {(this.props.t('panels.campaigns.otherLabels.actions.deleteTitle'))} "{this.state.campaign.name}"
					</p>
                    <p>
                        {(this.props.t('panels.campaigns.otherLabels.actions.deleteText'))}
                        <br />
                        {(this.props.t('panels.campaigns.otherLabels.actions.deleteSubText'))}
                    </p>
                    <div className="text-right">
                        <MDBBtn color="red" size="sm" onClick={() => this.deleteCampaign()}>
                            <strong>
                                {(this.props.t('panels.campaigns.otherLabels.actions.deleteButton'))}
                            </strong>
                        </MDBBtn>
                        <button className="link-like-btn link-styled" onClick={() => this.setState({ deleteModalIsOpen: false })}>
                            {(this.props.t('panels.campaigns.otherLabels.actions.cancel'))}
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    getCampaign = () => {
        return this.formRef.current && this.formRef.current.state.values.campaign;
    }

    testEmail = () => {
        return (
            <>
                <Modal centered isOpen={this.state.emailModalIsOpen} toggle={() => this.setState({ emailModalIsOpen: false })}>
                    <ModalBody className="testEmailModal">
                        {this.state.emailModalIsOpen && <TestEmail
                            campaign={this.getCampaign()}
                            closeModal={() => this.setState({ emailModalIsOpen: false })}
                            onSuccess={() => toast(this.props.t(`${TRANSLATIONS_PREFIX}testEmailSuccessful`),
                                { containerId: "maximal-editor-toast" })}
                        />}
                    </ModalBody>
                </Modal>
            </>
        )

    }

    render() {
        if (!this.state.campaign) {
            return null;
        }
        const initialValues = this.getInitialValues(this.state.campaign);
        let campaign = this.formRef.current ? this.formRef.current.state.values.campaign : this.state.campaign;
        campaign.benefitMessage = getBenefitMessage(campaign, this.props.t, this.state.productCategoriesArray);
        return (
            <div className="mx-lg-5 pb-5" id="maximal-editor">
                <MDBContainer>
                    {this.deleteModal()}
                    <PreviewModal campaignName={campaign.name}
                        campaignSubject={campaign.subject}
                        campaignId={campaign.id}
                        customTemplate={() => { return getInProgressCampaignPreview(campaign) }}
                        isOpen={this.state.preview}
                        close={() => this.handlePreview("close")}
                        t={this.props.t} />
                    {this.renderHeader()}
                    {this.testEmail()}
                    {this.campaignNotSavedModal()}
                    <Toast containerId="maximal-editor-toast" autoClose={false} />
                    {this.modeIsEdit() && this.state.campaign.isPredefined && this.minimalEditorModal()}
                    <Formik
                        onSubmit={() => { }}
                        ref={this.formRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema(this.props.t, (this.state.campaign.isPredefined), true)}
                    >
                        <Form>
                            {this.formRef.current && <Field render={(props) => this.renderTabs()} />}
                        </Form>
                    </Formik>
                </MDBContainer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.misc.loading,
        availableStores: state.application.availableStores,
        companyId: state.application.selectedCompany.id
    }
}

// const getIfDefined = (obj, path) => {
//     let subPaths = path.split('.');
//     let gotUndefined = false;
//     subPaths.forEach(subPath => {
//         if(obj[subPath] && !gotUndefined){
//             obj = obj[subPath];
//         } else {
//             gotUndefined = true;
//         }
//     })
//     return gotUndefined ? {} : obj;
// }

export default connect(mapStateToProps, { setLoading })(translate('common')(withRouter(MaximalEditor)));
