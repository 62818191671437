import { envConfig } from "../../../config";

export const translations = {
  p1: {
    es: `¿Sabías que puedes conectar la aplicación de ${envConfig.companyName} con Gmail? Desde el momento que te conectes registraremos a todos los clientes que se contacten con tu comercio a través de tu dirección de email.`,
    en: `Did you know that you can connect the ${envConfig.companyName} application with Gmail? From the moment you connect, we will register all customers who contact you through your email address.`
  },
  p2: {
    es: "Conectarlo es muy sencillo:",
    en: "Connecting it is very simple:"
  },
  p4: {
    es: `Coloca los datos de tu cuenta en Gmail y que aceptes los permisos para trabajar junto con ${envConfig.companyName}.`,
    en: `Put your Gmail account and accept the permissions to work together with ${envConfig.companyName}.`
  },
  p5: {
    neutral: {
      es: "Por último, elige en ",
      en: "Finally, choose in"
    },
    strong: {
      es: `cuál de tus tiendas de ${envConfig.companyName} quieres recibir los datos de tus clientes de Gmail.`,
      en: `which of your ${envConfig.companyName} stores you want to receive data from your Gmail customers.`
    }
  },
  p6: {
    es: "¡Listo! ¡Ya estás conectado!",
    en: "Done! You're already connected!"
  },
  p7: {
    text: {
      es: "Para mayor información, haz",
      en: "For more information, do",
    },
    link: {
      es: "click aquí",
      en: "click here"
    }
  }
}