import React from 'react'
import { FastField } from "formik"
import { MDBInput } from "mdbreact"
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import className from 'classnames';
import i18next from 'i18next';
import './CampaignValidity.css';


const TRANSLATIONS_PREFIX = "panels.campaigns.minimalEditor.";
export const CampaignValidity = ({ isEditing, t, campaign }) => {
    const handleChange = (e, props) => {
        const { value } = e.target;
        props.form.setFieldValue(props.field.name, value);
    }
    if (!campaign ||
        campaign.isPredefined ||
        !campaign.countAsWT
    ) return null;

    let dayPickerRef;

    return <div className="campaignValidityContainer">
        <span className="validityLabel">
            {t(TRANSLATIONS_PREFIX + "campaignValidFor")}
        </span>
        <FastField
            name={"campaign.wtInterval"}
            render={(props) => {
                const hasError = props.form.errors && Object.keys(props.form.errors).length > 0 &&
                    Object.keys(props.form.errors.campaign).length > 0 &&
                    props.form.errors.campaign.wtInterval !== undefined
                return <div className={className({ "validityInputContainer": true, "hasError": hasError })} >
                    <MDBInput onChange={(e) => handleChange(e, props)} className="validityInput" type="number" value={props.field.value} />
                    {hasError && <span className="error-message">{props.form.errors.campaign.wtInterval}</span>}
                </div>
            }
            }
        />
        <span className="validityLabel">
            {t(`${TRANSLATIONS_PREFIX}daysAfterBenefitOr`)}
        </span>
        <FastField
            name="campaign.wtValidTo"
            render={(props) => {
                const hasError = props.form.errors && Object.keys(props.form.errors).length > 0 &&
                    Object.keys(props.form.errors.campaign).length > 0 &&
                    props.form.errors.campaign.wtValidTo !== undefined && props.form.errors.campaign.wtValidTo
                let gmt0Date;
                if (props.field.value) {
                    let gmtLocalDate = new Date(props.field.value);
                    gmt0Date = new Date(gmtLocalDate.getTime() + gmtLocalDate.getTimezoneOffset() * 60000);
                } else {
                    gmt0Date = t("panels.campaigns.maximalEditor.unlimited");
                }
                return <div className={`wtValidToContainer ${hasError && 'hasError'}`}>
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        value={gmt0Date}
                        ref={ref => (dayPickerRef = ref)}
                        placeholder={t("panels.campaigns.maximalEditor.unlimited")}
                        component={props => <button className="dayPickerTrigger" {...props}>{props.value}</button>}
                        dayPickerProps={{
                            locale: i18next.languages[0].slice(0, 2),
                            localeUtils: MomentLocaleUtils,
                            todayButton: t("panels.campaigns.maximalEditor.unlimited"),
                            onTodayButtonClick: function () {
                                props.form.setFieldValue(props.field.name, null);
                                dayPickerRef.hideDayPicker();
                            }
                        }}

                        onDayChange={function (value) {
                            props.form.setFieldValue(props.field.name, value)
                        }}
                    />
                    {hasError && <span className="error-message">{hasError}</span>}
                </div>
            }} />
        <span className="validityLabel">
            {t(`${TRANSLATIONS_PREFIX}whateverHappensFirst`)}
        </span>
    </div>;
}

export default CampaignValidity

// const getFormattedDate = (value, t, type) => {
//     let result;
//     if(!value){
//        result = t("panels.campaigns.maximalEditor.unlimited")
//     }   
//     else {
//         let tempVar = moment.utc(value)
//         if(type === "datetime"){
//             result = `${tempVar.date()}/${(tempVar.month()+1)}/${tempVar.year()}`
//         } else if(type === "epoch"){
//             result = tempVar.valueOf()
//         }
//     }
//     return result;
// }