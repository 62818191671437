import React from 'react'
import { envConfig } from '../../config/config';
import { Image } from "botbit-ui-components"

export const NewLoader = ({ className = "", white }) => {

  return (
    <div className={`w-100 d-flex justify-content-center align-items-center ${className}`}>
      <Image
        style={{ width: '300px' }}
        src={`${envConfig.staticImgUrl}${white ? "loader_ray_blanco.gif" : "loader_ray.gif"}`}
      // src={`${envConfig.staticImgUrl}botbit-loader-blue.gif`}
      ></Image>
    </div>
  )
}