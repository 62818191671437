import React from 'react';
import { Select } from 'botbit-ui-components'
import './HeaderCompanySelector.scss'

const selectStyle = () => ({
    // El box principal
    control: (base, state) => {
        return {
            ...base,
            minWidth: 250,
            maxWidth: 250,
            minHeight: 30,
            maxHeight: 30,
            borderColor: state.isFocused ? "#00B492" : "#e5e6e7",
            "&:hover": { borderColor: "#e5e6e7" },
            boxShadow: null,
            backgroundColor: "#FFFFFF",
            cursor: "text",
            fontSize: "14px"
        }
    },
    // cada una de las opciones cuando el select se despliega
    option: (base, state) => {
        return {
            ...base,
            backgroundColor: state.isFocused ? "#00B492" : state.isSelected ? "transparent" : "transparent",
            color: state.isFocused ? "#FFFFFF" : state.isSelected ? "#555555" : "#555555",
            boxSizing: "border-box",
            // top right bottom left
            padding: "5px 8px 5px 8px",
            margin: "2px 5px 2px 5px"
        }
    },
    // el combo desplegable
    menuList: (base, state) => {
        return {
            overflowX: "hidden",
            ...base
        }
    },
    // para centrar el value
    singleValue: (base, state) => {
        return {
            ...base,
            top: "40%"
        }
    }
});

export const HeaderCompanySelector = ({ companies, onSelectCompany, selectedCompany }) => {
    const getCompaniesOptions = () => {
        return companies.map(company => {
            return {
                checked: company.id === selectedCompany.id,
                text: company.name,
                value: company.id
            }
        })
    }

    return <div data-intercom-target="company-header-selector" id="company-header-selector">
        {
            companies.length > 1 &&
            <Select
                search={true}
                options={getCompaniesOptions()}
                style={selectStyle()}
                getValue={onSelectCompany}
            />
        }
        {
            companies.length === 1 &&
            <div id="single-company">{selectedCompany.name}</div>
        }
        {selectedCompany && window.innerWidth > 991 && <img alt="" src={selectedCompany.logo} />}
    </div>
}
