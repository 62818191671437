import { FastField } from "formik";
import React from "react";
import {MDBInput} from "mdbreact";
import './CampaignName.css';

const CampaignName = ({isEditing, t}) => {
    return <div id="campaignNameContainer">
        <FastField
            name="campaign.name"
            render = {(props) => {
                const hasError = Object.keys(props.form.errors).length > 0 && 
                                 Object.keys(props.form.errors.campaign).length > 0 &&
                                 props.form.errors.campaign.name 
                return  <>
                            <label>{t('panels.campaigns.otherLabels.name')}</label>
                            {props.form.values.campaign.isPredefined ?
                                <label id="readOnlyLabel">{props.field.value}</label>
                                :
                                <>
                                    <MDBInput 
                                        name={props.field.name}
                                        type="text"
                                        className={(hasError ? "hasError" : "")}
                                        value={props.field.value} 
                                        onChange={(e) => props.form.setFieldValue(props.field.name, e.target.value)} />
                                    {hasError && <span className="error-message">{props.form.errors.campaign.name}</span>}
                                </>
                            }
                        </>
            }}
        />
    </div>
}

export default CampaignName;