import React from 'react';
import { connect } from 'react-redux';
import Select from '../../commons/components/utils/form/Select';
import { setLoading } from '../../commons/components/application/miscActions';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { sendCustomCampaignToUser } from '../../api/userApi.js';
import Loading from '../../commons/components/utils/Loading.js';
import { getBasicCampaignsByCompanyId } from '../../api/campaignsApi';

const MySwal = withReactContent(Swal);

class SendCampaignToUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: [],
      companies: [],
      loading: true,
    };
    this.getCampaigns(this.props.selectedCompany.id);
  }

  campaignsToOptions = () => {
    const { campaigns } = this.state;
    return campaigns.map((c) => ({
      ...c,
      value: c.id,
      label: c.name,
    }));
  };

  getCampaigns = async (companyId) => {
    const campaignsReponse = await getBasicCampaignsByCompanyId(companyId);
    const campaigns =
      campaignsReponse && campaignsReponse.data && campaignsReponse.data.data
        ? campaignsReponse.data.data
        : [];
    this.setState({ campaigns: campaigns, loading: false });
  };

  openAlert = () => {
    MySwal.fire({
      title: 'Confirmación ',
      text:
        "Enviar campaña '" +
        this.state.selectedCampaign.name +
        "' al cliente: " +
        this.props.user.name +
        ' ' +
        this.props.user.lastName,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#1ab394',
    }).then((result) => {
      if (result.value) {
        sendCustomCampaignToUser(
          this.props.user.id,
          this.state.selectedCampaign.id
        ).then((response) => {
          MySwal.fire({
            title: 'Enviado! ',
            text: 'Se ha enviado la campaña con éxito',
            type: 'success',
            confirmButtonColor: '#1ab394',
          });
        });
        this.props.onSuccess();
      }
    });
  };

  handleCampaignChange = (campaign) => {
    this.setState({ selectedCampaign: campaign });
  };

  render() {
    if (this.state.loading) return <Loading loading={true} />;
    return (
      <Container style={{ height: '400px' }}>
        <Row>
          <Col lg={9}>
            <Select
              className="mt-5"
              onChange={this.handleCampaignChange}
              options={this.campaignsToOptions()}
              isSearchable={true}
            ></Select>
          </Col>
          <Col lg={2}>
            <button
              className="btn btn-secondary m-1"
              disabled={!this.state.selectedCampaign}
              onClick={this.openAlert}
            >
              <FontAwesomeIcon icon={faShare} />
            </button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    availableStores: state.application.availableStores,
    admin: state.login.admin,
    selectedCompany: state.application.selectedCompany,
  };
};

export default connect(mapStateToProps, { setLoading })(SendCampaignToUserForm);
