import React from 'react'
import { Row, Col } from "react-bootstrap";
import { MDBCard, MDBCardBody } from 'mdbreact';
import { translations } from "../../../../config/translations/Clients/UserActivitySummary";
import translate from "../../../../config/translations/translate";

const daysWithoutComing = (last) => {
	if (last !== null) {
		var lastVisit = new Date();
		lastVisit.setTime(last);
		var difference = (new Date() - lastVisit);
		var minutes = Math.floor(difference / 60000);
		var days = Math.floor(minutes / 60 / 24);
		return days;
	}
	else {
		return 'N/A';
	}
}

const UserActivitySumary = ({ t, userDetails }) => (
	<MDBCard className='m-2'>
		<MDBCardBody>
			<hr className="my-1" />
			<Row className="small mb-2">
				<Item value={userDetails.visitationsCount} label={t("accumulatedVisits")} />
				<Item value={userDetails.walkthrougCount} label='Walkthroughs' />
			</Row>
			<hr className="my-1" />
			<Row className="small mb-2">
				<Item value={userDetails.npsAverage} label={t("averageScore")} avoidZero={true} />
				<Item value={daysWithoutComing(userDetails.lastVisitationDate)} label={t("daysWithoutComing")} />
			</Row>
			<hr className="my-1" />
			<Row className="small mb-2">
				<Item value={userDetails.mailDeliveredCount} label={t("receivedCampaigns")} />
				<Item value={userDetails.mailOpenedCount} label={t("openCampaigns")} />
			</Row>
			<Row className="small mb-2">
				<Item value={userDetails.onlinePurchaseCount} label={t("onlinePurchases")} />
				<Item value={userDetails.stimulatedPurchaseCount} label={t("stimulatedPurchases")} />
			</Row>
		</MDBCardBody>
	</MDBCard>
);

const Item = ({ value, label, avoidZero = false }) => (
	<Col>
		<div className='float-left' style={{ width: '2.5em' }}>
			<strong > {!value && avoidZero ? 'N/A' : value}</strong>
		</div>
		<span>{label}</span>
	</Col>

)

UserActivitySumary.translations = {
	...translations
}

export default translate(UserActivitySumary)
