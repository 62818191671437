export const objectToQueryString = (obj, page = undefined, order = undefined) => {
    return obj === undefined || obj === null ? '' : `?${page === undefined || page === null ? '' : 'pageNumber=' + page + '&'}` +
        Object.keys(obj).reduce(function (filtered, key) {
            
            if (obj[key] !== undefined && obj[key] !== null && obj[key].length > 0) {
                filtered.push(encodeURIComponent(key) + '=' +
                    encodeURIComponent(obj[key]));
            }
            return filtered;
        }, []).join('&') +
        `${(order === undefined || order === null) ? '' : '&sort=' + order.name + ',' + order.direction}`
}

export default objectToQueryString
