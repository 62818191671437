import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle as unknown } from '@fortawesome/free-solid-svg-icons'

export default class RegisteredTroughIcon extends React.Component{

    render(){
		switch (this.props.name) {
			case "WiFi":
				return (<FontAwesomeIcon icon= 'wifi' size='2x'/>);
			case "Importation":
				return (<FontAwesomeIcon icon= 'upload' size='2x'/>);
			case "Manual":
				return (<FontAwesomeIcon icon= 'hand-paper' size='2x'/>);
			case "Payment":
				return (<FontAwesomeIcon icon= 'shopping-cart' size='2x'/>);
			case "Other":
				return (<FontAwesomeIcon icon= 'question' size='2x'/>);
			case "Inquiry":
				return (<FontAwesomeIcon icon= 'comment' size='2x'/>);
			case "Form":
				return (<FontAwesomeIcon icon= 'wpforms' size='2x'/>);
			case "Reservation":
				return (<FontAwesomeIcon icon= 'chair' size='2x'/>);
			case "Tablet":
				return (<FontAwesomeIcon icon= 'tablet' size='2x'/>);
			case "CRM":
				return (<FontAwesomeIcon icon= 'users' size='2x'/>);
			case "OnlineOrdering":
				return (<FontAwesomeIcon icon= 'shopping-cart' size='2x'/>);
			case "ReviewRequest":
				return (<FontAwesomeIcon icon= 'paper-plane' size='2x'/>);
			case "Email":
				return (<FontAwesomeIcon icon= 'envelope' size='2x'/>);
			case "Whatsapp":
			case "FacebookMessenger":
			case "Instagram":
				return (<FontAwesomeIcon icon= 'comment' size='2x'/>);
			default : 
				return (<FontAwesomeIcon icon={unknown} size="2x"/>);
		}
    }
}


    /*

"data": [
    {
      "id": "WiFi",
      "name": "WiFi"
    },
    {
      "id": "Tablet",
      "name": "Tablet"
    },
    {
      "id": "Form",
      "name": "Form"
    },
    {
      "id": "Inquiry",
      "name": "Inquiry"
    },
    {
      "id": "Importation",
      "name": "Importation"
    },
    {
      "id": "Reservation",
      "name": "Reservation"
    },
    {
      "id": "Payment",
      "name": "Payment"
    },
    {
      "id": "Manual",
      "name": "Manual"
    },
    {
      "id": "Other",
      "name": "Other"
    }
  ]

*/
