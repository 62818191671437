import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBInput } from 'mdbreact';
import EmojiPicker from 'emoji-picker-react';
import { useSelector } from 'react-redux';
import { getFirstHundredReplyTemplates, saveReplyTemplate } from '../../api/feedbackApi';
import { Fragment } from 'react';
import { useMessengerData } from './useMessengerData';
import useWindowDimensions from '../../commons/utils/useWindowDimensions';
import { getNpsLink } from '../../api/storeApi';
import Compress from "compress.js";
import { emitTrackingEvent } from '../../api/trackingEvent';
import withEventTracking from '../../config/withEventTracking';
import { trackers } from "../../config/withEventTracking";
import translate from '../../config/translations/translate';
import {translations} from '../../config/translations/Messenger/Messenger';
import ReplyTemplateEditModal from '../feedback/ReplyTemplateEditModal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);
const compress = new Compress();

const CustomInput = ({minified, externalSend, trackEvent, t}) => {
    const { send, selectedChat, previewingImage, setPreviewingImage, setIsImagePreviewerOpen, isImagePreviewerOpen, chatController, selectedStore, setIsAppLoading } = useMessengerData();
    const chatCtl = chatController();
    const [textValue, setTextValue] = useState("");
    // const [isRecording, setIsRecording] = useState(false);
    // const [audioRecorderInstance, setAudioRecorderInstance] = useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [correlationId, setCorrelationId] = useState("");
    const currentCompanyId = useSelector(state => state.application.selectedCompany.id);
    const [showReplyTemplateEditModal, setShowReplyTemplateEditModal] = useState(false)
    const [isSendingReviewRequest, setIsSendingReviewRequest] = useState(false);
    const { isMedium } = useWindowDimensions();
    const selectedCompany = useSelector(state => state.application.selectedCompany);

    const textareaRef = useRef();
    const formRef = useRef();
    const templateSelector = useRef()
    const imgInput = useRef();

    useEffect(() => {
        if (!textareaRef || !textareaRef.current) return;
        textareaRef.current.addEventListener("keydown", (e) => {
            if (e.keyCode === 13) {
                if (!e.shiftKey) {
                        e.preventDefault();
                        formRef.current.requestSubmit();
                    }
            }
        })

        return () => {
            textareaRef.current.removeEventListener("keydown", (e) => {
                if (!e.shiftKey) {
                    e.preventDefault();
                    formRef.current.requestSubmit();
                }
            })
        }
    }, [textareaRef])

    useEffect(() => {
        requestTemplates();
    }, [currentCompanyId, selectedChat])

    const requestTemplates = () => {
        if (currentCompanyId) {
            (async () => {
                const templatesRes = (await getFirstHundredReplyTemplates(currentCompanyId, "CHAT")).data.data.items;
                let templatesFiltered = templatesRes;
                if (selectedChat.expiredState !== "ACTIVE") {
                    templatesFiltered = templatesRes.filter(template => template.isExternal && template.externalState === "APROVED" && template.channel === selectedChat.channel)
                }
                const templatesFormatted = templatesFiltered.map(t => ({ text: t.name, value: t }))
                setTemplateOptions(templatesFormatted)
            })()
        }
    }

    
    const onTextSubmit = useCallback((text) => {
        //console.log("onTextSubmit");
        let res;
        if (isImagePreviewerOpen) {
            res = previewingImage.value = text;
            setIsImagePreviewerOpen(false)
            setPreviewingImage({})
        } else {
            res = { type: "TEXT", value: text };
            if (correlationId && isSendingReviewRequest) {
                trackEvent("REVIEW_REQUEST_REQUESTED", {
                    correlationId: correlationId,
                    channel: 'WHATSAPP',
                    storeId: selectedStore.id
                }, false, [trackers.BACKEND])
                setIsSendingReviewRequest(false);
            }
        }
        chatCtl.setActionResponse(res)
        setTextValue("");
    }, [chatCtl]);
    
    // const audioChunks = useRef([])
    // const recordAudio = () => {
    //     return new Promise(resolve => {
    //         navigator.mediaDevices.getUserMedia({ audio: true })
    //             .then(stream => {
    //                 const mediaRecorder = new MediaRecorder(stream);

    //                 mediaRecorder.addEventListener("dataavailable", e => {
    //                     audioChunks.current.push(e.data);
    //                 })

    //                 const startRecording = () => {
    //                     mediaRecorder.start()
    //                     setIsRecording(true);
    //                 }

    //                 const stopRecording = () => {
    //                     return new Promise(resolve => {
    //                         mediaRecorder.addEventListener("stop", () => {
    //                             const audioBlob = new Blob(audioChunks.current)
    //                             const audioUrl = URL.createObjectURL(audioBlob);
    //                             const audio = new Audio(audioUrl);

    //                             const play = () => {
    //                                 audio.play()
    //                             }
    //                             setIsRecording(false);

    //                             resolve({ audioBlob, audioUrl, audio, play });
    //                         })
    //                         mediaRecorder.stop();
    //                     })
    //                 }

    //                 resolve({ startRecording, stopRecording })
    //             })
    //     })
    // }

    // const startRecording = async () => {
    //     const audioInstance = await recordAudio()
    //     setAudioRecorderInstance(audioInstance);

    //     audioInstance.startRecording()
    // }

    // const stopRecording = async () => {
    //     audioRecorderInstance.stopRecording()
    //         .then(aud => {
    //             aud.play()
    //             const { audioBlop, audioUrl, audio } = aud;
    //             console.log({ audioBlop, audioUrl, audio })

    //             const res = { type: "audio", value: audioUrl };
    //             chatCtl.setActionResponse(res)
    //         })
    //         .finally(() => {
    //             setAudioRecorderInstance(null);
    //             audioChunks.current = [];
    //         })

    // }

    const insertAtCursor = (myField, myValue) => {
        //IE support
        if (document.selection) {
            myField.focus();
            let sel = document.selection.createRange();
            sel.text = myValue;
        }
        //MOZILLA and others
        else if (myField.selectionStart || myField.selectionStart === '0') {
            const startPos = myField.selectionStart;
            const endPos = myField.selectionEnd;
            const finalTextValue = myField.value.substring(0, startPos)
                + myValue
                + myField.value.substring(endPos, myField.value.length);
            myField.value = finalTextValue;
            setTextValue(finalTextValue);
            myField.focus();
            myField.selectionStart = startPos + myValue.length;
            myField.selectionEnd = startPos + myValue.length;
        } else {
            myField.value += myValue;
            setTextValue(myValue)
        }
    }

    const handleEmojiClick = (e, emojiObject) => {
        // e.preventDefault()
        const textInput = document.getElementById("text-input-custom")
        insertAtCursor(textInput, emojiObject.emoji)
        setShowEmojiPicker(false);
    }

    const handleTemplateSend = (templateId) => {
        const msgBody = {
            templateId,
            conversationId: selectedChat.id
        }
        send(msgBody);
    }

    const handleImageUpload = async (e) => {
        const selectedFile = e.target.files[0]
        //console.log("CustomInput.handleImageUpload selectedFile", selectedFile);

        if (selectedFile && selectedFile.size > 25000000) {
            MySwal.fire({
                type: 'error',
                title: t("maxUploadLimit.title"),
                text: t("maxUploadLimit.content"),
                heightAuto: false
            }).then(() => {
            });
            return;
        }


        var compressedFile;
        var attachment;
        if (selectedFile.type.startsWith("image/")) {
            compressedFile = await compress.compress([selectedFile], {
                size: 1.5,
                quality: 0.75,
                resize: true
            }).then(res => {
                const {data, ext} = res[0];
                return Compress.convertBase64ToFile(data, ext)
            }).catch(e => console.log("ERROR!", e));
            const fileUrl = window.URL.createObjectURL(compressedFile);
            attachment = { type: "MULTIMEDIA", attachType: "IMAGE", fileUrl, file: compressedFile };
            //console.log("CustomInput.handleImageUpload attachment", attachment)
            setPreviewingImage(attachment)
            setIsImagePreviewerOpen(true)
        } else if (selectedFile.type.startsWith("application/")){
            var blobFile;
            const reader = new FileReader()
            reader.onload = function() {
                blobFile = new Blob([reader.result])
                const fileUrl = window.URL.createObjectURL(selectedFile, {type: selectedFile.type});
                attachment = { type: "MULTIMEDIA", attachType: "FILE", fileUrl, file: blobFile, fileName: selectedFile.name, contentType: selectedFile.type };
                //console.log("CustomInput.handleImageUpload attachment", attachment)
                setPreviewingImage(attachment)
                setIsImagePreviewerOpen(true)
            }
            reader.readAsArrayBuffer(selectedFile)
        } else {
            // error=
        }
        // chatCtl.setActionResponse(, res)
    }

    const handleRRRequest = async () => {
        setIsAppLoading(true);
        const event = {
			source: 'BOTBIT_BACKOFFICE',
			callToAction: 'chat_RR_button',
			subType: 'REVIEW_REQUEST_BUTTON_CLICKED'
		}
		const correlationId = await emitTrackingEvent(event)
			.then(res => {
				if (res.status === 200) {
					return res.data.data.correlationId;
				}
			})

        let npsLink;
        getNpsLink({
            name: selectedChat.user.name,
            storeId: selectedStore.id,
            userId: selectedChat.user.id || null,
            correlationId
        })
            .then(res => {
                const shortenNpsLink = res.data.data.url
                npsLink = shortenNpsLink
            })
            .catch(e => console.log(`Link couldn't be shortened, using default link: ${e}`))
            .finally(() => {
                //console.log({npsLink})
                const message = `¡Hola ${selectedChat.user.name}! Cuéntanos tu experiencia con ${selectedStore.name} ${npsLink}`
                setTextValue(message);
                setCorrelationId(correlationId)
                setIsSendingReviewRequest(true);
                setIsAppLoading(false);
            })
    }

    const openTemplateEditingModal = () => {
        setShowReplyTemplateEditModal(true);
    }

    const saveNewTemplate = (replyTemplate, errorHandler = undefined) => {
        return saveReplyTemplate(replyTemplate, errorHandler)
            .finally(() => {
                requestTemplates();
            })
    }

    return (
        <div className="custom-input-container">
            <ReplyTemplateEditModal
                showModal={showReplyTemplateEditModal}
                setShowModal={setShowReplyTemplateEditModal}
                replyTemplate={{
                    name: '',
                    comment: '',
                    companyId: selectedCompany.id,
                    category: 'CHAT'
                }}
                onSaveReplyTemplate={saveNewTemplate}
                // actions={actions}
            />  
            <form ref={formRef} onSubmit={(e) => {
                e.preventDefault();
                if ((textValue && textValue.length > 0) || isImagePreviewerOpen)
                    externalSend 
                        ? externalSend(textValue)
                        : onTextSubmit(textValue);
            }}>
                <div className="text-input-bar">
                    {showEmojiPicker && <EmojiPicker
                        onEmojiClick={handleEmojiClick}
                    />}
                    <div className="textarea-container">
                        <MDBInput
                            disabled={selectedChat.expiredState !== "ACTIVE"}
                            inputRef={ref => textareaRef.current = ref}
                            type="textarea"
                            size="lg"
                            outline
                            label={selectedChat.expiredState === "ACTIVE" ? t("enterText") : t("onlyTemplates." + selectedChat.channel)}
                            // type="text"
                            value={textValue}
                            id="text-input-custom"
                            getValue={(value = "") => { setTextValue(value) }}
                        />
                    </div>
                    <div className="actions-row">
                        <div className="input-actions left">
                            {selectedChat.expiredState === "ACTIVE" &&
                                <MDBBtn
                                    rounded={isMedium}
                                    size={"sm"}
                                    tag="a"
                                    floating={isMedium}
                                    flat
                                    hint="Emoji"
                                    onClick={() => {
                                        setShowEmojiPicker(!showEmojiPicker);
                                    }}
                                >
                                    <MDBIcon far icon="grin-alt" /> {!isMedium && t("emojis")}
                                </MDBBtn>
                            }
                            {!minified && 
                                <>
                                    <Fragment>
                                        <MDBDropdown dropup>
                                            <MDBDropdownToggle className="template-selector-trigger" ref={templateSelector} color="primary">
                                                {t("templates")}
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu basic>
                                                <MDBDropdownItem 
                                                    key="openTemplateEdit"
                                                    onClick={openTemplateEditingModal}
                                                >
                                                    {t("newTemplate")}
                                                </MDBDropdownItem>
                                                {templateOptions.map(t => (
                                                    <MDBDropdownItem
                                                        key="sendTemplate"
                                                        onClick={() => {
                                                            // setTextValue(t.value.comment)
                                                            handleTemplateSend(t.value.id)
                                                        }}
                                                    >{t.text}</MDBDropdownItem>
                                                ))}
                                            </MDBDropdownMenu>
                                        </MDBDropdown>
                                    </Fragment>
                                    <MDBBtn
                                        rounded={isMedium}
                                        size={"sm"}
                                        tag="a"
                                        floating={isMedium}
                                        flat
                                        onClick={() => {
                                            templateSelector.current.context.toggle();
                                        }}
                                    >
                                        <MDBIcon far icon="bookmark" />  {!isMedium && t("templates")}
                                    </MDBBtn>
                                    {selectedChat.expiredState === "ACTIVE" &&
                                        <>
                                            <MDBBtn
                                                rounded={isMedium}
                                                size={"sm"}
                                                tag="a"
                                                floating={isMedium}
                                                flat
                                                onClick={() => {
                                                    // handleImageUpload()
                                                    console.log(imgInput.current);
                                                    imgInput.current.value="";
                                                    imgInput.current.click();
                                                }}
                                            >
                                                <input
                                                    ref={imgInput}
                                                    type="file"
                                                    name="image"
                                                    className="image-file-input"
                                                    id="img-file"
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    onChange={handleImageUpload}
                                                />
                                                <MDBIcon fas="true" icon="paperclip" /> {!isMedium && t("attach")}
                                            </MDBBtn>
                                            <MDBBtn
                                                rounded={isMedium}
                                                size={"sm"}
                                                tag="a"
                                                floating={isMedium}
                                                flat
                                                onClick={handleRRRequest}
                                            >
                                                <MDBIcon far icon="star" /> {!isMedium && t("reviewRequest")}
                                            </MDBBtn>
                                        </>
                                    }
                                </>
                            }
                        </div>
                        <div className="input-actions right">
                            {/* {
                                (textValue && textValue.length > 0) || isImagePreviewerOpen
                                    ?  */}
                                    <MDBBtn
                                        size="sm"
                                        rounded
                                        tag="a"
                                        floating
                                        color="primary"
                                        flat
                                        onClick={() => {
                                            externalSend
                                            ?   externalSend(textValue)
                                            :   onTextSubmit(textValue)
                                        }}
                                    >
                                        <MDBIcon fas="true" icon="paper-plane" />
                                    </MDBBtn>
                                    {/* : <MDBBtn
                                        size="sm"
                                        rounded
                                        tag="a"
                                        floating
                                        flat
                                        color="primary"
                                        onClick={() => {
                                            isRecording
                                                ? stopRecording()
                                                : startRecording()
                                        }}
                                    >
                                        <MDBIcon fas="true" icon={isRecording ? "stop-circle" : "microphone"} />
                                    </MDBBtn>
                            } */}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

CustomInput.translations = {...translations}

export default withEventTracking(translate(CustomInput));