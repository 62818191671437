import axiosClient from "./axiosClient"

export const processParameters = (...params) => {
    const paramsObj = new URLSearchParams();
    params.forEach(([value, key]) => {
        if (value) {
            let parsedValue = value;
            if (Object.prototype.toString.call(value) === '[object Date]') {
                parsedValue = parsedValue.toISOString();
            }
            paramsObj.append(key, parsedValue);
        }
    })
    return paramsObj;
}

export const getLeaderboard = (stores, period, sort) => {
    const parameters = processParameters(
        [stores, "storeIds"],
        [period && period.from, "dateFrom"],
        [period && period.to, "dateTo"],
        [sort, "sort"],
        ["admin", "groupBy"],
        ["MANUAL", "executionMode"]
    )
    return axiosClient.get(`/feedback/review-request/summary?${parameters}`)
}

export const getMinLeaderboard = (stores, period, sort) => {
    const parameters = processParameters(
        [stores, "storeIds"],
        [period && period.from, "dateFrom"],
        [period && period.to, "dateTo"],
        [sort, "sort"],
        ["admin", "groupBy"],
        ["MANUAL", "executionMode"]
    )
    return axiosClient.get(`/feedback/review-request/summary?${parameters}&pageSize=3`)
    // return Promise.resolve({ data: { "data": { "items": [{ "adminId": 1736, "adminName": "Melisa", "adminLastName": "Raiczyk", "adminAvatar": "mini-noavatar.jpg", "channelName": null, "totalAccepted": 20, "totalPrivateGenerated": 4, "totalPublicGenerated": 0, "averagePrivateScore": 3.0 }, { "adminId": 1522, "adminName": "Adrian", "adminLastName": "Paredes", "adminAvatar": "mini-noavatar.jpg", "channelName": null, "totalAccepted": 4, "totalPrivateGenerated": 2, "totalPublicGenerated": 0, "averagePrivateScore": 1.0 }, { "adminId": 455, "adminName": "Gustavo", "adminLastName": "Fuhr", "adminAvatar": "mini-noavatar.jpg", "channelName": null, "totalAccepted": 2, "totalPrivateGenerated": 1, "totalPublicGenerated": 0, "averagePrivateScore": 2.0 }], "pageNumber": 0, "pageSize": 3, "totalSize": 3, "empty": false }, "result": "success", "message": "ok", "statusCode": 200 } })
}

export const getLeaderboardCsv = (stores, period, sort, filters) => {
    const parameters = processParameters(
        [stores, "storeIds"],
        [period && period.from, "dateFrom"],
        [period && period.to, "dateTo"],
        [sort, "sort"],
        ["admin", "groupBy"],
        ["MANUAL", "executionMode"]
    )
    return axiosClient.get(`/feedback/review-request/summary/csv?${parameters}`)
}

export const getRRFunnel = (stores, period, sort, adminId) => {
    const groupByAux = adminId ? "admin" : false
    const parameters = processParameters(
        [stores, "storeIds"],
        [period && period.from, "dateFrom"],
        [period && period.to, "dateTo"],
        [sort, "sort"],
        [groupByAux, "groupBy"],
        [adminId !== "scheduler" ? "MANUAL" : "AUTOMATIC", "executionMode"]
    )
    return axiosClient.get(`/feedback/review-request/summary?${parameters}`)
}

export const getFunnelUserDetail = (paramObj, page) => {
    const { stores, period, adminId, channel } = paramObj;
    const parameters = processParameters(
        [stores, "storeIds"],
        [period && period.from, "dateFrom"],
        [period && period.to, "dateTo"],
        [adminId && adminId !== "scheduler" ? adminId : null, "adminId"],
        [channel || null, "channel"],
        [adminId !== "scheduler" ? "MANUAL" : "AUTOMATIC", "executionMode"]
    )
    return axiosClient.get(`/feedback/review-request/detail/users?${parameters}&pageNumber=${page}&pageSize=10`)
}

export const getFunnelReviewDetail = (paramObj, page) => {
    const { stores, period, adminId, channel, publicReviews } = paramObj;
    const parameters = processParameters(
        [stores, "storeIds"],
        [period && period.from, "dateFrom"],
        [period && period.to, "dateTo"],
        [adminId && adminId !== "scheduler" ? adminId : null, "adminId"],
        [publicReviews, "publicReviews"],
        [channel || null, "channel"],
        [adminId !== "scheduler" ? "MANUAL" : "AUTOMATIC", "executionMode"]

    )
    return axiosClient.get(`/feedback/review-request/detail/reviews?${parameters}&pageNumber=${page}&pageSize=10`)
}