import React from 'react';
import { Select } from 'botbit-ui-components';
import { ConversationFlowActionType } from './FlowCreator';
import { MDBBtn, MDBInput } from 'mdbreact';

/**
 * @typedef ConversationOptionAction
 * @property {string} name nombre de la acción
 * @property {string} optionText texto de la acción
 * @property {('OPTION')} type tipo de la acción (ConversationFlowActionType)
 * @property {string} optionTransientNextConversationFlowStepId
 * @property {number} optionNumber
 */

/**
 * @typedef ConversationFlowStep
 * @property {string} name nombre del paso
 * @property {string} transientId id del paso
 * @property {ConversationOptionAction[]} actions tipo de la acción (ConversationFlowActionType)
 */

/**
 * @callback ConversationFlowActionListEdit
 * @param {number} index índice de la acción a editar 
 */

/**
 * @callback ConversationFlowActionEdit
 * @param {number} index índice de la acción a editar
 * @param {ConversationOptionAction} action nuevo valor
 */


function newAction(){
    return {
        type:ConversationFlowActionType.OPTION
    }
} 
/**
 * 
 * @param {Object} props
 * @param {Object} props.steps steps para las opciones
 * @param {ConversationOptionAction[]} props.actions opciones del subflujo
 * @param {Function} props.setActions función de actualización de acciones
 */
export const SubflowContainer = ({t, actions=[], setActions, steps})=>{
    
    return <div>

        <SubflowTable
            t={t}
            actions={actions}
            downAction={(index)=>{
                const action=actions[index];
                const newActions = [...actions];
                newActions[index]=actions[index+1];
                newActions[index+1]=action;
                setActions(newActions);
            }}

            upAction={(index)=>{
                const action=actions[index];
                const newActions = [...actions];
                newActions[index]=actions[index-1];
                newActions[index-1]=action;
                setActions(newActions);
            }}
            removeAction={index => {
                setActions(actions.filter((v,i,a)=>i!==index))
            }}
            updateAction={(index, value)=>{
                const newActions = [...actions];
                newActions[index] = value;
                setActions(newActions);
            }}
            steps={steps}
        ></SubflowTable>
        <MDBBtn color="primary" 
            data-testid="SubflowContainerButton"
            onClick={() => setActions([...actions, newAction()])}
            >+ Agregar</MDBBtn>
    </div>
}

/**
 * 
 * @param {Object} props
 * @param {Function} props.t
 * @param {ConversationOptionAction[]} props.actions opciones del subflujo
 * @param {Object} props.steps steps para las opciones
 * @param {ConversationFlowActionListEdit} props.upAction se ejecuta al subir acción en el orden
 * @param {ConversationFlowActionListEdit} props.downAction se ejecuta al bajar la acción en el orden
 * @param {ConversationFlowActionListEdit} props.removeAction se ejecuta al remover la acción
 * @param {ConversationFlowActionEdit} props.updateAction se ejecuta al atualizar una acción
 */
export const SubflowTable = ({ t, actions, steps, upAction, downAction, removeAction, updateAction }) => {
    return <div className="container">
        {
            !actions.length &&
            <p>{t("subflowTable.empty")}</p>
        }
        {
            actions.sort((a,b) => a.optionNumber>b.optionNumber).map((action, index, arr) => (
                <div key={index}>
                    <ConversationFlowActionRow
                        downAction={index+1===arr.length ? undefined :()=>downAction(index)}
                        upAction={index===0 ? undefined : ()=>upAction(index)}
                        removeAction={()=>removeAction(index)}
                        updateAction={newAction => updateAction(index, newAction)}
                        steps={steps}
                        action={action}
                        t={t}
                    ></ConversationFlowActionRow>
                </div>
            ))
        }
    </div>
}


/**
 * 
 * @param {Object} props
 * @param {ConversationOptionAction} props.action
 * @param {Function} props.upAction se ejecuta al subir acción en el orden
 * @param {Function} props.downAction se ejecuta al bajar la acción en el orden
 * @param {Function} props.removeAction se ejecuta al remover la acción
 * @param {Function} props.updateAction
 * @param {string} props.placeholderdata-testid="actionRow"
 * @param {ConversationFlowStep[]} props.steps opciones para el selec
 */
export const ConversationFlowActionRow = ({ action={}, t, steps = [], updateAction, upAction, downAction, removeAction }) => {
    const options = steps.map(
        s => (
            {
                text: s.name,
                value: s.transientId,
                checked: action.optionTransientNextConversationFlowStepId === s.transientId
            }
        )
    );
    return <div className="row" data-testid="ConversationFlowActionRow">
        <div className="col-4">
            <MDBInput
                placeholder={t('nameInput.placeholder')}
                data-testid="nameInput"
                value={action.optionText}
                onChange={(e)=> updateAction({
                    ...action,
                    optionText: e.target.value
                })}
            ></MDBInput>
        </div>
        <div className="col-4">
            <Select
                selected={t('stepSelect.placeholder')}
                search={true}
                options={options}
                getValue={(value) => updateAction({
                    ...action,
                    optionTransientNextConversationFlowStepId: value[0]
                })}
            ></Select>
        </div>
        <div className="col-4 m-auto">
            {
                upAction &&
                <MDBBtn color="secondary" size="sm"  id="actionUpButton" data-testid="actionUpButton" onClick={upAction}>↑</MDBBtn>
            }
            {
                downAction &&
                <MDBBtn color="secondary" size="sm" id="actionDownButton" data-testid="actionDownButton" onClick={downAction}>↓</MDBBtn>
            }
            <MDBBtn color="secondary" size="sm" id="actionRemoveButton" data-testid="actionRemoveButton"  onClick={removeAction}>⌫</MDBBtn>
        </div>
    </div>
}