import React from 'react';
import { Select } from 'botbit-ui-components';
import { MDBBtn, MDBSpinner, MDBInput } from 'mdbreact';
import {
  sendTestEmail,
  getAdminsEmailsForCompany,
} from '../../../api/campaignsApi';
import { setLoading } from '../../../commons/components/application/miscActions';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import './TestEmail.scss';
import { Field, Formik, Form } from 'formik';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
const TRANSLATIONS_PREFIX = 'panels.campaigns.testEmail.';

class TestEmail extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      emails: null,
      isValid: true,
      invalidInputs: [],
    };
  }

  componentDidMount = () => {
    let adminsEmails = [];
    getAdminsEmailsForCompany(this.props.campaign.companyId).then((res) => {
      for (let i = 0; i < res.data.data.length; i++) {
        adminsEmails = [
          ...adminsEmails,
          {
            text: res.data.data[i].email,
            value: res.data.data[i].email,
            checked: false,
          },
        ];
      }
      this.setState({ availableEmails: adminsEmails });
    });
  };

  onSend = (values) => {
    const { campaign } = this.props;
    let requiresComa = values.extraTestEmails.length > 0;
    let emails = values.extraTestEmails + (requiresComa ? ',' : '');
    emails += this.state.adminEmailsToSend ? this.state.adminEmailsToSend : '';

    if (emails && emails.length > 0) {
      this.props.setLoading(true);
      this.props.closeModal();
      emails = emails.split(',');
      let necessaryEmailData = {
        ...campaign,
        testEmails: emails,
      };
      sendTestEmail(necessaryEmailData)
        .then((res) => {
          if (this.props.onSuccess) this.props.onSuccess();
        })
        .catch(() => {})
        .finally(() => {
          this.props.setLoading(false);
        });
    } else {
      MySwal.fire({
        type: 'error',
        title: this.props.t(
          'panels.campaigns.newCampaign.smartCampaignWizard.mustSelectEmailTitle'
        ),
        text: this.props.t(
          'panels.campaigns.newCampaign.smartCampaignWizard.mustSelectEmail'
        ),
      });
    }
  };

  render() {
    let validationTranslations =
      'panels.campaigns.newCampaign.smartCampaignWizard.';
    if (!this.props.campaign || !this.state.availableEmails)
      return (
        <div className="w-100 d-flex justify-content-center">
          <MDBSpinner />
        </div>
      );
    const { t } = this.props;

    return (
      <Formik
        ref={this.formRef}
        validationSchema={Yup.object().shape({
          extraTestEmails: Yup.string().test('valid-emails', '', function (
            emails
          ) {
            if (emails && emails !== '') {
              let splittedEmails;
              if (emails.indexOf(',')) {
                splittedEmails = emails.split(',');
              } else {
                splittedEmails = [emails];
              }
              let invalidEmails = [];
              let emailIsValidTest = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
              let errorMessage = '';
              splittedEmails.forEach((email) => {
                if (!emailIsValidTest.test(email.trim())) {
                  invalidEmails.push(email.trim());
                }
              });
              if (invalidEmails.length > 0) {
                errorMessage = t(
                  validationTranslations + 'multipleEmailsInvalid',
                  { invalidEmails: invalidEmails.join() }
                );
              }
              return invalidEmails.length > 0
                ? this.createError({
                    message: errorMessage,
                    path: 'extraTestEmails',
                  })
                : true;
            } else {
              return true;
            }
          }),
        })}
        initialValues={{ extraTestEmails: '' }}
        onSubmit={this.onSend}
      >
        <Form>
          <div className="testEmailModalBody">
            <span className="modalTitle">
              {t(`${TRANSLATIONS_PREFIX}selectEmail`)}
            </span>
            <Select
              options={this.state.availableEmails}
              selected={t(`${TRANSLATIONS_PREFIX}selectEmailSub`)}
              multiple
              getValue={(val) => {
                this.setState({ adminEmailsToSend: val.toString() });
              }}
            />
            <strong>{t(`${TRANSLATIONS_PREFIX}aditionalEmail`)}</strong>
            <Field
              name={'extraTestEmails'}
              render={(props) => {
                const errors =
                  props.form.errors && props.form.errors.extraTestEmails;
                return (
                  <div id="extraEmailsInput" className={errors ? 'error' : ''}>
                    <MDBInput
                      name={props.field.name}
                      value={props.field.value}
                      className={errors ? 'error' : ''}
                      label={t(`${TRANSLATIONS_PREFIX}insertMail`)}
                      onChange={(event) => {
                        props.form.setFieldValue(
                          'extraTestEmails',
                          event.target.value
                        );
                      }}
                    />
                    {errors && <label className="error">{errors}</label>}
                  </div>
                );
              }}
            />
            <div>
              <div className="modalButtons">
                <MDBBtn color="primary" type="submit">
                  {t(`${TRANSLATIONS_PREFIX}send`)}
                </MDBBtn>
                <button
                  type="button"
                  className="link-like-btn-important"
                  onClick={() => this.props.closeModal()}
                >
                  {t(`${TRANSLATIONS_PREFIX}cancel`)}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    );
  }
}
const mapStateToProps = () => {};

export default connect(mapStateToProps, { setLoading })(
  translate('common')(TestEmail)
);
