import React from 'react'
import {Row, Col} from "react-bootstrap";
import {envConfig} from "../../config/config";
import {translate} from "react-i18next";

const url = `${envConfig.servicesUrl}/swagger-ui.html`;

const ApiConfigTitle=({t})=> (
  <Row>
    <Col lg={12}>
      <p className="word-wrap-cs">{t("apiConfig.introduction")}<a href={url} target="blank">{url}</a>
      </p>
    </Col>
  </Row>
);

export default translate('common')(ApiConfigTitle)