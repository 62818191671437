import React from "react";
import SocialLocationPostLocationsSelect from "./SocialLocationPostLocationsSelect";
import { Button } from "botbit-ui-components";
export const LocationSelectAndSubmitWrapper = ({
  setSelectedLocations,
  formRef,
  t,
  socialLocationId,
  selectedLocations,
}) => {
  return (
    <div className="container row m-0 p-0">
      <div className="col-7 p-0">
        <SocialLocationPostLocationsSelect
          onChange={(value) => setSelectedLocations(value)}
          socialLocationId={socialLocationId}
        />
      </div>
      <div className="col-4 offset-md-1 p-0">
        <Button
          type="submit"
          className="btn btn-primary float-rigth float-right"
          data-testid="submit"
          disabled={!selectedLocations || !selectedLocations.length || !formRef.current}
          onClick={() => formRef.current.handleSubmit()}
        >
          {t("submit")}
        </Button>
      </div>
    </div>
  );
};
