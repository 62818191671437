import { envConfig } from "../../config";

export const translations = {
    event: {
        es: 'Evento',
        en: "Event"
    },
    name: {
        es: "Nombre",
        en: "Name"
    },
    dateAndTime: {
        es: "Fecha y hora",
        en: "Date and time"
    },
    store: {
        es: "Tienda",
        en: "Store"
    },
    detail: {
        es: "Detalle",
        en: "Detail"
    },
    unregistration: {
        es: "Anulación de Registro",
        en: "Unregistration"
    },
    registration: {
        es: "Registro",
        en: "Registration"
    },
    errorCampaign: {
        es: "Error en el envío",
        en: "Sent error"
    },
    bouncedCampaign: {
        es: "Campaña rechazada",
        en: "Campaign bounced"
    },
    deliveredCampaign: {
        es: "Campaña enviada",
        en: "Campaign delivered"
    },
    campaignOpen: {
        es: "Campaña abierta",
        en: "Campaign open"
    },
    clickedCampaign: {
        es: "Campaña clickeada",
        en: "Clicked campaign"
    },
    reviewDeleted: {
        es: "Reseña Eliminada",
        en: "Review Deleted"
    },
    npsSent: {
        es: "Encuesta enviada",
        en: "Survey sent"
    },
    chosenProduct: {
        es: "Producto elegido",
        en: "Chosen product"
    },
    succesful: {
        es: "Exitoso",
        en: "Succesful"
    },
    rejected: {
        es: "Rechazado",
        en: "Rejected"
    },
    canceled: {
        es: "Cancelado",
        en: "Canceled"
    },
    returned: {
        es: "Devuelto",
        en: "Returned"
    },
    inProgress: {
        es: "En proceso",
        en: "In progress"
    },
    paymentMethod: {
        es: "Medio de pago",
        en: "Payment Method"
    },
    gamePlayed: {
        es: "Juego jugado",
        en: "Game Played"
    },
    gameWon: {
        es: "Juego ganado",
        en: "Game won"
    },
    prize: {
        es: "Premio",
        en: "Prize"
    },
    updatedTask: {
        es: "Tarea actualizada",
        en: "Updated task"
    },
    objective: {
        es: "Objetivo",
        en: "Objective"
    },
    expiration: {
        es: "Vencimiento",
        en: "Expiration"
    },
    assignedTo: {
        es: "Asignada a",
        en: "Assigned to"
    },
    createdTask: {
        es: "Tarea creada",
        en: "Created task"
    },
    consultation: {
        es: "Consulta",
        en: "Consultation"
    },
    registeredBy: {
        es: "Registrada por",
        en: "Registered by"
    },
    statusChange: {
        es: "Cambio de estado ",
        en: "Status change "
    },
    interaction: {
        es: "Interacción",
        en: "Interaction"
    },
    purchaseOrder: {
        es: "Orden de compra",
        en: "Purchase order"
    },
    completed: {
        es: "Completada",
        en: "Completed"
    },
    stimulatedByBotbit: {
        es: `Estimulada por ${envConfig.companyName}`,
        en: `Stimulated by ${envConfig.companyName}`
    },
    emailReceived: {
        es: "Email recibido",
        en: "Email received"
    },
    satisfactionSurvey: {
        es: "Encuesta de satisfacción",
        en: "Satisfaction survey"
    },
    reviewIn: {
        es: "Reseña en ",
        en: "Review in "
    },
    uknownSource: {
        es: "fuente desconocida",
        en: "uknown source"
    },
    responseFrom: {
        es: "Respuesta de ",
        en: "Response from "
    },
    administrator: {
        es: "Administrador",
        en: "Administrator"
    },
    pending: {
        es: "Pendiente",
        en: "Pending"
    },
    closed: {
        es: "Cerrada",
        en: "Closed"
    },
    reopen: {
        es: "Reabierta",
        en: "Reopen"
    },
    importation: {
        es: "Importación",
        en: "Importation"
    },
    manual: {
        es: "Manual",
        en: "Manual"
    },
    payment: {
        es: "Pago",
        en: "Payment"
    },
    other: {
        es: "Otro",
        en: "Other"
    },
    reservation: {
        es: "Reserva",
        en: "Reservation"
    },
    purchase: {
        es: "Compra",
        en: "Purchase"
    },
    reviewRequest: {
        es: "Pedido de reseña",
        en: "Review request"
    },
    "userStatusType": {
		"title": {
            en:"Status",
            es: "Estado"
        },
		"1": {
            en:"Lead",
            es:"Averiguador"
        },
		"2": {
            en: "Monthly",
            es:"Suscriptor Mensual"
        },
		"3": {
            en:"Inactive",
            es:"Ex Socio"
        },
		"4": {
            en:"Annual",
            es:"Suscriptor Anual"
        },
		"5": {
            en:"Unknown",
            es:"Desconocido"
        },
        6: {
            es: "Suscriptor Semestral",
            en: "Biannual"
        },
		"opportunity": {
            en:"Opportunity",
            es:"Oportunidad"
        }
	}
}
