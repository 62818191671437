export const translations = {
    responses: {
        es: "Encuestas respondidas",
        en: "Answered surveys"
    },
    request: {
        es: "Solicitudes enviadas",
        en: "Requests sent"
    },
    generated: {
        es: "Reseñas generadas",
        en: "Answered surveys"
    },
    score: {
        es: "Puntaje promedio",
        en: "Average score"
    }
}