import React from 'react';
import { Helmet } from 'react-helmet';
import { envConfig } from '../../config/config';

const ReviewsWidgetDiv = ({ token, companyId, disableMarginBottom }) => {

    // Se usa imgUrl para que en prod vaya por el CDN
    const backofficeUrl = envConfig['imgUrl'];
    return <>
        <link href={`${backofficeUrl}widgets/reviews/botbit-reviews-widget.css`} rel="stylesheet" />
        <Helmet>
        <script src={`${backofficeUrl}widgets/reviews/botbit-reviews-widget.js`}></script>
        </Helmet>
        <div className="botbit_reviews_widget" data-token={token} data-company-id={companyId}
        style={(disableMarginBottom) ? {} : { marginBottom: '15px' }}></div>
    </>

}

export default ReviewsWidgetDiv