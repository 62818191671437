import React from 'react'
import {FastField} from "formik"
import './CampaignCompany.css';
const TRANSLATIONS_PREFIX = "panels.campaigns.minimalEditor."
export const CampaignCompany = ({isEditing, t}) => {
    return <div className="campaignCompanyContainer">
                <FastField
                    name={"campaign.company.name"}
                    render={(props) =><div className="fieldWrapper">
                        <span className="companyLabel">
                            {t(TRANSLATIONS_PREFIX + "company")}
                        </span>
                        <span>
                            {props.field.value}
                        </span>
                    </div>
                    }
                />
            </div>
}

export default CampaignCompany