import React from 'react';

import translate from '../../../config/translations/translate';
import { translations } from '../../../config/translations/Feedback/SentimentContainer';
import { translations as monthTranslations } from '../../../config/translations/Months';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getFirstDayOfMonth, getLastDayOfMonth } from './sentimentUtils';
import { SectionTitle } from './SectionTitle';

const SentimentMonthCalendarGraph = ({ t, monthStatistics, onDaySelected, month, year }) => {

	const dateChanged = (value, event) => {
		const dayStats = monthStatistics.find((stats) => stats.day === value.getDate())
		onDaySelected(value, dayStats)
	}

	const firstDay = getFirstDayOfMonth(year, month)
	const lastDay = getLastDayOfMonth(year, month)

	const isDateDisabled = (date) => {
		if (date.getMonth() + 1 !== month) {
			return true
		}
		const dayStats = monthStatistics.find((stats) => stats.day === date.getDate())
		if (!dayStats) {
			return true
		}
		return false
	}

	const getClassForCell = (date) => {
		if (date.getMonth() + 1 !== month) {
			return 'calendar-other-month'
		}
		const dayStats = monthStatistics.find((stats) => stats.day === date.getDate())
		if (!dayStats) {
			return 'calendar-no-stats calendar-day-number-color'
		}
		if (dayStats.totalPositiveAndNegativeReviews === 0) {
			return 'background-only-neutrals calendar-day-number-color'
		} else {
			const positivePercentage = dayStats.positiveWithoutNeutralPercentage
			const zeroToTenPositiveWeight = Math.round(positivePercentage / 10)
			return `background-zero-to-ten-${zeroToTenPositiveWeight} calendar-day-number-color`
		}
	}

	return <>
		<SectionTitle
			help={[t('dailyStatisticsSection.calendar.help1')]}
			title={t('dailyStatisticsSection.calendar.title')}
			name={'sentiment-month-summary'}
		/>
		<div className='month-calendar'>
			<Calendar
				value={undefined}
				onClickDay={dateChanged}
				activeStartDate={firstDay}
				maxDate={lastDay}
				minDate={firstDay}
				showNavigation={false}
				tileClassName={({ date }) => {
					return getClassForCell(date)
				}}
				tileDisabled={({ date }) => isDateDisabled(date)}
			/>
		</div>
	</>
}

SentimentMonthCalendarGraph.translations = {
	...translations,
	...monthTranslations
};

export default translate(SentimentMonthCalendarGraph);
