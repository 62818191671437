export const translations = {
    adminName: {
        es: "Nombre",
        en: "Name"
    },
    totalSent: {
        es: "Solicitudes enviadas",
        en: "Requests sent"
    },
    totalPrivateGenerated: {
        es: "Encuestas respondidas",
        en: "Answered surveys"
    },
    totalPublicGenerated: {
        es: "Reseñas generadas",
        en: "Generated reviews"
    },
    averagePrivateScore: {
        es: "Puntaje promedio",
        en: "Average score"
    },
    title: {
        es: "Tabla de posiciones",
        en: "LeaderBoard"
    },
    store: {
        es: "Tiendas",
        en: "Stores"
    },
    timePeriod: {
        es: "Periodo",
        en: "Period"
    },
    order: {
        es: "Ordenar",
        en: "Order"
    },
    allStores: {
        es: "Todas las tiendas",
        en: "All stores"
    },
    emptyStateMsg: {
        es: "No hay información suficiente. Prueba ordenando por otro criterio.",
        en: "Not enough information. Try ordering by another criteria."
    },
    exportCSV: {
        es: "Exportar CSV",
        en: "Export CSV"
    },
    inviteAdmin: {
        es: "Invitar usuarios",
        en: "Invite new people"
    },
    received: {
        es: "Recibidos",
        en: "Received"
    },
    viewClients: {
        es: "Ver clientes",
        en: "View Clients"
    },
    viewSurveys: {
        es: "Ver Encuestas",
        en: "View Surveys"
    },
    viewReviews: {
        es: "Ver reseñas",
        en: "View Reviews"
    },
    clients: {
        es: "Clientes",
        en: "Clients"
    },
    surveys: {
        es: "Encuestas respondidas",
        en: "Answered Surveys"
    },
    reviews: {
        es: "Reseñas",
        en: "Reviews"
    },
    avatar: {
        es: "Avatar",
        en: "Avatar"
    },
    userName: {
        es: "Nombre",
        en: "Name"
    },
    timestamp: {
        es: "Fecha",
        en: "Date"
    },
    channel: {
        es: "Canal",
        en: "Channel"
    },
    storeName: {
        es: "Tienda",
        en: "Store"
    },
    score: {
        es: "Puntaje",
        en: "Score"
    },
    socialSource: {
        es: "Sitio",
        en: "Source"
    },
    detailedRR: {
        es: "Resumen de {{name}}",
        en: "{{name}}'s summary"
    },
    publicReviews: {
        es: "Reseñas públicas",
        en: "Public reviews"
    },
    reviewRequestSent: {
        es: "Solicitudes de reseña enviadas",
        en: "Review requests sent"
    },
    schedulerName: {
        es: "Envíos de reseñas automáticas",
        en: "Automatic review request"
    },
    company: {
        es: "Empresa",
        en: "Company"
    }
}