import React from 'react'
import { MDBIcon } from 'mdbreact'

const TodoTaskStatus = ({ status }) => {

    return (<>
        {status === 'TODO' && <MDBIcon size="2x" icon="times-circle" className="text-danger" />}
        {status === 'DONE' && <MDBIcon size="2x" icon="check-circle" className="light-green-text" />}
        {(status === 'DISABLED' || status === 'IGNORED') && <MDBIcon size="2x" icon="pause-circle" className="text-muted" />}
    </>);
}

export default TodoTaskStatus
