import { getAllTimePeriods } from "botbit-ui-components";

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
let countries_ = countries.getNames("es");
let options = Object.keys(countries_).map(function (key) {
	return { checked: false, value: key, text: countries_[key] }
});


export const getDefaultFilterValues = (segments = [], { selectedStore, selectedStoreIsSuscription, t }) => {

	let initialOrder = undefined;
	let filters = [
		{
			id: "segments",
			label: t("segment"),
			type: "select",
			search: true,
			className: 'col-12',
			placeholder: t("segment"),
			priority: false,
			visible: true,
			values: segments.map(s => {
				return {
					checked: false,
					value: s.id,
					text: s.name
				}
			}
			)
		}, {
			id: 'gender',
			label: t("genre"),
			type: 'select',
			search: true,
			className: 'col-12',
			placeholder: t("genre"),
			priority: false,
			visible: true,
			values: [{
				checked: false,
				value: "M",
				text: t("men")
			}, {
				checked: false,
				value: "F",
				text: t("women")
			}
			]
		}, {
			id: "email",
			label: t("email"),
			type: "input",
			className: 'col-12',
			placeholder: t("email"),
			priority: true,
			visible: true
		}, {
			id: "name",
			label: t("name"),
			type: "input",
			search: true,
			className: 'col-12',
			placeholder: t("name"),
			priority: false,
			visible: true
		}, {
			id: "lastName",
			label: t("lastname"),
			type: "input",
			search: true,
			className: 'col-12',
			placeholder: t("lastname"),
			priority: false,
			visible: true
		}, {
			id: "phone",
			label: t("phone"),
			type: "input",
			search: true,
			className: 'col-12',
			placeholder: t("phone"),
			priority: false,
			visible: true
		}, {
			id: "identityNumber",
			label: "DNI",
			type: "input",
			search: true,
			className: 'col-12',
			placeholder: 'DNI',
			priority: false,
			visible: true
		}
		, {
			id: "country",
			label: t("country"),
			type: "multiselect",
			search: true,
			className: 'col-12',
			placeholder: t("country"),
			priority: false,
			visible: true,
			values: options
		},
		//,{
		//			id: "source",
		//			label:"Fuente",
		//			type: "multiselect",
		//			search: true,          
		//			className: 'col-12',
		//			placeholder: 'Fuente',
		//			priority: false,
		//			visible: true,
		//			values: [
		//				{checked:false, value:"WiFi", text:"WiFi"},
		//				{checked:false, value:"Importation", text:"Importation"},
		//				{checked:false, value:"Manual", text:"Manual"},
		//				{checked:false, value:"Payment", text:"Payment"},
		//				{checked:false, value:"Other", text:"Other"},
		//				{checked:false, value:"Inquiry", text:"Inquiry"},
		//				{checked:false, value:"Form", text:"Form"},
		//				{checked:false, value:"Reservation", text:"Reservation"},
		//				{checked:false, value:"Tablet", text:"Tablet"}
		//			]
		//		}
	]

	if (selectedStore) {
		initialOrder = {
			name: "registrationDate",
			order: "DESC"
		}
		if (selectedStoreIsSuscription) {
			filters.push(
				{
					id: "status",
					label: t('userStatusType.title'),
					type: "multiselect",
					search: false,
					className: 'col-12',
					placeholder: t('userStatusType.title'),
					priority: true,
					visible: true,
					values: [
						{
							value: "1",
							text: t("userStatusType.1"),
							checked: false
						},
						{
							value: "2",
							text: t("userStatusType.2"),
							checked: false
						},
						{
							value: "3",
							text: t("userStatusType.3"),
							checked: false
						}, {
							value: "6",
							text: t("userStatusType.6"),
							checked: false
						},
						{
							value: "4",
							text: t("userStatusType.4"),
							checked: false
						}, {
							value: "5",
							text: t("userStatusType.5"),
							checked: false
						}
					]
				},
				{
					id: "iddle",
					type: "timeperiod",
					priority: false,
					visible: true,
					className: 'col-12',
					placeholder: "Clientes sin tareas en",
					label: "Clientes sin tareas en",
					options: getAllTimePeriods()
				}
			)
		}
	}

	return { filters, initialOrder }
}
