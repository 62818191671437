import React, { Component } from "react";
import { connect } from 'react-redux'
import { setLoading } from '../../commons/components/application/miscActions';
import { setFeedbackFilters, setFeedbackSavedFilters, setFeedbackPreviousFilters } from './FeedbackFiltersActions';
import { MDBRow, MDBIcon, MDBContainer } from "mdbreact";
import Moment from 'react-moment';
import 'moment/locale/es';
import Review from './Review'
import SocialSourceIcon from './SocialSourceIcon'
import Score from "./Score";
import ReviewsSummary from "./ReviewsSummary";
import { getAvailableSites, getReviewsSummary, getReviews, getReview, reply, report, getReplyTemplates, saveReplyTemplate, exportReviews } from '../../api/feedbackApi';
import { getTimePeriods } from "botbit-ui-components";
import {
	createDefaultValues, applySelectedFiltersOverFilters, getSavedFilters,
	removeInvisibleFilters, getSelectedTimePeriodLabel, getSelectedTimePeriodPrevious, showFilters
} from "./FiltersHelper";
import './feedbackCenter.css';
import { Views } from "./Views";
import deepCopy from "../../commons/utils/deepCopy";
import CollectionVisualizerContainerWithCompanies from "../collectionVisualizer/CollectionVisualizerContainerWithCompanies";
import { firePageView } from "../../commons/components/utils/trackers";
import { translations } from "../../config/translations/Feedback/ReviewsContainer";
import translate from "../../config/translations/translate";
import withEventTracking, { trackers } from '../../config/withEventTracking';

const mapStateToProps = (state) => {
	return {
		availableStores: state.application.availableStores,
		availableCompanies: state.application.availableCompanies,
		selectedCompany: state.application.selectedCompany,
		filters: state.feedback.filters,
		savedFilters: state.feedback.savedFilters,
		previousFilters: state.feedback.previousFilters,
		previousView: state.feedback.previousView,
		previousOrder: state.feedback.previousOrder,
		admin: state.login.admin
	}
};

const provinces = new Set();
const localities = new Set();

class ReviewsContainer extends Component {

	constructor(props) {
		super(props);
		//setear feedback filters con tiendas, periodo, fuente = google, puntaje = 5, estimuladas por botbit = si
		this.state = {
			reviewsSummary: [],
			reviewsSummaryLoading: true,
			showFilterModal: false,
			timePeriods: getTimePeriods(),
			availableSites: [],
			showBackButton: false,
			replyTemplates: [],
			reviewId: this.getReviewIdFromRequestParam(),
			debugEnabled: false
		}

		this.props.availableStores.forEach(store => {
			if (store.province.trim().length > 0)
				provinces.add(store.province);
			if (store.locality.trim().length > 0)
				localities.add(store.locality);
		});
	}

	getReviewIdFromRequestParam = () => {
		const reviewStr = new URLSearchParams(this.props.location.search).get('reviewId')
		return reviewStr && parseInt(reviewStr)
	}

	componentDidMount = async () => {
		const availableSitesResponse = await getAvailableSites()
		const availableSites = availableSitesResponse.data.data
		this.setState({
			availableSites: availableSites,
		}, () => this.initFilters())
		firePageView()
	}

	componentDidUpdate = () => {
		if (this.state.hide) this.setState({ hide: false });
	}

	getReplyTemplates = async (companyId) => {
		const company = this.props.selectedCompany; //this.props.availableCompanies.find(company => company.id === this.props.selectedCompany.id);
		if (!company.operations.includes("REPLY_TEMPLATES__SELECT"))
			return;
		const response = await getReplyTemplates(companyId, 'FEEDBACK');
		if (!response || !response.data || !response.data.data) return;
		const templates = response.data.data;
		const replyTemplates = templates.map(t => {
			return { text: t.name, value: t.id.toString() };
		});
		this.setState({ replyTemplates: replyTemplates });
	}

	getReviewsSummary = (selectedFilters) => {
		removeInvisibleFilters(selectedFilters, this.props.filters);
		this.setState({ reviewsSummaryLoading: true });

		var copiedFilters = deepCopy(selectedFilters);
		copiedFilters.resetFromTime=[false];

		getReviewsSummary(copiedFilters).then((response) => {
			if (response.data.data) {
				this.setState({ reviewsSummary: response.data.data });
			}
		}).finally(() => {
			this.setState(
				{
					reviewsSummaryLoading: false
				})
		});

	}

	getReviewsWrapper = (filters, page, order) => {
		var copiedFilters = deepCopy(filters);
		copiedFilters.resetFromTime=[false];
		return getReviews(copiedFilters, page, order);
	}

	onFiltersApply = (selectedFilters, filters) => {
		if (this.state.debugEnabled) {
			console.log("Reviews Container --- onFiltersAply param selectedFilters", selectedFilters)
			console.log("Reviews Container --- onFiltersAply param filters", filters)
			console.log("Reviews Container --- onFiltersAply state this.props.filters", this.props.filters)
		}

		this.props.setFeedbackFilters(filters)

		if (this.state.previousAppliedFilters && JSON.stringify(this.state.previousAppliedFilters) === JSON.stringify(selectedFilters)) {
			if (this.state.debugEnabled)
				console.log("Reviews Container --- ignoring new filters, are the same than previous", selectedFilters)
			return;
		}

		if (this.state.debugEnabled)
			console.log("Reviews Container --- new filters are distinct than previous", this.state.previousAppliedFilters, selectedFilters)

		this.getReviewsSummary(selectedFilters)
		this.getReplyTemplates(this.props.selectedCompany.id)
		this.setState({ previousAppliedFilters: selectedFilters })
	}

	initBackButton = () => {
		const params = new URLSearchParams(this.props.location.search);
		const fromSummary = params.get('fromSummary')
		const _showBackButton = (fromSummary === 'true')
		if (_showBackButton !== this.state.showBackButton) {
			this.setState({ showBackButton: _showBackButton })
		}
	}

	initFilters = () => {
		this.initBackButton()
		if (this.props.filters) {
			if (this.state.debugEnabled)
				console.log("Review container --- existe this.props.filters", this.props.filter)
			this.getInitialFilters();
			showFilters(this.props.filters, 'scores', 'botbitGenerated')
		} else {
			if (this.state.debugEnabled)
				console.log("Review container --- no existe this.props.filters")
			this.setDefaultFiltersValues();
		}
	}

	getInitialFilters = () => {
		const filtersDefaultValues = createDefaultValues(
			provinces, localities,
			this.props.availableCompanies, this.props.selectedCompany.id,
			this.props.availableStores, this.state.timePeriods,
			this.state.availableSites, Views.REVIEWS_VIEW
		);
		this.setState({ defaultFilters: deepCopy(filtersDefaultValues) });
		if (this.state.debugEnabled)
			console.log("Review container --- getInitialFilters", filtersDefaultValues)
		return filtersDefaultValues;
	}

	resetFilters = () => {
		const filtersDefaultValues = this.getInitialFilters();
		this.props.setFeedbackFilters(filtersDefaultValues);

		const savedFilters = getSavedFilters();
		this.props.setFeedbackSavedFilters(savedFilters);
		this.setState({
			hide: true
		})
	}

	setDefaultFiltersValues = () => {
		const filtersDefaultValues = this.getInitialFilters();
		// this.initRequestParameters(filtersDefaultValues);
		this.props.setFeedbackFilters(filtersDefaultValues);
		if (this.state.debugEnabled)
			console.log("Review container --- setting feedback filters", filtersDefaultValues)

		const savedFilters = getSavedFilters();
		this.props.setFeedbackSavedFilters(savedFilters);
		if (this.state.debugEnabled)
			console.log("Review container --- setting feedback saved filters", savedFilters)
	}

	// initRequestParameters = (filters) => {
	//   const params = new URLSearchParams(this.props.location.search)
	//   setTimePeriodFromRequestParam(filters, params)
	//   setBotbitGeneratedFromRequestParam(filters, params)
	// }

	postReply = (review, comment) => {
		this.props.setLoading(true);
		const promise = new Promise((resolve) => {
			reply(review.id, comment).then(() => {
				resolve('success');
			}).finally(() => {
				this.props.setLoading(false);
			});
		})
		return promise;
	}

	postReport = (review, comment) => {
		this.props.setLoading(true);
		const promise = new Promise((resolve) => {
			report(review.id, comment).then(() => {
				resolve('success');
			}).finally(() => {
				this.props.setLoading(false);
			});
		})
		return promise;
	}

	onSavedFiltersChange = (selectedFilters) => {
		const filtersApplied = applySelectedFiltersOverFilters(selectedFilters, this.props.filters);
		this.props.setFeedbackFilters(filtersApplied);
	}

	goToBack = () => {
		this.props.setFeedbackFilters(this.props.previousFilters)
		this.props.history.push(this.props.previousView + (
			(!this.props.previousOrder) ? '' : '?sort=' + this.props.previousOrder.name + ',' + this.props.previousOrder.direction)
		)
	}

	saveReplyTemplate = (replyTemplate) => {
		const promise = new Promise((resolve) => {
			saveReplyTemplate(replyTemplate).then(response => {
				const newTemplate = response.data.data
				this.setState({
					replyTemplates: [...this.state.replyTemplates, {
						value: newTemplate.id.toString(),
						text: newTemplate.name
					}]
				})
				resolve('sucess')
			})
		})
		return promise;
	}

	exportReviews = (order, filters) => {
		this.props.setLoading(true);
		this.props.trackEvent('REVIEWS_EXPORT_REQUESTED', {type:'EXPORT'}, true, [trackers.BACKEND])
		var copiedFilters = deepCopy(filters);
		copiedFilters.resetFromTime=[false];
		return new Promise((resolve, reject) => {
			exportReviews(copiedFilters)
				.then((response) => {
					resolve(response.data);
				})
				.finally(() => {
					this.props.setLoading(false);
				});
		})
	}

	checkIfStoreIsActive = (storeId) => {
		const selectedStore = this.props.availableStores.find(store => store.id === storeId);
		const isActive = selectedStore && selectedStore.status === "ACTIVE";
		return isActive;
	}

	render() {
		const { t } = this.props;
		const columns = [
			// for BIG screens: greater than md
			{
				dataField: 'date',
				title: t("date"),
				sortable: true,
				className: 'align-left text-truncate d-none d-md-block',
				formatter: (cellContent, row) => {
					return <Moment fromNow>{cellContent}</Moment>
				}
			}, {
				dataField: 'storeName',
				isHeader: false,
				title: t("store"),
				sortable: true,
				className: 'align-left text-truncate d-none d-md-block',
				size: 4
			}, {
				dataField: 'socialSourceId',
				title: t("socialSource"),
				sortable: true,
				className: 'align-left text-truncate d-none d-md-block p-0',
				formatter: (cellContent, row) => {
					return <SocialSourceIcon site={cellContent} />
				}
			}, {
				dataField: 'score',
				title: t("score"),
				sortable: true,
				className: 'align-left text-truncate d-none d-md-block',
				formatter: (cellContent, row) => {
					return (<Score value={cellContent} size='l' />);
				}
			}, {
				dataField: 'comment',
				title: t("activity"),
				className: 'align-left text-truncate d-none d-md-block',
				formatter: (cellContent, row) => {
					return (<>
						{cellContent && <><MDBIcon icon="comment" />&nbsp;&nbsp;</>}
						{row.autoReplied && <><MDBIcon icon="robot" />&nbsp;</>}
						{(row.replyEdited || (row.replyComment && !row.autoReplied)) && <><MDBIcon icon="reply" />&nbsp;&nbsp;</>}
						{row.npsCustomAnswers && row.npsCustomAnswers.length>0 && <><MDBIcon icon="question" />&nbsp;</>}
					</>)
				}
			},
			// for SMALL screens: lower than md
			{
				dataField: 'storeName',
				isHeader: true,
				title: t("store"),
				sortable: false,
				className: 'align-left text-truncate d-md-none row-title',
				size: 12
			}, {
				dataField: 'date',
				title: t("date"),
				sortable: true,
				className: 'align-left d-md-none pr-0',
				formatter: (cellContent, row) => {
					return <Moment fromNow>{cellContent}</Moment>
				},
				size: 4
			}, {
				dataField: 'socialSourceId',
				title: t("socialSource"),
				sortable: true,
				className: 'align-left text-truncate d-md-none',
				formatter: (cellContent, row) => {
					return <SocialSourceIcon site={cellContent} />
				},
				size: 3
			}, {
				dataField: 'score',
				title: t("score"),
				sortable: true,
				className: 'align-left text-truncate d-md-none',
				formatter: (cellContent, row) => {
					return (<Score value={cellContent} size='s' />);
				},
				size: 2
			}, {
				dataField: 'comment',
				title: '',
				className: 'align-left text-truncate d-md-none p-0 pl-2',
				size: 3,
				formatter: (cellContent, row) => {
					return (<>
						{cellContent && <><MDBIcon icon="comment" />&nbsp;</>}
						{row.autoReplied && <><MDBIcon icon="robot" />&nbsp;</>}
						{(row.replyEdited || (row.replyComment && !row.autoReplied)) && <><MDBIcon icon="reply" />&nbsp;&nbsp;</>}
						{row.npsCustomAnswers && row.npsCustomAnswers.length>0 && <><MDBIcon icon="question" />&nbsp;</>}
					</>)
				}
			}];

			if (this.state.debugEnabled && this.state.previousAppliedFilters)
				console.log("previewsFilters", this.state.previousAppliedFilters.companies);

			if (this.state.debugEnabled && this.state.previousAppliedFilters)
				console.log("companies", this.state.previousAppliedFilters.companies);

			const replyTemplatesByCompany = 
				(this.state.previousAppliedFilters && 
					this.state.previousAppliedFilters.companies && 
					this.state.previousAppliedFilters.companies.length === 1 && 
					this.state.previousAppliedFilters.companies[0] === ""+this.props.selectedCompany.id) ? this.state.replyTemplates : null

			if (this.state.debugEnabled) {
				console.log("replyTemplatesByCompany", replyTemplatesByCompany);
				console.log("this.props.selectedCompany.id", this.props.selectedCompany.id);
			}

		return (<>
			<MDBContainer className="mt-3">
				{this.state.defaultFilters && !this.state.hide &&
					<CollectionVisualizerContainerWithCompanies
						scrollTopButton
						title={t("reviews")}
						showFiltersInfo={{
							ignore: ['timePeriod']
						}}
						header={
							(this.state.reviewsSummary && this.state.reviewsSummary.totalReviews && this.state.reviewsSummary.totalReviews > 0) ?
								<>
									<MDBRow>
										<ReviewsSummary t={t} data={this.state.reviewsSummary} loading={this.state.reviewsSummaryLoading} />
									</MDBRow>
									<MDBRow>
										<h4 className="ml-2 subtitle">{t("comparison")} {getSelectedTimePeriodLabel(this.props.filters).toLowerCase()} vs {getSelectedTimePeriodPrevious(this.props.filters)}</h4>
									</MDBRow>
								</> : <></>}
						//amp autoRefresh={60000}
						defaultFilters={this.state.defaultFilters}
						filters={this.props.filters}
						setFilters={this.props.setFeedbackFilters}
						alwaysFloatingFilter={true}
						columnsCommonClasses="m-auto"
						rowsCommonClasses={(review) => review.generatedBy !== null ? 'botbit-generated-review' : ''}
						columns={columns}
						getData={(page, order, filters) => this.getReviewsWrapper(filters, page, order)}
						dataPath="data.items"
						onFiltersApply={this.onFiltersApply}
						// onResetFilters={this.setDefaultFiltersValues}
						onResetFilters={this.resetFilters}
						//amp savedFilters={this.props.savedFilters}
						//amp onSavedFiltersChange={this.onSavedFiltersChange}
						expandedRowId={this.state.reviewId}
						expandRow={(active, row, onRowChanged) => {
							return (active === true && <Review
								mode="inline"
								review={row}
								onRowChanged={onRowChanged}
								getReview={getReview}
								onReply={(comment) => this.postReply(row, comment)}
								onReport={(comment) => this.postReport(row, comment)}
								replyTemplates={ replyTemplatesByCompany }
								onSaveReplyTemplate={this.saveReplyTemplate}
								admin={this.props.admin}
								selectedCompanyId={this.props.selectedCompany.id}
								isActive={this.checkIfStoreIsActive(row.storeId)}
								answersGlobalStatistics={this.state.reviewsSummary.answers}
							/>)
						}}
						filtersTitle="Filtrar Reseñas"
						export={this.exportReviews}
						exportName={`Reviews-${Date.now()}`}
						formats={[{ type: 'text/csv', format: 'csv' }]}
						companies={this.props.availableCompanies}
						selectedCompany={this.props.selectedCompany}
						stores={this.props.availableStores}
					/>}
			</MDBContainer>
		</>)
	}
}

ReviewsContainer.translations = {
	...translations
}

export default withEventTracking(connect(mapStateToProps, { setLoading, setFeedbackFilters, setFeedbackSavedFilters, setFeedbackPreviousFilters })(translate(ReviewsContainer)))
