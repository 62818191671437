import { envConfig } from "../../config";

export const translations = {
  visits: {
    en: "visits",
    es_ar: "visitas",
    es: "visitas",
  },
  locale: {
    en: "en",
    es: "es",
  },
  stimulatedByBotbit: {
    en: `stimulated by ${envConfig.companyName}`,
    es_ar: `estimulado por ${envConfig.companyName}`,
    es: `estimulado por ${envConfig.companyName}`,
  },
  respondidoPorBotbit: {
    en: `by ${envConfig.companyName}`,
    es_ar: `por ${envConfig.companyName}`,
    es: `por ${envConfig.companyName}`
  },
  seeDetail: {
    en: "View details",
    es_ar: "Ver detalle",
    es: "Ver detalle",
  },
  discoverWhy: {
    es: "Entérate porqué",
    en: "Discover why",
    es_ar: "Enterate porqué"
  },
  // orders: {
  //   en: "orders",
  //   es_ar: "pedidos",
  //   es: "ordenes",
  // },
  outOf: {
    en: "out of",
    es_ar: "de",
    es: "de"
  },
  generated: {
    es: "del total de reseñas que tienes en Google {{period}}.",
    en: "of all your Google reviews {{period}}."
  },
  weGenerated: {
    es: "Generamos el {{percentage}}%",
    en: "We generated {{percentage}}%"
  },
  totalOfFiveStars:{
    es:"Total de reseñas 5 ⭐ que tienes en Google en {{period}}. ",
    en:"Total reviews 5 ⭐ you have on Google in {{period}}. "
  },
  hours: {
    es: "horas",
    en: "hours"
  },
  youSaved: {
    es: "Te ahorraste",
    en: "You saved"
  },
  answerToTheRest: {
    es: "¡respóndeles ahora!",
    en: "answer to them now!"
  },
  answerToTheRestSing: {
    es: "¡respóndele ahora!",
    en: "answer to them now!"
  },
  youAnsweredTo: {
    es: "Respondiste a {{answeredAmount}} de ellos, ",
    en: "You answered to {{answeredAmount}} out of them, "
  },
  notAnsweredTo: {
    es: "Aún no respondiste a ",
    en: "You still haven't answered to "
  },
  badExperience: {
    es: "clientes que tuvieron una mala experiencia",
    en: "clients who had a bad experience"
  },
  badExperienceSing: {
    es: "cliente que tuvo una mala experiencia",
    en: "client who had a bad experience"
  },
  scoreBetween: {
    es: "Entre {{low}} y {{high}}",
    en: "Between {{low}} & {{high}}"
  },
  scoreShort: {
    es: "{{low}} a {{high}}",
    en: "{{low}} to {{high}}"
  },
  stores: {
    es: "tiendas",
    en: "stores"
  },
  store: {
    es: "tienda",
    en: "store"
  },
  allClientsAnswered: {
    es: "🎉 Respondiste a todos tus clientes insatisfechos, sigue así",
    en: "🎉 You answered to all your dissatisfied customers, so keep it up",
  },
  nMore: {
    es: "y {{n}} más",
    en: "and {{n}} more"
  },
  averageScore: {
    es: "Puntaje promedio de todas tus reseñas ",
    en: "Average score from all your reviews "
  },
  averageScoreSources: {
    es: "en {{sources}} {{period}}.",
    en: "at {{sources}} {{period}}."
  },
  answered: {
    es: "Respondimos al ",
    en: "We replied "
  },
  period: {
    es: "de tus reseñas {{period}}.",
    en: "of your reviews {{period}}."
  },
  clientsWho: {
    es: "Clientes cuya",
    en: "Clients how their"
  },
  latestSatisfied: {
    es: "última calificación es positiva y la anterior negativa.",
    en: "last rating is positive and the previous rating is negative."
  },
  latestUnsatisfied: {
    es: "última calificación es negativa y la anterior positiva.",
    en: "last rating is negative and the previous rating is positive."
  },
  rankingByRating: {
    es: "Ranking por puntaje",
    en: "Ranking by score"
  },
  storesInPeriod: {
    es: "de todas tus tiendas {{period}}.",
    en: "of your stores {{period}}."
  },
  hoursOfWork: {
    es: "de trabajo",
    en: "of work"
  },
  moreStores: {
    es: "¿Tienes más tiendas? 🏘️",
    en: "Do you have more stores? 🏘️"
  },
  addMoreStores: {
    prev: {
      es: "¿Qué esperas para ",
      en: "What are you waiting for "
    },
    bold: {
      es: `sumarlas a ${envConfig.companyName}`,
      en: `add them to ${envConfig.companyName}`
    },
    post: {
      es: " y verlas en este ranking?",
      en: " and see them here?"
    },
    clickHere: {
      es: "Haz click aquí",
      en: "Click here"
    }
  },
  sentRequest: {
    es: "Solicitudes enviadas",
    en: "Sent requests"
  },
  manual: {
    es: "✋ ({{manual}})",
    en: "✋ ({{manual}})"
  },
  automatic: {
    es: "🤖 ({{automatic}})",
    en: "🤖 ({{automatic}})"
  },
  manualFull: {
    es: "Manuales",
    en: "Manual"
  },
  automaticFull: {
    es: "Automáticas",
    en: "Automatic"
  },
  generatedReviews: {
    es: "Reseñas generadas",
    en: "Generated reviews"
  },
  rrCardFooter: {
    first: {
      es: "Se enviaron ",
      en: "They're sent "
    },
    second: {
      es: "{{total}} solicitudes ",
      en: "{{total}} requests "
    },
    secondAlt: {
      es: "{{total}} solicitud ",
      en: "{{total}} request "
    },
    fourth: {
      es: "en {{period}}.",
      en: "in {{period}}."
    },
    third: {
      es: " que generaron {{reviews}} reseñas ",
      en: "generated {{reviews}} reviews "
    }
  },
  beforeApril: {
    es: "Datos disponibles a partir de abril",
    en: "Data available from April"
  }
}