import { translations } from "../../../config/translations/Dashboard/LinkToHelpCenter"
import _ from "lodash";
import React from "react";
import translate from "../../../config/translations/translate";
import "./LinkToHelpCenter.css"

const LinkToHelpCenter = ({ labels, url, t, className, intercomName, linkRenderer }) => {

    const getLabelOrDefault = (labelName) => {
        if (labels && labels[labelName]) {
            return labels[labelName];
        } else {
            return t(`default${_.upperFirst(labelName)}`);
        }
    }
    return (<div data-intercom-target={intercomName} className={`link-to-helpcenter ${className || ""}`}>
        <span>{getLabelOrDefault("beforeLinkText")}
            {linkRenderer
                ? linkRenderer()
                : <a rel="noopener noreferrer" href={url} target="_blank">{getLabelOrDefault("linkText")}</a>}
            {getLabelOrDefault("afterLinkText")}
        </span>
    </div>)
}

LinkToHelpCenter.translations = {
    ...translations
}

export default translate(LinkToHelpCenter);