import React from 'react';
import styled from "styled-components";
import { Image } from "botbit-ui-components";
import { envConfig } from '../../config/config';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: ${({ width }) => width || "100%"};
`
const Title = styled.h4`
    font-size: 0.8rem;
    font-weight: bold;
`


const Feature = ({ src, text, width }) => {
    return (
        <Container width={width}>
            <Image src={envConfig.staticImgUrl + src} scaleFixedSize={{ width: "65px", height: "65px" }} />
            <Title>{text}</Title>
        </Container>
    );
}

export default Feature;