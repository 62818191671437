import React from "react";
import { MDBContainer, MDBBtn, MDBIcon, MDBInput, MDBCard, MDBCardBody, MDBSelect } from "mdbreact";
import { Formik, Form, FastField, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import ClickeableTagFastField from "./ClickeableTagFastField";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'botbit-ui-components'
import withEventTracking from '../../config/withEventTracking';
import "./ReplyTemplateEditModal.scss";

const ReplyTemplateEditModal = ({ showModal, setShowModal, replyTemplate, onSaveReplyTemplate, actions, trackEvent, categoryModifiable = true }) => {

    const getTitle = () => {
        return (replyTemplate && replyTemplate.id) ? 'Editar plantilla' : 'Nueva plantilla'
    }

    const buildCategoryCombo = (value) => {
        return [
            {
                text: "CHAT",
                value: 'CHAT',
                checked: value === 'CHAT'
            }, {
                text: "FEEDBACK",
                value: 'FEEDBACK',
                checked: value === 'FEEDBACK'
            }
        ]
    }

    const isEditing = replyTemplate && replyTemplate.id;
    const canModifyCategory = categoryModifiable && !isEditing;

    return (
        <Modal className="review-text reply-template no-animation" size="lg" isOpen={showModal} toggle={() => setShowModal(true)}>
            <ModalHeader toggle={() => setShowModal(!showModal)}>{getTitle()}</ModalHeader>
            <Formik
                initialValues={replyTemplate}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required('El nombre es requerido.'),
                    comment: Yup.string().required('El contenido es requerido.'),
                    category: Yup.string().required('La categoría es requerida.')
                })}
                onSubmit={(values) => {
                    let isNew = replyTemplate.id === undefined
                    if (onSaveReplyTemplate) {
                        onSaveReplyTemplate(values).then((response) => {
                            if (actions && actions.updateRow) actions.updateRow(response.data.data, actions.action)
                            setShowModal(!showModal)
                            // TODO acá solo se debería llamar al update de Intercom si es el primero que crea
                            trackEvent(isNew ? 'reply_template_created' : 'reply_template_updated', {}, isNew)
                        })
                    }
                }}>
                {({ errors, touched }) => (
                    <Form>
                        <ModalBody>
                            <MDBContainer className="margins-reduced">
                                <div className={ (errors.category)  ? 'is-invalid-important' : ''}>
                                    <Field
                                        name="category"
                                        render={(props) => {
                                            return (
                                                canModifyCategory ? <MDBSelect
                                                    className='w-100 form-control'
                                                    selected={props.field.value}
                                                    options={buildCategoryCombo(props.field.value)}
                                                    name={props.field.name}
                                                    label="Categoría"
                                                    outline
                                                    getValue={(values) => {
                                                        if (props.field.value !== values[0]) {
                                                            props.form.setFieldValue(props.field.name, values[0])
                                                        }
                                                    }} /> : <div style={{ marginTop: '10px', marginBottom: '10px' }}>Categoría: <b>{props.field.value}</b></div>
                                                );
                                            }}
                                        />
                                    <ErrorMessage  
                                        name="category" 
                                        component="div"
                                        className="invalid-feedback d-block"
                                        style={{ marginTop: '-15px', marginBottom: '10px' }} />
                                </div>


                                <FastField
                                    name="name"
                                    render={(props) => {
                                        return (
                                            <MDBInput
                                                className={'w-100 form-control' + (errors.name && touched.name ? ' is-invalid-important' : '')}
                                                name={props.field.name}
                                                label="Nombre de la plantilla"
                                                value={props.field.value}
                                                outline
                                                onChange={e => {
                                                    if (props.field.value !== e.target.value) props.form.setFieldValue(props.field.name, e.target.value);
                                                }}
                                            />
                                        );
                                    }}
                                />
                                <ErrorMessage 
                                    name="name" 
                                    component="div"
                                    className="invalid-feedback d-block"
                                    style={{ marginTop: '-10px', marginBottom: '10px' }} />


                                <FastField
                                    name="comment"
                                    render={(props) => {
                                        return (
                                            <MDBInput
                                                id="commentInput"
                                                className={'w-100 mb-0 form-control' + (errors.comment && touched.comment ? ' is-invalid-important' : '')}
                                                style={{ height: '10em' }}
                                                name={props.field.name}
                                                label="Escriba el contenido de la plantilla"
                                                type="textarea"
                                                value={props.field.value}
                                                outline
                                                onChange={e => {
                                                    if (props.field.value !== e.target.value) props.form.setFieldValue(props.field.name, e.target.value);
                                                }}
                                            />
                                        );
                                    }}
                                />
                                <ErrorMessage 
                                    name="comment"
                                    component="div"
                                    className="invalid-feedback d-block"
                                    style={{ marginTop: '-10px', marginBottom: '10px' }} />

                                <MDBCard className="mt-3">
                                    <MDBCardBody>
                                        <small className="available-tags-title">Etiquetas disponibles</small>
                                        <div className="d-flex justify-content-start">
                                            <div><ClickeableTagFastField name="comment" elementId="commentInput" tag="{nombre}" /></div>
                                            <div className="ml-1"><small>Nombre del reseñador</small></div>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <div><ClickeableTagFastField name="comment" elementId="commentInput" tag="{apellido}" /></div>
                                            <div className="ml-1"><small>Apellido del reseñador</small></div>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <div><ClickeableTagFastField name="comment" elementId="commentInput" tag="{tienda}" /></div>
                                            <div className="ml-1"><small>Nombre de la tienda</small></div>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <div><ClickeableTagFastField name="comment" elementId="commentInput" tag="{email}" /></div>
                                            <div className="ml-1"><small>Email de la tienda</small></div>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>

                            </MDBContainer>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-around">
                            <MDBBtn color="danger" size="sm" onClick={() => setShowModal(!showModal)}>
                                <MDBIcon icon="times" size="1x" /> Cancelar
                            </MDBBtn>
                            <MDBBtn color="primary" type="submit" size="sm">
                                <MDBIcon icon="edit" size="1x" /> Guardar
                            </MDBBtn>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )

}

export default withEventTracking(ReplyTemplateEditModal)