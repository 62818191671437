export const EventSubType = {
    /* USER_VISITATION */
    STILL_UNDEFINED: 0, REGISTRATION: 1, CHECK_IN: 2, UNREGISTERED_VISITATION: 3, WALKTRHOUGH: 4, PASS_BY: 5,
    /* CAMPAIGN_INTERACTION */
    BOUNCED: 10, UNSUSCRIBE: 11, DEFERRED: 12, DELIVERED: 13, PROCESSED: 14, CLICKED: 15, OPENED: 16, REPORTED_SPAM:
        17, DROPPED: 18, ERROR: 19,
    /* FEEDBACK */
    NPS_FEEDBACK: 20, FEEDBACK_REPLY: 21, FEEDBACK_DELETE: 22, REVIEW_REQUEST: 23, NPS_SENT: 24,
    /* CRM */
    INQUIRY: 30, SET_CUSTOMER_STATUS: 31, INTERACTION: 32, CREATE_TASK: 33, UPDATE_TASK : 34,UNREGISTRATION : 35,
    /* RESERVATIONS */
    RESERVATION: 40, RESERVATION_CANCEL: 41,
    /* SOCIAL */
    FACEBOOK_REVIEW: 50, TRIP_ADVISOR_REVIEW: 51, FACEBOOK_LIKE: 52, TWITTER_FOLLOW: 53, INSTAGRAM_FOLLOW:
        54, FORM_SIGNUP: 55, TRIP_ADVISOR_LINK_CLICK: 56, GOOGLE_MY_BUSINESS_LINK_CLICK: 57,
    /* PRODUCT PREFERENCES */
    SHOWED_PRODUCTS: 60, CHOOSED_PRODUCT: 61,
    /* PAYMENTS */
    PAYMENT_SUCCESFULL: 70, PAYMENT_REJECTED: 71, PAYMENT_CANCELLED: 72, PAYMENT_REFUNDED: 73, PAYMENT_IN_PROCESS: 74,
    /* GAMIFICATION */
    GAME_SHOWED: 80, GAME_PLAYED: 81, GAME_WON: 82,
    /* PURCHASE */
    PURCHASE_DONE: 95, PURCHASE_STIMULATED: 98,
    /*EMAIL*/
    EMAIL_RECIEVED: 110,
}
