export const translations = {
	noActiveStores: {
		es: "No tiene tiendas activas para operar",
		en: "You don't have active stores"
	},
    youHaveSuspendedStores: {
        first: {
            es: "Tienes tiendas suspendidas que no pueden",
            en: "You have suspended stores that can not"
        },
        second: {
            es: "utilizar WiFi, enviar campañas, estimular reseñas 5 estrellas, auto responder reseñas",
            en: "use WiFi, send campaigns, stimulate 5 stars reviews, autoreply reviews"
        },
        third: {
            es: "y más",
            en: "and more"
        }
    },
    reactivate: {
        es: "Haz click para reactivarlas",
        en: "Click here to reactivate"
    },
    reactivateStores: {
        es: "Reactivar Tiendas",
        en: "Reactivate Stores"
    },
    returnToChecklist: {
        es: "Si quieres continuar con las tareas pendientes, ",
        en: "If you want to continue with the pending tasks, "
    },
    returnToChecklistList: {
        es: "haz click aquí",
        en: "click here"
    }
}