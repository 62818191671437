import React from 'react'
import { MDBIcon, MDBBadge, MDBContainer, MDBSwitch, MDBBtn, MDBSpinner } from 'mdbreact'
import './Analytics.css'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-i18next'
import { Chart } from 'react-google-charts'
import { getCampaignAnalytics, getTargetedUsers, getClientsAnalytics } from '../../../api/campaignAnalyticsApi'
import { setLoading, setError } from '../../../commons/components/application/miscActions'
import { connect } from 'react-redux'
import { deepCopy } from '../../../commons/utils/deepCopy'
import { envConfig } from '../../../config/config';
import { BestDaysCardContainer } from './BestDays/BestDaysCardContainer'
import { Toast, toast, Modal, ModalBody, TimePeriodSelector, timePeriodsIds, getAllTimePeriods, getTimePeriods, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'botbit-ui-components'
import { switchCampaignActive, deleteCampaign, sendCampaign } from '../../../api/campaignsApi';
import { PreviewModal, handlePreview } from "./../MinimalEditor/Helper";
import MinimalEditor from '../MinimalEditor/MinimalEditor'
import { getEmptyActivableCampaignsImage } from '../CampaignsHelper'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactTooltip from 'react-tooltip';
import { ClientsAnalytics } from '../ClientsAnalytics/ClientsAnalytics'
import SegmentDetail from '../../segments/SegmentDetail/SegmentDetail'

const MySwal = withReactContent(Swal);
const HELP_CENTER_WT_URL = `https://help.botbit.io/es/articles/3586151-como-hacer-mas-atractivas-tus-campanas-y-lograr-una-mejor-tasa-de-conversion-wtr?utm_source=admin&utm_medium=browser`
const HELP_CENTER_OPENED_URL = `https://help.botbit.io/es/articles/3585881-que-puedes-hacer-para-mejorar-el-open-rate-o-tasa-de-apertura-de-tus-campanas?utm_source=admin&utm_medium=browser`
const TRANSLATION_PREFIX = "panels.campaigns.analytics."
const screenIsMobile = (window.innerWidth < 992)
const mobileWidth = 992
const mapStateToProps = (state) => {
  return {
    loading: state.misc.loading,
    availableStores: state.application.availableStores,
    companyId: state.application.selectedCompany.id,
    selectedCompany: state.application.selectedCompany,
    selectedStore: state.application.selectedStore
  }
};

const getAllTimePeriodsNew = () => {
  let tp = getAllTimePeriods();
  tp.push(timePeriodsIds.CUSTOM);
  return tp;
}
class AnalyticsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      applyButtonDisabled: true,
      timePeriodValue: this.setTimePeriodSelectorInitialValue(),
      loading: true
    }
    props.setLoading(true)
  }

  componentDidMount() {
    this.props.setLoading(true)
    this.getCampaignAnalytics()
    getTargetedUsers(this.props.campaignId, (error) => {
      return { ignore: true }
    })
      .then((res) => {
        this.setState({ targetedUsers: res.data.data.targetedUsers })

      }).catch(error => {
        this.setState({ targetedUsersHasError: true })
      }).finally(() => {
        this.setState({ loading: false })
      })
  }

  getCampaignAnalytics = () => {
    this.props.setLoading(true)
    let campaignAnalytics = {}

    getCampaignAnalytics(this.props.campaignId, this.state.timePeriodValue, (error) => { return { ignore: true } })
      .then((res) => {
        campaignAnalytics = res.data.data;
        Object.keys(campaignAnalytics.metrics).forEach(key => {
          if (typeof (campaignAnalytics.metrics[key]) === "object" && campaignAnalytics.metrics[key] !== null) {
            campaignAnalytics.metrics[key].name = key;
          }
        })
        this.props.setLoading(false)
      }).catch((error) => {
        //si no encuentro una campania con ese id
        this.setState({ campaignNotExistant: true })
      })
      .finally(() => {
        this.setState({ campaignAnalytics: campaignAnalytics })
      })
  }

  setTimePeriodSelectorInitialValue = () => {
    //setear el valor inicial a Ultimos 30 dias
    let timePeriodList = getTimePeriods();
    let timePeriodSelectorInitVal = {};
    let from = new Date();
    let to = new Date();
    from.setDate(from.getDate() - 29);
    from.setHours(0, 0, 0, 0);
    to.setHours(0, 0, 0, 0);
    timePeriodSelectorInitVal.text = timePeriodList.filter(tp => tp.id === timePeriodsIds.LAST_30_DAYS)[0].value;
    timePeriodSelectorInitVal.value = timePeriodsIds.LAST_30_DAYS;
    timePeriodSelectorInitVal.from = from;
    timePeriodSelectorInitVal.to = to;
    return timePeriodSelectorInitVal;
  }

  mobileTimePeriodSelector = () => {
    return (
      <Modal isOpen={this.state.timePeriodSelectorModalIsOpen} className="mobileTimePeriodSelector">
        <ModalBody className="p-0">
          <MDBIcon icon="arrow-left" className="closeModalIcon" id="backIcon" fas="true" onClick={() => this.setState({ timePeriodSelectorModalIsOpen: false })} />
          <span id="timePeriodModalTitle">{this.props.t(TRANSLATION_PREFIX + 'timePeriodSelector.title')}</span>
          {this.timePeriodSelectorComponent()}
          <MDBBtn
            color="primary"
            id="dateApplyBtn"
            disabled={this.state.applyButtonDisabled}
            onClick={() => this.handleFilterApply(this.state.timePeriodTempValue)}
          >
            {this.props.t(`${TRANSLATION_PREFIX}apply`)}
          </MDBBtn>
        </ModalBody>
      </Modal>
    )
  }

  handleSwitchChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.setLoading(true)
    switchCampaignActive(this.state.campaignAnalytics.metrics.campaignId, this.state.campaignAnalytics.active)
      .then((res) => {
        let newNextFireTime = (!this.state.campaignAnalytics.active) ? (res.data.data && res.data.data.nextFireTime) : null;
        this.setState({
          campaignAnalytics: {
            ...this.state.campaignAnalytics,
            deliveries: {
              ...this.state.campaignAnalytics.deliveries,
              nextFireTime: newNextFireTime
            },
            active: !this.state.campaignAnalytics.active
          }
        })
        toast(
          this.props.t('panels.campaigns.otherLabels.actions.campaign' + (!this.state.campaignAnalytics.active ? 'Deactivated' : 'Activated')) + ' ' + this.state.campaignAnalytics.summary.name,
          { containerId: "analytics-toast" })
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.props.setLoading(false)
      })
  }

  setPreviewIsOpen = (isOpen) => {
    return new Promise((resolve) => {
      this.setState({
        preview: isOpen
      }, () => resolve())
    })
  }

  handlePreview(action) {
    handlePreview(this.setPreviewIsOpen, (action === "open"));
  }

  handleSendNow = ({ id, company, summary: { name }, active, segment }) => {
    const translationPrefix = 'panels.campaigns.otherLabels.sendNow.';
    const { t, setLoading } = this.props;
    let swalText = t(`${translationPrefix}text.${!!segment}`, { status: t(`${translationPrefix}text.active.${active}`) })
    let confirmationButtonText = t(`${translationPrefix}${(segment ? `sendNow` : 'goBack')}`)
    let swalType = (segment ? 'warning' : 'error')
    let campaignName = name.substring(0, 13) + (name.length >= 13 ? '...' : '')

    MySwal.fire({
      title: t(`${translationPrefix}sendNowSub`, { name: campaignName }),
      text: swalText,
      type: swalType,
      showCancelButton: segment,
      cancelButtonText: t(`${translationPrefix}cancel`),
      confirmButtonText: confirmationButtonText,
      confirmButtonColor: '#1ab394'
    }).then((result) => {
      if (result.value && segment) {
        setLoading(true)
        //llamada al servicio
        sendCampaign(id, company.id)
          .then((response) => {
            toast(t(`${translationPrefix}campaignSentSuccessfully`, { name: campaignName }),
              { containerId: "analytics-toast" })
          })
          .catch((err) => {
            toast(t(`${translationPrefix}campaignDeliveryHadError`, { name: campaignName }),
              { containerId: "analytics-toast" })
          })
          .finally(() => {
            setLoading(false)
          })
      }
    });
  }

  timePeriodSelectorComponent = () => <TimePeriodSelector
    expanded
    options={getAllTimePeriodsNew()}
    value={this.state.timePeriodTempValue}
    getValue={(val) => {
      this.setState({ applyButtonDisabled: false, timePeriodTempValue: val })
    }}
  />
  pageHeader = () => {
    let locale = navigator.language;
    const { t } = this.props;
    let analytics = deepCopy(this.state.campaignAnalytics)
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const deployDate = new Date(this.state.campaignAnalytics.metrics.companyMigrationDate).toLocaleDateString(locale, options);
    return (
      <div className="analyticsHeader">
        <div className="d-flex justify-content-between">
          <MDBIcon icon="arrow-left" id="backIcon" fas="true" onClick={() => { this.props.history.push(`/campaigns`) }} />
          {screenIsMobile &&
            <div>
              <button className="link-like-btn" id="timePeriodLabel" onClick={() => this.setState({ timePeriodSelectorModalIsOpen: true })}>
                {this.state.timePeriodValue.text}
                <MDBIcon icon="caret-down" className="ml-2" />
              </button>
              <Dropdown className="d-inline-block">
                <DropdownToggle caret>
                  <MDBIcon icon="cog" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem><span onClick={() => this.handlePreview("open")}>{t(`${TRANSLATION_PREFIX}previsualize`)}</span></DropdownItem>
                  {!this.state.campaignAnalytics.isPredefined &&
                    <DropdownItem>
                      <span
                        onClick={() => this.props.history.push(`/campaigns/${this.state.campaignAnalytics.id}/create?templateType=CUSTOM&companyId=${this.state.campaignAnalytics.company.id}`)}
                      >
                        {t('panels.campaigns.otherLabels.duplicate')}
                      </span>
                    </DropdownItem>
                  }
                  {analytics.canModify &&
                    <>
                      <DropdownItem><span onClick={() => this.handleSendNow(this.state.campaignAnalytics)}>{t(`panels.campaigns.otherLabels.sendNow.sendNow`)}</span></DropdownItem>
                      <DropdownItem>
                        <span onClick={() => this.openEditor()}>
                          {t(`${TRANSLATION_PREFIX}edit`)}
                        </span>
                      </DropdownItem>
                      <DropdownItem>
                        <span className="delete" onClick={() => this.setState({ confirmationModalIsOpen: true })}>
                          {t(`${TRANSLATION_PREFIX}delete`)}
                        </span>
                      </DropdownItem>
                    </>
                  }

                </DropdownMenu>
              </Dropdown>
            </div>
          }
          {!screenIsMobile &&
            <div className="headerActions">
              <span className="d-inline-block mr-4 greenText">
                <span onClick={() => this.handlePreview("open")}>{t(`${TRANSLATION_PREFIX}previsualize`)}</span>
              </span>
              {!this.state.campaignAnalytics.isPredefined &&
                <span
                  onClick={() => this.props.history.push(`/campaigns/${this.state.campaignAnalytics.id}/create?templateType=CUSTOM&companyId=${this.state.campaignAnalytics.company.id}`)}
                  className="d-inline-block mr-4 greenText">
                  <span>{t('panels.campaigns.otherLabels.duplicate')}</span>
                </span>
              }
              {analytics.canModify &&
                <>
                  <span onClick={() => this.handleSendNow(this.state.campaignAnalytics)} className="d-inline-block mr-4 greenText">
                    <span>{t(`panels.campaigns.otherLabels.sendNow.sendNow`)}</span>
                  </span>
                  <span onClick={() => this.openEditor()} className="d-inline-block mr-4 greenText">
                    <span>{t(`${TRANSLATION_PREFIX}edit`)}</span>
                  </span>
                  <span className="d-inline-block delete" onClick={() => this.setState({ confirmationModalIsOpen: true })}>
                    <span>{t(`${TRANSLATION_PREFIX}delete`)}</span>
                  </span>
                </>
              }
            </div>
          }
        </div>
        <div id="nameBadgeContainer">
          <div className="d-flex justify-content-between">
            <div className={(screenIsMobile ? "w-100" : '')}>
              <div className={(screenIsMobile ? "d-block mb-1" : 'd-flex')}>
                <span id="campaignName">
                  {analytics.summary.name}
                </span>
                <MDBSwitch
                  disabled={!this.state.campaignAnalytics.canModify}
                  checked={this.state.campaignAnalytics.active}
                  onChange={(event) => this.handleSwitchChange(event)}
                  labelLeft=""
                  labelRight=""
                  className={"d-inline-block" + (screenIsMobile ? " mobileSwitch" : ' mt-1 ml-5')} />
              </div>
              {analytics.isPredefined &&
                <MDBBadge color="warning" className="shadow-none position-absolute">
                  <MDBIcon far icon="lightbulb" /> {t(`${TRANSLATION_PREFIX}smart`)}
                </MDBBadge>
              }
            </div>
            {!screenIsMobile &&
              <Dropdown className="dateDropdownContainer">
                <DropdownToggle outline caret color="primary" className="dropdownToggle">
                  {this.state.timePeriodValue.text}
                </DropdownToggle>
                <DropdownMenu basic className="w-100 bg-white">
                  {this.timePeriodSelectorComponent()}
                  <MDBBtn color="primary" id="dateApplyBtn" disabled={this.state.applyButtonDisabled} onClick={() => this.handleFilterApply(this.state.timePeriodTempValue)}>{t(`${TRANSLATION_PREFIX}apply`)}</MDBBtn>
                </DropdownMenu>
              </Dropdown>
            }
          </div>
        </div>
        {analytics.metrics.metricsWarning &&
          <div id="deployDateWarning">
            <div>
              <MDBIcon icon="exclamation-triangle" />
            </div>
            <div>
              <span>{t(`${TRANSLATION_PREFIX}${analytics.metrics.companyMigrationDate ? `deployDateWarning` : `deployDateWarningWithoutDate`}`, { date: deployDate })}</span>
            </div>
          </div>
        }
      </div>
    )
  }
  handleFilterApply = (timePeriodTempValue) => {
    if (!screenIsMobile) {
      let dropdown = document.getElementsByClassName('dropdownToggle')[0];
      dropdown.click()
    }
    this.setState({
      ...this.state,
      timePeriodValue: timePeriodTempValue,
      applyButtonDisabled: true,
      timePeriodSelectorModalIsOpen: false
    })
    setTimeout(() => this.getCampaignAnalytics(), 0)
    //aca seria la llamada al servicio de vuelta, con los datos de time period.
  }
  pageBody = () => {
    const { t } = this.props;
    //TODO: para mi este deepCopy esta demas. No veo que analytics sea afectado de manera directa o indirecta.
    let analytics = deepCopy(this.state.campaignAnalytics)
    let effectivityCardBackground = (analytics.metrics.totalSent === 0 ? "noDeliveryCard" : `${analytics.effectivity.performance}Card`)
    let issueModificator = t(TRANSLATION_PREFIX + (analytics.effectivity.goodDeliveryRate ? "sendRate.without" : "sendRate.with"))

    let mainImageUrl;
    if (analytics.summary.mainImage) {
      mainImageUrl = analytics.summary.mainImage;
    } else if (analytics.summary.attachedImages && analytics.summary.attachedImages !== "") {
      mainImageUrl = analytics.summary.attachedImages.split(";")[0];
    } else {
      mainImageUrl = envConfig.staticImgUrl + "stores/no-image.png";
    }

    const mobile = screenIsMobile ? "Mobile" : "";
    return (
      <>
        <div className={"campaignSummary pb-5" + (analytics.isPredefined ? ' isSmart' : '')}>
          <span id="title" className="d-block">
            {t(TRANSLATION_PREFIX + 'campaignSummary')}
          </span>
          <div id="bodyCards">
            <div id="effectivityCard" className={effectivityCardBackground}>
              <div>
                <span id="effectivity">
                  {t(TRANSLATION_PREFIX + 'effectivity.label')}
                </span>
                <div>
                  {analytics.metrics.totalSent !== 0 ?
                    <div id="effectivityDesc">
                      <span>
                        {t(`${TRANSLATION_PREFIX}effectivity.${analytics.effectivity.performance}`)}
                      </span>
                      {!screenIsMobile &&
                        <MDBIcon icon={analytics.effectivity.performance === "HIGH" || analytics.effectivity.performance === "MEDIUM" ? "circle" : "exclamation-circle"} />

                      }
                    </div>
                    :
                    <span id="noDeliveryEffectivity">{t(`${TRANSLATION_PREFIX}noDeliveryEffectivity`)}</span>
                  }
                </div>
              </div>
              {analytics.metrics.totalSent !== 0 && analytics.countAsWT &&
                <>
                  <div id="roi" className="position-relative">
                    <>
                      <MDBIcon far icon="money-bill-alt" className="position-absolute" />
                      {/* <MDBIcon icon="check-circle" className="position-absolute" /> */}
                    </>
                    <span className="d-block">
                      {t(`${TRANSLATION_PREFIX}roi.text`, { roi: analytics.effectivity.roi })}
                    </span>
                  </div>
                  <div id="walkthrough" className="position-relative">
                    <span className="position-absolute">
                      {analytics.metrics.walkthrough.total}
                    </span>
                    <span className="d-block">
                      {t(TRANSLATION_PREFIX + 'walkthrough')}
                    </span>
                  </div>
                </>
              }
              {analytics.active && analytics.metrics.totalSent !== 0 &&
                <div id="sendRate" className="position-relative">
                  {analytics.effectivity.goodDeliveryRate ?
                    <>
                      <MDBIcon icon="envelope" className="position-absolute" />
                      <MDBIcon icon="check-circle" className="position-absolute" />
                    </>
                    :
                    <MDBIcon icon="exclamation-circle" className="position-absolute" />
                  }
                  <span className="d-block">
                    {t(TRANSLATION_PREFIX + 'sendRate.title', { issueModificator: issueModificator })}
                  </span>
                </div>
              }
            </div>
            <div id="firstCard">
              {this.campaignMainImage(mainImageUrl)}
              <div id="firstCardTexts">
                <span className="d-inline-block" id="campaignMessage">
                  {analytics.summary.subject}
                </span>
                {analytics.summary.benefitMessage && analytics.summary.benefitMessage !== "Ninguno" && analytics.summary.benefitMessage !== "None" &&
                  <span className="d-block">
                    {t(`${TRANSLATION_PREFIX}clientsWillGet`, { benefit: analytics.summary.benefitMessage })}
                  </span>
                }
                {analytics.summary.lastUpdateTimestamp &&
                  <span className="d-block">
                    {t(TRANSLATION_PREFIX + 'lastEdit' + mobile,
                      {
                        lastEdit: this.getDay(analytics.summary.lastUpdateTimestamp),
                        editHours: this.getHours(analytics.summary.lastUpdateTimestamp),
                        editedBy: (analytics.summary.lastEditedBy && analytics.summary.lastEditedBy.fullName &&
                          t(TRANSLATION_PREFIX + 'lastEditBy', { lastEditBy: analytics.summary.lastEditedBy.fullName }))
                      })}<br />
                  </span>
                }
                {analytics.deliveries && analytics.deliveries.prevFireTime &&
                  <span className="d-block">{t(`${TRANSLATION_PREFIX}lastDelivery` + mobile,
                    {
                      date: this.getDay(analytics.deliveries.prevFireTime),
                      deliveryHours: this.getHours(analytics.deliveries.prevFireTime)
                    })}</span>
                }
                {analytics.deliveries && analytics.deliveries.nextFireTime &&
                  <span className={"d-block" + (!screenIsMobile ? "mt-0" : "")}>{t(`${TRANSLATION_PREFIX}nextDelivery` + mobile,
                    {
                      date: this.getDay(analytics.deliveries.nextFireTime),
                      deliveryHours: this.getHours(analytics.deliveries.nextFireTime)
                    }
                  )}</span>
                }
              </div>
            </div>
            {!screenIsMobile && this.secondCard()}
          </div>
          {screenIsMobile && this.secondCard()}
        </div>
        {this.campaignStatus()}
        {this.state.campaignAnalytics.bestOpeningDays && this.state.campaignAnalytics.bestOpeningDays.length > 0 &&
          <div className="mt-5">
            <span id="title">
              {this.props.t('panels.campaigns.analytics.card.title')}
            </span>
            <BestDaysCardContainer mobileWidth={mobileWidth} t={this.props.t} bestOpeningDays={this.state.campaignAnalytics.bestOpeningDays} />
          </div>
        }
      </>
    )
  }

  getDay = (timeStamp) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(timeStamp);
    if (screenIsMobile) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${(month < 10 && `0${month}`) || (month >= 10 && `${month}`)}/${year}`;
    }
    return date.toLocaleDateString(navigator.language, options);
  }

  getHours = (timeStamp) => {
    const date = new Date(timeStamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes > 10 ? minutes : '0' + minutes}`
  }

  clickedInfo = () => {
    let campaignMetrics = deepCopy(this.state.campaignAnalytics.metrics);
    let showSeeClientsButton;
    if (campaignMetrics.opened.total >= 1) {
      showSeeClientsButton = campaignMetrics.companyMigrationDate ? new Date(campaignMetrics.companyMigrationDate) < this.state.timePeriodValue.from : true
    }
    return (
      !this.state.campaignAnalytics.countAsWT &&
      <div id="clicked" className={!this.state.campaignAnalytics.countAsWT ? 'noWalkthrough' : ''}>
        <span>{this.props.t(TRANSLATION_PREFIX + 'campaignStatus.clicked')} {!campaignMetrics.clicked.goodRate && campaignMetrics.totalSent !== 0 && <MDBIcon icon="exclamation-circle" className="text-danger" />}</span>
        <span style={(screenIsMobile && { display: 'flex', flexDirection: 'column' }) || {}}><strong>{campaignMetrics.clicked.total} {screenIsMobile && '/'}</strong> {!screenIsMobile && '/'}{campaignMetrics.clicked.rate}%</span>
        {
          showSeeClientsButton &&
          <MDBBtn className="seeClientsBtn" onClick={() => {
            this.setState({
              clientsModalIsOpen: true,
              clientsAnalyticsTitle: this.props.t(`${TRANSLATION_PREFIX}clientsThatClicked`),
              clientsAnalyticsMode: "CLICKED",
              isOpened: true
            })
          }}>{this.props.t(`${TRANSLATION_PREFIX}seeClients`)}</MDBBtn>
        }
      </div>
    )
  }

  returnedInfo = () => {
    let campaignMetrics = deepCopy(this.state.campaignAnalytics.metrics)
    let showSeeClientsButton = false;
    if (campaignMetrics.walkthrough.total >= 1) {
      showSeeClientsButton = campaignMetrics.companyMigrationDate ? new Date(campaignMetrics.companyMigrationDate) < this.state.timePeriodValue.from : true
    }
    return (
      // <>
      //     <div id="clicks" className={!this.state.campaignAnalytics.countAsWT ? 'noWalkthrough' : ''}>
      //         <span>Clicks {!campaignMetrics.clicked.goodRate && campaignMetrics.totalSent !== 0 && <MDBIcon icon="exclamation-circle" className="text-danger"/>}</span>
      //         <span><strong>{campaignMetrics.clicked.total}</strong> / {campaignMetrics.clicked.rate}%</span>
      //     </div>
      this.state.campaignAnalytics.countAsWT &&
      <div id="returned">
        <span>{this.props.t(TRANSLATION_PREFIX + 'campaignStatus.returned')} {!campaignMetrics.walkthrough.goodRate && campaignMetrics.totalSent !== 0 && <MDBIcon icon="exclamation-circle" className="text-danger" />}</span>
        <span style={(screenIsMobile && { display: 'flex', flexDirection: 'column' }) || {}}><strong>{campaignMetrics.walkthrough.total} {screenIsMobile && '/'}</strong> {!screenIsMobile && '/'}{campaignMetrics.walkthrough.rate}%</span>
        {showSeeClientsButton &&
          <MDBBtn className="seeClientsBtn" onClick={() => {
            this.setState({
              clientsModalIsOpen: true,
              clientsAnalyticsTitle: this.props.t(`${TRANSLATION_PREFIX}clientsThatReturned`),
              clientsAnalyticsMode: "WALKTHROUGH",
              isOpened: false
            })
          }}>{this.props.t(`${TRANSLATION_PREFIX}seeClients`)}</MDBBtn>
        }
      </div>

      // </>
    )
  }

  openedAndRecievedInfo = () => {
    let campaignMetrics = deepCopy(this.state.campaignAnalytics.metrics)
    let showSeeClientsButton = false;
    if (campaignMetrics.opened.total >= 1) {
      showSeeClientsButton = campaignMetrics.companyMigrationDate ? new Date(campaignMetrics.companyMigrationDate) < this.state.timePeriodValue.from : true
    }
    return (
      <>
        <div id="recieved" className={!this.state.campaignAnalytics.countAsWT ? 'noWalkthrough' : ''}>
          <span>{this.props.t(TRANSLATION_PREFIX + 'campaignStatus.recieved')} {!campaignMetrics.delivery.goodRate && campaignMetrics.totalSent !== 0 && <MDBIcon icon="exclamation-circle" className="text-danger" />}</span>
          <strong>{campaignMetrics.delivery.total}</strong>
          <span>{campaignMetrics.totalSent} {this.props.t(TRANSLATION_PREFIX + 'campaignStatus.sent')}</span>
        </div>
        <div id="opened" className={!this.state.campaignAnalytics.countAsWT ? 'noWalkthrough' : ''}>
          <span>{this.props.t(TRANSLATION_PREFIX + 'campaignStatus.opened')} {!campaignMetrics.opened.goodRate && campaignMetrics.totalSent !== 0 && <MDBIcon icon="exclamation-circle" className="text-danger" />}</span>
          <span style={(screenIsMobile && { display: 'flex', flexDirection: 'column' }) || {}}><strong>{campaignMetrics.opened.total} {screenIsMobile && '/'}</strong>{!screenIsMobile && '/'}{campaignMetrics.opened.rate}%</span>
          {showSeeClientsButton &&
            <MDBBtn className="seeClientsBtn" onClick={() => {
              this.setState({
                clientsModalIsOpen: true,
                clientsAnalyticsTitle: this.props.t(`${TRANSLATION_PREFIX}clientsThatOpened`),
                clientsAnalyticsMode: "OPENED",
                isOpened: true
              })
            }}>{this.props.t(`${TRANSLATION_PREFIX}seeClients`)}</MDBBtn>
          }
          {/* */}
        </div>
      </>
    )
  }

  statusDescriptionSub = () => {
    let campaignMetrics = deepCopy(this.state.campaignAnalytics.metrics)
    let metricsArray = [campaignMetrics.delivery, campaignMetrics.opened]
    if (this.state.campaignAnalytics.countAsWT) {
      metricsArray.push(campaignMetrics.walkthrough)
    }
    if (!this.state.campaignAnalytics.countAsWT) {
      metricsArray.push(campaignMetrics.clicked);
    }
    return (
      <div id="statusDescSub">
        {
          metricsArray.map((i) => {
            return (
              <div className={(i.goodRate ? "" : 'badRate') + (!this.state.campaignAnalytics.countAsWT ? ' noWalkthrough' : '')}>
                <div className="position-relative">
                  <MDBIcon icon={i.goodRate ? "check-circle" : "exclamation-circle"}
                    className={"text-" + (i.goodRate ? "info" : "danger")} />
                  {this.props.t(TRANSLATION_PREFIX +
                    "campaignStatus.rateMessages."
                    + i.name + '.'
                    + (i.goodRate ? "good" : "bad"))}
                  {(i.name === "walkthrough" || i.name === "opened") && !i.goodRate &&
                    <>
                      <br />
                      <a href={i.name === "walkthrough" ? HELP_CENTER_WT_URL : HELP_CENTER_OPENED_URL} target="_blank" rel="noopener noreferrer">
                        {this.props.t(TRANSLATION_PREFIX
                          + 'campaignStatus.rateMessages.walkthrough.callToAction')}
                      </a>
                    </>
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  seeClientsModal = () => {
    return <Modal fullscreen isOpen={this.state.clientsModalIsOpen} toggle={() => this.setState({ clientsModalIsOpen: false }, () => { this.setState({ rerender: !this.state.rerender }) })} className="clientsAnalyticsModal" fullHeightCentered>
      <MDBIcon icon='times' onClick={() => this.setState({ clientsModalIsOpen: false }, () => { this.setState({ rerender: !this.state.rerender }) })} />
      <ModalBody>
        <ClientsAnalytics t={this.props.t}
          getData={getClientsAnalytics}
          title={this.state.clientsAnalyticsTitle}
          isOpened={this.state.isOpened}
          eventSubType={this.state.clientsAnalyticsMode}
          campaignId={this.state.campaignAnalytics.id}
          period={this.state.timePeriodValue} />
      </ModalBody>
    </Modal>
  }

  campaignStatus = () => {
    return (
      <div id="campaignStatus">
        {this.state.campaignAnalytics.metrics.totalSent === 0 ?
          <div className="d-flex pb-5" id="emptyTitle">
            <span>{this.props.t(`${TRANSLATION_PREFIX}noDeliveriesYet`)}</span>
          </div>
          :
          <>
            <span id="title" className="d-block">
              {this.props.t(`${TRANSLATION_PREFIX}campaignStatus.title`)}
            </span>
            <div id="chartContainer">
              {screenIsMobile ?
                <>
                  <div className="statusDesc">
                    {this.openedAndRecievedInfo()}
                    {!this.state.campaignAnalytics.countAsWT && this.clickedInfo()}
                    {this.state.campaignAnalytics.countAsWT && this.returnedInfo()}
                  </div>
                  {this.areaChart()}
                </>
                :
                <>
                  {/*SI el screen es desktop*/}
                  <div className="mt-4">
                    {this.areaChart()}
                  </div>
                  <div id="status">
                    <div className="statusDesc">
                      {this.openedAndRecievedInfo()}
                      {this.state.campaignAnalytics.countAsWT && this.returnedInfo()}
                      {!this.state.campaignAnalytics.countAsWT && this.clickedInfo()}
                    </div>
                    {this.state.campaignAnalytics.metrics.totalSent !== 0 && this.statusDescriptionSub()}
                  </div>
                </>
              }
            </div>
          </>
        }
      </div>
    )
  }


  campaignMainImage = (url) => {
    let image = <div className="campaignMainImage" style={{ backgroundImage: "url(" + url + ")" }} />
    return image;
  }

  areaChart = (part) => {
    let campaignMetrics = deepCopy(this.state.campaignAnalytics.metrics)
    let options = {
      vAxis: {
        minValue: 0,
        ticks: [],
      },
      chartArea: { width: '100%', height: '100%' },
      colors: ["#B066FE"],
      areaOpacity: 0.8,
      enableInteractivity: false
    }
    let data = [
      ['x', 'y']
    ]
    if (!part) {
      options.hAxis = {
        ticks: [1, 2, 3.0003],
        baselineColor: "white"
      }
      data.push(
        [1, campaignMetrics.totalSent],
        [2, campaignMetrics.delivery.total],
        [3, campaignMetrics.opened.total],
        //[3, campaignMetrics.opened.total],
        // [4, campaignMetrics.clicked.total]
      )
      if (this.state.campaignAnalytics.countAsWT) {
        data.push([4, campaignMetrics.walkthrough.total]);
        options.hAxis = {
          ticks: [1, 2.002, 3, 4]
        }
      }
      else {
        data.push(
          [4, campaignMetrics.clicked.total]
        )
      }
    }
    else if (part) {
      if (part === 1) {
        options.hAxis = {
          ticks: [2.005],
        }
        data.push(
          [1, campaignMetrics.totalSent],
          [2, campaignMetrics.delivery.total],
          [3, campaignMetrics.opened.total]
        )
        if (!this.state.campaignAnalytics.countAsWT) {
          data.push([4, campaignMetrics.clicked.total])
        }
      } else {
        options.hAxis = {
          ticks: [],
        }
        data.push(
          [1, campaignMetrics.opened.total],
          [2, campaignMetrics.walkthrough.total]
        )
      }
    }
    return <Chart
      //por alguna razon, el relleno del chart no ocupa 100% de su espacio. tengo que sacarle 1 pixel por la izq para poder colocar la chart bien, aunque queda un espacio blanco.
      width={screenIsMobile ? 'calc( 100% - 1px )' : '100%'}
      height={screenIsMobile ? '113px' : '170px'}
      chartType="AreaChart"
      data={data}
      options={options}
    />;
  }

  secondCard = (className) => {
    const { t } = this.props;
    return (
      <div id="secondCard" className={className}>
        <div id="secondCardTexts">
          <div className={`d-block ${this.state.targetedUsersHasError ? `segmentHasError` : ``}`} id="clientsQuantity">
            {this.state.loading ?
              <MDBSpinner />
              :
              this.state.targetedUsersHasError ?
                <>
                  <div className="d-flex flex-column align-items-center" id="targetedUsersError">
                    <MDBIcon icon="exclamation-circle" />
                    <span>{t(`${TRANSLATION_PREFIX}segmentHasProblems`)}</span>
                    {this.state.campaignAnalytics.canModify ?
                      this.state.campaignAnalytics.isPredefined
                        ?
                        <span>
                          {t(`${TRANSLATION_PREFIX}segmentHasProblemsSmartSub`)}
                          <a href="mailto:cs@botbit.io">cs@botbit.io</a>
                        </span>
                        :
                        <span>
                          {t(`${TRANSLATION_PREFIX}segmentHasProblemsCustomSub`)}
                          <span className="link-styled" onClick={() => this.props.history.push(`/campaigns/${this.state.campaignAnalytics.id}/edit?activeTab=2`)}>{t(`${TRANSLATION_PREFIX}here`)}</span>
                        </span>

                      : ''}
                  </div>
                </>
                :
                <>
                  <strong>{this.state.targetedUsers}</strong>
                  <span id="clientsNumber">{t(`${TRANSLATION_PREFIX}clients`)}</span>

                  <MDBIcon data-tip data-for='clientsCount' icon="question-circle" />
                  <ReactTooltip id='clientsCount' effect='solid'>
                    <span>{t(`${TRANSLATION_PREFIX}estimate`)}</span>
                  </ReactTooltip>

                  <span className="d-block couldRecieveCampaign">{t(`${TRANSLATION_PREFIX}couldRecieveCampaign`)}</span>
                  {this.state.campaignAnalytics.segment ?
                    <span id="segmentinfo">
                      <SegmentDetail
                        segment={this.state.campaignAnalytics.segment}
                        availableStores={this.props.availableStores}
                        t={t}
                      />
                    </span>
                    :
                    <div>
                      {t(`${TRANSLATION_PREFIX}noSegmentInformation`)}
                    </div>
                  }
                </>
            }
          </div>
        </div>
      </div>
    )
  }

  confirmationModal = () => {
    return (
      <Modal isOpen={this.state.confirmationModalIsOpen} centered className="deletionModal">
        <ModalBody>
          <p>
            {(this.props.t('panels.campaigns.otherLabels.actions.deleteTitle'))} "{this.state.campaignAnalytics.summary.name}"
					</p>
          <p>
            {(this.props.t('panels.campaigns.otherLabels.actions.deleteText'))}
            <br />
            {(this.props.t('panels.campaigns.otherLabels.actions.deleteSubText'))}
          </p>
          <div className="text-right">
            <MDBBtn color="red" size="sm" onClick={() => this.deleteCampaign()}>
              <strong>
                {(this.props.t('panels.campaigns.otherLabels.actions.deleteButton'))}
              </strong>
            </MDBBtn>
            <button className="link-styled" onClick={() => this.setState({ confirmationModalIsOpen: false })}>
              {(this.props.t('panels.campaigns.otherLabels.actions.cancel'))}
            </button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  deleteCampaign = () => {
    deleteCampaign(this.state.campaignAnalytics.metrics.campaignId)
      .then(() => {
        this.setState({
          confirmationModalIsOpen: false
        })
        toast(this.props.t('panels.campaigns.otherLabels.actions.campaignDeleted') + ' ' + this.state.campaignAnalytics.summary.name,
          { containerId: "analytics-toast" })

        this.props.history.push('/campaigns')
      })
  }


  minimalEditorModal = () => {
    return (
      <Modal isOpen={this.state.minimalEditorIsOpen} fullscreen className="minimalEditorModal">
        <ModalBody className="p-0">
          <MinimalEditor
            setLoading={(val) => this.props.setLoading(val)}
            onBack={this.closeEditor}
            onClose={this.closeEditor}
            t={this.props.t}
            campaignId={this.state.campaignAnalytics.id}
            availableStores={this.props.availableStores}
            companyId={this.props.companyId}
            mode="edit"
            history={this.props.history}
          />
        </ModalBody>
      </Modal>
    )
  }

  openEditor = () => {
    if (this.state.campaignAnalytics.isPredefined) {
      this.setState({ minimalEditorIsOpen: true })
    } else {
      //si es una campania custom, le abrimos el editor maximal.
      this.props.history.push(`/campaigns/${this.state.campaignAnalytics.metrics.campaignId}/edit`)
    }
  }

  closeEditor = () => {
    this.setState({
      minimalEditorIsOpen: false
    }, () => this.getCampaignAnalytics())
  }

  render() {
    const { t } = this.props;
    if (this.state.campaignNotExistant) {
      return (<MDBContainer id="analyticsEmptyStateContainer">
        {getEmptyActivableCampaignsImage()}
        <span className="text-center">{this.props.t(TRANSLATION_PREFIX + 'emptyState.text')}</span>
        <MDBBtn
          className="outlined-primary-button mt-3 campaignsActionButton"
          onClick={() => this.props.history.push('/campaigns')}>
          {this.props.t(TRANSLATION_PREFIX + 'emptyState.actionButton')}
        </MDBBtn>
      </MDBContainer>)
    }
    else if (!this.state.campaignAnalytics) {
      return null
    }
    if (this.state.campaignAnalytics.metrics) {
      const metrics = this.state.campaignAnalytics.metrics;
      if (metrics.totalSent > 1000 && (metrics.delivery.total / metrics.totalSent) < 0.1)
        MySwal.fire({
          title: t(TRANSLATION_PREFIX + 'campaignStatus.weAreStillProcessing.title'),
          text: t(TRANSLATION_PREFIX + 'campaignStatus.weAreStillProcessing.text'),
          confirmButtonText: t(TRANSLATION_PREFIX + 'campaignStatus.weAreStillProcessing.confirmButtonText')
        });
    }

    return (
      <MDBContainer id="analyticsContainer" className="mt-4">
        <Toast containerId="analytics-toast" autoClose={4000} />
        {this.minimalEditorModal()}
        {this.confirmationModal()}
        {this.seeClientsModal()}
        {this.mobileTimePeriodSelector()}
        <PreviewModal campaignName={this.state.campaignAnalytics.summary.name}
          campaignSubject={this.state.campaignAnalytics.summary.subject}
          campaignId={this.state.campaignAnalytics.id}
          isOpen={this.state.preview}
          close={() => this.handlePreview('close')}
          t={this.props.t} />
        {this.pageHeader()}
        {this.pageBody()}
      </MDBContainer>
    )
  }
}

export default translate('common')(connect(mapStateToProps, { setLoading, setError })(withRouter(AnalyticsContainer)))
