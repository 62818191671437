export const translations = {
	rowActions: {
		sendEmail: {
			es: "Enviar email de bienvenida",
			en: "Send welcome email"
		},
		edit: {
			es: "Editar",
			en: "Edit"
		},
		delete: {
			es: "Eliminar",
			en: "Delete"
		},
		resetPassword: {
			es: "Resetear contraseña",
			en: "Reset password"
		},
		activate: {
			es: "Activar",
			en: "Activate"
		},
		deactivate: {
			es: "Desactivar",
			en: "Deactivate",
		},
		results: {
			close: {
				es: "Cerrar",
				en: "Close"
			},
			success: {
				es: "Operación exitosa",
				en: "Operation successfull"
			},
			resetPassword: {
				es: "Email enviado al administrador",
				en: "An email was sent to administrator"
			},
			sendEmail: {
				es: "Email enviado",
				en: "Email sent"
			}
		},
		confirmation: {
			confirm: {
				es: "Confirmar",
				en: "Confirm"
			},
			cancel: {
				es: "Cancelar",
				en: "Cancel"
			},
			activate: {
				es: "Se va a activar el administrador",
				en: "The administrator will be activated"
			},
			deactivate: {
				es: "Se va a desactivar el administrador",
				en: "The administrator will be deactivated"
			},
			delete: {
				es: "El administrador será eliminado, esta operación no podrá deshacerse",
				en: "Administrator will be deleted, this operation can not be undone"
			}
		}
	},
    createAdmin: {
        es: "Nuevo administrador",
        en: "New administrator"
    },
    adminName: {
        es: "Nombre",
        en: "Name"
    },
    title: {
        es: "Administradores",
        en: "Administrators"
    },
    emptyStateMsg: {
        es: "No hay información suficiente. Prueba ordenando por otro criterio.",
        en: "Not enough information. Try ordering by another criteria."
    },
    exportCSV: {
        es: "Exportar CSV",
        en: "Export CSV"
    },
    inviteAdmin: {
        es: "Invitar usuarios",
        en: "Invite new people"
    },
    avatar: {
        es: "Avatar",
        en: "Avatar"
    },
    userName: {
        es: "Nombre",
        en: "Name"
    },
    email: {
        es: "Correo eléctronico",
        en: "Email"
    },
	adminStatus: {
		label: {
			es: "Estado",
			en: "Status"
		}, 
		active: {
			es: "Activo",
			en: "Active"
		},
		inactive: {
			es: "Inactivo",
			en: "Inactive"
		}
	},
    company: {
        es: "Empresa",
        en: "Company"
    },
	store: {
		es: "Tienda",
		en: "Store"
	},
}