import React, { useState } from "react";
import { Formik, Form, FastField, ErrorMessage } from "formik";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'botbit-ui-components'
import * as Yup from 'yup';
import { MDBContainer, MDBBtn, MDBIcon, MDBSelect } from "mdbreact";

const GoogleFormModal = ({showModal, setShowModal, stores, googleForm, onSubmit, actions, t}) => {

    const getTitle = () => t('socialAccounts.connectGoogle')

    const [errorStoreId, setErrorStoreId] = useState(false)

    const buildStores = (selected) => {
        setErrorStoreId(false)
        return stores.map(store => {
            return {
                text: store.name,
                value: store.id,
                checked: store.id === selected
            }
        })
    }

    const isError = (errors, touched, props) => (errors[props.field.name] && touched[props.field.name]) ? true : false

    return (
        <Modal isOpen={showModal} toggle={() => setShowModal(true)}>
            <ModalHeader toggle={() => setShowModal(!showModal)}>{getTitle()}</ModalHeader>
            <Formik
                initialValues={googleForm}
                validationSchema={Yup.object().shape({
                    storeId: Yup.string().required(t('socialAccounts.selectStoreError'))
                })}
                onSubmit={(googleForm) => {
                    if (onSubmit) {
                        onSubmit(googleForm, actions)
                    }
                }}>
                {({ errors, touched }) => (
                    <Form>
                        <ModalBody>
                            <MDBContainer style={{height: '400px'}}>
                            <div className={(errorStoreId) ? 'is-invalid-important pr-2' : 'pr-2'}>
                                <FastField
                                    name="storeId"
                                    render={(props) => {
                                        return (props.field && <MDBSelect
                                                selected={t('socialAccounts.selectStore')}
                                                options={buildStores(props.field.value)}
                                                getValue={(values) => {
                                                    setErrorStoreId(isError(errors, touched, props))
                                                    if (props.field.value !== values[0]) {
                                                        props.form.setFieldValue(props.field.name, values[0])
                                                    }
                                                }}
                                            />)
                                    }}
                                />
                                <ErrorMessage name="storeId" component="div"
                                    className="invalid-feedback d-block"
                                    style={{ marginTop: '-15px' }} />
                            </div>

                            </MDBContainer>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-around">
                            <MDBBtn color="danger" size="sm" onClick={() => setShowModal(!showModal)}>
                                <MDBIcon icon="times" size="1x" /> {t('socialAccounts.cancel')}
                            </MDBBtn>
                            <MDBBtn color="primary" type="submit" size="sm">
                                <MDBIcon icon="edit" size="1x" /> {t('socialAccounts.connect')}
                            </MDBBtn>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )

}

export default GoogleFormModal
