import React from 'react'
import 'react-cron-generator/dist/cron-builder.css'
import './CronGenerator.css'
import { Select } from 'botbit-ui-components'
import { MDBInput, MDBSpinner } from 'mdbreact'
import 'react-day-picker/lib/style.css';
import i18next from 'i18next'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css';
import moment from 'moment'
import { QuartzCronFormatterBuild, QuartzCronFormatterParse } from './CronHelper'
var cronstrue = require('cronstrue/i18n');

const TRANSLATION_PREFIX = "panels.campaigns.maximalEditor.cron.";
export default class CronGenerator extends React.Component {
    constructor(props) {
        super(props)
        const { t } = this.props;
        this.state = {
            cronMainOptions: [
                {
                    "key": 1,
                    "text": t(`${TRANSLATION_PREFIX}minutes`),
                    "subText": t(`${TRANSLATION_PREFIX}minutesSub`),
                    "prefix": t(`${TRANSLATION_PREFIX}sendEvery`),
                    "value": "minutes",
                    "render": this.renderMinutesOption,
                    "minutesRepeater": 10,
                    "cronExpression": '',
                    "valid": true
                },
                {
                    "key": 3,
                    "text": t(`${TRANSLATION_PREFIX}daily`),
                    "subText": t(`${TRANSLATION_PREFIX}dailySub`),
                    "prefix": t(`${TRANSLATION_PREFIX}runAt`),
                    "value": "daily",
                    "render": this.renderDailyOption,
                    "minutes": moment().minutes(),
                    "hours": moment().hours(),
                    "cronExpression": '',
                    "valid": true
                },
                {
                    "key": 4,
                    "text": t(`${TRANSLATION_PREFIX}weekly`),
                    "subText": t(`${TRANSLATION_PREFIX}weeklySub`),
                    "prefix": t(`${TRANSLATION_PREFIX}runAt`),
                    "secondSubText": t(`${TRANSLATION_PREFIX}on`),
                    "value": "weekly",
                    "render": this.renderWeeklyOption,
                    "minutes": moment().minutes(),
                    "hours": moment().hours(),
                    "daysOfWeek": [],
                    "cronExpression": '',
                    "valid": true
                },
                {
                    "key": 5,
                    "text": t(`${TRANSLATION_PREFIX}monthly`),
                    "subText": t(`${TRANSLATION_PREFIX}every`),
                    "prefix": t(`${TRANSLATION_PREFIX}sendOnDay`),
                    "secondSubText": t(`${TRANSLATION_PREFIX}monthlySub`),
                    "value": "monthly",
                    "render": this.renderMonthlyOption,
                    "dayNumber": 1,
                    "monthRepeater": 1,
                    "cronExpression": '',
                    "valid": true
                }
            ],
            validFromDay: moment().toDate(),
            validFromTime: moment()
        }
    }

    componentDidMount = () => {
        const { cronMainOptions } = this.state;
        let selectedOption;
        let cronOptions = cronMainOptions;
        let time = '';
        if (this.props.cronExpression) {
            var parsedExpressionObject = QuartzCronFormatterParse(this.props.cronExpression)
            let attributes = ["render", "valid", "text", "subText", "prefix", "secondSubText", "key"]
            attributes.forEach(attr => {
                parsedExpressionObject[attr] = cronMainOptions.filter(option => option.value === parsedExpressionObject.value)[0][attr]
            })
            selectedOption = parsedExpressionObject;
            cronOptions.filter(option => option.value === selectedOption.value)[0].checked = true;
            time = `${selectedOption.hours}:${selectedOption.minutes}`
        } else {
            selectedOption = this.state.cronMainOptions.filter(option => option.value === "daily")[0]
            selectedOption.checked = true;
        }
        selectedOption = this.selectedOptionWithCronExpression(selectedOption)
        this.setState({ selectedOption: selectedOption, cronMainOptions: cronOptions, timePicker: this.getInitialTimePickerValue(time) })
    }

    componentDidUpdate = () => {
        const { selectedOption } = this.state;
        if (this.props.onChange) {
            this.props.onChange(selectedOption, selectedOption.valid)
        }
    }

    selectedOptionWithCronExpression = (selectedOption) => {
        var cronExpression = QuartzCronFormatterBuild(selectedOption)
        selectedOption.cronExpression = cronExpression
        selectedOption.cronExpressionDescription = cronstrue.toString(
            cronExpression,
            { verbose: true, locale: i18next.languages[0].slice(0, 2) },
            { dayOfWeekStartIndexZero: false })
        return selectedOption
    }

    createDaysArray = () => {
        const { t } = this.props;
        moment.locale('en-US')
        const weekDays = moment.weekdays(true)
        let weekDaysArray = [];
        weekDays.forEach(element => {
            weekDaysArray.push({
                //generate new random key for every object
                "key": Math.round(Math.random() * 10000),
                "text": t(`${TRANSLATION_PREFIX}${element.toLowerCase()}`),
                //values need to be MON, TUE, WED...for when the cron expression is created
                "value": element.substring(0, 3).toUpperCase()
            })
        });
        weekDaysArray.filter(weekDay => this.state.selectedOption.daysOfWeek.includes(weekDay.value)).forEach(element => {
            element.checked = true;
        })
        return weekDaysArray;
    }

    renderInvalidWarning = () => {
        const { t } = this.props;
        const { selectedOption } = this.state;
        return <span className="text-danger">{t(`${TRANSLATION_PREFIX}invalid`, { label: t(`${TRANSLATION_PREFIX}${selectedOption.value}Sub`) })}</span>
    }

    renderWeeklyOption = () => {
        let daysArray = this.createDaysArray()
        return (
            <>
                <TimePicker
                    allowEmpty={false}
                    value={this.state.timePicker}
                    showSecond={false}
                    use12Hours
                    onChange={(value) => {
                        this.updateSelectedOption(value, true, 1)
                    }}
                />
                <span>{this.state.selectedOption.secondSubText}</span>
                <Select
                    multiple
                    options={daysArray}
                    getValue={(value) => {
                        this.updateSelectedOption(value, false, 2)
                    }}
                />
            </>
        )
    }

    renderMinutesOption = () => {
        const { selectedOption } = this.state;
        return (
            <>
                <MDBInput
                    type="number"
                    className="d-inline-block"
                    value={selectedOption.minutesRepeater}
                    onChange={(event) => {
                        this.updateSelectedOption(event.target.value)
                    }}
                />
                <span>{selectedOption.subText}</span>
            </>
        )
    }

    renderMonthlyOption = () => {
        const { selectedOption } = this.state;
        return (
            <>
                <MDBInput
                    type="number"
                    min="1"
                    max="31"
                    className="d-inline-block"
                    value={selectedOption.dayNumber}
                    onChange={(event) => {
                        this.updateSelectedOption(event.target.value, false, 1)
                    }}
                />
                <div className="monthlyOptionMobile">
                    <span>{selectedOption.subText}</span>
                    <MDBInput
                        type="number"
                        min="1"
                        max="12"
                        className="d-inline-block"
                        value={selectedOption.monthRepeater}
                        onChange={(event) => {
                            this.updateSelectedOption(event.target.value, false, 2)
                        }}
                    />
                    <span>{selectedOption.secondSubText}</span>
                </div>
            </>
        )
    }

    renderDailyOption = () => {
        return (
            <>
                <TimePicker
                    allowEmpty={false}
                    value={this.state.timePicker}
                    showSecond={false}
                    use12Hours
                    onChange={(value) => {
                        this.updateSelectedOption(value, true)
                    }}
                />
            </>
        )
    }

    updateSelectedOption = (value, isTimePickerValue, part) => {
        var selectedOption = { ...this.state.selectedOption }
        let inputIsValid = true;
        switch (this.state.selectedOption.value) {
            case "minutes":
                selectedOption.minutesRepeater = value
                inputIsValid = (value <= 60 && value >= 10)
                break;
            case "daily":
                selectedOption.minutes = value.minutes()
                selectedOption.hours = value.hours()
                break;
            case "weekly":
                if (part === 1) {
                    selectedOption.minutes = value.minutes()
                    selectedOption.hours = value.hours()
                } else {
                    selectedOption.daysOfWeek = value
                }
                break;
            case "monthly":
                if (part === 1) {
                    selectedOption.dayNumber = value
                    inputIsValid = (value <= 31 && value >= 1)
                } else {
                    selectedOption.monthRepeater = value
                    inputIsValid = (value <= 12 && value >= 1)
                }
                break;
            default:
                break;
        }
        var cronExpression = QuartzCronFormatterBuild(selectedOption)
        selectedOption.cronExpression = cronExpression
        selectedOption.cronExpressionDescription = cronstrue.toString(
            cronExpression,
            { verbose: true, locale: i18next.languages[0].slice(0, 2) },
            { dayOfWeekStartIndexZero: false })
        selectedOption.valid = inputIsValid;
        this.setState({ selectedOption: selectedOption, timePicker: (isTimePickerValue ? value : this.state.timePicker) })
    }


    renderSelectedOption = () => {
        return this.state.selectedOption.render()
    }

    getInitialTimePickerValue = (time) => {
        return (time ? moment(`${moment().year()}-${moment().month() + 1}-${moment().day()} ${time}`) : moment())
    }

    render() {
        const { cronMainOptions, selectedOption } = this.state
        const { t } = this.props
        if (!selectedOption) {
            return (
                <div className="w-100 d-flex justify-content-center">
                    <MDBSpinner />
                </div>
            )
        }
        return (
            <div className="cronExpressionBuilder">
                <div className="mainSelector">
                    <Select
                        value={selectedOption}
                        options={cronMainOptions}
                        getValue={(val, event) => {
                            if (val.length > 0) {
                                let selectedOption = this.selectedOptionWithCronExpression(this.state.cronMainOptions.filter(option => option.value === val[0])[0])
                                this.setState({ selectedOption: selectedOption, timePicker: this.getInitialTimePickerValue() })

                            }
                        }}
                    />
                </div>
                <div className="sendEveryContainer">
                    {selectedOption.prefix}
                    <div className="everyInputContainer">
                        {this.renderSelectedOption()}
                    </div>
                </div>
                {!selectedOption.valid && this.renderInvalidWarning()}
                {this.props.showDescription && selectedOption.cronExpressionDescription && selectedOption.valid &&
                    <span className="mt-3">{t(`${TRANSLATION_PREFIX}campaignWillBeSent`, { text: selectedOption.cronExpressionDescription.toLowerCase() })}</span>
                }
            </div>
        )
    }
}
