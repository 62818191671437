import React, { useState, useEffect } from 'react';
import { MDBBtn } from 'mdbreact';
import { activateStores } from '../../../api/storeApi.js'
import { translate } from 'react-i18next';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useCompanyAvailableStores } from '../../utils/useCompanyAvalaibleStores.js';
import Loading from '../utils/Loading.js';
import { HeaderAlert } from 'botbit-ui-components'
import './ReactivateStores.scss';

const ReactivateStores = ({ t, toggle }) => {
    const width = window.innerWidth;
    const isMobile = width < 992;
    const [stores, setStores] = useState(null);
    const [selectedStores, setSelectedStores] = useState(null);
    const reduxStores = useCompanyAvailableStores();

    useEffect(() => {
        if (reduxStores && reduxStores.length) {
            let suspendedStores = reduxStores;
            suspendedStores = suspendedStores.filter(s => s.status === 'SUSPENDED');
            setStores(suspendedStores);
            setSelectedStores(Array(suspendedStores.length).fill(true));
        }
    }, [reduxStores]);

    const selectStore = (selectedStores, idx) => {
        const _selectedStores = [...selectedStores];
        _selectedStores[idx] = !_selectedStores[idx];
        return _selectedStores;
    }

    const swal = withReactContent(Swal);
    const reactivateStores = () => {

        let storeIdsToReactivate = [];
        selectedStores.forEach((storeIsSelected, idx) => {
            if (storeIsSelected) storeIdsToReactivate.push(stores[idx].id);
        });
        activateStores(storeIdsToReactivate)
            .then(() => {
                // let _stores = [...stores];
                // selectedStores.forEach((storeIsSelected, idx) => {
                //     if (storeIsSelected) _stores[idx].status = 'ACTIVE';
                // });
                // dispatch(setAvailableStores(_stores));
                toggle(false);
                swal.fire({
                    type: 'success',
                    title: t("reactivate.success"),
                    text: t("reactivate.successMessage")
                });
            })
            .catch(() => {
                swal.fire({
                    type: 'error',
                    title: t("reactivate.error"),
                    text: t("reactivate.errorMessage")
                });
            });

    }

    return (
        <>
            <Loading loading={!stores} />
            {stores && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                <div id="main-reactivate" style={(isMobile && { marginBottom: `-400px` }) || {}}>
                    <div>
                        <HeaderAlert className="alert-box alert alert-info"><span style={{ fontWeight: "bold", fontSize: '1.5em' }}>{t("reactivate.billingWillBeBack")}</span></HeaderAlert>
                    </div>
                    <div id="storesContainer">
                        {stores && selectedStores && stores.map((s, i) => {
                            return (<div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                    className="custom-control-input"
                                    id={`checkbox${i}`}
                                    checked={selectedStores[i]}
                                    onClick={() => setSelectedStores(selectStore(selectedStores, i))}
                                    key={i} />
                                <label className="custom-control-label checkbox-label" for={`checkbox${i}`}>{s.name}</label>
                            </div>
                            )
                        })}
                    </div>
                    <div id="push"></div>
                </div>
                <div style={(isMobile && { position: 'fixed', bottom: 0 }) || {}} id="reactivateButtonContainer">
                    <MDBBtn style={(isMobile && { width: '100vw', margin: 0, height: '50px', opacity: '1.0' }) || {}} color="primary" onClick={() => reactivateStores()} disabled={selectedStores && selectedStores.every(s => s === false)}>{t("reactivate.reactivate")}</MDBBtn>
                </div>
            </div>}
        </>
    );
}

export default (translate('common')(ReactivateStores));