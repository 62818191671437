import React, {useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { translate } from 'react-i18next';
import { useSelector } from "react-redux";
import './SuspendedStore.scss';

const SuspendStore = ({t}) => {
    const {storeId} = useParams();
    const availableStores = useSelector(state => state.application.availableStores);
    const [store, setStore] = useState(null);
    const [storeIsActive, setStoreIsActive] = useState(false);

    useEffect(() => {
        if (availableStores) {
            setStore(availableStores.find(x => x.id.toString() === storeId));
        }
    }, [availableStores]);

    useEffect(() => {
        if (store) {
            setStoreIsActive(store.status === 'ACTIVE');
        }
    }, [store]);

    return (
        <div id="main-container">
            <div>
                {t("suspend.suspension")}
            </div>
            {store && storeIsActive &&
                <div id="inner-container">
                    {t("suspend.toSuspend", {storeName: store.name})}<a href="mailto:help@botbit.io">help@botbit.io</a>
                </div>
            }
            {store && !storeIsActive &&
                <div id="inner-container">
                     {t("suspend.cantSuspend", {storeName: store.name})}
                </div>
            }
        </div>
    )
}

export default translate('common')(SuspendStore)