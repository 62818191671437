import React, { useState, useEffect } from "react";
import { getLocations } from "../../api/socialAccountsApi";
import { MDBListGroup, MDBListGroupItem } from "mdbreact";
import SocialLocation from "./SocialLocation";
import { useDispatch } from "react-redux";
import { setLoading } from "../../commons/components/application/miscActions";

const SocialAccount = ({t, socialAccountId}) => {

    const dispatch = useDispatch()

    const [socialLocations, setSocialLocations] = useState(undefined)

    useEffect(() => {        
        const fetchLocations = async () => {
            dispatch(setLoading(true))
            const locations = await getLocations(socialAccountId)
            setSocialLocations(locations.data.data)
            dispatch(setLoading(false))
        };
        fetchLocations()
    }, [socialAccountId])
    
    if (socialLocations && socialLocations.length > 0) {
        return (
            <MDBListGroup>
            {socialLocations && socialLocations.map(l => <MDBListGroupItem><SocialLocation location={l} t={t} /></MDBListGroupItem>)}
        </MDBListGroup>
    )  
    } else {
        return <p style={{padding: "1rem"}}>{t("socialAccounts.noMatchedAccounts")}</p>
    }
    
}

export default SocialAccount