export const translations = {
    title: {
        es: "Panel de control",
        en: "Control Panel"
    },
    store: {
        es: "Tiendas",
        en: "Stores"
    },
    timePeriod: {
        es: "Periodo",
        en: "Period"
    },
    allStores: {
        es: "Todas las tiendas",
        en: "All stores"
    },
    emptyStateMsg: {
        es: "No hay información suficiente. Prueba ordenando por otro criterio.",
        en: "Not enough information. Try ordering by another criteria."
    },
    exportCSV: {
        es: "Exportar CSV",
        en: "Export CSV"
    },
    company: {
        es: "Empresa",
        en: "Company"
    },
    totalConversations: {
        es: "Conversaciones",
        en: "Conversations"
    }, 
    adminName: {
        es: "Nombre",
        en: "Name"
    },
    totalMessagesSent: {
        es: "Mensajes enviados",
        en: "Messages sent"
    },
    totalUniqueUsers: {
        es: "Clientes atendidos",
        en: "Customers served"
    },
    externalAdmin: {
        es: "Aplicación",
        en: "Application"
    }
}