import React from 'react'
import { MDBInput } from 'mdbreact';
import { Select } from "botbit-ui-components"
import { Field } from 'formik';
import './CampaignFromAddress.css';
const TRANSLATIONS_PREFIX = "panels.campaigns.minimalEditor."

export default class CampaignFromAddress extends React.Component {

    componentDidUpdate(newProps) {
        if (JSON.stringify(this.props.campaign) !== JSON.stringify(newProps.campaign)) {
            this.setState({ campaign: newProps.campaign })
        }
    }

    handleChange = (fieldName) => (val) => {
        let campaignEdited = {
            ...this.state.campaign,
            [`${fieldName}`]: val.target.value
        }
        this.setState({
            campaign: campaignEdited
        })
    }

    fromEmailIsCustom = (props) => props.form.values.campaign.fromEmailIsCustom

    renderInputFrom = (props) => {
        if (props.form.values.campaign.fromEmailIsCustom === undefined) {
            const formikState = props.form.values;
            props.form.setFieldValue("campaign.fromEmailIsCustom", (formikState.campaign.fromAddress !== formikState.campaign.company.fromEmail))
            return null
        }
        const fromErrors = props.form.errors && props.form.errors.campaign && props.form.errors.campaign.fromAddress;
        return <div className="d-inline-block w-100">
            <Select
                id="fromEmailSelector"
                options={[
                    {
                        text: this.props.t("panels.campaigns.minimalEditor.fromMore.useFromCompanyEmail"),
                        value: false,
                        checked: !this.fromEmailIsCustom(props)
                    },
                    {
                        text: this.props.t("panels.campaigns.minimalEditor.fromMore.useCustomEmail"),
                        value: true,
                        checked: this.fromEmailIsCustom(props)
                    }
                ]}
                selected={this.props.t('panels.campaigns.minimalEditor.fromMore.select')}
                getValue={(values) => {
                    const newFromEmailIsCustom = values[0];
                    if (newFromEmailIsCustom !== undefined) {
                        const newFromAddress = (newFromEmailIsCustom ? "" : props.form.values.campaign.company.fromEmail);
                        props.form.setFieldValue(props.field.name, newFromAddress);
                        props.form.setFieldValue("campaign.fromEmailIsCustom", values[0]);
                        if (this.props.formik.state.values.campaign.toEmailType === "fromEmail") {
                            props.form.setFieldValue("campaign.replyToAddress", newFromAddress);
                        }
                    }
                }}
            />
            <div>
                {(this.props.formik !== null &&
                    this.props.formik.state &&
                    this.props.formik.state.values.campaign.fromEmailIsCustom) ? <>
                        <MDBInput
                            name={props.field.name}
                            value={props.field.value}
                            className={fromErrors ? "error" : ""}
                            type="text"
                            onChange={e => {
                                if (props.field.value !== e.target.value) {
                                    if (props.form.values.campaign.toEmailType === "fromEmail") {
                                        props.form.setFieldValue("campaign.replyToAddress", e.target.value);
                                    }
                                    props.form.setFieldValue(props.field.name, e.target.value);
                                }
                            }}
                        />
                        {fromErrors && <label>{fromErrors}</label>}
                    </> : <span className="fromEmailCompanyLabel">{props.form.values.campaign.company.fromEmail}</span>}
            </div>
        </div>
    }

    render() {
        return (
            <div className="campaignFromToContainer">
                <div className="fromOrTo">
                    <span className="d-inline-block float-left fromToLabel">
                        {this.props.t(TRANSLATIONS_PREFIX + "from")}
                    </span>
                    <Field
                        name={"campaign.fromAddress"}
                        render={(props) => {
                            if (props.form.values.campaign.fromEmailIsCustom === undefined) {
                                const formikState = props.form.values;
                                props.form.setFieldValue("campaign.fromEmailIsCustom", (formikState.campaign.fromAddress !== formikState.campaign.company.fromEmail))
                            }
                            return (
                                <>
                                    {this.props.isEditing ?
                                        this.renderInputFrom(props) :
                                        <span className="d-inline-block font-weight-normal fromToMessage">{this.fromEmailIsCustom(props) ? props.field.value : props.form.values.campaign.company.fromEmail}</span>

                                    }
                                </>
                            )
                        }}
                    />
                </div>
            </div>
        )
    }

}
