export const translations = {
  title: {
    es: "Conecta con",
    en: "Connect with"
  },
  primaryBtn: {
    es: "Conectar",
    en: "Connect"
  },
  cancelBtn: {
    es: "Cancelar",
    en: "Cancel"
  },
  errors: {
    required: {
        es: "El campo es requerido",
        en: "This field is required"
    }
  },
}