import React from 'react';
import CompanyForm from './CompanyForm.js';
import { getCompany, updateCompany } from '../../api/companyApi.js';
import { getImagesForCompany } from '../../api/companyApi';
import { translate } from 'react-i18next';


class CompanyContainer extends React.Component {


	state={}

	componentDidMount() {
		
		if (this.props.companyId) {
			getImagesForCompany(this.props.companyId)
				.then(
					(responseImages) => 
					{
						this.setState({
							images: responseImages.data.data  ?  responseImages.data.data : []
						})
					}
				);
			getCompany(this.props.companyId)
				.then(
					res =>{
						this.setState({
							company: res.data.data
						})
					}
				)
		}
		else
			this.setState({
				company: {},
				images: []
			});
	}

	onSubmit = (company) => {
		console.log("company to save onSubmit", company)
		updateCompany(company)
			.then(
				res => {
					this.setState({
						company: res.data.data
					})
					if (this.props.onSubmit)
						this.props.onSubmit(company);
				}
			);

	}

	render(){
		const {company} = this.state;
		if (!company) return null;
		return (
			<div>
				<CompanyForm
					inline={this.props.inline}
					company={company}
					onSubmit={this.onSubmit}
					images={this.state.images}
					t={this.props.t} 
					readOnly={this.props.readOnly}
					admin={this.props.admin}
				></CompanyForm>
			</div>
		)
	}
}

export default translate("common")(CompanyContainer)
