import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./MessengerWrapper.scss"
import {
    getConversations, getMessagesFromConversaion, getSourceAccounts, sendMessage, markConversationAsClosed, markConversationAsOpen, sendMessageFile,
    assignConversation, unassignConversation, getConversationById
} from '../../api/messengerApi';
import { getAllStoreAdministratorsWithOperation } from '../../api/administratorApi';
import { SocketMessageType, useChatStompClient } from './useChatStompClient';
import _ from 'lodash'
import { toast } from 'botbit-ui-components'
import  {rejectUpdateByIssueState, rejectUpdateByIssueType, rejectUpdateByUserName, rejectUpdateByUserPhone, rejectUpdateByAssignedOperator, ASSIGMENT_FILTER_VALUES} from "./FiltersUtils"
import { setLoading } from '../../commons/components/application/miscActions';



export const MessengerContext = React.createContext()

export function getMessengerData(conversationId) {
    const [chatsList, setChatList] = useState([]);
    const [selectedChat, setSelectedChat] = useState()
    const [conversationOpen, setConversationOpen] = useState(false);
    const [arrivedMsg, setArrivedMsg] = useState();
    const [selectedStore, setSelectedStore] = useState(null);
    const [messages, setMessages] = useState([]);
    const [storeSocialAccounts, setStoreSocialAccounts] = useState([]);
    const [arrivedUpdate, setArrivedUpdate] = useState();
    const [updatedMessage, setUpdatedMessage] = useState();
    const [selectedImages, setSelectedImages] = useState([]);
    const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
    const [isImagePreviewerOpen, setIsImagePreviewerOpen] = useState(false);
    const [previewingImage, setPreviewingImage] = useState({})
    const [isAppLoading, setIsAppLoading] = useState(false);
    const [administrators, setAdministrators] = useState([]);
    const [conversationIssue, setConversationIssue] = useState();
    const [canCreateConversationOnSelectedStore, setCanCreateConversationOnSelectedStore] = useState(false);

    const admin = useSelector(state => state.login.admin);

    const dispatch = useDispatch();

    const adminHasAssignerOperation = admin.operations.includes("CONVERSATION__ASSIGN");
    const adminHasAutoassignerOperation = admin.operations.includes("CONVERSATION_UNASSIGNED__VIEW");
    const [conversationFilterValues, setConversationFilterValues] = useState({ types: [], states: [], userName: [""], userPhone: [""], assignedTo: adminHasAssignerOperation ? [] : (adminHasAutoassignerOperation ? [ admin.id.toString(), 'UNASSIGNED'] : [admin.id.toString()]), conversationState: ["OPEN"] })


    useEffect(() => {
        if (selectedStore && selectedStore.id) {
            setConversationsList({ cleanList: true });
        }
    }, [selectedStore ? selectedStore.id : undefined, JSON.stringify(conversationFilterValues)])

    useEffect(() => {
        if (selectedStore && selectedStore.id) {
            getAdministratorsAndPutThemInState()
        }
    }, [selectedStore ? selectedStore.id : undefined])

    useEffect(() => {
        if (selectedStore && selectedStore.id) {
            setSourceAccounts()
        }
    }, [selectedStore ? selectedStore.id : undefined])


    const selectedChatId = selectedChat ? selectedChat.id : undefined;
    useEffect(() => {
        if (selectedChatId) {
            // getConversationById(selectedChatId)
            //     .then(res => {
            //         setSelectedChat(res.data.data)
            //     });
            getMessages()
        } else {
            setMessages([])
        }
    }, [selectedChatId])

    useEffect(() => {
        if (arrivedMsg) {
            setMessages((oldMessages) => {
                return [
                    ...oldMessages,
                    arrivedMsg
                ]
            })
        }
    }, [arrivedMsg])

    useEffect(() => {
        if (updatedMessage) {
            setMessages((oldMessages) => {

                return oldMessages.map(om => {
                    if (om.id === updatedMessage.id)
                        return updatedMessage;
                    return om;
                })
            })
        }
    }, [updatedMessage])

    useEffect(() => {
        if (conversationId) {
            getConversationById(conversationId)
                .then(res => {
                    openChat(res.data.data)
                    setSelectedStore(res.data.data.store)
                });
        } else {
            closeChat();
        }
    }, [conversationId])

    /**
    * Determina el estado de las conversaciones a mostrar en base al tab seleccionado
    */
    const getSelectedConversationState = () => {
        return conversationFilterValues.conversationState[0] || 'OPEN';
        //return selectedTab === 0 ? "OPEN" : "CLOSED";
    }

    /**
     * Determina si una actualización de conversación que llega por socket tiene que ser procesada
     * en base a los filtros seleccionados
     * @param {*} socketArrivedConverasationUpdate mensaje de conversation_update que llega por socket
     */
    const aceptUpdate = (socketArrivedConverasationUpdate) => {
        const state = getSelectedConversationState();
        // TODO: esto hay que cambiarlo por los filtros nuevos
        //const showOnlyMyAssigments = false;
        //const showOnlyUnassigned = false;
        // const showOnlyMyAssigments = !adminHasAssignerOperation || conversationFilterValues.assignedTo.values[0]==='ASSIGNED_TO_ME';
        // const showOnlyUnassigned = adminHasAssignerOperation && conversationFilterValues.assignedTo.values[0]==='UNASSIGNED';
        if (socketArrivedConverasationUpdate.state !== state) {
            socketArrivedConverasationUpdate.toastMessage = "La conversación cambió de estado";
            return false;

        }
        const rejectUpdateByAssignedOperatorResult = rejectUpdateByAssignedOperator(
            conversationFilterValues.assignedTo,
            socketArrivedConverasationUpdate.currentAssignment,
            admin);
        if(rejectUpdateByAssignedOperatorResult){
            socketArrivedConverasationUpdate.toastMessage = rejectUpdateByAssignedOperatorResult;
            return false;
        }

        if(rejectUpdateByIssueState(conversationFilterValues.states, socketArrivedConverasationUpdate.issues) ||
             rejectUpdateByIssueType(conversationFilterValues.types, socketArrivedConverasationUpdate.issues) || 
             rejectUpdateByUserName(conversationFilterValues.userName, socketArrivedConverasationUpdate.user) ||
             rejectUpdateByUserPhone(conversationFilterValues.userPhone, socketArrivedConverasationUpdate.user) )
             return false;
        return true;

    }

    useEffect(() => {
        if (arrivedUpdate) {
            if (aceptUpdate(arrivedUpdate))
                addOrUpdateConversation(arrivedUpdate)
            else
                removeConversation(arrivedUpdate);
        }
    }, [JSON.stringify(arrivedUpdate)])

    /**
     * Elimina de la lista de chats el que coincida por id con conversationToRemove
     * También borra la selección en caso de que sea la conversación seleccionada actualmente
     * @param {*} conversationToRemove un update de conversación que llega por socket
     */
    const removeConversation = (conversationToRemove) => {
        if (chatsList.length === 0) return;
        const newChatList = _.filter(chatsList, (chat) => chat.id !== conversationToRemove.id);
        if (chatsList.length > newChatList.length) {
            setChatList(newChatList);
            if (selectedChat && selectedChat.id === conversationToRemove.id) {
                closeChat();
                if(conversationToRemove.toastMessage)
                    toast(conversationToRemove.toastMessage);
            }
        }
    }

    useEffect(() => {
        if (selectedChat && !conversationOpen)
            setConversationOpen(true);
    }, [selectedChat])

    const getMessages = async () => {
        setMessages([])
        const paramObj = {
            conversationId: selectedChat.id,
            lastMessageTimestamp: new Date(),
            totalResults: 50
        }
        const response = await getMessagesFromConversaion(paramObj);
        const messages = response.data.data;
        setMessages(messages)
    }

    const addMessage = (data) => {
        const parsedData = JSON.parse(data);
        setArrivedMsg(parsedData.payload);
    }

    const addOrUpdateConversation = body => {
        // Primero checkamos si la conversación existe y si es necesario actualizarla
        let chatToUpdateIndex;
        const chatToUpdate = chatsList.find((chat, i) => {
            if (chat.id === body.id) {
                chatToUpdateIndex = i;
                return true;
            }
            return false;
        });

        if (chatToUpdate && chatToUpdateIndex !== undefined) {
            const updatedChat = {
                ...chatToUpdate,
                ...body
            }

            let updatedChatsList = [...chatsList];
            if (arrivedUpdate && arrivedUpdate.lastMessageTimestamp <= chatsList[0].lastMessageTimestamp) {
                updatedChatsList[chatToUpdateIndex] = updatedChat
            } else {
                updatedChatsList.splice(chatToUpdateIndex, 1)
                updatedChatsList = [
                    updatedChat,
                    ...updatedChatsList
                ];
            }

            setChatList(updatedChatsList)

            if (selectedChat && selectedChat.id === updatedChat.id)
                setSelectedChat(updatedChat);
        }

        else {
            // Si no existe la conversación se agrega a la lista tal cual viene del socket
            setChatList(previousChatList => {
                return [
                    body,
                    ...previousChatList
                ]
            })
        }
    }

    const stompClient = useChatStompClient(selectedChat, selectedStore ? selectedStore.id : undefined, admin.id, {
        conversationTopicMessageHandler: (body) => {
            const parsedData = JSON.parse(body);
            const payload = parsedData.payload;
            if (parsedData.type === SocketMessageType.MESSAGE_CREATED)
                setArrivedMsg(payload);
            if (parsedData.type === SocketMessageType.MESSAGE_UPDATE)
                setUpdatedMessage(payload);
            if (parsedData.type === SocketMessageType.ISSUE_UPDATE)
                setConversationIssue(payload);
            if (parsedData.type === SocketMessageType.CONVERSATION_UPDATE)
                setArrivedUpdate(payload);
        },
        storeTopicMessageHandler: (body) => {
            const parsedUpdate = JSON.parse(body)
            const parsedFormatted = parsedUpdate.payload
            setArrivedUpdate(parsedFormatted)
        }
    });

    const send = async (message, file, onMessageSent) => {

        if (message.type === 'TEXT' && !message.message) return;
        if (message.type === 'MULTIMEDIA' && !file) return;

        let sendMsgResponse;
        if (file !== null) {
            sendMsgResponse = (await sendMessageFile(message, file)).data.data;
            //console.log("useMessengerData.send", { sendMsgResponse, message, file })
        } else {
            sendMsgResponse = (await sendMessage(message)).data.data
            //console.log("useMessengerData.send", { sendMsgResponse })
        }
        if (onMessageSent) {
            onMessageSent(sendMsgResponse);
        }
    }

    const setConversationsList = async (options = {}) => {
        const lastMessageTimestampFilter = !options.cleanList && chatsList && chatsList.length > 0 ?
            new Date(chatsList[chatsList.length - 1].lastMessageTimestamp) :
            new Date();
        if (selectedStore) {
            dispatch(setLoading(true))

            let assignmentFilterValue = conversationFilterValues.assignedTo.filter(f => !isNaN(f))
            let assignmentFilterIsFixedOptionSelected = (!!!conversationFilterValues.assignedTo.length 
                || (administrators.length && conversationFilterValues.assignedTo.length === administrators.length + 1)) ?
                ASSIGMENT_FILTER_VALUES.ALL : conversationFilterValues.assignedTo.includes(ASSIGMENT_FILTER_VALUES.UNASSIGNED) ?
                ASSIGMENT_FILTER_VALUES.UNASSIGNED : ASSIGMENT_FILTER_VALUES.SPECIFIC_ADMINISTRATOR;
            //assignmentFilterValue === "ALL" || assignmentFilterValue === "ASSIGNED_TO_ME" || assignmentFilterValue === "UNASSIGNED";

            const paramObj = {
                storeId: selectedStore.id,
                sort: "RECENT_FIRST",
                state: getSelectedConversationState(),
                lastMessageTimestamp: lastMessageTimestampFilter,
                totalResults: 20,
                assignmentFilter: assignmentFilterIsFixedOptionSelected,
                assignedAdminId: assignmentFilterValue.length === 0 ? undefined : assignmentFilterValue,
                userPhone: conversationFilterValues.userPhone.length > 0 ? conversationFilterValues.userPhone : undefined,
                userName: conversationFilterValues.userName.length > 0 ? conversationFilterValues.userName : undefined,
                issueTypes: conversationFilterValues.types,
                issueStates: conversationFilterValues.states
            }
            const conversations = (await getConversations(paramObj)).data.data
            setChatList(options.cleanList ? conversations : [...chatsList, ...conversations]);
            dispatch(setLoading(false))
        } else {
            setChatList([]);
        }
        if (options.onLoadFinish)
            options.onLoadFinish();
    }

    const setSourceAccounts = async () => {
        const paramObj = {
            storeId: selectedStore.id,
            category: "CONVERSATION"
        }
        const sourceAccountsResponse = (await getSourceAccounts(paramObj)).data.data;
        setCanCreateConversationOnSelectedStore(sourceAccountsResponse.some(s => s.source.id === 17))
        setStoreSocialAccounts(sourceAccountsResponse);
    }

    const getAdministratorsAndPutThemInState = async () => {
        const admins = (await getAllStoreAdministratorsWithOperation(selectedStore.id, "CONVERSATION__REPLY")).data.data;
        setAdministrators(admins)
    }

    const openChat = chat => {
        getConversationById(chat.id)
        .then(res => {
            setSelectedChat(res.data.data)
            setConversationOpen(true)
        })
    }

    const closeChat = () => {
        setSelectedChat(null);
        setConversationOpen(false);
    }

    const createNewConversation = () => {
        setSelectedChat({
            storeId: selectedStore.id
        });
        setConversationOpen(true);
    }

    const openImageInImageViewer = (imgScr) => {
        setSelectedImages([imgScr])
        setIsImageViewerOpen(true)
    }

    const closeImageInImageViewer = () => {
        setIsImageViewerOpen(false);
        setSelectedImages([])
    }

    const chatController = () => ({
        setActionResponse: (msg) => {
            let formattedMsg = {}
            let file;
            if (msg.type === "MULTIMEDIA") {
                file = msg.file;
            }

            //console.log("useMessengerData.chatController msg", msg)

            var attachmentProps = file ? { attachment: {
                contentType: msg.contentType,
                fileName: msg.fileName
            }} : {};

            if (selectedChat) {
                if (selectedChat.id) {
                    formattedMsg = {
                        ...formattedMsg,
                        conversationId: selectedChat.id,
                        type: msg.type,
                        attachType: msg.attachType,
                        message: msg.value,
                        ...attachmentProps
                    }
                    send(formattedMsg, file)
                } else {
                    formattedMsg = {
                        ...selectedChat,
                        type: msg.type,
                        attachType: msg.attachType,
                        message: msg.value,
                        ...attachmentProps
                    }
                    send(formattedMsg, file, (m) => {
                        setSelectedChat(m.conversation);
                    });
                }
            }
        },
        setActionRequest: () => { }
    })

    const assignConversationWrapper = (conversationId, targetAdminId, comment, notifyUser) => {
        return assignConversation(conversationId, targetAdminId, comment, notifyUser);
    }

    const unassignConversationWrapper = (conversationId, comment) => {
        return unassignConversation(conversationId, comment)
    }

    return {
        conversationOpen, setConversationOpen,
        chatsList, setChatList,
        selectedChat, setSelectedChat,
        arrivedMsg, setArrivedMsg,
        arrivedUpdate, setArrivedUpdate,
        selectedStore, setSelectedStore,
        messages, setMessages,
        storeSocialAccounts, setStoreSocialAccounts,
        getMessages,
        addMessage,
        addOrUpdateConversation,
        stompClient,
        send,
        setConversationsList,
        setSourceAccounts,
        openChat,
        closeChat,
        createNewConversation,
        markConversationAsClosed: () => { markConversationAsClosed(selectedChat.id) },
        markConversationAsOpen: () => { markConversationAsOpen(selectedChat.id) },
        selectedImages, setSelectedImages,
        isImageViewerOpen, setIsImageViewerOpen,
        openImageInImageViewer, closeImageInImageViewer,
        isImagePreviewerOpen, setIsImagePreviewerOpen,
        previewingImage, setPreviewingImage,
        chatController,
        isAppLoading, setIsAppLoading,
        administrators,
        assignConversationWrapper, unassignConversationWrapper,
        conversationIssue, setConversationIssue,
        conversationFilterValues, setConversationFilterValues,
        canCreateConversationOnSelectedStore
    }
}

export function useMessengerData() {
    const context = React.useContext(MessengerContext);

    if (!context) {
        throw new Error("useData must be used within a <Parent />");
    }

    return context;
}