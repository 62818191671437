import React from "react"
import { SocialSource } from '../../commons/enums/SocialSource'
import { envConfig } from "../../config/config"

const SocialSourceIcon = ({ site }) => <img src={getSiteIconUrl(site)} width={35} height={35} alt={getSiteName(site)} />

export const getSiteIconUrl = (site) => {
    let src = null
    if (site === SocialSource.BOTBIT)
        src = (envConfig.staticImgUrl + "RAY-Brand-Iso-Red.png")
    else if (site === SocialSource.GOOGLE)
        src = '/static/img/social_logos/google_logo.png'
    else if (site === SocialSource.FACEBOOK)
        src = '/static/img/social_logos/facebook_logo.png'
    else if (site === SocialSource.GMB)
        src = '/static/img/social_logos/gmb_logo.png'
    else if (site === SocialSource.GMAIL)
        src = '/static/img/social_logos/gmail_logo.png'

    return src
}

export const getSiteName = (site) => {
    if (site === SocialSource.BOTBIT)
        return envConfig.companyName
    else if (site === SocialSource.GOOGLE)
        return 'Google'
    else if (site === SocialSource.FACEBOOK)
        return 'Facebook'
    else if (site === SocialSource.GMB)
        return 'Google My Business'
    else if (site === SocialSource.GMAIL)
        return 'GMail'
    else
        return 'unknown'
}

export default SocialSourceIcon
