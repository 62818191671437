import { MDBIcon } from 'mdbreact';
import React from 'react';
import ReactTooltip from 'react-tooltip';

const defaultTitleStyle = { marginBottom: '10px', fontWeight: 'bolder', textAlign: 'left' }
const defaultHelpSectionStyle = { marginBottom: '0px', fontWeight: 'normal', maxWidth: '250px' }

export const SectionTitle = ({ title, help: helpTexts, name, titleStyle }) => {

	return <div className="d-inline">
		<div style={{ ...defaultTitleStyle, ...titleStyle }}>{title}
			{helpTexts && helpTexts.length > 0 && <>
				<MDBIcon
					data-tip
					data-for={name}
					className="ml-1 text-info"
					icon="exclamation-circle"
				/>
				<ReactTooltip
					id={name}
					effect="solid"
					overridePosition={(e) => {
						let position = { ...e };
						if (window.innerWidth < 991) {
							position = {
								left: 15,
								top: e.top,
							};
						}
						return position;
					}}
				>
					{
						helpTexts.map((help, index) => <React.Fragment key={`tooltip-${index}`}>
							<p style={defaultHelpSectionStyle}>{help}</p>
							{(index < helpTexts.length - 1) && <br />}
						</React.Fragment>)
					}
				</ReactTooltip>
			</>}
		</div>
	</div>
}