import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBBadge } from "mdbreact";
import { sourcesTranslations } from "../../../config/translations/integrations/Sources";
import { translations } from "../../../config/translations/integrations/SourceCard";
import translate from "../../../config/translations/translate";
import {someStoreConnected} from "../IntegrationsHelper";
import "./SourceThumbnail.scss";
import {Image} from "botbit-ui-components";

const SourceThumbnail = ({ source, t, openCardModal }) => {
  const { logoAbsolute, name } = source;
  
  return (
    <MDBCard className="source-thumbnail" onClick={openCardModal} >
      <div className="source-thumbnail_image-container">
      <Image scaleFixedSize={{width: "93%", height: "140px"}} className="source-image" src={logoAbsolute}></Image>
      </div>
      <MDBCardBody className="thumbnail-body">
        <MDBCardTitle className="d-flex align-items-center">
          {`${t(`sources.${name}.name`)} - ${t(`sources.${name}.category`)}`}
        </MDBCardTitle>
        {(someStoreConnected(source)) && <MDBBadge
          color={("green")}
          className="status-badge">
          {t(`sources.status.CONNECTED`)}
        </MDBBadge>}
      </MDBCardBody>
    </MDBCard>
  );
}

SourceThumbnail.translations = {
  ...sourcesTranslations,
  ...translations
}

export default translate(SourceThumbnail);