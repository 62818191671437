export const SegmentFiltersInterpreter = (t, segment, part) => {
    let result;
    switch (part) {
        case "age":
            let ageFilters = segment.filters.filter(filter => filter.function.name === "age")
            result = t('segmentFilters.age.all')
            if(ageFilters.length > 0 && ageFilters[0].operatorValues !== undefined && ageFilters[0].operatorValues[0] !== undefined){
                let unprocessedAgeRange = ageFilters[0].operatorValues[0]
                let processedAgeRange = [
                    unprocessedAgeRange.substr(0, unprocessedAgeRange.indexOf('--')), 
                    unprocessedAgeRange.substr(unprocessedAgeRange.indexOf('--') + 2, unprocessedAgeRange.length-1)
                ]
                result = processedAgeRange[0] + ' - ' + processedAgeRange[1]
            }
            return result
        case "gender":
            let genderFilters = segment.filters.filter(filter => filter.filterAttribute && filter.filterAttribute.name === "Gender")
            result = t('segmentFilters.gender.all')
            if(genderFilters.length > 0 && genderFilters[0].operatorValues){
                if(genderFilters[0].operatorValues.length === 1){
                    result = t("segmentFilters.gender." + genderFilters[0].operatorValues[0])
                }
            }
            return result;
        default:
            break;
    }
}