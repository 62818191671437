import React, { useState, useEffect } from 'react';
import { getTranslationFunction } from "../../config/translations/translate";
import { translations } from "../../config/translations/newReservationForm";
import { Formik, Form, Field } from 'formik';
import { MDBInput, MDBDatePicker, MDBBtn, MDBTimePicker } from "mdbreact";
import { useCompanyAvailableStores } from '../../commons/utils/useCompanyAvalaibleStores';
import { Select } from "botbit-ui-components";
import * as Yup from 'yup';
import _ from "lodash";

const t = getTranslationFunction({ ...translations });

const sourceSelectOptions = [
	{ value: "1", text: "Whatsapp" },
	{ value: "2", text: "Email" },
	{ value: "3", text: "Llamado telefonico" },
	{ value: "4", text: "Restorando" },
	{ value: "5", text: "Otros" },
	{ value: "6", text: "Facebook" }
]

const typeSelectOptions = [
	{ value: "BIRTHDAY", text: "Cumpleaños" },
	{ value: "BACHELOR_PARTY", text: "Despedida" },
	{ value: "COUPLE", text: "Pareja" },
	{ value: "RESTORANDO", text: "Restorando" },
	{ value: "END_OF_STUDY", text: "Recibida" },
	{ value: "MATCH", text: "Partido" },
	{ value: "FRIENDS", text: "Reunión amigos" },
	{ value: "EVENT", text: "Evento" },
	{ value: "AFTER_OFFICE", text: "After Office" },
	{ value: "FRIENDS_DAY", text: "Día del Amigo" },
	{ value: "VOUCHER", text: "Voucher" },
	{ value: "SAINT_PATRICK", text: "San Patricio" },
	{ value: "MARKETING_ACTION", text: "Acción de Marketing" },
	{ value: "SUSTAINABILITY_ACTION", text: "Acción de Sustentabilidad" },
	{ value: "OTHER", text: "Otros" }
]

const formatOptions = (options, fieldValue) => {
	const formattedValues = options.map((option) => ({
		...option,
		checked: option.value === fieldValue
	}))
	return formattedValues;
}

const validationSchema = Yup.object().shape({
	date: Yup.date().required("Este campo es requerido"),
	type: Yup.string().min(3).required("Este campo es requerido"),
	source: Yup.number().required("Este campo es requerido"),
	store: Yup.object().required("Este campo es requerido"),
	groupSize: Yup.number().required("Este campo es requerido")
});

const initialValues = {
	date: new Date(),
	type: "",
	source: "",
	store: "",
	groupSize: "",
	location: ""
}

const DateTimePicker = ({ getValue, meta }) => {
	const [date, setDate] = useState();
	const [time, setTime] = useState()

	const setValue = () => {
		let dateTime = new Date(date);
		dateTime.setHours(time[0]);
		dateTime.setMinutes(time[1]);
		getValue(dateTime);
	}

	const updateDate = (value) => {
		setDate(value);
	}

	const updateTime = (value) => {
		const split = value.split(":");
		setTime(split);
	}

	useEffect(() => {
		if (date && time && time.length === 2) {
			setValue()
		}
	}, [date, time])

	return (
		<div className={`user-reservation-form-input`}>
			<MDBDatePicker
				id="datetime-local"
				label={t("date")}
				className="botbit-mdb-datepicker form-control botbit-form-control p-0 border-0"
				color="primary"
				type="date-local"
				// value={date}
				getValue={value => updateDate(value)}
				minDate={new Date()}
			/>
			<MDBTimePicker
				id="timePicker"
				label={t("time")}
				hours={time ? time[0] : undefined}
				minutes={time ? time[1] : undefined}
				type="date-local"
				color="primary"
				hoursFormat={24}
				getValue={value => updateTime(value)}
			/>
		</div>
	)
}

const UserReservationForm = ({ user, onSubmit }) => {
	const companyStores = useCompanyAvailableStores();

	const storeToOptions = () => {
		const storesFormatted = companyStores.map(store => ({
			text: store.name,
			value: { ...store }
		}))
		return [...storesFormatted];
	}

	const storeOptions = storeToOptions();

	useEffect(() => {
		// return formik.resetForm();
	}, [])

	return (
		<div className="user-reservation-form-container">
			<Formik
				onSubmit={(values, { resetForm }) => {
					onSubmit(values);
					resetForm();
				}}
				validationSchema={validationSchema}
				initialValues={_.cloneDeep(initialValues)}
			>
				{({ errors, touched }) => (
					<Form>
						<Field name="date" >
							{({ field, form, meta }) => (
								<DateTimePicker
									meta={{ ...meta }}
									getValue={value => {
										if (value && value !== field.value) {
											form.setFieldValue(field.name, value)
										}
									}}
								/>
							)}
						</Field>
						<Field name="type">
							{({ field, form, meta }) => (
								<div className={`user-reservation-form-input ${touched.type && errors.type ? "errors" : ""}`}>
									<Select
										label={t("type")}
										selected={t("type")}
										// value={field.value}
										options={formatOptions(typeSelectOptions, field.value)}
										search={true}
										color="red"
										getValue={(value) => {
											if (value[0] && field.value !== value[0]) {
												form.setFieldValue(field.name, value[0]);
											}
										}}
									/>
								</div>
							)}
						</Field>
						<Field name="source">
							{({ form, field, meta }) => (
								<div className={`user-reservation-form-input ${touched.source && errors.source ? "errors" : ""}`}>
									<Select
										label={t("source")}
										selected={t("source")}
										// value={field.value}
										options={formatOptions(sourceSelectOptions, field.value)}
										search={true}
										getValue={(value) => {
											if (value[0] && field.value !== value[0]) {
												form.setFieldValue(field.name, value[0]);
											}
										}}
									/>
								</div>
							)}
						</Field>
						<Field name="store">
							{({ field, form, meta }) => (
								<div className={`user-reservation-form-input ${touched.store && errors.store ? "errors" : ""}`}>
									<Select
										label="Seleccionar Tienda"
										selected={field.value ? field.value.name : ""}
										options={storeOptions}
										search={true}
										getValue={(value) => {
											if (value[0] && field.value !== value[0]) {
												form.setFieldValue(field.name, value[0]);
											}
										}}
									/>
								</div>
							)}
						</Field>
						<Field name="location">
							{({ field, form, meta }) => (
								<div className={`user-reservation-form-input ${touched.location && errors.location ? "errors" : ""}`}>
									<MDBInput
										label={t("location")}
										type="text"
										name={field.name}
										value={field.value}
										onChange={(event) => {
											if (field.value !== event.target.value) {
												form.setFieldValue(field.name, event.target.value);
											}
										}}
									/>
								</div>
							)}
						</Field>
						<Field name="groupSize" >
							{({ form, field, meta }) => (
								<div className={`user-reservation-form-input ${touched.groupSize && errors.groupSize ? "errors" : ""}`}>
									<MDBInput
										label={t("groupSize")}
										type="number"
										name={field.name}
										value={field.value}
										onChange={(event) => {
											if (field.value !== event.target.value) {
												form.setFieldValue(field.name, event.target.value);
											}
										}}
									/>
								</div>
							)}
						</Field>
						<MDBBtn
							color="primary"
							type="submit"
						>
							Continuar
				</MDBBtn>
					</Form>
				)}
			</Formik>
		</div>
	);
}

export default UserReservationForm;

