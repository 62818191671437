import axiosClient from './axiosClient'

export const processOptIn = (queryString) => {
    return axiosClient.post(`/sources/optin`, { queryString: queryString }, {errorHandler: (error) => ({ message: error.response.data.message, status: error.response.status, navigateOnOk: "/integrations" }) });
}

export const getOptInUrl = (providerName, companyId, params) => {
    return axiosClient.post(`/sources/optin/url`, { providerName: providerName, companyId: companyId, params: params });
}

export const getLocations = (id) => {
    return axiosClient.get(`/sources/accounts/${id}/locations`);
}
export const getAccount = (id) => {
    return axiosClient.get(`/sources/accounts/${id}`);
}

export const matchAccount = (account) => {
    //let _account = {...account};
    //_account.store = { id: account.storeId };
    return axiosClient.post(`/sources/accounts/match`, account, {errorHandler: () => ({ ignore: true })});
}

export const getSources = (companyId) => {
    // const dummyAnswer = {
    //     "data": {
    //             "data": {
    //                 "items": [
    //                     {
    //                         authorizationType: "OAUTH",
    //                         category: "ONLINE_ORDER",
    //                         configuration: {
    //                             documentation: {
    //                                 show: true,
    //                                 url: "https://app.intercom.com/a/apps/z0yssql1/articles/articles/4398089/show"
    //                             },
    //                             fields: [{id: "shopName", type: "text"}],
    //                             matching: {
    //                                 allowedTypes: ["ACCOUNT"]
    //                             }
    //                         },
    //                         countByStatus: [{
    //                             status: "CONNECTED",
    //                             count: 0
    //                         },
    //                         {
    //                             status: "OPTIN_OK",
    //                             count: 0
    //                         }],
    //                         documentationUrl: null,
    //                         id: 11,
    //                         integrationType: "WEBHOOK",
    //                         logo: "source_logos/shopify.svg",
    //                         logoAbsolute: "http://admin0.botbit.io/static/img/source_logos/shopify.svg",
    //                         name: "shopify",
    //                     },
    //                     {
    //                         authorizationType: "OAUTH",
    //                         category: "EMAIL",
    //                         configuration: {
    //                             documentation: {
    //                                 show: true,
    //                                 url: "https://app.intercom.com/a/apps/z0yssql1/articles/articles/4430690/show"
    //                             },
    //                             fields: [],
    //                             matching: {
    //                                 allowedTypes: ["ACCOUNT"]
    //                             }
    //                         },
    //                         countByStatus: [{
    //                             status: "CONNECTED",
    //                             count: 0
    //                         },
    //                         {
    //                             status: "OPTIN_OK",
    //                             count: 0
    //                         }],
    //                         documentationUrl: null,
    //                         id: 12,
    //                         integrationType: "WEBHOOK",
    //                         logo: "source_logos/gmail.png",
    //                         logoAbsolute: "http://admin0.botbit.io/static/img/source_logos/gmail.png",
    //                         name: "gmail",
    //                     },
    //                 ],
    //                 "pageNumber": 0,
    //                 "pageSize": 0,
    //                 "totalSize": 0
    //             },
    //             "result": "success",
    //             "message": "ok",
    //             "statusCode": 200
    //     }
    // }

    // return new Promise((resolve, reject) => {
    //     resolve(dummyAnswer);
    // });  
    const queryParams = new URLSearchParams();
    companyId && queryParams.append("companyId", companyId);
    return axiosClient.get(`/sources?${queryParams.toString()}`);
}

export const getSource = (id, companyId) => {
    // const mock = {
    //     data: {
    //         "data": {
    //             "id": 11,
    //             "configuration": {
    //                 "fields": [
    //                     {
    //                         "id": "shopName",
    //                         "type": "text"
    //                     }
    //                 ],
    //                 "matching": {
    //                     "allowedTypes": [
    //                         "ACCOUNT"
    //                     ]
    //                 }
    //             },
    //             "name": "shopify",
    //             "category": "ONLINE_ORDER",
    //             "authorizationType": "OAUTH",
    //             "integrationType": "WEBHOOK",
    //             "documentationUrl": "https://google.com",
    //             "logo": "source_logos/shopify.svg",
    //             "logoAbsolute": "http://admin0.botbit.io/static/img/source_logos/shopify.svg",
    //         },
    //         "result": "success",
    //         "message": "ok",
    //         "statusCode": 200
    //     }
    // }
    // return new Promise((resolve) => {
    //     resolve(mock);
    // })
    const queryParams = new URLSearchParams();
    companyId && queryParams.append("companyId", companyId);
    return axiosClient.get(`sources/${id}?${queryParams.toString()}`)
}