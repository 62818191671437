import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default class ClientTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            suggestions: [
                
            ]
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
    }

    handleDelete(i) {
        const { tags } = this.props;
		var newTags = tags.filter((tag, index) => index !== i);
        this.props.setTags(newTags);
    }

	handleAddition(tag, currPos, newPos) {
		var newTags =  [...this.props.tags, tag]
		this.props.setTags(newTags);
    }

    handleDrag(tag, currPos, newPos) {
        const tags = [...this.props.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
		this.props.setTags(newTags);
    }



    render() {
        return (
            <React.Fragment>
                <style>{`
               li {
                list-style-type: none;
        
            }
                `}</style>
                <ReactTags
                    classNames={{
                        tags: 'd-flex flex-column',
                        tagInput: '',
                        tagInputField: 'form-control',
                        selected: 'bg-muted ',
                        tag: 'label-primary p-2 m-1 d-inline-flex',
                        remove: 'pl-2',
                        suggestions: 'list-group',
                        activeSuggestion: 'label-primary p-2 m-1'
                    }}
                    inline={true}
					placeholder={this.props.placeholder}
                    tags={this.props.tags}
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    handleDrag={this.handleDrag}
                    delimiters={delimiters} />
            </React.Fragment>
        )
    }
}
