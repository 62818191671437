import React, { useEffect, useRef, useState } from "react";
import { MDBTimePicker, MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBIcon, MDBSelect, MDBSwitch, MDBTooltip, MDBInput } from "mdbreact";
import { Formik, Form, FastField, ErrorMessage, FieldArray } from "formik";
import * as Yup from 'yup';
import AutoReplyRuleNaturalLanguage from "./AutoReplyRuleNaturalLanguage";
import "./settings.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import deepCopy from "../../commons/utils/deepCopy";
import ReplyTemplateEditModal from './ReplyTemplateEditModal';
import CollectionVisualizerContainer from "../collectionVisualizer/CollectionVisualizerContainer";
import withEventTracking, { trackers } from '../../config/withEventTracking';

const MySwal = withReactContent(Swal);

const AutoReplyRules = ({ t, availableCompanies, selectedCompany, admin, getRules, onSaveAutoReplyRule, onDeleteAutoReplyRule, getFlows, getReplyTemplates, onSaveReplyTemplate, trackEvent }) => {

    const [flows, setFlows] = useState([])

    const [replyTemplates, setReplyTemplates] = useState([])

    const [showEditRuleModal, setShowEditRuleModal] = useState(false)

    const [showReplyTemplateEditModal, setShowReplyTemplateEditModal] = useState(false)

    const [replyTemplate, setReplyTemplate] = useState(undefined)
	
	// esto es para especificar si se responde con template o con flujo
	const [autoReplyWith, setAutoReplyWith] = useState(undefined)

    const activeRulesQtty = useRef(undefined)

    const rules = useRef(undefined)

    // Antes de salir de esta vista, hay que actualizar Intercom para que la serie de OB_AUTO_REPLY continúe
    useEffect(() => {
        return () => {
            //console.log("Active rules leaving " + activeRulesQtty.current)
            window.Intercom('update', { "auto_reply_rules_enabled": activeRulesQtty.current });
        }
    }, [])

    const openReplyTemplateEditModal = (replyTemplate, rule, updateRow) => {
        setActions({ updateRow: () => updateRow(rule, 'refresh') })
        setReplyTemplate(replyTemplate)
        setShowReplyTemplateEditModal(true)
    }

    const prefix = 'panels.feedback.settings.rules.nl.edit'

    const emptyRule = () => {
        return {
            operator: undefined,
            score: undefined,
            onlyForEmptyReviews: false,
            replyTemplates: [],
            companyId: selectedCompany.id,
            category: 'FEEDBACK',
            //category: 'CHAT',
            timeRangesFilter: [],
            lastInteractionDaysIntervalFilter: 14,
			hoursWithoutOperatorMessageForFlowActivation: 5,
            filterType: 'LAST_INTERACTION_DAYS_INTERVAL',
			channels: undefined,
			conversationFlow: undefined
            //filterType: 'TIME_RANGE'
        }
    }

    const [autoReplyRule, setAutoReplyRule] = useState(emptyRule())

    const [errorOperator, setErrorOperator] = useState(false)

    const buildOperators = (value) => {
        setErrorOperator(false)
        return [
            {
                text: t(`${prefix}.LESS`),
                value: 'LESS',
                checked: value === 'LESS'
            }, {
                text: t(`${prefix}.GREATER`),
                value: 'GREATER',
                checked: value === 'GREATER'
            }, {
                text: t(`${prefix}.EQUAL`),
                value: 'EQUAL',
                checked: value === 'EQUAL'
            }
        ]
    }

    const buildCategories = (value) => {
        return [
            {
                text: t(`${prefix}.category.feedback`),
                value: 'FEEDBACK',
                checked: value === 'FEEDBACK'
            }, {
                text: t(`${prefix}.category.chat`),
                value: 'CHAT',
                checked: value === 'CHAT'
            }
        ]
    }

    const buildChannels = (value) => {
        return [
            {
                text: 'Whatsapp',
                value: 'WHATSAPP',
                checked: value && value.includes('WHATSAPP')
            }, {
                text: 'Facebook Messenger',
                value: 'FACEBOOK_MESSENGER',
                checked: value && value.includes('FACEBOOK_MESSENGER')
            }, {
                text: 'Instagram',
                value: 'INSTAGRAM',
                checked: value && value.includes('INSTAGRAM')
            }
        ]
    }

    const buildDays = (value) => {
        return [
            {
                text: t(`${prefix}.filterType.timeRange.days.monday`),
                value: 'MONDAY',
                checked: value === 'MONDAY'
            }, 
            {
                text: t(`${prefix}.filterType.timeRange.days.tuesday`),
                value: 'TUESDAY',
                checked: value === 'TUESDAY'
            }, 
            {
                text: t(`${prefix}.filterType.timeRange.days.wednesday`),
                value: 'WEDNESDAY',
                checked: value === 'WEDNESDAY'
            }, 
            {
                text: t(`${prefix}.filterType.timeRange.days.thursday`),
                value: 'THURSDAY',
                checked: value === 'THURSDAY'
            }, 
            {
                text: t(`${prefix}.filterType.timeRange.days.friday`),
                value: 'FRIDAY',
                checked: value === 'FRIDAY'
            }, 
            {
                text: t(`${prefix}.filterType.timeRange.days.saturday`),
                value: 'SATURDAY',
                checked: value === 'SATURDAY'
            }, 
            {
                text: t(`${prefix}.filterType.timeRange.days.sunday`),
                value: 'SUNDAY',
                checked: value === 'SUNDAY'
            }
        ]
    }

    const [errorScore, setErrorScore] = useState(false)

    const buildScores = (value) => {
        setErrorScore(false)
        return [
            {
                text: '1',
                value: 1,
                checked: value === 1
            }, {
                text: '2',
                value: 2,
                checked: value === 2
            }, {
                text: '3',
                value: 3,
                checked: value === 3
            }, {
                text: '4',
                value: 4,
                checked: value === 4
            }, {
                text: '5',
                value: 5,
                checked: value === 5
            }
        ]
    }

    const [errorReplyTemplates, setErrorReplyTemplates] = useState(false)

    const buildReplyTemplates = (values, category) => {
        setErrorReplyTemplates(false)

        const filteredTemplates = replyTemplates.filter( (template) => template.category === category);

        const v = filteredTemplates.map(template => {
            return {
                text: template.name,
                value: template.id,
                checked: values.find(value => value.id === template.id) !== undefined
            }
        })
        return v
    }

    const buildFlows = (value) => {
        const v = flows.map(flow => {
            return {
                text: flow.name,
                value: flow.id,
                checked: value && value.id === flow.id
            }
        })
        return v
    }

    const [actions, setActions] = useState(undefined)

    const openEditRuleModal = (rule, updateRow) => {
        setActions({ updateRow: updateRow })
		if (rule.replyTemplates && rule.replyTemplates.length > 0)
			setAutoReplyWith("TEMPLATES")
		else
			setAutoReplyWith("FLOW")
        setAutoReplyRule(rule)
        setShowEditRuleModal(true)
    }

    const openAddRuleModal = (updateRow) => {
        setActions({ updateRow: updateRow, action: 'append' })
		setAutoReplyWith("TEMPLATES")
        setAutoReplyRule(emptyRule())
        setShowEditRuleModal(true)
    }

    const openDeleteRuleModal = (rule, updateRow) => {
        MySwal.fire({
            title: t('panels.feedback.settings.rules.delete.title'),
            text: t('panels.feedback.settings.rules.delete.text'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('panels.feedback.settings.rules.delete.confirm'),
            cancelButtonText: t('panels.feedback.settings.rules.delete.cancel'),
            confirmButtonColor: '#1ab394'
        }).then(result => {
            if (result.value) {
                if (onDeleteAutoReplyRule) {
                    onDeleteAutoReplyRule(rule, selectedCompany.id).then(() => {
                        updateRow(rule, 'refresh')
                    })
                }
            }
        });
    }

    const canEdit = () => {
        return admin && admin.operations.includes("AUTO_REPLY_RULES__EDIT")
    }

    const canDelete = () => {
        return admin && admin.operations.includes("AUTO_REPLY_RULES__DELETE")
    }

    const rowActions = (row, updateRow) => {

        const actions = []
        if (canEdit()) {
            actions.push({
                key: 1,
                color: "",
                icon: "edit",
                label: t('panels.feedback.settings.rules.editrule'),
                onClick: () => openEditRuleModal(row, updateRow)
            })
        }

        if (canDelete() && !row.defaultType) {
            actions.push({
                key: 2,
                color: "",
                icon: "trash",
                label: t('panels.feedback.settings.rules.deleterule'),
                onClick: () => openDeleteRuleModal(row, updateRow)
            })
        }
        return actions
    }

    const headerActions = (updateRow) => {
        return canEdit() ? [{
            key: 0,
            icon: "toggle-on",
            label: t('panels.feedback.settings.rules.activatedefaultrules'),
            primary: true,
            onClick: () => activateDefaultRules(updateRow)
        }, {
            key: 1,
            icon: "plus",
            label: t('panels.feedback.settings.rules.addrule'),
            primary: false,
            onClick: () => openAddRuleModal(updateRow)
        }] : []
    }

    const activateDefaultRules = (updateRow) => {
        let calls = []
        let update = false
        let enable = false
        rules.current.forEach(rule => {
            if (rule.category === "FEEDBACK") {
                if (rule.defaultType) {
                    // Default rule: si está disabled -> enable
                    if (rule.enabled === false) {
                        update = true
                        enable = true
                    }
                } else {
                    // Custom rule: si está enabled -> disable
                    if (rule.enabled) {
                        update = true
                        enable = false
                    }
                }
                if (update) {
                    calls.push(switchEnableInternal(enable, rule, updateRow))
                }
            }
        })
        // Espero que terminen de actualizar todas las reglas
        // TODO controlar si falla
        Promise.all(calls).then(result => {
            MySwal.fire({
                title: t('panels.feedback.settings.rules.enable.result.default.title'),
                text: t('panels.feedback.settings.rules.enable.result.default.text'),
                type: 'success',
                showCancelButton: false,
                confirmButtonText: t('panels.feedback.settings.rules.enable.error.notemplates.ok'),
                confirmButtonColor: '#1ab394'
            })
        })
    }

    const switchEnable = async (value, row, updateRow) => {
        if (value) {
            openConfirmationModal(value, row, updateRow)
        } else {
            switchEnableInternal(value, row, updateRow)
        }
    }

    const switchEnableInternal = async (value, row, updateRow) => {
        if (!row.replyTemplates || (row.category === "FEEDBACK" && row.replyTemplates.length < 3) ||  
			(row.category === "CHAT" && row.replyTemplates.length < 1 && (!row.conversationFlow || !row.conversationFlow.id) )) {
            openWarningModal(row.category)
            return;
        }
        let rule = deepCopy(row)
        rule.enabled = value
        const response = await onSaveAutoReplyRule(rule)
        updateRow(response.data.data)
        updateRules(rule.id, value)
        activeRulesQtty.current += (rule.enabled === true ? 1 : -1)
        trackEvent(rule.enabled === true ? 'AUTO_REPLY_RULE_ACTIVATED' : 'AUTO_REPLY_RULE_DEACTIVATED',
            {
                onlyForEmptyReviews: rule.onlyForEmptyReviews,
                operator: rule.operator,
                score: rule.score
            },false
            , [trackers.BACKEND])
        // Si hay dos reglas activas update a Intercom para que siga la serie, sino se actualizará cuando el cliente salga de esta pantalla
        // TODO: esto habría que hacerlo solamente si el usuario se está onboardeando en auto_reply
        if (activeRulesQtty.current === 2 && rule.enabled === true)
            window.Intercom('update', { "auto_reply_rules_enabled": 2 });
    }

    const updateRules = (id, enabled) => {
        let rule = rules.current.find(rule => rule.id === id)
        if (rule !== undefined)
            rule.enabled = enabled
    }

    const getActiveRules = (rules) => {
        let activeRules = 0
        rules.forEach(rule => {
            if (rule.enabled === true)
                activeRules++
        })
        return activeRules
    }

    const openConfirmationModal = (value, rule, updateRow) => {
        MySwal.fire({
            title: t('panels.feedback.settings.rules.enable.confirmation.title'),
            text: t('panels.feedback.settings.rules.enable.confirmation.text'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('panels.feedback.settings.rules.enable.confirmation.confirm'),
            cancelButtonText: t('panels.feedback.settings.rules.enable.confirmation.cancel'),
            confirmButtonColor: '#1ab394'
        }).then(result => {
            if (result.value) {
                switchEnableInternal(value, rule, updateRow);
            }
        });
    }

    const openWarningModal = (category) => {
        MySwal.fire({
            title: t('panels.feedback.settings.rules.enable.error.notemplates.title'),
            text: category === "FEEDBACK" ? t('panels.feedback.settings.rules.enable.error.notemplates.text') : t('panels.feedback.settings.rules.enable.error.notemplates.textOneTemplate'),
            type: 'error',
            showCancelButton: false,
            confirmButtonText: t('panels.feedback.settings.rules.enable.error.notemplates.ok'),
            confirmButtonColor: '#1ab394'
        });
    }

    const columns = [
        // for BIG screens: greater than md
        {
            dataField: 'enabled',
            className: 'd-none d-md-block',
            size: 2,
            formatter: (cellContent, row, updateRow) => {
                return <div>
                    <MDBSwitch
                        data-botbit-security="auto-reply-rules-activate"
                        labelLeft="" labelRight=""
                        checked={row.enabled}
                        disabled={!canEdit()}
                        getValue={(value) => switchEnable(value, row, updateRow)}
                    />
                    <br/>
                    {row.category}
                </div>
            }
        },
        {
            dataField: 'orderNumber',
            size: 1,
            className: 'd-none d-md-block text-right',
            formatter: (cellContent) => {
                return cellContent + '.'
            }
        },
        // for SMALL screens: lower than md
        {
            dataField: 'orderNumber',
            isHeader: true,
            className: 'd-md-none',
            formatter: (cellContent, row) => {
                return cellContent + '.  ' + row.category
            }
        },
        {
            dataField: 'enabled',
            isHeader: true,
            className: 'd-md-none',
            formatter: (cellContent, row, updateRow) => {
                return <div>
                    <MDBSwitch
                        data-botbit-security="auto-reply-rules-activate"
                        labelLeft="" labelRight=""
                        checked={row.enabled}
                        getValue={(value) => switchEnable(value, row, updateRow)}
                    />
                </div>
                
            }
        },
        {
            dataField: 'score',
            formatter: (cellContent, row, updateRow) => {
                return <AutoReplyRuleNaturalLanguage
                    t={t}
                    rule={row}
                    onClickReplyTemplate={(replyTemplate) => openReplyTemplateEditModal(replyTemplate, row, updateRow)} />
            }
        }
    ]

    const isError = (errors, touched, props) => (errors[props.field.name] && touched[props.field.name]) ? true : false

    const selectValuesToFieldValue = (values) => {
        if (!values || values.length === 0) return []
        return values.map(value => {
            return { id: value }
        })
    }

    const areEquals = (value1, value2) => {
        const value1empty = !value1 || value1.length === 0
        const value2empty = !value2 || value2.length === 0
        if (value1empty && value2empty) return true
        if (value1empty || value2empty) return false
        if (value1.length !== value2.length) return false
        return value1.every(v1 => value2.find(v2 => v1.id === v2.id) !== undefined)
    }

    const getDefaultFilterValues = () => []

    const getData = async () => {
        getReplyTemplates(selectedCompany.id).then(response => {
            setReplyTemplates(response.data.data)
        })
		getFlows(selectedCompany.id).then(response => {
			if (response.data.data.items)
				setFlows(response.data.data.items)
		})
        let response = await getRules(selectedCompany.id)
        rules.current = await response.data.data.items
        activeRulesQtty.current = getActiveRules(rules.current)
        return response
    }

    const turnOnOnlyForEmptyReviewsAfterScoreChanged = (props, score) => {
        const operator = props.form.values.operator
        turnOnOnlyForEmptyReviewsIfNecessary(props, operator, score)
    }

    const turnOnOnlyForEmptyReviewsAfterOperatorChanged = (props, operator) => {
        const score = props.form.values.score
        turnOnOnlyForEmptyReviewsIfNecessary(props, operator, score)
    }

    const turnOnOnlyForEmptyReviewsIfNecessary = (props, operator, score) => {
        const turnOn = turnOnOnlyForEmptyReviewsIsNecessary(operator, score)
        if (turnOn) props.form.setFieldValue('onlyForEmptyReviews', true)
    }

    const turnOnOnlyForEmptyReviewsIsNecessary = (operator, score) => {
        return ((operator === 'EQUAL' || operator === 'GREATER') && (score === 1 || score === 2 || score === 3)) || operator === 'LESS'
    }

    const openConfirmationModalOnSave = (rule) => {
        MySwal.fire({
            title: t('panels.feedback.settings.rules.edit.confirmation.title'),
            text: t('panels.feedback.settings.rules.edit.confirmation.text'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('panels.feedback.settings.rules.edit.confirmation.confirm'),
            cancelButtonText: t('panels.feedback.settings.rules.edit.confirmation.cancel'),
            confirmButtonColor: '#1ab394'
        }).then(result => {
            if (result.value) save(rule)
        });
    }

    const save = (rule) => {
        if (onSaveAutoReplyRule) {
            onSaveAutoReplyRule(rule).then((response) => {
                if (actions && actions.updateRow) actions.updateRow(response.data.data, actions.action)
                setShowEditRuleModal(!showEditRuleModal)
            })
        }
    }

    const getHoursFromTimePickerValue = (value) => {
        return parseInt(value.substring(0,2));
    }

    const getMinutesFromTimePickerValue = (value) => {
        return parseInt(value.substring(3,5));
    }

    return (<div id="settings">

        <MDBModal 
            className="review-text" 
            isOpen={showEditRuleModal} 
            toggle={() => setShowEditRuleModal(!showEditRuleModal)}>
            <MDBModalHeader
                toggle={() => setShowEditRuleModal(!showEditRuleModal)}>
                {t(`panels.feedback.settings.rules.${(autoReplyRule.id) ? 'editrule' : 'addrule'}`)}
            </MDBModalHeader>
            <Formik
                initialValues={autoReplyRule}
                validationSchema={Yup.object().shape({
                    category: Yup.string().required(t(`${prefix}.category.error`)),
                    operator: Yup.string().nullable().when('category', {
                        is: 'FEEDBACK',
                        then: Yup.string().required(t(`${prefix}.operator.error`))
                    }),
                    score: Yup.string().nullable().when('category', {
                        is: 'FEEDBACK',
                        then: Yup.string().required(t(`${prefix}.score.error`)),
                    }),
                    replyTemplates: Yup.array().when('category', {
						is: 'FEEDBACK',
						then: Yup.array().min(3, t(`${prefix}.replyTemplates.errorFeedback`))
					}).when(['category', 'conversationFlow'], {
						is: (category, conversationFlow) => { return category === 'CHAT' && (!conversationFlow || !conversationFlow.id)},
						then: Yup.array().min(1, t(`${prefix}.replyTemplates.errorChat`)).max(1, t(`${prefix}.replyTemplates.errorChat`))
					}),
					// conversationFlow: Yup.object().shape({
					// 	id: Yup.number().when(['category', 'replyTemplates'], {
					// 		is: (category, replyTemplates) => {
					// 			console.log(category, replyTemplates)
					// 			return category ==="CHAT" && (!replyTemplates || replyTemplates.length === 0)
					// 		},
					// 		then: Yup.number().required(t(`${prefix}.replyTemplates.errorChat`))
					// 	})
					// }),
					timeRangesFilter: Yup.array().of(
                        Yup.object().shape({
                            day: Yup.string().when(['category', 'filterType'], {
                                is: (category, filterType) => category === 'CHAT' && filterType === 'TIME_RANGE',
                                then: Yup.string().required(t(`${prefix}.filterType.timeRange.errorDay`))
                            }),
                            startHour: Yup.number().when(['category', 'filterType'], {
                                is: (category, filterType) => category === 'CHAT' && filterType === 'TIME_RANGE',
                                then: Yup.number().test('hourRule', t(`${prefix}.filterType.timeRange.errorStartHour`), (number) => number && number>=0 && number <24)
                            }),
                            endHour: Yup.number().when(['category', 'filterType'], {
                                is: (category, filterType) => category === 'CHAT' && filterType === 'TIME_RANGE',
                                then: Yup.number().test('hourRule', t(`${prefix}.filterType.timeRange.errorEndHour`), (number) => number && number>=0 && number <24)
                            })
                        // --- no anda, no tengo acceso al parent y no puedo hacer que se evalue solo cuando es time range
                        // }).test('startHourLessThanEndHour', t(`${prefix}.filterType.timeRange.errorInvalidTimes`), function (object, context) {
                        //     console.log("object", object);
                        //     console.log("this", this);
                        //     console.log("context", context);
                        //     return object.category !== 'CHAT' || 
                        //         (object.category === 'CHAT' && object.filterType !== 'TIME_RANGE') ||
                        //         object.startHour < object.endHour || 
                        //         (object.startHour === object.endHour && object.startMinute < object.endMinute);
                        // })
                        // --- tampoco anda, no entiendo pq
                        // }).when(['category', 'filterType'], {
                        //     is: (category, filterType) => {
                        //         console.log("category", category)
                        //         console.log("filterType", filterType)
                        //         return category === 'CHAT' && filterType === 'TIME_RANGE'
                        //     },
                        //     then: Yup.object().test('startHourLessThanEndHour', t(`${prefix}.filterType.timeRange.errorInvalidTimes`), (object) => {
                        //         console.log("object", object);
                        //         return object.startHour < object.endHour || 
                        //             (object.startHour === object.endHour && object.startMinute < object.endMinute);
                        //     })
                        })

                    ).test('atLeastOneTimeRange', t(`${prefix}.filterType.timeRange.errorEmptyTimes`), function (object, context) {
                        // si se seleccionaron opciones que no tienen nada que ver con time range pasa bien la validacion
                        if (this.parent.category !== 'CHAT' || (this.parent.category === 'CHAT' && this.parent.filterType !== 'TIME_RANGE'))
                            return true;
                        return (object && object.length > 0)
                    }).test('startHourLessThanEndHour', t(`${prefix}.filterType.timeRange.errorInvalidTimes`), function (object, context) {
                        // si se seleccionaron opciones que no tienen nada que ver con time range pasa bien la validacion
                        if (this.parent.category !== 'CHAT' || (this.parent.category === 'CHAT' && this.parent.filterType !== 'TIME_RANGE'))
                            return true;
                        if (!object)
                            return false;
                        const invalidHours = object.filter( (value) => value.startHour>value.endHour || (value.startHour === value.endHour && value.startMinute >= value.endMinute))
                        return  invalidHours.length === 0;
                    }),
                    lastInteractionDaysIntervalFilter: Yup.number().nullable().when(['category', 'filterType'], {
                        is: (category, filterType) => category === 'CHAT' && filterType === 'LAST_INTERACTION_DAYS_INTERVAL',
                        then: Yup.number().typeError(t(`${prefix}.filterType.daysInterval.error`)).test(
                            'hourRule', t(`${prefix}.filterType.daysInterval.error`), (number) => number && number>=0
                        )
                    }),
                    hoursWithoutOperatorMessageForFlowActivation: Yup.number().nullable().when(['category', 'conversationFlow'], {
						is: (category, conversationFlow) => { return category === 'CHAT' && conversationFlow && conversationFlow.id },
                        then: Yup.number().typeError(t(`${prefix}.filterType.hoursWithoutOperatorMessageForFlowActivation.error`)).test(
                            'hoursWithoutOperatorMessageForFlowActivationRule', t(`${prefix}.filterType.hoursWithoutOperatorMessageForFlowActivation.error`), (number) => !number || (number && number>=0)
                        )
                    })
                })}
                onSubmit={(rule) => {
                    const turnOn = turnOnOnlyForEmptyReviewsIsNecessary(rule.operator, rule.score)
                    if (turnOn && !rule.onlyForEmptyReviews) {
                        openConfirmationModalOnSave(rule)
                    } else {
                        save(rule)
                    }
                }}>
                {({ errors, values, touched, setFieldValue }) => (
                    <Form>
                        <MDBModalBody>
                            <MDBContainer className="margins-reduced" style={{ height: '400px', overflowY: "scroll" }}>
                                <div className="d-md-none">{t(`${prefix}.category.title`)}</div>
                                <div className="d-flex justify-content-start mb-0">
									<div className="d-none d-md-flex align-items-center mb-2 mr-2" style={{ whiteSpace: 'pre' }}>{t(`${prefix}.category.title`)}:</div>
                                    <div className='pr-2'>
                                        <FastField
                                            name="category"
                                            render={(props) => {
                                                return (autoReplyRule.id ? <span><b>{t(`${prefix}.category.${props.field.value.toLowerCase()}`)}</b></span> :
                                                    (props.field && <MDBSelect
                                                        selected={t(`${prefix}.category.label`)}
                                                        options={buildCategories(props.field.value)}
                                                        getValue={(values) => {
                                                            //setErrorOperator(isError(errors, touched, props))
                                                            if (props.field.value !== values[0]) {
                                                                props.form.setFieldValue(props.field.name, values[0])
                                                                //turnOnOnlyForEmptyReviewsAfterOperatorChanged(props, values[0])
                                                            }
                                                        }}
                                                    />)
                                                );
                                            }}
                                        />
                                        <ErrorMessage name="category" component="div"
                                            className="invalid-feedback d-block"
                                            style={{ marginTop: '-15px' }} />
                                    </div>
                                </div>
								{/* START CHAT */}
                                { values.category === 'CHAT' && <>
									<div className="d-flex justify-content-start pt-2 mb-2">
										<div className="d-none d-md-flex align-items-center mb-2 mr-2" style={{ whiteSpace: 'pre' }}>
											{t(`${prefix}.channels.label`)}:
										</div>
										<FastField
											name="channels"
											render={(props) => {
												return (
													props.field  && <MDBSelect
														multiple={true}
														selected={t(`${prefix}.channels.label`)}
														options={buildChannels(props.field.value)}
														getValue={(values) => {
															if (!areEquals(values, props.field.value)) props.form.setFieldValue(props.field.name, values)
														}}
													/>
												);
											}}
										/>
									</div>
                                    <div className="d-flex justify-content-start mb-2">
										<div className="d-none d-md-flex align-items-center mb-2 mr-2">{t(`${prefix}.filterType.title`)}</div>
                                        <FastField
                                            name="filterType"
                                            render={(props) => {
                                                return ((props.field && <>
                                                        <MDBInput 
                                                            containerClass="pl-0"
                                                            type="radio"
                                                            name="filterType" 
                                                            id='filterTypeDaysInterval' 
                                                            label={t(`${prefix}.filterType.daysInterval.selectLabel`)} 
                                                            checked={props.field.value === "LAST_INTERACTION_DAYS_INTERVAL"}
                                                            onClick={ (event) => {
                                                                if (event.target.value !== props.field.value)
                                                                    props.form.setFieldValue(props.field.name, event.target.value)
                                                            }}
                                                            value="LAST_INTERACTION_DAYS_INTERVAL" />
                                                        <MDBInput 
                                                            type="radio"
                                                            name="filterType" 
                                                            id='filterTypeTimeRange' 
                                                            label={t(`${prefix}.filterType.timeRange.selectLabel`)}
                                                            checked={props.field.value === "TIME_RANGE"}
                                                            onClick={ (event) => {
                                                                if (event.target.value !== props.field.value)
                                                                    props.form.setFieldValue(props.field.name, event.target.value)
                                                            }}
                                                            value='TIME_RANGE' />
                                                    </>)
                                                );
                                            }}
                                        />
                                    </div>
                                    { values.filterType === 'LAST_INTERACTION_DAYS_INTERVAL' && <>
                                        <FastField
                                            name="lastInteractionDaysIntervalFilter"
                                            render={(props) => {
                                                return (
                                                    <MDBInput
                                                        containerClass="mt-4"
                                                        label={t(`${prefix}.filterType.daysInterval.inputLabel`)} 
                                                        type="text"
                                                        value={props.field.value}
                                                        getValue={(value) => { 
                                                            props.form.setFieldValue(props.field.name, value) 
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <ErrorMessage name="lastInteractionDaysIntervalFilter" component="div"
                                            className="invalid-feedback d-block"
                                            style={{ marginTop: '-15px' }} />
                                    </>}        
                                    { values.filterType === 'TIME_RANGE' && <>
                                        <FieldArray
                                            name="timeRangesFilter"
                                            render={(props) => {
                                                return (
                                                    <>
                                                        <div className="mb-2">
                                                            <span style={{ textDecoration: "underline", cursor: "pointer", color: "blue" }}
                                                                onClick={ () => { props.push( { day: 'MONDAY', startHour: 0, startMinute: 0, endHour: 0, endMinute: 0 });
                                                            }}>
                                                                {t(`${prefix}.filterType.timeRange.add`)}
                                                            </span>
                                                        </div>
                                                        {   
                                                            values.timeRangesFilter.map ((timeRange, index) => (
                                                                <div key={index}>
                                                                    <div className="d-flex justify-content-start" key={index}>
                                                                        <div className="ml-1 mr-1">
                                                                            <FastField
                                                                                name={`timeRangesFilter.${index}.day`}
                                                                                render={(props) => {
                                                                                    const selected = prefix + ".filterType.timeRange.days." + props.field.value.toLowerCase();
                                                                                    //console.log("test", selected)
                                                                                    return (
                                                                                        <MDBSelect
                                                                                            selected={t(selected)}
                                                                                            options={buildDays(props.field.value)}
                                                                                            getValue={(values) => {
                                                                                                if (props.field.value !== values[0]) {
                                                                                                    props.form.setFieldValue(props.field.name, values[0])
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="ml-1 mr-1">
                                                                            <FastField
                                                                                name={`timeRangesFilter.${index}.startHour`}
                                                                                render={(props) => {
                                                                                    return (
                                                                                        <MDBTimePicker 
                                                                                            id={`startHour_${index}`} 
                                                                                            label={t(`${prefix}.filterType.timeRange.startHourLabel`)}
                                                                                            hours={props.field.value} 
                                                                                            minutes={props.form.values.timeRangesFilter[index].startMinute} 
                                                                                            hoursFormat={24} 
                                                                                            getValue={ (value) => {
                                                                                                props.form.setFieldValue(props.field.name, getHoursFromTimePickerValue(value)) 
                                                                                                props.form.setFieldValue(`timeRangesFilter.${index}.startMinute`, getMinutesFromTimePickerValue(value)) 
                                                                                            }} 
                                                                                        />
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="ml-1 mr-1">
                                                                            <FastField
                                                                                name={`timeRangesFilter.${index}.endHour`}
                                                                                render={(props) => {
                                                                                    return (
                                                                                        <MDBTimePicker 
                                                                                            id={`endHour_${index}`} 
                                                                                            label={t(`${prefix}.filterType.timeRange.endHourLabel`)}
                                                                                            hours={props.field.value} 
                                                                                            minutes={props.form.values.timeRangesFilter[index].endMinute} 
                                                                                            hoursFormat={24} 
                                                                                            getValue={ (value) => {
                                                                                                props.form.setFieldValue(props.field.name, getHoursFromTimePickerValue(value)) 
                                                                                                props.form.setFieldValue(`timeRangesFilter.${index}.endMinute`, getMinutesFromTimePickerValue(value)) 
                                                                                            }} 
                                                                                        />
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <span style={{ textDecoration: "underline", cursor: "pointer", color: "blue", alignSelf: "center" }}
                                                                            onClick={ () => {
                                                                                props.remove(index);
                                                                            }}>{t(`${prefix}.filterType.timeRange.remove`)}</span>

                                                                    </div>
                                                                    <ErrorMessage name={`timeRangesFilter.${index}.day`}
                                                                        component="div"
                                                                        className="invalid-feedback d-block"
                                                                        style={{ marginTop: '-15px' }} />
                                                                    <ErrorMessage name={`timeRangesFilter.${index}.startHour`}
                                                                        component="div"
                                                                        className="invalid-feedback d-block"
                                                                        style={{ marginTop: '-15px' }} />
                                                                    <ErrorMessage name={`timeRangesFilter.${index}.endHour`}
                                                                        component="div"
                                                                        className="invalid-feedback d-block"
                                                                        style={{ marginTop: '-15px' }} />
                                                                </div>
                                                            ))
                                                        }

                                                    </>
                                                );
                                            }}
                                        />
                                        <ErrorMessage name={`timeRangesFilter`} component="div"
                                            className="invalid-feedback d-block"
                                            style={{ marginTop: '-5px' }} />
                                        <ErrorMessage name="lastInteractionDaysIntervalFilter" component="div"
                                            className="invalid-feedback d-block"
                                            style={{ marginTop: '-15px' }} />
                                    </>}

									<div className="d-md-none pt-2">{t(`${prefix}.autoreplyWith.label`)}:</div>
                                    <div className="d-flex justify-content-start mb-2 mt-4">
										<div className="d-none d-md-flex align-items-center mb-2 mr-2">{t(`${prefix}.autoreplyWith.label`)}</div>
											<MDBInput 
												containerClass="pl-0"
												type="radio"
												name="autoReplyWith" 
												id='autoReplyWithTemplates' 
												label={t(`${prefix}.autoreplyWith.templates`)} 
												checked={autoReplyWith === "TEMPLATES"}
												onClick={ (event) => { setAutoReplyWith("TEMPLATES"); setFieldValue("conversationFlow", null); }}
												value="TEMPLATES" />
											<MDBInput 
												type="radio"
												name="autoReplyWith" 
												id='autoReplyWithFlow' 
												label={t(`${prefix}.autoreplyWith.flow`)}
												checked={autoReplyWith === "FLOW"}
												onClick={ (event) => { setAutoReplyWith("FLOW"); setFieldValue("replyTemplates", []); }}
												value='FLOW' />
                                        <div/>
                                    </div>

									{autoReplyWith === "FLOW" && <>
										<div className="d-md-none pt-2">{t(`${prefix}.flow.select`)}:</div>
										<div className="d-flex justify-content-start pt-2">
											<div className="d-none d-md-flex align-items-center mb-2 mr-2" style={{ whiteSpace: 'pre' }}>{t(`${prefix}.flow.select`)}:</div>
											<div className={(errorReplyTemplates) ? 'is-invalid-important' : ''}>
												<FastField
													name="conversationFlow"
													render={(props) => {
														return (
															props.field && flows && <MDBSelect
																multiple={false}
																selected={t(`${prefix}.flow.select`)}
																options={buildFlows(props.field.value)}
																getValue={(values) => {
																	let newValue = (values && values.length > 0) ? {id: values[0], name: (flows.find((flow)=>flow.id=values[0])).name } : undefined;
																	let oldValue = props.field.value;
																	let hasChanged = (newValue && !oldValue) || (!newValue && oldValue) || (newValue && oldValue && newValue.id !== oldValue.id);
																	if (hasChanged) {
																		props.form.setFieldValue(props.field.name, newValue)
																		// si hay un valor seteado tengo que nulear los templates
																		if (newValue)
																			props.form.setFieldValue("replyTemplates", [])
																	}
																}}
															/>
														);
													}}
												/>
												<ErrorMessage name="replyTemplates" component="div"
													className="invalid-feedback d-block"
													style={{ marginTop: '-15px' }} />
											</div>
										</div>
										{ values.filterType === 'TIME_RANGE' && <>
											<div className="d-flex justify-content-start mb-2">
												<div className="d-none d-md-flex align-items-center mb-2 mr-2" style={{ whiteSpace: 'pre' }}>
													{t(`${prefix}.filterType.hoursWithoutOperatorMessageForFlowActivation.label`)}:
												</div>
												<div>
													<FastField
														name="hoursWithoutOperatorMessageForFlowActivation"
														render={(props) => {
															return (
																<MDBInput
																	containerClass="mt-0 mb-4"
																	label={t(`${prefix}.filterType.hoursWithoutOperatorMessageForFlowActivation.inputLabel`)} 
																	type="text"
																	value={props.field.value}
																	getValue={(value) => { 
																		props.form.setFieldValue(props.field.name, value) 
																	}}
																/>
															);
														}}
													/>
													<ErrorMessage name="hoursWithoutOperatorMessageForFlowActivation" component="div"
														className="invalid-feedback d-block"
														style={{ marginTop: '0px' }} />
												</div>
											</div>
										</> 
										}

									</>}

                                </>}
								{/* END CHAT */}
								{/* START FEEDBACK */}
                                { values.category === 'FEEDBACK' &&  <>
                                    <div className="d-flex justify-content-start">
                                        <div className="d-none d-md-flex align-items-center mb-2 mr-2" style={{ whiteSpace: 'pre' }}>{t(`${prefix}.1`)}</div>
                                        <div className={(errorOperator) ? 'is-invalid-important pr-2' : 'pr-2'}>
                                            <FastField
                                                name="operator"
                                                render={(props) => {
                                                    return (autoReplyRule.defaultType ? <span><b>{t(`${prefix}.${props.field.value}`)}</b></span> :
                                                        (props.field && <MDBSelect
                                                            selected={t(`${prefix}.operator.label`)}
                                                            options={buildOperators(props.field.value)}
                                                            getValue={(values) => {
                                                                setErrorOperator(isError(errors, touched, props))
                                                                if (props.field.value !== values[0]) {
                                                                    props.form.setFieldValue(props.field.name, values[0])
                                                                    turnOnOnlyForEmptyReviewsAfterOperatorChanged(props, values[0])
                                                                }
                                                            }}
                                                        />)
                                                    );
                                                }}
                                            />
                                            <ErrorMessage name="operator" component="div"
                                                className="invalid-feedback d-block"
                                                style={{ marginTop: '-15px' }} />
                                        </div>
                                        <div className={(errorScore) ? 'is-invalid-important' : ''}>
                                            <FastField
                                                name="score"
                                                render={(props) => {
                                                    return (autoReplyRule.defaultType ? <span>{props.field.value}</span> :
                                                        props.field && <MDBSelect
                                                            selected={t(`${prefix}.score.label`)}
                                                            options={buildScores(props.field.value)}
                                                            getValue={(values) => {
                                                                setErrorScore(isError(errors, touched, props))
                                                                if (props.field.value !== values[0]) {
                                                                    props.form.setFieldValue(props.field.name, values[0])
                                                                    turnOnOnlyForEmptyReviewsAfterScoreChanged(props, values[0])
                                                                }
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <ErrorMessage name="score" component="div"
                                                className="invalid-feedback d-block"
                                                style={{ marginTop: '-15px' }} />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <FastField
                                            name="onlyForEmptyReviews"
                                            render={(props) => {
                                                const label = t(`${prefix}.onlyForEmptyReviews.label`)
                                                return (autoReplyRule.defaultType ? (props.field.value ? <span>{label}</span> : <span></span>) :
                                                    props.field && <MDBSwitch
                                                        labelLeft={t(`${prefix}.onlyForEmptyReviews.label`)} labelRight=""
                                                        checked={props.field.value}
                                                        onChange={() => {
                                                            props.form.setFieldValue(props.field.name, !props.field.value);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div> 
                                </>
								}
								{/* END FEEDBACK */}
								{autoReplyWith === "TEMPLATES" && <>
									<div className="d-md-none pt-2">{t(`${prefix}.4`)}:</div>
									<div className="d-flex justify-content-start pt-2">
										<div className="d-none d-md-flex align-items-center mb-2 mr-2" style={{ whiteSpace: 'pre' }}>{t(`${prefix}.4`)}:</div>
										<div className={(errorReplyTemplates) ? 'is-invalid-important' : ''}>
											<FastField
												name="replyTemplates"
												render={(props) => {
													return (
														props.field && replyTemplates && <MDBSelect
															multiple={values.category === 'FEEDBACK'}
															selected={t(`${prefix}.replyTemplates.label`)}
															options={buildReplyTemplates(props.field.value, values.category)}
															getValue={(values) => {
																setErrorReplyTemplates(isError(errors, touched, props))
																const newFieldValue = selectValuesToFieldValue(values)
																if (!areEquals(newFieldValue, props.field.value)) {
																	props.form.setFieldValue(props.field.name, newFieldValue)
																	if (newFieldValue && newFieldValue.length > 0)
																		props.form.setFieldValue("conversationFlow", null)
																}
															}}
														/>
													);
												}}
											/>
											<ErrorMessage name="replyTemplates" component="div"
												className="invalid-feedback d-block"
												style={{ marginTop: '-15px' }} />
										</div>
										{values.category === 'FEEDBACK' && <MDBTooltip placement="botom" domElement>
											<i className="far fa-question-circle d-flex align-items-center mb-2 ml-1" style={{ color: "#676a6c" }}></i>
											<div>{t(`${prefix}.replyTemplates.tooltip`)}</div>
										</MDBTooltip>}
									</div>
								</>}
                            </MDBContainer>
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-around">
                            <MDBBtn color="danger" size="sm" onClick={() => setShowEditRuleModal(!showEditRuleModal)}>
                                <MDBIcon icon="times" size="1x" /> {t('panels.feedback.settings.cancel')}
                            </MDBBtn>
                            <MDBBtn color="primary" type="submit" size="sm">
                                <MDBIcon icon="edit" size="1x" /> {t('panels.feedback.settings.save')}
                            </MDBBtn>
                        </MDBModalFooter>
                    </Form>
                )}
            </Formik>
        </MDBModal>

        <ReplyTemplateEditModal
            showModal={showReplyTemplateEditModal}
            setShowModal={setShowReplyTemplateEditModal}
            replyTemplate={replyTemplate}
            onSaveReplyTemplate={onSaveReplyTemplate}
            actions={actions}
        />

        <MDBContainer className="mt-3">
            {/* <h1>Active rules: {activeRulesQtty}</h1> */}
            <CollectionVisualizerContainer
                scrollTopButton
                title={t('panels.feedback.settings.rules.title')}
                getData={(page, order, filters) => getData()}
                columns={columns}
                pagination={false}
                rowActions={rowActions}
                headerActions={headerActions}
                defaultFilters={getDefaultFilterValues()}
                alwaysFloatingFilter={true}
                filtersTitle={t('panels.feedback.settings.selectCompany')}
                showFiltersInfo
                availableCompanies={availableCompanies}
                selectedCompany={selectedCompany}
            />
        </MDBContainer>
    </div>)

}

export default withEventTracking(AutoReplyRules)
