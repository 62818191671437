import React from 'react';
import PropTypes from 'prop-types';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './menu.css';

export default class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = { show: false };
    }

	showMenu = (event) => {
		event.preventDefault();
		event.stopPropagation();
        this.setState({ show: true });
    }

    hideMenu = () => {
        this.setState({ show: false });
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.hideMenu);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.hideMenu);
    }

    getMenuClasses = () => {
        var baseClasses = 'menu ';
        if (this.state.show === true) {
            baseClasses += 'position-absolute text-white p-2  bg-secondary animated animated-short fadeInRight';
        }
        else {
            baseClasses += 'd-none ';
        }
        return baseClasses;
    }

    render = () => (
        <div className='d-inline position-relative'>
			<button className={this.props.buttonClasses+' btn btn-link border-0 bg-transparent pr-2'} onClick={this.showMenu}>
                <FontAwesomeIcon icon={faEllipsisV} />
            </button>
            <div
                className={this.getMenuClasses()}
                onMouseLeave={this.hideMenu}
                style={{
                    right: '0',
					top: '0',
					zIndex: 10000
                }}>
                <div className='menu-content'>
                    {this.props.children}
                </div>
            </div>
        </div>
    );
}

Menu.propTypes = {
    children: PropTypes.arrayOf(
        PropTypes.object
    ).isRequired
}
