import { envConfig } from "../config";

export const translations = {
    firstTime: {
        password: {
            es: "Contraseña",
            en: "Password"
        },
        confirmPassword: {
            es: "Confirmar contraseña",
            en: "Confirm password"
        },
        submit: {
            es: "Crear cuenta",
            en: "Create account"
        }
    },
    reset: {
        password: {
            es: "Nueva contraseña",
            en: "New password"
        },
        confirmPassword: {
            es: "Confirmar contraseña",
            en: "Confirm password"
        },
        submit: {
            es: "Crear cuenta",
            en: "Create account"
        }
    },
    passShouldBeTheSame: {
        es: "La contraseñas deben ser iguales",
        en: "The passwords should be the same"
    },
    inputIsRequired: {
        es: "Este campo es requerido",
        en: "This field is required"
    },
    name: {
        es: "Nombre",
        en: "Name"
    },
    lastName: {
        es: "Apellido",
        en: "Last Name"
    },
    avatar: {
        es: "Avatar",
        en: "Avatar"
    },
    chooseImage: {
        es: "Elige una imágen",
        en: "Choose an image"
    },
    dragAndDrop: {
        es: "Arrastra una imagen hasta aquí o usa el botón de abajo",
        en: "Drag and drop an image here or use the button bellow"
    },
    joinYourTeam: {
        es: `¡Únete a tu equipo en ${envConfig.companyName}!`,
        en: `Join your team in ${envConfig.companyName}!`
    },
    subtitle: {
        es: "Para empezar, cuentanos más sobre ti.",
        en: "First things first, tell us a bit about yourself."
    }
}
