import axiosClient from './axiosClient'
import {dateTOYYYYMMDD} from '../commons/utils/formatDate'

export const getSegmentForStores = (storeId, companyId, strict=false) => {
	var url = `/segments/small?storeIds[]=${storeId}&companyId=${companyId}&strict=${strict}`;
    return axiosClient.get(url);
}

export const getPossibleSegments = (campaignType, companyId, pageNumber, mustTargetOnlyAdminStores, selectedId ) => {
		return axiosClient.get('/segments/available', {
				params: {
					campaignType: campaignType,
					companyId: companyId,
					pageNumber: (pageNumber || ''),
					selectedId: (selectedId || null),
					pageSize: 12,
					// subCategoryId: (subCategoryId ? subCategoryId : ''),
					mustTargetOnlyAdminStores: !!mustTargetOnlyAdminStores,
				}
			})
}

export const deleteSegment = (id) => {
	return axiosClient.delete(`/segments?segmentId=${id}`);
}

export const getSegmentUsers = (segment, pageNumber=1, pageSize=10, getIgnoredAndBlacklisted=false, sortField, sortOrder) => {
	let orderBy=sortField? `orderBy=${sortField}&`: '';
	let orderDirection= sortField? `&direction=${sortOrder}`: '';

	if(!segment)
		return;
	return new Promise((resolve) => {
		axiosClient.put(`/segments/users?${orderBy}pageNumber=${pageNumber}&pageSize=${pageSize}&getIgnoredAndBlacklisted=${getIgnoredAndBlacklisted}${orderDirection}`, segment)
			.then((res) =>{
				resolve({segment:segment, users:res.data.data.users, data:{ data: {items: res.data.data.users}}});
			});
	});
}

export const getSegmentMetricsHistory = (segmentId, dateFrom, dateTo) => {
    return axiosClient.get(
        `/segments/${segmentId}/metrics/history`,
        {
            params: {
                dateTo: dateTOYYYYMMDD(dateTo),
                dateFrom: dateTOYYYYMMDD(dateFrom)
            }
        }
    );
}

export const getSegmentMetrics = (segmentId) => {
    return axiosClient.get(`/segments/${segmentId}/metrics`);
}

export const exportUsers = (segment, errorHandler) => {
		var url = `/segments/users/csv`;
		return axiosClient.put(url, segment, {
					timeout: 5000000,
					errorHandler
				});
}
