import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Select } from "botbit-ui-components";
import { getStore, getUserActivity } from '../../api/storeApi';
import { useEffect } from 'react';
import { useCompanyAvailableStores } from '../../commons/utils/useCompanyAvalaibleStores';
import { MDBBtn } from 'mdbreact';


const SelectStoreForm = ({ user, action, type }) => {
  const companyStores = useCompanyAvailableStores();
  const [selectedStore, setSelectedStore] = useState();
  const [userAvailableStores, setUserAvailableStores] = useState();
  const [storeOptions, setStoreOptions] = useState();

  const storesToOptions = async () => {
    const formattedStores = await userAvailableStores.map(async store => {
      const storeRes = await getStore(store.storeId);
      const storeData = storeRes.data.data;
      return {
        value: { ...storeData },
        label: storeData.name,
        text: storeData.name
      };
    });
    Promise.all(formattedStores).then(res => {
      setStoreOptions(res);
    })
  }

  const getUserAvailableStores = async () => {
    const companyStoresIds = companyStores.map(store => store.id);
    const res = await getUserActivity(user.id, companyStoresIds);
    const stores = res.data.data.userInformation.storeRegistrations;
    const storesFilteredByCompany = stores.filter(store => companyStoresIds.includes(store.storeId))
    setUserAvailableStores(storesFilteredByCompany);
  }

  useEffect(() => {
    getUserAvailableStores();
  }, [])

  useEffect(() => {
    if (userAvailableStores) {
      storesToOptions();
    }
  }, [userAvailableStores])

  return (
    <div className="select-store-container">
      {userAvailableStores && storeOptions && (
        <Formik onSubmit={(values, actions) => {
          action(values);
        }}>
          <Form>
            <Field name="store">
              {({ field, form, meta }) => (
                <>
                  <label>Seleccionar tienda</label>
                  <Select
                    label="Seleccionar Tienda"
                    selected="Tienda"
                    value={selectedStore}
                    onChange={setSelectedStore(field.value)}
                    options={storeOptions}
                    isSearchable={true}
                    getValue={(value) => {
                      if (value[0] && field.value !== value[0]) {
                        form.setFieldValue(field.name, value[0]);
                      }
                    }}
                  />
                </>
              )}
            </Field>
            <MDBBtn
              className="uppercase"
              color="primary"
              disabled={!selectedStore}
              type="submit"
            >
              {type}
            </MDBBtn>
          </Form>
        </Formik>
      )}
    </div>
  );
}

export default SelectStoreForm;