import { Field, Form, Formik } from 'formik';
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { translations } from '../../config/translations/Feedback/ReviewRequest';
import translate from '../../config/translations/translate';
import IntlTelInput from "react-intl-tel-input";
import { useCompanyAvailableStores } from "../../commons/utils/useCompanyAvalaibleStores";
import { Select, Modal, ModalHeader, ModalBody } from "botbit-ui-components";
import * as Yup from "yup"
import "./ReviewRequest.scss";
import { useDispatch, useSelector } from 'react-redux';
import { sendReviewRequest } from '../../api/feedbackApi';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loading from '../../commons/components/utils/Loading';
import { useHistory } from "react-router";

import { findByUsername } from '../../api/administratorApi';
import { loginSuccess } from '../login/loginActions';
import CheerUpModal from './CheerUpModal/CheerUpModal';
import withEventTracking from '../../config/withEventTracking';
import { trackers } from "../../config/withEventTracking";
import { getNpsLink } from '../../api/storeApi';
import { useLocation } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const ReviewRequest = ({ t, isOpen, toggle, triggerModalOpen, trackEvent, correlationId }) => {
    const [isEmail, setIsEmail] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const [emailOrPhoneTouched, setEmailOrPhoneTouched] = useState(false);
    const [isCheerUpOpen, setCheerUpOpen] = useState(false);
    const [cheerUpModalStep, setCheerUpModalStep] = useState(1);
    const [keepState, setKeepState] = useState(false);
    const [cheerUpEmail, setCheerUpEmail] = useState();
    const [cheerUpStore, setCheerUpStore] = useState();

    const history = useHistory();

    const dispacth = useDispatch();

    const companyAvailableStores = useCompanyAvailableStores()

    const selectedCompany = useSelector(state => state.application.selectedCompany)
    const admin = useSelector(state => state.login.admin)
    const token = useSelector(state => state.login.token)

    const { search } = useLocation();

    useEffect(() => {
        if (search.includes('send_review_request=true'))
            triggerModalOpen();
    }, [search])

    useEffect(() => {
        if (emailOrPhoneTouched) {
            if (isEmail) {
                document.getElementById("review-request-email").focus()
            } else if (isPhone) {
                document.getElementById("review-request-phone").focus()
            } else {
                document.getElementById("review-request-email-or-phone").focus()
            }
        }
    }, [isEmail, isPhone])

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("fieldRequired")),
        email: Yup.string().matches(/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/, t("shouldBeAValidEmail")),
        phone: Yup.mixed().test("test-phone", "Teléfono inválido",
            (value) => {
                if (value === undefined || value === null || value === "") return true;
                if (!value.startsWith('+'))
                    value = '+' + value;
                const phoneNumber = parsePhoneNumberFromString(value);
                return phoneNumber !== undefined && phoneNumber.isValid();
            }),
        store: Yup.mixed().required(t("fieldRequired")),
        emailOrPhone: Yup.string().required()
    })

    const getStoresOptions = () => {
        const storesFiltered = companyAvailableStores.filter(store => store.status === "ACTIVE").map((store, index) => ({
            value: store.id,
            checked: (index === 0),
            text: store.name
        }))
        return storesFiltered;
    }

    const triggerSuccessSwal = () => {
        MySwal.fire({
            type: "success",
            text: t("successMsg"),
            showCloseButton: true,
            confirmButtonText: t("newRequest"),
            confirmButtonColor: "#ff7043"
        }).then(res => {
            if (res.value) {
                triggerModalOpen()
            }
        })
    }

    const submitValues = values => {
        // const metadata = {
        //     channel: isEmail ? 'email' : 'SMS',
        //     through: 'review_request_main_button'
        // }
        // Adrian: 2021-03-05:
        // el evento de review_requested ya no se trackea más por acá
        // ahora es el backend, en el mismo send, el que va a emitir
        // el evento a mixPanel y a intercom
        //trackEvent("review_requested", metadata, checkIfFirstReviewRequest())
        const dataObj = {
            storeUsers: [{
                storeId: values.store,
                userName: values.name,
                userEmail: values.email,
                userPhone: values.phone,
            }],
            companyId: selectedCompany.id,
            executionMode: "MANUAL",
            source: window.location.pathname,
            correlationId: correlationId
        }
        sendReviewRequest(dataObj)
            .then(res => {
                if (res.status === 200) {
                    if (checkIfFirstReviewRequest() || keepState) {
                        // window.Intercom('update', { "review_request_sent_last_30": 1 });
                        openCheerUpModal(values.email, values.store);
                        findByUsername(admin.username)
                            .then(response => {
                                dispacth(loginSuccess({ administrator: response.data.data, token: token }, ""));
                            })
                    } else triggerSuccessSwal();
                }
            })
    }

    const checkIfFirstReviewRequest = () => {
        if (!admin.adminState || admin.adminState.length === 0) return true;
        admin.adminState.forEach(object => {
            for (const [key, value] of Object.entries(object)) {
                if (key === "id" && value === "REVIEW_REQUEST_SENT") return true;
            }
        })
        return false;
    }

    const getInitialValues = () => {
        const initialValues = {
            name: "",
            emailOrPhone: "",
            store: (getStoresOptions().length > 0)
                ? getStoresOptions()[0].value
                : undefined
        }
        if (checkIfFirstReviewRequest()) {
            return {
                ...initialValues,
                name: admin.name,
                emailOrPhone: admin.email,
                email: admin.email
            }
        }
        return initialValues;
    }

    const openCheerUpModal = (email, store) => {
        setCheerUpEmail(email);
        setCheerUpStore(store);
        setCheerUpOpen(true);
    };

    const closeCheerUpModal = () => setCheerUpOpen(!isCheerUpOpen);

    const closeModal = () => {
        if (window.location.search.includes("?send_review_request=true")) {
            history.replace("")
        }
        setEmailOrPhoneTouched(false);
        setIsPhone(false);
        setIsEmail(false);
        toggle();
    }

    const goHome = (maintainState) => {
        if (maintainState) setKeepState(true);
        else setKeepState(false);
        setCheerUpOpen(false);
        triggerModalOpen()
    }

    const getStoreNameFromId = storeId => {
        const correspondingStore = getStoresOptions().find(store => store.value === storeId);
        if (correspondingStore) return correspondingStore.text;
    }

    const cleanPhoneNumber = number => {
        const cleanedNumber = number.split(" ").join("").split("-").join("");
        return cleanedNumber;
    }

    return (
        <>
            <CheerUpModal
                isCheerUpOpen={isCheerUpOpen}
                closeCheerUpModal={closeCheerUpModal}
                admin={admin}
                step={cheerUpModalStep}
                goToStep={setCheerUpModalStep}
                goHome={goHome}
                email={cheerUpEmail}
                store={cheerUpStore}
                t={t}
            />
            <Modal className={`review-request${isOpen ? " no-animation" : ""}`} id="review-request" isOpen={isOpen} toggle={() => { }}>
                {companyAvailableStores ? <>

                    {getStoresOptions().length > 0
                        ? <>
                            <ModalHeader toggle={closeModal}></ModalHeader>

                            <ModalBody>
                                {
                                    (getStoresOptions().length > 1) &&
                                    <div className="form-field">
                                        <div className="md-form">
                                            <label className="active">{t("title")}</label>
                                        </div>
                                    </div>
                                }

                                {
                                    (getStoresOptions().length === 1) &&
                                    <div className="storeOnly">
                                        {t("altTitle")} <span>{getStoresOptions()[0].text}</span>
                                    </div>
                                }

                                <Formik
                                    onSubmit={values => {
                                        submitValues(values);
                                        closeModal()
                                    }}
                                    initialValues={getInitialValues()}
                                    validationSchema={validationSchema}
                                >
                                    {({ values, setFieldValue, errors, touched, setFieldError, resetForm }) => (<>
                                        <Form>
                                            <Field name="store">
                                                {({ field }) => (
                                                    <div className="form-field botbit-select">
                                                        {(getStoresOptions().length > 1) &&
                                                            <Select
                                                                // label={t("title")}
                                                                data-intercom-target="review_request_store"
                                                                selected={t("store")}
                                                                value={field.value}
                                                                disabled={getStoresOptions().length === 1}
                                                                options={getStoresOptions()}
                                                                getValue={(value) => {
                                                                    if (value[0] && field.value !== value[0]) {
                                                                        setFieldValue(field.name, value[0]);
                                                                    }
                                                                }}
                                                            />}
                                                        {errors.store && touched.store && <span className="form-error">{t("fieldRequired")}</span>}
                                                    </div>)}
                                            </Field>
                                            <Field name="name">
                                                {({ field }) => (
                                                    <div className="form-field">
                                                        <MDBInput
                                                            value={field.value}
                                                            label={t("sendTo")}
                                                            hint={t("name")}
                                                            data-intercom-target="review_request_name"
                                                            getValue={value => {
                                                                if (value !== field.value) setFieldValue(field.name, value)
                                                            }}
                                                        />
                                                        {errors.name && touched.name && <span className="form-error">{t("fieldRequired")}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                            {(!isEmail && !isPhone) &&
                                                <Field name="emailOrPhone">
                                                    {({ field }) => (
                                                        <div className="form-field">
                                                            <MDBInput
                                                                valueDefault={field.value}
                                                                label={t("emailOrPhone")}
                                                                hint={t("emailOrPhone2")}
                                                                data-intercom-target="review_request_phone_or_email"
                                                                id="review-request-email-or-phone"
                                                                getValue={value => {
                                                                    if (value.length > 4) {
                                                                        setEmailOrPhoneTouched(true)
                                                                        if (/([a-zA-Z])/.test(value)) {
                                                                            setFieldValue("email", value)
                                                                            setFieldValue(field.name, "email")
                                                                            setIsEmail(true)
                                                                        } else {
                                                                            setFieldValue("phone", value)
                                                                            setFieldValue(field.name, "phone")
                                                                            setIsPhone(true)
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            {errors.emailOrPhone && touched.emailOrPhone && <span className="form-error">{t("fieldRequired")}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            }
                                            {isEmail &&
                                                <Field name="email">
                                                    {({ field }) => (
                                                        <div className="form-field">
                                                            <MDBInput
                                                                value={field.value}
                                                                label={t("emailOrPhone")}
                                                                id="review-request-email"
                                                                getValue={value => {
                                                                    if (value.length > 4) {
                                                                        setFieldValue(field.name, value)
                                                                        setFieldValue("phone", undefined);
                                                                    }
                                                                    else {
                                                                        setFieldValue("emailOrPhone", value)
                                                                        setFieldValue(field.name, undefined)
                                                                        setIsEmail(false)
                                                                    }
                                                                }}
                                                            />
                                                            {errors.email && touched.email && <span className="form-error">{t("shouldBeAValidEmail")}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            }
                                            {isPhone &&
                                                <Field name="phone">
                                                    {({ field }) => (

                                                        <div className="form-field position-relative">
                                                            <div className="md-form">

                                                                <label id="md-form-label" className="active">{t("emailOrPhone")}</label>

                                                                <IntlTelInput
                                                                    fieldId="review-request-phone"
                                                                    value={field.value}
                                                                    inputClassName="form-control"
                                                                    containerClassName="intl-tel-input md-form"
                                                                    defaultCountry={selectedCompany.country.toLowerCase()}
                                                                    onPhoneNumberFocus={() => {
                                                                        document.getElementById("md-form-label").classList.add("focused")
                                                                    }}
                                                                    onPhoneNumberBlur={(valid, _raw, _data, formatted) => {
                                                                        document.getElementById("md-form-label").classList.remove("focused")
                                                                        if (valid) setFieldValue(field.name, formatted)
                                                                    }}
                                                                    onPhoneNumberChange={(valid, raw, _data, formatted) => {
                                                                        if (raw.length > 4) {
                                                                            if (raw.includes('@')) {
                                                                                setFieldValue("email", raw);
                                                                                setFieldValue(field.name, undefined)
                                                                                setIsEmail(true)
                                                                                setIsPhone(false)
                                                                            } else {
                                                                                setFieldValue(field.name, raw);
                                                                                setFieldValue("email", undefined);
                                                                            }
                                                                        }
                                                                        else {
                                                                            setFieldValue("emailOrPhone", raw)
                                                                            setFieldValue(field.name, undefined)
                                                                            setIsPhone(false)
                                                                        }
                                                                    }}
                                                                />

                                                                {errors.phone && touched.phone && <span className="form-error">{t("shouldBeAValidPhone")}</span>}

                                                            </div>
                                                        </div>

                                                    )}
                                                </Field>
                                            }
                                            <MDBBtn
                                                type="submit"
                                                className="submit-btn"
                                                color="deep-orange"
                                                data-intercom-target="review_request_send_button"
                                                size="lg"
                                            >
                                                <MDBIcon far className="mr-1" icon="paper-plane" />{isPhone ? t("sendSMS") : t("submit")}
                                            </MDBBtn>
                                            {isPhone &&
                                                <MDBBtn
                                                    color="primary"
                                                    size="lg"
                                                    className="submit-btn-2  whatsapp-btn"
                                                    onClick={() => {
                                                        const { store, phone, name } = values;
                                                        const phoneRaw = cleanPhoneNumber(phone);
                                                        // let npsLink = `${envConfig.frontendPhpUrl}/nps?storeId=${store}&phone=${encodeURIComponent(phoneRaw)}&name=${name}&emailUniqueId=${store + Date.now()}&adminId=${admin.id}&utm_source=BotBit&utm_medium=whatsapp&utm_campaign=nps&correlationId=${correlationId}`;
                                                        // shorten(npsLink)
                                                        let npsLink;
                                                        getNpsLink({
                                                            name,
                                                            phone: phoneRaw,
                                                            storeId: store,
                                                            correlationId,
                                                            userId: null
                                                        })
                                                            .then(res => {
                                                                const shortenNpsLink = res.data.data.url
                                                                npsLink = shortenNpsLink
                                                            })
                                                            .catch(e => console.log(`Link couldn't be shortened, using default link: ${e}`))
                                                            .finally(() => {
                                                                const message = `¡Hola ${name}! Cuéntanos tu experiencia con ${getStoreNameFromId(store)} ${encodeURIComponent(npsLink)}. Si no te aparece el link en azul escribe "ayuda".`
                                                                const url = (`https://api.whatsapp.com/send?phone=${phone}&text=${message}`)
                                                                window.open(url, "_blank")
                                                                trackEvent("REVIEW_REQUEST_REQUESTED", {
                                                                    correlationId: correlationId,
                                                                    channel: 'WHATSAPP',
                                                                    storeId: store
                                                                }, false, [trackers.BACKEND])

                                                                closeModal();
                                                                setTimeout(resetForm(), 1000);
                                                            })
                                                    }}
                                                ><MDBIcon fab className="mr-1" icon="whatsapp" size="lg" /> {t("sendWhatsapp")}</MDBBtn>
                                            }
                                        </Form>
                                    </>)}
                                </Formik>
                            </ModalBody>
                        </>
                        : <>
                            <ModalHeader toggle={toggle}></ModalHeader>
                            <ModalBody>
                                <h3>{t("noAvailablestores")}</h3>
                            </ModalBody>
                        </>}
                </>
                    : <Loading />
                }
            </Modal>

        </>
    );
}

ReviewRequest.translations = { ...translations }

export default withEventTracking(translate(ReviewRequest));
