import React from 'react'
import './campaigns.css'
import { ActivateCampaignCard } from './ActivateCampaignCard'
import { translate } from 'react-i18next'
import { getActivableCampaigns } from '../../api/campaignsApi';
import { HorizontalScroll } from 'botbit-ui-components';
import { envConfig } from '../../config/config'


class ActivateFirstCampaign extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activableCampaigns: [],
            loading: true
        }
    }

    componentDidUpdate = (oldProps, newProps) => {
        if (oldProps.companyId !== newProps.companyId && newProps.companyId !== undefined) {
            this.setState({ loading: true })
            this.getActivableCampaigns(newProps.companyId);
        }
    }

    componentDidMount = () => {
        this.getActivableCampaigns(this.props.companyId);
    }

    getActivableCampaigns = () => {
        //traer las campanias activables
        getActivableCampaigns(this.props.companyId)
            .then((response) => {
                if (!response.data.data || response.data.data.length === 0) {
                    this.props.onEmpty !== undefined && this.props.onEmpty();
                }
                this.setState({
                    activableCampaigns: (response && response.data.data ? response.data.data : [])
                })
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    getCampaignThumbnail = (type) => {
        let path = "stores/templates/default_";
        switch (type) {
            case "BIRTHDAY":
                path += "birthdaysA_mainImage.gif"
                break;
            case "LOYAL":
                path += "loyals_mainImage.gif"
                break;
            case "NEW_USER":
                path += "newUsers_mainImage.jpg"
                break;
            default:
                path += "iddleUsersA_mainImage.gif"
                break;
        }
        //path += "_mainImage.gif";

        return envConfig.staticImgUrl + path;
    }

    renderActivableCampaignCards = () => {
        const blankCampaign = { key: 100, subCategoryName: "BLANK" }
        return this.state.activableCampaigns.map(campaignCategory =>
            <ActivateCampaignCard
                onClick={() => this.props.onCardClick(campaignCategory)}
                className="activateCampaignCardComponent"
                key={campaignCategory.id}
                imageSrc={this.getCampaignThumbnail(campaignCategory.categoryName || campaignCategory.subCategoryName)}
                categoryName={campaignCategory.subCategoryName}
                id={campaignCategory.id}
                title={this.props.t('panels.campaigns.otherLabels.' + campaignCategory.subCategoryName)}
                text={this.props.t('panels.campaigns.otherLabels.' + campaignCategory.subCategoryName + "_Desc")}
            />).concat((this.state.activableCampaigns.length > 0 && this.props.withBlank) ? [
                <ActivateCampaignCard
                    onClick={() => this.props.onCardClick(blankCampaign)}
                    className="activateCampaignCardComponent"
                    key={"blank"}
                    imageSrc={envConfig.imgUrl + "img/blank-layout.png"}
                    title={this.props.t('panels.campaigns.otherLabels.blankPage')}
                    text={this.props.t('panels.campaigns.otherLabels.blankPage_Desc')}
                />] : []);
    }
    render() {
        if (!this.state.activableCampaigns || this.state.activableCampaigns.length === 0) {
            return null;
        }
        return (
            <div className="float-left w-100" id="activateFirstCampaignDiv">
                {this.props.title &&
                    <h3>{this.props.title}</h3>
                }
                {this.props.withText && <div>
                    <p id="activateTitle">
                        {this.props.t('panels.campaigns.otherLabels.activateFirstCampaign')}
                    </p>
                    <p id="activateParagraph" className="col-lg-6 col-md-12">
                        {this.props.t('panels.campaigns.otherLabels.activateFirstCampaign_Desc')}
                    </p>
                </div>}
                <div className="mt-3 d-block multipleCampaignCardContainer">
                    {this.props.withHorizontalScroll && <HorizontalScroll>
                        {this.renderActivableCampaignCards()}
                    </HorizontalScroll>}
                    {!this.props.withHorizontalScroll && this.renderActivableCampaignCards()}
                </div>
            </div>
        )
    }
}
export default translate('common')(ActivateFirstCampaign)