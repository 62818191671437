import React, { useEffect, useRef, useState } from 'react';
import Message from './Message';
import { useMessengerData } from './useMessengerData';
import useOnScreen from "../../commons/utils/useOnScreen"
import { getMessagesFromConversaion } from '../../api/messengerApi';

const MessagesList = () => {
    const [noMoreMessages, setNoMoreMessages] = useState(true);

    const { messages, selectedChat, setMessages } = useMessengerData();
    const messagesContainer = useRef();
    const topOfConversation = useRef();
    let [firstLoad, setFirstLoad] = useState(true);
    const isInTheTop = useOnScreen(topOfConversation);

    useEffect(() => {
        setFirstLoad(true)
    }, [selectedChat.id])
    
    useEffect(() => {
        goToTheBottomOfTheList();
        if (messages && messages.length > 0) {
            setNoMoreMessages(false);
        }
    }, [messages.length])

    useEffect(() => {
        (() => {if (isInTheTop && messages && messages.length > 0 && !noMoreMessages) {
            const requestBody = {
                conversationId: selectedChat.id,
                totalResults: 50,
                lastMessageTimestamp: new Date(messages[0].requestedTimestamp)
            }
            getMessagesFromConversaion(requestBody).then(res => {
                const newMessagesList = res.data.data;

                if (newMessagesList.length === 0) {
                    setNoMoreMessages(true);
                    return
                }
                const positionFromTheBottom = messagesContainer.current.scrollHeight - messagesContainer.current.scrollTop;
                setMessages(prev => [...newMessagesList, ...prev]);
                handleScrollInPagination(messagesContainer, positionFromTheBottom)
            })
        }})()
    }, [isInTheTop, messages.length])

    const goToTheBottomOfTheList = () => {
        if (messagesContainer && messagesContainer.current && messages.length > 0 && ((messagesContainer.current.scrollTop + 1000) > messagesContainer.current.scrollHeight || firstLoad))
            setTimeout(() => {
                if(!messagesContainer.current)
                    return;
                messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight;
                setFirstLoad(false);
            }, 200)
    }

    const handleScrollInPagination = (elem, positionFromTheBottom) => {
        // This function will try to maintain the position of the scrolling component
        // frm the bottom
        elem.current.scrollTop = elem.current.scrollHeight - positionFromTheBottom
    }

    return (
        <div ref={messagesContainer} className="messages-container">
            {!noMoreMessages &&
                <div className="top-of-the-conversation lds-ellipsis">
                    <div></div><div></div><div></div><div></div>
                </div>
            }
            <div ref={topOfConversation} />
                <ul>
                    {messages.map(m => (
                        <div className="message-wrapper" key={m.id}>
                            <Message
                                messageObj={m}
                                >
                                {m.referredMessage && <div className="referred-message-wrapper"><Message
                                    messageObj={m.referredMessage}
                                    /></div>}        
                            </Message>
                        </div>
                    ))}
                </ul>
        </div>
    );
}

export default MessagesList;