import React, { useEffect } from 'react';
import MessengerCore from '../Messenger/MessengerCore';
import "./MessengerWrapper.scss"
import ChatsContainer from './ChatsContainer';
import useWindowDimensions from '../../commons/utils/useWindowDimensions';
import { useMessengerData, getMessengerData, MessengerContext } from './useMessengerData';
import Loading from '../../commons/components/utils/Loading';
import { useParams } from "react-router-dom"

const MessengerWrapper = () => {
    const {
        conversationOpen,
        isAppLoading
    } = useMessengerData()

    const { isMedium } = useWindowDimensions();

    const {conversationId} = useParams();

    useEffect(() => {
        window.Intercom('update', {hide_default_launcher: true})
        return () => window.Intercom('update', {hide_default_launcher: false})
    }, [])

    const chatWidth = conversationId!==undefined ? " w-100" : "";

    return (
        <div className={"container m-0 p-0 no-footer mw-100 w-100 messenger-wrapper"+chatWidth}>
            <Loading loading={isAppLoading} fullScreen />
            <div className="row m-0 sections-container">
                {conversationId===undefined && <ChatsContainer hide={(conversationOpen && isMedium)} />}
                {/* {(!conversationOpen || !isMedium) && <ChatsContainer />} */}
                {(conversationOpen || !isMedium) && <div className={"col-12 col-md-8 p-0 current-chat-container"+chatWidth}>
                    <MessengerCore showBack={conversationId===undefined} />
                </div>}
            </div>
        </div>
    );
}

const Messenger = () => {
    const {conversationId} = useParams();
    return (
        <MessengerContext.Provider value={getMessengerData(conversationId)}>
            <MessengerWrapper />
        </MessengerContext.Provider>
    )
}

export default Messenger;