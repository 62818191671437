import React from 'react';
import { MDBIcon, MDBContainer } from 'mdbreact';
import { useMessengerData } from "./useMessengerData";
import CustomInput from './CustomInput';

//import { Document } from 'react-pdf/dist/esm/entry.webpack'

const ImagePreviewer = () => {
    const {previewingImage, setIsImagePreviewerOpen, chatController} = useMessengerData()
    const chatCtl = chatController();

    const handleSend = (textValue) => {
        chatCtl.setActionResponse({
            ...previewingImage,
            value: textValue
        });
        setIsImagePreviewerOpen(false)
    }

    //console.log("ImagePreviewer previewingImage", previewingImage)

    return (
        <div className="img-previewer-container">
            <MDBIcon onClick={() => setIsImagePreviewerOpen(false)} className="close-icon" icon="times" />
            <div className="img-container">
                <div className="img-object">
                    { previewingImage.attachType === "IMAGE" && <img src={previewingImage.fileUrl} alt="to sent" />}
                    { previewingImage.contentType === "application/pdf" && <embed src={previewingImage.fileUrl} alt="to sent" />}
                    {/* { previewingImage.contentType === "application/pdf" && <Document 
                        file={previewingImage.file} 
                        onLoadError={(e) => console.log("pdf error", e)} 
                        onSourceError={(e) => console.log("pdf error", e)} 
                    /> } */}
                </div>
            </div>
            <MDBContainer className="img-previewer-input-container">
                <CustomInput minified externalSend={handleSend}/>
            </MDBContainer>
        </div>
    );
}

export default ImagePreviewer;