import React, { Component } from "react"
import { MDBContainer } from "mdbreact";
import './feedbackCenter.css'
import { connect } from 'react-redux'
import Review from './Review'
import { setLoading } from '../../commons/components/application/miscActions'

import { getReview, reply, report, getReplyTemplates, saveReplyTemplate } from '../../api/feedbackApi'
import { Views } from "./Views";

const mapStateToProps = (state) => {
    return {
		availableCompanies: state.application.availableCompanies,
		selectedCompany: state.application.selectedCompany,
		admin: state.login.admin,
	    availableStores: state.application.availableStores
    }
}

class ReviewContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
        review: undefined,
        replyTemplates: [],
        reply: false
    }

    getReview(this.props.match.params.reviewId).then(response => {
        if (!response || !response.data || !response.data.data) return
        const params = new URLSearchParams(this.props.location.search)
        const reply = params.get('reply')
    
        this.setState({
          review: response.data.data,
          reply: reply === 'true'
        })

        this.getReplyTemplates()
    })
  }

  getReplyTemplates = async () => {
	const company = this.props.selectedCompany; // this.props.availableCompanies.find(company => company.id === this.props.selectedCompany.id);
	if (!company.operations.includes("REPLY_TEMPLATES__SELECT"))
		return;
    const response = await getReplyTemplates(this.getSelectedCompany(), 'FEEDBACK');
    if (!response || !response.data || !response.data.data) return;
    const templates = response.data.data;
    const replyTemplates = templates.map(t => {
      return { text: t.name, value: t.id.toString() };
    });
    this.setState({ replyTemplates: replyTemplates });
  }  

  postReply = (comment) => {
    this.props.setLoading(true);
    const promise = new Promise((resolve) => {
      reply(this.state.review.id, comment).then(() => {
        resolve('success');
      }).finally(() => {
        this.props.setLoading(false);
      });
    })
    return promise;
  }

  postReport = (comment) => {
    this.props.setLoading(true);
    const promise = new Promise((resolve) => {
      report(this.state.review.id, comment).then(() => {
        resolve('success');
      }).finally(() => {
        this.props.setLoading(false);
      });
    })
    return promise;
  }

  saveReplyTemplate = (replyTemplate) => {
    const promise = new Promise((resolve) => {
      saveReplyTemplate(replyTemplate).then(response => {
        const newTemplate = response.data.data        
        this.setState({ replyTemplates: [...this.state.replyTemplates, {
          value: newTemplate.id.toString(),
          text: newTemplate.name
        }] })
        resolve('sucess')
      })
    })
    return promise;
  }

  redirectToReviews = () => {
    this.props.history.push(Views.REVIEWS_VIEW)
  }

  redirectToReplyFalse = () => {
    this.props.history.push(`/feedback/reviews/${this.state.review.id}?reply=false`)
  }

  getSelectedCompany = () => {
    return this.state.review && this.state.review.companyId
  }

  checkIfStoreIsActive = (storeId) => {
    const selectedStore = this.props.availableStores.find(store => store.id === storeId);
    const isActive = selectedStore && selectedStore.status === "ACTIVE";
    // console.log(storeId)
    return isActive;
  }

  render() {
    return (<>
        {this.state.review && <>
            <MDBContainer className="mt-3">                
                <Review
                    mode="standalone"
                    review={this.state.review}
                    getReview={getReview}
                    onReply={this.postReply}
                    onReport={this.postReport}
                    replyTemplates={this.state.replyTemplates}
                    onSaveReplyTemplate={this.saveReplyTemplate}
                    admin={this.props.admin}
                    reply={this.state.reply}
                    redirectToReviews={this.redirectToReviews}
                    redirectToReplyFalse={this.redirectToReplyFalse}
                    selectedCompanyId={this.state.review.companyId}
                    isActive={this.checkIfStoreIsActive(this.state.review.storeId)}
                />
            </MDBContainer>
        </>}
    </>)
  }
}

export default connect(mapStateToProps, { setLoading })(ReviewContainer)