import { envConfig } from "../../config";

export const translations = {
    profilePicture: {
        es: "Foto de perfil",
        en: "Profile picture"
    },
    watchReviewHistory: {
        es: "Ver historial de reseñas",
        en: "Go to review history"
    },
    profileOf: {
        es: "Perfil de",
        en: "Profile: "
    },
    detail: {
        es: "Detalle",
        en: "Detail"
    },
    ratedYouWith: {
        es: "te calificó con",
        en: "rated you with"
    },
    inOurSatisfactionSurvey: {
        es: "en nuestra encuesta de satisfacción",
        en: "in our satisfaction survey"
    },
    later: {
        es: "más tarde",
        en: "later"
    },
    leftAReview: {
        es: "dejó una reseña de",
        en: "left a review of"
    },
    in: {
        es: "en",
        en: "in"
    },
    reply: {
        es: "Responder",
        en: "Reply"
    },
    replyOtherReviews: {
        es: "Responder otras reseñas",
        en: "Reply other reviews"
    },
    replyBy: {
        es: "Respondido por",
        en: "Reply by"
    },
    edit: {
        es: "Editar",
        en: "Edit"
    },
    add: {
        es: "Agregar",
        en: "Add"
    },
    reviewStimulatedBy: {
        first: {
            es: "Esta reseña fue estimulada por",
            en: "This review was stipulated by"
        },
        second: {
            es: "a partir de la encuesta de satisfacción enviada.",
            en: "from a sent customer satisfaction survey."
        }
    },
    reviews: {
        es: "Reseñas",
        en: "Reviews"
    },
    seeDetail: {
        es: "Ver detalle",
        en: "See detail"
    },
    atBotBit: {
        es: `en ${envConfig.companyName}`,
        en: `at ${envConfig.companyName}`
    },
    reviewNotByBotBit: {
        first: {
            es: `Si creés que esta reseña no fue estimulada por ${envConfig.companyName} por favor hacé click`,
            en: `If you believe this review was not stimulated by ${envConfig.companyName}, please click`
        },
        second: {
            es: "acá",
            en: "here"
        },
        third: {
            es: "¡Gracias!",
            en: "Thanks!"
        }
    },
    reviewWhyNot: {
        es: `¿Por qué no es una reseña estimulada por ${envConfig.companyName}?`,
        en: `Why is this review not stimulated by ${envConfig.companyName}?`
    },
    reviewAt: {
        es: "Reseña en",
        en: "Review at"
    },
    reviewAdvice: {
        first: {
            es: "Los negocios que responden reseñas son",
            en: "Business that reply reviews are"
        },
        second: {
            es: "más propensos a elevar su calificación",
            en: "more likely to get a better rating"
        },
        third: {
            es: "media estrella",
            en: "(around half a star)"
        },
        fourth: {
            es: "en 6 meses.",
            en: "in 6 months."
        }
    },
    reviewWasEdited: {
        first: {
            es: "Esta reseña fue modificada por",
            en: "This review was edited by"
        },
        second: {
            es: "en una fecha posterior a la respuesta de",
            en: "at a later date to the reply from"
        }
    },
    answerToUserMail: {
        es: "La respuesta será enviada por email al usuario que escribió esta reseña.",
        en: "The reply will be send to this user email"
    },
    answerWillPublish: {
        es: "La respuesta saldrá publicada en",
        en: "The reply will be publish at"
    },
    minutes: {
        es: "minutos",
        en: "minutes"
    },
    seconds: {
        es: "segundos",
        en: "seconds"
    },
    shareReviews: {
        es: "Compartir reseña en:  ",
        en: "Share review on:  "
    },
    storeSuspended: {
        es: "Tienda suspendida",
        en: "Store suspended"
    },
    chooseTemplate: {
        es: "Elija una plantilla",
        en: "Choose a template"
    },
    writeAnswer: {
        es: "Escriba su respuesta",
        en: "Write your answer"
    },
    commented: {
        es: " comentó: ",
        en: " commented: "
    },
    recommend: {
        es: " recomienda ",
        en: " recommend "
    },
    recommendNot: {
        es: " no recomienda ",
        en: " does not recommend "
    },
    recommendFrom: {
        first: {
            es: "La recomendación de ",
            en: "The recommendation from "
        },
        second: {
            es: " es neutral",
            en: " is unbiased"
        }
    },
    admin: {
        es: "Administrador",
        en: "Admin"
    },
	scoreStatsSuffix: {
		es: 'de respuestas con 5 estrellas',
		en: 'of 5 stars answers'
	},
	optionsStatsSuffix: {
		es: 'del total de respuestas',
		en: 'of total answers'
	}
}