import React, { useEffect, useState } from 'react';
import { Table, Filters } from "botbit-ui-components"
import { getAdministratorsForCompany, sendWelcomeEmail, deleteAdmin, resetPassword, activateAdmin, deactivateAdmin } from '../../api/administratorApi';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Admins/Administrators";
//import useWindowDimensions from "../../commons/utils/useWindowDimensions";

import "./Administrators.scss"
//import AdminInviteModal from '../AdminInvite/AdminInviteModal';
import withEventTracking from '../../config/withEventTracking';
//import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../commons/components/application/miscActions';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import { MDBBadge } from 'mdbreact';

const MySwal = withReactContent(Swal);

const Administrators = ({ t, trackEvent }) => {
	//const availableStores = useSelector(state => state.application.availableStores);
	const availableCompanies = useSelector(state => state.application.availableCompanies)
	const availableStores = useSelector(state => state.application.availableStores)
	const selectedCompany = useSelector(state => state.application.selectedCompany);
	const loggedAdmin = useSelector(state => state.login.admin);
	//const [isAdminInviteModalOpen, setIsAdminInviteModalOpen] = useState(false);
	const [filterValues, setFilterValues] = useState(null)
	const dispatch = useDispatch();
	const history=useHistory();

	//const { isMedium } = useWindowDimensions();

	useEffect(() => {
		let newFilterValues = { companies: [selectedCompany.id] }
		if (JSON.stringify(filterValues) !== JSON.stringify(newFilterValues)) {
			setFilterValues({ ...newFilterValues })
		}
	}, [])

	const getRowActions = (row, onchange) => {
		let rowActions = [
			{
				key: "admin-actions-edit",
				icon:"edit",
				label: t("rowActions.edit"),
				onClick: () => { 
					history.push("/settings/administrators/"+row.id)
				}
			},
			{
				key: "admin-actions-send-email",
				icon:"envelope",
				label: t("rowActions.sendEmail"),
				onClick: () => sendWelcomeEmail(row.id).then((response) => {
					MySwal.fire({
						type: "success",
						text: t("rowActions.results.sendEmail"),
						confirmButtonText: t("rowActions.results.close"),
						confirmButtonColor: "#ff7043"
					})
				}),
			}, 
		]
		if (loggedAdmin.objectAccess === "ALL") {
			rowActions.push(
				{
					key: "admin-actions-reset-password",
					icon:"key",
					label: t("rowActions.resetPassword"),
					onClick: () => resetPassword(row.id).then((response) => {
						MySwal.fire({
							type: "success",
							text: t("rowActions.results.resetPassword"),
							confirmButtonText: t("rowActions.results.close"),
							confirmButtonColor: "#ff7043"
						})
					}),
				}
			);
		}
		if (loggedAdmin.operations.includes("ADMINISTRATORS__ACTIVATE")) {
			const action = row.active ? "deactivate" : "activate";
			rowActions.push(
				{
					key: "admin-actions-activation",
					icon:"trash",
					label: t("rowActions." + action),
					onClick: () => MySwal.fire({
						type: "warning",
						text: t("rowActions.confirmation." + action),
						showCancelButton: true,
						showConfirmButton: true,
						confirmButtonText: t("rowActions.confirmation.confirm"),
						confirmButtonColor: "#ff7043",
						cancelButtonText:  t("rowActions.confirmation.cancel")
					}).then(res => {
						if (res.value) {
							var invokation = row.active ? deactivateAdmin : activateAdmin;
							invokation(row.id).then((response) => {
								onchange(response.data.data, "update");
								MySwal.fire({
									type: "success",
									text: t("rowActions.results.success"),
									confirmButtonText: t("rowActions.results.close"),
									confirmButtonColor: "#ff7043"
								})
							})
						}
					})
				});
		}
		if (loggedAdmin.objectAccess === "ALL") {
			rowActions.push(
				{
					key: "admin-actions-delete",
					icon:"trash",
					label: t("rowActions.delete"),
					onClick: () => MySwal.fire({
						type: "warning",
						text: t("rowActions.confirmation.delete"),
						showCancelButton: true,
						showConfirmButton: true,
						confirmButtonText: t("rowActions.confirmation.confirm"),
						confirmButtonColor: "#ff7043",
						cancelButtonText:  t("rowActions.confirmation.cancel")
					}).then(res => {
						if (res.value) {
							deleteAdmin(row.id).then((response) => {
								if(response.data.data.deleted)
									onchange(row, "delete");
								else
									onchange({...row, active:false}, "update");
								MySwal.fire({
									type: "warning",
									text: response.data.data.message,
									confirmButtonText: t("rowActions.results.close"),
									confirmButtonColor: "#ff7043"
								})
							})
						}
					})
				});
		}
		
		return rowActions;
	}

	const getColumns = () => {
		let columns =
			[{
				dataField: "avatar",
				title: t(""),
				size: 10,
				// className: "mb-2",
				formatter: (cellContent, row) => {
					let imgsrc = `https://admin.botbit.io/static/img/avatars/${cellContent && cellContent !== "" ? cellContent : 'noavatar.jpg'}`;
					return (
						<div className="w-100">
							<img src={imgsrc} className="rounded-circle" style={{ width: "45px" }} alt="" />
						</div>
					);
				},
				}, {
					dataField: "name",
					title: t("adminName"),
					size: 40,
					// className: "mb-2",
					formatter: (cellContent, row) => {
						return `${cellContent && cellContent} ${row.lastName && row.lastName}`;
					},
				}, {
					dataField: "email",
					title: t("email"),
					size: 30,
					// className: "mb-2",
					formatter: (cellContent, row) => {
						return <a href={`mailto:${cellContent}`}>{cellContent}</a>
					}, 
				}, {
					dataField: 'active',
					title: t('adminStatus.label'),
					size: 10,
					className: 'my-auto d-none d-md-block',
					formatter: (cellContent, row) => {
						return (
							<MDBBadge color={row.active === true ? 'success' : 'danger'}>
								{ row.active ? t("adminStatus.active") : t("adminStatus.inactive") }
							</MDBBadge>
						);
					},
				}
			];
		return columns;
	}

	// const openAdminInviteModal = () => {
	// 	setIsAdminInviteModalOpen(true);
	// }
	// const closeAdminInviteModal = () => {
	// 	setIsAdminInviteModalOpen(false);
	// }

	const getSchemas = () => {
		const schemas = {
			companies: {
				id: "companies",
				label: t("company"),
				type: "select",
				icon: "building",
				placeholder: t("company"),
				priority: true,
				visible: availableCompanies.length > 1,
				options: availableCompanies.map(s => ({ value: s.id, text: s.name })),
				defaultValues: filterValues.companies,
				search: true
			},
			stores: {
				id: "stores",
				label: t("store"),
				type: "multiselect",
				icon: "store-alt",
				placeholder: t("filters.allStores"),
				priority: true,
				visible: true,
				options: availableStores.map(s => ({ value: s.id, text: s.name, dependencyId: s.companyId })),
				search: true,
				defaultValues: filterValues.stores,
				dependsOn: "companies"
			}
		}
		return schemas;
	}

	const getAdministrators = async (filters) => {
		dispatch(setLoading(true));
		let data = await getAdministratorsForCompany(filters.companies[0], filters.stores);
		dispatch(setLoading(false));
		return data;
	}

	const createAdmin = () => {
		history.push("/settings/administrators/create")
	}

	const getActions = () => {
		const actions = [
			{
				key: "create_admin_action_" + 1,
				icon: "user-plus",
				label: t("createAdmin"),
				primary: true,
				onClick: () => createAdmin()
			},
		]
		// Dejo esto comentado por si vuelve a ser necesario pronto
		// if (adminAccess === "ALL" || adminAccess === "COMPANY") {
		// if (filterValues.companies.length <= 1) {
		// 	actions.unshift({
		// 		key: "leaderboard_action_" + 2,
		// 		icon: "user-plus",
		// 		label: t("inviteAdmin"),
		// 		intercomTarget: "leaderboard_actions_invite_user",
		// 		primary: false,
		// 		onClick: () => {
		// 			openAdminInviteModal();
		// 		}
		// 	})
		// }

		return actions;
	}

	return (
		<div className="leaderboard">
			{/* <AdminInviteModal isOpen={isAdminInviteModalOpen} toggle={closeAdminInviteModal} triggerOpenModal={() => setIsAdminInviteModalOpen(true)} /> */}
			{filterValues && <Filters
				title={t("title")}
				schemas={getSchemas()}
				values={filterValues}
				onChange={(newValues) => setFilterValues(newValues)}
				showInfo
				actions={getActions()}
				actionsIntercomTarget="leaderboard_actions"
			/>}
			{filterValues && filterValues.companies &&
				<div>
					<Table
						actions={getActions()}
						filterValues={filterValues}
						getData={(page, order, filters) => getAdministrators(filters)}
						pagination={false}
						columns={getColumns()}
						//order="TOTAL_REVIEWS_REQUESTED"
						dataPath="data.data"
						elementKey="id"
						className="classname-prueba"
						//emptyStateIntercomTarget="leaderboard_ranking"
						onElementClick={element => console.log(element)}
						elementActions={ (row, onchange) => getRowActions(row, onchange)}
					// export={() => alert("Oh no!")}
					// exportName={`TasksExport-${Date.now()}`}
					// formats={[{ type: 'text/csv', format: 'csv' }]}
					/>
				</div>}
		</div>
	);
}

Administrators.translations = { ...translations }

export default withEventTracking(translate(Administrators));
