import React from "react";
import { FastField } from "formik";
import { MDBBadge } from "mdbreact";

const ClickeableTagFastField = ({name, elementId, tag}) => {

    return (
        <FastField
            name={name}
            render={(props) => {
                return (
                    <MDBBadge className="pt-1 pb-1 mb-1"
                        style={{width: '70px', cursor: 'pointer'}}
                        onClick={e => {
                            const element = document.getElementById(elementId)
                            const start = element.selectionStart
                            const oldValue = props.field.value
                            const newValue = [oldValue.slice(0, start), tag, oldValue.slice(start)].join('')
                            props.form.setFieldValue(props.field.name, newValue)
                            element.focus()
                            const newPosition = start + tag.length
                            setTimeout(() => element.setSelectionRange(newPosition, newPosition))                            
                        }}
                        pill>{tag}</MDBBadge>
                );
            }}
        />
    )
}

export default ClickeableTagFastField