import { miscConstants } from './miscConstants'

export function misc(state = { loading: false, notifications: [] }, action) {
	switch (action.type) {
		case miscConstants.SET_LOADING:
			return {
				...state,
				loading: action.payload.loading
			};
		case miscConstants.SET_ERROR:
			const notification = {
				timestamp: Date.now(),
				message: action.payload.error.message,
				navigateOnOk: action.payload.error.navigateOnOk,
				type: 'ERROR',
				severity: 'FATAL'
			}
			return {
				...state,
				notifications: [...state.notifications, notification]
			};
		case miscConstants.REMOVE_NOTIFICATIONS:
			let notifications
			if (action.payload.id === undefined)
				notifications = [];
			else {
				//TODO implementar
				notifications = state.notifications;
			}
			return {
				...state,
				notifications: notifications
			};
		default:
			return state
	}
}