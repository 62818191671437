import React, { useState, useEffect } from "react";
import { Image } from "botbit-ui-components";
import { useHistory } from "react-router-dom";
import { createUser } from "../../../../api/userApi";
import { envConfig } from "../../../../config/config";
import { getPublicStore, updateNpsComment } from "../../../../api/storeApi";
import { translations } from "../../../../config/translations/Feedback/LeftComment";
import translate from "../../../../config/translations/translate";
import "./LeftComment.scss";
import { MDBBtn, MDBIcon, MDBInput } from "mdbreact";
import { Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import NPSCustomQuestionInput from "./NPSCustomQuestionInput";

const LeftComment = ({ t }) => {
  const [store, setStore] = useState();
  // const [userRegistered, setUserRegistered] = useState();
  // const [userIdFromRegister, setUserIdFromRegister] = useState();
  const [showPrivateMsgInput, setShowPrivateMsgInput] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);

  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const storeId = params.get("storeId");
  const userId = params.get("userId");
  const name = params.get("name");
  const phone = params.get("phone");
  const correlationId = params.get("correlationId");
  const score = parseInt(params.get("score"));

  useEffect(() => {
    // Creo la función asincrona y la llamo inmediatamente
    (async () => {
      const selectedStore = await getPublicStore(storeId);
      const store = selectedStore.data.data;
      // si tiene gating hay que mantener el comportamiento viejo:
      // - ocultar botones de google/facebook
      // - mostrar el form de una
      setShowPrivateMsgInput(
        selectedStore.data.data.gating ||
          (!store.facebookURL && !store.googleBusinessURL)
      );
      setStore(store);
      if (!userId) {
        registerUser();
      }
    })();
  }, []);

  const registerUser = () => {
    const userObj = {
      name: name,
      phone: phone,
    };
    const externalParams = {
      registeredThrough: "REVIEW_REQUEST",
      source: "BOTBIT",
    };
    const config = {
      headers: {
        Authorization: `Bearer ${envConfig.token}`,
      },
    };
    createUser(userObj, storeId, externalParams, config);
    // .then(res => {
    //     if (res.status === 200) {
    //         // setUserRegistered(true);
    //         const userId = res.data.data.id;
    //         setUserIdFromRegister(userId);
    //         // if (correlationId) trackEvent("REVIEW_REQUEST_SHOWED", { correlationId: correlationId }, false, [trackers.BACKEND_UNSECURE])
    //     };
    // })
  };

  const storeLogo = store ? store.logo : "";
  const storeLogoFormatted = storeLogo
    ? storeLogo.replace("(", "\\(").replace(")", "\\)")
    : "";
  const googleUrl = store ? store.googleBusinessURL : undefined;
  const facebookUrl = store ? store.facebookURL : undefined;
  const hasGating = store ? store.gating : undefined;
  const hasFacebookOrGoogle = googleUrl || facebookUrl;

  const renderGoogleButton = () => {
    return (
      <MDBBtn
        color="danger"
        onClick={() => {
          setFeedbackSent(true);
          window.open(googleUrl, "_blank");
        }}
        className="google-btn review-btn"
      >
        <img
          className={"mr-1"}
          src={envConfig.staticImgUrl + "social_logos/google_icon.png"}
          alt="google-icon"
        />{" "}
        {t("googleBtn")}
        {/* <MDBIcon fab className="mr-1" icon="google" /> {t("googleBtn")} */}
      </MDBBtn>
    );
  };

  const renderFacebookButton = () => {
    return (
      <MDBBtn
        color="indigo"
        onClick={() => {
          setFeedbackSent(true);
          window.open(facebookUrl, "_blank");
        }}
        className="review-btn"
      >
        <MDBIcon fab className="mr-1" icon="facebook" /> {t("facebookBtn")}
      </MDBBtn>
    );
  };

  const renderBadExperienceMessage = () => {
    return (
      <span className="d-block font-weight-bold">{t("badExperience")}</span>
    );
  };

  const renderPrivateButton = () => {
    return (
      <MDBBtn
        type="submit"
        color="primary"
        className="review-btn"
        onClick={() => setShowPrivateMsgInput(true)}
      >
        <MDBIcon className="mr-1" icon="comment" /> {t("internBtn")}
      </MDBBtn>
    );
  };

  const renderFormikFieldForCustomQuestion = (
    customQuestion,
    index,
    setFieldValue
  ) => {
    let fieldName;
    if (customQuestion.type === "TEXT") fieldName = "answeredText";
    if (customQuestion.type === "OPTIONS")
      fieldName = "answeredNPSQuestionOption.id";
    if (customQuestion.type === "SCORE") fieldName = "answeredScore";

    let completeFieldName = `npsCustomAnswers[${index}].${fieldName}`;

    return (
      <div key={"custom-question-container-" + customQuestion.id}>
        <div className="custom-question-label">{customQuestion.text}</div>
        <div>
          <Field name={completeFieldName}>
            {({ field }) => (
              <NPSCustomQuestionInput
                customQuestion={customQuestion}
                setValue={(value) => {
                  setFieldValue(field.name, value);
                }}
                value={field.value || ""}
              />
            )}
          </Field>
        </div>
      </div>
    );
  };

  const calculateIfShowCustomQuestions = () => {
    const hasCustomQuestions =
      store && store.npsCustomQuestions && store.npsCustomQuestions.length > 0;
    // if store doesnt have custom questions configured, dont show
    if (!hasCustomQuestions) {
      return false;
    }
    // store have custom questions at this point
    // if store have undefined configuration, show them by default
    if (
      !store.showCustomQuestionsWhenScoreIsLessOrEqualThan &&
      store.showCustomQuestionsWhenScoreIsLessOrEqualThan !== 0
    ) {
      return true;
    }
    // score always came in 0-10 scale
    // if store scale is STARS, the incoming score must be normalized before comparing
    const scoreInCorrectScale =
      store.npsScoreScale === "STARS" ? score / 2 : score;

    // show if score is less or equal than configured param
    return (
      scoreInCorrectScale <= store.showCustomQuestionsWhenScoreIsLessOrEqualThan
    );
  };

  const showCustomQuestions = calculateIfShowCustomQuestions();

  const getInitialValues = () => {
    let values = {
      score,
    };
    if (showCustomQuestions) {
      let npsCustomAnswers = [];
      store.npsCustomQuestions.forEach((element) => {
        npsCustomAnswers.push({
          npsQuestion: {
            id: element.id,
          },
          answeredNPSQuestionOption: {
            id: undefined,
          },
          answeredText: undefined,
          answeredScore: undefined,
        });
      });
      values.npsCustomAnswers = npsCustomAnswers;
    } else {
      values.privateComment = "";
    }
    return values;
  };

  const getValidationSchema = () => {
    if (showCustomQuestions) {
      return null;
    } else {
      return Yup.object().shape({
        privateComment: Yup.string().when("score", {
			is: (score) => score < 7,
			then: ()=>Yup.string().required(),
			otherwise: ()=>Yup.string().notRequired()
		}) 
      });
    }
  };

  const npsButton = (number, handleNps, score) => {
	  const selected = score >= number;
    return (
      <span
        key={"key-nps-button-" + number}
        className={`${selected ? "selected" : ""}`}
        onClick={() => handleNps(number)}
      >
        {" "}
        {selected ? "★" : "☆"} 
      </span>
    );
  };

  return (
    <div className="left-comment">
      {store ? (
        <>
          <div className="left-comment_header">
            <Image
              scaleFixedSize={{ width: "128px", height: "128px" }}
              src={storeLogoFormatted}
            />
          </div>
          <div className="info-container">
            {!feedbackSent ? (
              <>
                <div className="left-comment_info">
                  <h2 className="title">{t("title")}</h2>
                </div>
                <div className="actions-container">
                  {!hasGating && googleUrl && renderGoogleButton()}
                  {!hasGating && facebookUrl && renderFacebookButton()}
                  {!hasGating &&
                    hasFacebookOrGoogle &&
                    renderBadExperienceMessage()}
                  {!hasGating && hasFacebookOrGoogle && renderPrivateButton()}

                  {showPrivateMsgInput && (
                    <div className={`private-comment`}>
                      <Formik
                        initialValues={getInitialValues()}
                        validationSchema={getValidationSchema()}
                        onSubmit={(values, { resetForm }) => {
                          const body = {
							score: values.score,
                            comment: values.privateComment,
                            npsCustomAnswers: values.npsCustomAnswers,
                            correlationId,
                          };
                          console.log(body);
                          updateNpsComment(body).then((res) => {
                            if (res.status === 200) {
                              resetForm();
                              setFeedbackSent(true);
                            }
                          });
                        }}
                      >
                        {({ values, setFieldValue }) => (
                          <Form id="left-comment-form" role="form">
                            {score && (
                              <Field id="score" name="score">
                                {({ form: { values } }) => (
                                  <div className="stars">
									<h2 className="title">{t("yourScore")}{values.score / 2}</h2>
                                    {[2, 4, 6, 8, 10].map((n) =>
                                      npsButton(
                                        n,
                                        () => setFieldValue("score", n),
                                        values.score
                                      )
                                    )}
                                  </div>
                                )}
                              </Field>
                            )}
                            {!showCustomQuestions && (
                              <Field id="privateComment" name="privateComment">
                                {({ field }) => (
                                  <MDBInput
                                    className="private-comment-input"
                                    label={t("privateComment")}
                                    id="private-comment-input"
                                    type="textarea"
                                    outline
                                    value={field.value || ""}
                                    getValue={(value) => {
                                      setFieldValue("privateComment", value);
                                    }}
                                  />
                                )}
                              </Field>
                            )}

                            {showCustomQuestions && (
                              <FieldArray name="npsCustomAnswers">
                                <>
                                  {store.npsCustomQuestions.map(
                                    (element, index) => {
                                      return renderFormikFieldForCustomQuestion(
                                        element,
                                        index,
                                        setFieldValue
                                      );
                                    }
                                  )}
                                </>
                              </FieldArray>
                            )}
                            <MDBBtn
                              id="sendComments"
                              type="submit"
                              color="primary"
                              className=" review-btn"
                            >
                              <MDBIcon className="mr-1" icon="paper-plane" />{" "}
                              {t("sendComment")}
                            </MDBBtn>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="final-view">
                <h2 className="title">{t("finalTitle")}</h2>
              </div>
            )}
          </div>
        </>
      ) : null}
      <div className="powered-by">
        <span>Powered by</span>{" "}
        <img
          alt="botbit"
          src={`${envConfig.staticImgUrl}RAY-Brand-Red-60.png`}
        ></img>
      </div>
    </div>
  );
};

LeftComment.translations = { ...translations };

export default translate(LeftComment);
