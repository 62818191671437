import React from 'react';
import { Modal, ModalHeader, ModalBody } from "botbit-ui-components"
import { envConfig } from '../../../config/config';
import { MDBAlert, MDBBtn, MDBIcon } from 'mdbreact';
import { getLeaderboard } from '../../../api/leaderboardApi';
import withEventTracking from '../../../config/withEventTracking';

const CheerUpModal = ({ isCheerUpOpen, closeCheerUpModal, admin, step, t, goToStep, goHome, email, store, trackEvent }) => {

    const addHours = (date, hours) => {
        date.setTime(date.getTime() + (hours * 60 * 60 * 1000))
        return date
    }

    const _goToStep = (_step) => {
        trackEvent(getEventName(step))
        goToStep(_step)
    }

    const getEventName = (step, action = 'next') => {
        let stepName
        switch (step) {
            case 1:
                stepName = 'congrats_sent_review_request'
                break
            case 2:
                stepName = 'open_email_explanation'
                break
            case 3.11:
                stepName = 'congrats_first_private_review'
                break
            case 3.12:
                stepName = 'lets_reply_private_review'
                break
            case 3.2:
                stepName = 'first_private_review_not_generated'
                break
            default:
                stepName = 'undefined'
        }
        return `clicked_${action}_onboarding_step_${step}_${stepName}`
    }

    const result = async () => {
        const from = addHours(new Date(), -1)
        let leaderboard = await getLeaderboard([store], { from: from, to: addHours(new Date(), 1) }, "TOTAL_REVIEWS_REQUESTED");
        let repliedNPS = false
        leaderboard.data.data.items.find(item => {
            if (item.adminId === admin.id) {
                // Contestó el NPS
                if (item.totalPrivateGenerated > 0) {
                    repliedNPS = true
                    // Seteo la # de reseñas que tiene para responder en 1 para que si va a responder su reseña se active el OB
                    window.Intercom('update', { "reviews_to_reply": 1 });
                }
                return true
            }
            return false
        })
        if (repliedNPS)
            _goToStep(3.11)
        else
            _goToStep(3.2)

    }

    return (
        <>
            <Modal className="review-request rr-cheerup" isOpen={isCheerUpOpen} toggle={closeCheerUpModal}>
                <ModalHeader toggle={closeCheerUpModal}></ModalHeader>
                <ModalBody>
                    {step === 1 && <>
                        <img src={envConfig.staticImgUrl + "minions.gif"} alt="" className="img-fluid mb-3" />
                        <p><b>{t("stepOne.first", { name: admin.name })}</b></p>
                        <p>{t("stepOne.second")}</p>
                        <MDBBtn color="primary" className="step-btn" onClick={() => _goToStep(2)}>{t("continue")}</MDBBtn>
                    </>}
                    {step === 2 && <>
                        <p>{t("stepTwo.first")} <b>{t("stepTwo.second", { email })}</b> {t("stepTwo.third")}</p>
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe title="embedsPage" className="embed-responsive-item" src="https://www.youtube.com/embed/Q9QVQemxDso?rel=0" allowfullscreen></iframe>
                        </div>
                        {((email.includes("gmail") || email.includes("hotmail") || email.includes("outlook") || email.includes("yahoo"))) &&
                            <MDBBtn color="secondary" className="step-btn" onClick={() => {
                                trackEvent(getEventName(step, 'open_email'))
                                const emailServer = email.split("@")[1];
                                const emailUrl = "http://" + emailServer;
                                window.open(emailUrl, "_blank")
                            }}>{t("stepTwo.btn")}</MDBBtn>
                        }
                        <>
                            <br /><br />
                            <p style={{ marginBottom: 0 }}>{t("stepTwo.fourth")}</p>
                            <MDBBtn color="primary" className="step-btn" onClick={() => result()}>{t("continue")}</MDBBtn>
                        </>
                    </>}
                    {step === 3.11 && <>
                        <img src={envConfig.staticImgUrl + "minions_1.gif"} alt="" className="img-fluid mb-3" />
                        <p><b>{t("stepThree_1_1.firstP")}</b></p>
                        <p>
                            {t("stepThree_1_1.secondP.first")}
                        </p>
                        <MDBBtn color="primary" className="step-btn" onClick={() => _goToStep(3.12)}>{t("continue")}</MDBBtn>
                    </>}
                    {step === 3.12 && <>
                        <p><b>{t("stepThree_1_2.firstP")}</b></p>
                        <p>
                            {t("stepThree_1_2.secondP")}
                        </p>
                        <MDBAlert color="info" className="review-info"><b>{t("reviewAdvice.zero")}</b><br />{t("reviewAdvice.first")} <strong>68%</strong> {t("reviewAdvice.second")} <strong>{t("reviewAdvice.third")}</strong> {t("reviewAdvice.fourth")}</MDBAlert>
                        <p>
                            {t("stepThree_1_2.thirdP")}
                        </p>
                        <MDBBtn color="primary" className="step-btn" onClick=
                            {
                                () => {
                                    trackEvent(getEventName(step, 'reply'))
                                    // TODO hago esto porque si mando el redirect sin esperar, el trackEvent no llega a terminar. Lo ideal sería que trackEvent devuelva 
                                    // una promesa y hacer el redirect cuando se cumpla la promesa
                                    setTimeout(() => { window.location.href = '/feedback/reviews?timePeriod=TODAY&withoutReply=true&firstReply=true' }, 2000)
                                }
                            } >
                            {t("stepThree_1_2.button")}
                        </MDBBtn>
                    </>}
                    {step === 3.2 && <>
                        <img src={envConfig.staticImgUrl + "minions_whatever.gif"} alt="" className="img-fluid mb-3" />
                        <p>
                            <b>{t("stepThree_2.firstP")}</b>
                        </p>
                        <p>
                            {t("stepThree_2.secondP")}
                        </p>
                        <MDBBtn
                            className="submit-btn"
                            color="deep-orange"
                            data-intercom-target="review_request_send_button"
                            size="lg"
                            onClick={() => {
                                trackEvent(getEventName(step, 'try_again'))
                                _goToStep(2)
                                goHome(true)
                            }}
                        >
                            <MDBIcon far className="mr-1" icon="paper-plane" />{t("newRequestAlt")}
                        </MDBBtn>
                    </>}
                </ModalBody>
            </Modal>
        </>
    );
}


export default withEventTracking(CheerUpModal);