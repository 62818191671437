import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axiosClient from '../../api/axiosClient'
import translate from '../../config/translations/translate';
export const REPORTER_TYPES = {
    ERROR: "ERROR",
    SOCKET_SUBSCRIPTION: "SOCKET_SUBSCRIPTION",
    SOCKET_MESSAGE: "SOCKET_MESSAGE"
}

/**
 * @typedef action acción que procesa el reducer
 * @property {string} type tipo de acción [REPORTER_TYPES]
 * @property {*} payload carga útil de la acción que se quiere guardar en el log. Ejemplos: el mensaje, el error.
 */

 /**
  * @typedef socketLog log de los mensajes por socket a ser incluídos en los reportes
  * @property {Array} subscriptions
  * @property {Array} messages
  */

 /**
  * @typedef reporterState estado del reducer de reporter
  * @property {Array} errors registro de errores
  * @property {socketLog} socket registro de actividad de socket
  */

/**
 * 
 * @param {number} max cantidad máxima de elemento
 * @param {Array} arr arreglo a reducir
 */
const limitArray = (max, arr) => {
    const length = arr.length;
    if(!max || max>length) return arr;
    else return arr.slice(length-max, length);
}
/**
 * @param {number} maxErrors cantidad máxima de elementos de los arreglos de registros
 */
export const reporterReducer = (maxErrors)=>{
    /**
     * 
     * @param {reporterState} state 
     * @param {action} action 
     */
    const reducer = (state={errors:[],socket:{subscriptions:[], messages:[]}}, action) => {
        switch (action.type){
            case REPORTER_TYPES.SOCKET_SUBSCRIPTION:
                return {
                    ...state,
                    socket:{
                        ...state.socket,
                        subscriptions: limitArray(maxErrors,[...state.socket.subscriptions, action.payload])
                    }
                }
            case REPORTER_TYPES.SOCKET_MESSAGE:
                return {
                    ...state,
                    socket:{
                        ...state.socket,
                        messages: limitArray(maxErrors,[...state.socket.messages, action.payload])
                    }
                }
            case REPORTER_TYPES.ERROR:
                return {
                    ...state,
                    errors: limitArray(maxErrors,[...state.errors, action.payload])
                }
            default:
                return state;
        }
    }
    return reducer;
}

const createAction = (type, payload) => ({
    type: type,
    payload: {
        ...payload,
        timestamp: new Date()
    }
})

export const errorAction = (payload) => 
    createAction(REPORTER_TYPES.ERROR,{
        error:payload
    })


export const socketSubscriptionAction = (payload) =>
    createAction(REPORTER_TYPES.SOCKET_SUBSCRIPTION,{
        topic:payload
    })

export const socketMessageAction = (payload) => 
    createAction(REPORTER_TYPES.SOCKET_MESSAGE,{
        message:payload
    })


const creteErrorReport = (body)=>{
    return axiosClient.post('/error/report', body)
}


const ReporterComponent = ({t, history}) =>{

    const [comment, setComment] = useState("");

    const admin = useSelector(state => state.login.admin);

    const detail = useSelector(state => state.reporter);

    return <div className="text-center" style={{height:'100vh'}}>
        <h1 className="text-white">{t('title')}</h1>
        <p className="text-white">{t('message')}</p>
        <textarea data-testid="comment-input"
            className="form-control px-5"
            value={comment}
            onChange={e=>setComment(e.target.value)}
        />
        {
            comment.length<20 && 
            <p className="text-warning">{t("minComment")}</p>
        }
        <button data-testid="submit-report" 
            className="btn btn-primary"
            disabled={comment.length<20}
            onClick={
                ()=>{
                    creteErrorReport({
                        comment:comment,
                        errorDetail:{
                            admin:admin,
                            detail: detail
                        }
                    }).then(()=>{
                        alert(t("thanks"));
                        history.push("/");
                    })
                }
            }>
            {t('button')}
        </button>
    </div>
}

ReporterComponent.translations={
    button:{
        es:"Reportar",
        en: "Report"
    },
    title:{
        es: "Reportar un error",
        en: "Report a bug"
    },
    message:{
        es: "Déjanos un comentario describiendo el error",
        en: "Leave us a comment describing the bug"
    },
    thanks: {
        es: "¡Muchas Gracias!",
        en: "Thanks!"
    },
    minComment: {
        es:"Al menos 20 caracteres",
        en:"20 caracters at least"
    }
}
export default withRouter(translate(ReporterComponent));