import React from 'react'
import { MDBSelect } from 'mdbreact'
import { Field } from 'formik'
import './CompanyAndStoreFilter.css'


export default class CompanyAndStoreFilter extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            showStoresSelector: false,
			selectedStores: [],
			primaryOptions: [
				{
					text: this.props.t('panels.campaigns.minimalEditor.segmentMore.allCurrentStores'),
					value: 'allStores',
					key:  'allStores'

				},
				{
					text: this.props.t('panels.campaigns.minimalEditor.segmentMore.allCurrentAndFutureStores'),
					value: 'company'
					, key:  'company'
				},
				{
					text: this.props.t('panels.campaigns.minimalEditor.segmentMore.someStores'),
					value: 'someStores'
					, key:  'someStores'
				}
			]
        }
	
	}

    formikStoresAndActivableStoresAreEqual() { 
		let formikStores = this.props.formik.state.values.campaign.segment.stores || [];
		let activableStoreList = this.props.storesActivableSmartCampaign.stores || [];
		// if length is not equal 
		if(formikStores.length !== activableStoreList.length) {
			return false; 
		} else{ 
		// comapring each element of array 
			for(var i=0;i < formikStores.length; i++ ) {
				if(formikStores[i].name !== activableStoreList[i].name) {
					return false; 
				} else {
					return true; 
				}
			}
		} 
	} 
	
	componentDidMount(){
		this.returnSelectedOption();
	}

    returnSelectedOption = () => {
		if(this.props.formik){
			if(this.props.formik.state.values.campaign.segment.useCompanyUsers){
				this.setState({
					selectedOption: this.state.primaryOptions.find(obj => obj.value === 'company').text
				})
			} else {
				if(this.formikStoresAndActivableStoresAreEqual()){
					this.setState({
						selectedOption: this.state.primaryOptions.find(obj => obj.value === 'allStores').text
					})
				} else {
					this.setState({
						selectedOption: this.state.primaryOptions.find(obj => obj.value === 'someStores').text,
						showStoresSelector: true
					})
				}
			}
		} else{
			return null;
		}
	}

	storeSelector = (canTargetCompany, secondaryOptions, activableCampaignStoreList) => <Field 
					name="campaign.segment.stores"
					render={(props) => {
						let errors = props.form.errors;
						let storeSelectorError = errors.campaign && errors.campaign.segment && errors.campaign.segment.stores;
						return <div id={canTargetCompany ? "storeSelector" : "cantTargetCompanyStoreSelector"} className={"d-inline-block " + (storeSelectorError ? "error" : "")}>
							<MDBSelect
								multiple
								search
								selectAll={!canTargetCompany}
								options={secondaryOptions}
								selected={this.props.t('panels.campaigns.minimalEditor.segmentMore.selectStores')}
								getValue={(values) => {
									let optionsToStores = activableCampaignStoreList.filter(store => values.some(value => value === store.id))
									this.props.formik.setFieldValue('campaign.segment.stores', optionsToStores)
									}
								}
							/>
							{storeSelectorError && <label>{storeSelectorError}</label>}
						</div>
					}}
				/>

    textAndStoreSelector = () => {
        if(this.props.storesActivableSmartCampaign && this.props.storesActivableSmartCampaign.stores && this.props.storesActivableSmartCampaign.stores.length > 0){
			let campaign = this.props.formik.state.values.campaign;
			let activableCampaignStoreList = this.props.storesActivableSmartCampaign.stores;

            if(!this.props.isEditing){
				var result;
				if(campaign.segment.useCompanyUsers && this.props.storesActivableSmartCampaign.canTargetCompany){
					result = this.props.t('panels.campaigns.minimalEditor.segmentMore.allCurrentAndFutureStoresSub')
				} else if(this.storesAreEqual(campaign.segment.stores, activableCampaignStoreList) && this.props.storesActivableSmartCampaign.canTargetCompany){
					result = this.props.t('panels.campaigns.minimalEditor.segmentMore.allCurrentStoresSub')
				} else {
					result = campaign.segment.stores.map(function(val) {
						return val.name;
					}).join(', ');
				}
                if(result.length > 0){
                    return (
                        <div id="targetStoresParent">
                            {this.props.t('panels.campaigns.minimalEditor.segmentMore.clientsFrom')} 
							<div id="segmentTargetStores">
                            	{result}
							</div>
                        </div>
                    );
                } else {
                    return null;
                }
			} else{ //estoy editando
				let secondaryOptions = activableCampaignStoreList.map(store => {
					return {
							text: store.name, 
							value: store.id, 
							checked: campaign.segment.stores.some(segmentStore => segmentStore.id === store.id)
						}
					})
				//si se puede seleccionar a toda la company, entonces...
				if(this.props.storesActivableSmartCampaign.canTargetCompany){
					
					return(
						<>
							<span>
								{this.props.t('panels.campaigns.minimalEditor.segmentMore.clientsFrom')} 
							</span>
							<Field 
							name="campaign.segment.stores"
							render={(props) => 
								<>
									<MDBSelect
										options={this.state.primaryOptions}
										selected={this.state.selectedOption}
										id="segmentOptionSelector"
                                        className={"d-inline-block"}
										getValue={ (value) => { 
											if(props.field.value !== value && value.length > 0){
												this.setState({
													selectedOption: this.state.primaryOptions.find(obj => obj.value === value[0]).text
												})
												switch (value[0]) {
													case this.state.primaryOptions[0].value:
														//si elegi todas las tiendas actuales, entonces...
														props.form.setFieldValue(props.field.name, activableCampaignStoreList)
														props.form.setFieldValue('campaign.segment.useCompanyUsers', false)
														props.form.setFieldValue('campaign.segment.useCompanyEvents', false)
														this.setState({
															showStoresSelector: false
														})
														break
													case this.state.primaryOptions[1].value:
														//si elegi todas las tiendas actuales y futuras, entonces...	
														props.form.setFieldValue('campaign.segment.useCompanyUsers', true)
														props.form.setFieldValue('campaign.segment.useCompanyEvents', true)
														this.setState({
															showStoresSelector: false
														})
														break
													case this.state.primaryOptions[2].value:
														//si elegi algunas tiendas, entonces...
														props.form.setFieldValue('campaign.segment.useCompanyUsers', false)
														props.form.setFieldValue('campaign.segment.useCompanyEvents', false)
														this.setState({
															showStoresSelector: true
														})
														break;
													default:
														break;
												}
											}
										}}
									/>
								</>
							}
							/>
							{this.state.showStoresSelector &&
								this.storeSelector(true, secondaryOptions, activableCampaignStoreList)
							}
						</>
					)
				} else {
					return (
						<>
							<span>
								{this.props.t('panels.campaigns.minimalEditor.segmentMore.clientsFrom')} 
								{this.props.t('panels.campaigns.minimalEditor.segmentMore.someStoresSub')}
							</span>
							{this.storeSelector(false, secondaryOptions, activableCampaignStoreList)}
						</>
					)
				}	
            }
        } else {
            return null;
        }
    }

    storesAreEqual = (formikStores, activableCampaignStoreList) => {
		if(formikStores.length !== activableCampaignStoreList.length){
			return false; 
		} else { 
		// comapring each element of array 
			for(var i=0;i<formikStores.length;i++) {
				if(formikStores[i].id !== activableCampaignStoreList[i].id) {
					return false; 
				} else {
					return true; 
				}
			}
		} 
    }
    
    render(){ 
		if(!this.props.storesActivableSmartCampaign || !this.props.storesActivableSmartCampaign.stores){
			return null;
		}
        return(
            <div className="campaignCompanyAndStoreSelector">
            {this.textAndStoreSelector()}
            </div>
        )
    }
}