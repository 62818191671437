import React, { useState, useEffect } from 'react';
import { getCurrentIssueForUserInStore, getTypesForCompany, postIssue } from '../../api/issuesApi';
import { Select } from 'botbit-ui-components';

import { useSelector } from 'react-redux';

import translate from "../../config/translations/translate";
import { MDBBtn, MDBChip, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import './IssuesContainer.css'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const IssueContainerComponent = ({userId, storeId, conversationId, conversationIssue, t}) => {
    const [types, setTypes] = useState();
    const [currentIssue, setCurrentIssue] = useState();
    const selectedCompany = useSelector(state => state.application.selectedCompany)
    // const [collapsableOpen, setCollapsableOpen] = useState(false);

    const [currentType, setCurrentType] = useState();
    const [currentState, setCurrentState] = useState();

    useEffect(()=>{
        getTypesForCompany(selectedCompany.id)
        .then(res => setTypes(res.data.data.type));

    }, []);

    useEffect(()=>{
        if(conversationIssue)
            setCurrentIssue(conversationIssue);
    }, [JSON.stringify(conversationIssue)])
    
    useEffect(()=>{
        setCurrentType(undefined);
        setCurrentState(undefined);
        getCurrentIssueForUserInStore(userId, storeId)
        .then(res =>{
            if(res.data.data)
                setCurrentIssue(res.data.data[0])
            else{
                setCurrentIssue(undefined)
            }
        })
    }, [userId])

    useEffect(()=>{
        if(currentIssue && types){
            setCurrentType(types.find(t=>currentIssue.issueType.id === t.id));
            setCurrentState(currentIssue.currentIssueState)
        }
    }, [currentIssue, types])


    const makePost = ()=>{
        postIssue(currentIssue, userId, storeId, currentType, currentState, conversationId)
        .then(res=>setCurrentIssue(res.data.data));
    }

    const handleApply = ()=>{
        if(currentIssue && currentIssue.issueType.id !== currentType.id){
            MySwal.fire({
                // title: t("swalTitle"),
                type: "warning",
                html: <span>{t("typeChangeWarning")} <b>{currentIssue.issueType.name }</b> {t("to")} <b>{currentType.name}</b></span>,
                confirmButtonText: t("ok"),
                cancelButtonText: t("cancel"),
                showCancelButton: true
            })
            .then(result => {
                if (result.value) {
                    makePost();
                }
            })
        } else {
            makePost();
        }
    }

    if(!types || !types.length) return null;

    const onTypeSelected = (values) => {
        const typeId = Number.parseInt(values[0]);
        setCurrentType(types.find(t => t.id === typeId));
        setCurrentState(undefined);
    };
    const onStateSelected = (values) => {
        const stateId = Number.parseInt(values[0]);
        setCurrentState(currentType.states.find(s => s.id === stateId));
    };
    return <MDBDropdown dropleft >
                {
                    currentIssue && 
                    <MDBChip className="m-0 cursor-default d-none d-md-inline-block">{currentIssue.issueType.name}
                        {
                            currentIssue && currentIssue.currentIssueState &&
                            <span> - {currentIssue.currentIssueState.name}</span>
                        }
                    </MDBChip>
                }
                {/* {
                    currentIssue && currentIssue.currentIssueState &&
                    <MDBChip className="my-0">{currentIssue.currentIssueState.name}</MDBChip>
                } */}
                <MDBDropdownToggle onClick={()=>{
                    if(currentIssue){
                        setCurrentType(types.find(t=>currentIssue.issueType.id === t.id));
                        setCurrentState(currentIssue.currentIssueState)
                    } else {
                        setCurrentState(undefined);
                        setCurrentType(undefined);
                    }
                }} color='link' className='btn-circle'>
                    <FontAwesomeIcon icon={faTag} />
                </MDBDropdownToggle>
                <MDBDropdownMenu  basic className='issues-dropdown-menu'>
                    <div>
                        <IssueInput {...{types, currentType, onTypeSelected, currentState, onStateSelected}}/>
                        <MDBDropdownItem disabled={currentType === undefined} className="m-0 p-0">
                            <MDBBtn size='sm' className="w-100 m-0" disabled={currentType === undefined} color='primary' onClick={handleApply}>{t('apply')}</MDBBtn>
                        </MDBDropdownItem>
                    </div>
                </MDBDropdownMenu>
            </MDBDropdown>

}


IssueContainerComponent.translations = { 
    typeChangeWarning: {
        es: "Se cambiará el motivo actual de ",
        en: "The current subject associated with this user will be changed from "
    },
    ok:{
        es: "Aceptar",
        en: "Accept"
    },
    to:{
        es: " a ",
        en: " to "
    },
    cancel:{
        es: "Cancelar",
        en: "Cancel"
    },
    typeSelectPlaceholder:{
        es: "Seleccione un tipo",
        en: "Select a type"
    },
    stateSelectPlaceholder:{
        es: "Seleccione un estado",
        en: "Select a state"
    },
    apply:{
        es: "Aplicar",
        en: "Apply"
    },
}

/**
 * @typedef IssueInput
 * @param {*} props
 * @param {Function} props.t función de traducción
 * @param {Object[]} props.types lista de posibles tipos
 * @param {Object} props.currentType tipo seleccionado actualmente
 * @param {Function} props.onTypeSelected callback al seleccionar un tipo
 * @param {Object} props.currentState estado seleccionado actualmente
 * @param {Function} props.onStateSelected callback al seleccionar un estado
 */

export const IssueContainer = translate(IssueContainerComponent);

/**
 * @param {*} props
 * @param {Function} props.t función de traducción
 * @param {Object[]} props.types lista de posibles tipos
 * @param {Object} props.currentType tipo seleccionado actualmente
 * @param {Function} props.onTypeSelected callback al seleccionar un tipo
 * @param {Object} props.currentState estado seleccionado actualmente
 * @param {Function} props.onStateSelected callback al seleccionar un estado
 */
const IssueInputComponent =({t, types, currentType, onTypeSelected, currentState, onStateSelected}) =>{
    
    const currentCompleteType = currentType ? types.find(t=>t.id.toString()===currentType.id.toString()) : undefined;
    
    return <>
        <Select
            selected={t('typeSelectPlaceholder')}
            search={false}
            options={types.map(t => ({ text: t.name, value: t.id.toString(), checked: currentType && currentType.id.toString() === t.id.toString() }))}
            getValue={onTypeSelected}
        ></Select>
        {currentCompleteType &&
            <Select
                data-testid="stateSelector"
                selected={t('stateSelectPlaceholder')}
                search={false}
                options={currentCompleteType.states.map(s => ({ text: s.name, value: s.id.toString(), checked: currentState && currentState.id.toString() === s.id.toString() }))}
                getValue={onStateSelected}
            ></Select>}
    </>;
}

IssueInputComponent.translations = {
    typeSelectPlaceholder:{
        es: "Seleccione un tipo",
        en: "Select a type"
    },
    stateSelectPlaceholder:{
        es: "Seleccione un estado",
        en: "Select a state"
    }
}


export const IssueInput = translate(IssueInputComponent);