import React, { useEffect, useState } from 'react';
import { Carousel, Table, Filters, timePeriodsIds as timePeriods, getLabel, getSuffixLabel, calculateFrom, calculateTo } from "botbit-ui-components"
import { getEvolution, getEvolutionCsv } from '../../api/messengerApi';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Messenger/ChatsDashboardContainer";
import _ from "lodash"
import useWindowDimensions from "../../commons/utils/useWindowDimensions";
import { MDBBadge, MDBCard, MDBCardTitle, MDBIcon } from "mdbreact";
import Number from '../../commons/components/utils/FormatedNumber'

import "./ChatsDashboardContainer.scss"
import ClientActions from '../clients/ClientActions';
import withEventTracking from '../../config/withEventTracking';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../commons/components/application/miscActions';

import { envConfig } from '../../config/config.js';

const ChatsDashboardContainer = ({ t, trackEvent }) => {
    const availableStores = useSelector(state => state.application.availableStores);
    const availableCompanies = useSelector(state => state.application.availableCompanies)
    const selectedCompany = useSelector(state => state.application.selectedCompany);
    const [filterValues, setFilterValues] = useState(null)
    const [filtersFormatted, setFiltersFormatted] = useState();

    const [evolutionData, setEvolutionData] = useState(undefined)

    const history = useHistory()
    const dispatch = useDispatch();

    const { isMedium } = useWindowDimensions();

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        const dateFromParam = params.get("dateFrom")
        const dateToParam = params.get("dateTo");
        const storesParam = params.get("stores")
        const periodParam = params.get("period");
        const companiesParam = params.get("companies");

        let newFilterValues = { timePeriod: [{ value: timePeriods.LAST_7_DAYS }], stores: [], companies: [selectedCompany.id] }

        if (periodParam && periodParam !== timePeriods.CUSTOM) {
            newFilterValues = {
                ...newFilterValues,
                timePeriod: [{ value: periodParam }]
            }
        } else if (dateFromParam && dateToParam) {
            newFilterValues = {
                ...newFilterValues,
                timePeriod: [{
                    value: timePeriods.CUSTOM,
                    from: new Date(dateFromParam),
                    to: new Date(dateToParam),
                }]
            }
        }

        if (companiesParam) {
            newFilterValues = {
                ...newFilterValues,
                companies: [
                    ...companiesParam.split(",").map(elem => parseInt(elem))
                ]
            }
        }

        if (storesParam) {
            newFilterValues = {
                ...newFilterValues,
                stores: [
                    ...storesParam.split(",").map(elem => parseInt(elem))
                ]
            }
        }

        if (JSON.stringify(filterValues) !== JSON.stringify(newFilterValues)) {
            setFilterValues({ ...newFilterValues })
        }
    }, [])

    useEffect(() => {
        if (!filterValues) return;
        const formatted = { stores: getStoresFromFilters(), timePeriod: getPeriodFromFilters() }
        setFiltersFormatted(formatted)
    }, [filterValues])



    const getAdminName = (row) => {
        var adminName = null;
        if (row.adminId === -11)
            adminName = "Ray BOT";
        else if (row.adminId === -12) {
            adminName = t("externalAdmin");
        } else if (row.adminId > 0) {
            adminName = row.name + " " + row.lastName;
        }
        return adminName;
    }

    const getColumns = () => {
        if (isMedium) return (
            [
                {
                    dataField: "avatar",
                    title: t(""),
                    size: 100,
                    // className: "mb-2",
                    formatter: (cellContent, row) => {
                        let imgsrc = `${envConfig.staticImgUrl}/avatars/${cellContent}`;
                        return (
                            <div className="mobile-row_container w-100">
                                <div className="mobile-row_heading">
                                    <img src={imgsrc} className="rounded-circle mobile-row_admin-avatar admin-avatar" alt="" />
                                    <h3 className="mobile-row_admin-name">{getAdminName(row)}</h3>
                                </div>
                                <div className="mobile-row_metrics">
                                    <MDBIcon icon='paper-plane' /> {t("totalMessagesSent")}: <MDBBadge pill color="default">{row.totalMessagesSent.current}</MDBBadge>
                                </div>
                                <div className="mobile-row_metrics">
                                    <MDBIcon icon='users' /> {t("totalUniqueUsers")}: <MDBBadge pill color="default">{row.totalUniqueUsers.current}</MDBBadge>
                                </div>
                            </div>
                        );
                    },
                },
            ]
        )
        return (
            [
                {
                    dataField: "avatar",
                    title: t(""),
                    size: 14,
                    // className: "mb-2",
                    formatter: (cellContent, row) => {
                        let imgsrc = `${envConfig.staticImgUrl}/avatars/${cellContent}`;
                        return (
                            <div className="w-100">
                                <img src={imgsrc} className="rounded-circle admin-avatar" alt="" />
                            </div>
                        );
                    },
                },
                {
                    dataField: "name",
                    title: t("adminName"),
                    size: 46,
                    // className: "mb-2",
                    formatter: (cellContent, row) => {
                        return (<div className="cell-wrapper">
                            <div className="cell-content"> {getAdminName(row)} </div>
                        </div>)
                    },
                },
                {
                    dataField: "totalUniqueUsers",
                    title: t("totalUniqueUsers"),
                    size: 20,
                    // className: "mb-2",
                    formatter: (cellContent, row) => {
                        return (
                            <div className="cell-wrapper">
                                <div className="cell-content">
                                    <i data-test="fa" className="fa fa-users"></i> {row.totalUniqueUsers.current}
                                </div>
                            </div>
                        );
                    },
                },
                {
                    dataField: "totalMessagesSent",
                    title: t("totalMessagesSent"),
                    size: 20,
                    // className: "mb-2",
                    formatter: (cellContent, row) => {
                        return (
                            <div className="cell-wrapper">
                                <div className="cell-content">
                                    <i data-test="fa" className="fa fa-paper-plane"></i> {row.totalMessagesSent.current}
                                </div>
                            </div>
                        );
                    },
                },
            ]
        )
    }

    const getSchemas = () => {
        const schemas = {
            timePeriod: {
                id: 'timePeriod',
                label: t("timePeriod"),
                type: 'timeperiod',
                icon: 'calendar-alt',
                placeholder: t("custom"),
                priority: true,
                visible: true,
                options: [timePeriods.LAST_7_DAYS, timePeriods.THIS_MONTH, timePeriods.LAST_30_DAYS, timePeriods.LAST_6_MONTHS, timePeriods.LAST_12_MONTHS, timePeriods.THIS_YEAR, timePeriods.CUSTOM],
                defaultValues: [{ value: timePeriods.LAST_7_DAYS }],
            },
            companies: {
                id: "companies",
                label: t("company"),
                type: "multiselect",
                icon: "building",
                placeholder: t("allCompanies"),
                priority: true,
                visible: availableCompanies.length > 1,
                options: availableCompanies.map(s => ({ value: s.id, text: s.name })),
                defaultValues: filterValues.companies,
                search: true
            },
            stores: {
                id: "stores",
                label: t("store"),
                type: "multiselect",
                icon: "store-alt",
                placeholder: t("allStores"),
                priority: true,
                visible: true,
                options: availableStores.map(s => ({ value: s.id, text: s.name, dependencyId: s.companyId })),
                search: true,
                defaultValues: [],
                dependsOn: "companies"
            }
        }
        return schemas;
    }

    const actions = [
        {
            key: "conversation_dashboard_action_export",
            icon: "file-export",
            label: t("exportCSV"),
            primary: false,
            onClick: () => exportCSV()
        }
    ]

    const exportCSV = () => {
        // alert("Oh no")
        const { stores, timePeriod } = filtersFormatted;
        //trackEvent('CONVERSATION_DASHBOARD_EXPORT_REQUESTED', { type: 'EXPORT' }, true, [trackers.BACKEND])
        getEvolutionCsv(stores, timePeriod)
            .then(fileBlob => {
                const fileName = `Conversation Dashboard - ${Date.now()}`;
                try {
                    var blob = new Blob([fileBlob.data], { type: "text/csv" });
                    //downloading the file depends on the browser
                    //IE handles it differently than chrome/webkit
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        var elem = window.document.createElement('a');
                        elem.href = window.URL.createObjectURL(blob);
                        elem.download = fileName + ".csv";
                        document.body.appendChild(elem);
                        elem.click();
                        document.body.removeChild(elem);
                    }
                } catch (exc) {
                    console.log("Save Blob method failed with the following exception.");
                    console.log(exc);
                }
            })
    }


    const getPeriodFromFilters = () => {
        return _.at(filterValues, "timePeriod[0].value")[0]
            ? _.at(filterValues, "timePeriod[0].value")[0] !== "CUSTOM"
                ? { suffixText: getSuffixLabel(_.at(filterValues, "timePeriod[0].value")[0]), text: "· 📅 " + getLabel(_.at(filterValues, "timePeriod[0].value")[0]), from: calculateFrom(_.at(filterValues, "timePeriod[0].value")[0]), to: calculateTo(_.at(filterValues, "timePeriod[0].value")[0]) }
                : { suffixText: getSuffixLabel(_.at(filterValues, "timePeriod[0].value")[0]), text: "· 📅 " + getLabel(_.at(filterValues, "timePeriod[0].value")[0]), from: _.at(filterValues, "timePeriod[0]")[0].from, to: _.at(filterValues, "timePeriod[0]")[0].to }
            : { text: "" }
    }

    const getStoresFromFilters = () => {
        if (!filterValues.stores || filterValues.stores.length === 0 || filterValues.stores[0] === "allStores") {
            return availableStores.filter(s => filterValues.companies.includes(s.companyId)).map(s => s.id);
        }
        return filterValues.stores
    }

    const formatData = async (stores, timePeriod) => {
        dispatch(setLoading(true));
        let response = await getEvolution(stores, timePeriod);
        const evolutionData = response.data.data;
        setEvolutionData(evolutionData)
        dispatch(setLoading(false));
        return {
            data: {
                items: evolutionData.messagesByAdmin,
                pageSize: 0
            },
        };
    }

    const renderInsights = () => {
        if (!evolutionData || !filtersFormatted)
            return null;

        const sessionsByChannel = <div key="conversationsCardContainer" className="conversation-insight-container"><MDBCard key="conversationsCard" className={["card-body", "m-2", "conversation-insight"]}>
            <MDBCardTitle className="insight-title">{t("totalConversations")}</MDBCardTitle>
            {evolutionData && <>
                <span className="big-number"><Number number={evolutionData.totalSessions.current} /></span>
                {evolutionData && evolutionData.sessionsByChannel && evolutionData.sessionsByChannel.map((s, i) => {
                    return (<div className="channel-detail" key={"channel" + i}>
                        <div>
                            <span> <img className="source-icon" src={`${envConfig.staticImgUrl}/channels/${s.channel}.png`} alt={s.channel} /> {s.total.current}</span>
                        </div>
                        {s.channel==="WHATSAPP" && s.total.current>0 && <div className="channel-subdetail">
                            <div>
                                <div>
                                    <MDBIcon icon='arrow-right'/> {s.outbound.current}
                                </div>
                                <div>
                                    <MDBIcon icon='arrow-left'/> {s.inbound.current}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    )
                })}
            </>}
        </MDBCard></div>;

        const messagesByChannel = <div key="messagesCardContainer" className="conversation-insight-container"><MDBCard key="messagesCard" className={["card-body", "m-2", "conversation-insight"]}>
            <MDBCardTitle className="insight-title">{t("totalMessagesSent")}</MDBCardTitle>
            {evolutionData && <>
                <span className="big-number"><Number number={evolutionData.totalMessages.current} /></span>
                {evolutionData && evolutionData.messagesByChannel && evolutionData.messagesByChannel.map((s, i) => {
                    return (<div key={"channel" + i} className="channel-detail">
                        <span>
                            <img className="source-icon" src={`${envConfig.staticImgUrl}/channels/${s.channel}.png`} alt={s.channel} /> {s.total.current}
                        </span>
                    </div>
                    )
                })}
            </>}
        </MDBCard></div>

        const uniqueCustomersByChannel = <div key="uniqueCustomersCardContainer" className="conversation-insight-container"><MDBCard key="messagesCard" className={["card-body", "m-2", "conversation-insight"]}>
            <MDBCardTitle className="insight-title">{t("totalUniqueUsers")}</MDBCardTitle>
            {evolutionData && <>
                <span className="big-number"><Number number={evolutionData.totalUniqueCustomers.current} /></span>
                {evolutionData && evolutionData.uniqueCustomersByChannel && evolutionData.uniqueCustomersByChannel.map((s, i) => {
                    return (<div key={"channel" + i} className="channel-detail">
                        <span>
                            <img className="source-icon" src={`${envConfig.staticImgUrl}/channels/${s.channel}.png`} alt={s.channel} /> {s.total.current}
                        </span>
                    </div>
                    )
                })}
            </>}
        </MDBCard></div>

        if (isMedium) {
            return (<div className="row"><div className="container"><div className="row"><Carousel
                items={[uniqueCustomersByChannel, sessionsByChannel, messagesByChannel,]}
                autoPlay={false}
                fadeOutAnimation={true}
                mouseDragEnabled={true}
                responsive={{
                    "991": {
                        "items": 3
                    }
                }}
                disableAutoPlayOnAction={true}
                buttonsDisabled={true}
                infinite={false}
                autoWidth
                stagePadding={({ paddingRight: 15 })}
            /></div></div></div>)
        } else {
            return <div className="container">
                <div data-intercom-target="chat_dashboard_content" className="row conversation-insight-container">
                    {uniqueCustomersByChannel}
                    {sessionsByChannel}
                    {messagesByChannel}
                </div>
            </div>

        }
    }

    return (
        <div className="conversation-dashboard-container">
            <ClientActions />
            {filterValues && <Filters
                title={t("title")}
                schemas={getSchemas()}
                values={filterValues}
                onChange={(newValues) => setFilterValues(newValues)}
                actions={actions}
                showInfo
                actionsIntercomTarget="chat_dashboard_actions"
            />}

            {renderInsights()}

            {filtersFormatted && filtersFormatted.stores && filtersFormatted.timePeriod &&
                <div className="container">
                    <div className="admin-stats-container row">
                        <div className="admin-stats-table col-10">
                            <Table
                                filterValues={filtersFormatted}
                                getData={(page, order, filters) => formatData(filters.stores, filters.timePeriod)}
                                pagination={false}
                                columns={getColumns()}
                                dataPath="data.items"
                                elementKey="id"
                                emptyStateIntercomTarget="chat_dashboard_empty"
                                onElementClick={() => { }}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

ChatsDashboardContainer.translations = { ...translations }

export default withEventTracking(translate(ChatsDashboardContainer));
