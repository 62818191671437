import axiosClient from './axiosClient'

export const processParameters = (...params) => {
	const paramsObj = new URLSearchParams();
	params.forEach(([value, key]) => {
		if (value) {
			let parsedValue = value;
			if (Object.prototype.toString.call(value) === '[object Date]') {
				parsedValue = parsedValue.toISOString();
			}
			paramsObj.append(key, parsedValue);
		}
	})
	return paramsObj;
}

export const getAvailableSites = () => {
	return axiosClient.get(`/feedback/sources`);
}

export const getReviewsSummary = (filters, errorHandler = undefined) => {
	//console.log("getReviewsSummary", filters)
	return axiosClient.get(`/feedback/reviews/summary${jsonToQueryString(filters)}`, { errorHandler: errorHandler });
}

export const getReviews = (filters, page, order, errorHandler = undefined) => {
	//console.log("getReviews", filters)
	return axiosClient.get(`/feedback/reviews${jsonToQueryString(filters, page, order)}&pageSize=20`, { errorHandler: errorHandler });
}

export const getReview = (id) => {
	return axiosClient.get(`/feedback/reviews/${id}`);
}

export const report = (reviewId, comment) => {
	return axiosClient.post(`/feedback/reviews/${reviewId}/report`, { comment: comment });
}

export const reply = (reviewId, comment) => {
	return axiosClient.post(`/feedback/reviews/${reviewId}/reply`, { comment: comment, manual: true });
}

export const getInsights = (filters, order, errorHandler = undefined) => {
	return axiosClient.get(`/feedback/reviews/insights${jsonToQueryString(filters, undefined, order)}`, { errorHandler: errorHandler });
}

export const getInsightsEvolution = (filters, errorHandler = undefined) => {
	return axiosClient.get(`/feedback/reviews/insights/evolution${jsonToQueryString(filters)}`, { errorHandler: errorHandler });
}

export const getDetractors = (filters, order, errorHandler = undefined) => {
	return axiosClient.get(`/feedback/reviews/detractors${jsonToQueryString(filters, undefined, order)}`, { errorHandler: errorHandler });
}

export const getDetractorsEvolution = (filters, errorHandler = undefined) => {
	return axiosClient.get(`/feedback/reviews/detractors/evolution${jsonToQueryString(filters)}`, { errorHandler: errorHandler });
}

export const getReplyTemplates = (companyId, category = undefined, errorHandler = undefined) => {
	var queryCategory = category ? ('?category=' + category) : '';
	return axiosClient.get(`/feedback/replytemplates/${companyId}/basic${queryCategory}`, { errorHandler: errorHandler });
}

export const getTemplateApplied = (replyTemplateId, reviewId, errorHandler = undefined) => {
	return axiosClient.get(`/feedback/replytemplates/${replyTemplateId}/reviews/${reviewId}`, { errorHandler: errorHandler })
}

export const saveReplyTemplate = (replyTemplate, errorHandler = undefined) => {
	return axiosClient.post('/feedback/replytemplates', replyTemplate)
}

export const deleteReplyTemplate = (replyTemplate, companyId) => {
	return axiosClient.delete(`/feedback/replytemplates/${replyTemplate.id}?companyId=${companyId}`)
}

export const getFirstHundredReplyTemplates = (companyId, category = undefined, errorHandler = undefined) => {
	var queryCategory = category ? ('&category=' + category) : '';
	return axiosClient.get(`/feedback/replytemplates/${companyId}?pageNumber=0&pageSize=100${queryCategory}`)
}

export const getAutoReplyRules = (companyId, page) => {
	return axiosClient.get(`/feedback/autoreplyrules/${companyId}${(page) ? `?pageNumber=${page}&pageSize=20` : ''}`)
}

export const getFirstHundredAutoReplyRules = (companyId) => {
	return axiosClient.get(`/feedback/autoreplyrules/${companyId}?pageNumber=0&pageSize=100`)
}

export const saveAutoReplyRule = (autoReplyRule) => {
	return axiosClient.post(`/feedback/autoreplyrules`, autoReplyRule);
}

export const deleteAutoReplyRule = (autoReplyRule, companyId) => {
	return axiosClient.delete(`/feedback/autoreplyrules/${autoReplyRule.id}?companyId=${companyId}`)
}

export const createCompanyDefaults = (companyId) => {
	return axiosClient.post(`/feedback/autoreplyrules/default/${companyId}`)
}

export const getConnectedStores = (companyId, filters, page, errorHandler = undefined) => {
	return axiosClient.get(`/feedback/matching/${companyId}/connected-stores${jsonToQueryString(filters, page)}&pageSize=20`, { errorHandler: errorHandler })
}

// https://api3.botbit.io/feedback/reviews/summary?sources=2&stores=226,606
const jsonToQueryString = (json, page = undefined, order = undefined) => {
	return json === undefined || json === null ? '' : `?${page === undefined || page === null ? '' : 'pageNumber=' + page + '&'}` +
		Object.keys(json).reduce(function (filtered, key) {

			if (json[key] !== undefined && json[key] !== null && json[key].length > 0) {
				if (key === 'timePeriod') {
					if (typeof (json[key][1]) === 'string') {
						filtered.push(`from=${json[key][1]}`);
						filtered.push(`to=${json[key][2]}`);
					} else {
						filtered.push(`from=${json[key][1].toISOString()}`);
						filtered.push(`to=${json[key][2].toISOString()}`);
					}
					return filtered;
				}

				filtered.push(encodeURIComponent(key) + '=' +
					encodeURIComponent(json[key]));
			}
			return filtered;
		}, []).join('&') +
		`${(order === undefined || order === null) ? '' : '&sort=' + order.name + ',' + order.direction}`
}

export const exportReviews = (filters) => {
	return axiosClient.get(`/feedback/reviews/csv${jsonToQueryString(filters)}`)
}

// const npsDummy = {"data": {"data": [
	// {"id":64,"name":"Casa Temple","nps":null,"sent":null,"replies":1,"replyRate":null,"color":"#a0ff85"},
	// {"id":920,"name":"Rebelión","nps":null,"sent":null,"replies":null,"replyRate":null,"color":null},
	// {"id":223,"name":"Temple Brewery","nps":null,"sent":null,"replies":10,"replyRate":null,"color":"#a3f683"},
	// {"id":2208,"name":"Temple Caballito - Pedro Goyena","nps":null,"sent":null,"replies":null,"replyRate":null,"color":null},
	// {"id":2882,"name":"Temple Caminito","nps":null,"sent":null,"replies":null,"replyRate":null,"color":null},
	// {"id":83,"name":"Temple Cordoba","nps":null,"sent":null,"replies":7,"replyRate":null,"color":"#d19270"},
	// {"id":1425,"name":"Temple Craft - Castelar","nps":null,"sent":null,"replies":null,"replyRate":null,"color":null},
	// {"id":2837,"name":"Temple Craft Barrio Norte","nps":null,"sent":null,"replies":null,"replyRate":null,"color":null},
	// {"id":181,"name":"Temple Craft Botanico","nps":null,"sent":null,"replies":4,"replyRate":null,"color":"#a0ff85"},
	// {"id":925,"name":"Temple Craft Devoto","nps":91,"sent":null,"replies":60,"replyRate":null,"color":"#a3f783"},
	// {"id":152,"name":"Temple Craft Hollywood","nps":88,"sent":null,"replies":145,"replyRate":null,"color":"#a4f483"},
	// {"id":543,"name":"Temple Craft Madero","nps":74,"sent":null,"replies":301,"replyRate":null,"color":"#aae880"},
	// {"id":919,"name":"Temple Craft Nueva Cordoba","nps":null,"sent":null,"replies":2,"replyRate":null,"color":"#b3d47d"},
	// {"id":141,"name":"Temple Craft Pilar","nps":null,"sent":null,"replies":8,"replyRate":null,"color":"#b3d47d"},
	// {"id":2834,"name":"Temple Craft Recoleta Vicente López","nps":null,"sent":null,"replies":null,"replyRate":null,"color":null},
	// {"id":215,"name":"Temple Craft Salta","nps":null,"sent":null,"replies":2,"replyRate":null,"color":"#c6aa75"},
	// {"id":54,"name":"Temple Craft Soho","nps":null,"sent":null,"replies":17,"replyRate":null,"color":"#abe580"},
	// {"id":2327,"name":"Temple Lanus ","nps":null,"sent":null,"replies":null,"replyRate":null,"color":null},
	// {"id":2762,"name":"Temple Lomitas","nps":null,"sent":null,"replies":4,"replyRate":null,"color":"#a0ff85"},
	// {"id":1309,"name":"Temple Parador Pinamar","nps":null,"sent":null,"replies":null,"replyRate":null,"color":null},
	// {"id":2835,"name":"Temple Trastienda","nps":null,"sent":null,"replies":null,"replyRate":null,"color":null},
	// {"id":2813,"name":"Temple Zarate","nps":null,"sent":null,"replies":null,"replyRate":null,"color":null}
// ],"result":"success","message":"ok","statusCode":200}}

export const getStoresNPS = (filters, page, order) => {
	return axiosClient.get(`/feedback/reviews/nps${jsonToQueryString(filters, page, order)}`)
	// return new Promise((resolve, reject) => {
	// 	resolve(npsDummy)
	// })
}

export const getStoresNPSSummary = (companyId, intervalPeriod, sources) => {
	return axiosClient.get(`/feedback/reviews/nps/summary?company=${companyId}&intervalPeriod=${intervalPeriod}${sources ? `&sources=${sources}` : ""}`)
}

export const getReviewsWidgetConfig = companyId => {
	return axiosClient.get(`/reviews-widget-configurations/companies/${companyId}`);
}

export const setReviewsWidgetConfig = data => {
	return axiosClient.post('/reviews-widget-configurations', data);
}

export const exportInsighs = filters => {
	return axiosClient.get(`/feedback/reviews/insights/csv${jsonToQueryString(filters)}`);
}

export const sendReviewRequest = data => {
	return axiosClient.post('/feedback/reviews/nps/send', data);
}

export const savePrivateReview = data => {
	return axiosClient.post('/public-access/nps', data);
}

export const getReviewsCount = ({ stores, sources, period }) => {
	const params = processParameters(
		[stores, "stores"],
		[period && period.from, "from"],
		[period && period.to, "to"],
		[sources, "sources"],
		["true", "calculateAccumulates"]
	)

	return axiosClient.get(`/feedback/reviews/insights/evolution?${params}&bootbitGenerated=false&withoutReply=false&withComment=false&autoReplied=false`)
}

// const result = {
// 	"data": [
// 	  {
// 		"year": 2023,
// 		"month": 6,
// 		"day": null,
// 		"totalPositiveReviews": 559,
// 		"totalNegativeReviews": 168,
// 		"totalNeutralReviews": 24,
// 		"totalReviews": 751,
// 		"totalPositiveAndNegativeReviews": 727,
// 		"positivePercentage": 74.43408788282291,
// 		"negativePercentage": 22.37017310252996,
// 		"neutralPercentage": 3.195739014647131,
// 		"positiveWithoutNeutralPercentage": 76.89133425034387,
// 		"negativeWithoutNeutralPercentage": 23.108665749656126
// 	  },
// 	  {
// 		"year": 2023,
// 		"month": 7,
// 		"day": null,
// 		"totalPositiveReviews": 534,
// 		"totalNegativeReviews": 243,
// 		"totalNeutralReviews": 57,
// 		"totalReviews": 834,
// 		"totalPositiveAndNegativeReviews": 777,
// 		"positivePercentage": 64.02877697841727,
// 		"negativePercentage": 29.136690647482016,
// 		"neutralPercentage": 6.834532374100714,
// 		"positiveWithoutNeutralPercentage": 68.72586872586872,
// 		"negativeWithoutNeutralPercentage": 31.27413127413128
// 	  },
// 	  {
// 		"year": 2023,
// 		"month": 8,
// 		"day": null,
// 		"totalPositiveReviews": 507,
// 		"totalNegativeReviews": 137,
// 		"totalNeutralReviews": 57,
// 		"totalReviews": 701,
// 		"totalPositiveAndNegativeReviews": 644,
// 		"positivePercentage": 72.32524964336662,
// 		"negativePercentage": 19.543509272467904,
// 		"neutralPercentage": 8.131241084165477,
// 		"positiveWithoutNeutralPercentage": 78.72670807453416,
// 		"negativeWithoutNeutralPercentage": 21.27329192546584
// 	  },
// 	  {
// 		"year": 2023,
// 		"month": 9,
// 		"day": null,
// 		"totalPositiveReviews": 526,
// 		"totalNegativeReviews": 153,
// 		"totalNeutralReviews": 43,
// 		"totalReviews": 722,
// 		"totalPositiveAndNegativeReviews": 679,
// 		"positivePercentage": 72.85318559556787,
// 		"negativePercentage": 21.191135734072024,
// 		"neutralPercentage": 5.955678670360111,
// 		"positiveWithoutNeutralPercentage": 77.46686303387335,
// 		"negativeWithoutNeutralPercentage": 22.533136966126648
// 	  },
// 	  {
// 		"year": 2023,
// 		"month": 10,
// 		"day": null,
// 		"totalPositiveReviews": 434,
// 		"totalNegativeReviews": 152,
// 		"totalNeutralReviews": 50,
// 		"totalReviews": 636,
// 		"totalPositiveAndNegativeReviews": 586,
// 		"positivePercentage": 68.23899371069182,
// 		"negativePercentage": 23.89937106918239,
// 		"neutralPercentage": 7.861635220125795,
// 		"positiveWithoutNeutralPercentage": 74.06143344709898,
// 		"negativeWithoutNeutralPercentage": 25.938566552901023
// 	  },
// 	  {
// 		"year": 2023,
// 		"month": 11,
// 		"day": null,
// 		"totalPositiveReviews": 14,
// 		"totalNegativeReviews": 5,
// 		"totalNeutralReviews": 5,
// 		"totalReviews": 24,
// 		"totalPositiveAndNegativeReviews": 19,
// 		"positivePercentage": 58.333333333333336,
// 		"negativePercentage": 20.833333333333336,
// 		"neutralPercentage": 20.83333333333333,
// 		"positiveWithoutNeutralPercentage": 73.68421052631578,
// 		"negativeWithoutNeutralPercentage": 26.31578947368422
// 	  }
// 	],
// 	"result": "success",
// 	"message": "ok",
// 	"statusCode": 200
//   }

export const getReviewSentimentSummaryByMonth = (dateFrom, dateTo, companyIds, storeIds, socialSourceIds, keywordIds) => {
	const params = processParameters(
		[storeIds, "storeIds"],
		[socialSourceIds, "socialSourceIds"],
		[dateFrom, "dateFrom"],
		[dateTo, "dateTo"],
		[companyIds, "companyIds"],
		[keywordIds, "keywordIds"]
	)
	return axiosClient.get(`/feedback/sentiment/summary?${params}`)
	// return Promise.resolve({data: result})
}

export const getReviewSentimentByMonth = (companyIds, storeIds, socialSourceIds, year, month, keywordIds) => {
	const params = processParameters(
		[storeIds, "storeIds"],
		[socialSourceIds, "socialSourceIds"],
		[year, "year"],
		[month, "month"],
		[companyIds, "companyIds"],
		[keywordIds, "keywordIds"]
	)
	return axiosClient.get(`/feedback/sentiment/month-detail?${params}`)
}

export const getSentimentsByDay = (companyIds, storeIds, socialSourceIds, year, month, day, keywordIds) => {
	const params = processParameters(
		[storeIds, "storeIds"],
		[socialSourceIds, "socialSourceIds"],
		[year, "year"],
		[month, "month"],
		[day, "day"],
		[companyIds, "companyIds"],
		[keywordIds, "keywordIds"]
	)
	return axiosClient.get(`/feedback/sentiment/reviews?${params}`)
}

export const sendFeedbackForReviewSentiment = (id, text, isPositive) => {
	return axiosClient.post('/feedback/sentiment/reviews/report', {
		reviewSentimentId: id,
		text: text,
		isPositive: isPositive
	});
	// return Promise.resolve({})
}

export const getLastUpdateTime = (companyId) => {
	return axiosClient.get(`/feedback/sentiment/last-updated/company/${companyId}`);
	// return Promise.resolve({})
}

export const getKeywordsForCompany = (companyId) => {
	return axiosClient.get(`/feedback/sentiment/companies/${companyId}/keywords`);
}

export const createKeywordForCompany = (companyId, keyword) => {
	return axiosClient.post(`/feedback/sentiment/companies/${companyId}/keywords`, {keyword});
}

export const deleteKeywordForCompany = (companyId, keyword) => {
	return axiosClient.delete(`/feedback/sentiment/companies/${companyId}/keywords/${keyword}`);
}

export const getAnswerStats = (companyId, from, to, stores) => {
	const params = processParameters(
		[companyId, "company"],
		[from, "from"],
		[to, "to"],
		[stores, "stores"]
	)
	return axiosClient.get(`/feedback/reviews/summary/answers/stats?${params}`);
}


