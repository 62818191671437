import React, { useEffect } from "react";
import { MDBInput, MDBSwitch } from "mdbreact";
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  claimPage,
  createClient,
  getLocalPages,
  updateStyles,
} from "../../../api/localPagesApi";
import CollectionVisualizerContainer from "../../collectionVisualizer/CollectionVisualizerContainer";
import translate from "../../../config/translations/translate";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import { Select, Text, TextArea } from "../Inputs.js";
import { FormikLocalPageImageField } from "../ImageField.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../commons/components/application/miscActions.js";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Button } from 'botbit-ui-components';

const MySwal = withReactContent(Swal);
const LocalPagesContainer = ({ t }) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [domain, setDomain] = React.useState("");

  const company = useSelector((state) => state.application.selectedCompany);

  useEffect(() => {
    if (company.domain) {
      history.push(`${url}/list`);
    }
  }, [company]);
  return (
    <Switch>
      <Route exact path={path}>
        <LocalPagesDomain
          url={url}
          setDomain={setDomain}
          t={t}
          companyId={company.id}
        />
      </Route>
      <Route path={`${path}/list`}>
        <LocalPageList domain={domain} t={t} companyId={company.id} />
      </Route>
    </Switch>
  );
};

LocalPagesContainer.translations = {
  title: {
    es: "Páginas locales",
    en: "Local Pages",
  },
  subtitle: {
    es: "Administra tus páginas locales",
    en: "Manage your local pages",
  },
  domainInputPlaceholder: {
    es: "Ingrese el dominio de su empresa",
    en: "Enter your company domain",
  },
  searchDomain: {
    es: "Buscar páginas",
    en: "Search pages",
  },
  name: {
    es: "Nombre",
    en: "Name",
  },
  claimed: {
    es: "Reclamada",
    en: "Claimed",
  },
  descriptionInputPlaceholder: {
    es: "Descripción",
    en: "Description",
  },
  descriptionRequired: {
    es: "La descripción es requerida",
    en: "Description is required",
  },
  bannerPhotoRequired: {
    es: "La foto de portada es requerida",
    en: "Banner photo is required",
  },
  bannerPhotoLabel: {
    es: "Foto de portada",
    en: "Banner photo",
  },
  submit: {
    es: "Actualizar",
    en: "Submit",
  },
  titleInputPlaceholder: {
    es: "Título",
    en: "Title",
  },
  titleRequired: {
    es: "El título es requerido",
    en: "Title is required",
  },
  claim: {
    es: "Reclamar",
    en: "Claim",
  },
  socialNetworkRequired: {
    es: "La red social es requerida",
    en: "Social network is required",
  },
  socialNetworkUrlRequired: {
    es: "Una url válida de la red social es requerida",
    en: "A valid social network url is required",
  },
  addSocialNetwork: {
    es: "Agregar red social",
    en: "Add social network",
  },
  socialNetwork: {
    es: "Red social",
    en: "Social network",
  },
  url: {
    es: "Url",
    en: "Url",
  },
  submitOkText: {
    es: "Actualizado correctamente",
    en: "Updated successfully",
  },
  domainFormTitle: {
    es: "Bienvenido a tus Local Pages",
    en: "Welcome to your Local Pages",
  },
  domainFormsubtitle: {
    es: "Ingresa el dominio de tu empresa para comenzar",
    en: "Enter your company domain to start",
  },
  visit: {
    es: "Visitar",
    en: "Visit",
  },
  updateAll: {
    es: "Actualizar todas",
    en: "Update all",
  },
  submitAllOkText: {
    es: " actualizadas correctamente",
    en: " updated successfully",
  },
  submitConfirmText:{
    es: "Se actualizaran todas tus páginas con estos valores",
    en: "All pages will be updated with this values"
  },
  cancel:{
    es: "Cancelar",
    en: "Cancel"
  }
};

export default translate(LocalPagesContainer);

const LocalPagesDomain = ({ url, setDomain, t, companyId }) => {
  const [inputValue, setInputValue] = React.useState("");
  const history = useHistory();

  return (
    <div>
      <h1>{t("domainFormTitle")}</h1>
      <p>{t("domainFormsubtitle")}</p>
      <MDBInput
        label={t("domainInputPlaceholder")}
        getValue={(v) => setInputValue(v)}
      />
      <Button
        onClick={() => {
          createClient(companyId, inputValue).then(() => {
            setDomain(inputValue);
            history.push(`${url}/list`);
          });
        }}
      >
        {t("searchDomain")}
      </Button>
    </div>
  );
};

const LocalPageList = ({ domain, t, companyId }) => {
  const dispatch = useDispatch();
  const [allPlaces, setAllPlaces] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const _claimPage = (placeId) => {
    dispatch(setLoading(true));
    claimPage(placeId).then(() => {
      dispatch(setLoading(false));
      MySwal.fire({
        type: "success",
        text: t("submitOkText"),
        confirmButtonText: t("ok"),
        showCloseButton: true,
      });
    });
  };
  const columns = [
    {
      title: t("name"),
      dataField: "name",
    },
    {
      title: "URL",
      dataField: "localPageUrl",
      formatter: (cellContent, row) => {
        return (
          <a rel="noopener noreferrer" href={cellContent} target="_blank">
            {t("visit")}
          </a>
        );
      },
    },
    {
      title: t("claimed"),
      dataField: "claimed",
      className: "text-center m-auto d-none d-md-block",
      headerClassName: "text-center px-5 d-none d-md-block",
      size: 2,
      formatter: claimedButtonFormatter(_claimPage, t),
    },
    {
      title: t("claimed"),
      dataField: "claimed",
      className: "text-center m-auto d-sm-block d-md-none",
      headerClassName: "d-none",
      size: 12,
      formatter: claimedButtonFormatter(_claimPage, t),
    },
  ];

  React.useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  if (reload) return null;

  return (
    <CollectionVisualizerContainer
      getData={(page, order, filters) =>
        getLocalPages(companyId).then((res) => {
          setAllPlaces(
            res.data.data
              .filter((place) => place.claimed)
              .map((place) => place.placeId)
          );
          return res;
        })
      }
      pagination={false}
      title={t("title")}
      subTitle={<p>{t("subtitle")}</p>}
      dataPath="data"
      columns={columns}
      rowKey="placeId"
      expandRow={(active, row, onRowChanged) => {
        if (!row.claimed) return null;
        return (
          <LocalPageForm
            active={active}
            t={t}
            customStyle={row.customStyle}
            placeId={row.placeId}
            allPlaces={allPlaces}
            reload={() => setReload(true)}
          />
        );
      }}
    />
  );
};

const LocalPageForm = ({
  t,
  customStyle,
  placeId,
  allPlaces,
  active,
  reload,
}) => {
  const formRef = React.useRef();
  const [updateAll, setUpdateAll] = React.useState(false);
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    if (updateAll) {
      MySwal.fire({
        type: "warning",
        text: t("submitConfirmText"),
        confirmButtonText: t("ok"),
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: t("cancel")
        
      }).then((result)=>{
        if(result.value){
          allPlaces.forEach(async (placeId, index) => {
            dispatch(setLoading(true));
            await updateStyles(placeId, values).then(() => {
              if (index === allPlaces.length - 1) {
                dispatch(setLoading(false));
                MySwal.fire({
                  type: "success",
                  text: allPlaces.length + t("submitAllOkText"),
                  confirmButtonText: t("ok"),
                  showCloseButton: true,
                });
                reload();
              }
            });
          });
        }})
      return;
    }
    dispatch(setLoading(true));
    updateStyles(placeId, values).then(() => {
      dispatch(setLoading(false));
      MySwal.fire({
        type: "success",
        text: t("submitOkText"),
        confirmButtonText: t("ok"),
        showCloseButton: true,
      });
    });
  };
  React.useEffect(() => {
    if (!active) {
      setUpdateAll(false);
    }
  }, [active]);

  const validationSchema = Yup.object().shape({
    title: Yup.string(), //.required(t("titleRequired")),
    description: Yup.string(), //.required(t("descriptionRequired")),
    bannerPhoto: Yup.string(), //.required(t("bannerPhotoRequired")),
    socialNetworks: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().required(t("socialNetworkRequired")),
        url: Yup.string()
          .url(t("socialNetworkUrlRequired"))
          .required(t("socialNetworkUrlRequired")),
      })
    ),
  });
  return (
    <div className="mx-5">
      <Formik
        ref={formRef}
        initialValues={customStyle}
        onSubmit={(values, actions) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ errors, handleSubmit, values }) => {
          return (
            <form data-testid="LocalPageForm" onSubmit={handleSubmit}>
              <Text
                name="title"
                label={t("titleInputPlaceholder")}
                errors={errors.title}
              />
              <TextArea
                name="description"
                label={t("descriptionInputPlaceholder")}
                errors={errors.description}
              />
              <FormikLocalPageImageField
                label={t("bannerPhotoLabel")}
                images={[]}
                errors={errors.bannerPhoto}
                t={t}
              />
              <SocialNetworksField t={t} values={values} errors={errors} />
              <div className="text-right">
                <MDBSwitch
                  checked={updateAll}
                  onChange={() => setUpdateAll(!updateAll)}
                  labelLeft=""
                  labelRight={t("updateAll")}
                />
              </div>
              <Button
                type="submit"
                className="btn btn-primary float-rigth float-right"
                data-testid="submit"
              >
                {t("submit")}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

const SocialNetworksField = ({ t, values, errors }) => {
  return (
    <FieldArray
      name="socialNetworks"
      render={(arrayHelpers) => (
        <div>
          {values.socialNetworks &&
            values.socialNetworks.length > 0 &&
            values.socialNetworks.map((socialNetwork, index) => (
              <div key={index}>
                <Select
                  label={t("socialNetwork")}
                  name={`socialNetworks.${index}.id`}
                  errors={
                    errors.socialNetworks &&
                    errors.socialNetworks[index] &&
                    errors.socialNetworks[index].id
                  }
                  options={[
                    { text: "Facebook", value: 0 },
                    { text: "X", value: 1 },
                    { text: "Instagram", value: 2 },
                    { text: "Pinterest", value: 3 },
                    { text: "TikTok", value: 4 },
                    { text: "Google", value: 5 },
                    { text: "Web", value: 6 },
                    { text: "Yelp", value: 7 },
                  ]}
                />
                <Text
                  name={`socialNetworks.${index}.url`}
                  label={t("url")}
                  errors={
                    errors.socialNetworks &&
                    errors.socialNetworks[index] &&
                    errors.socialNetworks[index].url
                  }
                />
                <Button
                  type="button"
                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                >
                  -
                </Button>
              </div>
            ))}
          <Button onClick={() => arrayHelpers.push({})}>
            {/* show this when user has removed all friends from the list */}
            {t("addSocialNetwork")}
          </Button>
        </div>
      )}
    />
  );
};
function claimedButtonFormatter(_claimPage, t) {
  return (cellContent, row) => {
    return cellContent ? (
      <FontAwesomeIcon
        data-tip
        data-for={"verified.info"}
        icon={faCheckCircle}
        color={"#4b89ff"}
      />
    ) : (
      <Button onClick={() => _claimPage(row.placeId)}>{t("claim")}</Button>
    );
  };
}
