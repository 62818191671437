import React, { useState } from "react";
import { MDBContainer, MDBBadge } from "mdbreact";
import { CaptureMode } from "../../commons/enums/CaptureMode";
import { SocialSource } from "../../commons/enums/SocialSource";
import SocialSourceIcon from "../feedback/SocialSourceIcon";
import { envConfig } from "../../config/config";
import "./socialAccounts.css";
import { SocialAccountStatus, getSocialAccountStatus } from "../../commons/enums/SocialAccountStatus";
import GoogleFormModal from "./GoogleFormModal";
import ConnectedStoresModal from "./ConnectedStoresModal";
import CollectionVisualizerContainer from "../collectionVisualizer/CollectionVisualizerContainer";
import SocialAccount from "./SocialAccount";
import DateTime from "../../commons/components/time/DateTime";

const SocialAccounts = ({
  t,
  title,
  captureMode,
  getSocialAccounts,
  availableCompanies,
  selectedCompany,
  googleOptIn,
  facebookOptIn,
  getStoresByCompany,
  goToConnectedStores,
}) => {
  const [stores, setStores] = useState([]);

  // const getMockData = (page, order, filter) => {
  //   console.log('This works');
  //   const response = new Promise((resolve) => resolve({ ...data }));
  //   return response;
  // };

  const column_container = window.innerWidth >= 992 ? ' column-container' : '';
  const columns = [
    // for BIG screens: greater than md
    {
      dataField: 'socialSourceId',
      title: t('socialAccounts.source'),
      className: 'align-left text-truncate d-none d-md-block' + column_container,
      size: 1,
      formatter: (cellContent, row) => {
        return <SocialSourceIcon site={getSite(cellContent)} />
      }
    },
    {
      dataField: 'sourceUserId',
      title: t('socialAccounts.email'),
      size: 4,
      className: 'd-none d-md-block text-left text-truncate' + column_container,
      formatter: (cellContent, row) => {
        return (
          <div className="row-title">
            {row.sourceUserId}
          </div>
        )
      }
    },
    {
      dataField: 'socialAccounts.administrator',
      title: t('socialAccounts.admin'),
      className: 'd-none d-md-block' + column_container,
      size: 4,
      formatter: (cellContent, row) => {
        return (
          <div className="col-align-center">
            <div style={{
              width: '25px',
              height: '25px',
              borderRadius: '50%',
              backgroundImage: `url(${envConfig.staticImgUrl}avatars/${row.administrator && row.administrator.avatar ? row.administrator.avatar : 'mini-noavatar.jpg'})`,
              backgroundSize: 'cover',
              marginBottom: '5px',
              float: 'left'
            }} />
            <span className="text-dark text-truncate" style={{
              fontSize: '12px',
              lineHeight: '19px',
              fontWeight: '600',
              maxWidth: '100%'
            }}>{(row.administrator && row.administrator.fullName) || 'N/A'}</span>
            <DateTime timestamp={row.timestamp} bold />
          </div>
        )
      }
    },
    {
      dataField: 'status',
      title: t('socialAccounts.status'),
      className: 'align-left text-truncate d-none d-md-block p-0' + column_container,
      formatter: (cellContent, row) => {
        const status = getSocialAccountStatus(cellContent)
        return (
          <MDBBadge color={status.color}>{t(status.label)}</MDBBadge>
        );
      }
    },
    {
      dataField: '',
      className: 'align-left text-truncate d-none d-md-block p-0' + column_container,
      size: 1,
      formatter: (cellContent, row) => {
        return (
          <>
            <button
              className="btn-primary"
              style={{
                border: "none",
                padding: "0.2rem",
                borderRadius: "3px",
                cursor: "pointer",
                boxShadow: "0 3px 6px #ccc"
              }}
            >
              {t('socialAccounts.seeAccounts')}
            </button>
          </>
        )
      }
    },
    // for SMALL screens: lower than md
    {
      dataField: 'socialSourceId',
      className: 'align-left text-truncate d-md-none',
      size: 4,
      formatter: (cellContent, row) => {
        return <SocialSourceIcon site={getSite(cellContent)} />
      }
    },
    {
      //title: t('socialAccounts.sourceUserId'),
      dataField: 'sourceUserId',
      isHeader: true,
      size: 11,
      className: 'text-left text-truncate row-title d-md-none'
    },
    {
      dataField: 'status',
      className: 'align-left text-truncate d-md-none p-0',
      formatter: (cellContent, row) => {
        const status = getSocialAccountStatus(cellContent)
        return (
          <>
            <MDBBadge color={status.color}>{t(status.label)}</MDBBadge>
            {/* <button 
                className="btn-primary"
                style={{
                  border: "none",
                  padding: "0.2rem",
                  borderRadius: "3px",
                  cursor: "pointer",
                  boxShadow: "0 3px 6px #ccc",
                  marginLeft: 10
                }} 
              >
                {t('socialAccounts.clients')}
              </button> */}
          </>
        );
      }
    }
  ]


  const getSite = (socialSourceId) => {
    if (socialSourceId === SocialSource.GOOGLE) {
      return captureMode === CaptureMode.REVIEWS
        ? SocialSource.GMB
        : SocialSource.GMAIL;
    }
    return socialSourceId;
  };

  const getDefaultFilters = () => {
    const statusFilter = {
      id: 'status',
      label: t('socialAccounts.status'),
      type: 'select',
      placeholder: t('socialAccounts.allStatus'),
      priority: false,
      visible: true,
      values: [
        {
          checked: false,
          value: 'PENDING',
          text: t(SocialAccountStatus.PENDING.label),
        },
        {
          checked: false,
          value: 'CONNECTED',
          text: t(SocialAccountStatus.CONNECTED.label),
        },
        {
          checked: false,
          value: 'ERROR',
          text: t(SocialAccountStatus.ERROR.label),
        },
      ],
    };

    const socialUserIdFilter = {
      id: 'sourceUserId',
      label: t('socialAccounts.sourceUserId'),
      type: 'input',
      placeholder: t('socialAccounts.sourceUserId'),
      priority: false,
      visible: true,
    };

    return [statusFilter, socialUserIdFilter];
  };

  const getData = (filters) => {
    if (getStoresByCompany) {
      getStoresByCompany(selectedCompany.id).then((response) => {
        if (response && response.data && response.data.data) {
          setStores(response.data.data);
        }
      });
    }

    return getSocialAccounts(filters);
  };

  const headerActions = (updateRow) => {
    const googleAction = {
      key: 1,
      icon: {
            url: '/static/img/social_logos/google_icon.png',
            className: 'google-header-icon'
        },
      iconFab: true,
      className: 'google-header-button',
      label: t('socialAccounts.connectGoogle'),
      primary: true,
      onClick: () => connectWithGoogle(updateRow),
    };

    const facebookAction = {
      key: 2,
      icon: 'facebook',
      iconFab: true,
      color: 'blue',
      label: t('socialAccounts.connectFacebook'),
      primary: true,
      onClick: () => connectWithFacebook(updateRow),
    };

    return captureMode === CaptureMode.REVIEWS
      ? [googleAction, facebookAction, getShowStoresAction()]
      : [googleAction];
  };

  const connectWithGoogle = (updateRow) => {
    if (captureMode === CaptureMode.REVIEWS) {
      googleOptIn(captureMode, selectedCompany.id).then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.redirectUrl
        ) {
          window.location = response.data.data.redirectUrl;
        }
      });
    } else {
      openGoogleFormModal(updateRow);
    }
  };

  const [showGoogleFormModal, setShowGoogleFormModal] = useState(false);

  const [actions, setActions] = useState(undefined);

  const googleForm = { storeId: undefined };

  const openGoogleFormModal = (updateRow) => {
    setActions({ updateRow: updateRow });
    setShowGoogleFormModal(true);
  };

  const onSubmitGoogleForm = (form, actions) => {
    googleOptIn(captureMode, selectedCompany.id, form.storeId).then(
      (response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.redirectUrl
        ) {
          window.open(response.data.data.redirectUrl, '_blank');
          actions.updateRow(undefined, 'refresh');
          setShowGoogleFormModal(false);
        }
      }
    );
  };

  const connectWithFacebook = (updateRow) => {
    facebookOptIn(selectedCompany.id).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.redirectUrl
      ) {
        window.location = response.data.data.redirectUrl;
      }
    });
  };

  const rowActions = (row, updateRow) => {
    return captureMode === CaptureMode.REVIEWS
      ? []
      : [getShowStoresAction(row)];
  };

  const getShowStoresAction = (row) => {
    return {
      key: 100,
      color: '',
      icon: 'store-alt',
      label: t('socialAccounts.showStores'),
      primary: false,
      onClick: () =>
        captureMode === CaptureMode.REVIEWS
          ? goToConnectedStores()
          : openShowConnectedStoresModal(row),
    };
  };

  const [showConnectedStoresModal, setShowConnectedStoresModal] = useState(
    false
  );

  const [socialAccountStores, setSocialAccountStores] = useState(undefined);

  const openShowConnectedStoresModal = (row) => {
    setSocialAccountStores(row.stores);
    setShowConnectedStoresModal(true);
  };

  return (
    <>
      <GoogleFormModal
        showModal={showGoogleFormModal}
        setShowModal={setShowGoogleFormModal}
        stores={stores}
        googleForm={googleForm}
        onSubmit={onSubmitGoogleForm}
        actions={actions}
        t={t}
      />

      <ConnectedStoresModal
        showModal={showConnectedStoresModal}
        setShowModal={setShowConnectedStoresModal}
        stores={socialAccountStores}
        t={t}
      />

      <MDBContainer className="mt-3 mb-3">
        <CollectionVisualizerContainer
          title={title}
          // getData={() => getMockData()}
          getData={(page, order, filters) => getData(filters)}
          columns={columns}
          pagination={false}
          dataPath="data"
          defaultFilters={getDefaultFilters()}
          showFiltersInfo
          headerActions={headerActions}
          rowActions={rowActions}
          companies={availableCompanies}
          selectedCompany={selectedCompany}
          expandRow={(active, row, onRowChanged) => {
            return active && <SocialAccount socialAccountId={row.id} t={t} />
          }}
        />
      </MDBContainer>
    </>
  );
};

export default SocialAccounts;
