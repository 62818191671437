import React from "react";
import "./DataCard.scss";
import translate from "../../../config/translations/translate";
import { translations } from "../../../config/translations/Dashboard/DataCard";
import { NewLoader } from "../../NewLoader/NewLoader";

/* eslint-disable jsx-a11y/accessible-emoji */
const DataCard = ({ data, period, config, t, actions = { body: () => { }, footer: () => { } }, intercomName, extraData }) => {
  return (
    <div data-intercom-target={intercomName} className={`data-card ${data ? "" : "loading"}`}>
      {data ? (
        <>
          <div className={`card-body-container ${config.id}`}>
            {!config.noTitle && <h4 className={`card-title${config.compactCard ? " small-title" : ""}`}>{t(`${config.id}`)}</h4>}
            {config.body(data, actions.body, period, extraData)}
          </div>
          {config.footer(data, actions.footer, period, extraData)}
        </>
      ) : (
        <NewLoader />
      )}
    </div>
  );
};

DataCard.translations = {
  ...translations,
};

export default translate(DataCard);
