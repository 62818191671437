import React from 'react';
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import 'moment/locale/es';
import 'rc-input-number/assets/index.css';
import NumericInput from "react-numeric-input";

const SecondsEditor = ({ valueSeconds, onChange, t }) => {
	//const [seconds, setSeconds] = useState(valueSeconds);

	var minutes = Math.floor(valueSeconds/60) % 60;
	var hours = Math.floor(valueSeconds/3600) % 24;;
	var days = Math.floor(valueSeconds/86400);

	function changed (who, newValue) {
		let minutes = (who === "minutes") ? newValue : Math.floor(valueSeconds/60) % 60;
		let hours = (who === "hours") ? newValue : Math.floor(valueSeconds/3600) % 24;
		let days = (who === "days") ? newValue : Math.floor(valueSeconds/86400);

		let newSecondsValue = minutes * 60 + hours * 60 * 60 + days * 60 * 60 * 24;
		if (onChange) {
			onChange(newSecondsValue);
		}
	}

	const createField = (min, max, value, label, type) => {
		return 	(
			<MDBCol size="12" sm="4">
				<span>{label}</span>
				<NumericInput 
					className="form-control" 
					min={min} 
					max={max} 
					step={1} 
					value={value} 
					onChange={ (newValue) => changed(type, newValue) } 
					//format={ (value) => value + " " + type }
				/>
			</MDBCol>
		)
	}

	return (
		<MDBContainer className="form-group">
			<MDBRow>
			{ createField(0,120, days, t("common.dateLabels.days"), "days") }
			{ createField(0,23, hours, t("common.dateLabels.hours"), "hours") }
			{ createField(0,59, minutes, t("common.dateLabels.minutes"), "minutes") }
			</MDBRow>
		</MDBContainer>)
}

export default SecondsEditor;