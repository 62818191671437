import React from 'react';
import Chart from "react-google-charts";
import DateRangePicker from '../form/DateRangePicker';
import Loading from '../Loading';
import PropTypes from 'prop-types';
import {Container, Col, Row} from "react-bootstrap";
import './lineChart.css'

const options = {
	animation: {
		            duration: 1500,
		            easing: 'linear',
		            startup: true,
		          },
    legend: {
        position: 'labeled',
		textStyle: {
			fontName: "open sans"
		}
    },
    colors: [
         { color: '#169881' },
         { color: '#1ab394' },
         { color: '#23c6c8' },
         { color: '#1872ab' },
         { color: '#676a6c' },
         { color: '#f7ac59' },
         { color: '#f69d3c' },
         { color: '#ed5565' },
         { color: '#ea394c' },
         { color: '#1eacae' }
    ],
    'width':'100%',
	'height':150,
	tooltip: { 
		trigger: 'selection',
		textStyle: {
			fontName: "open sans"
		}
	}
};

const miniatureOptions = {
    ...options,
    'height': '3rem',
    'width': '6rem',
    tooltip: { trigger: 'none' },
    pointsVisible: false,
}
const moment = require('moment');
const thisMonth = {
    dateFrom: moment().startOf('month').toDate(),
    dateTo: moment().endOf('month').toDate()
};


class PieChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            zoom:'d-none'
        }
    }

    calculateChartData = () => {
        var titles = [this.props.hAxis.label,this.props.vAxis.label];
        var chartData =
            this.props.source.map((x) => {
				var pointArray = [this.props.hAxis.calc(x),this.props.vAxis.calc(x)];
                return pointArray;
            });
        chartData.unshift(titles);
        return chartData
    }

    render = () => {
        if(this.props.source===undefined || this.props.source===[]){
            return (<Loading loading={this.props.source===undefined}/>);
        }
		var data = this.calculateChartData();
        if(this.props.miniature ===true ){
            return(
                <Container onMouseLeave={(event)=>{this.setState({zoom:'d-none'})}}>
                    <Row >
                        <Col lg={10}>
                            <Chart
                            chartType="PieChart"
                            data={this.calculateChartData()}
                            options={miniatureOptions}
                            />
                            <div 
                            className='overlay'
                            onClick={(event)=>{this.setState({zoom:'animated fadeInRight'})}}
                            
                            />
                        </Col>
                        <Col lg={2}>
                        </Col>
                    </Row>
                    <Row className={this.state.zoom}>
                        <Chart
                        chartType="PieChart"
                        data={data}
                        options={options}
                        />
                    </Row>
                </Container>
            )
        }
        return (
            <React.Fragment>
                {
                    this.props.dateRangeFilterChangeHandler !== undefined &&
                    (
                            <DateRangePicker
                                onSelect={this.props.dateRangeFilterChangeHandler}
                                initDate={this.props.initDate} />
                        )
                }
                
                        <Chart
							chartLanguage={require('browser-locale')()}
							className='animated fadeInRight'
                            chartType="PieChart"
                            data={data}
                            options={options}
                        />
                    
            </React.Fragment>
        );
    }
}

PieChart.defaultProps = {
    initDate: thisMonth
};


PieChart.propTypes = {
    hAxis: PropTypes.shape({
        label: PropTypes.string,
        calc: PropTypes.func.isRequired
    }).isRequired,
    source:  PropTypes.arrayOf(PropTypes.object),
    vAxis: PropTypes.shape({
            label: PropTypes.string,
            calc: PropTypes.func
    }).isRequired,
    dateRangeFilterChangeHandler: PropTypes.func,
    initDate: PropTypes.shape({
        dateFrom: PropTypes.object,
        dateTo: PropTypes.object
    }),
    miniature: PropTypes.bool
}

export default (PieChart)
