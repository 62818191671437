import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from "react-redux";
import {  withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import translate from '../../config/translations/translate';
import { translations } from '../../config/translations/Checklist/ChecklistContainer';
import CollectionVisualizerContainer from "../collectionVisualizer/CollectionVisualizerContainer";
import { MDBBtn, MDBContainer, MDBProgress } from 'mdbreact';
import { getChecklist, ignoreTask, unignoreTask } from "../../api/administratorApi";
import useWindowDimensions from '../../commons/utils/useWindowDimensions';
import TodoTaskStatus from './TodoTaskStatus';

import "./ChecklistContainer.scss"
import { envConfig } from '../../config/config';
import { setLoading } from '../../commons/components/application/miscActions';
import { Alert } from 'react-bootstrap';

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}

const ChecklistContainer = ({t, history}) => {

    const {isUpMedium: isDesktop} = useWindowDimensions()

    const [showTasks, setShowTasks] = useState(true)

    const [progress, setProgress] = useState(0)

    const ssoToken = useSelector(state => state.login.token)

    useEffect(() => {
        document.addEventListener(visibilityChange, handleVisibilityChange, false)

        return () => document.removeEventListener(visibilityChange, handleVisibilityChange)
    }, [])

    const dispatch = useDispatch();

    const handleVisibilityChange = () => {
        if (document[hidden]) {
            setShowTasks(false)
        } else {
            setShowTasks(true)
        }
    }

    const getTaskTitle = taskName => t('todotask.' + taskName + '.title')

    const getTaskDisabledText = taskName => t('todotask.' + taskName + '.disabled')

    const columns = [
        {
            dataField: 'status',
            size: 1,
            className: 'text-right',
            formatter: (cellContent, row) => <TodoTaskStatus status={cellContent} orderNumber={row.orderNumber} />
        },
        {
            dataField: 'name',
            size: 9,
            formatter: (cellContent, row) => <span className={row.status === 'DISABLED' ? 'task-title-disabled' : 'task-title'}>
                {getTaskTitle(cellContent)}
            </span>
        }
    ]

    /*
    const rowActions = (row) => ['DISABLED', 'DONE'].includes(row.status) ? [] : [
        {
            key: 1,
            icon: 'angle-right',
            primary: true,
            onClick: () => executeAction(row)
        }
    ]
    */

    const executeAction = (row) => {
        switch (row.action.type) {
            case 'INTERCOM':
                if (isDesktop) {
                    // tiene que haber redirección para que se active el tour
                    window.location.href = row.action.url + '&fromChecklist=true'
                } else {
                    window.open(row.action.secondaryUrl, "_blank")
                }
                break
            case 'REDIRECTION':
                const finalUrl = addSsoTokenIfNecessary(row.action.url)
                window.location.href = finalUrl
                break
            default:
                window.open(row.action.url, "_blank");
        }
    }

    const ignore = (row, onRowChanged) => {
        dispatch(setLoading(true));
        setShowTasks(false);
        ignoreTask(row.id)
        .then(()=> {
            dispatch(setLoading(false));
            setShowTasks(true);
        });
    }


    const unignore = (row, onRowChanged) => {
        dispatch(setLoading(true));
        setShowTasks(false);
        unignoreTask(row.id)
        .then(()=> {
            dispatch(setLoading(false));
            setShowTasks(true);
        });
    }
    const addSsoTokenIfNecessary =  url => {
        const isPhpDest = url.includes('.php')
        return isPhpDest ? `${url}&sso=${ssoToken}` : url
    }

    const getData = async () => {
        const checklist = await getChecklist()
        const result = checklist && checklist.data && checklist.data.data ? 
            {data:{data:checklist.data.data.filter((item) => item.status !== 'HIDE')}} : 
            {}
        if (result && result.data && result.data.data) {
            setProgress(calculateProgress(result.data.data))
        }
        return result;
    }

    const calculateProgress = (tasks) => {
        const reducer = (doneTasks, currentTask) => currentTask.status === 'DONE' ? ++doneTasks : doneTasks
        const doneTasks = tasks.reduce(reducer, 0)
        return (doneTasks / tasks.length) * 100
    }

    const addChecklistParam = (url) => {
        return url.includes('?') ? url + '&fromChecklist=true' : url + '?fromChecklist=true'
    }
    return (<>

            <MDBContainer className="mt-3">

                <h1>{t('title')}</h1>
                <h2 style={{fontWeight:'bold'}}>{t('subtitle')}</h2>
                
                {showTasks && <>
                    <CollectionVisualizerContainer
                        header={
                            <div className="mt-2 mb-2">
                                <MDBProgress value={progress} />
                            </div>
                        }
                        scrollTopButton
                        getData={getData}
                        pagination={false}
                        columns={columns}
                        dataPath='data'
                        //rowActions={rowActions}
                        rowsCommonClasses={task => {
                            return  (task.status === 'DISABLED' ? 'task-disabled' : '');
                        }}
                        expandRow={(active, row, onRowChanged) => {
                            // if (active === true && row.status === 'TODO') {
                            //     executeAction(row)
                            //     return null
                            // }
                            let message;
                            const msgClass='mt-3'
                            if (active){
                                switch (row.status) {
                                    case 'DISABLED':
                                        message = <div className={msgClass}><span>{getTaskDisabledText(row.name)}</span></div>
                                        break;
                                    default:
                                        if(row.usingSecondaryUrl)
                                            message = (<div className={msgClass}><span>{t(`todotask.${row.name}.usingSecondaryUrl`)}</span></div>);
                                        else
                                            message = (<div className={msgClass}><span>{t(`todotask.${row.name}.todo`)}</span></div>);
                                        break;
                                }
                            }
                            const imageName = row.usingSecondaryUrl ? `${row.name}_secondary.png` : `${row.name}.png`;
                            return <div className='row'>
                                <div className='my-3 m-md-auto col-12 col-lg-9'>
                                    <div className='pl-5 pr-3'>
                                        {row.status === 'DONE' && 
                                            <Alert variant='success' dismissible={false}>
                                                <div><span>{t(`todotask.completed`)}</span></div>
                                            </Alert>
                                        }
                                        {row.status === 'IGNORED' && 
                                            <Alert variant='info' dismissible={false}>
                                                <div><span>{t(`todotask.ignored`)}</span></div>
                                            </Alert>
                                        }
                                        {message}
                                        {row.status === 'TODO' && 
                                            <div className='row mx-auto mt-3 width-md-50'>
                                                <div className='col-12 col-md-6 order-last order-md-first text-center'>
                                                    <MDBBtn color="secondary" size="sm" onClick={()=>{ignore(row, onRowChanged)}}>{t('todotask.ignore')}</MDBBtn>
                                                </div>
                                                <div className='col-12 col-md-6 text-center'>
                                                    <MDBBtn color="primary" size="sm" onClick={()=>{executeAction(row)}}>{t('todotask.doitnow')}</MDBBtn>
                                                </div>
                                            </div>
                                        }
                                        {row.status === 'IGNORED' && 
                                            <div className='text-center mt-3'>
                                                <MDBBtn color="primary" size="sm" onClick={()=>{unignore(row, onRowChanged)}}>{t('todotask.unignore')}</MDBBtn>
                                            </div>
                                        }
                                        {row.status === 'DONE' &&  row.action.url &&
                                            <div className='text-center mt-3'>
                                                <MDBBtn color="primary" size="sm" onClick={()=>{history.push(addChecklistParam(row.action.url))}}>{t('todotask.gonow')}</MDBBtn>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='col-12 col-lg-3 d-none d-md-inline'>
                                    <img style={{width:'100%'}} src={`${envConfig.staticImgUrl}/checklist/${imageName}`} alt="Description"></img>
                                </div>
                                
                            </div>        
                        }}
                    />
                </>}
            </MDBContainer>
        </>
    )
}

ChecklistContainer.translations = {
    ...translations,
};

export default withRouter(translate(ChecklistContainer))
