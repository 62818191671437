import axiosClient from './axiosClient'


//Mock de una respuesta con los campos que necesitamos:
// Nombre 
// Dirección
// Teléfono
// Cuenta verificada. Boolean
// Posibles cuentas duplicadas. Array de places
// Valoración en google
// cantidad de reviews
// promedio del puntaje
// Cantidad de fotos publicadas
// sitio web

const mockResponse = [{
    name: 'Casa de la pizza',
    address: 'Av. Corrientes 1234',
    phone: '1234-1234',
    verified: true,
    rating: 4.5,
    reviews: 123,
    averageRating: 4.5,
    photos: 123,
    website: 'www.casadelapizza.com.ar',
    categories: ['Pizzeria', 'Restaurant'],
    socialLocationId: 1364,
    placeId:'ChIJzcrefk61vJURNjw-wgQPJQg'
},{
    name: 'Casa de la birra Buenos Aires',
    address: 'Av. Buenos Aires 1234',
    verified: false,
    possibleDuplicate: [{
        name: 'Casa de la pizza',
        address: 'Av. Corrientes 1234',
        phone: '1234-1234',
        verified: true,
        rating: 4.5,
        reviews: 123,
        averageRating: 4.5,
        photos: 123,
        website: 'www.casadelapizza.com.ar',
        categories: ['Pizzeria', 'Restaurant'],
    }],
    rating: 4.5,
    reviews: 123,
    averageRating: 4.5,
    photos: 123,
    website: 'www.casadelapizza.com.ar',
    socialLocationId: 1364,
    categories: [
        "App Development Company",
        "Software Company",
        "Mobile App Development Service",
        "Software Application Development",
        "Mobile Application Software",
        "Tech Startup",
        "Information Technology Company",
        "Business to Business Service",
        "Computer Software Store",
        "Internet Service Provider"
    ],
    placeId:'ChIJzcrefk61vJURNjw-wgQPJQg'
},{
    name: 'Casa de la birra Duplicado',
    phone: '1234-1234',
    verified: false,
    possibleDuplicate: [{
        name: 'Casa de la pizza',
        address: 'Av. Corrientes 1234',
        phone: '1234-1234',
        verified: true,
        rating: 4.5,
        reviews: 123,
        averageRating: 4.5,
        photos: 123,
        website: 'www.casadelapizza.com.ar',
        categories: ['Pizzeria', 'Restaurant', 'Birreria', 'Papa frita'],
    }],
    rating: 4.5,
    reviews: 123,
    averageRating: 4.5,
    socialLocationId: 1364,
    photos: 123,
    placeId:'ChIJzcrefk61vJURNjw-wgQPJQg'
}]


export const getPlaces = (page, order, filters) => {
    console.log('filters', filters)
    let profileStatusParams = ''
    if(filters.profileStatus){
        profileStatusParams = filters.profileStatus.map((status) => `profileStatus=${status}`).join('&')
    }
    let rateParam=''
    if(filters.rate){
        rateParam = filters.rate.map((rate) => `rate=${rate}`).join('&')
    }
    let searchParam=''
    if(filters.query){
        searchParam = `query=${filters.query}`
    }
    return axiosClient.get('/gbp/'+filters.companyId+`/byfilters?${profileStatusParams}&${rateParam}&${searchParam}`)
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         resolve({ data: { data: mockResponse }, status: 200 })
    //     }, 1000)
    // })
}

export const getPlacesByFlags = (page, order, filters, errorHandler) => {
    // let profileStatusParams = ''
    // if(filters.profileStatus){
    //     profileStatusParams = filters.profileStatus.map((status) => `profileStatus=${status}`).join('&')
    // }
    // let rateParam=''
    // if(filters.rate){
    //     rateParam = filters.rate.map((rate) => `rate=${rate}`).join('&')
    // }
    // let searchParam=''
    // if(filters.query){
    //     searchParam = `query=${filters.query}`
    // }
    return axiosClient.get('/gbp/'+filters.companyId, {params: filters, errorHandler: errorHandler})
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         resolve({ data: { data: mockResponse }, status: 200 })
    //     }, 1000)
    // })
}

export const analyzePlaces = (companyId,errorHandler) => {
    return axiosClient.post('/gbp/analyze/'+companyId, undefined,{errorHandler})
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         resolve({ data: { data: mockResponse }, status: 200 })
    //     }, 1000)
    // })
}

export const getPlaceImprovementsSuggestions = (companyId, socialLocationId) => {
    return axiosClient.get('/gbp/'+companyId+"/business-profile-improvements/"+socialLocationId)
}

export const exportPlaces = (page, order, filters) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(JSON.stringify(mockResponse))
        }, 1000)
    })
}

export const getSummary = (companyId) => {
    return axiosClient.get('/gbp/'+companyId+"/summary")
}