import React from "react";
import { translate } from 'react-i18next';
import { envConfig } from "../../../config/config";
import "./Logo.scss"

class Logo extends React.Component {
    render() {
        return (
            <div className="logo-container pb-5">
                <img id="login-logo" className={`mw-100 ${this.props.className ? this.props.className : ""}`} src={this.props.white ? envConfig.rayLogoWhiteUrl : envConfig.rayLogoUrl} alt="" />
            </div>
        );
    }
}

export default translate('common')(Logo);
