import { Filters, Table } from "botbit-ui-components";
import React, { useEffect, useState } from 'react';
import { createKeywordForCompany, deleteKeywordForCompany, getKeywordsForCompany } from '../../../api/feedbackApi';
import { translations } from "../../../config/translations/Feedback/KeywordsConfigContainer";
import translate from "../../../config/translations/translate";

import "./KeywordsConfigContainer.scss";
import withEventTracking from '../../../config/withEventTracking';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../commons/components/application/miscActions';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from "mdbreact";

const MySwal = withReactContent(Swal);

const KeywordCreateModal = ({ t, isOpen, toggle, createKeywordFn }) => {
	const [keyword, setKeyword] = useState(undefined);
	// no spaces, numbers or symbols
	const regex = /^[A-Za-zÀ-ÿ\u00f1\u00d1]+$/;
	const evaluation = regex.test(keyword)

	useEffect(() => {
		setKeyword(undefined);
	}, [isOpen]);

	return (
		<MDBModal className="keyword-create-modal" isOpen={isOpen} toggle={toggle}>
			<MDBModalHeader toggle={toggle}>
				<span className="keyword-create-modal_title">{t("table.actions.create.modal.title")}</span>
			</MDBModalHeader>
			<MDBModalBody>
				{t("table.actions.create.modal.text")}
				<MDBInput
					name="keword"
					id="keyword"
					value={keyword}
					label={t("table.actions.create.modal.label")}
					getValue={value => {
						setKeyword(value)
					}}
				/>
			</MDBModalBody>
			<MDBModalFooter>
				<MDBBtn color="secondary" onClick={toggle} size="sm">
					{t("table.actions.create.modal.cancel")}
				</MDBBtn>
				<MDBBtn color="primary" disabled={keyword === undefined || keyword === "" || !evaluation} onClick={() => createKeywordFn(keyword)} size="sm">
					{t("table.actions.create.modal.confirm")}
				</MDBBtn>
			</MDBModalFooter>
		</MDBModal>
	);
}

const KeywordsConfigContainer = ({ t, trackEvent }) => {
	const selectedCompany = useSelector(state => state.application.selectedCompany);
	const [isCreateKeywordModalOpen, setIsCreateKeywordModalOpen] = useState(false);
	const [keywords, setKeywords] = useState(undefined);
	const dispatch = useDispatch();

	useEffect(() => {
		getKeywords();
	}, []);

	const deleteKeyword = (keyword) => {
		MySwal.fire({
			type: "warning",
			text: t("table.actions.delete.confirmation"),
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: t("table.actions.delete.confirm"),
			confirmButtonColor: "#ff7043",
			cancelButtonText: t("table.actions.delete.cancel")
		}).then(res => {
			if (res.value) {
				deleteKeywordForCompany(selectedCompany.id, keyword).then((response) => {
					getKeywords()
					MySwal.fire({
						type: "success",
						text: t("table.actions.delete.success"),
						confirmButtonText: t("table.actions.delete.close"),
						confirmButtonColor: "#ff7043"
					})
				})
			}
		})
	}

	// this was replaced by a separate column, because the icon can be misclicked with row actions
	const getRowActions = (row, onchange) => {
		// let rowActions = [{
		// 	key: "keyword-actions-delete",
		// 	icon: "trash",
		// 	label: t("table.actions.delete.button"),
		// 	onClick: () => deleteKeyword(row.keyword)
		// }]
		// return rowActions;
		return []
	}

	const getColumns = () => {
		let columns =
			[{
				dataField: "keyword",
				title: t("table.header.keyword"),
				size: 80,
				formatter: (cellContent, row) => {
					return (
						<div className="keyword-cell">
							{row.keyword}
						</div>
					);
				}
			}, {
				dataField: "keyword",
				title: '',
				size: 20,
				formatter: (cellContent, row) => {
					return (
						<div className="keyword-delete">
							<MDBBtn 
								data-html2canvas-ignore 
								circle 
								flat tag="a" 
								color='none' 
								style={{ color: 'black' }}
								onClick={(evt) => deleteKeyword(row.keyword)}
							>
								<MDBIcon icon="trash" size='lg'/>
							</MDBBtn>
						</div>
					);
				}
			}];
		return columns;
	}

	const getKeywords = () => {
		setKeywords(undefined)
		dispatch(setLoading(true));
		getKeywordsForCompany(selectedCompany.id).then((rsp => {
			setKeywords(rsp.data.data)
		})).catch((error) => {
			console.log(error)
		}).finally(() => {
			dispatch(setLoading(false));
		});
	}

	const getKeywordsWrapper = () => {
		return Promise.resolve({ data: { data: keywords } })
	}

	const createKeyword = (keyword) => {
		createKeywordForCompany(selectedCompany.id, keyword).then((rsp) => {
			getKeywords()
			setIsCreateKeywordModalOpen(false)
		})
	}

	const getActions = () => {
		const actions = [
			{
				key: "create_keyword_action_" + 1,
				icon: "plus",
				label: t("table.actions.create.button"),
				primary: true,
				onClick: () => setIsCreateKeywordModalOpen(true)
			},
		]
		return actions;
	}

	return (
		<div className="keywords-container">
			<KeywordCreateModal
				isOpen={isCreateKeywordModalOpen}
				toggle={() => setIsCreateKeywordModalOpen(false)}
				createKeywordFn={createKeyword}
				t={t}
			/>
			{
				<div className="keywords-table">
					<Filters
						title={t("title")}
						showInfo
						actions={getActions()}
						actionsIntercomTarget="leaderboard_actions"
					/>
					{keywords && <Table
						actions={getActions()}
						getData={() => getKeywordsWrapper()}
						pagination={false}
						columns={getColumns()}
						dataPath="data.data"
						elementKey="id"
						className="classname-prueba"
						// onElementClick={element => console.log(element)}
						elementActions={(row, onchange) => getRowActions(row, onchange)}
					/>}
					<br />
					<br />
					<br />
					<br />
				</div>}
		</div>
	);
}

KeywordsConfigContainer.translations = { ...translations }
KeywordCreateModal.translations = { ...translations }

export default withEventTracking(translate(KeywordsConfigContainer));
