import React from 'react';
import Error from '../utils/Error';
import Iframe from 'react-iframe';
import { translate } from 'react-i18next';
import { MDBIcon } from 'mdbreact';
import { Modal, ModalBody } from 'botbit-ui-components';


export class MercadoPagoModal extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Modal className="mp-modal" isOpen={this.props.isOpen} fullscreen={this.props.fullscreen} toggle={this.props.toggle}>
          <ModalBody>
            <div className="mp-modal_header">
              <span>
                <MDBIcon
                  fas
                  size="2x"
                  icon="arrow-left"
                  onClick={this.props.toggle}
                  style={{ cursor: 'pointer', color: "#000" }}
                />
              </span>
              <h4>{this.props.t(this.props.product)}</h4>
            </div>
            {(!this.props.href) && <Error error='generic' />}
            {(this.props.href) &&
              <Iframe position="relative"
                url={this.props.href}
                className="col-12"
              />}
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}


export default (translate('common')(MercadoPagoModal));