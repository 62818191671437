import React from 'react';
import { Modal, ModalHeader } from "botbit-ui-components";
import AdminInvite from './AdminInvite';
import { translations } from "../../config/translations/Admins/AdminInvite";
import translate from "../../config/translations/translate";

const AdminInviteModal = ({ t, isOpen, toggle, triggerOpenModal }) => {

    return (
        <Modal className="admin-invite-modal" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <span className="admin-invite-modal_title">{t("title")}</span>
            </ModalHeader>
            <AdminInvite embedded toggle={toggle} triggerOpenModal={triggerOpenModal} />
        </Modal>
    );
}

AdminInviteModal.translations = { ...translations }

export default translate(AdminInviteModal);