import React from 'react';
import {Col, Row} from "react-bootstrap";
import classNames from 'classnames';
import './Wizard.css'
import Button from '../Button';

export const WizardNavigationButtons = (props) => {
  return (
    <div className="row align-content-sm-end">
      <div className="col-6">
        {props.backHandler ?
          <Button
            block
            disabled={false}
            type="button"
            className="btn btn-primary block m-b"
            onClick={props.backHandler}
          >
            Anterior
          </Button>:''
        }
      </div>
      <div className="col-6">
        <Button
          block
          disabled={false}
          type="submit"
          className="btn btn-primary block m-b"
        >
          Siguiente
        </Button>
      </div>
    </div>
  )
}
class WizardForm extends React.Component {
  render = () => {
    const {steps, nameCurrentStep} = this.props;
    if(!steps || steps.length===0) {
      return null;
    }

    let current = steps.find(step => step.name === nameCurrentStep);
    if(current === undefined){
      current = steps[0];
    }
    const CurrentComponent = current.component;

    return <React.Fragment>
      <Row className="d-md-none px-3 pt-3 pb-3">
        <Col className="col-12">
          <ul className="horizontal">
            {steps.map((step, stepIndex) =><li key={step.name +'-h'}
                                               className={classNames('horizontal', {active: current.name===step.name})}
                                               onClick={()=>this.props.goToHandler(stepIndex)}/>)}
          </ul>
        </Col>
      </Row>
      <Row className="pl-3 pt-3">
        <Col className="col-md-4 d-none d-md-block white-bg pt-5">
            <ul className="vertical">
              {steps.map((step, stepIndex) => <li key={step.name+'-v'}
                                              className={classNames('vertical', {active: current.name===step.name})}
                                              onClick={()=>this.props.goToHandler(stepIndex)}>
                {step.title}
              </li>)}
            </ul>
        </Col>
        <Col className="col-md-8 col-12">
          <CurrentComponent {...current.data} nextHandler={this.props.nextHandler} backHandler={this.props.backHandler} key={current.name+"-step"}/>
        </Col>
      </Row>
    </React.Fragment>;
  }
}

export default WizardForm;