import React from 'react'
import Number from '../../utils/FormatedNumber';
import { MDBCard, MDBCardBody } from 'mdbreact';

const UserDetails = ({ t, value }) => (

	<div className='text-right mr-2'>
		<MDBCard className='m-2'>
			<MDBCardBody>
				<small>	{t("estimatedValue")}</small>
				<h2>$ <Number number={value} /></h2>
			</MDBCardBody>
		</MDBCard>
	</div>
);

export default (UserDetails)
