import axiosClient from './axiosClient'


export const getLocationsEnabledToPost = (companyId) => {
    return axiosClient.get(`/sociallocationpost/companies/${companyId}/socialLocations/enabled`)
}

export const createSocialLocationPost = (socialAccountWithLocations) => {
    
    // uncomment this to test loading
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         alert(JSON.stringify(socialAccountWithLocations))
    //         resolve({ data: { data: socialAccountWithLocations }, status: 200 })
    //     }, 5000)
    // })
    return axiosClient.post(`/sociallocationpost`,  socialAccountWithLocations)
}