import { CampaignListFiltersConstants } from './CampaignListFiltersConstants';

export const setCampaignListFilters = (filters) => {    
    return {
        type: CampaignListFiltersConstants.SET_CAMPAIGNS_LIST_FILTERS,
        payload: {
            filters: filters
        }
    }
}

export const setCampaignListSavedFilters = (savedFilters) => {
    return {
        type: CampaignListFiltersConstants.SET_CAMPAIGNS_LIST_SAVED_FILTERS,
        payload: {
            savedFilters: savedFilters
        }
    }
}

export const setCampaignListPreviousFilters = (previousFilters, previousView, previousOrder) => {
    return {
        type: CampaignListFiltersConstants.SET_CAMPAIGNS_LIST_PREVIOUS_FILTERS,
        payload: {
            previousFilters: previousFilters,
            previousView: previousView,
            previousOrder: previousOrder
        }
    }
}
