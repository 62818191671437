export const translations = {
    noVisits: {
        es: "Sin visitas en el último año",
        en: "No visits last year"
    },
    visitsHistory: {
        es: "Distribución histórica de visitas",
        en: ""
    },
    visitsLastYear: {
        es: "Visitas del último año",
        en: ""
    },
    store: {
        es: "Tienda",
        en: "Store"
    },
    visits: {
        es: "Visitas",
        en: "Visits"
    },
    date: {
        es: "Fecha",
        en: "Date"
    }
}
