import React from 'react';
import Button from '../../commons/components/utils/form/Button';
import { translate } from 'react-i18next';
import { Col, Container, Row } from "react-bootstrap";
import GenderSelector from '../../commons/components/utils/form/GenderSelector';
import { MDBCountrySelector } from '../../commons/components/utils/form/CountrySelector';
import moment from "moment";
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { MDBSelect, MDBInput } from "mdbreact";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';


class UserForm extends React.Component {

	componentDidMount = () => {
	}

	componentDidUpdate(prevProps) {
	}

	dateToString(date) {
		if (moment(date).isValid()) {
			const string = moment(date).format("YYYY-MM-DD", true)
			return string;
		}
	}

	stringToDate(string) {
		if (moment(string, "YYYY-MM-DD", true).isValid()) {
			return moment(string, "YYYY-MM-DD", true).toDate();
		}
	}

	// validateStringDate(string) {
	// 	const dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
	// 	const dateIsValid = dateRegex.test(string);
	// 	return dateIsValid
	// }

	render() {
		const { t } = this.props;
		const userSchema = Yup.object().shape({
			name: Yup.string().required('El nombre es requerido.'),
			lastName: Yup.string().required('El apellido es requerido.'),
			email: Yup.string().email('El email es invalido.').required('El email es requerido.'),
			phone: Yup.mixed().test("test-phone", "Teléfono inválido",
				(value) => {
					if (value === undefined || value === null || value === "") return true;
					if (!value.startsWith('+'))
						value = '+' + value;
					const phoneNumber = parsePhoneNumberFromString(value);
					return phoneNumber !== undefined && phoneNumber.isValid();
				}),
		})

		let { id, name, lastName, email, phone, gender, country = "AR", identityNumber, birthDate } = this.props.user
		return (
			<Formik
				initialValues={
					{
						store: this.props.store ? this.props.store.id : undefined,
						user: {
							id: id,
							country: country,
							name: name,
							lastName: lastName,
							email: email,
							phone: phone,
							gender: gender,
							identityNumber: identityNumber,
							birthDate: birthDate
						}
					}
				}

				onSubmit={values => this.props.onSubmit(values)}
				validationSchema={Yup.object().shape({
					store: this.props.storeSelectOptions ? Yup.string().required('La tienda es requerida') : undefined,
					user: userSchema

				})}
			>
				{({ errors, touched, setFieldError, values }) => (
					<Form>
						<Container>
							{
								this.props.storeSelectOptions && !this.props.store && (
									<Row>
										<Col lg={12}>
											<Field
												name="store"
												render={(props) => {
													return (
														<>
															<MDBSelect
																className="botbit-form-input"
																options={this.props.storeSelectOptions}
																selected="Tienda"
																getValue={(values) => { if (props.field.value !== values[0]) props.form.setFieldValue('store', values[0]) }}
																search={true}
															/>
															<Error submitCount={props.form.submitCount} message={errors.store} />
														</>
													);
												}}
											/>
										</Col>
									</Row>
								)
							}
							<Row>
								<Col lg={6}>

									<Field
										name="user.name"
										render={(props) => {
											return (
												<>
													<MDBInput
														label={t('panels.clients.name')}
														type="text"
														value={props.field.value}
														getValue={(value = "") => { props.form.setFieldValue('user.name', value) }}
													/>
													<Error submitCount={props.form.submitCount} message={errors.user ? errors.user.name : undefined} />

												</>
											);
										}}
									/>
								</Col>
								<Col lg={6}>
									<Field
										name="user.lastName"
										render={(props) => {
											return (
												<>
													<MDBInput
														label={t('panels.clients.lastname')}
														type="text"
														value={props.field.value}
														getValue={(value = "") => { props.form.setFieldValue('user.lastName', value) }}
													/>
													<Error submitCount={props.form.submitCount} message={errors.user ? errors.user.lastName : undefined} />
												</>
											);
										}}
									/>
								</Col>

							</Row>
							<Row>
								<Col lg={6}>
									<Field
										name="user.email"
										render={(props) => {
											return (
												<>
													<MDBInput
														label={t('panels.clients.email')}
														type="text"
														value={props.field.value}
														getValue={(value = "") => { props.form.setFieldValue('user.email', value) }}
													/>
													<Error submitCount={props.form.submitCount} message={errors.user ? errors.user.email : undefined} />
												</>
											);
										}}
										disabled={this.props.user && this.props.user.email}
									/>
								</Col>
								<Col lg={6}>
									<Field
										name="user.phone"
										type="text"
										render={(props) => {
											return (
												<>
													<IntlTelInput
														label={t('panels.clients.phone')}
														defaultValue={props.field.value ? (props.field.value) : ''}
														defaultCountry={(values.country)
															? values.country.toLowerCase()
															: this.props.store
																? this.props.store.country.toLowerCase()
																: ""}
														inputClassName="form-control"
														containerClassName="intl-tel-input md-form"
														onPhoneNumberChange={
															(_valid, raw, _a, formatted) => {
																props.form.setFieldValue('user.phone', formatted);
															}
														}
														onPhoneNumberBlur={(_valid, raw, _c, formatted) => {
															props.form.setFieldValue("user.phone", formatted);
															// console.log({ formatted, raw, store: this.props.store, country: values.country })
														}}
														allowDropdown
													/>
													<Error className="mt-3" submitCount={props.form.submitCount} message={errors.user ? errors.user.phone : undefined} />
													<span className="d-block">Debes indicar el número con el código del país y el código de área</span>
												</>
											);
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg={6}>
									<Field
										type="text"
										name="user.gender"
										render={(props) => {
											return (
												<GenderSelector
													label={t('panels.clients.gender')}
													selected={props.field.value}
													onChange={(value) => { props.form.setFieldValue('user.gender', value) }}
												/>
											)
										}}
									/>
								</Col>
								<Col lg={6}>
									<Field
										type="text"
										name="user.country"
										render={(props) => {
											return (
												<>
													<MDBCountrySelector
														value={props.field.value}
														getValue={
															(value) => {
																props.form.setFieldValue('user.country', value);
															}
														}
														label={t('panels.clients.country')}
													/>
													<Error submitCount={props.form.submitCount} message={errors.user ? errors.user.country : undefined} />
												</>
											)
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg={6}>
									<Field
										type="number"
										name="user.identityNumber">
										{
											(props) => {
												return (
													<MDBInput
														label='DNI/P/LC'
														id="identityNumber"
														value={props.field.value ? props.field.value : ''}
														getValue={
															(value) => {
																if (value.length && !/^\d+$/.test(value)) return;
																props.form.setFieldValue('user.identityNumber', value)
															}
														}
													/>
												)
											}
										}
									</Field>
								</Col>
								<Col lg={6}>
									<Field
										type="text"
										name="user.birthDate"
										validate
										render={(props) => {
											return (
												<>
													{/* <MDBDatePicker
														id="datetime-local"
														className="botbit-mdb-datepicker form-control botbit-form-control p-0 border-0"
														label={t('panels.clients.birthDate')}
														type="date-local"
														color="primary"
														value={props.field.value ? props.field.value : undefined}
														getValue={(value) => { props.form.setFieldValue('user.birthDate', value) }}
														maxDate={new Date()}
														maxDateMessage=" "
													/> */}
													<MDBInput
														label={t("panels.clients.birthDate")}
														color="primary"
														type="date"
														value={props.field.value ? this.dateToString(props.field.value) : undefined}
														getValue={(value) => {
															const dateIsValid = moment(value).isValid();
															if (dateIsValid) {
																const valueToDate = this.stringToDate(value);
																if (value !== this.dateToString(props.field.value)) props.form.setFieldValue("user.birthDate", valueToDate);
															} else {
																setFieldError("user.birthDate", 'El formato de fecha debe ser "dd/mm/aaaa"')
															}
														}}
													/>
													<Error submitCount={props.form.submitCount} message={errors.user ? errors.user.birthDate : undefined} />
												</>
											);
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<Button
										block
										type="submit"
										className="btn btn-primary block full-width m-t"
									>
										{this.props.buttonText ? this.props.buttonText : t('panels.clients.save')}
									</Button>
								</Col>
							</Row>
						</Container>
					</Form>
				)
				}
			</Formik>
		)
	}
}

const Error = ({ message, submitCount, className }) => {
	if (submitCount === 0) return null;
	return (
		<span className={`text-danger position-relative ${className || ""}`} style={{ top: '-1em' }}>{message}</span>
	)
}

export default (translate('common')(UserForm))
