import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { googleOptInCallback } from '../../api/socialAccountsApi';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../commons/components/application/miscActions';

const GoogleOptInCallback = ({history}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        callback(window.location.search)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const callback = async (params) => {
        dispatch(setLoading(true))
        const state = new URLSearchParams(params).get('state').split('_')
        const companyId = parseInt(state[1])
        await googleOptInCallback(params)
        dispatch(setLoading(false))
        history.push(`/socialaccounts?companyId=${companyId}`)
    }

    return (<></>)
}

export default withRouter(GoogleOptInCallback)
