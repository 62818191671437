import React from 'react';
import {Col, Row} from "react-bootstrap";
import IBox from '../../commons/components/inspinia/IBox';
import SmallWidget from '../../commons/components/inspinia/SmallWidget';
import Number from '../../commons/components/utils/FormatedNumber';
import classNames from 'classnames';
import {EMAIL_CLICKED_COUNT, EMAIL_DELIVERED_COUNT, EMAIL_OPEN_COUNT} from "./constans";
import { translate } from 'react-i18next';

const EmailTotals = ({base, totals, t}) => {
  return (
  <Row>
    <Col lg={12}>
      <IBox title={t("panels.emailActivity.summaryTittle")}>
        <Row>
          <Col lg={4}>
            <ItemTotal base={base.total} item={base} t={t}/>
          </Col>
          {totals.map((item) => (
            <Col lg={4} key={item.serviceKey}>
              <ItemTotal base={base.total} item={item} t={t}/>
            </Col>
            ))
          }
        </Row>
      </IBox>
    </Col>
  </Row >
)
};
const serviceKeyConfiguration = {
  [EMAIL_DELIVERED_COUNT]: {
    iconName: 'envelope',
    title: 'mail.delivered',
    color: 'email-delivered-color'
  },
  [EMAIL_OPEN_COUNT]:  {
    iconName:'envelope-open',
    title: 'mail.opened',
    color: 'email-open-count'
  },
  [EMAIL_CLICKED_COUNT]: {
    iconName: 'mouse-pointer',
    title: 'mail.clicked',
    color: 'email-clicked-count'
  }
};

const ItemTotal =({base, item, t}) => {
   const itemConfig = serviceKeyConfiguration[item.serviceKey];;

   if(!itemConfig) {
      return null;
    }

    const color = itemConfig.color?itemConfig.color:'';
    return (
      <SmallWidget title={<span>
                              {t(itemConfig.title)}
                              {base !== 0 && base !== item.total && <b> ({((item.total / base) * 100).toFixed(2)}%)</b>}
                           </span>}
                   mainText={<Number number={item.total} id="number"/>}
                   icon={itemConfig.iconName}
                   className={classNames('email-widget text-white', color)}
      />
    );
};

export default translate('common')(EmailTotals)