import React from 'react';
import { getTranslationFunction } from "../../../../config/translations/translate";
import { translations } from "../../../../config/translations/integrations/docs/GmailDoc";

const t = getTranslationFunction({ ...translations })

const GmailDoc = ({ url }) => {
  return (
    <>
      <p>{t("p1")}</p>

      <p><b style={{ fontWeight: "bold" }}>{t("p2")}</b></p>
      
      <p>{t("p4")}</p>

      <p>{t("p5.neutral") + " "}<b style={{ fontWeight: "bold" }}>{t("p5.strong")}</b></p>

      <p>{t("p6")}</p>

      <p>{t("p7.text") + " "}<a href={url} target="_blank" rel="noopener noreferrer">{t("p7.link")}</a></p>
    </>
  );
}

export default GmailDoc;