import React from "react";
import InputRange from 'react-input-range';
import "./InputSliderField.css";

export class InputSliderField extends React.Component {

  render =() => {
    const {input, maxValue, minValue, formatLabel, input: {value}, label} = this.props;
    /*if(!value || value===''){
      input.onChange(minValue)
    }*/
    return (
        <div className="form-group">
              <div className="row">
                <div className="col">
                <label>{label}</label>
                </div>
              </div>
              <div className="row mt-3 mb-4">
                <div className="col">
                <InputRange
                  {...input}
                  maxValue={maxValue}
                  minValue={minValue}
                  value={!value || value===''?minValue:input.value}
                  formatLabel={formatLabel}
                  onChange={value => input.onChange(value)}
                  />
                </div>
              </div>
        </div>
    )

  }
}
export default InputSliderField;