import React, { useEffect, useState } from 'react';
import Loading from '../../commons/components/utils/Loading';
import { getEntries, getHeader } from '../../api/reviewHistoryApi';
import './UserReviewHistory.scss';
import { MDBCard, MDBIcon } from 'mdbreact';
import { ChatFeed, ChatBubble } from 'react-chat-ui';
import DateTime from '../../commons/components/time/DateTime';
import Score from '../feedback/Score';
import { withRouter } from 'react-router-dom';
import translate, { getTranslationFunction } from '../../config/translations/translate';
import { translations } from '../../config/translations/Dashboard/UserReviewHistory';
import { NoResultsImage } from './NoResultsImage';
import { SocialSource } from '../../commons/enums/SocialSource';

const MyChatBubble = ({ message, bubbleStyles }) => {
  const t = getTranslationFunction({ ...translations });
  let header;
  let finalBubbleStyles = { ...bubbleStyles };
  if (message.float === 'right') {
    header = (
      <div style={{ margin: '0 0 5px auto' }} className="flex-column">
        <label style={{ margin: '0 0 5px auto', fontWeight: "400" }}>
          <img alt="" src={message.profileUrl} style={{ height: '1.5em', borderRadius: '100%' }} />
          {" " + message.name + t("rated")}{message.score && <Score size="s" value={message.score}></Score>}
          {t("in")}<img alt="" src={message.socialSourceIcon} style={{ height: '2em', borderRadius: '100%' }} />
          {t("at")}<DateTime timestamp={message.timeStamp} fontSize='12px'></DateTime>
        </label>
      </div>
    )
    finalBubbleStyles.chatbubble = ({
      ...bubbleStyles.chatbubble,
      borderRadius: '10px 10px 0 10px',
      background: '#bff5ea',
      float: 'right'
    })
    finalBubbleStyles.text = ({
      ...bubbleStyles.text,
    })
  }
  else {
    header = (
      <div style={{ margin: '0 auto 5px 0' }} className="flex-column">
        <label style={{ margin: '0 auto 5px 0', fontWeight: "400" }}>
          <img alt="" src={message.profileUrl} style={{ height: '1.5em', borderRadius: '100%' }} />
          {" " + message.name + t("answered")}<DateTime timestamp={message.timeStamp} fontSize='12px'></DateTime>
        </label>
      </div>
    )
    finalBubbleStyles.chatbubble = ({
      ...bubbleStyles.chatbubble,
      float: 'left'
    })
    finalBubbleStyles.text = { ...bubbleStyles.text }
  }
  return (
    <div className="message-container" style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
      {header}
      {message.message && <ChatBubble message={message} bubbleStyles={finalBubbleStyles}></ChatBubble>}
    </div>
  )
}

const axiosErrorHandler = (props) => (error) => {
  return { ...error, ignore: true };
};

const UserReviewHistory = (props) => {
  const { fullId, className, t, isMobile, toggle } = props;

  const [header, setHeader] = useState(null);
  const [messages, setMessages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [emptyState, setEmptyState] = useState(false);


  useEffect(() => {
    const _getHeader = async () => {
      try {
        const header = await getHeader(fullId, axiosErrorHandler(props));
        setHeader(header.data.data);
      } catch (error) {
        setEmptyState(true);
      }

    }
    const _getEntries = async () => {
      try {
        const entries = await getEntries(fullId, axiosErrorHandler(props));
        let messages = [];
        entries.data.data.items.forEach((e, idx) => {
          const getSourceIcon = id => {
            let source;
            for (let key in SocialSource) {
              if (SocialSource[key] === id) {
                source = key.toLowerCase();
              }
            }
            return `/static/img/social_logos/${source}_logo.png`
          }
          messages.push(
            {
              message: e.text || t("noComment"),
              id: e.changeType === 'REVIEWER_EDITION' ? 0 : 1,
              score: e.changeType === 'REVIEWER_EDITION' ? e.score : null,
              profileUrl: e.interlocutorProfilePhotoUrl,
              name: e.interlocutorFullName,
              float: e.changeType === 'REVIEWER_EDITION' ? 'right' : 'left',
              socialSourceIcon: getSourceIcon(e.socialSourceId),
              timeStamp: e.timestamp
            }
          );
        });
        setMessages(messages);
      } catch (error) {
        setEmptyState(true);
      }
    }
    _getHeader();
    _getEntries();
  }, []);

  useEffect(() => {
    if ((!header || !messages) && !emptyState) return;
    setLoading(false);
  }, [header, messages, emptyState]);

  const mobileHeight = window.innerHeight - 170;

  return (
    <>
      {loading && <Loading loading={loading}></Loading>}
      {!loading &&
        <div id='user-review-history' className={`${className || ''}`}>
          {!emptyState &&
            <MDBCard>
              <div className="user-review-history_header d-flex flex-row justify-content-between align-items-center">
                <div id='user-details'>
                  <div className="text-container">
                    <div className="mx-1 premium-tag">
                      <div className="premium-tag-container">
                        <MDBIcon fas icon="crown" hint="Esta es una caracteristica premiun" />
                        <span className="premium-tag-text"> {t("newFeature")}</span>
                      </div>
                    </div>
                    <span className="first-row">
                      <label>{t("reviewHistory")}<b>{header.reviewerDisplayName}</b>{t("with")}<b>{header.storeName}</b></label>
                    </span>
                    <span className="second-row">
                      <label>{t("state")} <span style={{ fontSize: "1rem" }}>{header.currentMood === 'PROMOTER' ? '😁' : '😡'}</span></label>
                    </span>
                  </div>
                </div>
                {toggle &&
                  <div style={{ alignSelf: "self-start" }} className="toggle-tag mx-1">
                    <MDBIcon fas icon="times" onClick={toggle} />
                  </div>
                }
              </div>
              <div id='reviews'>
                <ChatFeed
                  messages={messages}
                  showSenderName
                  bubblesCentered={false}
                  maxHeight={isMobile ? mobileHeight : 400}
                  chatBubble={MyChatBubble}
                  bubbleStyles={
                    {
                      text: {
                        fontSize: isMobile ? 14 : 16,
                        color: '#000'
                      },
                      chatbubble: {
                        borderRadius: '0 10px 10px 10px',
                        padding: 10,
                        maxWidth: isMobile ? '90%' : '75%',
                        background: '#f1f1f1',
                        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.28)',
                        margin: '5px'
                        // border: 'solid'
                      }
                    }
                  }
                />
              </div>
            </MDBCard>
          }
          {emptyState &&
            <div className="empty-state">
              <div className="noResultsImage">
                <NoResultsImage />
              </div>
              {t("emptyState")}
            </div>
          }
        </div>
      }
    </>
  )
}

UserReviewHistory.translations = {
  ...translations,
};

export default withRouter(translate(UserReviewHistory));