import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft as collapseIcon } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown as expandIcon } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames';
import { translate } from 'react-i18next';
import { toggleNavigationBar } from '../../../commons/components/application/applicationActions';
import { connect } from 'react-redux';


class NavigationItem extends React.Component {

	// the admin must have permissions on at least one child
	areChildrenAllowed = (operations, children) => {
		let hasPermission = false;
		for (const subItem of children) {
			hasPermission = hasPermission || !subItem.operations || this.hasOperationRequired(operations, subItem.operations);  //(operations.indexOf(subItem.operations) < 0)
		}
		return hasPermission;
	};

	hasOperationRequired = (adminOperations, itemOperations) => {
		return itemOperations === "ALL" || adminOperations.indexOf(itemOperations) > -1;
	};

	hasAccessLevelRequired = (adminObjectAccess, itemAccess) => {
		return itemAccess === null ||
			itemAccess === "SELF" ||
			(itemAccess === "COMPANY" && (adminObjectAccess === "COMPANY" || adminObjectAccess === "ALL")) ||
			(itemAccess === "ALL" && adminObjectAccess === "ALL");
	};

	isRenderAllowed = (item, admin, selectedCompany) => {

		// condiciones sobre la company elegida, aborto el render si no se cumplen
		if (item.showOnlyIfHasSubscriptionStores && !selectedCompany.hasSubscriptionStores) {
			return false;
		}

		// // condiciones sobre roles de usuario, al menos uno tiene que cumplirse
		// si es elemento raiz se muestra si alguno de sus hijos matchea con los permisos
		if (item.children != null && !this.areChildrenAllowed(admin.operations, item.children)) {
			return false;
		}
		// si es elemento hoja tiene que tener las operaciones que requiere el item
		if (item.children == null && item.operations != null && !this.hasOperationRequired(admin.operations, item.operations)) {
			return false;
		}
		// si es elemento hoja, tiene que tener nivel de acceso mayor o igual al que requiere el item
		if (item.children == null && item.access != null && !this.hasAccessLevelRequired(admin.objectAccess, item.access)) {
			return false;
		}

		return true;
	};

	addCompanyAndTokenToURL = (url, selectedCompany, token) => {
		let newUrl = url;
		// urls hacia php, agrego company seleccionada y sso
		if (newUrl != null && newUrl.indexOf('http') > -1) {
			if (newUrl.indexOf("?") > 0)
				newUrl = newUrl + `&companyId=${selectedCompany.id}&sso=${token}`;
			else
				newUrl = newUrl + `?companyId=${selectedCompany.id}&sso=${token}`;
		}
		return newUrl;
	};

	handleClick(item, isChild){
		(item.noChildrenMenu || isChild) && window.innerWidth < 991 && this.props.toggleNavigationBar()
	}

	render() {
		const { navigationMenuExpanded: menuExpanded, admin, selectedCompany, item, token, clickFn, t } = this.props;

		if (!this.isRenderAllowed(item, admin, selectedCompany)) {
			return null;
		}
		return !item.notVisible && (
			<li className={classNames('', { active: item.active })} data-intercom-target={item.key} onClick={() => this.handleClick(item)}>
				{/* Si tiene URL meto funcion de goto, sino funcion de expand/collapse */}
				<a 
					data-botbit-security={ "menu-" + item.key } 
					href={this.addCompanyAndTokenToURL(item.url, selectedCompany, this.props.token)}
					onClick={(event) => { clickFn(item, event, item.target) }}
					id={item.id} 
					target={item.target}
					rel={item.norel}
				>
					{/* Icono de la izquierda, solo para opciones de primer nivel */}
					{(item.icon) && (
						<React.Fragment>
							<FontAwesomeIcon icon={item.icon} />
							{(menuExpanded) && (<React.Fragment>&nbsp; &nbsp;</React.Fragment>)}
						</React.Fragment>
					)}
					{/*It can not be done with classNames because the template overwrites the display*/}
					<span className="nav-label" style={item.children ? {} : menuExpanded ? { display: "inline" } : { display: "block" }}>
						{t("menu." + item.text + ".text")}
					</span>

					{/* Si tiene hijos me determina el icono a mostrar a la derecha, siempre y cuando el menu exte expandido*/}
					{item.children && menuExpanded && !item.noChildrenMenu && <FontAwesomeIcon icon={item.active ? expandIcon : collapseIcon} className="float-right nav-item-right-arrow" />}

					{(item.isNew) && (<span className="label label-info float-right">NEW</span>)}
				</a>
				{/* Renderizo los hijos */}
				{!item.noChildrenMenu && item.children && item.active && (
					<ul className="nav nav-second-level collapse in" onClick={() => this.handleClick(item, true)}>
						{item.children.map(function (object, index) {
							if (object.notVisible) {
								return null;
							}
							return (<NavigationItem
								key={object.key}
								item={object}
								token={token}
								clickFn={clickFn}
								admin={admin}
								selectedCompany={selectedCompany}
								t={t}
								navigationMenuExpanded={menuExpanded} />)
						})
						}
					</ul>
				)}
			</li>
		)
	}
}

function mapStateToProps(state) {
	// return {
	// 	navigationMenuExpanded: state.application.navigationMenuExpanded
	// }
	return {}
}

export default connect(mapStateToProps, { toggleNavigationBar })(translate('common')(NavigationItem));