import axiosClient from './axiosClient';
import axiosRoot from "axios";

export const unignoreUser = (userId, storeId, allowAutomaticIgnore) => {
	return axiosClient.post("/users/unignore", { userId, storeId, allowAutomaticIgnore });
}

export const ignoreUser = (userId, storeId, ignoredBy) => {
	return axiosClient.post("/users/ignore", { userId, storeId, ignoredBy });
}

const basicUser = (user) => {
	return {
		...user,
		storeRegistrations: null,
	}
}

export const saveUser = (user) => {
	return axiosClient.post("/users", basicUser(user));
}

export const deleteUser = (userId) => {
	return axiosClient.delete("/users?userId=" + userId);
}

export const importUsers = (storeId, username, tags, acceptedFiles) => {

	var bodyFormData = new FormData();
	bodyFormData.set('storeId', storeId);
	bodyFormData.set('loggedUser', username);
	bodyFormData.set('tags', tags);
	for (var f in acceptedFiles) {
		bodyFormData.set(`file[${f}]`, acceptedFiles[f]);
	}
	return axiosRoot.post(`/users/import`, bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data;',
			'Accept': 'application/json;'
		}
	});
}

export const sendCustomCampaignToUser = (userId, campaignId) => {
	return axiosClient.post(`/campaigns/${campaignId}/send/users/${userId}`);
}

export const createUser = (user, store, externalParams = {}, config = {}) => {
	let body = {
		"registeredThrough": "CRM",
		"source": "BOTBIT",
		"storeId": store,
		"user": user,
		...externalParams
	}
	return axiosClient.post("/users/register", body, config);

}

export const exportUsers = (storeId, segmentId) => {
	var segmentParam = segmentId ? `/segments/${segmentId}` : '';
	var url = `/stores/${storeId}${segmentParam}/csv`;
	return axiosClient.get(url, {
		timeout: 5000
	});
}


export const updateUserStatus = (userId, storeId, userStatus) => {
	return axiosClient.post(`/users/${userId}/stores/${storeId}`, userStatus);
}

export const getUserStatus = (userId, storeId) => {
	return axiosClient.get(`/users/${userId}/stores/${storeId}`);
}

export const getUserTags = (userId, storeId) => {
	return axiosClient.get(`/users/${userId}/stores/${storeId}/user-tags`);
}

export const addTag = (tag) => {
	return axiosClient.post(`/users/user-tags`, tag);
}

export const deleteTag = (tag) => {
	return axiosClient.delete(`/users/user-tags`, { data: tag });
}

export const unregisterUser = (userId, storeId, registeredThrough = 'CRM') => {
	return axiosClient.post(
		'users/unregister',
		{
			userId: userId,
			storeId: storeId,
			registeredThrough: registeredThrough,
			source: 'BOTBIT'
		}
	);
}

export const findStoreUserByPhone=(storeId, query='')=>{
	return axiosClient.get(`/users/findByPhoneNameOrLastName?query=${query.replace('+','')}&storeId=${storeId}`);
}