import React from 'react';

export const GradientHelper = ({ leftText, rightText }) => {
	return <div className='gradient'>
		<div className="bar">
			<div className="fixed-box background-zero-to-ten-0"></div>
			<div className="fixed-box background-zero-to-ten-3"></div>
			<div className="fixed-box background-zero-to-ten-6"></div>
			<div className="fixed-box background-zero-to-ten-8"></div>
			<div className="fixed-box background-zero-to-ten-10"></div>
		</div>
		<div className="labels">
			<div className="bottom-label left-label">{leftText}</div>
			<div className="bottom-label right-label">{rightText}</div>
		</div>
	</div>
}