import React, { useEffect, useState } from 'react';
import { MDBCard, MDBBtn } from "mdbreact";
import { Image } from "botbit-ui-components";
import "./UpgradePlan.scss"
import { envConfig } from '../../config/config';
import Feature from './Feature';
import useWindowDimensions from '../../commons/utils/useWindowDimensions';
import { NewLoader } from '../NewLoader/NewLoader';
import { getPlanInfo } from '../../api/upgradeApi';
import translate from '../../config/translations/translate';
import { useSelector } from "react-redux";
import { translations } from '../../config/translations/Upgrade/Upgrade';
import { sendGAEvent } from '../../commons/components/utils/trackers';

const UpgradePlan = ({ t, product, embed }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [featureList, setFeatureList] = useState([]);
    const [highlightedFeatures, setHighlightedFeatures] = useState([])
    const [planName, setPlanName] = useState("")
    const [currentPlanName, setCurrentPlanName] = useState("")
    const [price, setPrice] = useState({})
    const [parent, setParent] = useState({})
    const { isMedium } = useWindowDimensions();

    const selectedCompany = useSelector(state => state.application.selectedCompany)

    useEffect(() => {
        if (product) {
            getPlanInfo(product, selectedCompany.id).then(res => {
                const response = res.data.data;
                const interestedFeature = response.interestedFeature;
                const features = response.features.filter(feature => feature.id !== interestedFeature.id);
                const orderedFeatures = orderFeatures(features)
                const fullFeatures = [interestedFeature, ...orderedFeatures];
                setFeatureList(fullFeatures);
                setHighlightedFeatures(fullFeatures.filter(feature => feature.highlight));
                setPlanName(response.name)
                setCurrentPlanName(response.currentPlanName)
                setPrice({ price: response.monthlyPricePerSucursal, currency: response.priceCurrency })
                setParent(response.parent)
                setIsLoading(false)
            })
        }
    }, [product])

    const orderFeatures = (features) => {
        const featuresSorted = features.sort((a, b) => {
            const fa = a.implemented, fb = b.implemented;
            if (fa > fb) return -1;
            else return 1
        })

        return featuresSorted
    }

    return (
        <>
            <div className={`upgrade-plan w-100${embed ? "" : " pb-5"}`}>
                {!isLoading ?
                    <div className="upgrade-plan_container">
                        <div className="upgrade-plan_content">
                            <div className="upgrade-plan_header w-100 my-2">
                                <h2 className="upgrade-plan_feature-title mt-0">{t(`operations.${featureList[0].id}.name`)}</h2>
                                <p className="upgrade-plan_feature-description mb-0">{t(`operations.${featureList[0].id}.description`)}</p>
                                {/* <span className="d-table plan-badge mx-auto mt-4"><b>{t("headerMessage")}</b></span> */}
                            </div>
                            {isMedium && <MDBBtn
                                rounded
                                className="upgrade-btn my-2"
                                color="deep-orange"
                                onClick={(e) => {
                                    sendGAEvent("purchase_intention", "upgrade_clicked", featureList[0].id, 1, null, null);
                                    window.Intercom("showNewMessage", t("upgradeMessage", { planName: t(`plans.${planName}.name`) }));
                                    var metadata = {
                                        feature: featureList[0].id
                                    }
                                    window.Intercom('trackEvent', 'purchase_intention_' + planName, metadata);

                                }}
                            >{t("requestUpgrade")}</MDBBtn>}
                            <div className="upgrade-plan_presentation-card my-2">
                                <MDBCard>
                                    <div className="upgrade-plan_presentation-card_container">
                                        <div className="upgrade-plan_presentation-card_header">
                                            <Image className="image" src={envConfig.staticImgUrl + (planName ? t(`plans.${planName}.image`) : "Botbit_Icono-AddOn_V1.png")} />
                                            <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center w-100">
                                                <div className="header-description">
                                                    <h3 className="plan-title ml-1">{t(`plans.${planName}.name`)}</h3>
                                                    <span className="plan-badge">
                                                        {price.price ? (<><b>{price.currency} {price.price}*</b> {t("monthsByStore")}</>) : t("consultPrice")}
                                                    </span>
                                                    {price.price && <span className="d-block mt-1 ml-1 small">{t("dependingCost")}</span>}
                                                </div>
                                                <span className="mt-1 mt-lg-0 " style={{ color: "#05C2D7", fontSize: (isMedium ? "0.7rem" : "") }}>{t("yourCurrent")}<b>Plan {currentPlanName}</b></span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="upgrade-plan_presentation-card_features">
                                            {highlightedFeatures.map((feature, index) => (
                                                <Feature
                                                    key={feature.id}
                                                    src={t(`operations.${feature.id}.image`)}
                                                    text={t(`operations.${feature.id}.name`)}
                                                    width={isMedium ? "100%" : `${100 / highlightedFeatures.length}%`}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </MDBCard>
                            </div>
                            <div className="upgrade-plan_feature-list my-2">
                                <MDBCard>
                                    <div className="upgrade-plan_feature-list_container">
                                        {parent && <h3>{t("includes", { parentName: t(`plans.${parent.name}.name`) })}</h3>}
                                        <div className="list">
                                            <ul className={(!isMedium && featureList.length > 4) ? "split" : ""}>
                                                {featureList.map(feature => <li key={feature.id} >{t(`operations.${feature.id}.name`)}{!feature.implemented && <span className="inline-badge">coming soon!</span>}</li>)}
                                            </ul>
                                        </div>
                                    </div>
                                </MDBCard>
                            </div>
                            {!isMedium && <MDBBtn
                                rounded
                                className="upgrade-btn my-2"
                                color="deep-orange"
                                onClick={(e) => {
                                    sendGAEvent("purchase_intention", "upgrade_clicked", featureList[0].id, 1, null, null);
                                    window.Intercom("showNewMessage", t("upgradeMessage", { planName: t(`plans.${planName}.name`) }));
                                    var metadata = {
                                        feature: featureList[0].id
                                    }
                                    window.Intercom('trackEvent', 'purchase_intention_' + planName, metadata);

                                }}
                            >{t("requestUpgrade")}</MDBBtn>}
                        </div>
                        {!isMedium && !embed &&
                            <div className="upgrade-plan_vector-image">
                                <Image src={envConfig.staticImgUrl + t(`plans.${planName}.vector`)} style={{ width: "100%" }} />
                            </div>
                        }
                    </div>
                    : <NewLoader className="py-5" />}
            </div>
        </>
    );
}

UpgradePlan.translations = {
    ...translations
}

export default translate(UpgradePlan);