import axiosClient from './axiosClient';


export const getTypesForCompany =(companyId) =>{
    return axiosClient.get(`/issues/companies/${companyId}/types`);
}

export const getCurrentIssueForUserInStore = (userId, storeId)=>{
    return axiosClient.get(`/issues/?storeIds=${storeId}&userId=${userId}`);
}


export const getIssuesForStore = (storeId, type, states, assignation, page)=>{
    const typeParam = type && type.length ? `&issueTypeId=${type}` : "";
    const statesParam = states && states.length ? `&issueStateIds=${states}` : "";
    const assignationParam = assignation && assignation.length ? `&assignedAdminIds=${assignation}` : "";
    return axiosClient.get(`/issues/?pageNumber=${page}&storeIds=${storeId}${typeParam}${statesParam}${assignationParam}`);
}

export const getIssuesForStoreCsv = (storeId, type, states)=>{
    const typeParam = type && type.length ? `&issueTypeId=${type}` : "";
    const statesParam = states && states.length ? `&issueStateIds=${states}` : "";
    return axiosClient.get(`/issues/csv?storeIds=${storeId}${typeParam}${statesParam}`);
}

export const getIssueType = (issueTypeId) => {
    return axiosClient.get(`/issues/types/${issueTypeId}`);
}

export const deleteIssueType = (issueTypeId) => {
    return axiosClient.delete(`/issues/types/${issueTypeId}`);
}

export const saveIssueType = (issueType) => {
    return axiosClient.post(`/issues/types`, issueType);
}

export const postIssue = (issue, userId, storeId, type, state, conversationId) =>{
    const body = {
        ...issue,
        store:{
            id:storeId
        },
        user:{
            id: userId
        },
        issueType:type,
        currentIssueState: state,
        conversationCreationContext: conversationId
    }
    return axiosClient.post('/issues/', body);
}