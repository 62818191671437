import { MDBTimePicker } from "mdbreact";
import React from "react";
import { Error } from "./Error.js";
import { Field } from "formik";

export const TimePicker = ({
  field,
  label = "",
  errors,
  isSubmitting,
  setFieldValue,
}) => {
  return (
    <div className="schedule">
      <MDBTimePicker
        data-testid={label}
        label={label}
        value={field.value}
        hoursFormat={24}
        getValue={(value) => {
          const time = value.split(":");
          setFieldValue(field.name + ".hours", time[0]);
          setFieldValue(field.name + ".minutes", time[1]);
        }}
      />
      <Error
        errors={
          errors
        }
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export const FormikTimePicker = ({ name, label, errors }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { isSubmitting, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <TimePicker
          field={field}
          label={label}
          errors={errors}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
        />
      )}
    </Field>
  );
};
