/**
 * Cast date to format YYYY-MM-DD
 * @param {mixed} date a date object or a date string
 * @returns {string} date in format YYYY-MM-DD
 */
export const dateTOYYYYMMDD = (date) => {    
    if(!(date instanceof Date)){
        date = new Date(date);
    }
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear();
    return year+'-'+month+'-'+day;
}

export const format = (date) => {    
    if(!(date instanceof Date)){
        date = new Date(date);
    }
    var day = date.getDate();
	var month = date.getMonth()+1;
    var year = date.getFullYear();
	return year+'/'+month+'/'+day;
}
