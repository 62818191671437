import { miscConstants } from './miscConstants';

export const setLoading = (loading) => {
    return {
        type: miscConstants.SET_LOADING,
        payload: {
            loading: loading
        }
    }
}

export const setError = (error, type) => {
    return {
        type: miscConstants.SET_ERROR,
        payload: {
            error: error
        }
    }
}

export const removeNotifications = (id) => {
    return {
        type: miscConstants.REMOVE_NOTIFICATIONS,
        payload: {
            id: id
        }
    }
}