import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Table } from "botbit-ui-components"
import { MDBIcon } from 'mdbreact';
import useWindowDimensions from '../../commons/utils/useWindowDimensions';
import { getFunnelConfig } from './FunnelModalConfigs';
import { useSelector } from 'react-redux';
import { getReplyTemplates } from '../../api/feedbackApi';

const FunnelDetailModal = ({ isOpen, toggle, stores, period, mode, adminId }) => {
    const [replyTemplates, setReplyTemplates] = useState();

    const { isMedium } = useWindowDimensions();
    const selectedCompany = useSelector(state => state.application.selectedCompany)

    useEffect(() => {
        (async () => {
            const templates = await _getReplyTemplates();
            setReplyTemplates(templates);
        })()
    }, [selectedCompany])

    const _getReplyTemplates = async (company = selectedCompany) => {
        if (!company || !company.operations) return;
        if (!company.operations.includes("REPLY_TEMPLATES__SELECT"))
            return;
        const response = await getReplyTemplates(company.id, 'FEEDBACK');
        if (!response || !response.data || !response.data.data) return;
        const templates = response.data.data;
        const replyTemplates = templates.map(t => {
            return { text: t.name, value: t.id };
        });
        return replyTemplates;
    }

    const {
        title,
        getData,
        columns,
        columnsMobile,
        elementKey,
        securityId,
        onElementClick,
        expandElement,
        dataPath
    } = getFunnelConfig({
        stores,
        period,
        mode,
        adminId,
        company: selectedCompany,
        replyTemplates
    })

    return (
        // Cuando se tiene un modal que contiene un collapsable (el elementExpan pe)
        // es necesario que el modal esté dentro de un componente con clase "modal-open"
        // para asegurar que no hayan problemas con el elemento a scrollear
        <div className={isOpen ? "modal-open" : ""}>
            {replyTemplates !== undefined &&
                <Modal id="scrollable-component-mobile" isOpen={isOpen} className="funnel-detail-modal" centered fullscreen size="lg">
                    <ModalBody id="scrollable-component">
                        <span>
                            <MDBIcon
                                fas="true"
                                size="2x"
                                icon="arrow-left"
                                onClick={toggle}
                                style={{ cursor: 'pointer', color: "#000" }}
                            />
                        </span>
                        <h2 style={{ color: "#000", fontWeight: "bold" }}>{title}</h2>
                        <div className="table-container">
                            <>
                                <Table
                                    getData={getData}
                                    columns={(isMedium && columnsMobile) ? columnsMobile : columns}
                                    pagination={'button'}
                                    pageSizePath="data.data.pageSize"
                                    dataPath={dataPath}
                                    elementKey={elementKey}
                                    securityId={securityId}
                                    showFiltersInfo={true}
                                    onElementClick={onElementClick}
                                    expandElement={expandElement}
                                    getScrollContainer={() => document.getElementById(isMedium ? "scrollable-component-mobile" : "scrollable-component")}
                                />
                            </>
                        </div>
                    </ModalBody>
                </Modal>
            }
        </div>
    );
}

export default FunnelDetailModal;