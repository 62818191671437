import React from 'react';
import { compose } from "redux";
import { translate } from 'react-i18next';
import { applicationConstants } from '../../../commons/components/application/applicationConstants';
import { envConfig } from '../../../config/config.js';
import { NavBarHeader } from "./NavBarHeader";
// import { HeaderNotifications } from "./HeaderNotifications";
// import { HeaderLogout } from "./HeaderLogout";
import { HeaderReviewButton } from "./HeaderReviewButton";
import { HeaderCompanySelector } from './HeaderCompanySelector';
import ReviewRequest from '../../ReviewRequest/ReviewRequest';
import withMixpanel from '../../../config/withMixpanel';
// import { HeaderReviewButtonFloatCircle } from './HeaderReviewButtonFloatCircle';
import { connect } from 'react-redux';
import { emitTrackingEvent } from '../../../api/trackingEvent';
import { withRouter } from 'react-router'
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from 'mdbreact';
// import { OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus as inviteIcon } from "@fortawesome/free-solid-svg-icons";

const isMediumOrLess = window.innerWidth < 768;

class HeaderContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isSecondLevelMenu: this.isSecondLevelMenu(0),
			isThirdLevelMenu: this.isThirdLevelMenu(0),
			isSmallScreen: this.isSmallScreen(),
			isVerySmallScreen: this.isVerySmallScreen(),
			imageWidth: 0,
			reviewRequestIsOpen: false
		};
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	isSecondLevelMenu = (imageWidth) => (window.innerWidth < 492 + imageWidth);
	isThirdLevelMenu = (imageWidth) => (window.innerWidth) < 348 + imageWidth;
	isSmallScreen = () => (window.innerWidth < 470);
	isVerySmallScreen = () => (window.innerWidth < 348);

	availableStoresOptions = (availableStores) => {
		var storeKeyValue = [];
		const allStoresLegend = this.props.t("header.comboAllStoresLabel");
		// si hay mas de una tienda, agrego la opcion "Todas"
		if (availableStores != null && availableStores.length > 1) {
			var defaultStore = applicationConstants.DEFAULT_STORE;
			defaultStore.label = allStoresLegend;
			storeKeyValue.push(defaultStore);
		}
		if (availableStores != null) {
			availableStores.forEach(element => {
				storeKeyValue.push({ value: element.id.toString(), label: element.name, ...element });
			});
		}
		return storeKeyValue;
	};

	componentDidMount = () => {
		document.addEventListener('mousedown', this.handleClickOutside);
		window.addEventListener("resize", () => this.handleResize());
	};

	componentWillUnmount = () => {
		document.removeEventListener('mousedown', this.handleClickOutside);
		window.removeEventListener("resize", () => this.handleResize());
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.props.handleClickOutsideNotification();
		}
	}

	getLogoStoreUrl = (selectedStore, options) => {
		if (selectedStore == null) {
			return null;
		}
		for (const item of options) {
			if (item.id === selectedStore.id && item.id !== -1) {
				return envConfig.staticImgUrl + "stores/" + item.logo;
			}
		}
		return null;
	};

	getComboSelected = (selectedStore, options) => {
		if (selectedStore == null)
			return options[0];

		for (const item of options) {
			if (item.id === this.props.selectedStore.id) {
				return item;
			}
		}
	};

	handleMenuLevels = (imageWidth) => {
		if (this.state.isSecondLevelMenu !== this.isSecondLevelMenu(imageWidth)) {
			this.setState({ ...this.state, isSecondLevelMenu: !this.state.isSecondLevelMenu });
		}
		if (this.state.isThirdLevelMenu !== this.isThirdLevelMenu(imageWidth)) {
			this.setState({ ...this.state, isThirdLevelMenu: !this.state.isThirdLevelMenu });
		}
	}

	handleResize = () => {

		this.handleMenuLevels(this.state.imageWidth);

		if (this.state.isSmallScreen !== this.isSmallScreen()) {
			this.setState({ ...this.state, isSmallScreen: !this.state.isSmallScreen });
		}
		const isVerySmallScreen = this.isVerySmallScreen();
		if (isVerySmallScreen || this.state.isVerySmallScreen !== isVerySmallScreen) {
			this.setState({ ...this.state, isVerySmallScreen: isVerySmallScreen });
		}
	};

	openReviewRequestModal = () => {
		// Adrian: 2021-03-05:
		// el evento de review_request_intention ya no se trackea más por acá
		// ahora es el backend el que va a emitir
		// el evento a mixPanel y a intercom
		//const { mixpanel } = this.props;
		//mixpanel.track("review_request_intention");
		const event = {
			source: 'BOTBIT_BACKOFFICE',
			callToAction: 'main_RR_button',
			subType: 'REVIEW_REQUEST_BUTTON_CLICKED'
		}
		emitTrackingEvent(event)
			.then(res => {
				if (res.status === 200) {
					this.setState({ correlationId: res.data.data.correlationId });
				}
			})

		document.querySelector("body").classList.add("body-overflow-auto");
		window.Intercom("hide");
		this.setState({
			reviewRequestIsOpen: true
		})
	}

	closeReviewRequestModal = () => {
		document.querySelector("body").classList.remove("body-overflow-auto")
		this.setState({
			reviewRequestIsOpen: false
		})
	}

	onImgLoad = ({ target: img }) => {
		// Se toma el max-height del estilo nav-header-store-log, que por ahora es 30
		// y se escala el width de la imagen usando el naturalWidth y el naturalHeight.
		// De esta forma, sabemos debería ser el width con el que se debería dibujar
		// el logo, incluso si la imagen está escondido con el estilo d-none.

		// Si alguien cambia el max-height de la clase CSS nav-header-store-log,
		// debe cambiar esta constante para que el escalado se realice correctamente.
		const maxHeight = 30;
		const width = maxHeight * img.naturalWidth / img.naturalHeight;
		this.setState({ ...this.state, imageWidth: width });
		this.handleMenuLevels(width);
	}

	handleRedirectFC() {
		window.open(envConfig.feedbackDomain);
	}

	showNewCompanySelector = () => true

	render() {

		// const { t, notificationsOpen } = this.props;
		const { t } = this.props;
		// var hideNotificationsBell = (document.body.classList.contains('body-small') && document.body.classList.contains('mini-navbar'))
		const avatar = envConfig.staticImgUrl + "avatars/" + this.props.admin.avatar;
		const profileEditUrl = `/settings/administrators/${this.props.admin.id}`;

		return (
			<>
				<div ref={this.setWrapperRef} className="row border-bottom">
					<nav className="navbar navbar-static-top no-margin-bottom" role="navigation">
						<div id="navContainer">
							<NavBarHeader toggleNavigationBar={this.props.toggleNavigationBar} />
							{/* if menu is expanded on small devices, hide company selector */}
							{ ((this.props.navigationMenuExpanded && isMediumOrLess) || !isMediumOrLess) && <HeaderCompanySelector
								companies={this.props.availableCompanies}
								onSelectCompany={this.props.onSelectCompany}
								selectedCompany={this.props.selectedCompany}
							/>}
							<ul className="nav navbar-top-links">
								{!this.props.location.pathname.startsWith('/chat') && !this.props.location.pathname.startsWith('/issues') &&
									<>
										{!isMediumOrLess && (
											<HeaderReviewButton
												openReviewRequestModal={this.openReviewRequestModal}
												t={t}
											/>
										)
										}

										{/* {isMediumOrLess && (
											<HeaderReviewButtonFloatCircle
												openReviewRequestModal={this.openReviewRequestModal}
												t={t}
											/>
										)} */}
									</>
								}

								<li className='invite-users-icon'>
									<span onClick={() => this.props.history.push("/settings/invite")} style={{ cursor: 'pointer' }}>
										<FontAwesomeIcon icon={inviteIcon} size='2x' />
									</span>
								</li>

								{/* {!hideNotificationsBell &&
									<HeaderNotifications notificationsOpen={notificationsOpen}
										isSmallScreen={this.state.isSmallScreen}
										isVerySmallScreen={this.state.isVerySmallScreen}
										expandNotifications={this.props.expandNotifications}
										clickNotificationHandler={this.props.clickNotificationHandler}
										notifications={this.props.notifications}
										t={t} />
								} */}
								{/* <HeaderLogout handleLogout={this.props.handleLogout} t={t} /> */}
								<li className='user-settings-dropdown'>
									<MDBDropdown>
										<MDBDropdownToggle nav caret>
											<span>
												<img src={avatar} className="rounded-circle user-avatar-img" alt=''/>
											</span>
										</MDBDropdownToggle>
										<MDBDropdownMenu>
											<MDBDropdownItem onClick={() => this.props.history.push(profileEditUrl)}>{this.props.t("header.actions.editProfile")}</MDBDropdownItem>
											<MDBDropdownItem onClick={() => this.props.history.push("/configurations")}> {this.props.t("header.actions.settings")}</MDBDropdownItem>
											<MDBDropdownItem onClick={() => this.props.handleLogout()}>{this.props.t("header.actions.logout")}</MDBDropdownItem>
										</MDBDropdownMenu>
									</MDBDropdown>
								</li>
							</ul>
						</div>
					</nav>
				</div>
				<div className="mx-auto d-none d-md-block">
					<ReviewRequest isOpen={this.state.reviewRequestIsOpen}
						toggle={this.closeReviewRequestModal}
						triggerModalOpen={this.openReviewRequestModal}
						correlationId={this.state.correlationId} />
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		selectedCompany: state.application.selectedCompany,
		admin: state.login.admin,
		navigationMenuExpanded: state.application.navigationMenuExpanded
	}
}

export default compose(
	translate('common'),
	connect(mapStateToProps),
)(withRouter(withMixpanel(HeaderContainer)))
