import React from 'react';
import PropTypes from 'prop-types';
import TaskForm from './TaskForm.js';
import { getTask, saveTask } from "../../api/taskApi.js";
import { withRouter } from 'react-router-dom'
import Loading from '../../commons/components/utils/Loading.js';


class TaskContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	componentDidMount() {
		getTask(this.props.taskId)
			.then(
				response => {
					this.setState(
						{
							task: response.data.data
						}
					)
				}
			);
	}

	onSubmit = (values) => {
		const params = new URLSearchParams(this.props.location.search);
		const param = params.get('backUrl');
		this.setState({task: undefined})
		saveTask(values)
			.then(
				response => {
					const task = response.data.data
					this.setState(
						{
							task: task
						}
					)
					if (this.props.onSubmit)
						this.props.onSubmit(task);
					else
						if (param)
							window.location.href = decodeURIComponent(param.trim());
				}
			);
	}

	render() {
		if (!this.state.task) return <Loading loading={true}></Loading>;
		return (
			<TaskForm task={this.state.task} onSubmit={this.onSubmit} selectedStore={this.props.selectedStore} />
		)
	}
}

TaskContainer.propTypes = {
	taskId: PropTypes.number.isRequired,
	onSubmit: PropTypes.func
}

export default withRouter(TaskContainer)

