import React from "react";
import {Button, UxIcon} from "botbit-ui-components"
import translate from "../../../config/translations/translate";
import "./styles.css";


const FirstConfigPage = ({ t, analyze }) => {

  return (
    <div className="gbp-container first-config-page-container">
    <div className="first-config-page-container">
        <div className="first-config-page-header">
            <h1 style={{fontFamily:'Open Sans', fontWeight:'bold', fontSize:'48px', color:'black'}}>
                {t('title')}
            </h1>
            <h4 style={{fontFamily:'Open Sans', fontWeight:'normal', fontSize:'18px', color:'black'}}>
                {t('subtitle')}
            </h4>
        </div>
        <div className="first-config-page-body">
            <div className="first-config-page-body-card">
                <div className="first-config-page-body-card-title">
                <h2 style={{fontFamily:'Open Sans', fontWeight:'bold', fontSize:'36px', color:'black'}}>
                    {t('card_1_title')}
                </h2>
                <h5 style={{fontFamily:'Open Sans', fontWeight:'normal', fontSize:'15px', textAlign:'center', color:'black'}}>
                    {t('card_1_body')}
                </h5>
                </div>
                <hr/>
                <UxIcon type='clipboard'/>
            </div>
            <div className="first-config-page-body-card">
                <div className="first-config-page-body-card-title">
                <h2 style={{fontFamily:'Open Sans', fontWeight:'bold', fontSize:'36px', color:'black'}}>
                    {t('card_2_title')}
                </h2>
                <h5 style={{fontFamily:'Open Sans', fontWeight:'normal', fontSize:'15px', textAlign:'center', color:'black'}}>
                    {t('card_2_body')}
                </h5>
                </div>
                <hr/>
                <UxIcon type='tools'/>
            </div>
            <div className="first-config-page-body-card">
                <div className="first-config-page-body-card-title">

                <h2 style={{fontFamily:'Open Sans', fontWeight:'bold', fontSize:'36px', color:'black'}}>
                    {t('card_3_title')}
                </h2>
                <h5 style={{fontFamily:'Open Sans', fontWeight:'normal', fontSize:'15px', textAlign:'center', color:'black'}}>
                    {t('card_3_body')}
                </h5>
                </div>
                <hr/>
                <UxIcon type='vectorMarket'/>
            </div>
        </div>
        <Button onClick={()=>analyze()}>
            {t('button')}
        </Button>

    </div>
    </div>

  );
};

FirstConfigPage.translations = {
  title: {
    en: "Welcome to Profile Pages!",
    es: "¡Bienvenido a Profile Pages!",
  },
  subtitle: {
    en: "You will be able to analyze your locations and see the pending improvements.....",
    es: "Podrás analizar tus locales y ver las mejoras pendientes....",
  },
  card_1_title: {
    en: "Analysis",
    es: "ANÁLISIS",
  },
  card_1_body: {
    en: "We review and analyze your premises to help you get more customers",
    es: "Revisamos y analizamos tus locales para ayudarte a conseguir más clientes",
  },
  card_2_title: {
    en: "SCORING",
    es: "SCORING",
  },
  card_2_body: {
    en: "We show you the situation and the actionable",
    es: "Te mostramos la situación y los accionables",
  },
  card_3_title: {
    en: "Suggestions",
    es: "SUGERENCIAS",
  },
  card_3_body: {
    en: "We provide you with tutorials and best practices",
    es: "Te brindamos tutoriales y mejores prácticas",
  },
  button: {
    en: "Start Analysis",
    es: "Comenzar Análisis",
  },

};

export const FirstConfigPageContainer = translate(
    FirstConfigPage
);
