import React from "react";
import { translate } from 'react-i18next';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

class Error extends React.Component {
  buildErrorMessage = (error, errorMessage, errorDefaultMessage) => {
    //default message when !error
    if(!error){
      return 'Reintenta en unos minutos';
    }
    return errorMessage? errorMessage: errorDefaultMessage;
  };
  render = () => {
    if (this.props.error === undefined) {
        return null;
    }

    let errorStatusMsg = this.props.t('error.' + this.props.error.status);
    let message = this.buildErrorMessage(this.props.error, this.props.error.message,
      errorStatusMsg ==='error.' + this.props.error.status?this.props.t('error.generic'):errorStatusMsg);

    if (this.props.popupMode) {
        MySwal.fire({
                type: 'error',
                title: 'Ups',
                text: message,
                heightAuto: false
        }).then(() => this.props.cleanError());
        return null;
    }
     return (<div className="error-container">{message}</div>);
  }
}

export default translate('common')(Error);