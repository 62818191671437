import React from 'react'
import deepCopy from "../../../commons/utils/deepCopy";
import SegmentSimple from '../SegmentSimple'
import { getFilterPossibleValues } from '../../../api/campaignsApi';
import './CampaignSegment.css';


export default class CampaignSegment extends React.Component { 
    getAvailableValuesForFilter = filterDef => {
		let segment = this.props.campaign.segment;
		let companyId = (segment) ? segment.companyId : null;
		let storeIds = (segment && segment.stores && segment.stores.length>0) ? segment.stores.map(
			(element) => { return { id: element.id } }) : null;
		return getFilterPossibleValues(filterDef, this.props.adminId, storeIds, companyId)
    }

    render(){
        return(
            <div className="campaignSegmentContainer">
                <SegmentSimple 
                lgChildSize="12" 
                data={deepCopy(this.props.campaign.segment)} 
                mode={this.props.isEditing ? "edit" : "view"} 
                t={this.props.t} 
                getValuesService={this.getAvailableValuesForFilter} 
                availableStores={this.props.availableStores}
                storesActivableSmartCampaign={this.props.storesActivableSmartCampaign}
                isEditing={this.props.isEditing}
                formik={this.props.formik}
                setInvalid={this.props.setInvalid}
                />
            </div>
        )
    }
}