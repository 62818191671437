import { envConfig } from "../../config";

export const translations = {
  averageScoreFromAllSources: {
    es: "Puntaje promedio de todas tus reseñas 🚦",
    en: "Average score of all your reviews 🚦"
  },
  fiveStarGoogleReviews: {
    es: `Nuevas reseñas 5 ⭐ en Google`,
    en: `New Google 5 ⭐ reviews`
  },
  answeredReviews: {
    es: `Reseñas respondidas por ${envConfig.companyName} 🤖`,
    en: `Reviews replied by ${envConfig.companyName} 🤖`
  },
  highestScoreStore: {
    es: "Mayor puntaje 🏆",
    en: "Highest score 🏆"
  },
  lowestScoreStore: {
    es: "Menor puntaje 🥉",
    en: "Lowest score 🥉"
  },
  singleStoreScore: {
    es: "Puntaje ⭐",
    en: "Score ⭐"
  },
  storesByScore: {
    es: "Todas tus tiendas 🏫",
    en: "All your stores 🏫"
  },
  totalUnsatisfiedClients: {
    es: "Total de clientes insatisfechos 😒",
    en: "Total dissatisfied customers 😒"
  },
  nowSatisfiedClients: {
    es: "Clientes insatisfechos que ahora están felices 💕",
    en: "Dissatisfied customers who are now happy 💕"
  },
  nowUnsatisfiedClients: {
    es: "Clientes felices que ahora están insatisfechos 💔",
    en: "Happy customers who are now dissatisfied 💔"
  },
  moreStores: {
    es: "¿Tienes más tiendas? 🏘️",
    en: "Do you have more stores? 🏘️"
  },
  reviewRequest: {
    es: "Solicitudes de reseña 🚀",
    en: "Review requests 🚀"
  }
}

// faceToFaceSale: {
//   es: "Venta presencial"
// },
// onlineSales: {
//   es: "Venta online"
// },
// estimatedComissionsSavings: {
//   es: "Ahorro estimado en comisiones de delivery"
// },

// promotionsSent: {
//   es: "Promociones enviadas"
// },
// promotionsOpened: {
//   es: "Promociones abiertas"
// },
// promotionsRedeemed: {
//   es: "Promociones canjeadas"
// },