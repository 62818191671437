import React from "react";
import _ from "lodash";


const navigateObject = (obj, path) => {
    return _.at(obj, path)[0];
}
//Las keys de este objeto son las primeras dos letras del locale. Y adentro tienen un array con todos los locales que aceptamos y traducimos especificamente.
//Si no soportamos el locale especifico (por ejemplo es-CL) se toma acceptedLanguage.es[0]
const acceptedLocales = {
    es: [
        'es',
        'es_ar'
    ],
    en: [
        'en'
    ]
};

/**
 * @template T
 * @param {T} WrappedComponent
 * @returns {T}
 */
function translate(WrappedComponent) {
    class TranslatedComponent extends React.Component {
        render() {
            // Wraps the input component in a container, without mutating it. Good!
            return <WrappedComponent {...this.props} t={getTranslationFunction(WrappedComponent.translations)} />;
        }

    }

    return TranslatedComponent;
}

export const getCurrentLocale = () => {
    let userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.toLowerCase().replace("-", "_");
    let language;
    const locale = acceptedLocales[userLang.substring(0, 2)];
    if (locale) {
        if (locale.includes(userLang)) {
            language = userLang;
        } else {
            language = locale[0];
        }
    } else {
        language = "es";
    }
    return language;
}

export const getLanguageFor = (labels, path) => {
    let userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.toLowerCase().replace("-", "_");

    let language;
    const locale = acceptedLocales[userLang.substring(0, 2)];
    if (locale) {
        if (locale.includes(userLang)) {
            language = userLang;
            // si 'es_ar' no esta definido para este path particular
            if (!navigateObject(labels, `${path}.${language}`)) {
                // uso 'es'
                language = locale[0];
            }
        } else {
            language = locale[0];
        }
    } else {
        //Si el idioma del browser no está traducido caemos en "es" (se puede cambiar al que queramos)
        //fallback language
        language = "es";
    }
    return language;
}

export const getTranslationFunction = (labels) => (path, replacements = {}) => {
    let translation = path;
    if (labels && navigateObject(labels, path)) {
        const language = getLanguageFor(labels, path);
        let pathWithLang = `${path}.${language}`;
        if (navigateObject(labels, pathWithLang)) {
            translation = replace(navigateObject(labels, pathWithLang), replacements)
        }
    }

    if (translation.includes("<br>")) {
        const parragraphs = translation.split("<br>");
        return <>
            {parragraphs.map((parr, i) => {
                if (i > 0) {
                    return <><br />{parr}</>
                }
                return <>{parr}</>
            })}
        </>
    }
    return translation;
}

const replace = (text, replacements) => {
    Object.keys(replacements).forEach(key => {
        if (typeof (key) === "string") {
            text = text.replace(new RegExp(`{{${key}}}`, "g"), replacements[key])
        }
    });
    return text;
}

export default translate;
