import { MixpanelConsumer } from 'react-mixpanel';
import React from 'react';

function withMixpanel(WrappedComponent) {
    class ComponentWithMixpanel extends React.Component {
        render() {
            let self = this;
            return (<MixpanelConsumer>
                {mixpanel => <WrappedComponent {...self.props} mixpanel={mixpanel} />}
            </MixpanelConsumer>)
        }
    }
    return ComponentWithMixpanel;
}

export default withMixpanel;