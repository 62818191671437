import axiosClient from './axiosClient';


export const getUserActivity = (userId, stores) => {
	var storeIds = stores ? `&storeIds=${stores}` : ''
	return axiosClient.get(`/stores/analytics/user-activity?userId=${userId}${storeIds}`);
}

export const exportUsers = (store, segment) => {
	var segmentParam = segment && segment.id ? `/segments/${segment.id}` : '';
	var storeParam = store ? store.id : '';
	var url = `/stores/${storeParam}/${segmentParam}/users/csv`;
	return axiosClient.get(url, {
		timeout: 5000000
	});
}


export const getTags = (storeId) => {
	return axiosClient.get(`/stores/${storeId}/user-tags`);
}

export const suspendStore = (storeId) => {
	return axiosClient.put(`/stores/${storeId}/suspend`);
}

export const activateStores = (stores) => {
	return axiosClient.put(`/stores/activate-request/?${stores.map((s, i) => `stores[]=${s}&`).join('')}`);
}

export const getStore = storeId => {
	return axiosClient.get(`/stores/${storeId}`);
}

export const getPublicStore = storeId => {
	return axiosClient.get(`/public-access/stores/${storeId}`);
	// return Promise.resolve({ data: { "data": { "name": "BotBit", "logo": "http://admin0.botbit.io/static/img/stores/3/Botbit_Logo_white_small_new.png", "googleBusinessURL": "https://search.google.com/local/writereview?placeid=ChIJo009FnK2vJURQclEd9oPB-M", "facebookURL": "something.to.show" }, "result": "success", "message": "ok", "statusCode": 200 } })

}

export const getNpsLink = body => {
	const nameParam = `?name=${body.name}`
	const correlationIdParam = `&correlationId=${body.correlationId}`
	const phoneParam = `&phone=${encodeURIComponent(body.phone)}`
	const storeIdParam = `&storeId=${body.storeId}`
	const userIdParam = body.userId ? `&userId=${body.userId}` : ""
	return axiosClient.get(`/stores/nps-link${nameParam}${correlationIdParam}${phoneParam}${storeIdParam}${userIdParam}`);
}

export const updateNpsComment = body => {
	return axiosClient.post(`/public-access/update-nps-comment`, { ...body })
}

export const getConnectedStores = (companyId) => {
	return axiosClient.get(`/stores/companies/${companyId}/connected-stores`);
}