import React from 'react';
import { getTags } from '../../../../api/storeApi.js';
import { getUserTags, addTag, deleteTag } from '../../../../api/userApi.js';
import { MDBRow, MDBCol, MDBIcon, MDBChip, MDBBtn, MDBModalHeader, MDBModal, MDBModalBody, MDBModalFooter, MDBSelect, MDBInput } from 'mdbreact';
import { HorizontalScroll } from 'botbit-ui-components';
import { hasOperation, OPERATIONS } from "../../../utils/adminProfiles.js";
import { translations } from "../../../../config/translations/Clients/UserTags";
import translate from "../../../../config/translations/translate";

class UserTagsContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			tags: [],
			options: [],
			canCreate: props.admin !== undefined && hasOperation(props.admin, OPERATIONS.USER_TAGS__EDIT)
		};
	}


	setTagFromSelect = (tag) => {
		this.setState(
			{
				showInput: tag === ""
			}
		);
		this.setTag(tag);
	}

	setTag = (tag) => {
		this.setState(
			{
				tag: tag
			}
		);
	}

	toggle = () => {
		const open = !this.state.modal;
		if (open) {
			this.updateTags();
		}
		this.setState({
			modal: !this.state.modal,
			tag: undefined
		});
	}

	addTag = () => {
		let tag = { tag: this.state.tag, storeId: this.state.selectedStore.id, userId: this.props.userId }
		addTag(tag)
			.then(
				response => {
					this.setState(prevState =>
					({
						// tags: [...prevState.tags, ...response.data.data],
						tag: undefined,
						options: []
					})
					);
					this.updateUserTags();
				}
			);
		this.toggle();
	}

	deleteTag = (tagToDelete, storeId) => {
		let tag = { tag: tagToDelete, storeId: storeId, userId: this.props.userId }
		deleteTag(tag)
			.then(
				response => {
					// this.setState(
					// 	{
					// 		tags: response.data.data,
					// 	}
					// );
					this.updateUserTags();
				}
			);
		const newList = this.state.tags.filter(
			tag => tag.tag !== tagToDelete
		);
		this.setState(
			{
				tags: newList
			}
		);
	}

	updateTags = () => {
		const { t } = this.props;
		this.setState({ options: [] });
		this.props.selectedStore.forEach(store => {
			getTags(store.id)
				.then(
					response => {
						let options = response.data.data.map(
							tag => ({
								text: tag.tag,
								value: tag.tag,
								checked: false
							})
						);
						if (this.state.canCreate)
							options.unshift({
								text: t("selectATag.second"),
								value: "",
								checked: false
							})
						this.setState(prevState => ({
							options: [...prevState.options, ...options]
						}));
					}
				)
		})
	}

	updateUserTags = () => {
		const tags = this.props.selectedStore.map(async store => {
			const res = await getUserTags(this.props.userId, store.id)
				.then(response => response.data.data);
			return res;
		})
		Promise.all(tags).then(res => {
			let tagsArray = [];
			res.forEach(store => store.forEach(tag => tagsArray.push(tag)));
			this.setState({
				tags: [...tagsArray],
			})
		})

	}

	storesToOptions = () => {
		const storesFormatted = this.props.selectedStore.map(store => ({
			text: store.name,
			value: store
		}))
		this.setState({
			storeOptions: storesFormatted
		})
	}

	componentDidMount() {
		this.updateUserTags();
		if (this.props.selectedStore && this.props.selectedStore.length === 1) {
			this.setState({
				selectedStore: this.props.selectedStore[0],
			})
		}
		this.storesToOptions();
	}

	render() {
		// const { selectedStore } = this.props;
		const emptyList = this.state.tags.length === 0;
		const { t } = this.props;
		return (
			<>
				<MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md"  >
					<MDBModalHeader toggle={this.toggle}>{t("addTag")}</MDBModalHeader>
					<MDBModalBody style={{ overflowY: 'visible' }}>
						{
							this.state.canCreate &&
							<div className="text-dark">
								{t("selectATag.first")} <strong>{t("selectATag.second")}</strong> {t("selectATag.third")}
							</div>
						}
						{
							this.props.selectedStore.length > 1 && this.state.storeOptions && (
								<MDBSelect
									selected={t("selectStore")}
									value={this.state.selectedStore}
									className="mb-5"
									options={this.state.storeOptions}
									getValue={(values) => { this.setState({ selectedStore: values[0] }) }}
								/>
							)
						}

						<MDBSelect
							options={this.state.options}
							className="mb-5"
							search
							selected={t("selectATag")}
							value={this.state.tag}
							getValue={(values) => this.setTagFromSelect(values[0])}
						/>

						{
							this.state.showInput &&
							<MDBInput
								label={t("selectATag.second")}
								icon="tag"
								getValue={(value) => this.setTag(value)}
							/>
						}
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="secondary" onClick={this.toggle} size="sm">{t("cancel")}</MDBBtn>
						<MDBBtn color="primary" disabled={this.state.tag === undefined || this.state.tag === ""} onClick={this.addTag} size="sm">{t("add")}</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
				<MDBRow className="my-auto" style={{ marginLeft: 0, marginRight: 0 }}>
					{
						emptyList &&
						<MDBCol style={{ padding: 0 }} className="col-12 my-auto">
							<MDBBtn color="primary" className="btn " size="sm" rounded onClick={this.toggle}>
								{t("addTag")}
							</MDBBtn>
						</MDBCol>
					}
					{
						!emptyList &&
						<div className="d-flex align-items-center">
							<MDBBtn color="primary" className="btn-flat btn-circle" onClick={this.toggle}>
								<MDBIcon className="icon" icon='plus'></MDBIcon>
							</MDBBtn>
						</div>
					}
					{
						this.state.tags && this.state.tags.length > 0 &&
						(<MDBCol className="col-9 col-md-11 my-auto tags-scroll p-0">
							<HorizontalScroll>
								{this.state.tags.map(
									(tag, index) => {
										const selectedStoresIds = this.props.selectedStore.map(store => store.id);
										if (selectedStoresIds.includes(tag.storeId))
											return (
												<MDBChip
													key={`usertag-${index}`}
													className={"m-0 mr-1 filter-chip d-flex align-items-center btn-grey"}
													close={true}
													handleClose={() => { this.deleteTag(tag.tag, tag.storeId) }}>
													<MDBIcon className="icon" icon="tag" />
													<span className=" text-truncate" style={{ maxWidth: '150px' }}>{tag.tag}</span>
												</MDBChip>
											)
										else
											return null;
									})}
							</HorizontalScroll>
						</MDBCol>)
					}
				</MDBRow>
			</>
		)
	}
}

UserTagsContainer.translations = { ...translations }

export default translate(UserTagsContainer)
