import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialMediaLinks = ({ links ={}, size = undefined }) => {
  if (size === undefined)
    size = '2x'
  return (
    <React.Fragment>
      {links.instagram && links.instagram.length > 0 &&
        <a href={links.instagram} rel="noopener noreferrer" target="_blank" className="mr-2">
          <FontAwesomeIcon icon={["fab", "instagram"]} size={size} />
        </a>
      }
      {links.facebook && links.facebook.length > 0 &&
        <a href={links.facebook} rel="noopener noreferrer" target="_blank" className="mr-2">
          <FontAwesomeIcon icon={["fab", "facebook"]} size={size} />
        </a>
      }
      {links.google && links.google.length > 0 &&
        <a href={links.google} rel="noopener noreferrer" target="_blank" className="mr-2">
          <FontAwesomeIcon icon={["fab", "google-plus-square"]} size={size} />
        </a>
      }
      {links.twitter && links.twitter.length > 0 &&
        <a href={links.twitter} rel="noopener noreferrer" target="_blank" className="mr-2">
          <FontAwesomeIcon icon={["fab", "twitter-square"]} size={size} />
        </a>
      }
      {links.linkedIn && links.linkedIn.length > 0 &&
        <a href={links.linkedIn} rel="noopener noreferrer" target="_blank" className="mr-2">
          <FontAwesomeIcon icon={["fab", "linkedin"]} size={size} />
        </a>
      }
    </React.Fragment>
  )
};

export default SocialMediaLinks
