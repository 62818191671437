export const translations = {
  companyQR: {
    es: "QR de la empresa",
    en: "Company QR"
  },
  qrSaved: {
    es: "QR guardado con éxito",
    en: "QR Saved"
  },
  qrSavedText: {
    es: "Tu código QR ha sido guardado con éxito",
    en: "Your QR code has been saved!"
  },
  urlPrefixLabel: {
    es: "Prefijo para URL de registro manual",
    en: "URL prefix for registration"
  },
  clientsCouldAccessA: {
    es: "Los clientes podrán acceder a ",
    en: "Clients could access to "
  },
  clientsCouldAccessB: {
    es: " si prefieren no escanear el código QR",
    en: " if they do not want to scan the QR"
  },
  helperMessageLabel: {
    es: "Texto que acompaña a la selección de tiendas",
    en: "Message to show within the store selection"
  },
  save: {
    es: "Guardar",
    en: "Save"
  },
  forDownloading: {
    a: {
      es: "Para descargar el QR hacer ",
      en: "For downloading the QR "
    },
    b: {
      es: "click derecho",
      en: "right-click"
    },
    c: {
      es: " en la imagen y luego seleccionar ",
      en: " on the image below and then click "
    },
    d: {
      es: "guardar como",
      en: "save as"
    },
  },
  language: {
    es: "es",
    en: "en"
  },
  signOn: {
    es: "Registrate en",
    en: "Sign on"
  },
  useUrl: {
    es: "O puedes utilizar la siguiente dirección ",
    en: "Or you can use this link "
  },
  prefixHint:{
    es:"Prefijo que identifica a su empresa, por ej.: ",
    en:"Prefix that identifies your company, for example: "
  },
}
