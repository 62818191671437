import React from "react";
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const InputField = (props) => {
  const {meta: {touched, error}, input, type, placeholder, autoFocus, id, input: {name}, label, help:HelpComponent, icon, disabled} = props;
  const fieldId= id? id : name;
	const validationClasses = {"is-invalid":touched && error , "": touched && !error};
	const inputProps= {
		disabled : disabled === true 
	}
  return (
    <React.Fragment>
      <div className="form-group">
        {props.label? <label className="form-control-label pt-2" htmlFor={fieldId}> {label}</label>:''}
        <div className={classNames("input-group")}>
			<input
				{...inputProps}
              {...input}
              id={fieldId}
              type={type}
              placeholder={placeholder}
              autoFocus={autoFocus}
              className={classNames("form-control", validationClasses )}
            />
          {icon?
            <span className="input-group-addon">
                 <FontAwesomeIcon icon={icon}/>
            </span>:
            ''
          }
          {touched && error? <div className="invalid-feedback">{error}</div>:''}
        </div>
        {HelpComponent?<HelpComponent/>:(!touched) || (touched && !error) ? <div>&nbsp;</div> : ''
        }
      </div>
    </React.Fragment>
  )
};

export default InputField;
