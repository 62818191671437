import React from 'react';
import Chart from "react-google-charts";
import { translate } from 'react-i18next';
import IBox from '../../commons/components/inspinia/IBox';
import DateRangePicker from '../../commons/components/utils/form/DateRangePicker';
import Loading from '../../commons/components/utils/Loading'
import {Col, Row} from "react-bootstrap";

const options = {
	curveType: "function",
	legend: { position: "bottom" },
	animation: {
		startup: true,
		easing: 'in' 
	},
	chartArea:{width:'80%', top:15}
};

class EmailActivityChart extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.dailyInformation!==this.props.dailyInformation;
    }

	render = () => {
        if(this.props.dailyInformation===undefined || this.props.dailyInformation===[]){
            return (<Loading loading={this.props.dailyInformation===undefined}/>);
        }
        let { t } = this.props;
        let chartData = this.props.dailyInformation;
		const tittles = [
			t("common.date"),
			t("mail.delivered"),
			t("mail.opened"),
			t("mail.clicked"),
		];
		chartData.unshift(tittles);

		return (
      <Row>
        <Col lg={12}>
          <IBox title={t("panels.emailActivity.chartTittle")}>
            <DateRangePicker
              onSelect={this.props.dateChangeHandler}
              initDate={this.props.initDate}/>
	      { chartData.length === 1 &&
	        <div>{t("panels.emailActivity.noData")}</div>
	      }
	      { chartData.length > 1 &&
                <Chart
                  chartType="Line"
                  width="100%"
                  height="400px"
                  data={chartData}
                  options={options}
                />
	      }
          </IBox>
        </Col>
      </Row>
		);
	}
}

export default translate('common')(EmailActivityChart)
