export const translations = {
	months: {
		1: {
			es: 'Enero',
			en: 'January'
		},
		2: {
			es: 'Febrero',
			en: 'February'
		},
		3: {
			es: 'Marzo',
			en: 'March'
		},
		4: {
			es: 'Abril',
			en: 'April'
		},
		5: {
			es: 'Mayo',
			en: 'May'
		},
		6: {
			es: 'Junio',
			en: 'June'
		},
		7: {
			es: 'Julio',
			en: 'July'
		},
		8: {
			es: 'Agosto',
			en: 'August'
		},
		9: {
			es: 'Setiembre',
			en: 'September'
		},
		10: {
			es: 'Octubre',
			en: 'October'
		},
		11: {
			es: 'Noviembre',
			en: 'November'
		},
		12: {
			es: 'Diciembre',
			en: 'December'
		},
	},
	shortMonths: {
		1: {
			es: 'Ene',
			en: 'Jan'
		},
		2: {
			es: 'Feb',
			en: 'Feb'
		},
		3: {
			es: 'Mar',
			en: 'Mar'
		},
		4: {
			es: 'Abr',
			en: 'Apr'
		},
		5: {
			es: 'May',
			en: 'May'
		},
		6: {
			es: 'Jun',
			en: 'Jun'
		},
		7: {
			es: 'Jul',
			en: 'Jul'
		},
		8: {
			es: 'Ago',
			en: 'Aug'
		},
		9: {
			es: 'Set',
			en: 'Sep'
		},
		10: {
			es: 'Oct',
			en: 'Oct'
		},
		11: {
			es: 'Nov',
			en: 'Nov'
		},
		12: {
			es: 'Dic',
			en: 'Dec'
		},
	}

}