import { MDBBtn, MDBInput } from "mdbreact"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { getFlow, postFlow } from "../../../api/flowsApi"
import translate from "../../../config/translations/translate"
import { StepForm } from "./StepForm"
import "./FlowContainer.scss"
import useCompanyAdministratos from "./useCompanyAdministrators"
import { CompanyAdminSelect } from "./ActionsTable"
import { WithContext as ReactTags } from 'react-tag-input';
import { useHistory } from "react-router-dom"


export const ConversationFlowActionType = {
    SEND_TEMPLATE: 'SEND_TEMPLATE', 
    OPTION: 'OPTION', 
    CREATE_ISSUE: 'CREATE_ISSUE', 
    ASSIGN_TO_ADMINISTRATOR: 'ASSIGN_TO_ADMINISTRATOR', 
    EXIT_FLOW: 'EXIT_FLOW'
}


export const StepsTable = ({steps=[], setCurrentStep, removeStep})=>{
    return <div className="container">
        {
            steps.map((s,i)=>(
                <div className="row">
                    <div onClick={()=>setCurrentStep(i)} className="col-10 m-auto cursor-pointer">
                        {s.name}
                    </div>
                    <div className="col-2">
                        <MDBBtn color="secondary" size="sm" id="actionRemoveButton" data-testid="actionRemoveButton"  onClick={()=>removeStep(i)}>⌫</MDBBtn>
                    </div>
                </div>
            ))
        }
    </div>
}

const initialFlow = {
    name:"Nuevo flujo",
    steps:[{
        isInitialState:true,
        name: "Step 0",
        transientId: "step_"+Date.now(),
        actions:[]
    }]
}
export const FlowContainer = ({id, t}) => {
    const selectedCompany = useSelector(state => state.application.selectedCompany);

    const swal = withReactContent(Swal);

    const [currentFlow, setCurrentFlow] = useState(id ? undefined : {
        ...initialFlow,
        company:selectedCompany
    });
    const [currentStep, setCurrentStep] = useState(0);

    const history = useHistory();

    useEffect(()=>{
        if(id && !currentFlow){
            getFlow(id)
            .then(res=>{
                setCurrentFlow(res);
            })
        }
    }, [])

    if(!currentFlow) return null;

    return <div className="flow-container pb-5" style={{overflow:'hidden'}}>
        <div className="row">
            <div className="col-4">
                <MDBInput
                    value={currentFlow.name}
                    getValue={(value)=>{
                        setCurrentFlow({
                            ...currentFlow,
                            name: value
                        })
                    }}
                ></MDBInput>
                <StepsTable
                    steps={currentFlow.steps}
                    setCurrentStep={setCurrentStep}
                    removeStep={(value)=>{
                        const newSteps = [...currentFlow.steps]
                        newSteps.splice(value, 1);
                        setCurrentFlow({
                            ...currentFlow,
                            steps: newSteps
                        })
                        if(value===currentStep)
                            setCurrentStep(0);
                    }}
                >
                </StepsTable>
                <MDBBtn color="primary"
                    onClick={()=>{
                        setCurrentFlow({
                            ...currentFlow,
                            steps: [
                                ...currentFlow.steps,
                                {
                                    name: "Step "+currentFlow.steps.length,
                                    transientId: "step_"+Date.now(),
                                    isInitialState: false
                                }
                            ]
                        })
                    }}
                >+ Agregar</MDBBtn>
            </div>
            <div className="col-8">
                <StepForm
                    selectedStep={currentFlow.steps[currentStep]}
                    t={t}
                    steps={currentFlow.steps}
                    updateSelectedStep={(value)=>{
                        const newSteps = [...currentFlow.steps]
                        // initialState previo al update
                        const initialStepIndex = newSteps.findIndex(s=>s.isInitialState);
                        // actualizo el initalState en caso de que sea necesario
                        if(initialStepIndex===currentStep){
                            // si el initialState es el que estoy actualizando lo seteo en true para estar seguro
                            value.isInitialState=true;
                        } else {
                            if(value.isInitialState){
                                // si la actualización setea el step seleccionado con inicial, seteo en false el initialState previo al update
                                newSteps[initialStepIndex]={
                                    ...newSteps[initialStepIndex],
                                    isInitialState:false
                                }
                            }
                        }
                        newSteps[currentStep]=value;
                        setCurrentFlow({
                            ...currentFlow,
                            steps: newSteps
                        })
                    }}
                ></StepForm>
            </div>
        </div>
        <FlowConfiguration
            t={t}
            currentFlow={currentFlow}
            setCurrentFlow={setCurrentFlow}
        ></FlowConfiguration>
        <MDBBtn color="primary" onClick={
            ()=>postFlow(currentFlow)
            .then(()=>{
                swal.fire({
                    title: t("flowSaved.title"),
                    text: t("flowSaved.text"),
                    type: 'info',
                    confirmButtonColor: '#1ab394',
                }).then(()=>{
                    history.push("/flows");
                })
            })
        }> {t("save")}</MDBBtn>
        <MDBBtn color="primary" onClick={
            ()=>{
                swal.fire({
                    title: t("flowEditCancel.title"),
                    text: t("flowEditCancel.text"),
                    type: 'info',
                    confirmButtonColor: '#1ab394',
                    showCancelButton: true,
                    cancelButtonText: t("cancel")
                }).then(result => {
                    if (result.value) {
                        history.push("/flows")
                    }
                })
        }}> {t("cancel")}</MDBBtn>
    </div>
}

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const FlowConfiguration = ({t, currentFlow, setCurrentFlow}) =>{

    const companyAdministrators = useCompanyAdministratos();

    //parche para usar react tags input
    const formatTags = ()=>currentFlow.transferKeywords ? currentFlow.transferKeywords.map(keyword=>({
        id:keyword,
        text:keyword
    })) : []

    const setTransferKeywords = (transferKeywords) => {
        setCurrentFlow({
            ...currentFlow,
            transferKeywords: transferKeywords
        });
    }

    const handleDelete = (i) => {
        const tags = currentFlow.transferKeywords || [];
		var newTags = tags.filter((tag, index) => index !== i);
        setTransferKeywords(newTags);
    }

	const handleAddition = (tag) => {
        const tags = currentFlow.transferKeywords || [];
		var newTags =  [...tags, tag.text]
		setTransferKeywords(newTags);
    }

    const handleDrag = (tag, currPos, newPos) => {
        const tags = currentFlow.transferKeywords || [];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag.text);

        // re-render
		setTransferKeywords(newTags);
    }

    return (<div>
        <h2>{t("flowConfiguration.title")}</h2>
        <p>{t("flowConfiguration.subtitle")}</p>
        <MDBInput
            type="textarea"
            label={t("flowConfiguration.welcomeMessage")}
            value={currentFlow.welcomeMessage}
            getValue={(value)=>setCurrentFlow({
                ...currentFlow,
                welcomeMessage:value
            })}
        ></MDBInput>
        <MDBInput
            type="textarea"
            label={t("flowConfiguration.transferMessage")}
            value={currentFlow.transferMessage}
            getValue={(value)=>setCurrentFlow({
                ...currentFlow,
                transferMessage:value
            })}
        ></MDBInput>
        <ReactTags
                classNames={{
                    tags: 'd-flex flex-column',
                    tagInput: 'md-form',
                    tagInputField: 'form-control',
                    tag: 'label-primary p-2 m-1 d-inline-flex',
                    remove: 'pl-2',
                    suggestions: 'list-group',
                    activeSuggestion: 'label-primary p-2 m-1'
                }}
                inline={true}
                placeholder={t("flowConfiguration.transferKeywords")}
                tags={formatTags()}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                delimiters={delimiters} 
                autofocus={false}/>
        <CompanyAdminSelect
            placeholder={t("flowConfiguration.transferAdministrator")}
            administrators={companyAdministrators}
            selected={currentFlow.transferAdministrator}
            onAdministratorSelected={adminId =>{
                setCurrentFlow({
                    ...currentFlow,
                    transferAdministrator: {
                        id:adminId[0]
                    }
                })
            }}
        />
        
    </div>)
}

FlowContainer.translations = {
    flowSaved: {
        title:{
            es: "Flujo guardado",
            en: "Flow saved"
        },
        text:{
            es: "El flujo fue guardado con éxito",
            en: "The flow was saved successfully",
        }
    },
    isInitialState:{
        es: "Este es el primer paso del flujo",
        en: "This is the first step of the flow"
    },
    subflow:{
        es: "Subflujo: otras opciones dentro de la principal",
        en: "Subflow: other options within the main"
    },
    actions:{
        es: "Acciones: derivar conversación, mostrar plantilla y/o etiquetar conversación",
        en: "Actions: bypass conversation, show template and/or tag conversation"
    },
    add: {
        es: "+ Agregar",
        en: "+ Add"
    },
    save: {
        es: "Guardar",
        en: "Save"
    }
    ,cancel: {
        es: "Cancelar",
        en: "Cancel"
    },
    actionTypeSelect: {
        placeholder: {
            es: "Seleccione un tipo de acción",
            en: "Select an action type"
        },
        SEND_TEMPLATE: {
            es: "Mostrar plantilla",
            en: "Show template"
        },
        CREATE_ISSUE: {
            es: "Etiquetar conversación",
            en: "Tag conversation"
        },
        ASSIGN_TO_ADMINISTRATOR:{
            es: "Derivar conversación",
            en: "Bypass conversation"
        },
        EXIT_FLOW:{
            es: "Salir",
            en: "Exit"
        }
    },
    stepSelect: {
        placeholder: {
            es: "Selecione un paso",
            en: "Select step"
        }
    },
    flowConfiguration: {
        title:{
            es: "Configuraciones del flujo",
            en: "Flow configuration"
        },
        subtitle:{
            es: "⚠️ Estas configuraciones afectan a todos los pasos del flujo",
            en: "⚠️ These settings affect all steps in the flow"
        },
        welcomeMessage: {
            es: "Mensaje de bienvenida",
            en: "Welcome message"
        },
        transferKeywords: {
            es: "Palabras que transfieren la conversación",
            en: "Words that causes conversation tranfer"
        },
        transferMessage: {
            es: "Mensaje de transferencia",
            en: "Transfer message"
        },
        transferAdministrator: {
            es: "Administrador para transferir la conversación",
            en: "Administrator to transfer conversation"
        }
    },
    flowEditCancel: {
        title:{
            es: "Cancelar",
            en: "Cancel"
        },
        text:{
            es: "Se perderán los cambios",
            en: "Changes will be lost",
        }
    },
    subflowTable: {
        empty: {
            es:"⚠️ Este menú no tiene ninguna opción, agrégalas desde el boton + AGREGAR",
            en: "⚠️ This menu does not have any options, add them from the + ADD button"
        }
    },
    actionsTable: {
        empty: {
            es:"⚠️ Esta opción no realiza ninguna acción al ser seleccionada, agrégalas desde el boton + AGREGAR",
            en: "⚠️ This option does not perform any action when selected, add them from the + ADD button"
        }
    }
}

export default translate(FlowContainer);