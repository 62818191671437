import { Formik } from "formik";
import React from "react";
import translate from "../../config/translations/translate.js";
import "./styles.css";
import { eventValidationSchema } from "./validations.js";
import { SocialLocationPostCallToActionType } from "./SocialLocationPostCallToActionType";
import { eventFormTtranslations } from "./translations.js";
import { FormikPostImageField } from "./ImageField.js";
import { FormikDatePicker } from "./DatePicker.js";
import { FormikTimePicker } from "./TimePicker.js";
import { Select, Text, TextArea } from "./Inputs.js";
import { showCallToActionUrl } from "./showCallToActionUrl.js";

const actionTypes = Object.values(SocialLocationPostCallToActionType);

const SocialLocationPostUpdateForm = ({
  t,
  socialLocationPost,
  onSubmit,
  images,
  formRef,
}) => {
  const validationSchema = eventValidationSchema(t);

  return (
    <div data-testid="SocialLocationPostUpdateForm">
      <Formik
        ref={formRef}
        initialValues={{ ...socialLocationPost }}
        onSubmit={(values, actions) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          values,
        }) => {
          return (
            <form
              data-testid="SocialLocationPostUpdateFormForm"
              onSubmit={handleSubmit}
            >
              <h4>{t("panels.socialLocationPost.form.titles.addImage")}</h4>
              <div className="row">
                <div className="col-md-4">
                  <FormikPostImageField
                    label={t(
                      "panels.socialLocationPost.form.label.media.sourceUrl"
                    )}
                    images={images}
                    errors={errors.media}
                    t={t}
                  />
                </div>
                <div className="col-md-8">
                  <Text
                    name="event.title"
                    label={t("panels.socialLocationPost.form.label.title")}
                    errors={errors.event && errors.event.title}
                  />

                  <TextArea
                    name="summary"
                    label={t("panels.socialLocationPost.form.label.summary")}
                    errors={errors.summary}
                  />

                  <Select
                    name="callToAction.actionType"
                    options={actionTypes.map((o) => ({
                      text: t(o),
                      value: o,
                    }))}
                    label={t(
                      "panels.socialLocationPost.form.label.callToAction.actionType"
                    )}
                    errors={
                      errors.callToAction && errors.callToAction.actionType
                    }
                    className="w-50 ml-auto"
                  />

                  {showCallToActionUrl(values) && (
                    <Text
                      info={t(`utmInfo`)}
                      name="callToAction.url"
                      label={t(
                        "panels.socialLocationPost.form.label.callToAction.url"
                      )}
                      errors={errors.callToAction && errors.callToAction.url}
                      prefix={'https://'}
                    />
                  )}
                </div>
              </div>
              <h4>{t("panels.socialLocationPost.form.titles.postDetails")}</h4>
              <h6>{t("panels.socialLocationPost.form.titles.eventDate")}</h6>
              <div className="row">
                <div className="col-12 col-md-6">
                  <FormikDatePicker
                    name="event.schedule.startDate"
                    label={t(`panels.socialLocationPost.form.label.startDate`)}
                    errors={
                      errors.event &&
                      errors.event.schedule &&
                      errors.event.schedule.startDate
                    }
                    alwaysBefore={"event.schedule.endDate"}
                  />
                  <FormikTimePicker
                    name="event.schedule.startTime"
                    label={t(`panels.socialLocationPost.form.label.startTime`)}
                    errors={
                      errors.event &&
                      errors.event.schedule &&
                      errors.event.schedule.startTime
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormikDatePicker
                    name="event.schedule.endDate"
                    label={t(`panels.socialLocationPost.form.label.endDate`)}
                    errors={
                      errors.event &&
                      errors.event.schedule &&
                      errors.event.schedule.endDate
                    }
                    alwaysAfter={"event.schedule.startDate"}
                  />

                  <FormikTimePicker
                    name="event.schedule.endTime"
                    label={t(`panels.socialLocationPost.form.label.endTime`)}
                    errors={
                      errors.event &&
                      errors.event.schedule &&
                      errors.event.schedule.endTime
                    }
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

SocialLocationPostUpdateForm.translations = eventFormTtranslations;
export default translate(SocialLocationPostUpdateForm);
