import { faEdit as keywordConfig } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Filters, calculateFrom, calculateTo, timePeriodsIds as timePeriods } from 'botbit-ui-components';
import _ from 'lodash';
import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getKeywordsForCompany, getLastUpdateTime, getReviewSentimentByMonth, getReviewSentimentSummaryByMonth, getSentimentsByDay, sendFeedbackForReviewSentiment } from '../../../api/feedbackApi';
import { setLoading } from '../../../commons/components/application/miscActions';
import { firePageView } from "../../../commons/components/utils/trackers";
import { SocialSourceNames } from '../../../commons/enums/SocialSource';
import { translations } from '../../../config/translations/Feedback/SentimentContainer';
import { translations as monthTranslations } from '../../../config/translations/Months';
import translate from '../../../config/translations/translate';
import { GradientHelper } from './GradientHelper';
import KeywordsDistributionByComments from './KeywordsDistributionByComments';
import KeywordsDistributionByStores from './KeywordsDistributionByStores';
import KeywordsSummary from './KeywordsSummary';
import { SectionTitle } from './SectionTitle';
import SendFeedbackModal from './SendFeedbackModal';
import './SentimentContainer.scss';
import SentimentDayDetail from './SentimentDayDetail';
import SentimentMonthCalendarGraph from './SentimentMonthCalendarGraph';
import SentimentMonthEvolutionGraph from './SentimentMonthEvolutionGraph';

const SentimentContainer = ({ t }) => {
	const availableCompanies = useSelector((state) => state.application.availableCompanies);
	const selectedCompany = useSelector((state) => state.application.selectedCompany);
	const availableStores = useSelector(state => state.application.availableStores)
	// const isMOTU = useSelector(state => state.login.admin.objectAccess === 'ALL')

	const [filterValues, setFilterValues] = useState({
		// timePeriod: [{ value: timePeriods.LAST_6_MONTHS }],
		companies: [selectedCompany.id],
		stores: [],
		socialSources: [],
		keywords: []
	})
	const [statisticsByMonthSummary, setStatisticsByMonthSummary] = useState(undefined)
	const [statisticsByMonth, setStatisticsByMonth] = useState(undefined)
	const [statisticsByDay, setStatisticsByDay] = useState(undefined)

	const [selectedDay, setSelectedDay] = useState(undefined)
	const [selectedDayStats, setSelectedDayStats] = useState(undefined)
	const [selectedMonth, setSelectedMonth] = useState(undefined)
	const [selectedYear, setSelectedYear] = useState(undefined)

	const [reviewSentimentIdForFeedback, setReviewSentimentIdForFeedback] = useState(undefined)
	const [showFeedbackModal, setShowFeedbackModal] = useState(false)

	const [lastUpdateTime, setLastUpdateTime] = useState(undefined)

	const [firstLoad, setFirstLoad] = useState(true)
	const [keywords, setKeywords] = useState([]);
	// const [selectedKeyword, setSelectedKeyword] = useState(undefined);

	const [lastYearSelected, setLastYearSelected] = useState(undefined)
	const [lastMonthSelected, setLastMonthSelected] = useState(undefined)

	const history = useHistory();

	const schemas = {
		socialSources: {
			id: "socialSources",
			type: "multiselect",
			options: [
				{
					value: 1,
					text: SocialSourceNames[1]
				},
				{
					value: 2,
					text: SocialSourceNames[2]
				},
				{
					value: 3,
					text: SocialSourceNames[3]
				},
			],
			placeholder: t("filters.socialSource"),
			label: t("filters.socialSource"),
			visible: true,
			priority: true,
			defaultValues: filterValues.socialSources,
		},
		// timePeriod: {
		// 	id: 'timePeriod',
		// 	label: t("filters.timePeriod"),
		// 	type: 'timeperiod',
		// 	icon: 'calendar-alt',
		// 	placeholder: t("custom"),
		// 	priority: true,
		// 	visible: true,
		// 	options: [timePeriods.THIS_MONTH, timePeriods.LAST_2_MONTHS, timePeriods.LAST_3_MONTHS, timePeriods.LAST_6_MONTHS, timePeriods.THIS_YEAR],
		// 	defaultValues: filterValues.timePeriod,
		// },
		companies: {
			id: "companies",
			label: t("filters.company"),
			type: "select",
			icon: "building",
			placeholder: t("filters.allCompanies"),
			priority: true,
			visible: availableCompanies.length > 1,
			options: availableCompanies.map(s => ({ value: s.id, text: s.name })),
			defaultValues: filterValues.companies,
			search: true
		},
		stores: {
			id: "stores",
			label: t("filters.store"),
			type: "multiselect",
			icon: "store-alt",
			placeholder: t("filters.allStores"),
			priority: true,
			visible: true,
			options: availableStores.map(s => ({ value: s.id, text: s.name, dependencyId: s.companyId })),
			search: true,
			defaultValues: filterValues.stores,
			dependsOn: "companies"
		},
		keywords: {
			id: "keywords",
			label: t("filters.keywords"),
			type: "select",
			icon: "hashtag",
			placeholder: t("filters.keywords"),
			priority: true,
			visible: true,
			options: keywords.map(s => ({ value: s.id, text: s.keyword })),
			// search: true,
			defaultValues: []
		}
	};

	const dispatch = useDispatch();

	const fetchSummaryByMonth = () => {
		dispatch(setLoading(true));
		fetchLastUpdateTime()
		getReviewSentimentSummaryByMonth(
			calculateFrom(timePeriods.LAST_6_MONTHS),
			calculateTo(timePeriods.LAST_6_MONTHS),
			// calculateFrom(filterValues.timePeriod[0].value),
			// calculateTo(filterValues.timePeriod[0].value),
			filterValues.companies,
			filterValues.stores,
			filterValues.socialSources,
			filterValues.keywords
		).then(rsp => {
			const monthList = rsp.data.data;
			const monthCopy = rsp.data.data.slice().reverse();
			const lastMonthWithData = monthCopy.find(element => (element.totalNegativeReviews + element.totalPositiveReviews) > 0)
			const lastMonthSelectedData = monthCopy.find(element => element.year === lastYearSelected && element.month === lastMonthSelected)
			setStatisticsByMonthSummary(monthList)
			setStatisticsByMonth(undefined)
			setStatisticsByDay(undefined)
			setSelectedDayStats(undefined)
			if (lastMonthSelectedData) {
				setSelectedMonth(lastMonthSelected)
				setSelectedYear(lastYearSelected)
			}
			else if (lastMonthWithData) {
				setSelectedMonth(lastMonthWithData.month)
				setSelectedYear(lastMonthWithData.year)
			} else {
				setLastYearSelected(undefined)
				setLastMonthSelected(undefined)
				// if there's no data no fetchMonthDetails will be triggered, so I must disable loader
				dispatch(setLoading(false));
			}
		}).finally(() => {
			setFirstLoad(false)
		}).catch(() => {
			// if there's an error no fetchMonthDetails will be triggered, so I must disable loader
			dispatch(setLoading(false));
		});
	};

	const fetchMonthDetails = () => {
		if (!selectedMonth || !selectedYear)
			return;
		dispatch(setLoading(true));
		getReviewSentimentByMonth(
			filterValues.companies,
			filterValues.stores,
			filterValues.socialSources,
			selectedYear,
			selectedMonth,
			filterValues.keywords
		).then(rsp => {
			setStatisticsByMonth(rsp.data.data)
			setStatisticsByDay(undefined)
			setSelectedDayStats(undefined)
		}).finally(() => {
			dispatch(setLoading(false));
		});
	};

	const fetchDayReviews = () => {
		if (!selectedMonth || !selectedYear)
			return;
		dispatch(setLoading(true));
		getSentimentsByDay(
			filterValues.companies,
			filterValues.stores,
			filterValues.socialSources,
			selectedYear,
			selectedMonth,
			selectedDay.getDate(),
			filterValues.keywords
		).then(rsp => {
			const reviewsByDay = rsp.data.data
			setStatisticsByDay(reviewsByDay)
		}).finally(() => {
			dispatch(setLoading(false));
		});
	};

	const sendFeedback = (id, text, isPositive) => {
		dispatch(setLoading(true));
		sendFeedbackForReviewSentiment(id, text, isPositive).then((rsp) => {
			const reviewUpdated = rsp.data.data
			const newStatiticsByDay = statisticsByDay.map((elem) => {
				return (elem.id === reviewUpdated.id) ? reviewUpdated : elem
			})
			setStatisticsByDay(newStatiticsByDay)
			setReviewSentimentIdForFeedback(undefined)
		}).finally(() => {
			setShowFeedbackModal(false)
			dispatch(setLoading(false));
		})
	}

	const fetchLastUpdateTime = () => {
		getLastUpdateTime(filterValues.companies[0]).then((rsp) => {
			setLastUpdateTime(rsp.data.data);
		})
	}

	const changeKeywordFilter = (keywordId) => {
		const newFilterValues = { ...filterValues }
		newFilterValues.keywords = [keywordId]
		setFilterValues(newFilterValues)
	}

	useEffect(() => {
		firePageView();
	}, []);

	useEffect(() => {
		getKeywordsForCompany(selectedCompany.id).then((rsp => {
			setKeywords(rsp.data.data)
		}))
	}, []);

	useEffect(() => {
		setLastMonthSelected(selectedMonth)
		setLastYearSelected(selectedYear)
		setSelectedDay(undefined)
		setSelectedMonth(undefined)
		setSelectedDayStats(undefined)
		setSelectedYear(undefined)
		fetchSummaryByMonth()

		// if (filterValues.keywords.length === 0) {
		// 	setSelectedKeyword(undefined)
		// } else {
		// 	const keywordToSelect = keywords.find(kw => kw.id === filterValues.keywords[0])
		// 	setSelectedKeyword(keywordToSelect)
		// }

	}, [JSON.stringify(filterValues)])

	useEffect(() => {
		if (selectedYear && selectedMonth)
			fetchMonthDetails()
	}, [selectedYear, selectedMonth])

	useEffect(() => {
		if (selectedDay)
			fetchDayReviews()
	}, [selectedDay])

	const onMonthSelected = (year, month) => {
		if (selectedMonth !== month || selectedYear !== year) {
			setStatisticsByMonth(undefined)
			setStatisticsByDay(undefined)
		}
		setLastMonthSelected(month)
		setLastYearSelected(year)
		setSelectedMonth(month)
		setSelectedYear(year)
	}

	const onDaySelected = (day, dayStats) => {
		setSelectedDay(day)
		setSelectedDayStats(dayStats)
	}

	const onSendFeedbackClicked = (reviewSentimentId) => {
		setReviewSentimentIdForFeedback(reviewSentimentId)
		setShowFeedbackModal(true)
	}

	const hideFeedbackModal = () => {
		setShowFeedbackModal(false)
	}

	// const getKeywordName = (keywordId) => {
	// 	const keywordToExtractName = keywords.find(kw => kw.id === keywordId)
	// 	return keywordToExtractName && keywordToExtractName.keyword
	// }

	return (
		<MDBContainer className="sentiment-container">
			<SendFeedbackModal
				show={showFeedbackModal}
				hide={hideFeedbackModal}
				reviewSentimentId={reviewSentimentIdForFeedback}
				onAccept={sendFeedback}
			/>
			{/* Filters */}
			<MDBRow size='12'>
				<MDBCol size='12'>
					<Filters
						title={t("title")}
						schemas={schemas}
						values={filterValues}
						onChange={(newValues) => {
							if (!_.isEqual(newValues, filterValues)) {
								setFilterValues(newValues)
							}
						}}
						showInfo
					/>
				</MDBCol>
			</MDBRow>
			{/* Last 6 months evolution */}
			<MDBRow size='12'>
				<MDBCol size='12'>
					{(statisticsByMonthSummary && statisticsByMonthSummary.length > 0) ?
						<>
							<h4 className='principal-title'>
								{t('periodEvolutionSection.title')}
							</h4>
							<MDBCard className='mb-4'>
								<MDBCardBody className='card-content'>
									<SectionTitle
										help={[t('periodEvolutionSection.evolutionChart.help1'), t('periodEvolutionSection.evolutionChart.help2')]}
										title={t('periodEvolutionSection.evolutionChart.title')}
										name={'sentiment-period-summary'}
									/>
									<SentimentMonthEvolutionGraph
										onMonthSelected={onMonthSelected}
										statisticsByMonth={statisticsByMonthSummary}
									/>
									{lastUpdateTime && <>
										<div className="last-update-box">{t('periodEvolutionSection.lastUpdateTime')}
											<Moment
												fromNow
												locale={navigator.language.toLocaleLowerCase()}
											>{lastUpdateTime}</Moment>
										</div>
									</>}
								</MDBCardBody>
							</MDBCard>
						</>
						: !firstLoad && <div className="empty-state" style={{ height: 300 }}>
							<h1>{t("periodEvolutionSection.emptyState")}</h1>
						</div>
					}
				</MDBCol>
			</MDBRow>
			{/* Keywords analysis */}
			<MDBRow>
				<MDBCol size='12'>
					{statisticsByMonth && <>
						<h4 className='principal-title'>
							{t('keywordAnalysisSection.title')}&nbsp;-&nbsp;{t(`months.${selectedMonth}`)}&nbsp;{selectedYear}
						</h4>
						<MDBRow>
							<MDBCol size='12' xl='4'>
								<MDBRow>
									<MDBCol size='12' md='5' xl='12'>
										<MDBCard className='mb-4 fixed-height-350'>
											<MDBCardBody className='card-content'>
												<KeywordsSummary
													sentimentsByKeyword={statisticsByMonth.sentimentsByKeyword}
													changeKeywordFilter={changeKeywordFilter}
													selectedKeywords={filterValues.keywords}
												/>
												<div className='gradient-help-container'>
													<GradientHelper
														leftText={t('sentimentColorHelp.negative')}
														rightText={t('sentimentColorHelp.positive')}
													/>
												</div>
												<div className='bottom-comment-fixed'>
													<span>
														{t('configureKeywords.main')}&nbsp;
													</span>
													<span onClick={() => history.push('/settings/sentiments/keywords')} className="keyword-help-link">
														{t('configureKeywords.link')}&nbsp;
													</span>
													<span onClick={() => history.push('/settings/sentiments/keywords')} className="keyword-help-link">
														<FontAwesomeIcon icon={keywordConfig} size='1x' />
													</span>
												</div>
											</MDBCardBody>
										</MDBCard>
									</MDBCol>
									<MDBCol size='12' md='7' xl='12'>
										<MDBCard className='mb-4 fixed-height-350'>
											<MDBCardBody className='card-content'>
												<KeywordsDistributionByComments
													sentimentsByKeyword={statisticsByMonth.sentimentsByKeyword}
													selectedKeywords={filterValues.keywords}
												/>
											</MDBCardBody>
										</MDBCard>
									</MDBCol>
								</MDBRow>
							</MDBCol>
							{/* <MDBCol size='12' xl='5' lg='4' md='6' sm='6'> */}
							<MDBCol size='12 'xl='8'>
								<MDBCard className='mb-4 fixed-height-725'>
									<MDBCardBody className='card-content'>
										<KeywordsDistributionByStores
											sentimentsByStore={statisticsByMonth.sentimentsByStore}
										/>
									</MDBCardBody>
								</MDBCard>
							</MDBCol>
							{/* { selectedKeyword && statisticsByMonth && <MDBCol size='12' lg='4' md='4' sm='6'>
								<MDBCard className='mb-4'>
									<MDBCardBody className='card-content'>
										<KeywordStatistics
											keyword={selectedKeyword}
											sentimentsByKeyword={statisticsByMonth.sentimentsByKeyword}
										/>
									</MDBCardBody>
								</MDBCard>
							</MDBCol>} */}
							{/* {isMOTU && filterValues.companies[0] === 1016 && <MDBCol size='12' lg='12' md='12' sm='12'>
								<MDBCard className='mb-4'>
									<MDBCardBody className='card-content'>
										<WordCloud 
											words={statisticsByMonth.wordOcurrences}
										/>
									</MDBCardBody>
								</MDBCard>
							</MDBCol>} */}
						</MDBRow>
					</>
					}
				</MDBCol>
			</MDBRow>
			{/* daily statistics */}
			{selectedYear && selectedMonth && statisticsByMonth && <MDBRow size='12'>
				<MDBCol size='12' className='selected-keyword-analysis'>
					<h4 className='principal-title'>
						{t('dailyStatisticsSection.title')}&nbsp;-&nbsp;{t(`months.${selectedMonth}`)}&nbsp;{selectedYear}
					</h4>
				</MDBCol>
				<MDBCol size='12' lg='4'>
					<MDBCard className='mb-4'>
						<MDBCardBody className='card-content fixed-height-400'>
							<SentimentMonthCalendarGraph
								onDaySelected={onDaySelected}
								month={selectedMonth}
								year={selectedYear}
								monthStatistics={statisticsByMonth.statisticsByDay}
							/>
						</MDBCardBody>
					</MDBCard>
				</MDBCol>
				<MDBCol size='12' lg='8'>
					<div className="sentiments-by-day">
						<SentimentDayDetail
							sentimentsByDay={statisticsByDay}
							dayStats={selectedDayStats}
							onSendFeedback={onSendFeedbackClicked}
							sendFeedback={sendFeedback}
							activeKeywords={keywords}
						/>
					</div>
				</MDBCol>
			</MDBRow>
			}

			<br />
			<br />
			<br />

		</MDBContainer >
	);
};

SentimentContainer.translations = {
	...translations,
	...monthTranslations
};

export default translate(SentimentContainer);
