import React from 'react';
import Chart from "react-google-charts";
import DateRangePicker from '../form/DateRangePicker';
import Loading from '../Loading';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import './lineChart.css'

let locale = "en";

if (typeof window !== 'undefined') {
	  locale = require('browser-locale')()
}

const options = {
	language:locale,
    animation: {
        startup: true,
        easing: 'in'
    },
    series: {
        0: { color: '#169881' },
        1: { color: '#1ab394' },
        2: { color: '#23c6c8' },
        3: { color: '#1872ab' },
        4: { color: '#676a6c' },
        5: { color: '#f7ac59' },
        6: { color: '#f69d3c' },
        7: { color: '#ed5565' },
        8: { color: '#ea394c' },
        9: { color: '#1eacae' },
    },
	// pointSize: 4,
    'width':'100%',
	'height':200,
	hAxis: {
		textStyle: {
			fontName: "open sans"
		}
	},
	vAxis: {
		textStyle: {
			fontName: "open sans"
		},
		viewWindow : {
			mode: 'pretty'
		}
	},
	legend: {
        position: 'top',
		textStyle: {
			fontName: "open sans"
		}
	}
};

const moment = require('moment');
const thisMonth = {
    dateFrom: moment().startOf('month').toDate(),
    dateTo: moment().endOf('month').toDate()
};


class LineChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        	loading:true 
		}
		this.options = {...options};
		if (props.percentage){
			this.options.vAxis.viewWindow = {
				max:100,
				min:0
			}
			this.options.pointSize=0;
		}
	}

	componentDidMount(){
		new Promise(resolve => {
			resolve(this.calculateChartData());
		})
			.then(
				(data) => {
					this.setState({data:data, loading:false});
					
				}
			);
	}

    calculateChartData = () => {
		var titles = [];
		this.props.vAxis.forEach((f_h)=>{
            titles.push(f_h.label);
			if (f_h.tooltip){
				titles.push({ role: "tooltip", type: "string", p: { html: true } });
			}
        });
        titles.unshift(this.props.hAxis.label);
        var chartData =
            this.props.source.map((x, i) => {
				var pointArray = [];
				
				this.props.vAxis.forEach((f_h)=>{
					var calc_result = f_h.calc(x);
					pointArray.push(calc_result);
					if (f_h.tooltip){
						pointArray.push(f_h.tooltip(x, calc_result));
					}
				});

                pointArray.unshift(this.props.hAxis.calc(x));
                return pointArray;
            });
        chartData.unshift(titles);
        return chartData
    }

    render = () => {
        if(this.props.source===undefined || this.props.source===[]){
            return (<Loading loading={this.props.source===undefined}/>);
        }

        return (
            <React.Fragment>
                {
                    this.props.dateRangeFilterChangeHandler !== undefined &&
                    (<Row>
                        <Col lg={12}>
                            <DateRangePicker
                                onSelect={this.props.dateRangeFilterChangeHandler}
                                initDate={this.props.initDate} />
                        </Col>
                    </Row>)
                }
            	<Loading loading={this.state.loading}/>
				{
					this.state.data &&
						(
							<Row>
								<Col lg={12}>
									<Chart
										chartLanguage={require('browser-locale')()}
										className='animated fadeInRight'
										chartType="LineChart"
										data={this.state.data}
										options={this.options}
									/>
								</Col>
							</Row>
						)
				}
            </React.Fragment>
        );
    }
}

LineChart.defaultProps = {
    initDate: thisMonth
};


LineChart.propTypes = {
    hAxis: PropTypes.shape({
        label: PropTypes.string,
        calc: PropTypes.func.isRequired
    }).isRequired,
    source:  PropTypes.arrayOf(PropTypes.object),
    vAxis: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            calc: PropTypes.func
        })
    ).isRequired,
    dateRangeFilterChangeHandler: PropTypes.func,
    initDate: PropTypes.shape({
        dateFrom: PropTypes.object,
        dateTo: PropTypes.object
    }),
    miniature: PropTypes.bool
}

export default (LineChart)
