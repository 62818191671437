export const translations = {
	title: {
		es: "Análisis de sentimientos",
		en: "Sentiment analysis"
	},
	sentimentColorHelp: {
		positive: {
			es: "Positiva",
			en: "Positive"
		},
		negative: {
			es: "Negativa",
			en: "Negative"
		}
	},
	in: {
		es: "en",
		en: "in"
	},
	noData: {
		es: "Sin datos",
		en: "No data"
	},
	configureKeywords: {
		main: {
			es: "Puedes configurar tus categorías",
			en: "You can configure your topics"
		},
		link: {
			es: 'aquí',
			en: 'here'
		}
	},
	filters: {
		socialSource: {
			es: "Fuentes",
			en: "Sources"
		},
		company: {
			es: "Empresa",
			en: "Company",
		},
		allCompanies: {
			es: "Todas las empresas",
			en: "All companies"
		},
		store: {
			es: "Tienda",
			en: "Store",
		},
		allStores: {
			es: "Todas las tiendas",
			en: "All stores"
		},
		keywords: {
			es: "Categorías",
			en: "Topics",
		},
		timePeriod: {
			es: "Período",
			en: "Period",
		}
	},
	periodEvolutionSection: {
		title: {
			es: 'Menciones positivas vs. negativas',
			en: 'Positive vs. negative mentions'
		},
		lastUpdateTime: {
			es: "Actualizado por última vez ",
			en: "Last updated "
		},
		filterRate: {
			title: {
				en: 'Show rate',
				es: 'Mostrar índice'
			},
			positive: {
				en: 'Positivity',
				es: 'Positividad'
			},
			negative: {
				en: 'Negativity',
				es: 'Negatividad'
			}
		},
		evolutionChart: {
			title: {
				es: 'Evolución en los últimos 6 meses',
				en: 'Evolution in last six months'
			},
			help1: {
				es: 'Muestra para cada mes la cantidad y el porcentaje de comentarios con texto evaluados como positivos (verde) y negativos (rojo).',
				en: 'Shows for each month the number and percentage of comments with text evaluated as positive (green) and negative (red).'
			},
			help2: {
				es: 'Este gráfico excluye los comentarios clasificados como neutros, es decir aquellos que nuestra IA no pudo clasificar con certeza como positivo o negativo.',
				en: 'This graph excludes comments classified as neutral, that is, those that our AI could not classify with certainty as positive or negative.'
			},
			positiveReviews: {
				es: 'Positivas',
				en: 'Positive'
			},
			negativeReviews: {
				es: 'Negativas',
				en: 'Negative'
			},
			netSentiment: {
				es: 'Sentimiento neto',
				en: 'Net sentiment'
			},
			negativityRate: {
				es: 'Índice de negatividad',
				en: 'Negativity rate'
			},
			positivityRate: {
				es: 'Índice de positividad',
				en: 'Positivity rate'
			}
		},
		emptyState: {
			es: 'No hay datos con los filtros seleccionados',
			en: 'No data with the selected filter'
		}
	},
	keywordAnalysisSection: {
		title: {
			es: 'Análisis por categorías',
			en: 'Topic analysis'
		},
		keywordSummary: {
			title: {
				es: 'Valoración por categorías',
				en: 'Topic valuation'
			},
			help1: {
				es: 'Muestra por cada categoría el porcentaje de valoraciones positivas respecto al total (excluyendo las neutrales).',
				en: 'Shows for each topic the percentage of positive reviews compared to the total (excluding neutral ones).'
			},
			help2: {
				es: 'Si por ejemplo la categoría X fue valuada en 5 comentarios como positiva, en 10 como negativa y en 8 como neutral, el porcentaje será: ',
				en: 'If, for example, topic X was rated in 5 comments as positive, in 10 as negative and in 8 as neutral, the percentage will be:'
			},
			help3: {
				es: '(5 / 15) * 100 = 33.33%',
				en: '(5 / 15) * 100 = 33.33%'
			}
		},
		keywordStatistics: {
			title: {
				es: 'Estadísticas de categorías',
				en: 'Topic statistics'
			},
			comments: {
				title: {
					en: 'Total comments',
					es: 'Comentarios totales'
				},
				help1: {
					en: 'Shows the number of comments where the tapic was evaluated.',
					es: 'Muestra la cantidad de comentarios donde la categoría fue evaluada.'
				}
			},
			ratedComments: {
				title: {
					en: 'Rated comments',
					es: 'Comentarios con valoración'
				},
				help1: {
					en: 'Shows the number of comments where the topiic was evaluated positively or negatively.',
					es: 'Muestra la cantidad de comentarios donde la categoría fue evaluada positiva o negativa.'
				}
			},
			positiveComments: {
				title: {
					en: 'Positive comments',
					es: 'Comentarios positivos'
				},
				help1: {
					en: 'Shows the number of comments where the topic was evaluated positively.',
					es: 'Muestra la cantidad de comentarios donde la categoría fue evaluada positiva.'
				}
			},
			negativeComments: {
				title: {
					en: 'Negative comments',
					es: 'Comentarios negativos '
				},
				help1: {
					en: 'Shows the number of comments where the topic was evaluated negatively.',
					es: 'Muestra la cantidad de comentarios donde la categoría fue evaluada negativa.'
				}
			},
			ratedWeight: {
				title: {
					en: 'Total weight',
					es: 'Peso total'
				},
				help1: {
					es: 'Muestra el porcentaje de evaluaciones positivas y negativas que representa esta categoría vs. el resto de las categorías.',
					en: 'Shows the percentage of positive and negative evaluations that this topic represents vs. the rest of the topics.'
				}
			},
			positiveWeight: {
				title: {
					en: 'Positive weight',
					es: 'Peso relativo'
				},
				help1: {
					es: 'Muestra el porcentaje de evaluaciones positivas que representa esta categoría vs. el resto de categorías.',
					en: 'Shows the percentage of positive evaluations that this topic represents vs. the rest of the topics.'
				}
			},
			negativeWeight: {
				title: {
					en: 'Negative weight',
					es: 'Peso negativo'
				},
				help1: {
					es: 'Muestra el porcentaje de evaluaciones negativas que representa esta categoría vs. el resto de categorías.',
					en: 'Shows the percentage of negative evaluations that this topic represents vs. the rest of the topics.'
				}
			},
			connector: {
				en: 'of',
				es: 'de'
			}
		},
		distributionByComments: {
			title: {
				es: 'Participación de categorías mencionadas',
				en: 'Distribution of topic mentions'
			},
			help1: {
				es: 'Muestra la cantidad de veces que cada categoría fue valuada positiva o negativa en un comentario.',
				en: 'Shows the number of times each topic was rated positively or negatively in a comment.'
			},
			all: {
				es: 'Todos',
				en: 'All'
			},
			positive: {
				es: 'Positivos',
				en: 'Positive'
			},
			negative: {
				es: 'Negativos',
				en: 'Negative'
			}
		},
		distributionByStore: {
			title: {
				es: 'Tabla de posiciones',
				en: 'Location ranking'
			},
			help1: {
				es: 'Muestra la cantidad de veces que cada categoría fue valuada positiva o negativa en una tienda.',
				en: 'Shows the number of times each topic was rated positively or negatively in a store.'
			},
			help2: {
				es: 'Las valuaciones neutrales estan excluídas de este gráfico.',
				en: 'Neutral valuations are excluded from this graph.'
			},
			sortBy: {
				label: {
					es: 'Ordenar por',
					en: 'Sort by'
				},
				positivityRate: {
					es: 'Porcentaje de positivas',
					en: 'Positivity rate'
				},
				totalMentions: {
					es: 'Cantidad de menciones positivas y negativas',
					en: 'Total positive and negative mentions'
				}
			},
			graph: {
				stores: {
					es: 'Tiendas',
					en: 'Stores'
				},
				positive: {
					es: 'Positivas',
					en: 'Positive'
				},
				positivePercentage: {
					es: 'Porcengaje de positivas',
					en: 'Positive rate'
				},
				positivePercentagePrevMonth: {
					es: 'Porcengaje de positivas mes anterior',
					en: 'Positive rate previous month'
				},
				negative: {
					es: 'Negativas',
					en: 'Negative'
				},
				positivePrevMonth: {
					es: 'Positivas mes anterior',
					en: 'Positive previous month'
				},
				negativePrevMonth: {
					es: 'Negativas mes anterior',
					en: 'Negative previous month'
				}
			}
		},
		wordCloud: {
			title: {
				en: 'Word cloud',
				es: 'Nube de palabras'
			},
			help1: {
				es: 'Muestra las palabras mas frecuentes usadas por los clientes en los comentarios.',
				en: 'Shows the most frequent words used by customers in the comments.'
			}
		}
	},
	dailyStatisticsSection: {
		title: {
			es: 'Estadísticas diarias',
			en: 'Daily statistics'
		},
		calendar: {
			title: {
				es: 'Evaluación diaria',
				en: 'Daily evaluation'
			},
			help1: {
				es: 'Muestra como fue evaluado tu negocio cada día del mes, utilizando un gradiente donde el color verde indica buena valoración y el rojo mala',
				en: 'Show how your business was evaluated each day of the month, using a gradient where green indicates a good rating and red indicates a bad rating.'
			}
		},
		clickOnDayHelp: {
			es: 'Clickeá en un día para ver los detalles',
			en: 'Click on a day to view details'
		},
		dayDetail: {
			title: {
				en: 'Statistics for',
				es: 'Estadísticias para'
			},
			help1: {
				en: 'Shows statistics for the selected day and customer comments',
				es: 'Muestra las estadísticas para el día seleccionado y los comentarios de los clientes'
			},
			positiveMentions: {
				es: 'Menciones positivas',
				en: 'Positive mentions'
			},
			neutralMentions: {
				es: 'Menciones neutrales',
				en: 'Neutral mentions'
			},
			negativeMentions: {
				es: 'Menciones negativas',
				en: 'Negative mentions'
			},
			totalMentions: {
				es: 'Menciones totales',
				en: 'Total mentions'
			},
			positive: {
				es: 'Positiva',
				en: 'Positive'
			},
			negative: {
				es: 'Negativa',
				en: 'Negative'
			},
			neutral: {
				es: 'Neutral',
				en: 'Neutral'
			},
			feedback: {
				askForFeedback: {
					es: '¿Cómo evalúas este análisis?',
					en: 'How do you evaluate this analysis?'
				},
				somethingWrongTellUsMore: {
					es: 'Por favor, envíanos tus comentarios de por qué este análisis no es correcto',
					en: 'Please, send us your comments and tell us why this analysis is wrong'
				},
				sendFeedback: {
					title: {
						es: 'Enviar comentarios',
						en: 'Send feedback'
					},
					cancel: {
						es: 'Cancelar',
						en: 'Cancel'
					},
					send: {
						es: 'Enviar',
						en: 'Send'
					}
				}
			}
		}
	}
}