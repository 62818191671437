import { Formik } from "formik";
import React from "react";
import translate from "../../config/translations/translate.js";
import { updateValidationSchema } from "./validations.js";
import { updateFormTtranslations } from "./translations.js";
import { SocialLocationPostCallToActionType, SocialLocationPostTopicType } from "./SocialLocationPostCallToActionType.js";
import { FormikPostImageField } from "./ImageField.js";
import { Select, Text, TextArea } from "./Inputs.js";
import { showCallToActionUrl } from "./showCallToActionUrl.js";

const actionTypes = Object.values(SocialLocationPostCallToActionType);

export const SocialLocationPostUpdateForm = ({
  t,
  socialLocationPost,
  onSubmit,
  images,
  formRef,
}) => {

  const validationSchema = updateValidationSchema(t);

  return (
    <div data-testid="SocialLocationPostUpdateForm">
      <Formik
        ref={formRef}
        initialValues={{ ...socialLocationPost, topicType: SocialLocationPostTopicType.STANDARD }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ errors, handleSubmit, values }) => {
          return (
            <form
              data-testid="SocialLocationPostUpdateFormForm"
              onSubmit={handleSubmit}
            >
              <h4>{t("panels.socialLocationPost.form.titles.addImage")}</h4>
              <div className="row">
                <div className="col-12 col-md-4">
                  <FormikPostImageField
                    label={t(
                      "panels.socialLocationPost.form.label.media.sourceUrl"
                    )}
                    images={images}
                    errors={errors.media}
                    t={t}
                  />
                </div>
                <div className="col-12 col-md-8">
                  <TextArea
                    name="summary"
                    label={t("panels.socialLocationPost.form.label.summary")}
                    errors={errors.summary}
                  />
                  <Select
                    errors={
                      errors.callToAction && errors.callToAction.actionType
                    }
                    name="callToAction.actionType"
                    label={t(
                      "panels.socialLocationPost.form.label.callToAction.actionType"
                    )}
                    options={actionTypes.map((o) => ({
                      text: t(o),
                      value: o,
                    }))}
                    className="w-50 ml-auto"
                  />
                  {showCallToActionUrl(values) && (
                      <>
                        <Text
                          info={t(`utmInfo`)}
                          name="callToAction.url"
                          label={t(
                            "panels.socialLocationPost.form.label.callToAction.url"
                          )}
                          errors={
                            errors.callToAction && errors.callToAction.url
                          }
                          prefix={'https://'}
                        />
                      </>
                    )}
                </div>
                
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

SocialLocationPostUpdateForm.translations = updateFormTtranslations;

export default translate(SocialLocationPostUpdateForm);


