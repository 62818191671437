import React, { useState } from 'react';

import Chart from 'react-google-charts';
import translate from '../../../config/translations/translate';
import { translations } from '../../../config/translations/Feedback/SentimentContainer';
import { translations as monthTranslations } from '../../../config/translations/Months';
import { negativeMentionColor, positiveMentionColor } from './sentimentUtils';
import { NewLoader } from '../../NewLoader/NewLoader';
import { MDBInput } from 'mdbreact';

const SentimentMonthEvolutionGraph = ({ t, statisticsByMonth, onMonthSelected }) => {

	const [showPositiveRate, setShowPositiveRate] = useState(true)


	const formatData = () => {
		if (!statisticsByMonth)
			return [];
		const dataArr = [[
			"Period",
			t("periodEvolutionSection.evolutionChart.positiveReviews"),
			{ role: "annotation" },
			t("periodEvolutionSection.evolutionChart.negativeReviews"),
			{ role: "annotation" },
			showPositiveRate ?
				t("periodEvolutionSection.evolutionChart.positivityRate") :
				t("periodEvolutionSection.evolutionChart.negativityRate"),
			{ type: 'string', role: 'tooltip' }
		]]
		statisticsByMonth.forEach(row => {
			let positiveLabel = ''
			let negativeLabel = ''
			const total = row.totalNegativeReviews + row.totalPositiveReviews
			if (total > 0) {
				const positivePercent = Math.round(row.totalPositiveReviews * 100 / total)
				const negativePercent = Math.round(row.totalNegativeReviews * 100 / total)
				positiveLabel = `${row.totalPositiveReviews} (${positivePercent}%)`
				negativeLabel = `${row.totalNegativeReviews} (${negativePercent}%)`
			}
			dataArr.push([
				t(`shortMonths.${row.month}`) + ' ' + row.year,
				row.totalPositiveReviews,
				positiveLabel,
				row.totalNegativeReviews,
				negativeLabel,
				showPositiveRate ?
					(row.positiveWithoutNeutralPercentage && (row.positiveWithoutNeutralPercentage / 100)) :
					(row.negativeWithoutNeutralPercentage && (row.negativeWithoutNeutralPercentage / 100)),
				showPositiveRate ? 
					`${Math.round(row.positiveWithoutNeutralPercentage)}%` :
					`${Math.round(row.negativeWithoutNeutralPercentage)}%`
				
			])
		});

		return dataArr;
	}

	return <div>
		<div className="d-flex align-items-center">
			<div>{t('periodEvolutionSection.filterRate.title')}</div>
			<MDBInput
				type="radio"
				id='positive'
				name='posivite'
				label={t('periodEvolutionSection.filterRate.positive')}
				checked={showPositiveRate}
				onClick={() => setShowPositiveRate(true)}
				labelClass='fontSize-12px'
			/>
			<MDBInput
				type="radio"
				id='negative'
				name='negative'
				label={t('periodEvolutionSection.filterRate.negative')}
				checked={!showPositiveRate}
				onClick={() => setShowPositiveRate(false)}
				labelClass='fontSize-12px'
			/>
		</div>
		<Chart
			width="100%"
			height="400px"
			chartType="ComboChart"
			data={formatData()}
			loader={<NewLoader className="loader" />}
			options={{
				focusTarget: 'category',
				hAxis: {
					textStyle: {
						italic: true,
						fontSize: 12
					}
				},
				vAxes: {
					0: {
						gridlines: {
							color: "transparent"
						},
						format: "short",
						textStyle: {
							italic: true,
							fontSize: 12
						},
					},
					1: {
						gridlines: {
							color: "transparent"
						},
						format: 'percent',
						textStyle: {
							italic: true,
							fontSize: 12
						},
						minValue: 0,
						maxValue: 1
					}
				},
				series: {
					0: {
						type: "bars",
						targetAxisIndex: 0,
						color: positiveMentionColor,
						annotations: {
							textStyle: { fontSize: 10 }
						}
					},
					1: {
						type: "bars",
						targetAxisIndex: 0,
						color: negativeMentionColor,
						annotations: {
							textStyle: { fontSize: 10 }
						}
					},
					2: {
						type: 'line',
						targetAxisIndex: 1,
						pointShape: 'circle',
						color: "000000",
						lineWidth: 1
					}
				},
				displayAnnotations: true,
				isStacked: true, // muestra las barras apiladas en lugar de una al lado de la otra
				pointSize: 5,
				// title: t('chartTitle'),
				// titleTextStyle: {
				// 	fontSize: 18
				// },
				legend: {
					position: 'top',
					alignment: 'center',
					textStyle: {
						color: 'black',
						fontSize: 12,
						italic: false
					}
				},
				chartArea: {
					width: '85%',
					height: '75%'
				},
				tooltip: {
					textStyle: { // Personaliza el estilo del tooltip
						fontSize: 12,
					}
				},
			}} // end options
			formatters={[
				{
					type: 'ArrowFormat',
					column: 2,
					options: {
						pattern: '0.00%',
					},
				},
			]}
			chartEvents={[
				{
					eventName: "select",
					callback: ({ chartWrapper }) => {
						const row = chartWrapper.getChart().getSelection()[0]
						// if there's no data on selection do nothing
						if (!row || (!row.row && row.row !== 0)) {
							return;
						}
						const month = statisticsByMonth[row.row]
						if (month.totalPositiveReviews + month.totalNegativeReviews > 0) {
							onMonthSelected(month.year, month.month)
						}
					}
				},
			]}
		/>
	</div>
}

SentimentMonthEvolutionGraph.translations = {
	...translations,
	...monthTranslations
};

export default translate(SentimentMonthEvolutionGraph);
