export const SocialAccountStatus = {
    PENDING: { value: 'PENDING', label: 'socialAccounts.pending', color: 'yellow' },
    CONNECTED: { value: 'CONNECTED', label: 'socialAccounts.connected', color: 'green' },
    ERROR: { value: 'ERROR', label: 'socialAccounts.error', color: 'red' }
}

export const getSocialAccountStatus = (value) => {
    switch (value) {
        case 'PENDING': return SocialAccountStatus.PENDING
        case 'CONNECTED': return SocialAccountStatus.CONNECTED
        case 'ERROR': return SocialAccountStatus.ERROR
        default: return null
    }
}
