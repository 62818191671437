import { useSelector } from "react-redux";
import { getAllStoresAdministratorsWithOperation } from "../../../api/administratorApi";

const { useState, useEffect } = require("react");

const useCompanyAdministratos = () => {

    const selectedCompany = useSelector(state => state.application.selectedCompany.id);
    const availableStores = useSelector(state => state.application.availableStores);

    const [administrators, setAdministrators] = useState();
    useEffect(()=>{
        if(!administrators)
        getAllStoresAdministratorsWithOperation(availableStores.filter(s => s.companyId === selectedCompany).map(s => s.id), "CONVERSATION__REPLY")
        .then(
                res => {
                    setAdministrators(res.data.data);
                }
            );
    },[])

    return administrators || [];
}

export default useCompanyAdministratos;