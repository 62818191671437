import React from 'react';
import { Modal, ModalBody } from "botbit-ui-components";
import { MDBIcon, MDBBtn } from "mdbreact";
import { useBreakpoint } from "../../../commons/utils/useWindowDimensions";
import { translations } from "../../../config/translations/integrations/DocsModal";
import translate from "../../../config/translations/translate";

import "./DocsModal.scss"
import DocsContainer from './DocsContainer';
import { sourcesTranslations } from '../../../config/translations/integrations/Sources';

const DocsModal = ({ toggle, isOpen, onAdd, source, t }) => {
  const isMobile = useBreakpoint(499)

  return (
    <Modal toggle={toggle} className="documentation-modal" size="lg" centered isOpen={isOpen} >
      <ModalBody>
        <div className="d-flex flex-row align-items-center">
          <MDBIcon
            className="back-button"
            onClick={toggle}
            icon="arrow-left"
          />
          <h4 style={{ marginLeft: "15px", fontSize: "1.1rem" }}>
            {t("title", {sourceName: t(`sources.${source.name}.name`)})}
          </h4>
        </div>
        <DocsContainer height={isMobile ? "75vh" : "65vh"} noBorder source={source} />
        <MDBBtn onClick={onAdd} color="primary">{t("connect")}</MDBBtn>
      </ModalBody>
    </Modal>
  );
}

DocsModal.translations = {
  ...translations,
  ...sourcesTranslations
}

export default translate(DocsModal);
