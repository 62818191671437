import React from 'react';
import { Formik, Field, Form } from 'formik';
import { MDBSelect, MDBInput, MDBDatePicker, MDBTimePicker, MDBBtn } from "mdbreact";
import MDBStoreSelector from "../../commons/components/utils/form/StoreSelector.js";
import { getStoreAdminitrators } from "../../api/administratorApi.js";
import { getAllChannels, getTaskTypes } from "../../api/taskApi.js";
import * as Yup from 'yup';
import { UserPill } from '../../commons/components/users/details/UserCard.js';
import { translate } from 'react-i18next';
import moment from 'moment';
import './tasks.css'
import IBox from '../../commons/components/inspinia/IBox.js';
import TimeAgo from '../../commons/components/utils/TimeAgo.js';



class DateTimePicker extends React.Component {
	
	state={}

	constructor(props){
		super(props);
		this.state.value = props.value;
	}

	shouldComponentUpdate(nextProps, nextState){
		return this.props.value !== nextProps.value;
	}

	componentDidUpdate(prevProps, prevState, snapshot){
	}
	
	updateTime = 	(value) => {
	let dateValue = this.props.value ? new Date(this.props.value) : undefined;
		if (!dateValue) dateValue = new Date(); 
		const split = value.split(":");
		dateValue.setHours(split[0]);
		dateValue.setMinutes(split[1]);
		this.props.getValue(dateValue)
	}	

	render () {
		const value = this.props.value ? new Date(this.props.value) : undefined;
		let timeValue;
		let dateValue;
		if (value){
			timeValue = value.getHours()+':'+value.getMinutes();
			dateValue = value;	
		}
		return (<>
				<MDBDatePicker
					onBlur={this.props.onBlur}
					id="datetime-local"
					className="botbit-mdb-datepicker botbit-form-input form-control border-0 p-0 bg-transparent my-3"
					label={this.props.label}
					type="date-local"
					value={dateValue}
					getValue={this.props.getValue}
					locale={moment.locale("ar")}
				/>
				<div 	className="botbit-mdb-datepicker botbit-form-input form-control border-0 p-0 bg-transparent my-3">
				<MDBTimePicker 
					id="timePicker" 
					label="Hora"
					onBlur={this.props.onBlur}
					hours={timeValue ? timeValue.split(":")[0]: undefined}
					minutes={timeValue ? timeValue.split(":")[1]: undefined}
					hoursFormat={24}
					getValue={this.updateTime}
				/>
				</div>
		</>);

	}
}

export default class TaskForm extends React.Component {


	async setInitial(storeId){
		let response = await getStoreAdminitrators(storeId);
		return {
			adminFromOptions : response.data.data.map(
				admin => {
					return {
						value:`${admin.id}`,
						text:`${admin.name} ${admin.lastName}`
					}
				}
			)
		}

	} 

	// shouldComponentUpdate(nextProps, nextState){
	// 	// return nextProps.task !== this.props.task;
	// }

	render(){
		const notesHistory = this.props.task.notesHistory;
		const lastNote = notesHistory && notesHistory.findLast(() => true);
		return (
			<div>
				<Formik 
					ref={this.formRef} 
					initialValues={{dueDate: Date.now(), ...this.props.task}} 
					onSubmit={this.props.onSubmit}
					validationSchema={Yup.object().shape({
						storeId: Yup.string().required('La tienda es requerida.'),
						channel: Yup.number().required('El canal es requerido.'),
						type: Yup.number().required('El tipo es requerido.'),
						adminTo: Yup.object().shape({
							id: Yup.number().required('Asigne la tarea.')
						}),
						dueDate: Yup.number().required('La fecha de vencimiento es requerida'),
					})}>
						{({ errors, touched, isSubmitting }) => (
							<Form>
								<div className="container">
									<div className="row">
										<div className="col pt-3">
												<UserPill 
													user={this.props.task.user}
													storeId={
														this.props.selectedStore ? this.props.selectedStore.id : undefined
													}
												/>
										</div>
										<div className="col">
											<span className="text-right">
											<Field
												name="status.id"
												render={
													(props) => (
														<MDBTaskStatusButtonTranslated
															status={props.field.value}
															getValue={ (value) => { 
																this.props.onSubmit(
																	{
																		...props.form.values,
																		status: {
																			id: value
																		}
																	}
																)
															}}
														/>
													)
												}
											/>
											</span>
										</div>
									</div>
									{
										this.props.stores &&
											<div className="row">
												<div className="col">
													<Field name="storeId"
														render={
															(props) => (
																<>
																	<MDBStoreSelector 
																		stores={this.props.stores}
																		selectedStore={this.props.selectedStore}
																		getValue={ 
																			(values) => {
																				if (props.field.value !== values[0])
																					props.form.setFieldValue('storeId', values[0]) 
																			}
																		}
																	/>
																	{ props.form.errors.storeId && 
																			( props.form.touched.storeId || props.form.submitCount > 0 ) && 
																				<Error message={errors.storeId} />
																	}
																</>
															)
														}
													/>
												</div>
											</div>
									}
									<div className="row">
										<div className="col-12 col-lg-6">
											<Field
												name="type"
												render={(props) => {
													return (
														<>
															<TaskTypeSelector
																onBlur={()=>  props.form.setFieldTouched('type')}
																selectedStore={props.form.values.storeId}
																value={props.field.value}
																getValue={ 
																	(values) => { 
																		if (props.field.value !== values[0]) props.form.setFieldValue('type', values[0]) 
																	}
																}
															/>
															{ props.form.errors.type && 
																	( props.form.touched.type || props.form.submitCount > 0 ) && 
																		<Error message={ errors.type } />
															}
														</>
													);
												}}
											/>

										</div>

										<div className="col-12 col-lg-6">
											<Field
												name="channel"
												render={(props) => {
													return (
														<>
														<MDBChannelSelector
															value={props.field.value}
															onBlur={()=>  props.form.setFieldTouched('channel')}
															getValue={ 
																(value) => { 
																	// props.form.setFieldTouched('channel');
																	props.form.setFieldValue('channel', value);
																}
															}/>
															{ props.form.errors.channel && 
															 ( props.form.touched.channel || props.form.submitCount > 0 ) && 
																<Error message={props.form.errors.channel} />}

														</>
													);
												}}
											/>

										</div>
									</div>
									<div className="row">
										<div className="col-12 col-lg-6">
											<Field	name="adminTo.id">
												{(props) => {
													return (
														<>
															<MDBAdminSelector
																onBlur={()=>  props.form.setFieldTouched('adminTo')}
																selectedStore={props.form.values.storeId}
																value={props.field.value}
																getValue={ (values) => { if (props.field.value !== values[0]) props.form.setFieldValue('adminTo.id', values[0]) }}
															/>
															{ props.form.errors.adminTo && props.form.errors.adminTo.id && 
																	( props.form.touched.adminTo || props.form.submitCount > 0 ) && 
																		<Error message={ errors.adminTo.id } />
															}
														</>
													);
												}}
											</Field>
										</div>
										<div className="col-12 col-lg-6">
											<Field
												name="dueDate"
												render={(props) => {
													return (
														<>
															<DateTimePicker
																onBlur={()=>  props.form.setFieldTouched('dueDate')}
																id="datetime-local"
																className="botbit-mdb-datepicker botbit-form-input form-control border-0 p-0 bg-transparent my-3"
																label="Fecha de vencimiento"
																type="date-local"
																value={props.field.value}
																getValue={ 
																	(date) => { 
																		props.form.setFieldValue('dueDate', date.getTime())
																	}
																}
																locale={moment.locale("ar")}
															/>
															{ props.form.errors.dueDate && 
																	( props.form.touched.dueDate || props.form.submitCount > 0 ) && 
																		<Error message={errors.dueDate} />
															}
														</>
													);
												}}
											/>

										</div>
									</div>

									<div className="row">
										{
											lastNote &&
											<div className='col-12'>
												<IBox hideClose={true} collapsed={true} title={
													<div>
														{lastNote.administrator && lastNote.administrator.fullName} <TimeAgo date={lastNote.timestamp}></TimeAgo> :	<div className='text-truncate'>{lastNote.text}</div>
													</div>
												}>
													{
														notesHistory.map(note => (
															<div className='row mb-1'>
																<div className='col-4'>
																	{note.administrator && note.administrator.fullName}  <TimeAgo date={note.timestamp}></TimeAgo>
																</div>
																<div className='col-8'>
																	{note.text}
																</div>
															</div>
														))
													}
													<div className=''></div>
												</IBox>
											</div>
										}
										<div className="col">
											<Field
												name="notes"
												render={(props) => {
													return (
														<>
															<MDBInput
																label="Nueva nota"
																type="textarea"
																labelClass="text-dark notes-label"
																id="notes-input"
																icon="sticky-note"
																outline
																value={props.field.value}
																getValue={ (value) => { props.form.setFieldValue('notes', value)}}
															/>
														</>
													);
												}}
											/>


										</div>
									</div>
									<div className="row">
										<div className="col">
											<button
												type="submit"
												className="btn btn-primary block full-width m-t"
												disabled={ isSubmitting }
											>
												Guardar
											</button>
										</div>
									</div>
								</div>


							</Form>
						)
						}
					</Formik>
				</div>
		)
	}
}

class MDBTaskStatusButton extends React.Component {
	
	constructor(props){
		super(props);
		this.state={
			current:props.status,
			next: this.getNext(props.status)	
		}
	}

	getNext = (status) => {
		let next = undefined;
		switch (status) {
			case 1:
				next=2;
				break;
			case 2:
				next=3;
				break;
			case 3:
				next=2;
				break;
			default:
				break;
		}
		return next;
	}

	getColor = (status) => {
		let color = undefined;
		switch (status) {
			case 1:
				color ="danger";
				break;
			case 2:
				color ="success";
				break;
			case 3:
				color ="warning";
				break;
			default:
				color ="transparent";
				break;
		}
		return color ;
	}

	componentDidUpdate(prevProps){
		if (prevProps.status===this.props.status) return;
		this.setState(
			{
				current:this.props.status,
				next: this.getNext(this.props.status)	
			}
		);
	}

	render() {
		let {next, current} = this.state;
		if (!current) return null;
		return (
			<div>
				<h3 className={`text-uppercase m-2 text-${this.getColor(current)}`}>{this.props.t(`taskStatusType.${current}`)}</h3>
				<MDBBtn color={this.getColor(next)} onClick={()=>{this.props.getValue(next)}}>{this.props.t(`taskStatusType.action.${next}`)}</MDBBtn>
			</div>
		)
	}

}

const MDBTaskStatusButtonTranslated = translate('common')(MDBTaskStatusButton);


class MDBChannelSelectorUntranslated extends React.Component {
	constructor(props){
		super(props);
		this.state={}
	}


	async componentDidMount() {
		let response = await getAllChannels();
		this.setState( {
			options : response.data.data.map(
				channel => {
					return {
						value:channel.id,
						text:this.props.t(`channel.${channel.id}`),
						checked: channel.id===this.props.value
					}
				}
			).sort((a,b)=>(a.text>b.text))
		})

	}

	render(){
		return (
			<>
				<MDBSelect
					options={this.state.options}
					color="primary"
					selected="Seleccione canal"
					getValue={(values)=> this.props.getValue(values[0])}
					onBlur={this.props.onBlur}
				/>
			</>
		);
	}
}
export const MDBChannelSelector = translate('common')(MDBChannelSelectorUntranslated);

class MDBAdminSelector extends React.Component{

	constructor(props){
		super(props);
		this.state={}
	}

	async updateAdministrators(selectedStore) {
		if(!selectedStore) return;
		let response = await getStoreAdminitrators(this.props.selectedStore);
		this.setState( {
			adminFromOptions : response.data.data.map(
				admin => {
					return {
						value:admin.id,
						text:`${admin.name} ${admin.lastName}`,
						checked: admin.id===this.props.value
					}
				}
			)
		})
	}

	componentDidMount = () => {
		this.updateAdministrators(this.props.selectedStore);
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.selectedStore===prevProps.selectedStore) return;
		this.updateAdministrators(this.props.selectedStore)
	}

	shouldComponentUpdate(nextProps, nextState){
		return this.props.selectedStore!==nextProps.selectedStore || 
			this.props.value !== nextProps.value
			|| this.state.adminFromOptions !== nextState.adminFromOptions;
	}

	render(){
		return (
			<>
				<MDBSelect
					options={this.state.adminFromOptions}
					color="primary"
					selected="Asignada a"
					getValue={this.props.getValue}
					search={true}
					onBlur={this.props.onBlur}
				/>
			</>
		);

	}

}
const Error = ({message}) => (
	<span className="text-danger position-relative" style={{ top: '-1em' }}>{message}</span>
)

class TaskTypeSelector extends React.Component {

	constructor(props){
		super(props);
		this.state={}
	}

	async updateTaskTypes(selectedStore) {
		if(!selectedStore) return;
		let response = await getTaskTypes(this.props.selectedStore);
		this.setState( {
			taskTypesOptions : response.data.data.map(
				taskType => {
					return {
						value:taskType.id,
						text:`${taskType.label}`,
						checked: taskType.id===this.props.value
					}
				}
			)
		})
	}

	componentDidMount = () => {
		this.updateTaskTypes(this.props.selectedStore);
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.selectedStore===prevProps.selectedStore) return;
		this.updateTaskTypes(this.props.selectedStore)
	}

	shouldComponentUpdate(nextProps, nextState){
		return this.props.selectedStore!==nextProps.selectedStore || 
			this.props.value !== nextProps.value
			|| this.state.taskTypesOptions !== nextState.taskTypesOptions;
	}

	render(){
		return (
			<>
				<MDBSelect
					options={this.state.taskTypesOptions}
					color="primary"
					selected="Objetivo"
					getValue={this.props.getValue}
					onBlur={this.props.onBlur}
				/>
			</>
		);

	}

}
