import { applicationConstants } from './applicationConstants.js'
import { loginConstants } from '../../../components/login/loginConstants';

export function application(state = { navigationMenuExpanded: true }, action) {
	switch (action.type) {		
		case loginConstants.LOGIN_SUCCESS:			
			return {
				...state,
				selectedCompany: { id: action.admin.companyId },
				selectedStore: null,
				navigationMenuExpanded: state.navigationMenuExpanded ? state.navigationMenuExpanded : true
			}
		case applicationConstants.SELECTED_COMPANY_CHANGED:
			return {
				...state,
				selectedCompany: action.payload.selectedCompany
			}	
		case applicationConstants.TOGGLE_NAVIGATION_BAR:
			return {
				...state,
				navigationMenuExpanded: !state.navigationMenuExpanded
			}
		case loginConstants.LOGOUT:
			return {
			}
		case applicationConstants.SET_AVAILABLE_STORES:
			return {
				...state,
				availableStores : action.payload.availableStores
			}
		case applicationConstants.SET_AVAILABLE_COMPANIES:
			return {
				...state,
				availableCompanies : action.payload.availableCompanies
			}	
		default:
			return state
	}
}
