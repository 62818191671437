import React from "react";
import { Switch, Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import LoginContainer from "../../../components/login/LoginContainer";
import LogoutContainer from "../../../components/login/LogoutContainer";
import AuthenticatedRouter from "./AuthenticatedRouter";
import { connect } from 'react-redux';
import { removeNotifications } from './miscActions'
import Loading from "../../../commons/components/utils/Loading";
import NotificationsViewer from "../../../components/layout/NotificationsViewer";
import "./AppRouter.css";
import { translate } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import OnBoardingContainer from "../../../components/onboarding/OnBoardingContainer";
import FeedbackRouter from "./FeedbackRouter";
import isFeedbackCenterInStandaloneMode from "../../../components/feedback/FeedbackCenterUtil";
import GiveNps from "../../../components/feedback/nps/GiveNps";
import { firePageView, identify } from "../../../commons/components/utils/trackers";
import SetPassword from "../../../components/login/SetPassword";
import LeftComment from "../../../components/feedback/nps/LeftComment/LeftComment";
import CookiesDisclaimer from '../../../components/CookiesDisclaimer/CookiesDisclaimer'
import GiveNpsInOneStep from "../../../components/feedback/nps/GiveNpsInOneStep";
import ReporterComponent, {errorAction} from "../../../commons/reporter/reporter"

class AppRouter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			reSized: true
		}
	}

	handleResize = () => {
		//amp en master esto estaba comentado
		this.setState({ reSized: !this.state.reSized })
		const classes = document.getElementsByTagName("body")[0].classList;
		if (window.innerWidth < 769) {
			classes.add('body-small');
		} else {
			classes.remove('body-small');
		}
	};

	componentDidMount = () => {
		this.handleResize();
		this.handleAuthenticatedStyle(this.props.loggedIn);
		this.listener = window.addEventListener("resize", () => { this.handleAuthenticatedStyle(this.props.loggedIn); this.handleResize() });
		firePageView();
		identify(this.props.loggedAdmin, this.props.company ? this.props.company.signUpDate : undefined)
	};
	componentWillUnmount = () => {
		window.removeEventListener("resize", this.listener);
	};

	handleAuthenticatedStyle = (loggedIn) => {
		const classes = document.getElementById('wrapper').classList;
		if (loggedIn) {
			if (window.location.href.indexOf('feedback') === -1)
				classes.add('authenticated');
			classes.remove('login');
			classes.remove('fill-height');
		} else {
			classes.remove('authenticated');
			classes.add('fill-height');
			classes.add('login');
		}
	};

	componentDidCatch(error, info){
		this.props.errorAction({error, info})
	}

	render() {
		//amp en master esta linea estaba descomentada
		//this.handleResize();
		this.handleAuthenticatedStyle(this.props.loggedIn);

		let title = this.props.t("application.title");
		let loginUrl = (window.location.href.indexOf("/login") === -1)
			? "/login?originalUrl=" + encodeURIComponent(window.location.pathname) + encodeURIComponent(window.location.search)
			: "/login";
		return (
			<DocumentTitle title={title}>
				<React.Fragment>
					<Loading fullScreen={true} loading={this.props.loading} />
					<Router>
						<NotificationsViewer notifications={this.props.notifications} removeNotifications={this.props.removeNotifications} />
						<Switch>
							<Route path={'/login*'} render={(props) => <LoginContainer />} />
							<Route path={`/setPassword`} component={SetPassword} />
							<Route path={'/nps'} component={GiveNps} ></Route>
							<Route path={'/left-comment'} component={LeftComment} ></Route>
							<Route path={'/logout*'} component={LogoutContainer} />
							<Route path={'/wizard'} component={OnBoardingContainer} />
							<Route path={'/nps-one-step'} component={GiveNpsInOneStep} ></Route>
							<Route path={'/error-report'} component={ReporterComponent} ></Route>
							{isFeedbackCenterInStandaloneMode() && <Route path={'/'} render={(props) =>
								(!this.props.loggedIn ? (<Redirect to={loginUrl} />) : (<FeedbackRouter {...props} />))
							}
							/>}
							<Route path={'/'} render={(props) => {
								return (!this.props.loggedIn ? (<Redirect to={loginUrl} />) : (<AuthenticatedRouter {...props} />))
							}
							}
							/>
						</Switch>
					</Router>
					<CookiesDisclaimer></CookiesDisclaimer>
				</React.Fragment>
			</DocumentTitle>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		loggedAdmin: state.login.admin,
		loggedIn: state.login.loggedIn,
		loading: state.misc.loading,
		currentError: state.misc.error,
		notifications: state.misc.notifications,
		company: state.application.selectedCompany
	}
}

export default connect(mapStateToProps, { removeNotifications, errorAction })(translate('common')(AppRouter))

//export default withRouter(connect(mapStateToProps, { loginSuccess, loginFail, setLoading })(LoginContainer))
