import React from 'react';
import UserReviewHistory from './UserReviewHistory';
import { MDBIcon } from "mdbreact";
import { useHistory } from "react-router-dom";

import "./UserReviewHistoryContainer.scss"

const UserReviewHistoryContainer = () => {

    const URLParams = new URLSearchParams(window.location.search);
    const identityId = URLParams.get('identityId');
    const storeId = URLParams.get('storeId')
    const type = URLParams.get('type')

    const history = useHistory();

    const createReviewId = () => {
        const reviewId = `${type}_${identityId}_${storeId}`.toUpperCase();
        return reviewId;
    }

    return (
        <div className="user-review-history-container">
            <MDBIcon
                fas
                size="2x"
                icon="arrow-left"
                onClick={() => history.goBack()}
                style={{ cursor: 'pointer', color: "#000", marginTop: "1rem" }}
            />
            <UserReviewHistory
                className='user-review-history user-review-history-page'
                fullId={createReviewId()}
            />
        </div>
    );
}

export default UserReviewHistoryContainer;