import { envConfig } from "../../config";

export const translations = {
    title: {
        es: "Visualizaciones en Google · 📅 Últimos 18 meses",
        en: "Views in Google · 📅 Last 18 months"
    },
    views: {
        es: "Visualizaciones",
        en: "Views"
    },
    startWithBotbit: {
        es: `Inicio en ${envConfig.companyName}`,
        en: `Started with ${envConfig.companyName}`
    },
    noData: {
        es: "No hay datos suficientes para las tiendas seleccionadas.",
        en: "There is no available data for the selected stores."
    },
    linkToHelpCenter: {
        beforeLinkText: {
            es: "¿Sabes que puedes captar muchos más clientes?",
            es_ar: "¿Sabías que podés captar muchos más clientes?",
            en: "Do you know that you can get more clients?",
        },
        linkText: {
            es: "Haz click aquí",
            es_ar: "Hacé click acá",
            en: "Click here",
        },
        afterLinkText: {
            es: "y entérate cómo",
            es_ar: "y enterate cómo",
            en: "and find out how",
        },
    },
    disclaimerLastDays: {
        es: "Los datos de los últimos 5 días pueden no estar completos aún",
        en: "The last 5 days information may not be complete yet"
    },
    beforeLinkText: {
        es: "Conoce más sobre tus fichas de Google haciendo",
        en: "To know more about your Google views"
    },
    linkText: {
        es: "click aquí",
        es_ar: "click acá",
        en: "click here"
    },
    monthViews: {
        es: "Cantidad de visualizaciones por mes de tus fichas en Google ",
        en: "Views per month of your profiles on Google "
    },
    maps: {
        es: "y Google Maps",
        en: "and Google Maps"
    },
    period: {
        es: "en los últimos 18 meses.",
        en: "in the last 18 months."
    }
}