export const translations = {
    userName: {
        es:"Nombre",
        en:"Name"
    },
    userLastName: {
        es:"Apellido",
        en:"Last name"
    },
    assign: {
        es:"Asignación",
        en:"Assignation"
    },
    issueType: {
        es:"Motivo",
        en:"Issue"
    },
    currentIssueState: {
        es:"Estado",
        en:"State"
    },
    timestamp: {
        es:"Fecha Ult Estado",
        en:"Update date"
    },
    company:{
        es:"Empresa",
        en: "Company"
    },
    store: {
        es:"Tienda",
        en:"Store"
    },
    title:{
        es: "Motivos de conversaciones",
        en: "Issues"
    },
    contact:{
        es:"Contactar",
        en: "Contact"
    },
    exportCSV: {
        es: "Exportar CSV",
        en: "Export CSV"
    }
}