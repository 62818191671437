import React from "react";
export const NoResultsImage = () =><svg width="317" height="229" viewBox="0 0 317 229" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
<path d="M278.085 111.544H275.263V223.054H278.085V111.544Z" fill="#3F3D56"/>
<path d="M304.126 125.755C304.306 164.747 276.885 196.484 276.885 196.484C276.885 196.484 249.172 165.001 248.993 126.009C248.813 87.0167 276.234 55.2801 276.234 55.2801C276.234 55.2801 303.946 86.7625 304.126 125.755Z" fill="#3F3D56"/>
<path d="M221.662 164.011H220.211V221.364H221.662V164.011Z" fill="#3F3D56"/>
<path d="M235.057 171.32C235.149 191.375 221.046 207.698 221.046 207.698C221.046 207.698 206.793 191.506 206.7 171.451C206.608 151.396 220.711 135.073 220.711 135.073C220.711 135.073 234.964 151.266 235.057 171.32Z" fill="#3F3D56"/>
<path d="M250.269 229C271.985 229 289.59 226.3 289.59 222.97C289.59 219.64 271.985 216.94 250.269 216.94C228.552 216.94 210.948 219.64 210.948 222.97C210.948 226.3 228.552 229 250.269 229Z" fill="#3F3D56"/>
<path d="M253.512 82.433C250.181 76.8016 243.59 76.5391 243.59 76.5391C243.59 76.5391 237.167 75.7175 233.047 84.2942C229.207 92.2885 223.907 100.007 232.194 101.879L233.691 97.2177L234.618 102.226C235.798 102.31 236.981 102.331 238.164 102.286C247.038 101.999 255.489 102.37 255.217 99.1841C254.856 94.9489 256.717 87.8516 253.512 82.433Z" fill="#2F2E41"/>
<path d="M241.515 93.0383C241.515 93.0383 245.82 99.0683 243.237 103.95C240.654 108.831 249.264 114 249.264 114L255.578 100.217C255.578 100.217 248.116 95.3354 250.125 90.7411L241.515 93.0383Z" fill="#FBBEBE"/>
<path d="M244.385 95.6225C248.507 95.6225 251.848 92.28 251.848 88.1569C251.848 84.0337 248.507 80.6912 244.385 80.6912C240.264 80.6912 236.923 84.0337 236.923 88.1569C236.923 92.28 240.264 95.6225 244.385 95.6225Z" fill="#FBBEBE"/>
<path d="M218.56 96.7872L224.796 88.7375C224.796 88.7375 226.992 80.2999 230.172 80.7277C233.352 81.1554 228.916 90.8465 228.916 90.8465L222.719 99.7567L218.56 96.7872Z" fill="#FBBEBE"/>
<path d="M250.125 203.875L252.135 214.499L256.44 215.648L255.292 203.014L250.125 203.875Z" fill="#FBBEBE"/>
<path d="M293.465 192.389L301.214 203.014L304.371 204.449L306.955 200.142L299.205 190.092L293.465 192.389Z" fill="#FBBEBE"/>
<path d="M248.403 109.405L243.615 103.1C243.615 103.1 234.339 104.811 233.478 105.673C232.617 106.534 235.775 122.327 235.775 122.327C235.775 122.327 236.349 126.634 238.932 128.931L241.515 130.654L262.754 126.347L263.553 116.892C263.843 113.449 263.412 109.982 262.286 106.715C261.16 103.448 259.364 100.452 257.014 97.9197L253.318 98.4251L248.403 109.405Z" fill="#575A89"/>
<path d="M234.627 106.247L233.191 105.673L226.59 105.098C226.59 105.098 224.294 104.524 224.868 103.375C225.442 102.227 226.016 101.94 224.868 101.653C223.72 101.365 223.433 101.078 223.72 100.217C224.007 99.3554 225.729 97.6326 225.729 97.6326L220.85 93.6126C220.85 93.6126 220.586 93.7995 220.159 94.1452C217.736 96.1075 210.071 103.187 215.683 110.267C222.285 118.594 230.034 123.475 236.349 121.753L234.627 106.247Z" fill="#575A89"/>
<path d="M240.941 129.218V133.238C240.941 133.238 238.358 138.12 239.219 142.714C240.08 147.308 240.367 149.605 240.367 149.605C240.46 153.513 241.138 157.384 242.376 161.091C244.385 166.834 237.784 204.449 246.107 205.024C254.431 205.598 259.023 206.172 261.606 203.301C264.189 200.429 257.301 151.041 257.301 151.041C257.301 151.041 280.836 200.142 285.715 198.132C290.595 196.122 302.936 191.528 301.501 188.944C300.066 186.359 263.902 128.931 263.902 128.931L262.754 126.347L240.941 129.218Z" fill="#2F2E41"/>
<path d="M254.717 212.489C254.717 212.489 250.125 212.202 250.125 213.638C250.125 215.074 247.829 219.955 247.829 219.955C247.829 219.955 246.107 225.698 250.699 225.124C255.291 224.549 258.162 219.381 258.162 219.381L257.014 213.925L254.717 212.489Z" fill="#2F2E41"/>
<path d="M302.649 202.439C302.649 202.439 298.631 199.855 298.918 201.578C299.205 203.301 299.492 207.895 301.501 208.182C303.51 208.469 309.538 210.192 309.825 210.767C310.112 211.341 317 213.638 317 209.905C317 206.172 312.695 203.301 312.695 203.301L307.816 199.568C307.816 199.568 304.658 199.281 304.084 201.291C303.51 203.301 302.649 202.439 302.649 202.439Z" fill="#2F2E41"/>
<path d="M244.396 81.3353C247.028 81.3353 249.162 79.2007 249.162 76.5677C249.162 73.9346 247.028 71.8 244.396 71.8C241.764 71.8 239.631 73.9346 239.631 76.5677C239.631 79.2007 241.764 81.3353 244.396 81.3353Z" fill="#2F2E41"/>
<path d="M239.129 75.313C239.129 74.1355 239.565 72.9997 240.352 72.1244C241.14 71.2491 242.223 70.6962 243.393 70.5722C243.226 70.5546 243.059 70.5456 242.891 70.5454C241.628 70.5454 240.415 71.0477 239.522 71.9418C238.628 72.8359 238.126 74.0486 238.126 75.313C238.126 76.5775 238.628 77.7902 239.522 78.6843C240.415 79.5784 241.628 80.0807 242.891 80.0807C243.059 80.0805 243.226 80.0715 243.393 80.0539C242.223 79.9299 241.14 79.377 240.352 78.5017C239.565 77.6263 239.129 76.4905 239.129 75.313Z" fill="#2F2E41"/>
<path d="M252.039 81.8902L245.527 78.4778L236.535 79.8739L234.675 88.0942L239.306 87.9159L240.6 84.896V87.8662L242.737 87.7841L243.977 82.9759L244.752 88.0942L252.349 87.9392L252.039 81.8902Z" fill="#2F2E41"/>
<path d="M251.649 129.883L241.607 130.155C241.607 130.155 233.039 131.823 233.519 128.363C234 124.903 242.359 126.113 242.359 126.113L251.51 124.717L251.649 129.883Z" fill="#FBBEBE"/>
<path d="M259.594 97.433C260.099 97.3565 260.614 97.4628 261.048 97.7328C261.481 98.0027 261.804 98.4186 261.958 98.9054C263.924 105.103 269.98 125.843 263.963 128.689C256.876 132.04 251.703 131.893 251.703 131.893L248.935 125.074L251.455 122.707L254.146 105.4L255.435 98.0633L259.594 97.433Z" fill="#575A89"/>
<path opacity="0.4" d="M257.732 108.975L256.584 120.46L246.538 124.767L257.732 122.183V108.975Z" fill="black"/>
<path d="M203.556 71.0569C206.791 71.9146 209.591 73.949 211.406 76.7623L212.345 78.2169L212.392 78.277L228.863 83.0985C229.165 83.1869 229.42 83.3903 229.573 83.665C229.727 83.9397 229.767 84.2637 229.684 84.5673L228.405 89.2578C228.363 89.4105 228.292 89.5536 228.195 89.6786C228.098 89.8037 227.977 89.9083 227.839 89.9864C227.701 90.0646 227.55 90.1147 227.393 90.1339C227.235 90.1532 227.076 90.1411 226.924 90.0985L210.449 85.4916L210.449 85.4921L208.794 86.1179C205.747 87.2703 202.406 87.3839 199.288 86.4411L203.556 71.0569Z" fill="#3F3D56"/>
<path d="M220.901 84.6598C221.057 84.1264 220.373 83.4569 219.373 83.1642C218.374 82.8716 217.437 83.0668 217.281 83.6001C217.125 84.1335 217.809 84.8031 218.808 85.0957C219.808 85.3883 220.745 85.1931 220.901 84.6598Z" fill="#575A89"/>
<path d="M220.06 85.1516C220.442 85.1516 220.751 84.8419 220.751 84.4598C220.751 84.0778 220.442 83.7681 220.06 83.7681C219.678 83.7681 219.368 84.0778 219.368 84.4598C219.368 84.8419 219.678 85.1516 220.06 85.1516Z" fill="#3F3D56"/>
<path d="M204.933 79.9413C206.306 75.7038 205.867 71.7649 203.952 71.1436C202.036 70.5222 199.37 73.4536 197.997 77.6911C196.623 81.9286 197.062 85.8675 198.978 86.4888C200.893 87.1102 203.559 84.1787 204.933 79.9413Z" fill="#38D39F"/>
<path opacity="0.4" d="M204.421 79.7914C205.744 75.7083 205.384 71.903 203.643 71.0752C203.747 71.0883 203.851 71.1112 203.951 71.1435C205.867 71.7649 206.306 75.7038 204.933 79.9413C203.559 84.1788 200.893 87.1102 198.978 86.4888C198.908 86.4661 198.84 86.4389 198.774 86.4073C200.658 86.6473 203.12 83.8057 204.421 79.7914Z" fill="black"/>
<path d="M203.772 71.0719L166.392 42.0634C159.782 36.8967 153.439 31.397 147.387 25.5856C136.732 15.4243 122.267 7.30016 105.425 2.9748C64.5078 -7.53392 32.0929 11.0993 17.3497 38.4593C-5.17946 80.2683 -22.4451 148.113 77.4491 111.999C99.2959 104.101 121.41 103.262 138.178 99.1015L198.893 86.5776L203.772 71.0719Z" fill="#38D39F"/>
<path d="M91.5492 22.2578C92.6588 22.2578 93.5583 21.3579 93.5583 20.2478C93.5583 19.1378 92.6588 18.2379 91.5492 18.2379C90.4396 18.2379 89.54 19.1378 89.54 20.2478C89.54 21.3579 90.4396 22.2578 91.5492 22.2578Z" fill="#F2F2F2"/>
<path d="M130.577 24.8937H130.054V24.3706H129.952V24.8937H129.429V24.996H129.952V25.5191H130.054V24.996H130.577V24.8937Z" fill="#F2F2F2"/>
<path d="M183.101 68.5394H182.578V68.0162H182.476V68.5394H181.953V68.6416H182.476V69.1648H182.578V68.6416H183.101V68.5394Z" fill="#F2F2F2"/>
<path d="M179.37 82.035H178.847V81.5118H178.745V82.035H178.222V82.1372H178.745V82.6604H178.847V82.1372H179.37V82.035Z" fill="#F2F2F2"/>
<path d="M18.9283 93.5207H18.4054V92.9975H18.3032V93.5207H17.7803V93.6229H18.3032V94.1461H18.4054V93.6229H18.9283V93.5207Z" fill="#F2F2F2"/>
<path d="M41.8898 30.9237H41.3668V30.4006H41.2646V30.9237H40.7417V31.026H41.2646V31.5491H41.3668V31.026H41.8898V30.9237Z" fill="#F2F2F2"/>
<path d="M131.151 84.3321H130.629V83.809H130.526V84.3321H130.003V84.4343H130.526V84.9575H130.629V84.4343H131.151V84.3321Z" fill="#F2F2F2"/>
<path d="M76.3312 12.5467H75.8082V12.0235H75.706V12.5467H75.1831V12.6489H75.706V13.1721H75.8082V12.6489H76.3312V12.5467Z" fill="#F2F2F2"/>
<path d="M76.5329 53.8888L75.8813 53.9893C76.0851 55.2929 76.2459 56.6252 76.3635 57.9432L77.0208 57.8858C76.9031 56.5506 76.7395 55.2068 76.5329 53.8888Z" fill="#E6E6E6"/>
<path d="M74.7501 46.0383L74.1187 46.2307C74.5004 47.4941 74.8419 48.7892 75.1318 50.0784L75.7776 49.932C75.482 48.6284 75.1347 47.319 74.7501 46.0383Z" fill="#E6E6E6"/>
<path d="M71.906 38.5095L71.3062 38.7851C71.8572 39.9882 72.3738 41.2229 72.8417 42.4577L73.4588 42.2251C72.9852 40.976 72.4657 39.7241 71.906 38.5095Z" fill="#E6E6E6"/>
<path d="M68.0597 31.4515L67.5029 31.8047C68.2119 32.9188 68.8921 34.0731 69.5235 35.2303L70.1033 34.9144C69.4661 33.7429 68.7773 32.5771 68.0597 31.4515Z" fill="#E6E6E6"/>
<path d="M63.2782 24.9822L62.7759 25.41C63.6312 26.415 64.4635 27.466 65.25 28.5284L65.7781 28.135C64.9859 27.0611 64.1449 25.9987 63.2782 24.9822Z" fill="#E6E6E6"/>
<path d="M57.6584 19.2221L57.2192 19.7131C58.2066 20.5947 59.1738 21.5221 60.0951 22.4668L60.5687 22.0045C59.6359 21.0484 58.6572 20.1123 57.6584 19.2221Z" fill="#E6E6E6"/>
<path d="M51.3097 14.2776L50.9395 14.8231C52.0359 15.5611 53.1208 16.3479 54.1626 17.1605L54.5702 16.6407C53.514 15.8195 52.4176 15.0241 51.3097 14.2776Z" fill="#E6E6E6"/>
<path d="M45.5322 10.8434V11.5899C46.2182 11.9488 46.8984 12.325 47.5643 12.7098L47.8944 12.1384C47.1194 11.6904 46.332 11.2587 45.5322 10.8434Z" fill="#E6E6E6"/>
<path d="M76.5771 62.0165L77.2369 62.0045C77.249 62.671 77.2499 63.341 77.2396 63.9961L76.5799 63.9858C76.5899 63.3382 76.5891 62.6756 76.5771 62.0165Z" fill="#E6E6E6"/>
<path d="M37.7858 110.456L37.1343 110.556C37.3381 111.86 37.4988 113.192 37.6165 114.51L38.2737 114.453C38.156 113.118 37.9924 111.774 37.7858 110.456Z" fill="#E6E6E6"/>
<path d="M36.003 102.605L35.3716 102.798C35.7533 104.061 36.0949 105.356 36.3848 106.645L37.0305 106.499C36.7349 105.195 36.3876 103.886 36.003 102.605Z" fill="#E6E6E6"/>
<path d="M33.1585 95.0764L32.5586 95.3521C33.1097 96.5552 33.6263 97.7899 34.0941 99.0247L34.7112 98.7921C34.2376 97.543 33.7181 96.2911 33.1585 95.0764Z" fill="#E6E6E6"/>
<path d="M29.3127 88.0185L28.7559 88.3717C29.4648 89.4857 30.145 90.6401 30.7765 91.7973L31.3562 91.4814C30.7191 90.3099 30.0302 89.1441 29.3127 88.0185Z" fill="#E6E6E6"/>
<path d="M24.5311 81.5492L24.0288 81.977C24.8841 82.982 25.7165 84.0329 26.5029 85.0954L27.031 84.702C26.2388 83.6281 25.3978 82.5656 24.5311 81.5492Z" fill="#E6E6E6"/>
<path d="M18.9113 75.7891L18.4722 76.2801C19.4595 77.1617 20.4267 78.0891 21.3481 79.0338L21.8216 78.5715C20.8889 77.6153 19.9101 76.6793 18.9113 75.7891Z" fill="#E6E6E6"/>
<path d="M12.5626 70.8445L12.1924 71.3901C13.2888 72.128 14.3737 72.9148 15.4156 73.7274L15.8231 73.2077C14.7669 72.3865 13.6705 71.5911 12.5626 70.8445Z" fill="#E6E6E6"/>
<path d="M6.78467 67.4103V68.1569C7.47064 68.5158 8.15086 68.892 8.81674 69.2767L9.14681 68.7053C8.37186 68.2574 7.58448 67.8257 6.78467 67.4103Z" fill="#E6E6E6"/>
<path d="M37.8296 118.584L38.4893 118.572C38.5015 119.238 38.5024 119.908 38.492 120.563L37.8323 120.553C37.8424 119.905 37.8415 119.243 37.8296 118.584Z" fill="#E6E6E6"/>
<path d="M170.387 82.0287L169.735 82.1292C169.939 83.4328 170.1 84.7651 170.218 86.0831L170.875 86.0257C170.757 84.6905 170.593 83.3467 170.387 82.0287Z" fill="#E6E6E6"/>
<path d="M168.605 74.1782L167.973 74.3706C168.355 75.634 168.696 76.929 168.986 78.2183L169.632 78.0719C169.336 76.7682 168.989 75.4589 168.605 74.1782Z" fill="#E6E6E6"/>
<path d="M165.76 66.6493L165.16 66.925C165.711 68.1281 166.228 69.3628 166.696 70.5975L167.313 70.3649C166.839 69.1159 166.32 67.8639 165.76 66.6493Z" fill="#E6E6E6"/>
<path d="M161.914 59.5914L161.357 59.9446C162.066 61.0587 162.747 62.213 163.378 63.3702L163.958 63.0543C163.321 61.8828 162.632 60.717 161.914 59.5914Z" fill="#E6E6E6"/>
<path d="M157.132 53.1221L156.63 53.5499C157.485 54.5549 158.318 55.6059 159.104 56.6683L159.632 56.2749C158.84 55.201 157.999 54.1386 157.132 53.1221Z" fill="#E6E6E6"/>
<path d="M151.512 47.362L151.073 47.853C152.061 48.7346 153.028 49.662 153.949 50.6067L154.423 50.1444C153.49 49.1882 152.511 48.2522 151.512 47.362Z" fill="#E6E6E6"/>
<path d="M145.164 42.4174L144.793 42.963C145.89 43.7009 146.975 44.4877 148.017 45.3003L148.424 44.7806C147.368 43.9594 146.272 43.164 145.164 42.4174Z" fill="#E6E6E6"/>
<path d="M139.386 38.9832V39.7298C140.072 40.0887 140.752 40.4649 141.418 40.8497L141.748 40.2783C140.973 39.8303 140.186 39.3986 139.386 38.9832Z" fill="#E6E6E6"/>
<path d="M170.431 90.1565L171.091 90.1445C171.103 90.811 171.104 91.481 171.094 92.1361L170.434 92.1258C170.444 91.4781 170.443 90.8155 170.431 90.1565Z" fill="#E6E6E6"/>
<path d="M42.85 57.3982H42.3635V56.9113H42.2685V57.3982H41.7817V57.493H42.2685V57.98H42.3635V57.493H42.85V57.3982Z" fill="#F2F2F2"/>
<path d="M37.2411 86.7892H36.7546V86.3022H36.6596V86.7892H36.1729V86.8842H36.6596V87.3709H36.7546V86.8842H37.2411V86.7892Z" fill="#F2F2F2"/>
<path d="M20.7851 112.548H20.2983V112.061H20.2033V112.548H19.7168V112.643H20.2033V113.13H20.2983V112.643H20.7851V112.548Z" fill="#F2F2F2"/>
<path d="M66.5646 79.878C61.8595 72.8809 59.8165 64.4285 60.8062 56.0537C61.7959 47.6789 65.7528 39.9362 71.9592 34.2297C78.1656 28.5232 86.2107 25.2308 94.6354 24.9495C103.06 24.6682 111.307 27.4167 117.88 32.6965C124.453 37.9763 128.917 45.4378 130.462 53.728C132.008 62.0181 130.533 70.5879 126.304 77.8833C122.076 85.1787 115.374 90.7167 107.415 93.4928C99.4552 96.2689 90.7647 96.0995 82.9193 93.0151C82.4107 94.8314 81.4598 96.4931 80.1518 97.8515C78.8438 99.2099 77.2196 100.223 75.4244 100.799C73.6293 101.375 71.7193 101.498 69.8654 101.154C68.0115 100.811 66.2716 100.013 64.8015 98.8325C63.3314 97.6516 62.1769 96.1244 61.4415 94.3877C60.706 92.6509 60.4124 90.7589 60.5871 88.8808C60.7618 87.0027 61.3993 85.1973 62.4425 83.6262C63.4856 82.0551 64.902 80.7672 66.5646 79.878Z" fill="#3F3D56"/>
<path d="M95.6128 40.0381C103.693 40.0381 109.253 44.5222 109.253 50.968C109.253 55.2364 107.184 58.1899 103.198 60.5397C99.4484 62.717 98.1771 64.3123 98.1771 67.0717C98.1771 67.2953 98.1331 67.5168 98.0475 67.7234C97.962 67.9301 97.8366 68.1178 97.6785 68.276C97.5204 68.4341 97.3328 68.5595 97.1262 68.6451C96.9197 68.7307 96.6983 68.7748 96.4748 68.7748H92.3438C91.9026 68.7748 91.4786 68.6034 91.1612 68.2968C90.8438 67.9901 90.6579 67.5722 90.6425 67.1311L90.6352 66.9208C90.2688 62.4799 91.8203 59.7205 95.7206 57.4353C99.3622 55.258 100.892 53.8783 100.892 51.2051C100.892 48.532 98.3064 46.5702 95.0957 46.5702C92.6553 46.5702 90.724 47.7586 89.8382 49.6988C89.5269 50.3556 89.034 50.9094 88.418 51.2948C87.8019 51.6802 87.0884 51.881 86.3619 51.8734C83.5629 51.8734 81.7139 48.8956 83.0228 46.4205C85.0935 42.5051 89.4624 40.0381 95.6128 40.0381ZM89.9456 76.9883C89.9456 74.5092 92.0142 72.5474 94.5569 72.5474C97.1212 72.5474 99.1898 74.4876 99.1898 76.9883C99.1898 79.489 97.1212 81.4292 94.5569 81.4292C91.9927 81.4292 89.9456 79.489 89.9456 76.9883Z" fill="#38D39F"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="317" height="229" fill="white"/>
</clipPath>
</defs>
</svg>