import React from "react";
import { MDBCard, MDBSpinner, MDBRow, MDBContainer } from "mdbreact";
import Chart from "react-google-charts";

import './reviewsSummary.css';
import { round } from '../../commons/utils/numbers';
import { Carousel } from "botbit-ui-components";
import { translations } from "../../config/translations/Feedback/FeedbackDashboard";
import translate from "../../config/translations/translate";

const DashboardSummary = ({ data, loading, onSummaryClick, t }) => {
    if (loading) {
        return <div className="text-center m-auto dashboard-loading"><MDBSpinner /></div>
    }
    const scores = [], reviews = [], botbitReviews = [], promoterRate = [], replyRate = [];

    const createScoreTooltipContent = (label, score) => {
        return '<div class="summary-tooltip">' +
            '<span class="summary-tooltip-small">' + label + '</span><br/>' +
            '<span class="summary-tooltip-big">' + score + '</span>' +
            '</div>';
    }

    const createReviewsTooltipContent = (label, reviews) => {
        return '<div class="summary-tooltip">' +
            '<span class="summary-tooltip-small">' + label + '</span><br/>' +
            '<span class="summary-tooltip-big">' + reviews + '</span>' +
            '</div>';
    };

    const createBotBitReviewsTooltipContent = (label, botbitGeneratedReviews) => {
        return '<div class="summary-tooltip">' +
            '<span class="summary-tooltip-small">' + label + '</span><br/>' +
            '<span class="summary-tooltip-big">' + botbitGeneratedReviews + '</span>' +
            '</div>';
    };

    const createReplyRateTooltipContent = (label, manualReplies, manualReplyRate, autoReplies, autoReplyRate) => {
        return '<div class="summary-tooltip">' +
            '<span class="summary-tooltip-small">' + label + '</span><br/>' +
            `<span class="auto-reply-color">${t("automatic")}:</span> <b>` + autoReplies + ' (' + autoReplyRate + ')</b><br/>' +
            '<span class="manual-reply-color">Manuales:</span> <b>' + manualReplies + ' (' + manualReplyRate + ')</b>' +
            '</div>';
    };

    const createPromoterRateTooltipContent = (label, promoterRate) => {
        return '<div class="summary-tooltip">' +
            '<span class="summary-tooltip-small">' + label + '</span><br/>' +
            '<span class="summary-tooltip-big">' + promoterRate + '</span>' +
            '</div>';
    }

    const chartSelectEvent = (chartId, chart) => {
        const selection = chart.getSelection()
        if (selection.length === 1) {
            const [selectedItem] = selection
            const params = {
                from: data[selectedItem.row].from,
                to: data[selectedItem.row].to,
                botbitGenerated: chartId === 2 || chartId === 3,
                publicPromoters: chartId === 3
            }
            const label = data[selectedItem.row].longLabel
            if (onSummaryClick) {
                onSummaryClick(label, params);
            }
        }
    }

    /*
    {
      "shortLabel": "8 ago",
      "longLabel": "8 agosto",
      "from": "2019-08-08T00:00:00",
      "to": "2019-08-09T00:00:00",
      "score": 4.0645,
      "reviews": 31,
      "botbitGeneratedReviewsMatchedByName": 0,
      "botbitGeneratedReviewsMatchedByTimestamp": 0,
      "botbitGeneratedReviews": 0,
      "botbitGeneratedReviewsRate": 0,
      "manualReplies": 32,
      "autoReplies": 1,
      "replies": 33,
      "manualReplyRate": 104,
      "autoReplyRate": 4,
      "replyRate": 107,
      "publicPromoterReviews": 14,
      "botbitPromoterReviews": 0,
      "promoterRate": 0
    },*/
    scores.push([
        { role: 'domain' },
        { role: 'data', label: t("score") },
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { role: 'style' }
    ])
    reviews.push([
        { role: 'domain' },
        { role: 'data', label: 'Cantidad de reseñas' },
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { role: 'style' }
    ])
    botbitReviews.push([
        { role: 'domain' },
        { role: 'data', label: 'Reseñas estimuladas por Ray' },
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { role: 'style' }
    ])
    promoterRate.push([
        { role: 'domain' },
        { role: 'data', label: 'Tasa' },
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { role: 'style' }
    ])
    replyRate.push([
        { role: 'domain' },
        { role: 'data', label: 'Manual' },
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { role: 'data', label: 'Automáticos' },
        { sourceColumn: 0, role: 'annotation', type: 'string', calc: 'stringify' },
        { role: 'tooltip', type: 'string', 'p': { 'html': true } },
        { role: 'style' }
    ])
    if (data) {
        data.forEach(item => {
            const scoreRounded = round(item.score, 2);
            scores.push([
                // domain
                item.shortLabel,
                // data: score
                scoreRounded,
                // annotation
                (data.length <= 8) ? scoreRounded : null,
                // tooltip
                createScoreTooltipContent(item.longLabel, scoreRounded),
                // style
                null
                //'fill-color: #4285f4' => primary-color
                //'fill-color: #cce5ff;stroke-width: 1;stroke-color: #004085'
            ]);
            reviews.push([
                // domain
                item.shortLabel,
                // data: reviews
                item.reviews,
                // annotation
                (data.length <= 8) ? item.reviews : null,
                // tooltip
                createReviewsTooltipContent(item.longLabel, item.reviews),
                // style
                null
                //'fill-color: #4285f4' => primary-color
                //'fill-color: #cce5ff;stroke-width: 1;stroke-color: #004085'
            ]);
            botbitReviews.push([
                // domain
                item.shortLabel,
                // data: Reseñas estimuladas por BotBit
                item.botbitGeneratedReviews,
                // annotation
                (data.length <= 8 && item.botbitGeneratedReviews > 0) ? item.botbitGeneratedReviews : null,
                // tooltip
                createBotBitReviewsTooltipContent(item.longLabel, item.botbitGeneratedReviews),
                // style
                null
            ]);
            promoterRate.push([
                // domain
                item.shortLabel,
                // data
                item.promoterRate / 100,
                // annotation
                (data.length <= 8 && item.promoterRate > 0) ? item.promoterRate + '%' : null,
                // tooltip
                createPromoterRateTooltipContent(item.longLabel, (item.promoterRate) ? item.promoterRate + '%' : '0%'),
                // style
                null
            ]);
            replyRate.push([
                // domain
                item.shortLabel,
                // data: Manual
                item.manualReplyRate / 100,
                // tooltip
                createReplyRateTooltipContent(item.longLabel, item.manualReplies, (item.manualReplyRate) ? item.manualReplyRate + '%' : '0%', item.autoReplies, (item.autoReplyRate) ? item.autoReplyRate + '%' : '0%'),
                // data: Automatic
                item.autoReplyRate / 100,
                // annotation
                (data.length <= 8 && item.replyRate > 0) ? item.replyRate + '%' : null,
                // tooltip
                createReplyRateTooltipContent(item.longLabel, item.manualReplies, (item.manualReplyRate) ? item.manualReplyRate + '%' : '0%', item.autoReplies, (item.autoReplyRate) ? item.autoReplyRate + '%' : '0%'),
                // style
                null
                //'fill-color: #cce5ff;stroke-width: 1;stroke-color: #004085' // primary-color
            ]);
        });
    }
    const items = [
        <MDBCard key="0" className="card-body m-2">
            <MDBContainer>
                <MDBRow className="chart-summary-row">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ColumnChart"
                        loader={<div>Cargando...</div>}
                        data={scores}
                        options={{
                            chartArea: { 'width': (data.length > 8) ? '80%' : '100%', 'height': '70%' },
                            title: t("score"),
                            legend: { position: 'none' },
                            bar: { groupWidth: '90%' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxis: {
                                minValue: 0,
                                maxValue: 5
                            },
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            }
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(0, chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow>
                    <span className="summary-legend">{t("scoreEvolution")}</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>,
        <MDBCard key="1" className="card-body m-2">
            <MDBContainer>
                <MDBRow className="chart-summary-row">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ColumnChart"
                        loader={<div>Cargando...</div>}
                        data={botbitReviews}
                        options={{
                            title: t("botbitStimulatedReviews"),
                            chartArea: { 'width': (data.length > 8) ? '80%' : '100%', 'height': '70%' },
                            bar: { groupWidth: '90%' },
                            legend: { position: 'none' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxis: {
                                minValue: 0,
                                maxValue: 1
                            },
                            isStacked: 'absolute',
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            }
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(2, chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow>
                    <span className="summary-legend">{t("quantityReviews")}</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>,
        <MDBCard key="2" className="card-body m-2">
            <MDBContainer>
                <MDBRow className="chart-summary-row">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ColumnChart"
                        loader={<div>Cargando...</div>}
                        data={promoterRate}
                        options={{
                            title: t("promotersPercent"),
                            chartArea: { 'width': (data.length > 8) ? '80%' : '100%', 'height': '70%' },
                            bar: { groupWidth: '90%' },
                            legend: { position: 'none' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxis: {
                                format: 'percent',
                                minValue: 0,
                                maxValue: 0.001
                            },
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            }
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(3, chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow>
                    <span className="summary-legend">{t("fiveStarsPercent")}</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>,
        <MDBCard key="3" className="card-body m-2">
            <MDBContainer>
                <MDBRow className="chart-summary-row">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ColumnChart"
                        loader={<div>Cargando...</div>}
                        data={replyRate}
                        options={{
                            title: t("overallResponseRate"),
                            chartArea: { 'width': (data.length > 8) ? '80%' : '100%', 'height': '70%' },
                            bar: { groupWidth: '90%' },
                            legend: { position: 'none' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxis: {
                                format: 'percent',
                                minValue: 0,
                                maxValue: 0.001
                            },
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            },
                            isStacked: true
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(4, chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow>
                    <span className="summary-legend">{t("responseRateEvolution.first")} <span className="auto-reply-color">{t("responseRateEvolution.second")}</span> {t("and")} <span className="manual-reply-color">{t("responseRateEvolution.third")}</span> {t("responseRateEvolution.fourth")}.</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>,
        <MDBCard key="4" className="card-body m-2">
            <MDBContainer>
                <MDBRow className="chart-summary-row">
                    <Chart
                        chartLanguage={require('browser-locale')()}
                        width={'100%'}
                        height={'100%'}
                        chartType="ColumnChart"
                        loader={<div>Cargando...</div>}
                        data={reviews}
                        options={{
                            labels: 'value',
                            title: t("reviewsQuantity"),
                            chartArea: { 'width': (data.length > 8) ? '80%' : '100%', 'height': '70%' },
                            bar: { groupWidth: '90%' },
                            legend: { position: 'none' },
                            label: 'none',
                            titleTextStyle: {
                                fontSize: 15,
                                color: '#676a6c',
                                bold: false
                            },
                            hAxis: {
                                slantedText: false
                            },
                            vAxis: {
                                minValue: 0,
                                maxValue: 1
                            },
                            tooltip: {
                                isHtml: true,
                                ignoreBounds: false
                            },
                        }}
                        chartEvents={[{
                            eventName: 'select',
                            callback: ({ chartWrapper }) => chartSelectEvent(1, chartWrapper.getChart())
                        }]}
                    />
                </MDBRow>
                <MDBRow>
                    <span className="summary-legend">{t("reviewsEvolution")}</span>
                </MDBRow>
            </MDBContainer>
        </MDBCard>
    ];


    return <Carousel
        autoPlay={false}
        fadeOutAnimation={true}
        mouseDragEnabled={true}
        responsive={
            {
                600: {
                    items: 4
                },
                1200: {
                    items: 4
                }
            }
        }
        disableAutoPlayOnAction={true}
        buttonsDisabled={true}
        infinite={false}
        items={items}
    />
}
DashboardSummary.translations = {
    ...translations
}
export default translate(DashboardSummary)