import React from 'react'
import { MDBContainer, MDBIcon, MDBSwitch, MDBBadge, MDBBtn, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact'
import { Toast, toast, Modal, ModalBody } from 'botbit-ui-components'
import './MinimalEditor.css'
import { MinimalEditorBox } from './MinimalEditorBox'
import { getImagesForCompany } from '../../../api/companyApi';
import { updateCampaign, getCampaignDetail, getCampaignProductCategories, switchCampaignActive, getStoresActivableSmartCampaign } from '../../../api/campaignsApi';
import { Formik, Form } from 'formik';
import { getBenefit, benefitIsEmpty, saveCampaign, validationSchema, PreviewModal, handlePreview } from './Helper'
import { containers } from './ContainerHelper'
import TestEmail from '../TestEmail/TestEmail'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const containerWhiteList = [
  containers.segment.name,
  containers.benefit.name,
  containers.responseEmailsAndDisplay.name,
  containers.messageAndSubject.name,
  containers.image.name,
  containers.button.name,
  containers.sendDates.name
]


const TRANSLATIONS_PREFIX = "panels.campaigns.minimalEditor."

export default class MinimalEditor extends React.Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef();
    this.state = {
      imagesToSet: [],
      isEditing: '',
      isMobile: (window.innerWidth < 576)
    }
    this.handleCancelEdit = this.handleCancelEdit.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  componentDidMount() {
    getCampaignDetail(this.props.campaignId)
      .then((res) => {
        this.setState({
          ...this.state,
          campaignBeingEdited: res
        })
        getStoresActivableSmartCampaign(res.companyId, res.campaignSubCategory.id, this.props.campaignId)
          .then((res) => {
            this.setState({
              storesActivableSmartCampaign: (res.data.data ? res.data.data : [])
            })
          }).catch((error) => {
            this.setState({ ...this.state });
            console.log(error)
          })

        getImagesForCompany(res.companyId)
          .then((responseImages) => {
            let imagesToSet = responseImages.data.data ? responseImages.data.data : [];
            this.setState({
              images: imagesToSet
            })
          }).catch((error) => {
            this.setState({ ...this.state });
          })
      })
    let productCategories = [];
    let productCategoriesArray = [];
    getCampaignProductCategories(this.props.campaignId)
      .then((res => {
        productCategories = res;
        for (var i in productCategories) {
          productCategoriesArray.push(productCategories[i])
        }
        this.setState({
          productCategoriesArray: productCategoriesArray
        })
      }))
  }

  handleSwitchChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    switchCampaignActive(this.state.campaignBeingEdited.id, this.state.campaignBeingEdited.active)
      .then((res) => {
        this.setState({
          campaignBeingEdited: {
            ...this.state.campaignBeingEdited,
            active: !this.state.campaignBeingEdited.active
          }
        })
        toast(this.props.t('panels.campaigns.otherLabels.actions.campaign' + (!this.state.campaignBeingEdited.active ? 'Deactivated' : 'Activated')) + ' ' + this.state.campaignBeingEdited.name,
          { containerId: "minimal-editor-toast" })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  minimalEditorHeader = () => {
    const subCategoryName = this.state.campaignBeingEdited.campaignSubCategory.subCategoryName;
    return <div className="minimalEditorHeader">
      <div className="editCampaignTitle">
        <span className={this.modeIsEdit() ? "text-truncate" : "crop-text-2"} >
          {this.modeIsEdit() ?
            this.props.t(TRANSLATIONS_PREFIX + "editTitle") + " " + this.state.campaignBeingEdited.name
            : this.props.t("panels.campaigns.newCampaign.smartCampaignWizard.smartCampaignOfCategory",
              {
                'CATEGORY':
                  this.props.t("panels.campaigns.otherLabels." + subCategoryName)
              })
          }
        </span>
        {this.modeIsEdit() && <MDBSwitch
          checked={this.state.campaignBeingEdited.active}
          onChange={(event) => this.handleSwitchChange(event)}
          labelLeft=""
          labelRight=""
          className="d-inline-block" />}
        {this.modeIsEdit() && <MDBBadge color="warning" className="smartBadge shadow-none">
          <MDBIcon far icon="lightbulb" /> {this.props.t(TRANSLATIONS_PREFIX + "smartCampaign")}
        </MDBBadge>}
      </div>
      {!this.state.isMobile && this.renderActions("justify-content-between")}
    </div>
  }

  handlePrimaryAction = () => {
    if (this.props.mode === "edit") {
      this.handlePreview('open')
    } else {
      this.emptyBenefitWarning(() => {
        window.history.pushState({ newStep: 5 }, "step 5", "?step=5")
        switchCampaignActive(this.state.campaignBeingEdited.id, false).then(res => {
          if (this.props.onClose !== undefined) {
            this.props.onClose();
          } else {
            this.props.onBack();
          }
        })
          .catch((error) => {
            console.log(error)
          })
      })
    }
  }

  handleSaveAndClose = () => {
    this.emptyBenefitWarning(() => {
      //it's already saved, just close.
      if (this.props.onClose !== undefined) {
        this.props.onClose()
      } else {
        this.props.onBack()
      }
    })
  }


  emptyBenefitWarning = (onSuccess) => {
    if (benefitIsEmpty(this.formRef.current.state.values.campaign)) {
      MySwal.fire({
        title: this.props.t(`panels.campaigns.maximalEditor.benefitEmptyTitle`),
        text: this.props.t(`panels.campaigns.maximalEditor.benefitEmptyDesc2`),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.props.t(`panels.campaigns.maximalEditor.exit`),
        cancelButtonText: this.props.t(`panels.campaigns.maximalEditor.cancel`),
        confirmButtonColor: '#1ab394'
      })
        .then((result) => {
          if (result.value) onSuccess();
        });
    } else {
      onSuccess();
    }
  }

  modeIsEdit = () => {
    return this.props.mode === "edit";
  }

  getCampaign = () => {
    return this.formRef.current && this.formRef.current.state.values.campaign ? this.formRef.current.state.values.campaign : this.state.campaignBeingEdited;
  }

  testEmail = () => {
    return (
      <>
        <Modal isNested className={"testEmailModalParent"} centered isOpen={this.state.emailModalIsOpen} toggle={() => this.setState({ emailModalIsOpen: false })}>
          <ModalBody className="testEmailModal">
            <TestEmail
              campaign={this.getCampaign()}
              closeModal={() => this.setState({ emailModalIsOpen: false })}
              onSuccess={() => toast(this.props.t(`panels.campaigns.maximalEditor.testEmailSuccessful`, { containerId: "minimal-editor-toast" }))}
            />
          </ModalBody>
        </Modal>
      </>
    )
  }

  goToMaximalEditor = () => {
    this.props.history.push(`/campaigns/${this.state.campaignBeingEdited.id}/edit`)
  }

  renderActions = (className) => {
    const primaryActionLabel = (this.modeIsEdit() ? "previsualize" : "activateAndClose");
    return <div className={"d-flex align-items-center " + className}>
      <MDBDropdown>
        <MDBDropdownToggle outline caret color="primary">
          {this.props.t(TRANSLATIONS_PREFIX + "moreOptions")}
        </MDBDropdownToggle>
        <MDBDropdownMenu basic>
          <MDBDropdownItem onClick={() => this.setState({ emailModalIsOpen: true })}>{this.props.t(TRANSLATIONS_PREFIX + "testMail")}</MDBDropdownItem>
          {!this.props.disableMaximalEdition &&
            <MDBDropdownItem onClick={this.goToMaximalEditor}>{this.props.t(TRANSLATIONS_PREFIX + "advancedEditor")}</MDBDropdownItem>
          }
          {(this.modeIsEdit()
            ? null
            : <><MDBDropdownItem onClick={() => this.handlePreview("open")}>
              {this.props.t(TRANSLATIONS_PREFIX + "previsualize")}
            </MDBDropdownItem>
              <MDBDropdownItem onClick={() => this.handleSaveAndClose()}>
                {this.props.t(TRANSLATIONS_PREFIX + "saveWithoutActivating")}
              </MDBDropdownItem>
            </>)}
        </MDBDropdownMenu>
      </MDBDropdown>
      <MDBBtn color="primary" className={primaryActionLabel + "Btn"} onClick={() => this.handlePrimaryAction()}>
        {this.props.t(TRANSLATIONS_PREFIX + primaryActionLabel)}
      </MDBBtn>
    </div>
  }

  mobileFooter = () => {
    return (
      <div>
        {this.state.isEditing !== '' ?
          <div className="d-flex modalSaveBtnsFooter">
            <MDBBtn outline color="primary" onClick={() => this.handleCancelEdit()}>
              {this.props.t(TRANSLATIONS_PREFIX + "cancelBtn")}
            </MDBBtn>
            <MDBBtn color="primary" onClick={() => this.handleSave()}>{this.props.t(TRANSLATIONS_PREFIX + "saveBtn")}</MDBBtn>
          </div>
          : this.renderActions("modalBtnsFooter")
        }
      </div>
    )
  }

  handleCancelEdit() {
    this.setState({
      isEditing: ''
    })
    this.formRef.current.resetForm()
  }

  messageNotCustomModal = () => {
    return (
      <Modal isNested isOpen={this.state.benefitMessageIsCustomModalOpen} fullscreen centered={true} id="messageNotCustomModal">
        <ModalBody style={{ paddingBottom: '17px' }}>
          <p style={{
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: '600'
          }}>
            {(this.props.t('panels.campaigns.minimalEditor.benefitMore.customModal.title'))}
          </p>
          <p style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '19px'
          }}>
            {(this.props.t('panels.campaigns.minimalEditor.benefitMore.customModal.body1'))}
            <br />
            {(this.props.t('panels.campaigns.minimalEditor.benefitMore.customModal.body2'))}

          </p>
          <div className="text-right">
            <MDBBtn color="primary" onClick={() => { this.handleBenefitMessage('accept') }} style={{ width: '146px' }} size="sm" className="text-capitalize shadow-none font-size-small">
              <strong style={{ fontSize: '14px' }}>
                {(this.props.t('panels.campaigns.minimalEditor.benefitMore.customModal.yesButton'))}
              </strong>
            </MDBBtn>
            <button className="text-capitalize link-like-btn font-size-small" style={{
              fontSize: '14px',
              color: '#19AA89',
              fontWeight: '600',
              lineHeight: '19px',
              marginLeft: "17px"
            }} onClick={() => { this.handleBenefitMessage('cancel') }}>
              {(this.props.t('panels.campaigns.minimalEditor.benefitMore.customModal.noButton'))}
            </button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  genericContainer = (containerName, bodyContent, editable, containerClassName) => {
    return (
      <MinimalEditorBox
        key={containerName}
        containerName={containerName}
        bodyContent={bodyContent}
        editable={editable}
        t={this.props.t}
        TRANSLATIONS_PREFIX={TRANSLATIONS_PREFIX}
        isEditing={this.state.isEditing === containerName}
        setIsEditing={this.handleEdit}
        campaign={this.state.campaignBeingEdited}
        formik={this.formRef}
        handleSave={this.handleSave}
        handleCancelEdit={this.handleCancelEdit}
        containerClassName={containerClassName}
        isMobile={this.state.isMobile}
        hide={(containerName === containers.button.name && !this.state.campaignBeingEdited.countAsWT)}
      />
    )
  }

  cleanBody = (key) => {
    return this.genericContainer(
      containers[key].name,
      containers[key].renderFunction({
        key: containers[key].name,
        isEditing: this.state.isEditing === containers[key].name,
        t: this.props.t,
        campaignBeingEdited: this.formRef.current.state.values.campaign,
        TRANSLATIONS_PREFIX,
        availableStores: this.props.availableStores,
        storesActivableSmartCampaign: this.state.storesActivableSmartCampaign,
        formRef: this.formRef,
        productCategoriesArray: this.state.productCategoriesArray,
        images: this.state.images,
        companyId: this.props.companyId,
        submitForm: this.submitForm,
        showLabels: true
      }),
      containers[key].isEditable)
  }

  bodyContainer = () => {
    const campaignSubCategoryName = this.state.campaignBeingEdited.campaignSubCategory.subCategoryName
    const subCategoryLabel = this.props.t("panels.campaigns.otherLabels." + campaignSubCategoryName);
    const subTitle = !this.modeIsEdit()
      && ((this.state.isMobile && this.state.isEditing === '') || !this.state.isMobile)
      && <h2>
        {this.props.t(
          TRANSLATIONS_PREFIX + "readyToActivate",
          { "CATEGORY": subCategoryLabel })}
      </h2>

    const allowedContainers = Object.keys(containers).filter(key => containerWhiteList.includes(key));
    let cleanBody;
    if (this.state.isMobile && this.state.isEditing !== '') {
      cleanBody = this.cleanBody(this.state.isEditing);
    } else {
      cleanBody = allowedContainers.map(key => {
        return this.cleanBody(key);
      });
    }

    return <>
      {!this.state.isMobile && this.minimalEditorHeader()}
      <MDBContainer id="bodyContainer" className={(this.state.isEditing !== '' ? 'editingBody' : '')}>
        {this.state.isMobile && this.state.isEditing === '' && this.minimalEditorHeader()}
        {subTitle}
        {cleanBody}
      </MDBContainer>
    </>
  }

  campaignNoErrors = (state) => {
    if (state.errors.discount && state.values.campaign.benefitTypeId === 2) {
      return false
    }
    return true
  }

  handleSave = () => {
    let campaign = this.formRef.current.state.values.campaign;

    let benefitTypeIdChanged = (campaign.benefitTypeId !== this.state.campaignBeingEdited.benefitTypeId);

    let discountChanged = (campaign.discount !== this.state.campaignBeingEdited.discount)

    let campaignBenefitIsEmpty = benefitIsEmpty(campaign)

    let productCategoryChanged;
    if (campaign.productCategory && this.state.campaignBeingEdited.productCategory) {
      productCategoryChanged = (campaign.productCategory.id !== this.state.campaignBeingEdited.productCategory.id)
    } else {
      productCategoryChanged = (campaign.productCategory !== this.state.campaignBeingEdited.productCategory);
    }

    let customProductNameChanged = (campaign.customProductName !== this.state.campaignBeingEdited.customProductName);

    let messageIsCustomAndChangedBenefit = (campaign.benefitMessageIsCustom && (benefitTypeIdChanged || discountChanged || productCategoryChanged || customProductNameChanged))


    if (messageIsCustomAndChangedBenefit && !campaignBenefitIsEmpty) {
      this.setState({
        benefitMessageIsCustomModalOpen: true
      })
    } else {
      this.formRef.current.submitForm()
    }
  }

  handleBenefitMessage = (action) => {
    if (action === 'accept') {
      this.formRef.current.setFieldValue('campaign.benefitMessageIsCustom', false)
      this.formRef.current.setFieldValue('campaign.benefitMessage', getBenefit(this.formRef.current.state.values.campaign, this.props.t, this.state.productCategoriesArray))
      setTimeout((() => this.formRef.current.submitForm()), 100)
    } else {
      this.formRef.current.submitForm()
    }
    this.setState({
      isEditing: '',
      benefitMessageIsCustomModalOpen: false
    })
  }

  handleEdit = (containerName) => {
    this.setState({
      isEditing: containerName
    })
  }
  submitForm(values) {
    this.saveCampaign(values);
  }

  getInitialValues = campaign => {
    const initialValues = {};
    initialValues.campaign = campaign; //JSON.parse(JSON.stringify(campaign));
    initialValues.campaign.campaignSubjectIsValid = true;
    if (campaign.segment && campaign.segment.filters) {
      campaign.segment.filters.forEach(field => {
        initialValues["filter_" + field.id] = field.operatorValues; //JSON.parse(JSON.stringify(field.operatorValues));
      });
    }
    return initialValues;
  }


  saveCampaign = data => {
    this.setState({
      isEditing: ''
    }, () => {
      this.props.setLoading(true)
    })
    saveCampaign(data,
      this.props.t,
      this.state.productCategoriesArray,
      updateCampaign
    ).then(response => {
      this.setState({
        campaignBeingEdited: {
          ...response,
        }
      })
      this.props.setLoading(false);
    });
  }

  setPreviewIsOpen = (isOpen) => {
    return new Promise((resolve) => {
      this.setState({
        preview: isOpen
      }, () => resolve())
    })
  }

  handlePreview(action) {
    handlePreview(this.setPreviewIsOpen, (action === "open"));
  }

  onBack = () => {
    if (this.modeIsEdit()) {
      this.emptyBenefitWarning(this.props.onBack);
    } else {
      this.props.onBack();
    }
  }

  render() {
    if (!this.state.campaignBeingEdited) {
      return null;
    }
    let initialValues = this.getInitialValues(this.state.campaignBeingEdited);
    return (
      <div className="minimalEditorContainer">
        <MDBContainer>
          <PreviewModal campaignName={this.state.campaignBeingEdited.name}
            campaignSubject={this.state.campaignBeingEdited.subject}
            campaignId={this.state.campaignBeingEdited.id}
            isOpen={this.state.preview}
            close={() => this.handlePreview("close")}
            t={this.props.t}
            isNested />
          {this.messageNotCustomModal()}
          {this.testEmail()}
          <Toast containerId="minimal-editor-toast" autoClose={4000} />
          <Formik
            ref={this.formRef}
            initialValues={initialValues}
            onSubmit={(values) => this.submitForm(values)}
            validationSchema={validationSchema(this.props.t, true, false)}
          >
            <Form>
              <MDBIcon icon="arrow-left" id="backIcon" fas="true" onClick={this.state.isEditing !== '' ? () => this.handleCancelEdit() : this.onBack} className="closeModalIcon w-100 pb-3" />
              {this.formRef.current !== null &&
                this.bodyContainer(this.formRef)
              }
            </Form>
          </Formik>
        </MDBContainer>
        {this.state.isMobile && this.mobileFooter()}
      </div>
    )
  }
}
