import React from 'react';
import { MDBBtn, MDBIcon, MDBBtnFixedItem } from "mdbreact";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import FixedMenu from "./BotBitFixedMenu.js";
import PropTypes from 'prop-types';




/* -------------
 * BotBitActions
 * -------------
 */
export default class BotBitActions extends React.Component {

	renderFixed(){
		return (
			<FixedMenu actions={this.props.actions}/>
		)
	}

	renderDefault(){
		let menuActions=[];
		let buttonActions=[];
		this.props.actions.forEach(
			action=>{
				//mapeo de configuracion al componente grafico
				if(action.primary){
					buttonActions.push(<Action renderMode={ActionRenderMode.DEFAULT} {...action}/>);
				}else{
					menuActions.push(<Action renderMode={ActionRenderMode.WITH_TEXT} {...action}/>)
				}
			}
		);
		return (
			<div className="botbit-actions-container" onClick={(e)=>{e.stopPropagation()}}>
				<span className="primary-actions">
					{[...buttonActions]}
				</span>
					{
						menuActions.length>0 &&
				<MDBDropdown className='d-inline'>
					<MDBDropdownToggle circle flat rounded>
						<MDBIcon size="lg" icon="ellipsis-v" style={{color:'black'}}
						/>
					</MDBDropdownToggle>
						<MDBDropdownMenu basic>
							{[...menuActions]}
						</MDBDropdownMenu>
				</MDBDropdown>
					}
			</div>
		)

	}

	render(){
		//console.log("render botbit actions")

		switch (this.props.renderMode){
			case RenderMode.DEFAULT:
				return this.renderDefault();
			case RenderMode.FIXED:
				return this.renderFixed();
			default:
				return null;
		}
	}
}

export const RenderMode = {
	DEFAULT:"DEFAULT",
	FIXED: "FIXED"
}

export const actionsConfigShape = PropTypes.shape({
	icon:PropTypes.string.isRequired,
	label:PropTypes.string.isRequired,
	primary:PropTypes.bool,
	tooltip:PropTypes.string,
	onClick: PropTypes.func.isRequired,
	color: PropTypes.string,
});

BotBitActions.propTypes = {
	actions: PropTypes.arrayOf(actionsConfigShape).isRequired,
	renderMode: PropTypes.oneOf([RenderMode.DEFAULT, RenderMode.FIXED])
}

/*------------
 *Action
 *------------
 */
export class Action extends React.Component {

	onClick = (event) => {
		if (event)
			event.stopPropagation();
		this.props.onClick(event);
	}

	render(){

		if (this.props.showAction) {
			if (this.props.showAction() === false)
				return null;
		}

		switch (this.props.renderMode){
			case ActionRenderMode.DEFAULT:
				return (
					<MDBBtn color="none" circle flat style={{color:'black'}} tag="a"  onClick={this.onClick} >
						<MDBIcon size="lg" icon={this.props.icon} />
					</MDBBtn>
				);
			case (ActionRenderMode.WITH_TEXT):
				return(
					<MDBDropdownItem onClick={this.onClick}>
						<MDBIcon className='pr-2' icon={this.props.icon} />
						{this.props.label}
					</MDBDropdownItem>
				);
			case (ActionRenderMode.FIXED):
				return (
					<MDBBtnFixedItem
						onClick={this.onClick}
						color={this.props.color}
						buttonStyle={this.props.buttonStyle}
						icon={this.props.icon}
					/>
				);
			default:
				return null;

		}
	}
}

export const ActionRenderMode = {
	DEFAULT:"DEFAULT",
	WITH_TEXT: "WITH_TEXT",
	FIXED: "FIXED"
}

Action.propTypes = {
	icon:PropTypes.string.isRequired,
	label:PropTypes.string.isRequired,
	renderMode:PropTypes.oneOf([ActionRenderMode.DEFAULT, ActionRenderMode.WITH_TEXT, ActionRenderMode.FIXED]).isRequired,
	buttonStyle: PropTypes.object,
	primary:PropTypes.bool,
	tooltip:PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	showAction: PropTypes.func
};



/*----------------------------------
 * Ejemplo de uso de BotBitActions
 *----------------------------------
 */
export class ActionsContainerExample extends React.Component {
	render() {
		let config = [
			{
				key:1,
				icon:"search",
				label:"Buscar",
				color:"blue",
				primary:true,
				onClick: ()=>{alert("buscar")},
			},
			{
				key:2,
				icon:"plus",
				label:"agregar",
				primary:true,
				onClick: ()=>{alert("agregar")}
			},
			{
				key:3,
				icon:"edit",
				label:"Editar",
				onClick: ()=>{alert("editar")}
			}
			,
			{
				key:4,
				icon:"share",
				label:"Compartir",
				onClick: ()=>{alert("compartir")}
			}

		];
		return (
			<div>
				<BotBitActions actions={config} renderMode={RenderMode.DEFAULT}/>
				<BotBitActions actions={config} renderMode={RenderMode.FIXED}/>
			</div>
		);
	}
}


