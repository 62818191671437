import React from 'react';
import { MDBInput } from 'mdbreact';
import { Select } from 'botbit-ui-components';
import { Field } from 'formik';
import '../CampaignFromAddress/CampaignFromAddress.css';
const TRANSLATIONS_PREFIX = 'panels.campaigns.minimalEditor.';

export default class CampaignReplyTo extends React.Component {
  toEmailType = (props) => props.form.values.campaign.toEmailType;

  renderInputTo = (props) => {
    if (props.form.values.campaign.toEmailType === undefined) {
      const formikState = props.form.values;
      if (
        formikState.campaign.replyToAddress === '' ||
        formikState.campaign.replyToAddress === null
      ) {
        props.form.setFieldValue('campaign.toEmailType', 'fromEmail');
        props.form.setFieldValue(
          'campaign.replyToAddress',
          formikState.campaign.fromAddress
        );
      } else if (
        formikState.campaign.replyToAddress ===
        formikState.campaign.company.replyTo
      ) {
        props.form.setFieldValue('campaign.toEmailType', 'company');
      } else if (
        formikState.campaign.replyToAddress === formikState.campaign.fromAddress
      ) {
        props.form.setFieldValue('campaign.toEmailType', 'fromEmail');
      } else {
        props.form.setFieldValue('campaign.toEmailType', 'custom');
      }
      return null;
    }

    const toErrors =
      props.form.errors &&
      props.form.errors.campaign &&
      props.form.errors.campaign.replyToAddress;
    let options = [
      {
        text: this.props.t(
          'panels.campaigns.minimalEditor.fromMore.useFromEmail'
        ),
        value: 'fromEmail',
        checked: this.toEmailType(props) === 'fromEmail',
      },
      {
        text: this.props.t(
          'panels.campaigns.minimalEditor.fromMore.useReplyCompanyEmail'
        ),
        value: 'company',
        checked: this.toEmailType(props) === 'company',
      },
      {
        text: this.props.t(
          'panels.campaigns.minimalEditor.fromMore.useCustomEmail'
        ),
        value: 'custom',
        checked: this.toEmailType(props) === 'custom',
      },
    ];

    if (!props.form.values.campaign.company.replyTo) {
      options = options.filter((o) => o.value !== 'company');
    }

    return (
      <div className="d-inline-block w-100">
        <Select
          id="fromEmailSelector"
          options={options}
          selected={this.props.t(
            'panels.campaigns.minimalEditor.fromMore.select'
          )}
          getValue={(values) => {
            if (values[0] !== undefined) {
              props.form.setFieldValue('campaign.toEmailType', values[0]);
              switch (values[0]) {
                case 'company':
                  props.form.setFieldValue(
                    props.field.name,
                    props.form.values.campaign.company.replyTo
                  );
                  break;
                case 'custom':
                  props.form.setFieldValue(props.field.name, '');
                  break;
                case 'fromEmail':
                  props.form.setFieldValue(
                    props.field.name,
                    this.props.formik.state.values.campaign.fromAddress
                  );
                  break;
                default:
                  break;
              }
            }
          }}
        />
        <div>
          {this.props.formik !== null && this.props.formik.state && (
            <>
              {this.props.formik.state.values.campaign.toEmailType ===
                'custom' && (
                <>
                  <MDBInput
                    name={props.field.name}
                    value={props.field.value}
                    className={toErrors ? 'error' : ''}
                    type="text"
                    onChange={(e) => {
                      if (props.field.value !== e.target.value)
                        props.form.setFieldValue(
                          props.field.name,
                          e.target.value
                        );
                    }}
                  />
                  {toErrors && <label>{toErrors}</label>}
                </>
              )}
              {this.props.formik.state.values.campaign.toEmailType !==
                'custom' && (
                <span className="fromEmailCompanyLabel">
                  {props.field.value}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="campaignFromToContainer">
        <div className="fromOrTo">
          <span className="d-inline-block float-left fromToLabel">
            {this.props.t(TRANSLATIONS_PREFIX + 'responses')}
          </span>
          <Field
            name={'campaign.replyToAddress'}
            render={(props) => {
              return (
                <>
                  {!this.props.isEditing ? (
                    <span className="d-inline-block font-weight-normal fromToMessage">
                      {props.field.value === ''
                        ? props.form.values.campaign.fromAddress
                        : props.field.value}
                    </span>
                  ) : (
                    this.renderInputTo(props)
                  )}
                </>
              );
            }}
          />
        </div>
      </div>
    );
  }
}
