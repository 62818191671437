import React from 'react';
import { Modal } from 'react-bootstrap';
import UserForm from './UserForm';
import {
	withRouter
} from 'react-router-dom'
import withEventTracking, { trackers } from '../../config/withEventTracking';


class SearchUser extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			openModal: false,
			storeSelectOptions: props.stores.map(
				store => {
					return {
						value: `${store.id}`,
						text: `${store.name}`,
						checked: props.selectedStore === store.id
					}
				}
			)

		}
	}

	storeSelectOptions = () => {
		return this.props.stores.map(
			store => {
				return {
					value: `${store.id}`,
					text: `${store.name}`,
				}
			}
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedStore === this.props.selectedStore) return;
		this.setState(
			{
				storeSelectOptions: this.props.stores.map(
					store => {
						return {
							value: `${store.id}`,
							text: `${store.name}`,
							checked: this.props.selectedStore === store.id
						}
					}
				)

			}
		);
	}

	onSubmit = (values) => {
		this.props.trackEvent('CUSTOMER_CREATED', undefined, true, [trackers.BACKEND]);
		this.props.createUser(values.user, values.store)
	}

	render() {
		return (
			<>
				<Modal
					show={this.props.show}
					onHide={this.props.closeModal}
					className='p0'
					dialogClassName={this.state.dialogClassName}
					contentClassName='overflow-auto'
					size="lg"
				>
					<Modal.Header closeButton>
						<Modal.Title>
							Crear usuario
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h3>{this.props.selectedStore ? this.props.selectedStore.name : ''}</h3>
						<UserForm buttonText="Continuar" store={this.props.selectedStore} user={this.props.user} onSubmit={this.onSubmit} storeSelectOptions={this.state.storeSelectOptions} />
					</Modal.Body>
					{
						this.state.footer &&
						(
							<Modal.Footer>
								{
									this.state.footer
								}
							</Modal.Footer>
						)
					}
				</Modal>
			</>
		)

	}
}

export default withEventTracking( withRouter(SearchUser))
