import React from 'react';
import { translations } from '../../../config/translations/Feedback/SentimentContainer';
import { translations as monthTranslations } from '../../../config/translations/Months';
import translate from '../../../config/translations/translate';
import { SectionTitle } from './SectionTitle';

const KeywordItem = ({ sentiment, index, changeKeywordFilter, highlight, t }) => {
	const hasPositiveData = sentiment.positiveWithoutNeutralPercentage || sentiment.positiveWithoutNeutralPercentage === 0
	const positivePercent = hasPositiveData && Math.round(sentiment.positiveWithoutNeutralPercentage);
	const zeroToTenPositiveWeight = (positivePercent || positivePercent === 0) ? Math.round(positivePercent / 10) : 'undefined'
	const keywordClass = highlight ? 'selected-keyword' : 'keyword'

	return <div className='keyword-summary-item mb-1' key={'sentiment-card-' + index}>
		<span className={`${keywordClass} foreground-zero-to-ten-${zeroToTenPositiveWeight}`} onClick={() => changeKeywordFilter(sentiment.keywordId)}>
			{sentiment.keyword}&nbsp;{hasPositiveData && ('(' + positivePercent + '%)')}
		</span>
	</div>
}

const isNumber = (number) => number || number === 0

const KeywordsSummary = ({ t, sentimentsByKeyword, changeKeywordFilter, selectedKeywords }) => {
	const sentimentsByKeywordSorted = sentimentsByKeyword && sentimentsByKeyword.sort((elementA, elementB) => {
		if (isNumber(elementA.positiveWithoutNeutralPercentage) && isNumber(elementB.positiveWithoutNeutralPercentage)) {
			return elementB.positiveWithoutNeutralPercentage - elementA.positiveWithoutNeutralPercentage
		}
		if (isNumber(elementA.positiveWithoutNeutralPercentage)) {
			return -1
		}
		if (isNumber(elementB.positiveWithoutNeutralPercentage)) {
			return 1
		}
		return 0
	})

	return <>
		<SectionTitle
			help={[t('keywordAnalysisSection.keywordSummary.help1'), t('keywordAnalysisSection.keywordSummary.help2'), t('keywordAnalysisSection.keywordSummary.help3')]}
			title={t('keywordAnalysisSection.keywordSummary.title')}
			name={'sentiment-month-keywords-summary'}
		/>
		<div className="scrollbar scrollbar-secondary mx-auto" style={{ height: '220px', overflowX: 'hidden', overflowY: 'auto' }}>
		{
			sentimentsByKeywordSorted && sentimentsByKeywordSorted.map((sentiment, index) =>
				<KeywordItem
					sentiment={sentiment}
					index={index}
					key={'sentiment-' + index}
					t={t}
					changeKeywordFilter={changeKeywordFilter}
					highlight={selectedKeywords.includes(sentiment.keywordId)}
				/>
			)
		}
		</div>
	</>

}

KeywordsSummary.translations = {
	...translations,
	...monthTranslations
};

export default translate(KeywordsSummary);
