export const required = value => {
	return value ? undefined : 'Requerido';
};

export const validEmail = (value) =>(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)?undefined:"Email inválido");


export const validURL = (value) =>(new RegExp('^(https?:\\/\\/)?'+ // protocol
                                                      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                                                      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
                                                      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
                                                      '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
                                                      '(\\#[-a-z\\d_]*)?$','i').test(value)?undefined:"URL inválida");
