import { loginConstants } from './loginConstants';

export function loginSuccess(user, rememberSesion) {
    return {
        type: loginConstants.LOGIN_SUCCESS,
        admin: user.administrator,
        token: user.token,
        isLogged: true,
        rememberSesion: rememberSesion,
        meta:{
            analytics:{
                category: 'User',
                action: 'Login success'
            }
        }
    }
}

export function loginFail(error) {
    return {
        type: loginConstants.LOGIN_FAILURE,
        isLogged: false,
        error: error,
        meta:{
            analytics:{
                category: 'User',
                action: 'Login fail'
            }
        }
    }
}

export const loginRequest = (user, password, rememberSesion) => ({
    type: loginConstants.LOGIN_REQUEST,
    payload : {
        user: user,
        password: password,
        rememberSesion: rememberSesion
    }
})

export const logoutRequest = () => ({
    type: loginConstants.LOGOUT
})