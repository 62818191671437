export const translations = {
    defaultLinkText: {
        es: "click aquí",
        es_ar: "click acá"
    },
    defaultBeforeLinkText: {
        es: " ",
        es_ar: " "
    },
    defaultAfterLinkText: {
        es: " ",
        es_ar: " ",
        en: " "
    }
}