import React from 'react';
import { MDBCountrySelector } from '../../commons/components/utils/form/CountrySelector';
import { Formik, Field, Form } from 'formik';
import { MDBSelect, MDBInput } from "mdbreact";
import { MediaGallery } from "botbit-ui-components"
import * as Yup from 'yup';
import { envConfig } from '../../config/config.js';
import { ModalFooter, ModalBody } from "botbit-ui-components";
import { useToken } from "../../commons/utils/token.js";
import './companies.css';
import { HexColorPicker } from 'react-colorful';
import useCategories from './useCategories.js';


const Error = ({ message }) => (
	<span className="text-danger position-relative" style={{ top: '-1em' }}>{message}</span>
)

const fieldsArray = {};

const LabelOrInput = (props) => {
	switch (props.mode) {
		case (Mode.select):
			return (
				<MDBSelect
					{...props}
					mode={undefined}
				/>
			);
		case (Mode.input):
			return (
				<MDBInput
					{...props}
					mode={undefined}
				/>
			);
		default:
			return (<div>
				<p className="m-0 mt-3  text-dark h-auto" style={{ fontSize: " 14px", lineHeight: " 16px", fontWeight: " 600", maxWidth: " 140px" }}>
					{props.label ? props.label.replace('*', '') : props.selected.replace('*', '')}
				</p>
				<p className="font-weight-normal text-dark m-0" style={{ fontStyle: " normal", fontSize: " 12px", lineHeight: " 16px" }}>
					{props.value ? props.value : '--'}
				</p>
			</div>);
	}
}

fieldsArray["restorando"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			className="botbit-form-input"
			label={formProps.t("panels.companies.form.restorando")}
			type="text"
			value={props.field.value}
			getValue={(value) => { props.form.setFieldValue('restorando', value) }}
		/>
	)
}


fieldsArray["pinterest"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			className="botbit-form-input"
			label={formProps.t("panels.companies.form.pinterest")}
			type="text"
			value={props.field.value}
			getValue={(value) => { props.form.setFieldValue('pinterest', value) }}
		/>
	)
}


fieldsArray["instagram"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			className="botbit-form-input"
			label={formProps.t("panels.companies.form.instagram")}
			type="text"
			value={props.field.value}
			getValue={(value) => { props.form.setFieldValue('instagram', value) }}
		/>
	)
}


fieldsArray["twitter"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			className="botbit-form-input"
			label={formProps.t("panels.companies.form.twitter")}
			type="text"
			value={props.field.value}
			getValue={(value) => { props.form.setFieldValue('twitter', value) }}
		/>
	)
}


fieldsArray["youtube"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			className="botbit-form-input"
			label={formProps.t("panels.companies.form.youtube")}
			type="text"
			value={props.field.value}
			getValue={(value) => { props.form.setFieldValue('youtube', value) }}
		/>
	)
}


fieldsArray["facebook"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			className="botbit-form-input"
			label={formProps.t("panels.companies.form.facebook")}
			type="text"
			value={props.field.value}
			getValue={(value) => { props.form.setFieldValue('facebook', value) }}
		/>
	)
}


fieldsArray["companyNameForCampaigns"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				className="botbit-form-input"
				label={formProps.t("panels.companies.form.nameFromCampaigns")}
				type="text"
				value={props.field.value}
				getValue={(value) => { props.form.setFieldValue('companyNameForCampaigns', value) }}
			/>
		</>
	);
}

fieldsArray["companySegmentId"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				options={[
					{ value: 1, text: 'Key Account', checked: props.field.value === 1 },
					{ value: 2, text: 'Longtail', checked: props.field.value === 2 },
					{ value: 3, text: 'Influencer', checked: props.field.value === 3 },
					{ value: 4, text: 'Pilot', checked: props.field.value === 4 }
				]}
				color="primary"
				selected={formProps.t('panels.companies.form.segment')}
				getValue={values => { if (values[0] !== props.field.value) props.form.setFieldValue('companySegmentId', values[0]) }}
			/>
			{props.form.errors.companySegmentId &&
				(props.form.touched.companySegmentId || props.form.submitCount > 0) &&
				<Error message={errors.companySegmentId} />
			}

		</>
	);
}

fieldsArray["isQrRedeem"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			options={[
				{ value: true, text: formProps.t('panels.companies.form.isQrRedeem.1'), checked: props.field.value === true },
				{ value: false, text: formProps.t('panels.companies.form.isQrRedeem.0'), checked: props.field.value === false }
			]}
			color="primary"
			selected={formProps.t('panels.companies.form.isQrRedeem.label')}
			getValue={values => { if (values[0] !== props.field.value) props.form.setFieldValue('isQrRedeem', values[0]) }}
		/>
	);
}

fieldsArray["replyTo"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				className="botbit-form-input"
				label={formProps.t("panels.companies.form.replyTo")}
				type="text"
				value={props.field.value}
				getValue={(value) => { props.form.setFieldValue('replyTo', value) }}
			/>
			{props.form.errors.replyTo &&
				(props.form.touched.replyTo || props.form.submitCount > 0) &&
				<Error message={errors.replyTo} />
			}
		</>
	);
}

fieldsArray["fromEmail"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				label={formProps.t("panels.companies.form.fromEmail")}
				type="text"
				className="botbit-form-input"
				value={props.field.value}
				getValue={(value) => { props.form.setFieldValue('fromEmail', value) }}
			/>
			{props.form.errors.fromEmail &&
				(props.form.touched.fromEmail || props.form.submitCount > 0) &&
				<Error message={errors.fromEmail} />
			}
		</>
	);
}

fieldsArray["locale"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				options={[
					{ value: "es_AR", text: formProps.t('panels.companies.form.locale.es_AR'), checked: props.field.value === "es_AR" },
					{ value: "en_US", text: formProps.t('panels.companies.form.locale.en_US'), checked: props.field.value === "en_US" },
					{ value: "pt_BR", text: formProps.t('panels.companies.form.locale.pt_BR'), checked: props.field.value === "pt_BR" },
					{ value: "fr_FR", text: formProps.t('panels.companies.form.locale.fr_FR'), checked: props.field.value === "fr_FR" }
				]}
				className="botbit-form-input"
				color="primary"
				selected={formProps.t('panels.companies.form.locale.label')}
				getValue={values => { if (values[0] !== props.field.value) props.form.setFieldValue('locale', values[0]) }}
			/>
			{props.form.errors.locale &&
				(props.form.touched.locale || props.form.submitCount > 0) &&
				<Error message={errors.locale} />
			}
		</>
	);
}

fieldsArray["name"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				className="botbit-form-input"
				label={formProps.t("panels.companies.form.name")}
				type="text"
				value={props.field.value}
				getValue={(value) => { props.form.setFieldValue('name', value) }}
			/>
			{props.form.errors.name &&
				(props.form.touched.name || props.form.submitCount > 0) &&
				<Error message={errors.name} />
			}
		</>
	);
}

const isNumeric = (value) => {
	return /^-?\d+$/.test(value);
}


fieldsArray["minDaysBetweenSameUserFeedbacks"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				className="botbit-form-input"
				label={formProps.t("panels.companies.form.minDaysBetweenSameUserFeedbacks.label")}
				type="number"
				value={props.field.value}
				getValue={(value) => {
					if (value === "") {
						props.form.setFieldValue('minDaysBetweenSameUserFeedbacks', undefined)
					} else if (isNumeric(value)) {
						if (parseInt(value) > 0) {
							props.form.setFieldValue('minDaysBetweenSameUserFeedbacks', value)
						}
					}
				}}
			/>
			{props.form.errors.minDaysBetweenSameUserFeedbacks &&
				(props.form.touched.locale || props.form.submitCount > 0) &&
				<Error message={errors.minDaysBetweenSameUserFeedbacks} />
			}
		</>
	);
}

fieldsArray["registrationBgColor"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				className="botbit-form-input"
				label={formProps.t("panels.companies.form.registrationBgColor")}
				type="text"
				value={props.field.value}
				getValue={(value) => {
					props.form.setFieldValue('registrationBgColor', value || "")
					// if (value === "") {
					// 	props.form.setFieldValue('registrationBgColor', undefined)
					// } else if (isNumeric(value)) {
					// 	if (parseInt(value) > 0) {
					// 		props.form.setFieldValue('registrationBgColor', value)
					// 	}
					// }
				}}
			/>
			<HexColorPicker color={props.field.value || ""} onChange = {(color) => {
				props.form.setFieldValue('registrationBgColor', color)
			}} />
			{props.form.errors.registrationBgColor &&
				(props.form.touched.locale || props.form.submitCount > 0) &&
				<Error message={errors.registrationBgColor} />
			}
		</>
	);
}


const noImageUrl = envConfig.staticImgUrl + "/stores/no-image.png";




const ImageField = (props, errors, formProps, mode) => {
	/* const baseUrl = 
		formProps.company.imgBaseURL ? 
		  envConfig.staticImgUrl + formProps.company.imgBaseURL : 
		  'common/img/tmp/'; */
	if (mode === Mode.view)
		return (
			<img className="img-thumbnail profile-image avatar"
				//src={ props.field.value ? baseUrl + props.field.value : noImageUrl } 
				src={props.field.value ? props.field.value : noImageUrl}
				alt={props.label} />

		);
	const token = useToken();
	return (
		<>
			{props.label}
			<div className="row">
				<img className="img-thumbnail profile-image avatar"
					src={props.field.value ? props.field.value : noImageUrl}
					alt="" />
				<MediaGallery
					images={formProps.images}
					onSelect={(res) => {
						let image;
						if (res.length > 0) {
							image = res[0].image;
						}
						props.form.setFieldValue(props.field.name, image);
					}}
					showPreview={false}
					multipleSelection={false}
					t={formProps.t}
					erasableChilds={true}
					triggerClassName="triggerBtn"
					deleteBtnMode="link"
					defaultSelected={formProps.company.logo ? [-1] : []}
					triggerIcon={props.field.value ? "pencil-alt" : "plus"}
					token={token}
					mode="upload"
					postUrl={
						envConfig.servicesUrl + "/upload"
						+ (formProps.company.id ? "?companyId=" + formProps.company.id : '')
					}
					deleteBtnClassName="deleteBtn"
					labels={
						{
							uploadTabTitle: formProps.t("panels.companies.form.logo.uploadTabTitle"),
							selectTabTitle: formProps.t("panels.companies.form.logo.selectTabTitle"),
							clickOrDragHere: formProps.t("panels.companies.form.logo.clickOrDragHere"),
							deleteBtn: formProps.t("panels.companies.form.logo.delete")
						}
					}
					triggerMode="button"
				/>

			</div>
		</>
	)
}

fieldsArray["logo"] = ImageField;
fieldsArray["bgMobile"] = ImageField;
fieldsArray["bgDesktop"] = ImageField;

fieldsArray["country"] = (props, errors, formProps, mode) => {
	if (mode === Mode.view)
		return (<LabelOrInput label={formProps.t("panels.companies.form.country")} value={props.field.value} mode={mode} />);
	return (
		<>
			<MDBCountrySelector
				value={props.field.value}
				getValue={
					(value) => {
						props.form.setFieldValue('country', value);
					}
				}
				label={formProps.t("panels.companies.form.country")}
				notUseDefault={true}
			/>
			{props.form.errors.country &&
				(props.form.touched.country || props.form.submitCount > 0) &&
				<Error message={errors.country} />
			}
		</>
	)
}
fieldsArray["urlPrefix"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			className="botbit-form-input"
			label={formProps.t("panels.companies.form.urlPrefix")}
			type="text"
			value={props.field.value}
			getValue={(value) => { props.form.setFieldValue('urlPrefix', value) }}
		/>
	)
}
fieldsArray["hotBotId"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			className="botbit-form-input"
			label={formProps.t("panels.companies.form.hotBotId")}
			type="text"
			value={props.field.value}
			getValue={(value) => { props.form.setFieldValue('hotBotId', value) }}
		/>
	)
}

fieldsArray["storeSelectorLabel"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			className="botbit-form-input"
			label={formProps.t("panels.companies.form.storeSelectorLabel")}
			type="text"
			value={props.field.value}
			getValue={(value) => { props.form.setFieldValue('storeSelectorLabel', value) }}
		/>
	)
}

fieldsArray["npsScoreScale"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				options={[
					{ value: "STARS", text: formProps.t("panels.companies.form.npsScoreScale.five"), checked: props.field.value === "STARS" },
					{ value: "NUMBERS", text: formProps.t("panels.companies.form.npsScoreScale.ten"), checked: props.field.value === "NUMBERS" },
				]}
				color="primary"
				selected={formProps.t('panels.companies.form.npsScoreScale.label')}
				label={formProps.t('panels.companies.form.npsScoreScale.label')}
				getValue={values => { 
					if (values[0] !== props.field.value) {
						props.form.setFieldValue('npsScoreScale', values[0]) 
						props.form.setFieldValue('showCustomQuestionsWhenScoreIsLessOrEqualThan', undefined)
					}
				}}
			/>
		</>
	);
}

fieldsArray["askUserDataForRegistration"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				options={[
					{ value: "ask", text: formProps.t("panels.companies.form.askUserDataForRegistration.ask"), checked: props.field.value === true },
					{ value: "dontAsk", text: formProps.t("panels.companies.form.askUserDataForRegistration.dontAsk"), checked: props.field.value === false },
				]}
				color="primary"
				selected={formProps.t('panels.companies.form.askUserDataForRegistration.label')}
				label={formProps.t('panels.companies.form.askUserDataForRegistration.label')}
				getValue={values => { 
					if (values.length === 1) {
						const askUserDataForRegistration = values[0] === "ask"
						if (askUserDataForRegistration !== props.field.value) {
							props.form.setFieldValue('askUserDataForRegistration', askUserDataForRegistration) 
						}
					}
				}}
			/>
		</>
	);
}

const getStarsOptionLabel = (value, t) => {
	return `${value} ${value === 1 ? 
		t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.star') : 
		t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.stars') 
	} ${t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.orLess') }`
}

const getPointsOptionLabel = (value, t) => {
	return `${value} ${value === 1 ? 
		t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.point') : 
		t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.points') 
	} ${t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.orLess') }`
}

const createShowCustomQuestionOptions = (npsScoreScale, actualValue, t) => {
	if (npsScoreScale === 'STARS') {
		return [0,1,2,3,4,5].map( (number) => {
			if (number === 0) {
				return { value: 0, text: t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.never'), checked: 0 === actualValue }
			} else if (number === 5) {
				return { value: 5, text: t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.always'), checked: 5 === actualValue }
			} else {
				return { value: number, text: getStarsOptionLabel(number, t), checked: number === actualValue }
			}

		})
	} else {
		return [0,1,2,3,4,5,6,7,8,9,10].map( (number) => {
			if (number === 0) {
				return { value: 0, text: t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.never'), checked: 0 === actualValue }
			} else if (number === 10) {
				return { value: 10, text: t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.always'), checked: 10 === actualValue }
			} else {
				return { value: number, text: getPointsOptionLabel(number, t), checked: number === actualValue }
			}

		})
	}
}

fieldsArray["showCustomQuestionsWhenScoreIsLessOrEqualThan"] = (props, errors, formProps, mode) => {
	return (
		<>
			<LabelOrInput
				mode={mode}
				options={createShowCustomQuestionOptions(props.form.values.npsScoreScale, props.field.value, formProps.t)}
				color="primary"
				selected={formProps.t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.label')}
				label={formProps.t('panels.companies.form.showCustomQuestionsWhenScoreIsLessOrEqualThan.label')}
				getValue={values => { 
					if (values[0] !== props.field.value) {
						props.form.setFieldValue('showCustomQuestionsWhenScoreIsLessOrEqualThan', values[0])
					}
				}}
			/>
		</>
	);
}

const CompanyCategoryInput = ({t, setValue, value}) =>{
	const categories = useCategories(t);

	if(!categories) return null;

	return <>
		<MDBSelect
			options={categories.map(c => ({value: c.id, text: c.name, checked: c.id === value}))}
			getValue={(newvalue) => {
				if(value!==newvalue[0])
					setValue(newvalue[0])
			}}
			label={t('panels.companies.form.companyCategory')}
			/>
	</>
}
fieldsArray["companyCategoryId"] = (props) => <CompanyCategoryInput {...props} />

fieldsArray["customNpsQuestion"] = (props, errors, formProps, mode) => {
	return (
		<LabelOrInput
			mode={mode}
			className="botbit-form-input"
			label={formProps.t("panels.companies.form.customNpsQuestion")}
			type="text"
			value={props.field.value}
			getValue={(value) => { props.form.setFieldValue('customNpsQuestion', value) }}
		/>
	)
}


const Mode = {
	view: 'view',
	input: 'input',
	select: 'select',
	other: 'other'
}


export default class CompanyForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			defaultSelected: (this.props.company.logo ? [-1] : [])
		}
		this.formRef = React.createRef();

	}

	componentDidMount() {
		if (this.props.company.logo) {
			this.formRef.current.setFieldValue("logo", this.props.company.logo);
		}
	}

	imageIndexInJson(imageArray, file) {
		var encodedFile = encodeURI(file);
		var i;
		for (i = 0; i < imageArray.length; i++) {
			if (imageArray[i].src === encodedFile) {
				return [i];
			}
		}
		return [];
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (
			nextProps.company !== this.props.company
			|| nextProps.images !== this.props.images
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.images && this.props.images) {
			this.setState({
				defaultSelected: ((this.props.company.logo && this.props.images) ? (this.imageIndexInJson(this.props.images, this.props.company.logo.toString())) : [])
			})
		}
	}

	onMediaGallerySelect(props, res) {
		let image;
		if (res.length > 0) {
			image = res[0].image;
		}
		props.form.setFieldValue(props.field.name, image);
	}

	render() {
		const { t, readOnly } = this.props;
		return (
			<div >
				<Formik
					ref={this.formRef}
					initialValues={this.props.company}
					onSubmit={this.props.onSubmit}
					validationSchema={Yup.object().shape({
						name: Yup.string().required(t("panels.companies.form.errors.name")),
						companySegmentId: Yup.number().required(t("panels.companies.form.errors.companySegmentId")),
						country: Yup.string().required(t("panels.companies.form.errors.country")),
						locale: Yup.string().required(t("panels.companies.form.errors.locale")),
						fromEmail: Yup.string().nullable().email(t("panels.campaigns.newCampaign.smartCampaignWizard.emailInvalid"))
							.required(t("panels.companies.form.errors.fromEmail")),
						replyTo: Yup.string().nullable().email(t("panels.campaigns.newCampaign.smartCampaignWizard.emailInvalid"))
							.required(t("panels.companies.form.errors.replyTo"))
					})}>
					{({ errors, touched, isSubmitting }) => {
						let body = (
							<div className="container">
								<div className="row">
									<div className="col">
										{
											this.props.images &&
											<Field
												name="logo"
												label={t(`panels.companies.form.logo.label`)}
												render={(props) => fieldsArray["logo"]({ ...props, label: t(`panels.companies.form.logo.label`) }, errors, this.props, readOnly ? Mode.view : Mode.other)}
											></Field>
										}
									</div>
								</div>
								<div className="row">
									<div className="col">
										{
											this.props.images &&
											<Field
												name="bgMobile"
												label={t(`panels.companies.form.logo.label`)}
												render={(props) => fieldsArray["logo"]({ ...props, label: t(`panels.companies.form.bgMobile.label`) }, errors, this.props, readOnly ? Mode.view : Mode.other)}
											></Field>
										}
									</div>
									<div className="col">
										{
											this.props.images &&
											<Field
												name="bgDesktop"
												label={t(`panels.companies.form.logo.label`)}
												render={(props) => fieldsArray["logo"]({ ...props, label: t(`panels.companies.form.bgDesktop.label`) }, errors, this.props, readOnly ? Mode.view : Mode.other)}
											></Field>
										}
									</div>
								</div>
								<div className="row overflow-auto">
									<div className="col">
										<Field
											name="name"
											render={(props) => fieldsArray["name"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col">

										<Field
											type="text"
											name="country"
											render={(props) => fieldsArray["country"](props, errors, this.props, readOnly ? Mode.view : Mode.select)}
										/>
									</div>
									<div className="col">
										<Field
											name="locale"
											render={(props) => fieldsArray["locale"](props, errors, this.props, readOnly ? Mode.view : Mode.select)}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Field
											name="fromEmail"
											render={(props) => fieldsArray["fromEmail"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										/>
									</div>
									<div className="col">
										<Field
											name="replyTo"
											render={(props) => fieldsArray["replyTo"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										/>
									</div>
								</div>
								{this.props.admin.objectAccess === 'ALL' &&
									<div className="row">
										<div className="col">
											<Field
												name="companySegmentId"
												render={(props) => fieldsArray["companySegmentId"](props, errors, this.props, readOnly ? Mode.view : Mode.select)}
											/>
										</div>
									</div>
								}
								<div className="row">
									<div className="col">
										<Field
											name="companyNameForCampaigns"
											render={(props) => fieldsArray["companyNameForCampaigns"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col">
										<Field
											name="urlPrefix"
											render={(props) => fieldsArray[props.field.name](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										/>
									</div>
									<div className="col">
										<Field
											name="hotBotId"
											render={(props) => fieldsArray[props.field.name](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Field name="facebook"
											render={(props) => fieldsArray["facebook"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										></Field>
									</div>
									<div className="col">
										<Field name="youtube"
											render={(props) => fieldsArray["youtube"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										></Field>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Field name="twitter"
											render={(props) => fieldsArray["twitter"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										></Field>
									</div>
									<div className="col">
										<Field name="instagram"
											render={(props) => fieldsArray["instagram"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										></Field>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Field name="pinterest"
											render={(props) => fieldsArray["pinterest"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										></Field>
									</div>
									<div className="col">
										<Field name="restorando"
											render={(props) => fieldsArray["restorando"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										></Field>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Field name="npsScoreScale"
											render={(props) => fieldsArray["npsScoreScale"](props, errors, this.props, readOnly ? Mode.view : Mode.select)}
										></Field>
									</div>
									<div className="col">
										<Field name="showCustomQuestionsWhenScoreIsLessOrEqualThan"
											render={(props) => fieldsArray["showCustomQuestionsWhenScoreIsLessOrEqualThan"](props, errors, this.props, readOnly ? Mode.view : Mode.select)}
										></Field>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Field name="askUserDataForRegistration"
											render={(props) => fieldsArray["askUserDataForRegistration"](props, errors, this.props, readOnly ? Mode.view : Mode.select)}
										></Field>
									</div>
									<div className="col">
										<Field name="customNpsQuestion"
											render={(props) => fieldsArray["customNpsQuestion"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										></Field>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Field name="storeSelectorLabel"
											render={(props) => fieldsArray["storeSelectorLabel"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										></Field>
									</div>
									<div className="col">
										<Field name="registrationBgColor"
											render={(props) => fieldsArray["registrationBgColor"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										></Field>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Field name="minDaysBetweenSameUserFeedbacks"
											render={(props) => fieldsArray["minDaysBetweenSameUserFeedbacks"](props, errors, this.props, readOnly ? Mode.view : Mode.input)}
										></Field>
									</div>
									<div className="col">
										<Field name="companyCategory"
											render={(props) => fieldsArray["companyCategoryId"]({t:t, setValue:(value)=>props.form.setFieldValue("companyCategory", value), value:props.form.values.companyCategory})}
										></Field>
									</div>
								</div>

							</div>

						)
						let footer = (
							<button
								type="submit"
								className="btn btn-primary float-rigth float-right"
							>
								{this.props.t('panels.companies.form.submit')}
							</button>
						)
						return (
							<Form>
								{
									this.props.inline ?
										<div className=' mb-5 pb-5'>
											{body}
											{!readOnly && footer}
										</div>
										:
										<>
											<ModalBody className="p-1">
												{body}
											</ModalBody>
											{!readOnly &&
												<ModalFooter>
													{footer}
												</ModalFooter>
											}
										</>
								}
							</Form>
						)
					}
					}
				</Formik>
			</div>
		)
	}
}
