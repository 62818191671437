import React, { useState, useEffect } from 'react';
import ActivateFirstCampaign from '../../ActivateFirstCampaign';
import { CollectionVisualizer } from 'botbit-ui-components';
import { getCampaignTemplates } from '../../../../api/campaignsApi';
import {
  MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabPane,
  MDBTabContent,
} from 'mdbreact';
import { envConfig } from '../../../../config/config';

const TRANSLATION_PREFIX_CAMPAIGNS = 'panels.campaigns.';
const TRANSLATION_PREFIX =
  TRANSLATION_PREFIX_CAMPAIGNS + 'newCampaign.selectCampaignType.';

export const SelectCampaignType = ({
  onBack,
  t,
  companies,
  selectedCompany,
  setSelectedCompany,
  setCampaignType,
  history,
}) => {
  const [activeTab, setActiveTab] = useState('1');
  const [renderTemplates, setRenderTemplates] = useState(true);

  const onSmartCampaignSelect = (type) => {
    setCampaignType(type);
  };

  const getCompanyName = () => {
    let companyName = '';
    if (selectedCompany) {
      const comp = companies.find(
        (c) => c.id.toString() === selectedCompany.toString()
      );
      if (comp) {
        companyName = comp.name;
      }
    }
    return companyName;
  };

  useEffect(() => {
    setRenderTemplates(false);
    setTimeout(() => {
      setRenderTemplates(true);
    }, 0);
  }, [selectedCompany]);

  const renderAllTemplates = (type) => (
    <CollectionVisualizer
      mode="grid"
      gridFormatter={(row) => {
        return (
          <TemplateCard
            name={
              type === 'LAYOUT'
                ? t(TRANSLATION_PREFIX_CAMPAIGNS + 'otherLabels.' + row.name)
                : row.name
            }
            preview={`${envConfig.imgUrl}${row.preview}`}
            onSelect={() =>
              history.push(
                `/campaigns/create?templateId=${row.id}&companyId=${selectedCompany}&templateType=${type}`
              )
            }
            isLayout={type === 'LAYOUT'}
          />
        );
      }}
      getData={(page) =>
        getCampaignTemplates(
          selectedCompany,
          page,
          type,
          type === 'LAYOUT' ? 100 : 12
        )
      }
      pagination={type === 'LAYOUT' ? false : 'button'}
      gridRowSize={{ xl: '2', md: '2', size: '6' }}
    />
  );

  const renderTabs = () => (
    <div id="customCampaignsContainer" className="classic-tabs">
      <MDBNav classicTabs className="mt-5">
        <MDBNavItem active={activeTab === '1'}>
          <MDBNavLink
            className="text-truncate"
            to="#"
            onClick={() => setActiveTab('1')}
          >
            {t(TRANSLATION_PREFIX + 'layout')}
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem active={activeTab === '2'}>
          <MDBNavLink
            className="text-truncate"
            to="#"
            onClick={() => setActiveTab('2')}
          >
            {t(TRANSLATION_PREFIX + 'predesigned')}
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem active={activeTab === '3'}>
          <MDBNavLink
            className="text-truncate"
            to="#"
            onClick={() => setActiveTab('3')}
          >
            {t(TRANSLATION_PREFIX + 'custom') + getCompanyName()}
          </MDBNavLink>
        </MDBNavItem>
      </MDBNav>
      <MDBTabContent className="card mb-5" activeItem={activeTab}>
        <MDBTabPane tabId="1">
          {renderTemplates && renderAllTemplates('LAYOUT')}
        </MDBTabPane>
        <MDBTabPane tabId="2">
          {renderTemplates && renderAllTemplates('THEME')}
        </MDBTabPane>
        <MDBTabPane tabId="3">
          {renderTemplates && renderAllTemplates('CUSTOM')}
        </MDBTabPane>
      </MDBTabContent>
    </div>
  );

  return (
    <>
      <button className="link-like-btn" onClick={onBack}>
        <MDBIcon icon="arrow-left" className="back-button" fas></MDBIcon>
      </button>
      <div className="new-campaign-header">
        <div>
          <h1>
            {t(TRANSLATION_PREFIX + 'newCampaignFor')}{' '}
            <span className="campaign-name-tilt">{getCompanyName()}</span>
          </h1>
        </div>
      </div>
      {renderTemplates && (
        <div className={'float-left w-100'}>
          <ActivateFirstCampaign
            title={t(TRANSLATION_PREFIX + 'smartCampaigns')}
            withHorizontalScroll
            onCardClick={onSmartCampaignSelect}
            companyId={selectedCompany}
          />
        </div>
      )}
      <div className="float-left w-100">
        <h3>{t(TRANSLATION_PREFIX + 'customCampaigns')}</h3>
        {renderTabs()}
      </div>
    </>
  );
};

const TemplateCard = ({ name, onSelect, preview, id, isLayout }) => {
  return (
    <div key={id} className="new-campaign-template-card" onClick={onSelect}>
      {isLayout ? (
        <img alt="" src={preview} />
      ) : (
          <div
            className="previewImage"
            style={{ backgroundImage: 'url(' + preview + ')' }}
          />
        )}
      <span>{name}</span>
    </div>
  );
};
