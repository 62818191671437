import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars as expandIcon } from "@fortawesome/free-solid-svg-icons";
// import { envConfig } from '../../../config/config';

export const NavBarHeader = ({ toggleNavigationBar }) => {
	return (
		<div className="navbar-header">
			{/* eslint-disable-next-line */}
			<a className="navbar-minimalize minimalize-styl-2 btn btn-primary" id="hamburguerBtn" onClick={() => toggleNavigationBar()}>
				<FontAwesomeIcon icon={expandIcon} />
			</a>
			{/* <span className="d-md-inline d-none ml-2">
				<img style={{ width: "75px" }} src={envConfig.rayLogoUrl} alt="logo" />
			</span> */}
		</div>
	)
};