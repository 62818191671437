import React from 'react';
import {Col, Row} from "react-bootstrap";
import IBox from '../../commons/components/inspinia/IBox';
import Number from '../../commons/components/utils/FormatedNumber';
import SmallWidget from '../../commons/components/inspinia/SmallWidget';
import './emailActivity.css';
import {translate} from 'react-i18next'

const getUseStatus = (percentageUsed) =>{
  if (percentageUsed < 50) {
    return 'success';
  }

  if (percentageUsed < 75) {
    return 'warning';
  }

  return 'danger';
};
const getPercentageUsed = (packStatus, availablePlan) =>{
  return  packStatus && availablePlan > 1 ? packStatus.percentage : 100;
};

const EmailDelivered = ({packStatus, dataBaseTotal, t}) => {
  if(!packStatus) return null;
  const availablePack = packStatus ? packStatus.availablePack : 0;
  const available = packStatus ? packStatus.available : 0;
  const planLimit = packStatus ? packStatus.planLimit : 0;
  const percentageUsed = getPercentageUsed(packStatus, packStatus ?  packStatus.availablePlan : 1);
  const planUsed = packStatus ? packStatus.planLimit - packStatus.availablePlan : 0;
  let useStatus = getUseStatus(percentageUsed);

  const smsPackLimit = packStatus.smsPack ? packStatus.smsPack : 0;
  const smsPlanLimit = packStatus.smsLimit ? packStatus.smsLimit : 0;
  const smsUsed = packStatus.smsCurrentMonthCount + packStatus.whatsappCurrentMonthCount;
  const smsAvailable = (smsPlanLimit + smsPackLimit)-smsUsed;
  const smsPercentageUsed = smsUsed < smsPlanLimit ? Math.round((smsUsed/smsPlanLimit)*100) : 100;
  return (
    <Row>
            <Col lg={12}>
              <IBox title={t("panels.emailActivity.smsPlanTittle")}>
                <Row>
                  <Col lg={4} className="align-content-center">
                    <SmallWidget type={useStatus}
                                 title={<span>{t("panels.emailActivity.currentPlanUse")} <b>(<Number number={smsUsed}/>/<Number number={smsPlanLimit}/>)</b></span>}
				 mainText={<span><Number number={smsPercentageUsed > 0 ? smsPercentageUsed : 0}/>%</span>}
                                 icon='tachometer-alt'
                                 className="email-widget"/>
                  </Col>
                  <Col lg={4} className="align-content-center">
                    <SmallWidget type='info' title={t("panels.emailActivity.available.pack")}
                                 mainText={<Number number={smsPackLimit}/>}
                                 icon='box'
                                 className="email-widget"/>
                  </Col>
                  <Col lg={4} className="align-content-center">
                    <SmallWidget type='info'
                                 title={t("panels.emailActivity.available.total")}
                                 mainText={<Number number={smsAvailable}/>}
                                 icon='heart'
                                 className="email-widget"/>
                  </Col>
                </Row>
              </IBox>
            </Col>
            <Col lg={12}>
              <IBox title={t("panels.emailActivity.planTittle")}>
                <Row>
                  <Col lg={4} className="align-content-center">
                    <SmallWidget type={useStatus}
                                 title={<span>{t("panels.emailActivity.currentPlanUse")} <b>(<Number number={planUsed}/>/<Number number={planLimit}/>)</b></span>}
								 mainText={<span>{percentageUsed > 0 ? percentageUsed : 0}%</span>}
                                 icon='tachometer-alt'
                                 className="email-widget"/>
                  </Col>
                  <Col lg={4} className="align-content-center">
                    <SmallWidget type='info' title={t("panels.emailActivity.available.pack")}
                                 mainText={<Number number={availablePack}/>}
                                 icon='box'
                                 className="email-widget"/>
                  </Col>
                  <Col lg={4} className="align-content-center">
                    <SmallWidget type='info'
                                 title={t("panels.emailActivity.available.total")}
                                 mainText={<Number number={available}/>}
                                 icon='heart'
                                 className="email-widget"/>
                  </Col>
                </Row>
              </IBox>
            </Col>
  </Row>)
};


export default translate('common')(EmailDelivered) 
