export const translations = {
  date: {
    es: "Fecha",
    en: "Date"
  },
  time: {
    es: "Hora",
    en: "Time"
  },
  type: {
    es: "Tipo",
    en: "Type"
  },
  source: {
    es: "Origen",
    en: "Source"
  },
  location: {
    es: "Ubicación",
    en: "Location"
  },
  groupSize: {
    es: "Cantidad",
    en: "Quantity"
  }
}