export const translations = {
    columns: {
        name: {
            es: "Nombre",
            en: "Name"
        },
        address: {
            es: "Dirección",
            en: "Address"
        }, 
        city: {
            es: "Ciudad",
            en: "City"
        },
        matchedStore: {
            es: "Tienda",
            en: "Store"
        }
    },
    modalTitle: {
        before: {
            es: "¿A qué tienda quieres conectar ",
            en: "Which store do you want to connect to ",
            ONLINE_ORDER: {
                es: "¿En dónde quieres visualizar a los clientes que realizan compras en ",
                en: "Where do you want to view the clients that buy in "
            },
            INQUIRY: {
                es: "¿En dónde quieres recibir los datos de tus clientes que te contactan en ",
                en: "Where do you want to view the clients that contact you in "
            },
            CONVERSATION: {
                es: "¿En dónde quieres recibir los datos de tus clientes que te contactan  ",
                en: "Where do you want to view the clients that contact you in "
            }
        },
        after: {
            es: "?",
            en: "?"
        }
    },
    selectAStore: {
        es: "Seleccione una tienda",
        en: "Select a store"
    },
    modalSubmit: {
        es: "Seleccionar",
        en: "Select"
    },
    submit: {
        es: "Finalizar",
        en: "Finish"
    },
    modal: {
        warning:{
            es: "Este sitio ya se encuentra conectada a la tienda",
            en: "This location is already connected to a store"
        }
    }
}