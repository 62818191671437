import React from 'react';
import ReactSelect from 'react-select';
import { Form } from 'react-bootstrap';

const selectStyle = {
    // El box principal
    control: (base, state) => {
      return {
        ...base,
        minHeight: 30,
        maxHeight: 30,
        borderColor: state.isFocused ? "#00B492" : "#e5e6e7",
        "&:hover": {  },
        boxShadow: null,
        backgroundColor: "#FFFFFF",
        cursor: "text",
        fontSize: "14px",
        borderRadius: "0px",
        color:'#676a6c'
      }
    },
    // cada una de las opciones cuando el select se despliega
    option: (base, state) => {
      return {
        ...base,
        backgroundColor: state.isFocused ? "#00B492" : "transparent",
        color: state.isFocused ? "#FFFFFF" : "#676a6c",
        boxSizing: "border-box",
        // top right bottom left
        padding: "5px 8px 5px 8px",
        margin: "2px 5px 2px 5px"
      }
    },
    // el combo desplegable
    menuList: (base, state) => {
      return {
        overflowX: "hidden",
        ...base
      }
    },
  };
export default class Select  extends React.Component {
    render = () => (
        <ReactSelect {...this.props} className='m-1' styles={selectStyle}/>
    )
}

export class SelectReduxForm extends React.Component {


	constructor(props){
		super(props);
		this.state = {}
	}

	handleChange = (o) => {
		this.setState({selected: o});
		this.props.input.onChange(o.value);
	}

	handleBlur = () => {
		this.props.input.onBlur(this.state.selected? this.state.selected.value: undefined);
	}

	render() {
		const {meta: {touched, error}, label, options} = this.props;
		let validationClasses = touched && error? "is-invalid" : "border-0";
		validationClasses+=' form-control p-0';

		return (
			<div className=''>
				<Form.Label className='pt-2'>{label}</Form.Label>
				<div className={validationClasses}>
					<ReactSelect {...this.props.input} 
						options={options} 
						value={this.state.selected} 
						isSearchable={true} 
						onChange={this.handleChange} 
						onBlur={this.handleBlur}
						styles={selectStyle}/>
				</div>
				{touched && error? <div className="invalid-feedback">{error}</div>:''}
			</div>
		)
	}

}

