import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import SockJs from 'sockjs-client';
import Stomp from 'stompjs';
import { useToken } from "../../commons/utils/token";
import {socketMessageAction, socketSubscriptionAction} from "../../commons/reporter/reporter"


import { envConfig } from '../../config/config';

const dummyFunction = ()=>{}

const defaultHandler = {
    conversationTopicMessageHandler : dummyFunction,
    storeTopicMessageHandler : dummyFunction
}

/**
 * @typedef StompClientReference
 * @property {Stomp.Client} current
 */

/**
 * 
 * @param {*} selectedChat 
 * @param {number} selectedStore 
 * @returns {StompClientReference}
 */
export const useChatStompClient = (selectedChat, selectedStore, adminId, handlers = {...defaultHandler}) =>{
    /**
     * @type {StompClientReference}
     */
    const stompClient = useRef();

    const token = useToken();

    const dispatch = useDispatch();

    //indica si el cliente esta conectado
    const [connected, setConnected] = useState(false);

    //indica si el cliente esta en error
    const [inError, setInError] = useState(false);

    const handler = (message)=>{
        const destination = message.headers.destination;
        if(!destination) return;
        dispatch(socketMessageAction(message));
        if(destination.startsWith('/topic/chat/conversation/')){
            handlers.conversationTopicMessageHandler(message.body)
            return;
        }
        if(destination.startsWith('/topic/chat/conversations/stores/')){
            handlers.storeTopicMessageHandler(message.body)
            return;
        }
    }


    const connectToTopic = (topic, handler) => {
       let subscription;
       do {
           subscription = stompClient.current.subscribe(topic, handler);
       } while (!subscription.id);
       dispatch(socketSubscriptionAction(topic));
       return subscription;
    }
          
    const selectedChatId = selectedChat ? selectedChat.id : undefined;
    // suscripción al  canal de la convesación seleccionada
    useEffect(()=>{
        if(connected && selectedChat && selectedChat.id){
            const topic = `/topic/chat/conversation/${selectedChat.id}`;
            //me suscribo al chat seleccionado
            const subscription = connectToTopic(topic, handler);
            //el saneamiento tienen que ser los unsuscribe
            return ()=>stompClient.current.unsubscribe(subscription.id)
        }

    }, [connected, selectedChatId])


    // suscripción al  canal de la tienda
    useEffect(()=>{
        if(connected && selectedStore && adminId){
            const topic = `/topic/chat/conversations/stores/${selectedStore}/administrators/${adminId}`;
            //me suscribo al chat seleccionado
            const subscription = connectToTopic(topic, handler);
            //el saneamiento tienen que ser los unsuscribe
            return ()=>stompClient.current.unsubscribe(subscription.id)
        }

    }, [connected, selectedStore]);

    const connect=()=>{
        let sock=new SockJs(`${envConfig.socketUrl}/ray-chat-websocket`);
        stompClient.current = Stomp.over(sock);
        stompClient.current.connect({ 'Authorization': token }, () => {
            setConnected(true);
        }, (error) => {
            setInError({error:error});
            setConnected(false);
        });

        stompClient.current.debug = function(str) {};
        return () => { 
            if(stompClient.current.connected)
                stompClient.current.disconnect() 
        }
    }

    useEffect(() => {
        return connect();
    }, [])

    useEffect(() => {
        if (!connected && inError){
            connect();
        }
    }, [inError, connected])

    return stompClient;
}

export const SocketMessageType = {
    MESSAGE_UPDATE: 'MESSAGE_UPDATE',
    MESSAGE_CREATED:'MESSAGE_CREATED',
    ISSUE_UPDATE: 'ISSUE_UPDATE',
    CONVERSATION_UPDATE: 'CONVERSATION_UPDATE'   
}
