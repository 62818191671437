import React from 'react';
import { MDBIcon } from 'mdbreact';
import { useMessengerData } from './useMessengerData';
import { MDBBadge } from "mdbreact";
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Messenger/Messenger";
import Moment from "react-moment"
import { getConversationById } from '../../api/messengerApi';

const ConversationItem = ({ conversation, t, selected, setClicked }) => {
    const { user, lastMessage, lastMessageTimestamp, totalMessagesUnreaded, channel } = conversation;
    if(!user) return null;
    const { fullName, phone } = user;
    const { selectedChat, setSelectedChat, chatsList, setChatList } = useMessengerData();
    // let imgsrc = `https://admin.botbit.io/static/img/avatars/${avatar}`;
    const renderLastMsgContent = () => {
        const { message, type } = lastMessage;
        const contentByType = {
            "STATE_CHANGE": t("stateChange"),
            "ASSIGNMENT": t("assignment"),
            "UNASSIGNMENT": t("unassignment"),
            "ISSUE_UPDATE": t("issueUpdate"),
            "TEXT": message
        }

        return contentByType[type] || "";
    }

    const getFormattedDate = () => {
        let format = "l";

        const today = new Date().setHours(0, 0, 0, 0);
        const yesterday = new Date().setDate(new Date(today).getDate() - 1);

        const msgDate = new Date(lastMessageTimestamp);
        const msgDateWithoutHours = new Date(msgDate).setHours(0, 0, 0, 0)

        switch (msgDateWithoutHours) {
            case today:
                format = "LT"
                break;
            case yesterday:
                return t("yesterday")
            default:
                break;
        }

        return <small><Moment format={format}>{msgDate}</Moment></small>
    }

    const renderUserName = () => {
        const lastNameIsNumber = !isNaN(parseInt(fullName.split(" ")[1]));
        if (lastNameIsNumber || !phone) return fullName;

        return `${fullName} ${phone}`
    }

    const handleConversationClicked = async () => {
        setClicked(conversation.id);
        if (selectedChat){
            if(selectedChat.id===conversation.id)
                return;
            if(!selectedChat.lastMessageTimestamp) {
                let mutableChatList = chatsList;
                mutableChatList.shift()
    
                setChatList(mutableChatList);
            }
        }
        // setSelectedChat({});
        const chat= await getConversationById(conversation.id);
        setSelectedChat(chat.data.data);
    }

    const renderSourceIcon = () => {
        const iconsByChannel = {
            "WHATSAPP": "whatsapp",
            "FACEBOOK_MESSENGER": "facebook-messenger",
            "INSTAGRAM": "instagram"
        }

        return <MDBIcon fab icon={iconsByChannel[channel]} />;
    }

    return (
        <div onClick={handleConversationClicked} className={`row ml-0 conversation-item ${selected ? "selected-item" : ""}`}>
            <div className="col-9 conversation-primary">
                <div className="text-content">
                    <h4 className="conversation-name">{renderUserName()}</h4>
                    {lastMessage && <p>{renderLastMsgContent()}</p>}
                </div>
            </div>
            <div className="col-3 p-0 text-right conversation-details" >
                {totalMessagesUnreaded && totalMessagesUnreaded > 0
                    ? <MDBBadge pill color="primary">{totalMessagesUnreaded}</MDBBadge>
                    : null
                }
                <span> {renderSourceIcon()}</span>
                <span className="time">{getFormattedDate()}</span> 
            </div>
            <div className="col-12">
                { conversation.issues && conversation.issues.length > 0 && <> <MDBBadge pill color="primary">
                    {conversation.issues[0].issueType.name} 
                </MDBBadge><span> </span>
                {conversation.issues[0].currentIssueState && <MDBBadge pill color="primary">
                    {conversation.issues[0].currentIssueState.name}
                </MDBBadge>} 
                </>}
            </div>
            
        </div>
    );
}

ConversationItem.translations = { ...translations };

export default translate(ConversationItem);