import React from 'react';
import { translate } from 'react-i18next';
import classNames from 'classnames';

export class PageTitle extends React.Component {
    render = () => {
		const pathLen = this.props.path.length;
        return (
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-09">
                        <h2>{this.props.t(this.props.title)}</h2>
                        <ol className="breadcrumb">
                            {this.props.path.map((object, index) =>
                                  <li  key={object} className={classNames('breadcrumb-item', { active: (pathLen === index + 1) })} >
                                    { (pathLen === index + 1) ? <strong>{this.props.t(object)}</strong> : this.props.t(object) }
                                  </li>
                            )
                            }
                        </ol>
                    </div>
                </div>
        );
    }
}

export default translate('common')(PageTitle)