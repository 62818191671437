/**
 * 
 * @param {array} filteredTypes 
 * @param {array} arrivedTypes 
 */
const rejectUpdateByIssueType = (filteredTypes, arrivedTypes) => {
    return applyArrayFilter(filteredTypes, arrivedTypes, (val) => val.issueType.id);
}


/**
 * 
 * @param {array} filteredTypes 
 * @param {array} arrivedTypes 
 */
const rejectUpdateByIssueState = (filteredStates, arrivedIssues) => {
    return applyArrayFilter(filteredStates, arrivedIssues, (val) => val.currentIssueState && val.currentIssueState.id)
}


/**
 * 
 * @param {array} filtered 
 * @param {array} arrived 
 * @param {Function} extractValue
 */
const applyArrayFilter = (filtered, arrived, extractValue) => {
    if (!filtered || !filtered.length)
        return false;
    else {
        if (!arrived)
            return true;
        return !(arrived.some((element) => {
            return filtered.includes(extractValue(element))
        }))
    }
}


/**
 * @typedef User
 * @property {string} name
 * @property {string} lastName
 * @property {string} phone
 */

/**
 * 
 * @param {array} filter 
 * @param {User} user 
 */
const rejectUpdateByUserName = (filter, user) => {
    const filterString = filter && filter[0];
    if (!filterString || filterString === "")
        return false;
    else {
        return !(user.name.includes(filterString) || user.lastName.includes(filterString))
    }
}

/**
 * 
 * @param {array} filter 
 * @param {User} user 
 */
const rejectUpdateByUserPhone = (filter, user) => {
    let filterString = filter && filter[0];
    if (!filterString || filterString === "")
        return false;
    else {
        if (filterString.startsWith("+"))
            filterString = filterString.substring(1);
        return !user.phone.includes(filterString)
    }
}

const ASSIGMENT_FILTER_VALUES = {
    ASSIGNED_TO_ME : "ASSIGNED_TO_ME",
    UNASSIGNED : "UNASSIGNED",
    ALL : "ALL",
    SPECIFIC_ADMINISTRATOR: "SPECIFIC_ADMINISTRATOR"
}

const rejectUpdateByAssignedOperator = (filtered=[], assigment={}, currentAdmin={}) => {
    const assignedAdmin=assigment && assigment.assignedAdministrator;
    if(!filtered || !filtered.length || filtered.includes(ASSIGMENT_FILTER_VALUES.ALL))
        return false;
    if(!assignedAdmin){
        if(!filtered.includes(ASSIGMENT_FILTER_VALUES.UNASSIGNED))
            return "Ya no puedes ver esta conversación";
        else
            return false;
    } else {
        if(filtered.includes(ASSIGMENT_FILTER_VALUES.ASSIGNED_TO_ME) && currentAdmin.id===assignedAdmin.id)
            return false;
    }

    if(!filtered.some(f=> f.toString() === assignedAdmin.id.toString()))
        return "La conversación ya no está asignada a este administrador";
}


export { rejectUpdateByIssueState, rejectUpdateByIssueType, rejectUpdateByUserName, rejectUpdateByUserPhone, rejectUpdateByAssignedOperator, ASSIGMENT_FILTER_VALUES }