import { useEffect, useState } from 'react';

/* 
    Este hook indica si un determinado nodo
    o componente de React es visible
*/

export default function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        if(!ref || !ref.current)
            return;
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [ref.current])

    return isIntersecting
}