import React from "react";
import { MDBInput, MDBSelect, MDBSpinner } from "mdbreact";
import './MinimalEditor/MinimalEditor.css'
import { FastField } from 'formik';
import { NoConnectedDateRangePicker as DateRangePicker } from '../../commons/components/utils/form/DateRangePicker';


import SecondsEditor from './SecondsEditor';
import moment from 'moment/min/moment-with-locales';

class SegmentFilter extends React.Component {

	constructor(props) {
		super(props);

		let hasPossibleValues = this.filterHasPossibleValues(this.props.data);
		let isMultipleSelector = (hasPossibleValues) ? this.filterHasMultiplePossibleValuesOperator(this.props.data) : false;
		let isSingleSelector = (hasPossibleValues) ? this.filterHasSinglePossibleValuesOperator(this.props.data) : false;
		let possibleValues = (hasPossibleValues && this.props.data.filterAttribute.options.possibleValues) ?
			this.props.data.filterAttribute.options.possibleValues.map(
				(element) => {
					return {
						text: element.label.replace(/--/g, '-'),
						value: element.value + ""
					}
				}
			)
			: null;

		//console.log("CONDITION",props.data.subFilters && props.data.subFilters.length>0);

		let subFilterHasPossibleValues = (props.data.subFilters && props.data.subFilters.length > 0) ? this.filterHasPossibleValues(props.data.subFilters[0]) : false;
		let subFilterIsMultipleSelector = (subFilterHasPossibleValues) ? this.filterHasMultiplePossibleValuesOperator(props.data.subFilters[0]) : false;
		let subFilterIsSingleSelector = (subFilterHasPossibleValues) ? this.filterHasSinglePossibleValuesOperator(props.data.subFilters[0]) : false;
		let subFilterPossibleValues = (subFilterHasPossibleValues && props.data.subFilters[0].filterAttribute.options.possibleValues) ?
			this.props.data.subFilters[0].filterAttribute.options.possibleValues.map(
				(element) => {
					return {
						text: element.label.replace(/--/g, '-'),
						value: element.value + ""
					}
				}
			)
			: null;



		this.state = {
			filter: this.props.data,
			hasPossibleValues: hasPossibleValues,
			isMultipleSelector: isMultipleSelector,
			isSingleSelector: isSingleSelector,
			possibleValues: possibleValues,
			subFilterHasPossibleValues: subFilterHasPossibleValues,
			subFilterIsMultipleSelector: subFilterIsMultipleSelector,
			subFilterIsSingleSelector: subFilterIsSingleSelector,
			subFilterPossibleValues: subFilterPossibleValues
		}

	}


	config = {
		functionPlurals: {
			"5": "singular",
			"6": "singular",
			"7": "singular",
			"8": "plural",
			"9": "singular",
			"10": "singular",
			"11": "singular"
		},
	}

	componentDidMount() {
		this.updateFilterValues();
	}

	// consume un servicio que retorna los posibles valores para el combo
	// TODO: mover a API y retornar Promise
	updateFilterValues() {
		if (this.state.hasPossibleValues && !this.state.possibleValues) {
			this.props.getValuesService(this.state.filter, this.props.availableStores).then((response) => {
				let data = response.data.data;
				let comboOptions = [];
				if (data && data.length > 0) {
					let keyNames = Object.keys(data[0]);
					// casos normales, viene id y otro atributo
					if (keyNames.includes("id")) {
						let otherKey = keyNames[1];
						comboOptions = data.map(
							(element) => { return { value: element.id + "", text: element[otherKey] } }
						);
					}
					// caso de user tags
					else {
						comboOptions = data.map(
							(element) => { return { text: element.tag, value: element.tag } }
						);
					}
				}
				this.setState({
					...this.state,
					possibleValues: comboOptions
				})
			}).finally(() => {
			});
		}
		if (this.state.subFilterHasPossibleValues && !this.state.subFilterPossibleValues) {
			this.props.getValuesService(this.state.filter.subFilters[0], this.props.availableStores).then((response) => {
				let data = response.data.data;
				let comboOptions = [];
				if (data && data.length > 0) {
					let keyNames = Object.keys(data[0]);
					// casos normales, viene id y otro atributo
					if (keyNames.includes("id")) {
						let otherKey = keyNames[1];
						comboOptions = data.map(
							(element) => { return { value: element.id + "", text: element[otherKey] } }
						);
					}
					// caso de user tags
					else {
						comboOptions = data.map(
							(element) => { return { text: element.tag, value: element.tag } }
						);
					}
				}
				this.setState({
					...this.state,
					subFilterPossibleValues: comboOptions
				})
			}).finally(() => {
			});
		}
	}

	// para mostrar combo tienen que pasar dos cosas
	// 1) debe tener una lista de valores posibles (por url o fijos)
	// 2) debe tener un operador que aplique a la seleccion multiple (x ej: contains, starts with, etc no aplican)
	filterHasPossibleValues(filterDef) {
		//console.log("FILTERDEF", filterDef)
		return filterDef.filterAttribute
			&& (filterDef.filterAttribute.options.possibleValues || filterDef.filterAttribute.options.possibleValuesURL || false)
			&& (this.filterHasSinglePossibleValuesOperator(filterDef) || this.filterHasMultiplePossibleValuesOperator(filterDef));
	}
	filterHasSinglePossibleValuesOperator(filterDef) {
		// 3=is, 4=is not, 5=es igual a, 17=exactly matches, 21 does not exactly match, 
		return (
			filterDef.operator.id === 3 || filterDef.operator.id === 4 || filterDef.operator.id === 5 ||
			filterDef.operator.id === 17 || filterDef.operator.id === 21)
			&& (filterDef.filterAttribute && filterDef.filterAttribute.options.controlType !== "checkbox");
	}
	filterHasMultiplePossibleValuesOperator(filterDef) {
		// 8=is one of, 7=is between
		return filterDef.operator.id === 7 || filterDef.operator.id === 8 ||
			(filterDef.filterAttribute && filterDef.filterAttribute.options.controlType === "checkbox");
	}


	firstLetterUpperCase(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	appendToString(appendTo, string) {
		if (appendTo === "") {
			return string;
		}
		if (string === "") {
			return appendTo;
		}
		return appendTo + " " + string;
	}

	getDescriptionForSeconds(seconds) {
		let t = this.props.t;
		let minutes = seconds / 60;
		let hours = 0;
		let days = 0;

		// lo tengo en minutos
		if (minutes >= 60) {
			hours = Math.floor(minutes / 60);
			minutes = minutes % 60;
		}
		// esta en horas
		if (hours >= 24) {
			days = Math.floor(hours / 24);
			hours = hours % 24;
		}

		let textToShow = (days === 0) ? "" : (days === 1) ? days + " " + t("common.dateLabels.day") : days + " " + t("common.dateLabels.days");

		hours = (hours === 0) ? "" : (hours === 1) ? hours + " " + t("common.dateLabels.hour") : hours + " " + t("common.dateLabels.hours");
		minutes = (minutes === 0) ? "" : (minutes === 1) ? minutes + " " + t("common.dateLabels.minute") : minutes + " " + t("common.dateLabels.minutes");

		textToShow = this.appendToString(textToShow, hours);
		textToShow = this.appendToString(textToShow, minutes);

		return textToShow;
	}

	getValuesAsString(filterDef, t) {
		let result = "";
		for (let i = 0; i < filterDef.operatorValues.length; i++) {
			let separator = (i === 0) ? "" : ",";
			let value = filterDef.operatorValues[i] + "";
			value = value.replace(/--/g, " " + t("segments.and") + " ")
			value = value.replace(/'/g, '')
			result = result + separator + value;
		}
		return result;
	}

	getMultipleSelectionValuesAsString(filterDef) {
		let result = ""
		this.state.possibleValues.map(
			(element) => {
				if (filterDef.operatorValues.includes(element.value)) {
					let firstTime = (result === "")
					if (!firstTime)
						result = result + ","
					result = result + element.text
				}
				return null;
			}
		)
		return result
	}

	getDateValueAsString(filterDef, t) {
		if (!filterDef.operatorValues || filterDef.operatorValues.length === 0)
			return "";
		let isFromTo = filterDef.operator.id === 7;
		let stringDate = filterDef.operatorValues[0].replace(/'/g, '');
		let dates = stringDate.split("--");
		if (isFromTo) {
			return dates[0] + " " + t("segments.and") + " " + dates[1]
		} else {
			return dates[0];
		}
	}

	getComboControlForFilter(filterDef, label) {
		let comboOptions = this.state.possibleValues.map(
			(element) => {
				return { ...element, checked: filterDef.operatorValues.includes(element.value) }
			}
		);
		return <FastField
			name={"filter_" + filterDef.id}
			render={(props) => {
				return (<>
					<MDBSelect
						label={label}
						options={comboOptions}
						multiple={!this.state.isSingleSelector}
						selected="Seleccione"
						getValue={(values) => {
							if (JSON.stringify(props.field.value) !== JSON.stringify(values)) {
								props.form.setFieldValue(props.field.name, values);
							};
						}}
					/>
				</>
				);
			}}
		/>
	}

	getTextFieldControlForFilter(filterDef, label) {
		return <FastField
			name={"filter_" + filterDef.id}
			validate={(value) => this.validateTextField}
			render={(props) => {
				return (
					<>
						<MDBInput
							label={label}
							type="text"
							value={(props.field.value && props.field.value.length > 0) ? props.field.value[0] : null}
							onChange={e => {
								if (props.field.value !== e.target.value) {
									props.form.setFieldValue(props.field.name, [e.target.value]);
									props.form.setFieldTouched(props.field.name)
								}
							}}
						/>
						{props.form.errors[props.field.name] &&
							props.form.touched[props.field.name] &&
							<span className="text-danger position-relative" style={{ top: '-1em' }}>
								{props.form.errors[props.field.name](props.field.value)}
							</span>
						}
					</>
				);
			}}
		/>
	}

	getSliderControlForFilter(filterDef, label, suffix) {
		return <FastField
			name={"filter_" + filterDef.id}
			render={(props) => {
				return (
					<div className="md-form md-range-input-fix" style={{ width: "inherit" }}>
						<div id="segmentDescription" className="active">
							<span>{this.firstLetterUpperCase(label) + "  "}</span>
							<div className="d-inline-block" id="segmentFormInput">
								<MDBInput
									className="d-inline-block"
									type="number"
									value={props.field.value && props.field.value[0]}
									getValue={(value) => {
										if (value !== parseInt(props.field.value[0]) && value.length <= 2) {
											props.form.setFieldValue(props.field.name, [value + ""]);
										};
									}} />
							</div>
							<div className="d-inline-block">{(suffix ? (" " + suffix) : "")}</div>
						</div>
					</div>
				);
			}}
		/>
	}

	getDurationFieldForFilter(filterDef, label) {
		return <FastField
			name={"filter_" + filterDef.id}
			render={(props) => {
				return (<div className="md-form md-range-input-fix">
					<span>{label}</span>
					<SecondsEditor
						className="mt-2"
						t={this.props.t}
						valueSeconds={props.field.value ? parseInt(props.field.value[0]) : 0}
						onChange={(newValue) => {
							if (newValue !== parseInt(props.field.value[0])) {
								props.form.setFieldValue(props.field.name, [newValue + ""]);
							};
						}}
					/>
				</div>
				);
			}}
		/>
	}

	getDateFieldForFilter(filterDef, label) {
		let isFromTo = filterDef.operator.id === 7;
		return <>
			<FastField
				name={"filter_" + filterDef.id}
				render={(props) => {
					let stringDate = props.field.value[0].replace(/'/g, '');
					let dates = stringDate.split("--");
					let dateFrom = moment(dates[0], "YYYY-MM-DD").toDate();
					let dateTo = moment(dates[1], "YYYY-MM-DD").toDate();;
					return (<div className="md-form md-range-input-fix">
						<span>{this.firstLetterUpperCase(label)}</span>
						<DateRangePicker
							className="mt-2"
							onSelect={(newValues) => {
								let momentFrom = moment(newValues.dateFrom);
								let momentTo = moment(newValues.dateTo);
								let momentFromString = momentFrom.format('YYYY-MM-DD');
								let momentToString = momentTo.format('YYYY-MM-DD');
								let finalValue = "'" + momentFromString + "'--'" + momentToString + "'";
								if (props.field.value[0] !== finalValue)
									props.form.setFieldValue(props.field.name, [finalValue]);
							}}
							singleDatePicker={!isFromTo}
							initDate={{ dateFrom: dateFrom, dateTo: dateTo }}
							showCustomRangeLabel={isFromTo}
							t={this.props.t} />
					</div>
					);
				}}
			/>
		</>
	}

	validateTextField = (value) => {
		let isValid = value && value[0] && value[0] !== '';
		/*console.log("validate", value)
		console.log("validate", isValid)*/
		if (!isValid) {
			return this.props.t("panels.campaigns.card.form.errors.notEmpty")
		}
	}


	getValueForFilter(filterDef, t, mode) {
		let subFilterText;
		if (filterDef.subFilters && filterDef.subFilters.length > 0) {
			//console.log("SUBFILTER0", filterDef.subFilters[0]);
			//console.log("STATE", this.state);
			subFilterText = this.getValueForFilter(filterDef.subFilters[0], t, "view");
		}
		let fnDef = filterDef.function;
		let fnValues = filterDef.functionValues;
		let params = {};
		let paramNumber = 0;
		let opValue;

		// ------------------
		//  funcion + attrs
		// ------------------
		// si viene subtype es siempre el primer parametro
		if (fnDef.parameters.eventSubType) {
			paramNumber++;
			let paramKey = "param" + paramNumber;
			let singularityKey = this.config.functionPlurals[fnDef.id];
			params[paramKey] = t("events.subtypes." + fnValues[0] + "." + singularityKey);
		}

		// luego hay dos opciones, que venga un duration o venga cualquier otra cosa
		if (fnDef.parameters.date === 'DURATION') {
			paramNumber++;
			let paramKey = "param" + paramNumber;
			let paramStringValue = fnValues[paramNumber - 1];
			let paramIntValue = parseInt(paramStringValue, 10);
			params[paramKey] = this.getDescriptionForSeconds(paramIntValue);
		}
		let translateKey = "segments.function." + filterDef.function.id + ".name";

		// funcion traducida
		let fnDescription = t(translateKey, params);

		// agrego a la funcion traducida la descripcion del atributo de filtrado adelante
		if (filterDef.filterAttribute !== null) {
			let filterAttrKey = "segments.filterAttribute." + filterDef.filterAttribute.id + ".name";
			fnDescription = t(filterAttrKey) + " " + fnDescription;
		}


		// ------------------
		// 	operador
		// ------------------
		let operatorControl = null;
		let suffix;
		let opDescription = t("segments.operator." + filterDef.operator.id + ".name") + " ";
		let prefix = fnDescription + " " + opDescription;
		if (subFilterText) {
			prefix = subFilterText + t("segments.and") + prefix;
		}
		prefix = this.firstLetterUpperCase(prefix);

		// tipo fecha 
		if (filterDef.operatorType === 'DATE') {
			// dias
			if (filterDef.operator.parametersType[0] === "INTEGER") {
				opValue = filterDef.operatorValues[0]
				suffix = t("segments.days");
				if (mode === "edit")
					operatorControl = this.getSliderControlForFilter(filterDef, prefix, suffix);
			}
			// segundos
			if (filterDef.operator.parametersType[0] === "DURATION") {
				let intParameter = parseInt(filterDef.operatorValues[0]);
				opValue = this.getDescriptionForSeconds(intParameter);
				if (mode === "edit")
					operatorControl = this.getDurationFieldForFilter(filterDef, prefix);
			}
			// fechas en formato string
			if (filterDef.operator.parametersType[0] === "DATE") {
				opValue = this.getDateValueAsString(filterDef, t);
				if (mode === "edit")
					operatorControl = this.getDateFieldForFilter(filterDef, prefix);
			}
		}
		// tipo int
		else if (filterDef.operatorType === 'INTEGER') {
			// caso particular de funcion de cumpleaños
			if (filterDef.operator.parameters[0] === "days") {
				opValue = filterDef.operatorValues[0];
				suffix = t("segments.days");
				if (mode === "edit")
					operatorControl = this.getSliderControlForFilter(filterDef, prefix, suffix);
			} else {
				if (this.state.hasPossibleValues) {
					opValue = this.getMultipleSelectionValuesAsString(filterDef)
					if (mode === "edit")
						operatorControl = this.getComboControlForFilter(filterDef, prefix);
				} else {
					opValue = this.getValuesAsString(filterDef, t);
					if (mode === "edit")
						operatorControl = this.getSliderControlForFilter(filterDef, prefix, null);
				}
			}
		}
		// resto
		else if (filterDef.operatorType === 'STRING') {
			if (this.state.hasPossibleValues) {
				opValue = this.getMultipleSelectionValuesAsString(filterDef)
				if (mode === "edit")
					operatorControl = this.getComboControlForFilter(filterDef, prefix);
			} else {
				opValue = this.getValuesAsString(filterDef, t);
				if (mode === "edit")
					operatorControl = this.getTextFieldControlForFilter(filterDef, prefix);
			}
		}

		// TODO: modo edicion de subfiltros
		if (mode === "edit")
			return operatorControl;
		if (mode === "view") {
			let operatorText;
			// es un subfiltro
			if (filterDef.id !== this.state.filter.id) {
				if (this.state.subFilterHasPossibleValues) {
					if (this.state.subFilterPossibleValues[opValue + ""])
						opValue = this.state.subFilterPossibleValues[opValue + ""].text;
				}
				operatorText = prefix + " " + opValue + (suffix ? (" " + suffix) : "");
				if (!filterDef.operatorValues[0]) {
					operatorText = ''
				}
			} else {
				if (this.state.hasPossibleValues) {
					if (this.state.possibleValues[opValue + ""])
						opValue = this.state.possibleValues[opValue + ""].text;
				}
				if (!filterDef.operatorValues[0]) {
					operatorText = subFilterText;
				} else {
					operatorText = <>{prefix + " "} <span className="filter-value">{opValue + (suffix ? (" " + suffix) : "")}</span></>;
				}
				if (operatorText === '' || !operatorText) {
					if (this.props.setInvalid !== undefined) {
						this.props.setInvalid(true, true);
					}
				} else operatorText = <div className={"filter-text" + (this.props.isEditing ? ' mt-3' : '')}> {operatorText} </div>

			}
			return operatorText;
		}
	}
	render() {
		//return "soy el filtro " + this.state.filter.id;

		if ((this.state.hasPossibleValues && !this.state.possibleValues) || (this.state.subFilterHasPossibleValues && !this.state.subFilterPossibleValues))
			return <div className="text-center m-0"><MDBSpinner small={true} /></div>;

		let operatorControl;

		// esto es un fix rapido para cuando tengo dos controles del mismo filtro, uno en modo edit y otro en modo view .. el de view no puedo pisarle state facilmente, el de edit formik lo hace por mi
		if (this.props.mode === "edit")
			operatorControl = this.getValueForFilter(this.state.filter, this.props.t, this.props.mode)
		else
			operatorControl = this.getValueForFilter(this.props.data, this.props.t, this.props.mode)

		return (
			<>
				{operatorControl}
			</>
		)
	}
}

export default SegmentFilter;