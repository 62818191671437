export const translations = {
    addTag: {
        es: "Agregar tag",
        en: "Add tag"
    },
    add: {
        es: "Agregar",
        en: "Add"
    },
    cancel: {
        es: "Cancelar",
        en: "Cancel"
    },
    selectATag: {
        es: "Seleccione tag",
        en: "Select tag",

        first: {
            es: "Seleccione un tag existente aqui o elija",
            en: "Select an existent tag or choose"
        },
        second: {
            es: '"Nuevo tag"',
            en: '"New tag"'
        },
        third: {
            es: "para ingresar uno",
            en: "to add one"
        }
    },
    selectStore: {
        es: "Seleccione una tienda",
        en: "Select a store"
    }
}