import { envConfig } from "../../config/config"

export const SocialSource = {
    BOTBIT: 1,
    GOOGLE: 2,
    FACEBOOK: 3
}

export const SocialSourceNames = {
    1: envConfig.companyName,
    2: 'Google',
    3: 'Facebook'
}