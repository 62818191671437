import React from 'react'
import { MDBBadge } from 'mdbreact'
import './AdminActivity.css'
import { getAdminEventList, getAdminEventActivityTypes } from '../../api/adminEventApi'
import ReactDiffViewer from 'react-diff-viewer'
import { connect } from 'react-redux'
import { setLoading, setError } from '../../commons/components/application/miscActions'
import { Modal, ModalHeader, ModalBody } from 'botbit-ui-components'
import { timePeriodsIds as timePeriods, getLabel, getAllTimePeriods } from 'botbit-ui-components';
import { translate } from 'react-i18next'
import CollectionVisualizerContainer from '../collectionVisualizer/CollectionVisualizerContainer'
import { firePageView } from "../../commons/components/utils/trackers";

const mapStateToProps = (state) => {
    return {
        availableStores: state.application.availableStores,
        availableCompanies: state.application.availableCompanies,
        selectedCompany: state.application.selectedCompany,
        admin: state.login.admin,
        loading: state.misc.loading
    }
};

class AdminActivityContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiffModal: false,
            contextBefore: undefined,
            contextAfter: undefined,
            activities: []
        }
        this.loadActivities()
    }

    loadActivities = async () => {
        this.props.setLoading(true)
        const response = await getAdminEventActivityTypes()
        this.setState({ activities: response })
        this.props.setLoading(false);
    }

    openAction = (oldCode, newCode) => {

        let contextBeforeTemp = oldCode;
        let contextAfterTemp = newCode;
        if (newCode.other && newCode.other["exception"] && undefined !== typeof (newCode.other["exception"])) {
            contextAfterTemp.other["exception"] = "Exception Error";
        }
        this.setState({
            showDiffModal: true,
            contextBefore: JSON.stringify(contextBeforeTemp, null, 1),
            contextAfter: JSON.stringify(contextAfterTemp, null, 1)
        })
    }

    rowActions = (row, updateRow) => {
        let actions = []
        if (window.innerWidth > 576) {
            actions = [
                {
                    key: 1,
                    color: "",
                    icon: "clone",
                    far: true,
                    onClick: () => this.openAction(row.contextBefore, row.contextAfter)
                }]
        }
        return actions;
    }
    closeModal = () => {
        this.setState({ ...this.state, showDiffModal: false });
    }

    defaultFilters = (stores) => {
        let filters = [
            {
                id: "activity",
                label: this.props.t('panels.adminActivity.filters.activity'),
                type: "multiselect",
                search: true,
                className: 'col-12',
                placeholder: this.props.t('panels.adminActivity.filters.activity'),
                priority: true,
                visible: true,
                values: this.state.activities.map(a => {
                    return {
                        checked: false,
                        value: a,
                        text: a
                    }
                }
                )
            },
            {
                id: 'status',
                label: this.props.t('panels.adminActivity.filters.status'),
                type: 'select',
                search: true,
                className: 'col-12',
                placeholder: this.props.t('panels.adminActivity.filters.status'),
                priority: true,
                visible: true,
                values: [{
                    checked: false,
                    value: "true",
                    text: this.props.t('panels.adminActivity.filters.success')
                }, {
                    checked: false,
                    value: "false",
                    text: this.props.t('panels.adminActivity.filters.failed')
                }
                ]
            },
            {
                id: "timePeriod",
                label: this.props.t('panels.adminActivity.filters.period'),
                type: "timeperiod",
                icon: 'calendar-alt',
                className: 'col-12',
                placeholder: this.props.t('panels.adminActivity.filters.custom'),
                priority: true,
                visible: true,
                options: getAllTimePeriods(),
                value: {
                    value: timePeriods.LAST_30_DAYS,
                    text: getLabel(timePeriods.LAST_30_DAYS)
                }
            }
        ]
        return filters;
    }

    componentDidMount() {
        firePageView();
    }

    render() {
        if (this.props.loading)
            return null;
        const newStyles = {
            gutter: {
                "min-width": "57px"
            },
            diffContainer: {
                "line-height": "18px",
                pen: {
                    "line-height": "18px"
                }
            }
        }
        const columns = [
            {
                dataField: '',
                title: '',
                className: 'align-left text-truncate d-md-none row-title mb-2',
                size: 12,
                classes: 'align-left',
                formatter: (cellContent, row) => {
                    return (
                        <MDBBadge color={row.success === true ? 'success' : 'danger'} className="shadow-none">
                            {row.type ? row.type : ''}
                        </MDBBadge>
                    );
                },
            },
            {
                dataField: 'timestamp',
                title: this.props.t('panels.adminActivity.columns.date'),
                className: 'my-auto d-none d-md-block',
                formatter: (cellContent, row) => {
                    var date = new Date(row.timestamp);
                    return (
                        <div>
                            {date.toLocaleString()}
                        </div>
                    );
                },
            },
            {
                dataField: '',
                title: '',
                size: 12,
                className: 'my-auto d-block d-md-none',
                formatter: (cellContent, row) => {
                    var date = new Date(row.timestamp);
                    return (
                        <div>
                            <div>
                                {date.toLocaleString()}
                            </div>
                            <div>
                                {row.admin ? ' ' + row.admin.fullName : ''} {row.store ? ' - ' + row.store.name : ''}
                            </div>
                        </div>
                    );
                },
            },
            {
                dataField: 'type',
                title: this.props.t('panels.adminActivity.columns.event'),
                className: 'my-auto d-none d-md-block',
                formatter: (cellContent, row) => {
                    return (
                        <MDBBadge color={row.success === true ? 'success' : 'danger'}>
                            {cellContent}
                        </MDBBadge>
                    );
                },
            },
            {
                dataField: 'admin',
                title: this.props.t('panels.adminActivity.columns.admin'),
                className: 'my-auto d-none d-lg-block',
                formatter: (cellContent, row) => {
                    return (
                        <div>
                            {row.admin ? row.admin.fullName : ''}
                        </div>
                    );
                },
            },
            {
                dataField: 'store',
                title: this.props.t('panels.adminActivity.columns.store'),
                className: 'my-auto d-none d-md-block d-lg-block',
                formatter: (cellContent, row) => {
                    return (
                        <div>
                            {row.store ? row.store.name : ''}
                        </div>
                    );
                },
            },
            {
                dataField: 'ip',
                title: 'IP',
                className: 'my-auto d-none d-lg-block'
            },
        ]
        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDiffModal}
                    toggle={() => this.closeModal()}
                    fullscreen>
                    <ModalHeader toggle={() => this.closeModal()}>Context Compare</ModalHeader>
                    <ModalBody className="modal-body">
                        <ReactDiffViewer
                            oldValue={this.state.contextBefore}
                            newValue={this.state.contextAfter}
                            splitView={true}
                            styles={newStyles}
                            disableWordDiff={true}
                        />
                    </ModalBody>
                </Modal>
                <br />
                <div className="mx-lg-5">
                    <CollectionVisualizerContainer
                        scrollTopButton
                        title={this.props.t('menu.activity.title')}
                        getData={(page, order, filters) =>
                            getAdminEventList(
                                page,
                                order,
                                filters
                            )
                        }
                        dataPath='data'
                        columns={columns}
                        rowActions={this.rowActions}
                        pagination={true}
                        alwaysFloatingFilter={true}
                        showFiltersInfo={true}
                        defaultFilters={this.defaultFilters(this.props.availableStores)}
                        filtersTitle="Filtrar"
                        companies={this.props.availableCompanies}
                        selectedCompany={this.props.selectedCompany}
                        stores={this.props.availableStores}
                    />
                </div>
            </React.Fragment>
        )
    }
}
export default translate('common')(connect(mapStateToProps, { setLoading, setError })(AdminActivityContainer))