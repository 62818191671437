import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { MDBIcon } from "mdbreact";
import { Modal, ModalHeader, ModalBody, WebPreviewer } from 'botbit-ui-components';
import { getCampaignPreview } from "./../../../api/campaignsApi";
import { listToText } from '../../../commons/utils/listToText';
import moment from 'moment';

export const getBenefit = (campaign, t, productCategories) => {
    // console.log("en getBenefit me llego", campaign.discount)
    let benefitType = campaign.benefitTypeId
    let productType = (campaign.productCategory) ? campaign.productCategory.id : null;
    let { discount, customProductName } = campaign;
    const empty = t(`panels.campaigns.card.form.completeBenefitMessage.none`);

    var productName = null;
    if (productType && productCategories) { //tengo un producto elegido.
        //obtengo el nombre de dicho producto
        productName = t("productTypes." + productCategories.find(category => category.id === productType).name);
    } else {
        productName = customProductName;
    }

    if (benefitIsEmpty(campaign)) {
        return empty
    }

    switch (benefitType) {
        case false:
            return empty
        case 1:
            return t('panels.campaigns.card.form.completeBenefitMessage.product', { productName: productName });
        case 2:
            return t('panels.campaigns.card.form.completeBenefitMessage.discount', { discount: discount });
        case 3:
            return t('panels.campaigns.card.form.completeBenefitMessage.2x1', { productName: productName });
        case null:
            return empty
        default:
            return empty;
    }
}

export const benefitIsEmpty = (campaign) => {
    let benefitType = campaign.benefitTypeId
    let productType = (campaign.productCategory) ? campaign.productCategory.id : null;

    let { customProductName, discount } = campaign;

    let benefitWithProductCondition = !productType && !customProductName

    switch (benefitType) {
        case 1:
            return benefitWithProductCondition
        case 2:
            return !discount || discount < 0;
        case 3:
            return benefitWithProductCondition
        default:
            return true
    }
}

export const getBenefitMessage = (campaign, t, productCategories) => {
    if (campaign.benefitMessageIsCustom) {
        return campaign.benefitMessage;
    } else {
        let benefit = getBenefit(campaign, t, productCategories);
        return benefit;
    }
}

export const validationSchema = (t, isSmart, isMaximal) => {
    const TRANSLATION_PREFIX = "panels.campaigns.newCampaign.smartCampaignWizard.";
    const getDeliveryOptionCalendarValidation = (field) => {
        return Yup.mixed().test(field, t(`${TRANSLATION_PREFIX}atLeast1HourInFuture`),
            (value) => {
                return (!value || (new Date(value).setSeconds(0, 0) >= moment().add(1, 'h').toDate().setSeconds(0, 0)))
            })
    }
    const onlySmartValidations = (isSmart) ? {
        segment: Yup.object().shape({
            stores: Yup.array().when('useCompanyUsers',
                {
                    is: false,
                    then: Yup.array().required(t(TRANSLATION_PREFIX + "selectAStore")),
                    otherwise: Yup.array().notRequired()
                })
        }),
    } : {}


    const onlyMaximalValidations = (isMaximal) ? {
        sendOnceOn: getDeliveryOptionCalendarValidation('sendOnceOn'),
        activeFrom: getDeliveryOptionCalendarValidation('activeFrom'),
        wtValidTo: Yup.mixed().when('isPredefined',
            {
                is: false,
                then: Yup.mixed().when('countAsWT',
                    {
                        is: true,
                        then: Yup.mixed().test('wtValidTo', t(`${TRANSLATION_PREFIX}pastDate`),
                            (value) => {
                                if (!value) return true
                                let today = new Date()
                                today.setHours(0, 0, 0, 0)
                                value = moment(value, 'YYYY-MM-DD')
                                return (value >= today)
                            })
                    })
            }),
        wtInterval: Yup.number().when(
            'isPredefined',
            {
                is: false,
                then: Yup.number().when(
                    'countAsWT',
                    {
                        is: true,
                        then: Yup.number()
                            .required(t(TRANSLATION_PREFIX + "validityEmpty"))
                            .typeError(t(TRANSLATION_PREFIX + "validityEmpty"))
                            .positive(t(TRANSLATION_PREFIX + "validityNegative")),
                        otherwise: Yup.number().nullable()
                    }),
                otherwise: Yup.number().nullable()
            }
        ),
        name: Yup.string().required(t(TRANSLATION_PREFIX + "nameEmpty")).typeError(t(TRANSLATION_PREFIX + "nameEmpty")),
        usersNotReelegibleDaysQtty: Yup.mixed().test('usersNotReelegibleDaysQtty', t(TRANSLATION_PREFIX + "daysQttyPositive"),
            (value) => {
                return !value || parseInt(value) >= 1
            }),
        //TODO: Mover al return una vez que el backend resuelva incosistencias.
        fromDisplay: Yup.string()
            .typeError(t(TRANSLATION_PREFIX + "fromDisplayEmpty"))
            .required(t(TRANSLATION_PREFIX + "fromDisplayEmpty")),
        mainImage: Yup.mixed().when(
            'mainImageReq',
            {
                is: true,
                then: Yup.string().required(t(TRANSLATION_PREFIX + "imageRequired")).typeError(t(TRANSLATION_PREFIX + "imageRequired"))
            }
        )
    } : {}

    return Yup.object().shape({
        campaign: Yup.object().shape({
            ...onlySmartValidations,
            ...onlyMaximalValidations,
            fromAddress: Yup.string().when(
                'fromEmailIsCustom',
                {
                    is: true,
                    then: Yup.string()
                        .typeError(t(TRANSLATION_PREFIX + "cantBeEmpty"))
                        .email(t(TRANSLATION_PREFIX + "emailInvalid"))
                        .required(t(TRANSLATION_PREFIX + "cantBeEmpty")),
                    otherwise: Yup.string().nullable()
                }
            ),
            replyToAddress: Yup.string().when(
                'toEmailType',
                {
                    is: "custom",
                    then: Yup.string()
                        .email(t(TRANSLATION_PREFIX + "emailInvalid"))
                        .required(t(TRANSLATION_PREFIX + "cantBeEmpty")),
                    otherwise: Yup.string().nullable()
                }
            ),
            discount: Yup.mixed().when('benefitTypeId', {
                is: 2,
                then: Yup.number()
                    .typeError(t(TRANSLATION_PREFIX + "discountEmpty"))
                    .min(0, t(TRANSLATION_PREFIX + "discountOutOfRange"))
                    .max(100, t(TRANSLATION_PREFIX + "discountOutOfRange"))
            }),
            benefitMessage: Yup.mixed().when('benefitMessageIsCustom', {
                is: true,
                then: Yup.string().typeError(t(TRANSLATION_PREFIX + "benefitEmpty")).required(t(TRANSLATION_PREFIX + "benefitEmpty")),
                otherwise: Yup.string().nullable()
            }),
            customProductName: Yup.string().nullable().test('customProductName', t(TRANSLATION_PREFIX + "nameEmpty"),
                function (customProductName) {
                    const { benefitTypeId, productCategory } = this.parent;
                    if ((benefitTypeId === 1 || benefitTypeId === 3) && (!productCategory || !productCategory.id)) {
                        //si seleccione Producto o 2x1, y ademas en el combo de producto seleccione Otro (id = null)
                        //entonces el customProductName es obligatorio
                        return customProductName && customProductName !== "";
                    } else {
                        //siempre soy valido
                        return true;
                    }
                }),
            campaignSubjectIsValid: Yup.mixed().oneOf([true]),
        }),

    });
}

export const saveCampaign = (data, t, productCategories, updateCampaign) => {
    let keys = Object.keys(data);
    let operatorValuesMap = {};
    for (var i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (key.startsWith("filter_")) {
            var filterId = parseInt(key.split("_")[1]);
            operatorValuesMap[filterId] = data[key];
        }
    }

    if (data.campaign.segment && data.campaign.segment.filters) {
        for (var j = 0; j < data.campaign.segment.filters.length; j++) {
            let filter = data.campaign.segment.filters[j];
            if (operatorValuesMap[filter.id] && operatorValuesMap[filter.id].length > 0) {
                filter.operatorValues = operatorValuesMap[filter.id];
            }
        }
    }
    //TODO: Ver si este calculo se puede evitar. Deberia hacerse en tiempo real, no al guardar.
    data.campaign.benefitMessage = getBenefitMessage(data.campaign, t, productCategories)

    //TODO: Esta es una deuda de la funcionalidad de CampaignBenefit. Cuando cambia el beneficio deja el id de productCategory en null.
    if (data.campaign.productCategory !== null && data.campaign.productCategory.id === null) {
        data.campaign.productCategory = null;
    }

    //TODO: lo estoy dejando por si acaso. 
    if (!data.campaign.isPredefined) {
        if (!benefitIsEmpty(data.campaign)) {
            data.campaign.countAsWT = true;
        } else {
            data.campaign.countAsWT = false;
            data.campaign.wtInterval = null;
            data.campaign.wtValidFrom = null;
            data.campaign.wtValidTo = null;
        }
    }

    if (data.campaign.replyToAddress === "") {
        data.campaign.replyToAddress = data.campaign.fromAddress;
    }

    return new Promise((resolve, reject) => {
        updateCampaign(data.campaign).then((response) => {
            if (resolve)
                resolve(response.data.data);
        })
    })
}

export const handlePreview = (setPreviewIsOpen, isOpen) => {
    if (isOpen) {
        setPreviewIsOpen(true).then(() => {
            setTimeout(() => {
                document.getElementById('__adm1__').remove()
                document.getElementById('__adm2__').remove()
                document.getElementById('__adm3__').remove()
                document.body.style.overflowX = "hidden";
            }, 500);
        });
    } else {
        const link1 = document.createElement("link");
        const link2 = document.createElement("link");
        const link3 = document.createElement("link");


        link1.href = "/static/css/style.css";
        link1.rel = "stylesheet";
        link1.id = "__adm1__"

        link2.href = "/static/css/animate.css";
        link2.rel = "stylesheet";
        link2.id = "__adm2__"
        link3.href = "/static/css/customStyle.css";
        link3.rel = "stylesheet";
        link3.id = "__adm3__";

        let linkPredecessor = document.getElementById("__opensans__");
        document.head.insertBefore(link3, linkPredecessor);
        document.head.insertBefore(link2, linkPredecessor);
        document.head.insertBefore(link1, linkPredecessor);
        document.body.style.overflowX = "auto";
        setTimeout(() => {
            setPreviewIsOpen(false);
        }, 500);
    }
}

export const PreviewModal = ({ campaignName, campaignSubject, campaignId, isOpen, close, t, isNested, customTemplate }) => {
    useEffect(() => {
        return () => {
            // me hacen unmount pero no tocaron back
            const adm1 = document.getElementById('__adm1__')
            if (adm1 === null) {
                const link1 = document.createElement("link");
                const link2 = document.createElement("link");
                const link3 = document.createElement("link");


                link1.href = "/static/css/style.css";
                link1.rel = "stylesheet";
                link1.id = "__adm1__"

                link2.href = "/static/css/animate.css";
                link2.rel = "stylesheet";
                link2.id = "__adm2__"
                link3.href = "/static/css/customStyle.css";
                link3.rel = "stylesheet";
                link3.id = "__adm3__";

                let linkPredecessor = document.getElementById("__opensans__");
                document.head.insertBefore(link3, linkPredecessor);
                document.head.insertBefore(link2, linkPredecessor);
                document.head.insertBefore(link1, linkPredecessor);
                document.body.style.overflowX = "auto";
            }
        }
    }, []);

    let getRawHTML;
    if (customTemplate) {
        getRawHTML = customTemplate;
    } else {
        getRawHTML = () => getCampaignPreview(campaignId)
    }

    return (
        <Modal isNested={isNested} fullscreen isOpen={isOpen} toggle={close} className="webPreviewerModal">
            <ModalHeader className="d-flex align-items-center crop-text-2">
                <button className="link-like-btn back-button" onClick={close}>
                    <MDBIcon icon="arrow-left"></MDBIcon>
                </button>
                {t("panels.campaigns.card.campaign")} {campaignName} - {campaignSubject}
            </ModalHeader>
            <ModalBody className="p-0">
                <WebPreviewer getRawHTML={getRawHTML}></WebPreviewer>
            </ModalBody>
        </Modal>
    )
}

export const countStores = (segment, t, maxStoresAmt = 1) => {
    if (!segment) {
        return "";
    }
    if (segment.useCompanyUsers) {
        return t('panels.campaigns.home.storesCount.allStores')
    }
    let storeNames = segment.stores ? segment.stores.map(store => store.name) : [];
    return listToText(storeNames, maxStoresAmt, t);
}