
import React from 'react';
import { Modal } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImport as fileImport } from '@fortawesome/free-solid-svg-icons';
import ClientTags from './ClientTags.js';
import DropzoneComponent from 'react-dropzone-component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { envConfig } from '../../config/config.js';
const MySwal = withReactContent(Swal);


class ImportUsersModal extends React.Component {

	constructor(props) {
		super(props);

		this.djsConfig = {
			addRemoveLinks: true,
			acceptedFiles: ".csv",
			autoProcessQueue: false,
			uploadMultiple: true,
			headers : { 'Authorization':`Bearer ${this.props.token}`},
			dictDefaultMessage: props.t("panels.clients.dragAndDropText"),
			maxFilesize:1.5
		};

		this.componentConfig = {
			iconFiletypes: ['.csv'],
			showFiletypeIcon: false,
			postUrl: `${envConfig.servicesUrl}/users/import`,
		};

		this.dropzone = null;

		this.state = { tags: [] };
	}

	setTags = (tags) => {
		this.setState({tags:tags});		
	}

	processQueue = () => {
		if (this.dropzone.getQueuedFiles().length === 0) {
			MySwal.fire({
				title: 'Importación ',
				text: "Debe seleccionar un archivo antes de presionar este botón",
				type: 'info',
				showCancelButton: false,
				confirmButtonText: 'Ok',
				confirmButtonColor:'#1ab394'
			});
			return;
		}
		this.dropzone.processQueue();
		this.props.closeModal();

	}

	render() {
		const config = this.componentConfig;
		const parameters = {
			storeId: this.props.store.id,
			loggedUser: this.props.admin ? this.props.admin.username : undefined,
			tags: this.state.tags.map(t=>(t.id)).toString(),
			representativeId : ''
		}

		this.djsConfig.params = parameters;


		const eventHandlers = {
			init: dz => this.dropzone = dz,
			successmultiple: () => {
				MySwal.fire({
					title: 'Importación ',
					text: "La importación ya está en curso. Cuando finalice recibirás una notificación con el resultado.",
					type: 'info',
					showCancelButton: false,
					confirmButtonText: 'Ok',
					confirmButtonColor:'#1ab394'
				});

			},
			error: (dzObject, error) => {
				let msg;
				if (typeof error === 'string')
					msg=error;
				else
					msg=error.message
				const searchRegExp = /\n/g; const replaceWith = '<br/>';
				msg=msg.replace(searchRegExp, replaceWith);
				MySwal.fire({
					title: 'Importación ',
					html: msg,
					type: 'error',
					showCancelButton: false,
					confirmButtonText: 'Ok',
					confirmButtonColor:'#1ab394'
				});

			},
			maxfilesexceeded: () => {
				MySwal.fire({
					title: 'Importación ',
					text: "Tamaño máximo de archivo excedido.",
					type: 'error',
					showCancelButton: false,
					confirmButtonText: 'Ok',
					confirmButtonColor:'#1ab394'
				});

			}


		}

		return (
				<Modal show={this.props.show === true} onHide={this.props.closeModal}  >
					<Modal.Header closeButton>
						<Modal.Title>{this.props.t("panels.clients.importClients")}</Modal.Title>
					</Modal.Header>
					<Modal.Body className='p-4'>
						<form action="">
							<DropzoneComponent config={config} eventHandlers={eventHandlers} djsConfig={this.djsConfig}/>
							<br/>
							<p>
								{this.props.t("panels.clients.fileSizeRestriction")}
							</p>
							<p>
								{this.props.t("panels.clients.modalMessage")}
							</p>
							<ClientTags placeholder={this.props.t("panels.clients.tagInput")} setTags={this.setTags} tags={this.state.tags}/>
						</form>
					</Modal.Body>
					<Modal.Footer className='p-4'>
						<button className='btn btn-secondary btn-sm w-100'>
							<FontAwesomeIcon className='m-auto' icon='download' />
							<a href='/misc/users_sample.csv' target='_blank' className='m-auto pl-3 text-white'>{this.props.t("panels.clients.sample")}</a>
						</button>
						<button className='btn btn-primary btn-sm w-100'
							onClick={this.processQueue}>
							<FontAwesomeIcon className='m-auto' icon={fileImport} />
							<span className='m-auto pl-3'>{this.props.t("panels.clients.import")}</span>
						</button>

					</Modal.Footer>
				</Modal>
		)
	}
}

export default (translate('common')(ImportUsersModal));
