import React from "react";
import './campaigns.css'
import 'moment/locale/es';
import SegmentFilter from './SegmentFilter';
import CompanyAndStoreFilter from "./CompanyAndStoreFilter/CompanyAndStoreFilter";

const SegmentSimple = ({ data, mode, t, getValuesService, availableStores, size, lgChildSize, visibleElements = 0, storesActivableSmartCampaign, isEditing, formik, setInvalid}) => {
	if (!data || !data.filters || !data.filters.length>0){
		if(setInvalid !== undefined) {
			setInvalid(true, false);
		}
		return null;
	}


	let visibleItems = [];


	data.filters.map((element, index) => {
		if (index < visibleElements) visibleItems.push (
			<div key={"filter_"+element.id}>
				<SegmentFilter 
					data={element} 
					index={index} 
					mode={mode} 
					t={t} 
					getValuesService={getValuesService} 
					availableStores={availableStores}
					setInvalid={setInvalid}
				/>
			</div>
		);
		return null;
	})
	
	var collapsableItems;
	if (data.filters.length > visibleElements) {
		collapsableItems = data.filters.map((element, index) => {
					if (index >= visibleElements) return (
						<div key={"filter_" + element.id}>
							<SegmentFilter 
								segment={data}
								data={element} 
								index={index} 
								mode={mode} 
								t={t} 
								getValuesService={getValuesService} 
								availableStores={availableStores}
								storesActivableSmartCampaign={storesActivableSmartCampaign}
								isEditing={isEditing}
								formik={formik}
								setInvalid={setInvalid}
							/>
						</div>
					);
					return null;
				});

		// let textToShow;
		// if (visibleElements === 0)
		// 	textToShow = t("panels.campaigns.card.segmentDetails");
		// else
		// 	textToShow = t("panels.campaigns.card.segmentViewMore");

		visibleItems.push (collapsableItems)
	}
	
	if(!storesActivableSmartCampaign){
		return null
	}
	return <>
			<CompanyAndStoreFilter
				segment={data}
				mode={mode} 
				t={t} 
				getValuesService={getValuesService} 
				availableStores={availableStores}
				storesActivableSmartCampaign={storesActivableSmartCampaign}
				isEditing={isEditing}
				formik={formik}
			/>
			{ 	
				visibleItems.map( (element ) => {
					return element;
				}) 
			}
		</>
}

export default SegmentSimple