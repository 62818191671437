export const translations = {
    operations: {
        CAMPAIGNS: {
            name: {
                es: "Campañas a medida",
                en: "Custom campaigns",
            },
            description: {
                es: "Automatiza las comunicaciones con tus clientes activando nuestras campañas inteligentes con un par de clicks. Envía campañas a medida muy fácilmente.",
                en: "Automate communications with your customers by activating our smart campaigns with a couple of clicks. Send customized campaigns very easily."
            },
            image: {
                es: "Botbit_Material-Upgrades-Marketing_v2-03.png",
                en: "Botbit_Material-Upgrades-Marketing_v2-03.png"
            },

        },
        CAMPAIGNS_SMART_BIRTHDAY: {
            name: {
                es: "Campaña inteligente de cumpleaños",
                en: "Birthday smart campaign",
            }
        },
        CAMPAIGNS_SMART_LOST_CUSTOMER: {
            name: {
                es: "Campañas inteligentes de recuperación de clientes perdidos",
                en: "Smart campaigns for lost customer recovery",
            },
            image: {
                es: "Botbit_Material-Upgrades-Marketing_v3-15.png",
                en: "Botbit_Material-Upgrades-Marketing_v3-15.png"
            },
        },
        CAMPAIGNS_SMART_LOYAL_CUSTOMER: {
            name: {
                es: "Campaña intelingente de clientes fidelizados",
                en: "Smart campaign for loyal customers",
            },
            image: {
                es: "Botbit_Material-Upgrades-Marketing_v3-15.png",
                en: "Botbit_Material-Upgrades-Marketing_v3-15.png"
            },
        },

        COMPETITIVE_BENCHMARK: {
            name: {
                es: "Benchmark competitivo",
                en: "Competitive Benchmark"
            },
            description: {
                es: "",
            }
        },
        CORE_FEATURES: {
            name: {
                es: "",
            },
            description: {
                es: "",
            }
        },
        CUSTOM_QUESTIONS: {
            name: {
                es: "Preguntas personalizadas a clientes",
                en: "Customized questions to customers",
            },
            description: {
                es: "",
            }
        },
        DETRACTOR_WHATSAPP_ALERT: {
            name: {
                es: "Alerta de clientes enojados por WhatsApp",
                en: "Angry customers alert by WhatsApp"
            },
            description: {
                es: "Entérate al instante cuando un cliente te califique negativamente. No esperes a que llegue un email, recibe el alerta directamente en tu WhatsApp para que puedas responder desde tu teléfono y revertir la situación antes que su enojo llegue a tus redes sociales.",
                en: "Find out instantly about those customers who have given you a bad rating, do not wait for an email to arrive, receive the alert directly on your WhatsApp so you can respond from your phone and reverse the situation before their anger reaches your social networks ."
            },
            image: {
                es: "Botbit_Material-Upgrades-13.png"
            },
        },
        GAMIFICATION_CUSTOMIZE: {
            name: {
                es: "Juego customizable",
                en: "Customize gamification"
            },
            description: {
                es: "¿Quieres que tus clientes vean el logo de tu marca cuando ganan o en otra parte del juego? ¡Precisas esta funcionalidad!",
                en: "Do you want your customers see your brand logo when they win or in another part of the game? You need this functionality!"
            }
        },
        NPS_DASHBOARD: {
            name: {
                es: "Dashboard NPS",
                en: "NPS Dashboard"
            },
            description: {
                es: "Visualiza el NPS de todas tus sucursales y compárate con tus competidores haciendo solo un click.",
                en: "View the NPS of all your branches and compare yourself with your competitors with just one click."
            },
            image: {
                es: "Botbit_Material-Upgrades-12.png",
                en: "Botbit_Material-Upgrades-12.png"
            },
        },
        REPLY_TEMPLATES: {
            name: {
                es: "Respuestas a reseñas con templates",
                en: "Responses to reviews with templates"
            },
            description: {
                es: "¿Cansado de escribir respuestas de forma manual para las reseñas que dejan tus clientes? Optimiza tu tiempo utilizando nuestros templates predefinidos o crea los tuyos para utilizarlos en las próximas reseñas que lleguen.",
                en: "Tired of manually writing responses for customer reviews? Optimize your time using our predefine templates or create your owns to use them in the next reviews that arrive."
            },
            image: {
                es: "Botbit_Material-Upgrades-09.png",
                en: "Botbit_Material-Upgrades-09.png"
            },
        },
        REVIEWS_AUTO_REPLY: {
            name: {
                es: "Respuesta automática de reseñas",
                en: "Automatic response to reviews"
            },
            description: {
                es: "No pierdas más tiempo respondiendo reseñas ¡deja que nuestro robot humanizado lo haga por ti!",
                en: "Don't waste your time answering reviews, let our humanized robot do it for you!"
            },
            image: {
                es: "Botbit_Material-Upgrades-10.png",
                en: "Botbit_Material-Upgrades-10.png"
            },
        },
        REVIEWS_WIDGET: {
            name: {
                es: "Widget de reseñas",
                en: "Widget reviews",
            },
            description: {
                es: "Gana nuevos clientes mostrando en tu sitio web las mejores reseñas que otros clientes te han dejado. Es muy sencillo, solo copiar y pegar.",
                en: "Win new customers by showing on your website the best reviews that other customers have left you. It's very simple, just copy and paste it."
            },
            image: {
                es: "Botbit_Material-Upgrades-11.png",
                en: "Botbit_Material-Upgrades-11.png"
            },
        },
        REVIEW_CHANGE_ALERT: {
            name: {
                es: "Alerta de cambio de reseña",
                en: "Change review alert"
            },
            description: {
                es: "Entérate cuando un cliente ha modificado su reseña, es importante sobre todo si añade un comentario o cambia su opinión para que puedas identificar oportunidades de mejora para tu negocio. No tienes que preocuparte en ir a revisar cuál fue su reseña anterior, nosotros te enviamos un resumen para que la puedas comparar fácilmente y hasta responder desde allí.",
                en: "Find out when a customer has modified their review, it is important especially if they add a comment or change their opinion so that you can identify opportunities for improvement for your business. You do not have to worry about finding the previous review, we will send you a summary so that you can easily compare it and even respond from there."
            }
        },
        REVIEW_GENERATION: {
            name: {
                es: "Generación de reseñas 5 estrellas",
                en: "5 stars review generation"
            },
            description: {
                es: "Nos encanta cuando los clientes te califican positivamente en nuestra encuesta de satisfacción, por eso que los invitamos a que cuenten su experiencia en Google o Facebook para que mejore tu reputación online. Esto generará confianza en tus potenciales clientes, que sin dudas te elegirán cada vez más.",
                en: "We love when customers leave good ratings in their satisfaction survey, that is why we invite them to leave their experience on Google or Facebook to improve your online reputation. This will generate confidence in your potential clients, who will undoubtedly choose you more and more."
            },
            image: {
                es: "Botbit_Material-Upgrades-08.png",
                en: "Botbit_Material-Upgrades-08.png",
            },
        },
        USERS_INSIGHTS: {
            name: {
                es: "Insights de clientes",
                en: "Customers insights"
            },
            description: {
                es: "",
            }
        },
        USERS_REVIEW_HISTORY: {
            name: {
                es: "Historial de reseñas de un cliente",
                en: "Customer reviews history"
            },
            description: {
                es: "Ahora ya no tienes que buscar reseña por reseña para saber todo lo que un cliente dijo de ti. Puedes ver todo el historial de reseñas que un cliente te ha dejado en una tienda y las respuestas que obtuvo de parte de los administradores.",
                en: "Now you dont have to search review by review to find out everything a customer said about you. You can see all the review history that a customer has left you in a store and the responses they got from the administrators."
            }
        },
        WHATSAPP_REVIEW_REQUEST: {
            name: {
                es: "Encuesta de satisfacción por WhatsApp",
                en: "Satisfaction Survey by WhatsApp"
            },
            description: {
                es: "Envía la encuesta de satisfacción a tus clientes a través de WhatsApp y verás como tus reseñas se multiplican.",
                en: "Send the satisfaction survey to your customers through WhatsApp and you will see how your reviews multiply."
            }
        },
    },
    plans: {
        professional: {
            name: {
                es: "Plan Professional",
                en: "Professional Plan"
            },
            image: {
                es: "Botbit_Material-Upgrades-06.png",
                en: "Botbit_Material-Upgrades-06.png"
            },
            vector: {
                es: "Botbit_Material-Upgrades-04.png",
                en: "Botbit_Material-Upgrades-04.png",
            }
        },
        premium: {
            name: {
                es: "Plan Premium",
                en: "Premium Plan",
            },
            image: {
                es: "Botbit_Material-Upgrades-07.png",
                en: "Botbit_Material-Upgrades-07.png",
            },
            vector: {
                es: "Botbit_Material-Upgrades-05.png",
                en: "Botbit_Material-Upgrades-05.png",
            }
        },
        marketing: {
            name: {
                es: "Marketing",
                en: "Marketing"
            },
            image: {
                es: "Botbit_Icono-AddOn_V1.png",
                en: "Botbit_Icono-AddOn_V1.png"
            },
            vector: {
                es: "Botbit_Material-Upgrades-05.png",
                en: "Botbit_Material-Upgrades-05.png",
            }
        },
        starter: {
            name: {
                es: "Plan Starter",
                en: "Starter Plan"
            }
        }
    },
    monthsByStore: {
        es: "/mes por sucursal",
        en: "/month by store"
    },
    headerMessage: {
        es: "¡HABILITA ESTA FUNCIONALIDAD HACIENDO DESPEGAR TU PLAN!",
        en: "ENABLE THIS FUNCTIONALITY BY LAUNCHING YOUR PLAN!"
    },
    requestUpgrade: {
        es: "Solicitar ahora",
        en: "Request now"
    },
    includes: {
        es: "Incluye todo el {{parentName}} más",
        en: "Includes all the {{parentName}} features plus"
    },
    upgradeMessage: {
        es: "Hola! Estoy interesado en el {{planName}}",
        en: "Hello! I'm interested in the {{planName}}",
    },
    consultPrice: {
        es: "Consultar costos",
        en: "Check prices",
    },
    dependingCost: {
        es: "*Valor sujeto a forma de contratación",
        en: "*Price subject to contract conditions"
    },
    yourCurrent: {
        es: "Tu plan actual es: ",
        en: "Your current plan is: "
    }
}