export const positiveMentionColor = '81BD4A'
export const negativeMentionColor = 'EB6951'

export const getFirstDayOfMonth = (year, month) => {
	return new Date(year, month - 1, 1);
}

export const getLastDayOfMonth = (year, month) => {
	return new Date(year, month, 0);
}

