export const translations = {
	editionTitle: {
		en: "Edit issue",
		es: "Editar motivo"
	},
	creationTitle: {
		en: "Create issue",
		es: "Crear motivo"
	},
	issueTypeNameLabel: {
		en: "Name",
		es: "Nombre"
	},
	issueTypeNameRequired: {
		en: "Name is required",
		es: "Nombre es obligatorio"
	},
	issueTypeDescriptionLabel: {
		en: "Description",
		es: "Descripción"
	},
	addState: {
		en: "Add new state",
		es: "Agregar nuevo estado"
	},
	removeState: {
		en: "Remove",
		es: "Eliminar"
	},
	statesTitle: {
		en: "States",
		es: "Estados"
	},
	stateNameLabel: {
		en: "Name",
		es: "Nombre"
	},
	stateDescriptionLabel: {
		en: "Description",
		es: "Descripción"
	},
	stateFinalStateLabel: {
		en: "Is final state?",
		es: "Estado final?"
	},
	save: {
		en: "Save",
		es: "Guardar"
	},
	cancel: {
		en: "Cancel",
		es: "Canclar"
	},
	saveOkText: {
		en: "Save succesfull",
		es: "Operación exitoso"
	},
	saveOkButton: {
		en: "Back",
		es: "Volver"
	}
}