import React from 'react';
import SourceCard from "../SourceCard/SourceCard";
import { Modal, ModalBody } from "botbit-ui-components";
import { MDBIcon } from "mdbreact";

import "./SourceCardModal.scss"

const SourceCardModal = ({ isOpen, toggle, onAdd, source }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="source-card-modal" >
      <ModalBody>
        <MDBIcon
          className="back-button"
          onClick={toggle}
          icon="arrow-left"
        // style={}
        />
        <SourceCard onAdd={onAdd} source={source} />
      </ModalBody>
    </Modal>
  );
}

export default SourceCardModal;