import React, { useState } from 'react';
// import { Modal, ModalBody } from "botbit-ui-components";
import { sourcesTranslations } from '../../../config/translations/integrations/Sources';
import { translations } from "../../../config/translations/integrations/PreOptIn";
import translate from "../../../config/translations/translate"
import { MDBBtn, MDBInput, MDBCard, MDBContainer } from 'mdbreact';
import "./Connect.scss"
// import UrlField from './UrlField/UrlField';
import { Formik, Form, Field } from 'formik';
import { useEffect } from 'react';
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSource } from '../../../api/sourceApi';
import * as Yup from "yup";
import { connect } from '../IntegrationsHelper';
import { useBreakpoint } from "../../../commons/utils/useWindowDimensions"

const InputTypes = {
  "text": {
    renderFunction: (config, source, t, isMobile) => (props) => (
      <div className="connect-field">
        <span className="prefix">{t(`sources.${source.name}.prefix`)}</span>
        <MDBInput
          name={props.field.name}
          label={t(`sources.${source.name}.fields.${config.id}${isMobile ? ".short" : ""}`)}
          id={config.id}
          value={props.field.value ? props.field.value + "" : ""}
          getValue={value => {
            props.form.setFieldValue(props.field.name, value);
          }}
        />
        <span className="sufix">{t(`sources.${source.name}.sufix`)}</span>
        <span className="compiledPrefixAndSufix">{t(`sources.${source.name}.prefix`)}<strong>{props.field.value}</strong>{t(`sources.${source.name}.sufix`)}</span>
      </div>
    ),
    initialValue: "",
    requiredValidation: Yup.string().required("errors.required")
  }
}

const Connect = ({ t }) => {
  const [isLoading, setIsloading] = useState(true);
  const [source, setSource] = useState({});
  const [initialValues, setInitialValues] = useState(null);
  const selectedCompany = useSelector(state => state.application.selectedCompany);
  const history = useHistory();
  const isMobile = useBreakpoint(499);
  const { sourceId } = useParams();

  useEffect(() => {
    const get = async () => {
      const sourceObj = await getSource(sourceId, selectedCompany.id);
      setSource(sourceObj.data.data);
      setIsloading(false);
    }
    get();
  }, [])

  useEffect(() => {
    if (source && source.configuration && source.configuration.fields) {
      const sourceInitialValues = source
        .configuration
        .fields
        .reduce((prev, current) => {
          prev[current.id] = InputTypes[current.type].initialValue;
          return prev;
        }, {});
      setInitialValues(sourceInitialValues);
    } else {
      setInitialValues(null);
    }
  }, [source])

  const renderField = (config, source) => {
    let inputField = InputTypes[config.type].renderFunction(config, source, t, isMobile);

    return <Field key={config.id} name={config.id}>
      {props => <div className="field-with-error">
        {inputField(props)}
        {props.form.errors[config.id] &&
          <span className="error-message">{t(props.form.errors[config.id])}</span>}
      </div>}
    </Field>
  }

  const createValidationsSchema = (fields) => {
    let validationsByField = {};
    fields.forEach(field => {
      validationsByField[field.id] = InputTypes[field.type].requiredValidation;
    });
    return Yup.object().shape({
      ...validationsByField
    });
  }
  const handleConnect = async (values) => {
    connect(source, selectedCompany, values);
  }


  return (
    <MDBContainer id="integrations-pre-optin">
      <MDBCard>
        {!isLoading && source &&
          <div className="integrations-pre-optin_container">
            <h4 className="title">{`${t(`title`)} ${t(`sources.${source.name}.name`)}`}</h4>
            <Formik
              initialValues={initialValues}
              validationSchema={createValidationsSchema(source.configuration.fields)}
              onSubmit={handleConnect}
            >
              <Form className="integrations-pre-optin_form">
                {source.configuration.fields.map(c =>
                  renderField(c, source)
                )}
                <MDBBtn type="submit" color="primary">{t(`primaryBtn`)}</MDBBtn>
              </Form>
            </Formik>
            <button onClick={() => history.push(`/integrations`)} className="link-like-btn">{t(`cancelBtn`)}</button>
          </div>}
      </MDBCard>
    </MDBContainer>
  );
}

Connect.translations = {
  ...sourcesTranslations,
  ...translations
}

export default translate(Connect);