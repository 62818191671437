import React from 'react';
import { setLoading } from '../../commons/components/application/miscActions';
import withReactContent from 'sweetalert2-react-content';
import { Modal } from 'react-bootstrap';
import UserForm from './UserForm';
import UserReservationForm from './UserReservationForm.js';
import Swal from 'sweetalert2';
import { ignoreUser, unignoreUser, saveUser, deleteUser, unregisterUser } from '../../api/userApi';
import { saveTask } from "../../api/taskApi.js";
import { addReservation } from '../../api/reservationApi.js';
import { connect } from 'react-redux';
import SendCampaignToUserForm from './SendCampignToUserForm.js'
import TaskForm from './TaskForm.js';
import InquiryForm from './InquiryForm.js';
import { saveInquiry } from '../../api/inquiryApi.js';
import SelectStoreForm from './SelectStoreForm'
import { envConfig } from '../../config/config';
import { withRouter } from "react-router-dom";
import { translations } from "../../config/translations/Clients/ClientActions";
import translate from "../../config/translations/translate";
import _ from "lodash"
const MySwal = withReactContent(Swal);

class ClientActions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
        }
    }

    saveUser = (values) => {
        saveUser(values.user)
            .then(res => {
                const changes = _.omitBy(res.data.data, _.isNull);
                let newUser = { ...this.props.client, ...changes };
                this.props.updateUser(newUser);
                this.closeModal();
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.actionName !== prevProps.actionName) {
            if (this.props.actionId) {
                this.openModal(this.props.actionId);
            }
        }
    }

    saveTask = (values) => {
        let task = {
            user: { id: this.props.client.id },
            ...values
        }
        saveTask(task)
            .then(
                () => {
                    this.props.updateUser({ ...this.props.client });
                    this.closeModal();
                }
            );
    }

    saveInquiry = (values) => {
        let inquiry = {
            user: { id: this.props.client.id },
            ...values
        }
        saveInquiry(inquiry)
            .then(
                () => {
                    this.props.updateUser({ ...this.props.client });
                    this.closeModal();
                }
            );

    }

    openModal = (actionId) => {
        var actionComponent;
        var size;
        var dialogClassName = '';
        var footer;
        var openModal = true;
        switch (actionId) {
            case 'edit':
                actionComponent = (<UserForm user={this.props.client} client={this.props.client} onSubmit={this.saveUser} />);
                //footer = (<button className="btn btn-lg btn-primary m-t-n-xs" onClick={this.saveUser}>Guardar Cambios</button>);
                break;
            case 'reservation':
                actionComponent = (<UserReservationForm user={this.props.client} onSubmit={this.addReservation} />);
                //footer = (<button className="btn btn-lg btn-primary m-t-n-xs" onClick={this.saveUser}>Guardar Cambios</button>);
                break;
            case 'send-campaign':
                actionComponent = (<SendCampaignToUserForm
                    stores={this.props.stores}
                    user={this.props.client}
                    onSuccess={this.closeModal} />);
                //footer = (<button className="btn btn-lg btn-primary m-t-n-xs" onClick={this.saveUser}>Guardar Cambios</button>);
                break;
            case 'add-task':
                actionComponent = (<TaskForm user={this.props.client}
                    stores={this.props.stores}
                    selectedStore={this.props.selectedStore}
                    task={{ user: this.props.client, storeId: this.props.selectedStore ? this.props.selectedStore.id : undefined }}
                    onSubmit={this.saveTask} />);
                break;
            case 'Agregar consulta':
                actionComponent = (<InquiryForm user={this.props.client}
                    stores={this.props.stores}
                    selectedStore={this.props.selectedStore}
                    onSubmit={this.saveInquiry} />);
                break;
            case 'delete-physical':
                openModal = false;
                this.handleDelete();
                return;
            case 'ignore':
                if (this.props.selectedStore) {
                    openModal = false;
                    this.handleIgnore({ store: this.props.selectedStore });
                    return;
                } else {
                    actionComponent = (<SelectStoreForm
                        user={this.props.client}
                        action={this.handleIgnore}
                        type={this.props.actionName}
                    />);
                }
                break;
            case 'unignore':
                if (this.props.selectedStore) {
                    openModal = false;
                    this.handleUnignore({ store: this.props.selectedStore });
                    return;
                } else {
                    actionComponent = (<SelectStoreForm
                        user={this.props.client}
                        action={this.handleUnignore}
                        type={this.props.actionName}
                    />);
                }
                break;
            case 'delete':
                if (this.props.selectedStore) {
                    openModal = false;
                    this.handleUnregister({ store: this.props.selectedStore });
                    return;
                } else {
                    actionComponent = (<SelectStoreForm
                        user={this.props.client}
                        action={this.handleUnregister}
                        type={this.props.actionName}
                    />);
                }
                break;
            case 'send-nps':
                if (this.props.selectedStore) {
                    openModal = false;
                    this.handleSendNPS({ store: this.props.selectedStore });
                    return;
                } else {
                    actionComponent = (<SelectStoreForm
                        user={this.props.client}
                        action={this.handleSendNPS}
                        type={this.props.actionName}
                    />);
                }
                break;
            case 'review-history':
                if (this.props.selectedStore && this.props.client) {
                    openModal = false;
                    this.handleReviewHistory({ store: this.props.selectedStore, identityId: this.props.client.id, type: 'user' })
                    return
                } else if (!this.props.selectedStore && this.props.client) {
                    if (this.props.review) {
                        // console.log({ review: this.props.review })
                        this.handleReviewHistory({ store: this.props.review.storeId, identityId: this.props.client.id, type: 'user' })
                    } else if (this.props.componentSelectedStore) {
                        this.handleReviewHistory({ store: this.props.componentSelectedStore, identityId: this.props.client.id, type: 'user' })
                    } else {
                        const self = this;
                        const callHandler = ({ store }) => {
                            self.handleReviewHistory({ store, identityId: self.props.client.id, type: 'user' })
                        }
                        actionComponent = (<SelectStoreForm
                            user={this.props.client}
                            action={callHandler}
                            type={this.props.actionName}
                        />);
                    }
                }
                break
            default:
                return;
        }
        this.setState({
            openModal: openModal,
            modalSize: size,
            dialogClassName: dialogClassName,
            footer: footer,
            action: {
                component: actionComponent,
                name: this.props.actionName
            }
        });
    }

    closeModal = () => {
        this.setState({ openModal: false, action: {} });
        this.props.onClose();
    }

    handleDelete = () => {
        const { t } = this.props;
        MySwal.fire({
            title: t("confirmation"),
            text: t("deleteClient") + this.props.client.name + " " + this.props.client.lastName,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t("confirm"),
            confirmButtonColor: '#1ab394'
        }).then((result) => {
            if (result.value) {
                deleteUser(this.props.client.id)
                    .then((res) => {
                        this.props.updateUser({ id: this.props.client.id }, "delete");
                        MySwal.fire({
                            title: t("deleted"),
                            text: t("userDeleted"),
                            type: 'success',
                            confirmButtonColor: '#1ab394'
                        }).then(this.closeModal);
                    });
            }
            else this.props.onClose();
        });
    }

    handleUnregister = ({ store }) => {
        const { t } = this.props;
        MySwal.fire({
            title: t("confirmation"),
            text: `${t("deleteClient")} ${this.props.client.name} ${this.props.client.lastName}  ${store.name ? t("fromTheStore") + store.name : ""}`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t("confirm"),
            confirmButtonColor: '#1ab394'
        }).then((result) => {
            if (result.value) {
                unregisterUser(this.props.client.id, store.id)
                    .then((res) => {
                        this.props.updateUser({ id: this.props.client.id }, "delete");
                        MySwal.fire({
                            title: t("deleted"),
                            text: t("userDeleted"),
                            type: 'success',
                            confirmButtonColor: '#1ab394'
                        }).then(this.closeModal);
                    });
            }
            else this.props.onClose();
        });
    }

    addReservation = (reservation) => {
        const { t } = this.props;
        var reservationDto = {
            ...reservation,
            user: { id: this.props.client.id },
            storeId: reservation.store.id
        }
        addReservation(reservationDto)
            .then((response) => {
                MySwal.fire({
                    title: t("added"),
                    text: t("reservationAdded"),
                    type: 'success',
                    confirmButtonColor: '#1ab394'
                })
                    .then(this.closeModal);
            });
    }

    handleUnignore = ({ store }) => {
        const { t } = this.props;
        MySwal.fire({
            title: t("confirmation"),
            text: `${t("unignoreClient")} ${this.props.client.name} ${this.props.client.lastName}  ${store.name ? t("fromTheStore") + store.name : ""}`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t("confirm"),
            confirmButtonColor: '#1ab394'
        }).then((result) => {
            if (result.value) {
                unignoreUser(this.props.client.id, store.id, this.props.client.allowAutomaticIgnore)
                    .then((res) => {
                        this.props.updateUser({ ...res.data.data, ignored: false });
                        MySwal.fire({
                            title: t("unignored"),
                            text: t("userUnignored"),
                            type: 'success',
                            confirmButtonColor: '#1ab394'
                        }).then(this.closeModal);
                    });
            }
            else this.props.onClose();
        });
    }

    handleIgnore = ({ store }) => {
        const { t } = this.props
        MySwal.fire({
            title: t("confirmation"),
            text: `${t("ignoreClient")} ${this.props.client.name} ${this.props.client.lastName}  ${store.name ? t("ofTheStore") + store.name : ""}`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t("confirm"),
            confirmButtonColor: '#1ab394'
        }).then((result) => {
            if (result.value) {
                ignoreUser(this.props.client.id, store.id, this.props.admin.username)
                    .then((res) => {
                        this.props.updateUser({ ...res.data.data, ignored: true });
                        MySwal.fire({
                            title: t("ignored"),
                            text: t("userIgnored"),
                            type: 'success',
                            confirmButtonColor: '#1ab394'
                        }).then(this.closeModal);
                    });
            }
            else this.props.onClose();
        });
    }

    handleSendNPS = ({ store }) => {
        const message = `¡Hola ${this.props.client.name}! Cuéntanos tu experiencia con ${store.name} ${envConfig.frontendPhpUrl + encodeURIComponent(`/nps?storeId=${store.id}&userId=${this.props.client.id}&emailUniqueId=${this.props.client.id + store.id + Date.now()}&utm_source=BotBit&utm_medium=whatsapp&utm_campaign=nps`)}`
        window.open(`https://api.whatsapp.com/send?phone=${this.props.client.phone}&text=${message}`, "_blank");
        this.closeModal();
    }

    handleReviewHistory = ({ store, identityId, type }) => {
        // console.log({ store })
        const url = `/reviewHistory/?identityId=${identityId}&storeId=${!store.id && typeof (store) === "number" ? store : store.id}&type=${type}`;
        this.props.history.push(url);
    }

    render = () => (
        <div className='text-right user-detail-options' onClick={(e) => { e.stopPropagation() }}>
            {
                (this.state.action) &&
                <Modal
                    id="client-action-modal"
                    show={this.state.action.component !== undefined}
                    onHide={this.closeModal}
                    className='p0 d-block client-action-modal'
                    style={{ zIndex: '5000000 !important', display: "block !important" }}
                    dialogClassName={this.state.dialogClassName}
                    contentClassName='overflow-auto'
                    backdropClassName="client-actions-backdrop"
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                this.state.action.name
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.action.component
                        }
                    </Modal.Body>
                    {
                        this.state.footer &&
                        (
                            <Modal.Footer>
                                {
                                    this.state.footer
                                }
                            </Modal.Footer>
                        )
                    }
                </Modal>
            }
        </div>
    )

}


ClientActions.defaultProps = {
    options: {}
}

const mapStateToProps = (state) => {
    return {
        admin: state.login.admin
    }
};

ClientActions.translations = {
    ...translations
}

export default withRouter(connect(mapStateToProps, { setLoading })(translate(ClientActions)));
