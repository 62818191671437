import React from 'react';
import { Form } from 'react-bootstrap';
import Select from './Select';
import { MDBSelect } from "mdbreact";


let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
let countries_ = countries.getNames("es");
let options = Object.keys(countries_).map(function (key) {
  return { value: key, label: countries_[key]}
});
let mdbOptions = (checked) =>{
	return Object.keys(countries_).map(
	key => {
		return { value: key, text: countries_[key], checked: key===checked}
	}
	)
};

class CountrySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        selected: props.selected === undefined ? 'AR' : props.selected
    }
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  handleCountryChange(e) {
    this.setState(e.target.value);
    this.props.onChange(e);
  }

  render() {
      //TODO traer según locale
    return (
        <React.Fragment>
            <Form.Label className='pt-2'>{this.props.label}</Form.Label>
            <Select 
            {...this.props}
            options={options}
            isSearchable={true}
            onChange={(option) => {this.props.onChange(option.value)}}
            onBlur={()=>{}}/>
        </React.Fragment>
    )
  }
}

export default CountrySelector;

export class CountrySelectorReduxForm extends React.Component {
    render(){
        const {input} = this.props;
        const value = options.filter(o => o.value === input.value)[0];
        return (
            <React.Fragment>
                <CountrySelector label={this.props.placeholder} {...input} value={value}/>
            </React.Fragment>
        )
    }
}

export class MDBCountrySelector extends React.Component {

	constructor(props){
		super(props);
		let checked;
		if(props.notUseDefault)
			checked=props.value;
		else
			checked=props.value ? props.value : 'AR';
		this.state={
			options: mdbOptions(checked)
		}
	}

	shouldComponentUpdate(nextProps, nextState){
		return this.props.value !== nextProps.value;
	}

	render(){
		return (
			<MDBSelect
				options={this.state.options}
				getValue={(value) => {this.props.getValue(value[0])}}
				selected={ this.props.label}
				className="botbit-form-input"
				search={true}
			/>
		);
	}
}
