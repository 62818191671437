import React from 'react'
import ProfileAvatar from '../utils/table/formaters/ProfileAvatar';
import Table from '../utils/table/Table';
import { EventSubType } from '../../enums/EventSubType';
import ReactCountryFlag from "react-country-flag";
import Moment from 'react-moment';
import Score from "../../../components/feedback/Score";
// TODO importar locale a usar
import 'moment/locale/es';
import { EventSource } from '../../enums/EventSource';
import { HashLoader } from 'react-spinners';
import EditTaskModal from '../../../components/clients/EditTaskModal.js';
import { MDBCard } from "mdbreact";

import { MDBIcon } from "mdbreact";
import { envConfig } from '../../../config/config';
import translate, { getTranslationFunction } from "../../../config/translations/translate";
import { translations } from "../../../config/translations/Clients/Events";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FEED = "feed", TABLE = "table";

//TODO Importar asi y no definir inline
// import { Icon } from 'botbit-ui-components';

const Icon = (props) => {

	return (
		props.customIcon ? <img alt="" src={props.icon.url} className={props.className} style={{ height: '1em', verticalAlign: 'text-bottom', ...props.style }}></img> :
			<MDBIcon fab={props.iconType === 'fab'} {...props}></MDBIcon>
	);
}

const t = getTranslationFunction(translations)

class Events extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			events: null,
			filters: this.props.filters
		};
	}

	componentDidMount() {
		this.props.getEvents(this.state.filters).then((result) => {
			this.setState({ events: result })
		}).catch((err) => {
		})
	}

	render = () => {
		const { t } = this.props;
		if (this.state.events === null)
			return (<HashLoader
				sizeUnit={"px"}
				size={100}
				color={'#fff'}
				loading={true}
			/>);
		let obj = null;
		switch (this.props.mode) {
			case FEED:
				obj = (
					<div className="position-absolute p-2 col-11" >
						<div id="vertical-timeline" className="vertical-container light-timeline no-margins ">
							{this.state.events.map(event => {
								const eventDetails = getEventDetails(event, t, this.props.updateUser);
								return eventDetails.icon === null ? "" : (
									<div key={event.id} className="vertical-timeline-block">
										<div className="vertical-timeline-icon navy-bg d-flex justify-content-center border-0">
											<Icon style={{ left: 'inherit', top: 'inherit', marginLeft: 'inherit', marginTop: 'inherit', height: 'auto' }} className="align-self-center" icon={eventDetails.icon} />
										</div>
										<MDBCard className="vertical-timeline-content" style={{ width: "95%" }}>
											<h3>{eventDetails.title}{eventDetails.subTitle !== null && <small> {eventDetails.subTitle}</small>}</h3>
											{eventDetails.description !== null && <div className="mb-1">{eventDetails.description}</div>}
											<div><span className="label text-white"><small>{event.storeName}</small></span></div>
											<div className="vertical-date">
												{/* TODO setear la locale del estado global */}
												<small><Moment format="LLL" locale="es">{event.timestamp}</Moment></small>
											</div>
										</MDBCard>
									</div>
								)
							})}
						</div>
					</div>
				);
				break;
			case TABLE:
				const columns = [(this.state.filters === undefined || this.state.filters.subTypes === undefined) && {
					dataField: 'subType',
					text: t("event"),
					classes: 'align-middle',
					formatter: (cellContent, row) => {
						const eventDetails = getEventDetails(row, t);
						return (
							<span><Icon icon={eventDetails.icon} /> {eventDetails.title} </span>
						);
					}
				}, {
					dataField: 'avatar',
					text: '',
					classes: 'align-middle',
					formatter: (cellContent, row) => {
						return (
							<ProfileAvatar blackListed={false} inactive={false} />
						);
					}
				}, {
					dataField: 'user',
					text: t("name"),
					classes: 'align-middle',
					formatter: (cellContent, row) => {
						return (
							`${row.user.name} ${row.user.lastName}`
						);
					}
				}, {
					dataField: 'user.email',
					text: 'Email',
					classes: 'align-middle',
					formatter: (cellContent, row) => {
						return (
							<a href={`mailto:${cellContent}`}>{cellContent}</a>
						);
					}
				}, {
					dataField: 'timestamp',
					text: t("dateAndTime"),
					classes: 'align-middle',
					formatter: (cellContent, row) => {
						return (
							//TODO setear la locale del estado global
							<span><Moment format="LLL" locale="es">{cellContent}</Moment></span>
						);
					}
				}, (this.state.filters === undefined || this.state.filters.stores === undefined) && {
					dataField: 'store',
					text: t("store"),
					classes: 'align-middle',
					formatter: (cellContent, row) => {
						return (
							<span><ReactCountryFlag code={cellContent.country} svg /> {cellContent.name}</span>
						);
					}
				}, {
					dataField: 'customProps',
					text: t("detail"),
					classes: 'align-middle',
					formatter: (cellContent, row) => {
						const eventDetails = getEventDetails(row, t);
						return (
							<span>{eventDetails.description !== null && eventDetails.description}</span>
						);
					}
				}];
				obj = <Table
					columns={columns}
					data={this.state.events}
					breackpointToCard='md'
				/>
				break;
			default:
				obj = (<div>Unsupported mode</div>)
		}
		return (obj)
	}
}

const getEventDetails = (event, t, updateUser) => {
	let icon = null;
	let title = null;
	let subTitle = null;
	let description = null;
	switch (event.subType.id) {
		case EventSubType.CHECK_IN:
			let checkedInThrough = null;
			if (event.source === EventSource.CLOUDTRAX || event.source === EventSource.MERAKI)
				checkedInThrough = { desc: 'WiFi (Presence)', icon: 'wifi' };
			else if (event.source === EventSource.MERCADO_PAGO)
				checkedInThrough = { desc: 'MercadoPago', icon: 'shopping-cart' };
			else
				if (event.customProps.pointOfContactId === 1)
					checkedInThrough = { desc: 'WiFi', icon: 'wifi' };
				else if (event.customProps.pointOfContactId === 8)
					checkedInThrough = { desc: 'Redeem', icon: 'qrcode' };
			// {"mac": "00-39-5F-45-38-08", "count": 76, "last_seen": 1549720960, "associated": false, "avg_signal": -58, "first_seen": 1549720555, "max_signal": -52, "min_signal": -68, "last_seen_signal": -66, "total_time_in_store": 405000, "isCustomerRegistered": false}
			icon = 'user-check'
			title = 'Check In'
			subTitle = <span className="label label-success font-weight-normal">{checkedInThrough === null ? t("other") : <React.Fragment>{checkedInThrough.desc} <Icon icon={checkedInThrough.icon} /></React.Fragment>}</span>
			description = (event.customProps === undefined || event.customProps.total_time_in_store === undefined || event.customProps.total_time_in_store < 60000) ? null : <React.Fragment>Tiempo de permanencia: <b>{Math.round(event.customProps.total_time_in_store / 1000 / 60)} minutos</b></React.Fragment>
			break;
		case EventSubType.UNREGISTRATION:
			let registeredThrough = getRegisteredThroughConfiguration(event.customProps.registeredThrough, event.source, t);
			icon = 'user-minus'
			title = t("unregistration")
			subTitle = <span className={`label label-success font-weight-normal`} style={registeredThrough && registeredThrough.badgeStyles} >{registeredThrough === null ? 'Unknown' : <React.Fragment>{registeredThrough.desc} <Icon style={registeredThrough.iconStyles} icon={registeredThrough.icon} customIcon={registeredThrough.customIcon} /></React.Fragment>}</span>
			description = null;
			break;
		case EventSubType.REGISTRATION:
			// {"mac": "D4-A3-3D-D3-BC-08", "pointOfContactId": 1, "deviceAppClientId": 2287483, "registeredThrough": "WiFi"}
			registeredThrough = getRegisteredThroughConfiguration(event.customProps.registeredThrough, event.source, t);
			icon = 'user-plus'
			title = t("registration")
			subTitle = <span className="label label-success font-weight-normal" style={registeredThrough && registeredThrough.badgeStyles}>{registeredThrough === null ? 'Unknown' : <React.Fragment>{registeredThrough.desc} <Icon style={registeredThrough.iconStyles} iconType={registeredThrough.iconType} icon={registeredThrough.icon} customIcon={registeredThrough.customIcon} /></React.Fragment>}</span>
			description = null;
			break;
		case EventSubType.WALKTRHOUGH:
			// {"cost": 13, "ticket": 300, "campaignId": 983, "campaignName": "Perdidos 1", "isCustomCampaign": false, "campaignOpenEventId": 30496166}
			icon = "dollar-sign";
			// TODO: localizar y formatear el ticket
			title = 'Walkthrough'
			subTitle = <span className='label label-primary font-weight-normal font-weight-normal'>$ {event.customProps.ticket}</span>
			description = getCampaignDescription(event.customProps);
			break;
		case EventSubType.ERROR:
			icon = "ban";
			title = t("bouncedCampaign")
			description = getCampaignDescription(event.customProps)
			break;
		case EventSubType.BOUNCED:
			icon = "ban";
			title = t("bouncedCampaign")
			description = getCampaignDescription(event.customProps)
			break;
		case EventSubType.DELIVERED:
			// {"ip": "168.245.45.10", "tls": 1, "email": "meinolf.leuchtmann@gmail.com", "event": "delivered", "smtp-id": "<35lkICUCRZ-6auELgaaJuQ@ismtpd0007p1maa1.sendgrid.net>", "variant": "Nuevos clientesA", "category": "campaignId=2406&variante=Nuevos clientesA&isCustomCampaign=0&storeId=377", "response": "250 2.0.0 OK  1550508912 i124si7833315ybb.90 - gsmtp ", "timestamp": 1550508912, "campaignId": 2406, "sg_event_id": "RFKQ9bTmSe6trqH9fq_2iQ", "campaignName": "Nuevos clientes", "sg_message_id": "35lkICUCRZ-6auELgaaJuQ.filter0397p1iad2-4612-5C6AE36D-26.0", "isCustomCampaign": false}
			icon = "envelope";
			title = t("deliveredCampaign")
			description = getCampaignDescription(event.customProps)
			break;
		case EventSubType.OPENED:
			// {"ip": "66.102.8.106", "email": "narayoli@hotmail.com", "event": "open", "variant": "CumpleaÃ±osA", "category": "campaignId=1936&variante=CumpleaÃ±osA&isCustomCampaign=0&storeId=310", "timestamp": 1550675722, "useragent": "Mozilla/5.0 (Windows NT 5.1; rv:11.0) Gecko Firefox/11.0 (via ggpht.com GoogleImageProxy)", "campaignId": 1936, "sg_event_id": "RqM65oVYTGq5y9DxpL_gaQ", "campaignName": "Perdidos 1", "sg_message_id": "SZ3vmYHbTkGEXL8xoQempQ.filter0215p1iad2-9271-5C6B20F4-3F.0", "isCustomCampaign": false}
			icon = "envelope-open";
			title = t("campaignOpen")
			description = getCampaignDescription(event.customProps)
			break;
		case EventSubType.CLICKED:
			//{"ip": "186.18.124.134", "url": "https://admin.botbit.io/npsFeedback.php?storeId=630&userId=185443&emailUniqueId=1854435c6731b1c5a410.95743941&score=8", "email": "mrocio79@gmail.com", "event": "click", "variant": null, "category": "campaignId=-2&isCustomCampaign=0&storeId=630", "timestamp": 1550675829, "useragent": "Mozilla/5.0 (Linux; Android 8.1.0; SM-G610M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/72.0.3626.105 Mobile Safari/537.36", "campaignId": -2, "url_offset": {"type": "html", "index": 2}, "sg_event_id": "S2DqebrFR1eSPRNjih2nyQ", "campaignName": "NPS", "sg_message_id": "HiJPBGKDQ9mZX8Xp2epfIQ.filter0898p1las1-18208-5C6731B1-30.0", "isCustomCampaign": false}
			icon = "mouse-pointer";
			title = t("clickedCampaign")
			description = <React.Fragment>{getCampaignDescription(event.customProps)}<br />URL: <small>{event.customProps.url}</small></React.Fragment>;
			break;
		case EventSubType.NPS_FEEDBACK:
			//{"score": 9, "comment": "", "emailUniqueId": "673-3909.219182016965", "npsFeedbackId": 172546}
			icon = "comment";
			//const type = getReviewType(event)
			title = getReviewTitle(event, t)
			// subTitle = <span className={`label label-${type} font-weight-normal`}>{event.customProps.score}</span>
			subTitle = <Score nps value={event.customProps.score} size='s' />
			description = event.customProps.comment && event.customProps.comment.length > 0 ? <div className="card card-body bg-light mt-2 font-italic mb-3 shadow-none"><small>"{event.customProps.comment}"</small></div> : null
			break;
		case EventSubType.FEEDBACK_REPLY:
			//{"score": 9, "comment": "One day, I know we'll meet again.", "replierId": 455, "replyTime": 1561733244472, "externalId": null,
			// "replyComment": "Under the iron sky"}
			icon = "reply";
			title = event.customProps.replierDisplayName && event.customProps.replierDisplayName.length > 0 ? getReviewReplyTitle(event, t) + event.customProps.replierDisplayName : getReviewReplyTitle(event, t) + getReviewReplyAdmin(event, t)
			description = event.customProps.replyComment && event.customProps.replyComment.length > 0 ? <div className="card card-body bg-light mt-2 font-italic mb-3 shadow-none"><small>"{event.customProps.replyComment}"</small></div> : null
			break;
		case EventSubType.FEEDBACK_DELETE:
			//{"score": 9, "comment": "One day, I know we'll meet again.", "replierId": 455, "replyTime": 1561733244472, "externalId": null,
			// "replyComment": "Under the iron sky"}
			icon = "trash";
			title = t('reviewDeleted')
			subTitle = <Score value={event.customProps.score} size='s' />
			description = event.customProps.comment && event.customProps.comment.length > 0 ? <div className="card card-body bg-light mt-2 font-italic mb-3 shadow-none"><small>"{event.customProps.comment}"</small></div> : null
			break;
		case EventSubType.REVIEW_REQUEST:
			//{"channel": "EMAIL"}
			icon = "paper-plane";
			title = t('reviewRequest')
			subTitle = <span className="label label-success font-weight-normal" style={registeredThrough && registeredThrough.badgeStyles}>{event.customProps.channel}</span>
			description = null
			break;
		case EventSubType.NPS_SENT:
			//{"channel": "EMAIL"}
			icon = "paper-plane";
			title = t('npsSent')
			subTitle = <span className="label label-success font-weight-normal" style={registeredThrough && registeredThrough.badgeStyles}>{event.customProps.channel}</span>
			description = null
			break;
		case EventSubType.FACEBOOK_LIKE:
			//{}
			icon = "thumbs-up";
			title = 'Facebook like';
			subTitle = <span className='label label-success font-weight-normal'><Icon icon={["fab", "facebook"]} /></span>
			break;
		case EventSubType.TWITTER_FOLLOW:
			//{}
			icon = "thumbs-up";
			title = 'Twitter follow';
			subTitle = <span className='label label-success font-weight-normal'><Icon icon={["fab", "twitter"]} /></span>
			break;
		case EventSubType.INSTAGRAM_FOLLOW:
			//{}
			icon = "thumbs-up";
			title = 'Instagram follow';
			subTitle = <span className='label label-success font-weight-normal'><Icon icon={["fab", "instagram"]} /></span>
			break;
		case EventSubType.TRIP_ADVISOR_LINK_CLICK:
			//{}
			icon = "thumbs-up";
			title = 'TripAdvisor link click';
			subTitle = <span className='label label-success font-weight-normal'><Icon icon={["fab", "tripadvisor"]} /></span>
			description = "Promotor NPS redireccionado a TripAdvisor"
			break;
		case EventSubType.GOOGLE_MY_BUSINESS_LINK_CLICK:
			//{}
			icon = "thumbs-up";
			title = 'GMB link click';
			subTitle = <span className='label label-success font-weight-normal'><Icon icon={["fab", "google"]} /></span>
			description = "Promotor NPS redireccionado a Google My Business"
			break;
		case EventSubType.CHOOSED_PRODUCT:
			// {
			//   "storeId": 2,
			//   "storeName": "Buller Recoleta",
			//   "companyId": 2,
			//   "ticketAverage": 290,
			//   "timestamp": 1501533178000,
			//   "eventSubType": {
			//     "id": 61,
			//     "name": "CHOOSED_PRODUCT",
			//     "eventTypeId": "6"
			//   },
			//   "customProps": {
			//     "productId": 2
			//   },
			//   "productImage": "honey-beer-buller.png",
			//   "productName": "Honey",
			//   "source": "BOTBIT"
			// },      
			icon = "hand-pointer";
			title = t("chosenProduct")
			// TODO: des-harcodear url
			description =
				<img src={`https://cdn-admin.botbit.io/common/img/stores/${event.companyId}/${event.productImage}`}
					alt={event.productName}
					style={{ maxWidth: '100%' }} />
			break;
		case EventSubType.PAYMENT_SUCCESFULL:
		case EventSubType.PAYMENT_REJECTED:
		case EventSubType.PAYMENT_CANCELLED:
		case EventSubType.PAYMENT_REFUNDED:
		case EventSubType.PAYMENT_IN_PROCESS:
			let status = null;
			if (event.subType.id === EventSubType.PAYMENT_SUCCESFULL)
				status = <span className="label label-primary font-weight-normal">{t("succesful")}</span>
			else if (event.subType.id === EventSubType.PAYMENT_REJECTED)
				status = <span className="label label-danger font-weight-normal">{t("rejected")}</span>
			else if (event.subType.id === EventSubType.PAYMENT_CANCELLED)
				status = <span className="label label-danger font-weight-normal">{t("canceled")}</span>
			else if (event.subType.id === EventSubType.PAYMENT_REFUNDED)
				status = <span className="label label-warning font-weight-normal">{t("returned")}</span>
			else if (event.subType.id === EventSubType.PAYMENT_IN_PROCESS)
				status = <span className="label label-success font-weight-normal">{t("inProgress")}</span>
			// {"paymentId": "4532201563", "currencyId": "ARS", "couponAmount": 0, "paymentMethod": "credit_card", "paymentReason": "Temple Craft Hollywood", "receivedAmount": 385}
			icon = "shopping-cart";
			title = t("payment")
			subTitle = <React.Fragment><span className='label label-primary font-weight-normal mr-1'>{event.customProps.currencyId} {event.customProps.receivedAmount}</span>{status}</React.Fragment>
			description = <React.Fragment>{t("paymentMethod")}: {event.customProps.paymentMethod}<br />Producto: {event.customProps.paymentReason}</React.Fragment>
			break;
		case EventSubType.GAME_PLAYED:
			//{}
			icon = 'dice';
			title = t("gamePlayed");
			break;
		case EventSubType.GAME_WON:
			//{"prizeWon": "Â¡2x1 en Cerveza!"}
			icon = 'trophy';
			title = t("gameWon");
			description = `${t("prize")}: ${event.customProps.prizeWon}`
			break;
		case EventSubType.UPDATE_TASK:
			icon = 'tasks'
			title = t("updatedTask")
			subTitle = (
				<span>
					{getStatus(event.customProps.taskStatusId, t)}
					<div className="text-right position-relative" style={{ top: "-1.2em" }} >
						<EditTaskModal taskId={event.customProps.id} updateUser={updateUser} />
					</div>
				</span>
			)
			description =
				<>
					<b>{t("objective")}: </b>{event.customProps.type}<br />
					<b>{t("expiration")}: </b><Moment format="LLL" locale="es">{event.customProps.dueDate}</Moment>
					{[...getTaskDescription(t, event.customProps)]}
					<b>{t("assignedTo")}:</b> {event.customProps.adminTo}<br />
					{event.customProps.notes && event.customProps.notes.length &&
						<div className="bg-light p-2 mt-1 mb-3 font-italic">
							<small>{event.customProps.notes}</small>
						</div>
					}
				</>
			break;
		case EventSubType.CREATE_TASK:
			icon = 'tasks'
			title = t("createdTask")
			subTitle = (
				<span>
					{getStatus(event.customProps.taskStatusId, t)}
					<div className="text-right position-relative" style={{ top: "-1.2em" }} >
						<EditTaskModal taskId={event.customProps.id} updateUser={updateUser} />
					</div>
				</span>
			)
			description = event.customProps.adminTo &&
				event.customProps.adminTo.length > 0 ?
				<>
					<b>{t("objective")}: </b>{event.customProps.type}<br />
					<b>{t("expiration")}: </b> <Moment format="LLL" locale="es">{event.customProps.dueDate}</Moment>
					{[...getTaskDescription(t, event.customProps)]}
					<b>{t("assignedTo")}:</b> {event.customProps.adminTo}<br />
					{event.customProps.notes && event.customProps.notes.length &&
						<div className="bg-light p-2 mt-1 mb-3 font-italic">
							<small>{event.customProps.notes}</small>
						</div>
					}
				</> :
				null;
			break;
		case EventSubType.INQUIRY:
			icon = 'comments'
			title = t("consultation")
			description =
				<>
					<b>{t("registeredBy")}: </b> {event.customProps.adminFrom}<br />
					{event.customProps.notes && event.customProps.notes.length &&
						<div className="bg-light p-2 mt-1 mb-3 font-italic">
							<small>"{event.customProps.notes}"</small>
						</div>
					}
				</>
			break;

		case EventSubType.SET_CUSTOMER_STATUS:
			icon = 'user'
			title = t("statusChange")
			let registeredBy = undefined;
			switch (event.source) {
				case EventSource.RDSTATION:
					registeredBy = 'RD Station';
					subTitle = <span className={" label font-weight-normal label-info m-2 "}>{t(`userStatusType.${event.customProps.userStatusType}`)}</span>
					break;
				case EventSource.OTHER:
					registeredBy = 'Externo';
					subTitle = <span className={" label font-weight-normal label-info m-2 "}>{event.customProps.userStatusType}</span>;
					break;
				default:
					subTitle = getCustomerStatus(event.customProps.userStatusTypeId, t(`userStatusType.${event.customProps.userStatusTypeId}`));
					registeredBy = event.customProps.administrator;
			}
			description = (
				<>
					<b>{t("registeredBy")}: </b> {registeredBy}<br />
				</>
			)
			break;
		case EventSubType.INTERACTION:
			if (event.source === EventSource.RDSTATION) {
				icon = 'user'
				title = t("interaction")
				subTitle = <span className={" label font-weight-normal label-info m-2 "}>{event.customProps.clientInteractionType}</span>
				description = <><b>{t("registeredBy")}: </b> RD Station</>
			}
			break;
		case EventSubType.PURCHASE_DONE:
			icon = "dollar-sign";
			title = t("purchaseOrder")
			subTitle = <span className='label label-primary font-weight-normal font-weight-normal'>{t("completed")}</span>
			description = getPurchaseDescription(event.customProps);
			break;
		case EventSubType.PURCHASE_STIMULATED:
			icon = "dollar-sign";
			title = t("purchaseOrder")
			subTitle = <span className='label label-primary font-weight-normal font-weight-normal'>{t("stimulatedByBotbit")}</span>
			description = getPurchaseDescription(event.customProps);
			break;
		case EventSubType.EMAIL_RECIEVED:
			icon = "envelope";
			title = t("emailReceived")
			description = getEmailDescription(event.customProps);
			break;
		default:
			break;
	}
	return {
		icon: icon,
		title: title,
		description: description,
		subTitle: subTitle
	}
}

/* const getReviewType = (event) => {
  if (event.source === EventSource.BOTBIT) {
	return event.customProps.score > 8 ? 'primary' : event.customProps.score < 7 ? 'danger' : 'warning'
  }
  return event.customProps.score > 4 ? 'primary' : event.customProps.score < 4 ? 'danger' : 'warning'
}*/

const getReviewTitle = (event, t) => {
	return (event.source === EventSource.BOTBIT) ? t('satisfactionSurvey') : (t('reviewIn') + getSourceName(event, t))
}

const getSourceName = (event, t) => {
	return (event.source) ? (event.source.charAt(0).toUpperCase() + event.source.slice(1).toLowerCase()) : t('uknownSource')
}

const getReviewReplyTitle = (event, t) => {
	return t('responseFrom')
}

const getReviewReplyAdmin = (event, t) => {
	return t('administrator')
}

const getCampaignDescription = (customProps) => {
	return (
		<button
			className="btn btn-secondary btn-sm float-none"
			onClick={(campaignId) => showCampaignDetails(campaignId)}>{customProps.campaignName}
			{/* TODO: truncar texto del botón */}
			{/* <span className="d-inline-block text-truncate" style={{maxWidth: 'calc(100%-10px)'}}>{customProps.campaignName}</span> */}
		</button>)
}

const getPurchaseDescription = (customProps) => {
	var items = {};

	if (customProps.items) {
		customProps.items.forEach(element => {
			if (items[element.category])
				items[element.category]++
			else
				items[element.category] = 1;
		})
	}

	var products = Object.keys(items).map(function (key) {
		return items[key] + " " + key + "  ";
	});
	const currency = customProps.currency ? customProps.currency.toUpperCase() + " " : "";
	return (
		<div>
			{ customProps.total_normalized && <><b>Consumo: </b> {currency}{customProps.total_normalized.toLocaleString(undefined, { maximumFractionDigits: 2 })}<br /></>}
			{products}<br />
		</div>
	)
}

const getEmailDescription = (customProps) => {
	return (
		<div>
			<b>Asunto: </b> <span className="d-block w-100 text-truncate">{customProps.subject}</span>
		</div>
	)
}

const getTaskDescription = (t, customProps) => {
	return (
		[<div
		><b>Canal:</b> {t(`channel.${customProps.channel}`)}
			{/* TODO: truncar texto del botón */}
			{/* <span className="d-inline-block text-truncate" style={{maxWidth: 'calc(100%-10px)'}}>{customProps.campaignName}</span> */}
		</div>])
}

const getCustomerStatus = (id, status) => {
	let className = "";
	switch (id) {
		case 1:
			className = " label-info "
			break;
		case 2:
			className = " label font-weight-normal "
			break;
		case 3:
			className = " label-danger "
			break;
		case 4:
			className = " font-weight-normal "
			break;
		default:
			break;
	}
	return (
		<span className={" label font-weight-normal " + className + " m-2 "}>{status}</span>
	)
}

const showCampaignDetails = (campaignId) => {
	alert("Not implemented yet");
}

const getStatus = (id, t) => {
	let status = "";
	let className = "";
	switch (id) {
		case 1:
			status = t("pending");
			className = "label label-danger font-weight-normal"
			break;
		case 2:
			status = t("closed");
			className = "label label-primary font-weight-normal"
			break;
		case 3:
			status = t("reopen");
			className = "label label-danger font-weight-normal"
			break;
		default:
			break;
	}
	return (
		<span className={className + " mx-2"}>{status}</span>
	)
}

export const getRegisteredThroughConfiguration = (rt, source) => {
	let registeredThrough = null;
	switch (rt) {
		case "WiFi":
			registeredThrough = { desc: 'WiFi', icon: 'wifi' };
			break;
		case "Importation":
			registeredThrough = { desc: t("importation"), icon: 'upload' };
			break;
		case "Manual":
			registeredThrough = { desc: t("manual"), icon: 'hand-paper' };
			break;
		case "Payment":
			registeredThrough = { desc: t("payment"), icon: 'shopping-cart' };
			break;
		case "Other":
			registeredThrough = { desc: t("other"), icon: 'question' };
			break;
		case "Inquiry":
			registeredThrough = { desc: t("consultation"), icon: 'comment' };
			break;
		case "Form":
			registeredThrough = { desc: 'Form', icon: 'fa-wpforms' };
			break;
		case "Reservation":
			registeredThrough = { desc: t("reservation"), icon: 'chair' };
			break;
		case "Tablet":
			registeredThrough = { desc: 'Tablet', icon: 'tablet' };
			break;
		case "ReviewRequest":
			registeredThrough = { desc: t("reviewRequest"), icon: 'paper-plane' };
			break;
		case "CRM":
			registeredThrough = { desc: 'CRM', icon: 'users' };
			if (source === EventSource.RDSTATION) {
				registeredThrough.desc = 'RD Station'
				registeredThrough.iconStyles = { marginLeft: "5px" }
				registeredThrough.customIcon = true;
				//registeredThrough.icon={url: envConfig.staticImgUrl + "/source_logos/rdstation-square-logo.png"}
				registeredThrough.badgeStyles = { display: "inline-flex", alignItems: "center", backgroundColor: "#15325d" }
			}
			break;
		case "OnlineOrdering":
			registeredThrough = { desc: t("purchase"), icon: 'shopping-cart' };
			if (source === "SHOPIFY") {
				registeredThrough.desc = "Shopify"
				registeredThrough.iconStyles = { marginLeft: "5px" }
				registeredThrough.customIcon = true;
				registeredThrough.icon = { url: envConfig.staticImgUrl + "/source_logos/shopify-square-logo.png" }
				registeredThrough.badgeStyles = { display: "inline-flex", alignItems: "center", backgroundColor: "#212B36" }
			}
			break;
		case "Email":
			registeredThrough = { desc: 'Email', icon: 'envelope' };
			if (source === "GMAIL") {
				registeredThrough.desc = "Gmail"
				registeredThrough.iconStyles = { marginLeft: "5px" }
				registeredThrough.customIcon = true;
				registeredThrough.icon = { url: envConfig.staticImgUrl + "/source_logos/gmail-square-logo.png" }
				registeredThrough.badgeStyles = { display: "inline-flex", alignItems: "center", backgroundColor: "#3E65CF" }
			}
			break;
		case "SMS":
			registeredThrough = { desc: 'SMS', icon: 'sms' };
			break;
		case "WHATSAPP":
		case "Whatsapp":
			registeredThrough = { desc: 'Whatsapp', icon: 'whatsapp', iconType: "fab" };
			break;
		case "EMAIL":
			registeredThrough = { desc: 'Email', icon: 'envelope' };
			if (source === "GMAIL") {
				registeredThrough.desc = "Gmail"
				registeredThrough.iconStyles = { marginLeft: "5px" }
				registeredThrough.customIcon = true;
				registeredThrough.icon = { url: envConfig.staticImgUrl + "/source_logos/gmail-square-logo.png" }
				registeredThrough.badgeStyles = { display: "inline-flex", alignItems: "center", backgroundColor: "#3E65CF" }
			}
			break;
		case "FacebookMessenger":
		case "Instagram":
			registeredThrough = { desc: rt, icon: 'comment' };
			break;
		default:
			registeredThrough = null;
			break;
	}
	return registeredThrough;
}

Events.translations = { ...translations }

export default translate(Events)
