import React from 'react';
import { translate } from 'react-i18next';
import Swal from 'sweetalert2'

class Confirmation extends React.Component {

	render() {

        var { t } = this.props;
        var tittle = t("confirmation." + this.props.actionType + ".tittle");
        var text = t("confirmation." + this.props.actionType + ".contentSuccesfull");
        Swal.fire({
            title: tittle,
            type: 'info',
            text: text
        }).then((result) => {
            var url = "/";
            window.location.href = url;
        });

		return null;
	}
}

export default (translate('common')(Confirmation));