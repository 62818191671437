import axiosClient from './axiosClient';

export const processParameters = (...params) => {
    const paramsObj = new URLSearchParams();
    params.forEach(([value, key]) => {
        if (value) {
            let parsedValue = value;
            if (Object.prototype.toString.call(value) === '[object Date]') {
                parsedValue = parsedValue.toISOString();
            }
            paramsObj.append(key, parsedValue);
        }
    })
    return paramsObj;
}

export const getConversations = ({ storeId, sort, state, lastMessageTimestamp, totalResults, assignmentFilter, 
    assignedAdminId, userPhone, userName, issueTypes, issueStates }) => {
    const params = processParameters(
        [storeId, "storeId"],
        [sort, "sort"],
        [state, "state"],
        [lastMessageTimestamp, "lastMessageTimestamp"],
        [totalResults, "totalResults"],
        [assignmentFilter, "assignmentFilter"],
        [assignedAdminId, "assignedAdminId"],
        [userPhone, "userPhone"],
        [userName, "userName"],
        [issueTypes, "issueTypes"],
        [issueStates, "issueStates"]
    )
    return axiosClient.get(`/conversations?${params}`)
    // return Promise.resolve({ data: { "data": [{ "id": 10, "channel": "WHATSAPP", "user": { "id": 3787859, "name": "Testvalidphoneuser", "lastName": "542494608347", "email": "+542494608347@botbitmail.com", "avatar": null, "fullName": "Testvalidphoneuser 542494608347" }, "creationTimestamp": 1622744012000, "lastStateChangeTimestamp": null, "state": "OPEN", "expiredState": "ACTIVE", "lastSessionOpenedTimestamp": 1623191839000, "lastMessageTimestamp": 1623191839000 }, { "id": 11, "channel": "WHATSAPP", "user": { "id": 3787859, "name": "Testvalidphoneuser", "lastName": "542494608347", "email": "+542494608347@botbitmail.com", "avatar": null, "fullName": "Testvalidphoneuser 542494608347" }, "creationTimestamp": 1622744012000, "lastStateChangeTimestamp": null, "state": "OPEN", "expiredState": "ACTIVE", "lastSessionOpenedTimestamp": 1623191839000, "lastMessageTimestamp": 1610145439000 }], "result": "success", "message": "ok", "statusCode": 200 } })
}

export const getMessagesFromConversaion = ({ conversationId, lastMessageTimestamp, totalResults }) => {
    const params = processParameters(
        [lastMessageTimestamp, "lastMessageTimestamp"],
        [totalResults, "totalResults"]
    )
    return axiosClient.get(`/conversations/${conversationId}/messages?${params}`)
    // return Promise.resolve({ data: { "data": [{ "id": 12, "direction": "OUT", "senderAdmin": { "id": 455, "name": "Gus", "lastName": "Local", "email": "gustavo@botbit.io", "avatar": "455_avatar-vader.png", "username": "gfuhr", "fullName": "Gus Local" }, "type": "TEXT", "state": "REQUESTED", "requestedTimestamp": 1623192377000, "sentTimestamp": null, "deliveredTimestamp": null, "viewedTimestamp": null, "changedConversationState": null, "externalId": null, "message": "prueba desde Junit" }, { "id": 13, "direction": "IN", "senderAdmin": null, "type": "TEXT", "state": "REQUESTED", "requestedTimestamp": 1623190997000, "sentTimestamp": null, "deliveredTimestamp": null, "viewedTimestamp": null, "changedConversationState": null, "externalId": null, "message": "lalalaala" }], "result": "success", "message": "ok", "statusCode": 200 } })
}

export const sendMessage = (body) => {
    //return axiosClient.post(`/conversations/send`, { ...body })
    return sendMessageFile(body, null)
    // return Promise.resolve({ data: { "data": { "id": 24, "direction": "OUT", "senderAdmin": { "id": -7, "name": "botbit_salesforce", "lastName": "", "email": "dev@botbit.io.noexiste", "avatar": "", "username": "botbit_salesforce", "fullName": "botbit_salesforce" }, "type": "TEXT", "state": "REQUESTED", "requestedTimestamp": 1623359985868, "sentTimestamp": null, "deliveredTimestamp": null, "viewedTimestamp": null, "conversation": { "id": 10, "channel": "WHATSAPP", "user": { "id": 3787859, "name": "Testvalidphoneuser", "lastName": "542494608347", "email": "+542494608347@botbitmail.com", "avatar": null, "fullName": "Testvalidphoneuser 542494608347" }, "creationTimestamp": 1622744012000, "lastStateChangeTimestamp": null, "state": "OPEN", "expiredState": "WAITING_FOR_USER", "lastSessionOpenedTimestamp": 1623358605000, "lastMessageTimestamp": 1623359985868 }, "changedConversationState": null, "externalId": null, "message": "esto es una prueba desde postman con conversationId" }, "result": "success", "message": "ok", "statusCode": 200 } })
}

export const sendMessageFile = (message, file) => {
    //console.log("messengerApi.sendMessageFile message", message)
    const msgJson = JSON.stringify(message)
    const msgBlob = new Blob([msgJson], {
        type: 'application/json'
    })
    const formData = new FormData();
    formData.append('message', msgBlob);
    if (file)
        formData.append('attachment', file);

    return axiosClient.post(`/conversations/send`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
          }
    })
    // return Promise.resolve({ data: { "data": { "id": 24, "direction": "OUT", "senderAdmin": { "id": -7, "name": "botbit_salesforce", "lastName": "", "email": "dev@botbit.io.noexiste", "avatar": "", "username": "botbit_salesforce", "fullName": "botbit_salesforce" }, "type": "TEXT", "state": "REQUESTED", "requestedTimestamp": 1623359985868, "sentTimestamp": null, "deliveredTimestamp": null, "viewedTimestamp": null, "conversation": { "id": 10, "channel": "WHATSAPP", "user": { "id": 3787859, "name": "Testvalidphoneuser", "lastName": "542494608347", "email": "+542494608347@botbitmail.com", "avatar": null, "fullName": "Testvalidphoneuser 542494608347" }, "creationTimestamp": 1622744012000, "lastStateChangeTimestamp": null, "state": "OPEN", "expiredState": "WAITING_FOR_USER", "lastSessionOpenedTimestamp": 1623358605000, "lastMessageTimestamp": 1623359985868 }, "changedConversationState": null, "externalId": null, "message": "esto es una prueba desde postman con conversationId" }, "result": "success", "message": "ok", "statusCode": 200 } })
}

export const getSourceAccounts = ({ storeId, category }) => {
    return axiosClient.get(`/sources/stores/${storeId}?category=${category}`)
}

export const markConversationAsClosed = (conversationId) => {
    return axiosClient.put(`/conversations/${conversationId}/close`)
}

export const markConversationAsOpen = (conversationId) => {
    return axiosClient.put(`/conversations/${conversationId}/open`)
}

export const setMsgAsRead = (messageId) => {
    return axiosClient.put(`/conversations/messages/${messageId}/viewed`);
}

export const getConversation = (body) => {
    return axiosClient.post(`/conversations`, { ...body })
}

export const getEvolution = (storeIds, period) => {
    const params = processParameters(
        [storeIds, "storeIds"],
        [period && period.from, "dateFrom"],
        [period && period.to, "dateTo"]
    );
    return axiosClient.get(`/conversations/evolution?${params}`)
}

export const getEvolutionCsv = (storeIds, period) => {
    const params = processParameters(
        [storeIds, "storeIds"],
        [period && period.from, "dateFrom"],
        [period && period.to, "dateTo"]
    );
    return axiosClient.get(`/conversations/evolution/csv?${params}`)
}

export const assignConversation = (conversationId, administratorId, comment, notifyUser ) => {
    const params = processParameters(
        [administratorId, "targetAdminId"],
        [comment, "comment"],
        [notifyUser, "notifyUser"]
    );
    return axiosClient.post(`/conversations/${conversationId}/assign?${params}`)
}

export const unassignConversation = (conversationId, comment ) => {
    const params = processParameters(
        [comment, "comment"]
    );
    return axiosClient.post(`/conversations/${conversationId}/unassign?${params}`)
}

export const getConversationById=(conversationId)=>{
    // return Promise.resolve({ data: { "data": { "id": 9, "channel": "WHATSAPP", store:{id:3}, "user": { "id": 3787859, "name": "Testvalidphoneuser", "lastName": "542494608347", "email": "+542494608347@botbitmail.com", "avatar": null, "fullName": "Testvalidphoneuser 542494608347" }, "creationTimestamp": 1622744012000, "lastStateChangeTimestamp": null, "state": "OPEN", "expiredState": "ACTIVE", "lastSessionOpenedTimestamp": 1623191839000, "lastMessageTimestamp": 1623191839000 }, "result": "success", "message": "ok", "statusCode": 200 } })
    return axiosClient.get(`/conversations/${conversationId}/conversation`)
}

