import React, { useEffect, useState } from 'react';
import { Table, Filters } from "botbit-ui-components"
import { getTypesForCompany, deleteIssueType } from '../../api/issuesApi';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Issues/IssueTypesConfigurationContainer";

import "./IssueTypesConfigurationContainer.scss"
import withEventTracking from '../../config/withEventTracking';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../commons/components/application/miscActions';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const IssuesConfigurationContainer = ({ t, trackEvent }) => {
	const availableCompanies = useSelector(state => state.application.availableCompanies)
	const selectedCompany = useSelector(state => state.application.selectedCompany);
	//const loggedAdmin = useSelector(state => state.login.admin);
	const [filterValues, setFilterValues] = useState(null);
	const dispatch = useDispatch();
	const history=useHistory();

	useEffect(() => {
		let newFilterValues = { companies: [selectedCompany.id] }
		if (JSON.stringify(filterValues) !== JSON.stringify(newFilterValues)) {
			setFilterValues({ ...newFilterValues })
		}
	}, [])

	const getRowActions = (row, onchange) => {
		let rowActions = [{
			key: "issue-actions-edit",
			icon:"edit",
			label: t("table.rowActions.edit.label"),
			onClick: () => { 
				history.push("/settings/issues/" + row.id) 
			}
		}, {
			key: "issue-actions-delete",
			icon:"trash",
			label: t("table.rowActions.delete.label"),
			onClick: () => MySwal.fire({
				type: "warning",
				text: t("table.rowActions.delete.text"),
				showCancelButton: true,
				showConfirmButton: true,
				confirmButtonText: t("table.rowActions.delete.confirm"),
				confirmButtonColor: "#ff7043",
				cancelButtonText:  t("table.rowActions.delete.cancel")
			}).then(res => {
				if (res.value) {
					deleteIssueType(row.id).then((response) => {
						onchange(row, "delete");
						MySwal.fire({
							type: "success",
							text: t("table.rowActions.delete.feedback"),
							confirmButtonText: t("table.rowActions.delete.close"),
							confirmButtonColor: "#ff7043"
						})
					})
				}
			})
		}]
		return rowActions;
	}

	const getColumns = () => {
		let columns = [{
				dataField: "name",
				title: t("table.columns.name"),
				size: 20,
			}, {
				dataField: "description",
				title: t("table.columns.description"),
				size: 20,
			}, {
				dataField: "states",
				title: t("table.columns.states"),
				size: 60,
				formatter: (cellContent, row) => {
					var content = "";
					if (row && row.states && row.states.length > 0) {
						row.states.forEach( (element, index) => {
							content = content + element.name
							if (index < row.states.length - 1)
								content = content + ", ";
						})
					}
					return content;
			},
		}];
		return columns;
	}

	const getSchemas = () => {
		const schemas = {
			companies: {
				id: "companies",
				label: t("company"),
				type: "select",
				icon: "building",
				placeholder: t("company"),
				priority: true,
				visible: availableCompanies.length > 1,
				options: availableCompanies.map(s => ({ value: s.id, text: s.name })),
				defaultValues: filterValues.companies,
				search: true
			}
		}
		return schemas;
	}

	const getIssues = async (filters) => {
		dispatch(setLoading(true));
		let data = await getTypesForCompany(filters.companies[0]);
		dispatch(setLoading(false));
		return data;
	}

	const createIssue = () => {
		history.push("/settings/issues/create") 
	}

	const getActions = () => {
		const actions = [
			{
				key: "create_issue_action_" + 1,
				icon: "user-plus",
				label: t("table.actions.create"),
				primary: true,
				onClick: () => createIssue()
			},
		]
		return actions;
	}

	return (
		<>
			<div className="leaderboard">
				{filterValues && <Filters
					title={t("title")}
					schemas={getSchemas()}
					values={filterValues}
					onChange={(newValues) => setFilterValues(newValues)}
					showInfo
					actions={getActions()}
					actionsIntercomTarget="leaderboard_actions"
				/>}
				{filterValues && filterValues.companies &&
					<div>
						<Table
							actions={getActions()}
							filterValues={filterValues}
							getData={(page, order, filters) => getIssues(filters)}
							pagination={false}
							columns={getColumns()}
							dataPath="data.data.type"
							elementKey="id"
							className="classname-prueba"
							//onElementClick={element => console.log(element)}
							elementActions={ (row, onchange) => getRowActions(row, onchange)}
						/>
					</div>}
			</div>
		</>
	);
}

IssuesConfigurationContainer.translations = { ...translations }

export default withEventTracking(translate(IssuesConfigurationContainer));
