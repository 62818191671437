import React, { useEffect } from 'react';
import { processOptIn } from '../../../api/sourceApi';
import  { useHistory } from "react-router-dom";
import _ from "lodash";
import {setLoading} from "../../../commons/components/application/miscActions";
import {useDispatch} from "react-redux";

const OptInCallback = () => {
	const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
		dispatch(setLoading(true));
        processOptIn(history.location.search).then( (result) => {
			dispatch(setLoading(false));
			// if (result.data.data.status === 'CONNECTED') {
			// 	history.push(`/integrations/accounts/${result.data.data.source.id}`);
			// }
			// else {
				history.push(`/integrations/finish/${_.at(result, "data.data.id")[0]}?source=${_.at(result, "data.data.source.id")[0]}`);
			// }
		})
    }, [])

	return (
		<div></div>
	);
};

export default OptInCallback;