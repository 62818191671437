import { getTabletopsForCompany } from '../../api/tabletopApi.js';
import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import Loading from '../../commons/components/utils/Loading.js';

const CompanyTabletopDownloader = () => {
    
    const params = new URLSearchParams(window.location.search);
    const companyId = params.get('companyId');
    const [loading, setLoading] = useState(true);

    const Download = () => {
        getTabletopsForCompany(companyId)
        .then(res => {
            setLoading(false);
            const blob = new Blob([res.data],  {type: 'application/zip'});
            saveAs(blob, `tabletop-manual-${companyId}`);
        })
        .catch(() => {
            console.log('Error retrieving file');
            setLoading(false);
        })
        .finally(() => {
            window.location.href = '/';
        })
    }

    useEffect(() => {
        Download();
    }, []);

    return <Loading fullScreen={true} loading={loading} />
}

export default CompanyTabletopDownloader;
