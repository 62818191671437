import React, { useEffect, useState } from 'react';
import NPSCards from './NPSCards';
import { FilterTypes } from 'botbit-ui-components';
import { timePeriodsIds as timePeriods, getLabel } from 'botbit-ui-components';
import { useSelector } from 'react-redux';
import { translate } from 'react-i18next';
// import { setLoading } from '../../../commons/components/application/miscActions';
import { getStoresNPS } from '../../../api/feedbackApi';
import _ from 'lodash';
import './NPSContainer.scss';
import { MDBIcon } from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import CollectionVisualizerContainer from '../../collectionVisualizer/CollectionVisualizerContainer';
import { firePageView } from "../../../commons/components/utils/trackers";
import { envConfig } from '../../../config/config';

const useMock = false

const mock = [
	{
		"id": 2891,
		"name": "Cerveza Patagonia - Jardín Cervecero Italia",
		"nps": 100,
		"sent": null,
		"replies": 2,
		"replyRate": null,
		"color": "#a0ff85"
	},
	{
		"id": 2905,
		"name": "Cerveza Patagonia - Jardín Cervecero Parque Patricios",
		"nps": 100,
		"sent": null,
		"replies": 1,
		"replyRate": null,
		"color": "#a0ff85"
	},
	{
		"id": 2892,
		"name": "Cerveza Patagonia - Jardín Cervecero Rotonda Fisherton",
		"nps": 100,
		"sent": null,
		"replies": 3,
		"replyRate": null,
		"color": "#a0ff85"
	},
	{
		"id": 2934,
		"name": "Cerveza Patagonia - Refugio Alto Rosario",
		"nps": 100,
		"sent": null,
		"replies": 2,
		"replyRate": null,
		"color": "#a0ff85"
	},
	{
		"id": 2917,
		"name": "Cerveza Patagonia - Refugio Casa Del Tango",
		"nps": 100,
		"sent": null,
		"replies": 4,
		"replyRate": null,
		"color": "#a0ff85"
	},
	{
		"id": 2913,
		"name": "Cerveza Patagonia - Refugio Comodoro",
		"nps": 100,
		"sent": null,
		"replies": 34,
		"replyRate": null,
		"color": "#a0ff85"
	},
	{
		"id": 2900,
		"name": "Cerveza Patagonia - Refugio La Florida",
		"nps": 100,
		"sent": null,
		"replies": 1,
		"replyRate": null,
		"color": "#a0ff85"
	},
	{
		"id": 2899,
		"name": "Cerveza Patagonia - Refugio El Calafate",
		"nps": 96,
		"sent": null,
		"replies": 28,
		"replyRate": null,
		"color": "#a1fb84"
	},
	{
		"id": 2931,
		"name": "Cerveza Patagonia - Refugio Las Quinchas",
		"nps": 95,
		"sent": null,
		"replies": 43,
		"replyRate": null,
		"color": "#a1fa84"
	},
	{
		"id": 2906,
		"name": "Cerveza Patagonia - Refugio Plaza de la Música",
		"nps": 95,
		"sent": null,
		"replies": 60,
		"replyRate": null,
		"color": "#a1fa84"
	},
	{
		"id": 2895,
		"name": "Cerveza Patagonia - Refugio Caballito",
		"nps": 94,
		"sent": null,
		"replies": 51,
		"replyRate": null,
		"color": "#a2f984"
	},
	{
		"id": 2919,
		"name": "Cerveza Patagonia - Refugio Iguazu",
		"nps": 94,
		"sent": null,
		"replies": 18,
		"replyRate": null,
		"color": "#a2f984"
	},
	{
		"id": 2903,
		"name": "Cerveza Patagonia - Refugio Mendoza Shopping",
		"nps": 91,
		"sent": null,
		"replies": 69,
		"replyRate": null,
		"color": "#a3f783"
	},
	{
		"id": 2916,
		"name": "Cerveza Patagonia - Refugio Lanús",
		"nps": 90,
		"sent": null,
		"replies": 30,
		"replyRate": null,
		"color": "#a3f683"
	},
	{
		"id": 2894,
		"name": "Cerveza Patagonia - Refugio Palmares",
		"nps": 90,
		"sent": null,
		"replies": 10,
		"replyRate": null,
		"color": "#a3f683"
	},
	{
		"id": 2918,
		"name": "Cerveza Patagonia - Jardin Cervecero Formosa",
		"nps": 89,
		"sent": null,
		"replies": 19,
		"replyRate": null,
		"color": "#a4f583"
	},
	{
		"id": 2923,
		"name": "Cerveza Patagonia - Refugio Rada Tilly",
		"nps": 89,
		"sent": null,
		"replies": 76,
		"replyRate": null,
		"color": "#a4f583"
	},
	{
		"id": 3026,
		"name": "Cerveza Patagonia - Refugio Palermo",
		"nps": 88,
		"sent": null,
		"replies": 75,
		"replyRate": null,
		"color": "#a4f483"
	},
	{
		"id": 3032,
		"name": "Cerveza Patagonia - Refugio Paraná",
		"nps": 87,
		"sent": null,
		"replies": 287,
		"replyRate": null,
		"color": "#a5f382"
	},
	{
		"id": 2909,
		"name": "Cerveza Patagonia - Refugio Santa Fe",
		"nps": 83,
		"sent": null,
		"replies": 301,
		"replyRate": null,
		"color": "#a6f082"
	},
	{
		"id": 2888,
		"name": "Cerveza Patagonia - Bar Córdoba",
		"nps": 81,
		"sent": null,
		"replies": 37,
		"replyRate": null,
		"color": "#a7ee81"
	},
	{
		"id": 2922,
		"name": "Cerveza Patagonia - Refugio Jujuy",
		"nps": 81,
		"sent": null,
		"replies": 72,
		"replyRate": null,
		"color": "#a7ee81"
	},
	{
		"id": 2924,
		"name": "Cerveza Patagonia - Refugio Mercado de las Rosas",
		"nps": 76,
		"sent": null,
		"replies": 52,
		"replyRate": null,
		"color": "#a9ea81"
	},
	{
		"id": 2928,
		"name": "Cerveza Patagonia - Refugio Bahía Blanca",
		"nps": 69,
		"sent": null,
		"replies": 116,
		"replyRate": null,
		"color": "#abe480"
	},
	{
		"id": 2887,
		"name": "Cerveceria Bariloche",
		"nps": 0,
		"sent": null,
		"replies": 2,
		"replyRate": null,
		"color": "#c6aa75"
	},
	{
		"id": 2896,
		"name": "Cerveza Patagonia - Jardin Cervecero Corrientes",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2926,
		"name": "Cerveza Patagonia - Jardin Cervecero Pilar",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2893,
		"name": "Cerveza Patagonia - Jardin Cervecero San Juan",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2889,
		"name": "Cerveza Patagonia - Jardín Cervecero 20 y 50",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2902,
		"name": "Cerveza Patagonia - Jardín Cervecero Maschwitz",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2929,
		"name": "Cerveza Patagonia - Refugio 50 y 10",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2914,
		"name": "Cerveza Patagonia - Refugio Aconquija",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2915,
		"name": "Cerveza Patagonia - Refugio Catamarca",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2912,
		"name": "Cerveza Patagonia - Refugio City Bell",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2897,
		"name": "Cerveza Patagonia - Refugio Distrito Arcos",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2898,
		"name": "Cerveza Patagonia - Refugio El Abasto",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2925,
		"name": "Cerveza Patagonia - Refugio Leloir",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2930,
		"name": "Cerveza Patagonia - Refugio Mar del Plata",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2904,
		"name": "Cerveza Patagonia - Refugio Monte Grande",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2927,
		"name": "Cerveza Patagonia - Refugio Rio Gallegos",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2907,
		"name": "Cerveza Patagonia - Refugio Riobamba y Santa Fé",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2908,
		"name": "Cerveza Patagonia - Refugio Salta",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2910,
		"name": "Cerveza Patagonia - Refugio Santiago Del Estero",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2921,
		"name": "Cerveza Patagonia - Refugio Tucumán Sheraton",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2962,
		"name": "Cerveza Patagonia - Refugio WTC",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	},
	{
		"id": 2911,
		"name": "Cerveza Patagonia - Refugio Yerba Buena",
		"nps": null,
		"sent": null,
		"replies": null,
		"replyRate": null,
		"color": null
	}
]

const NPSContainer = ({ t }) => {
	const availableCompanies = useSelector(
		(state) => state.application.availableCompanies
	);
	const selectedCompany = useSelector(
		(state) => state.application.selectedCompany
	);

	//	const [summaryLoading, setSummaryLoading] = useState(false);
	const [npsSummary, setNPSSummary] = useState(null);
	const [npsStores, setNPSStores] = useState(null);
	const [sourceIsBotbit, setSourceIsBotbit] = useState(null);

	// const dispatch = useDispatch();

	useEffect(() => {
		// const fetchData = async () => {
		// 	dispatch(setLoading(true));
		// 	const response = await getStoresNPSSummary(
		// 		selectedCompany.id,
		// 		timePeriods.LAST_30_DAYS,
		// 		null
		// 	);
		// 	setNPSSummary(response.data.data);
		// 	dispatch(setLoading(false));
		// };
		// fetchData();
		firePageView();
	}, []);

	const renderNoDataTooltip = (reason) => {
		return (
			<div className="d-inline">
				{t('nps.noData')}
				<MDBIcon
					data-tip
					data-for={reason}
					className="ml-2 text-info"
					icon="exclamation-circle"
				/>
				<ReactTooltip
					id={reason}
					effect="solid"
					overridePosition={(e) => {
						let position = { ...e };
						if (window.innerWidth < 991) {
							position = {
								left: 15,
								top: e.top,
							};
						}
						return position;
					}}
				>
					<span>{t(`nps.${reason}`)}</span>
				</ReactTooltip>
			</div>
		);
	};

	const isNumber = (number) => number || number === 0

	const columns = [
		{
			dataField: 'name',
			className: 'd-none d-md-block',
			title: t('nps.store'),
			size: 6,
		},
		{
			dataField: 'nps',
			className: 'd-none d-md-block',
			title: t('nps.nps'),
			size: 3,
			formatter: (cellContent, row) =>
				isNumber(cellContent) ? cellContent : renderNoDataTooltip('noDataDisclaimer'),
		},
		// {
		// 	dataField: 'sent',
		// 	className: 'd-none d-md-block',
		// 	title: t('nps.surveysSent'),
		// 	size: 3,
		// 	formatter: (cellContent, row) => {
		// 		return (
		// 			cellContent ||
		// 			(sourceIsBotbit ? (
		// 				<div>{t('nps.noData')}</div>
		// 			) : (
		// 				renderNoDataTooltip('sourceDisclaimer')
		// 			))
		// 		);
		// 	},
		// },
		{
			dataField: 'replies',
			className: 'd-none d-md-block',
			title: t('nps.surveysAnswered'),
			size: 3,
			formatter: (cellContent, row) =>
				cellContent ? (
					<div>
						{cellContent}{' '}
						{row.sent && `(${Math.round((cellContent * 100) / row.sent)}%)`}
					</div>
				) : sourceIsBotbit ? (
					<div>{t('nps.noData')}</div>
				) : (
					renderNoDataTooltip('sourceDisclaimer')
				),
		},
		{
			dataField: 'name',
			isHeader: true,
			title: '',
			className: 'align-left text-truncate d-md-none row-title',
			size: 12,
		},
		{
			dataField: 'name',
			title: '',
			className: 'd-md-none',
			size: 12,
			formatter: (cellContent, row) => (
				<div>
					<div className="m-0">
						{t('nps.nps')}: {isNumber(row.nps) ? row.nps : renderNoDataTooltip('noDataDisclaimer')}
					</div>
					<div className="m-0">
						{t('nps.surveysSent')}:{' '}
						{row.sent ||
							(sourceIsBotbit ? (
								<div>{t('nps.noData')}</div>
							) : (
								renderNoDataTooltip('sourceDisclaimer')
							))}
					</div>
					<div className="m-0">
						{t('nps.surveysAnswered')}:{' '}
						{row.replies ||
							(sourceIsBotbit ? (
								<div>{t('nps.noData')}</div>
							) : (
								renderNoDataTooltip('sourceDisclaimer')
							))}{' '}
						{sourceIsBotbit &&
							row.sent &&
							`(${Math.round((row.replies * 100) / row.sent)}%)`}
					</div>
				</div>
			),
		},
	];

	const filtersSchema = [
		{
			id: 'timePeriod',
			label: t('panels.adminActivity.filters.period'),
			type: 'timeperiod',
			icon: 'calendar-alt',
			className: 'col-12',
			placeholder: t('panels.adminActivity.filters.custom'),
			priority: true,
			visible: true,
			options: [
				timePeriods.LAST_30_DAYS,
				timePeriods.LAST_2_MONTHS,
				timePeriods.LAST_3_MONTHS,
				timePeriods.LAST_12_MONTHS,
				timePeriods.LAST_6_MONTHS,
				timePeriods.CUSTOM
			],
			value: {
				value: timePeriods.LAST_30_DAYS,
				text: getLabel(timePeriods.LAST_30_DAYS),
			},
		},
		{
			id: 'sources',
			label: t('nps.source'),
			type: 'multiselect',
			search: true,
			className: 'col-12',
			placeholder: t('nps.source'),
			priority: true,
			visible: true,
			values: [
				{ checked: true, value: 1, text: envConfig.companyName },
				{ checked: false, value: 2, text: 'Google' },
				{ checked: false, value: 3, text: 'Facebook' },
			],
		},
	];

	const onFiltersApply = async (oldFilters, newFilters) => {
		if (!_.isEqual(oldFilters, newFilters)) {
			// setSummaryLoading(true);
			let checkedValue = (v) => {
				let filter = newFilters.find(f => f.id === v)
				return FilterTypes[filter.type].getCheckedValues(filter)
			}
			// let companyId = checkedValue("company")[0]
			// let timePeriod = checkedValue("timePeriod")[0]
			let newSource = checkedValue("sources")
			setSourceIsBotbit(newSource.length === 1 && newSource[0] === 1)
			// const res = await getStoresNPSSummary(companyId, timePeriod, newSource.toString())
			// setNPSSummary(res.data.data)
			// setSummaryLoading(false)
		}
	};

	const exportCsv = () => {
		console.log('export', npsStores)

		let csvContent = `${t('nps.store')},${t('nps.nps')},${t('nps.surveysSent')},${t('nps.surveysAnswered')}\n`
		if (npsStores) {
			const noData = t('nps.noData')
			npsStores.forEach(store => {
				csvContent = csvContent + `${store.name},${isNumber(store.nps) ? store.nps : noData},${store.sent || noData},${store.replies || noData}\n`
			})
		}
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');

		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', 'nps');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	return (
		<div className="mt-4 nps-container">
			{(
				<CollectionVisualizerContainer
					headerActions={[{
						key: 1,
						icon: 'file-export',
						label: t('nps.export.button'),
						id: "export-csv",
						primary: false,
						onClick: () => exportCsv()
					}]}
					scrollTopButton={true}
					onFiltersApply={onFiltersApply}
					defaultFilters={filtersSchema}
					columns={columns}
					dataPath={'data'}
					alwaysFloatingFilter={true}
					showFiltersInfo={true}
					header={
						<>
							{npsSummary && <NPSCards ratings={npsSummary} loading={false} />}
							<h2 className="font-weight-bold m-0">{t('nps.NPSAllStores')}</h2>
						</>
					}
					title={
						<div className="d-flex align-items-baseline my-3">
							{t('nps.NPSInBotbit')}
							<h4>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://help.botbit.io/es/articles/3181928-que-es-nps"
									className="ml-3 text-info"
								>
									{t('nps.whatIsNPS')}
								</a>
							</h4>
						</div>
					}
					pagination={false}
					getData={(page, order, filters) => {
						const promise = getStoresNPS(filters, page, order)
						promise.then((rsp) => {
							const stores = useMock ? mock : rsp.data.data
							let minStore, maxStore;
							stores.forEach((element) => {
								if (isNumber(element.nps)) {
									if (!minStore || minStore.nps > element.nps) {
										minStore = { ...element }
									}
									if (!maxStore || maxStore.nps < element.nps) {
										maxStore = { ...element }
									}
								}
							})
							const npsSummary = [{
								type: 'BEST',
								...maxStore
							}, {
								type: 'WORST',
								...minStore
							}]
							setNPSSummary(npsSummary)
							setNPSStores(useMock ? mock : rsp.data.data)
						})
						return useMock ? new Promise((resolve) => {
							resolve({ data: 
								{ data: mock } 
							});
						}) : promise;
						// return promise
					}}
					companies={availableCompanies}
					selectedCompany={selectedCompany}
				/>
			)}
			<br />
			<br />
		</div>
	);
};

export default translate('common')(NPSContainer);
