import React from 'react';
import { Modal } from 'react-bootstrap';
import TaskForm from './TaskContainer.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class EditTaskModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			openModal: false	
		}
	}

	closeModal = () => {
		this.props.updateUser();
		this.setState({
			openModal: false
		});
	}

	render(){
		return (
			<>
				<span className='mx-2 text-primary cursor-pointer' onClick={()=>{this.setState({openModal:true})}}>
						  Editar <FontAwesomeIcon icon="edit"/>
					  </span>

				<Modal
					show={this.state.openModal}
					onHide={this.closeModal}
					className='p0'
					contentClassName='overflow-auto'>
					<Modal.Header closeButton>
						<Modal.Title>
							Editar tarea
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<TaskForm taskId={this.props.taskId} onSubmit={this.closeModal}/>
					</Modal.Body>
				</Modal>
			</>
		)

	}
}

