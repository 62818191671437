import React from 'react';
import "./DocsContainer.scss";
import Docs from './DocFiles/index.js';
import _ from "lodash";

const DocsContainer = ({ style, className, width, height, noBorder, source }) => {
  let Doc;
  switch (source.name) {
    case "shopify":
      Doc = Docs.ShopifyDoc;
      break;
    case "gmail":
      Doc = Docs.GmailDoc;
      break;
    case "rdstation":
      Doc = Docs.RDStationDoc;
      break;
    default:
      break;
  }
  return (Doc !== undefined &&
    <>
      <div className={`documentation-container ${className ? className : ""} ${noBorder && "no-border"}`} style={{ width, height, display: "block", ...style }}>
        <Doc url={_.at(source, "configuration.documentation.url")[0]} />
      </div>
    </>
  );
}

export default DocsContainer;