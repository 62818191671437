import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames';
import Menu from '../utils/menu/Menu.js'

export default class IBox extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			collapsed: props.collapsed ? props.collapsed : false,
			closed: false
		}
	}

	render = () => {
		const { options } =  this.props;
		return (
			!this.state.closed &&
				<div className={classNames('ibox p-0 py-2 m-0', {'border-bottom': this.state.collapsed})}>
					<div className="ibox-title cursor-pointer" onClick={() => this.setState({ collapsed: !this.state.collapsed })}>
						{
							this.props.icon &&
								<FontAwesomeIcon className='mr-1' icon={this.props.icon} />

						}
						<h5 className='w-100'>{this.props.title}</h5>
						{
							(this.props.showIcons === undefined || this.props.showIcons === true) && 
								<div className="ibox-tools">
									<span className="collapse-link" >
										<FontAwesomeIcon icon={this.state.collapsed ? 'chevron-down' : 'chevron-up'} />
									</span>
									{
										!this.props.hideClose && 
											<span className="close-link" onClick={() => this.setState({ closed: true })}>
												<FontAwesomeIcon icon="times" />
											</span>
									}
								</div>
						}
						{
							(options && options.length>0) &&
								<div className="ibox-tools">
										<Menu buttonClasses='pt-0 pr-2'>
											{options}
										</Menu>
									</div>
						}
					</div>
					{
						!this.state.collapsed &&
							<div className='ibox-content p-sm-0 p-md-0 p-lg-4' >
								{this.props.children}
							</div>
					}
				</div >
		)
	};
}
