import React from 'react';
import './loding.scss';
import { Image } from 'botbit-ui-components';
import { envConfig } from '../../../config/config';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { MDBProgress } from 'mdbreact';

const Loading = (props) => {
  const { isMedium } = useWindowDimensions();
  return (
    props.loading ? (
      <div
        onScroll={(e) => {
          e.stopPropagation();
        }}
        className={!props.fullScreen ? 'p-4 m-auto d-table' : 'loading-overlay'}
      >
        <div
          className={!props.fullScreen ? 'd-table m-auto' : 'loading-container'}
        >
          {/* <HashLoader
						sizeUnit={"px"}
						size={props.size? props.size : 100}
						color={!props.fullScreen? '#999' : '#fff'}
						loading={true}
					/> */}
          <Image
            style={{ width: props.small && isMedium ? '200px' : '400px', ...props.style }}
            src={`${envConfig.staticImgUrl}${props.white ? "loader_ray_blanco.gif" : "loader_ray.gif"}`}
            // src={`${envConfig.staticImgUrl}botbit-loader-blue.gif`}
            data-testid="loading-img"
          ></Image>
        </div>

        { props.loading.progress &&
          <div className='mx-5'>
            {props.loading.progress.message &&
              <div class="alert alert-dark" role="alert" data-mdb-color="dark">
                {props.loading.progress.message}
              </div>
            }
            <MDBProgress 
              value={props.loading.progress.now}
              min={0}
              max={props.loading.progress.max}
              
            >
              {props.loading.progress.now}/{props.loading.progress.max}
            </MDBProgress>
          </div>
        }
      </div>
    ) : null
  );
};

export default Loading;
