import React from 'react';
import { unignoreUser } from '../../api/userApi';
import { connect } from 'react-redux';
import Confirmation from './Confirmation'
import { setLoading } from '../../commons/components/application/miscActions'

class ConfirmationContainer extends React.Component {

	componentDidMount() {
		const params = new URLSearchParams(this.props.queryString);
		const actionType = params.get('actionType');
		if (actionType === 'unignoreUser') {
			var userId = params.get('userId');
			var storeId = params.get('storeId');
			this.props.setLoading(true);
			unignoreUser(userId, storeId, false).then(res => {
				this.setState(
					{
						actionType: actionType,
						serviceOk: true
					}
				);
				this.props.setLoading(false);
			});
		}
	}

	render = () => (
        <React.Fragment>
            {(this.state && this.state.actionType) && (<Confirmation {...this.state} token={this.props.token}/>)}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
	return {
		token: state.login.token
	}
}
export default connect(mapStateToProps, { setLoading })(ConfirmationContainer);