
import React from 'react';
import { Modal } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImport as fileImport } from '@fortawesome/free-solid-svg-icons';
import ClientTags from './ClientTags.js';
import DropzoneComponent from 'react-dropzone-component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


class ImportUsersModal extends React.Component {

	constructor(props) {
		super(props);

		this.djsConfig = {
			addRemoveLinks: true,
			acceptedFiles: ".csv",
			autoProcessQueue: false,
			uploadMultiple: true,
			parallelUploads: 1,
			maxFiles: 1,

			dictDefaultMessage: ""
		};

		this.componentConfig = {
			iconFiletypes: ['.csv'],
			showFiletypeIcon: false,
			postUrl: '/users/imiiiiport',
		};

		this.dropzone = null;

		this.state = { open: false, tags: [] };
	}

	openModal = () => {
		this.setState({ open: true, tags: [] });
	}

	setTags = (tags) => {
		this.setState({tags:tags});		
	}

	closeModal = () => {
		this.setState({ open: false });

	}

	processQueue = () => {
		if (this.dropzone.getQueuedFiles().length === 0) {
			MySwal.fire({
				title: this.props.t("panels.clients.importAlertTitle"),
				text: this.props.t("panels.clients.importAlertSelectFile"),
				type: 'info',
				showCancelButton: false,
				confirmButtonText: 'Ok',
				confirmButtonColor:'#1ab394'
			});
			return;
		}
		this.dropzone.processQueue();
		this.closeModal();

	}

	render() {
		const config = this.componentConfig;

		const parameters = {
			storeId: this.props.store ? this.props.store.id : undefined,
			loggedUser: this.props.admin ? this.props.admin.username : undefined,
			tags: this.state.tags.map(t=>(t.id)).toString(),
			representativeId : ''
		}

		this.djsConfig.params = parameters;


		const eventHandlers = {
			init: dz => this.dropzone = dz,
			successmultiple: () => {
				MySwal.fire({
					title: this.props.t("panels.clients.importAlertTitle"),
					text: this.props.t("panels.clients.importAlertInProcess"),
					type: 'info',
					showCancelButton: false,
					confirmButtonText: 'Ok',
					confirmButtonColor:'#1ab394'
				});

			},
			error: () => {
				MySwal.fire({
					title: this.props.t("panels.clients.importAlertTitle"),
					text: this.props.t("panels.clients.importAlertError"),
					type: 'error',
					showCancelButton: false,
					confirmButtonText: 'Ok',
					confirmButtonColor:'#1ab394'
				});

			}

		}

		return (
			<React.Fragment>
				<div className='btn btn-link text-primary w-100 text-left'
					onClick={this.openModal}>
					<FontAwesomeIcon className='m-auto' icon={fileImport} />
					<span className='m-auto pl-3'>{this.props.t("panels.clients.import")}</span>
				</div>
				<Modal show={this.state.open === true} onHide={this.closeModal}  >
					<Modal.Header closeButton>
						<Modal.Title>{this.props.t("panels.clients.importClients")}</Modal.Title>
					</Modal.Header>
					<Modal.Body className='p-4'>
						<form action="">
							<DropzoneComponent config={config} eventHandlers={eventHandlers} djsConfig={this.djsConfig}>
								<h2 className='my-auto'>{this.props.t("panels.clients.dragAndDropText")}</h2>
							</DropzoneComponent>
							<br/>
							<p>
								{this.props.t("panels.clients.dragAndDropText")}
							</p>
							<ClientTags placeholder={this.props.t("panels.clients.tagInput")} setTags={this.setTags} tags={this.state.tags}/>
						</form>
					</Modal.Body>
					<Modal.Footer className='p-4'>
						<button className='btn btn-sm btn-default w-100'>
							<FontAwesomeIcon className='m-auto' icon='download' />
							<a href='/misc/users_sample.csv' target='_blank' className='m-auto pl-3 text-white'>{this.props.t("panels.clients.sample")}</a>
						</button>
						<button className='btn btn-sm btn-primary w-100'
							onClick={this.processQueue}>
							<FontAwesomeIcon className='m-auto' icon={fileImport} />
							<span className='m-auto pl-3'>{this.props.t("panels.clients.import")}</span>
						</button>

					</Modal.Footer>
				</Modal>
			</React.Fragment>
		)
	}
}

export default (translate('common')(ImportUsersModal));
