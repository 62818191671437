import React, { useState, useEffect } from 'react';
import { Table, Filters, Actions } from 'botbit-ui-components';
import { envConfig } from "../../config/config";
import DateTime from "../../commons/components/time/DateTime";
import './AccountList.scss';
import { MDBBadge, MDBIcon } from 'mdbreact';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { translations } from "../../config/translations/integrations/AccountList";
import translate from "../../config/translations/translate";
import { disconnect, getAccounts } from '../../api/IntegrationsApi';
import { ActionRenderMode } from "../../commons/components/utils/actions/ActionsContainer";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import _ from "lodash";
import { connect } from "./IntegrationsHelper";
import { useHistory } from "react-router";
import { getSources } from "../../api/sourceApi";
import { setLoading } from "../../commons/components/application/miscActions";

const logosPrefix = '/static/img/';

const AccountList = ({ t }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const swal = withReactContent(Swal);
    const { sourceId } = useParams();
    const selectedCompany = useSelector(state => state.application.selectedCompany);
    const [values, setValues] = useState(
        {
            status: [],
            name: [""]
        }
    )
    const [action, setAction] = useState(null);
    const [selectedSource, setSelectedSource] = useState(null);
    const mobile = window.innerWidth < 992;
    const column_container = !mobile ? ' column-container' : '';

    useEffect(() => {
        if (!sourceId) return;
        const _getSources = async () => {
            const sources = await getSources(selectedCompany.id);
            setSelectedSource(sources.data.data.items.find(s => s.id === parseInt(sourceId)));
        }
        _getSources();
    }, [sourceId]);

    useEffect(() => {
        if (!selectedSource) return;
        const iconUrlPrefix = '/static/img/brand_logos/';
        const classNameButton = `${selectedSource.name}-header-button`;
        const classNameIcon = `${selectedSource.name}-header-icon`;
        if (selectedSource.configuration && !selectedSource.configuration.hideConnectButton) {
            const action = [
                {
                    key: 1,
                    icon: {
                        url: `${iconUrlPrefix}${selectedSource.name}.png`,
                        className: classNameIcon
                    },
                    iconFab: true,
                    className: classNameButton,
                    label: (selectedSource && `${t('connectWith')} ${selectedSource.displayName}`) || '',
                    primary: true,
                    onClick: () => {
                        const fields = _.at(selectedSource, "configuration.fields")[0];
                        if (fields && fields.length > 0) {
                            history.push(`/integrations/connect/${selectedSource.id}`);
                        }
                        else {
                            connect(selectedSource, selectedCompany, {});
                        }
                    }
                }
            ];
            setAction(action);
        }
    }, [selectedSource])

    //console.log("selectedSource", selectedSource);

    const desktopExpandRow = (active, element) => {
        return (
            <div style={{ padding: '1em' }}>
                <b style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        element.status === 'CONNECTED' && element.matchingType==="ACCOUNT" &&
                        <>
                            <span>{t("connectedWith")} { element.store ? element.store.name : ""}</span>
                            <span>{t("connectedWhen")}: <DateTime timestamp={element.connectedTimestamp} /></span>
                        </>
                    }
                    {    element.status === 'CONNECTED' && element.matchingType==="LOCATION" && element.locations && element.locations.map( l=>
                            <>
                                { l.store && l.enabled && <div>
                                    <div className="location mt-2">{l.name}</div>
                                    <span>{ l.store ? t("connectedWith")+" "+ l.store.name : t("notConnected")}</span>
                                        <div>{t("connectedWhen")}: <DateTime timestamp={l.createdAt} /></div>
                                        <div>{t("lastSync")}: <DateTime timestamp={l.lastSyncAt} /></div>
                                    </div>
                                }
                            </>
                        )
                    }
                </b>
            </div>
        )
    }
    const desktopColumns = [
        {
            dataField: "source",
            title: t("source"),
            size: 20,
            className: column_container,
            formatter: (cellcontent, row) => {
                return <img src={`${logosPrefix}${row.source.logo}`} width={'35%'} alt={row.name} />
            }

        },
        {
            dataField: "name",
            title: t("name"),
            size: 20,
            className: column_container,
            formatter: (cellcontent, row) => {
                return <span><b>{row.name}</b></span>
            }
        },
        {
            dataField: "administrator",
            title: t("connectedBy"),
            size: 20,
            className: column_container,
            formatter: (cellcontent, row) =>
                <div className="col-align-center">
                    <div style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        backgroundImage: `url(${envConfig.staticImgUrl}avatars/${row.administrator && row.administrator.avatar ? row.administrator.avatar : 'mini-noavatar.jpg'})`,
                        backgroundSize: 'cover',
                        marginBottom: '5px',
                        float: 'left'
                    }} />
                    <span className="text-dark text-truncate" style={{
                        fontSize: '12px',
                        lineHeight: '19px',
                        fontWeight: '600',
                        maxWidth: '100%'
                    }}>{(row.administrator && row.administrator.fullName) || 'N/A'}</span>
                </div>

        },
        {
            dataField: "status",
            title: t("status"),
            size: 20,
            className: column_container,
            formatter: (cellcontent, row) => {
                return <MDBBadge style={{ padding: '2%' }}>{t(row.status)}</MDBBadge>
            }
        },
        {
            dataField: "showRow",
            title: "",
            size: 20,
            className: column_container,
            formatter: (cellContent, row, updateRow) => {
                let config = [
                    {
                        key: 1,
                        icon: "eye",
                        label: t("details"),
                        color: "btn-primary",
                        onClick: () => { document.querySelector(`#element${row.id} div:first-child`).click() }
                    }
                ];
                if (row.status === 'CONNECTED') {
                    config.push(
                        {
                            key: 2,
                            icon: "deaf",
                            label: t("disconnect"),
                            color: "btn-primary",
                            onClick: () => {
                                swal.fire({
                                    title: t("disconnectTitle"),
                                    text: t("disconnectBody", { email: row.name }),
                                    type: 'warning',
                                    showCancelButton: true,
                                    cancelButtonText: t("cancel"),
                                    confirmButtonText: t("confirm"),
                                    confirmButtonColor: '#1ab394',
                                })
                                    .then(res => {
                                        if (res.value) {
                                            dispatch(setLoading(true));
                                            disconnect(row.id, selectedCompany.id)
                                                .then(() => {
                                                    row.status = 'DISCONNECTED'
                                                    updateRow(row, "change");
                                                    dispatch(setLoading(false));
                                                });
                                        }
                                    });
                            }
                        }
                    )
                }
                return (
                    <>
                        <Actions actions={config} renderMode={ActionRenderMode.DEFAULT} />
                    </>
                )
            }
        }
    ];

    const mobileColumn = [
        {
            dataField: "",
            title: "",
            size: 100,
            formatter: (cellcontent, row, updateRow) => {
                let config = [
                    {
                        key: 1,
                        icon: "eye",
                        label: t("details"),
                        color: "btn-primary",
                        onClick: () => { document.querySelector(`#element${row.id} div:first-child`).click() }
                    }
                ];
                if (row.status === 'CONNECTED') {
                    config.push(
                        {
                            key: 2,
                            icon: "deaf",
                            label: t("disconnect"),
                            color: "btn-primary",
                            onClick: () => {
                                swal.fire({
                                    title: t("disconnectTitle"),
                                    text: t("disconnectBody", { email: row.name }),
                                    type: 'warning',
                                    showCancelButton: true,
                                    cancelButtonText: t("cancel"),
                                    confirmButtonText: t("confirm"),
                                    confirmButtonColor: '#1ab394',
                                })
                                    .then(res => {
                                        if (res.value) {
                                            disconnect(row.id, selectedCompany.id)
                                                .then(() => {
                                                    row.status = 'DISCONNECTED'
                                                    updateRow(row, "change");
                                                });
                                        }
                                    });
                            }
                        }
                    )
                }

                return (
                    <div>
                        <b>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>
                                    <div>
                                        <img src={`${logosPrefix}${row.source.logo}`} width={'35%'} alt={row.name} style={{ marginRight: '45%' }} /><Actions actions={config} renderMode={ActionRenderMode.DEFAULT} />
                                    </div>
                                    <div style={{ marginTop: '0' }}>
                                        <span>{row.name}</span>
                                    </div>
                                </div>
                            </div>
                        </b>
                    </div>);
            }
        }
    ]

    return (
        <div id="integrations-list">
            <MDBIcon icon="arrow-left" id="backIcon" fas="true" onClick={() => { history.push(`/integrations`) }} style={{ marginTop: '25px' }} />
            <Filters schemas={{
                status: {
                    id: 'status',
                    label: 'Status', //TODO traduccion
                    type: 'select',
                    placeholder: 'Todos', //TODO traduccion
                    priority: true,
                    visible: false,
                    options: [{
                        value: 'CONNECTED',
                        text: 'Connected'
                    },
                    {
                        value: 'OPTIN_OK', //TODO TRADUCCIONES TODO
                        text: 'Pending'
                    }],
                    defaultValues: []
                },
                name: {
                    id: 'name',
                    label: 'Name',
                    type: 'input',
                    placeholder: 'Name',
                    priority: true,
                    visible: false
                }
            }}
                values={values}
                onChange={(newValues) => setValues(newValues)}
                showInfo
                actions={action || null}
                title={(selectedSource && `${t("title")} ${selectedSource.displayName}`) || ''}
            >

            </Filters>
            <Table
                filterValues={values}
                getData={(page, order, filters) => getAccounts(sourceId, selectedCompany.id)}
                columns={mobile ? mobileColumn : desktopColumns}
                pagination={false}
                expandElement={(active, element) => desktopExpandRow(active, element)}>
            </Table>
        </div>
    );
}

AccountList.translations = {
    ...translations
}
export default translate(AccountList);