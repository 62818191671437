import React, { useState } from "react";
import { MDBContainer } from "mdbreact";
import SourceCard from "./SourceCard/SourceCard";
import SourceThumbnail from "./SourceThumbnail/SourceThumbnail";
import CollectionVisualizerContainer from "../collectionVisualizer/CollectionVisualizerContainer";
import { getSources } from "../../api/sourceApi";
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/integrations/IntegrationsContainer";
import { sourcesTranslations } from "../../config/translations/integrations/Sources";
import { useBreakpoint } from "../../commons/utils/useWindowDimensions";
import SourceCardModal from "./SourceCardModal/SourceCardModal"
import "./IntegrationsContainer.css";
import _ from "lodash"
import { useHistory } from "react-router";
import DocsModal from "./DocsModal/DocsModal";
import { useSelector } from "react-redux";
import {connect} from "./IntegrationsHelper";

const IntegrationsContainer = ({ t }) => {

    const [sourceModalIsOpen, setSourceModalIsOpen] = useState(false);
    const [sourceModalConfig, setSourceModalConfig] = useState(null);

    const [docsModalIsOpen, setDocsModalIsOpen] = useState(false);
    const [selectedSource, setSelectedSource] = useState(0)

    const history = useHistory();

    const selectedCompany = useSelector(state => state.application.selectedCompany)



    const isMobile = useBreakpoint(499);

    // if( selectedCompany.goal !== 'organizeConversations' && (selectedCompany.id !== 690 && selectedCompany.id !== 499 && selectedCompany.id !== 364 && selectedCompany.id !== 707 && selectedCompany.id !== 710 && selectedCompany.id !== 24 && selectedCompany.id !== 704) && (envName === "aws-prod" || envName === "prod")) {
    //     return null;
    // }

    const handleAdd = (source) => {
        const showDocumentation = _.at(source, "configuration.documentation.show")[0];
        if (showDocumentation) {
            setSelectedSource(source);
            if (source && source.name) {
                setDocsModalIsOpen(true);
            }
        } else {
            handleConnect(source);
        }
        closeSourceCardModal();
    }

    const handleConnect = async (source) => {
        const fields = _.at(source, "configuration.fields")[0];
        if (fields && fields.length > 0) {
            history.push(`/integrations/connect/${source.id}`);
        } else {
            connect(source, selectedCompany, {})
        }
    }

    const openSourceCardModal = (source) => {
        setSourceModalConfig({
            source,
            onAdd: () => handleAdd(source),
        })
        setSourceModalIsOpen(true);
    }

    const closeSourceCardModal = () => {
        setSourceModalIsOpen(false);
        setSourceModalConfig(null);
    }

    return (
        <MDBContainer id="integrations">
            <CollectionVisualizerContainer
                getData={() => getSources(selectedCompany.id)}
                gridFormatter={(source) => {
                    return (
                        !isMobile ?
                            <SourceCard onAdd={() => handleAdd(source)} source={source}></SourceCard> :
                            <SourceThumbnail openCardModal={() => openSourceCardModal(source)} source={source} />
                    )
                }}
                gridRowSize={{ md: '4', size: '6' }}
                mode="grid"
                pagination={false}
                title={t("title")}
                subTitle={t("subtitle")}
            />
            {sourceModalIsOpen && isMobile && <SourceCardModal isOpen={sourceModalIsOpen} toggle={() => closeSourceCardModal()} onAdd={sourceModalConfig.onAdd} source={sourceModalConfig.source} />}
            <DocsModal isOpen={docsModalIsOpen} toggle={() => setDocsModalIsOpen(false)} onAdd={() => handleConnect(selectedSource)} source={selectedSource} />
        </MDBContainer>
    )
}

IntegrationsContainer.translations = {
    ...translations,
    ...sourcesTranslations
}

export default translate(IntegrationsContainer);