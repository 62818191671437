import React from 'react'
import './campaigns.css'

export class ActivateCampaignCard extends React.Component {
    render(){
        return(
            <div onClick={() => this.props.onClick && this.props.onClick({ categoryName: this.props.categoryName, id: this.props.id })} className={"activateCampaignCardContainer p-2 " + (this.props.className ? this.props.className : '')}>
                <div className="w-100" style={{maxHeight: "270px"}}>
                    {/* <img className="activateCampaignCardImage"
                    src={this.props.imageSrc}/> */}
                    <div className="activateCampaignCardImage" style={{backgroundImage: `url(${this.props.imageSrc})`}}/>
                    <div>
                        <h4 className="activateCampaignCardTitle">
                        {this.props.title}
                        </h4>
                        <p className="activateCampaignCardP">
                        {this.props.text}
                        </p>  
                    </div>    
                </div>
            </div>    
        )
    }
}