import { envConfig } from "../../config"

export const translations = {
    accumulatedVisits: {
        es: 'Visitas acumuladas',
        en: "Accumulated visits"
    },
    averageScore: {
        es: 'Calificación promedio',
        en: "Average score"
    },
    daysWithoutComing: {
        es: "Días sin venir",
        en: "Days not showing up"
    },
    receivedCampaigns: {
        es: "Campañas recibidas",
        en: "Received campaigns"
    },
    openCampaigns: {
        es: "Campañas abiertas",
        en: "Open Campaigns"
    },
    onlinePurchases: {
        es: "Compras online",
        en: "Online purchases"
    },
    stimulatedPurchases: {
        es: `Estimuladas por ${envConfig.companyName}`,
        en: `Stimulated by ${envConfig.companyName}`
    }
}
