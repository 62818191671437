import React from "react";
import { MDBCard, MDBBadge, MDBCardTitle, MDBCardText, MDBCardBody, MDBBtn } from "mdbreact";
import "./SourceCard.css";
import { sourcesTranslations } from "../../../config/translations/integrations/Sources";
import { translations } from "../../../config/translations/integrations/SourceCard";
import translate from "../../../config/translations/translate";
import { useHistory } from "react-router-dom";
import {someStore, someStoreConnected} from "../IntegrationsHelper";
import {Image } from "botbit-ui-components"

const SourceCard = ({ source, t, onAdd }) => {
  const history = useHistory();
  const { logoAbsolute, name } = source;
  const _someStore = someStore(source);
  const _someStoreConnected = someStoreConnected(source);

  const hideConnectButton = source.configuration && source.configuration.hideConnectButton;

  return <MDBCard className="source-card">
    <div className="source-image-container">
      {/* <MDBCardImage className="img-fluid source-image" src={logoAbsolute} /> */}
      <Image scaleFixedSize={{width: "210px", height: "140px"}} className="source-image" src={logoAbsolute}></Image>
    </div>
    <MDBCardBody>
      <MDBCardTitle
        className="d-flex align-items-center">
        {`${t(`sources.${name}.name`)} - ${t(`sources.${name}.category`)}`}
        {(_someStoreConnected) && <MDBBadge
          className="ml-3"
          color={("green")}>
          {t(`sources.status.CONNECTED`)}
        </MDBBadge>}
      </MDBCardTitle>
      <MDBCardText>
        {t(`sources.${name}.description`)}
      </MDBCardText>
      <div className="btn-container">
        {_someStore && <MDBBtn style={{ textTransform: "none" }} color="primary" outline onClick={() => history.push(`/integrations/accounts/${source.id}`)}>{t("seeConnections")}</MDBBtn>}
        {!_someStore && !hideConnectButton && <MDBBtn className={`${source.name}-button text-capitalize`} onClick={onAdd}><img alt="sourceImg" className={`source-img ${source.name}-icon`} src={`/static/img/brand_logos/${source.name}.png`}></img>{`${t("connectWith")} ${source.displayName}`}</MDBBtn>}
      </div>
    </MDBCardBody>
  </MDBCard >
}

SourceCard.translations = {
  ...sourcesTranslations,
  ...translations
}

export default translate(SourceCard);