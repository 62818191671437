import React from 'react'
import { MDBInput, MDBSelect, MDBContainer } from 'mdbreact'
import { Field } from 'formik'
import './CampaignBenefit.css'
import classNames from 'classnames';
import { getBenefit } from '../MinimalEditor/Helper'
export default class CampaignBenefit extends React.Component {
    constructor(props){
		super(props)
        this.state={
			showProduct: (props.data.benefitTypeId !== 2 && props.data.benefitTypeId !== undefined && props.data.benefitTypeId !== null),
			showDiscount: (props.data.benefitTypeId === 2),
			renderInput: true
		}
	}
	
	componentDidUpdate = (oldProps) => {
		if(this.props.data.benefitTypeId !== oldProps.data.benefitTypeId) {
			this.setState({
				showProduct: (this.props.data.benefitTypeId !== 2 && this.props.data.benefitTypeId !== undefined && this.props.data.benefitTypeId !== null),
				showDiscount: (this.props.data.benefitTypeId === 2)
			})
		}
	}

	reRenderInput = () => {
		setTimeout(() =>
		this.setState({
			renderInput: false
		}, () => {
			this.setState({
				renderInput: true
			})
		})
		, 0);
	}

    getBenefitTypeControl() {
		return <Field
			name="campaign.benefitTypeId"
			render={(props) => {
				return (
					<MDBSelect
					className="benefitSelector"
						label={ this.props.showLabels ? this.props.t('panels.campaigns.card.form.benefit') : "" }
						options={[
							{ text: this.props.t('panels.campaigns.card.form.none'), value: null, checked: !props.field.value },
							{ text: this.props.t('panels.campaigns.card.form.benefitType.product'), value: "1", checked: props.field.value===1 }, 
							{ text: this.props.t('panels.campaigns.card.form.benefitType.discount'), value: "2", checked: props.field.value===2 }, 
							{ text: this.props.t('panels.campaigns.card.form.benefitType.2x1'), value: "3", checked: props.field.value===3 }]}
						selected={ this.props.t('panels.campaigns.card.form.benefit') }
						getValue={ (values) => { 
							let valuesChanged = (!values[0] ? props.field.value !== values[0] : props.field.value !== parseInt(values[0]))
							if(valuesChanged) {
								props.form.setFieldValue("campaign.customProductName", null)
								this.reRenderInput();
								if(values[0] === null && props.field.value !== values[0]){
									props.form.setFieldValue(props.field.name, values[0])
									if(!props.form.values.campaign.isPredefined) {
										setTimeout(() => props.form.setFieldValue('campaign.countAsWT', null), 0)
									} 

									setTimeout(() => props.form.setFieldValue('campaign.discount', null), 0)
									setTimeout(() => props.form.setFieldValue('campaign.productCategory', null), 0)
									this.setState({
										...this.state,
										showDiscount: false,
										showProduct: false
									})
								} else if (props.field.value !== parseInt(values[0]) && values[0] !== null) {
										props.form.setFieldValue(props.field.name, parseInt(values[0]));
										let newCountAsWT = null;
										if(parseInt(values[0]) === 1 || parseInt(values[0]) === 3){
											this.setState({
												...this.state,
												showProduct: true,
												showDiscount: false
											})
											const {productCategories} = this.props;
											const productCategoryDefault = (productCategories && productCategories.length > 0) ? productCategories[0] : null;
											if(productCategoryDefault) {
												newCountAsWT = true;
											}
											setTimeout(() => props.form.setFieldValue('campaign.discount', null), 0)
											props.form.setFieldValue("campaign.productCategory", productCategoryDefault);
										} else if(parseInt(values[0]) === 2){
											this.setState({
											...this.state,
											showDiscount: true,
											showProduct: false
										})
										setTimeout(() => props.form.setFieldValue('campaign.productCategory', null), 0)
									}
									if(!props.form.values.campaign.isPredefined) {
										setTimeout(() => props.form.setFieldValue('campaign.countAsWT', newCountAsWT), 0)
									}
								}
							}
						}}
					/>
				);
			}}
		/>	
    }
    getBenefitProductDetailControl = () => {
		return <>
			<Field
				name="campaign.productCategory.id"
				render={(props) => {
					let options = [];
					if (this.props.productCategories) {
						this.props.productCategories.forEach((element, index) => {
							options.push( { 
								text: this.props.t("productTypes." + element.name), 
								value: element.id+"", 
								checked: props.field.value===element.id }
							)
						})
					}
					options.push({ 
						text: this.props.t('panels.campaigns.card.form.other'), 
						value: null, 
						checked: !props.field.value 
					});

					return <MDBSelect
								className="productSelector"
								label={ this.props.showLabels ? this.props.t('panels.campaigns.card.form.product') : "" }
								options={ options }
								selected={ this.props.t('panels.campaigns.card.form.product') }
								getValue={ (values) => { 
									let stringValue = parseInt(values[0]) ? parseInt(values[0]) : null;
									if (props.field.value !== stringValue && props.form.values.campaign.benefitTypeId !== null) {
										//si hay beneficio, entonces cuento WT
										if(!props.form.values.campaign.isPredefined) {
											props.form.setFieldValue('campaign.countAsWT', !!stringValue);
											if(stringValue) {
												props.form.setFieldValue("campaign.customProductName", null);
											}
										}
										props.form.setFieldValue(props.field.name, stringValue); 
									}
								}}
							/>
				}}
			/>
			<Field
				name="campaign.customProductName"
				render={(props) => {
					let {errors} = props.form;
					let customProductNameHasError = errors && errors.campaign && errors.campaign.customProductName;
					return (!props.form.values.campaign.productCategory || !props.form.values.campaign.productCategory.id) && 
						<div className={classNames("customProductNameInput", {
														"hasError": customProductNameHasError
													})}>
							{this.state.renderInput && <MDBInput 
								type="text"
								label={this.props.t('panels.campaigns.card.form.productName')}
								value={props.field.value || ""}
								onChange={(e) => {
									let newValue = e.target.value;
									const isAlphabetical = /^[a-zA-Z0-9 ]+$/
									if(newValue !== "" && !isAlphabetical.test(newValue)){
										//apply old value again
										newValue = props.field.value;
									}
									
									props.form.setFieldValue(props.field.name, newValue);
									props.form.setFieldValue("campaign.countAsWT", (newValue && newValue !== ''));
								}}
								/>}
							{customProductNameHasError && <label className="errorLabel text-danger">{customProductNameHasError}</label>}
						</div>
					
				}}
				/>
		</>
    }
    getBenefitDiscountDetailControl = () => {
		return <Field
			name="campaign.discount"
			render={(props) => {
				return (
					<div className="productSelector">
						<MDBInput
							name={props.field.name}
							label={ this.props.t('panels.campaigns.card.form.discount') }
							type="number"
							id='discount'
							className={props.form.errors.campaign && props.form.errors.campaign.discount ? 'hasError' : ''}
							value={props.field.value ? props.field.value + "" : ""}
							getValue={ value => { 
								let intValue = (value) ? parseInt(value) : null;
								if (props.field.value !== intValue) {
									if(!props.form.values.campaign.isPredefined) {
										props.form.setFieldValue('campaign.countAsWT', !!intValue);
									}
									props.form.setFieldValue(props.field.name, intValue); 
								}
							}}
						/>
						{props.form.errors.campaign && props.form.errors.campaign.discount &&
						<div className="text-danger position-absolute">{props.form.errors.campaign.discount}</div>}
					</div>
				);
			}}
		/>
	}
	
    getBenefitMessageControl(campaign) {
		return (
				<span>{getBenefit(campaign, this.props.t, this.props.productCategories)}</span>
		);
    }
	
    render(){
        return(
			<div className="benefitContainer">
				{this.props.withLabel && <span className="inputLabel">{this.props.t("panels.campaigns.minimalEditor.benefit")}</span>}
				<MDBContainer className={(this.props.className ? this.props.className : "") + ' campaignBenefitContainer p-0'}>
					{this.props.isEditing && 
						<div className={window.innerWidth > 576 ? "mt-3" : ''}>
							{ this.getBenefitTypeControl(this.props.data) }
							{ this.state.showProduct && this.getBenefitProductDetailControl() }
							{ this.state.showDiscount && this.getBenefitDiscountDetailControl() }
						</div>
					}
					{!this.props.isEditing &&
						<div>
							{this.getBenefitMessageControl(this.props.data)} 
						</div>
					}
				</MDBContainer>
			</div>
        )
    }
}