import React, { useEffect, useState } from 'react';
import RRFunnel from './RRFunnel.js'
import { getLabel, getSuffixLabel, calculateFrom, calculateTo } from "botbit-ui-components"
import { useHistory } from 'react-router';
import { useCompanyAvailableStores } from '../../commons/utils/useCompanyAvalaibleStores.js';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Feedback/Leaderboard";
import Moment from 'react-moment';


const RRFunnelView = ({ t }) => {
    const [adminInfo, setAdminInfo] = useState();

    const history = useHistory();
    const companyAvailableStores = useCompanyAvailableStores();

    const params = new URLSearchParams(history.location.search);
    const periodParam = params.get("period");
    const dateFromParam = params.get("dateFrom");
    const dateToParam = params.get("dateTo");
    const storesParam = params.get("stores");
    const orderParam = params.get("order");
    const adminParam = params.get("admin");
    const adminNameParam = params.get("adminName");
    const adminLastNameParam = params.get("adminLastName");
    const avgScore = params.get("avgScore")
    const schedulerParam = adminParam === "scheduler";

    useEffect(() => {
        let adminInfo = {
            name: "",
            lastName: ""
        };
        if (adminNameParam && adminLastNameParam) {
            adminInfo = {
                name: adminNameParam,
                lastName: adminLastNameParam
            }
        } else if (schedulerParam) {
            adminInfo.name = t("schedulerName");
        }
        setAdminInfo(adminInfo)
    }, [])

    const ceilHours = date => {
        date.setHours(date.getHours() + 1);
        date.setMinutes(0, 0, 0)

        return date;
    }

    const getPeriodFromParam = () => {
        return periodParam
            ? periodParam !== "CUSTOM"
                ? { suffixText: getSuffixLabel(periodParam), text: "· 📅 " + getLabel(periodParam), from: calculateFrom(periodParam), to: ceilHours(calculateTo(periodParam)) }
                : { suffixText: getSuffixLabel(periodParam), text: <>· 📅 <Moment format="L" locale="es">{dateFromParam}</Moment> - <Moment format="L" locale="es">{dateToParam}</Moment></>, from: dateFromParam, to: dateToParam }
            : { text: "" }
    }

    const getFilters = () => {
        const timePeriod = getPeriodFromParam();

        let stores;
        if (storesParam) stores = storesParam;
        else {
            stores = companyAvailableStores.map(s => (s.id));
        }
        let order;
        if (!orderParam) {
            order = "TOTAL_REVIEWS_REQUESTED"
        }

        return {
            timePeriod,
            stores,
            order
        }
    }

    const filters = getFilters();
    return (
        <div className="funnel-view-container">
            {adminInfo && <h2 style={{ color: "#000000", fontWeight: "bold" }} >{`${t("detailedRR", { name: `${adminInfo.name} ${adminInfo.lastName}` })}${avgScore ? ` -  ${avgScore}⭐` : ""}`} {getPeriodFromParam().text}</h2>}
            <RRFunnel
                filters={filters}
                adminId={adminParam}
            />
        </div>
    );
}

RRFunnelView.translations = { ...translations }

export default translate(RRFunnelView);