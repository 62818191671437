import React from 'react'
import {Chart} from 'react-google-charts'
import './BestDaysCard.css'
import { MDBIcon } from 'mdbreact'

export const BestDaysCard = ({t, className, first, day}) => {
    let colors = ['#49C6C5', '#D780FB', '#7BBCFC', '#CCCCCC']
    let slices = {}
    let restPercentage = 100;
    const getChartData = () => {
        let lastIndex = 0;
        let chartData = [['Order', 'Rate']];
        day.bestHours.forEach((hour, index) => {
            if(hour.openRate > 0){
                chartData = [...chartData, [(index+1).toString(), hour.openRate]]
            } 
            else {
                colors[index] = '#CCCCCC';
            }
            lastIndex = index+1;
            restPercentage -= hour.openRate
        })
        chartData = [...chartData, [lastIndex+1, restPercentage]]
        colors[lastIndex] = '#CCCCCC'  
        colors.forEach((color, index) => {
            slices = {...slices, [`${index}`]: {color}}
        })     
        return chartData;
    }

    return(
        <div className={"daysCard d-inline-block" + (className ? ' ' + className : '') + (first ? ' bestDay' : '')}>
            <div className="dateInfo">
                <span>{t('panels.campaigns.analytics.card.days.' + day.day)}, {day.bestHours[0].hour} hs</span>
                {first &&
                    <span><MDBIcon icon="trophy"/>{t('panels.campaigns.analytics.card.mostOpeningsDay')}</span>
                }
            </div>
            <div className="chartInfo">
                <Chart
                    width={'136px'}
                    height={'136px'}
                    chartType="PieChart"
                    data={getChartData()}
                    options={{
                        pieHole: 0.73,
                        pieSliceText: 'none',
                        legend: 'none',
                        tooltip: { trigger: 'none' },
                        chartArea:{ width:'100%', height:'100%' },
                        pieSliceBorderColor: '',
                        enableInteractivity: false,
                        slices: slices
                    }}
                />
                <div>
                    <span>{day.totalOpened}</span>
                    <span>{t('panels.campaigns.analytics.card.totalOpened')}</span>
                </div>
            </div>
            <div className="timeDetail">
                {day.bestHours.filter(({openRate}) => (openRate > 0)).map(({hour, openRate}, index) => {
                    return <span>{hour} hs<strong style={{color: colors[index]}}> • {openRate}%</strong></span>
                })}
                {restPercentage > 0 && 
                    <span className="restOfDaySpan">{t('panels.campaigns.analytics.card.restOfTheDay')}<strong> • {restPercentage}%</strong></span>
                }
            </div>
        </div>
    )
}