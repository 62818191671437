export const translations = {
    birthday: {
        es: "Cumpleaños",
        en: "Birthday"
    },
    phone: {
        es: "Teléfono",
        en: "Phone"
    },
    seenFirstTime: {
        es: "Visto por primera vez",
        en: "Seen for the first time"
    },
    seenLastTime: {
        es: "Visto por última vez",
        en: "Seen for the last time"
    }
}
