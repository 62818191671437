import { envConfig } from "../../config";

export const translations = {
  title: {
    es: `Conecta ${envConfig.companyName} con {{sourceName}}`,
    en: `Connect ${envConfig.companyName} with {{sourceName}}`,
  },
  connect: {
    es: "Conectar Ahora",
    en: "Connect now"
  }
}