import React from 'react';

export default class Number extends React.Component {
    constructor(props) {
        super(props);
        this.mobile = window.innerWidth < 992;
    }

    render = () => {
        var n = this.props.number.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('');
        n = n.startsWith('.') ? n.substring(1) : n;
        return (
            <span style={{ display: 'inline', fontSize: (this.mobile && '0.7em') || '1em', ...this.props.style }}>
                {n}
            </span>
        )
    };
}