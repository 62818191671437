import React, { Component } from "react";
import { MDBBtnFixed } from "mdbreact";
import { Action, ActionRenderMode } from './ActionsContainer.js';
import PropTypes from 'prop-types';
import "./BotBitActions.css";

class BotBitFixedMenu extends Component {

	state = {
		buttonStyle: {
			transform: "scaleY(0.0) scaleX(0.0) translateY(40px) translateX(0)",
			opacity: "0"
		}
	}


	onHover = (e) => {
		if(e.target.tagName!=='DIV' && e.target.tagName!=='I') return;
		this.setState({
			buttonStyle: {
				transform: "scaleY(1) scaleX(1) translateY(0) translateX(0)",
				opacity: "1"
			}
		});
	}

	onMouseLeave = () => {
		this.setState({
			buttonStyle: {
				transform: "scaleY(0.0) scaleX(0.0) translateY(40px) translateX(0)",
				opacity: "0"
			}
		});
	}

	render() {
		let buttonStyle = this.props.actions.length > 1 ? this.state.buttonStyle : {
				transform: "scaleY(1) scaleX(1) translateY(0) translateX(0)",
				opacity: "1"
			}
		;
		let actions = this.props.actions.map(
			action=>{
				return (<Action
					{...action}
					renderMode={ActionRenderMode.FIXED}
					buttonStyle={buttonStyle}
				/>
				)
			}
		);
		
		if (actions.length === 1){
			return (
				<div className="fixed-action-btn active"style={{ listStyle:'none', bottom: "10px", right: "10px" }}>
						{actions[0]}
				</div>
			);
		}
		
		return (
			<section
								className="botbit-actions"
							>
			<MDBBtnFixed
					onMouseOver={this.onHover}
					onMouseLeave={this.onMouseLeave}
					floating
					block
					topSection=""
					size="md"
					color="blue"
					icon="ellipsis-v"
					style={{ bottom: "10px", right: "10px" }}
				>

				{[...actions]}	
			</MDBBtnFixed>
		</section>
		);
	}
}

const actionsConfigShape = PropTypes.shape({
						icon:PropTypes.string.isRequired,
						label:PropTypes.string.isRequired,
						primary:PropTypes.bool,
						tooltip:PropTypes.string,
						onClick: PropTypes.func.isRequired,
						color: PropTypes.string,
				});


BotBitFixedMenu.propTypes = {
	actions: PropTypes.arrayOf(actionsConfigShape)
}

export default BotBitFixedMenu;
