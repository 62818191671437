import React, { useEffect } from "react";

export const Error = ({ errors, isSubmitting }) => {
  const [submitted, setSubmitted] = React.useState(false);
  useEffect(() => {
    if (isSubmitting) setSubmitted(true);
  }, [isSubmitting]);
  if (!submitted) return null;

  return (
    <div className="text-danger">
      {errors}
    </div>
  );
};
