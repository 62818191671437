import React, { useEffect, useState } from 'react';
import { Table, Filters } from "botbit-ui-components"
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Issues/IssuesDashboardContainer";

import "./IssuesDashboardContainer.scss"
import withEventTracking from '../../config/withEventTracking';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../commons/components/application/miscActions';

import { getIssuesForStore, getIssuesForStoreCsv, getTypesForCompany } from '../../api/issuesApi';
import { getAllStoresAdministratorsWithOperation } from '../../api/administratorApi';

import TimeAgo from '../../commons/components/utils/TimeAgo';
import useWindowDimensions from '../../commons/utils/useWindowDimensions';

import _ from "lodash";

const IssuesDashboardContainer = ({ t }) => {
    const availableStores = useSelector(state => state.application.availableStores);
    //const availableCompanies = useSelector(state => state.application.availableCompanies)
    const selectedCompany = useSelector(state => state.application.selectedCompany);
    const [filterValues, setFilterValues] = useState({ stores: [], type: [], state: [], assignation: [] })
    const [filtersFormatted, setFiltersFormatted] = useState(null);

    const [types, setTypes] = useState();
    const [states, setStates] = useState();
    const [admins, setAdmins] = useState([]);
    const history = useHistory()
    const dispatch = useDispatch();

    const { isMedium } = useWindowDimensions();

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        const storesParam = params.get("stores")
        const companiesParam = params.get("companies");

        //companies: [selectedCompany.id], 
        let newFilterValues = { stores: [], type: [], assignation: [], state: [] }

        if (companiesParam) {
            newFilterValues = {
                ...newFilterValues,
                companies: [
                    ...companiesParam.split(",").map(elem => parseInt(elem))
                ]
            }
        }

        if (storesParam) {
            newFilterValues = {
                ...newFilterValues,
                stores: [
                    ...storesParam.split(",").map(elem => parseInt(elem))
                ]
            }
        }

        if (JSON.stringify(filterValues) !== JSON.stringify(newFilterValues)) {
            setFilterValues({ ...newFilterValues })
        }
    }, [])

    useEffect(() => {
        getTypesForCompany(selectedCompany.id)
            .then(res => {
                setTypes(res.data.data.type);
                const statesPlainList = [];
                res.data.data.type.forEach(t => statesPlainList.push(...t.states))
                setStates(statesPlainList);
            });
    }, []);

    useEffect(() => {
        getAllStoresAdministratorsWithOperation(availableStores.filter(s => s.companyId === selectedCompany.id).map(s => s.id), "CONVERSATION__REPLY")
            .then(res => {
                setAdmins(res.data.data);
            });
    }, []);

    useEffect(() => {
        if (!filterValues) return;
        //console.log("filerValues", filterValues)
        const formatted = { ...filterValues, stores: getStoresFromFilters() }
        setFiltersFormatted( _.cloneDeep(formatted))
    }, [JSON.stringify(filterValues)])

    const getColumns = () => {
        if (!isMedium) {
            return (
                [
                    {
                        dataField: "user",
                        title: t("userName"),
                        formatter: (field) => <span>{field && field.name}</span>,
                        size: 17,
                    },
                    {
                        dataField: "user",
                        title: t("userLastName"),
                        formatter: (field) => <span>{field && field.lastName}</span>,
                        size: 17,
                    },
                    {
                        dataField: "issueType",
                        title: t("issueType"),
                        formatter: (field) => <span>{field && field.name}</span>,
                        size: 17
                    },
                    {
                        dataField: "currentIssueState",
                        title: t("currentIssueState"),
                        formatter: (field) => <span>{field && field.name}</span>,
                        size: 17
                    },
                    {
                        dataField: "timestamp",
                        title: t("timestamp"),
                        formatter: (field) => <TimeAgo date={field}></TimeAgo>,
                        size: 17
                    },
                    {
                        dataField: "currentConversationId",
                        title: "",
                        formatter: (field) => field && <a rel="noopener noreferrer" target='_blank' className='btn btn-sm btn-primary' href={`/chat/conversations/${field}`}>{t("contact")}</a>,
                        size: 15
                    }
                ]
            )
        } else {
            return (
                [
                    {
                        dataField: "user",
                        formatter: (field) => <span className='w-100'><b>{t("userName")}</b> {field && field.name} {field && field.lastName}</span>,
                        className: 'd-md-none',
                        isHeader: true
                    },
                    {
                        dataField: "timestamp",
                        formatter: (field) => <span><b>{t("timestamp")}</b> <TimeAgo date={field}></TimeAgo> </span>,
                        isHeader: true
                    },
                    {
                        dataField: "issueType",
                        formatter: (field) => <span><b>{t("issueType")}</b> {field && field.name}</span>,
                        isHeader: true
                    },
                    {
                        dataField: "currentIssueState",
                        formatter: (field) => field && <span><b>{t("currentIssueState")}</b> {field.name}</span>,
                        isHeader: true
                    },
                    {
                        dataField: "currentConversationId",
                        title: "",
                        formatter: (field) => field && <a rel="noopener noreferrer" target='_blank' className='btn btn-sm btn-primary mobile-contact-button' href={`/chat/conversations/${field}`}>{t("contact")}</a>
                    }
                ]
            )
        }
    }

    const getSchemas = () => {
        const schemas = {
            // companies: {
            //     id: "companies",
            //     label: t("company"),
            //     type: "multiselect",
            //     icon: "building",
            //     placeholder: t("allCompanies"),
            //     priority: true,
            //     visible: availableCompanies.length > 1,
            //     options: availableCompanies.map(s => ({ value: s.id, text: s.name })),
            //     defaultValues: filterValues.companies,
            //     search: true
            // },
            stores: {
                id: "stores",
                label: t("store"),
                type: "multiselect",
                icon: "store-alt",
                placeholder: t("allStores"),
                priority: true,
                visible: true,
                //options: availableStores.map(s => ({ value: s.id, text: s.name, dependencyId: s.companyId })),
                options: availableStores.filter(s => s.companyId === selectedCompany.id).map(s => ({ value: s.id, text: s.name, dependencyId: s.companyId })),
                search: true,
                defaultValues: [],
                //dependsOn: "companies"
            },
            type: {
                id: "type",
                label: t("issueType"),
                type: "select",
                icon: "building",
                priority: false,
                visible: true,
                options: types && types.map(s => ({ value: s.id, text: s.name })),
                defaultValues: [],
                search: false
            },
            state: {
                id: "state",
                label: t("currentIssueState"),
                type: "multiselect",
                icon: "store-alt",
                priority: false,
                visible: true,
                options: states && states.map(s => ({ value: s.id, text: s.name, dependencyId: s.issueTypeId })),
                search: true,
                defaultValues: [],
                dependsOn: "type"
            },
            assignation: {
                id: "assignation",
                label: t("assign"),
                type: "multiselect",
                icon: "user-cog",
                priority: false,
                visible: true,
                options: admins && admins.map(a => ({ value: a.id, text: a.fullName })),
                defaultValues: [],
                search: true
            },
        }

        return schemas;
    }

    const actions = [
        {
            key: "conversation_dashboard_action_export",
            icon: "file-export",
            label: t("exportCSV"),
            primary: false,
            onClick: () => exportCSV()
        }
    ]

    const exportCSV = () => {
        // alert("Oh no")
        const { stores, type, state, assignation } = filtersFormatted;
        //trackEvent('CONVERSATION_DASHBOARD_EXPORT_REQUESTED', { type: 'EXPORT' }, true, [trackers.BACKEND])
        getIssuesForStoreCsv(stores, type, state, assignation)
            .then(fileBlob => {
                const fileName = `Conversation Follow Up - ${Date.now()}`;
                try {
                    var blob = new Blob([fileBlob.data], { type: "text/csv" });
                    //downloading the file depends on the browser
                    //IE handles it differently than chrome/webkit
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        var elem = window.document.createElement('a');
                        elem.href = window.URL.createObjectURL(blob);
                        elem.download = fileName + ".csv";
                        document.body.appendChild(elem);
                        elem.click();
                        document.body.removeChild(elem);
                    }
                } catch (exc) {
                    console.log("Save Blob method failed with the following exception.");
                    console.log(exc);
                }
            })
    }



    const getStoresFromFilters = () => {
        if (!filterValues.stores || filterValues.stores.length === 0 || filterValues.stores[0] === "allStores") {
            //return availableStores.filter(s => filterValues.companies.includes(s.companyId)).map(s => s.id);
            return availableStores.filter(s => s.companyId === selectedCompany.id).map(s => s.id);
        }
        return filterValues.stores
    }

    const formatData = async (stores, type, state, assignation, page) => {
        dispatch(setLoading(true));
        let response = await getIssuesForStore(stores, type, state, assignation, page);
        dispatch(setLoading(false));
        return {
            data: {
                items: response.data.data,
                pageSize: 10
            }
        }
    }

    const width = isMedium ? " w-100" : " w-75"

    return (
        <div className="issue-dashboard-container">
            {filterValues && <Filters
                title={t("title")}
                schemas={getSchemas()}
                values={filterValues}
                onChange={(newValues) => setFilterValues(newValues)}
                showInfo
                actions={actions}
                actionsIntercomTarget="chat_dashboard_actions"
            />}
            {filtersFormatted && filtersFormatted.stores &&
                <div className="text-center">
                    <div className={"issues-container row" + width}>
                        <div className="issues-table text-left mb-5">
                            <Table
                                filterValues={filtersFormatted}
                                getData={(page, order, filters) => formatData(filters.stores, filters.type, filters.state, filters.assignation, page)}
                                columns={getColumns()}
                                dataPath="data.items"
                                elementKey="id"
                                emptyStateIntercomTarget="chat_dashboard_empty"
                                onElementClick={() => { }}
                                pagination='button'
                                className='text-center'
                            // export={() => alert("Oh no!")}
                            // exportName={`TasksExport-${Date.now()}`}
                            // formats={[{ type: 'text/csv', format: 'csv' }]}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

IssuesDashboardContainer.translations = { ...translations }

export default withEventTracking(translate(IssuesDashboardContainer));
