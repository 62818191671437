import React from "react";
import DateTime from "../../commons/components/time/DateTime";
import { useSelector } from "react-redux";
import { MDBBadge } from "mdbreact";

const SocialLocation = ({ t, location }) => {
    const isMOTU = useSelector(state => state.login.admin.objectAccess === 'ALL')

    return (
        <div>
            <div>
                <label>{
                    (location.url && location.url.length && <a rel="noopener noreferrer" href={location.url} target="_blank" style={{ fontWeight: 'bold', color: '#337ab7' }}><u>{location.socialLocationDisplayName}</u></a>)
                    || ((!location.url || !location.url.length) && <span>{location.socialLocationDisplayName}</span>)}
                    </label>
                <MDBBadge className="socialaccounts-active-badge" style={{ marginLeft: 10 }} color={location.enabled ? "green" : "red"}>{location.enabled ? t("socialAccounts.active") : t("socialAccounts.inactive")}</MDBBadge>
            </div>
            {(location.storeId || isMOTU) && <>
                <div>
                    <div><label>{t('socialAccounts.createdAt')}</label>: <DateTime timestamp={location.createdAt} /></div>
                    <div><label>{t('socialAccounts.lastSyncAt')}</label>: <DateTime timestamp={location.lastSyncAt} /></div>
                    {location.storeDisplayName && location.storeDisplayName.length && <div><label>{t('socialAccounts.matchedStore')}</label>: <span>{location.storeDisplayName}</span></div>}
                    {/* <div><label>{t('socialAccounts.matchedBy')}</label>: <MDBIcon icon={(location.matchedManually) ? 'user' : 'robot'}></MDBIcon></div> */}
                </div>
            </>}
        </div>
    )
}

export default SocialLocation