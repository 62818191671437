import React, { useRef, useState } from "react";
import {
    MDBCard, MDBCardTitle, MDBRow, MDBCol, MDBChip, MDBIcon, MDBBtn, MDBInput, MDBModal, MDBModalBody,
    MDBModalHeader, MDBCardBody, MDBTestimonial, MDBAvatar, MDBSpinner, MDBCardFooter, MDBSelect, MDBAlert, MDBCardHeader
} from "mdbreact";
import { Modal, ModalHeader, ModalBody } from 'botbit-ui-components'
import Score from './Score'
import './review.css'
import Moment from "react-moment";
import moment from 'moment'
import { SocialSource } from "../../commons/enums/SocialSource";
import { getSiteName } from "./SocialSourceIcon";
import { getTemplateApplied } from '../../api/feedbackApi';
import SocialSourceIcon from './SocialSourceIcon';
import ReplyTemplateEditModal from './ReplyTemplateEditModal';
import UserDetailsContainer from '../../commons/components/users/details/UserDetailsContainer.js';
import { useCompanyAvailableStores } from "../../commons/utils/useCompanyAvalaibleStores";
// import { getUserAvailableStores } from "../../commons/utils/getUserAvailableStores";
import { useHistory } from "react-router-dom";
import withEventTracking from '../../config/withEventTracking';

import {
    TwitterShareButton, TwitterIcon,
    FacebookShareButton, FacebookIcon,
    WhatsappShareButton, WhatsappIcon
} from 'react-share';
import { getUserActivity } from "../../api/storeApi";
import { translations } from "../../config/translations/Feedback/Review";
import translate from "../../config/translations/translate";

const Review = ({ t, mode, review, onReply, onRowChanged, getReview, onReport, replyTemplates, onSaveReplyTemplate,
    admin, reply, redirectToReviews, redirectToReplyFalse, selectedCompanyId, isActive, answersGlobalStatistics, trackEvent }) => {

    const replyingWithTemplate = useRef(false);
    const [replying, setReplying] = useState((mode === 'inline') ? false : reply);
    const [replyValue, setReplyValue] = useState(review.replyComment);
    const [showUserDetailModal, setShowUserDetailModal] = useState(false);
    const [userProfilePhotoSrc, setUserProfilePhotoSrc] = useState(null);
    const [fatherReview, setFatherReview] = useState(undefined);
    const [showFatherDetailModal, setShowFatherDetailModal] = useState(false);
    const [fatherReviewLoading, setFatherReviewLoading] = useState(false);
    const [fatherAndSonTimeDifference, setFatherAndSonTimeDifference] = useState(false);
    const [reporting, setReporting] = useState(false);
    const [reportValue, setReportValue] = useState(undefined);
    const [showReplyTemplateModal, setShowReplyTemplateModal] = useState(false);
    const [replyTemplate] = useState({ name: '', comment: '', companyId: selectedCompanyId, category: 'FEEDBACK' });
    const [showUserDetails, setShowUserDetails] = useState(false);
    const availableStores = useCompanyAvailableStores();
    const [userStores, setUserStores] = useState();
    // const companyStores = useCompanyAvailableStores();

    const history = useHistory();


    const showUserDetail = async (review) => {
        if (review.userId) {
            if (userStores) setUserStores(undefined);
            const userAvailableStores = await getUserAvailableStores(review.userId);
            const formattedStores = userAvailableStores.map(store => ({ ...store, id: store.storeId }));
            setUserStores(formattedStores);
            setShowUserDetails(true)
        } else {
            setUserProfilePhotoSrc(review.reviewerProfilePhotoUrl)
            setShowUserDetailModal(true)
        }
    }

    const getUserAvailableStores = async (userId) => {
        const companyStoresIds = availableStores.map(store => store.id);
        const res = await getUserActivity(userId, companyStoresIds);
        const stores = res.data.data.userInformation.storeRegistrations;
        const storesFiltered = stores.filter(store => companyStoresIds.includes(store.storeId));
        return storesFiltered;
    }

    const showFatherDetail = (review) => {
        setShowFatherDetailModal(true);
        if (!fatherReview) {
            setFatherReviewLoading(true)
            getReview(review.generatedBy).then((response) => {
                const _fatherReview = response.data.data
                const end = moment(review.date)
                setFatherAndSonTimeDifference(moment.duration(end.diff(_fatherReview.date)))
                setFatherReview(_fatherReview)
            }).finally(() => {
                setFatherReviewLoading(false)
            });
        }
    }

    const doReply = () => {
        onReply(replyValue).then(() => {
            review.replyComment = replyValue
            review.replyTime = new Date()
            review.replierFullName = admin.name + ' ' + admin.lastName
            review.autoReplied = false
            if (onRowChanged) onRowChanged(review)
            setReplying(false)
            unselectAllReplyTemplates()
            // Trackeo el evento
            // TODO Update a Intercom solo hacerlo si no lo tiene hecho --> https://trello.com/c/RKYdZvTT/2-guardar-la-lista-de-tareas-que-ya-hizo-y-que-est%C3%A9n-disponibles-en-el-objeto-admin-de-front
            // Ya esta hecho en backend
            // trackEvent('review_replied',
            //     {
            //         store: review.storeName,
            //         score: review.score,
            //         reviewerDisplayName: review.reviewerDisplayName,
            //         comment: review.comment,
            //         replyComment: review.replyComment,
            //         usedTemplate: replyingWithTemplate.current
            //     }, true)
            if (mode === 'standalone' && redirectToReplyFalse) redirectToReplyFalse()
        });
    }

    const cancelReply = () => {
        setReplying(false)
        unselectAllReplyTemplates()
    }

    const unselectAllReplyTemplates = () => {
        if (replyTemplates) {
            replyTemplates.forEach(t => t.checked = false)
        }
    }

    const doReport = () => {
        onReport(reportValue).then(() => {
            review.userId = null;
            review.generatedBy = null;
            review.matchedByName = false;
            review.matchedByTimestamp = false;
            if (onRowChanged) onRowChanged(review);
            setReporting(false);
            setShowUserDetailModal(false);
            setShowFatherDetailModal(false);
            setShowUserDetails(false);
        })
    }

    const getDiffDescription = (duration) => {
        if (duration.asSeconds() > 120)
            return `${Math.round(duration.asMinutes())} ${t("minutes")}`
        else
            return `${duration.asSeconds()} ${t("seconds")}`
    }

    const selectReplyTemplate = (value) => {
        if (value && value.length > 0) {
            getTemplateApplied(value, review.id).then(response => setReplyValue(response.data.data.comment))
            replyingWithTemplate.current = true
        }
    }

    const openReplyTemplateModal = () => {
        setShowReplyTemplateModal(true);
    }

    const replyImplemented = (socialSourceId) => socialSourceId === SocialSource.GOOGLE
        || socialSourceId === SocialSource.BOTBIT
        || socialSourceId === SocialSource.FACEBOOK

    const getReplyWarning = (socialSourceId) => {
        if (socialSourceId === SocialSource.BOTBIT) {
            return t("answerToUserMail")
        }
        const siteName = getSiteName(socialSourceId)
        return `${t("answerWillPublish")} ${siteName}.`
    }

    const getSocialText = (review) => {
        if (review.comment) return review.reviewerDisplayName + t("commented") + review.comment
        if (review.score === 1 || review.score === 2)
            return review.reviewerDisplayName + t("recommendNot") + review.storeName
        if (review.score === 3)
            return t("recommendFrom.first") + review.reviewerDisplayName + t("recommendFrom.second")
        if (review.score === 4 || review.score === 5)
            return review.reviewerDisplayName + t("recommend") + review.storeName
        return ''
    }

    const getSocialUrl = (review) => {
        const storeWebsite = review.storeWebsite
        return (storeWebsite) ? storeWebsite : 'https://botbit.io'
    }

    const editReply = (review) => {
        setReplyValue(review.replyComment)
        setReplying(true)
    }

    const getReplierFullName = (review) => {
        return (review.replierFullName) ? review.replierFullName : t("admin")
    }

    const showReviewHistory = (review) => {
        const url = `/reviewHistory/?identityId=${review.id}&storeId=${review.storeId}&type=review`;
        history.push(url);
    }

	const renderCustomAnswer = (answer, answersGlobalStatistics) => {
		const stats = answersGlobalStatistics && answersGlobalStatistics.find(stat => stat.questionId === answer.npsQuestion.id)
		let statsText = ''
		if (stats) {
			if (stats.questionType === 'SCORE') {
				const pct = Math.round(stats.totalFiveStarsAnswers * 100 / stats.totalAnswers)
				statsText = ` (${pct}% ${t('scoreStatsSuffix')})`
			}
			if (stats.questionType === 'OPTIONS') {
				if (stats.options && answer.answeredNPSQuestionOption) {
					const optionStats = stats.options.find(option =>  option.optionId === answer.answeredNPSQuestionOption.id)
					const pct = Math.round(optionStats.total * 100 / stats.totalAnswers)
					statsText = ` (${pct}% ${t('optionsStatsSuffix')})`
				}
			}
		}
		return <>
			<span><strong>{answer.npsQuestion.text}</strong>:&nbsp;</span> 
			{ answer.npsQuestion.type === "OPTIONS" && <span>{answer.answeredNPSQuestionOption && answer.answeredNPSQuestionOption.text} </span> }
			{ answer.npsQuestion.type === "TEXT" && <span>{answer.answeredText && answer.answeredText}</span> }
			{ answer.npsQuestion.type === "SCORE" && answer.answeredScore && [1,2,3,4,5].map( (score) => {
				if (score <= answer.answeredScore)
					return <span>★</span>
				else 
					return null;
			})}
			<span className='answer-stat'>{statsText}</span>
		</>
	}

    return (
        <>
            <MDBModal className="review-text" centered isOpen={showUserDetailModal} toggle={() => setShowUserDetailModal(!showUserDetailModal)}>
                <MDBModalHeader toggle={() => setShowUserDetailModal(!showUserDetailModal)}>{t("profilePicture")}</MDBModalHeader>
                <MDBModalBody className="text-center">
                    {userProfilePhotoSrc !== null && <img src={userProfilePhotoSrc} style={{ maxWidth: '100%' }} alt="" />}
                    <MDBBtn color="primary" data-botbit-security="reviews-google-users-history" className="mx-auto my-3" onClick={() => showReviewHistory(review)}>{t("watchReviewHistory")}</MDBBtn>
                </MDBModalBody>
            </MDBModal>

            <Modal isOpen={showUserDetails} fullscreen toggle={() => setShowUserDetails(!showUserDetails)}>
                <ModalHeader toggle={() => setShowUserDetails(!showUserDetails)}>
                    {t("profileOf")} {review.reviewerDisplayName}
                </ModalHeader>
                <ModalBody>
                    {showUserDetails && userStores && <UserDetailsContainer selectedStores={userStores} userId={review.userId} review={review} inline={false} />}
                </ModalBody>
            </Modal>

            <MDBModal className="review-text" centered isOpen={showFatherDetailModal} toggle={() => setShowFatherDetailModal(!showFatherDetailModal)}>
                <MDBModalHeader toggle={() => setShowFatherDetailModal(!showFatherDetailModal)}>{t("detail")}</MDBModalHeader>
                <MDBModalBody className="text-left">
                    {fatherReviewLoading === true && <div className="text-center m-t m-b"><MDBSpinner /></div>}
                    {fatherReviewLoading === false && fatherReview &&
                        <>
                            <MDBRow>
                                <MDBCol>
                                    <ul className="list-inline">
                                        <li><strong>{fatherReview.reviewerDisplayName}</strong> {t("ratedYouWith")} <Score value={fatherReview.score} size="s" /> {t("inOurSatisfactionSurvey")}</li>
                                        <li>
                                            <strong>
                                                <span className="text-primary">{getDiffDescription(fatherAndSonTimeDifference)}</span>
                                                {t("later")} {review.reviewerDisplayName}
                                            </strong>
                                            {t("leftAReview")} <Score value={review.score} size="s" /> {t("in")} {getSiteName(review.socialSourceId)}
                                        </li>
                                    </ul>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-2 text-center">
                                <MDBCol>
                                    <MDBCard className="p-3 botbit-review-testimonial" >
                                        <MDBTestimonial>
                                            <MDBAvatar className="mx-auto">
                                                <img
                                                    src={fatherReview.reviewerProfilePhotoUrl.includes('noavatar') ? review.reviewerProfilePhotoUrl : fatherReview.reviewerProfilePhotoUrl}
                                                    alt=""
                                                    className="rounded-circle z-depth-1 img-fluid"
                                                    style={{ maxHeight: '150px' }}
                                                />
                                            </MDBAvatar>
                                            <h4 className="font-weight-bold dark-grey-text mt-4">{fatherReview.reviewerDisplayName}</h4>
                                            {fatherReview.comment !== null && fatherReview.comment.length > 0 && <p className="font-weight-normal dark-grey-text">
                                                <MDBIcon className="fa fa-quote-left pr-2" />
                                                {fatherReview.comment}
                                            </p>}
                                            <small>
                                                <div className="mx-auto">
                                                    <Score value={fatherReview.score} size='l' /> {t("atBotBit")}
                                                </div>
                                            </small>
                                        </MDBTestimonial>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-2">
                                <MDBCol>
                                    {!reporting &&
                                        <small>{t("reviewNotByBotBit.first")}
                                            <span className="font-weight-bold botbit-link" onClick={() => setReporting(!reporting)}>{t("reviewNotByBotBit.second")}</span>.
                                            {t("reviewNotByBotBit.third")}
                                        </small>
                                    }
                                    {reporting &&
                                        <>
                                            <MDBRow>
                                                <MDBCol className="d-none d-md-block" size="12" style={{ marginBottom: '-15px' }}>
                                                    <MDBInput type="textarea" autoFocus={true} rows={2} label={t("reviewWhyNot")} outline className="mb-2 w-100" getValue={setReportValue} />
                                                </MDBCol>
                                                <MDBCol className="d-md-none" size="12" style={{ marginBottom: '-15px' }}>
                                                    <MDBInput type="textarea" autoFocus={true} rows={2} label={t("reviewWhyNot")} outline className="mb-2 w-100" getValue={setReportValue} />
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol size="12" className="text-right">
                                                    <MDBBtn tag="a" floating color="primary" className="mr-1">
                                                        <MDBIcon icon="check" onClick={doReport} />
                                                    </MDBBtn>
                                                    <MDBBtn tag="a" floating color="danger" onClick={() => setReporting(false)}>
                                                        <MDBIcon icon="times" />
                                                    </MDBBtn>
                                                </MDBCol>
                                            </MDBRow>
                                        </>
                                    }
                                </MDBCol>
                            </MDBRow>
                        </>
                    }
                </MDBModalBody>
            </MDBModal>

            <ReplyTemplateEditModal
                showModal={showReplyTemplateModal}
                setShowModal={setShowReplyTemplateModal}
                replyTemplate={replyTemplate}
                onSaveReplyTemplate={onSaveReplyTemplate}
                categoryModifiable={false}
            />

            {mode === 'standalone' && <h4 className="subtitle">{t("reviewAt")} {review.storeName}</h4>}

            {mode === 'standalone' && <MDBAlert dismiss color="success" className="review-info">{t("reviewAdvice.first")} <strong>68%</strong> {t("reviewAdvice.second")} <strong>{t("reviewAdvice.third")}</strong> {t("reviewAdvice.fourth")}</MDBAlert>}

            <MDBCard className="review-text">
                {review.modifiedAfterReply &&
                    <MDBCardHeader className="white">
                        <MDBIcon icon="exclamation-triangle" style={{ color: '#a9a906' }} /> {t("reviewWasEdited.first")} {review.reviewerDisplayName} {t("reviewWasEdited.second")} <strong>{getReplierFullName(review)}</strong>.
                    </MDBCardHeader>}
                <MDBCardBody>
                    <MDBCardTitle tag="h6">
                        {mode === 'inline' &&
                            <MDBRow>
                                <MDBCol className="m-auto">
                                    <MDBChip onClick={() => showUserDetail(review)} size="sm" src={review.reviewerProfilePhotoUrl} alt={review.reviewerDisplayName} bgColor="light-blue lighten-4" className="mb-0 mr-1" waves>
                                        {review.reviewerDisplayName}
                                    </MDBChip>
                                </MDBCol>
                                <MDBCol className="m-auto text-center d-none d-md-block biggerFont">
                                    <b>{review.storeName}</b>
                                </MDBCol>
                                <MDBCol className="m-auto d-none d-md-block text-right biggerFont">
                                    <Score value={review.score} size="s" /> <strong>en {getSiteName(review.socialSourceId)}</strong> <Moment fromNow>{review.date}</Moment>
                                </MDBCol>
                            </MDBRow>
                        }
                        {mode === 'standalone' && <>
                            <MDBRow className="mb-2 m-0 d-flex justify-content-between">
                                <MDBChip onClick={() => showUserDetail(review)} size="sm" src={review.reviewerProfilePhotoUrl} alt={review.reviewerDisplayName} bgColor="light-blue lighten-4" className="mb-0 mr-1" waves>
                                    {review.reviewerDisplayName}
                                </MDBChip>
                                <div className="m-auto d-none d-md-block text-center">
                                    <Score value={review.score} size="s" /> <strong>en {getSiteName(review.socialSourceId)}</strong> <Moment fromNow>{review.date}</Moment>
                                </div>
                                <SocialSourceIcon site={review.socialSourceId} />
                            </MDBRow>
                            <MDBRow className="mb-2 d-md-none">
                                <MDBCol className="m-auto">
                                    <Score value={review.score} size="s" /> <strong>en {getSiteName(review.socialSourceId)}</strong> <Moment fromNow>{review.date}</Moment>
                                </MDBCol>
                            </MDBRow>
                        </>}
                    </MDBCardTitle>
                    {review.score <= 3 && <MDBRow className="mb-2 ml-0 d-flex justify-content-start align-items-center">
                        <div>{t("shareReviews")}</div>
                        <WhatsappShareButton
                            url={getSocialUrl(review)}
                            title={getSocialText(review)}
                            className="cursor-pointer mr-1">
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                    </MDBRow>}
                    {review.score >= 4 && <MDBRow className="mb-2 ml-0 d-flex justify-content-start align-items-center">
                        <div>{t("shareReviews")}</div>
                        <TwitterShareButton
                            url={getSocialUrl(review)}
                            className="cursor-pointer mr-1"
                            title={getSocialText(review)}>
                            <TwitterIcon
                                size={32}
                                round />
                        </TwitterShareButton>
                        <FacebookShareButton
                            url={getSocialUrl(review)}
                            quote={getSocialText(review)}
                            className="cursor-pointer mr-1">
                            <FacebookIcon
                                size={32}
                                round />
                        </FacebookShareButton>
                        <WhatsappShareButton
                            url={getSocialUrl(review)}
                            title={getSocialText(review)}
                            className="cursor-pointer mr-1">
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                    </MDBRow>}
                    { (!review.npsCustomAnswers || review.npsCustomAnswers.length===0) && <MDBRow>
                        <MDBCol>
                            {review.comment}
                        </MDBCol>
                    </MDBRow>}
                    { review.npsCustomAnswers && review.npsCustomAnswers.length>0 && 
						review.npsCustomAnswers.map( (answer) => (
							<MDBRow>
								<MDBCol>
									{ renderCustomAnswer(answer, answersGlobalStatistics) }
								</MDBCol>
							</MDBRow>
						))
					}
                    {mode === 'standalone' && review.replyComment === null &&
                        <div className="d-flex justify-content-between m-0 p-0">
                            <MDBBtn className="mr-1 ml-0 pl-4 pr-4" color="secondary" onClick={redirectToReviews} size="sm" rounded>
                                <MDBIcon icon="arrow-left" size="1x" /> {t("reviews")}
                            </MDBBtn>
                            {replyImplemented(review.socialSourceId) && replying === false && <MDBBtn className="mr-1 pl-4 pr-4" disabled={!isActive} color="primary" onClick={() => setReplying(!replying)} size="sm" rounded><MDBIcon icon="reply" size="1x" /> {t("reply")}</MDBBtn>}
                        </div>
                    }
                    {mode === 'standalone' && review.replyComment !== null &&
                        <div className="d-flex justify-content-between m-0 p-0">
                            <MDBBtn className="mr-1 ml-0 pl-4 pr-4" color="secondary" onClick={redirectToReviews} size="sm" rounded>
                                <MDBIcon icon="arrow-left" size="1x" /> {t("replyOtherReviews")}
                            </MDBBtn>
                        </div>
                    }
                    <MDBRow>
                        {(mode === 'inline' && replyImplemented(review.socialSourceId) && review.replyComment === null && replying === false) &&
                            <MDBCol title={!isActive ? t("storeSuspended") : ""} className="text-right">
                                <MDBBtn className="mr-1" color={isActive && "primary"} disabled={!isActive} onClick={() => setReplying(!replying)} size="sm" rounded>
                                    <MDBIcon icon="reply" size="1x" /> {t("reply")}
                                </MDBBtn>
                            </MDBCol>
                        }
                        {review.replyComment !== null && !replying &&
                            <MDBCol className="mt-2">
                                <div className="botbit-review-reply">
                                    <div>
                                        <strong>{t("replyBy")} {getReplierFullName(review)}</strong> <Moment fromNow>{review.replyTime}</Moment>
                                    </div>
                                    {review.replyComment}
                                </div>
                            </MDBCol>
                        }
                    </MDBRow>
                    {(replyImplemented(review.socialSourceId) && review.replyComment !== null && replying === false) &&
                        <MDBRow>
                            <MDBCol title={!isActive ? t("storeSuspended") : ""} className="text-right">
                                <MDBBtn className="mr-1" color={isActive && "primary"} disabled={!isActive} onClick={() => editReply(review)} size="sm" rounded>
                                    <MDBIcon icon="edit" size="1x" /> {t("edit")}
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    }
                    {replying &&
                        <>
                            <MDBRow>
                                <MDBCol size="12" style={{ marginBottom: '-15px' }}>
                                    <div className="d-flex justify-content-start" style={{ height: '50px' }}>
                                        {replyTemplates &&
                                            <>
                                                <MDBSelect
                                                    style={{ marginTop: '15px' }}
                                                    color="primary"
                                                    options={replyTemplates}
                                                    selected={t("chooseTemplate")}
                                                    getValue={selectReplyTemplate}
                                                    data-botbit-security="feedback-reviews_select_template"
                                                />
                                                {(admin && admin.operations.includes("REPLY_TEMPLATES__EDIT")) &&
                                                    <MDBBtn
                                                        flat
                                                        color="primary"
                                                        size="sm"
                                                        data-botbit-security="feedback-reviews_add_template"
                                                        onClick={openReplyTemplateModal}
                                                        className="btn-agregar-template"
                                                        style={{ marginTop: '15px', width: '60px', height: '40px', padding: '0' }}>
                                                        {t("add")}
                                                    </MDBBtn>}
                                            </>
                                        }
                                    </div>
                                    <MDBInput
                                        autoFocus={true}
                                        type="textarea"
                                        rows={2}
                                        label={t("writeAnswer")}
                                        outline
                                        className="mb-2 w-100"
                                        value={replyValue}
                                        getValue={setReplyValue} />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="reply-warning mt-2">
                                <MDBCol>
                                    {getReplyWarning(review.socialSourceId)}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="12" className="text-right">
                                    <MDBBtn tag="a" floating color="primary" className="mr-1" onClick={doReply}>
                                        <MDBIcon icon="check" />
                                    </MDBBtn>
                                    <MDBBtn tag="a" floating color="danger" onClick={cancelReply}>
                                        <MDBIcon icon="times" />
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </>
                    }
                </MDBCardBody>
                {(review.generatedBy !== null && review.score === 5) &&
                    <MDBCardFooter className="white">
                        {t("reviewStimulatedBy.first")} <strong>RAY</strong> {t("reviewStimulatedBy.second")}
                        <span className="font-weight-bold botbit-link" onClick={() => showFatherDetail(review)}>{t("seeDetail")}</span>
                    </MDBCardFooter>}
            </MDBCard>
        </>)
}

Review.translations = { ...translations }

export default withEventTracking(translate(Review))