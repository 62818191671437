import React from "react";
import { MDBContainer, MDBListGroup, MDBListGroupItem, MDBBtn, MDBIcon } from "mdbreact";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'botbit-ui-components'

const ConnectedStoresModal = ({showModal, setShowModal, stores, t}) => {

    const getTitle = () => t('socialAccounts.connectedStores')

    return (
        <Modal isOpen={showModal} toggle={() => setShowModal(true)}>
            <ModalHeader toggle={() => setShowModal(!showModal)}>{getTitle()}</ModalHeader>
            <ModalBody>
                <MDBContainer>
                <MDBListGroup>
                    {stores && stores.map(store =>
                        <MDBListGroupItem key={store.id}>{store.name}</MDBListGroupItem>
                    )}
                </MDBListGroup>
                </MDBContainer>
                <ModalFooter className="d-flex justify-content-around">
                    <MDBBtn onClick={() => setShowModal(false)} color="primary" type="submit" size="sm">
                        <MDBIcon icon="close" size="1x" /> {t('socialAccounts.close')}
                    </MDBBtn>
                </ModalFooter>
            </ModalBody>
        </Modal>
    )

}

export default ConnectedStoresModal