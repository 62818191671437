import React from 'react';
import {getAvailablePacks} from '../../../api/companyEmailPackApi';
import PackPurchaseButton from './PackPurchase'

class PackPurchaseContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state={};
    }

    updatePacks = () =>{
        getAvailablePacks(
            this.props.selectedCompany.id,
            this.props.admin.id
        )
        .then(response=>{
            if(this._isMounted === true)
                this.setState({availablePacks:response.data.data});
        });
    }

    componentDidMount(){
        this._isMounted = true;
        this.updatePacks();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedCompany.id !== prevProps.selectedCompany.id ) {
            this.updatePacks();
        }
    }

    render = () => (
        <PackPurchaseButton {...this.props} availablePacks={this.state.availablePacks}/>
    )
}
export default PackPurchaseContainer;