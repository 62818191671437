import { MDBContainer, MDBInput, MDBSwitch } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { ActionsContainer } from './ActionsTable';
import { ConversationFlowActionType } from './FlowCreator';
import { SubflowContainer } from './SubflowTable';

const actionShapeType={
    SUBFLOW : "1",
    ACTIONS : "2"
}

export const StepForm = ({t, steps, selectedStep, updateSelectedStep}) => {
    const [actionShape, setActionShape] = useState(actionShapeType.SUBFLOW);

    const updateActionShape = (value) => {
        setActionShape(value)
        updateSelectedStep({
            ...selectedStep,
            actions: []
        })
    }

    useEffect(()=>{
        if(selectedStep.actions)
            setActionShape(
                selectedStep.actions.every(a=>a.type===ConversationFlowActionType.OPTION) ?
                actionShapeType.SUBFLOW :
                actionShapeType.ACTIONS
            )
    }, [selectedStep.transientId])

    return <div>

        <MDBInput
            getValue={value=>updateSelectedStep({
                ...selectedStep,
                name:value
            })}
            value={selectedStep.name}
        ></MDBInput>

        <MDBSwitch
            labelRight={t("isInitialState")}
            labelLeft=""
            checked={selectedStep.isInitialState}
            getValue={value => {
                updateSelectedStep({
                    ...selectedStep,
                    isInitialState: value
                })
            }}
        ></MDBSwitch>
        <MDBContainer className="role-container">
            <MDBInput
                id="staff"
                checked={actionShape === actionShapeType.SUBFLOW}
                label={t("subflow")}
                type="radio"
                onClick={() => updateActionShape(actionShapeType.SUBFLOW)}
            />
            <MDBInput
                id="employer"
                checked={actionShape === actionShapeType.ACTIONS}
                label={t("actions")}
                type="radio"
                onClick={() => updateActionShape(actionShapeType.ACTIONS)}
            />
        </MDBContainer>

        {
            actionShape === actionShapeType.SUBFLOW && 
            <SubflowContainer
                steps={steps.filter((s,i)=>s.transientId!==selectedStep.transientId)}
                t={t}
                actions={selectedStep.actions}
                setActions={(actions)=>{
                    updateSelectedStep({
                        ...selectedStep,
                        actions:actions.map((v, i) => ({
                            ...v,
                            optionNumber: i+1
                        }))
                    })
                }}
            ></SubflowContainer>
        }
        {
            actionShape === actionShapeType.ACTIONS &&
            <ActionsContainer
                t={t}
                actions={selectedStep.actions}
                setActions={(actions)=>{
                    updateSelectedStep({
                        ...selectedStep,
                        actions:actions
                    })
                }}
            ></ActionsContainer>
        }
    </div>
}