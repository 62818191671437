import React from "react";
import translate from "../../../config/translations/translate";
import { ColorField, Select, Text } from "../Inputs";
import { FormikLocalPageLandingBannerImageField } from "../ImageField";
import { getLanding, updateLanding } from "../../../api/localPagesApi";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { MDBCol, MDBRow } from "mdbreact";
import { setLoading } from "../../../commons/components/application/miscActions";

const MySwal = withReactContent(Swal);
const LocationsPage = ({ t }) => {
  const [landingStyle, setLandingStyle] = React.useState();
  const company = useSelector(
    (state) => state.application.selectedCompany
  );

  const companyId=company.id

  React.useEffect(() => {
    getLanding(companyId).then((response) => {
      const data = response.data.data;
      data.bannerStyle = data.bannerStyle || {};
      data.bannerStyle.font = data.bannerStyle.font || {};
      data.linksStyle = data.linksStyle || {};
      data.linksStyle.font = data.linksStyle.font || {};
      data.backgroundStyle = data.backgroundStyle || {};
      data.breadcrumsStyle = data.breadcrumsStyle || {};
      setLandingStyle(data);
    });
  }, []);

  return (
    <div className="m-5 px-5">
      <div style={{display:'flex', direction:'row', alignItems:'center', gap:'10px', marginBottom:'50px'}}>
        <h1 style={{margin:'0%'}}>{t("title")}</h1>
        <a href={"https://reviews.rayapp.io/locations/"+company.domain}>
          <svg version="1.1" width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" enable-background="new 0 0 129 129">
            <path d="m118.5,76.7c-2.3,0-4.1,1.8-4.1,4.1v33.5h-99.8v-99.7h33.5c2.3,5.32907e-15 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1h-37.6c-2.3,0-4.1,1.8-4.1,4.1v107.9c0,2.3 1.8,4.1 4.1,4.1h107.9c2.3,0 4.1-1.8 4.1-4.1v-37.6c0.1-2.2-1.8-4.1-4-4.1z"/>
            <path d="m122.3,9c-0.5-1-1.3-1.8-2.3-2.3 0,0 0,0-0.1,0-0.5-0.2-1-0.3-1.5-0.3h-54c-2.3,0-4.1,1.8-4.1,4.1s1.8,4.1 4.1,4.1h44.1l-47,47c-1.6,1.6-1.6,4.2 0,5.8 0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l47-47v44.1c0,2.3 1.8,4.1 4.1,4.1 2.3,0 4.1-1.8 4.1-4.1v-54c0.1-0.5-0.1-1-0.2-1.5z"/>
          </svg>
        </a>
      </div>
      {landingStyle && (
        <LocationsPageForm
          t={t}
          landingStyle={landingStyle}
          companyId={companyId}
        />
      )}
    </div>
  );
};

const fontValidations = Yup.object().shape({
  family: Yup.string(),
  size: Yup.number(),
  color: Yup.string(),
});

const LocationsPageForm = ({ t, landingStyle, companyId }) => {
  const formRef = React.useRef();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required(t("businessNameRequired")),
    bannerStyle: Yup.object().shape({
      font: fontValidations,
      image: Yup.string(),
    }),
    backgroundStyle: Yup.object().shape({
      color: Yup.string(),
      opacity: Yup.string(),
    }),
    linksStyle: Yup.object().shape({
      font: fontValidations,
    }),
  });

  const onSubmit = (values) => {
    MySwal.fire({
      type: "warning",
      text: t("submitConfirmText"),
      confirmButtonText: t("ok"),
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: t("cancel")
      
    }).then((result)=>{
      if(result.value){
        dispatch(setLoading(true));
        updateLanding(companyId, values).then((response) => {
          dispatch(setLoading(false));
          MySwal.fire({
            type: "success",
            text: t("submitOkText"),
            confirmButtonText: t("ok"),
            showCloseButton: true,
          });
        });
      }
    });
  };

  return (
    <Formik
      ref={formRef}
      initialValues={landingStyle}
      onSubmit={(values, actions) => {
        onSubmit(values);
      }}
      validationSchema={validationSchema}
    >
      {({ errors, handleSubmit, values }) => {
        return (
          <form data-testid="LocalPageLandingForm" onSubmit={handleSubmit}>
            <Text
              errors={errors && errors.businessName}
              label={t("businessName")}
              name={"businessName"}
            />
            <h2>{t("bannerStyle")}</h2>
            <hr class="rounded"></hr>
            <FormikLocalPageLandingBannerImageField
              errors={errors && errors.bannerStyle && errors.bannerStyle.image}
              images={[]}
              label={t("bannerStyle.image")}
              t={t}
            />
            <FontSelector
              t={t}
              errors={
                errors && errors.backgroundStyle && errors.bannerStyle.font
              }
              name={"bannerStyle.font"}
            />
            
            <h2>{t("backgroundStyle")}</h2>
            <hr class="rounded"></hr>
            <MDBRow>
              <MDBCol>
                <ColorField
                  errors={
                    errors && errors.backgroundStyle && errors.backgroundStyle.color
                  }
                  label={t("color")}
                  name={"backgroundStyle.color"}
                />
              </MDBCol>
              <MDBCol>
                <Select
                  errors={
                    errors &&
                    errors.backgroundStyle &&
                    errors.backgroundStyle.opacity
                  }
                  label={t("opacity")}
                  name={"backgroundStyle.opacity"}
                  options={[
                    {text: "0.25", value:"0.25"},
                    {text: "0.50", value:"0.5"},
                    {text: "0.75", value:"0.75"},
                    {text: "1", value:"1"},
                  ]}
                    />
              </MDBCol>
            </MDBRow>
            <h2>{t("linkStyle")}</h2>
            <hr class="rounded"></hr>
            <FontSelector
              t={t}
              errors={errors && errors.linksStyle && errors.linksStyle.font}
              name={"linksStyle.font"}
            />
            <h2>{t("breadcrumsStyle")}</h2>
            <hr class="rounded"></hr>
            <ColorField
              errors={
                errors && errors.breadcrumsStyle && errors.breadcrumsStyle.backgroundColor
              }
              label={t("backgroundColor")}
              name={"breadcrumsStyle.backgroundColor"}
            />
            <FontSelector
              t={t}
              errors={errors && errors.breadcrumsStyle && errors.breadcrumsStyle.font}
              name={"breadcrumsStyle.font"}
            />
            <div className="text-right">
                <button
                    type="submit"
                    className="btn btn-primary"
                    data-testid="submit"
                    >
                    {t("submit")}
                </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

const FontSelector = ({ t, name, errors }) => {
  return (
    <>
    <h3>{t("font")}</h3>
    <div>
      <MDBRow>
      <MDBCol>
      <Select
        errors={errors && errors.family}
        label={t("variant")}
        name={name + ".family"}
        options={[
          { text: "Arial", value: "Arial" },
          { text: "Verdana", value: "Verdana" },
          { text: "Tahoma", value: "Tahoma" },
          { text: "Trebuchet", value: "Trebuchet" },
          { text: "Times", value: "Times" },
          { text: "Georgia", value: "Georgia" },
          { text: "Garamond", value: "Garamond" },
          { text: "Courier", value: "Courier" },
          { text: "Brush", value: "Brush" },
        ]}
      />
      </MDBCol>
      <MDBCol>
      <Select
        errors={errors && errors.size}
        label={t("size")}
        name={name + ".size"}
        options={[
          {text: "8", value: 8},
          {text: "9", value: 9},
          {text: "10", value: 10},
          {text: "12", value: 12},
          {text: "14", value: 14},
          {text: "18", value: 18},
          {text: "24", value: 24},
          {text: "30", value: 30},
          {text: "36", value: 36},
          {text: "48", value: 48},
          {text: "60", value: 60},
          {text: "72", value: 72},
        ]}
      />
      </MDBCol>
      <MDBCol>
      
      <ColorField
        errors={errors && errors.color}
        label={t("fontColor")}
        name={name + ".color"}
      />
      </MDBCol>
      </MDBRow>
    </div>
    </>
  );
};

LocationsPage.translations = {
  title: {
    en: "Configure your website locations section",
    es: "Configura la sección de ubicaciones de tu sitio web",
  },
  businessName: {
    en: "Business name",
    es: "Nombre del negocio",
  },
  businessNameRequired: {
    en: "Business name is required",
    es: "El nombre del negocio es requerido",
  },
  bannerStyle: {
    en: "Banner style",
    es: "Estilo del banner",
    image: {
      en: "Banner image",
      es: "Imagen del banner",
    },
  },
  color: {
    en: "Color",
    es: "Color",
  },
  opacity: {
    en: "Opacity",
    es: "Opacidad",
  },
  linkStyle: {
    en: "Link style",
    es: "Estilo de los links",
  },
  variant: {
    en: "Style",
    es: "Estilo",
  },
  font: {
    en: "Font",
    es: "Fuente",
  },
  size: {
    en: "Size",
    es: "Tamaño",
  },
  submit: {
    en: "Submit",
    es: "Actualizar",
  },
  submitOkText: {
    en: "Your changes have been saved",
    es: "Tus cambios fueron guardados",
  },
  backgroundStyle: {
    en: "Background style",
    es: "Estilo de fondo",
  },
  visit:{
    es:"Visitar sitio",
    en: "Visit site"
  },
  breadcrumsStyle:{
    es:"Estilo de breadcrum",
    en: "Breadcrum Style"
  },
  backgroundColor:{
    es:"Color de fondo",
    en: "Background color"
  },
  fontColor: {
    es: "Color de fuente",
    en: "Font color"
  },
  submitConfirmText:{
    es: "Se guardarán los cambios",
    en: "Changes will be saved"
  },
  cancel: {
    es: "Cancelar",
    en: "Cancel"
  }
};

export default translate(LocationsPage);
