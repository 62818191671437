// data-botbit-security
import { Feature } from '../commons/enums/Feature.js';

export const securityConfig = {
	elements: {
		"configurations-WIDGET": {
			operations: ["REVIEWS_WIDGET__VIEW"],
			feature: Feature.REVIEWS_WIDGET
		},
		"configurations-PROMOCODES": {
			operations: ["PROMO_CODES__VIEW"],
			feature: Feature.CAMPAIGNS
		},
		"feedback-reviews_select_template": {
			operations: ["REPLY_TEMPLATES__SELECT"],
			feature: Feature.REPLY_TEMPLATES
		},
		"emailActivity-purchase_pack": {
			operations: ["CAMPAIGNS__EDIT"],
			feature: Feature.CAMPAIGNS
		},
		"users-menu-send-campaign": {
			operations: ["CAMPAIGNS__EDIT"],
			feature: Feature.CAMPAIGNS
		},
		"users-menu-send-nps": {
			operations: ["REVIEWS_REQUEST_WHATSAPP__EXECUTE"],
			feature: Feature.WHATSAPP_REVIEW_REQUEST
		},
		"users-menu-review-history": {
			operations: ["USER_REVIEW_HISTORY__VIEW"],
			feature: Feature.USERS_REVIEW_HISTORY
		},
		"users-review-history": {
			operations: ["USER_REVIEW_HISTORY__VIEW"],
			feature: Feature.USERS_REVIEW_HISTORY
		},
		"auto-reply-rules-activate": {
			operations: ["AUTO_REPLY_RULES__ACTIVATE"],
			feature: Feature.REVIEWS_AUTO_REPLY
		},
		"reviews-google-users-history": {
			operations: ["USER_REVIEW_HISTORY__VIEW"],
			feature: Feature.USERS_REVIEW_HISTORY
		}
	}
}