import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReviewsWidgetConfig, setReviewsWidgetConfig } from '../../api/feedbackApi';
import { Filters } from 'botbit-ui-components';
import translate from '../../config/translations/translate';
import { translations } from '../../config/translations/Feedback/ReviewsWidget';
import { withRouter } from 'react-router-dom';
import './ReviewsWidget.scss';
import { useCompanyAvailableStores } from '../../commons/utils/useCompanyAvalaibleStores';
import { SocialSourceNames } from '../../commons/enums/SocialSource';
import { MDBBtn, MDBIcon } from 'mdbreact';
import { setLoading } from '../../commons/components/application/miscActions';
import ReviewsWidgetDiv from './ReviewsWidgetDiv';
import { envConfig } from '../../config/config';

const copy = id => {
    const copyText = document.getElementById(id).textContent;
    const textArea = document.createElement('textarea');
    textArea.style.position = 'absolute';
    textArea.style.left = '-100%';
    textArea.textContent = copyText;
    document.body.append(textArea);
    textArea.select();
    document.execCommand("copy");
}

const ReviewsWidget = ({ t }) => {
    const queryLimitMin = 15;
    const mobile = window.innerWidth < 992;
    const selectedCompanyId = useSelector(state => state.application.selectedCompany.id);
    const availableStores = useCompanyAvailableStores();
    const [widgetConfig, setWidgetConfig] = useState(null);
    const [filterValues, setFilterValues] = useState({
        timePeriod: [{ value: 1 }]
    });
    const [filterSchemas, setFilterSchemas] = useState(null);

    const dispatch = useDispatch();
    const loading = useSelector(state => state.misc.loading)

    useEffect(() => {
        if (!availableStores || availableStores.length === 0 || !widgetConfig) return;
        let sources = [];
        for (let key in SocialSourceNames) {
            sources.push({ value: parseInt(key), text: SocialSourceNames[key] });
        }
        // const scoreOptions = [
        //     {value: 1, text: t('oneStar')},
        //     {value: 2, text: t('stars', {score: 2})},
        //     {value: 3, text: t('stars', {score: 3})},
        //     {value: 4, text: t('stars', {score: 4})},
        //     {value: 5, text: t('stars', {score: 5})}
        // ]
        setFilterSchemas({
            stores: {
                id: 'stores',
                label: t('stores'),
                type: 'multiselect',
                search: true,
                priority: true,
                visible: true,
                options: availableStores.map(s => ({ value: s.id, text: s.name })),
                defaultValues: []
            },
            sources: {
                id: 'sources',
                label: t('sources'),
                type: 'multiselect',
                priority: true,
                visible: true,
                options: sources,
                defaultValues: []
            },
            amount: {
                id: 'amount',
                label: t('amount'),
                type: 'input',
                inputType: 'number',
                priority: true,
                visible: true,
                inputMin: 15,
                message: t('amountMessage'),
                messageStyle: { marginBottom: '15px', fontWeight: 'normal' },
                defaultValues: []
            },
            theme: {
                id: 'theme',
                label: t('theme'),
                type: 'select',
                priority: true,
                visible: true,
                options: [{ text: t('light'), value: 'LIGHT' }, { text: t('dark'), value: 'DARK' }, { text: t('gray'), value: 'GRAY' }],
                defaultValues: []
            }
        });
        setFilterValues({
            stores: widgetConfig.storeIds,
            sources: widgetConfig.sourceIds,
            theme: [widgetConfig.theme],
            amount: [widgetConfig.queryLimit]

        });
    }, [availableStores, widgetConfig]);

    const _getReviewsWidgetConfig = async () => {
        const config = await getReviewsWidgetConfig(selectedCompanyId);
        setWidgetConfig(config.data.data);
    }
    useEffect(() => {
        if (!selectedCompanyId) return;
        _getReviewsWidgetConfig();
    }, [selectedCompanyId]);

    useEffect(() => {
        if (!widgetConfig) return;
    }, [widgetConfig]);

    const submitFilters = async () => {
        dispatch(setLoading(true));
        const data = {
            companyId: selectedCompanyId,
            storeIds: filterValues.stores,
            sourceIds: filterValues.sources,
            theme: filterValues.theme[0],
            queryLimit: filterValues.amount && filterValues.amount[0] >= queryLimitMin ? filterValues.amount[0] : queryLimitMin,
            token: widgetConfig.token
        }
        await setReviewsWidgetConfig(data);
        await _getReviewsWidgetConfig();
        dispatch(setLoading(false));
    }

    // Se usa imgUrl para que en prod vaya por el CDN
    const backofficeUrl = envConfig['imgUrl'];    

    return (
        <div id="reviews-widget">
            <h2 class="title">
                {t('title')}
            </h2>
            <div className="mx-1 premium-tag">
                <div className="premium-tag-container">
                    <MDBIcon fas icon="crown" hint="Esta es una caracteristica premiun" />
                    <span className="premium-tag-text"> {t("newFeature")}</span>
                </div>
            </div>
            {
                filterSchemas && filterValues &&
                <>
                    <div>
                        <label>{t('personalize')}</label><hr className='separator'></hr>
                    </div>
                    <div>
                        <Filters
                            schemas={filterSchemas}
                            values={filterValues}
                            onChange={(newValues) => setFilterValues(newValues)}
                            title={null}
                            noApply
                            labels={{ moreFilters: t('others') }}
                        />
                        <div style={{ textAlign: 'center' }}>
                            <MDBBtn rounded color='primary' className='filters-button' onClick={() => submitFilters()}>{t('applyButton')}</MDBBtn>
                        </div>
                    </div>
                    <hr style={{ width: '50%', marginBottom: '35px' }}></hr>
                    {
                        !loading && widgetConfig && widgetConfig.token &&
                        <ReviewsWidgetDiv token={widgetConfig.token}></ReviewsWidgetDiv>
                    }
                    <label style={{ marginTop: '35px' }}>{t('widgetAlreadyConfigured')}</label>
                    <div style={{ marginTop: '30px' }}>
                        <label>Widget</label><hr className='separator'></hr>
                    </div>
                    <p>{t('copy1')}</p>
                    <div className={!mobile ? 'flex' : ''} style={{ textAlign: 'center', alignItems: 'end' }}>
                        <div className="code">
                            <pre><code style={{ backgroundColor: 'transparent' }} id='code-widget'>{`<div class="botbit_reviews_widget" data-token="${widgetConfig.token}"></div>`}</code></pre>
                        </div>
                        <MDBBtn rounded color='primary' className='filters-button' style={{ marginLeft: '15px' }} onClick={() => copy('code-widget')}>{t('copyButton')}</MDBBtn>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <label>{t('code')}</label><hr className='separator'></hr>
                    </div>
                    <p>{t('copy2')}</p>
                    <div className={!mobile ? 'flex' : ''} style={{ textAlign: 'center', alignItems: 'end' }}>
                        <div className="code">
                            <pre>
                                <code style={{ backgroundColor: 'transparent' }} id='code-code'>
                                    {`<link href="${backofficeUrl}/widgets/reviews/botbit-reviews-widget.css" rel="stylesheet" />
                              <script src="${backofficeUrl}/widgets/reviews/botbit-reviews-widget.js"></script>`
                                    }
                                </code>
                            </pre>
                        </div>
                        <MDBBtn rounded color='primary' className='filters-button' style={{ marginLeft: '15px' }} onClick={() => copy('code-code')}>{t('copyButton')}</MDBBtn>
                    </div>
                </>
            }
            <div style={{ height: '35px' }}></div>
        </div>
    )
}

ReviewsWidget.translations = {
    ...translations,
};

export default withRouter(translate(ReviewsWidget));