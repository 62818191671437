import React from "react";
import { Error } from "./Error.js";
import { Field } from "formik";
import { 
  UxDateSelector,
} from "botbit-ui-components";

export const DatePicker = ({
  field,
  label = "",
  errors,
  isSubmitting,
  getValue,

}) => {
  const key = label;
  return (
    <div className="schedule">
      {/* <MDBDatePicker
        data-testid={key}
        label={field.value ? label : ""}
        value={field.value}
        getValue={getValue}
        disablePast
        valueDefault={null}
        emptyLabel={label}
      /> */}
      <UxDateSelector
        data-testid={key}
        label={field.value ? label : ""}
        selectedDate={field.value}
        onChange={getValue}
        disablePast
        valueDefault={null}
        emptyLabel={label}
      />
      <Error
        errors={
          errors
        }
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export const FormikDatePicker = ({ name, label, errors, alwaysBefore, alwaysAfter }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { isSubmitting, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <DatePicker
          field={field}
          label={label}
          errors={errors}
          isSubmitting={isSubmitting}
          getValue={(value) => {
            setFieldValue(field.name, value);
            if (alwaysBefore){
              const alwaysAfterField= alwaysBefore.split('.').reduce((a, b) => a[b], values);
              if (alwaysAfterField < value){
                setFieldValue(alwaysBefore, value);
              }
            }
            if(alwaysAfter){
              const alwaysBeforeField= alwaysAfter.split('.').reduce((a, b) => a[b], values);
              if (alwaysBeforeField > value){
                setFieldValue(alwaysAfter, value);
              }
            }
          }}
        />
      )}
    </Field>
  );
};
