import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class ProfileAvatar extends React.Component {

    constructor(props) {
        super(props);
        const avatarImg = props.avatar ? props.avatar : 'noavatar.jpg';
        this.img = new Image();
        this.img.onload = this.onload;
        this.img.onerror = this.onerror;
        this.img.src = `https://admin.botbit.io/static/img/avatars/${avatarImg}`;
        this.state = { avatarImg: '' };
    }

    onload = () => {
        this.setState({
            avatarImg: this.img.src
        });
    }

    onerror = () => {
        this.setState({
            avatarImg: 'https://admin.botbit.io/static/img/avatars/noavatar.jpg'
        });
    }

    render() {
        let tooltipMessage = '';
        let style = {};
        if (this.props.blackListed || this.props.inactive) {
//            style = { border: '2px solid red' };

            if (this.props.blackListed && this.props.inactive) {
                tooltipMessage = (<div>En blacklist <br /> Ignorado por: {this.props.storeRegistration.ignoredBy} <br /> {getIgnoredDate(this.props.storeRegistration.ignoredTimestamp)}</div>);
            } else if (this.props.inactive) {
                tooltipMessage = (<div>Ignorado por: {this.props.storeRegistration.ignoredBy} <br /> {getIgnoredDate(this.props.storeRegistration.ignoredTimestamp)}</div>);
            } else {
                tooltipMessage = 'En blacklist';
            }
        }
        if (tooltipMessage !== '') {
            return (<div className="profile-image w-100">
                <OverlayTrigger
                    placement={'right'}
                    overlay={
                        <Tooltip id="tooltip">
                            {tooltipMessage}
                        </Tooltip>
                    }
                >
                    <img src={this.state.avatarImg} style={style} className="rounded-circle" alt='' />
                </OverlayTrigger>
            </div>
            );
        } else {
            return (
                <div className="profile-image w-100">
                    <img src={this.state.avatarImg} style={style} className="rounded-circle" alt='' />
                </div>
            );
        }
    }
}
ProfileAvatar.defaultProps = {
    storeRegistration: {}
}
export default (ProfileAvatar);


const getIgnoredDate = (date) => {
    if (!date)
        return "";
    try {
        return new Date(date).toLocaleString();
    } catch (error) {
        return "";
    }
}
