import React from 'react';
import { MDBBtn, MDBIcon } from "mdbreact";


export const HeaderReviewButton = ({ openReviewRequestModal, t }) => (
    <li>
        <MDBBtn data-intercom-target="review_request_button" onClick={openReviewRequestModal} color="deep-orange" size="sm">
            <MDBIcon far icon="paper-plane" size="lg" className="mr-1" />
            {t('review.reviewRequest')}
        </MDBBtn>
    </li>
);