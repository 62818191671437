import React from 'react';
import { Modal, ModalBody } from "botbit-ui-components";
import UpgradePlan from './UpgradePlan';
import { MDBIcon } from 'mdbreact';

const UpgradePlanModal = ({ product, toggle, isOpen }) => {
    return (
        <Modal id="upgrade-plan-modal" hideOtherModals className="modal-plan-upgrade mt-0" isOpen={isOpen} toggle={toggle} size="lg">
            <ModalBody>
                <div>
                    <MDBIcon className="float-right cursor-pointer" icon="times" onClick={toggle} />
                    <UpgradePlan product={product} embed />
                </div>
            </ModalBody>
        </Modal>
    );
}

export default UpgradePlanModal;