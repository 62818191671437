import React from "react";
import moment from "moment";

const DateTime = ({timestamp, bold, fontSize}) => {

    let formattedUpdateTimestamp = timestamp ? moment(timestamp) : null

    return (
        <span className={(bold) ? "text-dark" : ""} style={{
            fontSize: fontSize || '12px',
            lineHeight: '19px',
            fontWeight: '600',
            maxWidth: '100%'
        }}>
            {formattedUpdateTimestamp ?
                <>
                    {formattedUpdateTimestamp.date()}/
                    {formattedUpdateTimestamp.month() + 1}/
                    {formattedUpdateTimestamp.year()}
                    {' ' + formattedUpdateTimestamp.hour()}
                    :{formattedUpdateTimestamp.minutes() > 10 ? formattedUpdateTimestamp.minutes() : '0' + formattedUpdateTimestamp.minutes()}
                </> : "N/A"
            }
        </span>
    )
}

export default DateTime