import React, { useEffect, useState } from 'react';
import { MDBCard, MDBIcon } from 'mdbreact';
import translate from "../../../config/translations/translate";
import { translations } from "../../../config/translations/Dashboard/DashboardContainer";

import "./MinLeaderboard.scss"
import { getMinLeaderboard } from '../../../api/leaderboardApi';
import LinkToHelpCenter from '../LinkToHelpCenter/LinkToHelpCenter';
import { useHistory } from 'react-router';
import { envConfig } from "../../../config/config";

const MinLeaderboard = ({ t, stores, period, companies }) => {
    const [adminList, setAdminList] = useState([]);
    const history = useHistory()

    useEffect(() => {
        getLeaderboardAdmins();
    }, [JSON.stringify(stores), JSON.stringify(period)])

    const getLeaderboardAdmins = async () => {
        try {
            const res = await getMinLeaderboard(stores, period, "TOTAL_REVIEWS_REQUESTED")
            const adminsArray = await res.data.data.items.slice(0, 3);
            setAdminList(adminsArray)
        } catch (err) {
            console.error(err)
        }
    }


    const getMedal = (position) => {
        const medalList = ["🏆", "🥈", "🥉"];
        const medal = medalList[position]
        return (medal)
    }

    const goToFullLeaderboard = () => {
        history.push(`/leaderboard?dateFrom=${period.from.toISOString()}&dateTo=${period.to.toISOString()}&stores=${stores.join(",")}&companies=${companies.join(",")}`)
    }

    return (
        <div className="minimal-leaderboard">
            <h3 className="dashboard-title">{t('leaderboardTitle', { period: period.text })}</h3>
            <MDBCard data-intercom-target="minLeaderboard" className="minimal-leaderboard_card">
                {adminList && adminList.length > 0
                    ? <div className="admins-container">
                        {adminList.map((admin, index) => (
                            <div key={index} className="admin-item" onClick={goToFullLeaderboard}>
                                <div className="admin-item_container">
                                    <img className="rounded-circle" src={"https://admin.botbit.io/static/img/avatars/" + admin.adminAvatar} alt="admin-avatar" />
                                    <span className="admin-medal">{getMedal(index)}</span>
                                </div>
                                <span className="total-sents">
                                    <MDBIcon fas="true" icon="paper-plane"></MDBIcon> {admin.totalAccepted}
                                </span>
                                {admin.adminName ? <span>{admin.adminName}</span> : null}
                            </div>
                        ))}
                    </div>
                    : <div className="empty-state minpodium-empty-state">
                        <img src={envConfig.staticImgUrl + "noGifts.png"} alt="podium-empty-state" />
                        <span>
                            {t("podiumEmptyState")} <button onClick={() => window.location.search = "?send_review_request=true"} className="link-like-btn">{t("goNow")}</button>
                        </span>
                    </div>
                }
            </MDBCard>
            <LinkToHelpCenter
                intercomName={`minLeaderboardFooter`}
                labels={{
                    beforeLinkText: t("leaderboardFooter") + " ",
                    linkText: t('defaultLinkText'),
                    afterLinkText: "",
                }}
                // url="https://help.botbit.io/es/articles/3200769-cuales-son-las-distintas-fuentes-para-captar-los-datos-de-mis-clientes?utm_source=BotBit&utm_medium=backOffice&utm_campaign=dashboard"
                linkRenderer={() => (<button onClick={goToFullLeaderboard} className="link-like-btn">{t('defaultLinkText')}</button>)}
            />
        </div>
    );
}

MinLeaderboard.translations = { ...translations }

export default translate(MinLeaderboard);