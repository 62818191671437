import React from "react";
import translate from "../../../config/translations/translate";
import "./styles.css";


const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner">
        <svg width="108" height="108" viewBox="0 0 490 490" fill="none" xmlns="http://www.w3.org/2000/svg">

<path fill="#AEB4BB" opacity="1.000000" stroke="none" 
	d="
M491.000000,254.555832 
	C490.354126,259.356354 489.455353,263.692322 489.102631,268.072266 
	C486.461945,300.863159 477.018127,331.609772 460.956604,360.753174 
	C452.106171,356.522064 443.871582,352.136169 435.658813,347.709717 
	C433.500305,346.546356 431.431458,345.217438 429.282990,344.034332 
	C424.147949,341.206604 418.990021,338.420441 413.841339,335.617493 
	C417.568329,326.795441 421.632141,318.098022 424.953674,309.125854 
	C432.367371,289.100067 435.725403,268.283173 436.533783,246.485229 
	C455.005035,246.341156 473.002533,246.670578 491.000000,247.000000 
	C491.000000,249.370560 491.000000,251.741104 491.000000,254.555832 
z"/>
<path fill="#1D5C9B" opacity="1.000000" stroke="none" 
	d="
M146.005463,22.111906 
	C165.019180,13.094039 185.052673,7.417590 205.756454,3.993196 
	C211.327591,3.071733 216.948074,2.448570 222.772949,1.344238 
	C230.354248,1.000000 237.708496,1.000000 245.531372,1.000000 
	C246.000000,18.888103 246.000000,36.776207 246.000000,55.001968 
	C218.683136,55.319054 193.193085,60.599361 168.319244,70.839928 
	C163.483658,61.584980 159.006271,52.698997 154.901031,43.644299 
	C151.696152,36.575508 148.953506,29.297146 146.005463,22.111906 
z"/>
<path fill="#C1C4C7" opacity="1.000000" stroke="none" 
	d="
M491.000000,246.545898 
	C473.002533,246.670578 455.005035,246.341156 436.561584,246.026169 
	C435.826141,224.787537 432.575958,204.017181 426.322144,183.358704 
	C437.237549,179.072464 447.717316,174.976166 458.390076,171.466812 
	C464.433716,169.479568 470.794128,168.455627 477.011871,166.997818 
	C483.814514,184.151886 487.014740,202.115936 489.066437,220.345917 
	C489.202148,221.552124 489.819427,222.704132 490.606384,223.940674 
	C491.000000,224.750000 491.000000,225.500000 490.689270,226.884949 
	C490.585693,230.679916 490.792847,233.839966 491.000000,237.000000 
	C491.000000,240.030594 491.000000,243.061188 491.000000,246.545898 
z"/>
<path fill="#7C8EA1" opacity="1.000000" stroke="none" 
	d="
M238.464645,491.000000 
	C232.842850,490.343750 227.685837,489.686523 222.528534,489.031494 
	C217.933243,488.447845 213.337631,487.866608 208.371887,486.719727 
	C208.666077,483.452423 209.527222,480.780487 209.955750,478.040833 
	C210.971649,471.546234 211.788116,465.020294 212.674454,458.505585 
	C213.792099,450.290802 214.899078,442.074585 216.010681,433.859009 
	C224.444870,434.533356 232.893646,435.898010 241.309814,435.720093 
	C253.898590,435.453979 266.464447,434.102905 279.497559,433.713867 
	C280.571716,440.078552 280.912537,445.987885 281.863525,451.797302 
	C283.742828,463.277863 285.959717,474.703125 288.037048,486.151306 
	C276.179993,487.531128 264.322937,488.910950 252.232941,490.645386 
	C247.643097,491.000000 243.286194,491.000000 238.464645,491.000000 
z"/>
<path fill="#406B96" opacity="1.000000" stroke="none" 
	d="
M1.000000,223.399994 
	C2.262275,216.113358 3.191976,209.145096 4.857357,202.357361 
	C8.134149,189.001862 11.819531,175.746597 15.988947,162.220337 
	C17.440084,162.175415 18.269356,162.275574 19.031652,162.557098 
	C34.893219,168.414917 50.747185,174.293304 66.602646,180.167633 
	C63.121735,193.689072 59.523727,207.182281 56.251686,220.754074 
	C55.536240,223.721619 55.983616,226.969513 55.382835,230.538574 
	C36.913467,229.659225 18.956734,228.329620 1.000000,227.000000 
	C1.000000,225.933334 1.000000,224.866653 1.000000,223.399994 
z"/>
<path fill="#496F95" opacity="1.000000" stroke="none" 
	d="
M1.000000,227.468658 
	C18.956734,228.329620 36.913467,229.659225 55.277004,230.986084 
	C56.280602,245.663742 56.877399,260.344147 57.041176,275.490387 
	C47.353218,277.378937 38.098843,278.805298 28.843180,280.223267 
	C20.610819,281.484497 12.377033,282.736420 4.143877,283.992462 
	C3.319654,278.151215 2.495430,272.309998 1.335604,266.234375 
	C1.000000,253.312439 1.000000,240.624878 1.000000,227.468658 
z"/>
<path fill="#F2F3F4" opacity="1.000000" stroke="none" 
	d="
M274.539368,1.000000 
	C278.495239,1.994661 281.982391,3.019386 285.490143,3.967762 
	C286.725677,4.301816 288.011780,4.448971 289.593170,5.205840 
	C288.939270,8.162658 287.572021,10.515547 287.063263,13.041350 
	C284.900696,23.777887 282.786530,34.533489 281.096527,45.351158 
	C280.452698,49.472073 280.995544,53.778374 280.997589,58.000187 
	C275.121185,57.295986 269.146698,57.005016 263.425568,55.646843 
	C261.605194,55.214699 259.171234,51.913937 259.216980,49.977795 
	C259.473572,39.119820 260.439026,28.279877 261.077637,17.428783 
	C261.247803,14.537650 260.896210,11.605412 261.198517,8.735692 
	C261.447174,6.375520 262.309479,4.079989 262.949890,1.377905 
	C266.692902,1.000000 270.385834,1.000000 274.539368,1.000000 
z"/>
<path fill="#F3F3F4" opacity="1.000000" stroke="none" 
	d="
M491.000000,236.545898 
	C490.792847,233.839966 490.585693,230.679916 490.689270,227.259949 
	C491.000000,230.030594 491.000000,233.061188 491.000000,236.545898 
z"/>
<path fill="#8D9AA8" opacity="1.000000" stroke="none" 
	d="
M288.458832,486.162994 
	C285.959717,474.703125 283.742828,463.277863 281.863525,451.797302 
	C280.912537,445.987885 280.571716,440.078552 279.957550,433.695068 
	C286.685974,431.400146 293.461243,429.791260 300.128967,427.818909 
	C324.020874,420.751587 345.541382,409.099365 365.621948,394.037903 
	C371.719666,401.330261 377.509521,408.289581 382.936157,415.521454 
	C388.137268,422.452789 392.961487,429.666931 397.951324,436.756805 
	C365.812256,462.538666 329.549011,479.217987 288.458832,486.162994 
z"/>
<path fill="#D5D6D8" opacity="1.000000" stroke="none" 
	d="
M376.010986,38.972698 
	C385.211243,45.378727 394.902161,51.192017 403.507568,58.316486 
	C420.432861,72.328972 435.095154,88.560066 447.319885,106.899437 
	C447.591522,107.306931 447.753784,107.787338 447.714111,108.744354 
	C432.945251,119.179276 418.429413,129.103561 403.913605,139.027847 
	C397.005249,130.587784 390.880402,121.314117 383.000671,113.907440 
	C371.815399,103.393715 359.338409,94.254250 347.212891,83.895966 
	C347.080475,82.773300 346.999817,82.190285 347.238251,81.804550 
	C353.924774,70.988197 360.511566,60.106216 367.428223,49.438274 
	C369.867340,45.676258 373.126343,42.445824 376.010986,38.972698 
z"/>
<path fill="#627D99" opacity="1.000000" stroke="none" 
	d="
M121.908325,456.281464 
	C108.727394,446.794800 94.775505,438.191986 82.590683,427.563385 
	C71.271332,417.689758 61.836975,405.655121 51.787117,394.221497 
	C52.756470,393.530548 53.601192,393.311768 54.252846,392.831177 
	C63.779583,385.805664 73.396843,378.894470 82.733650,371.623566 
	C86.796227,368.459961 90.270126,364.540375 94.011375,360.964111 
	C107.866669,378.094971 123.547310,393.246796 142.102066,405.275024 
	C144.280579,406.687256 146.519791,408.005859 148.658508,409.859375 
	C145.278656,415.890198 141.907852,421.392944 138.677612,426.976959 
	C133.042236,436.718719 127.493416,446.510590 121.908325,456.281464 
z"/>
<path fill="#E5E5E6" opacity="1.000000" stroke="none" 
	d="
M375.850769,38.674660 
	C373.126343,42.445824 369.867340,45.676258 367.428223,49.438274 
	C360.511566,60.106216 353.924774,70.988197 347.238251,81.804550 
	C346.999817,82.190285 347.080475,82.773300 347.006836,83.631912 
	C332.872498,77.448112 318.924988,70.456978 304.539062,64.529915 
	C297.284790,61.541134 289.235809,60.481205 281.271881,58.274643 
	C280.995544,53.778374 280.452698,49.472073 281.096527,45.351158 
	C282.786530,34.533489 284.900696,23.777887 287.063263,13.041350 
	C287.572021,10.515547 288.939270,8.162658 289.958374,5.366169 
	C304.523529,9.007077 319.489075,11.869853 333.437225,17.331123 
	C348.031097,23.045223 361.647095,31.256748 375.850769,38.674660 
z"/>
<path fill="#75899E" opacity="1.000000" stroke="none" 
	d="
M122.100342,456.551758 
	C127.493416,446.510590 133.042236,436.718719 138.677612,426.976959 
	C141.907852,421.392944 145.278656,415.890198 148.872650,410.137543 
	C156.175293,413.346436 162.987701,417.305359 170.255554,420.064728 
	C181.052185,424.163818 192.136520,427.524933 203.171295,430.966400 
	C207.092392,432.189301 211.226395,432.729431 215.636948,433.720093 
	C214.899078,442.074585 213.792099,450.290802 212.674454,458.505585 
	C211.788116,465.020294 210.971649,471.546234 209.955750,478.040833 
	C209.527222,480.780487 208.666077,483.452423 207.999695,486.560364 
	C185.937424,484.211090 164.976532,477.616028 144.817581,468.454742 
	C137.133911,464.962860 129.790726,460.721680 122.100342,456.551758 
z"/>
<path fill="#2E6397" opacity="1.000000" stroke="none" 
	d="
M44.868534,105.961876 
	C55.882912,93.042206 66.710007,79.955444 78.009750,67.290375 
	C82.156876,62.642166 87.422646,58.991997 92.651398,55.087067 
	C104.469162,68.885262 115.813660,82.482964 127.158157,96.080658 
	C113.149574,108.194565 100.089432,121.193459 89.058014,136.381165 
	C87.419609,135.187317 86.284515,133.957138 84.953537,132.999100 
	C77.181862,127.405014 69.449249,121.749741 61.532784,116.366081 
	C56.121944,112.686394 50.433090,109.415527 44.868534,105.961876 
z"/>
<path fill="#517497" opacity="1.000000" stroke="none" 
	d="
M4.098082,284.402893 
	C12.377033,282.736420 20.610819,281.484497 28.843180,280.223267 
	C38.098843,278.805298 47.353218,277.378937 57.065948,275.938568 
	C60.697121,292.531616 65.179405,308.743469 71.939575,324.586487 
	C65.731880,327.518280 59.817211,330.051147 53.888222,332.550079 
	C51.166847,333.697113 48.388466,334.711700 45.686127,335.900482 
	C38.065964,339.252563 30.471807,342.663757 22.867397,346.051636 
	C14.730981,330.080109 9.909923,312.999908 6.056463,295.604065 
	C5.265714,292.034332 4.714118,288.411621 4.098082,284.402893 
z"/>
<path fill="#376796" opacity="1.000000" stroke="none" 
	d="
M44.578789,106.125092 
	C50.433090,109.415527 56.121944,112.686394 61.532784,116.366081 
	C69.449249,121.749741 77.181862,127.405014 84.953537,132.999100 
	C86.284515,133.957138 87.419609,135.187317 88.798965,136.581787 
	C82.591171,148.519135 76.172867,160.136124 69.914635,171.838715 
	C68.623451,174.253189 67.928749,176.986633 66.780884,179.870346 
	C50.747185,174.293304 34.893219,168.414917 19.031652,162.557098 
	C18.269356,162.275574 17.440084,162.175415 16.249325,161.971542 
	C21.468155,144.251587 30.274710,128.117401 40.128792,112.500252 
	C41.458168,110.393402 42.899536,108.357216 44.578789,106.125092 
z"/>
<path fill="#C7C9CC" opacity="1.000000" stroke="none" 
	d="
M403.990601,139.369568 
	C418.429413,129.103561 432.945251,119.179276 447.767700,109.089539 
	C455.350708,121.678360 462.918365,134.278778 469.772949,147.255875 
	C472.909790,153.194565 474.642944,159.874680 477.012268,166.608185 
	C470.794128,168.455627 464.433716,169.479568 458.390076,171.466812 
	C447.717316,174.976166 437.237549,179.072464 426.294006,182.968536 
	C418.629852,168.579773 411.348724,154.145523 403.990601,139.369568 
z"/>
<path fill="#255F99" opacity="1.000000" stroke="none" 
	d="
M127.481689,95.991066 
	C115.813660,82.482964 104.469162,68.885262 92.972809,54.958984 
	C108.665703,41.146332 126.488815,30.857376 145.646423,22.127871 
	C148.953506,29.297146 151.696152,36.575508 154.901031,43.644299 
	C159.006271,52.698997 163.483658,61.584980 167.942902,70.880325 
	C154.658493,79.445152 141.231857,87.673317 127.481689,95.991066 
z"/>
<path fill="#5A7997" opacity="1.000000" stroke="none" 
	d="
M22.905926,346.420746 
	C30.471807,342.663757 38.065964,339.252563 45.686127,335.900482 
	C48.388466,334.711700 51.166847,333.697113 53.888222,332.550079 
	C59.817211,330.051147 65.731880,327.518280 72.057541,324.948303 
	C79.569359,336.707092 86.676033,348.516846 93.897049,360.645386 
	C90.270126,364.540375 86.796227,368.459961 82.733650,371.623566 
	C73.396843,378.894470 63.779583,385.805664 54.252846,392.831177 
	C53.601192,393.311768 52.756470,393.530548 51.674515,393.923279 
	C39.877869,379.451965 30.409271,363.723969 22.905926,346.420746 
z"/>
<path fill="#9BA4AE" opacity="1.000000" stroke="none" 
	d="
M398.261169,436.656891 
	C392.961487,429.666931 388.137268,422.452789 382.936157,415.521454 
	C377.509521,408.289581 371.719666,401.330261 365.940247,393.890564 
	C375.028687,384.146881 384.265472,374.764465 394.127777,365.239746 
	C408.893097,375.811279 423.032928,386.525116 437.172791,397.238953 
	C428.837830,406.447266 420.670715,415.815887 412.095032,424.794189 
	C407.985962,429.096130 403.103333,432.659241 398.261169,436.656891 
z"/>
<path fill="#A2AAB3" opacity="1.000000" stroke="none" 
	d="
M437.446045,397.041077 
	C423.032928,386.525116 408.893097,375.811279 394.410278,365.062561 
	C400.484894,355.386353 406.902527,345.744965 413.580750,335.860535 
	C418.990021,338.420441 424.147949,341.206604 429.282990,344.034332 
	C431.431458,345.217438 433.500305,346.546356 435.658813,347.709717 
	C443.871582,352.136169 452.106171,356.522064 460.692444,360.968689 
	C453.274597,372.957245 445.496948,384.900208 437.446045,397.041077 
z"/>
        </svg>
      </div>
    </div>
  );
};

const LoadingPage = ({ t }) => {

  return (
    <div className="gbp-container loading-page-container">
    <div className="loading-page-container">
        <div className="loading-page-header">
            <h1 style={{fontFamily:'Open Sans', fontWeight:'bold', fontSize:'48px', color:'black'}}>
                {t('title')}
            </h1>
            <h4 style={{fontFamily:'Open Sans', fontWeight:'normal', fontSize:'18px', color:'black'}}>
                {t('subtitle')}
            </h4>
        </div>
        <div className="loading-page-body">
            <Spinner/>
        </div>
    </div>
    </div>

  );
};

LoadingPage.translations = {
  title: {
    en: "Analyzing...",
    es: "Analizando...",
  },
  subtitle: {
    en: "We are analyzing all your stores to help you improve your positioning!",
    es: "Estamos analizando todos tus locales para ayudarte a mejorar tu posicionamiento!",
  },
};

export const LoadingPageContainer = translate(
  LoadingPage
);
