import React from 'react';
import WizardForm from "../../commons/components/utils/form/wizard/Wizard";
import './OnBoarding.css'
import CompanyForm from "./CompanyForm";
import StoreForm from "./StoreForm";
import NextSteps from "./NextSteps";

const makeStore =(store)=>
  ({ name: 'store-'+store.id,
    title: 'Completar los datos del Store '+store.id,
    titleSort: 'Store '+store.id,
    image: '',
    component: StoreForm,
    data:{storeId:store.id}
  })

const steps = [
    { name: 'company',
      title: 'Completar los datos de la Compañia',
      titleSort: 'Compañia',
      image: '',
      component: CompanyForm,
      data:{}
    },
    makeStore({id:1}),
    makeStore({id:2}),
    makeStore({id:3}),
  { name: 'next-steps',
    title: 'Bienvenido',
    titleSort: 'Bienvenido',
    image: '',
    component: NextSteps,
    data:{}
  }
];


class OnBoardingContainer extends React.Component {
  state = {
    currentStepIndex: 0,
  };

  handleNext = (values) => {
    if(this.state.currentStepIndex +1 <steps.length){
      this.setState({...this.state, currentStepIndex: this.state.currentStepIndex +1});
    } else {
      this.props.history.push("/");
    }

  };

  handleGoTo = (stepIndex) => {
    if(stepIndex>-1 && stepIndex <steps.length){
      this.setState({...this.state, currentStepIndex: stepIndex});
    }
  };

  handlerBack = () => {
    if(steps.length===0) return;

    const prevStep = this.state.currentStepIndex -1>0 && (this.state.currentStepIndex -1) < steps.length? this.state.currentStepIndex -1:0;
    this.setState({...this.state, currentStepIndex: prevStep});
  };

  render =() => {
    return (<div className="wrapper wrapper-content on-boarding-container">
      <WizardForm steps={steps}
                  nameCurrentStep={steps[this.state.currentStepIndex].name}
                  nextHandler={(values)=>this.handleNext(values)}
                  backHandler={()=>this.handlerBack()}
                  goToHandler={(stepIndex)=>this.handleGoTo(stepIndex)}/>
    </div>)
  }
}

export default OnBoardingContainer