import React from 'react';
import { Switch, Route } from "react-router-dom";
import {
	MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBFooter, MDBNavLink,
	MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

import { connect } from 'react-redux';

import { toggleNavigationBar, setAvailableStores } from './applicationActions'
import { setLoading } from './miscActions'

import ReviewsContainer from '../../../components/feedback/ReviewsContainer.js';
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "mdbreact/dist/css/mdb.css";
import { getStoresForAdministrator } from '../../../api/administratorApi';
import SocialSourceIcon from '../../../components/feedback/SocialSourceIcon';
import DashboardContainer from '../../../components/feedback/DashboardContainer';
import DetractorContainer from '../../../components/feedback/DetractorContainer';
import { Views } from '../../../components/feedback/Views';
import SuperAdminContainer from '../../../components/feedback/SuperAdminContainer';
import ReviewContainer from '../../../components/feedback/ReviewContainer';
import { hasMasterOfTheUniverseProfile } from "../../utils/adminProfiles";
import AutoReplyRulesContainer from '../../../components/feedback/AutoReplyRulesContainer';
import ReplyTemplatesContainer from '../../../components/feedback/ReplyTemplatesContainer';
import ConnectedStoresContainer from '../../../components/feedback/ConnectedStoresContainer';
import LogoutContainer from '../../../components/login/LogoutContainer';
import { envConfig } from '../../../config/config';

class FeedbackRouter extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			initializing: true
		};
	}

	componentDidMount() {
		if (window.location.href.indexOf("/login") === -1) {
			this.props.setLoading(true);
			getStoresForAdministrator(this.props.admin.id).then((response) => {
				let availableStores = response.data.data;
				this.props.setAvailableStores(availableStores);
				this.props.setLoading(false);
				this.setState((state) => {
					return { availableStores: availableStores, initializing: false }
				});
			});
		}
	}
	componentWillUnmount = () => {
	};

	toggleCollapse = collapseID => () =>
		this.setState(prevState => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ""
		}));

	goTo = link => {
		this.props.history.push(link);
	}

	closeCollapse = collapseID => () =>
		this.state.collapseID === collapseID && this.setState({ collapseID: "" });

	render() {
		return (
			<Router>
				<div className="flyout">
					<MDBNavbar color="indigo" dark expand="md" fixed="top" className="py-1" scrolling>
						<MDBNavbarBrand href="/">
							<div className="d-flex justify-content-start">
								<SocialSourceIcon site={1} />
								<div className="d-flex flex-column">
									<span style={{ marginLeft: '5px', marginTop: '-4px' }}>Feedback Center</span>
									<small style={{ fontSize: '50%', marginTop: '-4px', marginLeft: '5px' }}>{this.props.admin.name} {this.props.admin.lastName}</small>
								</div>
							</div>
						</MDBNavbarBrand>
						<MDBNavbarToggler onClick={this.toggleCollapse("mainNavbarCollapse")} />
						<MDBCollapse
							id="mainNavbarCollapse"
							isOpen={this.state.collapseID}
							navbar>
							<MDBNavbarNav right>
								<MDBNavItem>
									<MDBNavLink
										onClick={this.closeCollapse("mainNavbarCollapse")}
										to={Views.DASHBOARD_VIEW}>
										Dashboard
                  					</MDBNavLink>
								</MDBNavItem>
								<MDBNavItem>
									<MDBNavLink
										onClick={this.closeCollapse("mainNavbarCollapse")}
										to={Views.REVIEWS_VIEW}>
										Reseñas
                  					</MDBNavLink>
								</MDBNavItem>
								<MDBNavItem>
									<MDBNavLink
										onClick={this.closeCollapse("mainNavbarCollapse")}
										to={Views.DETRACTORS_VIEW}>
										Detractores
                  					</MDBNavLink>
								</MDBNavItem>
								<MDBNavItem>
									<MDBDropdown>
										<MDBDropdownToggle nav caret>
											<span className="mr-2">Configuraciones</span>
										</MDBDropdownToggle>
										<MDBDropdownMenu>
											<MDBDropdownItem><a href={Views.REPLY_TEMPLATES_VIEW}>Plantillas de respuestas</a></MDBDropdownItem>
											<MDBDropdownItem><a href={Views.AUTO_REPLY_RULES_VIEW}>Reglas de respuestas automáticas</a></MDBDropdownItem>
											<MDBDropdownItem><a href={Views.CONNECTED_STORES}>Tiendas conectadas</a></MDBDropdownItem>
										</MDBDropdownMenu>
									</MDBDropdown>
								</MDBNavItem>
								{this.props.admin && hasMasterOfTheUniverseProfile(this.props.admin) &&
									<MDBNavItem>
										<MDBNavLink
											onClick={this.closeCollapse("mainNavbarCollapse")}
											to={Views.SUPER_ADMIN_VIEW}>
											SuperAdmin
										</MDBNavLink>
									</MDBNavItem>
								}
								<MDBNavItem>
									<MDBNavLink
										onClick={this.closeCollapse("mainNavbarCollapse")}
										to={Views.LOGOUT_VIEW}>
										Salir
                  					</MDBNavLink>
								</MDBNavItem>
							</MDBNavbarNav>
						</MDBCollapse>
					</MDBNavbar>
					{/* {collapseID && overlay} */}
					<main style={{ marginTop: "4rem" }}>
						{!this.state.initializing &&
							<Switch>
								<Route path={Views.DASHBOARD_VIEW} component={DashboardContainer} />
								<Route path={Views.REVIEWS_VIEW + '/:reviewId'} component={ReviewContainer} />
								<Route path={Views.REVIEWS_VIEW} component={ReviewsContainer} />
								<Route path={Views.DETRACTORS_VIEW} component={DetractorContainer} />
								<Route path={Views.REPLY_TEMPLATES_VIEW} component={ReplyTemplatesContainer} />
								<Route path={Views.AUTO_REPLY_RULES_VIEW} component={AutoReplyRulesContainer} />
								<Route path={Views.CONNECTED_STORES} component={ConnectedStoresContainer} />
								<Route path={Views.SUPER_ADMIN_VIEW} component={SuperAdminContainer} />
								<Route path={Views.LOGOUT_VIEW} component={LogoutContainer} />
								<Route path={'/'} component={DashboardContainer} />
							</Switch>
						}
					</main>
					<MDBFooter color="indigo">
						<p className="footer-copyright mb-0 py-3 text-center">
							&copy; {new Date().getFullYear()} Copyright: <a href="https://www.botbit.io"> {envConfig.companyName} </a>
						</p>
					</MDBFooter>
				</div>
			</Router>
		)
	}
}

function mapStateToProps(state) {
	return {
		// selectedStore: state.application.selectedStore,
		// selectedCompany: state.application.selectedCompany,
		// availableCompanies: null,
		admin: state.login.admin,
		// token: state.login.token,
		// navigationMenuExpanded: state.application.navigationMenuExpanded,
		// initializing: state.misc.initializing
	}
}

export default connect(mapStateToProps, { toggleNavigationBar, setLoading, setAvailableStores })(FeedbackRouter);
