export const translations = {

    notConnected:{
        es: "No conectada",
        en: "Not connected"
    },
    connectedBy: {
        es: "Conectada por",
        en: "Connected by"
    },
    connectedWith: {
        es: "Conectada con la tienda",
        en: "Connected with store"
    },
    source: {
        es: "Fuente",
        en: "Source"
    },
    name: {
        es: "Nombre",
        en: "Name"
    },
    status: {
        es: "Estado",
        en: "Status"
    },
    showRow: {
        es: "Ver detalle",
        en: "More details"
    },
    CONNECTED: {
        es: "Conectada",
        en: "Connected"
    },
    OPTIN_OK: {
        es: "Pendiente",
        en: "Pending"
    },
    DISCONNECTED: {
        es: "Desconectada",
        en: "Disconnected"
    },
    connectedWhen: {
        es: "Conectada el",
        en: "Connected"
    },
    cancel: {
        es: "Cancelar",
        en: "Cancel"
    },
    confirm: {
        es: "Confirmar",
        en: "Confirm"
    },
    disconnectTitle: {
        es: "Desconectar cuenta",
        en: "Disconnect account"
    },
    disconnectBody: {
        es: "Al desconectar la cuenta dejarás de registrar a los clientes que te contactan por {{email}} y no les podremos enviar encuestas de satisfacción.",
        en: "If you disconnect this account, clients that contact you via {{email}} will stop being registered and we won't be able to send them satisfaction surveys."
    },
    details: {
        es: "Ver detalles",
        en: "Details"
    },
    disconnect: {
        es: "Desconectar",
        en: "Disconnect"
    },
    connectWith: {
        es: "Conectar con",
        en: "Connect with"
    },
    title: {
        es: "Conexiones a",
        en: "Connections to"
    },
    lastSync:{
        es: "Última sincronización",
        en: "Last sync"
    }
}