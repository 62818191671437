import React, { useState } from 'react';
import './userModal.scss';
import { Modal, ModalBody, Table } from 'botbit-ui-components';
import { MDBIcon } from 'mdbreact';
import defaultColumns from './defaultColumns';
import { useCompanyAvailableStores } from '../../commons/utils/useCompanyAvalaibleStores';
import UserDetailsContainer from '../../commons/components/users/details/UserDetailsContainer';
import translate from '../../config/translations/translate';
import { translations } from '../../config/translations/Dashboard/userModal';
import { withRouter } from 'react-router-dom';
import useWindowDimensions from '../../commons/utils/useWindowDimensions';
import UserReviewHistory from '../UserReviewHistory/UserReviewHistory';
import { useSelector } from "react-redux";

function UserModal(props) {
  // const adminUsername = useSelector(state => state.login.admin.username);
  // const [historyUser, setHistoryUser] = useState(false);
  const [historyModalIsOpen, setHistoryModalIsOpen] = useState(false);
  const [openedReview, setOpenedReview] = useState(null);
  const [prevYCord, setPrevYCord] = useState(0);

  // useEffect(() => {
  //   if (!adminUsername) return;
  //   setHistoryUser({
  //     "melisa@botbit.io": true,
  //     "rjchirinos": true,
  //   }[adminUsername]);
  // }, [adminUsername])

  const allStores = useSelector(state => state.application.availableStores)
  const availableStores = props.stores ? allStores.filter(s => props.stores.includes(s.id)) : useCompanyAvailableStores();

  //console.log(props);
  //console.log(availableStores);
  const { isMedium } = useWindowDimensions();

  const columns = props.hideDefaultColumns ? [...props.columns] : [...defaultColumns, ...props.columns];
  const columnsMobile = props.columnsMobile

  const openHistoryModal = (reviewId) => {
    document.getElementById("scrollable-component-mobile").style.display = "none!important";
    setOpenedReview(reviewId);
    setHistoryModalIsOpen(true);
  }

  const closeHistoryModal = () => {
    document.getElementById("scrollable-component-mobile").style.display = "flex!important";
    setHistoryModalIsOpen(false);
  }

  const scrollToBottomOfChat = (event, reviewId) => {
    event.preventDefault();
    if (reviewId !== openedReview) {
      const scrollableComponent = document.getElementById("scrollable-component")
      const calculatedCord = (event.screenY + scrollableComponent.scrollTop - 80);
      const cordToScroll = ((openedReview && (calculatedCord > prevYCord))
        ? calculatedCord - 400
        : calculatedCord)
      setTimeout(scrollableComponent.scrollTo({
        top: cordToScroll,
        left: 0,
        behavior: "smooth"
      }), 1750);
      setOpenedReview(reviewId);
      setPrevYCord(cordToScroll);
    } else {
      setOpenedReview(null)
    }
  }

  return (
    <div id="user-modal" className={`user-modal${props.isOpen ? " modal-open" : ""}`}>
      <Modal id="scrollable-component-mobile" isOpen={props.isOpen} fullscreen toggle={props.toggle}>
        <ModalBody id="scrollable-component" style={{ paddingBottom: '17px' }}>
          <span>
            <MDBIcon
              fas
              size="2x"
              icon="arrow-left"
              onClick={props.toggle}
              style={{ cursor: 'pointer' }}
            />
          </span>
          <div className={`container${isMedium ? " p-0" : ""}`}>
            {props.title && <h1 className="modal-title">{props.title}</h1>}
            <Table
              getData={(page, order, filters) => props.data(page, order, filters, props.stores, props.period)}
              columns={(isMedium && columnsMobile) ? columnsMobile : columns}
              pagination={'button'}
              scrollTopButton
              dataPath={props.dataPath}
              title={props.title || null}
              elementKey={props.elementKey}
              securityId={props.securityId}
              getScrollContainer={() => document.getElementById(
                isMedium
                  ? "scrollable-component-mobile"
                  : "scrollable-component"
              )}
              order={props.order}
              showFiltersInfo={true}
              onElementClick={(element, event) => {
                if (props.showHistory && isMedium) openHistoryModal(element.id);
                if (props.showHistory && !isMedium) scrollToBottomOfChat(event, element.id);
              }}
              expandElement={(active, element) => {
                return (
                  active === true && (
                    <div className="pt-2 gray-bg">
                      {(!props.showHistory) && (element.userId
                        ? <UserDetailsContainer
                          inline={false}
                          userId={element.userId}
                          selectedStores={availableStores}
						  componentSelectedStore={element.storeId}
						  hideActions={props.hideActions}
                        />
                        : <span className="p-2">{props.t("noData")}</span>)
                      }
                      {
                        props.showHistory && !isMedium &&
                        <UserReviewHistory
                          className='user-review-history'
                          fullId={element.id}
                        />
                      }
                    </div>
                  )
                );
              }}
              defaultFilters={props.defaultFilters || []}
            />
          </div>
        </ModalBody>
      </Modal>
      <Modal id="review-history-modal" className="review-history-modal" fullscreen isOpen={historyModalIsOpen} toggle={closeHistoryModal}>
        {/* <ModalHeader toggle={closeHistoryModal} /> */}
        <ModalBody>
          <UserReviewHistory
            className='user-review-history'
            fullId={openedReview}
            isMobile={isMedium}
            toggle={closeHistoryModal}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

UserModal.translations = {
  ...translations
}

export default withRouter(translate(UserModal))
