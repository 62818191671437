import React from 'react'
import { Carousel } from 'botbit-ui-components'
import { MDBCardTitle, MDBCard, MDBContainer, MDBSpinner } from 'mdbreact'
import './NPSCards.scss'
import { translate } from 'react-i18next'

const NPSCards = ({ t, ratings, loading }) => {
	// const ifThereIsLessThan3RatingsAndIsNotAverage = (r) => ratings.length < 3 && r.type !== "AVERAGE"

	//basicamente, lo que dice el titulo.
	//si me vienen menos de 3 tarjetas, es decir, AVERAGE y algun store, entonces para ese store no quiero mostrar BEST o WORST
	//solo mostrar "NPS"

	const isNumber = (number) => number || number === 0

	const items = []
	// ratings.forEach(rating => {
	// 	rating.score && items.push(
	// 		<MDBCard className={"card-body m-3"}>
	// 			<MDBCardTitle className="m-auto">{ifThereIsLessThan3RatingsAndIsNotAverage(rating) ? t(`nps.nps`) : t(`nps.${rating.type}`)}</MDBCardTitle>
	// 			<div className='color-number m-auto' style={{ color: rating.color }}>{rating.score}</div>
	// 			<p className="m-auto">{rating.storeName || (rating.type === "AVERAGE" ? t('nps.average') : '')}</p>
	// 		</MDBCard>
	// 	)
	// })
	ratings.forEach(rating => {
		isNumber(rating.nps) && items.push(
			<MDBCard className={"card-body m-3"}>
				<MDBCardTitle className="m-auto">{t(`nps.${rating.type}`)}</MDBCardTitle>
				<div className='color-number m-auto' style={{ color: rating.color }}>{rating.nps}</div>
				<p className="m-auto">{rating.name}</p>
			</MDBCard>
		)
	})
	return (<MDBContainer className="nps-cards">
		{loading ?
			<div className="w-100 d-flex justify-content-center">
				<MDBSpinner />
			</div>
			:
			<Carousel
				items={items}
				autoPlay={false}
				fadeOutAnimation={true}
				mouseDragEnabled={true}
				responsive={
					{
						991: {
							items: 2
						},
						1200: {
							items: 3
						}
					}
				}
				disableAutoPlayOnAction={true}
				buttonsDisabled={true}
				infinite={false}
			/>
		}
	</MDBContainer>)
}

export default translate('common')(NPSCards)