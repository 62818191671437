import isFeedbackCenterInStandaloneMode from "./FeedbackCenterUtil";

export const Views = {
	DASHBOARD_VIEW: isFeedbackCenterInStandaloneMode() ? '/dashboard' : '/feedback/metrics',
	DETRACTORS_VIEW: '/detractors',
	REVIEWS_VIEW: isFeedbackCenterInStandaloneMode() ? '/reviews' : '/feedback/reviews',
	SUPER_ADMIN_VIEW: '/superadmin',
	REPLY_TEMPLATES_VIEW: '/replytemplates',
	AUTO_REPLY_RULES_VIEW: '/autoreplyrules',
	CONNECTED_STORES: '/connectedstores',
	LOGOUT_VIEW: '/logout'
}
