export const translations = {
	table: {
		columns: {
			name: {
				es: "Nombre",
				en: "Name"
			},
			description: {
				es: "Descripción",
				en: "Description"
			},
			states: {
				es: "Estados",
				en: "States"
			}
		},
		rowActions: {
			edit: { 
				label: {
					es: "Editar",
					en: "Edit"
				}
			},
			delete: {
				label: {
					es: "Borrar",
					en: "Delete"
				},
				text: {
					es: "El motivo será eliminado, esta operación no podrá deshacerse",
					en: "Issue type will be deleted, this operation can not be undone"
				},
				confirm: {
					es: "Confirmar",
					en: "Confirm"
				},
				cancel: {
					es: "Cancelar",
					en: "Cancel"
				},
				feedback: {
					es: "El motivo fué eliminado",
					en: "Issue type was deleted"
				},
				close: {
					es: "Cerrar",
					en: "Close"
				}
			},
		},
		actions: {
			create: {
				es: "Crear motivo",
				en: "Create issue type"
			}
		}
	},
	company:{
		es:"Empresa",
		en: "Company"
	},
}